import API, { APIResponse } from '../api/index'
import { UpdatePushNotificationPermissionProps } from './types'

export const updatePushNotificationPermission = ({
  id,
  ...params
}: UpdatePushNotificationPermissionProps): Promise<APIResponse> => {
  return API.request('PUT', `/api/v1/user/permissions/push/${id}`, params, true)
}

export const getPushPermissions = (): Promise<APIResponse> => {
  return API.request('GET', '/api/v1/user/permissions/push', {}, true)
}
