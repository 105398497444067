import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import CheckBox from '@components/CheckBox'
import ItemSeparator from '@components/ItemSeparator'
import ListLoadingView from '@components/ListLoadingView'
import SectionListHeader from '@components/SectionListHeader'
import TransactionItem from '@components/TransactionItem'
import { FinancialAccountType, Transaction } from '@interfaces/Financial'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent from '@lib/OsidoriEvent'
import {
  default as ProgressHUD,
  default as RNProgressHud,
} from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { PayoffManager } from '@lib/api/Payoff'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React from 'react'
import {
  ActivityIndicator,
  ListRenderItem,
  Pressable,
  RefreshControl,
  SectionList,
  SectionListData,
  Text,
  View,
} from 'react-native'
import { PayoffScreenProps } from '../../PayoffScreen/types'

type State = {
  nextTransactionDate?: string | null
  refreshing: boolean
  sections?: SectionListData<Transaction>[]
  transactions: Transaction[]
  isSelectMode: boolean
  isSelected: { [transactionId: number]: boolean }
  isSelectedAll: boolean
  isDisabledSettleButtons: boolean
  isTappedSelectedAll: boolean // ページング用 一度でもすべて選択をタップしたらその後true
  isLoading: boolean
}

class BeforeSettlementListScreen extends React.Component<
  StackScreenProps<RootStackParamList, 'FinancialAccountTransactions'>,
  State
> {
  state: State = {
    refreshing: false,
    transactions: [],
    isSelectMode: false,
    isSelected: {},
    isSelectedAll: false,
    isDisabledSettleButtons: true,
    isTappedSelectedAll: false,
    isLoading: false,
  }

  private closePayoffScreenListerId?: string
  private didCancelSettlementListerId?: string
  private didUpdateTransactionListerId?: string

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      TrackingUtils.repro.track('【Screen】not settled lists', 'Screen')

      this.didUpdateTransactionListerId = OsidoriEvent.addListener(
        'DidUpdateTransaction',
        () => {
          this.getTransactions()
        },
      )

      if (this.state.transactions.length === 0) {
        this.getTransactions()
      }
    })

    this.closePayoffScreenListerId = OsidoriEvent.addListener(
      'ClosePayoffScreen',
      () => {
        this.setState({ isSelectMode: false })
        this.getTransactions()
      },
    )

    this.didCancelSettlementListerId = OsidoriEvent.addListener(
      'DidCancelSettlement',
      () => {
        this.setState({ isSelectMode: false })
        this.getTransactions()
      },
    )
  }

  componentWillUnmount() {
    OsidoriEvent.removeListener(this.closePayoffScreenListerId)
    OsidoriEvent.removeListener(this.didCancelSettlementListerId)
    OsidoriEvent.removeListener(this.didUpdateTransactionListerId)
  }

  private cancelSelectMode = () => {
    const isSelected: { [transactionId: number]: boolean } = {}
    this.state.transactions.forEach((v) => {
      isSelected[v.transactionId] = false
    })

    this.setState({
      isSelectMode: !this.state.isSelectMode,
      isSelected,
      isSelectedAll: false,
      isDisabledSettleButtons: true,
    })
  }

  private getTransactions = async (
    append = false,
    all = false,
    callback?: () => void,
  ) => {
    if (this.state.isLoading) return
    this.setState({ isLoading: true })

    try {
      const { transactions, nextTransactionDate } =
        await FinancialManager.getTransactions({
          yetPayoff: true,
          to: append
            ? this.state.nextTransactionDate
              ? this.state.nextTransactionDate
              : undefined
            : undefined,
          transfer: 'pl',
        })

      const isSelected: { [transactionId: number]: boolean } = append
        ? this.state.isSelected
        : {}
      transactions.forEach((v) => {
        isSelected[v.transactionId] = this.state.isTappedSelectedAll
      })

      const payoffTransactions = append
        ? [...this.state.transactions, ...transactions]
        : transactions

      const isNeedFetchNextTransactions = all && nextTransactionDate

      this.setState(
        {
          transactions: payoffTransactions,
          isSelected,
          nextTransactionDate,
          isDisabledSettleButtons: true,
        },
        () => {
          if (isNeedFetchNextTransactions) {
            this.setState({ isLoading: false }, () => {
              this.getTransactions(true, true, callback)
            })
          } else {
            this.setState({ isLoading: false }, () => {
              this.updateSections()
              callback?.()
            })
          }
        },
      )
    } catch (error) {
      CommonDialog.showError({ error })
      this.setState({ isLoading: false })
    }
  }

  private getFinancialAccountType = (
    transaction: Transaction,
  ): FinancialAccountType => {
    if (transaction.atUserBankAccountId) return 'bank'
    if (transaction.atUserCardAccountId) return 'card'
    if (transaction.atUserEmoneyServiceAccountId) return 'emoney'
    return 'manually_created'
  }

  private onPressItem = (item: Transaction) => {
    if (this.state.isSelectMode) {
      const isSelected = !this.state.isSelected[item.transactionId]
      this.setListItemSelected(item.transactionId, isSelected)
    } else {
      NavigationService.navigate('TransactionDetail', {
        userAccountType: FinancialManager.isGroupTransaction(item)
          ? 'family'
          : 'user',
        financialAccountType: this.getFinancialAccountType(item),
        accountId: item.accountId,
        transactionId: item.transactionId,
      })

      this.setState({ isSelectMode: false })
    }
  }

  private onPressMemoButton = (item: Transaction) => {
    if (this.state.isSelectMode) {
      const isSelected = !this.state.isSelected[item.transactionId]
      this.setListItemSelected(item.transactionId, isSelected)
    } else {
      NavigationService.navigate('TransactionDetail', {
        userAccountType: FinancialManager.isGroupTransaction(item)
          ? 'family'
          : 'user',
        financialAccountType: this.getFinancialAccountType(item),
        accountId: item.accountId,
        transactionId: item.transactionId,
        focusMemo: true,
      })
    }
  }

  private onPressSelectedAll = async () => {
    const isSelected: { [transactionId: number]: boolean } = {}

    if (this.state.isSelectedAll) {
      this.state.transactions.forEach((v) => {
        isSelected[v.transactionId] = false
      })
      this.setState({
        isSelected,
        isSelectedAll: !this.state.isSelectedAll,
        isTappedSelectedAll: true,
        isDisabledSettleButtons: this.state.isSelectedAll,
      })
    } else {
      if (this.state.nextTransactionDate) {
        ProgressHUD.show()

        this.getTransactions(true, true, () => {
          this.state.transactions.forEach((v) => {
            isSelected[v.transactionId] = true
          })

          this.setState(
            {
              isSelected,
              isSelectedAll: !this.state.isSelectedAll,
              isTappedSelectedAll: true,
              isDisabledSettleButtons: this.state.isSelectedAll,
            },
            () => {
              ProgressHUD.dismiss()
            },
          )
        })
      } else {
        this.state.transactions.forEach((v) => {
          isSelected[v.transactionId] = true
        })

        this.setState({
          isSelected,
          isSelectedAll: !this.state.isSelectedAll,
          isTappedSelectedAll: true,
          isDisabledSettleButtons: this.state.isSelectedAll,
        })
      }
    }
  }

  private onChangeSelected = (transactionId: number, isSelected: boolean) => {
    this.setListItemSelected(transactionId, isSelected)
  }

  private setListItemSelected = (
    transactionId: number,
    isSelected: boolean,
  ) => {
    const newIsSelected = {
      ...this.state.isSelected,
      [transactionId]: isSelected,
    }

    const isSelectedCount = Object.keys(newIsSelected).filter(
      (transactionId) => newIsSelected[+transactionId],
    ).length

    const isSelectedAll = this.state.transactions.length === isSelectedCount
    const isDisabledSettleButtons = isSelectedCount === 0
    this.setState({
      isSelected: newIsSelected,
      isSelectedAll,
      isDisabledSettleButtons,
    })
  }

  private renderItem: ListRenderItem<Transaction> = ({ item }) => {
    return (
      <TransactionItem
        transaction={item}
        onPress={() => this.onPressItem(item)}
        onPressMemoButton={() => this.onPressMemoButton(item)}
        isDisplayCheckMark={this.state.isSelectMode}
        isSelected={this.state.isSelected[item.transactionId]}
        onChangeSelected={(isSelected) =>
          this.onChangeSelected(item.transactionId, isSelected)
        }
      />
    )
  }

  private onEndReached = () => {
    if (this.state.nextTransactionDate) {
      this.getTransactions(true)
    }
  }

  private updateSections = async () => {
    const transactions: { [date: string]: Transaction[] } = {}

    const sections: SectionListData<Transaction>[] = []

    this.state.transactions.forEach((transaction: Transaction) => {
      const date = moment(transaction.usedDate).format('YYYYMMDD')
      if (transactions[date]) {
        transactions[date].push(transaction)
      } else {
        transactions[date] = [transaction]
      }
    })

    const today = moment().format('YYYYMMDD')

    Object.keys(transactions)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: transactions[date],
        })
      })

    this.setState({ sections })
  }

  private onPressSettleButton = async () => {
    const transactions = this.state.transactions.filter(
      (v) => this.state.isSelected[v.transactionId],
    )

    const payoffScreenProps: PayoffScreenProps = {
      displaySettleType: 'notSettled',
      transactions,
    }
    NavigationService.navigate('Payoff', payoffScreenProps)
  }

  private onPressDeletePayoffsButton = async () => {
    Alert.alert('', '未精算リストから外しますか？', [
      { text: 'いいえ', style: 'cancel' },
      { text: 'はい', onPress: this.deletePayoffs },
    ])
  }

  private deletePayoffs = async () => {
    try {
      RNProgressHud.show()
      const transactions = this.state.transactions.filter(
        (v) => this.state.isSelected[v.transactionId],
      )
      await PayoffManager.deleteYetPayoffs(transactions)
      this.getTransactions()

      this.cancelSelectMode()
      CommonDialog.showMessage('未精算リストから外しました')
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      RNProgressHud.dismiss()
    }
  }

  private renderEmptyView = () => (
    <View style={{ paddingVertical: 40, paddingHorizontal: 20 }}>
      <Text
        style={{
          fontSize: 18,
          fontWeight: 'bold',
        }}>
        未精算リスト
      </Text>
      <Text
        style={{
          marginTop: 30,
          fontSize: 14,
          fontWeight: 'normal',
          color: Color.DefaultText,
        }}>
        {'精算したい取引明細をこちらの画面に追加できます。\n'}
        {'\n'}
        {'●追加方法\n'}
        {'以下のいずれかの操作で追加できます。\n'}
        {'\n'}
        {'・該当の取引明細を開き\n'}
        {'「未精算リストに入れる」をチェック\n'}
        {'\n'}
        {'・取引明細の一覧画面で取引明細を\n'}
        {'　左にスワイプし「未精算リストに入れる」をタップ\n'}
      </Text>
    </View>
  )

  render() {
    if (!this.state.sections) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
          }}>
          <ActivityIndicator />
        </View>
      )
    }

    if (this.state.transactions.length === 0) {
      return this.renderEmptyView()
    }

    return (
      <View style={{ flex: 1, backgroundColor: 'white' }}>
        <View
          style={{
            paddingBottom: this.state.isSelectMode ? 6 : 0,
          }}>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Pressable onPress={this.cancelSelectMode}>
              <Text
                style={{
                  color: Color.Primary,
                  fontSize: 14,
                  fontWeight: 'bold',
                  padding: 20,
                }}>
                {this.state.isSelectMode ? 'キャンセル' : 'まとめて選択する'}
              </Text>
            </Pressable>
          </View>
          {this.state.isSelectMode && (
            <Pressable
              style={{
                marginRight: 120, // todo
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 15,
                marginTop: -20,
              }}
              onPress={this.onPressSelectedAll}>
              <CheckBox
                isOn={this.state.isSelectedAll}
                onPress={this.onPressSelectedAll}
              />
              <Text
                style={{
                  marginLeft: 5,
                  color: Color.DefaultText,
                  fontSize: 14,
                  fontWeight: 'bold',
                }}>
                すべて選択
              </Text>
            </Pressable>
          )}
        </View>
        <SectionList
          sections={this.state.sections}
          renderItem={this.renderItem}
          renderSectionHeader={({ section: { title } }) => (
            <SectionListHeader title={title} style={{ fontWeight: 'normal' }} />
          )}
          keyExtractor={(item: Transaction) =>
            [
              item.type,
              item.transactionId,
              item.isAccountShared,
              item.isTransactionShared,
            ].join('-')
          }
          onEndReached={this.onEndReached}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={async () => {
                this.setState({ refreshing: true })
                await this.getTransactions()
                this.setState({ refreshing: false })
              }}
            />
          }
          ItemSeparatorComponent={() => ItemSeparator}
          ListFooterComponent={() =>
            this.state.isLoading ? ListLoadingView : null
          }
          stickySectionHeadersEnabled={false}
        />
        {this.state.isSelectMode && (
          <View
            style={{
              borderTopColor: 'rgb(224,224,224)',
              borderTopWidth: 1,
              backgroundColor: 'rgb(247,247,247)',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 20,
              paddingHorizontal: 20,
              paddingBottom: 40,
            }}>
            <AppButton
              title="未精算リストから外す"
              onPress={this.onPressDeletePayoffsButton}
              disabled={this.state.isDisabledSettleButtons}
              type="white"
              style={{ flex: 1 }}
              textStyle={{ fontSize: 14, fontWeight: 'bold' }}
            />
            <View style={{ width: 10 }} />
            <AppButton
              title="精算する"
              onPress={this.onPressSettleButton}
              disabled={this.state.isDisabledSettleButtons}
              style={{ flex: 1 }}
              textStyle={{ fontSize: 14, fontWeight: 'bold' }}
            />
          </View>
        )}
      </View>
    )
  }
}

export default BeforeSettlementListScreen
