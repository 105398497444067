import { APIError, getApiUrl } from '@lib/api'
import * as FPAPI from './api'
import { GetFPProfilesResponse, GetFPTokenResponse } from './types'

export const getToken = async () => {
  const response = await FPAPI.getFPToken()
  if (!response.ok) throw new APIError(response)
  return (response.json as GetFPTokenResponse).app.fpToken
}

export const getWebURL = async () => {
  const token = await getToken()
  return `${getApiUrl()}/fp?fp_token=${token}`
}

export const getProfiles = async () => {
  const response = await FPAPI.getFPProfiles()
  if (!response.ok) throw new APIError(response)
  return (response.json as GetFPProfilesResponse).app
}
