// import { MaterialTopTabBarOptions } from '@react-navigation/material-top-tabs'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  flex: 1;
`

export const StyledMainContainer = styled.ScrollView`
  flex: 1;
`

export const StyledDescriptionText = styled.Text`
  margin-top: 30px;
  margin-horizontal: 20px;
  font-size: 12px;
  font-weight: normal;
  color: rgb(58, 58, 58);
  padding-bottom: 20px;
  border-bottom-color: rgb(224, 224, 224);
  border-bottom-width: 0.5px;
  line-height: 18px;
`

export const StyledSeparatorView = styled.View`
  height: 0.5px;
  margin-horizontal: 20px;
  background-color: rgb(224, 224, 224);
`

export const StyledAccountTypeContainer = styled.View`
  flex-direction: row;
  margin-vertical: 20px;
  margin-horizontal: 20px;
  align-items: center;
  justify-content: space-between;
`

export const StyledAccountTypeButtonsContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StyledPeriodContainer = styled.View`
  margin-vertical: 15px;
  margin-horizontal: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StyledPickerContainer = styled.View`
  flex-direction: row;
  margin-vertical: 20px;
  margin-left: 40px;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
`

export const StyledPickerButton = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
`

export const StyledPickerText = styled.Text`
  font-size: 15px;
  font-weight: normal;
  margin-right: 20px;
  color: rgb(53, 53, 53);
`

export const StyledTitleText = styled.Text`
  font-size: 14px;
  font-weight: bold;
  // color: rgb(53, 53, 53);
  color: rgb(102, 102, 102);
`

export const StyledPeriodDescription = styled.Text`
  font-size: 12px;
  font-weight: normal;
  color: rgb(102, 102, 102);
`

export const StyledPlSettingsContainer = styled.View`
  margin-vertical: 20px;
  margin-horizontal: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StyledButtonContainer = styled.View`
  margin-vertical: 40px;
  margin-horizontal: 20px;
`

// export const tabBarStyles: MaterialTopTabBarOptions = {
//   activeTintColor: Color.Green,
//   inactiveTintColor: '#999',
//   indicatorStyle: {
//     backgroundColor: Color.Green,
//     height: 1,
//   },
//   labelStyle: {
//     fontWeight: '600',
//     fontSize: 15,
//   },
//   style: {
//     elevation: 0,
//     backgroundColor: 'white',
//     borderBottomWidth: 1,
//     borderBottomColor: 'rgba(0,0,0,0.1)',
//   },
// }
