import { Image } from '@components'
import styled from 'styled-components/native'

export const RootContainer = styled.ScrollView`
  flex: 1;
  background-color: #f7f7f7;
  padding-horizontal: 20px;
`
export const AvatarsView = styled.View`
  padding-top: 30px;
  justify-content: center;
  flex-direction: row;
`

export const AvatarContainer = styled.View`
  padding-horizontal: 15px;
  align-items: center;
`

export const AvatarText = styled.Text`
  margin-top: 10px;
  color: rgb(102, 102, 102);
  font-size: 14px;
  font-weight: normal;
`

export const SettingsContainer = styled.View`
  margin-top: 10px;
`

export const StatusContainer = styled.View`
  margin-top: 10px;
  margin-bottom: 40px;
`

export const TitleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-vertical: 20px;
`

export const TitleText = styled.Text`
  margin-top: 10px;
  color: rgb(58, 58, 58);
  font-size: 18px;
  font-weight: bold;
`

export const EditLinkContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`

export const EditLinkText = styled.Text`
  color: rgb(102, 102, 102);
  font-size: 14px;
  font-weight: normal;
`

export const EditLinkImage = styled(Image)`
  tint-color: rgb(153, 153, 153);
`

export const Panel = styled.View`
  background-color: white;
  border-radius: 10px;
  padding-vertical: 20px;
  padding-horizontal: 25px;
`

export const SettingItemRow = styled.View<{ border?: boolean }>`
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: ${({ border }) => (border ? 10 : 0)}px;
  border-bottom-width: ${({ border }) => (border ? 1 : 0)}px;
  border-color: rgb(240, 240, 240);
`

export const SettingItemTitleText = styled.Text`
  color: rgb(58, 58, 58);
  font-size: 14px;
  font-weight: normal;
  margin-right: 5px;
  width: 30%;
  line-height: 20px;
`

export const SettingItemValueContainer = styled.View`
  width: 70%;
`

export const SettingItemValueBoldText = styled.Text`
  color: rgb(63, 197, 21);
  font-size: 16px;
  font-weight: bold;
`

export const SettingItemValueText = styled.Text`
  color: rgb(102, 102, 102);
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
`

export const ResultTitleText = styled.Text`
  color: rgb(63, 197, 21);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const StatusResultTitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  background-color: rgb(63, 197, 21);
  margin-bottom: 15px;
`

export const StatusResultRankText = styled.Text`
  color: white;
  font-size: 26px;
  font-weight: bold;
`

export const StatusResultTitleText = styled.Text`
  margin-left: 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
`

export const StatusItemRow = styled.View`
  flex-direction: row;
  padding-vertical: 10px;
`

export const StatusItemContainer = styled.View`
  align-items: center;
  flex-direction: row;
  width: 50%;
`

export const StatusItemCheckmark = styled.Text<{ on: boolean }>`
  color: ${({ on }) => (on ? 'rgb(63, 197, 21)' : 'rgb(224, 224, 224)')};
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
`

export const StatusItemText = styled.Text<{ on: boolean }>`
  color: ${({ on }) => (on ? 'rgb(58, 58, 58)' : 'rgb(153, 153, 153)')};
  font-size: 14px;
  font-weight: bold;
`
export const ChildrenItemRow = styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
`

export const ChildrenItemContainer = styled.View`
  align-items: center;
  flex-direction: row;
  width: 50%;
`

export const ChildrenNumberText = styled.Text`
  background-color: rgb(224, 224, 224);
  color: rgb(102, 102, 102);
  border-radius: 9px;
  overflow: hidden;
  padding-vertical: 2px;
  padding-horizontal: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
`

export const ChildrenAgeText = styled.Text`
  margin-left: 1px;
  color: rgb(63, 197, 21);
  font-size: 16px;
  font-weight: bold;
`
