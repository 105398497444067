import Avatar from '@components/Avatar'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import { TimelineActivity } from '@lib/api/Timeline'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import DeepLinkService from '@lib/DeepLinkService'
import TimelineManager from '@lib/TimelineManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native'
import { useSelector } from 'react-redux'

interface State {
  refreshing: boolean
  nextActivityDate?: string
  isLoading: boolean
  hasNextPage: boolean
}

interface StateProps {
  activities?: TimelineActivity[]
}

const ActivitiesScreen: React.FC<
  StateProps & StackScreenProps<RootStackParamList, 'ActivitiesTab'> & State
> = () => {
  const isFocused = useIsFocused()

  const [refreshing, setRefreshing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [nextActivityDate, setNextActivityDate] = useState<string>()
  const [isReloadWhenFocusing, setIsReloadWhenFocusing] = useState(true)

  const activities = useSelector(
    ({ timeline }: RootState) => timeline.activities,
  )

  const mainScreenTab = useSelector(({ main }: RootState) => main.selectedTab)

  useEffect(() => {
    if (mainScreenTab !== 'HomeTab') {
      setIsReloadWhenFocusing(true)
    }
  }, [mainScreenTab])

  const fetchActivities = useCallback(
    async (props?: { reload?: boolean }) => {
      if (isLoading) return
      setIsLoading(true)

      try {
        const { nextActivityDate: next } =
          await TimelineManager.fetchActivities({
            nextActivityDate: props?.reload ? undefined : nextActivityDate,
          })
        setNextActivityDate(next ? next : undefined)
        setHasNextPage(!!next)
      } catch (error) {
        CommonDialog.showError({ error })
      } finally {
        setIsLoading(false)
      }
    },
    [nextActivityDate, isLoading],
  )

  const onEndReached = useCallback(() => {
    if (!isLoading && hasNextPage) {
      fetchActivities()
    }
  }, [fetchActivities, hasNextPage, isLoading])

  useEffect(() => {
    if (isFocused && isReloadWhenFocusing) {
      fetchActivities({ reload: true })
      setIsReloadWhenFocusing(false)
      TrackingUtils.repro.track('【Screen】timeline_timeline', 'Screen')
    }
    // Exclude fetchActivities
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, isReloadWhenFocusing])

  const onPressItem = (item: TimelineActivity) => {
    DeepLinkService.open(item.url)
  }

  const renderItem: SectionListRenderItem<TimelineActivity> = ({ item }) => {
    const { icon, url, message } = item

    return (
      <TouchableOpacity
        style={styles.item}
        onPress={() => onPressItem(item)}
        disabled={!url}>
        {icon === 'person' ? (
          <Avatar size={20} style={{ width: 45 }} />
        ) : (
          icon === 'family' && (
            <Avatar type="couple" size={20} style={{ width: 45 }} />
          )
        )}
        <Text style={styles.textContent}>{message}</Text>
      </TouchableOpacity>
    )
  }

  const sections = useMemo((): SectionListData<TimelineActivity>[] => {
    const sections: SectionListData<TimelineActivity>[] = []
    if (!activities) {
      return sections
    }
    const activitiesData: { [date: string]: TimelineActivity[] } = {}
    activities.forEach((activity: TimelineActivity) => {
      const date = moment(activity.day).format('YYYY-MM-DD')
      if (activitiesData[date]) {
        activitiesData[date].push(activity)
      } else {
        activitiesData[date] = [activity]
      }
    })

    Object.keys(activitiesData)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title: date,
          data: activitiesData[date],
        })
      })

    return sections
  }, [activities])

  const onRefresh = useCallback(async () => {
    setRefreshing(true)
    setNextActivityDate(undefined)
    setIsLoading(false)
    setHasNextPage(true)
    await fetchActivities({ reload: true })
    setRefreshing(false)
  }, [fetchActivities])

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: 'Timeline-Activity',
        title: 'タイムライン_アクティビティー',
      })
    }
  }, [isFocused])

  const today = moment().format('YYYY-MM-DD')

  return (
    <SectionList
      sections={sections}
      renderItem={renderItem}
      renderSectionHeader={({ section: { title } }) => (
        <SectionListHeader
          title={today === title ? '今日' : title}
          style={{ fontWeight: 'normal' }}
        />
      )}
      keyExtractor={(item, index) => JSON.stringify(item) + '-' + index}
      ItemSeparatorComponent={() => ItemSeparator}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      onEndReached={onEndReached}
      style={styles.container}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: Color.White,
  },
  textContent: {
    color: Color.Gray,
  },
})

export default ActivitiesScreen
