import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import CommonHeader from '@components/CommonHeader'
import CoupleButton from '@components/CoupleButton'
import { PremiumView } from '@components/PremiumView'
import QuestionIcon from '@components/QuestionButton'
import SimplePremiumView from '@components/SimplePremiumView'
import { UserAccountType } from '@interfaces/Account'
import { GoalContent } from '@interfaces/Goal'
import Color from '@lib/Color'
import NavigationService from '@lib/NavigationService'
import { sendSignal } from '@lib/OsidoriSignal'
import TrackingUtils from '@lib/TrackingUtils'
import { GoalManager } from '@lib/api/Goal'
import { selectGoalCategories, selectGoalEditor } from '@lib/api/Goal/slice'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Dimensions,
  FlatList,
  ImageBackground,
  ListRenderItem,
  Platform,
} from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'

const GoalSelectTypeScreen: React.FC<
  StackScreenProps<RootStackParamList, 'GoalSelectType'>
> = ({ route }) => {
  const editor = useSelector(selectGoalEditor)
  const goalCategories = useSelector(selectGoalCategories)

  const [tabUserAccountType, setTabUserAccountType] = useState<UserAccountType>(
    store.getState().account.accountMode,
  )
  const [isVisiblePremiumdModal, setIsVisiblePremiumdModal] = useState(false)

  useEffect(() => {
    if (route.params?.userAccountType) {
      setTabUserAccountType(route.params?.userAccountType)
    }
  }, [route.params?.userAccountType])

  useEffect(() => {
    GoalManager.initEditor()
    TrackingUtils.repro.track('【Screen】Saving_create', 'Screen')
  }, [])

  const onPressItem = useCallback(
    (goalContent: GoalContent) => () => {
      if (
        tabUserAccountType === 'user' &&
        store.getState().profile.userProfile?.rank !== 'premium'
      ) {
        setIsVisiblePremiumdModal(true)
        return
      }

      GoalManager.updateEditor({
        ...editor,
        goalContentId: goalContent.id,
        imgUrl: goalContent.imgUrl,
        share: tabUserAccountType === 'family',
      })
      NavigationService.navigate('EditGoalDetail')
    },
    [editor, tabUserAccountType],
  )

  const renderItem: ListRenderItem<ItemType> = useCallback(
    ({ item: { type, title, goalContent }, index }) => {
      if (type === 'header') {
        return <StyledCategoryTitle>{title}</StyledCategoryTitle>
      } else if (type === 'item' && goalContent) {
        return (
          <StyledGoalContentView
            index={index}
            onPress={onPressItem(goalContent)}>
            <StyledGoalContentImage source={{ uri: goalContent?.imgUrl }} />
            <StyledGoalContentTitle>{goalContent?.name}</StyledGoalContentTitle>
            <StyledGoalContentDescription>
              {goalContent?.description}
            </StyledGoalContentDescription>
          </StyledGoalContentView>
        )
      } else {
        return null
      }
    },
    [onPressItem],
  )

  const data = useMemo(() => {
    const items: ItemType[] = []

    goalCategories[tabUserAccountType]?.forEach((goalCategory) => {
      items.push({
        type: 'header',
        title: goalCategory.name,
      })
      items.push({ type: 'null' })

      goalCategory.goalContents.map((goalContent) => {
        items.push({
          type: 'item',
          goalContent,
        })
      })

      if (goalCategory.goalContents.length % 2 === 1) {
        items.push({ type: 'null' })
      }
    })

    return items
  }, [goalCategories, tabUserAccountType])

  return (
    <StyledRootView>
      <CommonHeader title="目標貯金の作成" />
      <StyledTitleText>まずは貯金の目的を選ぼう</StyledTitleText>
      <StyledCaptionButton onPress={onPressNavigateGoalLP}>
        <StyledCaptionText>目標貯金とは</StyledCaptionText>
        <QuestionIcon size={12} webSize="1x" />
      </StyledCaptionButton>
      <StyledTabView>
        <CoupleButton
          buttonType="couple"
          onPress={() => setTabUserAccountType('family')}
          isOn={tabUserAccountType === 'family'}
          title="家族画面"
          disabled={tabUserAccountType === 'family'}
          style={{
            height: 25,
            width: Platform.OS !== 'web' ? '46%' : wp(46),
          }}
        />
        <CoupleButton
          buttonType="single"
          onPress={() => setTabUserAccountType('user')}
          isOn={tabUserAccountType === 'user'}
          title="個人画面"
          disabled={tabUserAccountType === 'user'}
          style={{
            height: 25,
            width: Platform.OS !== 'web' ? '46%' : wp(46),
          }}
        />
      </StyledTabView>

      {data.length > 0 ? (
        <FlatList
          keyExtractor={(item, index) =>
            item?.title
              ? item.title
              : item.goalContent
              ? item.goalContent.id.toString()
              : `null-${index}`
          }
          renderItem={renderItem}
          data={data}
          numColumns={2}
          contentContainerStyle={{ paddingBottom: 30 }}
        />
      ) : null}
      {isVisiblePremiumdModal ? (
        Platform.OS !== 'web' ? (
          <PremiumView
            showTopMargin
            premiumType="goal"
            title="プレミアムプランの機能です"
            message="個人の目標貯金はプレミアムプランにてご利用いただけます。"
            buttonTitle="さっそく作成する"
            onPressShowDetailButton={() => {
              sendSignal('CLOSED_PURCHASE_COMPLETE_MODAL', 'goal')
              setIsVisiblePremiumdModal(false)
            }}
            onBackdropPress={() => setIsVisiblePremiumdModal(false)}
          />
        ) : (
          <SimplePremiumView
            title="プレミアムプランの機能です"
            message="個人の目標貯金はプレミアムプランにてご利用いただけます。"
            image={require('@images/premium/limit_goal.webp')}
            onPressShowDetailButton={() => {
              setIsVisiblePremiumdModal(false)
              navigatePremiumPlanLP({ planCode: '001' })
            }}
            onDissmiss={() => {
              setIsVisiblePremiumdModal(false)
            }}
          />
        )
      ) : null}
    </StyledRootView>
  )
}

type ItemType = {
  type: 'header' | 'item' | 'null'
  title?: string
  goalContent?: GoalContent
}

const onPressNavigateGoalLP = () => NavigationService.navigate('GoalLP')

const { width } = Dimensions.get('window')

const StyledRootView = styled.View({ flex: 1 })

const StyledTitleText = styled.Text({
  marginTop: 20,
  fontSize: 18,
  fontWeight: 'bold',
  color: Color.DefaultText,
  textAlign: 'center',
})

const StyledCaptionButton = styled.TouchableOpacity({
  marginTop: 8,
  paddingBottom: 1,
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'center',
  borderBottomWidth: 1,
  borderColor: Color.Gray,
})

const StyledCaptionText = styled.Text({
  fontSize: 12,
  color: Color.Gray,
  marginRight: 2,
})

const StyledTabView = styled.View({
  marginTop: 20,
  marginBottom: 10,
  flexDirection: 'row',
  backgroundColor: '#f0f0f0',
  alignSelf: 'center',
  borderRadius: 16,
  justifyContent: 'space-between',
})

const StyledCategoryTitle = styled.Text({
  marginTop: 30,
  marginLeft: 15,
  marginBottom: 0,
  marginHorizontal: 8,
  fontSize: 14,
  color: Color.DefaultText,
  fontWeight: 'bold',
})

const StyledGoalContentView = styled.TouchableOpacity<{ index: number }>(
  ({ index }) => ({
    marginTop: 20,
    borderRadius: 5,
    marginRight: index % 2 === 0 ? 7.5 : 15,
    marginLeft: index % 2 === 1 ? 7.5 : 15,
    width: (width - 45) / 2,
    overflow: 'hidden',
    backgroundColor: Color.GrayVeryLight,
  }),
)

const StyledGoalContentImage = styled(
  // TODO なぜかconfig-overridesのaliasが効かないのでPlatform.OSをみる
  Platform.OS !== 'web' ? FastImage : ImageBackground,
).attrs({
  resizeMode: 'cover',
})({
  width: '100%',
  height: 'auto',
  aspectRatio: 5 / 3,
})

const StyledGoalContentTitle = styled.Text({
  marginTop: 8,
  marginHorizontal: 8,
  fontSize: 14,
  color: Color.DefaultText,
  fontWeight: 'bold',
})

const StyledGoalContentDescription = styled.Text({
  marginTop: 5,
  marginHorizontal: 8,
  marginBottom: 8,
  fontSize: 12,
  color: Color.DefaultText,
})

export default GoalSelectTypeScreen
