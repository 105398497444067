import { PressableProps } from 'react-native'
import styled from 'styled-components/native'

export type SettleButtonProps = PressableProps

const SettleButton = ({ onPress }: SettleButtonProps) => {
  return (
    <RootContainer onPress={onPress}>
      <StyleImage />
      <TextContainer>
        <StyleText>精算する</StyleText>
      </TextContainer>
    </RootContainer>
  )
}

const RootContainer = styled.Pressable({
  flex: 1,
  minWidth: 80,
  backgroundColor: 'rgb(71, 211, 255)',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyleImage = styled.Image.attrs({
  source: require('@images/icons/icon-settlement.png'),
  resizeMode: 'contain',
})({
  marginTop: 8,
  height: 30,
  width: '100%',
})

const TextContainer = styled.View({
  marginTop: 2,
  height: 30,
  justifyContent: 'center',
})

const StyleText = styled.Text({
  fontSize: 10,
  fontWeight: 'normal',
  color: 'white',
  textAlign: 'center',
})

export default SettleButton
