import React from 'react'
import { Image as RnImage, ImageProps, ImageSourcePropType } from 'react-native'

export const Image: React.FC<
  Omit<ImageProps, 'source'> & { source: string | ImageSourcePropType }
> = ({ source, ...props }) =>
  typeof source === 'string' ? (
    <RnImage source={source as never} {...props} />
  ) : (
    <RnImage source={source} {...props} />
  )
