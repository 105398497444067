import { MainScreenTab } from '../types'
import { Action, ActionType } from './types'

export const updateMainScreenSelectedTab = (
  selectedTab: MainScreenTab,
): Action => ({
  type: ActionType.UpdateMainScreenTab,
  selectedTab,
})

export const setTabBarVisible = (tabBarVisible: boolean): Action => ({
  type: ActionType.SetTabBarVisible,
  tabBarVisible,
})
