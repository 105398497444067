import { PayoffScreenProps } from '@Screen/Main/Home/Payoff/PayoffScreen'
import Avatar from '@components/Avatar'
import CheckBox from '@components/CheckBox'
import CheckMarkToggleButton from '@components/CheckMarkToggleButton'
import { faList } from '@fortawesome/pro-solid-svg-icons'
import { UserAccountType } from '@interfaces/Account'
import {
  FinancialAccountType,
  PaymentUserType,
  Transaction,
} from '@interfaces/Financial'
import { selectUserAccountType } from '@lib/AccountManager/selectors'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { FAIcon } from '@lib/FAIcon'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent from '@lib/OsidoriEvent'
import ProfileManager from '@lib/ProfileManager'
import { PayoffManager } from '@lib/api/Payoff'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Animated,
  Image,
  LayoutAnimation,
  NativeModules,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { RectButton } from 'react-native-gesture-handler'
import Swipeable from 'react-native-gesture-handler/Swipeable'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import Toast from 'react-native-simple-toast'
import { useSelector } from 'react-redux'
import CategoryImage from '../CategoryImage'
import AddPayoffListButton from './AddPayoffListButton'
import SettleButton from './SettleButton'
import { RightActionContainer } from './styles'

const { UIManager } = NativeModules
UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true)

export type TransactionItemProps = {
  transaction: Transaction
  onPress: (transaction: Transaction) => void
  onPressMemoButton: (transaction: Transaction) => void
  onSwipeableOpen?: (transaction: Transaction) => void
  isDisplayCheckMark?: boolean
  isSelected?: boolean
  onChangeSelected?: (newValue: boolean) => void
  onPressAddPaylistButton?: () => void
}

const TransactionItem = (props: TransactionItemProps) => {
  const appUserAccountType = useSelector(selectUserAccountType)

  const [isSwipeableOpened, setIsSwipeableOpened] = useState(false)

  const swiper = useRef<Swipeable>(null)

  const onPressAction = useCallback(() => {
    swiper.current?.close()
  }, [])

  const renderLeftActions = useCallback(
    (
      _progress: Animated.AnimatedInterpolation<string | number>,
      dragX: Animated.AnimatedInterpolation<string | number>,
      titleUserAccountType: UserAccountType,
    ) => {
      const trans = dragX.interpolate({
        inputRange: [0, 50, 100, 101],
        outputRange: [-20, 0, 0, 1],
      })

      const title =
        '　' +
        (titleUserAccountType === 'user'
          ? `個人に\n振り分ける`
          : `家族に\n振り分ける`)

      return (
        <RectButton style={styles.leftAction} onPress={onPressAction}>
          <Animated.Text
            style={[
              styles.shareText,
              {
                transform: [{ translateX: trans }],
              },
            ]}>
            {title}
          </Animated.Text>
        </RectButton>
      )
    },
    [onPressAction],
  )

  const onPressPayoff = useCallback(() => {
    const { transaction } = props

    if (transaction.payoffId) {
      CommonDialog.showMessage('この取引はすでに精算が完了しています')
      swiper.current?.close()
      return
    }

    if (transaction.amount >= 0) {
      CommonDialog.showMessage('収入の取引明細は精算できません')
      return
    }

    if (transaction.paymentUserType === PaymentUserType.Family) {
      CommonDialog.showMessage(
        '精算ができるのは、わたしかパートナーの取引のみです。家族の取引については、精算できません。',
      )
      swiper.current?.close()
      return
    }

    const financialAccountType = ((): FinancialAccountType | void => {
      if (transaction.atUserBankAccountId) return 'bank'
      if (transaction.atUserCardAccountId) return 'card'
      if (transaction.atUserEmoneyServiceAccountId) return 'emoney'
      return 'manually_created'
    })()
    if (!financialAccountType) return

    const payoffScreenProps: PayoffScreenProps = {
      transactions: [transaction],
      displaySettleType: 'notSettled',
    }
    NavigationService.navigate('Payoff', payoffScreenProps)

    swiper.current?.close()
  }, [props])

  const onPressAddPayoffList = useCallback(async () => {
    const { transaction } = props

    if (transaction.amount >= 0) {
      CommonDialog.showMessage(
        '収入の取引明細は精算できませんので、\n未精算リストに追加できません',
      )
      return
    }

    try {
      await PayoffManager.addPayoffList(props.transaction)
      Toast.showWithGravity(
        '未精算リストに保存しました',
        Toast.SHORT,
        Toast.BOTTOM,
      )
      OsidoriEvent.emit('DidUpdateTransaction', props.transaction)
      props.onPressAddPaylistButton?.()
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      swiper.current?.close()
    }
  }, [props])

  const renderRightActions = useCallback(() => {
    return (
      <RightActionContainer>
        <SettleButton onPress={onPressPayoff} />
        <AddPayoffListButton onPress={onPressAddPayoffList} />
      </RightActionContainer>
    )
  }, [onPressAddPayoffList, onPressPayoff])

  const onSwipeableOpen = useCallback(() => {
    LayoutAnimation.easeInEaseOut(() => {
      if (props.onSwipeableOpen) {
        props.onSwipeableOpen(props.transaction)
      }
    })

    setIsSwipeableOpened(true)
  }, [props])

  const onPress = useCallback(() => props.onPress(props.transaction), [props])

  const onPressMemoButton = useCallback(
    () => props.onPressMemoButton(props.transaction),
    [props],
  )

  const { transaction } = props

  const userAccountType = transaction.userAccountType ?? appUserAccountType

  const isSwipeable = props.onSwipeableOpen

  const {
    isDisplayUserAvatar,
    isDisplayPartnerAvatar,
    avatarText,
    swipeUserAccountType,
  } = useMemo(() => {
    // isAccountShared = true, 支払担当 = 家族
    // isTransactionShared = true, 表示する画面 = 家族

    // 〈デバッグ〉振り分けで振り分け表示がされない
    // www.wrike.com/open.htm?id=860181278

    let data: {
      isDisplayUserAvatar: boolean
      isDisplayPartnerAvatar: boolean
      avatarText: string
      swipeUserAccountType: UserAccountType
    }

    if (userAccountType === 'user') {
      // 個人表示モード
      if (transaction.paymentUserType === PaymentUserType.Family) {
        // 支払担当 = 家族
        // #4
        data = {
          isDisplayUserAvatar: true,
          isDisplayPartnerAvatar: true,
          avatarText: '家族',
          swipeUserAccountType: 'family',
        }
      } else if (transaction.paymentUserType === PaymentUserType.User) {
        // 支払担当 = わたし
        data = {
          isDisplayUserAvatar: true,
          isDisplayPartnerAvatar: false,
          avatarText: transaction.isTransactionShared
            ? `${ProfileManager.getName('user')}/移動` // #8 #10
            : ProfileManager.getName('user'), // #11
          swipeUserAccountType: transaction.isTransactionShared
            ? 'user'
            : 'family',
        }
      } else {
        // 支払担当 = パートナー
        // #13
        data = {
          isDisplayUserAvatar: false,
          isDisplayPartnerAvatar: true,
          avatarText: ProfileManager.getName('partner'),
          swipeUserAccountType: 'family',
        }
      }
    } else {
      // 家族表示モード
      if (transaction.isAccountShared) {
        if (transaction.isTransactionShared) {
          // #1
          data = {
            isDisplayUserAvatar: true,
            isDisplayPartnerAvatar: true,
            avatarText: '家族',
            swipeUserAccountType: 'user',
          }
        } else {
          // #3 #6
          data = {
            isDisplayUserAvatar: true,
            isDisplayPartnerAvatar: true,
            avatarText: '家族/移動',
            swipeUserAccountType: 'family',
          }
        }
      } else {
        if (transaction.paymentUserType === PaymentUserType.User) {
          data = {
            isDisplayUserAvatar: true,
            isDisplayPartnerAvatar: false,
            avatarText: ProfileManager.getName('user'),
            swipeUserAccountType: 'user',
          }
        } else {
          // #12
          data = {
            isDisplayUserAvatar: false,
            isDisplayPartnerAvatar: true,
            avatarText: ProfileManager.getName('partner'),
            swipeUserAccountType: 'user',
          }
        }
      }
    }
    return data
  }, [
    transaction.isAccountShared,
    transaction.isTransactionShared,
    transaction.paymentUserType,
    userAccountType,
  ])

  useEffect(() => {
    // 個人から家族に振替た場合の再表示用
    setIsSwipeableOpened(false)
  }, [props])

  if (isSwipeableOpened) {
    swiper.current?.close()
    return null
  }

  return (
    <Swipeable
      onSwipeableLeftOpen={onSwipeableOpen}
      ref={swiper}
      renderLeftActions={
        isSwipeable && !transaction.transferId
          ? (progress, dragX) =>
              renderLeftActions(progress, dragX, swipeUserAccountType)
          : undefined
      }
      overshootRight={false}
      renderRightActions={
        isSwipeable && !transaction.transferId ? renderRightActions : undefined
      }>
      <Pressable onPress={onPress}>
        <View style={styles.container}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={styles.flexRow}>
              {props.isDisplayCheckMark && (
                <CheckBox
                  style={{ marginRight: 10 }}
                  isOn={props.isSelected}
                  onPress={() => props.onChangeSelected?.(!props.isSelected)}
                />
              )}
              <CategoryImage
                categoryId={transaction.atTransactionCategoryId}
                isTransfer={!!transaction.transferId}
              />
              <Text
                allowFontScaling={false}
                ellipsizeMode="tail"
                numberOfLines={1}
                style={styles.content}>
                {transaction.transferId
                  ? '振替'
                  : transaction.usedLocation || transaction.categoryName2}
              </Text>
            </View>
            <Text
              style={styles.money}
              allowFontScaling={false}
              numberOfLines={1}>
              {transaction.amount.jpCurrency()}
            </Text>
          </View>
          <View style={styles.bottom}>
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 27,
                flex: 1,
              }}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {isDisplayUserAvatar && <Avatar size={19} />}
                {isDisplayPartnerAvatar && (
                  <Avatar type="partner" size={18} style={{ marginLeft: 2 }} />
                )}
                <Text
                  allowFontScaling={false}
                  style={{
                    fontSize: 12,
                    fontWeight: 'normal',
                    color: Color.DefaultText,
                    marginLeft: 3,
                  }}>
                  {avatarText}
                </Text>
              </View>
              {props.transaction.payoffId && (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <CheckMarkToggleButton isOn={true} size={12} />
                  <Text
                    allowFontScaling={false}
                    style={{
                      marginLeft: 3,
                      marginRight: 16,
                      fontSize: 12,
                      fontWeight: 'normal',
                      color: Color.DefaultText,
                    }}>
                    精算済
                  </Text>
                </View>
              )}
              {props.transaction.isYetPayoffed && (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FAIcon
                    icon={faList}
                    size={12}
                    webSize="xs"
                    color={Color.Gray}
                  />
                  <Text
                    allowFontScaling={false}
                    style={{
                      marginLeft: 3,
                      marginRight: 16,
                      fontSize: 12,
                      fontWeight: 'normal',
                      color: Color.DefaultText,
                    }}>
                    未精算
                  </Text>
                </View>
              )}
            </View>
            <AddMemoButton
              transaction={props.transaction}
              onPress={onPressMemoButton}
            />
          </View>
        </View>
        {transaction.isIgnored && (
          <View
            pointerEvents="none"
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: 'absolute',
              backgroundColor: '#ffffff80',
            }}
          />
        )}
      </Pressable>
    </Swipeable>
  )
}

const AddMemoButton = ({
  transaction,
  onPress,
}: {
  transaction: Transaction
  onPress: () => void
}) => {
  return (
    <View
      style={{
        width: wp('34%'),
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}>
      {transaction.memo && transaction.memo.length > 0 ? (
        <Pressable
          style={{
            flexDirection: 'row',
            paddingVertical: 6,
            alignItems: 'center',
          }}
          onPress={onPress}>
          <Image
            source={require('@images/icons/icon-memo.png')}
            style={{
              width: 24,
              height: 18,
              resizeMode: 'contain',
            }}
          />
          <Text
            ellipsizeMode="tail"
            numberOfLines={1}
            allowFontScaling={false}
            style={{
              color: Color.DefaultText,
              fontSize: 12,
              fontWeight: 'normal',
              flex: 1,
            }}>
            {transaction.memo.replace(/\r?\n/g, ' ')}
          </Text>
        </Pressable>
      ) : (
        <Pressable
          style={{
            flexDirection: 'row',
            backgroundColor: Color.LightGray,
            paddingVertical: 6,
            paddingHorizontal: 10,
            borderRadius: 12,
          }}
          onPress={onPress}>
          <Text
            allowFontScaling={false}
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              color: Color.Gray,
            }}>
            ＋
          </Text>
          <Text
            allowFontScaling={false}
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              color: '#777',
              marginLeft: 2,
            }}>
            メモを追加する
          </Text>
        </Pressable>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: Color.White,
  },
  flexRow: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    marginHorizontal: 4,
    fontSize: 16,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  bottom: {
    justifyContent: 'space-between',
    marginTop: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },
  money: {
    color: 'black',
    fontSize: 23,
    fontWeight: 'normal',
  },
  shareText: {
    color: Color.White,
    fontWeight: 'bold',
  },

  leftAction: {
    flex: 1,
    backgroundColor: Color.Primary,
    justifyContent: 'center',
  },
})

export default TransactionItem
