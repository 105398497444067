import CategoryManager from '@lib/CategoryManager'
import { FAIcon } from '@lib/FAIcon'
import React from 'react'
import { Image, ImageStyle, StyleProp, View, ViewStyle } from 'react-native'

export interface CategoryImageProps {
  categoryId: string | number
  size: number
  color?: string
  style?: StyleProp<ViewStyle | ImageStyle>
  isTransfer: boolean
}

class CategoryImage extends React.Component<CategoryImageProps> {
  static defaultProps: CategoryImageProps = {
    categoryId: '1',
    size: 24,
    isTransfer: false,
  }

  render() {
    if (this.props.isTransfer) {
      return (
        <Image
          source={require('@images/category/icon-category-transfer.png')}
          style={[
            {
              resizeMode: 'contain',
              width: this.props.size,
              height: this.props.size,
            },
            this.props.style as StyleProp<ImageStyle>,
          ]}
        />
      )
    }
    const iconInfo = CategoryManager.getCategoryIconInfo(this.props.categoryId)

    if (iconInfo?.imageSource) {
      const imageSize = this.props.size * 0.8
      return (
        <View
          style={[
            {
              width: this.props.size,
              height: this.props.size,
              borderRadius: 12,
              backgroundColor: this.props.color || iconInfo?.color,
              justifyContent: 'center',
              alignItems: 'center',
            },
            this.props.style as StyleProp<ViewStyle>,
          ]}>
          <Image
            source={iconInfo.imageSource}
            key={iconInfo.imageSource.toString()}
            style={{
              resizeMode: 'contain',
              tintColor: 'white',
              width: imageSize,
              height: imageSize,
            }}
          />
        </View>
      )
    } else {
      const customIconName = iconInfo?.customIconName || this.props.categoryId
      const icon = CategoryManager.customIcon(`${customIconName}`)
      if (!icon) return null
      return (
        <View
          style={[
            {
              width: this.props.size,
              height: this.props.size,
              borderRadius: 12,
              backgroundColor: this.props.color || iconInfo?.color,
              justifyContent: 'center',
              alignItems: 'center',
            },
            this.props.style as StyleProp<ViewStyle>,
          ]}>
          <FAIcon
            icon={icon}
            color="white"
            size={this.props.size * 0.66}
            webSize="sm"
          />
        </View>
      )
    }
  }
}

export default CategoryImage
