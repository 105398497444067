import Alert from '@components/Alert'
import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import HomeBudgetPremiumBlurView from '@components/BudgetPremiumBlurView'
import itemSeparator from '@components/ItemSeparatorComponent'
import { PremiumView } from '@components/PremiumView'
import { UserAccountType } from '@interfaces/Account'
import { BudgetManager, setBudget } from '@lib/Budget'
import { BudgetCategoryState } from '@lib/Budget/types'
import CommonDialog from '@lib/CommonDialog'
import { getPrevMonth } from '@lib/DateUtils'
import DateUtilsV1 from '@lib/DateUtilsV1'
import NavigationService from '@lib/NavigationService'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, Keyboard, ListRenderItem, Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import CateboryBudgetCell from './CategoryBudget/CateboryBudgetCell'
import { budgetSelector } from './selector'
import {
  ListHeader,
  RemainText,
  RemainTextContainer,
  RootContainer,
  SaveButtonContainer,
  StyledCalculatorInput,
  TitleText,
} from './styles'

type UpdateBudgetFunctionsProps = {
  userAccountType: UserAccountType
  year: number
  month: number
  amount: number
  categoryTotalAmount: number
  budgets: BudgetCategoryState[]
  pastUpdate?: boolean
}

const fetchBudget = (
  userAccountType: UserAccountType,
  thisMonthKey: string,
) => {
  const callAPI = async () => {
    const prevMonthKey = getPrevMonth(thisMonthKey).key

    try {
      await BudgetManager.fetchBudget({
        userAccountType,
        year: +prevMonthKey.slice(0, 4),
        month: +prevMonthKey.slice(-2),
      })
      await BudgetManager.fetchBudget({
        userAccountType,
        year: +thisMonthKey.slice(0, 4),
        month: +thisMonthKey.slice(-2),
      })
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  callAPI()
}

type ListRenderItemProps = BudgetCategoryState & {
  userAccountType: UserAccountType
  prevMonthAmount: number
  onChange: (categoryId: string, number: number) => void
}

const listHeader = () => <ListHeader>カテゴリー予算</ListHeader>

const styledItemSeparator = () =>
  itemSeparator({ style: { marginHorizontal: 15 } })

const onPressSaveButton = async (props: UpdateBudgetFunctionsProps) => {
  checkBudgetAmount(props)
}

const updateBudget = async ({
  userAccountType,
  year,
  month,
  categoryTotalAmount: amount,
  budgets,
  pastUpdate = false,
}: UpdateBudgetFunctionsProps) => {
  try {
    showAppButtonIndicator()
    await BudgetManager.updateBudget({
      userAccountType,
      year,
      month,
      amount,
      atGroupedCategoryBudgets: budgets.map(
        ({ atGroupedCategoryId, amount }) => ({ atGroupedCategoryId, amount }),
      ),
      pastUpdate,
    })
    NavigationService.goBack()
    await BudgetManager.fetchBudget({ userAccountType, year, month })
  } catch (error) {
    CommonDialog.showError({ error })
  } finally {
    hideAppButtonIndicator()
  }
}

const overBudgetAlert = (props: UpdateBudgetFunctionsProps) => {
  Alert.alert(
    '全体予算が足りません',
    '全体予算とカテゴリー予算合計が同じ額になると設定できます。全体予算を増やして保存しますか？',
    [
      { text: 'いいえ' },
      { text: 'はい', onPress: () => confirmPastUpdate(props) },
    ],
    { cancelable: true },
  )
}

const underBudgetAlert = (props: UpdateBudgetFunctionsProps) => {
  Alert.alert(
    '全体予算が余っています',
    '全体予算とカテゴリー予算合計が同じ額になると設定できます。全体予算を減らして保存しますか？',
    [
      { text: 'いいえ' },
      { text: 'はい', onPress: () => confirmPastUpdate(props) },
    ],
    { cancelable: true },
  )
}

const confirmPastUpdate = (props: UpdateBudgetFunctionsProps) => {
  Alert.alert(
    '予算をいつから始めますか',
    '過去分を含む「すべての期間」か\n「今月から」を選択できます',
    Platform.OS === 'ios' || Platform.OS === 'web'
      ? [
          {
            text: 'すべての期間',
            onPress: () => updateBudget({ ...props, pastUpdate: true }),
          },
          {
            text: '今月から',
            onPress: () => updateBudget({ ...props, pastUpdate: false }),
          },
          {
            text: 'キャンセル',
          },
        ]
      : [
          {
            text: '　キャンセル',
          },
          {
            text: '　　今月から',
            onPress: () => updateBudget({ ...props, pastUpdate: false }),
          },
          {
            text: 'すべての期間',
            onPress: () => updateBudget({ ...props, pastUpdate: true }),
          },
        ],
    { cancelable: true, menuDirection: 'column' },
  )
}

const checkBudgetAmount = (props: UpdateBudgetFunctionsProps) => {
  const { amount, categoryTotalAmount } = props

  if (amount < categoryTotalAmount) {
    overBudgetAlert(props)
  } else if (amount > categoryTotalAmount) {
    underBudgetAlert(props)
  } else {
    confirmPastUpdate(props)
  }
}

const BudgetSettingContentScreen: React.FC<
  StackScreenProps<RootStackParamList, 'BudgetSettingContent'>
> = ({ route }) => {
  const dispatch = useDispatch()
  const isFocused = useIsFocused()

  const rank = useSelector(
    ({ profile }: RootState) => profile.userProfile?.rank,
  )

  const didMount = useRef(false)

  // reduxのisKeyboardVisibleは使わない
  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  const [isPurchaseCompleted, setIsPurchaseCompleted] = useState(false)

  useEffect(() => {
    const unsubscribe1 = signalOn('PURCHASE_COMPLETE', () => {
      if (isFocused) {
        setIsPurchaseCompleted(true)
      }
    })

    const unsubscribe2 = signalOn('CLOSED_PURCHASE_COMPLETE_MODAL', () => {
      setIsPurchaseCompleted(false)
    })

    return () => {
      unsubscribe1()
      unsubscribe2()
    }
  }, [isFocused])

  const { userAccountType } = route.params

  const budget = useSelector(budgetSelector)[userAccountType]

  const { thisMonth } = useMemo(() => {
    if (didMount) {
      return { thisMonth: DateUtilsV1.monthKey() }
    } else {
      return { thisMonth: undefined }
    }
  }, [didMount])

  useEffect(() => {
    if (thisMonth) {
      fetchBudget(userAccountType, thisMonth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisMonth])

  const thisMonthBudget = thisMonth ? budget[thisMonth] : undefined
  const prevMonthBudget = thisMonth
    ? budget[getPrevMonth(thisMonth).key]
    : undefined

  useEffect(() => {
    didMount.current = true

    return () => {
      // dispatch(setBudget({ userAccountType })) // clear
    }
  }, [])

  const onChangeAmount = useCallback(
    (amount: number) => {
      if (!thisMonthBudget || !thisMonth) return

      const { year, month } = DateUtilsV1.parseMonthKey(thisMonth)

      dispatch(
        setBudget({
          userAccountType,
          year,
          month,
          budget: {
            atGroupedCategoryBudgets: thisMonthBudget.atGroupedCategoryBudgets,
            amount,
          },
        }),
      )
    },
    [dispatch, thisMonth, thisMonthBudget, userAccountType],
  )

  const onChangeCategoryBudgetAmount = useCallback(
    (categoryId: string, amount: number) => {
      if (!thisMonthBudget || !thisMonth) return

      const atGroupedCategoryBudgets =
        thisMonthBudget.atGroupedCategoryBudgets.map((v) => {
          return v.atGroupedCategoryId === categoryId
            ? {
                ...v,
                amount,
              }
            : v
        })

      const { year, month } = DateUtilsV1.parseMonthKey(thisMonth)

      dispatch(
        setBudget({
          userAccountType,
          year,
          month,
          budget: { ...thisMonthBudget, atGroupedCategoryBudgets },
        }),
      )
    },
    [dispatch, thisMonth, thisMonthBudget, userAccountType],
  )

  const categoryTotalAmount = useMemo(() => {
    if (!thisMonthBudget) return 0
    let total = 0
    thisMonthBudget.atGroupedCategoryBudgets.forEach((v) => {
      total += v.amount
    })
    return total
  }, [thisMonthBudget])

  const prevMonthAmounts = useMemo(
    () =>
      prevMonthBudget?.atGroupedCategoryBudgets.reduce(
        (v1, v2) => {
          v1[v2.atGroupedCategoryId] = v2.amount
          return v1
        },
        {} as {
          [categoryId: string]: number
        },
      ),
    [prevMonthBudget],
  )
  const categoryBudgets = useMemo(
    () =>
      thisMonthBudget?.atGroupedCategoryBudgets.map<ListRenderItemProps>(
        (v) => ({
          ...v,
          userAccountType,
          prevMonthAmount: prevMonthAmounts?.[v.atGroupedCategoryId] || 0,
          onChange: onChangeCategoryBudgetAmount,
        }),
      ) ?? [],
    [
      onChangeCategoryBudgetAmount,
      prevMonthAmounts,
      thisMonthBudget?.atGroupedCategoryBudgets,
      userAccountType,
    ],
  )

  useEffect(() => {
    if (Platform.OS === 'android') {
      const keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        () => setKeyboardVisible(false),
      )
      return () => {
        keyboardDidHideListener.remove()
      }
    }
  }, [])

  const calculatorInputWillFocus = useCallback(() => {
    if (Platform.OS === 'android') {
      setKeyboardVisible(true)
      // dispatch(setTabBarVisible(false))
    }
    return true
  }, [])

  const renderItem: ListRenderItem<ListRenderItemProps> = useCallback(
    ({
      item: {
        atGroupedCategoryId,
        categoryName1,
        amount,
        prevMonthAmount,
        onChange,
      },
    }) => {
      return (
        <CateboryBudgetCell
          categoryId={atGroupedCategoryId}
          categoryName={categoryName1}
          prevMonthAmount={prevMonthAmount}
          amount={amount}
          onChange={(number) => onChange(atGroupedCategoryId, number)}
          willFocus={calculatorInputWillFocus}
        />
      )
    },
    [calculatorInputWillFocus],
  )

  useEffect(() => {
    if (userAccountType === 'user' && rank !== 'premium') {
      TrackingUtils.repro.track(
        '【Screen】upper limit_personal budget',
        'Screen',
      )

      TrackingUtils.ga.pageview({
        page: 'Upperlimit-BudgetSetting',
        title: '予算の設定の「個人画面」上限タッチ画面',
      })
    }
  }, [rank, userAccountType])

  if (!thisMonthBudget || !thisMonth || !categoryBudgets) return null

  const { year, month } = DateUtilsV1.parseMonthKey(thisMonth)
  const remain = thisMonthBudget.amount - categoryTotalAmount

  return (
    <RootContainer>
      <TitleText>全体予算</TitleText>
      <StyledCalculatorInput
        editable={true}
        hideMinus
        animated={true}
        value={thisMonthBudget.amount}
        onChange={onChangeAmount}
        textStyle={{ fontSize: 30, fontWeight: 'normal' }}
        inputType="keyboard"
      />
      <RemainTextContainer>
        <RemainText over={remain < 0}>
          {remain >= 0 ? 'あと ' : '超過 '}
          {(thisMonthBudget.amount - categoryTotalAmount).jpCurrency()}
        </RemainText>
      </RemainTextContainer>
      <FlatList
        data={categoryBudgets}
        renderItem={renderItem}
        keyExtractor={(item) => String(item.atGroupedCategoryId)}
        ListHeaderComponent={listHeader}
        ItemSeparatorComponent={styledItemSeparator}
        removeClippedSubviews={false} // https://github.com/facebook/react-native/issues/28246#issuecomment-618163774
      />
      {!isKeyboardVisible && (
        <SaveButtonContainer>
          <AppButton
            title="保存する"
            onPress={() =>
              onPressSaveButton({
                userAccountType,
                year,
                month,
                amount: thisMonthBudget.amount,
                categoryTotalAmount: categoryTotalAmount,
                budgets: categoryBudgets,
              })
            }
          />
        </SaveButtonContainer>
      )}
      {userAccountType === 'user' && rank !== 'premium' ? (
        <>
          {Platform.OS !== 'web' ? (
            <PremiumView
              showTopMargin={false}
              title="プレミアムプランの機能です"
              message="※ 個人の予算設定はプレミアムプランの機能です。"
              premiumType="budget"
            />
          ) : (
            <HomeBudgetPremiumBlurView />
          )}
        </>
      ) : (
        userAccountType === 'user' &&
        isPurchaseCompleted && (
          <PremiumView
            showTopMargin={false}
            buttonTitle="さっそく作成する"
            image={require('@images/premium/budget_modal.png')}
            onPressShowDetailButton={() => {
              setIsPurchaseCompleted(false)
              sendSignal('CLOSED_PURCHASE_COMPLETE_MODAL', 'budget')
            }}
          />
        )
      )}
    </RootContainer>
  )
}

export default BudgetSettingContentScreen
