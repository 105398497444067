import { measure } from '@Screen/Debug/SystemReportScreen'
import { setTransactionDefaultSettings } from '@Screen/Main/SettingTransactionDefaultScreen/slice'
import Alert from '@components/Alert'
import PairingCompletedDialog from '@components/PairingCompletedDialog'
import { TransactionDefaultSettingsScreenType } from '@interfaces/Financial'
import AccountManager from '@lib/AccountManager'
import { BudgetManager } from '@lib/Budget'
import CategoryManager from '@lib/CategoryManager'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import Log from '@lib/Log'
import { PermissionManager } from '@lib/Permission'
import ProfileManager from '@lib/ProfileManager'
import RNProgressHud from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import TimelineManager from '@lib/TimelineManager'
import CheckUpdate, { CheckUpdateResponse } from '@lib/api/CheckUpdate'
import { updateRootScreen } from '@redux/actions'
import store from '@redux/store'
import { compareVersions } from 'compare-versions'
import moment from 'moment'
import { Linking, Platform } from 'react-native'
import { VERSION_NAME } from 'react-native-dotenv'
import { logging } from '../Firestore'
import AccountTrackerManager from './AccountTrackerManager'
import DeepLinkService from './DeepLinkService'
import { UIBank } from './Env'
import PairingManager from './PairingManager'
import { APIError } from './api'
import { GoalManager } from './api/Goal'
import { TransactionDefaultSettingsManager } from './api/TransactionDefaultSettings'

class Osidori {
  private isDisplayingMaintenanceAlert = false
  private isLogging = false

  public start = async (url: string | null = '') => {
    try {
      Log.info('OsidOri.start()', url)

      const authTokenStatus = await this.checkAuthToken()
      Log.info('Osidori.start(): authTokenStatus', authTokenStatus)
      logging(`authTokenStatus: ${authTokenStatus}`)

      if (Platform.OS !== 'web' && url) {
        Linking.openURL(url)
        return
      } else {
        if (url) {
          const path = url.replace(/^https?:\/\/[^/]*\//, '')
          if (path) {
            const deeplinkPath = `osidori://${path}`
            window.history.replaceState({}, '', '/')
            Log.info({ deeplinkPath })
            DeepLinkService.open(deeplinkPath)
            // return
          }
        }
      }
      // if (url) {
      //   Linking.openURL(url)
      //   return
      // }

      switch (authTokenStatus) {
        case 'valid': {
          // ログイン済み
          await measure(
            'GetUserCategories',
            async () =>
              await CategoryManager.fetchCategories({ accountType: 'user' }),
          )
          await measure(
            'GetFamilyCategories',
            async () =>
              await CategoryManager.fetchCategories({ accountType: 'family' }),
          )

          // ホーム画面表示前に必要なデータを読み込んでおく
          await measure('FetchHomeData', async () => await this.fetchHomeData())

          store.dispatch(updateRootScreen('Main'))
          this.checkPairing()

          AccountTrackerManager.syncAccount()

          // 取引明細デフォルト設定
          const userSettings = await measure(
            'GetUserTransactionDefaultSettings',
            async () =>
              await TransactionDefaultSettingsManager.getTransactionDefaultSettings(
                {
                  screen: TransactionDefaultSettingsScreenType.User,
                },
              ),
          )
          const familySettings = await measure(
            'GetFamilyTransactionDefaultSettings',
            async () =>
              await TransactionDefaultSettingsManager.getTransactionDefaultSettings(
                {
                  screen: TransactionDefaultSettingsScreenType.Family,
                },
              ),
          )
          store.dispatch(
            setTransactionDefaultSettings({
              userAccountType: 'user',
              settings: userSettings,
            }),
          )
          store.dispatch(
            setTransactionDefaultSettings({
              userAccountType: 'family',
              settings: familySettings,
            }),
          )
          break
        }
        case 'invalid':
          // 認証情報有効期限切れ
          store.dispatch(updateRootScreen('Account'))
          break
        case 'none':
          // 未ログイン
          setTimeout(() => {
            if (SessionManager.isWalkthroughDisplayCompleted()) {
              store.dispatch(updateRootScreen('Account'))
            } else {
              if (!UIBank) {
                store.dispatch(updateRootScreen('Walkthrough'))
              } else {
                store.dispatch(updateRootScreen('UIBankFirst'))
              }
            }
          }, 1000)
          break
        default:
          // error
          CommonDialog.showError({
            error: authTokenStatus,
            onPress: () => {
              this.start(url)
            },
          })
      }

      logging('Osidori.start()')
    } catch (error) {
      CommonDialog.showError({
        error,
        onPress: () => {
          this.start()
        },
      })
    }
  }

  fetchHomeData = async () => {
    const accountMode = SessionManager.getAccountMode()
    const isFamilyShareMode = SessionManager.isFamilyShareMode()

    TimelineManager.fetchNoficesUnreadCount()

    // sync
    await measure(
      'FetchPlSettings',
      async () => await ProfileManager.fetchPlSettings(),
    )
    await FinancialManager.fetchAssets(accountMode)
    await measure(
      'FetchCardAccounts',
      async () => await FinancialManager.fetchCardAccounts(accountMode),
    )
    await FinancialManager.fetchBalance(accountMode, isFamilyShareMode)
    await measure(
      'FetchHomeExpensesSummary',
      async () =>
        await FinancialManager.fetchHomeExpensesSummary({
          date: moment().format(),
        }),
    )
    await measure(
      'FetchManuallyCreatedTransactionsCount',
      async () =>
        await FinancialManager.fetchManuallyCreatedTransactionsCount(
          accountMode,
        ),
    )
    await measure('FetchGoals', async () => {
      await GoalManager.fetchGoals({ share: false })
      await GoalManager.fetchGoals({ share: true })
    })
    await measure('FetchGoalCategories', async () => {
      await GoalManager.fetchGoalCategories({ share: false })
      await GoalManager.fetchGoalCategories({ share: true })
    })
    await measure(
      'FetchUserProflie',
      async () => await ProfileManager.fetchUserProflie(),
    )
    await measure(
      'FetchPartnerProflie',
      async () => await ProfileManager.fetchPartnerProflie(),
    )
    await measure(
      'FetchUserStatus',
      async () => await ProfileManager.fetchUserStatus(),
    )
    await measure(
      'FetchBudget',
      async () =>
        await BudgetManager.fetchBudget({ userAccountType: accountMode }),
    )
  }

  checkPairing = async () => {
    const pairingToken = SessionManager.getPairingToken()
    SessionManager.clearPairingToken()

    if (pairingToken) {
      if (!store.getState().profile.userStatus?.paired) {
        try {
          RNProgressHud.show()
          await PairingManager.pairing(pairingToken)
          await this.fetchHomeData()
          PairingCompletedDialog.show()
          return true
        } catch (error) {
          Log.info(error)
          return false
        } finally {
          RNProgressHud.dismiss()
          //SessionManager.clearPairingToken()
        }
      }
    }
    return false
  }

  private checkAuthToken = async (): Promise<
    'valid' | 'invalid' | 'none' | Error
  > => {
    try {
      if (SessionManager.isLoggedIn()) {
        await ProfileManager.fetchUserProflie()
        await ProfileManager.fetchPartnerProflie()
        AccountManager.updateAccountMode(SessionManager.getAccountMode())
        AccountManager.updateFamilyShareMode(SessionManager.isFamilyShareMode())
        return 'valid'
      } else {
        return 'none'
      }
    } catch (error) {
      Log.info(error)
      if (error instanceof APIError && error.response?.status === 403) {
        await SessionManager.inalidateAccessToken()
        return 'invalid'
      } else {
        return error as Error
      }
    }
  }

  public checkAppVersion = async () => {
    const response = await CheckUpdate.checkUpdate()
    if (!response.ok) return

    const checkUpdateResponse = response.json as CheckUpdateResponse
    Log.info('checkAppVersion', checkUpdateResponse)

    const status =
      Platform.OS === 'ios'
        ? checkUpdateResponse.iOs
        : checkUpdateResponse.android
    if (!status.maintenance.inService) {
      CommonDialog.showMessage(status.maintenance.message, () =>
        this.checkAppVersion(),
      )
      return
    }

    Log.info('compareVersions', VERSION_NAME, status.version, status.update.url)
    if (
      !this.isDisplayingMaintenanceAlert &&
      compareVersions(VERSION_NAME, status.version) < 0
    ) {
      this.isDisplayingMaintenanceAlert = true
      if (status.update.forceUpdate) {
        Alert.alert(
          '',
          status.update.message,
          [
            {
              text: 'アップデート',
              onPress: () => {
                Linking.openURL(status.update.url)
                this.checkAppVersion()
                this.isDisplayingMaintenanceAlert = false
              },
            },
          ],
          { cancelable: false },
        )
      } else {
        Alert.alert('', status.update.message, [
          {
            text: '閉じる',
            onPress: () => {
              this.isDisplayingMaintenanceAlert = false
            },
          },
          {
            text: 'アップデート',
            onPress: () => {
              Linking.openURL(status.update.url)
              this.isDisplayingMaintenanceAlert = false
            },
          },
        ])
      }
    }

    if (
      Platform.OS === 'ios' &&
      status.review.reviewMode &&
      VERSION_NAME === status.review.appVersion
    ) {
      PermissionManager.requestAppTrackingTransparency()
    }

    if (status.logging) {
      this.isLogging = true
    }
  }

  public isloggingEnabled = () => this.isLogging
}

export default new Osidori()
