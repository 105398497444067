import { UserAccountType } from '@interfaces/Account'
import API, { APIResponse } from '@lib/api'
import { UploadImageProps, UploadImageType } from './types'

const apiPath = (
  imageUploadType: UploadImageType,
  userAccountType?: UserAccountType,
) => {
  switch (imageUploadType) {
    case 'profile':
      return `/api/v1/user/icon/upload${
        userAccountType === 'family' ? '?partner=true' : ''
      }`
    case 'home_backgroud':
      return `/api/v1/${userAccountType?.userAccountPath()}/home_img/upload`
    case 'goal':
      return '/api/v3/goals/img-upload'
    case 'lifeplan_event':
      return '/api/v3/lifeplan/events/img-upload'
  }
}

export const uploadImage = async ({
  imageUploadType,
  userAccountType,
  mime,
  base64,
}: UploadImageProps): Promise<APIResponse> => {
  const url = apiPath(imageUploadType, userAccountType)

  return API.request(
    'PUT',
    url,
    {
      image: { base64: `data:${mime};base64,${base64}` },
    },
    true,
  )
}
