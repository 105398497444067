import Color from '@lib/Color'
import React from 'react'
import { View } from 'react-native'

const ItemSeparator = (
  <View
    style={{
      borderBottomColor: Color.LightGray,
      borderBottomWidth: 1,
    }}
  />
)

export default ItemSeparator
