import Alert from '@components/Alert'
import Osidori from '@lib/Osidori'
import SessionManager from '@lib/SessionManager'

export interface PairingParams {
  token: string
}

export const pairing = (params: PairingParams) => {
  SessionManager.setPairingToken(params.token)
  if (!SessionManager.isLoggedIn()) {
    Alert.alert(
      'ペアリングを完了させましょう♪',
      'OsidOriのアカウントを作成するとペアリングが完了できます。\n(登録済みの方はログインしてください)',
      [{ text: '閉じる' }],
    )
  }
  Osidori.start()
}
