import AppButton from '@components/AppButton'
import Avatar from '@components/Avatar'
import AvatarBackground from '@components/AvatarBackground'
import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import { faImage, faUserMinus } from '@fortawesome/pro-regular-svg-icons'
import {
  faBell,
  faUnlockAlt,
  faUserFriends,
} from '@fortawesome/pro-solid-svg-icons'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FlatList, Image, ListRenderItem, Text, View } from 'react-native'
import { VERSION_CODE, VERSION_NAME } from 'react-native-dotenv'
import { useSelector } from 'react-redux'
import MenuListItem, { MenuListItemProps } from '../MenuListItem'
import { RootContainer, StyledNameText } from './styles'

const listItems: MenuListItemProps[] = [
  {
    title: 'プレミアムプランの設定',
    subTitle: '会員ステータスの確認、復元・購入の操作',
    isDisplayArrow: true,
    iconImageSource: require('@images/icons/icon-premium.png'),
    onPress: () => NavigationService.navigate('SettingPremium'),
    disabledTintColor: true,
  },
  {
    title: 'プロフィールの設定',
    subTitle: 'わたしやパートナーの写真、名前、誕生日等の設定',
    isDisplayArrow: true,
    iconDefinition: faUserFriends as never,
    onPress: () => NavigationService.navigate('SettingProfiles'),
  },
  {
    title: '背景画像の設定',
    subTitle: 'ホーム画面（家族、個人）の背景画像の設定',
    isDisplayArrow: true,
    iconDefinition: faImage as never,
    onPress: () => NavigationService.navigate('SettingHomeBackground'),
  },
  {
    title: 'ログイン情報の変更',
    subTitle: '登録メールアドレス、パスワードの変更',
    isDisplayArrow: true,
    iconDefinition: faUnlockAlt as never,
    onPress: () => NavigationService.navigate('SettingUserInformation'),
  },
  {
    title: '通知の設定',
    subTitle: 'アプリ通知、メール通知の設定',
    isDisplayArrow: true,
    iconDefinition: faBell as never,
    onPress: () => NavigationService.navigate('SettingNotificationTab'),
  },
  {
    title: '退会',
    iconDefinition: faUserMinus as never,
    isDisplayArrow: true,
    onPress: () => NavigationService.navigate('Withdraw'),
  },
]

const SettingAccountScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingAccount'>
> = ({ route }) => {
  const goBack = useCallback(() => {
    if (route.params?.goBack) {
      route.params.goBack()
    } else {
      NavigationService.goBack()
    }
  }, [route.params])

  useEffect(() => {
    const backHandler = HardwareBackPressHandler.addListener(goBack)

    return () => {
      backHandler?.remove()
    }
  }, [goBack])

  const userProfile = useSelector(
    ({ profile }: RootState) => profile.userProfile,
  )

  const iconImageView = useMemo(() => {
    return (
      <AvatarBackground>
        <Avatar size={90} style={{ zIndex: 1 }} />
        <StyledNameText>{userProfile?.name || 'わたし'}</StyledNameText>
      </AvatarBackground>
    )
  }, [userProfile])

  const logoutView = useMemo(() => {
    return (
      <View
        style={{
          padding: 20,
          paddingTop: 40,
          paddingBottom: 40,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: Color.LightGray,
          backgroundColor: 'white',
        }}>
        <AppButton
          type="white"
          onPress={() => {
            if (userProfile?.emailAuthenticated) {
              NavigationService.logout()
            } else {
              CommonDialog.unauthenticated()
            }
          }}
          title="ログアウト"
        />
      </View>
    )
  }, [userProfile])

  const footerView = useMemo(() => {
    return (
      <View style={{ flex: 1 }}>
        {logoutView}
        <View style={{ flex: 1, paddingVertical: 30, alignItems: 'center' }}>
          <Image
            resizeMode="contain"
            source={require('@images/icons/icon-logo-gray.png')}
            style={{ paddingTop: 30, width: 110, height: 27 }}
          />
          <Text
            style={{
              color: '#bbb',
              fontSize: 10,
              fontWeight: 'normal',
              paddingTop: 8,
            }}>
            2人で、生きたい人生を歩めるそのそばに。
          </Text>
        </View>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 'normal',
            color: '#ccc',
            width: '100%',
            textAlign: 'center',
            paddingBottom: 20,
          }}>
          Version {VERSION_NAME} ({VERSION_CODE})
        </Text>
      </View>
    )
  }, [logoutView])

  const renderItem: ListRenderItem<MenuListItemProps> = ({ item }) => {
    return (
      <MenuListItem
        title={item.title}
        subTitle={item.subTitle}
        iconImageSource={item.iconImageSource}
        iconDefinition={item.iconDefinition}
        isDisplayArrow={item.isDisplayArrow}
        onPress={item.onPress}
        disabledTintColor={item.disabledTintColor}
      />
    )
  }

  return (
    <RootContainer>
      <CommonHeader title="アカウント設定" onPressLeftButton={goBack} />
      <FlatList
        style={{ flex: 1 }}
        keyExtractor={(_, index) => `${index}`}
        renderItem={renderItem}
        data={listItems}
        ItemSeparatorComponent={() => ItemSeparator}
        ListHeaderComponent={iconImageView}
        ListFooterComponent={footerView}
      />
    </RootContainer>
  )
}

export default SettingAccountScreen
