import {
  selectMonthlyGoals,
  setGoals,
  setIsProcessingGoal,
} from '@Screen/Main/MonthlyReportScreen/slice'
import { UserAccountType } from '@interfaces/Account'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { GoalManager } from '@lib/api/Goal'
import { selectGoals } from '@lib/api/Goal/slice'
import * as scale from 'd3-scale'
import * as shape from 'd3-shape'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import {
  Grid,
  LineChart,
  StackedAreaChart,
  XAxis,
  YAxis,
} from 'react-native-svg-charts'
import { ViewProps } from 'react-native-svg/lib/typescript/fabric/utils'
import { useDispatch, useSelector } from 'react-redux'

type ChartData = {
  date: string
  total: number
}

const ChartColors = [
  '#6CDF49',
  '#47D3FF',
  '#FFD028',
  '#FF6D6D',
  '#DC7AFF',
  '#FF9A16',
  '#22ABDB',
  '#FF83AF',
  '#90E6B8',
  '#A2A0FF',
]

const ChartColors2 = ChartColors.map((v) => `${v}4d`)

export type GoalsPanelContentProps = {
  userAccountType: UserAccountType
} & ViewProps

const GoalChart = ({ userAccountType, ...props }: GoalsPanelContentProps) => {
  const dispatch = useDispatch()

  const yearMonth = useRef(moment().format('YYYYMM')).current
  const monthlyGoals = useSelector(selectMonthlyGoals)
  const goals = useSelector(selectGoals)

  const [planError, setPlanError] = useState(false)
  const [period, setPeriod] = useState<3 | 6 | 12 | 'all' | 'future'>(6)

  const [touchesChartData, setTouchesChartData] = useState<
    ChartData & { x: number; y: number; index: number } & { [key: number]: any }
  >()

  const graphEndDate = useMemo(() => {
    let endDate: moment.Moment | undefined

    goals[userAccountType]?.forEach((goal) => {
      if (!goal.endDate) return moment().add(5, 'years')

      if (!endDate || endDate.diff(goal.endDate) < 0) {
        endDate = moment(goal.endDate)
      }
    })

    return endDate ?? moment().add(5, 'years')
  }, [goals, userAccountType])

  const goalColors = useMemo(() => {
    const keys: { [id: number]: string[] } = {}

    monthlyGoals.forEach((monthlyReportGoal) => {
      monthlyReportGoal.goals.forEach((goal) => {
        if (!keys[goal.id]) {
          keys[goal.id] = [
            ChartColors[Object.keys(keys).length % ChartColors.length],
            ChartColors2[Object.keys(keys).length % ChartColors2.length],
          ]
        }
      })
    })

    return keys
  }, [monthlyGoals])

  const goalIdAndNames = useMemo(
    () =>
      monthlyGoals[0]?.goals.map((goal) => ({
        id: goal.id,
        name: goal.name,
      })) || [],
    [monthlyGoals],
  )

  const chartData = useMemo((): (ChartData & { [key: number]: any })[] => {
    const goalIds = goalIdAndNames.map((v) => v.id)

    const data = monthlyGoals.map((goal) => {
      const value: ChartData & { [key: number]: any } = {
        date: goal.date,
        total: goal.totalCurrentAmount,
      }
      goalIds.forEach((goalId) => {
        value[goalId] = 0
      })
      goal.goals.forEach((v) => {
        value[v.id] = v.currentAmount
      })
      return value
    })

    return data.reverse()
  }, [goalIdAndNames, monthlyGoals])

  const lineChartData = useMemo(() => {
    const goalKeys = goalIdAndNames.map((v) => v.id)

    const lineChartData: { [key: string]: any }[] = []

    chartData.forEach((c) => {
      const tmp = { ...c }

      goalKeys.forEach((key, index) => {
        for (let i = index + 1; i < goalKeys.length; i++) {
          tmp[key] += c[goalKeys[i]]
        }
      })

      lineChartData.push(tmp)
    })

    return lineChartData
  }, [chartData, goalIdAndNames])

  const getGoals = useCallback(async () => {
    try {
      if (!yearMonth) return

      dispatch(setIsProcessingGoal(true))

      const date =
        period === 'future' ? graphEndDate : moment(yearMonth, 'YYYYMM')

      const term =
        period === 'future' ? graphEndDate.diff(moment(), 'month') + 3 : period
      log.info({ term, date, graphEndDate })

      const goals = await GoalManager.getMonthlyReport({
        userAccountType,
        year: period === 'future' ? date.year() : undefined,
        month: period === 'future' ? date.month() + 1 : undefined,
        term,
      })

      dispatch(setGoals(goals))
      setPlanError(false)
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.errorCode === '007004') {
          setPlanError(true)
          TrackingUtils.repro.track(
            '【Screen】upper limit_monthly eport asset transition',
            'Screen',
          )
          return
        }
      }
      CommonDialog.showError({ error, onPress: () => setPeriod(6) })
    } finally {
      dispatch(setIsProcessingGoal(false))
    }
  }, [dispatch, graphEndDate, period, userAccountType, yearMonth])

  useEffect(() => {
    getGoals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, yearMonth])

  const onPressPeriodButton = useCallback(
    (value: 3 | 6 | 12 | 'all' | 'future') => {
      setPeriod(value)
    },
    [],
  )

  const totalAmount = useMemo(
    () =>
      chartData.length >= 1 ? chartData[chartData.length - 1].total : undefined,
    [chartData],
  )

  const prevMonthOver = useMemo(
    () =>
      chartData.length >= 2
        ? chartData[chartData.length - 1].total -
          chartData[chartData.length - 2].total
        : undefined,
    [chartData],
  )

  const ListHeader1 = useCallback(() => {
    if (chartData.length === 0) return <></>

    return (
      <View
        style={{
          marginTop: 10,
          marginBottom: 10,
          marginHorizontal: 15,
        }}>
        <Text
          style={{
            paddingLeft: 10,
            fontSize: 16,
            fontWeight: 'normal',
            color: 'rgb(58, 58, 58)',
          }}>
          貯金額合計
        </Text>
        <View
          style={{
            marginTop: -20,
            paddingHorizontal: 15,
          }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {prevMonthOver !== undefined &&
              (prevMonthOver > 0 ? (
                <Image
                  source={require('@images/report/up-icon.png')}
                  resizeMode="contain"
                  style={{ width: 22, height: 22 }}
                />
              ) : prevMonthOver < 0 ? (
                <Image
                  source={require('@images/report/down-icon.png')}
                  resizeMode="contain"
                  style={{ width: 22, height: 22 }}
                />
              ) : (
                <Image
                  source={require('@images/report/arrow-flat.png')}
                  resizeMode="contain"
                  style={{ width: 22, height: 22 }}
                />
              ))}
            <Text
              style={{
                marginLeft: 3,
                fontSize: 24,
                fontWeight: 'bold',
                color: 'rgb(58, 58, 58)',
              }}>
              {totalAmount != undefined ? totalAmount.jpCurrency() : '-'}
            </Text>
          </View>
          <View
            style={{
              marginTop: 5,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 'normal',
                color: 'rgb(102, 102, 102)',
              }}>
              前月比
            </Text>
            <Text
              style={{
                marginLeft: 10,
                fontSize: 14,
                fontWeight: 'bold',
                color: Color.number(prevMonthOver),
              }}>
              {prevMonthOver !== undefined
                ? prevMonthOver.jpCurrency({
                    showPlus: true,
                    showPlusMinus: true,
                  })
                : '-'}
            </Text>
          </View>
        </View>

        {/* 期間ボタン */}
        <View
          style={{
            marginTop: 20,
            marginBottom: 10,
            paddingHorizontal: 15,
            justifyContent: 'flex-end',
            flexDirection: 'row',
          }}>
          <PeriodButton
            title="3ヶ月"
            on={period === 3}
            onPress={() => onPressPeriodButton(3)}
          />
          <PeriodButton
            title="6ヶ月"
            on={period === 6}
            onPress={() => onPressPeriodButton(6)}
          />
          <PeriodButton
            title="1年"
            on={period === 12}
            onPress={() => onPressPeriodButton(12)}
          />
          <PeriodButton
            title="すべて"
            on={period === 'all'}
            onPress={() => onPressPeriodButton('all')}
          />
          <PeriodButton
            title="未来"
            on={period === 'future'}
            onPress={() => onPressPeriodButton('future')}
          />
        </View>
      </View>
    )
  }, [totalAmount, chartData, prevMonthOver, period, onPressPeriodButton])

  const ListHeader2 = useCallback(() => {
    const yMax = monthlyGoals.reduce(
      (prv, cur) => Math.max(prv, cur.totalCurrentAmount),
      0,
    )

    const goalIds = goalIdAndNames.map((v) => v.id).reverse()
    const colors = Object.values(goalColors)
      .map((v) => v[1])
      .reverse()

    const chartWidth = wp(100 - 15) - 15 // YAxisを除いた部分
    const chartInfoCenterX =
      (chartWidth / (chartData.length - 1)) * (touchesChartData?.index || 0)

    return (
      <View style={{ marginBottom: 20 }} {...props}>
        {/* チャート */}
        <View
          style={
            {
              // flexDirection: 'row',
              // height: wp(50),
              // backgroundColor: 'red',
            }
          }>
          <View
            style={{
              flexDirection: 'row',
              height: wp(50),
            }}>
            <YAxis
              style={{
                width: '15%',
                marginBottom: 10,
              }}
              formatLabel={(value) =>
                value >= 10000 ? `${Math.floor(value / 10000)}万` : value
              }
              numberOfTicks={5}
              data={StackedAreaChart.extractDataPoints(chartData, goalIds)}
              contentInset={{ top: 5, bottom: 5 }}
              svg={{ fontSize: 10, fill: 'grey' }}
            />
            <View
              // onTouchStart={onChartTouchStart}
              // onTouchMove={onChartTouchMove}
              // onTouchEnd={onChartTouchEnd}
              style={{ flex: 1 }}>
              <View
                style={{
                  flex: 1,
                  marginTop: 5,
                  paddingVertical: 0,
                  // backgroundColor: 'gray',
                }}>
                <StackedAreaChart
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  numberOfTicks={5}
                  data={chartData}
                  keys={goalIds}
                  colors={colors}
                  // curve={shape.curveNatural}
                  curve={shape.curveLinear}>
                  <Grid />
                </StackedAreaChart>
                {goalIds.map((goalId) => (
                  <LineChart
                    key={goalId}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    yMax={yMax}
                    yMin={0}
                    data={lineChartData.map((v) => v[goalId])}
                    svg={{ stroke: goalColors[goalId][0] }}
                  />
                ))}
              </View>
              <XAxis
                style={{
                  marginTop: 5,
                  // marginHorizontal: -100,
                  // paddingHorizontal: 100,
                  // overflow: 'visible',
                  // backgroundColor: 'red',
                }}
                data={chartData}
                // numberOfTicks={2}
                // scale={scale.scaleBand}
                scale={scale.scaleLinear}
                // spacingInner={10}
                // spacingOuter={10}
                formatLabel={(value, index) =>
                  chartData.length <= 6 ||
                  (chartData.length > 6 &&
                    index % Math.ceil(chartData.length / 6) ===
                      (chartData.length + 1) % 2)
                    ? moment(chartData[index].date, 'YYYY-MM-DD').format(
                        period === 'future' ? 'YY/M/D' : 'M/D',
                      )
                    : ''
                }
                contentInset={{ left: 14, right: 14 }}
                svg={{ fontSize: 10, fill: 'grey' }}
              />
              {touchesChartData?.date && (
                <>
                  <View
                    style={{
                      position: 'absolute',
                      left: chartInfoCenterX,
                    }}>
                    <View
                      style={{
                        alignSelf: 'center',
                        left: -1,
                        width: 2,
                        height: wp(50) - 17,
                        backgroundColor: Color.Primary,
                      }}
                    />
                  </View>
                  {touchesChartData.total >= 0 && (
                    <View
                      style={{
                        position: 'absolute',
                        left: chartInfoCenterX - 7.5,
                        top:
                          wp(50) -
                            17 -
                            (wp(50) - 17 - 4) *
                              (touchesChartData?.total / yMax) -
                            7.5 || 0, //  - 4 はLineChartとのずれを微調整
                        width: 15,
                        height: 15,
                        borderRadius: 7.5,
                        backgroundColor: Color.Primary,
                      }}
                    />
                  )}
                  {touchesChartData && (
                    <View
                      style={{
                        position: 'absolute',
                        left: Math.max(
                          -wp(15) + 5,
                          Math.min(
                            chartInfoCenterX - 50,
                            chartWidth - 100 + 10,
                          ),
                        ),
                        top: -38,
                        width: 100,
                        backgroundColor: Color.Primary,
                        borderRadius: 5,
                        overflow: 'hidden',
                        paddingVertical: 3,
                        height: 38,
                        justifyContent: 'space-between',
                      }}>
                      <Text
                        style={{
                          color: 'white',
                          alignSelf: 'center',
                        }}>
                        {touchesChartData?.total?.jpCurrency()}
                      </Text>
                      <Text
                        style={{
                          marginRight: 10,
                          color: 'white',
                          fontSize: 9,
                          fontWeight: 'normal',
                          alignSelf: 'flex-end',
                        }}>
                        {moment(touchesChartData.date, 'YYYY-MM-DD').format(
                          'YYYY/M/D',
                        )}
                      </Text>
                    </View>
                  )}
                </>
              )}
            </View>
          </View>
        </View>
      </View>
    )
  }, [
    monthlyGoals,
    goalIdAndNames,
    goalColors,
    chartData,
    touchesChartData,
    props,
    lineChartData,
    period,
  ])

  const ListHeader3 = useCallback(() => {
    const names = goalIdAndNames.map((v) => v.name)
    const colors = Object.values(goalColors).map((v) => v[0])

    return (
      <View
        style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
        {names.map((name, index) => (
          <View key={name} style={{ flexDirection: 'row', marginBottom: 15 }}>
            <View
              style={{
                marginLeft: 15,
                backgroundColor: colors[index],
                width: 18,
                height: 18,
                borderRadius: 10,
              }}
            />
            <Text
              style={{
                marginLeft: 8,
                fontSize: 14,
                fontWeight: 'normal',
                color: 'rgb(58, 58, 58)',
              }}>
              {name}
            </Text>
          </View>
        ))}
      </View>
    )
  }, [goalColors, goalIdAndNames])

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <ListHeader1 />
        <ListHeader2 />
        <ListHeader3 />
      </View>
    </View>
  )
}

const PeriodButton = ({
  title,
  on,
  ...props
}: { title: string; on?: boolean } & TouchableOpacityProps) => {
  return (
    <TouchableOpacity
      style={{
        marginLeft: 10,
        backgroundColor: on ? Color.Primary : '#eee',
        borderRadius: 18,
        height: 36,
        width: Math.min(68, wp(15)),
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}>
      <Text
        style={{
          fontSize: 12,
          color: on ? 'white' : 'rgb(153, 153, 153)',
          fontWeight: 'bold',
        }}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
  periodSection: {
    minHeight: 45,
    maxHeight: 45,
    paddingTop: 6,
    paddingHorizontal: 8,
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  chartSection: {
    flexDirection: 'row',
    height: wp('30%'),
    marginTop: 4,
  },
  dateText: {
    padding: 8,
    fontSize: 16,
    fontWeight: 'normal',
  },
  paymentSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textAmount: {
    marginRight: 24,
    color: Color.GrayVeryDark,
    fontSize: 34,
    fontWeight: 'normal',
  },
  textBalance: {
    marginTop: 14,
    marginRight: 12,
    color: Color.GrayVeryDark,
    fontSize: 15,
    fontWeight: 'normal',
  },
  scrollView: {
    height: '50%',
  },
  listPaymentsItem: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 15,
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listPaymentsItemTypeIcon: {
    marginRight: 6,
  },
  listPaymentsItemTypeText: {
    fontSize: 17,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
  },
  listPaymentsItemInfoPercent: {
    width: 50,
    fontSize: 17,
    fontWeight: 'normal',
    textAlign: 'right',
    color: Color.Gray,
  },
  listPaymentsItemInfoAmount: {
    fontSize: 22,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfoAmount2: {
    fontSize: 18,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  iconEdit: {
    resizeMode: 'contain',
    height: 20,
  },
  btnEdit: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  premiumTextOr: {
    margin: wp(3.5),
    color: 'white',
    fontSize: wp(4),
    fontWeight: 'bold',
  },
})

export default GoalChart
