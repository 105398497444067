import { GoalProgressStatus } from '../interfaces/Goal'
import { UIBank } from './Env'

class Color {
  Black = '#000000'
  DefaultText = '#555555'
  LightBlack = '#666666'
  SemiLightGray = '#F0F0F0'
  LightGray = '#F6F6F6'
  PlaceHolderText = '#c7c7cd' // iOS default
  White = '#FFFFFF'
  // OsidoriGreen = '#83D827'
  OsidoriGreen = '#5ac500'
  UIBankPrimary = '#141E50'
  Primary = UIBank ? this.UIBankPrimary : this.OsidoriGreen
  GreenBright = '#71D700'
  // DarkGreen = '#5ac500'
  Gray = '#999999'
  GrayDove = '#666666'
  GrayVeryDark = '#3A3A3A'
  GrayVeryLight = '#EEEEEE'
  GrayBoulder = '#7C7C7C'
  GraySilver = '#C1C1C1'
  DarkBlue = '#127C9A'
  Tundora = '#434343'
  Jumboapprox = '#78787E'
  Red = '#FB4033'
  Blue = '#1000FF'
  GoalProgressBest = '#77C325'
  GoalProgressNormal = '#FCC300'
  GoalProgressBad = '#FF7200'
  Orange = '#FF7200'
  NumberGreen = 'rgb(63 ,197, 21)'
  NumberOrange = 'rgb(255, 114, 0)'
  NumberBlack = '#3A3A3A'
  TextBlack = '#3A3A3A'

  goal = (status: GoalProgressStatus) => {
    switch (status) {
      case 'best':
        return this.GoalProgressBest
      case 'normal':
        return this.GoalProgressNormal
      case 'bad':
        return this.GoalProgressBad
    }
  }

  number = (number?: number) => {
    if (!number) return this.NumberBlack
    if (number > 0) return this.NumberGreen
    return this.NumberOrange
  }
}

export default new Color()
