import { NativeApp } from '@lib/Env'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'
import InvitePairingScreen from '@Screen/InvitePairingScreen'
import React from 'react'
import DiagnosisResultScreen from './DiagnosisResultScreen'
import HouseholdDiagnosisScreen from './HouseholdDiagnosisScreen'
import UserInfoDiagnosisScreen from './UserInfoDiagnosisScreen'

const Stack = createStackNavigator()

const Diagnose: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
      }}>
      <Stack.Screen
        name="HouseholdDiagnosis"
        component={HouseholdDiagnosisScreen}
      />
      <Stack.Screen
        name="UserInfoDiagnosis"
        component={UserInfoDiagnosisScreen}
      />
      <Stack.Screen name="DiagnosisResult" component={DiagnosisResultScreen} />
      <Stack.Screen name="InvitePairing" component={InvitePairingScreen} />
    </Stack.Navigator>
  )
}

export default Diagnose
