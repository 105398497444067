import { Image } from '@components'
import AppButton from '@components/AppButton'
import TintedImage from '@components/TintedImage'
import { PlSettings, PlType } from '@interfaces/Profile'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { StartDateSettingOption, getDateRange } from '@lib/DateUtils'
import FinancialManager from '@lib/FinancialManager'
import GlobalFontSizes from '@lib/GlobalFontSizes'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store, { RootState } from '@redux/store'
import moment from 'moment'
import React, { useCallback } from 'react'
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import RNPickerSelect, { Item } from 'react-native-picker-select'
import { useDispatch, useSelector } from 'react-redux'
import { setPeriodSetting } from './SettingPeriodTabScreen/slice'

interface Props {
  plSettings?: PlSettings
}

const days: Item[] = [...Array(31).keys()]
  .map((v) => ({
    label: 1 + v + '日',
    value: 1 + v,
  }))
  .concat({ label: '月末', value: -1 })

const SettingCountPeriodScreen: React.FC<
  Props & StackScreenProps<RootStackParamList, 'SettingCountPeriod'>
> = ({ route }) => {
  const dispatch = useDispatch()

  const { accountType } = route.params

  const plSettings = useSelector((root: RootState) => root.settingPeriod)
  const userSettings = plSettings.user
  const familySettings = plSettings.family

  const updatePlSettings = useCallback(async () => {
    try {
      if (!plSettings) return

      const now = moment()
      const year = now.year()
      const month = now.month() + 1

      await ProfileManager.updatePlSettings({
        plPeriodDate: userSettings.plPeriodDate || 1,
        plType: userSettings.plType || StartDateSettingOption.None,
        groupPlPeriodDate: familySettings.plPeriodDate || 1,
        groupPlType: familySettings.plType || StartDateSettingOption.None,
      })

      FinancialManager.fetchBalance(
        'user',
        store.getState().account.isFamilyShareMode,
        getDateRange(
          year,
          month,
          userSettings.plPeriodDate || 1,
          userSettings.plType || StartDateSettingOption.None,
        ).startDate.format(),
      )
      FinancialManager.fetchBalance(
        'family',
        store.getState().account.isFamilyShareMode,
        getDateRange(
          year,
          month,
          familySettings.plPeriodDate || 1,
          familySettings.plType || StartDateSettingOption.None,
        ).startDate.format(),
      )
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      NavigationService.goBack()
    }
  }, [
    familySettings.plPeriodDate,
    familySettings.plType,
    plSettings,
    userSettings.plPeriodDate,
    userSettings.plType,
  ])

  return (
    <View style={styles.container}>
      {plSettings[accountType]?.plType !== undefined &&
      plSettings[accountType]?.plPeriodDate !== undefined ? (
        <ScrollView>
          <View style={styles.settingDate}>
            <View
              pointerEvents="none"
              style={{
                top: 0,
                right: 20,
                left: 20,
                bottom: 0,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'absolute',
              }}>
              <Text style={styles.settingLabel}>集計期間の開始日</Text>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{
                    color: Color.Primary,
                    marginRight: 5,
                  }}>
                  {(plSettings[accountType]?.plPeriodDate || 1) > 0
                    ? plSettings[accountType]?.plPeriodDate + '日'
                    : '月末'}
                </Text>
                <TintedImage
                  source={require('@images/arrow/icon-arrow-white-small.png')}
                  style={{
                    tintColor: Color.Gray,
                    width: 19,
                    height: 19,
                    transform: [{ rotate: '90deg' }],
                  }}
                />
              </View>
            </View>
            <RNPickerSelect
              style={{
                inputAndroid: {
                  height: '100%',
                  color: '#00000000',
                  width: '200%', // アイコンを外に出すために横幅を広げる
                },
                inputIOS: {
                  height: '100%',
                  color: '#00000000',
                },
                inputWeb: {
                  height: 48,
                  color: '#00000000',
                  backgroundColor: '#00000000',
                },
              }}
              placeholder={{}}
              value={plSettings[accountType]?.plPeriodDate}
              onValueChange={(v) => {
                dispatch(
                  setPeriodSetting({
                    userAccountType: accountType,
                    settings: {
                      plPeriodDate: +v,
                      plType: plSettings[accountType]?.plType,
                    },
                  }),
                )
              }}
              items={days}
              Icon={null}
              doneText="完了"
            />
          </View>
          <Text
            style={{
              marginTop: 30,
              marginLeft: 20,
              marginBottom: 10,
              color: Color.DefaultText,
              fontSize: GlobalFontSizes.Small,
              fontWeight: 'normal',
            }}>
            開始日が土日祝日の場合
          </Text>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => {
              dispatch(
                setPeriodSetting({
                  userAccountType: accountType,
                  settings: {
                    plPeriodDate: plSettings[accountType]?.plPeriodDate,
                    plType: 'ago',
                  },
                }),
              )
            }}>
            <Text style={styles.settingLabel}>開始日の前にずらす</Text>
            <CheckMark
              type="ago"
              value={
                plSettings[accountType]?.plType || StartDateSettingOption.None
              }
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => {
              dispatch(
                setPeriodSetting({
                  userAccountType: accountType,
                  settings: {
                    plPeriodDate: plSettings[accountType]?.plPeriodDate,
                    plType: 'since',
                  },
                }),
              )
            }}>
            <Text style={styles.settingLabel}>開始日の後にずらす</Text>
            <CheckMark
              type="since"
              value={
                plSettings[accountType]?.plType || StartDateSettingOption.None
              }
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.settingItem, styles.settingItemBottom]}
            onPress={() => {
              dispatch(
                setPeriodSetting({
                  userAccountType: accountType,
                  settings: {
                    plPeriodDate: plSettings[accountType]?.plPeriodDate,
                    plType: '',
                  },
                }),
              )
            }}>
            <Text style={styles.settingLabel}>変更しない</Text>
            <CheckMark
              type=""
              value={
                plSettings[accountType]?.plType || StartDateSettingOption.None
              }
            />
          </TouchableOpacity>
          <AppButton
            title="保存する"
            onPress={() => updatePlSettings()}
            style={{ marginHorizontal: 20, marginVertical: 40 }}
          />
        </ScrollView>
      ) : (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator />
        </View>
      )}
    </View>
  )
}

const CheckMark = (props: { type: PlType; value: PlType }) => {
  return (
    <Image
      source={props.type === props.value ? Icon.CheckMarkOn : Icon.CheckMarkOff}
      style={styles.checkMark}
      resizeMode="contain"
    />
  )
}

export default SettingCountPeriodScreen

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F7F7F7',
  },
  body: {
    flex: 1,
  },
  settingDate: {
    marginTop: 30,
    borderTopWidth: 1,
    borderTopColor: '#eee',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    height: 50,
    backgroundColor: Color.White,
  },
  settingItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    backgroundColor: Color.White,
    borderTopWidth: 1,
    borderTopColor: '#eee',
    height: 50,
  },
  settingItemBottom: {
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  settingLabel: {
    color: Color.Tundora,
  },
  checkMark: {
    width: 20,
    height: 20,
  },
})
