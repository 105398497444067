import { BackHandler } from 'react-native'

class HardwareBackPressHandler {
  addListener = (listener: () => void) => {
    return BackHandler.addEventListener('hardwareBackPress', () => {
      listener()
      return true
    })
  }

  disableBackPress = () =>
    BackHandler.addEventListener('hardwareBackPress', () => true)

  onPressToExitApp = () => {
    return BackHandler.addEventListener('hardwareBackPress', () => {
      BackHandler.exitApp()
      return true
    })
  }
}

export default new HardwareBackPressHandler()
