import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'
import { View } from 'react-native'
import CommonHeader from './CommonHeader'

export type ImageCropProps = {
  uri: string
  callback: () => void
}

const ImageCrop: React.FC<
  StackScreenProps<RootStackParamList, 'ImageCrop'>
> = ({ route }) => {
  const { width, height, compressImageMaxWidth, compressImageMaxHeight } =
    route.params

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState<Area>()

  const onCropComplete = useCallback(
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedArea(croppedAreaPixels)
    },
    [],
  )

  const onPressComplete = useCallback(async () => {
    if (croppedArea) {
      const croppedImage = await getCroppedImg(
        route.params?.uri,
        croppedArea,
        compressImageMaxWidth,
        compressImageMaxHeight,
      )

      const path = ''
      const mime = croppedImage.match(/^data:([^;]+)/)?.[1]
      const data = croppedImage.match(/;base64,(.*)/)?.[1]
      route.params?.callback(mime ? { path, mime, data } : undefined)
      NavigationService.goBack()
    } else {
      NavigationService.goBack()
    }
  }, [compressImageMaxHeight, compressImageMaxWidth, croppedArea, route.params])

  if (!route.params?.uri) return null

  return (
    <View style={{ flex: 1, backgroundColor: 'black' }}>
      <CommonHeader
        title="写真を編集"
        rightButtonTitle="完了"
        leftButtonType="modal"
        onPressRightButton={onPressComplete}
      />
      <View
        style={{
          position: 'absolute',
          top: 80,
          left: 0,
          right: 0,
          bottom: 80,
        }}>
        <Cropper
          image={route.params.uri}
          crop={crop}
          zoom={zoom}
          aspect={width && height ? width / height : 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </View>
      <View
        style={{
          position: 'absolute',
          left: 20,
          right: 20,
          bottom: 20,
        }}>
        <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(+e.target.value)
          }}
          className="zoom-range"
        />
      </View>
    </View>
  )
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  compressImageMaxWidth: number,
  compressImageMaxHeight: number,
): Promise<string> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return ''
  }

  canvas.width = image.width
  canvas.height = image.height

  // canvas上に画像を描画
  ctx.drawImage(image, 0, 0)

  // トリミング後の画像を抽出
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
  )

  // canvasのサイズ指定(切り取り後の画像サイズに更新)
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // 抽出した画像データをcanvasの左隅に貼り付け
  ctx.putImageData(data, 0, 0)

  const croppedDataURL = canvas.toDataURL()
  const newImage = await createImage(croppedDataURL)

  const { width: newWidth, height: newHeight } = getWidthHeigth(
    newImage.width,
    newImage.height,
    compressImageMaxWidth,
    compressImageMaxHeight,
  )

  canvas.width = newWidth
  canvas.height = newHeight
  ctx.drawImage(
    newImage,
    0,
    0,
    newImage.width,
    newImage.height,
    0,
    0,
    newWidth,
    newHeight,
  )

  // canvasを画像に変換
  return canvas.toDataURL('image/jpeg', 0.75)
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob((file) => {
  //     if (file !== null) resolve(URL.createObjectURL(file))
  //   }, 'image/png')
  // })
}

const getWidthHeigth = (
  origWidth: number,
  origHeight: number,
  maxWidth: number,
  maxHeight: number,
) => {
  const origAspect = origWidth / origHeight

  let width,
    height = 0

  if (origWidth > maxWidth) {
    width = maxWidth
    height = maxWidth / origAspect
  } else {
    width = origWidth
    height = origHeight
  }

  if (height > maxHeight) {
    width = width * (height / maxHeight)
    height = maxHeight
  }

  return { width, height }
}

export default ImageCrop
