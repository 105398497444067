import { NativeApp } from '@lib/Env'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'
import WebViewScreen from '@Screen/WebViewScreen'
import CreateAccountOrLoginScreen from './CreateAccountOrLoginScreen'
import CreateAccountResendEmailScreen from './CreateAccountResendEmailScreen'
import CreateAccountSendEmailScreen from './CreateAccountSendEmailScreen'
import PasswordResetScreen from './PasswordResetScreen'
import PasswordResetSentEmailScreen from './PasswordResetSentEmailScreen'
import UpdatePasswordScreen from './UpdatePasswordScreen'

const Stack = createStackNavigator()

export default () => {
  return (
    <Stack.Navigator
      initialRouteName="CreateAccountOrLogin"
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
      }}>
      <Stack.Screen
        name="CreateAccountOrLogin"
        component={CreateAccountOrLoginScreen}
      />
      <Stack.Screen
        name="CreateAccountResendEmail"
        component={CreateAccountResendEmailScreen}
      />
      <Stack.Screen name="WebView" component={WebViewScreen} />
      <Stack.Screen
        name="CreateAccountSendEmail"
        component={CreateAccountSendEmailScreen}
      />
      <Stack.Screen name="PasswordReset" component={PasswordResetScreen} />
      <Stack.Screen
        name="PasswordResetSentEmail"
        component={PasswordResetSentEmailScreen}
      />
      <Stack.Screen name="UpdatePassword" component={UpdatePasswordScreen} />
    </Stack.Navigator>
  )
}
