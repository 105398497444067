import { PremiumType } from '@components/PremiumView'
import { ScreenName } from '@navigation/Screens'
import { EventEmitter } from 'events'
import { useCallback, useEffect, useState } from 'react'
import Log from './Log'
import { UploadImageType } from './api/UploadImage'

const eventEmitter = new EventEmitter()

type SignalType = {
  /** プレミアムプラン購入完了 */
  PURCHASE_COMPLETE: string // Product ID
  /** 手動更新回数の上限 */
  TRANSACTION_MANUAL_UPDATE_LIMIT: ScreenName
  /** 手動更新回数の上限から課金LPを開いた */
  TRANSACTION_MANUAL_UPDATE_LIMIT_OPEN_LP: ScreenName
  /** 取引明細コピーの上限 */
  TRANSACTION_COPY_LIMIT: ScreenName
  /** 取引明細コピーの上限から課金LPを開いた */
  TRANSACTION_COPY_LIMIT_OPEN_LP: ScreenName
  /** プレミアムプラン購入ページを開いた */
  // PREMIUM_LP_OPEN: ScreenName // どの画面から
  /** プレミアムプラン購入画面を未購入で閉じた */
  PREMIUM_LP_CLOSE: undefined
  /** Upper limitプレミアムプロモーション画面のCTAボタン（無料で作成するなど）のタップ */
  TAPPED_UPPER_LIMIT_PREMIUM_CTA: PremiumType
  /** 購入完了モーダルが閉じられた */
  CLOSED_PURCHASE_COMPLETE_MODAL: PremiumType
  /** ナビゲーションヘッダのバックボタンがタップされた */
  TAPPED_NAVIGATION_LEFT_BUTTON: undefined
  /** UploadImageManagerより、画像のアップロードがされた */
  IMAGE_UPLOADED: { uploadImageType: UploadImageType; imgUrl: string }
}

export const useSignal = <K extends keyof SignalType>(
  key: K,
): SignalType[K] | undefined => {
  const [data, setData] = useState<SignalType[K]>()

  const listener = useCallback((data: SignalType[K]) => {
    setData(data)
  }, [])

  useEffect(() => {
    eventEmitter.on(key, listener)
    return () => {
      eventEmitter.removeListener(key, listener)
    }
  }, [key, listener])

  return data
}

export const sendSignal = <K extends keyof SignalType>(
  key: K,
  data: SignalType[K] | undefined = undefined,
) => {
  Log.info(`sendSignal: ${key}`, { data })
  return eventEmitter.emit(key, data)
}

export const signalOn = <K extends keyof SignalType>(
  key: K,
  listener: (data: SignalType[K]) => void,
) => {
  Log.info(`signalOn: addListener(${key})`)

  const wrappedListener = (data: SignalType[K]) => {
    Log.info(`signalOn: fire: ${key}`, { data })
    listener(data)
  }

  eventEmitter.on(key, wrappedListener)

  return () => {
    Log.info(`signalOn: removeListener(${key})`)
    eventEmitter.removeListener(key, wrappedListener)
  }
}
