import Avatar from '@components/Avatar'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import { UserAccountType } from '@interfaces/Account'
import { Payoff, PayoffManager } from '@lib/api/Payoff'
import Color from '@lib/Color'
import {
  getDateRange,
  parseMonthKey,
  StartDateSettingOption,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import ProfileManager from '@lib/ProfileManager'
import SettledListItem from '@Screen/Main/Home/Payoff/PayoffListScreen/SettledListScreen/SettledListItem'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ListRenderItem,
  SectionList,
  SectionListData,
  Text,
  View,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import ShowMoreButton from '../ShowMoreButton'
import {
  selectUserAccountType,
  selectYearMonth,
  setIsProcessingPayoff,
  setPayoffAmounts,
} from '../slice'
import { RootContainer } from './styles'

const PayoffContent = () => {
  const dispatch = useDispatch()

  const userAccountType = useSelector(selectUserAccountType)

  // 支払済み額
  const [userPaidAmount, setUserPaidAmount] = useState(0)
  const [partnerPaidAmount, setPartnerPaidAmount] = useState(0)
  const [userLastMonthPaidAmount, setUserLastMonthPaidAmount] = useState(0)
  const [partnerLastMonthPaidAmount, setPartnerLastMonthPaidAmount] =
    useState(0)

  // 分担した額
  const [userSplitAmount, setUserSplitAmount] = useState(0)
  const [partnerSplitAmount, setPartnerSplitAmount] = useState(0)

  // 精算額
  const [settleAmount, setSettleAmount] = useState(0)

  const yearMonth = useSelector(selectYearMonth)

  const [payoffs, setPayoffs] = useState<Payoff[]>([])
  const [lastMonthPayoffs, setLastMonthPayoffs] = useState<Payoff[]>([])
  const [isShowMore, setIsShowMore] = useState(true)

  useEffect(() => {
    // マンスリーレポート上部のサマリー
    const totalPaidAmount = userPaidAmount + partnerPaidAmount
    const lastMonthTotalPaidAmount =
      userLastMonthPaidAmount + partnerLastMonthPaidAmount
    dispatch(setPayoffAmounts([totalPaidAmount, lastMonthTotalPaidAmount]))
  }, [
    dispatch,
    partnerLastMonthPaidAmount,
    partnerPaidAmount,
    userLastMonthPaidAmount,
    userPaidAmount,
  ])
  const sections = useMemo(() => {
    const payoffDates: { [date: string]: Payoff[] } = {}

    const sections: SectionListData<Payoff>[] = []

    payoffs
      .slice(0, isShowMore ? 3 : payoffs.length)
      .forEach((payoff: Payoff) => {
        const date = moment(payoff.payoffDate).format('YYYYMMDD')
        if (payoffDates[date]) {
          payoffDates[date].push(payoff)
        } else {
          payoffDates[date] = [payoff]
        }
      })

    const today = moment().format('YYYYMMDD')

    Object.keys(payoffDates)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: payoffDates[date],
        })
      })

    return sections
  }, [isShowMore, payoffs])

  useEffect(() => {
    if (!payoffs) return

    // 支払済み額
    let userTotalPaidAmount = 0
    let partnerTotalAmount = 0

    // 分担した額
    let userSplitAmount = 0
    let partnerSplitAmount = 0

    payoffs.forEach((payoff) => {
      userTotalPaidAmount += Math.abs(payoff.owner.calcSplitAmount)
      partnerTotalAmount += Math.abs(payoff.partner.calcSplitAmount)
      userSplitAmount += Math.abs(payoff.owner.splitAmount)
      partnerSplitAmount += Math.abs(payoff.partner.splitAmount)
    })

    // 支払済み額
    setUserPaidAmount(userTotalPaidAmount)
    setPartnerPaidAmount(partnerTotalAmount)

    // 分担した額
    setUserSplitAmount(userSplitAmount)
    setPartnerSplitAmount(partnerSplitAmount)
  }, [payoffs])

  useEffect(() => {
    // 先月分
    if (!lastMonthPayoffs) return

    // 支払済み額
    let userTotalPaidAmount = 0
    let partnerTotalAmount = 0

    lastMonthPayoffs.forEach((payoff) => {
      userTotalPaidAmount += Math.abs(payoff.owner.calcSplitAmount)
      partnerTotalAmount += Math.abs(payoff.partner.calcSplitAmount)
    })

    // 支払済み額
    setUserLastMonthPaidAmount(userTotalPaidAmount)
    setPartnerLastMonthPaidAmount(partnerTotalAmount)
  }, [lastMonthPayoffs])

  const onPressShowMoreButton = useCallback(() => setIsShowMore(false), [])

  useEffect(() => {
    // 精算額（パートナーがわたしに支払う金額。マイナスなら、わたしがパートナーに支払う金額となる）
    const settleAmount = userSplitAmount - userPaidAmount
    setSettleAmount(settleAmount)
  }, [userPaidAmount, userSplitAmount])

  const getPayoffs = useCallback(
    async (userAccountType: UserAccountType, year: number, month: number) => {
      const settings = getStartDateSettings(userAccountType)
      const { startDate, endDate } = getDateRange(
        year,
        month,
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      )

      const payoffs = (
        await PayoffManager.getPayoffs({
          from: startDate.format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD'),
        })
      ).payoffs

      return payoffs
    },
    [],
  )

  useEffect(() => {
    const asyncFunc = async () => {
      if (!userAccountType || !yearMonth) return

      dispatch(setIsProcessingPayoff(true))

      try {
        const { year, month } = parseMonthKey(yearMonth)
        const payoffs = await getPayoffs(userAccountType, year, month)
        setPayoffs(payoffs)

        let lastMonthYear = year
        let lastMonthMonth = month
        lastMonthMonth--
        if (lastMonthMonth === 0) {
          lastMonthYear--
          lastMonthMonth = 12
        }
        const lastMonthPayoffs = await getPayoffs(
          userAccountType,
          lastMonthYear,
          lastMonthMonth,
        )
        setLastMonthPayoffs(lastMonthPayoffs)
      } finally {
        dispatch(setIsProcessingPayoff(false))
      }
    }

    asyncFunc()
  }, [dispatch, getPayoffs, userAccountType, yearMonth])

  const isPaymentFromPartnerToOwner = useMemo(
    () => settleAmount >= 0,
    [settleAmount],
  )

  const renderItem: ListRenderItem<Payoff> = useCallback(
    ({ item }) => <SettledListItem payoff={item} />,
    [],
  )

  return (
    <RootContainer>
      <Text
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: 'normal',
          color: 'rgb(58, 58, 58)',
        }}>
        今月の精算額
      </Text>
      <View
        style={{
          marginTop: 20,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Text
          style={{
            fontSize: 15,
            fontWeight: 'normal',
            color: Color.DefaultText,
          }}>
          精算対象の金額
        </Text>
        <Text
          style={{
            marginLeft: 15,
            marginBottom: 3,
            fontSize: 20,
            fontWeight: 'normal',
            color: Color.DefaultText,
          }}>
          {(userPaidAmount + partnerPaidAmount).jpCurrency()}
        </Text>
      </View>
      <View
        style={{
          marginTop: 15,
          marginHorizontal: 20,
          padding: 15,
          alignItems: 'center',
          backgroundColor: 'white',
          borderWidth: 1,
          borderColor: '#cccccc',
          borderRadius: 10,
          shadowColor: '#00000080',
          shadowOffset: {
            width: 0,
            height: 0,
          },
          shadowOpacity: 0.25,
          shadowRadius: 5,
          elevation: 5,
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Avatar type="user" size={50} />
          <Text
            style={{
              marginHorizontal: 20,
              fontSize: 18,
              fontWeight: 'normal',
              color: '#888888',
            }}>
            {isPaymentFromPartnerToOwner ? '◀' : '▶'}
          </Text>
          <Avatar type="partner" size={50} />
        </View>
        <View>
          <View
            style={{
              marginTop: 10,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontSize: 15,
                fontWeight: 'bold',
                color: Color.DefaultText,
              }}>
              精算額
            </Text>
            <Text
              style={{
                marginLeft: 20,
                marginBottom: 3,
                fontSize: 24,
                fontWeight: 'bold',
                color: Color.Primary,
              }}>
              {Math.abs(settleAmount).jpCurrency()}
            </Text>
          </View>
          <View style={{ marginTop: 3 }}>
            <Text
              style={{
                color: Color.DefaultText,
                fontSize: 14,
                fontWeight: 'normal',
              }}>
              {isPaymentFromPartnerToOwner
                ? `${ProfileManager.getName(
                    'partner',
                  )}から${ProfileManager.getName('user')}へ支払い`
                : `${ProfileManager.getName(
                    'user',
                  )}から${ProfileManager.getName('partner')}へ支払い`}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          marginTop: 20,
          marginHorizontal: 20,
          paddingVertical: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomColor: Color.Gray,
          borderBottomWidth: 0.5,
        }}>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          {ProfileManager.getName('user')}
        </Text>
        <View style={{ flex: 1 }} />
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          {ProfileManager.getName('partner')}
        </Text>
      </View>
      <View
        style={{
          marginHorizontal: 20,
          paddingVertical: 15,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomColor: Color.Gray,
          borderBottomWidth: 0.5,
        }}>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {userSplitAmount.jpCurrency()}
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          支払済み額
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {partnerSplitAmount.jpCurrency()}
        </Text>
      </View>
      <View
        style={{
          marginHorizontal: 20,
          paddingVertical: 15,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomColor: Color.Gray,
          borderBottomWidth: 0.5,
        }}>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {userPaidAmount.jpCurrency()}
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          分担する額
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {partnerPaidAmount.jpCurrency()}
        </Text>
      </View>
      {sections ? (
        <SectionList
          sections={sections}
          renderItem={renderItem}
          renderSectionHeader={({ section: { title } }) => (
            <SectionListHeader title={title} style={{ fontWeight: 'normal' }} />
          )}
          keyExtractor={(item: Payoff) => `${item.payoffId}`}
          style={{ marginTop: 20 }}
          ItemSeparatorComponent={() => ItemSeparator}
          ListFooterComponent={() => (
            <>
              <View
                style={{
                  borderTopWidth: 1,
                  borderTopColor: Color.LightGray,
                  height: 5,
                }}
              />
              {isShowMore && payoffs.length > 3 && (
                <ShowMoreButton onPress={onPressShowMoreButton} />
              )}
            </>
          )}
        />
      ) : (
        <>
          <Text>明細はありません</Text>
        </>
      )}
    </RootContainer>
  )
}

export default PayoffContent
