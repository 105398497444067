import { UserAccountType } from '@interfaces/Account'
import AccountManager from '@lib/AccountManager'
import { deeplinkNavigation } from './DeeplinkNavigationParams'

export type NavigationHomeParams = {
  userAccountType?: UserAccountType
}

export const navigateHome = ({ userAccountType }: NavigationHomeParams) => {
  if (userAccountType === 'family' || userAccountType === 'user') {
    AccountManager.updateAccountMode(userAccountType)
  }

  deeplinkNavigation({ name: 'HomeTop' })
}
