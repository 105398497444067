import { Transaction } from '@interfaces/Financial'
import { APIError } from '@lib/api'
import store from '@redux/store'
import {
  setPayoff,
  setPayoffTransactions,
} from '@Screen/Main/Home/Payoff/slice'
import * as PayOffAPI from './api'
import {
  DeletePayoffProps,
  GetPayoffDetailProps,
  GetPayoffDetailResponse,
  GetPayoffSettingsResponse,
  GetPayoffsProps,
  GetPayoffsResponse,
  PayoffAmounts,
  UpdatePayoffSettingsProps,
} from './types'

export const updateSettings = async (props: UpdatePayoffSettingsProps) => {
  const response = await PayOffAPI.updateSettings(props)
  if (!response.ok) throw new APIError(response)
}

export const getSettings = async (): Promise<GetPayoffSettingsResponse> => {
  const response = await PayOffAPI.getSettings()
  if (!response.ok) throw new APIError(response)
  return response.json
}

export const payoffs = async (
  tranactions: Transaction[],
  owner: PayoffAmounts,
  partner: PayoffAmounts,
) => {
  const atUserBankTransactionIds: number[] = []
  const atUserCardTransactionIds: number[] = []
  const atUserEmoneyTransactionIds: number[] = []
  const userManuallyCreatedTransactionIds: number[] = []

  tranactions.forEach((tranaction) => {
    if (tranaction.type === 'bank') {
      atUserBankTransactionIds.push(tranaction.transactionId)
    } else if (tranaction.type === 'card') {
      atUserCardTransactionIds.push(tranaction.transactionId)
    } else if (tranaction.type === 'emoney') {
      atUserEmoneyTransactionIds.push(tranaction.transactionId)
    } else {
      userManuallyCreatedTransactionIds.push(tranaction.transactionId)
    }
  })

  const response = await PayOffAPI.payoffs({
    atUserBankTransactionIds,
    atUserCardTransactionIds,
    atUserEmoneyTransactionIds,
    userManuallyCreatedTransactionIds: userManuallyCreatedTransactionIds,
    owner,
    partner,
  })
  if (!response.ok) throw new APIError(response)
}

export const addPayoffList = async (tranaction: Transaction): Promise<void> => {
  let atUserBankTransactionIds: number[] | undefined
  let atUserCardTransactionIds: number[] | undefined
  let atUserEmoneyTransactionIds: number[] | undefined
  let userManuallyCreatedTransactionIds: number[] | undefined

  const transactions = [tranaction.transactionId]

  if (tranaction.type === 'bank') {
    atUserBankTransactionIds = transactions
  } else if (tranaction.type === 'card') {
    atUserCardTransactionIds = transactions
  } else if (tranaction.type === 'emoney') {
    atUserEmoneyTransactionIds = transactions
  } else {
    userManuallyCreatedTransactionIds = transactions
  }

  // await FinancialManager.updateTransaction({
  //   userAccountType: tranaction.isAccountShared ? 'family' : 'user',
  //   financialAccountType: tranaction.type,
  //   accountId: tranaction.accountId,
  //   transactionId: tranaction.transactionId,
  //   yetPayoff: true,
  // })

  const response = await PayOffAPI.addYetPeyoffs({
    atUserBankTransactionIds,
    atUserCardTransactionIds,
    atUserEmoneyTransactionIds,
    userManuallyCreatedTransactionIds,
  })
  if (!response.ok) throw new APIError(response)
}

export const deleteYetPayoffs = async (tranactions: Transaction[]) => {
  const atUserBankTransactionIds: number[] = []
  const atUserCardTransactionIds: number[] = []
  const atUserEmoneyTransactionIds: number[] = []
  const userManuallyCreatedTransactionIds: number[] = []

  tranactions.forEach((tranaction) => {
    if (tranaction.type === 'bank') {
      atUserBankTransactionIds.push(tranaction.transactionId)
    } else if (tranaction.type === 'card') {
      atUserCardTransactionIds.push(tranaction.transactionId)
    } else if (tranaction.type === 'emoney') {
      atUserEmoneyTransactionIds.push(tranaction.transactionId)
    } else {
      userManuallyCreatedTransactionIds.push(tranaction.transactionId)
    }
  })

  const response = await PayOffAPI.deleteYetPeyoffs({
    atUserBankTransactionIds,
    atUserCardTransactionIds,
    atUserEmoneyTransactionIds,
    userManuallyCreatedTransactionIds: userManuallyCreatedTransactionIds,
  })
  if (!response.ok) throw new APIError(response)
}

export const getPayoffs = async (props: GetPayoffsProps) => {
  const response = await PayOffAPI.getPayoffs(props)
  if (!response.ok) throw new APIError(response)
  return response.json as GetPayoffsResponse
}

export const getPayoffDetail = async (props: GetPayoffDetailProps) => {
  const response = await PayOffAPI.getPayoffDetail(props)
  if (!response.ok) throw new APIError(response)
  return (response.json as GetPayoffDetailResponse).payoff
}

export const fetchPayoffDetail = async (props: GetPayoffDetailProps) => {
  const { transactions, ...payoff } = await getPayoffDetail(props)
  store.dispatch(setPayoff({ payoff }))
  store.dispatch(setPayoffTransactions({ transactions }))
  return payoff
}

export const deletePayoff = async (props: DeletePayoffProps) => {
  const response = await PayOffAPI.deletePayoff(props)
  if (!response.ok) throw new APIError(response)
}
