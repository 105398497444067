import { APIError } from '@lib/api'
import { ActivateUserResponse, UserAPI } from '@lib/api/User'
import SessionManager from '@lib/SessionManager'

class ActivateManager {
  activateUser = async (token: string) => {
    const response = await UserAPI.activateUser({ token })
    if (response.ok) {
      const json = response.json as ActivateUserResponse
      const { token, expiresAt } = json.app
      await SessionManager.setAccessToken(token, expiresAt, '', '') // TODO
    } else {
      throw new APIError(response)
    }
  }
}

export default new ActivateManager()
