import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import Osidori from '@lib/Osidori'
import RNProgressHud from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import camelcaseKeys from 'camelcase-keys'
import { Platform } from 'react-native'
import {
  API_SERVER_URL,
  ENV,
  VERSION_CODE,
  VERSION_NAME,
} from 'react-native-dotenv'
import fs from 'react-native-fs'
import RNFetchBlob, { FetchBlobResponse } from 'rn-fetch-blob'
import snakeCaseKeys from 'snakecase-keys'

// for debug
let API_URL_INDEX = 0

const API_URL_LIST: string[] = API_SERVER_URL.split(',')

export const getApiUrlIndex = () => API_URL_INDEX

export const updateApiUrlIndex = (index?: number) => {
  API_URL_INDEX = index || (API_URL_INDEX + 1) % API_URL_LIST.length
  API_INFO = getApiInfo()
}

export const getApiUrl = () => API_URL_LIST[API_URL_INDEX]

const getApiInfo = () => {
  const match = API_URL_LIST[API_URL_INDEX].match(
    /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)(.*)$/,
  )
  return match
    ? {
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        path: match[5],
      }
    : {}
}

let API_INFO = getApiInfo()

const requestUrl = (path: string) => {
  return /^https?:/.test(path)
    ? path
    : `${API_INFO.protocol}//${API_INFO.host}${API_INFO.path}${path}`
}

export class APIError extends Error {
  public readonly response?: APIResponse

  constructor(response: APIResponse, message = '') {
    super(message)
    this.response = response
  }
}

export interface APIResponse {
  ok: boolean
  status: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any
  isNetworkError: boolean
  isAuthenticationError: boolean
  isTimeout: boolean
  errorCode?: string
  errorMessage?: string
  path?: string
}

interface GetRefreshTokenProps {
  refreshToken: string
}

interface GetRefreshTokenResponse {
  app: {
    accessToken: string
    accessTokenExpiresAt: string
    refreshToken: string
    refreshTokenExpiresAt: string
  }
}

const userAgent = `OsidOri ${ENV} ${VERSION_NAME} Build ${VERSION_CODE}/${
  Platform.OS
} ${Platform.Version ?? ''}`

type Methods =
  | 'POST'
  | 'GET'
  | 'DELETE'
  | 'PUT'
  | 'post'
  | 'get'
  | 'delete'
  | 'put'

export type APIRequestOptions = {
  updateRefreshTokenIfNeed?: boolean
  isSecureGetMethod?: boolean
  ignoreIsTokenUpdateInprocess?: boolean
}

class API {
  private isTokenUpdateInprocess = false

  async request(
    method: Methods,
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any = {},
    authorization = true,
    headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      'User-Agent': userAgent,
      Accept: 'application/json',
      CategoryVersion: '3',
    },
    {
      updateRefreshTokenIfNeed = true,
      isSecureGetMethod = false,
      ignoreIsTokenUpdateInprocess = false,
    }: APIRequestOptions = {},
  ): Promise<APIResponse> {
    if (!ignoreIsTokenUpdateInprocess && this.isTokenUpdateInprocess) {
      await this.waitIfIsTokenUpdateInprocess()
    }
    // if (authorization && updateRefreshTokenIfNeed) {
    //   await this.refreshTokenIfNeed()
    // }

    let url = requestUrl(path)

    if (authorization) {
      headers = this.addAuthorizationHeader(headers)
    }

    let params: RequestInit = {
      method,
      headers,
      mode: 'cors',
    }

    if (body instanceof FormData) {
      params = { ...params, body }
    } else {
      let filteredBody: { [key: string]: string | number | boolean } = {}
      for (const [key, value] of Object.entries(body)) {
        if (value !== null && typeof value !== 'undefined') {
          filteredBody[key] = value as string | number | boolean
        }
      }
      filteredBody = snakeCaseKeys(filteredBody, {
        deep: true,
        exclude: [
          'share_condition[0][account]',
          'share_condition[0][transaction]',
          'share_condition[1][account]',
          'share_condition[1][transaction]',
        ],
      }) as {
        [key: string]: string | number | boolean
      }

      if (isSecureGetMethod) {
        params = {
          ...params,
          body: JSON.stringify(filteredBody),
        }
      } else {
        switch (method.toLowerCase()) {
          case 'get':
          case 'head':
            // case 'delete':
            // eslint-disable-next-line no-case-declarations
            const queries = Object.keys(filteredBody)
              .map((key) => {
                return `${key}=${encodeURIComponent(filteredBody[key])}`
              })
              .join('&')
            if (queries) url += '?' + queries
            break
          default:
            params = {
              ...params,
              body: JSON.stringify(filteredBody),
            }
        }
      }
    }

    let response: Response
    try {
      response = await fetch(url, params)
    } catch (error) {
      return {
        ok: false,
        status: 0,
        json: error,
        isNetworkError: true,
        isAuthenticationError: false,
        isTimeout: false,
      }
    }

    const isAuthenticationError = response.status === 401
    if (isAuthenticationError) {
      Log.info(
        '=== 認証エラー ====',
        { isAuthenticationError },
        { method, path, body, authorization, headers },
      )
      if (SessionManager.isLoggedIn()) {
        if (updateRefreshTokenIfNeed) {
          // 1度だけリトライ
          Log.info('=== リトライ ====', { updateRefreshTokenIfNeed })
          await this.updateRefreshToken()
          return this.request(method, path, body, authorization, headers, {
            updateRefreshTokenIfNeed: false,
          })
        } else {
          Log.info('=== リトライしない ====', { updateRefreshTokenIfNeed })
        }

        RNProgressHud.dismiss()
        NavigationService.logout()
        return {
          ok: false,
          status: response.status,
          json: {},
          isNetworkError: false,
          isAuthenticationError,
          isTimeout: false,
        }
      }
    }

    const isServerError = response.status >= 500
    if (isServerError) {
      Osidori.checkAppVersion()
      return {
        ok: false,
        status: response.status,
        json: {},
        isNetworkError: false,
        isAuthenticationError,
        isTimeout: false,
      }
    }

    const contentType = response.headers.get('content-type')
    if (contentType?.startsWith('text/csv;')) {
      response.headers.forEach((value: string, key: string, parent: Headers) =>
        console.log({ value, key, parent }),
      )
      Log.info(response.headers.get('content-disposition'))
      // content-disposition: attachment;filename=20230124-20230124_osidori_pl.csv

      const filename = response.headers
        .get('content-disposition')
        ?.replace(/^.*filename=/, '')

      return response
        .text()
        .then((text) => ({
          ok: response.ok,
          status: response.status,
          json: { text, filename },
          isNetworkError: false,
          isAuthenticationError,
          isTimeout: false,
        }))
        .catch((error: Error) => ({
          ok: response.ok, // 204でレスポンスのJSONがない場合はここにくる
          status: response.status,
          json: { ...error },
          isNetworkError: false,
          isAuthenticationError,
          isTimeout: false,
        }))
    }

    return response
      .json()
      .then((json) => ({
        ok: response.ok,
        status: response.status,
        json: camelcaseKeys(json, { deep: true }),
        isNetworkError: false,
        isAuthenticationError,
        isTimeout: false,
        errorCode: this.getErrorCode(json),
        errorMessage: this.getErrorMessage(json),
      }))
      .catch((error: Error) => ({
        ok: response.ok, // 204でレスポンスのJSONがない場合はここにくる
        status: response.status,
        json: { ...error },
        isNetworkError: false,
        isAuthenticationError,
        isTimeout: false,
      }))
  }

  async requestBlob(
    method: Methods,
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any = {},
    authorization = true,
    headers: { [key: string]: string } = {
      'User-Agent': userAgent,
    },
    { updateRefreshTokenIfNeed = true }: APIRequestOptions = {},
  ): Promise<APIResponse> {
    if (this.isTokenUpdateInprocess) {
      await this.waitIfIsTokenUpdateInprocess()
    }

    // if (authorization && updateRefreshTokenIfNeed) {
    //   await this.refreshTokenIfNeed()
    // }

    let url = requestUrl(path)

    if (authorization) {
      headers = this.addAuthorizationHeader(headers)
    }

    let params: RequestInit = {
      headers,
      mode: 'cors',
    }

    if (body instanceof FormData) {
      params = { ...params, body }
    } else {
      let filteredBody: { [key: string]: string | number | boolean } = {}
      for (const [key, value] of Object.entries(body)) {
        if (value !== null && typeof value !== 'undefined') {
          filteredBody[key] = value as string | number | boolean
        }
      }
      filteredBody = snakeCaseKeys(filteredBody, { deep: true }) as {
        [key: string]: string | number | boolean
      }

      switch (method.toLowerCase()) {
        case 'get':
        case 'head':
          // case 'delete':
          // eslint-disable-next-line no-case-declarations
          const queries = Object.keys(filteredBody)
            .map((key) => {
              return `${key}=${encodeURIComponent(filteredBody[key])}`
            })
            .join('&')
          if (queries) url += '?' + queries
          break
        default:
          params = {
            ...params,
            body: JSON.stringify(filteredBody),
          }
      }
    }

    let filename: string
    let response: FetchBlobResponse
    try {
      response = await RNFetchBlob.config({
        fileCache: true,
      }).fetch(method, url, headers, params.body)

      const info = response.info()
      const receivedHeaders = camelcaseKeys(info.headers)

      Log.info(`curl -H "authorization: ${headers['Authorization']}" "${url}"`)
      Log.info(method, url, headers, receivedHeaders)

      if (info.status >= 400) {
        const json = await response.json()
        return {
          ok: false,
          status: info.status,
          json,
          errorCode: this.getErrorCode(json),
          isNetworkError: false,
          isAuthenticationError: false,
          isTimeout: false,
        }
      }

      filename = await (async () => {
        const contentDisposition: string = receivedHeaders['contentDisposition']
        if (contentDisposition) {
          const matches = contentDisposition.match(/^attachment;filename=(.+)$/)
          if (matches) {
            const path = `${RNFetchBlob.fs.dirs.CacheDir}/${matches[1]}`
            try {
              await fs.unlink(path)
            } catch (error) {
              /* ファイルがない */
            }
            await fs.moveFile(response.path(), path, {
              NSFileProtectionKey: 'NSFileProtectionNone',
            })
            return path
          }
        }

        return response.path()
      })()
    } catch (error) {
      Log.warn(error)

      return {
        ok: false,
        status: 0,
        json: error,
        isNetworkError: true,
        isAuthenticationError: false,
        isTimeout: false,
      }
    }

    const isAuthenticationError = response.info().status === 401
    if (isAuthenticationError) {
      if (SessionManager.isLoggedIn()) {
        if (updateRefreshTokenIfNeed) {
          // 1度だけリトライ
          Log.info('=== リトライ ====')
          await this.updateRefreshToken()
          return this.request(method, path, body, authorization, headers, {
            updateRefreshTokenIfNeed: false,
          })
        }

        RNProgressHud.dismiss()
        NavigationService.logout()
        return {
          ok: false,
          status: response.info().status,
          json: {},
          isNetworkError: false,
          isAuthenticationError,
          isTimeout: false,
        }
      }
    }

    const isServerError = response.info().status >= 500
    if (isServerError) {
      Osidori.checkAppVersion()
      return {
        ok: false,
        status: response.info().status,
        json: {},
        isNetworkError: false,
        isAuthenticationError,
        isTimeout: false,
      }
    }

    return {
      ok: true,
      status: response.info().status,
      json: {},
      isNetworkError: false,
      isAuthenticationError,
      isTimeout: false,
      path: filename,
    }
  }

  private addAuthorizationHeader(headers: { [key: string]: string }): {
    [key: string]: string
  } {
    const accessToken = SessionManager.getAccessToken()
    if (accessToken) {
      return {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      }
    }
    return headers
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getErrorCode = (json: any): string | undefined => {
    if (!json.errors) return undefined
    if (Array.isArray(json.errors) && json.errors.length > 0) {
      return json.errors[0].code
    } else {
      return json.errors.code
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getErrorMessage = (json: any): string | undefined => {
    if (!json.errors) return undefined
    if (Array.isArray(json.errors) && json.errors.length > 0) {
      return json.errors[0].message
    } else {
      return json.errors.message
    }
  }

  private getRefreshToken = (props: GetRefreshTokenProps) => {
    return this.request(
      'POST',
      '/api/v2/auth/refresh-token',
      props,
      true,
      undefined,
      {
        updateRefreshTokenIfNeed: false,
        ignoreIsTokenUpdateInprocess: true,
      },
    )
  }

  // private isAccessTokenExpiration = () => {
  //   const accessToken = SessionManager.getAccessToken()
  //   if (!accessToken) return true // 有効期限切れ

  //   const accessTokenExpires = SessionManager.getAccessTokenExpires()
  //   // 有効期限30秒前までリフレッシュはしない
  //   // Log.info('=== ' + moment().diff(moment(accessTokenExpires)))
  //   return moment().diff(moment(accessTokenExpires)) >= -30 * 1000
  // }

  private waitIfIsTokenUpdateInprocess = async (): Promise<void> => {
    if (this.isTokenUpdateInprocess) {
      Log.info('=== リフレッシュ処理待機中 ===')
      await this.sleep(250)
      await this.waitIfIsTokenUpdateInprocess()
    }
  }

  private updateRefreshToken = async (): Promise<void> => {
    if (this.isTokenUpdateInprocess) {
      await this.waitIfIsTokenUpdateInprocess()
      return
    } else {
      this.isTokenUpdateInprocess = true
    }
    // if (this.isTokenUpdateInprocess) {
    //   Log.info('=== リフレッシュ処理中 ===')
    //   await this.sleep(250)
    //   this.refreshTokenIfNeed()
    //   return
    // }

    // if (!this.isAccessTokenExpiration()) return

    try {
      const response = await this.getRefreshToken({
        refreshToken: SessionManager.getRefreshToken() || '',
      })
      if (!response.ok) throw new APIError(response)
      const token = (response.json as GetRefreshTokenResponse).app
      await SessionManager.setAccessToken(
        token.accessToken,
        token.accessTokenExpiresAt,
        token.refreshToken,
        token.refreshTokenExpiresAt,
      )
    } finally {
      this.isTokenUpdateInprocess = false
    }
  }

  private sleep = (msec: number) =>
    new Promise((resolve) => setTimeout(resolve, msec))
}

export default new API()
