import { useEffect, useId, useState } from 'react'
import {
  Image as RNImage,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  StyleProp,
  StyleSheet,
  View,
} from 'react-native'

export type TintedStyle = Omit<ImageStyle, 'tintColor' | 'source'> & {
  tintColor?: string
} & Pick<ImageStyle, 'resizeMode'>

export type TintedImageProps = {
  source: string | ImageSourcePropType
  style?: StyleProp<TintedStyle>
}

const TintedImage = ({ source, style: imageStyle }: TintedImageProps) => {
  const id = useId()

  const { width, height, tintColor, ...style } = StyleSheet.flatten(imageStyle)

  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  useEffect(() => {
    const canvas = document.getElementById(id) as HTMLCanvasElement
    const canvasContext = canvas?.getContext('2d')
    setContext(canvasContext)
  }, [id])

  useEffect(() => {
    if (context !== null) {
      const img = new Image()
      img.src = source as string

      img.onload = () => {
        if (!context.canvas) return
        const canvasAspect = context.canvas.width / context.canvas.height // canvasのアスペクト比
        const imgAspect = img.width / img.height // 画像のアスペクト比

        let width = context.canvas.width
        let height = context.canvas.height

        if (imgAspect >= canvasAspect) {
          // 画像が横長
          height = context.canvas.width / imgAspect
          setTop((context.canvas.height - height) / 2)
        } else {
          // 画像が縦長
          width = context.canvas.height * imgAspect
          setLeft((context.canvas.width - width) / 2)
        }

        context.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height)

        context.globalCompositeOperation = 'source-in'
        context.fillStyle = tintColor ?? 'black'
        context.fillRect(0, 0, width, height)
      }
    }
  }, [context, height, source, tintColor, width])

  const scale = window.devicePixelRatio

  return (
    <View style={style}>
      <canvas
        id={id}
        width={+(width ?? 0) * scale}
        height={+(height ?? 0) * scale}
        style={{
          width,
          height,
          marginLeft: left,
          marginTop: top,
        }}></canvas>
    </View>
  )
}

const DefaultRNImage = ({ source, style }: TintedImageProps) => (
  <RNImage source={source as ImageSourcePropType} style={style} />
)

export default Platform.OS === 'web' ? TintedImage : DefaultRNImage
