import RightArrowButton from '@components/molecules/RightArrowButton'
import { GetHomeExpensesSummaryResponse } from '@lib/api/Financial'
import Color from '@lib/Color'
import GlobalFont from '@lib/GlobalFont'
import ProfileManager from '@lib/ProfileManager'
import { StyleSheet, Text, View } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { PieChart, PieChartData } from 'react-native-svg-charts'

const FamilyColor = '#79C7AC'
const UserColor = '#9AE1F3'
const PartnerColor = '#F7E379'

export default ({
  summary,
  displayDetailButton: displayRate,
}: {
  summary: GetHomeExpensesSummaryResponse
  displayDetailButton: boolean
}) => {
  const pieChartData: PieChartData[] = [
    {
      value: Math.abs(summary.family.amount),
      svg: {
        fill: FamilyColor,
      },
      key: FamilyColor,
    },
    {
      value: Math.abs(summary.owner.amount),
      svg: {
        fill: UserColor,
      },
      key: UserColor,
    },
    {
      value: Math.abs(summary.partner.amount),
      svg: {
        fill: PartnerColor,
      },
      key: PartnerColor,
    },
  ]

  const pieChartSize = wp('27%')
  // const pieChartSize = displayRate ? wp('32%') : wp('27%')

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row' }}>
        <View
          style={{
            // flex: 1,
            justifyContent: 'flex-start',
            marginTop: 0,
            width: wp(45),
          }}>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <PieChart
              style={{ width: pieChartSize, height: pieChartSize }}
              data={pieChartData}
              outerRadius="98%"
              innerRadius="75%"
              padAngle={0.04}
            />
            <View style={{ position: 'absolute' }}>
              <Text
                style={{
                  fontSize: wp('3%'),
                  fontWeight: 'normal',
                  color: Color.DefaultText,
                  textAlign: 'center',
                  marginBottom: 1,
                }}>
                合計
              </Text>
              <Text
                style={{
                  fontSize: wp('3%'),
                  fontWeight: 'normal',
                  textAlign: 'center',
                }}>
                {summary.totalAmount.jpCurrency()}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.pieChartRight}>
          <View
            style={{
              marginBottom: 6,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <View style={styles.chartItemTitleRow}>
              <View style={[styles.li, styles.liFamily]} />
              <Text style={styles.chartItemTitleText}>家族</Text>
            </View>
            <View>
              <Text
                style={[
                  styles.chartItemAmount,
                  {
                    color: Color.NumberBlack,
                    fontSize: Math.min(26, wp(6)),
                    fontWeight: 'normal',
                    fontFamily: GlobalFont.Family.Number,
                  },
                ]}>
                {summary.family.amount.jpCurrency()}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginBottom: 6,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <View style={styles.chartItemTitleRow}>
              <View style={[styles.li, styles.liUser]} />
              <Text style={styles.chartItemTitleText}>
                {ProfileManager.getName('user')}
              </Text>
            </View>
            <Text
              style={[
                styles.chartItemAmount,
                {
                  color: Color.NumberBlack,
                  fontSize: Math.min(26, wp(6)),
                  fontWeight: 'normal',
                  fontFamily: GlobalFont.Family.Number,
                },
              ]}>
              {summary.owner.amount.jpCurrency()}
            </Text>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={styles.chartItemTitleRow}>
              <View style={[styles.li, styles.liPartner]} />
              <Text style={styles.chartItemTitleText}>
                {ProfileManager.getName('partner')}
              </Text>
            </View>
            <Text
              style={[
                styles.chartItemAmount,
                {
                  color: Color.NumberBlack,
                  fontSize: Math.min(26, wp(6)),
                  fontWeight: 'normal',
                  fontFamily: GlobalFont.Family.Number,
                },
              ]}>
              {summary.partner.amount.jpCurrency()}
            </Text>
          </View>
        </View>
      </View>
      {displayRate ? (
        <View style={styles.tapDetailView}>
          <RightArrowButton disabled>詳細を見る</RightArrowButton>
        </View>
      ) : (
        <View style={{ marginBottom: 5 }} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // flexDirection: 'row',
  },
  pieChartRight: {
    flex: 1,
    justifyContent: 'space-between',
  },
  chartItemTitleRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chartItemTitleText: {
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  chartItemAmount: {
    fontSize: 18,
    fontWeight: 'normal',
    textAlign: 'right',
    paddingRight: 20,
  },
  chartItemRate: {
    fontSize: 14,
    fontWeight: 'normal',
    color: Color.Gray,
    textAlign: 'right',
    paddingRight: 20,
  },
  li: {
    width: 12,
    height: 12,
    marginEnd: 5,
  },
  liFamily: {
    backgroundColor: FamilyColor,
  },
  liUser: {
    backgroundColor: UserColor,
  },
  liPartner: {
    backgroundColor: PartnerColor,
  },
  tapDetailView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 15,
    paddingRight: 20,
    alignItems: 'center',
  },
  tapDetailText: {
    color: '#888888',
    fontSize: 13,
    fontWeight: 'normal',
  },
  tapDetailArrowIcon: {
    width: 11,
    height: 11,
    tintColor: '#888888',
    marginLeft: 1,
  },
})
