/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserStatus } from '@interfaces/Profile'
import ReactGA from 'react-ga4'
import Log from './Log'

export type PlatformTrakingValue = {
  ios: string
  android: string
}

class TrackingUtils {
  constructor() {}

  repro = {
    setScreenTrackingAfterPurchase: (event: string) => {},
    track: (value: string, category: 'Screen' | 'Completed' | 'Tap') => {},
    trackOnce: (key: string, category: 'Screen' | 'Completed' | 'Tap') => {},
    // TODO: 現在のところ未使用
    cumulativeTrack: () => {},
    setUserId: () => {},
    setPlatform: () => {},
    setAppVersion: () => {},
    setAppBuild: (appBuild: string) => {},
    setOSVersion: (version: string) => {},
    setUserStatus: (userStatus: UserStatus) => {},
    setUserInformationRegistration: (value: 'YES' | 'NO') => {},
    setPartnerInvitation: (value: 'YES' | 'NO') => {},
    setPairing: (value: 'YES' | 'NO') => {},
    setPersonalFinancialInstitutionRegistration: (value: 'YES' | 'NO') => {},
    setFamilyFinancialInstitutionRegistration: (value: 'YES' | 'NO') => {},
    setCreationOfGoalSaving: (value: 'YES' | 'NO') => {},
    setMembershipStatus: (value: 'YES' | 'NO') => {},
    setMailRegistered: (value: 0 | 1) => {},
    setMailAuthenticated: (value: 0 | 1) => {},
    setFinanceRegistered: (value: 0 | 1) => {},
    setGoalCreated: (value: 0 | 1) => {},
    setTransactionShared: (value: 0 | 1) => {},
    setFinanceShared: (value: 0 | 1) => {},
    setPaired: (value: 0 | 1) => {},
    setGroupGoalCreated: (value: 0 | 1) => {},
    setGroupTransactionShared: (value: 0 | 1) => {},
    setGroupFinanceShared: (value: 0 | 1) => {},
    setUserManuallyCreatedTransactionCreated: (value: 0 | 1) => {},
    setGroupWalletShared: (value: 0 | 1) => {},
    setWalletRegistered: (value: 0 | 1) => {},
    setPremiumUserTrialPeriod: (value: 0 | 1) => {},
    setPremiumUserTrialDone: (value: 0 | 1) => {},
    setPremiumUserPaid: (value: 0 | 1) => {},
    setPremiumUserPaidStatus: (value: number) => {},
    setIOSSupportAppTrackingTransparency: (value: 0 | 1) => {},
    setIDFAOffer: (value: 0 | 1) => {},
    setViewFamilyCalendar: (value: 0 | 1) => {},
    setGroupFamilyBudgetCreated: (value: boolean) => {},
    setGroupSettled: (value: boolean) => {},
    setIDFAPermission: (value: 0 | 1) => {},
    setPushPermission: (value: 0 | 1) => {},
    setPushPermissionNoticefromOsidOri: (value: 0 | 1) => {},
    setGroupHouseholdDiagnosis: (value: boolean) => {},
    setGroupFPAdvices: (value: boolean) => {},
    setGroupHouseholdDiagnosisAreaMatch: (value: boolean) => {},
    setGroupLifeInsuranceConsultationApplication: (value: boolean) => {},
    setGroupElectricityOrderd1Yearfree: (value: boolean) => {},
    setGroupRepeatTransactionRegistered: (value: boolean) => {},
    setPersonalRepeatTransactionRegistered: (value: boolean) => {},
    setGroupCategoryRegistered: (value: boolean) => {},
    setGroupCategoryCustom: (value: boolean) => {},
    setPersonalCategoryRegistered: (value: boolean) => {},
    setPersonalCategoryCustom: (value: boolean) => {},
    setPersonalBudgetCreated: (value: boolean) => {},
    setMaritalStatus: (value: string) => {},
    setSex: (value: string) => {},
  }

  adjust = {
    trackEvent: () => {},
  }

  ga = {
    send: ReactGA.send,
    pageview: ({ page, title }: { page?: string; title?: string }) => {
      Log.info(`pageview: page=${page} title=${title}`)
      ReactGA.send({
        hitType: 'pageview',
        page,
        title,
      })
    },
    tap: ({ action, label }: { action: string; label?: string }) => {
      Log.info(`tap: action=${action} label=${label}`)
      ReactGA.event({
        category: 'Tap',
        action,
        label,
      })
    },
  }
}

export default new TrackingUtils()
