import { GoalEditorProps } from '@interfaces/Goal'
import API, { APIResponse } from '../index'
import {
  AddGoalAmountProps,
  DeleteGoalProps,
  GetGoalCategoriesProps,
  GetGoalProps,
  GetGoalsProps,
  GetMonthlyReportProps,
} from './types'

export const createGoal = (props: GoalEditorProps): Promise<APIResponse> => {
  return API.request('POST', '/api/v3/goals', { goal: props }, true)
}

export const updateGoal = ({
  goalId,
  ...props
}: GoalEditorProps): Promise<APIResponse> => {
  return API.request('PUT', `/api/v3/goals/${goalId}`, { goal: props }, true)
}

export const getGoals = (props: GetGoalsProps): Promise<APIResponse> => {
  return API.request('GET', '/api/v3/goals', props, true)
}

export const getGoal = (props: GetGoalProps): Promise<APIResponse> => {
  return API.request('GET', `/api/v3/goals/${props.goalId}`, {}, true)
}

export const addGoalAmount = ({
  goalId,
  ...props
}: AddGoalAmountProps): Promise<APIResponse> => {
  return API.request('PUT', `/api/v3/goals/${goalId}/add-amount`, props, true)
}

export const deleteGoal = (props: DeleteGoalProps): Promise<APIResponse> => {
  return API.request('DELETE', `/api/v3/goals/${props.goalId}`, true)
}

export const getMonthlyReport = ({
  userAccountType,
  ...props
}: GetMonthlyReportProps): Promise<APIResponse> => {
  return API.request(
    'GET',
    `/api/v3/${userAccountType === 'family' ? 'group' : 'user'}/report/goals`,
    { ...props, sort: true },
    true,
  )
}

export const getGoalCategories = (
  props: GetGoalCategoriesProps,
): Promise<APIResponse> => {
  return API.request('GET', `/api/v3/goals/contents`, props, true)
}
