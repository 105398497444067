import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { APIError } from '@lib/api'
import { UserAPI } from '@lib/api/User'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useState } from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'

const PasswordResetScreen: React.FC<
  StackScreenProps<RootStackParamList, 'PasswordReset'>
> = ({ route }) => {
  const [email, setEmail] = useState(route.params?.email || '')

  const onChangeEmail = useCallback((email: string) => setEmail(email), [])

  const onPressSendButton = useCallback(async () => {
    try {
      showAppButtonIndicator()
      const response = await UserAPI.changePasswordRequest({
        email,
      })
      if (response.ok) {
        NavigationService.navigate('PasswordResetSentEmail')
      } else {
        throw new APIError(response)
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator()
    }
  }, [email])

  return (
    <View>
      <CommonHeader title="パスワードを忘れた場合" />
      <View style={styles.context}>
        <TextInput
          autoFocus={true}
          placeholder="メールアドレス入力"
          value={email}
          onChangeText={onChangeEmail}
          style={styles.textInput}
          autoCorrect={false}
          keyboardType="email-address"
          autoCapitalize="none"
          returnKeyType="done"
          placeholderTextColor={Color.PlaceHolderText}
        />
        <Text style={styles.titleText}>パスワードをお忘れですか？</Text>
        <Text style={styles.messageText}>
          パスワードを再設定するには登録済みのメールアドレスを入力してください。
          {'\n'}
          入力したメール宛にメールをお送りします。
        </Text>
        <AppButton
          title="送信"
          disabled={!email.isValidEmail()}
          onPress={onPressSendButton}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  context: {
    marginTop: 50,
    marginHorizontal: 20,
  },
  textInput: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#2F2F2F',
    height: 45,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#C2C2C2',
    paddingLeft: 8,
    paddingRight: 8,
  },
  titleText: {
    color: Color.DarkBlue,
    fontSize: 12,
    fontWeight: 'normal',
    marginTop: 50,
    marginBottom: 20,
  },
  messageText: {
    color: Color.Black,
    fontSize: 12,
    fontWeight: 'normal',
    marginBottom: 50,
  },
})

export default PasswordResetScreen
