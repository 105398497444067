import { MainScreenTab } from '../types'

export enum ActionType {
  UpdateMainScreenTab = 'UpdateSelectedTab',
  SetTabBarVisible = 'SetTabBarVisible',
}

export type UpdateMainScreenTabAction = {
  type: ActionType.UpdateMainScreenTab
  selectedTab: MainScreenTab
}

export type SetTabBarVisibleAction = {
  type: ActionType.SetTabBarVisible
  tabBarVisible: boolean
}

export type Action = UpdateMainScreenTabAction | SetTabBarVisibleAction

export type State = {
  selectedTab: MainScreenTab
  tabBarVisible: boolean
}
