import TintedImage from '@components/TintedImage'
import styled from 'styled-components/native'

export const StyledTouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-end;
  /* padding-top: 15px; */
  align-items: center;
`

export const StyledText = styled.Text<{ color?: string; size?: number }>`
  color: ${({ color = 'rgb(102, 102, 102)' }) => color};
  font-size: ${({ size = 13 }) => size}px;
  font-weight: normal;
`

export const StyledImage = styled(TintedImage)`
  width: 11px;
  height: 11px;
  tint-color: rgb(153, 153, 153);
  margin-left: 1px;
  resize-mode: contain;
`
