import Color from '@lib/Color'
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'

const HeaderEditButton = (props: TouchableOpacityProps) => (
  <TouchableOpacity style={styles.container} {...props}>
    <Image
      style={styles.image}
      source={require('@images/buttons/edit/button-edit.png')}
    />
    <Text style={styles.text}>編集</Text>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 8,
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  image: {
    width: 18,
    height: 18,
    tintColor: Color.White,
  },
  text: {
    fontSize: 11,
    fontWeight: 'normal',
    color: Color.White,
    marginTop: 3,
    paddingHorizontal: 10,
  },
})

export default HeaderEditButton
