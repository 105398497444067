import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CalculatorInput from '@components/Calculator/CalculatorInput'
import CommonHeader from '@components/CommonHeader'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { GoalManager } from '@lib/api/Goal'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useRef, useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import Toast from 'react-native-simple-toast'

const AddToSavingsScreen: React.FC<
  StackScreenProps<RootStackParamList, 'AddToSavings'>
> = ({ route }) => {
  const [amount, setAmount] = useState(0)
  const isSubtraction = useRef(!!route.params?.isSubtraction).current

  const addAmount = useCallback(async () => {
    const goalId = store.getState().goal.goal?.goalId
    if (!goalId) {
      CommonDialog.showMessage('目標がありません。')
      return
    }

    try {
      showAppButtonIndicator()

      await GoalManager.addGoalAmount({
        goalId,
        addAmount: amount,
      })

      await GoalManager.fetchGoal(goalId)

      if (Platform.OS !== 'web') {
        Toast.showWithGravity(
          isSubtraction
            ? '貯金を取り崩しました。'
            : '貯金額にお金を追加しました。',
          Toast.SHORT,
          Toast.BOTTOM,
        )
      }
      NavigationService.goBack()
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator()
    }
  }, [amount, isSubtraction])

  const onChangeAmount = useCallback(
    (amount: number) => {
      if (isSubtraction) {
        setAmount(-Math.abs(amount))
      } else {
        setAmount(amount)
      }
    },
    [isSubtraction],
  )

  return (
    <View style={styles.container}>
      <CommonHeader
        title={isSubtraction ? '貯金額の取り崩し' : '貯金額に追加'}
      />
      <View style={styles.body}>
        <Text style={styles.title}>
          {isSubtraction ? '取り崩す金額' : '追加する金額'}
        </Text>
        <View style={styles.input}>
          <CalculatorInput hideMinus value={amount} onChange={onChangeAmount} />
        </View>
        <AppButton
          title={isSubtraction ? '取り崩す' : '追加する'}
          style={styles.addButton}
          disabled={amount === 0}
          onPress={addAmount}
        />
      </View>
    </View>
  )
}

export default AddToSavingsScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.White,
  },
  title: {
    color: '#666666',
    fontSize: 17,
    fontWeight: 'normal',
    marginVertical: 30,
  },
  wrapInput: {
    paddingHorizontal: 20,
  },
  input: {
    paddingHorizontal: 10,
    width: '100%',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#dedede',
    height: 60,
    justifyContent: 'center',
  },
  body: {
    marginTop: 20,
    marginHorizontal: 20,
  },
  addButton: {
    marginTop: 30,
  },
})
