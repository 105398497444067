import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { accountModeSelector } from '@lib/AccountManager/actions'
import { useTabNavigatorWebHack } from '@lib/web/tab-navigator-web-hack'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React from 'react'
import { useSelector } from 'react-redux'
import SettingCarryForwardContentScreen from './SettingCarryForwardContentScreen'
import { StyledRootContainer } from './styles'

const Tab = createMaterialTopTabNavigator()

const SettingCarryForwardScreen: React.FC = () => {
  const userAccountType = useSelector(accountModeSelector)

  useTabNavigatorWebHack(
    userAccountType === 'family' ? undefined : 'SettingCarryForwardUser',
  )

  return (
    <StyledRootContainer>
      <CommonHeader title="繰り越し設定" />
      <Tab.Navigator
        backBehavior="none"
        tabBarOptions={TabBarOptions}
        initialRouteName={
          userAccountType === 'family'
            ? 'SettingCarryForwardFamily'
            : 'SettingCarryForwardUser'
        }>
        <Tab.Screen
          name="SettingCarryForwardFamily"
          component={SettingCarryForwardContentScreen}
          initialParams={{ userAccountType: 'family' }}
          options={{
            title: '家族画面',
          }}
        />
        <Tab.Screen
          name="SettingCarryForwardUser"
          component={SettingCarryForwardContentScreen}
          initialParams={{ userAccountType: 'user' }}
          options={{
            title: '個人画面',
          }}
        />
      </Tab.Navigator>
    </StyledRootContainer>
  )
}

export default SettingCarryForwardScreen
