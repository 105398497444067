import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { isValidGender } from '@interfaces/Gender'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import ProfileManager from '@lib/ProfileManager'
import ProgressHUD from '@lib/ProgressHUD'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SettingProfileScreen from './SettingProfileContentScreen'
import { clearProfiles, setProfile } from './slice'
import { RootContainer } from './styles'

const Tab = createMaterialTopTabNavigator<RootStackParamList>()

// const tabBarStyles: MaterialTopTabBarOptions = {
//   activeTintColor: Color.Green,
//   inactiveTintColor: '#999',
//   indicatorStyle: {
//     backgroundColor: Color.Green,
//     height: 1,
//   },
//   labelStyle: {
//     fontWeight: '600',
//     fontSize: 15,
//   },
//   style: {
//     elevation: 0,
//     backgroundColor: 'white',
//     borderBottomWidth: 1,
//     borderBottomColor: 'rgba(0,0,0,0.1)',
//   },
// }

const SettingProfilesScreen: React.FC = () => {
  const dispatch = useDispatch()

  const getProfiles = useCallback(async () => {
    try {
      ProgressHUD.show()

      const userProfile = await ProfileManager.fetchUserProflie()
      const userName = userProfile.name || undefined
      const userGender = userProfile.gender
      const userBithday = userProfile.birthday || undefined
      const userMarried =
        userProfile.married !== null ? userProfile.married : undefined
      const userEmail = userProfile.email || undefined

      const partnerProfile = await ProfileManager.fetchPartnerProflie()
      const partnerName = partnerProfile?.name || undefined
      const partnerGender = partnerProfile?.gender
      const partnerBithday = partnerProfile?.birthday || undefined
      const partnerMarried =
        partnerProfile?.married !== null ? partnerProfile?.married : undefined
      const partnerEmail = partnerProfile?.email

      dispatch(
        setProfile({
          userProfile: {
            name: userName,
            gender: isValidGender(userGender) ? userGender : undefined,
            birthday: userBithday,
            married: userMarried,
            email: userEmail,
          },
          partnerProfile: {
            name: partnerName,
            gender: isValidGender(partnerGender) ? partnerGender : undefined,
            birthday: partnerBithday,
            married: partnerMarried,
            email: partnerEmail,
          },
        }),
      )
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      ProgressHUD.dismiss()
    }
  }, [dispatch])

  useEffect(() => {
    getProfiles()

    return () => {
      dispatch(clearProfiles())
    }
    // Exclude getProfiles
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <RootContainer>
      <CommonHeader title="プロフィールの設定" />
      <Tab.Navigator backBehavior="none" tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="SettingProfileContentUser"
          component={SettingProfileScreen}
          initialParams={{ userType: 'user' }}
          options={{ title: 'わたし' }}
        />
        <Tab.Screen
          name="SettingProfileContentPartner"
          component={SettingProfileScreen}
          initialParams={{ userType: 'partner' }}
          options={{ title: 'パートナー' }}
        />
      </Tab.Navigator>
    </RootContainer>
  )
}

const Stack = createStackNavigator()

const StackScreen = () => (
  <Stack.Navigator
    screenOptions={{
      gestureEnabled: false,
      headerShown: false,
      ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
    }}>
    <Stack.Screen name="SettingProfiles" component={SettingProfilesScreen} />
  </Stack.Navigator>
)

export default StackScreen
