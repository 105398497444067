import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon as WebFontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIcon, Props } from '@fortawesome/react-native-fontawesome'
import { Platform, View } from 'react-native'

export type FAIconProps = Props & { webSize?: SizeProp }

export const FAIcon = ({ webSize, style, ...props }: FAIconProps) => (
  <View style={style}>
    {Platform.OS === 'web' ? (
      <WebFontAwesomeIcon {...props} size={webSize ?? 'xl'} />
    ) : (
      <FontAwesomeIcon {...props} />
    )}
  </View>
)
