import API, { APIResponse } from './index'

export interface TimelineActivity {
  day: string
  url: string
  message: string
  icon: 'person' | 'family'
}

export interface TimelineNotice {
  id: number
  title: string
  url: string
}

export interface GetTimelineActivitiesResponse {
  activities: TimelineActivity[]
  nextActivityDate: string | null
}

export interface GetTimelineNoticesResponse {
  notices: TimelineNotice[]
}

export interface GetTimelineNoticesUnreasCountResponse {
  notices: {
    unreadTotalCount: number
  }
}

export type GetActivitiesProps = {
  nextActivityDate?: string
}

class Timeline {
  getActivities = (props?: GetActivitiesProps): Promise<APIResponse> => {
    return API.request(
      'GET',
      `/api/v2/activities`,
      { to: props?.nextActivityDate },
      true,
    )
  }

  getNotices = (): Promise<APIResponse> => {
    return API.request('GET', '/api/v1/notices', {}, true)
  }

  getNoticeUnreadCount = (): Promise<APIResponse> => {
    return API.request('GET', '/api/v1/notices/unread-total-count', {}, true)
  }

  markAllNoticesAsRead = (): Promise<APIResponse> => {
    return API.request('PUT', '/api/v1/notices/all-read', {}, true)
  }
}

export default new Timeline()
