import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TransferTransactionItem from '@components/TransferTransactionItem'
import { UserAccountType } from '@interfaces/Account'
import { FinancialAccount, Transaction } from '@interfaces/Financial'
import { GetAccountTransactionsResponse } from '@lib/api/Financial'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import {
  RootStackParamList,
  TransferSelectTransactionCallback,
} from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import {
  ActivityIndicator,
  ListRenderItem,
  RefreshControl,
  SectionList,
  SectionListData,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { connect } from 'react-redux'

interface Props {
  bankTransaction?: GetAccountTransactionsResponse
  emoneyTransaction?: GetAccountTransactionsResponse
  cardTransaction?: GetAccountTransactionsResponse
  walletTransaction?: GetAccountTransactionsResponse
}

interface State {
  nextTransactionDate?: string | null
  refreshing: boolean
  sections?: SectionListData<Transaction>[]
}

class TransferSelectTransactionScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'TransferSelectTransaction'>,
  State
> {
  private userAccountType!: UserAccountType
  private transferDirection!: 'from' | 'to'
  private financialAccount!: FinancialAccount
  private amount?: number
  private callback!: TransferSelectTransactionCallback

  state: State = {
    refreshing: false,
  }

  componentDidMount() {
    const {
      userAccountType,
      transferDirection,
      financialAccount,
      amount,
      callback,
    } = this.props.route.params
    this.userAccountType = userAccountType
    this.transferDirection = transferDirection
    this.financialAccount = financialAccount
    this.amount = amount
    this.callback = callback

    this.props.navigation.addListener('focus', () => {
      this.fetchAccountTransactions()
    })
  }

  private fetchAccountTransactions = async (append = false) => {
    try {
      const { nextTransactionDate } =
        await FinancialManager.fetchAccountTransactions(
          {
            userAccountType: this.userAccountType,
            financialType: this.financialAccount.finance as
              | 'bank'
              | 'emoney'
              | 'card'
              | 'wallet',
            accountId: this.financialAccount.accountId,
            to: append
              ? this.state.nextTransactionDate
                ? this.state.nextTransactionDate
                : undefined
              : undefined,
            amount: this.amount ? -this.amount : undefined,
          },
          append,
        )

      const sections = this.makeSections()

      this.setState({
        sections,
        nextTransactionDate,
      })
    } catch (error) {
      CommonDialog.showError({ error, onPress: NavigationService.goBack })
    }
  }

  private onPressItem = (item: Transaction) => {
    this.callback({
      userAccountType: this.userAccountType,
      transferDirection: this.transferDirection,
      financialAccountType: this.financialAccount.finance,
      financialAccountId:
        (this.financialAccount.finance === 'wallet'
          ? item.walletId
          : item.accountId) || 0,
      financialAccountName: this.financialAccount.name,
      transactionId: item.transactionId,
      transactionUsedLocation: item.usedLocation || item.categoryName2,
    })
    NavigationService.pop(2)
  }

  private renderItem: ListRenderItem<Transaction> = ({ item }) => {
    return (
      <TransferTransactionItem
        userAccountType={this.userAccountType}
        transaction={item}
        onPress={() => this.onPressItem(item)}
      />
    )
  }

  private title = () =>
    this.transferDirection === 'from'
      ? '振替元の取引を選択'
      : '振替先の取引を選択'

  private onEndReached = () => {
    if (this.state.nextTransactionDate) {
      this.fetchAccountTransactions(true)
    }
  }

  private makeSections = () => {
    const transactions: { [date: string]: Transaction[] } = {}

    const transaction = (() => {
      if (this.financialAccount.finance === 'bank') {
        return this.props.bankTransaction
      } else if (this.financialAccount.finance === 'emoney') {
        return this.props.emoneyTransaction
      } else if (this.financialAccount.finance === 'card') {
        return this.props.cardTransaction
      } else if (this.financialAccount.finance === 'wallet') {
        return this.props.walletTransaction
      }
    })()

    const accountTransactions = transaction?.transactions || []

    const sections: SectionListData<Transaction>[] = []

    accountTransactions
      .filter(
        // (v) => !v.transferId && (!this.amount || v.amount === -this.amount),
        (v) => !v.transferId,
      )
      .forEach((transaction: Transaction) => {
        const date = moment(transaction.usedDate).format('YYYYMMDD')
        if (transactions[date]) {
          transactions[date].push(transaction)
        } else {
          transactions[date] = [transaction]
        }
      })

    const today = moment().format('YYYYMMDD')

    Object.keys(transactions)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: transactions[date],
        })
      })

    return sections
  }

  listEmptyComponent = () => (
    <View style={{ margin: 10 }}>
      <Text>取引がありません</Text>
    </View>
  )

  render() {
    return (
      <>
        <CommonHeader title={this.title()} />
        {this.state.sections !== undefined ? (
          <View style={styles.container}>
            <View
              style={{
                height: 70,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <TouchableOpacity
                onPress={() => {
                  this.callback({
                    userAccountType: this.userAccountType,
                    transferDirection: this.transferDirection,
                    financialAccountType: this.financialAccount.finance,
                    financialAccountId: this.financialAccount.accountId,
                    financialAccountName: this.financialAccount.name,
                  })
                  NavigationService.pop(2)
                }}>
                <Text style={{ color: Color.Primary, fontWeight: 'bold' }}>
                  取引を指定せずに振替する
                </Text>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 10,
                marginBottom: 10,
                color: Color.DefaultText,
                fontWeight: 'bold',
              }}>
              取引を指定して振替する
            </Text>
            <SectionList
              sections={this.state.sections}
              renderItem={this.renderItem}
              renderSectionHeader={({ section: { title } }) => (
                <SectionListHeader
                  title={title}
                  style={{ fontWeight: 'normal' }}
                />
              )}
              keyExtractor={(item: Transaction) =>
                [
                  item.type,
                  item.transactionId,
                  item.isAccountShared,
                  item.isTransactionShared,
                ].join('-')
              }
              onEndReached={this.onEndReached}
              refreshControl={
                <RefreshControl
                  refreshing={this.state.refreshing}
                  onRefresh={async () => {
                    this.setState({ refreshing: true })
                    await this.fetchAccountTransactions()
                    this.setState({ refreshing: false })
                  }}
                />
              }
              ItemSeparatorComponent={() => ItemSeparator}
              ListEmptyComponent={() => this.listEmptyComponent()}
              stickySectionHeadersEnabled={false}
            />
          </View>
        ) : (
          <View style={styles.indicatorContainer}>
            <ActivityIndicator />
          </View>
        )}
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    bankTransaction: state.financial.bankTransaction,
    emoneyTransaction: state.financial.emoneyTransaction,
    cardTransaction: state.financial.cardTransaction,
    walletTransaction: state.financial.walletTransaction,
  }
}

export default connect(mapStateToProps)(TransferSelectTransactionScreen)
