import { UserAccountType } from '@interfaces/Account'
import { ScrollView } from '@nandorojo/anchor'
import styled from 'styled-components/native'
import DateButton from './DateButton'
import UserAccountButton from './UserAccountButton'

export const StyledScrollView = styled(ScrollView)`
  background-color: rgb(247, 247, 247);
`

export const StyledColorBgView = styled.View<{
  userAccountType: UserAccountType
}>`
  background-color: ${({ userAccountType }) =>
    userAccountType === 'family' ? 'rgb(224, 243, 206)' : 'rgb(255, 236, 207)'};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${wp(103)}px;
`

export const StyledBgImageView = styled.View<{
  userAccountType: UserAccountType
}>`
  position: absolute;
  top: ${({ userAccountType }) =>
    userAccountType === 'family' ? wp(20) : wp(26)}px;
  right: ${({ userAccountType }) =>
    userAccountType === 'family' ? wp(3) : wp(8)}px;
  width: ${wp(35)}px;
  height: ${({ userAccountType }) =>
    userAccountType === 'family' ? wp(62) : wp(55)}px;
  /* resize-mode: contain; */
  z-index: 1;
  /* background-color: red; */
`
export const StyledBgImage = styled.Image<{ userAccountType: UserAccountType }>`
  width: 100%;
  height: 100%;
  resize-mode: contain;
  /* z-index: 1; */
`

export const StyledMainContainer = styled.View`
  margin-top: ${wp(12)}px;
  margin-horizontal: ${wp(6)}px;
  align-items: flex-start;
`

export const StyledTitleText = styled.Text`
  font-size: ${wp(7.5)}px;
  font-weight: bold;
  color: rgb(58, 58, 58);
`

export const StyledDateButton = styled(DateButton)`
  margin-top: ${wp(4)}px;
  padding-vertical: ${wp(2)}px;
  padding-right: ${wp(3)}px;
`

export const StyledAvatarView = styled.View`
  margin-top: ${wp(6)}px;
  flex-direction: row;
  align-items: center;
`

export const StyledUserAccountButton = styled(UserAccountButton)`
  margin-left: ${wp(4)}px;
`

export const StyledOverviewText = styled.Text`
  margin-top: ${wp(3)}px;
  margin-bottom: ${wp(8)}px;
  font-size: ${wp(3.3)}px;
  font-weight: normal;
  color: rgb(58, 58, 58);
  line-height: ${wp(4)}px;
`

export const StyledCommonPanel = styled.View`
  margin-bottom: ${wp(5)}px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border-width: 0.5px;
  border-color: rgb(240, 240, 240);
  /* shadow-offset: {
    width: 0;
    height: 0;
  } */
  /* shadow-opacity: 0.1;
  shadow-radius: 5; */
  /* elevation: 2.5; */
  /* padding: ${wp(6)}px; */
`

export const StyledScrollTopTopButton = styled.TouchableOpacity`
  position: absolute;
  align-self: center;
  bottom: 20px;
`

export const StyledScrollTopTopImage = styled.Image`
  width: 64px;
  height: 64px;
  resize-mode: contain;
`

export const StyledFooterView = styled.View`
  margin-top: -10px;
  margin-left: 20px;
  margin-right: 20px;
`

export const StyledFooterRowView = styled.View`
  flex-direction: row;
`

export const StyledFooterSymbolText = styled.Text`
  margin-top: 1px;
  font-size: 12px;
  font-weight: normal;
`

export const StyledFooterText = styled.Text`
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
`
