import { useContext } from 'react'
import {
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import SpotlightTourContext from './SpotlightTourContext'
import { PositionSize, Spot } from './types'

const styles = StyleSheet.create({
  rootContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  tipsView: {
    // margin: 20,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
  },
})

export type TipsContainerProps = {
  spotId: string
  overlayViewRect: PositionSize
}

export type TipsProps = {
  spotId: string
  step: number
  maxStep: number
  next: () => void
  previous: () => void
  goto: (step: number) => void
  finish: () => void
}

const windowHeight = Dimensions.get('window').height

const getPosition = (spot: Spot, overlayViewRect: PositionSize) => {
  // Log.info(getPosition, { overlayViewRect })
  const cy = spot.y + spot.height / 2

  const position =
    spot.tipsPosition === 'auto'
      ? cy > windowHeight / 2
        ? 'top'
        : 'bottom'
      : spot.tipsPosition

  if (position === 'bottom') {
    if (spot.spotType === 'rect') {
      return {
        top:
          spot.y +
          spot.height +
          (spot.height * spot.scale - spot.height) / 2 +
          spot.translateY -
          overlayViewRect.y,
      }
    } else {
      const cy = spot.y + spot.height / 2
      const r = (Math.max(spot.width, spot.height) * spot.scale) / 2

      return {
        top: cy + r + spot.translateY - overlayViewRect.y,
      }
    }
  } else {
    if (spot.spotType === 'rect') {
      return {
        bottom:
          windowHeight -
          spot.y +
          (spot.height * spot.scale - spot.height) / 2 -
          spot.translateY -
          (windowHeight - overlayViewRect.height - overlayViewRect.y),
      }
    } else {
      const cy = spot.y + spot.height / 2
      const r = (Math.max(spot.width, spot.height) * spot.scale) / 2

      // console.log('***', {
      //   position,
      //   windowHeight,
      //   cy,
      //   r,
      //   spot,
      //   overlayViewRect,
      // })
      // console.log(
      //   '===',
      //   windowHeight -
      //     cy +
      //     r -
      //     spot.translateY -
      //     (windowHeight - overlayViewRect.height - overlayViewRect.y),
      // )

      return {
        bottom:
          (Platform.OS === 'web' ? 170 : 0) + // TODO web用の調整
          windowHeight -
          cy +
          r -
          spot.translateY -
          (windowHeight - overlayViewRect.height - overlayViewRect.y),
      }
    }
  }
}

const DefaultTips = ({
  spotId,
  step,
  maxStep,
  next,
  previous,
  finish,
}: TipsProps) => (
  <View style={styles.tipsView}>
    <Text>{spotId}</Text>
    <Text>{`Step ${step}`}</Text>
    <View>
      {step > 1 && (
        <TouchableOpacity onPress={previous}>
          <Text>Previous</Text>
        </TouchableOpacity>
      )}
      {step < maxStep ? (
        <TouchableOpacity onPress={next}>
          <Text>Next</Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity onPress={finish}>
          <Text>Finish</Text>
        </TouchableOpacity>
      )}
    </View>
  </View>
)

const TipsContainer = ({ spotId, overlayViewRect }: TipsContainerProps) => {
  const {
    spots,
    tour,
    step,
    setStep,
    nextStep,
    previousStep,
    finishSpotlightTour,
    // basePosition,
  } = useContext(SpotlightTourContext)

  if (!step) return null

  const spot = spots[spotId]

  const position = getPosition(spot, overlayViewRect)

  const rootContainerStyle = {
    ...styles.rootContainer,
    ...position,
  }

  return (
    <View style={rootContainerStyle} pointerEvents="box-none">
      {spot.tipsComponent ? (
        <spot.tipsComponent
          spotId={spotId}
          step={step}
          maxStep={tour.length}
          next={nextStep}
          previous={previousStep}
          goto={setStep}
          finish={finishSpotlightTour}
        />
      ) : (
        <DefaultTips
          spotId={spotId}
          step={step}
          maxStep={tour.length}
          next={nextStep}
          previous={previousStep}
          goto={setStep}
          finish={finishSpotlightTour}
        />
      )}
    </View>
  )
}

export default TipsContainer
