import API, { APIResponse } from '../index'
import {
  GetTransactionDefaultSettingsProps,
  PostTransactionDefaultSettingsProps,
} from './types'

export const getTransactionDefaultSettings = (
  props: GetTransactionDefaultSettingsProps,
): Promise<APIResponse> => {
  return API.request(
    'GET',
    '/api/v3/user-transaction-default-settings',
    props,
    true,
  )
}

export const postTransactionDefaultSettings = (
  props: PostTransactionDefaultSettingsProps,
): Promise<APIResponse> => {
  return API.request(
    'POST',
    '/api/v3/user-transaction-default-settings',
    props,
    true,
  )
}
