import Color from '@lib/Color'
import React from 'react'
import {
  Image,
  ImageBackground,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

interface Props {}

interface State {
  visible: boolean
}

class PairingCompletedDialog extends React.PureComponent<Props, State> {
  private static current?: PairingCompletedDialog

  public static show = (visible = true) => {
    PairingCompletedDialog.current?.setState({ visible })
  }

  state: State = {
    visible: false,
  }

  componentDidMount() {
    PairingCompletedDialog.current = this
  }

  componentWillUnmount() {
    PairingCompletedDialog.current = undefined
  }

  private close = () => {
    this.setState({ visible: false })
  }

  render() {
    return (
      <Modal
        transparent={true}
        visible={this.state.visible}
        animationType="fade"
        onRequestClose={this.close}>
        <ImageBackground
          style={styles.container}
          source={require('@images/dsTopCoupleFirst/ds_top_couple_first.png')}>
          <View style={styles.dialog}>
            <View style={styles.wrapImage}>
              <View style={styles.borderImage}>
                <Image
                  source={require('@images/avatar/tint/icon-avatar-women.png')}
                  style={styles.image}
                />
              </View>
              <View style={[styles.borderImage, styles.imageRight]}>
                <Image
                  source={require('@images/avatar/tint/icon-avatar-men.png')}
                  style={styles.image}
                />
              </View>
            </View>
            <View style={styles.content}>
              <Text style={styles.text}>ペアリングが完了しました！</Text>
              <Text style={styles.text2}>これから一緒に頑張りましょう！</Text>
            </View>
            <TouchableOpacity onPress={this.close}>
              <Text style={styles.btnOK}>OK</Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#00000080',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    backgroundColor: 'white',
  },
  wrapImage: {
    marginTop: -40,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  borderImage: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 80,
    backgroundColor: '#e0e0e0',
    borderWidth: 3,
    borderColor: 'white',
    borderRadius: 40,
  },
  imageRight: {
    marginLeft: -10,
  },
  image: {
    tintColor: Color.Gray,
    width: 60,
    height: 60,
  },
  content: {
    padding: 20,
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 14,
  },
  text2: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  btnOK: {
    borderTopColor: '#f1f1f1',
    borderTopWidth: 1,
    paddingVertical: 10,
    color: '#008bff',
    textAlign: 'center',
  },
})

export default PairingCompletedDialog
