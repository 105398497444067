import { PressableProps } from 'react-native'
import styled from 'styled-components/native'

export type AddPayoffListButtonProps = PressableProps

const AddPayoffListButton = ({ onPress }: AddPayoffListButtonProps) => (
  <RootContainer onPress={onPress}>
    <StyleImage />
    <TextContainer>
      <StyleText>{'未精算リスト\nに入れる'}</StyleText>
    </TextContainer>
  </RootContainer>
)

const RootContainer = styled.Pressable({
  flex: 1,
  minWidth: 80,
  backgroundColor: 'rgb(52, 197, 162)',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyleImage = styled.Image.attrs({
  source: require('@images/icons/icon-settlement-list.png'),
  resizeMode: 'contain',
})({
  marginTop: 8,
  height: 30,
  width: '100%',
})

const TextContainer = styled.View({
  marginTop: 2,
  height: 30,
  justifyContent: 'center',
})

const StyleText = styled.Text({
  fontSize: 10,
  fontWeight: 'normal',
  color: 'white',
  textAlign: 'center',
})

export default AddPayoffListButton
