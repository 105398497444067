import Color from '@lib/Color'
import React from 'react'
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native'

type Props = TextInputProps & { containerStyle?: StyleProp<ViewStyle> }

interface State {
  isTextInputFocused: boolean
  textInputValue?: string
  height?: string | number
}

class EllipsizeTextInput extends React.Component<Props> {
  state: State = {
    isTextInputFocused: false,
    textInputValue: this.props.value,
    // textInputValue: Platform.OS === 'android' ? '' : this.props.value,
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({ textInputValue: this.props.value })
    }
  }
  focus = () => {
    this.textInputRef.current?.focus()
  }

  private textInputRef = React.createRef<TextInput>()

  private onChangeText = (text: string) => {
    this.setState({ textInputValue: text }, () => {
      this.props.onChangeText &&
        this.props.onChangeText(this.state.textInputValue || '')
    })
  }

  private onFocusTextInput = () => {
    this.setState({
      isTextInputFocused: true,
      textInputValue: this.props.value,
    })
  }

  private onEndEditing = () => {
    this.props.onChangeText &&
      this.props.onChangeText(this.state.textInputValue?.trim() || '')
    this.setState({
      isTextInputFocused: false,
      // textInputValue:
      //   Platform.OS === 'android' ? '' : this.state.textInputValue,
    })
  }

  private onPressText = () => {
    if (this.props.editable === undefined || this.props.editable) {
      this.textInputRef.current?.focus()
    }
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          this.props.containerStyle,
          this.props.multiline
            ? {
                height: +(this.state.height || 0) + 20,
                minHeight: '100%',
              }
            : undefined,
        ]}>
        <TextInput
          ref={this.textInputRef}
          onEndEditing={this.onEndEditing}
          onFocus={this.onFocusTextInput}
          onChangeText={this.onChangeText}
          value={this.state.textInputValue}
          editable={this.props.editable}
          autoFocus={this.props.autoFocus}
          placeholder={this.props.placeholder}
          placeholderTextColor={Color.PlaceHolderText}
          returnKeyType={this.props.returnKeyType ?? 'done'}
          multiline={this.props.multiline}
          onContentSizeChange={(event) => {
            if (Platform.OS !== 'web') {
              this.setState({ height: event.nativeEvent.contentSize.height })
            }
          }}
          style={[
            styles.textInput,
            this.props.style,
            this.props.multiline
              ? Platform.select({
                  ios: { paddingTop: 20 },
                  android: { paddingTop: 20 },
                  web: { paddingTop: 20 },
                })
              : undefined,
            Platform.OS === 'web' && !this.state.textInputValue
              ? { color: Color.PlaceHolderText }
              : {},
          ]}
        />
        {Platform.OS === 'android' && !this.state.isTextInputFocused && (
          <Text
            onPress={this.onPressText}
            ellipsizeMode="tail"
            numberOfLines={this.props.multiline ? undefined : 1}
            style={[
              styles.text,
              this.props.style,
              this.props.value ? {} : styles.placeholder,
              { backgroundColor: 'white' },
            ]}>
            {this.props.value || this.props.placeholder}
          </Text>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    // height: '100%',
    minHeight: 44,
  },
  text: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    textAlignVertical: 'center',
    backgroundColor: 'white',
    paddingVertical: 0,
  },
  textInput: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    textAlignVertical: 'center',
    paddingVertical: 0,
  },
  placeholder: {
    color: '#9e9e9e',
  },
})

export default EllipsizeTextInput
