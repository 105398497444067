import Color from '@lib/Color'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  flex: 1;
  background-color: ${Color.LightGray};
`

export const StyledNameText = styled.Text`
  color: ${Color.White};
  font-size: 15px;
  font-weight: bold;
  margin-top: 11px;
  text-shadow-offset: 1px 1px;
  text-shadow-radius: 1px;
  text-shadow-color: rgba(0, 0, 0, 0.1);
`
