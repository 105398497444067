import { NativeApp } from '@lib/Env'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import {
  createStackNavigator,
  StackScreenProps,
  TransitionPresets,
} from '@react-navigation/stack'
import React, { useEffect } from 'react'
import FinancialAccountsScreen from '../Home/FinancialAccounts'
import MainTabsStackScreens from '../MainTabsStackScreens'

const Stack = createStackNavigator()

const FinancialAccountsTabScreen: React.FC<
  StackScreenProps<RootStackParamList, 'FinancialAccountsTab'>
> = ({ route }) => {
  useEffect(() => {
    if (route.params?.tab) {
      // navigation.popToTop()
      // NavigationService.popToTop()
      NavigationService.navigate(route.params?.tab)
    }
  }, [route.params?.tab])

  return (
    <Stack.Navigator
      initialRouteName="FinancialAccounts"
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
      }}>
      <Stack.Screen
        key="FinancialAccounts"
        name="FinancialAccounts"
        component={FinancialAccountsScreen}
        initialParams={route.params}
      />
      {MainTabsStackScreens(Stack, 'FinancialAccountsTab')}
    </Stack.Navigator>
  )
}

export default FinancialAccountsTabScreen
