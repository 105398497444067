import CommonHeader from '@components/CommonHeader'
import AccountTrackerManager from '@lib/AccountTrackerManager'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useEffect, useRef } from 'react'
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'

const WaitExternalServiceScreen: React.FC<
  StackScreenProps<RootStackParamList, 'WaitExternalService'>
> = ({ route }) => {
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    timeout.current = setTimeout(() => {
      const data = route.params
      const uri = AccountTrackerManager.makeAccountTrackerUri(data)
      Log.info('WaitExternalServiceScreen', data, uri)
      NavigationService.navigate('AccountTracker', { uri })
    }, 1000)
    return () => {
      timeout.current && clearTimeout(timeout.current)
    }
  }, [route.params])

  return (
    <>
      <CommonHeader
        leftButtonTitle="口座"
        onPressLeftButton={() => {
          timeout.current && clearTimeout(timeout.current)
          NavigationService.navigate('FinancialAccountCooperation')
        }}
      />
      <View style={styles.container}>
        <Text style={styles.textBold}>
          金融機関を連携するために{'\n'}
          外部サービスに接続中です。
        </Text>
        <View style={styles.wrapLoading}>
          <ActivityIndicator color="white" size="large" />
        </View>
        <Text>少々お待ちください。</Text>
        <Text style={styles.textSmall}>
          銀行口座などの情報を取得するため、
          {'\n'}
          OsidOriは「Account Tracker」を利用しています。
        </Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapLoading: {
    backgroundColor: '#e5e5e5',
    padding: 15,
    borderRadius: 10,
    marginVertical: 30,
  },
  textBold: {
    fontWeight: 'bold',
  },
  textSmall: {
    fontSize: 10,
    fontWeight: 'normal',
    textAlign: 'center',
  },
})

export default WaitExternalServiceScreen
