import { UserAccountType } from '@interfaces/Account'
import { ProfileState } from '@lib/ProfileManager/types'
import store from '@redux/store'
import React from 'react'
import {
  ImageBackground,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  StyleSheet,
  View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { connect } from 'react-redux'

export interface HomeBackgroundImageProps {
  userAccountType: UserAccountType
  style?: StyleProp<ImageStyle>
  blurRadius?: number
}

interface StateProps {
  profile: ProfileState
}

class HomeBackgroundImage extends React.Component<
  HomeBackgroundImageProps & StateProps
> {
  private backgroundImage = () => {
    const { userAccountType, profile } = this.props
    let source: ImageSourcePropType
    if (userAccountType === 'user') {
      if (profile.userProfile?.homeImgUrl) {
        source = { uri: profile.userProfile?.homeImgUrl }
      } else {
        source = require('@images/home/home-top-bg.png')
      }
    } else {
      if (profile.partnerProfile?.homeImgUrl) {
        source = { uri: profile.partnerProfile.homeImgUrl }
      } else {
        source = require('@images/home/bg-home-all-nomatch.png')
      }
    }
    return (
      <ImageBackground
        source={source}
        style={this.props.style}
        blurRadius={this.props.blurRadius}>
        <SafeAreaView edges={['top']} />
        <View style={{ height: 115 }} />
      </ImageBackground>
    )
  }

  render() {
    return this.backgroundImage()
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    profile: state.profile,
  }
}

export default connect(mapStateToProps)(HomeBackgroundImage)

export const styles = StyleSheet.create({})
