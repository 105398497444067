import FullWidthImage from '@components/FullWidthImage'
import NavigationService from '@lib/NavigationService'
import SpotlightTourContext from '@lib/SpotlightTour/SpotlightTourContext'
import { useContext } from 'react'
import * as Animatable from 'react-native-animatable'
import styled from 'styled-components/native'

const StyledTopContainer = styled(Animatable.View)`
  /* flex: 1; */
  align-items: center;
  margin-bottom: -10px;
`

const StyledBottomContainer = styled(Animatable.View)`
  /* flex: 1; */
  align-items: center;
  margin-top: -10px;
`

const StyledAvatarContainer = styled(Animatable.View)`
  flex: 1;
  align-items: center;
  margin-top: -5px;
`

const StyledImage = styled(FullWidthImage)`
  width: 92%;
`

const StyledButtonsView = styled.View`
  position: absolute;
  flex-direction: row;
  width: ${wp(78)}px;
  margin-horizontal: ${wp(10)}px;
  bottom: ${wp(8)}px;
  justify-content: space-between;
`

const StyledLookAgainButton = styled.TouchableOpacity`
  border-radius: 5px;
  width: ${wp(37)}px;
  height: 30px;
  border-width: 1px;
  border-color: #3fc515;
  background-color: white;
  align-items: center;
  justify-content: center;
`

const StyledLookAgainText = styled.Text`
  font-size: 14px;
  font-weight: normal;
  color: #3fc515;
`

const StyledStartText = styled.Text`
  font-size: 14px;
  font-weight: normal;
  color: white;
`
const StyledStartButton = styled.TouchableOpacity`
  border-radius: 5px;
  width: ${wp(37)}px;
  height: 30px;
  border-width: 1px;
  border-color: #3fc515;
  background-color: #3fc515;
  align-items: center;
  justify-content: center;
`

const StyledSideMenuContainer = styled(Animatable.View)`
  margin-top: -5px;
  padding-left: 5px;
`

const StyledSideMenuBalloon = styled.Image.attrs({
  resizeMode: 'contain',
})`
  width: 200px;
  height: 70px;
`

export type TutorialTipsBalloonProps = { spotId: string; step: number }

const TutorialTipsBalloon = ({ spotId, step }: TutorialTipsBalloonProps) => {
  const { nextStep, setStep, finishSpotlightTour } =
    useContext(SpotlightTourContext)

  if (spotId === 'tab2') {
    return (
      <StyledTopContainer
        animation="fadeIn"
        pointerEvents="none"
        delay={800}
        duration={300}>
        <StyledImage source={require('@images/tour_balloon1.png')} />
      </StyledTopContainer>
    )
  } else if (spotId === 'tab3') {
    return (
      <StyledTopContainer
        animation="fadeIn"
        pointerEvents="none"
        delay={800}
        duration={300}>
        <StyledImage source={require('@images/tour_balloon2.png')} />
      </StyledTopContainer>
    )
  } else if (spotId === 'tab4') {
    return (
      <StyledTopContainer
        animation="fadeIn"
        pointerEvents="none"
        delay={800}
        duration={300}>
        <StyledImage source={require('@images/tour_balloon3.png')} />
      </StyledTopContainer>
    )
  } else if (spotId === 'tab5') {
    return (
      <StyledTopContainer
        animation="fadeIn"
        pointerEvents="none"
        delay={800}
        duration={300}>
        <StyledImage source={require('@images/tour_balloon4.png')} />
      </StyledTopContainer>
    )
  } else if (spotId === 'menuButton') {
    return (
      <StyledSideMenuContainer>
        <StyledSideMenuBalloon
          source={require('@images/tour_balloon_sidemenu.png')}
        />
      </StyledSideMenuContainer>
    )
  } else if (spotId === 'avatar') {
    return (
      <StyledAvatarContainer pointerEvents="none">
        <StyledImage source={require('@images/tour_balloon5.png')} />
      </StyledAvatarContainer>
    )
  } else if (spotId === 'avatarBottom') {
    return (
      <StyledAvatarContainer
        animation="fadeIn"
        pointerEvents="none"
        delay={800}
        duration={300}>
        <StyledImage source={require('@images/tour_balloon6.png')} />
      </StyledAvatarContainer>
    )
  } else if (spotId === 'tutorial') {
    return (
      <StyledTopContainer
        animation="fadeIn"
        pointerEvents="box-none"
        delay={800}
        duration={300}>
        <StyledImage source={require('@images/tour_balloon_last.png')} />
        <StyledButtonsView>
          <StyledLookAgainButton
            onPress={() => {
              NavigationService.closeDrawer()
              setStep(1)
            }}>
            <StyledLookAgainText>もう1回見る</StyledLookAgainText>
          </StyledLookAgainButton>
          <StyledStartButton onPress={nextStep}>
            <StyledStartText>はじめる</StyledStartText>
          </StyledStartButton>
        </StyledButtonsView>
      </StyledTopContainer>
    )
  }
  // Transaction
  else if (spotId === 'transactionRow1') {
    return (
      <StyledBottomContainer
        pointerEvents="none"
        animation="fadeIn"
        duration={300}>
        <StyledImage source={require('@images/transaction_balloon1.png')} />
      </StyledBottomContainer>
    )
  } else if (spotId === 'transactionRow2') {
    return (
      <StyledTopContainer pointerEvents="none">
        <StyledImage source={require('@images/transaction_balloon2.png')} />
      </StyledTopContainer>
    )
  } else if (spotId === 'transactionRow3') {
    return (
      <StyledTopContainer pointerEvents="none">
        <StyledImage source={require('@images/transaction_balloon3.png')} />
      </StyledTopContainer>
    )
  } else if (spotId === 'transactionRow4') {
    return (
      <StyledTopContainer pointerEvents="none">
        <StyledImage source={require('@images/transaction_balloon4.png')} />
      </StyledTopContainer>
    )
  } else if (spotId === 'CommonHeaderHelpIconSpot' && step !== 1) {
    return (
      <StyledBottomContainer pointerEvents="box-none">
        <StyledImage source={require('@images/transaction_balloon_last.png')} />
        <StyledButtonsView>
          <StyledLookAgainButton onPress={() => setStep(3)}>
            <StyledLookAgainText>もう1回見る</StyledLookAgainText>
          </StyledLookAgainButton>
          <StyledStartButton onPress={nextStep}>
            <StyledStartText>説明をとじる</StyledStartText>
          </StyledStartButton>
        </StyledButtonsView>
      </StyledBottomContainer>
    )
  } else if (spotId === 'CommonHeaderHelpIconSpot' && step === 1) {
    // 2回目表示以降のstep1
    return (
      <StyledBottomContainer pointerEvents="box-none">
        <StyledImage
          source={require('@images/transaction_balloon_first.png')}
        />
        <StyledButtonsView>
          <StyledLookAgainButton onPress={finishSpotlightTour}>
            <StyledLookAgainText>とじる</StyledLookAgainText>
          </StyledLookAgainButton>
          <StyledStartButton onPress={() => setStep(3)}>
            <StyledStartText>説明を見る</StyledStartText>
          </StyledStartButton>
        </StyledButtonsView>
      </StyledBottomContainer>
    )
  }
  return null
}

export default TutorialTipsBalloon
