import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'
import { StyledCommonPanel } from '../styles'
import SharePanelContent from './Content'

const StyledPanel = styled(StyledCommonPanel)`
  padding-top: ${wp(7)}px;
  padding-bottom: ${wp(7)}px;
  padding-horizontal: ${wp(3)}px;
`

const SharePanel = (props: TouchableOpacityProps) => {
  return (
    <StyledPanel>
      <SharePanelContent />
    </StyledPanel>
  )
}

export default SharePanel
