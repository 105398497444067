import { Image } from '@components'
import AdView from '@components/AdView'
import AppButton from '@components/AppButton'
import CategoryImage from '@components/CategoryImage'
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons'
import AccountManager from '@lib/AccountManager'
import { accountModeSelector } from '@lib/AccountManager/actions'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import { MobileSafari, WebApp } from '@lib/Env'
import { FAIcon } from '@lib/FAIcon'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import Financial from '@lib/api/Financial'
import { useIsFocused } from '@react-navigation/native'
import store from '@redux/store'
import { EventEmitter } from 'events'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dimensions, Modal, StyleSheet, Text, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { navigatePremiumPlanLP } from './PremiumPlan'
import { TransactionDetailScreenNavigationParams } from './TransactionDetailScreen'
import { updateMainScreenSelectedTab } from './redux/actions'
import { mainScreenSelectedTabSelector } from './redux/selector'

const eventEmitter = new EventEmitter()

export type ShowCreatedTransactionDialogProps = {
  isCreate: boolean
  newTransactionHandler: (props: {
    isCopy?: boolean
    isPremiumError?: boolean
  }) => void
} & TransactionDetailScreenNavigationParams

type EmitShowState = {
  detail: ShowCreatedTransactionDialogProps
}

export const showCreatedTransactionDialog = (
  detail: ShowCreatedTransactionDialogProps,
) => {
  const params: EmitShowState = { detail }
  eventEmitter.emit('show', params)
}

const CreatedTransactionDialog = () => {
  const dispatch = useDispatch()
  const isFocused = useIsFocused()

  const selectedTab = useSelector(mainScreenSelectedTabSelector)
  const accountMode = useSelector(accountModeSelector)

  const [isVisible, setIsVisible] = useState(false)
  const [detail, setDetail] = useState<ShowCreatedTransactionDialogProps>()

  const close = useCallback(() => {
    setIsVisible(false)
    NavigationService.goBack()
  }, [])

  const goToTransactions = useCallback(() => {
    setIsVisible(false)

    AccountManager.updateAccountMode(
      detail?.isTransactionShared ? 'family' : 'user',
    )

    if (selectedTab !== 'TransactionsTab') {
      dispatch(updateMainScreenSelectedTab('TransactionsTab'))
    } else {
      NavigationService.goBack()
      NavigationService.popToTop()
    }

    if (accountMode === 'family') {
      TrackingUtils.repro.track('【Tap】family_view History', 'Tap')
    } else {
      // 個人はない
    }
  }, [accountMode, detail?.isTransactionShared, dispatch, selectedTab])

  const newTransaction = useCallback(
    (isCopy?: boolean) => {
      setIsVisible(false)
      detail?.newTransactionHandler({ isCopy })

      if (accountMode === 'family') {
        TrackingUtils.repro.track('【Tap】family_continue to create', 'Tap')
      } else {
        // 個人はない
      }
    },
    [detail, accountMode],
  )

  const category = useMemo(
    () =>
      detail?.subCategoryId
        ? CategoryManager.categoryBySubCategoryId(detail?.subCategoryId)
        : undefined,
    [detail?.subCategoryId],
  )

  useEffect(() => {
    eventEmitter.on('show', ({ detail }: EmitShowState) => {
      console.log('showCreatedTransactionDialog', { isFocused, detail })
      if (isFocused) {
        setDetail(detail)
        setIsVisible(true)
      }
    })

    return () => {
      eventEmitter.removeAllListeners()
    }
  }, [isFocused])

  // if (!detail) return null

  return (
    <Modal animationType="fade" transparent={true} visible={isVisible}>
      <ScrollView style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
        <View style={styles.container}>
          <View style={styles.bodyPopup}>
            <Text style={styles.titleText}>
              {detail?.isCreate
                ? '取引明細を作成しました'
                : '取引明細を更新しました'}
            </Text>
            <View style={styles.itemsView}>
              <View style={styles.itemView}>
                <Image style={styles.icon} source={Icon.Calendar} />
                <Text style={styles.itemText}>{detail?.usedDate}</Text>
              </View>
              <View style={styles.itemView}>
                <Image style={styles.icon} source={Icon.Yen} />
                <Text style={styles.itemText}>{detail?.amount}</Text>
              </View>
              <View style={styles.itemView}>
                <CategoryImage categoryId={category?.categoryId} />
                <Text style={styles.itemText}>{category?.name}</Text>
              </View>
              <View style={styles.itemView}>
                <Image
                  style={{
                    width: 26,
                    height: 20,
                    resizeMode: 'contain',
                    tintColor: '#888888',
                  }}
                  source={
                    detail?.isTransactionShared
                      ? require('@assets/images/icons/icon-couple-small.png')
                      : require('@assets/images/icons/icon-woman-small.png')
                  }
                />
                <Text style={styles.itemText}>
                  {detail?.isTransactionShared
                    ? '家族画面に表示'
                    : '個人画面に表示'}
                </Text>
              </View>
            </View>
            {store.getState().profile.userProfile?.rank === 'free' && (
              <View
                style={{
                  backgroundColor: '#f0f0f0',
                  marginBottom: 20,
                }}>
                <AdView
                  type="Transaction300x250"
                  width={wp(80)}
                  onPressHiddenButton={() => {
                    NavigationService.goBack()
                    navigatePremiumPlanLP({ planCode: '001' })
                  }}
                />
              </View>
            )}
            <View style={styles.wrapButton}>
              {detail?.isCreate ? (
                <>
                  <AppButton
                    type="secondary"
                    title="履歴を確認"
                    style={[styles.appButton, { marginHorizontal: 4 }]}
                    textStyle={styles.appButtonText}
                    onPress={goToTransactions}
                  />
                  <AppButton
                    type="secondary"
                    title="コピーして作成"
                    style={[styles.appButton, { marginHorizontal: 4 }]}
                    textStyle={styles.appButtonText}
                    onPress={async () => {
                      const response = await Financial.confirmCopyTransaction()
                      if (response.ok) {
                        if (!detail) return
                        close()

                        setTimeout(() => {
                          NavigationService.navigate(
                            'CreateTransactionDetail',
                            {
                              ...detail,
                              isCopy: true,
                              usedDate: moment().format('YYYY-MM-DD'),
                            },
                          )
                        }, 0) // カレンダー画面を開いていると画面遷移がうまくいかないためsetTimeoutで別スレッドにする

                        if (accountMode === 'family') {
                          TrackingUtils.repro.track(
                            '【Tap】family_copy and create',
                            'Tap',
                          )
                        } else {
                          // 個人はない
                        }
                      } else {
                        setIsVisible(false)
                        detail?.newTransactionHandler({
                          isCopy: true,
                          isPremiumError: true,
                        })
                      }
                    }}
                  />
                  <AppButton
                    title="続けて作成"
                    style={[styles.appButton, { marginLeft: 4 }]}
                    textStyle={styles.appButtonText}
                    onPress={() => newTransaction()}
                  />
                </>
              ) : (
                <AppButton
                  title="閉じる"
                  style={styles.appButton}
                  textStyle={styles.appButtonText}
                  onPress={close}
                />
              )}
            </View>
            {detail?.isCreate && (
              <StyledCloseButton onPress={close}>
                <FAIcon
                  icon={faCircleXmark}
                  size={40}
                  webSize="2x"
                  color={Color.Primary}
                />
              </StyledCloseButton>
            )}
          </View>
        </View>
      </ScrollView>
    </Modal>
  )
}

const StyledCloseButton = styled.TouchableOpacity({
  position: 'absolute',
  top: -10,
  right: -10,
  backgroundColor: 'white',
  borderRadius: 20,
  // padding:
})

const styles = StyleSheet.create({
  container: {
    minHeight: WebApp
      ? MobileSafari
        ? '-webkit-fill-available'
        : '100vh'
      : Dimensions.get('window').height,
    justifyContent: 'center',
  },
  titleText: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333333',
    width: '100%',
    textAlign: 'center',
  },
  itemsView: {
    borderColor: '#eeeeee',
    borderWidth: 1,
    borderRadius: 6,
    marginHorizontal: 20,
    marginTop: 15,
    marginBottom: 20,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  itemView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  itemText: {
    marginLeft: 5,
    color: '#3a3a3a',
    fontSize: 14,
    fontWeight: 'normal',
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  bodyPopup: {
    backgroundColor: Color.White,
    borderRadius: 10,
    margin: wp('10%'),
    // flex: 1,
  },
  welcome: {
    color: '#69C900',
    fontSize: wp('5%'),
    textAlign: 'center',
    marginTop: wp('2%'),
    fontWeight: 'bold',
  },
  textContent: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: wp('3%'),
    fontWeight: 'normal',
  },
  button: {
    backgroundColor: Color.Primary,
    flex: 1,
    height: 50,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleButton: {
    color: Color.White,
    fontSize: wp('4%'),
    fontWeight: 'bold',
  },
  iconClose: {
    width: 22,
    height: 22,
  },
  wrapIconClose: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  imgNoticeDialog: {
    width: '100%',
    height: wp('30%'),
    marginTop: 10,
  },
  seeYou: {
    color: '#666666',
    fontSize: wp('4%'),
    fontWeight: 'normal',
  },
  appButton: {
    flex: 1,
    height: 40,
  },
  appButtonText: {
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: 16,
  },
  wrapButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    backgroundColor: Color.White,
    paddingBottom: 20,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
})

export default CreatedTransactionDialog
