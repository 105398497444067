import { UserAccountType } from '@interfaces/Account'
import { PlType } from '@interfaces/Profile'
import { RootState } from '@redux/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type PeriodSetting = {
  plPeriodDate?: number
  plType?: PlType
}

export type State = {
  user: PeriodSetting
  family: PeriodSetting
}

type Reducer = {
  setPeriodSetting: (
    state: State,
    action: PayloadAction<{
      userAccountType: UserAccountType
      settings: PeriodSetting
    }>,
  ) => void
}

const initialState: State = {
  user: { plPeriodDate: 1, plType: '' },
  family: { plPeriodDate: 1, plType: '' },
}

const slice = createSlice<State, Reducer>({
  name: 'settingPeriod',
  initialState,
  reducers: {
    setPeriodSetting: (state, { payload }) => {
      state[payload.userAccountType] = payload.settings
    },
  },
})

export const { setPeriodSetting } = slice.actions
export const selectPeriodSetting = (root: RootState) => root.settingPeriod

export default slice.reducer
