import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CalculatorInput from '@components/Calculator/CalculatorInput'
import CheckBox from '@components/CheckBox'
import CommonHeader from '@components/CommonHeader'
import FinancialAccountIcon from '@components/FinancialAccountIcon'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useState } from 'react'
import { Platform, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

const CreateWalletScreen: React.FC<
  StackScreenProps<RootStackParamList, 'CreateWallet'>
> = ({ route }) => {
  const [name, setName] = useState('')
  const [balance, setBalance] = useState(0)
  const [categoryType, setCategoryType] = useState<
    'bank' | 'card' | 'emoney' | 'stock' | 'wallet'
  >()

  const onPressCreateButton = useCallback(async () => {
    try {
      if (!categoryType) {
        CommonDialog.showMessage('カテゴリーが選択されていません')
        return
      }

      showAppButtonIndicator()

      await FinancialManager.createWallet({
        userAccountType:
          route.params?.userAccountType || store.getState().account.accountMode,
        name,
        balance: categoryType === 'card' ? 0 : balance,
        type: categoryType,
      })

      CommonDialog.showMessage('お財布を登録しました。', () => {
        if (route.params?.goBack) {
          route.params.goBack()
        } else {
          NavigationService.pop(2)
        }
      })
    } catch (error) {
      CommonDialog.showError({
        error,
        message: 'お財布の登録に失敗しました。',
      })
    } finally {
      hideAppButtonIndicator()
    }
  }, [balance, categoryType, name, route.params])

  return (
    <StyledRootView>
      <CommonHeader title="財布を作成" />
      <StyledScrollView>
        <StyledWalletNameView>
          <StyledWalletTitleText>財布の名前</StyledWalletTitleText>
        </StyledWalletNameView>
        <StyledTextInput
          placeholder="わたしの財布"
          placeholderTextColor={Color.PlaceHolderText}
          autoFocus={Platform.OS === 'android'}
          onChangeText={(name) => setName(name)}
          value={name}
          returnKeyType="done"
          style={{
            color:
              Platform.OS === 'web' && !name
                ? Color.PlaceHolderText
                : undefined,
          }}
        />
        {categoryType !== 'card' && (
          <StyledBalanceView>
            <StyledBalanceTitleText>はじめの残高</StyledBalanceTitleText>
            <StyledCalculatorInput
              value={balance}
              minusInput
              onChange={(balance) => setBalance(balance)}
            />
          </StyledBalanceView>
        )}
        <StyleCategoryView>
          <StyledCategoryTitleText>
            この財布の金融カテゴリー
          </StyledCategoryTitleText>
          <StyledCategoryDetailText>
            財布をどの金融カテゴリーに表示するのかを選択できます。{'\n'}
            （例）銀行口座に設定すると、登録口座の一覧で銀行口座カテゴリーに表示されます。
          </StyledCategoryDetailText>
          <StyleCategoryItemView onPress={() => setCategoryType('bank')}>
            <FinancialAccountIcon financialAccountType="bank" />
            <StyleCategoryNameText>銀行口座</StyleCategoryNameText>
            <CheckBox isOn={categoryType === 'bank'} disabled />
          </StyleCategoryItemView>
          <StyleCategoryItemView onPress={() => setCategoryType('stock')}>
            <FinancialAccountIcon financialAccountType="stock" />
            <StyleCategoryNameText>証券/投資信託</StyleCategoryNameText>
            <CheckBox isOn={categoryType === 'stock'} disabled />
          </StyleCategoryItemView>
          <StyleCategoryItemView onPress={() => setCategoryType('card')}>
            <FinancialAccountIcon financialAccountType="card" />
            <StyleCategoryNameText>カード</StyleCategoryNameText>
            <CheckBox isOn={categoryType === 'card'} disabled />
          </StyleCategoryItemView>
          <StyleCategoryItemView onPress={() => setCategoryType('emoney')}>
            <FinancialAccountIcon financialAccountType="emoney" />
            <StyleCategoryNameText>電子マネー</StyleCategoryNameText>
            <CheckBox isOn={categoryType === 'emoney'} disabled />
          </StyleCategoryItemView>
          <StyleCategoryItemView onPress={() => setCategoryType('wallet')}>
            <FinancialAccountIcon financialAccountType="wallet" />
            <StyleCategoryNameText>財布（現金管理）</StyleCategoryNameText>
            <CheckBox isOn={categoryType === 'wallet'} disabled />
          </StyleCategoryItemView>
        </StyleCategoryView>
        <StyledDetailView>
          <TouchableOpacity onPress={navigateWeb}>
            <StyledDetailButtonText>
              財布の詳しい説明はこちら
            </StyledDetailButtonText>
          </TouchableOpacity>
        </StyledDetailView>
      </StyledScrollView>
      <StyledFooterView>
        {name.trim().length === 0 && (
          <StyledFooterTextView>
            <StyledCautionIcon />
            <StyledFooterText>未入力の項目があります</StyledFooterText>
          </StyledFooterTextView>
        )}
        <StyledAppButton
          title="作成する"
          onPress={onPressCreateButton}
          disabled={name.trim().length === 0}
        />
      </StyledFooterView>
    </StyledRootView>
  )
}

const navigateWeb = () => {
  NavigationService.navigate('MainMediaWebView', {
    uri: 'https://www.osidori.co/app/article.php?p=2459',
    isShowOriginalHeader: true,
  })
}

const StyledRootView = styled.View({
  flex: 1,
})

const StyledScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
})({
  flex: 1,
})

const StyledWalletNameView = styled.View({})

const StyledWalletTitleText = styled.Text({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#666666',
})

const StyledBalanceTitleText = styled.Text({
  fontSize: 16,
  fontWeight: 'normal',
  color: '#3a3a3a',
})

const StyledCategoryTitleText = styled.Text({
  fontSize: 14,
  fontWeight: 'bold',
  color: Color.DefaultText,
  marginBottom: 4,
})

const StyledCategoryDetailText = styled.Text({
  fontSize: 12,
  fontWeight: 'normal',
  color: Color.DefaultText,
  marginBottom: 10,
})

const StyledTextInput = styled.TextInput({
  fontSize: 18,
  height: 44,
  borderBottomColor: '#ddd',
  borderBottomWidth: 1,
})

const StyledBalanceView = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: Platform.OS === 'ios' ? 10 : 0,
  marginTop: 10,
  height: 44,
  borderBottomColor: '#ddd',
  borderBottomWidth: 1,
})

const StyledCalculatorInput = styled(CalculatorInput).attrs({
  prefixTextStyle: {
    color: '#3a3a3a',
    fontSize: 22,
    fontWeight: '500',
    marginRight: 0,
  },
  textStyle: {
    color: '#3a3a3a',
    fontSize: 22,
    fontWeight: '500',
  },
})({
  flex: 1,
  width: undefined,
})

const StyledDetailView = styled.View({
  marginTop: 20,
  alignItems: 'flex-end',
})

const StyledDetailButtonText = styled.Text({
  color: 'rgb(88, 199, 54)',
  fontSize: 14,
  fontWeight: 'normal',
})

const StyledFooterView = styled.View({
  paddingHorizontal: 16,
  marginBottom: 20,
})

const StyledFooterTextView = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledFooterText = styled.Text({
  color: Color.DefaultText,
  fontSize: 14,
  fontWeight: 'normal',
})

const StyledAppButton = styled(AppButton).attrs({
  textStyle: {
    fontWeight: 'bold',
  },
})({
  marginTop: 8,
})

const StyledCautionIcon = styled.Image.attrs({
  source: require('@images/icons/icon-caution.png'),
})({
  marginRight: 5,
  marginBottom: 1,
  width: 20,
  height: 20,
})

const StyleCategoryView = styled.View({
  marginTop: 30,
})

const StyleCategoryItemView = styled.Pressable({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingVertical: 10,
  borderBottomColor: '#ddd',
  borderBottomWidth: 1,
  marginHorizontal: -20,
  paddingHorizontal: 20,
})

const StyleCategoryNameText = styled.Text({
  flex: 1,
  marginLeft: 10,
  fontSize: 16,
  fontWeight: 'normal',
  color: '#3a3a3a',
})

export default CreateWalletScreen
