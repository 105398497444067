import { navigationFromReduxState } from '@lib/DeepLinkService/DeeplinkNavigationParams'
import { NativeApp } from '@lib/Env'
import { SpotlightTourProvider } from '@lib/SpotlightTour'
import { RootStackParamList } from '@navigation/Screens'
import { createDrawerNavigator } from '@react-navigation/drawer'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'
import PremiumPlanForPremiumUserScreen from '@Screen/Main/PremiumPlan/PremiumPlanForPremiumUserScreen'
import MediaWebViewScreen from '@Screen/MediaWebViewScreen'
import WebViewScreen from '@Screen/WebViewScreen'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CategoryColorPickerScreen from './CategoryColorPickerScreen'
import CategoryIconPickerScreen from './CategoryIconPickerScreen'
import CreateCategoryTopScreen from './CreateCategoryTopScreen'
import EditCategoryTopScreen from './EditCategory/EditCategoryTopScreen'
import EditSubCategoryScreen from './EditCategory/EditSubCategoryScreen'
import FPScreen from './FPScreen'
import CreateWalletScreen from './Home/CreateWalletScreen'
import DeleteFinancialAccountsScreen from './Home/DeleteFinancialAccountsScreen'
import DeleteGoalsScreen from './Home/DeleteGoalsScreen'
import TermTransactionScreen from './Home/TermTransactionScreen'
import HousingWebViewScreen from './HousingLPWebViewScreen'
import InsuranceWebViewScreen from './InsuranceWebViewScreen'
import MainTabScreen from './MainTabScreen'
import PaymentSourceScreen from './PaymentSourceScreen'
import PremiumPlanSelectScreen from './PremiumPlan/PremiumPlanSelectScreen'
import PremiumPlanWebLPScreen from './PremiumPlan/PremiumPlanWebLPScreen'
import { setTabBarVisible } from './redux/actions'
import SideMenu from './SideMenu'
import SortFinanceAccountScreen from './SortFinance/SortFinanceAccountScreen'
import SortFinanceCategoryScreen from './SortFinance/SortFinanceCategoryScreen'
import TransactionDetailScreen from './TransactionDetailScreen'
import Transactions from './Transactions'
import TransferSelectFinancialAccountScreen from './TransferSelectFinancialAccountScreen'
import TransferSelectTransactionScreen from './TransferSelectTransactionScreen'
import WeeklyReportWebViewScreen from './WeeklyReportWebViewScreen'

const Stack = createStackNavigator<RootStackParamList>()
const Drawer = createDrawerNavigator()

const DrawerComponent = () => (
  <Drawer.Navigator drawerContent={() => <SideMenu />}>
    <Drawer.Screen
      name="MainTab"
      component={MainTabScreen}
      options={{ headerShown: false }}
      // options={{ headerShown: false, drawerType: 'front' }}
    />
  </Drawer.Navigator>
)

const Main = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTabBarVisible(true))
    navigationFromReduxState()
  }, [dispatch])

  return (
    <SpotlightTourProvider>
      <Stack.Navigator
        screenOptions={{
          gestureEnabled: false,
          headerShown: false,
          ...(NativeApp ? TransitionPresets.ModalSlideFromBottomIOS : {}),
        }}>
        <Stack.Screen name="Drawer" component={DrawerComponent} />
        <Stack.Screen
          name="PremiumPlanForPremiumUser"
          component={PremiumPlanForPremiumUserScreen}
        />
        <Stack.Screen
          name="PremiumPlanWebLP"
          component={PremiumPlanWebLPScreen}
        />
        <Stack.Screen name="FP" component={FPScreen} />
        <Stack.Screen
          name="CreateTransactionDetail"
          component={TransactionDetailScreen}
          initialParams={{ financialAccountType: 'manually_created' }}
        />
        <Stack.Screen
          name="DeleteFinancialAccounts"
          component={DeleteFinancialAccountsScreen}
        />
        <Stack.Screen name="DeleteGoals" component={DeleteGoalsScreen} />
        <Stack.Screen
          name="CreateTermTransaction"
          component={TermTransactionScreen}
        />
        <Stack.Screen
          name="CategoryPickerTop"
          component={EditCategoryTopScreen}
        />
        <Stack.Screen
          name="CategoryPickerSub"
          component={EditSubCategoryScreen}
        />
        <Stack.Screen
          name="CreateCategoryTop"
          component={CreateCategoryTopScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="TransferSelectFinancialAccount"
          component={TransferSelectFinancialAccountScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="TransferSelectTransaction"
          component={TransferSelectTransactionScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="CreateTermTransactionTop"
          component={TermTransactionScreen}
        />
        <Stack.Screen
          name="PremiumPlanSelect"
          component={PremiumPlanSelectScreen}
        />
        <Stack.Screen name="TransactionsTop" component={Transactions} />
        <Stack.Screen name="MainMediaWebView" component={MediaWebViewScreen} />
        <Stack.Screen
          name="WeeklyReportWebView"
          component={WeeklyReportWebViewScreen}
        />
        {/* SlideFromRightIOS */}
        <Stack.Screen
          name="CategoryPickerSubSlide"
          // component={CategoryPickerSub}
          component={EditSubCategoryScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="CategoryIconPicker"
          component={CategoryIconPickerScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="CategoryColorPicker"
          component={CategoryColorPickerScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="PaymentSource"
          component={PaymentSourceScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="CreateWallet"
          component={CreateWalletScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="InsuranceWebView"
          component={InsuranceWebViewScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="HousingWebView"
          component={HousingWebViewScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="EditCategoryTop"
          component={EditCategoryTopScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="EditSubCategory"
          component={EditSubCategoryScreen}
          options={
            NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
          }
        />
        <Stack.Screen
          name="SortFinanceCategory"
          component={SortFinanceCategoryScreen}
        />
        <Stack.Screen
          name="SortFinanceAccount"
          component={SortFinanceAccountScreen}
        />
        <Stack.Screen name="MainStackWebView" component={WebViewScreen} />
      </Stack.Navigator>
    </SpotlightTourProvider>
  )
}

export default Main
