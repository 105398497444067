import { UserAccountType } from '@interfaces/Account'
import { ActionType, Actions, CalendarUserAccountType } from './types'

export const updateCalenderDate = (
  calendarUserAccountType: CalendarUserAccountType,
  yearMonth: moment.Moment,
  startDate: moment.Moment,
  endDate: moment.Moment
): Actions => ({
  type: ActionType.UpdateCalenderDate,
  calendarUserAccountType,
  yearMonth,
  startDate,
  endDate,
})

export const updateCalendarUserAccountType = (
  calendarUserAccountType: CalendarUserAccountType
): Actions => ({
  type: ActionType.UpdateCalendarUserAccountType,
  calendarUserAccountType,
})

export const updateCalendarTotalPaymentAmount = (
  userAccountType: UserAccountType | 'all',
  totalPaymentAmount: number
): Actions => ({
  type: ActionType.UpdateCalendarTotalPaymentAmount,
  userAccountType,
  totalPaymentAmount,
})

export const updateCalendarTotalReceiptAmount = (
  userAccountType: UserAccountType | 'all',
  totalReceiptAmount: number
): Actions => ({
  type: ActionType.UpdateCalendarTotalReceiptAmount,
  userAccountType,
  totalReceiptAmount,
})

export const updateShowTransactions = (
  isShowTransactions: boolean
): Actions => ({
  type: ActionType.UpdateShowTransactions,
  isShowTransactions,
})

export const updateNeedUpdateCalendarUserAccountType = (
  isNeedUpdateCalendarUserAccountType: boolean
): Actions => ({
  type: ActionType.UpdateNeedUpdateCalendarUserAccountType,
  isNeedUpdateCalendarUserAccountType,
})
