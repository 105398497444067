import API, { APIResponse } from './index'

export interface AppStoreReceiptVerificationProps {
  receiptData: string
  sandBox?: boolean
}

export interface GooglePlayReceiptVerificationProps {
  productId: string
  purchaseToken: string
}

export type PurchaseConfirmProps = {
  platform: 'app-store' | 'google-play'
  productId: string
}

class Purchase {
  appStoreReceiptVerification = (
    props: AppStoreReceiptVerificationProps,
  ): Promise<APIResponse> => {
    return API.request(
      'POST',
      '/api/v2/purchases/app-store-receipt-verification',
      props,
      true,
    )
  }

  googlePlayReceiptVerification = (
    props: GooglePlayReceiptVerificationProps,
  ): Promise<APIResponse> => {
    return API.request(
      'POST',
      '/api/v2/purchases/google-play-receipt-verification',
      props,
      true,
    )
  }

  confirm = ({ platform, productId }: PurchaseConfirmProps) => {
    return API.request(
      'GET',
      `/api/v2/purchases/confirm/${platform}`,
      { productId },
      true,
    )
  }
}

export default new Purchase()
