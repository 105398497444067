import { ButtonProps, StyleProp, TextStyle, ViewStyle } from 'react-native'

export type Props = ButtonProps & {
  loadingKey?: string
  type?: 'default' | 'white' | 'primary' | 'secondary' | 'secondary-min'
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

export const AppButtonDefaultLoadingKey = 'AppButtonDefaultLoadingKey'
