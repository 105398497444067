// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import ActivateManager from '@lib/ActivateManager'
import CategoryManager from '@lib/CategoryManager'
import CommonDialog from '@lib/CommonDialog'
import Osidori from '@lib/Osidori'
import TrackingUtils from '@lib/TrackingUtils'
import { updateRootScreen } from '@redux/actions'
import store from '@redux/store'

export interface ActivateParams {
  token: string
}

export const activate = async (params: ActivateParams) => {
  try {
    await ActivateManager.activateUser(params.token)
    await CategoryManager.fetchCategories({ accountType: 'user' })
    await CategoryManager.fetchCategories({ accountType: 'family' })
    // NavigationService.navigate('Welcome')
    store.dispatch(updateRootScreen('Welcome'))
    TrackingUtils.repro.track('【Screen】welcome', 'Screen')
    TrackingUtils.adjust.trackEvent({ ios: '10u7nw', android: 'jbyzn7' })
  } catch (error) {
    CommonDialog.showError({
      error,
      message: '登録に失敗しました。',
      onPress: () => Osidori.start(),
    })
  }
}
