import Color from '@lib/Color'
import { TouchableOpacityProps } from 'react-native'
import { Edge, SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'

export type AddCaregoryButtonProps = {
  title: string
  edges?: ReadonlyArray<Edge>
} & TouchableOpacityProps

export const AddCaregoryButton = ({
  title,
  onPress,
  style,
  edges,
}: AddCaregoryButtonProps) => {
  return (
    <StyledSafeAreaView edges={edges ?? ['bottom']}>
      <StyledTouchableOpacity onPress={onPress} style={style}>
        <StyledText>{title}</StyledText>
      </StyledTouchableOpacity>
    </StyledSafeAreaView>
  )
}

const StyledSafeAreaView = styled(SafeAreaView)({
  position: 'absolute',
  bottom: 10,
  right: 10,
  alignSelf: 'flex-end',
})

const StyledTouchableOpacity = styled.TouchableOpacity({
  borderRadius: 22,
  paddingHorizontal: 15,
  backgroundColor: Color.OsidoriGreen,
  height: 44,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  shadowColor: 'rgba(0,0,0,0.5)',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 4,
})

const StyledText = styled.Text({
  fontSize: 14,
  fontWeight: 'bold',
  color: Color.White,
})
