import { PremiumView2 } from '@Screen/Main/EditCategory/functions'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import CommonHeader from '@components/CommonHeader'
import CommonListItem, { CommonListItemProps } from '@components/CommonListItem'
import { PremiumView } from '@components/PremiumView'
import { AccountTrackerAddType } from '@interfaces/AccountTracker'
import AccountTrackerManager from '@lib/AccountTrackerManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, ListRenderItem, Platform } from 'react-native'
import styled from 'styled-components/native'

const FinancialAccountCooperationScreen: React.FC<
  StackScreenProps<RootStackParamList, 'FinancialAccountCooperation'>
> = ({ route }) => {
  const isFocused = useIsFocused()

  const [isPlanError, setIsPlanError] = useState(false)
  const [isDisplayPurchaseCompletedModal, setIsDisplayPurchaseCompletedModal] =
    useState(false)

  const renderItem: ListRenderItem<ListItemProps> = useCallback(
    ({
      item: { type, title, iconImageSource, detailText, borderTop, ...props },
    }) =>
      type === 'header' ? (
        <StyledItemView>
          {borderTop && <StyledItemBorderTopView />}
          <StyledItemHeaderView>
            {iconImageSource && <StyledItemImage source={iconImageSource} />}
            <StyledItemHeaderTextView>
              <StyledHeaderTitleText>{title}</StyledHeaderTitleText>
              <StyledHeaderDetailText>{detailText}</StyledHeaderDetailText>
            </StyledItemHeaderTextView>
          </StyledItemHeaderView>
        </StyledItemView>
      ) : (
        <StyledCommonListItem
          title={title}
          iconImageSource={iconImageSource}
          {...props}
        />
      ),
    [],
  )

  const addFinancialAccount = useCallback(
    async (addType: AccountTrackerAddType) => {
      try {
        await AccountTrackerManager.addFinancialAccount({
          addType,
          navigateBackScreen: route.name,
          backButtonTitle: '口座連携',
          share: store.getState().account.accountMode === 'family',
        })
      } catch (error) {
        if (error instanceof APIError) {
          if (error.response?.errorCode === '007002') {
            setIsPlanError(true)
            TrackingUtils.repro.track('【Screen】upper limit_finance', 'Screen')

            TrackingUtils.ga.pageview({
              page: 'Upperlimit-FinancialAccounts',
              title: '口座連携の上限の画面',
            })
            return
          } else if (error.response?.errorCode === '001012') {
            CommonDialog.unauthenticated(
              'メール記載のURLをクリックして完了すると、すべての機能が使えるようになります。',
            )
            return
          }
        }
        CommonDialog.showError({ error })
      }
    },
    [route.name],
  )

  const keyExtractor = useCallback(
    (item: ListItemProps, index: number) => item.title + '-' + index,
    [],
  )

  const items: ListItemProps[] = useMemo(
    () => [
      {
        type: 'header',
        title: '自動で連携',
        detailText:
          '銀行口座やカード等を連携すると、\n自動でデータをアプリに毎日反映できます。',
        iconImageSource: require('@images/icons/icon-financial-account-link.png'),
      },
      {
        type: 'item',
        title: '銀行口座',
        financialAccountType: 'bank',
        isDisplayArrow: true,
        onPress: () => addFinancialAccount('bank'),
      },
      {
        type: 'item',
        title: 'カード',
        financialAccountType: 'card',
        isDisplayArrow: true,
        onPress: () => addFinancialAccount('card'),
      },
      {
        type: 'item',
        title: '電子マネー',
        financialAccountType: 'emoney',
        isDisplayArrow: true,
        onPress: () => addFinancialAccount('iccard'),
      },
      {
        type: 'item',
        title: '証券/投資信託',
        financialAccountType: 'stock',
        isDisplayArrow: true,
        onPress: () => addFinancialAccount('invest'),
      },
      {
        type: 'header',
        title: '手動で登録',
        detailText:
          '手入力での管理に。現金・銀行の残高管理や、\nカード・QR決済等の利用履歴を登録し見える化\nできます。',
        iconImageSource: require('@images/icons/icon-financial-account-analog.png'),
        borderTop: true,
      },
      {
        type: 'item',
        title: '財布（現金管理、口座、カードなど）',
        financialAccountType: 'wallet',
        isDisplayArrow: true,
        onPress: () => NavigationService.navigate('CreateWallet'),
      },
    ],
    [addFinancialAccount],
  )

  useEffect(() => {
    if (store.getState().account.accountMode === 'family') {
      TrackingUtils.repro.track('【Screen】Family_category select', 'Screen')
    } else {
      TrackingUtils.repro.track('【Screen】Personal_category select', 'Screen')
    }

    const unsubscribe1 = signalOn('PURCHASE_COMPLETE', () => {
      setIsPlanError(false)
      if (isFocused) {
        setIsDisplayPurchaseCompletedModal(true)
      }
    })

    const unsubscribe2 = signalOn('CLOSED_PURCHASE_COMPLETE_MODAL', () => {
      setIsDisplayPurchaseCompletedModal(false)
    })

    return () => {
      unsubscribe1()
      unsubscribe2()
    }
  }, [isFocused])

  return (
    <StyledRootView>
      <CommonHeader
        title="口座連携"
        leftButtonTitle="口座"
        onPressLeftButton={() =>
          NavigationService.navigate('FinancialAccounts')
        }
      />
      <StyleMainView>
        <StyledFlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
        {isPlanError ? (
          <>
            {Platform.OS !== 'web' ? (
              <PremiumView
                showTopMargin={false}
                title="無料プランの上限になりました"
                message={
                  '※ 無料プランでは銀行・カード・電子マネー・証券を1アカウントにつき5つまで作成できます。'
                }
                premiumType="financial_account"
                onBackdropPress={() => {
                  setIsPlanError(false)
                }}
              />
            ) : (
              PremiumView2({
                image: require('@images/premium/limit_financial_account.png'),
                onPressShowDetailButton: () => {
                  TrackingUtils.repro.track('【Tap】upper limit_finance', 'Tap')
                  navigatePremiumPlanLP({ planCode: '001' })
                  TrackingUtils.repro.setScreenTrackingAfterPurchase(
                    '【Screen】purchase completed via upper limit_finance',
                  )
                },
              })
            )}
          </>
        ) : (
          isDisplayPurchaseCompletedModal && (
            <PremiumView
              showTopMargin={false}
              buttonTitle="さっそく登録する"
              image={require('@images/premium/financial_account_modal.png')}
              onPressShowDetailButton={() => {
                setIsDisplayPurchaseCompletedModal(false)
                sendSignal(
                  'CLOSED_PURCHASE_COMPLETE_MODAL',
                  'financial_account',
                )
              }}
            />
          )
        )}
      </StyleMainView>
    </StyledRootView>
  )
}

type ListItemProps = {
  type: 'header' | 'item'
  detailText?: string
  borderTop?: boolean
} & CommonListItemProps

const StyledRootView = styled.View({ flex: 1 })

const StyleMainView = styled.View({ flex: 1 })

const StyledFlatList = styled(FlatList<ListItemProps>)({
  backgroundColor: Color.LightGray,
})

const StyledItemView = styled.View({
  backgroundColor: 'white',
})

const StyledItemBorderTopView = styled.View({
  marginTop: 10,
  borderTopColor: 'rgb(240, 240, 240)',
  borderTopWidth: 1,
})

const StyledItemHeaderView = styled.View({
  flexDirection: 'row',
  marginTop: 5,
  marginLeft: 5,
  padding: 20,
})

const StyledItemImage = styled.Image({
  width: 24,
  height: 24,
  marginTop: Platform.select({ web: 2, default: 0 }),
})

const StyledItemHeaderTextView = styled.View({
  marginLeft: 10,
  marginRight: 40,
  marginTop: 3,
})

const StyledHeaderTitleText = styled.Text({
  fontSize: 18,
  fontWeight: 'bold',
  color: 'rgb(58, 58, 58)',
})

const StyledHeaderDetailText = styled.Text({
  marginTop: 7,
  fontSize: 13,
  fontWeight: 'normal',
  lineHeight: 18,
  color: 'rgb(153, 153, 153)',
})

const StyledCommonListItem = styled(CommonListItem)({
  paddingLeft: 60,
  height: 54,
})

export default FinancialAccountCooperationScreen
