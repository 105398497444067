import Alert from '@components/Alert'
import { APIError } from '@lib/api'
import { UserAPI } from '@lib/api/User'
import Log from '@lib/Log'
import store from '@redux/store'
import { AlertButton, Platform } from 'react-native'
import { ENV } from 'react-native-dotenv'
import NavigationService from './NavigationService'

class CommonDialog {
  show = (title = '', message?: string, onPress?: () => void) => {
    Alert.alert(title, message, [
      {
        text: '閉じる',
        onPress,
      },
    ])
  }

  showMessage = (message: string, onPress?: () => void) => {
    this.show('', message, onPress)
  }

  showError = (props: ShowErrorProps) => {
    const { error, onPress, onPressRetry } = props

    const buttons: AlertButton[] = []
    if (!onPress && !onPressRetry) {
      buttons.push({ text: '閉じる' })
    } else if (onPress) {
      buttons.push({
        text: '閉じる',
        onPress: onPress,
      })
    } else {
      buttons.push({
        text: '再試行',
        onPress: onPressRetry,
      })
    }

    if (error instanceof APIError) {
      if (error.response?.isAuthenticationError) {
        // 何もしない
        return
      }

      if (error.response?.isNetworkError) {
        Alert.alert(
          'ネットワークエラー',
          'ネットワーク環境の良い場所で再度アクセスをお願い致します。',
          buttons,
        )
        return
      }

      const message =
        props.message || error.message || error.response?.errorMessage
      if (message) {
        Alert.alert('', message, buttons)
        return
      }
    } else if (error instanceof Error && error.message) {
      Log.info(error)
      Alert.alert('', error.message, buttons)
      return
    }

    Log.info(error)
    Alert.alert(
      'サーバーが混み合っています',
      '時間を置いて再度操作を行っていただくか、サポートへお問い合わせください。',
      buttons,
    )
  }

  unauthenticated = (message?: string) => {
    let buttons = [
      {
        text: '認証メールを再送信',
        onPress: async () => {
          try {
            const response = await UserAPI.reauthenticate({
              email: store.getState().profile.userProfile?.email || '',
            })
            if (!response.ok) throw new APIError(response)
            this.show(
              '登録メールアドレスに再送しました',
              'メール記載のURLをクリックして認証を完了してください。',
            )
          } catch (error) {
            this.showError({ error })
          }
        },
      },
      {
        text: '別のメールアドレスに変更',
        onPress: () =>
          NavigationService.navigate('SettingUserInformation', {
            type: 'activation',
          }),
      },
      {
        text: 'あとで',
      },
    ]
    if (Platform.OS === 'android') {
      buttons = buttons.reverse()
    }

    Alert.alert(
      Platform.select({
        ios: 'メールアドレス認証を完了してください',
        android: 'メール認証を完了してください',
        default: '',
      }),
      message ||
        'メール記載のURLをクリックして完了すると、すべての機能が使えるようになります。',
      buttons,
    )
  }

  debug = (data: unknown) => {
    if (ENV !== 'release') {
      this.show(
        'このダイアログは開発環境でのみ表示されます',
        JSON.stringify(data),
      )
    }
  }
}

export type ShowErrorProps = {
  error: unknown
  message?: string
  onPress?: () => void
  onPressRetry?: () => void
}

export default new CommonDialog()
