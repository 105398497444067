/* eslint-disable @typescript-eslint/no-var-requires */
import AddIcon from '@images/buttons/add/button-add.png'
import ArrowLeftDisabled from '@images/buttons/arrow/button-arrow-left-disabled.png'
import ArrowLeft from '@images/buttons/arrow/button-arrow-left.png'
import ArrowRightDisabled from '@images/buttons/arrow/button-arrow-right-disabled.png'
import ArrowRight from '@images/buttons/arrow/button-arrow-right.png'
import ListDeleteIcon from '@images/buttons/button-list-delete.png'
import DeleteIcon from '@images/buttons/delete/button-delete.png'
import EditIcon from '@images/buttons/edit/button-edit.png'
import HeaderTransfer from '@images/buttons/header/button-header-transfer.png'
import InputIcon from '@images/buttons/input/button-input.png'
import PlusIcon from '@images/buttons/plus/button-plus.png'
import TermTransactionDialogOpen from '@images/buttons/repeat-fukidasi-button.png'
import TimeTreeConnectDialogClose from '@images/buttons/timetree/timetree-connect-dialog-close-button.png'
import TimeTreeConnectDialogDetail from '@images/buttons/timetree/timetree-connect-dialog-detail-button.png'
import TimeTreeDescriptionClose from '@images/buttons/timetree/timetree-description-dialog-close-button.png'

export const TimeTree = {
  Connect: {
    Dialog: {
      Close: TimeTreeConnectDialogClose,
      Detail: TimeTreeConnectDialogDetail,
    },
  },
  Description: {
    Close: TimeTreeDescriptionClose,
  },
}

export const TermTransaction = {
  Dialog: {
    Open: TermTransactionDialogOpen,
    Close: TimeTreeConnectDialogClose,
  },
}

export const Arrow = {
  Left: ArrowLeft,
  LeftDisabled: ArrowLeftDisabled,
  Right: ArrowRight,
  RightDisabled: ArrowRightDisabled,
}

export const Plus = PlusIcon
export const Edit = EditIcon
export const Input = InputIcon
export const Delete = DeleteIcon
export const Add = AddIcon
export const ListDelete = ListDeleteIcon

export const Header = {
  Transfer: HeaderTransfer,
}
