import store from '@redux/store'
import React from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import BadgeView from './BadgeView'

export interface TimelineTabLabelProps {
  tintColor: string
}

interface StateProps {
  noticesBadgeCount: number
}

class TimelineTabLabel extends React.Component<
  TimelineTabLabelProps & StateProps
> {
  render() {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 15,
            fontWeight: 'bold',
            color: this.props.tintColor,
          }}>
          お知らせ
        </Text>
        <BadgeView
          badgeNumber={this.props.noticesBadgeCount}
          containerStyle={{ marginTop: -3 }}
        />
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    noticesBadgeCount: state.timeline.noticesBadgeCount,
  }
}

export default connect(mapStateToProps)(TimelineTabLabel)
