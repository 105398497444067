import moment from 'moment'
import { FinancialActions, FinancialState } from './types'

export default (
  state: FinancialState = {
    assets: {},
    manuallyCreatedTransactionsCount: 0,
    homeExpensesSummary: {},
    homeExpensesSharingTransactions: {},
    homeExpensesIsYearly: false,
    user: {
      updatedAt: {},
      monthsPL: {},
      manuallyCreatedTransactionsCount: 0,
      homeExpensesSummary: {},
      homeExpensesSharingTransactions: {},
      calendarTransactions: {},
    },
    family: {
      updatedAt: {},
      monthsPL: {},
      manuallyCreatedTransactionsCount: 0,
      homeExpensesSummary: {},
      homeExpensesSharingTransactions: {},
      calendarTransactions: {},
    },
    calendarTransactions: {},
    updatedAt: {},
  },
  action: FinancialActions,
): FinancialState => {
  switch (action.type) {
    case 'UpdateFinancialAssets': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        assets: action.assets,
        [userAccountType]: {
          ...state[userAccountType],
          banks: action.assets.banks,
          emoneys: action.assets.emoneys,
        },
      }
    }
    case 'UpdateTotalAssetAmount': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        // totalAssetAmount: action.totalAssetAmount,
        [userAccountType]: {
          ...state[userAccountType],
          totalAssetAmount: action.totalAssetAmount,
        },
      }
    }
    case 'UpdateThisMonthsPayment': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        // thisMonthsPayment: action.thisMonthsPayment,
        [userAccountType]: {
          ...state[userAccountType],
          thisMonthsPayment: action.thisMonthsPayment,
        },
      }
    }
    case 'UpdateCardAccounts': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        cards: action.cards,
        [userAccountType]: {
          ...state[userAccountType],
          cards: action.cards,
        },
      }
    }
    case 'UpdateBankAccounts': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        assets: {
          banks: action.banks,
          emoneys: state.assets.emoneys,
          [userAccountType]: {
            ...state[userAccountType],
            banks: action.banks,
          },
        },
      }
    }
    case 'UpdateEmoneyAccounts': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        assets: {
          banks: state.assets.banks,
          emoneys: action.emoneys,
          [userAccountType]: {
            ...state[userAccountType],
            emoneys: action.emoneys,
          },
        },
      }
    }
    case 'UpdateMonthsPL': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        [userAccountType]: {
          ...state[userAccountType],
          monthsPL: {
            ...state[userAccountType].monthsPL,
            ...action.monthsPL,
          },
        },
      }
    }
    case 'UpdateTransactions': {
      const userAccountType = action.userAccountType
      if (userAccountType) {
        return {
          ...state,
          [userAccountType]: {
            ...state[userAccountType],
            transactions: action.transactions,
            nextTransactionDate: action.nextTransactionDate,
            updatedAt: {
              ...state[userAccountType].updatedAt,
              transactions: moment().format(),
            },
          },
        }
      } else {
        return {
          ...state,
          transactions: action.transactions,
          nextTransactionDate: action.nextTransactionDate,
          updatedAt: {
            ...state.updatedAt,
            transactions: moment().format(),
          },
        }
      }
    }
    case 'UpdateCategoryTransactions': {
      const userAccountType = action.userAccountType
      return {
        ...state,
        [userAccountType]: {
          ...state[userAccountType],
          categoryTransactions: action.categoryTransactions,
        },
      }
    }
    case 'UpdateBankTransaction': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        bankTransaction: action.bankTransaction,
        [userAccountType]: {
          ...state[userAccountType],
          bankTransaction: action.bankTransaction,
          updatedAt: {
            ...state[userAccountType].updatedAt,
            bankTransaction: moment().format(),
          },
        },
      }
    }
    case 'UpdateEmoneyTransaction': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        emoneyTransaction: action.emoneyTransaction,
        [userAccountType]: {
          ...state[userAccountType],
          emoneyTransaction: action.emoneyTransaction,
          updatedAt: {
            ...state[userAccountType].updatedAt,
            emoneyTransaction: moment().format(),
          },
        },
      }
    }
    case 'UpdateCardTransaction': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        cardTransaction: action.cardTransaction,
        [userAccountType]: {
          ...state[userAccountType],
          cardTransaction: action.cardTransaction,
          updatedAt: {
            ...state[userAccountType].updatedAt,
            cardTransaction: moment().format(),
          },
        },
      }
    }
    case 'UpdateGroupedTransactions': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        groupedTransactions: action.groupedTransactions,
        [userAccountType]: {
          ...state[userAccountType],
          groupedTransactions: action.groupedTransactions,
        },
      }
    }
    case 'UpdateManuallyCreatedTransactionsCount': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        manuallyCreatedTransactionsCount: action.count,
        [userAccountType]: {
          ...state[userAccountType],
          manuallyCreatedTransactionsCount: action.count,
        },
      }
    }
    case 'UpdateWallets': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        wallets: action.wallets,
        [userAccountType]: {
          ...state[userAccountType],
          wallets: action.wallets,
        },
      }
    }
    case 'UpdateWalletTransaction': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        walletTransaction: action.walletTransaction,
        [userAccountType]: {
          ...state[userAccountType],
          walletTransaction: action.walletTransaction,
        },
      }
    }
    case 'UpdateInvestments': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        investments: action.investments,
        [userAccountType]: {
          ...state[userAccountType],
          investments: action.investments,
        },
      }
    }
    case 'UpdateAssetProducts': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        assetProducts: action.assetProducts,
        [userAccountType]: {
          ...state[userAccountType],
          assetProducts: action.assetProducts,
        },
      }
    }
    case 'UpdateHomeExpensesSummary': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        homeExpensesSummary: {
          ...state.homeExpensesSummary,
          ...action.homeExpensesSummary,
        },
        [userAccountType]: {
          ...state[userAccountType],
          homeExpensesSummary: {
            ...state[userAccountType].homeExpensesSummary,
            ...action.homeExpensesSummary,
          },
        },
      }
    }
    case 'UpdateHomeExpensesSharingTransactions': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        homeExpensesSharingTransactions: {
          ...state.homeExpensesSharingTransactions,
          ...action.transactions,
        },
        [userAccountType]: {
          ...state[userAccountType],
          homeExpensesSharingTransactions: {
            ...state[userAccountType].homeExpensesSharingTransactions,
            ...action.transactions,
          },
        },
      }
    }
    case 'UpdateHomeExpensesSelectedDate': {
      const userAccountType = action.userAccountType || 'family' // TODO
      return {
        ...state,
        homeExpensesSelectedDate: action.homeExpensesSelectedDate,
        [userAccountType]: {
          ...state[userAccountType],
          homeExpensesSelectedDate: action.homeExpensesSelectedDate,
        },
      }
    }
    case 'UpdateHomeExpensesSelectedYearMonth': {
      const userAccountType = action.userAccountType || 'family' // TODO

      return {
        ...state,
        [userAccountType]: {
          ...state[userAccountType],
          homeExpensesSelectedYearMonth: action.homeExpensesSelectedYearMonth,
        },
      }
    }
    case 'UpdateLatestTransactionsCount': {
      const { userAccountType, count } = action
      if (userAccountType) {
        return {
          ...state,
          [userAccountType]: {
            ...state[userAccountType],
            updatedAt: {
              ...state[userAccountType].updatedAt,
              transactionsCount: count,
            },
          },
        }
      } else {
        return {
          ...state,
          updatedAt: {
            ...state.updatedAt,
            transactionsCount: count,
          },
        }
      }
    }
    case 'UpdateCalendarTransactions': {
      const userAccountType = action.userAccountType
      if (userAccountType) {
        return {
          ...state,
          [userAccountType]: {
            ...state[userAccountType],
            calendarTransactions: {
              ...state[userAccountType].calendarTransactions,
              ...action.calendarTransactions,
            },
          },
        }
      } else {
        return {
          ...state,
          calendarTransactions: {
            ...state.calendarTransactions,
            ...action.calendarTransactions,
          },
        }
      }
    }
    case 'UpdateHomeExpensesIsYearly': {
      return {
        ...state,
        homeExpensesIsYearly: action.isYearly,
      }
    }
    default:
      return state
  }
}
