import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { GoalEditorProps } from '@interfaces/Goal'
import Color from '@lib/Color'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { GoalManager } from '@lib/api/Goal'
import store from '@redux/store'
import React from 'react'
import {
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import FastImage from 'react-native-fast-image'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { connect } from 'react-redux'

interface Props {
  editor: GoalEditorProps
}

class SavingCompletionScreen extends React.Component<Props> {
  componentDidMount() {
    const editor = this.props.editor
    GoalManager.fetchGoals({ share: editor.share })

    TrackingUtils.repro.trackOnce(
      '【Screen】Saving_create completed_unique',
      'Screen',
    )
    TrackingUtils.adjust.trackEvent({ ios: 'q4vyja', android: '7a110j' })
    TrackingUtils.repro.track(
      '【Screen】Saving_create completed_cumulative',
      'Screen',
    )
    TrackingUtils.adjust.trackEvent({ ios: 'blqvs6', android: 'lz586l' })
  }

  private ImageComponent = Platform.OS === 'web' ? ImageBackground : FastImage

  render() {
    const editor = this.props.editor

    const imageWidth = wp('100%') - 72
    const imageHeight = imageWidth * (176 / 311)

    return (
      <>
        <View style={styles.container}>
          <View>
            <CommonHeader title="目標貯金の作成完了" leftButtonType="hidden" />
          </View>

          <View style={styles.contentView}>
            <ImageBackground
              style={styles.bgImage}
              source={require('@images/bg/goal-add-done-bg.png')}>
              <ScrollView style={styles.scrollView}>
                <Text style={styles.text1}>目標貯金を作成しました</Text>
                <Text style={styles.text2}>これから頑張りましょう！</Text>
                <View
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    backgroundColor: 'white',
                    borderRadius: 10,
                    overflow: 'hidden',
                  }}>
                  <this.ImageComponent
                    style={{ height: imageHeight }}
                    // source={{ uri: S3Utils.getRemoteURL(goals.imgUrl!) }}
                    source={{ uri: editor.imgUrl }}
                    resizeMode="cover"
                  />
                  <Text
                    style={{
                      paddingHorizontal: 20,
                      paddingTop: 20,
                      fontSize: 24,
                      fontWeight: 'bold',
                    }}>
                    {editor.name}
                  </Text>
                  <View
                    style={{
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 20,
                      flexDirection: 'row',
                    }}>
                    <Text
                      style={{
                        color: '#888',
                        fontSize: 15,
                        fontWeight: 'normal',
                      }}>
                      目標貯金額{'  '}
                    </Text>
                    <Text
                      style={{
                        color: Color.DefaultText,
                        fontSize: 15,
                        fontWeight: 'bold',
                      }}>
                      {editor.goalAmount?.jpCurrency()}
                    </Text>
                  </View>
                </View>

                <View style={styles.buttonArea}>
                  <AppButton
                    type="primary"
                    title="次へ"
                    onPress={() => {
                      NavigationService.popToTop()
                    }}
                  />
                </View>
              </ScrollView>
            </ImageBackground>
          </View>
        </View>
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentView: {
    flex: 1,
  },
  bgImage: {
    flex: 1,
    resizeMode: 'cover',
  },
  scrollView: {
    paddingHorizontal: 36,
  },
  text1: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    color: Color.White,
    marginTop: 80,
    marginBottom: 15,
  },
  text2: {
    fontSize: 17,
    fontWeight: 'normal',
    textAlign: 'center',
    color: Color.White,
    marginBottom: 20,
  },
  buttonArea: {
    marginTop: 60,
    marginBottom: 20,
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    editor: state.goal.editor,
  }
}

export default connect(mapStateToProps)(SavingCompletionScreen)
