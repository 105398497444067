import { TransactionDisplayType } from '@Screen/Main/Transactions/redux/types'
import { UserAccountType } from '@interfaces/Account'
import Log from '@lib/Log'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { logging } from '../Firestore'
import CommonDialog from './CommonDialog'

const AsyncStorageSessionKey = '@Session'
const AsyncStorageCumulativeKey = '@Cumulative'

type CacheDataType = {
  AccessToken?: string
  AccessTokenExpires?: string // format: 2020-02-10T02:18:58.000+09:00
  RefreshToken?: string
  RefreshTokenExpires?: string // format: 2020-02-10T02:18:58.000+09:00
  IsWalkthroughDisplayCompleted?: boolean
  AccountMode?: string
  PairingToken?: string
  IsFamilyShareMode?: boolean
  LaunchHomeCount?: number
  LaunchSubCategoryPickerCount?: number
  DisplayCreateAccountDialogCount?: number
  DisplayInvitePartnerDialogCount?: number
  DisplayPushNotificationDialogCount?: number
  DisplayStoreRequestReviewCount?: number
  DisplayHomeExpensesSubCategories?: boolean
  TransactionDisplayType?: TransactionDisplayType
  IsOpenedATTransparencyDialog?: boolean
  DefaultPersonalWalletId?: number
  DefaultFamilyWalletId?: number
  IsTransactionTabDisplayCompleted?: boolean
  Passcode?: string
  Biometrics?: boolean
  AuthenticationRequestInterval?: number
  IsIgnoreUnlockScreen?: boolean
  IsLockScreen?: boolean
  InActiveTime?: number
  Adid?: string
  IsPesonalVisibleTotalAssets?: boolean
  IsFamilyVisibleTotalAssets?: boolean
  IsTutorialDisplayCompleted?: boolean
  IsTutorialTransactionDisplayCompleted?: boolean
  IsDisplayedBalloonCreateSubCategory?: boolean
  LogoutUserId?: number
}

type SessionKey = keyof CacheDataType

class SessionManager {
  private cachedData: CacheDataType = {}

  private cumulativeData: { [key: string]: number | undefined } = {}

  init = async () => {
    Log.info('SessionManager.init()')
    logging(
      `SessionManager.init(): Object.keys(this.cachedData).length = ${
        Object.keys(this.cachedData).length
      }`,
    )
    if (Object.keys(this.cachedData).length) return

    try {
      const json = await AsyncStorage.getItem(AsyncStorageSessionKey)
      if (json) {
        this.cachedData = JSON.parse(json)
        Log.info(this.cachedData)
      }
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  sync = async () => {
    try {
      await AsyncStorage.setItem(
        AsyncStorageSessionKey,
        JSON.stringify(this.cachedData),
      )
    } catch (e) {
      Log.info(e)
    }
  }

  set = (key: SessionKey, value: any) => {
    this.cachedData[key] = value
  }

  getString = (key: SessionKey, defaultValue?: string): string | undefined => {
    const value = this.cachedData[key]
    return typeof value === 'string' ? value : defaultValue
  }

  getBoolean = (key: SessionKey, defaultValue = false): boolean => {
    const value = this.cachedData[key]
    return typeof value === 'boolean' ? value : defaultValue
  }

  getNumber = (key: SessionKey, defaultValue = 0): number => {
    const value = this.cachedData[key]
    return typeof value === 'number' ? value : defaultValue
  }

  remove = (key: SessionKey) => {
    delete this.cachedData[key]
  }

  isLoggedIn = () => {
    return !!this.getString('AccessToken')
  }

  inalidateAccessToken = async () => {
    this.remove('AccessToken')
    this.remove('AccessTokenExpires')
    this.remove('RefreshToken')
    this.remove('RefreshTokenExpires')
    this.remove('AccountMode')
    this.remove('IsFamilyShareMode')
    await this.sync()
  }

  setAccessToken = async (
    accessToken: string,
    accessTokenExpires: string,
    refreshToken: string,
    refreshTokenExpires: string,
  ) => {
    this.set('AccessToken', accessToken)
    this.set('AccessTokenExpires', accessTokenExpires)
    this.set('RefreshToken', refreshToken)
    this.set('RefreshTokenExpires', refreshTokenExpires)
    await this.sync()
  }

  // invalidateAccessTokenExpires = () => {
  //   this.set('AccessTokenExpires, '2020-01-01T00:00:00.000Z')
  //   this.sync()
  // }

  getAccessToken = () => {
    return this.getString('AccessToken')
  }

  getAccessTokenExpires = () => {
    return this.getString('AccessTokenExpires')
  }

  getRefreshToken = () => {
    return this.getString('RefreshToken')
  }

  getRefreshTokenExpires = () => {
    return this.getString('RefreshTokenExpires')
  }

  setWalkthroughDisplayCompleted = (value: boolean) => {
    if (this.cachedData['IsWalkthroughDisplayCompleted'] !== value) {
      this.set('IsWalkthroughDisplayCompleted', value)
      this.sync()
    }
  }

  isWalkthroughDisplayCompleted = () => {
    return this.getBoolean('IsWalkthroughDisplayCompleted')
  }

  setAccountMode = (accountMode: UserAccountType) => {
    if (this.cachedData['AccountMode'] !== accountMode) {
      this.set('AccountMode', accountMode)
      this.sync()
    }
  }

  getAccountMode = () => {
    return (this.getString('AccountMode') || 'family') as UserAccountType
  }

  setPairingToken = (token: string) => {
    if (this.cachedData['PairingToken'] !== token) {
      this.set('PairingToken', token)
      this.sync()
    }
  }

  getPairingToken = () => {
    return this.getString('PairingToken')
  }

  setFamilyShareMode = (familyShareMode: boolean) => {
    if (this.cachedData['IsFamilyShareMode'] !== familyShareMode) {
      this.set('IsFamilyShareMode', familyShareMode)
      this.sync()
    }
  }

  isFamilyShareMode = () => {
    return this.getBoolean('IsFamilyShareMode')
  }

  getLaunchHomeCount = () => {
    return this.getNumber('LaunchHomeCount')
  }

  setLaunchHomeCount = (count: number) => {
    this.set('LaunchHomeCount', count)
    this.sync()
  }

  getLaunchSubCategoryPickerCount = () => {
    return this.getNumber('LaunchSubCategoryPickerCount')
  }

  setLaunchSubCategoryPickerCount = (count: number) => {
    this.set('LaunchSubCategoryPickerCount', count)
    this.sync()
  }

  getDisplayCreateAccountDialogCount = () => {
    return this.getNumber('DisplayCreateAccountDialogCount')
  }

  setDisplayCreateAccountDialogCount = (count: number) => {
    this.set('DisplayCreateAccountDialogCount', count)
    this.sync()
  }

  getDisplayInvitePartnerDialogCount = () => {
    return this.getNumber('DisplayInvitePartnerDialogCount')
  }

  setDisplayInvitePartnerDialogCount = (count: number) => {
    this.set('DisplayInvitePartnerDialogCount', count)
    this.sync()
  }

  getDisplayPushNotificationDialogCount = () => {
    return this.getNumber('DisplayPushNotificationDialogCount')
  }

  setDisplayPushNotificationDialogCount = (count: number) => {
    this.set('DisplayPushNotificationDialogCount', count)
    this.sync()
  }

  getDisplayStoreRequestReviewCount = () => {
    return this.getNumber('DisplayStoreRequestReviewCount')
  }

  setDisplayStoreRequestReviewCount = (count: number) => {
    this.set('DisplayStoreRequestReviewCount', count)
    this.sync()
  }

  getDisplayHomeExpensesSubCategories = () => {
    return this.getBoolean('DisplayHomeExpensesSubCategories')
  }

  setDisplayHomeExpensesSubCategories = (value: boolean) => {
    this.set('DisplayHomeExpensesSubCategories', value)
    this.sync()
  }

  getTransactionDisplayType = (): TransactionDisplayType => {
    const transactionDisplayType = this.getString('TransactionDisplayType')
    if (
      transactionDisplayType === 'transactions' ||
      transactionDisplayType === 'calendar'
    ) {
      return transactionDisplayType
    } else {
      return 'calendar'
    }
  }

  setTransactionDisplayType = (value: TransactionDisplayType) => {
    this.set('TransactionDisplayType', value)
    this.sync()
  }

  setOpenedATTDialog = (value: boolean) => {
    if (this.cachedData['IsOpenedATTransparencyDialog'] !== value) {
      this.set('IsOpenedATTransparencyDialog', value)
      this.sync()
    }
  }

  getDefaultPersonalWalletId = () => {
    return this.getNumber('DefaultPersonalWalletId')
  }

  setDefaultPersonalWalletId = (value?: number) => {
    if (this.cachedData['DefaultPersonalWalletId'] !== value) {
      this.set('DefaultPersonalWalletId', value)
      this.sync()
    }
  }

  getDefaultFamilyWalletId = () => {
    return this.getNumber('DefaultFamilyWalletId')
  }

  setDefaultFamilyWalletId = (value?: number) => {
    if (this.cachedData['DefaultFamilyWalletId'] !== value) {
      this.set('DefaultFamilyWalletId', value)
      this.sync()
    }
  }

  setTransactionTabDisplayCompleted = (value: boolean) => {
    if (this.cachedData['IsTransactionTabDisplayCompleted'] !== value) {
      this.set('IsTransactionTabDisplayCompleted', value)
      this.sync()
    }
  }

  isTransactionTabDisplayCompleted = () => {
    return this.getBoolean('IsTransactionTabDisplayCompleted')
  }

  isOpenedATTDialog = () => {
    return this.getBoolean('IsOpenedATTransparencyDialog')
  }

  isTutorialDisplayCompleted = () =>
    this.getBoolean('IsTutorialDisplayCompleted')

  setIsTutorialDisplayCompleted = (value: boolean) => {
    if (this.cachedData['IsTutorialDisplayCompleted'] !== value) {
      this.set('IsTutorialDisplayCompleted', value)
      this.sync()
    }
  }

  isTutorialTransactionDisplayCompleted = () =>
    this.getBoolean('IsTutorialTransactionDisplayCompleted')

  setIsTutorialTransactionDisplayCompleted = (value: boolean) => {
    if (this.cachedData['IsTutorialTransactionDisplayCompleted'] !== value) {
      this.set('IsTutorialTransactionDisplayCompleted', value)
      this.sync()
    }
  }

  getPasscode = () => {
    return this.getString('Passcode')
  }

  setPasscode = (value: string) => {
    if (this.cachedData['Passcode'] !== value) {
      this.set('Passcode', value)
      this.sync()
    }
  }

  removePasscode = () => {
    this.remove('Passcode')
    this.sync()
  }

  // 生体認証
  isBiometrics = () => this.getBoolean('Biometrics')

  setBiometrics = (value: boolean) => {
    if (this.cachedData['Biometrics'] !== value) {
      this.set('Biometrics', value)
      this.sync()
    }
  }

  getAuthenticationRequestInterval = () =>
    this.getNumber('AuthenticationRequestInterval')

  setAuthenticationRequestInterval = (value: number) => {
    if (this.cachedData['AuthenticationRequestInterval'] !== value) {
      this.set('AuthenticationRequestInterval', value)
      this.sync()
    }
  }

  isIgnoreUnlockScreen = () => this.getBoolean('IsIgnoreUnlockScreen')

  // iOS用
  setIgnoreUnlockScreen = (value: boolean) => {
    if (this.cachedData['IsIgnoreUnlockScreen'] !== value) {
      this.set('IsIgnoreUnlockScreen', value)
      this.sync()
    }
  }

  isLockScreen = () => this.getBoolean('IsLockScreen')

  setIsLockScreen = (value: boolean) => {
    if (this.cachedData['IsLockScreen'] !== value) {
      this.set('IsLockScreen', value)
      this.sync()
    }
  }

  getInactiveTime = () => this.getNumber('InActiveTime')

  setInactiveTime = (value: number) => {
    if (this.cachedData['InActiveTime'] !== value) {
      this.set('InActiveTime', value)
      this.sync()
    }
  }

  getAdid = () => this.getString('Adid')

  setAdid = (value: string) => {
    if (this.cachedData['Adid'] !== value) {
      this.set('Adid', value)
      this.sync()
    }
  }

  getIsVisibleTotalAssets = (accountType: UserAccountType) => {
    return accountType === 'user'
      ? this.getBoolean('IsPesonalVisibleTotalAssets', true)
      : this.getBoolean('IsFamilyVisibleTotalAssets', true)
  }

  setIsVisibleTotalAssets = (
    accountType: UserAccountType,
    isVisible: boolean,
  ) => {
    accountType === 'user'
      ? this.set('IsPesonalVisibleTotalAssets', isVisible)
      : this.set('IsFamilyVisibleTotalAssets', isVisible)
    this.sync()
  }

  isDisplayedBalloonCreateSubCategory = () =>
    this.getBoolean('IsDisplayedBalloonCreateSubCategory')

  setIsDisplayedBalloonCreateSubCategory = (value: boolean) => {
    if (this.cachedData['IsDisplayedBalloonCreateSubCategory'] !== value) {
      this.set('IsDisplayedBalloonCreateSubCategory', value)
      this.sync()
    }
  }

  setLogoutUserId = (userId?: number) => {
    if (this.cachedData['LogoutUserId'] !== userId) {
      this.set('LogoutUserId', userId)
      this.sync()
    }
  }

  getLogoutUserId = () => {
    return this.getNumber('LogoutUserId')
  }

  cumulative = (key: string): number => {
    const value = this.cumulativeData[key]
    const count = (value || 0) + 1
    this.cumulativeData[key] = count
    this.syncCumulative()
    return count
  }

  syncCumulative = async () => {
    try {
      await AsyncStorage.setItem(
        AsyncStorageCumulativeKey,
        JSON.stringify(this.cumulativeData),
      )
    } catch (e) {
      Log.info(e)
    }
  }

  clearPairingToken = async () => {
    this.remove('PairingToken')
    await this.sync()
  }

  clearAll = async () => {
    this.cachedData = {}
    this.cumulativeData = {}
    await this.sync()
    await this.syncCumulative()
  }

  dump = () => {
    Log.info('SessionManager', {
      cachedData: this.cachedData,
      cumulativeData: this.cumulativeData,
    })
  }

  dumpData = () =>
    removeUndefinedProperties({
      ...this.cachedData,
      Passcode: undefined,
    })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefinedProperties = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      obj[key] = removeUndefinedProperties(obj[key])
    }
  }
  return obj
}

export default new SessionManager()
