import { Action, ActionType, State } from './types'

const initialState: State = {
  selectedTab: 'HomeTab',
  tabBarVisible: true,
}

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateMainScreenTab:
      return { ...state, selectedTab: action.selectedTab }
    case ActionType.SetTabBarVisible:
      return { ...state, tabBarVisible: action.tabBarVisible }
    default:
      return state
  }
}

export default reducer
