import { Image } from '@components'
import ActionSheet from '@components/ActionSheet'
import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import Avatar from '@components/Avatar'
import AvatarBackground from '@components/AvatarBackground'
import CommonListItem from '@components/CommonListItem'
import DatePicker from '@components/DatePicker'
import FullWidthImage from '@components/FullWidthImage'
import ItemSeparator from '@components/ItemSeparator'
import { Gender, isValidGender } from '@interfaces/Gender'
import * as Buttons from '@lib/Buttons'
import CommonDialog from '@lib/CommonDialog'
import * as ImagePickerUtils from '@lib/ImagePickerUtils'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import RNProgressHud from '@lib/ProgressHUD'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { Dimensions, Text, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { saveProfiles } from '../functions'
import { profileSelector } from '../selector'
import { Profile, setProfile } from '../slice'
import {
  RootContainer,
  StyledAvatarUpdateButton,
  StyledAvatarUpdateImage,
  StyledAvatarUpdateText,
} from './styles'
import {
  GenderActionSheetIndex,
  GenderActionSheetOptions,
  MarriageActionSheetIndex,
  MarriageActionSheetOptions,
} from './types'

const SettingProfileContentScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingProfileContentUser'>
> = ({ route }) => {
  const userType = route.params.userType

  const dispatch = useDispatch()

  const profile = useSelector(profileSelector(userType))

  const isPaired = useSelector(
    ({ profile }: RootState) => !!profile.userStatus?.paired,
  )

  const isPairingError = userType === 'partner' && !isPaired

  const [isDatePickerVisible, setDatePickerVisible] = useState(false)
  const [isNameBalloonVisible, setNameBalloonVisible] = useState(false)

  const updateProfile = useCallback(
    (profile: Profile) => {
      if (userType === 'user') {
        dispatch(setProfile({ userProfile: profile }))
      } else {
        dispatch(setProfile({ partnerProfile: profile }))
      }
    },
    [dispatch, userType],
  )

  const checkPairing = useCallback(() => {
    if (isPairingError) {
      Alert.alert(
        '',
        'パートナー情報の追加編集は、パートナーとペアリングするとできるようになります',
        [{ text: '閉じる' }],
      )
      return false
    }
    return true
  }, [isPairingError])

  const onChangeName = useCallback(
    (newName: string) => {
      const name = newName.substring(0, 10)
      updateProfile({ ...profile, name })
    },
    [profile, updateProfile],
  )

  const onPressUpdateAvatar = useCallback(() => {
    if (!checkPairing()) return

    ImagePickerUtils.open(
      {
        compressImageMaxWidth: 512,
        compressImageMaxHeight: 512,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').width,
        includeBase64: true,
      },
      async (imageFile) => {
        if (!imageFile) return
        try {
          RNProgressHud.show()
          await ProfileManager.updateAvatarIcon({
            userAccountType: userType === 'user' ? 'user' : 'family',
            mime: imageFile.mime,
            base64: imageFile.data || '',
          })
        } catch (error) {
          CommonDialog.showError({ error })
        } finally {
          RNProgressHud.dismiss()
        }
      },
    )
  }, [checkPairing, userType])

  const onPressMarriage = useCallback(() => {
    if (!checkPairing()) return

    ActionSheet.showActionSheetWithOptions(
      {
        options: MarriageActionSheetOptions,
        cancelButtonIndex: MarriageActionSheetIndex.Cancel,
      },
      (index: number) => {
        if (index === MarriageActionSheetIndex.Cancel) return
        const married = index === MarriageActionSheetIndex.Married
        updateProfile({ ...profile, married })
      },
    )
  }, [checkPairing, profile, updateProfile])

  const onPressGender = useCallback(() => {
    if (!checkPairing()) return

    ActionSheet.showActionSheetWithOptions(
      {
        options: GenderActionSheetOptions,
        cancelButtonIndex: GenderActionSheetIndex.Cancel,
      },
      (index: number) => {
        if (index === GenderActionSheetIndex.Cancel) return
        const gender: Gender =
          index === GenderActionSheetIndex.Male ? Gender.Male : Gender.Female
        updateProfile({ ...profile, gender })
      },
    )
  }, [checkPairing, profile, updateProfile])

  const iconImageView = useMemo(() => {
    return (
      <AvatarBackground type={userType}>
        <View>
          <Avatar type={userType} size={100} style={{ zIndex: 1 }} />
          <StyledAvatarUpdateButton onPress={onPressUpdateAvatar}>
            <StyledAvatarUpdateImage
              source={require('@images/camera/icon-camera-small.png')}
            />
            <StyledAvatarUpdateText>変更</StyledAvatarUpdateText>
          </StyledAvatarUpdateButton>
        </View>
      </AvatarBackground>
    )
  }, [onPressUpdateAvatar, userType])

  const gender = useMemo(() => {
    const gender = profile?.gender
    if (isValidGender(gender)) {
      return gender === Gender.Male ? '男性' : '女性'
    }
  }, [profile])

  const married = useMemo(() => {
    const married = profile?.married
    if (married !== undefined) {
      return married ? '既婚' : '未婚'
    }
  }, [profile])

  const onDateConfirm = useCallback(
    (date: Date) => {
      const birthday = moment(date).format('YYYY-MM-DD')
      updateProfile({ ...profile, birthday })
      setDatePickerVisible(false)
    },
    [profile, updateProfile],
  )

  const onPressBirthday = useCallback(() => {
    if (!checkPairing()) return
    setDatePickerVisible(true)
  }, [checkPairing])

  const hideDateTimePicker = useCallback(() => {
    setDatePickerVisible(false)
  }, [])

  const onPressEmail = useCallback(() => {
    NavigationService.navigate('SettingUserInformation')
  }, [])

  const onPressNameQuestionButton = useCallback(() => {
    setNameBalloonVisible(!isNameBalloonVisible)
  }, [isNameBalloonVisible])

  const onPressBalloonCloseButton = useCallback(() => {
    setNameBalloonVisible(false)
  }, [])

  console.log({ userType, isPairingError })

  return (
    <RootContainer>
      {iconImageView}
      <View>
        <CommonListItem
          displayType="2column"
          title="名前"
          value={profile.name}
          placeholder={userType === 'user' ? 'わたし' : 'パートナー'}
          isDisplayArrow={false}
          isDisplayBalloonButton={userType === 'user'}
          //   disabled={!isPairingError && userType === 'partner'}
          editable={!isPairingError}
          onChangeText={onChangeName}
          onPress={isPairingError ? checkPairing : undefined}
          onPressBalloonButton={onPressNameQuestionButton}
        />
        {ItemSeparator}
        <CommonListItem
          displayType="2column"
          title="性別"
          value={gender || ''}
          placeholder="未設定"
          isDisplayArrow={true}
          arrowType="down"
          onPress={onPressGender}
        />
        {ItemSeparator}
        <CommonListItem
          displayType="2column"
          title="誕生日"
          value={
            profile?.birthday
              ? moment(profile?.birthday).format('YYYY年M月D日')
              : ''
          }
          placeholder="未設定"
          isDisplayArrow={true}
          arrowType="down"
          onPress={onPressBirthday}
        />
        {ItemSeparator}
        <CommonListItem
          displayType="2column"
          title="結婚"
          value={married || ''}
          placeholder="未設定"
          isDisplayArrow={true}
          arrowType="down"
          onPress={onPressMarriage}
        />
        {ItemSeparator}
        <CommonListItem
          displayType="2column"
          title="メールアドレス"
          value={profile.email || ''}
          placeholder="未設定"
          isDisplayArrow={userType === 'user'}
          arrowType="right"
          onPress={userType === 'user' ? onPressEmail : undefined}
          disabled={userType === 'partner'}
        />
        {isNameBalloonVisible && (
          <View
            style={{
              position: 'absolute',
              alignSelf: 'center',
              top: 34,
              left: 20,
              right: 20,
            }}>
            <FullWidthImage
              source={require('@images/profile-name-balloon.png')}
            />
            <View
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                paddingTop: wp(8),
                paddingHorizontal: wp(4),
              }}>
              <Text
                style={{
                  fontSize: wp(4),
                  fontWeight: 'bold',
                  color: 'rgb(63, 197, 21)',
                }}>
                名前を変えるとどうなるの？
              </Text>
              <Text
                style={{
                  marginTop: wp(4.3),
                  fontSize: wp(3.5),
                  fontWeight: 'normal',
                  lineHeight: wp(4.3),
                  color: 'rgb(58, 58, 58)',
                }}>
                {`OsidOriで表示される名前が、初期設定「わたし」から登録した名前に変更されます。\n\n名前は取引明細や支出の分担、目標貯金など多くの画面で表示されるため、登録することでより便利に使えます。`}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}>
              <View
                style={{
                  width: '100%',
                  alignItems: 'flex-end',
                }}>
                <TouchableOpacity
                  onPress={onPressBalloonCloseButton}
                  style={{
                    padding: wp(2),
                    marginTop: wp(5.5),
                    marginRight: wp(2),
                  }}>
                  <Image
                    source={Buttons.TimeTree.Connect.Dialog.Close}
                    style={{ width: 19, height: 19 }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </View>
      <View style={{ marginHorizontal: 20, marginVertical: 30 }}>
        <AppButton title="保存する" onPress={saveProfiles} />
      </View>
      <DatePicker
        date={
          profile.birthday
            ? moment(profile.birthday).toDate()
            : moment('1990-01-01').toDate()
        }
        isVisible={isDatePickerVisible}
        onConfirm={onDateConfirm}
        onCancel={hideDateTimePicker}
        headerFormat="日付を選択"
        confirmText="決定"
        cancelText="キャンセル"
        minimumDate={new Date('1900-01-01')}
      />
    </RootContainer>
  )
}

export default SettingProfileContentScreen
