import ActionSheet from '@components/ActionSheet'
import Alert from '@components/Alert'
import CommonHeader from '@components/CommonHeader'
import HeaderMenuButton from '@components/HeaderMenuButton'
import { TabBarOptions } from '@constants'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import ProgressHUD from '@lib/ProgressHUD'
import { GoalManager } from '@lib/api/Goal'
import { selectGoal } from '@lib/api/Goal/slice'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import Toast from 'react-native-simple-toast'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import AddToSavingButton from './AddToSavingButton'
import GoalDetailsScreen from './GoalDetailsScreen'
import GoalSummaryScreen from './GoalSummaryScreen'

const GoalDetailScreen: React.FC<
  StackScreenProps<RootStackParamList, 'GoalDetail'>
> = ({
  route: {
    params: { goalId, tab },
  },
}) => {
  const isFocused = useIsFocused()

  const goal = useSelector(selectGoal)

  const fetchGoalDetail = useCallback(async () => {
    try {
      await GoalManager.fetchGoal(goalId)
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [goalId])

  const subtraction = useCallback(
    () => NavigationService.navigate('AddToSavings', { isSubtraction: true }),
    [],
  )

  const editGoal = useCallback(() => {
    NavigationService.navigate('EditGoalDetail', { goalId })
  }, [goalId])

  const finishOrCancel = useCallback(() => {
    const title = goal?.completed
      ? '目標貯金を再開しますか？'
      : '目標貯金を完了しますか？'
    const message = goal?.completed
      ? undefined
      : '目標貯金を完了すると、完了済みとして保存され、毎月の貯金していた場合は、積立貯金を停止します。'

    Alert.alert(title, message, [
      { text: 'いいえ', style: 'cancel' },
      {
        text: 'はい',
        onPress: async () => {
          if (!goal) return

          try {
            ProgressHUD.show()
            await GoalManager.updateGoal({
              ...goal,
              complete: !goal?.completed,
            })
            await fetchGoalDetail()
          } catch (error) {
            CommonDialog.showError({ error })
          } finally {
            ProgressHUD.dismiss()
          }
        },
      },
    ])
  }, [fetchGoalDetail, goal])

  const deleteGoal = useCallback(() => {
    Alert.alert('この目標貯金を削除しますか？', undefined, [
      { text: 'いいえ' },
      {
        text: 'はい',
        onPress: async () => {
          try {
            await GoalManager.deleteGoal({ goalId })
            if (Platform.OS !== 'web') {
              Toast.showWithGravity('削除しました', Toast.SHORT, Toast.BOTTOM)
            }
            NavigationService.goBack()
          } catch (error) {
            CommonDialog.showError({ error })
          }
        },
      },
    ])
  }, [goalId])

  const onPressMenu = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: [
          '目標を編集する',
          goal?.completed ? '完了を取り消す' : '目標を完了する',
          '貯金を取り崩す',
          '目標を削除する',
          'キャンセル',
        ],
        cancelButtonIndex: ActionSheetIndex.Cancel,
        destructiveButtonIndex: ActionSheetIndex.Delete,
      },
      (index: number) => {
        if (index === ActionSheetIndex.Subtraction) {
          subtraction()
        } else if (index === ActionSheetIndex.Edit) {
          editGoal()
        } else if (index === ActionSheetIndex.Finish) {
          finishOrCancel()
        } else if (index === ActionSheetIndex.Delete) {
          deleteGoal()
        }
      },
    )
  }, [deleteGoal, editGoal, finishOrCancel, goal?.completed, subtraction])

  const renderRightButton = useCallback(
    () => <HeaderMenuButton onPress={onPressMenu} />,
    [onPressMenu],
  )

  const periodText = useMemo(
    () =>
      `${moment(goal?.startDate).format('YYYY年M月D日〜')}${
        goal?.endDate ? moment(goal?.endDate).format('YYYY年M月D日') : ''
      }`,
    [goal?.endDate, goal?.startDate],
  )

  useEffect(() => {
    if (isFocused) {
      fetchGoalDetail()
    }
    // TODO: 目標貯金へ追加の画面に行くと何故か実行されてしまう
    // return () => {
    //   log.info('unmount GoalDetailScreen')
    //   GoalManager.clearDetail()
    // }
  }, [fetchGoalDetail, isFocused])

  useEffect(() => {
    return () => {
      log.info('unmount GoalDetailScreen')
      GoalManager.clearDetail()
    }
  }, [])

  return (
    <StyledRootView>
      <CommonHeader title={goal?.name} renderRightButton={renderRightButton} />
      <StyledImageBackground source={{ uri: goal?.imgUrl ?? '' }}>
        <StyledPeriodText>{periodText}</StyledPeriodText>
        {goal?.completed && (
          <StyledCompletedView>
            <StyledCompletedText>完了済み</StyledCompletedText>
          </StyledCompletedView>
        )}
      </StyledImageBackground>
      <Tab.Navigator
        backBehavior="none"
        initialRouteName={tab}
        tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="GoalSummary"
          component={GoalSummaryScreen}
          options={{ title: '目標の概要' }}
        />
        <Tab.Screen
          name="GoalDetails"
          component={GoalDetailsScreen}
          options={{ title: '目標の詳細' }}
        />
      </Tab.Navigator>
      <AddToSavingButton />
    </StyledRootView>
  )
}

const ActionSheetIndex = {
  Edit: 0,
  Finish: 1,
  Subtraction: 2,
  Delete: 3,
  Cancel: 4,
} as const

const Tab = createMaterialTopTabNavigator()

const StyledRootView = styled.View({
  flex: 1,
})

const StyledImageBackground = styled.ImageBackground({
  width: '100%',
  height: 117,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  overflow: 'hidden',
})

const StyledPeriodText = styled.Text({
  fontSize: 16,
  color: Color.White,
  fontWeight: 'bold',
})

const StyledCompletedView = styled.View`
  position: absolute;
  padding: 4px 50px;
  top: 8;
  left: -45;
  background-color: ${Color.White};
  transform: rotate(-30deg);
  border-width: 0.5px;
  border-color: #00000030;
`

const StyledCompletedText = styled.Text({
  fontSize: 14,
  color: Color.DefaultText,
  fontWeight: 'bold',
})

export default GoalDetailScreen
