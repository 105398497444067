import { Transaction } from '@interfaces/Financial'
import { Payoff } from '@lib/api/Payoff'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PayoffDisplaySettleType } from './PayoffScreen/types'

export type State = {
  displaySettleType?: PayoffDisplaySettleType
  payoff?: Payoff
  transactions?: Transaction[]
}

type Reducer = {
  setPayoffDisplaySettleType: (
    state: State,
    action: PayloadAction<{ displaySettleType?: PayoffDisplaySettleType }>
  ) => void
  setPayoff: (state: State, action: PayloadAction<{ payoff?: Payoff }>) => void
  setPayoffTransactions: (
    state: State,
    action: PayloadAction<{ transactions?: Transaction[] }>
  ) => void
}

const initialState: State = {}

const slice = createSlice<State, Reducer>({
  name: 'payoff',
  initialState,
  reducers: {
    setPayoffDisplaySettleType: (state, { payload }) => {
      state.displaySettleType = payload.displaySettleType
    },
    setPayoff: (state, { payload }) => {
      state.payoff = payload.payoff
    },
    setPayoffTransactions: (state, { payload }) => {
      state.transactions = payload.transactions
    },
  },
})

export const {
  setPayoffDisplaySettleType,
  setPayoff,
  setPayoffTransactions,
} = slice.actions

export default slice.reducer
