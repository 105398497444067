import { Image } from '@components'
import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import { FinancialAccount } from '@interfaces/Financial'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import RNProgressHud from '@lib/ProgressHUD'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { Component } from 'react'
import {
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { connect } from 'react-redux'
import { navigatePremiumPlanLP } from '../PremiumPlan'

interface ListItem {
  financialAccount: FinancialAccount
}

interface StateProps {
  financial: FinancialState
}

interface State {
  refreshing: boolean
  famillyAccounts?: FinancialAccount[]
  personalAccounts?: FinancialAccount[]
  unSelectedFinancialAccounts: Set<FinancialAccount>
  displayMode: 'select' | 'confirm'
}

class DeleteFinancialAccountsScreen extends Component<
  StateProps & StackScreenProps<RootStackParamList, 'DeleteFinancialAccounts'>,
  State
> {
  state: State = {
    refreshing: false,
    // famillyAccounts: [],
    // personalAccounts: [],
    unSelectedFinancialAccounts: new Set<FinancialAccount>(),
    displayMode: 'select',
  }

  private sectionList = React.createRef<SectionList<ListItem>>()

  componentDidMount() {
    this.props.navigation.addListener('focus', async () => {
      RNProgressHud.show()
      await this.getFinancialAccounts()
      RNProgressHud.dismiss()

      if (store.getState().account.accountMode === 'family') {
        // TrackingUtils.repro.track('【Screen】Family_BS', 'Screen')
      } else {
        // TrackingUtils.repro.track('【Screen】Personal_BS', 'Screen')
      }

      // リフレッシュ中にアプリをバックグラウンドにした場合の対策
      this.setState({ refreshing: false })
    })
  }

  private getFinancialAccounts = async () => {
    const famillyAccounts: FinancialAccount[] = []
    const personalAccounts: FinancialAccount[] = []

    try {
      const famillyBanks = await FinancialManager.getBankAccounts('family')
      const famillyCards = await FinancialManager.getCardAccounts('family')
      const famillyEmoneys = await FinancialManager.getEmoneyAccounts('family')
      const famillyAssets = await FinancialManager.getStockAccounts('family')
      const userBanks = await FinancialManager.getBankAccounts('user')
      const userCards = await FinancialManager.getCardAccounts('user')
      const userEmoneys = await FinancialManager.getEmoneyAccounts('user')
      const userAssets = await FinancialManager.getStockAccounts('user')

      famillyAccounts.push(
        ...famillyBanks,
        ...famillyCards,
        ...famillyEmoneys,
        ...famillyAssets,
      )
      personalAccounts.push(
        ...userBanks,
        ...userCards,
        ...userEmoneys,
        ...userAssets,
      )

      const userId = store.getState().profile.userProfile?.userId

      console.log({
        userId,
        famillyAccounts: famillyAccounts.filter(
          (v) => v.userId === userId && v.finance !== 'wallet',
        ),
      })

      this.setState({
        famillyAccounts: famillyAccounts.filter(
          (v) => v.userId === userId && v.finance !== 'wallet',
        ),
        personalAccounts: personalAccounts.filter(
          (v) => v.finance !== 'wallet',
        ),
      })
    } catch (error) {
      CommonDialog.showError({ error, onPress: NavigationService.goBack })
    }
  }

  private sectionHeader = (info: { section: SectionListData<ListItem> }) => (
    <SectionListHeader
      title={info.section.title}
      style={{ fontWeight: 'normal' }}
    />
  )

  private onPressConfirm = () => {
    if (!this.state.famillyAccounts || !this.state.personalAccounts) return

    const count =
      this.state.famillyAccounts.length +
      this.state.personalAccounts.length -
      this.state.unSelectedFinancialAccounts.size

    if (count > 5) {
      CommonDialog.showMessage('口座連携を続ける口座は\n5個まで選択可能です')
    } else {
      this.setState({ displayMode: 'confirm' })
      this.scrollToTop()
    }
  }

  private scrollToTop = () => {
    this.sectionList.current?.scrollToLocation({
      sectionIndex: 0,
      itemIndex: 0,
      viewPosition: 0,
      viewOffset: 999,
      animated: false,
    })
  }

  private onPressItem = (item: ListItem) => {
    const unSelectedFinancialAccounts = this.state.unSelectedFinancialAccounts
    if (unSelectedFinancialAccounts.has(item.financialAccount)) {
      unSelectedFinancialAccounts.delete(item.financialAccount)
    } else {
      unSelectedFinancialAccounts.add(item.financialAccount)
    }
    this.setState({ unSelectedFinancialAccounts })
  }

  private renderItem: SectionListRenderItem<ListItem> = ({ item }) => {
    // let totalCurrentAmount = 0
    // const goals = item.financialAccount.goals || []
    // goals.forEach((v) => {
    //   totalCurrentAmount += v.currentAmount
    // })

    let icon
    switch (item.financialAccount.financialAccountType) {
      case 'bank':
        icon = Icon.Bank
        break
      case 'card':
        icon = Icon.CreditCard
        break
      case 'emoney':
        icon = Icon.IcCard
        break
      case 'stock':
        icon = Icon.Bond
        break
      default:
        icon = Icon.CategoryIcon.Uncategorized
    }

    const selected = !this.state.unSelectedFinancialAccounts.has(
      item.financialAccount,
    )

    return (
      <>
        <TouchableOpacity
          style={styles.item}
          disabled={this.state.displayMode === 'confirm'}
          onPress={() => this.onPressItem(item)}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={styles.row}>
              <Image source={icon} style={styles.iconLabel} />
              <Text style={styles.textItem}>{item.financialAccount.name}</Text>
            </View>
            <CheckMark value={item.financialAccount} selected={selected} />
          </View>
        </TouchableOpacity>
      </>
    )
  }

  private sections = (): SectionListData<ListItem>[] => {
    if (!this.state.famillyAccounts || !this.state.personalAccounts) return []

    const sections: SectionListData<ListItem>[] = []

    const familySection: SectionListData<ListItem> = {
      title: '家族ホーム',
      data: this.state.famillyAccounts.map((financialAccount) => ({
        financialAccount,
      })),
    }
    sections.push(familySection)

    const personalSection: SectionListData<ListItem> = {
      title: '個人ホーム',
      data: this.state.personalAccounts.map((financialAccount) => ({
        financialAccount,
      })),
    }
    sections.push(personalSection)

    return sections
  }

  private renderHeader = () => {
    return (
      <>
        <View style={{ backgroundColor: '#f7f7f7', padding: 20 }}>
          <View
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
              borderWidth: 1,
              borderColor: '#f0f0f0',
              padding: 20,
            }}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                <Image source={Icon.Caution} style={styles.cautionImage} />
                <Text
                  style={{
                    color: '#ff7200',
                    fontSize: 17,
                    fontWeight: 'bold',
                  }}>
                  削除時のご注意
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 20,
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 18,
                }}>
                金融機関とそれに紐づく各種取引データが完全に削除され、後から復元はできません。
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: '#666666',
                  lineHeight: 16,
                  paddingTop: 10,
                }}>
                ・「資産総額」から削除されます{'\n'}
                ・「家族ホーム」に登録した口座はあなたとパートナーの画面から削除されます
                {'\n'}
                ・金融機関に紐づく利用明細が削除されます{'\n'}
                ・過去の収入/支出のデータが変わります{'\n'}
                ・家族画面に振り分けた利用明細が削除されます
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: 'white',
            paddingHorizontal: 20,
            alignItems: 'center',
          }}>
          {this.state.displayMode === 'select' ? (
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 17,
                paddingTop: 20,
                lineHeight: 22,
                textAlign: 'center',
              }}>
              口座連携を続ける口座を{'\n'}5個まで選んでください
            </Text>
          ) : (
            <Text
              style={{
                color: '#ff7200',
                fontWeight: 'bold',
                fontSize: 17,
                paddingTop: 20,
                lineHeight: 22,
                textAlign: 'center',
              }}>
              チェック済の口座の{'\n'}口座連携を継続します
            </Text>
          )}
          <Text style={{ fontWeight: 'bold', fontSize: 12, paddingTop: 5 }}>
            (チェックを外した口座は削除されます)
          </Text>
          <Text
            style={{
              color: '#3a3a3a',
              fontSize: 12,
              fontWeight: 'normal',
              paddingTop: 10,
            }}>
            無料プランでは
            <Text style={{ fontWeight: 'bold' }}>最大5個</Text>まで連携できます
          </Text>
          <Text
            style={{
              color: '#3fc515',
              fontSize: 12,
              fontWeight: 'normal',
              paddingTop: 5,
            }}>
            🏅プレミアムプランなら
            <Text style={{ fontWeight: 'bold' }}>上限無制限</Text>です
          </Text>
        </View>
        <Text
          style={{
            paddingHorizontal: 20,
            paddingTop: 25,
            paddingBottom: 10,
            color: '#3a3a3a',
            fontSize: 13,
            fontWeight: 'bold',
          }}>
          連携中の金融機関一覧
        </Text>
      </>
    )
  }

  private renderFooter = () => {
    const buttonWidth = (wp('100%') - 50) / 2
    return (
      <View
        style={{
          borderTopColor: '#eeeeee',
          borderTopWidth: 1,
          paddingHorizontal: 20,
          paddingVertical: 20,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {this.state.displayMode === 'select' ? (
          <AppButton
            type="secondary"
            title="確認画面へ"
            style={{ width: buttonWidth }}
            textStyle={{ fontWeight: 'bold', fontSize: 14 }}
            onPress={this.onPressConfirm}
          />
        ) : (
          <AppButton
            type="secondary"
            title="完了する"
            style={{ width: buttonWidth }}
            textStyle={{ fontWeight: 'bold', fontSize: 14 }}
            onPress={async () => {
              RNProgressHud.show()
              await this.deleteFinancialAccounts()
              RNProgressHud.dismiss()
              NavigationService.goBack()
            }}
          />
        )}
        <AppButton
          title={'プレミアムプラン\nを確認する'}
          style={{ width: buttonWidth }}
          textStyle={{ fontWeight: 'bold', fontSize: 14, lineHeight: 18 }}
          onPress={() => {
            NavigationService.goBack()
            navigatePremiumPlanLP({ planCode: '001' })
          }}
        />
      </View>
    )
  }

  private deleteFinancialAccounts = async () => {
    await Promise.all(
      [...this.state.unSelectedFinancialAccounts].map(async (v) => {
        try {
          if (['bank', 'card', 'emoney'].includes(v.financialAccountType)) {
            await FinancialManager.deleteAccount({
              financialType: v.financialAccountType as
                | 'bank'
                | 'card'
                | 'emoney',
              accountId: v.accountId,
            })
          } else if (v.financialAccountType === 'stock') {
            await FinancialManager.deleteStockAccount({ id: v.accountId })
          }
        } catch (error) {
          CommonDialog.showError({ error })
        }
      }),
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader
          leftButtonType={
            this.state.displayMode === 'select' ? 'modal' : 'stack'
          }
          title={
            this.state.displayMode === 'select'
              ? '連携を続ける口座を選択'
              : '確認画面'
          }
          onPressLeftButton={() => {
            if (this.state.displayMode === 'select') {
              NavigationService.goBack()
            } else {
              this.setState({ displayMode: 'select' })
              this.scrollToTop()
            }
          }}
        />
        <SectionList
          ref={this.sectionList}
          keyExtractor={(item) => item.financialAccount.fncId}
          renderItem={this.renderItem}
          renderSectionHeader={this.sectionHeader}
          sections={this.sections()}
          extraData={this.props.financial}
          ItemSeparatorComponent={() => ItemSeparator}
          ListEmptyComponent={ListEmptyComponent}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={async () => {
                this.setState({ refreshing: true })
                await FinancialManager.fetchAssets()
                this.setState({ refreshing: false })
              }}
            />
          }
          ListHeaderComponent={this.renderHeader}
          ListFooterComponent={this.renderFooter}
        />
      </View>
    )
  }
}

const ListEmptyComponent = (
  <View style={{ height: 60 }}>
    <AbsoluteActivityIndicator />
  </View>
)

const CheckMark = (props: { value: FinancialAccount; selected: boolean }) => {
  return (
    <Image
      source={props.selected ? Icon.CheckMarkOn : Icon.CheckMarkOff}
      style={{ width: 20, height: 20 }}
      resizeMode="contain"
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  errorView: {
    borderWidth: 1,
    borderColor: '#eeeeee',
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
  },
  cautionImage: {
    marginEnd: 10,
    resizeMode: 'contain',
    width: 20,
    height: 20,
  },
  errorText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#3a3a3a',
  },
  label: {
    color: '#555555',
    paddingLeft: 15,
    fontSize: 15,
    fontWeight: 'normal',
    paddingTop: 15,
  },
  textTotal: {
    color: Color.Primary,
    textAlign: 'right',
    paddingRight: 15,
    fontSize: 30,
    fontWeight: 'bold',
    paddingBottom: 15,
  },
  title: {
    color: '#555555',
    fontSize: 13,
    fontWeight: 'normal',
    backgroundColor: '#f0f0f0',
    paddingLeft: 15,
    paddingVertical: 10,
  },
  item: {
    padding: 20,
    height: 56,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLabel: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  textItem: {
    fontSize: 13,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingLeft: 5,
    paddingRight: 10,
  },
  profitLossLabel: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingRight: 8,
  },
  jpyBottom: {
    marginTop: 15,
    justifyContent: 'flex-end',
  },
  jpyInvestment: {
    marginTop: 4,
    marginRight: 18,
    justifyContent: 'flex-end',
  },
  jpy: {
    fontSize: 22,
    color: '#3a3a3a',
    fontWeight: '700',
  },
  jpyPlus: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Primary,
  },
  jpyMinus: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Orange,
  },
  jpyOffset: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Gray,
  },
  goalsView: {
    paddingTop: 16,
    paddingBottom: 8,
    paddingLeft: 35,
    paddingRight: 20,
  },
  goalsItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
  },
  goalsItemLabel: {
    flex: 1,
    marginLeft: 8,
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
  }
}

export default connect(mapStateToProps)(DeleteFinancialAccountsScreen)
