import { UserAccountType } from '@interfaces/Account'
import { GoalCategory, GoalEditorProps, GoalEntity } from '@interfaces/Goal'
import { RootState } from '@redux/store'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialGoalEditorProps } from './types'

export type State = {
  goal?: GoalEntity
  editor: GoalEditorProps
  goals: {
    user?: GoalEntity[]
    family?: GoalEntity[]
  }
  goalCategories: {
    user?: GoalCategory[]
    family?: GoalCategory[]
  }
}

type Reducer = {
  updateGoalEditor: (
    state: State,
    action: PayloadAction<GoalEditorProps>,
  ) => void
  updateGoals: (
    state: State,
    action: PayloadAction<{
      userAccountType: UserAccountType
      goals: GoalEntity[]
    }>,
  ) => void
  updateGoal: (
    state: State,
    action: PayloadAction<GoalEntity | undefined>,
  ) => void
  updateGoalCategories: (
    state: State,
    action: PayloadAction<{
      userAccountType: UserAccountType
      goalCategories: GoalCategory[]
    }>,
  ) => void
}

const initialState: State = {
  goal: undefined,
  editor: initialGoalEditorProps,
  goals: {
    user: undefined,
    family: undefined,
  },
  goalCategories: {
    user: undefined,
    family: undefined,
  },
}

const slice = createSlice<State, Reducer>({
  name: 'goal',
  initialState,
  reducers: {
    updateGoalEditor: (state, { payload }) => {
      state.editor = payload
    },
    updateGoals: (state, { payload }) => {
      state.goals[payload.userAccountType] = payload.goals
    },
    updateGoal: (state, { payload }) => {
      state.goal = payload
    },
    updateGoalCategories: (state, { payload }) => {
      state.goalCategories[payload.userAccountType] = payload.goalCategories
    },
  },
})

export const {
  updateGoalEditor,
  updateGoals,
  updateGoal,
  updateGoalCategories,
} = slice.actions

export const selectGoalEditor = ({ goal }: RootState) => goal.editor
export const selectGoals = ({ goal }: RootState) => goal.goals
export const selectGoal = ({ goal }: RootState) => goal.goal
export const selectGoalCategories = ({ goal }: RootState) => goal.goalCategories

export default slice.reducer
