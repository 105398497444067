import {
  selectMonthlyGoals,
  selectYearMonth,
  setGoals,
  setIsProcessingGoal,
} from '@Screen/Main/MonthlyReportScreen/slice'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import AppButton from '@components/AppButton'
import { UserAccountType } from '@interfaces/Account'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { GetMonthlyReportGoalItem, GoalManager } from '@lib/api/Goal'
import * as scale from 'd3-scale'
import * as shape from 'd3-shape'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActivityIndicator,
  Image,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import {
  Grid,
  LineChart,
  StackedAreaChart,
  XAxis,
  YAxis,
} from 'react-native-svg-charts'
import { useDispatch, useSelector } from 'react-redux'
import ShowMoreButton from '../ShowMoreButton'
import GoalCard from './GoalCard'
import {
  PremiumContainerView,
  PremiumText,
  PremiumTitleText,
  StyledPlanErrorView,
} from './styles'

type Item =
  | (() => JSX.Element)
  | (GetMonthlyReportGoalItem & {
      monthOver?: number
      color: string
    })

const ChartColors = [
  '#6CDF49',
  '#47D3FF',
  '#FFD028',
  '#FF6D6D',
  '#DC7AFF',
  '#FF9A16',
  '#22ABDB',
  '#FF83AF',
  '#90E6B8',
  '#A2A0FF',
]

const ChartColors2 = ChartColors.map((v) => `${v}4d`)

export type GoalsPanelContentProps = {
  userAccountType: UserAccountType
}

const GoalsPanelContent = ({ userAccountType }: GoalsPanelContentProps) => {
  const dispatch = useDispatch()

  const yearMonth = useSelector(selectYearMonth)
  const goals = useSelector(selectMonthlyGoals)

  const sectionList = React.createRef<SectionList<Item>>()

  const [refreshing, setRefreshing] = useState(false)
  const [planError, setPlanError] = useState(false)
  const [period, setPeriod] = useState<3 | 6 | 12 | 'all' | 'future'>(6)
  const [isShowMore, setIsShowMore] = useState(true)

  const goalColors = useMemo(() => {
    const keys: { [id: number]: string[] } = {}

    goals.forEach((monthlyReportGoal) => {
      monthlyReportGoal.goals
        .filter((goal) => !goal.completedAt)
        .forEach((goal) => {
          if (!keys[goal.id]) {
            keys[goal.id] = [
              ChartColors[Object.keys(keys).length % ChartColors.length],
              ChartColors2[Object.keys(keys).length % ChartColors2.length],
            ]
          }
        })
    })

    return keys
  }, [goals])

  const goalIdAndNames = useMemo(
    () =>
      goals[0]?.goals
        .filter((v) => !v.completedAt)
        .map((goal) => ({ id: goal.id, name: goal.name })) || [],
    [goals],
  )

  const chartData = useMemo(() => {
    const goalIds = goalIdAndNames.map((v) => v.id)

    const data = goals.map((goal) => {
      const value: { [key: string]: any } = {
        date: goal.date,
        total: goal.totalCurrentAmount,
      }
      goalIds.forEach((goalId) => {
        value[goalId] = 0
      })
      goal.goals
        .filter((v) => !v.completedAt)
        .forEach((v) => {
          value[v.id] = v.currentAmount
        })
      return value
    })

    return data.reverse()
  }, [goalIdAndNames, goals])

  const lineChartData = useMemo(() => {
    const goalKeys = goalIdAndNames.map((v) => v.id)

    const lineChartData: { [key: string]: any }[] = []

    chartData.forEach((c) => {
      const tmp = { ...c }

      goalKeys.forEach((key, index) => {
        for (let i = index + 1; i < goalKeys.length; i++) {
          tmp[key] += c[goalKeys[i]]
        }
      })

      lineChartData.push(tmp)
    })

    return lineChartData
  }, [chartData, goalIdAndNames])

  const getGoals = useCallback(async () => {
    try {
      if (!yearMonth) return

      dispatch(setIsProcessingGoal(true))

      const date = moment(yearMonth, 'YYYYMM')

      const goals = await GoalManager.getMonthlyReport({
        userAccountType,
        // year: period === 'future' ? date.year() : undefined,
        // month: period === 'future' ? date.month() + 1 : undefined,
        year: date.year(),
        month: date.month() + 1,
        term: period,
      })

      dispatch(setGoals(goals))
      setPlanError(false)
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.errorCode === '007004') {
          setPlanError(true)
          sectionList.current?.scrollToLocation({
            sectionIndex: 0,
            itemIndex: 0,
          })
          TrackingUtils.repro.track(
            '【Screen】upper limit_monthly eport asset transition',
            'Screen',
          )
          return
        }
      }
      CommonDialog.showError({ error, onPress: () => setPeriod(6) })
    } finally {
      dispatch(setIsProcessingGoal(false))
    }
  }, [dispatch, period, sectionList, userAccountType, yearMonth])

  useEffect(() => {
    getGoals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, yearMonth, userAccountType])

  const onPressPeriodButton = useCallback((value: 3 | 6 | 12 | 'all') => {
    setPeriod(value)
  }, [])

  const totalAmount = useMemo(
    () =>
      chartData.length >= 1 ? chartData[chartData.length - 1].total : undefined,
    [chartData],
  )

  const prevMonthOver = useMemo(
    () =>
      chartData.length >= 2
        ? chartData[chartData.length - 1].total -
          chartData[chartData.length - 2].total
        : undefined,
    [chartData],
  )

  const listHeader1 = useCallback(() => {
    if (chartData.length === 0) return <></>

    return (
      <View
        style={{
          marginBottom: 20,
        }}>
        <View style={{ height: wp(33) }}>
          <Text
            style={{
              paddingHorizontal: 10,
              fontSize: 16,
              fontWeight: 'normal',
              color: 'rgb(58, 58, 58)',
            }}>
            目標貯金
          </Text>
          <View
            style={{
              marginTop: -20,
              paddingHorizontal: 15,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}>
              {prevMonthOver !== undefined &&
                (prevMonthOver > 0 ? (
                  <Image
                    source={require('@images/report/up-icon.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ) : prevMonthOver < 0 ? (
                  <Image
                    source={require('@images/report/down-icon.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ) : (
                  <Image
                    source={require('@images/report/arrow-flat.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ))}
              <Text
                style={{
                  marginLeft: 3,
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: 'rgb(58, 58, 58)',
                }}>
                {totalAmount != undefined ? totalAmount.jpCurrency() : '-'}
              </Text>
            </View>
            <View
              style={{
                marginTop: 5,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: 'rgb(102, 102, 102)',
                }}>
                前月比
              </Text>
              <Text
                style={{
                  marginLeft: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: Color.number(prevMonthOver),
                }}>
                {prevMonthOver !== undefined
                  ? prevMonthOver.jpCurrency({
                      showPlus: true,
                      showPlusMinus: true,
                    })
                  : '-'}
              </Text>
            </View>
          </View>

          {/* 期間ボタン */}
          <View
            style={{
              marginTop: 20,
              marginBottom: 10,
              paddingHorizontal: 15,
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}>
            <PeriodButton
              title="3ヶ月"
              on={period === 3}
              onPress={() => onPressPeriodButton(3)}
            />
            <PeriodButton
              title="6ヶ月"
              on={period === 6}
              onPress={() => onPressPeriodButton(6)}
            />
            <PeriodButton
              title="1年"
              on={period === 12}
              onPress={() => onPressPeriodButton(12)}
            />
            <PeriodButton
              title="すべて"
              on={period === 'all'}
              onPress={() => onPressPeriodButton('all')}
            />
          </View>
        </View>
      </View>
    )
  }, [totalAmount, chartData, prevMonthOver, period, onPressPeriodButton])

  const listHeader2 = useCallback(() => {
    const yMax = goals.reduce(
      (prv, cur) => Math.max(prv, cur.totalCurrentAmount),
      0,
    )

    const goalIds = goalIdAndNames.map((v) => v.id).reverse()
    const colors = Object.values(goalColors)
      .map((v) => v[1])
      .reverse()

    return (
      <View style={{ marginBottom: 20 }}>
        {/* チャート */}
        <View>
          <View
            style={{
              flexDirection: 'row',
              height: wp(40),
            }}>
            <YAxis
              style={{
                width: '15%',
                marginBottom: 10,
                // backgroundColor: 'red',
              }}
              formatLabel={(value) =>
                value >= 10000 ? `${Math.floor(value / 10000)}万` : value
              }
              numberOfTicks={5}
              data={StackedAreaChart.extractDataPoints(chartData, goalIds)}
              contentInset={{ top: 5, bottom: 5 }}
              svg={{ fontSize: 10, fill: 'grey' }}
            />
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flex: 1,
                  marginTop: 5,
                  paddingVertical: 0,
                  // backgroundColor: 'gray',
                }}>
                <StackedAreaChart
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  numberOfTicks={5}
                  data={chartData}
                  keys={goalIds}
                  colors={colors}
                  // curve={shape.curveNatural}
                  curve={shape.curveLinear}>
                  <Grid />
                </StackedAreaChart>
                {goalIds.map((goalId) => (
                  <LineChart
                    key={goalId}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    yMax={yMax}
                    yMin={0}
                    data={lineChartData.map((v) => v[goalId])}
                    svg={{ stroke: goalColors[goalId][0] }}
                  />
                ))}
              </View>
              <XAxis
                style={{
                  marginTop: 5,
                  marginHorizontal: 0,
                  // backgroundColor: 'red',
                }}
                data={chartData}
                // numberOfTicks={2}
                // scale={scale.scaleBand}
                scale={scale.scaleLinear}
                // spacingInner={10}
                // spacingOuter={10}
                formatLabel={(value, index) =>
                  chartData.length <= 6 ||
                  (chartData.length > 6 &&
                    index % Math.ceil(chartData.length / 6) ===
                      (chartData.length + 1) % 2)
                    ? moment(chartData[index].date, 'YYYY-MM-DD').format('M/D')
                    : ''
                }
                contentInset={{ left: 14, right: 14 }}
                svg={{ fontSize: 10, fill: 'grey' }}
              />
            </View>
          </View>
        </View>
      </View>
    )
  }, [chartData, goalColors, goalIdAndNames, goals, lineChartData])

  const listHeader3 = useCallback(() => {
    const names = goalIdAndNames.map((v) => v.name)
    const colors = Object.values(goalColors).map((v) => v[0])

    return (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {names.map((name, index) => (
          <View key={name} style={{ flexDirection: 'row', marginBottom: 15 }}>
            <View
              style={{
                marginLeft: 15,
                backgroundColor: colors[index],
                width: 18,
                height: 18,
                borderRadius: 10,
              }}
            />
            <Text
              style={{
                marginLeft: 8,
                fontSize: 14,
                fontWeight: 'normal',
                color: 'rgb(58, 58, 58)',
              }}>
              {name}
            </Text>
          </View>
        ))}
      </View>
    )
  }, [goalColors, goalIdAndNames])

  const sections = useMemo((): SectionListData<Item>[] => {
    const data: Item[] = []

    if (goals.length === 0) {
      return [
        {
          title: '',
          data: [listHeader1, listHeader2, listHeader3],
        },
      ]
    }

    goals[0].goals
      .filter((v) => !v.completedAt)
      .forEach((item) => {
        const prevMonthGoal = goals[1]?.goals
          .filter((v) => !v.completedAt)
          .find((v) => v.name === item.name)
        if (prevMonthGoal) {
          data.push({
            ...item,
            monthOver: item.currentAmount - prevMonthGoal.currentAmount,
            color: goalColors[item.id][0],
          })
        } else {
          data.push({
            ...item,
            monthOver: undefined,
            color: goalColors[item.id][0],
          })
        }
      })

    return [
      {
        title: '',
        data: [listHeader1, listHeader2, listHeader3],
      },
      { title: '', data },
    ]
  }, [goalColors, goals, listHeader1, listHeader2, listHeader3])

  const renderItem: SectionListRenderItem<Item> = useCallback(
    ({ item, index }) => {
      if (typeof item === 'function') return item()
      if (isShowMore && index >= 3) return null
      return <ListItem item={item} />

      // return item.scope ? (
      //   <ListItem item={item} onPress={() => onPressItem(item)} />
      // ) : (
      //   <NoDataListItem />
      // )
    },
    [isShowMore],
  )

  const onPressShowMoreButton = useCallback(() => setIsShowMore(false), [])

  const listFooter = useCallback(() => {
    return (
      <>
        <View
          style={{
            marginTop: 15,
            borderTopWidth: 0.5,
            borderTopColor: '#ccc',
          }}
        />
        {isShowMore && Object.keys(goalColors).length > 3 ? (
          <ShowMoreButton onPress={onPressShowMoreButton} />
        ) : (
          <View style={{ height: 25 }} />
        )}
      </>
    )
  }, [goalColors, isShowMore, onPressShowMoreButton])

  if (sections.length === 0 && !planError) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          backgroundColor: 'white',
        }}>
        <ActivityIndicator color={Color.Gray} />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <SectionList
          ref={sectionList}
          keyExtractor={(item: Item, index) =>
            JSON.stringify(item) || `${index}`
          }
          renderItem={renderItem}
          sections={sections}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true)
                await getGoals()
                setRefreshing(false)
              }}
            />
          }
          ListFooterComponent={listFooter}
        />
        {planError && (
          <StyledPlanErrorView>
            <PremiumContainerView>
              <PremiumTitleText>過去の資産推移を確認しよう</PremiumTitleText>
              <PremiumText>
                プレミアムプランでは1年以上前の資産推移を{'\n'}
                グラフで表示できます。{'\n'}
                {'\n'}
              </PremiumText>
              <AppButton
                title="詳しく見る"
                onPress={() => {
                  navigatePremiumPlanLP({ planCode: '001' })
                  TrackingUtils.repro.track(
                    '【Tap】upper limit_monthly eport asset transition',
                    'Tap',
                  )
                }}
              />
            </PremiumContainerView>
          </StyledPlanErrorView>
        )}
      </View>
    </View>
  )
}

// const NoDataListItem = () => (
//   <Text style={{ padding: 20, color: Color.DefaultText }}>
//     データありません
//   </Text>
// )

const ListItem: React.FC<{ item: Item }> = ({ item }) => {
  if (typeof item === 'function') return null

  return (
    <GoalCard
      key={item.name}
      status={item.icon}
      title={item.name}
      current={item.currentAmount}
      target={item.goalAmount}
      imageURI={item.imgUrl}
      progress={item.currentAmount / item.goalAmount}
      monthOver={item.monthOver}
      color={item.color}
    />
  )
}

const PeriodButton = ({
  title,
  on,
  ...props
}: { title: string; on?: boolean } & TouchableOpacityProps) => {
  return (
    <TouchableOpacity
      style={{
        marginLeft: 10,
        backgroundColor: on ? 'rgb(63, 197, 21)' : '#eee',
        borderRadius: 18,
        height: 36,
        width: Math.min(68, wp(15)),
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}>
      <Text
        style={{
          fontSize: 12,
          color: on ? 'white' : 'rgb(153, 153, 153)',
          fontWeight: 'bold',
        }}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
  periodSection: {
    minHeight: 45,
    maxHeight: 45,
    paddingTop: 6,
    paddingHorizontal: 8,
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  chartSection: {
    flexDirection: 'row',
    height: wp('30%'),
    marginTop: 4,
  },
  dateText: {
    padding: 8,
    fontSize: 16,
    fontWeight: 'normal',
  },
  paymentSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textAmount: {
    marginRight: 24,
    color: Color.GrayVeryDark,
    fontSize: 34,
    fontWeight: 'normal',
  },
  textBalance: {
    marginTop: 14,
    marginRight: 12,
    color: Color.GrayVeryDark,
    fontSize: 15,
    fontWeight: 'normal',
  },
  scrollView: {
    height: '50%',
  },
  listPaymentsItem: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 15,
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listPaymentsItemTypeIcon: {
    marginRight: 6,
  },
  listPaymentsItemTypeText: {
    fontSize: 17,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
  },
  listPaymentsItemInfoPercent: {
    width: 50,
    fontSize: 17,
    fontWeight: 'normal',
    textAlign: 'right',
    color: Color.Gray,
  },
  listPaymentsItemInfoAmount: {
    fontSize: 22,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfoAmount2: {
    fontSize: 18,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  iconEdit: {
    resizeMode: 'contain',
    height: 20,
  },
  btnEdit: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  premiumTextOr: {
    margin: wp(3.5),
    color: 'white',
    fontSize: wp(4),
    fontWeight: 'bold',
  },
})

export default GoalsPanelContent
