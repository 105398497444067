import FamilyShareToggleButton from '@components/FamilyShareToggleButton'
import { TabBarOptions } from '@constants'
import { AccountState } from '@lib/AccountManager/types'
import Color from '@lib/Color'
import DateUtilsV1 from '@lib/DateUtilsV1'
import { FinancialState } from '@lib/FinancialManager/types'
import { useTabNavigatorBugWorkaround } from '@lib/hooks'
import { SpotlightTourSpot } from '@lib/SpotlightTour'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { connect } from 'react-redux'
import HomeBudget from './HomeBudget'
import HomeExpensesBalance from './HomeExpensesBalance'
import HomeFamilyExpenses from './HomeExpensesSharing'

interface Props {}

interface StateProps {
  financial: FinancialState
  account: AccountState
}

const Tab = createMaterialTopTabNavigator()

const TabScreen = () => {
  useTabNavigatorBugWorkaround()

  return (
    <Tab.Navigator backBehavior="none" tabBarOptions={TabBarOptions}>
      <Tab.Screen
        name="HomeFamilyExpenses"
        component={HomeExpensesBalance}
        options={{
          title: '収支',
        }}
      />
      {store.getState().account.accountMode === 'family' && (
        <Tab.Screen
          name="HomeFamilyExpenses2"
          component={HomeFamilyExpenses}
          options={{
            title: '支出の分担',
          }}
        />
      )}
      <Tab.Screen
        name="HomeHomeBudget"
        component={HomeBudget}
        options={{
          title: '予算',
        }}
      />
    </Tab.Navigator>
  )
}

class HomeExpenses extends React.Component<Props & StateProps> {
  render() {
    const accountMode = this.props.account.accountMode

    const thisMonth = DateUtilsV1.monthKey()

    const thisMonthsPL = this.props.financial[accountMode].monthsPL[thisMonth]
    if (!thisMonthsPL) return null
    const from = moment(thisMonthsPL.from, 'YYYY-MM-DD').format('M/D')
    const to = moment(thisMonthsPL.to, 'YYYY-MM-DD').format('M/D')

    return (
      <View
        style={[
          styles.container,
          { minHeight: accountMode === 'user' ? 327 : 315 },
        ]}>
        <View style={styles.thisMonthsBalanceView}>
          <View style={styles.wrapMonthlyBalanceViewLeft}>
            <SpotlightTourSpot
              spotId="thisMonthHomeExpenses"
              scale={1.4}
              translateY={10}
              hideTips>
              <Text style={styles.titleTotalAssets}>今月の家計簿</Text>
            </SpotlightTourSpot>
            <Text style={styles.balanceTime}>
              {from}〜{to}
            </Text>
          </View>
          {this.props.account.accountMode === 'user' && (
            <FamilyShareToggleButton />
          )}
        </View>
        <TabScreen />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    backgroundColor: Color.White,
    marginTop: 20,
    borderColor: 'rgb(240, 240, 240)',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  thisMonthsBalanceView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  wrapMonthlyBalanceViewLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  balanceTime: {
    color: Color.Gray,
    fontSize: 15,
    fontWeight: 'normal',
    marginLeft: 8,
  },
  titleTotalAssets: {
    color: Color.TextBlack,
    fontSize: 15,
    fontWeight: 'bold',
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
    account: state.account,
  }
}

export default connect(mapStateToProps)(HomeExpenses)
