import LoginBackground from '@images/bg/bg-signin.png'
import CommonHeaderBackButton from '@images/common-header/common-header-back-button.png'
import CommonHeaderCloseButton from '@images/common-header/common-header-close-button.png'
import CommonHeaderMenuButton from '@images/common-header/common-header-menu-button.png'
import OsidOriLogo from '@images/osidori-logo.png'
import TabHome from '@images/tab/tab-home.png'
import TabTimeline from '@images/tab/tab-timeline.png'
import TabTransactions from '@images/tab/tab-transactions.png'

const Images = {
  OsidOriLogo,
  LoginBackground,
  CommonHeader: {
    MenuButton: CommonHeaderMenuButton,
    BackButton: CommonHeaderBackButton,
    CloseButton: CommonHeaderCloseButton,
  },
  Tab: {
    Home: TabHome,
    Transactions: TabTransactions,
    Timeline: TabTimeline,
  },
}

export default Images
