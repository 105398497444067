import Color from '@lib/Color'
import { Platform, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  buttonPrimaryFrame: {
    backgroundColor: Color.Primary,
    borderRadius: 5,
    height: 50,
    justifyContent: 'center',
  },
  buttonPrimaryText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 17,
    fontWeight: 'normal',
    lineHeight: 32,
    paddingTop: Platform.OS === 'ios' ? 1 : 0,
  },
  buttonSecondaryFrame: {
    height: 50,
    backgroundColor: Color.White,
    borderColor: Color.Primary,
    borderWidth: 1,
    borderRadius: 5,
    justifyContent: 'center',
  },
  buttonSecondaryText: {
    color: Color.Primary,
    textAlign: 'center',
    lineHeight: 32,
    fontSize: 17,
    fontWeight: 'normal',
    paddingTop: Platform.OS === 'ios' ? 1 : 0,
  },
  buttonSecondaryMinFrame: {
    borderRadius: 7,
    borderWidth: 1,
    borderColor: Color.Primary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 7,
    paddingHorizontal: 12,
  },
  buttonSecondaryMinText: {
    color: Color.Primary,
    fontSize: 13,
    fontWeight: 'normal',
  },
  whiteButtonFrame: {
    backgroundColor: 'white',
    borderRadius: 5,
    borderColor: Color.Primary,
    height: 50,
    justifyContent: 'center',
    borderWidth: 1,
  },
  whiteButtonText: {
    color: Color.Primary,
    textAlign: 'center',
    fontSize: 17,
    fontWeight: 'normal',
    paddingTop: Platform.OS === 'ios' ? 1 : 0,
  },
  disableButtonFrame: {
    height: 50,
    borderWidth: 1,
    borderColor: Color.GraySilver,
    backgroundColor: Color.White,
    borderRadius: 5,
    justifyContent: 'center',
  },
  disableButtonText: {
    color: Color.GraySilver,
    textAlign: 'center',
    lineHeight: 32,
    fontSize: 17,
    fontWeight: 'normal',
    paddingTop: Platform.OS === 'ios' ? 1 : 0,
  },
})

export default styles
