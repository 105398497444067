import { UserAccountType } from '@interfaces/Account'
import { PaymentUserType, TransferDetail } from '@interfaces/Financial'
import Log from '@lib/Log'
import API, { APIResponse } from './'

type Weekly =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

export interface GetTermTransactionsProps {
  userAccountType: UserAccountType
  share?: boolean
}

export interface GetTermTransactionsResponse {
  termTransactions: TermTransactionDetail[]
}

export interface GetTermTransactionProps {
  userAccountType: UserAccountType
  termTransactionId: number
}

export interface GetTermTransactionResponse {
  termTransaction: TermTransactionDetail
}

export interface TermTransactionDetail {
  userId: number
  termTransactionId: number
  paymentMethodType: 'wallet' | null
  paymentMethodId: number | null // 財布のid
  atTransactionCategoryId: string
  atGroupedCategoryId: string
  categoryName1: string
  categoryName2: string
  startDate: string
  endDate: string
  term: 'daily' | 'weekly' | 'monthly' | 'yearly'
  termToWeekly?: Weekly
  termToMonthly?: number // 1 - 31（月末は−1）
  termToYearly?: string // 4桁の文字列で月日
  title: string
  amount: number
  usedLocation?: string
  memo: string
  isIgnored: boolean
  isAccountShared: boolean // 支払い担当
  isShared: boolean // 表示する画面
  isAvailable: boolean // 有効・無効
  transferId?: number
  transfer?: TransferDetail
  paymentUserType: PaymentUserType
}

export interface DeleteTermTransactionProps {
  userAccountType: UserAccountType
  termTransactionId: number
}

export interface CreateTermTransactionProps {
  // userAccountType: UserAccountType
  paymentMethodType?: string // 現在はwalletのみ
  paymentMethodId?: number // 財布のid
  atTransactionCategoryId: string
  startDate: string
  endDate: string
  term: 'daily' | 'weekly' | 'monthly' | 'yearly'
  termToWeekly?: Weekly
  termToMonthly?: number // 1 - 31（月末は−1）
  termToYearly?: string // 4桁の文字列で月日
  title: string
  amount: number
  usedLocation?: string
  memo?: string
  ignore: boolean
  share: boolean // 表示する画面
  paymentUserType: PaymentUserType
}

export interface UpdateTermTransactionProps extends CreateTermTransactionProps {
  termTransactionId: number
}

class TermTransaction {
  getTermTransactions = (
    props: GetTermTransactionsProps,
  ): Promise<APIResponse> => {
    const params = { ...props }
    const { userAccountType, share } = params
    const query = share !== undefined ? `?share=${share}` : ''
    return API.request(
      'GET',
      `/api/v1/${userAccountType.userAccountPath()}/term-transactions${query}`,
      {},
      true,
    )
  }

  getTermTransaction = (
    props: GetTermTransactionProps,
  ): Promise<APIResponse> => {
    const { userAccountType, termTransactionId } = props
    return API.request(
      'GET',
      `/api/v1/${userAccountType.userAccountPath()}/term-transactions/${termTransactionId}`,
      {},
      true,
    )
  }

  createTermTransaction = (
    props: CreateTermTransactionProps,
  ): Promise<APIResponse> => {
    const { paymentUserType } = props
    const path = paymentUserType === PaymentUserType.Family ? 'group' : 'user'
    return API.request('POST', `/api/v1/${path}/term-transactions`, props, true)
  }

  updateTermTransaction = (
    props: UpdateTermTransactionProps,
  ): Promise<APIResponse> => {
    Log.info({ props })
    const { termTransactionId, ...params } = props
    const path =
      props.paymentUserType === PaymentUserType.Family ? 'group' : 'user'
    return API.request(
      'PUT',
      `/api/v1/${path}/term-transactions/${termTransactionId}`,
      params,
      true,
    )
  }

  deleteTermTransaction = (
    props: DeleteTermTransactionProps,
  ): Promise<APIResponse> => {
    const { userAccountType, termTransactionId } = props

    return API.request(
      'DELETE',
      `/api/v1/${userAccountType.userAccountPath()}/term-transactions/${termTransactionId}`,
      {},
      true,
    )
  }
}

export default new TermTransaction()
