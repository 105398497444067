import { PropsWithChildren } from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'

// Web版でタブのテキストが一瞬改行されて表示されてしまう問題の回避

const TabBarLabel = ({
  color,
  children,
  style,
}: PropsWithChildren<{ color?: string; style?: StyleProp<TextStyle> }>) => (
  <Text
    numberOfLines={1}
    style={[{ color, fontWeight: '600', fontSize: 15, lineHeight: 30 }, style]}>
    {children}
  </Text>
)

export default TabBarLabel
