import IconImage from '@components/IcomImage'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import {
  StyledArrowImage,
  StyledGrayoutView,
  StyledIconImage,
  StyledPlaceHolderText,
  StyledQuestionIcon,
  StyledText,
  StyledText1,
  StyledText1Container,
  StyledText2,
  StyledTextInput,
  StyledTouchableOpacity,
} from './styles'
import { CommonListItemProps } from './types'

const CommonListItem: React.FC<CommonListItemProps> = ({
  displayType = 'default',
  title = '',
  value,
  iconImageSource,
  isDisplayArrow = false,
  arrowType = 'right',
  onPress,
  style,
  textStyle,
  disabled,
  editable = false,
  placeholder,
  onChangeText,
  onTextInputFocus,
  isDisplayBalloonButton,
  onPressBalloonButton,
}) => {
  if (displayType === 'default') {
    return (
      <View>
        <StyledTouchableOpacity onPress={onPress} style={style}>
          {iconImageSource && <StyledIconImage source={iconImageSource} />}
          <StyledText style={textStyle}>{title}</StyledText>
          {!disabled && isDisplayArrow && (
            <StyledArrowImage
              source={require('@images/arrow/icon-arrow-white-small.png')}
            />
          )}
        </StyledTouchableOpacity>
        {disabled && <StyledGrayoutView />}
      </View>
    )
  } else {
    return (
      <View>
        <StyledTouchableOpacity
          onPress={onPress}
          style={style}
          disabled={disabled}>
          <StyledText1Container>
            <StyledText1>{title}</StyledText1>
            {isDisplayBalloonButton && (
              <TouchableOpacity onPress={onPressBalloonButton}>
                <StyledQuestionIcon
                  icon={faQuestionCircle as never}
                  size={18}
                />
              </TouchableOpacity>
            )}
          </StyledText1Container>

          {editable ? (
            <StyledTextInput
              placeholder={placeholder}
              onChangeText={onChangeText}
              onFocus={onTextInputFocus}
              value={value}
            />
          ) : value ? (
            <StyledText2>{value}</StyledText2>
          ) : (
            <StyledPlaceHolderText>{placeholder}</StyledPlaceHolderText>
          )}

          {!disabled && isDisplayArrow && (
            <IconImage
              name={arrowType === 'down' ? 'ArrowDown' : 'ArrowRight'}
            />
          )}
        </StyledTouchableOpacity>
      </View>
    )
  }
}

export default CommonListItem
