import { Gender } from '@interfaces/Gender'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Profile = {
  name?: string
  gender?: Gender
  birthday?: string
  married?: boolean
  email?: string
}

export type State = {
  userProfile: Profile
  partnerProfile: Profile
}

type Reducer = {
  setProfile: (
    state: State,
    action: PayloadAction<{
      userProfile?: Profile
      partnerProfile?: Profile
    }>,
  ) => void
  clearProfiles: (state: State) => void
}

const initialState: State = {
  userProfile: {},
  partnerProfile: {},
}

const slice = createSlice<State, Reducer>({
  name: 'settingProfiles',
  initialState,
  reducers: {
    setProfile: (state, { payload }) => {
      if (payload.userProfile) {
        state.userProfile = payload.userProfile
      }
      if (payload.partnerProfile) {
        state.partnerProfile = payload.partnerProfile
      }
    },
    clearProfiles: (state) => {
      state.userProfile = {}
      state.partnerProfile = {}
    },
  },
})

export const { setProfile, clearProfiles } = slice.actions

export default slice.reducer
