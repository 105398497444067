import styled from 'styled-components/native'

export const StyledEmptyText = styled.Text`
  color: #aaaaaa;
  font-size: 16px;
  font-weight: normal;
  flex: 1;
  text-align: center;
  justify-content: center;
`
