import { Image } from '@components'
import * as Buttons from '@lib/Buttons'
import Color from '@lib/Color'
import React from 'react'
import {
  GestureResponderEvent,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import FullWidthImage from './FullWidthImage'

export type BalloonProps = {
  title?: string
  message?: string
  onPressCloseButton?: (event: GestureResponderEvent) => void
  style?: ViewStyle
}

export const Balloon: React.FC<BalloonProps> = ({
  title,
  message,
  onPressCloseButton,
  style,
}) => {
  return (
    <View
      style={{
        position: 'absolute',
        alignSelf: 'center',
        top: 34,
        left: 20,
        right: 20,
        ...style,
      }}>
      <FullWidthImage source={require('@images/profile-name-balloon.png')} />
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          paddingTop: wp(8),
          paddingHorizontal: wp(4),
        }}>
        <Text
          style={{
            fontSize: wp(4),
            fontWeight: 'bold',
            color: Color.Primary,
          }}>
          {title}
        </Text>
        <Text
          style={{
            marginTop: wp(4.3),
            fontSize: wp(3.5),
            fontWeight: 'normal',
            lineHeight: wp(4.3),
            color: 'rgb(58, 58, 58)',
          }}>
          {message}
        </Text>
      </View>
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
        <View
          style={{
            width: '100%',
            alignItems: 'flex-end',
          }}>
          <TouchableOpacity
            onPress={onPressCloseButton}
            style={{
              padding: wp(2),
              marginTop: wp(5.5),
              marginRight: wp(2),
            }}>
            <Image
              source={Buttons.TimeTree.Connect.Dialog.Close}
              style={{ width: 19, height: 19 }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}
