import { FinancialAccountType } from '@interfaces/Financial'
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  ViewStyle,
} from 'react-native'
import styled from 'styled-components/native'

const IconColor = {
  bank: '#FFD028',
  card: '#FF6D6D',
  emoney: '#6CDF49',
  stock: '#47D3FF',
  wallet: '#DC7AFF',
  manually_created: '#DC7AFF',
  carry_forward: '',
} as const

type IconColor = keyof typeof IconColor

const icon = (
  financialAccountType: FinancialAccountType,
): ImageSourcePropType => {
  switch (financialAccountType) {
    case 'bank':
      return require('@images/icons/icon-bank.png')
    case 'card':
      return require('@images/icons/icon-credit-card.png')
    case 'emoney':
      return require('@images/icons/icon-ic-card.png')
    case 'stock':
      return require('@images/icons//icon-bond.png')
    default:
      return require('@images/icons/icon-wallet.png')
  }
}

const StyledView = styled.View<{ color: string }>`
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color};
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`

export type FinancialAccountIconType = {
  financialAccountType: FinancialAccountType
  style?: StyleProp<ViewStyle>
  imageStyle?: StyleProp<ImageStyle>
}

const FinancialAccountIcon = ({
  financialAccountType,
  style,
}: FinancialAccountIconType) => {
  return (
    <StyledView color={IconColor[financialAccountType]} style={style}>
      <Image
        source={icon(financialAccountType)}
        style={{
          width: 14,
          height: 14,
          tintColor: 'white',
          resizeMode: 'contain',
        }}
      />
    </StyledView>
  )
}

export default FinancialAccountIcon
