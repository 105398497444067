import ActionSheet from '@components/ActionSheet'
import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CategoryImage from '@components/CategoryImage'
import CheckMarkToggleButton from '@components/CheckMarkToggleButton'
import CommonHeader from '@components/CommonHeader'
import CoupleButton from '@components/CoupleButton'
import FaceIcon from '@components/FaceIcon'
import {
  PaymentUserType,
  TransactionDefaultSettingsScreenType,
} from '@interfaces/Financial'
import { Gender } from '@interfaces/Gender'
import CategoryManager, { CategoryUnit } from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent from '@lib/OsidoriEvent'
import ProfileManager from '@lib/ProfileManager'
import ProgressHUD from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import { TransactionDefaultSettingsManager } from '@lib/api/TransactionDefaultSettings'
import { WalletEntity } from '@lib/api/Wallet'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import { getPartnerGender, getUserGender } from '@redux/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Item } from 'react-native-picker-select'
import { useDispatch, useSelector } from 'react-redux'
import { HelpDialog } from './HelpDialog'
import {
  selectTransactionDefaultSettings,
  setTransactionDefaultSettings,
} from './slice'
import { StyledEmptyText } from './styles'

enum PaymentUserTypeActionSheetIndex {
  User = 0,
  Family = 1,
  Pertner = 2,
  Cancel = 3,
}

export type SettingTransactionDefaultScreenParams = {}

type Wallets = {
  user: WalletEntity[]
  family: WalletEntity[]
}

const CategoryDefaultName = '未分類'
const SubCategoryDefaultId = '387'

type DefaultSettings = {
  categoryName1?: string
  categoryName2?: string
  atTransactionCategoryId?: string | null
  paymentUserType?: PaymentUserType | null
  walletId?: number | null
  transactionShare?: boolean | null // 表示する画面 true: 家族
  yetPayoff?: boolean | null // 未精算リストに入れる
  ignore?: boolean | null // 収支に含めない
}

const SettingTransactionDefaultScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingTransactionDefault'>
> = () => {
  const dispatch = useDispatch()

  const {
    user: userTransactionDefaultSettings,
    family: familyTransactionDefaultSettings,
  } = useSelector(selectTransactionDefaultSettings)

  const [userDefaultSettings, setUserDefaultSettings] =
    useState<DefaultSettings>()
  const [familyDefaultSettings, setFamilyDefaultSettings] =
    useState<DefaultSettings>()

  const [selectedTab, setSelectedTab] =
    useState<TransactionDefaultSettingsScreenType>(
      TransactionDefaultSettingsScreenType.Family,
    )
  const [wallets, setWallets] = useState<Wallets>({
    user: [],
    family: [],
  })

  const [isDisplayHelpDialog, setIsDisplayHelpDialog] = useState(false)

  const selectedDefaultSettings = useMemo(
    () =>
      selectedTab === TransactionDefaultSettingsScreenType.User
        ? userDefaultSettings
        : familyDefaultSettings,
    [familyDefaultSettings, selectedTab, userDefaultSettings],
  )

  const setSelectedDefaultSettings = useCallback(
    (settings: DefaultSettings) => {
      if (selectedTab === TransactionDefaultSettingsScreenType.User) {
        setUserDefaultSettings({ ...selectedDefaultSettings, ...settings })
      } else {
        setFamilyDefaultSettings({ ...selectedDefaultSettings, ...settings })
      }
    },
    [selectedDefaultSettings, selectedTab],
  )

  // 表示する画面 false:個人 true:家族
  const onPressIsTransactionSharedButton = useCallback(
    (transactionShare: boolean) => {
      let settings = { ...selectedDefaultSettings, transactionShare }

      if (
        settings.atTransactionCategoryId &&
        !+settings.atTransactionCategoryId
      ) {
        // 独自に作成したカテゴリの場合は、カテゴリを初期値に戻す
        settings = {
          ...settings,
          categoryName1: CategoryDefaultName,
          categoryName2: CategoryDefaultName,
          atTransactionCategoryId: SubCategoryDefaultId,
        }
      }

      setSelectedDefaultSettings(settings)
    },
    [selectedDefaultSettings, setSelectedDefaultSettings],
  )

  const getWallets = useCallback(async () => {
    try {
      setWallets({
        user: await FinancialManager.getWallets({
          userAccountType: 'user',
          sort: false,
        }),
        family: await FinancialManager.getWallets({
          userAccountType: 'family',
          sort: false,
        }),
      })
    } catch (error) {
      CommonDialog.showError({
        error,
        onPress: () => NavigationService.goBack(),
      })
    }
  }, [])

  const getTransactionDefaultSettings = useCallback(async () => {
    try {
      const user = userTransactionDefaultSettings
      const family = familyTransactionDefaultSettings

      const userSubCategory = CategoryManager.categoryBySubCategoryId(
        user?.atTransactionCategoryId || SubCategoryDefaultId,
      )
      const userCategory = CategoryManager.categoryByCategoryId(
        'user',
        userSubCategory?.categoryId,
      )

      const familySubCategory = CategoryManager.categoryBySubCategoryId(
        family?.atTransactionCategoryId || SubCategoryDefaultId,
      )
      const familyCategory = CategoryManager.categoryByCategoryId(
        'family',
        familySubCategory?.categoryId,
      )

      setUserDefaultSettings({
        atTransactionCategoryId:
          user.atTransactionCategoryId || SubCategoryDefaultId,
        paymentUserType: user.paymentUserType || PaymentUserType.User,
        walletId: user.walletId || 0,
        transactionShare: user.transactionShare || false,
        yetPayoff: user.yetPayoff || false,
        ignore: user.ignore || false,
        categoryName1: userCategory?.name || CategoryDefaultName,
        categoryName2: userSubCategory?.name || CategoryDefaultName,
      })
      setFamilyDefaultSettings({
        atTransactionCategoryId:
          family.atTransactionCategoryId || SubCategoryDefaultId,
        paymentUserType: family.paymentUserType || PaymentUserType.Family,
        walletId: family.walletId || 0,
        transactionShare:
          family.transactionShare !== null ? family.transactionShare : true,
        yetPayoff: family.yetPayoff || false,
        ignore: family.ignore || false,
        categoryName1: familyCategory?.name || CategoryDefaultName,
        categoryName2: familySubCategory?.name || CategoryDefaultName,
      })
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [familyTransactionDefaultSettings, userTransactionDefaultSettings])

  const renderItem = useCallback(
    (props: {
      categoryId?: string
      icon?: ImageSourcePropType
      content: string
      isDisplayRightIcon?: boolean
      onPress?: () => void
      placeholder?: string
      columnMargin?: number
    }) => {
      const {
        categoryId,
        icon,
        content,
        isDisplayRightIcon,
        onPress,
        placeholder,
      } = props
      const textStyle = content === '' ? styles.placeholder : styles.content
      const text = content !== '' ? content : placeholder || ''
      return (
        <TouchableOpacity
          onPress={onPress}
          disabled={!isDisplayRightIcon}
          style={[
            styles.wrapContent,
            { marginRight: props.columnMargin, flex: 1 },
          ]}>
          {categoryId && <CategoryImage categoryId={categoryId} />}
          {icon && (
            <Image
              source={icon}
              style={{ width: 20, height: 20, resizeMode: 'contain' }}
            />
          )}
          <Text style={textStyle}>{text}</Text>
          {isDisplayRightIcon && (
            <Image
              source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
              style={styles.iconDown}
            />
          )}
        </TouchableOpacity>
      )
    },
    [],
  )

  const onPressPaymentUserType = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      Platform.OS === 'ios'
        ? {
            options: [
              ProfileManager.getName('user'),
              '家族',
              ProfileManager.getName('partner'),
              'キャンセル',
            ],
            cancelButtonIndex: PaymentUserTypeActionSheetIndex.Cancel,
          }
        : {
            options: [
              ProfileManager.getName('user'),
              '家族',
              ProfileManager.getName('partner'),
            ],
          },
      (index: number) => {
        if (index === PaymentUserTypeActionSheetIndex.User) {
          const walletId = SessionManager.getDefaultPersonalWalletId()

          setSelectedDefaultSettings({
            paymentUserType: PaymentUserType.User,
            walletId,
          })
        } else if (index === PaymentUserTypeActionSheetIndex.Family) {
          const walletId = SessionManager.getDefaultFamilyWalletId()
          setSelectedDefaultSettings({
            paymentUserType: PaymentUserType.Family,
            walletId,
            yetPayoff: false,
          })
        } else if (index === PaymentUserTypeActionSheetIndex.Pertner) {
          setSelectedDefaultSettings({
            paymentUserType: PaymentUserType.Partner,
            walletId: null,
          })
        }
      },
    )
  }, [setSelectedDefaultSettings])

  const renderSettingPaymentUserType = useCallback(() => {
    const items = {
      [PaymentUserType.User]: ProfileManager.getName('user'),
      [PaymentUserType.Family]: '家族',
      [PaymentUserType.Partner]: ProfileManager.getName('partner'),
    }

    return (
      <TouchableOpacity
        onPress={onPressPaymentUserType}
        style={[styles.wrapContent]}>
        {selectedDefaultSettings?.paymentUserType === PaymentUserType.User ? (
          <FaceIcon faceType={getUserGender()} />
        ) : selectedDefaultSettings?.paymentUserType ===
          PaymentUserType.Partner ? (
          <FaceIcon faceType={getPartnerGender()} />
        ) : (
          <FaceIcon faceType={[Gender.Male, Gender.Female]} />
        )}
        <Text style={styles.paymentPlaceText}>支払い担当</Text>
        <Text style={[styles.content, { textAlign: 'right', marginRight: 15 }]}>
          {
            items[
              selectedDefaultSettings?.paymentUserType || PaymentUserType.Family
            ]
          }
        </Text>
        <Image
          source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
          style={styles.iconDown}
        />
      </TouchableOpacity>
    )
  }, [onPressPaymentUserType, selectedDefaultSettings?.paymentUserType])

  const renderSettingShareView = () => {
    return (
      <View style={styles.settingShareView}>
        <Text
          style={{
            fontSize: 12,
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          表示する画面
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <CoupleButton
            buttonType="single"
            onPress={() => onPressIsTransactionSharedButton(false)}
            isOn={!selectedDefaultSettings?.transactionShare}
            title="個人画面"
            disabled={!selectedDefaultSettings?.transactionShare}
          />
          <CoupleButton
            buttonType="couple"
            onPress={() => onPressIsTransactionSharedButton(true)}
            isOn={!!selectedDefaultSettings?.transactionShare}
            title="家族画面"
            disabled={!!selectedDefaultSettings?.transactionShare}
            style={{ marginLeft: 8 }}
          />
        </View>
      </View>
    )
  }

  const onPressTab = useCallback(
    (selectedTab: TransactionDefaultSettingsScreenType) => {
      setSelectedTab(selectedTab)
    },
    [],
  )

  const tabComponent = useMemo(() => {
    const isFamily = selectedTab === TransactionDefaultSettingsScreenType.Family
    const isUser = !isFamily

    return (
      <View style={styles.tab}>
        <TouchableOpacity
          onPress={() =>
            onPressTab(TransactionDefaultSettingsScreenType.Family)
          }
          style={[
            styles.wrapTab,
            isFamily ? styles.wrapTabWithBorder : styles.wrapTabWithBorderGray,
          ]}
          disabled={isFamily}>
          <Text
            style={[
              styles.tabText,
              isFamily ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            家族画面
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onPressTab(TransactionDefaultSettingsScreenType.User)}
          style={[
            styles.wrapTab,
            isUser ? styles.wrapTabWithBorder : styles.wrapTabWithBorderGray,
          ]}
          disabled={isUser}>
          <Text
            style={[
              styles.tabText,
              isUser ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            個人画面
          </Text>
        </TouchableOpacity>
      </View>
    )
  }, [onPressTab, selectedTab])

  const chooseCategoryHandler = useCallback(
    (categoryUnit: CategoryUnit) => {
      const categoryName1 =
        CategoryManager.getCategoryIconInfo(categoryUnit.categoryId)
          ?.categoryName || ''
      setSelectedDefaultSettings({
        categoryName1,
        categoryName2: categoryUnit.name,
        atTransactionCategoryId: categoryUnit.subCategoryId,
      })
    },
    [setSelectedDefaultSettings],
  )

  const onPressCategory = useCallback(async () => {
    try {
      const userAccountType = selectedDefaultSettings?.transactionShare
        ? 'family'
        : 'user'
      await CategoryManager.fetchCategories({ accountType: userAccountType })

      const params = {
        categoryType: 'expense',
        userAccountType,
        // chooseCategoryHandler: this.chooseCategoryHandler,
        isSelectable: true,
        leftButtonType: 'modal',
      }

      // const params: CategoryPickerScreenParams = {
      //   categoryType: 'expense',
      //   accountType,
      //   // chooseCategoryHandler: chooseCategoryHandler,
      //   selectable: true,
      // }
      NavigationService.navigate('CategoryPickerTop', params)
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [selectedDefaultSettings?.transactionShare])

  const onPressSaveButton = useCallback(async () => {
    showAppButtonIndicator('save')

    try {
      userDefaultSettings &&
        (await TransactionDefaultSettingsManager.postTransactionDefaultSettings(
          {
            screen: TransactionDefaultSettingsScreenType.User,
            atTransactionCategoryId:
              userDefaultSettings.atTransactionCategoryId || null,
            paymentUserType: userDefaultSettings.paymentUserType || null,
            walletId: userDefaultSettings.walletId || null,
            transactionShare:
              userDefaultSettings.transactionShare !== undefined
                ? userDefaultSettings.transactionShare
                : null,
            yetPayoff:
              userDefaultSettings.yetPayoff !== undefined
                ? userDefaultSettings.yetPayoff
                : null,
            ignore:
              userDefaultSettings.ignore !== undefined
                ? userDefaultSettings.ignore
                : null,
          },
        ))
      familyDefaultSettings &&
        (await TransactionDefaultSettingsManager.postTransactionDefaultSettings(
          {
            screen: TransactionDefaultSettingsScreenType.Family,
            atTransactionCategoryId:
              familyDefaultSettings.atTransactionCategoryId || null,
            paymentUserType: familyDefaultSettings.paymentUserType || null,
            walletId: familyDefaultSettings.walletId || null,
            transactionShare:
              familyDefaultSettings.transactionShare !== undefined
                ? familyDefaultSettings.transactionShare
                : null,
            yetPayoff:
              familyDefaultSettings.yetPayoff !== undefined
                ? familyDefaultSettings.yetPayoff
                : null,
            ignore:
              familyDefaultSettings.ignore !== undefined
                ? familyDefaultSettings.ignore
                : null,
          },
        ))

      dispatch(
        setTransactionDefaultSettings({
          userAccountType: 'user',
          settings:
            await TransactionDefaultSettingsManager.getTransactionDefaultSettings(
              {
                screen: TransactionDefaultSettingsScreenType.User,
              },
            ),
        }),
      )
      dispatch(
        setTransactionDefaultSettings({
          userAccountType: 'family',
          settings:
            await TransactionDefaultSettingsManager.getTransactionDefaultSettings(
              {
                screen: TransactionDefaultSettingsScreenType.Family,
              },
            ),
        }),
      )
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('save')
      NavigationService.goBack()
    }
  }, [dispatch, familyDefaultSettings, userDefaultSettings])

  const initialize = useCallback(async () => {
    try {
      ProgressHUD.show()
      await getWallets()
      await getTransactionDefaultSettings()
    } finally {
      ProgressHUD.dismiss()
    }
  }, [getTransactionDefaultSettings, getWallets])

  useEffect(() => {
    initialize()
  }, [initialize])

  useEffect(() => {
    const listener = OsidoriEvent.addListener(
      'DidSelectSubCategory',
      chooseCategoryHandler,
    )

    return () => {
      OsidoriEvent.removeListener(listener)
    }
  }, [chooseCategoryHandler])

  // wallets
  const walletItems = useMemo(() => {
    let walletItems: Item[]
    if (selectedDefaultSettings?.paymentUserType === PaymentUserType.Family) {
      walletItems =
        wallets.family.map((v) => ({
          label: v.name,
          value: v.id,
        })) || []
    } else if (
      selectedDefaultSettings?.paymentUserType === PaymentUserType.User
    ) {
      walletItems =
        wallets.user.map((v) => ({
          label: v.name,
          value: v.id,
        })) || []
    } else {
      walletItems = []
    }
    walletItems.push({ label: '現金', value: 0 })
    return walletItems
  }, [selectedDefaultSettings?.paymentUserType, wallets.family, wallets.user])

  const selectedWalletId = useMemo(
    () =>
      walletItems.find((v) => v.value === selectedDefaultSettings?.walletId)
        ? selectedDefaultSettings?.walletId
        : 0,
    [selectedDefaultSettings?.walletId, walletItems],
  )

  const onPressTitleHelpIcon = useCallback(
    () => setIsDisplayHelpDialog(!isDisplayHelpDialog),
    [isDisplayHelpDialog],
  )

  const onPressCloseButton = useCallback(
    () => setIsDisplayHelpDialog(false),
    [],
  )

  return (
    <View style={styles.container}>
      <CommonHeader
        title="明細作成のデフォルト設定"
        onPressTitleHelpIcon={onPressTitleHelpIcon}
      />
      <View style={{ flex: 1 }}>
        {tabComponent}
        <ScrollView>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 20,
              justifyContent: 'flex-end',
            }}>
            <Text
              style={{ fontSize: 32, fontWeight: 'normal', color: '#aaaaaa' }}>
              ¥
            </Text>
            <Text
              style={{
                fontSize: 32,
                fontWeight: 'normal',
                color: '#aaaaaa',
                marginLeft: 20,
                marginRight: 20,
              }}>
              -
            </Text>
          </View>
          <View style={styles.wrapItem}>
            <View style={{ flexDirection: 'row' }}>
              {renderItem({
                icon: require('@images/icons/icon-calendar.png'),
                content: '',
                placeholder: '----/--/--',
                columnMargin: 10,
              })}
              {renderItem({
                categoryId:
                  selectedDefaultSettings?.atTransactionCategoryId || undefined,
                content: selectedDefaultSettings?.categoryName2 || '',
                isDisplayRightIcon: true,
                onPress: onPressCategory,
              })}
            </View>
            {renderSettingPaymentUserType()}

            <View
              style={{
                paddingLeft: 25,
                borderBottomWidth: 1,
                borderBottomColor: '#ddd',
                width: '100%',
                height: 50,
              }}>
              <View
                pointerEvents="none"
                style={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'absolute',
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Image
                    source={require('@images/icons/icon-wallet.png')}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: 'contain',
                    }}
                  />
                  <Text style={styles.paymentPlaceText}>支払い元</Text>
                </View>
                {selectedDefaultSettings?.paymentUserType !==
                  PaymentUserType.Partner && (
                  <Image
                    source={require('@images/arrow/icon-arrow-down-gray.png')}
                    style={styles.iconDown}
                  />
                )}
              </View>

              <Pressable
                onPress={() =>
                  NavigationService.navigate('PaymentSource', {
                    paymentUserType: selectedDefaultSettings?.paymentUserType,
                    onSelectWallet: async (wallet: WalletEntity) => {
                      await getWallets()
                      setSelectedDefaultSettings({ walletId: wallet.id })
                    },
                  })
                }
                disabled={
                  selectedDefaultSettings?.paymentUserType ===
                  PaymentUserType.Partner
                }
                style={{ flex: 1, justifyContent: 'center' }}>
                <Text
                  style={{
                    paddingRight: 31,
                    textAlign: 'right',
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: Color.DefaultText,
                  }}>
                  {walletItems.find((v) => v.value === selectedWalletId)?.label}
                </Text>
              </Pressable>
            </View>
            {renderSettingShareView()}
            <View style={styles.wrapContent}>
              <Image
                source={require('@images/icons/icon-shop.png')}
                style={{ width: 24, height: 22, resizeMode: 'contain' }}
              />
              <StyledEmptyText>-</StyledEmptyText>
            </View>
            <View style={[styles.wrapContent, { height: 56 }]}>
              <Image
                source={require('@images/icons/icon-memo.png')}
                style={{
                  width: 24,
                  height: 18,
                  resizeMode: 'contain',
                }}
              />
              <StyledEmptyText>-</StyledEmptyText>
            </View>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <Pressable
                style={styles.col2}
                onPress={() => {
                  setSelectedDefaultSettings({
                    yetPayoff: !selectedDefaultSettings?.yetPayoff,
                  })
                }}>
                <CheckMarkToggleButton
                  isOn={!!selectedDefaultSettings?.yetPayoff}
                  disabled={true}
                />
                <Text
                  style={{
                    marginTop: 5,
                    marginLeft: 10,
                    color: Color.DefaultText,
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}>
                  {'未精算リストに\n入れる'}
                </Text>
              </Pressable>
              <View style={{ width: 15 }} />
              <Pressable
                style={styles.col2}
                onPress={() => {
                  setSelectedDefaultSettings({
                    ignore: !selectedDefaultSettings?.ignore,
                  })
                }}>
                <CheckMarkToggleButton
                  isOn={!selectedDefaultSettings?.ignore}
                  disabled={true}
                />
                <Text
                  style={{
                    marginTop: 5,
                    marginLeft: 10,
                    color: Color.DefaultText,
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}>
                  {'収支の計算対象\nに入れる'}
                </Text>
              </Pressable>
            </View>
          </View>
          <View style={styles.wrapButton}>
            <View style={{ width: 8 }} />
            <AppButton
              title="保存する"
              key="SaveButton"
              onPress={onPressSaveButton}
              loadingKey="save"
              style={styles.submitButton}
            />
          </View>
        </ScrollView>
        {isDisplayHelpDialog && (
          <HelpDialog onPressCloseButton={onPressCloseButton} />
        )}
      </View>
    </View>
  )
}

export default SettingTransactionDefaultScreen

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  wrapTab: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
  wrapTabWithBorder: {
    borderBottomWidth: 1,
    borderColor: Color.Primary,
  },
  wrapTabWithBorderGray: {
    borderBottomWidth: 1,
    borderColor: Color.LightGray,
  },
  wrapItem: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 33,
  },
  titleItem: {
    fontSize: 18,
    fontWeight: 'normal',
    color: '#8E8E8E',
  },
  wrapContent: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    // flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
  },
  content: {
    color: '#3A3A3A',
    flex: 1,
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'normal',
  },
  placeholder: {
    color: '#aaaaaa',
    flex: 1,
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'normal',
  },
  iconDown: {
    width: 16,
    height: 16,
  },
  settingShareView: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
    alignItems: 'center',
  },
  wrapButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginTop: 4,
    marginBottom: 25,
  },
  paymentPlaceText: {
    color: '#9e9e9e',
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  tab: {
    width: '100%',
    flexDirection: 'row',
  },
  tabText: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  tabTextOn: {
    color: Color.Primary,
  },
  tabTextOff: {
    color: Color.Gray,
  },
  submitButton: {
    flex: 1,
    // width: wp('50%') - 16 - 8,
    resizeMode: 'contain',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  col2: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 7,
    paddingBottom: 11,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    alignItems: 'center',
  },
})
