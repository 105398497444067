import Color from '@lib/Color'
import React from 'react'
import {
  NativeSyntheticEvent,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TextInputFocusEventData,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'

export interface AmountInputProps {
  prefix?: string
  value?: number
  onChange?: (amount: number) => void
  maxLength?: number
  style?: TextStyle
  autoFocus?: boolean
  editable?: boolean
  containerStyle?: ViewStyle
  willFocus?: () => boolean
  onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
}

interface State {
  formattedValue: string
  textWidth: number
}

export default class AmountInput extends React.Component<
  AmountInputProps,
  State
> {
  static defaultProps: AmountInputProps = {
    prefix: '¥',
    maxLength: 13,
    style: {},
  }

  state: State = {
    formattedValue: (this.props.value || 0).numberFormat(),
    textWidth: 0,
  }

  private textInput = React.createRef<TextInput>()

  componentDidMount() {
    if (Platform.OS === 'ios' && this.props.autoFocus) {
      setTimeout(this.focus, 400)
    }
  }

  componentDidUpdate() {
    const formattedValue = (this.props.value || 0).numberFormat()
    if (this.state.formattedValue !== formattedValue) {
      this.setState({ formattedValue })
    }
  }

  focus = () => {
    if (this.props.willFocus && !this.props.willFocus()) return
    this.textInput.current?.focus()
  }

  render() {
    return (
      <Pressable onPress={this.focus} style={{ flex: 1 }}>
        <View
          pointerEvents="none"
          style={[styles.container, this.props.containerStyle]}>
          <Text style={[styles.prefix, { ...this.props.style }]}>
            {this.props.prefix}
          </Text>
          <TextInput
            ref={this.textInput}
            keyboardType="numeric"
            onChangeText={this.onChangeText}
            value={this.state.formattedValue}
            maxLength={this.props.maxLength}
            style={[
              styles.textInput,
              this.props.style,
              { width: this.state.textWidth },
            ]}
            editable={this.props.editable}
            returnKeyType="done"
            autoFocus={
              Platform.OS === 'android' ? this.props.autoFocus : undefined
            }
            onFocus={this.props.onFocus}
          />
          <Text
            style={{
              position: 'absolute',
              height: 0,
              fontSize: this.props.style?.fontSize ?? styles.textInput.fontSize,
              overflow: 'hidden',
              opacity: 0,
            }}
            onLayout={(e) => {
              this.setState({
                textWidth: e.nativeEvent.layout.width,
              })
            }}>
            {this.state.formattedValue}
          </Text>
        </View>
      </Pressable>
    )
  }

  private onChangeText = (text: string) => {
    if (!this.props.onChange) return
    const minus = text.startsWith('-') ? -1 : 1
    const value = +(text.replace(/[^\d]/g, '') || '0') * minus
    const formattedValue = value.numberFormat()
    this.setState({ formattedValue })
    this.props.onChange(value)
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginHorizontal: 5,
  },
  prefix: {
    fontSize: 30,
    fontWeight: 'normal',
  },
  textInput: {
    color: Color.Black,
    paddingVertical: 0,
    fontSize: 30,
    fontWeight: 'normal',
    outlineWidth: 0, // notwork?
  },
})
