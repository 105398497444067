import CommonHeader from '@components/CommonHeader'
import CommonListItem from '@components/CommonListItem'
import ItemSeparator from '@components/ItemSeparator'
import Color from '@lib/Color'
import { UIBank } from '@lib/Env'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import {
  RootStackParamList,
  ScreenName,
  WebViewScreenParams,
} from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import {
  FlatList,
  ListRenderItem,
  NativeEventSubscription,
  StyleSheet,
  View,
} from 'react-native'

interface ListItem {
  title: string
  screen: ScreenName
  params?: WebViewScreenParams
}

interface Props {}

class AboutOsidOriScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'AboutOsidOri'>
> {
  private backHandler?: NativeEventSubscription

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.addListener(this.goBack)
  }

  componentWillUnmount() {
    this.backHandler?.remove()
  }

  private goBack = () => {
    const params = this.props.route.params
    if (params?.goBack) {
      params.goBack()
    } else {
      NavigationService.goBack()
    }
  }

  private renderItem: ListRenderItem<ListItem> = ({ item }) => {
    return (
      <CommonListItem
        title={item.title}
        isDisplayArrow={true}
        onPress={() => NavigationService.navigate(item.screen, item.params)}
      />
    )
  }

  private listItems: ListItem[] = [
    {
      title: '利用規約',
      screen: 'WebView',
      params: {
        title: '利用規約',
        uri: 'https://www.osidori.co/terms/',
      },
    },
    {
      title: '個人情報保護方針',
      screen: 'WebView',
      params: {
        title: '個人情報保護方針',
        uri: !UIBank
          ? 'https://www.osidori.co/privacy/'
          : 'https://www.osidori.co/privacy/uibank/',
      },
    },
  ]

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader title="OsidOriについて" onPressLeftButton={this.goBack} />
        <FlatList
          style={styles.flatList}
          keyExtractor={(item) => item.title}
          renderItem={this.renderItem}
          data={this.listItems}
          ItemSeparatorComponent={() => ItemSeparator}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.LightGray,
  },
  flatList: {
    flex: 1,
  },
})

export default AboutOsidOriScreen
