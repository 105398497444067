import { userAvatar } from '@lib/Icon'
import { ProfileState } from '@lib/ProfileManager/types'
import store from '@redux/store'
import React from 'react'
import { ImageBackground, StyleSheet } from 'react-native'
import { connect } from 'react-redux'

export type AvatarBackgroundType = 'user' | 'partner'

interface AvatarBackgroundProps {
  type: AvatarBackgroundType
  children?: React.ReactNode
}

interface StateProps {
  profile: ProfileState
}

class AvatarBackground extends React.Component<
  AvatarBackgroundProps & StateProps
> {
  static defaultProps: AvatarBackgroundProps = {
    type: 'user',
  }

  render() {
    const profile = (this.props.type === 'partner'
      ? {
          userProfile: this.props.profile.partnerProfile,
          partnerProfile: this.props.profile.userProfile,
        }
      : this.props.profile) as ProfileState
    return (
      <ImageBackground
        blurRadius={32}
        source={userAvatar(profile)}
        style={styles.container}>
        {this.props.children}
      </ImageBackground>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    profile: state.profile,
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 175,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default connect(mapStateToProps)(AvatarBackground)
