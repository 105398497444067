import CommonDialog from '@lib/CommonDialog'
import Log from '@lib/Log'
import Osidori from '@lib/Osidori'
import store from '@redux/store'
import { setPlanCode } from '../../redux/actions'

export type UpdatePlansParams = {
  plan?: string // planCode
  ver?: string // planVersion
}

export const updatePlan = async ({ plan, ver = '3' }: UpdatePlansParams) => {
  Log.info('updatePlan', { plan, ver })

  try {
    store.dispatch(setPlanCode(plan, ver))
  } catch (error) {
    CommonDialog.debug({ error })
  } finally {
    Osidori.start()
  }
}
