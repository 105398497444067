import { UserAccountType } from './Account'

export type FinancialAccountType =
  | 'bank'
  | 'card'
  | 'emoney'
  | 'wallet'
  | 'manually_created'
  | 'stock'
  | 'carry_forward'

export interface PlGroupedCategory {
  atGroupedCategoryId: string
  categoryName1: string
  incomeAmount: number
  incomeCount: number
  expenseAmount: number
  expenseCount: number
}

export interface PlCategory {
  atGroupedCategoryId: string
  atTransactionCategoryId: string
  categoryName1: string
  categoryName2: string
  incomeAmount: number
  incomeCount: number
  expenseAmount: number
  expenseCount: number
}

export type FinanceType = 'bank' | 'card' | 'emoney' | 'stock' | 'wallet'

export interface FinancialAccount {
  accountId: number
  financialAccountType: FinancialAccountType
  name: string
  amount?: number // stock以外
  fncId: string
  lastRsltCd: string
  lastRsltMsg: string
  goals?: FinancialAccountGoal[] // bank
  balance?: number // stock
  profitLossAmount?: number // stock
  depositBalance?: number // stock
  userId?: number // wallets以外, group APIのみ
  owner?: boolean // true: 金融機関登録者, false: 金融機関登録者のパートナー
  scrpAt?: string // スクレイピングした日 YYYY-MM-DD HH:mm:ss
  soonScrpApproval?: boolean // 即時更新できるか（パートナーはfalse）
  soonScrpedCount?: number // 実行月内に即時更新した回数
  soonScrpCount?: number | null // 実行月内に即時更新可能な残数 nullの場合は無限
  finance: FinanceType // ※実態。金融機関に所属している財布はwallet
  type: FinanceType // ※所属。金融機関に所属している財布は金融機関になる
  // sort?: number
}

export interface FinancialAccountGoal {
  name: string
  balance?: number
  currentAmount?: number
}

export interface Transaction {
  accountId?: number // レスポンスには無い項目
  transferId?: number // 振替の場合
  atUserBankAccountId?: number
  atUserCardAccountId?: number
  atUserEmoneyServiceAccountId?: number
  atUserEmoneyAccountId?: number // TODO api/v3/emoney-accounts/24480/transactionsのレスポンスがこれ
  atTransactionCategoryId: string
  categoryName1: string
  categoryName2: string
  amount: number
  usedLocation?: string
  usedDate: string
  type: FinancialAccountType // Transactionのtypeは実態(finance)となる
  transactionId: number
  isTransactionShared: boolean
  isAccountShared: boolean
  isIgnored: boolean
  userId: number
  memo: string | null
  walletId?: number | null
  aggregationAmount?: {
    receiptAmount: number
    paymentAmount: number
    transferAmount: number
  }
  userAccountType?: UserAccountType
  payoffId?: number | null
  isYetPayoffed?: boolean
  deleted?: boolean // 振替で追加
  paymentUserType: 1 | 2 | 3 // 1:自分 2:パートナー 3:家族
}

export interface TransferDetail {
  transferId: number
  fromType: string
  fromAccountId: number
  fromAccountName: string
  fromTransactionId: number
  fromTransactionUsedLocation: string
  fromAccountShared: boolean
  fromUpdatable: boolean // true：更新可能、false：更新不可能
  toType: string // manually_created, bank, ...
  toAccountId: number
  toAccountName: string
  toTransactionId: number
  toTransactionUsedLocation: string
  toAccountShared: boolean
  toUpdatable: boolean // ※true：更新可能、false：更新不可能
}

export type TransactionImage = {
  imageUrl: string
  imageType: 'receipt'
  fileName: string
  updatedAt: number
}

export type TransactionImageProps = {
  imageType: 'receipt'
  imageUrl?: string
  fileName?: string
  base64?: string
  delete?: true
}

export interface TransactionDetail {
  paymentName?: string
  paymentUserType: 1 | 2 | 3 // 1:自分 2:パートナー 3:家族
  transactionId: number
  userId: number
  amount: number
  usedDate: string
  usedLocation: string
  memo: string | null
  type: FinancialAccountType // v3
  isAccountShared: boolean
  isTransactionShared: boolean
  isIgnored: boolean
  atGroupedCategoryId: string
  atTransactionCategoryId: string
  categoryName1: string
  categoryName2: string
  transfer?: TransferDetail | null
  payoffId?: number | null
  isYetPayoffed?: boolean
  images: TransactionImage[] | null
  walletId?: number | null
  transferId?: number
  termTransactionId?: number
}

export interface ManualTransactionDetail {
  id: number
  atTransactionCategory: {
    id: number
    categoryName1: string
    categoryName2: string
  }
  usedDate: string
  amount: number
  isTransactionShared: number
  usedLocation: string
}

export interface ProfitLossItem {
  categoryId: string
  amount: number
  ratio: number
  subCategories?: {
    subCategoryId: string
    amount: number
  }[]
}

export interface ProfitLossSummary {
  from: string
  to: string
  incomeAmount: number
  expenseAmount: number
  incomeList: ProfitLossItem[]
  expenseList: ProfitLossItem[]
}

export type TransactionDistributedType = 'family' | 'owner' | 'partner'

type valueOf<T> = T[keyof T]

export const PaymentUserType = {
  User: 1,
  Partner: 2,
  Family: 3,
} as const

export type PaymentUserType = valueOf<typeof PaymentUserType>

export const TransactionDefaultSettingsScreenType = {
  User: 1,
  Family: 2,
} as const

export type TransactionDefaultSettingsScreenType = valueOf<
  typeof TransactionDefaultSettingsScreenType
>
