import { UserAccountType } from '@interfaces/Account'
import { FinancialAccount } from '@interfaces/Financial'
import { AssetProduct } from '@interfaces/Stock'
import API, { APIResponse } from './index'

export interface UpdateStockAccountProps {
  id: number
  name: string
  share: boolean
}

export interface DeleteStockAccountProps {
  id: number
}

export interface GetStockAccountsProps {
  userAccountType: UserAccountType
}

export interface GetStockAccountsResponse {
  stockAccounts: FinancialAccount[]
  errors: {
    code: string
    message: string
    info: string
  }[]
}

export interface GetAssetProductsProps {
  userAccountType: UserAccountType
  accountId: number
}

export interface GetAssetProductsResponse {
  assetProducts: AssetProduct[]
}

class Stock {
  updateStockAccount = (
    props: UpdateStockAccountProps,
  ): Promise<APIResponse> => {
    const { id, name, share } = props
    return API.request(
      'PUT',
      `/api/v2/user/stock-accounts/${id}`,
      {
        stockAccounts: {
          name,
          share,
        },
      },
      true,
    )
  }

  deleteStockAccount = (
    props: DeleteStockAccountProps,
  ): Promise<APIResponse> => {
    const { id } = props
    return API.request('DELETE', `/api/v2/user/stock-accounts/${id}`, true)
  }

  getStockAccounts = (props: GetStockAccountsProps): Promise<APIResponse> => {
    const { userAccountType } = props
    return API.request(
      'GET',
      `/api/v2/${userAccountType.userAccountPath()}/stock-accounts`,
      { sort: true },
      true,
    )
  }

  getAssetProducts = (props: GetAssetProductsProps): Promise<APIResponse> => {
    const { userAccountType, accountId } = props
    return API.request(
      'GET',
      `/api/v2/${userAccountType.userAccountPath()}/stock-accounts/${accountId}/asset-products`,
      {},
      true,
    )
  }
}

export default new Stock()
