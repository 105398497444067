import {
  faAmbulance,
  faAnalytics,
  faAward,
  faBabyCarriage,
  faBackpack,
  faBallotCheck,
  faBedAlt,
  faBeer,
  faBell,
  faBicycle,
  faBone,
  faBook,
  faBreadLoaf,
  faBriefcase,
  faCalendarAlt,
  faCamera,
  faChair,
  faChalkboardTeacher,
  faCheck,
  faCheeseburger,
  faCloud,
  faCommentAltCheck,
  faCreditCard,
  faGamepadAlt,
  faGift,
  faHandHoldingHeart,
  faIcons,
  faLaughSquint,
  faLeaf,
  faLockAlt,
  faMobile,
  faPaperPlane,
  faPen,
  faPhoneLaptop,
  faPlusCircle,
  faRingsWedding,
  faSmoking,
  faStar,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons'
import { UserAccountType } from '@interfaces/Account'
import { APIError } from '@lib/api'
import AccountTracker from '@lib/api/AccountTracker'
import * as Icon from '@lib/Icon'
import { ImageSourcePropType } from 'react-native'
import store from '../redux/store'
import { CreateCategoryProps } from './api/AccountTracker'
import Category, {
  AddCategoryProps,
  // DeleteCategoryProps,
  GetCategoriesProps,
  GetCategoriesResponse,
} from './api/Category'

export type Category = {
  categoryId: string
  name: string
  sort: number
  hide: boolean
  permission: number
  icon?: string
  color?: string
  subCategories: CategoryUnit[]
}

export type CategoryUnit = {
  categoryId: string
  subCategoryId: string
  name: string
  permission: number
  hide: boolean
  sort: number
  customIconName?: string
  customIconColor?: string
}

export interface CategoryIconInfo {
  topCategory: boolean
  categoryId: string
  categoryName: string
  color: string
  // default icon
  imageSource?: ImageSourcePropType
  // custom icon
  customIconName?: string
}

class CategoryManager {
  userCategories: Category[] = []
  userSubCategories: CategoryUnit[] = []
  familyCategories: Category[] = []
  familySubCategories: CategoryUnit[] = []
  categoryIcons: { [categoryId: string]: CategoryIconInfo } = {}

  categories = (userAccountType: UserAccountType) =>
    userAccountType === 'user' ? this.userCategories : this.familyCategories

  subCategories = (userAccountType: UserAccountType) =>
    userAccountType === 'user'
      ? this.userSubCategories
      : this.familySubCategories

  private color = (categoryId: string): string | undefined => {
    if (!+categoryId) {
      const iconInfo = this.categoryIcons[categoryId]
      return iconInfo ? iconInfo.color : undefined
    }
    switch (+categoryId) {
      case 39:
        return '#34C5A2' // 食費
      case 40:
        return '#FFD028' // 日用品
      case 41:
        return '#47D3FF' // 交通費
      case 42:
        return '#FF83AF' // 趣味・娯楽
      case 43:
        return '#A2A0FF' // 衣服・美容
      case 44:
        return '#DC7AFF' // 交際費
      case 45:
        return '#83DBF7' // 健康・医療
      case 46:
        return '#D88E58' // 教育・教養
      case 47:
        return '#E2BE87' // 現金・カード
      case 48:
        return '#FF9A16' // 水道・光熱費
      case 49:
        return '#7E929D' // 通信費
      case 50:
        return '#6CDF49' // 住宅
      case 51:
        return '#BDB679' // 車・バイク
      case 52:
        return '#7DAEA0' // 税・社会保障
      case 53:
        return '#90E6B8' // 保険
      case 54:
        return '#FF6D6D' // 特別な支出
      case 55:
        return '#22ABDB' // 資産形成
      case 56:
        return '#B9BDC1' // その他
      case 57:
        return '#A9EE39' // 未分類
      case 58:
        return '#8BE994' // 入金
      default:
        return '#A9EE39' // 未分類と同じにしておく
    }
  }

  private defaultIcon = (
    categoryId: string | number | undefined,
  ): ImageSourcePropType | undefined => {
    if (!categoryId || !+categoryId) return undefined
    switch (+categoryId) {
      case 39: // 食費
        return Icon.CategoryIcon.Food as never
      case 40: // 日用品
        return Icon.CategoryIcon.Grocery as never
      case 41: // 交通費
        return Icon.CategoryIcon.Traffic as never
      case 42: // 趣味・娯楽
        return Icon.CategoryIcon.Hobby as never
      case 43: // 衣服・美容
        return Icon.CategoryIcon.Cloth as never
      case 44: // 交際費
        return Icon.CategoryIcon.Outgoing as never
      case 45: // 健康・医療
        return Icon.CategoryIcon.Medical as never
      case 46: // 教育・教養
        return Icon.CategoryIcon.Education as never
      case 47: // 現金・カード
        return Icon.CategoryIcon.Cash as never
      case 48: // 水道・光熱費
        return Icon.CategoryIcon.Supply as never
      case 49: // 通信費
        return Icon.CategoryIcon.Shipping as never
      case 50: // 住宅
        return Icon.CategoryIcon.House as never
      case 51: // 車・バイク
        return Icon.CategoryIcon.Car as never
      case 52: // 税・社会保障
        return Icon.CategoryIcon.Tax as never
      case 53: // 保険
        return Icon.CategoryIcon.Insurance as never
      case 54: // 特別な支出
        return Icon.CategoryIcon.Special as never
      case 55: // 資産掲載
        return Icon.CategoryIcon.CategoryAssetformation as never
      case 56: // その他
        return Icon.CategoryIcon.Others as never
      case 57: // 未分類
        return Icon.CategoryIcon.Uncategorized as never
      case 58: // 入金
        return Icon.CategoryIcon.Income as never
      default:
        return undefined
    }
  }

  customIcon = (categoryId: string): IconDefinition | undefined => {
    switch (categoryId) {
      case 'breadLoaf':
        return faBreadLoaf
      case 'handHoldingHeart':
        return faHandHoldingHeart
      case 'gamepadAlt':
        return faGamepadAlt
      case 'gift':
        return faGift
      case 'creditCard':
        return faCreditCard
      case 'cheeseburger':
        return faCheeseburger
      case 'ringsWedding':
        return faRingsWedding
      case 'book':
        return faBook
      case 'analytics':
        return faAnalytics
      case 'phoneLaptop':
        return faPhoneLaptop
      case 'beer':
        return faBeer
      case 'ambulance':
        return faAmbulance
      case 'bicycle':
        return faBicycle
      case 'calendarAlt':
        return faCalendarAlt
      case 'pen':
        return faPen
      case 'bedAlt':
        return faBedAlt
      case 'bone':
        return faBone
      case 'chalkboardTeacher':
        return faChalkboardTeacher
      case 'ballotCheck':
        return faBallotCheck
      case 'briefcase':
        return faBriefcase
      case 'chair':
        return faChair
      case 'smoking':
        return faSmoking
      case 'camera':
        return faCamera
      case 'mobile':
        return faMobile
      case 'lockAlt':
        return faLockAlt
      case 'babyCarriage':
        return faBabyCarriage
      case 'icons':
        return faIcons
      case 'backpack':
        return faBackpack
      case 'bell':
        return faBell
      case 'check':
        return faCheck
      case 'commentAltCheck':
        return faCommentAltCheck
      case 'leaf':
        return faLeaf
      case 'plusCircle':
        return faPlusCircle
      case 'award':
        return faAward
      case 'cloud':
        return faCloud
      case 'laughSquint':
        return faLaughSquint
      case 'paperPlane':
        return faPaperPlane
      case 'star':
        return faStar
      default:
        return undefined
    }
  }

  categoriesExpense = (userAccountType: UserAccountType) => {
    const categories =
      userAccountType === 'user' ? this.userCategories : this.familyCategories
    return categories.filter((v) => +v.categoryId !== 58) // 58=入金
  }

  categoriesIncome = (userAccountType: UserAccountType) => {
    const categories =
      userAccountType === 'user' ? this.userCategories : this.familyCategories
    return categories.filter((v) => +v.categoryId === 58) // 58=入金
  }

  isIncomeCategory = (categoryId: string) => +categoryId === 58 // 58=入金

  isExpenceCategory = (categoryId: string) => +categoryId !== 58 // 58=入金

  isIncomeBySubCategoryId = (subCateogryId: string) =>
    +(this.categoryBySubCategoryId(subCateogryId)?.categoryId || '0') === 58 // 58=入金

  isExpenseBySubCategoryId = (subCateogryId: string) =>
    !this.isIncomeBySubCategoryId(subCateogryId)

  categoryIdByName = (categoryName: string) => {
    const categories =
      store.getState().account.accountMode === 'user'
        ? this.userCategories
        : this.familyCategories
    const category = categories.find((v) => v.name === categoryName)
    return category ? category.categoryId : 37 // 37=未分類
  }

  categoryByCategoryId = (
    userAccounType?: UserAccountType,
    categoryId?: string,
  ) => {
    if (userAccounType) {
      return this.categories(userAccounType).find(
        (v) => v.categoryId === categoryId,
      )
    } else {
      return (
        this.categories('family').find((v) => v.categoryId === categoryId) ||
        this.categories('user').find((v) => v.categoryId === categoryId)
      )
    }
  }

  subCategoryById = (accounType: UserAccountType, subCategoryId: string) => {
    return this.subCategories(accounType).find(
      (v) => v.categoryId === subCategoryId,
    )
  }

  categoryBySubCategoryId = (subCategoryId: string) => {
    // const subCategories =
    //   store.getState().account.accountMode === 'user'
    //     ? this.userSubCategories
    //     : this.familySubCategories
    return (
      // tslint:disable-next-line: triple-equals
      this.userSubCategories.find((v) => v.subCategoryId == subCategoryId) ||
      // tslint:disable-next-line: triple-equals
      this.familySubCategories.find((v) => v.subCategoryId == subCategoryId)
    )
  }

  fetchCategories = async (props?: GetCategoriesProps) => {
    const groupedCategories = await this.getCategories(props)
    const categories: Category[] = []
    const subCategories: CategoryUnit[] = []

    groupedCategories.forEach((groupedCategory) => {
      const categoryUnits: CategoryUnit[] =
        groupedCategory.atTransactionCategories.map((v) => ({
          categoryId: `${groupedCategory.atGroupedCategoryId}`,
          subCategoryId: v.atTransactionCategoryId,
          permission: v.permission,
          sort: v.sort,
          name: v.atTransactionCategoryCategoryName1,
          hide: v.hide,
          customIconName: groupedCategory.icon,
          customIconColor: groupedCategory.color,
        }))
      const category: Category = {
        categoryId: `${groupedCategory.atGroupedCategoryId}`,
        name: groupedCategory.atGroupedCategoryName,
        sort: groupedCategory.sort,
        hide: groupedCategory.hide,
        permission: groupedCategory.permission,
        icon: groupedCategory.icon,
        color: groupedCategory.color,
        subCategories: categoryUnits,
      }
      categories.push(category)
      categoryUnits.forEach((v) => subCategories.push(v))
    })

    categories.forEach((category) => {
      // icon info
      this.categoryIcons[category.categoryId] = {
        topCategory: true,
        categoryId: category.categoryId,
        categoryName: category.name,
        color: category.color || this.color(category.categoryId) || '#000',
        imageSource:
          this.defaultIcon(category.categoryId) ||
          this.defaultIcon(category.icon),
        customIconName: category.icon,
      }

      // subCategory icon info
      category.subCategories.forEach((subCategory) => {
        this.categoryIcons[subCategory.subCategoryId] = {
          topCategory: false,
          categoryId: subCategory.subCategoryId,
          categoryName: subCategory.name,
          color: category.color || this.color(category.categoryId) || '#000',
          imageSource:
            this.defaultIcon(category.categoryId) ||
            this.defaultIcon(category.icon),
          customIconName: category.icon,
        }
      })
    })

    const accountType = props?.accountType
      ? props?.accountType
      : store.getState().account.accountMode
    if (accountType === 'user') {
      this.userCategories = categories
      this.userSubCategories = subCategories
    } else {
      this.familyCategories = categories
      this.familySubCategories = subCategories
    }
  }

  getCategoryIconInfo = (
    categoryId: string | number,
  ): CategoryIconInfo | undefined => this.categoryIcons[categoryId]

  getCategories = async (props: GetCategoriesProps = {}) => {
    const response = await Category.getCategories({
      accountType: props.accountType || store.getState().account.accountMode,
    })
    if (response.ok) {
      return (response.json as GetCategoriesResponse).app.atGroupedCategories
    } else {
      throw new APIError(response)
    }
  }

  updateSort = async (props: {
    accountType?: UserAccountType
    categories: Category[]
  }) => {
    const response = await Category.updateSort({
      accountType: props.accountType || store.getState().account.accountMode,
      atGroupedCategories: props.categories.map((v, index) => ({
        atGroupedCategoryId: v.categoryId,
        sort: 1 + index,
        hide: v.hide ? true : false,
      })),
    })
    if (!response.ok) throw new APIError(response)
  }

  updateTransactionSort = async (props: {
    accountType?: UserAccountType
    categoryId: string
    subCategories: CategoryUnit[]
  }) => {
    const response = await Category.updateTransactionSort({
      accountType: props.accountType || store.getState().account.accountMode,
      categoryId: props.categoryId,
      atTransactionCategories: props.subCategories.map((v, index) => ({
        atTransactionCategoryId: v.subCategoryId,
        sort: 1 + index,
        hide: v.hide ? true : false,
      })),
    })
    if (!response.ok) throw new APIError(response)
  }

  addSubCategory = async (props: AddCategoryProps) => {
    const response = await Category.addCategory(props)
    if (!response.ok) throw new APIError(response)
  }

  // deleteSubCategory = async (props: DeleteCategoryProps) => {
  //   const response = await Category.deleteCategory(props)
  //   if (!response.ok) throw new APIError(response)
  // }

  addCategory = async (props: CreateCategoryProps) => {
    const response = await AccountTracker.createCategory(props)
    if (!response.ok) throw new APIError(response)
  }
}

export default new CategoryManager()
