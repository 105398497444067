import { UserAccountType } from '@interfaces/Account'
import { APIError } from '@lib/api'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import * as CarryForwardAPI from './api'
import {
  GetCarryForwardSettingsResponse,
  GetCarryForwardTransactionResponse,
  UpdateCarryForwardSettingsProps,
  UpdateCarryForwardTransactionProps,
} from './types'

export const getCarryForwardSettings = async (
  userAccountType: UserAccountType,
) => {
  const response = await CarryForwardAPI.getCarryForwardSettings(
    userAccountType,
  )
  if (!response.ok) throw new APIError(response)
  return (response.json as GetCarryForwardSettingsResponse).carryForwardSetting
}

export const updateCarryForwardSettings = async (
  props: UpdateCarryForwardSettingsProps,
) => {
  const response = await CarryForwardAPI.updateCarryForwardSettings(props)
  if (!response.ok) throw new APIError(response)
}

export const getCarryForwardTransaction = async (transactionId: number) => {
  const response = await CarryForwardAPI.getCarryForwardTransaction(
    transactionId,
  )
  if (!response.ok) throw new APIError(response)
  return (response.json as GetCarryForwardTransactionResponse).transaction
}

export const updateCarryForwardTransaction = async (
  props: UpdateCarryForwardTransactionProps,
) => {
  const response = await CarryForwardAPI.updateCarryForwardTransaction(props)
  if (!response.ok) throw new APIError(response)

  OsidoriEvent.emit(OsidoriEventName.DidUpdateTransaction, {
    ...props,
  })
}
