import { GoalEntity } from '@interfaces/Goal'
import Color from '@lib/Color'
import * as Icon from '@lib/Icon'
import { useMemo } from 'react'
import { View } from 'react-native'
import { ProgressCircle } from 'react-native-svg-charts'
import styled from 'styled-components/native'

export type GoalSummaryProgressViewProps = {
  goal: GoalEntity
}

const GoalSummaryProgressView = ({ goal }: GoalSummaryProgressViewProps) => {
  const { color, icon, message } = useMemo(
    () => ({
      color: Color.goal(goal.currentProgressIcon),
      icon: Icon.goalFaceIcon(goal.currentProgressIcon),
      message:
        goal.currentProgressIcon === 'normal'
          ? '貯金に入金をして、夫婦で目標貯金をはじめましょう♪'
          : '目標貯金の達成に向かって、夫婦で一緒にがんばりましょう♪',
    }),
    [goal.currentProgressIcon],
  )

  return (
    <StyledRootView>
      <View>
        <StyledProgressCircle
          progress={goal.progress ?? 0}
          progressColor={color}
        />
        <StyledFaceIconView>
          <StyledFaceIconImage source={icon} />
        </StyledFaceIconView>
      </View>
      <StyledDetailView>
        <StyledDetailTitleText>目標の進捗率</StyledDetailTitleText>
        <StyledProgressText color={color}>
          {Math.round((goal.progress ?? 0) * 100)}%
        </StyledProgressText>
        <StyledMessageText>{message}</StyledMessageText>
      </StyledDetailView>
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  backgroundColor: Color.LightGray,
  marginTop: 10,
  marginBottom: 20,
  padding: 16,
  borderRadius: 12,
  flexDirection: 'row',
  alignItems: 'center',
})

const StyledProgressCircle = styled(ProgressCircle)({
  height: 94,
  width: 94,
})

const StyledFaceIconView = styled.View({
  position: 'absolute',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})

const StyledFaceIconImage = styled.Image.attrs({
  resizeMode: 'contain',
})({
  width: 47,
  height: 47,
})

const StyledDetailView = styled.View({
  flex: 1,
  paddingLeft: 16,
})

const StyledDetailTitleText = styled.Text({
  fontSize: 13,
  fontWeight: 'bold',
  color: Color.DefaultText,
})

const StyledProgressText = styled.Text<{ color: string }>(({ color }) => ({
  marginTop: 4,
  fontWeight: 'bold',
  fontSize: 20,
  color,
}))

const StyledMessageText = styled.Text({
  fontSize: 13,
  fontWeight: 'normal',
  color: Color.LightBlack,
  marginTop: 4,
})

export default GoalSummaryProgressView
