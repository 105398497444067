import AdView from '@components/AdView'
import Avatar from '@components/Avatar'
import CommonHeader from '@components/CommonHeader'
import FamilyShareToggleButton from '@components/FamilyShareToggleButton'
import TintedImage from '@components/TintedImage'
import { accountModeSelector } from '@lib/AccountManager/actions'
import Color from '@lib/Color'
import DateUtils from '@lib/DateUtilsV1'
import GlobalFontSizes from '@lib/GlobalFontSizes'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { PremiumPlanPageIndexCalendar } from '../PremiumPlan'
import { updateTransactionDisplayType } from '../Transactions/redux/actions'
import CalendarScreen from './CalendarScreen'
import TransactionsUsagePopup from './TransactionsUsagePopup'
import {
  updateCalendarUserAccountType,
  updateCalenderDate,
  updateNeedUpdateCalendarUserAccountType,
  updateShowTransactions,
} from './redux/actions'
import { CalendarUserAccountType } from './redux/types'

const Tab = createMaterialTopTabNavigator()

const CalendarContainerScreen: React.FC<
  StackScreenProps<RootStackParamList, 'CalendarContainerScreen'>
> = ({ navigation, route }) => {
  const dispatch = useDispatch()

  const yearMonth = useSelector(({ calendar }: RootState) => calendar.yearMonth)
  const startDate = useSelector(({ calendar }: RootState) => calendar.startDate)
  const endDate = useSelector(({ calendar }: RootState) => calendar.endDate)
  const isShowTransactions = useSelector(
    ({ calendar }: RootState) => calendar.isShowTransactions,
  )

  const calendarUserAccounType = useSelector(
    ({ calendar }: RootState) => calendar.calendarUserAccountType,
  )

  const totalPaymentAmount = useSelector(
    ({ calendar }: RootState) => calendar.totalPaymentAmount,
  )

  const totalReceiptAmount = useSelector(
    ({ calendar }: RootState) => calendar.totalReceiptAmount,
  )

  const userProfile = useSelector(
    ({ profile }: RootState) => profile.userProfile,
  )

  const userAccountType = useSelector(accountModeSelector)

  const selectedMainScreenTab = useSelector(
    ({ main }: RootState) => main.selectedTab,
  )

  const plSettings = useSelector(({ profile }: RootState) => profile.plSettings)

  const transactionsDisplayType =
    useSelector(({ transactions }: RootState) => transactions.displayType) ||
    SessionManager.getTransactionDisplayType()

  const [isTransactionTabDisplay, setIsTransactionTabDisplay] = useState(
    SessionManager.isTransactionTabDisplayCompleted(),
  )

  const onPressTransactionTabPopupCloseButton = useCallback(() => {
    setIsTransactionTabDisplay(true)
    SessionManager.setTransactionTabDisplayCompleted(true)
  }, [])

  useEffect(() => {
    const today = route.params?.date
      ? moment(route.params.date, 'YYYYMMDD')
      : moment()
    // const firstDay = moment().set('day', 1)

    // const userYearMonth = firstDay
    // const familyYearMonth = firstDay
    // const allYearMonth = firstDay

    const { startDate: userStartDate, endDate: userEndDate } =
      DateUtils.getRangeOfMonths(today, false, 'user')

    const { startDate: familyStartDate, endDate: familyEndDate } =
      DateUtils.getRangeOfMonths(today, false, 'family')

    const { startDate: allStartDate, endDate: allEndDate } =
      DateUtils.getRangeOfMonths(today, false, 'all')

    dispatch(updateCalenderDate('user', today, userStartDate, userEndDate))
    dispatch(
      updateCalenderDate('family', today, familyStartDate, familyEndDate),
    )
    dispatch(updateCalenderDate('all', today, allStartDate, allEndDate))
  }, [dispatch, plSettings, route.params?.date])

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', () => {
      if (calendarUserAccounType === 'all') {
        NavigationService.navigate('CalendarAll')
      } else {
        NavigationService.navigate(
          userAccountType === 'family'
            ? 'CalendarFamilyScreen'
            : 'CalendarUserScreen',
        )
      }
      setTimeout(
        () => dispatch(updateNeedUpdateCalendarUserAccountType(false)),
        500,
      )
    })

    const unsubscribeBlur = navigation.addListener('blur', () => {
      dispatch(updateNeedUpdateCalendarUserAccountType(true))
    })

    return () => {
      unsubscribeFocus()
      unsubscribeBlur()
    }
  }, [userAccountType, calendarUserAccounType, navigation, dispatch])

  useEffect(() => {
    dispatch(updateCalendarUserAccountType(userAccountType))
    if (
      selectedMainScreenTab === 'TransactionsTab' &&
      transactionsDisplayType === 'calendar'
    ) {
      NavigationService.navigate(
        userAccountType === 'family'
          ? 'CalendarFamilyScreen'
          : 'CalendarUserScreen',
      )
    }
    // Exclude selectedMainScreenTab, transactionsDisplayType,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userAccountType])

  const switchTransactions = useCallback(() => {
    dispatch(updateTransactionDisplayType('transactions'))
    dispatch(updateShowTransactions(false))
    SessionManager.setTransactionDisplayType('transactions')
  }, [dispatch])

  const renderRightButton = useCallback(() => {
    return (
      <TouchableOpacity
        onPress={switchTransactions}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 8,
          paddingHorizontal: 10,
          paddingBottom: 5,
        }}>
        <TintedImage
          style={{
            tintColor: Color.White,
            width: 18,
            height: 18,
          }}
          source={require('@images/tab/tab-transactions.png')}
        />
        <Text
          style={{
            fontSize: 11,
            fontWeight: 'normal',
            color: Color.White,
            marginTop: 3,
            paddingHorizontal: 10,
          }}>
          取引履歴
        </Text>
      </TouchableOpacity>
    )
  }, [switchTransactions])

  const onPressNextMonthButton = useCallback(() => {
    const userNextYearMonth = moment(yearMonth.user).add(1, 'month')
    const familyNextYearMonth = moment(yearMonth.family).add(1, 'month')
    const allNextYearMonth = moment(yearMonth.all).add(1, 'month')

    const { startDate: userStateDate, endDate: userEndDate } =
      DateUtils.getRangeOfMonths(
        moment(endDate.user).add(10, 'day'),
        false,
        'user',
      )

    const { startDate: familyStateDate, endDate: familyEndDate } =
      DateUtils.getRangeOfMonths(
        moment(endDate.family).add(10, 'day'),
        false,
        'family',
      )

    const { startDate: allStateDate, endDate: allEndDate } =
      DateUtils.getRangeOfMonths(
        moment(endDate.all).add(10, 'day'),
        false,
        'all',
      )

    dispatch(
      updateCalenderDate('user', userNextYearMonth, userStateDate, userEndDate),
    )
    dispatch(
      updateCalenderDate(
        'family',
        familyNextYearMonth,
        familyStateDate,
        familyEndDate,
      ),
    )
    dispatch(
      updateCalenderDate('all', allNextYearMonth, allStateDate, allEndDate),
    )
  }, [
    yearMonth.user,
    yearMonth.family,
    yearMonth.all,
    endDate.user,
    endDate.family,
    endDate.all,
    dispatch,
  ])

  const onPressPrevMonthButton = useCallback(() => {
    const userPrevYearMonth = moment(yearMonth.user).subtract(1, 'month')
    const familyPrevYearMonth = moment(yearMonth.family).subtract(1, 'month')
    const allPrevYearMonth = moment(yearMonth.all).subtract(1, 'month')

    const { startDate: userStateDate, endDate: userEndDate } =
      DateUtils.getRangeOfMonths(
        moment(startDate.user).subtract(10, 'day'),
        false,
        'user',
      )

    const { startDate: familyStateDate, endDate: familyEndDate } =
      DateUtils.getRangeOfMonths(
        moment(startDate.family).subtract(10, 'day'),
        false,
        'family',
      )

    const { startDate: allStateDate, endDate: allEndDate } =
      DateUtils.getRangeOfMonths(
        moment(startDate.all).subtract(10, 'day'),
        false,
        'all',
      )

    dispatch(
      updateCalenderDate('user', userPrevYearMonth, userStateDate, userEndDate),
    )
    dispatch(
      updateCalenderDate(
        'family',
        familyPrevYearMonth,
        familyStateDate,
        familyEndDate,
      ),
    )
    dispatch(
      updateCalenderDate('all', allPrevYearMonth, allStateDate, allEndDate),
    )
  }, [
    yearMonth.user,
    yearMonth.family,
    yearMonth.all,
    startDate.user,
    startDate.family,
    startDate.all,
    dispatch,
  ])

  const header = useMemo(
    () => (
      <HeaderContainer>
        <TouchableOpacity
          onPress={onPressPrevMonthButton}
          style={{ padding: 8 }}>
          <Image
            style={styles.arrowIcon}
            source={require('@images/buttons/arrow/button-arrow-left.png')}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <HeaderPeriodContainer>
          <HeaderDateText>
            {yearMonth[calendarUserAccounType]?.format('YYYY年M月')}
          </HeaderDateText>
          <HeaderPeriodText>
            集計期間 {startDate[calendarUserAccounType]?.format('M/D')}〜
            {endDate[calendarUserAccounType]?.format('M/D')}
          </HeaderPeriodText>
        </HeaderPeriodContainer>
        <TouchableOpacity
          onPress={onPressNextMonthButton}
          style={{ padding: 8 }}>
          <Image
            style={styles.arrowIcon}
            source={require('@images/buttons/arrow/button-arrow-right.png')}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </HeaderContainer>
    ),
    [
      onPressPrevMonthButton,
      yearMonth,
      calendarUserAccounType,
      startDate,
      endDate,
      onPressNextMonthButton,
    ],
  )

  const listHeader = useMemo(() => {
    return (
      <ListHeaderContainer>
        {calendarUserAccounType === 'user' && (
          <FamilyShareToggleButtonContainer>
            <FamilyShareToggleButton />
          </FamilyShareToggleButtonContainer>
        )}
        {calendarUserAccounType === 'all' ? (
          <AllAvatarContainer>
            <Avatar size={wp(10)} />
            <PlusText>＋</PlusText>
            <Avatar size={wp(10)} type="couple" />
          </AllAvatarContainer>
        ) : (
          <Avatar
            type={calendarUserAccounType === 'user' ? 'user' : 'couple'}
            size={wp(10)}
          />
        )}
        <ListHeaderDetailContainer>
          <ListHeaderDetailItem>
            <ListHeaderDetailTitle>収入</ListHeaderDetailTitle>
            <ListHeaderDetailValue type="income">
              {totalReceiptAmount[calendarUserAccounType].jpCurrency()}
            </ListHeaderDetailValue>
          </ListHeaderDetailItem>
          <ListHeaderDetailItem>
            <ListHeaderDetailTitle>支出</ListHeaderDetailTitle>
            <ListHeaderDetailValue type="expense">
              {totalPaymentAmount[calendarUserAccounType].jpCurrency()}
            </ListHeaderDetailValue>
          </ListHeaderDetailItem>
          <ListHeaderDetailItem>
            <ListHeaderDetailTitle>収支</ListHeaderDetailTitle>
            <ListHeaderDetailValue type="total">
              {(
                totalPaymentAmount[calendarUserAccounType] +
                totalReceiptAmount[calendarUserAccounType]
              ).jpCurrency()}
            </ListHeaderDetailValue>
          </ListHeaderDetailItem>
        </ListHeaderDetailContainer>
      </ListHeaderContainer>
    )
  }, [totalPaymentAmount, totalReceiptAmount, calendarUserAccounType])

  const changeCalendarType = useCallback(
    (calendarUserAccountType: CalendarUserAccountType) => {
      dispatch(updateCalendarUserAccountType(calendarUserAccountType))

      if (calendarUserAccountType === 'all' && userProfile?.rank === 'free') {
        TrackingUtils.repro.track(
          '【Screen】upper limit_all calendar',
          'Screen',
        )
      }
    },
    [dispatch, userProfile?.rank],
  )

  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: `Calendar-${userAccountType === 'family' ? 'Family' : 'User'}`,
        title: `${
          userAccountType === 'family' ? '家族' : '個人'
        }_取引履歴 カレンダー画面`,
      })
    }
  }, [isFocused, userAccountType])

  return (
    <RootContainer>
      <CommonHeader
        leftButtonType={
          selectedMainScreenTab === 'TransactionsTab' ? 'menu' : 'stack'
        }
        title="カレンダー"
        renderRightButton={
          selectedMainScreenTab === 'TransactionsTab'
            ? renderRightButton
            : undefined
        }
      />

      <View style={{ flex: 1 }}>
        {isShowTransactions || header}
        {isShowTransactions || listHeader}
        <Tab.Navigator
          backBehavior="none"
          tabBarOptions={{
            activeTintColor: Color.Primary,
            inactiveTintColor: Color.Gray,
            indicatorStyle: {
              backgroundColor: Color.Primary,
              height: 1,
            },
            labelStyle: {
              fontWeight: 'bold',
              fontSize: wp(3.5),
              height: wp(10),
            },
            style: {
              elevation: 0,
              backgroundColor: 'white',
              borderBottomWidth: Platform.OS === 'android' ? 1 : 0,
              borderBottomColor: Color.LightGray,
              height: isShowTransactions ? 0 : wp(10),
            },
          }}
          initialRouteName={
            userAccountType === 'family'
              ? 'CalendarFamilyScreen'
              : 'CalendarUserScreen'
          }>
          <Tab.Screen
            name="CalendarUserScreen"
            component={CalendarScreen}
            options={{ title: ProfileManager.getName('user') }}
            initialParams={{
              calendarUserAccountType: 'user',
              selectedDate: route.params?.date,
            }}
            listeners={() => ({
              tabPress: () => changeCalendarType('user'),
              swipeEnd: () => changeCalendarType('user'),
            })}
          />
          <Tab.Screen
            name="CalendarFamilyScreen"
            component={CalendarScreen}
            options={{ title: '家族' }}
            initialParams={{
              calendarUserAccountType: 'family',
              selectedDate: route.params?.date,
            }}
            listeners={() => ({
              tabPress: () => changeCalendarType('family'),
              swipeEnd: () => changeCalendarType('family'),
            })}
          />
          <Tab.Screen
            name="CalendarAll"
            component={CalendarScreen}
            options={{ title: 'すべて' }}
            initialParams={{
              calendarUserAccountType: 'all',
              selectedDate: route.params?.date,
            }}
            listeners={() => ({
              tabPress: () => changeCalendarType('all'),
              swipeEnd: () => changeCalendarType('all'),
            })}
          />
        </Tab.Navigator>
        {userProfile?.rank === 'free' && (
          <AdView
            type="320x50"
            premiulPageIndex={PremiumPlanPageIndexCalendar}
          />
        )}
        {isTransactionTabDisplay || (
          <TransactionsUsagePopup
            onPressCloseButton={onPressTransactionTabPopupCloseButton}
          />
        )}
      </View>
    </RootContainer>
  )
}

const RootContainer = styled.View`
  flex: 1;
`

const ListHeaderContainer = styled.View`
  background-color: ${Color.GrayVeryLight};
  align-items: center;
  padding: ${wp(2)}px;
`

const FamilyShareToggleButtonContainer = styled.View`
  position: absolute;
  top: ${wp(2)}px;
  right: ${wp(4)}px;
`

const AllAvatarContainer = styled.View`
  flex-direction: row;
  align-items: center;
`

const PlusText = styled.Text`
  font-size: ${wp(7)}px;
  font-weight: normal;
  color: ${Color.Gray};
  max-height: ${wp(10)}px;
  padding-horizontal: ${wp(1.5)}px;
`

const ListHeaderDetailContainer = styled.View`
  flex-direction: row;
  width: 100%;
  margin-top: ${wp(2)}px;
  padding-horizontal: ${wp(5)}px;
  justify-content: space-between;
`

const ListHeaderDetailItem = styled.View`
  align-items: center;
`

const ListHeaderDetailTitle = styled.Text`
  color: ${Color.DefaultText};
  font-size: ${wp(3)}px;
  font-weight: normal;
`

const ListHeaderDetailValue = styled.Text<{
  type: 'income' | 'expense' | 'total'
}>`
  color: ${({ type }) => {
    if (type === 'income') return Color.Primary
    if (type === 'expense') return Color.Orange
    return Color.DefaultText
  }};
  font-size: ${wp(4)}px;
  font-weight: bold;
  margin-top: ${wp(1)}px;
`

const HeaderContainer = styled.View`
  flex: 1;
  min-height: 60px;
  max-height: 60px;
  padding-horizontal: 8px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`

const HeaderPeriodContainer = styled.View`
  align-items: center;
`

const HeaderDateText = styled.Text`
  font-size: 16px;
  color: ${Color.DefaultText};
  font-weight: bold;
`

const HeaderPeriodText = styled.Text`
  padding-top: 4px;
  font-size: 12px;
  font-weight: normal;
  color: ${Color.Gray};
`

const styles = StyleSheet.create({
  container: {
    // flex: 1,
  },
  thisMonthsBalanceView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  wrapMonthlyBalanceViewLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  balanceTime: {
    color: Color.Gray,
    fontSize: 15,
    fontWeight: 'normal',
    marginLeft: 8,
  },
  titleTotalAssets: {
    color: '#555555',
    fontSize: 15,
    fontWeight: 'bold',
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
  dateText: {
    padding: 8,
    fontSize: 16,
    fontWeight: 'normal',
  },
  familyShareOnText: {
    color: Color.Primary,
    marginRight: 8,
    fontSize: GlobalFontSizes.Small,
    fontWeight: 'bold',
  },
  familyShareOffText: {
    color: Color.Gray,
    marginRight: 8,
    fontSize: GlobalFontSizes.Small,
    fontWeight: 'bold',
  },
  absolute: {
    position: 'absolute',
    top: wp(10),
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
    backgroundColor: '#33333366',
    // overflow: 'hidden',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 20,
  },
})

export default CalendarContainerScreen
