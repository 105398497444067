import Color from '@lib/Color'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import styled from 'styled-components/native'

export type SectionListHeader2Props = {
  title?: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  rightComponent?: JSX.Element
}

const SectionListHeader2 = ({
  title,
  style,
  textStyle,
  rightComponent,
}: SectionListHeader2Props) => {
  if (title === undefined) return null

  return (
    <StyledView style={style}>
      <StyledText style={textStyle}>{title}</StyledText>
      {rightComponent}
    </StyledView>
  )
}

const StyledView = styled.View({
  backgroundColor: '#f0f0f0',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
})

const StyledText = styled.Text({
  paddingVertical: 8,
  paddingHorizontal: 10,
  fontSize: 14,
  fontWeight: 'bold',
  backgroundColor: '#f0f0f0',
  color: Color.Black,
})

export default SectionListHeader2
