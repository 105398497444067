import API, { APIResponse } from '@lib/api'
import {
  CreateWalletProps,
  DeleteWalletProps,
  GetWalletsProps,
  GetWalletTransactionsProps,
  UpdateWalletProps,
} from './types'

export const createWallet = (
  props: CreateWalletProps,
): Promise<APIResponse> => {
  const { userAccountType, name, balance, type } = props
  return API.request(
    'POST',
    `/api/v2/${userAccountType.userAccountPath()}/wallets`,
    {
      wallets: {
        name,
        balance,
        type,
      },
    },
    true,
  )
}

export const updateWallet = (
  props: UpdateWalletProps,
): Promise<APIResponse> => {
  const { id, ...params } = props
  return API.request(
    'PUT',
    `/api/v2/user/wallets/${id}`,
    {
      wallets: params,
    },
    true,
  )
}

export const deleteWallet = (
  props: DeleteWalletProps,
): Promise<APIResponse> => {
  return API.request('DELETE', `/api/v2/user/wallets/${props.id}`, true)
}

export const getWallets = ({
  userAccountType,
  sort,
}: GetWalletsProps): Promise<APIResponse> => {
  return API.request(
    'GET',
    `/api/v2/${userAccountType.userAccountPath()}/wallets`,
    { sort },
    true,
  )
}

export const getWalletTransactions = (
  props: GetWalletTransactionsProps,
): Promise<APIResponse> => {
  const { id, from, to, amount } = props
  return API.request(
    'GET',
    `/api/v3/wallets/${id}/transactions`,
    { from, to, amount },
    true,
  )
}
