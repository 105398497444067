import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TintedImage from '@components/TintedImage'
import { UserAccountType } from '@interfaces/Account'
import { FinancialAccount } from '@interfaces/Financial'
import Color from '@lib/Color'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import NavigationService from '@lib/NavigationService'
import {
  RootStackParamList,
  TransferSelectTransactionCallback,
  TransferSelectTransactionScreenParams,
} from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import { Component } from 'react'
import {
  ActivityIndicator,
  Image,
  ImageSourcePropType,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { connect } from 'react-redux'

interface ListItem {
  financialAccount: FinancialAccount
  icon: ImageSourcePropType
}

interface StateProps {
  financial: FinancialState
}

interface State {
  isLoading: boolean
  refreshing: boolean
}

class TransferSelectFinancialAccountScreen extends Component<
  StateProps &
    StackScreenProps<RootStackParamList, 'TransferSelectFinancialAccount'>,
  State
> {
  state: State = {
    isLoading: true,
    refreshing: false,
  }

  private userAccountType!: UserAccountType
  private transferDirection!: 'from' | 'to'
  private isDisplayWalletsOnly!: boolean
  private isSelectFinancialAccountOnly!: boolean
  private amount?: number
  private callback!: TransferSelectTransactionCallback

  componentDidMount() {
    const {
      userAccountType,
      transferDirection,
      isDisplayWalletsOnly,
      isSelectFinancialAccountOnly,
      amount,
      callback,
    } = this.props.route.params

    this.userAccountType = userAccountType
    this.transferDirection = transferDirection
    this.isDisplayWalletsOnly = !!isDisplayWalletsOnly
    this.isSelectFinancialAccountOnly = !!isSelectFinancialAccountOnly
    this.amount = amount
    this.callback = callback

    this.props.navigation.addListener('focus', async () => {
      this.setState({ isLoading: true })
      await FinancialManager.fetchAssets(userAccountType)
      await FinancialManager.fetchCardAccounts(userAccountType)
      this.setState({ isLoading: false })
    })
  }

  private sectionHeader = (info: { section: SectionListData<ListItem> }) => (
    <SectionListHeader
      title={info.section.title}
      style={{ fontWeight: 'normal' }}
    />
  )

  private onPressItem = (item: ListItem) => {
    if (this.isSelectFinancialAccountOnly) {
      this.callback({
        userAccountType: this.userAccountType,
        transferDirection: this.transferDirection,
        financialAccountType: item.financialAccount.finance,
        financialAccountId: item.financialAccount.accountId,
        financialAccountName: item.financialAccount.name,
      })
      NavigationService.goBack()
    } else {
      const params: TransferSelectTransactionScreenParams = {
        userAccountType: this.userAccountType,
        transferDirection: this.transferDirection,
        financialAccount: item.financialAccount,
        amount: this.amount,
        callback: this.callback,
      }
      // TODO
      NavigationService.navigate('TransferSelectTransaction', params)
    }
  }

  private renderItem: SectionListRenderItem<ListItem> = ({ item }) => {
    return (
      <TouchableOpacity
        style={styles.item}
        onPress={() => this.onPressItem(item)}>
        <View style={styles.row}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image source={item.icon} style={styles.iconLabel} />
            <Text style={styles.textItem}>{item.financialAccount.name}</Text>
          </View>
          {this.isDisplayWalletsOnly || (
            <TintedImage
              source={require('@images/arrow/icon-arrow-white-small.png')}
              style={styles.arrow}
            />
          )}
        </View>
      </TouchableOpacity>
    )
  }

  private sections = (): SectionListData<ListItem>[] => {
    const sections: SectionListData<ListItem>[] = []

    const banks =
      (this.isDisplayWalletsOnly
        ? this.props.financial.assets.banks?.filter(
            (v) => v.finance === 'wallet',
          )
        : this.props.financial.assets.banks) || []

    if (banks.length > 0) {
      const section: SectionListData<ListItem> = {
        title: '銀行口座',
        data: banks.map((bank) => ({
          financialAccount: bank,
          financialAccountType: 'bank',
          icon: require('@images/icons/icon-bank.png'),
        })),
      }
      sections.push(section)
    }

    const emoneys =
      (this.isDisplayWalletsOnly
        ? this.props.financial.assets.emoneys?.filter(
            (v) => v.finance === 'wallet',
          )
        : this.props.financial.assets.emoneys) || []
    if (emoneys.length > 0) {
      const section: SectionListData<ListItem> = {
        title: '電子マネー',
        data: emoneys.map((emoney) => ({
          financialAccount: emoney,
          icon: require('@images/icons/icon-ic-card.png'),
        })),
      }
      sections.push(section)
    }

    const cards =
      this.props.financial.cards?.filter((v) => v.finance === 'wallet') || []

    if (this.transferDirection === 'from') {
      this.props.financial.cards
        ?.filter((v) => v.finance !== 'wallet')
        .forEach((v) => cards.push(v))
      if (cards.length > 0) {
        const section: SectionListData<ListItem> = {
          title: 'カード',
          data: cards.map((card) => ({
            financialAccount: card,
            icon: require('@images/icons/icon-credit-card.png'),
          })),
        }
        sections.push(section)
      }
    }

    const stocks =
      this.props.financial.investments?.filter((v) => v.finance === 'wallet') ||
      []
    if (this.transferDirection === 'from') {
      this.props.financial.investments
        ?.filter((v) => v.finance !== 'wallet')
        .forEach((v) => stocks.push(v))
    }
    if (stocks.length > 0) {
      const section: SectionListData<ListItem> = {
        title: '証券/投資信託',
        data: stocks.map((stock) => ({
          financialAccount: stock,
          icon: require('@images/icons/icon-bond.png'),
        })),
      }
      sections.push(section)
    }

    const wallets = this.props.financial.wallets || []
    if (wallets.length > 0) {
      const section: SectionListData<ListItem> = {
        title: '現金',
        data: wallets.map((wallet) => ({
          financialAccount: {
            accountId: wallet.id,
            financialAccountType: 'wallet',
            type: 'wallet',
            finance: 'wallet',
            name: wallet.name,
            amount: wallet.amount,
            fncId: '',
            lastRsltCd: '0',
            lastRsltMsg: '',
            goals: wallet.goals,
          },
          icon: require('@images/icons/icon-wallet.png'),
        })),
      }
      sections.push(section)
    }

    return sections
  }

  render() {
    const sections = this.sections()

    return (
      <View style={styles.container}>
        <CommonHeader
          title={
            this.transferDirection === 'from' ? '振替元を選択' : '振替先を選択'
          }
        />
        <View style={{ flex: 1, justifyContent: 'space-between' }}>
          {this.state.isLoading ? (
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <ActivityIndicator style={{ alignSelf: 'center' }} />
            </View>
          ) : (
            <View style={{ flex: 1 }}>
              {sections.length > 0 ? (
                <SectionList
                  keyExtractor={(item) => item.financialAccount.fncId}
                  renderItem={this.renderItem}
                  renderSectionHeader={this.sectionHeader}
                  sections={sections}
                  extraData={this.props.financial}
                  ItemSeparatorComponent={() => ItemSeparator}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.refreshing}
                      onRefresh={async () => {
                        this.setState({ refreshing: true })
                        await FinancialManager.fetchAssets(this.userAccountType)
                        this.setState({ refreshing: false })
                      }}
                    />
                  }
                  ListFooterComponent={() => <View style={{ height: 24 }} />}
                />
              ) : (
                <View
                  style={{
                    alignItems: 'center',
                    marginHorizontal: wp(8),
                  }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginTop: wp(16),
                      marginBottom: wp(10),
                      fontSize: 16,
                      color: Color.DefaultText,
                    }}>
                    振替用の口座登録がありません
                  </Text>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: 'normal',
                      color: Color.DefaultText,
                    }}>
                    {
                      '振替の設定はアプリへの「金融機関※」か「財布」の登録が必要です。\n'
                    }
                    {'※銀行口座・カード・電子マネー\n'}
                    {'\n'}
                    {'\n'}
                    {'（振替の例1）\n'}
                    {'　銀行から5万円を引き出し財布に入金\n'}
                    {'　・「銀行A」から¥50,000ATM引き出し\n'}
                    {'　・「財布B」へ¥50,000の入金\n'}
                    {'\n'}
                    {'（振替の例2）\n'}
                    {'　クレカから電子マネーへ1万円をチャージ\n'}
                    {'　・「クレカA」から¥10,000引き落とし\n'}
                    {'　・「電子マネーB」へ¥10,000チャージ'}
                  </Text>
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  disabledView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ffffff80',
  },
  errorView: {
    borderWidth: 1,
    borderColor: '#eeeeee',
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
  },
  cautionImage: {
    marginEnd: 10,
    resizeMode: 'contain',
  },
  errorText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#3a3a3a',
  },
  label: {
    color: '#555555',
    paddingLeft: 15,
    fontSize: 15,
    fontWeight: 'normal',
    paddingTop: 15,
  },
  textTotal: {
    color: Color.Primary,
    textAlign: 'right',
    paddingRight: 15,
    fontSize: 30,
    fontWeight: 'bold',
    paddingBottom: 15,
  },
  title: {
    color: '#555555',
    fontSize: 13,
    fontWeight: 'normal',
    backgroundColor: '#f0f0f0',
    paddingLeft: 15,
    paddingVertical: 10,
  },
  item: {
    padding: 15,
    paddingVertical: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 44,
  },
  iconLabel: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  textItem: {
    fontSize: 13,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingLeft: 5,
    paddingRight: 10,
  },
  profitLossLabel: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingRight: 8,
  },
  jpyBottom: {
    marginTop: 15,
    justifyContent: 'flex-end',
  },
  jpyInvestment: {
    marginTop: 4,
    marginRight: 18,
    justifyContent: 'flex-end',
  },
  jpy: {
    fontSize: 22,
    color: '#3a3a3a',
    fontWeight: '700',
  },
  jpyPlus: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Primary,
  },
  jpyMinus: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Orange,
  },
  jpyOffset: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Gray,
  },
  arrow: {
    width: 16,
    height: 16,
    tintColor: Color.Gray,
  },
  goalsView: {
    paddingTop: 16,
    paddingBottom: 8,
    paddingLeft: 35,
    paddingRight: 20,
  },
  goalsItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
  },
  goalsItemLabel: {
    flex: 1,
    marginLeft: 8,
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
  }
}

export default connect(mapStateToProps)(TransferSelectFinancialAccountScreen)
