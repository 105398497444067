import ArrowIcon from '@components/ArrowIcon'
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core'
import Color from '@lib/Color'
import { FAIcon } from '@lib/FAIcon'
import { Image, ImageSourcePropType, StyleProp, ViewStyle } from 'react-native'
import styled from 'styled-components/native'

const StyledTouchableOpacity = styled.TouchableOpacity`
  padding: 0 16px;
  background-color: white;
  height: 68px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledRootView = styled.View`
  padding: 0 16px;
  background-color: white;
  height: 68px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledMainView = styled.View<{ subTitle?: boolean }>`
  flex-direction: row;
  align-items: ${({ subTitle }) => (subTitle ? 'flex-start' : 'center')};
  flex: 1;
`

const StyleTitlesView = styled.View`
  flex: 1;
  margin-left: 12px;
`

const StyledIconImage = styled.Image.attrs({
  resizeMode: 'contain',
})<{ disabledTintColor?: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  ${({ disabledTintColor }) =>
    disabledTintColor ? '' : `tint-color: ${Color.Gray};`}
`

const StyledFontAwesomeIcon = styled(FAIcon).attrs({
  color: Color.Gray,
})`
  margin-left: 8px;
  width: 20px;
  height: 20px;
`

const StyledTitleText = styled.Text<{ subTitle?: boolean }>`
  font-size: 15px;
  color: ${Color.DefaultText};
  font-weight: ${({ subTitle }) => (subTitle ? 'bold' : 'normal')};
`

const StyledSubTitleText = styled.Text`
  margin-top: 6px;
  font-size: 12px;
  font-weight: normal;
  color: ${Color.Gray};
`

const StyledSwitch = styled.Switch`
  margin-left: 10px;
`

const StyledValueText = styled.Text`
  margin-right: 2px;
  font-size: 12px;
  font-weight: normal;
  color: ${Color.Gray};
`

const StyledDisabledView = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff80;
`

export type MenuListItemProps = {
  title?: string
  subTitle?: string
  iconImageSource?: ImageSourcePropType
  iconDefinition?: IconProp | IconDefinition
  isDisplayArrow?: boolean
  isDisplaySwitch?: boolean
  switchValue?: boolean
  value?: string
  disabled?: boolean
  disabledTintColor?: boolean
  onPress?: () => void
  onSwitchValueChange?: (value: boolean) => void
  style?: StyleProp<ViewStyle>
}

const MenuListItem = ({
  title,
  subTitle,
  iconImageSource,
  iconDefinition,
  isDisplayArrow,
  isDisplaySwitch,
  switchValue,
  value,
  disabled,
  disabledTintColor,
  onPress,
  onSwitchValueChange,
  style,
}: MenuListItemProps) => {
  const content = (
    <>
      <StyledMainView subTitle={!!subTitle}>
        {iconImageSource &&
          (typeof iconImageSource === 'number' || disabledTintColor ? (
            <StyledIconImage
              source={iconImageSource}
              disabledTintColor={disabledTintColor}
            />
          ) : (
            <Image
              source={iconImageSource}
              style={{
                width: 20,
                height: 20,
                resizeMode: 'contain',
                marginLeft: 8,
                tintColor: '#808080',
              }}
            />
          ))}
        {iconDefinition && (
          <StyledFontAwesomeIcon icon={iconDefinition} size={24} />
        )}
        <StyleTitlesView>
          <StyledTitleText subTitle={!!subTitle}>{title}</StyledTitleText>
          {subTitle && <StyledSubTitleText>{subTitle}</StyledSubTitleText>}
        </StyleTitlesView>
      </StyledMainView>
      {isDisplaySwitch && (
        <StyledSwitch onValueChange={onSwitchValueChange} value={switchValue} />
      )}
      {value && <StyledValueText>{value}</StyledValueText>}
      {isDisplayArrow && <ArrowIcon />}
      {disabled && <StyledDisabledView />}
    </>
  )

  const isTouchableRow = !disabled && !!onPress

  return isTouchableRow ? (
    <StyledTouchableOpacity onPress={onPress} style={style}>
      {content}
    </StyledTouchableOpacity>
  ) : (
    <StyledRootView style={style}>{content}</StyledRootView>
  )
}

export default MenuListItem
