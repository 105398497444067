import {
  AccountTrackerAddType,
  AccountTrackerData,
} from '@interfaces/AccountTracker'
import { APIError } from '@lib/api'
import AccountTracker, {
  AtSyncProps,
  GetAtUrlResponse,
} from '@lib/api/AccountTracker'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import NavigationService from '@lib/NavigationService'
import { ScreenName } from '@navigation/Screens'
import { measure } from '@Screen/Debug/SystemReportScreen'
import queryString from 'query-string'

class AccountTrackerManager {
  getAccountTrackerData = async (props?: {
    fncId?: string
    act?: 'scrp' | 'modify'
    share?: boolean
  }): Promise<AccountTrackerData> => {
    const process = props?.fncId ? undefined : 'add'

    const response = await AccountTracker.getAtUrl({
      fncId: props?.fncId,
      process,
      share: props?.share,
    })
    if (response.ok) {
      const json: GetAtUrlResponse = response.json
      json.app.fncId = props?.fncId
      json.app.act = props?.act
      return json.app
    } else {
      throw new APIError(response)
    }
  }

  getCheckLinkUrl = async (fncId: string): Promise<string> => {
    const response = await AccountTracker.getAtUrl({ fncId, type: 'manage' })
    if (response.ok) {
      const json: GetAtUrlResponse = response.json
      const data = json.app
      return `${data.url}?CHNL_ID=${data.chnlId}&TOKEN_KEY=${data.tokenKey}&FNC_ID=${fncId}&TO_URL=osidori://navigation?pop=2`
    } else {
      throw new APIError(response)
    }
  }

  makeAccountTrackerUri = (data: AccountTrackerData) => {
    // TODO: switch UIBank, Standard
    const toUrl = NativeApp
      ? 'osidori://close-at'
      : `${window.location.href}close-at`
    const appUrl = toUrl

    const params = {
      CHNL_ID: data.chnlId,
      TOKEN_KEY: data.tokenKey,
      // TO_URL: スクレイピングで外部サイト（ブラウザ）を開かない画面からの戻り用URL
      TO_URL: toUrl,
      // APP_URL: 外部サイトを開いたブラウザからアプリに戻るためののURL
      APP_URL: appUrl,
    } as { [key: string]: string }
    if (data.fncId) {
      params.FNC_ID = data.fncId
    }
    if (data.act) {
      params.ACT = data.act
    }
    if (data.addType) {
      params.ADD_TYPE = data.addType
      // https://osidori.slack.com/archives/GNX3XMMLY/p1673850642324959?thread_ts=1661921637.349709&cid=GNX3XMMLY
      params.CORP_YN = 'N'
    }
    return data.url + '?' + queryString.stringify(params)
  }

  addFinancialAccount = async ({
    addType,
    backButtonTitle,
    navigateBackScreen,
    share,
  }: {
    addType?: AccountTrackerAddType
    backButtonTitle: string
    navigateBackScreen: ScreenName
    share: boolean
  }) => {
    const accountTrackerData = await this.getAccountTrackerData({ share })
    NavigationService.navigate('NavigateAccountTracker', {
      accountTrackerData: {
        ...accountTrackerData,
        addType,
      },
      backButtonTitle,
      navigateBackScreen,
    })
  }

  updateFinancialAccount = async ({
    fncId,
    act = 'scrp',
    backButtonTitle,
    navigateBackScreen,
  }: {
    fncId: string
    act?: 'scrp' | 'modify'
    backButtonTitle: string
    navigateBackScreen: ScreenName
  }) => {
    try {
      const accountTrackerData = await this.getAccountTrackerData({
        fncId,
        act,
      })
      // NavigationService.navigate('WaitExternalService', accountTrackerData)
      NavigationService.navigate('NavigateAccountTracker', {
        accountTrackerData,
        backButtonTitle,
        navigateBackScreen,
      })
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  syncAccount = async (props: AtSyncProps = {}) => {
    const response = await measure(
      'AtSync',
      async () =>
        await AccountTracker.atSync({
          onlyAccounts: props.onlyAccounts,
          fncType: props.fncType === 'invest' ? 'stock' : props.fncType,
        }),
    )
    if (!response.ok) {
      throw new APIError(response)
    }
  }
}

export default new AccountTrackerManager()
