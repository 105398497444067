import { Pressable } from 'react-native'
import styled from 'styled-components/native'

export const RootContainer = styled(Pressable)`
  /* background-color: #eeeeee80; */
  /* width: 100%; */
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* padding-right: 25px; */
`

export const PrefixText = styled.Text`
  color: black;
  padding-vertical: 0;
  font-size: 30px;
  font-weight: normal;
  margin-right: 20px;
`

export const NumberText = styled.Text`
  color: black;
  padding-vertical: 0;
  font-size: 30px;
  font-weight: normal;
`
