import { UserAccountType } from '@interfaces/Account'
import { TransactionDefaultSettings } from '@lib/api/TransactionDefaultSettings/types'
import { RootState } from '@redux/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type State = {
  user: TransactionDefaultSettings
  family: TransactionDefaultSettings
}

type Reducer = {
  setTransactionDefaultSettings: (
    state: State,
    action: PayloadAction<{
      userAccountType: UserAccountType
      settings: TransactionDefaultSettings
    }>,
  ) => void
}

const initialSettings: TransactionDefaultSettings = {
  atTransactionCategoryId: null,
  paymentUserType: null,
  walletId: null,
  transactionShare: null,
  yetPayoff: null,
  ignore: null,
}

const initialState: State = {
  user: initialSettings,
  family: initialSettings,
}

const slice = createSlice<State, Reducer>({
  name: 'transactionDefaultSettings',
  initialState,
  reducers: {
    setTransactionDefaultSettings: (state, { payload }) => {
      state[payload.userAccountType] = payload.settings
    },
  },
})

export const { setTransactionDefaultSettings } = slice.actions
export const selectTransactionDefaultSettings = (root: RootState) =>
  root.settingTransactionDefault

export default slice.reducer
