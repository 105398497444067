import DateUtilsV1 from '@lib/DateUtilsV1'
import { RootState } from '@redux/store'

export const thisMonthBalanceSelector = (
  year?: number,
  month?: number,
  accountMode?: 'user' | 'family'
) => (state: RootState) => {
  const plAccountMode = accountMode || state.account.accountMode

  if (year && month) {
    return state.financial[plAccountMode].monthsPL[
      year + ('0' + month).slice(2)
    ]
  } else {
    return state.financial[plAccountMode].monthsPL[DateUtilsV1.monthKey()]
  }
}
