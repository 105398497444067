import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { accountModeSelector } from '@lib/AccountManager/actions'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import SettingCountPeriodScreen from '../SettingCountPeriodScreen'
import { setPeriodSetting } from './slice'

export const RootContainer = styled.View`
  flex: 1;
`
const Tab = createMaterialTopTabNavigator()

const SettingPeriodTabScreen: React.FC = () => {
  const dispatch = useDispatch()

  const userAccountType = useSelector(accountModeSelector)

  const fetchPlSettings = useCallback(async () => {
    try {
      const plSettings = await ProfileManager.fetchPlSettings()
      dispatch(
        setPeriodSetting({
          userAccountType: 'user',
          settings: {
            plPeriodDate: plSettings.plPeriodDate || 1,
            plType: plSettings.plType,
          },
        }),
      )
      dispatch(
        setPeriodSetting({
          userAccountType: 'family',
          settings: {
            plPeriodDate: plSettings.groupPlPeriodDate || 1,
            plType: plSettings.groupPlType,
          },
        }),
      )
    } catch {
      CommonDialog.showMessage('収支の集計期間設定の取得に失敗しました。', () =>
        NavigationService.goBack(),
      )
    }
  }, [dispatch])

  useEffect(() => {
    fetchPlSettings()
  }, [fetchPlSettings])

  return (
    <RootContainer>
      <CommonHeader title="家計簿の集計期間設定" />
      <Tab.Navigator
        backBehavior="none"
        tabBarOptions={TabBarOptions}
        initialRouteName={
          userAccountType === 'family'
            ? 'SettingPeriodFamily'
            : 'SettingPeriodUser'
        }>
        <Tab.Screen
          name="SettingPeriodFamily"
          component={SettingCountPeriodScreen}
          initialParams={{ accountType: 'family' }}
          options={{
            title: '家族画面',
          }}
        />
        <Tab.Screen
          name="SettingPeriodUser"
          component={SettingCountPeriodScreen}
          initialParams={{ accountType: 'user' }}
          options={{
            title: '個人画面',
          }}
        />
      </Tab.Navigator>
    </RootContainer>
  )
}

export default SettingPeriodTabScreen
