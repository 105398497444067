import { Image } from '@components'
import AdView from '@components/AdView'
import AppButton from '@components/AppButton'
import CategoryImage from '@components/CategoryImage'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent from '@lib/OsidoriEvent'
import ProfileManager from '@lib/ProfileManager'
import store from '@redux/store'
import { PureComponent } from 'react'
import { Modal, StyleSheet, Text, View } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { navigatePremiumPlanLP } from '../../PremiumPlan'
import { PayoffListScreenProps } from './PayoffListScreen'

interface SettledTransactionDialogParams {
  date: string
  categoryId: string
  amount: string
  isPaymentFromPartnerToOwner: boolean
}

interface Props {}

interface State {
  visible: boolean
  params?: SettledTransactionDialogParams
}

class SettledTransactionDialog extends PureComponent<Props, State> {
  private static current?: SettledTransactionDialog

  public static show = (
    visible = true,
    params: SettledTransactionDialogParams,
  ) => {
    SettledTransactionDialog.current?.setState({ visible, params })
  }

  state: State = {
    visible: false,
  }

  componentDidMount() {
    SettledTransactionDialog.current = this
  }

  componentWillUnmount() {
    SettledTransactionDialog.current = undefined
  }

  private close = () => {
    this.setState({ visible: false }, () => {
      OsidoriEvent.emit('ClosePayoffScreen')
      if (store.getState().main.selectedTab !== 'HomeTab') {
        NavigationService.popToTop()
      }
      const payoffListScreenProps: PayoffListScreenProps = {
        tab: 'NotSettled',
      }
      NavigationService.navigate('PayoffList', payoffListScreenProps)
    })
  }

  private goToTransactions = () => {
    this.setState({ visible: false }, () => {
      OsidoriEvent.emit('ClosePayoffScreen')
      if (store.getState().main.selectedTab !== 'HomeTab') {
        NavigationService.popToTop()
      }
      const payoffListScreenProps: PayoffListScreenProps = {
        tab: 'Settled',
      }
      NavigationService.navigate('PayoffList', payoffListScreenProps)
    })
  }

  render() {
    if (!this.state.params) return null
    const { params } = this.state
    const category = CategoryManager.categoryBySubCategoryId(params.categoryId)
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.visible}>
        <View style={styles.container}>
          <View style={styles.bodyPopup}>
            <Text style={styles.titleText}>精算が完了しました</Text>
            <View style={styles.itemsView}>
              <View style={styles.itemView}>
                <Image style={styles.icon} source={Icon.Calendar} />
                <Text style={styles.itemText}>{params.date}</Text>
              </View>
              <View style={styles.itemView}>
                <Image style={styles.icon} source={Icon.Yen} />
                <Text style={styles.itemText}>{params.amount}</Text>
              </View>
              <View style={styles.itemView}>
                <CategoryImage categoryId={category?.categoryId} />
                <Text style={styles.itemText}>{category?.name}</Text>
              </View>
              <View style={styles.itemView}>
                <Image
                  style={{
                    height: 20,
                    resizeMode: 'contain',
                    tintColor: '#888888',
                  }}
                  source={
                    params.isPaymentFromPartnerToOwner
                      ? require('@assets/images/icons/icon-woman-small.png')
                      : require('@assets/images/icons/icon-woman-small.png')
                  }
                />
                <Text style={styles.itemText}>
                  {params.isPaymentFromPartnerToOwner
                    ? `${ProfileManager.getName(
                        'partner',
                      )}から${ProfileManager.getName('user')}へ支払い`
                    : `${ProfileManager.getName(
                        'user',
                      )}から${ProfileManager.getName('partner')}へ支払い`}
                </Text>
              </View>
            </View>
            {store.getState().profile.userProfile?.rank === 'free' && (
              <View
                style={{
                  backgroundColor: '#f0f0f0',
                  marginBottom: 20,
                }}>
                <AdView
                  type="Transaction300x250"
                  width={wp(80)}
                  onPressHiddenButton={() => {
                    NavigationService.goBack()
                    navigatePremiumPlanLP({ planCode: '001' })
                  }}
                />
              </View>
            )}
            <View style={styles.wrapButton}>
              <AppButton
                type="secondary"
                title="完了"
                style={[styles.appButton, { marginRight: 4 }]}
                textStyle={styles.appButtonText}
                onPress={this.close}
              />
              <AppButton
                type="secondary"
                title="履歴を確認"
                style={[styles.appButton, { marginHorizontal: 4 }]}
                textStyle={styles.appButtonText}
                onPress={this.goToTransactions}
              />
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.6)',
    paddingHorizontal: wp('10%'),
    justifyContent: 'center',
  },
  titleText: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333333',
    width: '100%',
    textAlign: 'center',
  },
  itemsView: {
    borderColor: '#eeeeee',
    borderWidth: 1,
    borderRadius: 6,
    marginHorizontal: 20,
    marginTop: 15,
    marginBottom: 20,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  itemView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  itemText: {
    marginLeft: 5,
    color: '#3a3a3a',
    fontSize: 14,
    fontWeight: 'normal',
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  bodyPopup: {
    backgroundColor: Color.White,
    borderRadius: 10,
    overflow: 'hidden',
  },
  welcome: {
    color: '#69C900',
    fontSize: wp('5%'),
    textAlign: 'center',
    marginTop: wp('2%'),
    fontWeight: 'bold',
  },
  textContent: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: wp('3%'),
    fontWeight: 'normal',
  },
  button: {
    backgroundColor: Color.Primary,
    flex: 1,
    height: 50,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleButton: {
    color: Color.White,
    fontSize: wp('4%'),
    fontWeight: 'bold',
  },
  iconClose: {
    width: 22,
    height: 22,
  },
  wrapIconClose: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  imgNoticeDialog: {
    width: '100%',
    height: wp('30%'),
    marginTop: 10,
  },
  seeYou: {
    color: '#666666',
    fontSize: wp('4%'),
    fontWeight: 'normal',
  },
  appButton: {
    flex: 1,
    height: 40,
  },
  appButtonText: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  wrapButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    backgroundColor: Color.White,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
})

export default SettledTransactionDialog
