import Color from '@lib/Color'
import { ActivityIndicator } from 'react-native'

export default () => (
  <ActivityIndicator
    color={Color.Gray}
    style={{
      position: 'absolute',
      alignSelf: 'center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }}
  />
)
