import Alert from '@components/Alert'
import CommonHeader, {
  CommonHeaderLeftButtonType,
} from '@components/CommonHeader'
import { PremiumView } from '@components/PremiumView'
import { UserAccountType } from '@interfaces/Account'
import CategoryManager, { Category } from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import RNProgressHud from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DragListView from 'react-drag-listview'
import { Platform, ScrollView, Text, View } from 'react-native'
import styled from 'styled-components/native'
import { CreateCategoryTopScreenNavigationParams } from '../CreateCategoryTopScreen'
import { navigatePremiumPlanLP } from '../PremiumPlan'
import { AddCaregoryButton } from './AddCategoryButton'
import {
  TopCategoryListItem,
  TopCategoryListItemProps,
} from './TopCategoryListItem'
import { PremiumView2 } from './functions'

export type CategoryPickerType = 'list' | 'sort'

export type CategoryPickerStackScreenProps = {
  isSortOnly?: boolean
  isSelectable?: boolean // サブカテゴリーの選択可否
  userAccountType: UserAccountType
  leftButtonType?: CommonHeaderLeftButtonType
}

export interface ListItemProps {
  drag?: () => void
  sortable: boolean
  onPress?: () => void
  category: Category
  onPressEye?: (hide: boolean) => void
  disabled?: boolean
}

const EditCategoryTopScreen: React.FC<
  StackScreenProps<RootStackParamList, 'EditCategoryTop'>
> = ({
  navigation,
  route: {
    params: { isSortOnly, userAccountType, isSelectable, leftButtonType },
  },
}) => {
  const isFocused = useIsFocused()

  const [editType, setEditType] = useState<CategoryPickerType>(
    isSortOnly ? 'sort' : 'list',
  )
  const [categories, setCategories] = useState<Category[]>([])
  const [isDisplayedPremiumPlanView, setIsDisplayedPremiumPlanView] =
    useState(false)
  const [premiumPlanErrorType, setPremiumPlanErrorType] = useState<
    'sort' | 'hide'
  >()

  const [isPurchaseCompleted, setIsPurchaseCompleted] = useState(false)

  const renderItem = useCallback(
    (item: ListItemProps) => {
      if (item.category.hide) return <></>

      return (
        <TopCategoryListItem
          {...item}
          drag={() => {
            if (store.getState().profile.userProfile?.rank === 'free') {
              setIsDisplayedPremiumPlanView(true)
              setPremiumPlanErrorType('sort')
              TrackingUtils.repro.track(
                '【Screen】upper limit_sort big category',
                'Screen',
              )

              TrackingUtils.ga.pageview({
                page: 'Upperlimit-SortCategory',
                title: '大カテゴリ並び替え時のタッチ画面',
              })
            } else {
              item.drag?.()
            }
          }}
          onPressEye={
            editType === 'sort'
              ? (hide) => {
                  Alert.alert(
                    '',
                    `「${item.category.name}」カテゴリーを${
                      +item.category.categoryId
                        ? '非表示にします。'
                        : '削除します。'
                    }\nよろしいですか？`,
                    [
                      {
                        text: 'キャンセル',
                      },
                      {
                        text: 'はい',
                        onPress: () => {
                          const index = categories.findIndex(
                            (v) => v.categoryId === item.category.categoryId,
                          )
                          if (index === undefined) return
                          const newCategories = [...categories]
                          newCategories[index] = {
                            categoryId: item.category.categoryId,
                            name: item.category.name,
                            sort: item.category.sort,
                            subCategories: item.category.subCategories,
                            hide,
                            permission: item.category.permission,
                            icon: item.category.icon,
                            color: item.category.color,
                          }
                          setCategories(newCategories)
                        },
                      },
                    ],
                  )
                }
              : undefined
          }
          sortable={editType === 'sort'}
        />
      )
    },
    [editType],
  )

  // const renderItem = useCallback(
  //   ({ item, drag }: RenderItemParams<TopCategoryListItemProps>) => {
  //     if (item.category.hide) return null

  //     return (
  //       <TopCategoryListItem
  //         {...item}
  //         drag={() => {
  //           if (store.getState().profile.userProfile?.rank === 'free') {
  //             setPremiumPlanErrorType('sort')
  //             setIsDisplayedPremiumPlanView(true)

  //             TrackingUtils.repro.track(
  //               '【Screen】upper limit_sort big category',
  //               'Screen',
  //             )
  //           } else {
  //             drag()
  //           }
  //         }}
  //         onPressEye={
  //           editType === 'sort'
  //             ? (hide) => {
  //                 Alert.alert(
  //                   '',
  //                   `「${item.category.name}」カテゴリーを${
  //                     +item.category.categoryId
  //                       ? '非表示にします。'
  //                       : '削除します。'
  //                   }\nよろしいですか？`,
  //                   [
  //                     {
  //                       text: 'キャンセル',
  //                     },
  //                     {
  //                       text: 'はい',
  //                       onPress: () => {
  //                         const index = categories.findIndex(
  //                           (v) => v.categoryId === item.category.categoryId,
  //                         )
  //                         if (index === undefined) return
  //                         const tmpCategories = [...categories]
  //                         tmpCategories[index] = {
  //                           categoryId: item.category.categoryId,
  //                           name: item.category.name,
  //                           sort: item.category.sort,
  //                           subCategories: item.category.subCategories,
  //                           hide,
  //                           permission: item.category.permission,
  //                           icon: item.category.icon,
  //                           color: item.category.color,
  //                         }
  //                         setCategories(tmpCategories)
  //                       },
  //                     },
  //                   ],
  //                 )
  //               }
  //             : undefined
  //         }
  //         sortable={editType === 'sort'}
  //       />
  //     )
  //   },
  //   [categories, editType],
  // )

  const onPressCancelButton = useCallback(async () => {
    if (isDisplayedPremiumPlanView) {
      setIsDisplayedPremiumPlanView(false)
      setPremiumPlanErrorType(undefined)
    }

    if (isSortOnly) {
      navigation.goBack()
      return
    }

    setEditType('list')
    setCategories(CategoryManager.categories(userAccountType))
  }, [isDisplayedPremiumPlanView, isSortOnly, userAccountType, navigation])

  const onPressEditButton = useCallback(async () => {
    if (isDisplayedPremiumPlanView) return
    if (editType === 'list') {
      isFetchCategoriesEnable.current = false
      setEditType('sort')
    } else {
      // 完了
      if (
        store.getState().profile.userProfile?.rank === 'free' &&
        categories.find((v) => +v.categoryId && v.hide)
      ) {
        categories
          .filter((v) => +v.categoryId && v.hide)
          .forEach((v) => {
            v.hide = false
          })

        setPremiumPlanErrorType('hide')
        setIsDisplayedPremiumPlanView(true)

        setCategories([...categories])

        TrackingUtils.repro.track(
          '【Screen】upper limit_hide big category',
          'Screen',
        )
        return
      }

      if (!isSortOnly) {
        setEditType('list')
      }

      try {
        RNProgressHud.show()
        await CategoryManager.updateSort({
          accountType: userAccountType,
          categories,
        })
        await CategoryManager.fetchCategories({ accountType: userAccountType })

        if (isSortOnly) {
          navigation.goBack()
        }
      } catch (error) {
        CommonDialog.showError({ error })
      } finally {
        RNProgressHud.dismiss()
      }
    }
  }, [
    isDisplayedPremiumPlanView,
    editType,
    categories,
    isSortOnly,
    userAccountType,
    navigation,
  ])

  const renderRightButton = useCallback(() => {
    if (isDisplayedPremiumPlanView) return null

    if (editType === 'list') {
      return (
        <StyledEditButton onPress={onPressEditButton}>
          <StyledEditButtonImage />
          <StyledEditButtonText>追加・編集</StyledEditButtonText>
        </StyledEditButton>
      )
    } else {
      return (
        <StyledCompleteButton onPress={onPressEditButton}>
          <StyledCompleteButtonText>完了</StyledCompleteButtonText>
        </StyledCompleteButton>
      )
    }
  }, [editType, isDisplayedPremiumPlanView, onPressEditButton])

  const renderHiddenItem = useCallback(
    (item: TopCategoryListItemProps) => {
      return (
        <TopCategoryListItem
          {...item}
          onPressEye={(hide) => {
            Alert.alert(
              '',
              `「${item.category.name}」カテゴリーを再表示します。\nよろしいですか？`,
              [
                {
                  text: 'キャンセル',
                },
                {
                  text: 'はい',
                  onPress: () => {
                    const index = categories.findIndex(
                      (v) => v.categoryId === item.category.categoryId,
                    )
                    if (index === undefined) return
                    const newCategories = [...categories]
                    newCategories[index] = {
                      categoryId: item.category.categoryId,
                      name: item.category.name,
                      sort: item.category.sort,
                      subCategories: item.category.subCategories,
                      hide,
                      permission: item.category.permission,
                      icon: item.category.icon,
                      color: item.category.color,
                    }
                    setCategories(newCategories)
                  },
                },
              ],
            )
          }}
        />
      )
    },
    [categories],
  )

  const onPressCreateCategory = useCallback(() => {
    const params: CreateCategoryTopScreenNavigationParams = {
      userAccountType: userAccountType,
    }
    NavigationService.push('CreateCategoryTop', params)
  }, [userAccountType])

  const items: TopCategoryListItemProps[] = useMemo(
    () =>
      categories.map((category) => ({
        category,
        sortable: editType === 'sort',
        onPress: () => {
          navigation.navigate('EditSubCategory', {
            category,
            isSortOnly: false,
            userAccountType: userAccountType,
            isSelectable,
          })
        },
        disabled: !+category.categoryId && category.permission === 1,
      })),
    [userAccountType, categories, editType, isSelectable, navigation],
  )

  const hiddenItems: TopCategoryListItemProps[] = useMemo(
    () =>
      categories
        .filter((v) => v.hide && +v.categoryId)
        .map((category) => ({
          category,
          sortable: false,
        })),
    [categories],
  )

  const title = useMemo(
    () =>
      `カテゴリーの編集（${userAccountType === 'user' ? '個人' : '家族'}画面）`,
    [userAccountType],
  )

  const listFooterComponent = useMemo(
    () =>
      categories.length > 0 && editType === 'sort' ? (
        <>
          <StyledHideText>非表示</StyledHideText>
          {hiddenItems.map((item) => renderHiddenItem(item))}
          <View style={{ marginBottom: 110 }} />
        </>
      ) : null,
    [categories.length, editType, hiddenItems, renderHiddenItem],
  )

  const isFetchCategoriesEnable = useRef(true)

  useEffect(() => {
    if (isFocused) {
      if (categories.length === 0) {
        RNProgressHud.show()
        CategoryManager.fetchCategories({ accountType: userAccountType })
          .then(() => {
            setCategories(CategoryManager.categories(userAccountType))
          })
          .finally(() => {
            RNProgressHud.dismiss()
          })
      } else if (editType === 'list') {
        if (isFetchCategoriesEnable.current) {
          CategoryManager.fetchCategories({
            accountType: userAccountType,
          }).then(() => {
            setCategories(CategoryManager.categories(userAccountType))
          })
        } else {
          isFetchCategoriesEnable.current = true
        }
      }
    }
  }, [isFocused, userAccountType, categories.length, editType])

  useEffect(() => {
    const unsubscribe1 = signalOn('PURCHASE_COMPLETE', () => {
      setIsDisplayedPremiumPlanView(false)
      if (isFocused) {
        setIsPurchaseCompleted(true)
      }
    })

    const unsubscribe2 = signalOn('CLOSED_PURCHASE_COMPLETE_MODAL', () => {
      setIsPurchaseCompleted(false)
    })

    return () => {
      unsubscribe1()
      unsubscribe2()
    }
  }, [isFocused])

  const data = useMemo(
    () => [...items.filter((v) => +v.category.categoryId !== 58)],
    [items],
  )

  return (
    <StyledRootView>
      <CommonHeader
        title={title}
        renderRightButton={renderRightButton}
        leftButtonType={leftButtonType}
        onPressLeftButton={
          editType === 'sort' ? onPressCancelButton : undefined
        }
      />
      <StyledMainView>
        {editType === 'sort' && (
          <StyledSortDescriptionText>
            右のつまみを長押しして並べ替えができます
          </StyledSortDescriptionText>
        )}
        <ScrollView
          style={{ flex: 1, backgroundColor: Color.LightGray }}
          contentContainerStyle={{
            paddingBottom: 110,
          }}>
          <DragListView
            onDragEnd={(fromIndex, toIndex) => {
              const item = data.splice(fromIndex, 1)[0]
              data.splice(toIndex, 0, item)
              setCategories(data.map((v) => v.category))
            }}
            nodeSelector=".draggable"
            handleSelector={editType === 'sort' ? '.draggable' : undefined}>
            {data.map((item) => (
              <div key={item.category.categoryId} className="draggable">
                {renderItem(item)}
              </div>
            ))}
            {categories.length > 0 && editType === 'sort' ? (
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    backgroundColor: Color.LightGray,
                    padding: 15,
                    color: Color.DefaultText,
                    fontSize: 12,
                    fontWeight: 'normal',
                  }}>
                  非表示
                </Text>
                {hiddenItems.map((item) => renderHiddenItem(item))}
                <View style={{ marginBottom: 110 }} />
              </View>
            ) : null}
          </DragListView>
        </ScrollView>
        {isDisplayedPremiumPlanView ? (
          <>
            {Platform.OS !== 'web' ? (
              <PremiumView
                showTopMargin={false}
                title="プレミアムプランの機能です"
                message="※ カテゴリの並び替え・非表示はプレミアムプランの機能です。"
                premiumType={
                  premiumPlanErrorType === 'sort'
                    ? 'sort_category'
                    : 'hide_category'
                }
                onBackdropPress={() => {
                  setIsDisplayedPremiumPlanView(false)
                }}
              />
            ) : (
              PremiumView2({
                image: require('@images/premium/limit_sort_category.png'),
                onPressShowDetailButton: () => {
                  navigatePremiumPlanLP({ planCode: '001' })
                  setIsDisplayedPremiumPlanView(false)

                  TrackingUtils.repro.track(
                    premiumPlanErrorType === 'hide'
                      ? '【Tap】upper limit_hide big category'
                      : '【Tap】upper limit_sort big category',
                    'Tap',
                  )
                  TrackingUtils.repro.setScreenTrackingAfterPurchase(
                    premiumPlanErrorType === 'hide'
                      ? '【Screen】purchase completed via upper limit_hide big category'
                      : '【Screen】purchase completed via upper limit_sort big category',
                  )
                },
              })
            )}
          </>
        ) : (
          isPurchaseCompleted && (
            <PremiumView
              showTopMargin={false}
              buttonTitle="さっそくカスタマイズする"
              image={require('@images/premium/sort_category_modal.png')}
              onPressShowDetailButton={() => {
                setIsPurchaseCompleted(false)
                sendSignal('CLOSED_PURCHASE_COMPLETE_MODAL', 'sort_category')
              }}
            />
          )
        )}
      </StyledMainView>
      {!isDisplayedPremiumPlanView && !isPurchaseCompleted && (
        <AddCaregoryButton
          title="＋ 大カテゴリーを追加"
          onPress={onPressCreateCategory}
        />
      )}
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  flex: 1,
})

const StyledMainView = styled.View({
  flex: 1,
})

const StyledSortDescriptionText = styled.Text({
  backgroundColor: Color.LightGray,
  padding: 15,
  color: Color.DefaultText,
  fontSize: 12,
  fontWeight: 'normal',
})

const StyledHideText = styled.Text({
  backgroundColor: Color.LightGray,
  padding: 15,
  color: Color.DefaultText,
  fontSize: 12,
  fontWeight: 'normal',
})

const StyledEditButton = styled.TouchableOpacity({
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 8,
  paddingHorizontal: 10,
  paddingBottom: 5,
})

const StyledEditButtonImage = styled.Image.attrs({
  source: require('@images/buttons/edit/button-edit.png'),
})({
  width: 18,
  height: 18,
  tintColor: Color.White,
})

const StyledEditButtonText = styled.Text({
  fontSize: 11,
  fontWeight: 'normal',
  color: Color.White,
  marginTop: 3,
  paddingHorizontal: 10,
})

const StyledCompleteButton = styled.TouchableOpacity({
  paddingHorizontal: 15,
  justifyContent: 'center',
  height: 40,
})

const StyledCompleteButtonText = styled.Text({
  color: 'white',
  fontSize: 13,
  fontWeight: 'normal',
})

export default EditCategoryTopScreen
