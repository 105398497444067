import PasswordInput from '@components/PasswordInput'
import Color from '@lib/Color'
import { ImageBackground } from 'react-native'
import styled from 'styled-components/native'

export const StyledRootView = styled.View`
  flex: 1;
`

export const StyledForgotPasswordView = styled.View`
  align-items: flex-end;
`

export const StyledForgotPasswordText = styled.Text`
  margin-vertical: 15px;
  color: white;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
`

export const StyledFastImage = styled(ImageBackground)`
  flex: 1;
`

export const StyledScrollView = styled.ScrollView`
  flex: 1;
  padding-horizontal: 20px;
`

export const StyledFormView = styled.View`
  background-color: white;
  border-radius: 6px;
  margin-top: 100px;
  padding: 20px;
`

export const StyledDash = styled.View`
  height: 1px;
  background-color: ${Color.LightGray};
  margin-top: 10px;
  margin-bottom: 20px;
`

const textInputStyle = `
  font-size: 17px;
  font-weight: normal;
  color: #3a3a3a;
  height: 45px;
  font-weight: 400;
`

export const StyledEmailInput = styled.TextInput`
  ${textInputStyle}
`

export const StyledPasswordInput = styled(PasswordInput)`
  ${textInputStyle}
`

export const StyledLine = styled.View`
  background-color: ${Color.LightGray};
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const StyledTermsText = styled.Text`
  font-size: 14px;
  font-weight: normal;
  color: white;
  margin-vertical: 15px;
  text-align: center;
  line-height: 20px;
`

export const StyledFormTitleText = styled.Text`
  font-weight: 600;
  color: black;
  font-size: 12px;
  font-weight: normal;
`

export const StyledUnderlineText = styled.Text`
  margin-vertical: 15px;
  color: white;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-decoration-line: underline;
  font-weight: 600;
`
