import { PropsWithChildren } from 'react'
import styled from 'styled-components/native'

const StyledGroupView = styled.View``

const StyledUnderline = styled.View`
  margin-vertical: 8px;
  height: 0.5px;
  background-color: #dddddd;
`

const StyledTitleText = styled.Text`
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: bold;
  color: gray;
`

export type MenuGroupProps = {
  title: string
  underline?: boolean
}

const MenuGroup = ({
  title,
  underline,
  children,
}: PropsWithChildren<MenuGroupProps>) => (
  <StyledGroupView>
    <StyledTitleText>{title}</StyledTitleText>
    {children}
    {underline && <StyledUnderline />}
  </StyledGroupView>
)

export default MenuGroup
