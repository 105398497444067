import { useCallback, useState } from 'react'
import { Image, ImageProps, LayoutChangeEvent, View } from 'react-native'

export type FullWidthImageProps = ImageProps & {
  verticalAdjustment?: number
}

const FullWidthImage = ({
  source,
  verticalAdjustment,
  style,
  ...props
}: FullWidthImageProps) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const containerWidth = event.nativeEvent.layout.width
      // console.log({ event, source })

      if (typeof source === 'number') {
        const { width, height } = Image.resolveAssetSource(source)
        setWidth(containerWidth)
        setHeight((containerWidth * height) / width)
      } else {
        const imageSource = Array.isArray(source) ? source[0] : source
        // console.log({ imageSource, type: typeof imageSource })

        const uri =
          typeof imageSource === 'string' ? imageSource : imageSource?.uri

        if (!uri) return

        Image.getSize(
          uri,
          (width: number, height: number) => {
            setWidth(containerWidth)
            setHeight((containerWidth * height) / width)
          },
          (error) => {
            console.warn(error)
          },
        )
      }
    },
    [source],
  )

  // console.log({ width, height })

  return (
    <View
      onLayout={onLayout}
      {...props}
      style={[
        {
          marginTop: verticalAdjustment ? verticalAdjustment * height : 0,
        },
        style,
      ]}>
      <Image source={source} style={{ width, height }} />
    </View>
  )
}

export default FullWidthImage
