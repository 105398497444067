import AmountInput from '@components/AmountInput'
import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { isVisibleSelector } from '../selector'
import { closeCalculator, openCalculator } from '../slice'
import { NumberText, PrefixText, RootContainer } from './styles'
import { CalculatorInputProps } from './types'

const CalculatorInput: React.FC<CalculatorInputProps> = ({
  prefixText = '¥',
  style,
  prefixTextStyle,
  textStyle,
  editable = true,
  hideMinus,
  animated = true,
  value,
  inputType = 'default',
  onChange,
  willFocus,
  minusInput,
}) => {
  const dispatch = useDispatch()

  const isVisible = useSelector(isVisibleSelector)

  const onPress = useCallback(() => {
    if (isVisible) {
      dispatch(closeCalculator({ animated }))
    } else {
      if (editable && (!willFocus || willFocus())) {
        dispatch(
          openCalculator({
            onChange,
            initialValue: value,
            animated,
            minusInput,
          }),
        )
      }
    }
  }, [
    animated,
    dispatch,
    editable,
    isVisible,
    minusInput,
    onChange,
    value,
    willFocus,
  ])

  const number = useMemo(() => {
    const numberString = String(value)
    const [n1, n2] = numberString.split('.')
    const number =
      (+n1).numberFormat() + (numberString.indexOf('.') >= 0 ? '.' + n2 : '')
    return hideMinus ? number.replace(/^-/, '') : number
  }, [value, hideMinus])

  return inputType === 'default' ? (
    <RootContainer onPress={onPress} style={style}>
      <PrefixText numberOfLines={1} style={prefixTextStyle}>
        {prefixText}
      </PrefixText>
      <NumberText numberOfLines={1} style={textStyle}>
        {number}
      </NumberText>
    </RootContainer>
  ) : (
    <View style={style}>
      <AmountInput
        value={value}
        onChange={onChange}
        willFocus={willFocus}
        onFocus={willFocus}
        style={{ fontSize: textStyle?.fontSize, fontWeight: 'normal' }}
      />
    </View>
  )
}

export default CalculatorInput
