import ArrowIcon from '@components/ArrowIcon'
import Avatar from '@components/Avatar'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import TrackingUtils from '@lib/TrackingUtils'
import { FPManager, FPProfiles } from '@lib/api/FP'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import {
  AvatarContainer,
  AvatarText,
  AvatarsView,
  ChildrenAgeText,
  ChildrenItemContainer,
  ChildrenItemRow,
  ChildrenNumberText,
  EditLinkContainer,
  EditLinkText,
  Panel,
  ResultTitleText,
  RootContainer,
  SettingItemRow,
  SettingItemTitleText,
  SettingItemValueBoldText,
  SettingItemValueContainer,
  SettingItemValueText,
  SettingsContainer,
  StatusContainer,
  StatusItemCheckmark,
  StatusItemContainer,
  StatusItemRow,
  StatusItemText,
  StatusResultRankText,
  StatusResultTitleContainer,
  StatusResultTitleText,
  TitleContainer,
  TitleText,
} from './styles'

const Results: { title: string; detail: string }[] = [
  {
    title: 'しっかりきっちりタイプ',
    detail:
      '生活費も貯金もしっかり管理できています。\n一方、管理の手間が大変かも？？',
  },
  {
    title: '家計と貯蓄が混在タイプ',
    detail:
      '生活費も貯金もしっかり管理。\n一方、ひとつの口座内に生活費と貯金が混在し、貯まりにくいかも？',
  },
  {
    title: '備えがちょっぴり不安タイプ',
    detail:
      '生活費は夫婦でしっかり管理。\n一方、貯金は各自でしていて、夫婦合計の貯金額が見えにくいかも？？',
  },
  {
    title: '出費がちょっぴり不安タイプ',
    detail:
      '貯金は夫婦でしっかり管理。\n一方、夫婦全体の支出がいくらかかっているのか見えにくいかも？？',
  },
  {
    title: '個人プレータイプ',
    detail:
      '生活費は分担、貯金は各自でと、とても自由。一方、夫婦全体の貯金額・支出が見えにくいかも？？',
  },
]

function sliceByNumber<T>(array: T[], number: number) {
  const length = Math.ceil(array.length / number)
  return new Array(length)
    .fill(null)
    .map((_, i) => array.slice(i * number, (i + 1) * number))
}

const FPHomeDiagnosisScreen: React.FC<
  StackScreenProps<RootStackParamList, 'FPHomeDiagnosis'>
> = ({ navigation }) => {
  const [profiles, setProfiles] = useState<FPProfiles>()

  const getProfiles = useCallback(async () => {
    try {
      const profiles = await FPManager.getProfiles()
      setProfiles(profiles)
    } catch (error) {
      CommonDialog.showError({ error, onPress: NavigationService.goBack })
    }
  }, [])

  useEffect(() => {
    navigation.addListener('focus', getProfiles)

    TrackingUtils.repro.track(
      '【Screen】household diagnosis_information',
      'Screen',
    )
  }, [getProfiles, navigation])

  const onPressEditButton = useCallback(() => {
    if (NativeApp) {
      NavigationService.navigate('UserInfoDiagnosis')
    } else {
      NavigationService.navigate('HouseholdDiagnosis')
    }
  }, [])

  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: 'HouseholdDiagnosisResult',
        title: '家計診断の結果画面',
      })
    }
  }, [isFocused])

  if (!profiles) return null

  const {
    married,
    marrigeDate,
    children,
    budgetQuestionId,
    groupImgRegisted,
    groupHomeImgRegisted,
    paired,
    groupBudgetRegisted,
    groupWalletFinanceRegisted,
    goalCreated,
    diagnosised,
    groupPremium,
  } = profiles

  const score =
    +groupImgRegisted +
    +groupHomeImgRegisted +
    +paired +
    +groupBudgetRegisted +
    +groupWalletFinanceRegisted +
    +goalCreated +
    +diagnosised +
    +groupPremium

  const rank = score >= 7 ? 'S' : score >= 4 ? 'A' : 'B'
  const rankTitle =
    rank === 'S'
      ? '家計管理マスター夫婦'
      : rank === 'A'
      ? '家計管理エキスパート夫婦'
      : '家計管理ビギナー夫婦'

  const childrenArray = sliceByNumber(children || [], 2)

  let resultTitle: string
  let resultDetail: string

  switch (budgetQuestionId || 0) {
    case 5: {
      resultTitle = Results[0].title
      resultDetail = Results[0].detail
      break
    }
    case 6: {
      resultTitle = Results[1].title
      resultDetail = Results[1].detail
      break
    }
    case 7: {
      resultTitle = Results[2].title
      resultDetail = Results[2].detail
      break
    }
    case 8: {
      resultTitle = Results[3].title
      resultDetail = Results[3].detail
      break
    }
    case 9: {
      resultTitle = Results[4].title
      resultDetail = Results[4].detail
      break
    }
    default: {
      resultTitle = ''
      resultDetail = ''
    }
  }

  return (
    <RootContainer>
      <AvatarsView>
        <AvatarContainer>
          <Avatar />
          <AvatarText>{ProfileManager.getName('user')}</AvatarText>
        </AvatarContainer>
        <AvatarContainer>
          <Avatar type="partner" />
          <AvatarText>{ProfileManager.getName('partner')}</AvatarText>
        </AvatarContainer>
      </AvatarsView>
      <SettingsContainer>
        <TitleContainer>
          <TitleText>基本設定</TitleText>
          <EditLinkContainer onPress={onPressEditButton}>
            <EditLinkText>編集する</EditLinkText>
            <ArrowIcon />
          </EditLinkContainer>
        </TitleContainer>
        <Panel>
          <SettingItemRow border>
            <SettingItemTitleText>結婚</SettingItemTitleText>
            <SettingItemValueContainer>
              <SettingItemValueBoldText>
                {married ? '既婚' : '未婚'}
              </SettingItemValueBoldText>
            </SettingItemValueContainer>
          </SettingItemRow>
          <SettingItemRow border>
            <SettingItemTitleText>
              {'結婚年月/\n結婚予定年月'}
            </SettingItemTitleText>
            <SettingItemValueContainer>
              <SettingItemValueBoldText>
                {marrigeDate
                  ? moment(marrigeDate).format('YYYY年M月D日')
                  : '未設定'}
              </SettingItemValueBoldText>
            </SettingItemValueContainer>
          </SettingItemRow>
          <SettingItemRow border>
            <SettingItemTitleText>子ども</SettingItemTitleText>
            <SettingItemValueContainer>
              {childrenArray.length > 0 ? (
                childrenArray.map((row, i) => (
                  <ChildrenItemRow>
                    {row.map((item, j) => (
                      <ChildrenItemContainer>
                        <ChildrenNumberText>
                          {i * 2 + j + 1}人目
                        </ChildrenNumberText>
                        <ChildrenAgeText>{item.age}歳</ChildrenAgeText>
                      </ChildrenItemContainer>
                    ))}
                  </ChildrenItemRow>
                ))
              ) : (
                <SettingItemValueBoldText>いない</SettingItemValueBoldText>
              )}
            </SettingItemValueContainer>
          </SettingItemRow>
          <SettingItemRow>
            <SettingItemTitleText>家計タイプ</SettingItemTitleText>
            <SettingItemValueContainer>
              <ResultTitleText>{resultTitle}</ResultTitleText>
              <SettingItemValueText>{resultDetail}</SettingItemValueText>
            </SettingItemValueContainer>
          </SettingItemRow>
        </Panel>
      </SettingsContainer>
      <StatusContainer>
        <TitleContainer>
          <TitleText>お家の管理ステータス</TitleText>
        </TitleContainer>
        <Panel>
          <StatusResultTitleContainer>
            <StatusResultRankText>{rank}</StatusResultRankText>
            <StatusResultTitleText>{rankTitle}</StatusResultTitleText>
          </StatusResultTitleContainer>
          <StatusItemRow>
            <StatusItemContainer>
              <StatusItemCheckmark on={paired}>✓</StatusItemCheckmark>
              <StatusItemText on={paired}>ペアリング</StatusItemText>
            </StatusItemContainer>
            <StatusItemContainer>
              <StatusItemCheckmark on={groupWalletFinanceRegisted}>
                ✓
              </StatusItemCheckmark>
              <StatusItemText on={groupWalletFinanceRegisted}>
                口座、財布登録
              </StatusItemText>
            </StatusItemContainer>
          </StatusItemRow>
          <StatusItemRow>
            <StatusItemContainer>
              <StatusItemCheckmark on={groupImgRegisted}>✓</StatusItemCheckmark>
              <StatusItemText on={groupImgRegisted}>
                顔写真の設定
              </StatusItemText>
            </StatusItemContainer>
            <StatusItemContainer>
              <StatusItemCheckmark on={groupBudgetRegisted}>
                ✓
              </StatusItemCheckmark>
              <StatusItemText on={groupBudgetRegisted}>
                予算の設定
              </StatusItemText>
            </StatusItemContainer>
          </StatusItemRow>
          <StatusItemRow>
            <StatusItemContainer>
              <StatusItemCheckmark on={groupHomeImgRegisted}>
                ✓
              </StatusItemCheckmark>
              <StatusItemText on={groupHomeImgRegisted}>
                背景画像の設定
              </StatusItemText>
            </StatusItemContainer>
            <StatusItemContainer>
              <StatusItemCheckmark on={goalCreated}>✓</StatusItemCheckmark>
              <StatusItemText on={goalCreated}>目標貯金の作成</StatusItemText>
            </StatusItemContainer>
          </StatusItemRow>
          <StatusItemRow>
            <StatusItemContainer>
              <StatusItemCheckmark on={diagnosised}>✓</StatusItemCheckmark>
              <StatusItemText on={diagnosised}>家計診断</StatusItemText>
            </StatusItemContainer>
            <StatusItemContainer>
              <StatusItemCheckmark on={groupPremium}>✓</StatusItemCheckmark>
              <StatusItemText on={groupPremium}>
                プレミアムプラン
              </StatusItemText>
            </StatusItemContainer>
          </StatusItemRow>
        </Panel>
      </StatusContainer>
    </RootContainer>
  )
}

export default FPHomeDiagnosisScreen
