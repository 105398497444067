export enum ActionType {
  UpdateTransactionDisplayType = 'UpdateTransactionDisplayType',
}

export type UpdateTransactionDisplayTypeAction = {
  type: ActionType.UpdateTransactionDisplayType
  displayType: TransactionDisplayType
}

export type Action = UpdateTransactionDisplayTypeAction

export type State = {
  displayType?: TransactionDisplayType
}

export type TransactionDisplayType = 'transactions' | 'calendar'
