import Alert from '@components/Alert'
import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CalculatorInput from '@components/Calculator/CalculatorInput'
import { closeCalculator, openCalculator } from '@components/Calculator/slice'
import CategoryImage from '@components/CategoryImage'
import CheckMarkToggleButton from '@components/CheckMarkToggleButton'
import CommonHeader from '@components/CommonHeader'
import CoupleButton from '@components/CoupleButton'
import DatePicker from '@components/DatePicker'
import EllipsizeTextInput from '@components/EllipsizeTextInput'
import FaceIcon from '@components/FaceIcon'
import FullWidthImage from '@components/FullWidthImage'
// import { faImage } from '@fortawesome/pro-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { PayoffScreenProps } from '@Screen/Main/Home/Payoff/PayoffScreen'
import ActionSheet from '@components/ActionSheet'
import { faImage } from '@fortawesome/pro-solid-svg-icons'
import { UserAccountType } from '@interfaces/Account'
import {
  FinancialAccountType,
  PaymentUserType,
  TransactionDetail,
  TransactionImageProps,
  TransferDetail,
} from '@interfaces/Financial'
import { Gender } from '@interfaces/Gender'
import { UserStatus } from '@interfaces/Profile'
import { AccountState } from '@lib/AccountManager/types'
import CategoryManager, { CategoryUnit } from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { FAIcon } from '@lib/FAIcon'
import FinancialManager from '@lib/FinancialManager'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import * as ImagePickerUtils from '@lib/ImagePickerUtils'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import ProfileManager from '@lib/ProfileManager'
import ProgressHUD from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import { SpotlightTourSpot } from '@lib/SpotlightTour'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { CarryForwardManager } from '@lib/api/CarryForwardSettings'
import Financial from '@lib/api/Financial'
import { TransactionDefaultSettings } from '@lib/api/TransactionDefaultSettings/types'
import Transfer, { ConvertTransferProps } from '@lib/api/Transfer'
import { UserProfile } from '@lib/api/User'
import { WalletEntity } from '@lib/api/Wallet'
import {
  RootStackParamList,
  TransferSelectFinancialAccountScreenParams,
  TransferSelectTransactionCallback,
} from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import { getPartnerGender, getUserGender } from '@redux/utils'
import moment from 'moment'
import React from 'react'
import {
  ActivityIndicator,
  Image,
  ImageSourcePropType,
  Modal,
  NativeEventSubscription,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import ImageViewer from 'react-native-image-zoom-viewer'
import { Item } from 'react-native-picker-select'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import { connect } from 'react-redux'
import styled from 'styled-components/native'
import { PremiumView } from '../../../components/PremiumView'
import SpotlightTourContext from '../../../lib/SpotlightTour/SpotlightTourContext'
import CreatedTransactionDialog, {
  showCreatedTransactionDialog,
} from '../CreatedTransactionDialog'
import { navigatePremiumPlanLP } from '../PremiumPlan'
import TutorialTipsBalloon from '../TutorialTips/TutorialTipsBalloon'
import { setTabBarVisible } from '../redux/actions'
import { styles } from './styles'

type StateProps = {
  userStatus?: UserStatus
  userProfile?: UserProfile
  account: AccountState
  transactionDefaultSettings: {
    user: TransactionDefaultSettings
    family: TransactionDefaultSettings
  }
}

interface State {
  transactionUserId?: number
  accountId?: number
  transactionId?: number
  amount: number
  usedDate: string
  categoryName1: string
  categoryName2: string
  subCategoryId?: string
  usedLocation: string
  paymentName: string
  isTransactionShared: boolean
  isAccountShared?: boolean
  selectedTab: TabType
  isTransactionLoaded: boolean
  isDisplayDatePicker: boolean
  isIncludeBs: boolean
  memo: string
  headerTitle?: string
  walletId?: number | null
  // transfer
  transfer?: TransferDetail
  transferId?: number
  fromType?: string
  fromFinancialAccountId?: number
  fromFinancialAccountName: string
  fromTransactionId?: number
  fromTransactionUsedLocation: string
  fromWalletIsFamily: boolean
  toType?: string
  toWalletIsFamily: boolean
  toFinancialAccountId?: number
  toFinancialAccountName: string
  toTransactionId?: number
  toTransactionUsedLocation: string
  transferBase?: 'from' | 'to'
  //
  permissions: {
    displayTab: boolean
    deleteTransaction: boolean
    updateAmount: boolean
    updateIsIgnore: boolean
    updateUsedDate: boolean
    updateCategory: boolean
    updateAccount: boolean // 支払い担当
    updateFinancialAccount: boolean // 支払い元
    updateIsTransactionShared: boolean // 管理する画面
    updateUsedPlace: boolean // 使った場所
    updateMemo: boolean
  }
  paymentUserType: PaymentUserType
  addPayoffList: boolean
  payoffId?: number // 値があれば精算済み
  images: (TransactionImageProps & { updatedAt: number })[]
  isVisibleImageView: boolean
  isCreateMode: boolean
  isCopy: boolean
  isDisplayPremiumView: boolean
  isYetPayoffed: boolean // 未精算リスト入り = true
  termTransactionId?: number // 繰り返し明細
  isContinueCreate: boolean // 続けて作成ボタンで作成される明細
  isPurchaseCompleted: boolean
}

type TabType = 'expense' | 'income' | 'transfer'

enum PaymentUserTypeActionSheetIndex {
  User = 0,
  Family = 1,
  Pertner = 2,
  Cancel = 3,
}

export type TransactionDetailScreenNavigationParams = {
  isCopy?: boolean
  selectedTab?: TabType
  amount?: number
  usedDate?: string // yyyy-MM-dd
  subCategoryId?: string
  paymentUserType?: PaymentUserType // 支払い担当
  walletId?: number // 支払い元
  isTransactionShared?: boolean // 表示する画面 true:家族 false:個人
  usedLocation?: string // お店の名前
  memo?: string
  addPayoffList?: boolean // 未精算リストに入れる
  isIncludeBs?: boolean // 収支の計算対象に入れる

  financialAccountType?: FinancialAccountType
  userAccountType: UserAccountType
  accountId?: number
  transactionId?: number
  focusMemo?: boolean
  // 以下振替用
  transferId?: number
  accountName?: string
  transterTransactionId?: number
  transactionUsedLocation?: string
}

class TransactionDetailScreen extends React.Component<
  StateProps & StackScreenProps<RootStackParamList, 'TransactionDetail'>,
  State
> {
  context!: React.ContextType<typeof SpotlightTourContext>

  constructor(
    props: StateProps &
      StackScreenProps<RootStackParamList, 'TransactionDetail'>,
  ) {
    super(props)
    if (this.props.route.params?.transactionId) {
      store.dispatch(setTabBarVisible(false))
    }
  }

  defaultState = () => {
    // isCopy?: boolean
    // selectedTab?: TabType
    // amount?: number
    // usedDate?: string // yyyy-MM-dd
    // subCategoryId?: string
    // paymentUserType?: PaymentUserType // 支払い担当
    // walletId?: number // 支払い元
    // isTransactionShared?: boolean // 表示する画面 true:家族 false:個人
    // usedLocation?: string // お店の名前
    // memo?: string
    // addPayoffList?: boolean // 未精算リストに入れる
    // isIncludeBs ?: boolean // 収支の計算対象に入れる

    const { params } = this.props.route

    log.info('params', params)

    const userAccountType =
      params?.userAccountType || this.props.account.accountMode

    // category
    let categoryName1 = '未分類'
    let categoryName2 = '未分類'
    let subCategoryId =
      params?.subCategoryId ??
      this.props.transactionDefaultSettings[userAccountType]
        .atTransactionCategoryId
    if (subCategoryId !== null) {
      const subCategory = CategoryManager.categoryBySubCategoryId(subCategoryId)
      const category = CategoryManager.categoryByCategoryId(
        userAccountType,
        subCategory?.categoryId,
      )
      categoryName1 = category?.name || '未分類'
      categoryName2 = subCategory?.name || '未分類'
    } else {
      subCategoryId = '387' // 支出の未分類
    }

    // walletId
    const walletId = (() => {
      if (params?.walletId) {
        return params?.walletId
      }

      if (params?.financialAccountType === 'manually_created') {
        const walletId =
          this.props.transactionDefaultSettings[userAccountType].walletId
        // if (walletId === null) {
        //   walletId =
        //     userAccountType === 'user'
        //       ? SessionManager.getDefaultPersonalWalletId()
        //       : SessionManager.getDefaultFamilyWalletId() || 0 // 0=現金
        // }
        return walletId
      } else {
        return null
      }
    })()

    // isTransactionShared
    let isTransactionShared =
      params?.isTransactionShared ??
      this.props.transactionDefaultSettings[userAccountType].transactionShare
    if (isTransactionShared === null) {
      isTransactionShared = userAccountType === 'family'
    }

    // paymentUserType
    let paymentUserType =
      params?.paymentUserType ??
      this.props.transactionDefaultSettings[userAccountType].paymentUserType
    if (paymentUserType === null) {
      paymentUserType =
        userAccountType === 'user'
          ? PaymentUserType.User
          : PaymentUserType.Family
    }

    // addPayoffList
    let addPayoffList =
      params?.addPayoffList ??
      this.props.transactionDefaultSettings[userAccountType].yetPayoff
    if (addPayoffList === null) {
      addPayoffList = false
    }

    const ignore = this.props.transactionDefaultSettings[userAccountType].ignore

    const state: State = {
      isCopy: params?.isCopy ?? false,
      amount: params?.amount ?? 0,
      usedDate: params?.usedDate || moment().format('YYYY-MM-DD'),
      categoryName1,
      categoryName2,
      subCategoryId,
      usedLocation: params?.usedLocation ?? '',
      paymentName: '',
      isAccountShared: userAccountType === 'family',
      isTransactionShared,
      selectedTab: params?.selectedTab ?? 'expense',
      isTransactionLoaded: false,
      isDisplayDatePicker: false,
      isIncludeBs: params?.isIncludeBs ?? (ignore !== null ? !ignore : true),
      memo: params?.memo ?? '',
      walletId,
      // transfer
      transferId: 0,
      fromWalletIsFamily: this.props.account.accountMode === 'family',
      fromFinancialAccountId: 0,
      fromFinancialAccountName: '',
      fromTransactionId: 0,
      fromTransactionUsedLocation: '',
      toWalletIsFamily: this.props.account.accountMode === 'family',
      toFinancialAccountId: 0,
      toFinancialAccountName: '',
      toTransactionUsedLocation: '',
      toTransactionId: 0,
      permissions: {
        displayTab: false,
        deleteTransaction: false,
        updateAmount: false,
        updateIsIgnore: false,
        updateUsedDate: false,
        updateCategory: false,
        updateAccount: false,
        updateFinancialAccount: false,
        updateIsTransactionShared: false,
        updateUsedPlace: false,
        updateMemo: false,
      },
      paymentUserType,
      addPayoffList,
      images: [],
      isVisibleImageView: false,
      isCreateMode: !this.props.route.params?.transactionId,
      isDisplayPremiumView: false,
      isYetPayoffed: true,
      isContinueCreate: false,
      isPurchaseCompleted: false,
    }

    return state
  }

  static contextType = SpotlightTourContext

  private wallets: {
    user: WalletEntity[]
    family: WalletEntity[]
  } = {
    user: [],
    family: [],
  }

  private navigationParams!: TransactionDetailScreenNavigationParams

  private backHandler?: NativeEventSubscription

  state = this.defaultState()

  private onPressIsTransactionSharedButton = (isTransactionShared: boolean) => {
    this.setState({ isTransactionShared })

    if (this.state.subCategoryId && !+this.state.subCategoryId) {
      // 独自に作成したカテゴリの場合は、カテゴリを初期値に戻す
      const categoryName1 =
        this.state.selectedTab === 'expense' ? '未分類' : '入金'
      const categoryName2 =
        this.state.selectedTab === 'expense' ? '未分類' : 'その他入金'
      const subCategoryId = this.state.selectedTab === 'expense' ? '387' : '394'
      this.setState({
        categoryName1,
        categoryName2,
        subCategoryId,
      })
    }

    // 表示する画面を切り替えたら、必ずデフォルトのカテゴリー設定に戻る
    // https://www.wrike.com/open.htm?id=1155966612
    // const { params } = this.props.route

    // const defaultSettings =
    //   this.props.transactionDefaultSettings[this.props.account.accountMode]

    // let categoryName1 = '未分類'
    // let categoryName2 = '未分類'
    // let subCategoryId =
    //   params?.subCategoryId ??
    //   (defaultSettings.transactionShare === isTransactionShared
    //     ? defaultSettings.atTransactionCategoryId
    //     : this.props.transactionDefaultSettings[
    //         isTransactionShared ? 'family' : 'user'
    //       ].atTransactionCategoryId)
    // console.log('subCategoryId', subCategoryId)

    // if (subCategoryId !== null) {
    //   const subCategory = CategoryManager.categoryBySubCategoryId(subCategoryId)
    //   const category = CategoryManager.categoryByCategoryId(
    //     defaultSettings.transactionShare ? 'family' : 'user',
    //     subCategory?.categoryId,
    //   )
    //   categoryName1 = category?.name || '未分類'
    //   categoryName2 = subCategory?.name || '未分類'
    // } else {
    //   subCategoryId = '387' // 支出の未分類
    // }

    // this.setState({ categoryName1, categoryName2, subCategoryId })
  }

  private listeners: string[] = []
  private purchaseCompletedListener?: () => void = undefined

  async componentDidMount() {
    this.navigationParams = this.props.route.params

    this.backHandler = HardwareBackPressHandler.addListener(this.goBack)

    this.props.navigation.addListener('focus', () => {
      if (this.props.account.accountMode === 'user') {
        TrackingUtils.repro.track('【Screen】personal_detaile list', 'Screen')
        TrackingUtils.repro.track(
          '【Screen】personal_detaile list_cumulative',
          'Screen',
        )
      } else {
        TrackingUtils.repro.track('【Screen】family_detaile list', 'Screen')
        TrackingUtils.repro.track(
          '【Screen】family_detaile list_cumulative',
          'Screen',
        )
      }

      TrackingUtils.ga.pageview({
        page: `TransactionDetail-${
          this.props.account.accountMode === 'family' ? 'Family' : 'User'
        }`,
        title: `${
          this.props.account.accountMode === 'family' ? '家族' : '個人'
        }_取引明細`,
      })
    })

    await this.initialize()

    if (!SessionManager.isTutorialTransactionDisplayCompleted()) {
      this.startTour(2)
    }

    if (!this.state.isCreateMode) {
      store.dispatch(setTabBarVisible(false))
    }

    const listener = OsidoriEvent.addListener(
      'DidSelectSubCategory',
      this.chooseCategoryHandler,
    )
    listener && this.listeners.push(listener)

    this.purchaseCompletedListener = signalOn('PURCHASE_COMPLETE', () => {
      if (this.props.navigation.isFocused()) {
        this.setState({ isPurchaseCompleted: true })
      }
    })
  }

  componentWillUnmount() {
    this.backHandler?.remove()

    if (!this.state.isCreateMode) {
      store.dispatch(setTabBarVisible(true))
    }

    OsidoriEvent.removeListener(this.listeners)

    this.purchaseCompletedListener?.()
  }

  private initialize = async () => {
    await this.getWallets()
    await this.fetchTransaction()
    this.setPermissions()

    // 振替の場合
    if (this.navigationParams.transterTransactionId) {
      const transferBase =
        (this.navigationParams.amount || 0) < 0 ? 'from' : 'to'
      const newState =
        transferBase === 'from'
          ? {
              fromType: this.navigationParams.financialAccountType,
              fromFinancialAccountId: this.navigationParams.accountId,
              fromFinancialAccountName: this.navigationParams.accountName || '',
              fromTransactionId: this.navigationParams.transterTransactionId,
              fromTransactionUsedLocation:
                this.navigationParams.transactionUsedLocation || '',
              toFinancialAccountName: '',
              toTransactionUsedLocation: '',
            }
          : {
              toType: this.navigationParams.financialAccountType,
              toFinancialAccountId: this.navigationParams.accountId,
              toFinancialAccountName: this.navigationParams.accountName || '',
              toTransactionId: this.navigationParams.transterTransactionId,
              toTransactionUsedLocation:
                this.navigationParams.transactionUsedLocation || '',
              fromFinancialAccountName: '',
              fromTransactionUsedLocation: '',
            }

      this.setState({
        transferBase,
        selectedTab: 'transfer',
        amount: this.navigationParams.amount || 0,
        usedDate: this.navigationParams.usedDate || moment().format(),
        ...newState,
      })
    }

    if (
      !this.navigationParams.transterTransactionId &&
      this.state.isCreateMode &&
      SessionManager.isTutorialTransactionDisplayCompleted()
    ) {
      store.dispatch(
        openCalculator({
          onChange: this.onChangeAmount,
          initialValue: this.state.amount,
          animated: false,
        }),
      )
    }
  }

  private clearForm = ({
    isCopy,
    isPremiumError,
  }: {
    isCopy?: boolean
    isPremiumError?: boolean
  }) => {
    if (isPremiumError) {
      this.setState({ isDisplayPremiumView: true })

      if (isCopy) {
        sendSignal('TRANSACTION_COPY_LIMIT', 'CreateTransactionDetail')
      }

      return
    }

    if (isCopy) {
      this.setState({
        isCopy: true,
        usedDate: moment().format('YYYY-MM-DD'),
        isContinueCreate: false, // 続けて作成
      })
    } else {
      this.setState(
        {
          ...this.defaultState(),
          usedDate: this.state.usedDate,
          categoryName1: this.state.categoryName1,
          categoryName2: this.state.categoryName2,
          subCategoryId: this.state.subCategoryId,
          isAccountShared: this.state.isAccountShared,
          isTransactionShared: this.state.isTransactionShared,
          selectedTab: this.state.selectedTab,
          isIncludeBs: this.state.isIncludeBs,
          walletId: this.state.walletId,
          isTransactionLoaded: true,
          paymentUserType: this.state.paymentUserType,
          isContinueCreate: true, // 続けて作成
        },
        () => {
          this.setPermissions()
        },
      )
    }
  }

  private setPermissions = () => {
    const isCreateTransaction = this.state.isCreateMode
    const isManualTransaction = this.isManualTransaction()
    const isMyCreatedTransaction =
      this.state.transactionUserId === this.props.userProfile?.userId

    if (
      isCreateTransaction ||
      isMyCreatedTransaction ||
      isManualTransaction ||
      this.state.isAccountShared
    ) {
      // 新規作成
      // 自分が作成した取引明細
      // 家族口座の取引明細
      // マニュアル明細
      this.setState({
        permissions: {
          displayTab:
            isCreateTransaction && !this.navigationParams.transterTransactionId,
          deleteTransaction: !isCreateTransaction && isManualTransaction,
          updateAmount:
            (isCreateTransaction || isManualTransaction) &&
            !this.state.transfer &&
            !this.state.transferId &&
            !this.navigationParams.transterTransactionId,
          updateIsIgnore: true,
          updateUsedDate:
            (isCreateTransaction || isManualTransaction) &&
            !this.state.transfer &&
            !this.state.transferId &&
            !this.navigationParams.transterTransactionId,
          updateCategory: true,
          updateAccount: isManualTransaction,
          updateFinancialAccount: true,
          updateIsTransactionShared: true,
          updateUsedPlace: isCreateTransaction || isManualTransaction,
          updateMemo: true,
        },
      })
    } else {
      // パートナーが個人の明細を家族にシェアした取引明細
      this.setState({
        permissions: {
          displayTab: false,
          deleteTransaction: false,
          updateAmount: false,
          updateIsIgnore: true,
          updateUsedDate: false,
          updateCategory: true,
          updateAccount: isManualTransaction,
          updateFinancialAccount: false,
          updateIsTransactionShared: true,
          updateUsedPlace: false,
          updateMemo: true,
        },
      })
    }
  }

  private setTransactionDetail = (detail: TransactionDetail) => {
    const { accountId, transactionId } = this.navigationParams

    switch (this.financialAccountType()) {
      case 'bank':
      case 'card':
      case 'emoney':
        this.setState({
          transactionUserId: detail.userId,
          headerTitle: detail.usedLocation,
          accountId,
          transactionId,
          amount: detail.amount,
          usedDate: moment(detail?.usedDate).format('YYYY-MM-DD'),
          categoryName1: detail.categoryName1 || '',
          categoryName2: detail.categoryName2 || '',
          subCategoryId: detail.atTransactionCategoryId,
          usedLocation: detail.usedLocation,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          paymentName: detail.paymentName!,
          isTransactionShared: detail.isTransactionShared,
          isAccountShared: detail.isAccountShared,
          selectedTab: detail.amount <= 0 ? 'expense' : 'income',
          isIncludeBs: !detail.isIgnored,
          memo: detail.memo !== null ? detail.memo : '',
          paymentUserType: detail.paymentUserType,
          addPayoffList: !!detail.isYetPayoffed,
          payoffId: detail.payoffId || undefined,
          images: detail.images || [],
          isYetPayoffed: !!detail.isYetPayoffed,
        })
        break
      case 'manually_created':
        this.setState({
          transactionUserId: detail.userId,
          headerTitle: detail.usedLocation,
          transactionId,
          amount: detail.amount,
          usedDate: moment(detail?.usedDate).format('YYYY-MM-DD'),
          categoryName1: detail.categoryName1 || '',
          categoryName2: detail.categoryName2 || '',
          subCategoryId: detail.atTransactionCategoryId,
          usedLocation: detail.usedLocation,
          paymentName: detail.paymentName || '現金',
          isTransactionShared: detail.isTransactionShared,
          isAccountShared: detail.isAccountShared,
          selectedTab: detail.amount <= 0 ? 'expense' : 'income',
          isIncludeBs: !detail.isIgnored,
          memo: detail.memo !== null ? detail.memo : '',
          walletId: detail.walletId ? detail.walletId : 0,
          paymentUserType: detail.paymentUserType,
          addPayoffList: !!detail.isYetPayoffed,
          payoffId: detail.payoffId || undefined,
          images: detail.images || [],
          isYetPayoffed: !!detail.isYetPayoffed,
          termTransactionId: detail.termTransactionId,
        })
        break
      case 'wallet':
        this.setState({
          transactionUserId: detail.userId,
          headerTitle: detail.usedLocation,
          transactionId,
          amount: detail.amount,
          usedDate: moment(detail?.usedDate).format('YYYY-MM-DD'),
          categoryName1: detail.categoryName1 || '',
          categoryName2: detail.categoryName2 || '',
          subCategoryId: detail.atTransactionCategoryId,
          usedLocation: detail.usedLocation,
          paymentName: detail.paymentName || '現金',
          isTransactionShared: detail.isTransactionShared,
          isAccountShared: detail.isAccountShared,
          selectedTab: detail.amount <= 0 ? 'expense' : 'income',
          isIncludeBs: !detail.isIgnored,
          memo: detail.memo !== null ? detail.memo : '',
          walletId: detail.walletId ? detail.walletId : 0,
          paymentUserType: detail.paymentUserType,
          addPayoffList: !!detail.isYetPayoffed,
          payoffId: detail.payoffId || undefined,
          images: detail.images || [],
          isYetPayoffed: !!detail.isYetPayoffed,
          termTransactionId: detail.termTransactionId,
        })
        break
      case 'carry_forward':
        this.setState({
          transactionUserId: detail.userId,
          headerTitle: detail.usedLocation,
          transactionId,
          amount: detail.amount,
          usedDate: moment(detail?.usedDate).format('YYYY-MM-DD'),
          categoryName1: detail.categoryName1 || '',
          categoryName2: detail.categoryName2 || '',
          subCategoryId: detail.atTransactionCategoryId,
          usedLocation: detail.usedLocation,
          isTransactionShared: detail.isTransactionShared,
          isAccountShared: detail.isAccountShared,
          selectedTab: detail.amount <= 0 ? 'expense' : 'income',
          isIncludeBs: !detail.isIgnored,
          memo: detail.memo !== null ? detail.memo : '',
          images: detail.images || [],
          isYetPayoffed: !!detail.isYetPayoffed,
        })
    }
  }

  private getWallets = async () => {
    try {
      this.wallets.user = await FinancialManager.getWallets({
        userAccountType: 'user',
        sort: false,
      })
      this.wallets.family = await FinancialManager.getWallets({
        userAccountType: 'family',
        sort: false,
      })
    } catch (error) {
      CommonDialog.showError({
        error,
        onPress: () => this.goBack(),
      })
    }

    const userAccountType: UserAccountType =
      this.state.paymentUserType === PaymentUserType.Family ? 'family' : 'user'
    if (
      !this.wallets[userAccountType].find((v) => v.id === this.state.walletId)
    ) {
      this.setState({ walletId: 0 })
    }
  }

  private fetchTransaction = async () => {
    this.setState({ isTransactionLoaded: false })
    let detail: TransactionDetail | undefined

    if (!this.state.isCreateMode) {
      try {
        const transactionId = this.navigationParams.transactionId || 0
        if (
          this.financialAccountType() !== 'manually_created' &&
          this.financialAccountType() !== 'carry_forward' &&
          this.navigationParams.accountId
        ) {
          detail = await FinancialManager.getTransactionDetail({
            // userAccountType:
            //   this.navigationParams.userAccountType ||
            //   store.getState().account.accountMode,
            financialAccountType: this.financialAccountType(),
            accountId: this.navigationParams.accountId,
            transactionId: transactionId,
            transfer:
              store.getState().main.selectedTab === 'TransactionsTab'
                ? 'pl'
                : 'transaction',
          })
          // APIのバグ回避
          if (this.financialAccountType() === 'wallet' && !detail) {
            this.navigationParams.financialAccountType = 'manually_created'
            detail = await FinancialManager.getTransactionDetail({
              // userAccountType:
              //   this.navigationParams.userAccountType ||
              //   store.getState().account.accountMode,
              financialAccountType: 'manually_created',
              transactionId,
              transfer:
                store.getState().main.selectedTab === 'TransactionsTab'
                  ? 'pl'
                  : 'transaction',
            })
          }
        } else if (this.financialAccountType() === 'manually_created') {
          // manual
          detail = await FinancialManager.getTransactionDetail({
            // userAccountType:
            //   this.navigationParams.userAccountType ||
            //   store.getState().account.accountMode,
            financialAccountType: 'manually_created',
            transactionId,
            transfer:
              store.getState().main.selectedTab === 'TransactionsTab'
                ? 'pl'
                : 'transaction',
          })
        } else {
          // carry_forward
          detail = await CarryForwardManager.getCarryForwardTransaction(
            transactionId,
          )
        }
      } catch (error) {
        CommonDialog.showError({ error })
      }
    }

    if (detail) {
      this.setTransactionDetail(detail)

      // 振替の場合
      if (detail.transfer) {
        this.setState({
          selectedTab: 'transfer',
          transferId: detail.transfer.transferId,
          fromType: detail.transfer.fromType,
          fromWalletIsFamily: detail.transfer.fromAccountShared,
          fromFinancialAccountId: detail.transfer.fromAccountId,
          fromFinancialAccountName: detail.transfer.fromAccountName,
          fromTransactionId: detail.transfer.fromTransactionId,
          fromTransactionUsedLocation:
            detail.transfer.fromTransactionUsedLocation,
          toType: detail.transfer.toType,
          toWalletIsFamily: detail.transfer.toAccountShared,
          toFinancialAccountId: detail.transfer.toAccountId,
          toFinancialAccountName: detail.transfer.toAccountName,
          toTransactionId: detail.transfer.toTransactionId,
          toTransactionUsedLocation: detail.transfer.toTransactionUsedLocation,
        })
      }
    }
    this.setState({ isTransactionLoaded: true })
  }

  private renderItem = (props: {
    categoryId?: string
    icon?: ImageSourcePropType
    content: string
    isDisplayRightIcon?: boolean
    onPress?: () => void
    placeholder?: string
    columnMargin?: number
    opacity?: boolean
  }) => {
    const {
      categoryId,
      icon,
      content,
      isDisplayRightIcon,
      onPress,
      placeholder,
      opacity,
    } = props
    const textStyle = content === '' ? styles.placeholder : styles.content
    const text = content !== '' ? content : placeholder || ''
    const disabled = !isDisplayRightIcon

    return (
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled}
        style={[styles.wrapContent, { marginRight: props.columnMargin }]}>
        {categoryId && <CategoryImage categoryId={categoryId} />}
        {icon && (
          <Image
            source={icon}
            style={{ width: 20, height: 20, resizeMode: 'contain' }}
          />
        )}
        <Text style={[textStyle, opacity ? { color: '#3a3a3a55' } : {}]}>
          {text}
        </Text>
        {isDisplayRightIcon && (
          <Image
            source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
            style={styles.iconDown}
          />
        )}
      </TouchableOpacity>
    )
  }

  private renderSettingPaymentUserType = () => {
    const items = {
      [PaymentUserType.User]: ProfileManager.getName('user'),
      [PaymentUserType.Family]: '家族',
      [PaymentUserType.Partner]: ProfileManager.getName('partner'),
    }

    const disabled = !this.state.permissions.updateAccount

    return (
      <TouchableOpacity
        onPress={this.onPressPaymentUserType}
        style={[styles.wrapContent, { flex: -1 }]}
        disabled={disabled}>
        {this.state.paymentUserType === PaymentUserType.User ? (
          <FaceIcon faceType={getUserGender()} />
        ) : this.state.paymentUserType === PaymentUserType.Partner ? (
          <FaceIcon faceType={getPartnerGender()} />
        ) : (
          <FaceIcon faceType={[Gender.Male, Gender.Female]} />
        )}
        <Text style={styles.paymentPlaceText}>
          {this.state.selectedTab === 'expense' ? '支払い担当' : '受取り担当'}
        </Text>
        <Text
          style={[
            styles.content,
            { textAlign: 'right', marginRight: disabled ? 0 : 15 },
          ]}>
          {items[this.state.paymentUserType]}
        </Text>
        {disabled ? null : (
          <Image
            source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
            style={styles.iconDown}
          />
        )}
      </TouchableOpacity>
    )
  }

  private onPressPaymentUserType = () => {
    if (!this.checkUpdateError()) return

    ActionSheet.showActionSheetWithOptions(
      Platform.OS === 'ios'
        ? {
            options: [
              ProfileManager.getName('user'),
              '家族',
              ProfileManager.getName('partner'),
              'キャンセル',
            ],
            cancelButtonIndex: PaymentUserTypeActionSheetIndex.Cancel,
          }
        : {
            options: [
              ProfileManager.getName('user'),
              '家族',
              ProfileManager.getName('partner'),
            ],
          },
      (index: number) => {
        if (index === PaymentUserTypeActionSheetIndex.User) {
          const walletId = SessionManager.getDefaultPersonalWalletId()
          this.setState({ paymentUserType: PaymentUserType.User, walletId })
        } else if (index === PaymentUserTypeActionSheetIndex.Family) {
          const walletId = SessionManager.getDefaultFamilyWalletId()
          this.setState({
            paymentUserType: PaymentUserType.Family,
            addPayoffList: false,
            walletId,
          })
        } else if (index === PaymentUserTypeActionSheetIndex.Pertner) {
          this.setState({
            paymentUserType: PaymentUserType.Partner,
            walletId: undefined,
          })
        }
      },
    )
  }

  private renderSettingShareView = () => {
    const { isTransactionShared: isTransactionShared } = this.state
    return (
      <View style={styles.settingShareView}>
        <Text
          style={{
            fontSize: 12,
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          表示する画面
        </Text>
        <View
          style={{
            flexDirection: 'row',
            opacity: this.financialAccountType() === 'carry_forward' ? 0.5 : 1,
          }}>
          <CoupleButton
            buttonType="single"
            onPress={() => this.onPressIsTransactionSharedButton(false)}
            isOn={!isTransactionShared}
            title="個人画面"
            disabled={
              !isTransactionShared ||
              this.financialAccountType() === 'carry_forward'
            }
          />
          <CoupleButton
            buttonType="couple"
            onPress={() => this.onPressIsTransactionSharedButton(true)}
            isOn={isTransactionShared}
            title="家族画面"
            disabled={
              isTransactionShared ||
              this.financialAccountType() === 'carry_forward'
            }
            style={{ marginLeft: 8 }}
          />
        </View>
      </View>
    )
  }

  private renderWalletTypeView = (type: 'from' | 'to') => {
    const { fromWalletIsFamily, toWalletIsFamily } = this.state
    const isFamily = type === 'from' ? fromWalletIsFamily : toWalletIsFamily

    return (
      <View style={[styles.settingShareView, { borderBottomWidth: 0 }]}>
        <Text
          style={{
            fontSize: 12,
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {type === 'from' ? '振替元' : '振替先'}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <CoupleButton
            buttonType="single"
            onPress={() => {
              if (type === 'from') {
                this.setState({
                  fromWalletIsFamily: false,
                  fromFinancialAccountId: undefined,
                  fromFinancialAccountName: '',
                })
              } else {
                this.setState({
                  toWalletIsFamily: false,
                  toFinancialAccountId: undefined,
                  toFinancialAccountName: '',
                })
              }
            }}
            isOn={!isFamily}
            title={ProfileManager.getName('user')}
            disabled={!isFamily || this.state.transferBase === type}
          />
          <CoupleButton
            buttonType="couple"
            onPress={() =>
              type === 'from'
                ? this.setState({
                    fromWalletIsFamily: true,
                    fromFinancialAccountId: undefined,
                    fromFinancialAccountName: '',
                  })
                : this.setState({
                    toWalletIsFamily: true,
                    toFinancialAccountId: undefined,
                    toFinancialAccountName: '',
                  })
            }
            isOn={isFamily}
            title="家族"
            disabled={isFamily || this.state.transferBase === type}
            style={{ marginLeft: 8 }}
          />
        </View>
      </View>
    )
  }

  private onPressTab = (selectedTab: TabType) => {
    const amount =
      selectedTab === 'income' || selectedTab === 'transfer'
        ? Math.abs(this.state.amount)
        : -Math.abs(this.state.amount)
    const categoryName1 = selectedTab === 'expense' ? '未分類' : '入金'
    const categoryName2 = selectedTab === 'expense' ? '未分類' : 'その他入金'
    const subCategoryId = selectedTab === 'expense' ? '387' : '394'
    this.setState({
      selectedTab,
      amount,
      categoryName1,
      categoryName2,
      subCategoryId,
      addPayoffList:
        selectedTab === 'income' ? false : this.state.addPayoffList,
    })
  }

  private renderTab = () => {
    const isExpense = this.state.selectedTab === 'expense'
    const isIncome = this.state.selectedTab === 'income'
    const isTransfer = this.state.selectedTab === 'transfer'

    return (
      <View style={styles.tab}>
        <TouchableOpacity
          onPress={() => this.onPressTab('expense')}
          style={[
            styles.wrapTab,
            isExpense ? styles.wrapTabWithBorder : styles.wrapTabWithBorderGray,
          ]}
          disabled={isExpense}>
          <Text
            style={[
              styles.tabText,
              isExpense ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            支出
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onPressTab('income')}
          style={[
            styles.wrapTab,
            isIncome ? styles.wrapTabWithBorder : styles.wrapTabWithBorderGray,
          ]}
          disabled={isIncome}>
          <Text
            style={[
              styles.tabText,
              isIncome ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            収入
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onPressTab('transfer')}
          style={[
            styles.wrapTab,
            isTransfer
              ? styles.wrapTabWithBorder
              : styles.wrapTabWithBorderGray,
          ]}
          disabled={isTransfer}>
          <Text
            style={[
              styles.tabText,
              isTransfer ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            振替
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  private handleDatePicked = (date: Date) => {
    this.setState({
      usedDate: moment(date).format(),
      isDisplayDatePicker: false,
    })
  }

  private chooseCategoryHandler = (categoryUnit: CategoryUnit) => {
    const categoryName1 =
      CategoryManager.getCategoryIconInfo(categoryUnit.categoryId)
        ?.categoryName || ''
    this.setState({
      categoryName1,
      categoryName2: categoryUnit.name,
      subCategoryId: categoryUnit.subCategoryId,
    })
  }

  private onPressCategory = async () => {
    try {
      const userAccountType = this.state.isTransactionShared ? 'family' : 'user'

      await CategoryManager.fetchCategories({ accountType: userAccountType })

      if (this.state.selectedTab === 'expense') {
        const params = {
          categoryType: this.state.selectedTab,
          userAccountType,
          isSelectable: true,
          leftButtonType: 'modal',
        }
        NavigationService.navigate('CategoryPickerTop', params)
      } else if (this.state.selectedTab === 'income') {
        const params = {
          category: CategoryManager.categoriesIncome(userAccountType)[0],
          userAccountType,
          isSelectable: true,
          leftButtonType: 'modal',
        }
        NavigationService.navigate('CategoryPickerSub', params)
      }
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  private onPressSaveButton = async () => {
    if (this.financialAccountType() !== 'carry_forward') {
      if (this.state.selectedTab === 'transfer') {
        await this.onPressSaveTransferButton()
      } else {
        await this.onPressSaveTransactionButton()
      }
    } else {
      try {
        showAppButtonIndicator('save')

        const updateImages = this.state.images
          .filter((v) => v.updatedAt === 0 || v.delete)
          .map(({ imageType, fileName, base64, delete: deleteProp }) => ({
            imageType,
            fileName,
            base64,
            delete: deleteProp,
          }))
        await CarryForwardManager.updateCarryForwardTransaction({
          transactionId: this.state.transactionId || 0,
          memo: this.state.memo,
          images: updateImages.length ? updateImages : undefined,
          ignore: !this.state.isIncludeBs,
        })
        this.goBack()
      } catch (error) {
        CommonDialog.showError({ error })
      } finally {
        hideAppButtonIndicator('save')
      }
    }
  }

  private onPressSaveTransactionButton = async () => {
    if (this.state.isCreateMode) {
      showAppButtonIndicator('save')
    } else {
      ProgressHUD.show()
    }

    let promise: Promise<void>

    if (!this.state.isCreateMode) {
      const updateImages = this.state.images
        .filter((v) => v.updatedAt === 0 || v.delete)
        .map(({ imageType, fileName, base64, delete: deleteProp }) => ({
          imageType,
          fileName,
          base64,
          delete: deleteProp,
        }))

      if (this.isManualTransaction()) {
        promise = FinancialManager.updateTransaction({
          userAccountType: this.state.isAccountShared ? 'family' : 'user',
          financialAccountType: 'manually_created',
          transactionId: this.state.transactionId || 0,
          transactionShare: this.state.isTransactionShared,
          usedLocation: this.state.usedLocation,
          usedDate: moment(this.state.usedDate).format('YYYY-MM-DD'),
          amount: this.state.amount,
          atTransactionCategoryId: this.state.subCategoryId,
          ignore: !this.state.isIncludeBs,
          memo: this.state.memo,
          paymentMethodType: this.state.walletId ? 'wallet' : undefined,
          paymentMethodId:
            this.state.walletId !== null ? this.state.walletId : undefined,
          type: this.state.amount >= 0 ? 'receipt' : 'payment',
          paymentUserType: this.state.paymentUserType,
          yetPayoff: this.state.addPayoffList,
          images: updateImages.length ? updateImages : undefined,
        })
      } else {
        const userAccountType =
          this.navigationParams.userAccountType ||
          store.getState().account.accountMode
        promise = FinancialManager.updateTransaction({
          userAccountType,
          financialAccountType: this.financialAccountType(),
          accountId: this.state.accountId,
          transactionId: this.state.transactionId || 0,
          transactionShare: this.state.isTransactionShared,
          usedLocation: this.state.usedLocation,
          usedDate: moment(this.state.usedDate).format('YYYY-MM-DD'),
          amount: this.state.amount,
          atTransactionCategoryId: this.state.subCategoryId,
          ignore: !this.state.isIncludeBs,
          memo: this.state.memo,
          paymentMethodType: this.state.walletId ? 'wallet' : undefined,
          paymentMethodId:
            this.state.walletId !== null ? this.state.walletId : undefined,
          type: this.state.amount >= 0 ? 'receipt' : 'payment',
          paymentUserType: this.state.paymentUserType,
          yetPayoff: this.state.addPayoffList,
          images: updateImages.length ? updateImages : undefined,
        })
      }
    } else {
      const images = this.state.images
        .filter((v) => !v.delete)
        .map(({ imageType, base64 }) => ({
          imageType,
          base64,
        }))

      promise = FinancialManager.createTransaction({
        transactionShare: this.state.isTransactionShared,
        usedLocation: this.state.usedLocation,
        usedDate: moment(this.state.usedDate).format('YYYY-MM-DD'),
        amount: this.state.amount,
        atTransactionCategoryId: this.state.subCategoryId,
        ignore: !this.state.isIncludeBs,
        memo: this.state.memo,
        paymentMethodType: this.state.walletId ? 'wallet' : undefined,
        paymentMethodId: this.state.walletId ? this.state.walletId : undefined,
        type: this.state.amount >= 0 ? 'receipt' : 'payment',
        paymentUserType: this.state.paymentUserType,
        yetPayoff: this.state.addPayoffList,
        images: images.length > 0 ? images : undefined,
        copy: this.state.isCopy,
      })
    }

    try {
      await promise

      if (this.state.isCreateMode) {
        if (this.state.paymentUserType === PaymentUserType.User) {
          SessionManager.setDefaultPersonalWalletId(this.state.walletId || 0)
        } else {
          SessionManager.setDefaultFamilyWalletId(this.state.walletId || 0)
        }
      }

      showCreatedTransactionDialog({
        isCreate: this.state.isCreateMode,
        isCopy: this.state.isCopy,
        newTransactionHandler: this.clearForm,
        selectedTab: CategoryManager.isIncomeBySubCategoryId(
          this.state.subCategoryId ?? '',
        )
          ? 'income'
          : 'expense',
        amount: this.state.amount,
        usedDate: this.state.usedDate,
        subCategoryId: this.state.subCategoryId,
        paymentUserType: this.state.paymentUserType,
        walletId: this.state.walletId || undefined,
        isTransactionShared: this.state.isTransactionShared,
        usedLocation: this.state.usedLocation,
        memo: this.state.memo,
        addPayoffList: this.state.addPayoffList,
        isIncludeBs: this.state.isIncludeBs,
        userAccountType:
          this.navigationParams.userAccountType ||
          store.getState().account.accountMode,
      })

      if (this.isManualTransaction()) {
        TrackingUtils.repro.trackOnce(
          '【Completed】personal_create　detailes_unique',
          'Completed',
        )
        TrackingUtils.repro.track(
          '【Completed】personal_create　detailes_cumulative',
          'Completed',
        )
        if (this.state.isTransactionShared) {
          TrackingUtils.repro.trackOnce(
            '【Completed】Allocate details to family_unique',
            'Completed',
          )
          TrackingUtils.adjust.trackEvent({ ios: '46kvf5', android: '6cnfwh' })
          TrackingUtils.repro.track(
            '【Completed】Allocate details to family_cumulative',
            'Completed',
          )
          TrackingUtils.adjust.trackEvent({ ios: '659muc', android: '11ppeh' })
        }
      }

      if (this.props.account.accountMode === 'family') {
        if (this.state.isTransactionShared) {
          if (this.isManualTransaction()) {
            TrackingUtils.repro.track(
              '【Completed】Allocate details to family_manual_cumulative',
              'Completed',
            )
          } else {
            TrackingUtils.repro.track(
              '【Completed】Allocate details to family_auto_cumulative',
              'Completed',
            )
          }
        }
      } else {
        if (this.isManualTransaction()) {
          TrackingUtils.repro.track(
            '【Completed】personal_create detailes_manual_cumulative',
            'Completed',
          )
        } else {
          TrackingUtils.repro.track(
            '【Completed】personal_create detailes_auto_cumulative',
            'Completed',
          )
        }
      }

      if (
        this.state.isCreateMode &&
        this.state.walletId &&
        this.state.isAccountShared
      ) {
        TrackingUtils.repro.track(
          '【Completed】Family_BS_add Wallet',
          'Completed',
        )
      }

      if (store.getState().account.accountMode === 'family') {
        if (this.state.isCopy) {
          TrackingUtils.repro.track(
            '【Completed】family_create detailes_by copy and create',
            'Completed',
          )
        } else if (this.state.isContinueCreate) {
          TrackingUtils.repro.track(
            '【Completed】family_create detailes_by continue to create',
            'Completed',
          )
        }
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('save')
      ProgressHUD.dismiss()
    }
  }

  private goBack = () => {
    if (!this.state.isCreateMode) {
      store.dispatch(setTabBarVisible(true))
    }

    if (!this.state.permissions.updateAmount && this.state.isCreateMode) {
      // 振替変換
      NavigationService.popToTop()
    } else {
      NavigationService.goBack()
    }
  }

  private onPressSaveTransferButton = async () => {
    ProgressHUD.show()
    showAppButtonIndicator('save')

    try {
      if (!this.state.permissions.updateAmount) {
        if (!this.state.isCreateMode) {
          // 振替更新
          const props = {
            transferId: this.state.transferId || 0,
            fromWalletId:
              this.state.fromType === 'wallet' ||
              this.state.fromType === 'manually_created'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromAtUserBankAccountId:
              this.state.fromType === 'bank'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromAtUserEmoneyServiceAccountId:
              this.state.fromType === 'emoney'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromAtUserCardAccountId:
              this.state.fromType === 'card'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromUserManuallyCreatedTransactionId:
              this.state.fromType === 'wallet' ||
              this.state.fromType === 'manually_created'
                ? this.state.fromTransactionId
                : undefined,
            fromAtUserBankTransactionId:
              this.state.fromType === 'bank'
                ? this.state.fromTransactionId
                : undefined,
            fromAtUserEmoneyTransactionId:
              this.state.fromType === 'emoney'
                ? this.state.fromTransactionId
                : undefined,
            fromAtUserCardTransactionId:
              this.state.fromType === 'card'
                ? this.state.fromTransactionId
                : undefined,
            toWalletId:
              this.state.toType === 'wallet' ||
              this.state.toType === 'manually_created'
                ? this.state.toFinancialAccountId
                : undefined,
            toAtUserBankAccountId:
              this.state.toType === 'bank'
                ? this.state.toFinancialAccountId
                : undefined,
            toAtUserEmoneyServiceAccountId:
              this.state.toType === 'emoney'
                ? this.state.toFinancialAccountId
                : undefined,
            toUserManuallyCreatedTransactionId:
              this.state.toType === 'wallet' ||
              this.state.toType === 'manually_created'
                ? this.state.toTransactionId
                : undefined,
            toAtUserBankTransactionId:
              this.state.toType === 'bank'
                ? this.state.toTransactionId
                : undefined,
            toAtUserEmoneyTransactionId:
              this.state.toType === 'emoney'
                ? this.state.toTransactionId
                : undefined,
            memo: this.state.memo,
          }
          const response = await Transfer.updateTransfer(props)
          if (!response.ok) throw new APIError(response)
          OsidoriEvent.emit(OsidoriEventName.DidUpdateTransfer, props)

          CommonDialog.showMessage('振替明細を更新しました', this.goBack)
        } else {
          // 振替変換
          const props = {
            base:
              this.state.transferBase === 'from'
                ? 'from_transaction'
                : 'to_transaction',
            fromWalletId:
              this.state.fromType === 'wallet' ||
              this.state.fromType === 'manually_created'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromAtUserBankAccountId:
              this.state.fromType === 'bank'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromAtUserEmoneyServiceAccountId:
              this.state.fromType === 'emoney'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromAtUserCardAccountId:
              this.state.fromType === 'card'
                ? this.state.fromFinancialAccountId
                : undefined,
            fromUserManuallyCreatedTransactionId:
              this.state.fromType === 'wallet' ||
              this.state.fromType === 'manually_created'
                ? this.state.fromTransactionId
                : undefined,
            fromAtUserBankTransactionId:
              this.state.fromType === 'bank'
                ? this.state.fromTransactionId
                : undefined,
            fromAtUserEmoneyTransactionId:
              this.state.fromType === 'emoney'
                ? this.state.fromTransactionId
                : undefined,
            fromAtUserCardTransactionId:
              this.state.fromType === 'card'
                ? this.state.fromTransactionId
                : undefined,
            toWalletId:
              this.state.toType === 'wallet' ||
              this.state.toType === 'manually_created'
                ? this.state.toFinancialAccountId
                : undefined,
            toAtUserBankAccountId:
              this.state.toType === 'bank'
                ? this.state.toFinancialAccountId
                : undefined,
            toAtUserEmoneyServiceAccountId:
              this.state.toType === 'emoney'
                ? this.state.toFinancialAccountId
                : undefined,
            toUserManuallyCreatedTransactionId:
              this.state.toType === 'wallet' ||
              this.state.toType === 'manually_created'
                ? this.state.toTransactionId
                : undefined,
            toAtUserBankTransactionId:
              this.state.toType === 'bank'
                ? this.state.toTransactionId
                : undefined,
            toAtUserEmoneyTransactionId:
              this.state.toType === 'emoney'
                ? this.state.toTransactionId
                : undefined,
            memo: this.state.memo,
          } as ConvertTransferProps
          const response = await Transfer.convertTransfer(props)
          if (!response.ok) throw new APIError(response)
          OsidoriEvent.emit(OsidoriEventName.DidCreateTransfer, props)

          CommonDialog.showMessage('振替明細を作成しました', this.goBack)
        }
      } else {
        // 振替作成
        const props = {
          usedDate: moment(this.state.usedDate).format('YYYY-MM-DD'),
          fromWalletId: this.state.fromFinancialAccountId || 0,
          toWalletId: this.state.toFinancialAccountId || 0,
          amount: this.state.amount,
          memo: this.state.memo,
        }
        const response = await Transfer.createTransfer(props)
        if (!response.ok) throw new APIError(response)
        OsidoriEvent.emit(OsidoriEventName.DidCreateTransfer, props)

        CommonDialog.showMessage(
          '振替明細を作成しました',
          NavigationService.goBack,
        )
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('save')
      ProgressHUD.dismiss()
    }
  }

  /** 振替解除ボタンのタップ */
  private onPressDeleteTransferButton = async () => {
    const { transferId } = this.state
    if (!transferId) return

    try {
      showAppButtonIndicator('delete')

      const response = await Transfer.deleteTransfer({ transferId })
      if (!response.ok) throw new APIError(response)
      OsidoriEvent.emit(OsidoriEventName.DidDeleteTransfer, { transferId })

      CommonDialog.showMessage('振替を解除しました', this.goBack)
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('delete')
    }
  }

  /** 利用明細ボタンのタップ */
  private onPressDeleteButton = () => {
    const { transactionId } = this.state
    if (!transactionId) return

    if (this.isManualTransaction()) {
      if (this.state.payoffId) {
        CommonDialog.showMessage(
          'この取引明細はすでに精算済みです。\nコピー・削除は、精算を解除すると行えます。精算の解除は、サイドメニューの「割り勘｜精算」から操作できます。',
        )
        return
      }

      Alert.alert('', '本当に削除しますか？', [
        { text: 'いいえ', style: 'cancel' },
        {
          text: 'はい',
          onPress: async () => {
            try {
              showAppButtonIndicator('delete')
              await FinancialManager.deleteTransaction({
                userAccountType:
                  this.navigationParams.userAccountType ||
                  store.getState().account.accountMode,
                transactionId,
              })
              CommonDialog.showMessage('利用明細を削除しました', this.goBack)
            } catch (error) {
              CommonDialog.showError({ error })
            } finally {
              hideAppButtonIndicator('delete')
            }
          },
        },
      ])
    } else {
      CommonDialog.show(
        '',
        '金融機関の取引明細はコピー・削除できません。\nコピー・削除ができるのは手動で登録した取引明細のみです。',
      )
    }
  }

  private onChangeAmount = (amount: number) => {
    const type = this.state.selectedTab
    if (
      (type === 'income' && amount < 0) ||
      (type === 'expense' && amount > 0)
    ) {
      amount = -amount
    }
    this.setState({ amount })
  }

  private financialIcon = () => {
    switch (this.financialAccountType()) {
      case 'bank':
        return require('@images/icons/icon-bank.png')
      case 'card':
        return require('@images/icons/icon-credit-card.png')
      case 'emoney':
        return require('@images/icons/icon-ic-card.png')
      default:
        return require('@images/icons/icon-wallet.png')
    }
  }
  private financialAccountType = () =>
    this.navigationParams?.financialAccountType || 'manually_created'

  private isManualTransaction = () =>
    ['manually_created', 'wallet'].includes(this.financialAccountType())
  // private isCreateMode = () => !this.navigationParams?.transactionId
  // private isUpdateMode = () => !this.state.isCreateMode

  private onChangeUsedLocation = (usedLocation: string) =>
    this.setState({ usedLocation })

  private onChangeMemo = (memo: string) => this.setState({ memo })

  private onPressTransfer = () => {
    log.info(this.state)
    if (
      // (!this.state.walletId && !this.state.accountId) ||
      !this.navigationParams.walletId &&
      !this.navigationParams.accountId
      // this.state.isCreateMode ||
      // this.state.transfer ||
      // this.state.transferId ||
      // !this.navigationParams.accountId ||
      // this.navigationParams.financialAccountType === 'stock'
    ) {
      CommonDialog.show(
        '',
        '「支払い元」が「現金」の取引明細は、振替の操作を行えません。\nこの取引明細の「支払い元」を「財布」にすることで振替を行えるようになります。',
      )
      return
    }

    // this.goBack()
    log.info(this.props.route.name)
    // NavigationService.navigate("Transfer"this.props.route.name, {

    NavigationService.navigate('Transfer', {
      transferId: this.state.transferId,
      financialAccountType: this.financialAccountType(),
      amount: this.state.amount,
      usedDate: this.state.usedDate,
      accountId: this.state.accountId || this.state.walletId,
      transterTransactionId: this.state.transactionId,
      accountName: this.state.paymentName,
      transactionUsedLocation:
        this.state.usedLocation || this.state.categoryName2,
    })
  }

  // private renderRightButton = () => {
  //   if (
  //     this.state.isCreateMode ||
  //     this.state.transfer ||
  //     this.state.transferId ||
  //     !this.navigationParams.accountId ||
  //     !this.state.isTransactionLoaded ||
  //     this.navigationParams.financialAccountType === 'stock'
  //   )
  //     return null
  //   return (
  //     <TouchableOpacity
  //       style={{ paddingHorizontal: 20, paddingBottom: 4 }}
  //       onPress={() => {
  //         NavigationService.goBack()
  //         NavigationService.navigate(this.props.route.name, {
  //           transferId: this.state.transferId,
  //           financialAccountType: this.financialAccountType(),
  //           amount: this.state.amount,
  //           usedDate: this.state.usedDate,
  //           accountId: this.state.accountId || this.state.walletId,
  //           transterTransactionId: this.state.transactionId,
  //           accountName: this.state.paymentName,
  //           transactionUsedLocation:
  //             this.state.usedLocation || this.state.categoryName2,
  //         })
  //       }}>
  //       <Image
  //         source={require('@images/buttons/header/button-header-transfer.png')}
  //         style={{ width: 24, height: 35 }}
  //         resizeMode="contain"
  //       />
  //     </TouchableOpacity>
  //   )
  // }

  private selectTransferAccountHandler: TransferSelectTransactionCallback =
    (props: {
      transferDirection: 'from' | 'to'
      financialAccountType: FinancialAccountType
      financialAccountId: number
      financialAccountName: string
      transactionId?: number
      transactionUsedLocation?: string
    }) => {
      if (props.transferDirection === 'from') {
        this.setState({
          fromType: props.financialAccountType,
          fromFinancialAccountId: props.financialAccountId,
          fromFinancialAccountName: props.financialAccountName,
          fromTransactionId: props.transactionId,
          fromTransactionUsedLocation: props.transactionUsedLocation || '',
        })
      } else {
        this.setState({
          toType: props.financialAccountType,
          toFinancialAccountId: props.financialAccountId,
          toFinancialAccountName: props.financialAccountName,
          toTransactionId: props.transactionId,
          toTransactionUsedLocation: props.transactionUsedLocation || '',
        })
      }
    }

  private title = () => {
    if (!this.state.isTransactionLoaded) return ''

    if (this.financialAccountType() === 'carry_forward') return '繰越明細'

    if (this.state.isCreateMode) {
      return this.props.route.params?.transterTransactionId
        ? '振替明細の作成'
        : '取引明細の作成'
    }

    return this.state.transferId || this.navigationParams?.transterTransactionId
      ? '振替明細'
      : '取引明細'
  }

  private checkUpdateError = () => {
    if (this.state.payoffId) {
      CommonDialog.showMessage(
        '精算済み取引のため、変更並びに削除できません。精算を解除してから実施ください。',
      )
      return false
    }
    return true
  }

  private checkPayoffError = () => {
    if (this.state.payoffId) {
      CommonDialog.showMessage(
        'この取引明細はすでに精算済みです。\nコピー・削除は、精算を解除すると行えます。精算の解除は、サイドメニューの「割り勘｜精算」から操作できます。',
      )
      return false
    }

    if (!this.props.userStatus?.paired) {
      CommonDialog.showMessage(
        '取引明細の未精算リストへの追加は、パートナーとペアリングするとできるようになります',
      )
      return false
    }

    // if (this.state.amount > 0 || this.state.selectedTab === 'income') {
    if (this.state.selectedTab === 'income') {
      CommonDialog.showMessage('収入の取引明細は精算できません')
      return false
    }

    if (!this.props.userStatus?.paired) {
      CommonDialog.showMessage(
        '取引明細の未精算リストへの追加は、パートナーとペアリングするとできるようになります',
      )
      return false
    }

    if (this.state.paymentUserType === PaymentUserType.Family) {
      CommonDialog.showMessage(
        '精算ができるのは、わたしかパートナーの取引のみです。家族の取引については、精算できません。',
      )
      return false
    }

    return true
  }

  private onPressImagePickerButton = () => {
    ImagePickerUtils.open(
      {
        compressImageMaxWidth: 1024,
        compressImageMaxHeight: 1024,
        width: Platform.OS === 'ios' ? 1024 : undefined,
        height: Platform.OS === 'ios' ? 1024 : undefined,
        freeStyleCropEnabled: true,
        includeBase64: true,
      },
      async (imageFile) => {
        if (!imageFile) return

        const originFileName = this.state.images.length
          ? this.state.images[0].fileName
          : undefined

        this.setState(
          {
            images: [],
          },
          () => {
            const imagePath = imageFile.path || ''

            this.setState({
              images: [
                {
                  imageUrl: imagePath.startsWith('file://')
                    ? imagePath
                    : imagePath
                    ? `file://${imageFile.path}`
                    : undefined,
                  imageType: 'receipt',
                  fileName: originFileName,
                  base64: imageFile.data
                    ? `data:${imageFile.mime};base64,${imageFile.data || ''}`
                    : undefined,
                  updatedAt: 0,
                },
              ],
            })
          },
        )
      },
      this.state.images.length
        ? () => {
            this.setState({
              images: this.state.images.map((v) => ({
                ...v,
                delete: true,
              })),
            })
          }
        : undefined,
    )
  }

  private startTour = (step = 1) => {
    const { startSpotlightTour, nextStep, setDisabled, setOverlayColor } =
      this.context

    startSpotlightTour(
      [
        // 2回目以降の最初(step 1, step2は飛ばしてstep 3に行く必要がある)
        {
          spotIds: ['CommonHeaderHelpIconSpot'],
          before: () => {
            store.dispatch(closeCalculator({ animated: false }))
            setDisabled(true)
            setTimeout(() => setDisabled(false), 0 + 500)
          },
          after: () => {},
        },
        {
          // 初回の最初のステップ(step 2)
          spotIds: [''],
          before: () => {
            setDisabled(true)
            store.dispatch(closeCalculator({ animated: false }))
            setOverlayColor('rgba(0, 0, 0, 0)')
            setTimeout(() => {
              setOverlayColor('rgba(0, 0, 0, 0.3)')
              nextStep()
            }, 1200)
          },
        },
        {
          spotIds: ['transactionTab', 'transactionRow1'],
          before: () => {
            setOverlayColor('rgba(0, 0, 0, 0.3)')
            setDisabled(true)
            store.dispatch(closeCalculator({ animated: false }))
            setTimeout(() => setDisabled(false), 300 + 500)
          },
          onPressSuperView: () => {
            setDisabled(true)
            nextStep()
          },
        },
        {
          spotIds: ['transactionRow2'],
          before: () => {
            setTimeout(() => setDisabled(false), 0 + 500)
          },
          onPressSuperView: () => {
            setDisabled(true)
            nextStep()
          },
        },
        {
          spotIds: ['transactionRow3'],
          before: () => {
            setTimeout(() => setDisabled(false), 0 + 500)
          },
          onPressSuperView: () => {
            setDisabled(true)
            nextStep()
          },
        },
        {
          spotIds: ['transactionRow4'],
          before: () => {
            setTimeout(() => setDisabled(false), 0 + 500)
          },
          onPressSuperView: () => {
            setDisabled(true)
            nextStep()
          },
        },
        {
          spotIds: ['CommonHeaderHelpIconSpot'],
          before: () => {
            SessionManager.setIsTutorialTransactionDisplayCompleted(true)
            setTimeout(() => setDisabled(false), 0 + 500)
          },
        },
      ],
      { step },
    )
  }

  private onPressPayoff = () => {
    if (!this.checkPayoffError()) return

    const payoffScreenProps: PayoffScreenProps = {
      transactions: [
        {
          userId: this.state.transactionUserId || 0,
          amount: this.state.amount,
          accountId: this.state.accountId,
          transactionId: this.state.transactionId || 0,
          type: this.financialAccountType(),
          atTransactionCategoryId: this.state.subCategoryId || '',
          categoryName1: this.state.categoryName1,
          categoryName2: this.state.categoryName2,
          isAccountShared: !!this.state.isAccountShared,
          isIgnored: !this.state.isIncludeBs,
          usedDate: this.state.usedDate,
          isTransactionShared: this.state.isTransactionShared,
          memo: this.state.memo,
          paymentUserType: this.state.paymentUserType,
        },
      ],
      displaySettleType: 'notSettled',
      onSettled: () => {
        NavigationService.pop(2)
      },
    }
    NavigationService.navigate('Payoff', payoffScreenProps)
  }

  render() {
    const leftButtonType =
      this.props.route.params?.transactionId ||
      (this.state.isCreateMode &&
        this.props.route.params?.transterTransactionId)
        ? 'stack'
        : 'modal'
    const leftButtonTitle = leftButtonType === 'stack' ? '戻る' : ''

    // wallets
    let wallets: Item[]
    if (this.state.paymentUserType === PaymentUserType.Family) {
      wallets =
        this.wallets.family.map((v) => ({
          label: v.name,
          value: v.id,
        })) || []
    } else if (this.state.paymentUserType === PaymentUserType.User) {
      wallets =
        this.wallets.user.map((v) => ({
          label: v.name,
          value: v.id,
        })) || []
    } else {
      wallets = []
    }
    wallets.push({ label: '現金', value: 0 })
    const walletId = wallets.find((v) => v.value === this.state.walletId)
      ? this.state.walletId
      : 0

    const isWalletPickerSelectEnabled =
      this.state.isCreateMode ||
      this.state.transactionUserId === this.props.userProfile?.userId ||
      this.state.paymentUserType !== PaymentUserType.Partner
    // this.state.isAccountShared

    const { nextStep } = this.context

    return (
      <View style={styles.container}>
        <CommonHeader
          leftButtonType={leftButtonType}
          leftButtonTitle={leftButtonTitle}
          title={this.title()}
          onPressLeftButton={this.goBack}
          onPressTitleHelpIcon={() => {
            if (this.financialAccountType() !== 'carry_forward') {
              this.startTour()
            } else {
              CommonDialog.show(
                '繰越明細について',
                '「繰越明細」は、前⽉の収⽀結果を今⽉の収⽀に反映するために⾃動で作成される明細です。\n\n' +
                  '<編集できる内容>\n' +
                  '・カテゴリー\n' +
                  '・メモ\n' +
                  '・写真\n' +
                  '・収支の計算対象に入れる\n\n' +
                  '※その他編集や精算等はできません。',
              )
            }
          }}
          // renderRightButton={() => this.renderRightButton()}
          tipsComponent={(props) => <TutorialTipsBalloon {...props} />}
        />
        <View style={{ flex: 1 }}>
          {this.state.isTransactionLoaded &&
            this.state.selectedTab !== 'transfer' && (
              <ScrollView>
                <SpotlightTourSpot
                  spotId="transactionTab"
                  scale={1}
                  rectRadius={0}
                  spotType="rect"
                  hideTips
                  onPress={nextStep}>
                  {this.state.permissions.displayTab && this.renderTab()}
                  {this.state.permissions.updateAmount ? (
                    <>
                      <View
                        style={{
                          marginTop: this.state.isCreateMode ? 10 : 20,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        {this.state.isCreateMode || (
                          <Text
                            style={{
                              flex: 1,
                              textAlign: 'right',
                              marginRight: 30,
                              fontSize: 16,
                              fontWeight: 'normal',
                            }}>
                            {this.state.selectedTab === 'expense'
                              ? '支出'
                              : '収入'}
                          </Text>
                        )}
                        <CalculatorInput
                          editable={true}
                          hideMinus
                          style={{
                            width: this.state.isCreateMode ? '100%' : undefined,
                            paddingRight: 25,
                          }}
                          value={this.state.amount}
                          onChange={this.onChangeAmount}
                          willFocus={this.checkUpdateError}
                        />
                      </View>
                    </>
                  ) : (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingTop: 20,
                        justifyContent: 'flex-end',
                      }}>
                      <Text
                        style={{
                          fontSize: 16,
                          paddingRight: 16,
                          fontWeight: 'normal',
                        }}>
                        {this.state.amount < 0 ? '支出' : '収入'}
                      </Text>
                      <View>
                        <CalculatorInput
                          editable={false}
                          hideMinus
                          value={this.state.amount}
                          onChange={this.onChangeAmount}
                          style={{ paddingRight: 25 }}
                        />
                      </View>
                    </View>
                  )}
                </SpotlightTourSpot>
                <View style={styles.wrapItem}>
                  <SpotlightTourSpot
                    spotId="transactionRow1"
                    scale={1}
                    rectRadius={0}
                    spotType="rect"
                    tipsComponent={(props) => (
                      <TutorialTipsBalloon {...props} />
                    )}
                    onPress={nextStep}
                    style={{
                      marginTop: -8,
                      paddingTop: 8,
                      marginBottom: -4,
                      paddingBottom: 4,
                      marginHorizontal: -16,
                      paddingHorizontal: 16,
                    }}>
                    <View style={{ flexDirection: 'row' }}>
                      {this.renderItem({
                        icon: require('@images/icons/icon-calendar.png'),
                        content: moment(this.state.usedDate).format(
                          'YYYY-MM-DD',
                        ),
                        isDisplayRightIcon:
                          this.state.permissions.updateUsedDate,
                        onPress: () => {
                          this.setState({ isDisplayDatePicker: true })
                        },
                        columnMargin: 10,
                        opacity: !this.state.permissions.updateUsedDate,
                      })}
                      {this.renderItem({
                        categoryId: this.state.subCategoryId,
                        content: this.state.categoryName2,
                        isDisplayRightIcon:
                          this.financialAccountType() !== 'carry_forward',
                        onPress: this.onPressCategory,
                      })}
                    </View>
                  </SpotlightTourSpot>
                  <SpotlightTourSpot
                    spotId="transactionRow2"
                    scale={1.05}
                    rectRadius={0}
                    spotType="rect"
                    tipsComponent={(props) => (
                      <TutorialTipsBalloon {...props} />
                    )}
                    tipsPosition="top"
                    onPress={nextStep}
                    style={{ marginHorizontal: -16, paddingHorizontal: 16 }}>
                    {this.financialAccountType() !== 'carry_forward' && (
                      <>
                        {this.renderSettingPaymentUserType()}
                        {!this.isManualTransaction() ||
                        !isWalletPickerSelectEnabled ? (
                          this.renderItem({
                            icon: this.financialIcon(),
                            content: this.state.paymentName,
                          })
                        ) : (
                          <View
                            style={{
                              paddingLeft: 25,
                              borderBottomWidth: 1,
                              borderBottomColor: '#ddd',
                              width: '100%',
                              height: 50,
                            }}>
                            <View
                              pointerEvents="none"
                              style={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                position: 'absolute',
                              }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}>
                                <Image
                                  source={require('@images/icons/icon-wallet.png')}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    resizeMode: 'contain',
                                  }}
                                />
                                <Text style={styles.paymentPlaceText}>
                                  {this.state.selectedTab === 'expense'
                                    ? '支払い元'
                                    : '入金先'}
                                </Text>
                              </View>
                              {this.state.paymentUserType !==
                                PaymentUserType.Partner && (
                                <Image
                                  source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
                                  style={styles.iconDown}
                                />
                              )}
                            </View>
                            {this.state.payoffId ? (
                              <Pressable
                                onPress={this.checkUpdateError}
                                style={{ flex: 1, justifyContent: 'center' }}>
                                <Text
                                  style={{
                                    paddingRight: 31,
                                    textAlign: 'right',
                                    fontSize: 16,
                                    fontWeight: 'normal',
                                    color: Color.DefaultText,
                                  }}>
                                  {
                                    wallets.find((v) => v.value === walletId)
                                      ?.label
                                  }
                                </Text>
                              </Pressable>
                            ) : (
                              <Pressable
                                disabled={
                                  this.state.paymentUserType ===
                                  PaymentUserType.Partner
                                }
                                onPress={() =>
                                  NavigationService.navigate('PaymentSource', {
                                    paymentUserType: this.state.paymentUserType,
                                    onSelectWallet: async (
                                      wallet: WalletEntity,
                                    ) => {
                                      await this.getWallets()
                                      this.setState({ walletId: wallet.id })
                                    },
                                  })
                                }
                                style={{ flex: 1, justifyContent: 'center' }}>
                                <Text
                                  style={{
                                    paddingRight: 31,
                                    textAlign: 'right',
                                    fontSize: 16,
                                    fontWeight: 'normal',
                                    color: Color.DefaultText,
                                  }}>
                                  {
                                    wallets.find((v) => v.value === walletId)
                                      ?.label
                                  }
                                </Text>
                              </Pressable>
                            )}
                          </View>
                        )}
                      </>
                    )}
                    {this.renderSettingShareView()}
                  </SpotlightTourSpot>
                  <SpotlightTourSpot
                    spotId="transactionRow3"
                    scale={1.05}
                    rectRadius={0}
                    spotType="rect"
                    tipsComponent={(props) => (
                      <TutorialTipsBalloon {...props} />
                    )}
                    tipsPosition="top"
                    onPress={nextStep}
                    style={{ marginHorizontal: -16, paddingHorizontal: 16 }}>
                    <View style={styles.wrapContent}>
                      <Image
                        source={
                          this.state.selectedTab === 'expense'
                            ? require('@images/icons/icon-shop.png')
                            : require('@images/category/icon-category-income.png')
                        }
                        style={{
                          width: 24,
                          height: 22,
                          resizeMode: 'contain',
                        }}
                      />
                      <EllipsizeTextInput
                        value={this.state.usedLocation}
                        onChangeText={this.onChangeUsedLocation}
                        placeholder={
                          this.state.selectedTab === 'expense'
                            ? 'お店のおなまえ'
                            : '収入の内容'
                        }
                        style={[
                          styles.ellipsizeTextInput,
                          !this.state.permissions.updateUsedPlace
                            ? {
                                color: '#9e9e9e',
                                // opacity: 0.6,
                              }
                            : {},
                        ]}
                        containerStyle={{ flex: 1 }}
                        editable={this.state.permissions.updateUsedPlace}
                      />
                    </View>
                    <View>
                      <View
                        style={[
                          styles.wrapContent,
                          {
                            alignItems: 'flex-start',
                            height: undefined,
                            minHeight: 56,
                          },
                        ]}>
                        <Image
                          source={require('@images/icons/icon-memo.png')}
                          style={{
                            marginTop: 20,
                            width: 24,
                            height: 18,
                            resizeMode: 'contain',
                          }}
                        />
                        <EllipsizeTextInput
                          value={this.state.memo}
                          onChangeText={this.onChangeMemo}
                          placeholder="メモ"
                          multiline={true}
                          style={[
                            styles.ellipsizeTextInput,
                            {
                              marginBottom: Platform.select({ ios: 10 }),
                              flex: 1,
                            },
                          ]}
                          containerStyle={{ flex: 1 }}
                          autoFocus={this.navigationParams.focusMemo}
                          returnKeyType="default"
                        />
                        <TouchableOpacity
                          onPress={this.onPressImagePickerButton}
                          style={{
                            paddingHorizontal: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '100%',
                          }}>
                          <FAIcon
                            icon={faImage as never}
                            size={20}
                            color={Color.Gray}
                          />
                          <Text
                            style={{
                              marginLeft: 4,
                              fontSize: 14,
                              fontWeight: 'normal',
                              color: Color.DefaultText,
                            }}>
                            写真
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </SpotlightTourSpot>
                  {this.state.images
                    .filter((v) => !v.delete && v.imageUrl)
                    .map((v, index) => (
                      <TouchableOpacity
                        key={index}
                        onPress={() =>
                          this.setState({ isVisibleImageView: true })
                        }
                        style={{
                          flex: 1,
                          paddingVertical: 5,
                          borderBottomWidth: 1,
                          borderBottomColor: '#ddd',
                        }}>
                        <FullWidthImage source={{ uri: v.imageUrl }} />
                      </TouchableOpacity>
                    ))}
                  <SpotlightTourSpot
                    spotId="transactionRow4"
                    scale={1.05}
                    rectRadius={0}
                    spotType="rect"
                    tipsComponent={(props) => (
                      <TutorialTipsBalloon {...props} />
                    )}
                    tipsPosition="top"
                    onPress={nextStep}
                    style={{ marginHorizontal: -16, paddingHorizontal: 16 }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                      {this.financialAccountType() !== 'carry_forward' && (
                        <>
                          <Pressable
                            style={styles.col2}
                            disabled={!!this.state.payoffId}
                            onPress={() => {
                              if (!this.checkPayoffError()) return
                              this.setState({
                                addPayoffList: !this.state.addPayoffList,
                              })
                            }}>
                            <CheckMarkToggleButton
                              isOn={
                                this.state.addPayoffList && !this.state.payoffId
                              }
                              disabled={true}
                            />
                            <Text
                              style={{
                                marginTop: 5,
                                marginLeft: 10,
                                color: this.state.payoffId
                                  ? '#aaaaaa'
                                  : Color.DefaultText,
                                fontSize: 14,
                                fontWeight: 'bold',
                              }}>
                              {'未精算リストに\n入れる'}
                            </Text>
                          </Pressable>
                          <View style={{ width: 15 }} />
                        </>
                      )}
                      <Pressable
                        style={styles.col2}
                        onPress={() => {
                          this.setState({
                            isIncludeBs: !this.state.isIncludeBs,
                          })
                        }}>
                        <CheckMarkToggleButton
                          isOn={this.state.isIncludeBs}
                          disabled={true}
                        />
                        <Text
                          style={{
                            marginTop: 5,
                            marginLeft: 10,
                            color: Color.DefaultText,
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}>
                          {this.financialAccountType() === 'carry_forward'
                            ? '収支の計算対象に入れる'
                            : '収支の計算対象\nに入れる'}
                        </Text>
                      </Pressable>
                    </View>
                  </SpotlightTourSpot>
                </View>
                {this.state.isCreateMode && (
                  <View style={styles.wrapButton}>
                    {/* {!this.state.isCreateMode &&
                    this.financialAccountType() !== 'carry_forward' && (
                      <AppButton
                        title="精算する"
                        key="PayoffButton"
                        disabled={!!this.state.payoffId}
                        onPress={() => {
                          if (!this.checkPayoffError()) return

                          const payoffScreenProps: PayoffScreenProps = {
                            transactions: [
                              {
                                userId: this.state.transactionUserId || 0,
                                amount: this.state.amount,
                                accountId: this.state.accountId,
                                transactionId: this.state.transactionId || 0,
                                type: this.financialAccountType(),
                                atTransactionCategoryId:
                                  this.state.subCategoryId || '',
                                categoryName1: this.state.categoryName1,
                                categoryName2: this.state.categoryName2,
                                isAccountShared: !!this.state.isAccountShared,
                                isIgnored: !this.state.isIncludeBs,
                                usedDate: this.state.usedDate,
                                isTransactionShared:
                                  this.state.isTransactionShared,
                                memo: this.state.memo,
                                paymentUserType: this.state.paymentUserType,
                              },
                            ],
                            displaySettleType: 'notSettled',
                            onSettled: () => {
                              NavigationService.pop(2)
                            },
                          }
                          NavigationService.navigate(
                            'Payoff',
                            payoffScreenProps,
                          )
                        }}
                        type="white"
                        loadingKey="payoff"
                        style={styles.submitButton}
                        // textStyle={{ color: '#888' }}
                      />
                    )}
                  {this.state.permissions.deleteTransaction && (
                    <>
                      <View style={{ width: 8 }} />
                      <AppButton
                        title="削除する"
                        key="DeleteButton"
                        onPress={() => {
                          Alert.alert('', '本当に削除しますか？', [
                            { text: 'いいえ', style: 'cancel' },
                            {
                              text: 'はい',
                              onPress: this.onPressDeleteButton,
                            },
                          ])
                        }}
                        type="white"
                        loadingKey="delete"
                        style={styles.submitButton}
                      />
                    </>
                  )}
                  <View style={{ width: 8 }} /> */}
                    <AppButton
                      title="保存する"
                      key="SaveButton"
                      onPress={this.onPressSaveButton}
                      disabled={
                        (this.state.amount === 0 ||
                          (this.isManualTransaction() &&
                            this.state.walletId === null)) &&
                        this.financialAccountType() !== 'carry_forward'
                      }
                      loadingKey="save"
                      style={styles.submitButton}
                    />
                  </View>
                )}
              </ScrollView>
            )}

          {/* 振替 */}
          {this.state.isTransactionLoaded &&
            this.state.selectedTab === 'transfer' && (
              <ScrollView>
                {this.state.permissions.displayTab && this.renderTab()}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: this.state.permissions.displayTab ? 10 : 20,
                    justifyContent: 'flex-end',
                  }}>
                  {this.state.permissions.displayTab || (
                    <Text
                      style={{
                        fontSize: 16,
                        paddingRight: 16,
                        fontWeight: 'normal',
                      }}>
                      振替
                    </Text>
                  )}
                  <View>
                    <CalculatorInput
                      editable={this.state.permissions.updateAmount}
                      hideMinus
                      style={{
                        width: this.state.permissions.displayTab
                          ? '100%'
                          : undefined,
                        paddingRight: 25,
                      }}
                      value={this.state.amount}
                      onChange={this.onChangeAmount}
                    />
                  </View>
                </View>
                <View style={styles.wrapItem}>
                  {this.renderItem({
                    icon: require('@images/icons/icon-calendar.png'),
                    content: moment(this.state.usedDate).format('YYYY-MM-DD'),
                    isDisplayRightIcon: this.state.permissions.updateUsedDate,
                    onPress: () => {
                      this.setState({ isDisplayDatePicker: true })
                    },
                  })}
                  {this.renderWalletTypeView('from')}
                  <View style={{ flexDirection: 'row' }}>
                    <View
                      style={{
                        width: 40,
                        paddingLeft: 10,
                        justifyContent: 'center',
                      }}>
                      <Image
                        source={require('@images/icons/icon-transfer-arrow.png')}
                        style={{ width: 20, height: 20, resizeMode: 'contain' }}
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        paddingLeft: 25,
                        borderBottomWidth: 1,
                        borderBottomColor: '#ddd',
                        height: 50,
                      }}>
                      <TouchableOpacity
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          flex: 1,
                          alignItems: 'center',
                        }}
                        disabled={this.state.transferBase === 'from'}
                        onPress={() => {
                          const params: TransferSelectFinancialAccountScreenParams =
                            {
                              userAccountType: this.state.fromWalletIsFamily
                                ? 'family'
                                : 'user',
                              transferDirection: 'from',
                              isDisplayWalletsOnly:
                                this.state.isCreateMode &&
                                !this.navigationParams.amount,
                              isSelectFinancialAccountOnly:
                                this.state.isCreateMode &&
                                !this.navigationParams.amount,
                              amount: !this.state.permissions.updateAmount
                                ? this.state.amount
                                : undefined,
                              callback: this.selectTransferAccountHandler,
                            }
                          NavigationService.navigate(
                            this.state.isCreateMode // TODO
                              ? 'TransferSelectFinancialAccount'
                              : 'TransferSelectFinancialAccount',
                            params,
                          )
                        }}>
                        {this.state.fromFinancialAccountName ? (
                          <View style={{ flexDirection: 'column' }}>
                            <Text
                              style={{
                                fontSize: 16,
                                color: Color.DefaultText,
                                textAlign: 'right',
                                fontWeight: 'normal',
                              }}
                              numberOfLines={1}>
                              {this.state.fromFinancialAccountName}
                            </Text>
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 'normal',
                                color: Color.DefaultText,
                                marginTop: 8,
                                textAlign: 'right',
                              }}>
                              {this.state.fromTransactionUsedLocation ||
                                '取引指定なし'}
                            </Text>
                          </View>
                        ) : (
                          <Text
                            style={{
                              fontSize: 16,
                              color: Color.Gray,
                              fontWeight: 'normal',
                            }}>
                            振替元を指定してください
                          </Text>
                        )}
                        <Image
                          source={require('@images/arrow/icon-arrow-white-small.png')}
                          style={{
                            tintColor: Color.Gray,
                            marginLeft: 5,
                            width: 14,
                            height: 14,
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  {this.renderWalletTypeView('to')}
                  <View
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: '#ddd',
                      height: 50,
                      alignItems: 'flex-end',
                    }}>
                    <TouchableOpacity
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        flex: 1,
                        alignItems: 'center',
                      }}
                      disabled={this.state.transferBase === 'to'}
                      onPress={() => {
                        const params: TransferSelectFinancialAccountScreenParams =
                          {
                            userAccountType: this.state.toWalletIsFamily
                              ? 'family'
                              : 'user',
                            transferDirection: 'to',
                            isDisplayWalletsOnly:
                              this.state.isCreateMode &&
                              !this.navigationParams.amount,
                            isSelectFinancialAccountOnly:
                              this.state.isCreateMode &&
                              !this.navigationParams.amount,
                            amount: !this.state.permissions.updateAmount
                              ? this.state.amount
                              : undefined,
                            callback: this.selectTransferAccountHandler,
                          }
                        NavigationService.navigate(
                          this.state.isCreateMode // TODO
                            ? 'TransferSelectFinancialAccount'
                            : 'TransferSelectFinancialAccount',
                          params,
                        )
                      }}>
                      {this.state.toFinancialAccountName ? (
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: 'normal',
                              color: Color.DefaultText,
                              textAlign: 'right',
                            }}>
                            {this.state.toFinancialAccountName}
                          </Text>
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: 'normal',
                              color: Color.DefaultText,
                              marginTop: 8,
                              textAlign: 'right',
                            }}
                            numberOfLines={1}>
                            {this.state.toTransactionUsedLocation ||
                              '取引指定なし'}
                          </Text>
                        </View>
                      ) : (
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: 'normal',
                            color: Color.Gray,
                          }}>
                          振替先を指定してください
                        </Text>
                      )}
                      <Image
                        source={require('@images/arrow/icon-arrow-white-small.png')}
                        style={{
                          tintColor: Color.Gray,
                          marginLeft: 5,
                          width: 14,
                          height: 14,
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={[
                      styles.wrapContent,
                      {
                        alignItems: 'flex-start',
                        height: undefined,
                        minHeight: 56,
                      },
                    ]}>
                    <Image
                      source={require('@images/icons/icon-memo.png')}
                      style={{
                        marginTop: 20,
                        width: 24,
                        height: 18,
                        resizeMode: 'contain',
                      }}
                    />
                    <EllipsizeTextInput
                      value={this.state.memo}
                      onChangeText={this.onChangeMemo}
                      placeholder="メモ"
                      multiline={true}
                      style={[
                        styles.ellipsizeTextInput,
                        { marginBottom: Platform.select({ ios: 10 }) },
                      ]}
                      containerStyle={{ flex: 1 }}
                      autoFocus={this.navigationParams.focusMemo}
                    />
                  </View>
                </View>
                {/* 保存、解除ボタン */}
                {this.state.isCreateMode &&
                (() => {
                  return true
                })() ? (
                  <View style={styles.wrapButton}>
                    <AppButton
                      title="保存する"
                      key="SaveButton"
                      onPress={this.onPressSaveButton}
                      disabled={
                        this.state.amount === 0 ||
                        !this.state.fromFinancialAccountId ||
                        !this.state.toFinancialAccountId
                      }
                      loadingKey="save"
                      style={{ flex: 1 }}
                    />
                  </View>
                ) : (
                  <View style={styles.wrapButton}>
                    <AppButton
                      title="解除する"
                      key="DeleteButton"
                      onPress={() => {
                        Alert.alert(
                          '振替を解除しますか？',
                          '振替を解除すると、振替で新しく作成された取引明細も削除されます。',
                          [
                            { text: 'いいえ', style: 'cancel' },
                            {
                              text: 'はい',
                              onPress: this.onPressDeleteTransferButton,
                            },
                          ],
                        )
                      }}
                      type="white"
                      loadingKey="delete"
                      style={styles.submitButton}
                    />
                    <View style={{ width: 15 }} />
                    <AppButton
                      title="更新する"
                      key="SaveButton"
                      onPress={this.onPressSaveButton}
                      loadingKey="save"
                      style={styles.submitButton}
                    />
                  </View>
                )}
              </ScrollView>
            )}

          <CreatedTransactionDialog />
          {this.state.isTransactionLoaded || (
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <ActivityIndicator />
            </View>
          )}
        </View>
        {this.state.isTransactionLoaded && (
          <DatePicker
            date={moment(this.state.usedDate).toDate()}
            isVisible={this.state.isDisplayDatePicker}
            onConfirm={this.handleDatePicked}
            onCancel={() => this.setState({ isDisplayDatePicker: false })}
            headerFormat="日付を選択"
            confirmText="確定"
            cancelText="キャンセル"
          />
        )}
        <Modal visible={this.state.isVisibleImageView} animationType="fade">
          <SafeAreaProvider>
            <ImageViewer
              imageUrls={[{ url: this.state.images[0]?.imageUrl ?? '' }]}
              onSwipeDown={() => this.setState({ isVisibleImageView: false })}
              enableSwipeDown={true}
              renderIndicator={() => <></>}
            />
            <SafeAreaView style={{ position: 'absolute' }}>
              <TouchableOpacity
                onPress={() => this.setState({ isVisibleImageView: false })}
                style={{
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                }}>
                <Text
                  style={{
                    color: 'white',
                    fontSize: 16,
                    fontWeight: 'normal',
                    textShadowOffset: { width: 1, height: 1 },
                    textShadowRadius: 1,
                    textShadowColor: 'rgba(0, 0, 0, 0.1)',
                  }}>
                  閉じる
                </Text>
              </TouchableOpacity>
            </SafeAreaView>
          </SafeAreaProvider>
        </Modal>
        {!this.state.isCreateMode &&
          !(
            this.state.transferId ||
            this.navigationParams?.transterTransactionId
          ) && (
            <StyledFooterView>
              <StyledTabView>
                <StyledTabButton onPress={this.onPressDeleteButton}>
                  <StyledTabDeleteImage />
                  <StyledTabText>削除</StyledTabText>
                </StyledTabButton>
                <StyledTabButton onPress={this.onPressPayoff}>
                  <StyledTabPayoffImage />
                  <StyledTabText>精算</StyledTabText>
                </StyledTabButton>
                <StyledTabButton onPress={this.onPressTransfer}>
                  <StyledTabTransferImage />
                  <StyledTabText>振替</StyledTabText>
                </StyledTabButton>
                <StyledTabButton
                  onPress={async () => {
                    if (this.isManualTransaction()) {
                      if (this.state.payoffId) {
                        CommonDialog.showMessage(
                          'この取引明細はすでに精算済みです。\nコピー・削除は、精算を解除すると行えます。精算の解除は、サイドメニューの「割り勘｜精算」から操作できます。',
                        )
                        return false
                      }

                      if (this.state.termTransactionId) {
                        CommonDialog.showMessage(
                          '「繰り返し明細」はコピーできません。\n新たに取引明細を作成するか、繰り返し明細を作成・編集してください。',
                        )
                        return false
                      }

                      const response = await Financial.confirmCopyTransaction()
                      if (response.ok) {
                        NavigationService.navigate('CreateTransactionDetail', {
                          userAccountType: this.props.account.accountMode,
                          isCopy: true,
                          selectedTab: this.state.selectedTab,
                          amount: this.state.amount,
                          usedDate: moment().format('YYYY-MM-DD'),
                          subCategoryId: this.state.subCategoryId,
                          paymentUserType: this.state.paymentUserType,
                          walletId: this.state.walletId,
                          isTransactionShared: this.state.isTransactionShared,
                          usedLocation: this.state.usedLocation,
                          memo: this.state.memo,
                          addPayoffList: this.state.addPayoffList,
                          isIncludeBs: this.state.isIncludeBs,
                          // userAccountType: this.props.account.accountMode,
                          // isAccountShared: this.state.isAccountShared,
                        })

                        // this.sretState({
                        //   isCeateMode: true,
                        //   isCopy: true,
                        //   usedDate: moment().format('YYYY-MM-DD'),
                        // })
                      } else {
                        this.setState({ isDisplayPremiumView: true })
                        sendSignal(
                          'TRANSACTION_COPY_LIMIT',
                          'TransactionDetail',
                        )
                      }
                    } else {
                      CommonDialog.show(
                        '',
                        '金融機関の取引明細はコピー・削除できません。\nコピー・削除ができるのは手動で登録した取引明細のみです。',
                      )
                    }
                  }}>
                  <StyledTabCopyImage />
                  <StyledTabText>コピー</StyledTabText>
                </StyledTabButton>
                <StyledTabButton onPress={this.onPressSaveButton}>
                  <StyledTabSaveImage />
                  <StyledTabText>保存</StyledTabText>
                </StyledTabButton>
              </StyledTabView>
            </StyledFooterView>
          )}
        {this.state.isDisplayPremiumView && (
          <PremiumView
            buttonTitle="30日間無料で試す"
            title="無料プランの上限になりました"
            message="※無料プランでは「コピー作成機能」を1日に3回までご利用できます。"
            onPressShowDetailButton={() => {
              if (this.state.isCreateMode) {
                NavigationService.goBack()
              } else {
                this.setState({ isDisplayPremiumView: false })
              }
              navigatePremiumPlanLP({ planCode: '001' })
              sendSignal('TRANSACTION_COPY_LIMIT_OPEN_LP', 'TransactionDetail')
            }}
            image={require('@images/premium/premium_copy.png')}
            onBackdropPress={() => {
              if (this.state.isCreateMode) {
                NavigationService.goBack()
              } else {
                this.setState({ isDisplayPremiumView: false })
              }
            }}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    userStatus: state.profile.userStatus,
    userProfile: state.profile.userProfile,
    account: state.account,
    transactionDefaultSettings: state.settingTransactionDefault,
  }
}

export default connect(mapStateToProps)(TransactionDetailScreen)

const StyledFooterView = styled.View({
  width: '100%',
  marginBottom: 20,
  paddingHorizontal: 20,
})

const StyledTabView = styled.View({
  width: '100%',
  height: 65,
  backgroundColor: '#F7F7F7',
  borderColor: '#E0E0E0',
  borderWidth: 1,
  borderRadius: 35,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  // paddingTop: 10,
  // paddingBottom: 3,
  paddingHorizontal: 25,
})

const StyledTabButton = styled.TouchableOpacity({
  alignItems: 'center',
  width: 45,
  height: 45,
  // backgroundColor: 'rgba(0, 0, 255, 0.1)',
})

const StyledTabDeleteImage = styled.Image.attrs({
  resizeMode: 'contain',
  source: require('@assets/images/tab/transaction_tab_delete.png'),
})({
  flex: 1,
  width: 25,
})

const StyledTabPayoffImage = styled.Image.attrs({
  resizeMode: 'contain',
  source: require('@assets/images/tab/transaction_tab_pay.png'),
})({
  flex: 1,
  width: 25,
})

const StyledTabTransferImage = styled.Image.attrs({
  resizeMode: 'contain',
  source: require('@assets/images/tab/transaction_tab_transfer.png'),
})({
  flex: 1,
  width: 25,
})

const StyledTabCopyImage = styled.Image.attrs({
  resizeMode: 'contain',
  source: require('@assets/images/tab/transaction_tab_copy.png'),
})({
  flex: 1,
  width: 25,
})

const StyledTabSaveImage = styled.Image.attrs({
  resizeMode: 'contain',
  source: require('@assets/images/tab/transaction_tab_save.png'),
})({
  flex: 1,
  width: 25,
})

const StyledTabText = styled.Text({
  color: '#666666',
  fontSize: 10,
})
