import AppButton from '@components/AppButton'
import Color from '@lib/Color'
import { WebApp } from '@lib/Env'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react'
import {
  Alert,
  AlertButton,
  AlertOptions,
  Modal,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import styled from 'styled-components/native'

interface State {
  visible: boolean
  title?: string
  message?: string
  buttons?: AlertButton[]
  menuDirection?: 'row' | 'column'
  prompt: boolean
  promptText: string
}

class WebAlert extends React.Component<{}, State> {
  private static current?: WebAlert

  state: State = {
    visible: false,
    prompt: false,
    promptText: '',
  }

  public static alert = (
    title: string,
    message?: string,
    buttons?: AlertButton[],
    options?: (AlertOptions & { menuDirection?: 'row' | 'column' }) | undefined,
  ) => {
    if (WebApp) {
      WebAlert.current?.setState({
        visible: true,
        title,
        message,
        buttons,
        menuDirection: options?.menuDirection,
        prompt: false,
      })
    } else {
      Alert.alert(title, message, buttons, options)
    }
  }

  public static prompt = (
    title: string,
    message?: string,
    buttons?: AlertButton[],
  ) => {
    if (Platform.OS === 'web') {
      WebAlert.current?.setState({
        visible: true,
        title,
        message,
        buttons,
        prompt: true,
      })
    } else {
      Alert.prompt(title, message, buttons)
    }
  }

  componentDidMount() {
    WebAlert.current = this
  }

  componentWillUnmount() {
    WebAlert.current = undefined
  }

  private onPress = (onPress?: (value?: string | undefined) => void) => {
    this.setState({ visible: false }, () =>
      onPress?.(this.state.prompt ? this.state.promptText : undefined),
    )
  }

  render() {
    const { title, message, buttons, visible, prompt } = this.state

    // if (!visible) return null

    return (
      <Modal transparent={true} visible={visible}>
        <View style={styles.container}>
          <View style={styles.bodyPopup}>
            {!!title && <Text style={styles.titleText}>{title}</Text>}
            {!!message && <Text style={styles.bodyText}>{message}</Text>}
            {prompt && (
              <StyledTextInput
                onChangeText={(text) => this.setState({ promptText: text })}
              />
            )}
            <View
              style={[
                styles.wrapButton,
                { flexDirection: this.state?.menuDirection ?? 'row' },
                title || message ? {} : { marginTop: 25 },
              ]}>
              {buttons?.map((v) => (
                <AppButton
                  type="secondary"
                  key={v.text}
                  title={v.text || ''}
                  style={[
                    styles.appButton,
                    this.state.menuDirection === 'column'
                      ? {
                          width: '100%',
                          marginVertical: 4,
                        }
                      : {
                          flex: 1,
                        },
                  ]}
                  textStyle={styles.appButtonText}
                  onPress={() => this.onPress(v.onPress)}
                />
              ))}
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

export type ReduxState = {
  isVisible: boolean
  title: string
  message?: string
  buttons?: AlertButton[]
  options?: (AlertOptions & { menuDirection?: 'row' | 'column' }) | undefined
}

type Reducer = {
  setIsVisibleAlert: (
    state: ReduxState,
    action: PayloadAction<{ isVisible: boolean }>,
  ) => void
}

const initialState: ReduxState = {
  isVisible: false,
  title: '',
}

const slice = createSlice<ReduxState, Reducer>({
  name: 'alert',
  initialState,
  reducers: {
    setIsVisibleAlert: (state, { payload }) => {
      state.isVisible = payload.isVisible
    },
  },
})

export const { setIsVisibleAlert } = slice.actions

export const reducer = slice.reducer

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.6)',
    paddingHorizontal: wp('10%'),
    justifyContent: 'center',
  },
  titleText: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333333',
    width: '100%',
    textAlign: 'center',
  },
  bodyText: {
    margin: 20,
    fontSize: 14,
    fontWeight: 'normal',
    color: '#333333',
    textAlign: 'center',
  },

  bodyPopup: {
    backgroundColor: Color.LightGray,
    borderRadius: 10,
    overflow: 'hidden',
  },
  textContent: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: wp('3%'),
    fontWeight: 'normal',
  },
  button: {
    backgroundColor: Color.Primary,
    flex: 1,
    height: 50,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  appButton: {
    marginHorizontal: 8,
    paddingHorizontal: 1,
    paddingVertical: 4,
  },
  appButtonText: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: 16,
  },
  wrapButton: {
    marginTop: 15,
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    // backgroundColor: Color.White,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
})

const StyledTextInput = styled.TextInput({
  borderWidth: 1,
  borderColor: Color.Gray,
  borderRadius: 5,
  backgroundColor: 'white',
  marginHorizontal: 15,
  marginVertical: 20,
  paddingHorizontal: 8,
  height: 32,
})

export default WebAlert
