import { measure } from '@Screen/Debug/SystemReportScreen'
import { UserAccountType } from '@interfaces/Account'
import {
  FinancialAccount,
  ProfitLossSummary,
  Transaction,
  TransactionDetail,
} from '@interfaces/Financial'
import { getDateRange, getYearMonth, getYearlyDateRange } from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import DateUtilsV1 from '@lib/DateUtilsV1'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import { APIError } from '@lib/api'
import Financial, {
  CheckTransactionsUpdatedResponse,
  CreateTransactionDetailProps,
  DeleteAccountProps,
  DeleteTransactionProps,
  GetAccountTransactionsProps,
  GetAccountTransactionsResponse,
  GetAccountsResponse,
  GetCardThisMonthsPaymentResponse,
  GetFinanceSortResponse,
  GetGroupedTransactionsProps,
  GetHomeExpensesSummaryResponse,
  GetManuallyCreatedTransactionsCountResponse,
  GetMonthlyReportBsProps,
  GetPlCategoriesResponse,
  GetPlGroupedCategoriesResponse,
  GetTransactionDetailProps,
  GetTransactionsProps,
  GetTransactionsResponse,
  MonthlyReportBs,
  ScrapingAccountProps,
  UpdateAccountProps,
  UpdateFinanceAccountSortProps,
  UpdateFinanceSortProps,
  UpdateTransactionDetailProps,
} from '@lib/api/Financial'
import Stock, {
  DeleteStockAccountProps,
  GetAssetProductsProps,
  GetAssetProductsResponse,
  GetStockAccountsResponse,
  UpdateStockAccountProps,
} from '@lib/api/Stock'
import {
  CreateWalletProps,
  DeleteWalletProps,
  GetWalletsProps,
  GetWalletsResponse,
  UpdateWalletProps,
  Wallet,
} from '@lib/api/Wallet'
import store from '@redux/store'
import moment from 'moment'
import {
  updateCalendarTransactions,
  updateHomeExpensesTransactions,
  updateLatestTransactionsCount,
} from './types'

type CacheType = 'transactions' | 'bankTransaction'

class FinancialManager {
  fetchAssets = async (
    userAccountType: UserAccountType = store.getState().account.accountMode,
  ) => {
    const banks = await measure(
      'GetBankAccounts',
      async () => await this.getBankAccounts(userAccountType),
    )
    const emoneys = await measure(
      'GetEmoneyAccounts',
      async () => await this.getEmoneyAccounts(userAccountType),
    )

    const wallets = await measure(
      'FetchWallets',
      async () =>
        (
          await this.fetchWallets({ userAccountType, sort: true })
        ).map((v) => ({
          accountId: v.id,
          financialAccountType: 'wallet',
          name: v.name,
          amount: v.amount,
          fncId: '',
          lastRsltCd: '0',
          lastRsltMsg: '',
        })) as FinancialAccount[],
    )

    const investments = await measure(
      'GetStockAccounts',
      async () => await this.getStockAccounts(userAccountType),
    )

    // Total
    let totalAssetAmount = 0
    // Array.flat()はiOS11 Safari未サポートのようなのでconcatを使う
    banks.concat(emoneys, wallets).forEach((financialAccount) => {
      totalAssetAmount += financialAccount.amount ?? 0
    })
    investments.forEach((financialAccount) => {
      totalAssetAmount += financialAccount.balance ?? 0
    })

    store.dispatch({
      type: 'UpdateTotalAssetAmount',
      userAccountType,
      totalAssetAmount,
    })

    // Assets
    store.dispatch({
      type: 'UpdateFinancialAssets',
      userAccountType,
      assets: {
        banks,
        emoneys,
      },
    })
    store.dispatch({
      type: 'UpdateInvestments',
      userAccountType,
      investments,
    })
  }

  getThisMonthsPayment = async (
    userAccountType: UserAccountType = store.getState().account.accountMode,
  ) => {
    const thisMonthsPaymentResponse = await Financial.getCardThisMonthsPayment({
      accountType: userAccountType,
    })

    if (thisMonthsPaymentResponse.ok) {
      store.dispatch({
        type: 'UpdateThisMonthsPayment',
        userAccountType,
        thisMonthsPayment: (
          thisMonthsPaymentResponse.json as GetCardThisMonthsPaymentResponse
        ).app.currentMonthPayment,
      })
    }
  }

  scrapingAcount = async (props: ScrapingAccountProps) => {
    const response = await Financial.scrapingAccount(props)
    if (!response.ok) throw new APIError(response)
  }

  getFinancialAccounts = async (
    financialAccountType: 'bank' | 'card' | 'emoney',
    userAccountType: UserAccountType = store.getState().account.accountMode,
  ) => {
    const response = await Financial.getAccounts({
      accountType: userAccountType,
      financialType: financialAccountType,
    })
    if (!response.ok) throw new APIError(response)
    return (response.json as GetAccountsResponse).app.map((v) => ({
      ...v,
      financialAccountType,
    })) as FinancialAccount[]
  }

  getBankAccounts = (userAccountType?: UserAccountType) => {
    return this.getFinancialAccounts('bank', userAccountType)
  }

  getCardAccounts = (userAccountType?: UserAccountType) => {
    return this.getFinancialAccounts('card', userAccountType)
  }

  getEmoneyAccounts = (userAccountType?: UserAccountType) => {
    return this.getFinancialAccounts('emoney', userAccountType)
  }

  fetchFinancialAccounts = async (
    financialAccountType: 'bank' | 'card' | 'emoney',
    userAccountType?: UserAccountType,
  ) => {
    const financialAccounts = await this.getFinancialAccounts(
      financialAccountType,
      userAccountType,
    )
    switch (financialAccountType) {
      case 'bank':
        store.dispatch({
          type: 'UpdateBankAccounts',
          userAccountType,
          banks: financialAccounts,
        })
        break
      case 'card':
        store.dispatch({
          type: 'UpdateCardAccounts',
          userAccountType,
          cards: financialAccounts,
        })
        break
      case 'emoney':
        store.dispatch({
          type: 'UpdateEmoneyAccounts',
          userAccountType,
          emoneys: financialAccounts,
        })
        break
    }
    return financialAccounts
  }

  fetchBankAccounts = (userAccountType?: UserAccountType) => {
    return this.fetchFinancialAccounts('bank', userAccountType)
  }

  fetchCardAccounts = (userAccountType?: UserAccountType) => {
    return this.fetchFinancialAccounts('card', userAccountType)
  }

  fetchEmoneyAccounts = (userAccountType?: UserAccountType) => {
    return this.fetchFinancialAccounts('emoney', userAccountType)
  }

  fetchBalance = async (
    userAccountType: UserAccountType = store.getState().account.accountMode,
    share: boolean,
    date?: string,
    isYearly?: boolean,
  ) => {
    const baseDate = moment(date)

    const settings = getStartDateSettings(userAccountType)

    const yearMonth = getYearMonth(
      baseDate,
      settings.start,
      settings.startDateSettingOption,
    )

    const { startDate, endDate } = isYearly
      ? getYearlyDateRange(
          yearMonth.year,
          settings.start,
          settings.startDateSettingOption,
        )
      : getDateRange(
          yearMonth.year,
          yearMonth.month,
          settings.start,
          settings.startDateSettingOption,
        )

    const from = startDate.format('YYYY-MM-DD')
    const to = endDate.format('YYYY-MM-DD')

    const response = await measure(
      'GetGroupedCategories',
      async () =>
        await Financial.getPlGroupedCategories({
          from,
          to,
          share,
          userAccountType,
          term: isYearly ? 'yearly' : undefined,
          exclusionCarryForward: false,
        }),
    )

    const childCategories: {
      [key: string]: { subCategoryId: string; amount: number }[]
    } = {}

    const responseChild = await measure(
      'GetChildeGroupedCategories',
      async () =>
        await Financial.getPlCategories({
          userAccountType,
          from,
          to,
          share,
          term: isYearly ? 'yearly' : undefined,
          exclusionCarryForward: false,
        }),
    )
    if (responseChild.ok) {
      const categories = (responseChild.json as GetPlCategoriesResponse).pl
      categories.forEach((v) => {
        const value = {
          subCategoryId: `${v.atTransactionCategoryId}`,
          amount: v.expenseAmount + v.incomeAmount,
        }
        if (childCategories[v.categoryName1]) {
          childCategories[v.categoryName1].push(value)
        } else {
          childCategories[v.categoryName1] = [value]
        }
      })
    }

    if (response.ok) {
      const plGroupedCategories = (
        response.json as GetPlGroupedCategoriesResponse
      ).pl

      const plSummary: ProfitLossSummary = {
        from,
        to,
        incomeAmount: 0,
        expenseAmount: 0,
        incomeList: [],
        expenseList: [],
      }

      plGroupedCategories.forEach((plGroupedCategory) => {
        plSummary.incomeAmount += plGroupedCategory.incomeAmount
        plSummary.expenseAmount += plGroupedCategory.expenseAmount
      })

      plGroupedCategories.forEach((plGroupedCategory) => {
        if (plGroupedCategory.incomeCount > 0) {
          const ratio =
            plSummary.incomeAmount !== 0
              ? plGroupedCategory.incomeAmount / plSummary.incomeAmount
              : 0
          const subCategories = childCategories[plGroupedCategory.categoryName1]
          plSummary.incomeList.push({
            categoryId: plGroupedCategory.atGroupedCategoryId,
            amount: plGroupedCategory.incomeAmount,
            ratio,
            subCategories,
          })
        }
        if (plGroupedCategory.expenseCount > 0) {
          const ratio =
            plSummary.expenseAmount !== 0
              ? plGroupedCategory.expenseAmount / plSummary.expenseAmount
              : 0
          const subCategories = childCategories[plGroupedCategory.categoryName1]
          plSummary.expenseList.push({
            categoryId: plGroupedCategory.atGroupedCategoryId,
            amount: plGroupedCategory.expenseAmount,
            ratio,
            subCategories,
          })
        }
      })

      plSummary.incomeList.sort((a, b) => b.amount - a.amount)
      plSummary.expenseList.sort((a, b) => a.amount - b.amount)

      const yyyymm = DateUtilsV1.monthKey(baseDate)
      store.dispatch({
        type: 'UpdateMonthsPL',
        userAccountType,
        monthsPL: { [isYearly ? yyyymm.substring(0, 4) : yyyymm]: plSummary },
      })

      return plSummary
    } else {
      throw new APIError(response)
    }
  }

  isNeedCacheUpdate = async (
    cacheType: CacheType,
    userAccountType?: UserAccountType,
  ) => {
    const latestUpdatedResponse = await Financial.checkTransactionsUpdated()
    if (!latestUpdatedResponse.ok) throw new APIError(latestUpdatedResponse)

    const { updatedAt, count } = (
      latestUpdatedResponse.json as CheckTransactionsUpdatedResponse
    ).transaction

    const financial = userAccountType
      ? store.getState().financial[userAccountType]
      : store.getState().financial

    const cachedCount = financial.updatedAt.transactionsCount
    const cachedUpdatedAt = financial.updatedAt[cacheType]

    // Log.info(`* count=${count} cachedCount=${cachedCount}`)
    // Log.info(
    //   `* updatedAt=${updatedAt} cachedUpdatedAt=${cachedUpdatedAt} diff=${moment(
    //     updatedAt
    //   ).diff(moment(cachedUpdatedAt))}`
    // )

    // countを比較
    if (cachedCount !== count) {
      store.dispatch(updateLatestTransactionsCount(userAccountType, count))
      return true
    }

    // 日付を比較
    if (
      !cachedUpdatedAt ||
      moment(updatedAt).diff(moment(cachedUpdatedAt)) >= 0
    ) {
      return true
    }

    return false
  }

  fetchCategoryTransactions = async (
    props: GetTransactionsProps,
    append = false,
  ) => {
    const { accountType: userAccountType, ...params } = props

    const response = await Financial.getTransactions({
      ...params,
      accountType: userAccountType,
      exclusionCardDupulicate: true,
    })
    if (response.ok) {
      const categoryTransactions = (() => {
        if (!append) return []

        const transactions = userAccountType
          ? store.getState().financial[userAccountType].transactions
          : store.getState().financial.transactions
        return transactions ? [...transactions] : []
      })()

      const json = response.json as GetTransactionsResponse
      const newTransactions = json.transactions
      newTransactions.forEach((t) => {
        t.accountId =
          t.atUserBankAccountId ||
          t.atUserCardAccountId ||
          t.atUserEmoneyServiceAccountId ||
          t.walletId ||
          undefined
      })

      categoryTransactions.push(...newTransactions)

      store.dispatch({
        type: 'UpdateCategoryTransactions',
        userAccountType: props.accountType,
        categoryTransactions,
      })

      return newTransactions
    } else {
      return []
    }
  }

  getTransactions = async (
    props: GetTransactionsProps,
  ): Promise<{
    transactions: Transaction[]
    nextTransactionDate: string | null
  }> => {
    const response = await Financial.getTransactions(props)
    if (response.ok) {
      const json = response.json as GetTransactionsResponse
      const transactions = json.transactions
      transactions.forEach((t) => {
        t.accountId =
          t.atUserBankAccountId ||
          t.atUserCardAccountId ||
          t.atUserEmoneyServiceAccountId ||
          t.walletId ||
          undefined
      })

      const nextTransactionDate = json.nextTransactionDate

      return { transactions, nextTransactionDate }
    } else {
      throw new APIError(response)
    }
  }

  fetchTransactions = async (props: GetTransactionsProps, append = false) => {
    const transactions = (() => {
      if (!append) return []

      const transactions = props.accountType
        ? store.getState().financial[props.accountType].transactions
        : store.getState().financial.transactions
      return transactions ? [...transactions] : []
    })()

    const { transactions: addTransactions, nextTransactionDate } =
      await this.getTransactions(props)

    transactions.push(...addTransactions)

    store.dispatch({
      type: 'UpdateTransactions',
      userAccountType: props.accountType,
      transactions,
      nextTransactionDate,
    })

    return { transactions, nextTransactionDate }
  }

  getTransactionDetail = async (props: GetTransactionDetailProps) => {
    const response = await Financial.getTransactionDetail(props)
    if (response.ok) {
      const transactionDetail =
        props.financialAccountType !== 'wallet'
          ? (response.json.transaction as TransactionDetail)
          : (response.json.transaction as TransactionDetail)
      return transactionDetail
    } else {
      throw new APIError(response)
    }
  }

  createTransaction = async (props: CreateTransactionDetailProps) => {
    const response = await Financial.createTransactionDetail(props)
    if (!response.ok) {
      throw new APIError(response)
    }
    OsidoriEvent.emit(OsidoriEventName.DidCreateTransaction, props)
  }

  updateTransaction = async ({
    action,
    ...props
  }: UpdateTransactionDetailProps) => {
    const response = await Financial.updateTransactionDetail(props)
    if (!response.ok) {
      throw new APIError(response)
    }
    OsidoriEvent.emit(OsidoriEventName.DidUpdateTransaction, {
      ...props,
      action,
    })
  }

  deleteTransaction = async (props: DeleteTransactionProps) => {
    const response = await Financial.deleteTransaction(props)
    if (!response.ok) {
      throw new APIError(response)
    }
    store.dispatch({
      type: 'UpdateTransactions',
      userAccountType: props.userAccountType,
      transactions: [],
    })
    OsidoriEvent.emit(OsidoriEventName.DidDeleteTransaction, props)
  }

  fetchGroupedTransactions = async (
    props: GetGroupedTransactionsProps,
    append?: boolean,
  ) => {
    const { userAccountType, ...params } = props

    const response = await Financial.getTransactions({
      ...params,
      accountType: userAccountType,
      exclusionCardDupulicate: true,
      exclusionCarryForward: false,
    })
    if (response.ok) {
      const groupedTransactions = (() => {
        if (!append) return []

        const transactions = userAccountType
          ? store.getState().financial[userAccountType].transactions
          : store.getState().financial.transactions
        return transactions ? [...transactions] : []
      })()

      const json = response.json as GetTransactionsResponse
      const newTransactions = json.transactions
      newTransactions.forEach((t) => {
        t.accountId =
          t.atUserBankAccountId ||
          t.atUserCardAccountId ||
          t.atUserEmoneyServiceAccountId ||
          t.walletId ||
          undefined
      })

      groupedTransactions.push(...newTransactions)

      store.dispatch({
        type: 'UpdateGroupedTransactions',
        userAccountType,
        groupedTransactions,
      })

      return newTransactions
    } else {
      return []
    }
  }

  // 口座ごとの取引明細取得
  fetchAccountTransactions = async (
    props: GetAccountTransactionsProps,
    append = false,
  ) => {
    if (props.financialType !== 'wallet') {
      const response = await Financial.getAccountTransactions(props)
      if (response.ok) {
        const json = response.json as GetAccountTransactionsResponse
        const newTransactions =
          json.transactions?.map((v) => ({
            ...v,
            accountId:
              v.atUserBankAccountId ||
              v.atUserCardAccountId ||
              v.atUserEmoneyServiceAccountId ||
              v.atUserEmoneyAccountId,
          })) || []
        if (props.financialType === 'bank') {
          const transactions: Transaction[] = append
            ? store.getState().financial.bankTransaction?.transactions || []
            : []
          transactions.push(...newTransactions)
          store.dispatch({
            type: 'UpdateBankTransaction',
            userAccountType: props.userAccountType,
            bankTransaction: {
              transactions,
              next_transaction_used_date: json.nextTransactionUsedDate,
            },
          })
        } else if (props.financialType === 'emoney') {
          const transactions: Transaction[] = append
            ? store.getState().financial.emoneyTransaction?.transactions || []
            : []
          transactions.push(...newTransactions)
          store.dispatch({
            type: 'UpdateEmoneyTransaction',
            userAccountType: props.userAccountType,
            emoneyTransaction: {
              transactions,
              next_transaction_used_date: json.nextTransactionUsedDate,
            },
          })
        } else if (props.financialType === 'card') {
          const transactions: Transaction[] = append
            ? store.getState().financial.cardTransaction?.transactions || []
            : []
          transactions.push(...newTransactions)
          store.dispatch({
            type: 'UpdateCardTransaction',
            userAccountType: props.userAccountType,
            cardTransaction: {
              transactions,
              next_transaction_used_date: json.nextTransactionUsedDate,
            },
          })
        }
        return {
          transactions: json.transactions || [],
          nextTransactionDate: json.nextTransactionUsedDate,
        }
      } else {
        throw new APIError(response)
      }
    } else {
      // wallet
      const response = await Wallet.getWalletTransactions({
        id: props.accountId,
        from: props.from,
        to: props.to,
        amount: props.amount,
      })
      if (response.ok) {
        const json = response.json as GetAccountTransactionsResponse
        const transactions: Transaction[] = append
          ? store.getState().financial.walletTransaction?.transactions || []
          : []
        transactions.push(...(json.transactions || []))
        store.dispatch({
          type: 'UpdateWalletTransaction',
          userAccountType: props.userAccountType,
          walletTransaction: {
            transactions,
            next_transaction_used_date: json.nextTransactionUsedDate,
          },
        })
        return {
          transactions: json.transactions || [],
          nextTransactionDate: json.nextTransactionUsedDate,
        }
      } else {
        throw new APIError(response)
      }
    }
  }

  updateAccount = async (props: UpdateAccountProps) => {
    const response = await Financial.updateAccount(props)
    if (!response.ok) throw new APIError(response)
  }

  deleteAccount = async (props: DeleteAccountProps) => {
    const response = await Financial.deleteAccount(props)
    if (!response.ok) throw new APIError(response)
  }

  fetchAssetProducts = async (props: GetAssetProductsProps) => {
    const response = await Stock.getAssetProducts(props)
    if (!response.ok) throw new APIError(response)
    const assetProducts = (response.json as GetAssetProductsResponse)
      .assetProducts

    store.dispatch({
      type: 'UpdateAssetProducts',
      userAccountType: props.userAccountType,
      assetProducts,
    })
  }

  fetchManuallyCreatedTransactionsCount = async (
    userAccountType: UserAccountType = store.getState().account.accountMode,
  ) => {
    const response = await Financial.getManuallyCreatedTransactionsCount({
      userAccountType,
    })
    if (!response.ok) throw new APIError(response)
    const count = (response.json as GetManuallyCreatedTransactionsCountResponse)
      .app.count
    store.dispatch({
      type: 'UpdateManuallyCreatedTransactionsCount',
      userAccountType,
      count,
    })
    return count
  }

  createWallet = async (props: CreateWalletProps) => {
    const response = await Wallet.createWallet(props)
    if (!response.ok) throw new APIError(response)
  }

  updateWallet = async (props: UpdateWalletProps) => {
    const response = await Wallet.updateWallet(props)
    if (!response.ok) throw new APIError(response)
  }

  deleteWallet = async (props: DeleteWalletProps) => {
    const response = await Wallet.deleteWallet(props)
    if (!response.ok) throw new APIError(response)
  }

  getWallets = async (
    { userAccountType, sort }: GetWalletsProps = {
      userAccountType: store.getState().account.accountMode,
      sort: true,
    },
  ) => {
    const response = await Wallet.getWallets({ userAccountType, sort })
    if (!response.ok) throw new APIError(response)
    return (response.json as GetWalletsResponse).wallets
  }

  fetchWallets = async (
    { userAccountType, sort }: GetWalletsProps = {
      userAccountType: store.getState().account.accountMode,
      sort: true,
    },
  ) => {
    const wallets = await this.getWallets({ userAccountType, sort })
    store.dispatch({
      type: 'UpdateWallets',
      userAccountType,
      wallets,
    })
    return wallets
  }

  updateStockAccount = async (props: UpdateStockAccountProps) => {
    const response = await Stock.updateStockAccount(props)
    if (!response.ok) throw new APIError(response)
  }

  deleteStockAccount = async (props: DeleteStockAccountProps) => {
    const response = await Stock.deleteStockAccount({ id: props.id })
    if (!response.ok) throw new APIError(response)
  }

  getStockAccounts = async (
    userAccountType: UserAccountType = store.getState().account.accountMode,
  ) => {
    const response = await Stock.getStockAccounts({ userAccountType })
    if (!response.ok) throw new APIError(response)
    return (response.json as GetStockAccountsResponse).stockAccounts.map(
      (v) => ({
        ...v,
        financialAccountType: 'stock',
      }),
    ) as FinancialAccount[]
  }

  fetchStockAccounts = async (
    userAccountType: UserAccountType = store.getState().account.accountMode,
  ) => {
    const investments = this.getStockAccounts(userAccountType)
    store.dispatch({
      type: 'UpdateInvestments',
      userAccountType,
      investments,
    })
    return investments
  }

  getHomeExpensesSummary = async (props: {
    date: string
    userAccountType?: UserAccountType
    isYearly?: boolean
  }) => {
    if (props.isYearly) {
      const settings = getStartDateSettings()

      const { startDate, endDate } = getYearlyDateRange(
        moment(props.date).year(),
        settings.start,
        settings.startDateSettingOption,
      )

      const response = await Financial.getHomeExpensesSummary({
        from: startDate.format('YYYY-MM-DD'),
        to: endDate.format('YYYY-MM-DD'),
        scope: 'expense',
        term: 'yearly',
      })
      if (!response.ok) throw new APIError(response)
      return response.json as GetHomeExpensesSummaryResponse
    } else {
      const { startDate, endDate } = DateUtilsV1.getRangeOfMonths(
        moment(props.date),
        false,
        props.userAccountType,
      )
      const response = await Financial.getHomeExpensesSummary({
        from: startDate.format('YYYY-MM-DD'),
        to: endDate.format('YYYY-MM-DD'),
        scope: 'expense',
      })
      if (!response.ok) throw new APIError(response)
      return response.json as GetHomeExpensesSummaryResponse
    }
  }

  fetchHomeExpensesSummary = async (props: {
    date: string
    userAccountType?: UserAccountType
    isYearly?: boolean
  }) => {
    const response = await this.getHomeExpensesSummary(props)
    // const yyyymm = DateUtils.getRangeOfMonths(
    //   moment(props.date)
    // ).startDate.format('YYYYMM')
    const yyyymm = DateUtilsV1.monthKey(moment(props.date))
    store.dispatch({
      type: 'UpdateHomeExpensesSummary',
      userAccountType: store.getState().account.accountMode,
      homeExpensesSummary: {
        [props.isYearly ? yyyymm.substring(0, 4) : yyyymm]: response,
      },
    })
    return response
  }

  fetchHomeExpensesSharingTransactions = async (
    props: GetTransactionsProps & { monthKey: string },
  ) => {
    const response = await Financial.getTransactions({
      accountType: props.accountType,
      from: props.from,
      to: props.to,
      scope: 'expense',
      distributedType: props.distributedType,
      exclusionCardDupulicate: props.exclusionCardDupulicate,
      transfer: 'pl',
      // term: props.term,
    })
    if (response.ok) {
      const json = response.json as GetTransactionsResponse
      const transactions = json.transactions
      transactions.forEach((t) => {
        t.accountId =
          t.atUserBankAccountId ||
          t.atUserCardAccountId ||
          t.atUserEmoneyServiceAccountId ||
          t.walletId ||
          undefined
      })
      const userAccountStatus = store.getState().account.accountMode

      store.dispatch(
        updateHomeExpensesTransactions(userAccountStatus, {
          [props.monthKey]: transactions,
        }),
      )
      return transactions
    } else {
      throw new APIError(response)
    }
  }

  getFinancialAccountCount = () => {
    const financial = store.getState().financial
    return (
      (financial.assets.banks?.length || 0) +
      (financial.assets.emoneys?.length || 0) +
      (financial.cards?.length || 0) +
      (financial.investments?.length || 0) +
      (financial.wallets?.length || 0)
    )
  }

  isGroupTransaction = (transafction: Transaction) => {
    const userId = store.getState().profile.userProfile?.userId
    return (
      (userId === transafction.userId && transafction.isAccountShared) ||
      userId !== transafction.userId
    )
  }

  checkTransactionsUpdated = async () => {
    const response = await Financial.checkTransactionsUpdated()
    if (!response.ok) throw new APIError(response)
    return response.json as CheckTransactionsUpdatedResponse
  }

  fetchCalendarTransactions = async (props: GetTransactionsProps) => {
    const { accountType: userAccountType } = props

    const response = await Financial.getTransactions(props)
    if (response.ok) {
      const transactions: Transaction[] = []

      const json = response.json as GetTransactionsResponse
      const newTransactions = json.transactions
      newTransactions.forEach((t) => {
        t.accountId =
          t.atUserBankAccountId ||
          t.atUserCardAccountId ||
          t.atUserEmoneyServiceAccountId ||
          t.walletId ||
          undefined
      })

      transactions.push(...newTransactions)

      const yyyymm = DateUtilsV1.monthKey(moment(props.from))

      store.dispatch(
        updateCalendarTransactions(userAccountType, { [yyyymm]: transactions }),
      )

      return transactions
    } else {
      return []
    }
  }

  getMonthlyReportBs = async (
    props: GetMonthlyReportBsProps,
  ): Promise<MonthlyReportBs[]> => {
    const response = await Financial.getMonthlyReportBs(props)
    if (!response.ok) throw new APIError(response)
    return response.json.bs
  }

  getFinanceSort = async (
    userAccountType: UserAccountType = store.getState().account.accountMode,
  ) => {
    const response = await Financial.getFinanceSort(userAccountType)
    if (!response.ok) throw new APIError(response)
    return (response.json as GetFinanceSortResponse).types
  }

  updateFinanceSort = async (props: UpdateFinanceSortProps) => {
    const response = await Financial.updateFinanceSort(props)
    if (!response.ok) throw new APIError(response)
  }

  updateFinanceAccountSort = async (props: UpdateFinanceAccountSortProps) => {
    const response = await Financial.updateFinanceAccountSort(props)
    if (!response.ok) throw new APIError(response)
  }
}

export default new FinancialManager()
