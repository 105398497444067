import TutorialModal from '@Screen/Main/TutorialModal'
import TutorialTipsBalloon from '@Screen/Main/TutorialTips/TutorialTipsBalloon'
import AdView from '@components/AdView'
import AndroidSupportModal from '@components/AndroidSupportModal'
import AppButton from '@components/AppButton'
import Avatar from '@components/Avatar'
import EyeImage from '@components/EyeImage'
import HomeBackgroundImage from '@components/HomeBackgroundImage'
import PairingCompletedDialog from '@components/PairingCompletedDialog'
import TimelineBadgeIcon from '@components/TimelineBadgeIcon'
import AccountManager from '@lib/AccountManager'
import { accountModeSelector } from '@lib/AccountManager/actions'
import AccountTrackerManager from '@lib/AccountTrackerManager'
import { fetchAnnounces } from '@lib/Announce/manager'
import { BudgetManager } from '@lib/Budget'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import GlobalFont from '@lib/GlobalFont'
import GuidanceService from '@lib/GuidanceService'
import Log from '@lib/Log'
import NavigationService, {
  navigateToNavigatableScreen,
} from '@lib/NavigationService'
import Osidori from '@lib/Osidori'
import OsidoriEvent from '@lib/OsidoriEvent'
import ProfileManager from '@lib/ProfileManager'
import SessionManager from '@lib/SessionManager'
import { SpotlightTourSpot, useSpotlightTour } from '@lib/SpotlightTour'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/core'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  FlatList,
  Image,
  Linking,
  Platform,
  Pressable,
  RefreshControl,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import GuidanceFirstInvitePartnerDialog from '../Guidance/GuidanceFirstInvitePartnerDialog'
import AnnounceLevel1 from './AnnounceLevel1'
import AnnounceLevel2 from './AnnounceLevel2'
import HomeExpenses from './HomeExpenses'
import HomeGoalList from './HomeGoalList'

export type HomeTopScreenProps = {
  //
}

const groupTransactionSharedSelector = ({ profile }: RootState) =>
  profile.userStatus?.groupTransactionShared

const isFamilyShareModeSelector = ({ account }: RootState) =>
  account.isFamilyShareMode

const manuallyCreatedTransactionsCountSelector = ({ financial }: RootState) =>
  financial.manuallyCreatedTransactionsCount

const HomeTopScreen: React.FC<
  StackScreenProps<RootStackParamList, 'HomeTop'>
> = () => {
  const dispatch = useDispatch()
  const isFocused = useIsFocused()

  const userAccountType = useSelector(accountModeSelector)
  const userProfile = useSelector(
    ({ profile }: RootState) => profile.userProfile,
  )
  const groupTransactionShared = useSelector(groupTransactionSharedSelector)

  const isFamilyShareMode = useSelector(isFamilyShareModeSelector)

  const totalAssetAmount = useSelector(
    ({ financial }: RootState) => financial[userAccountType].totalAssetAmount,
  )
  const manuallyCreatedTransactionsCount = useSelector(
    manuallyCreatedTransactionsCountSelector,
  )

  const [refreshing, setRefreshing] = useState(false)

  const [dayOver, setDayOver] = useState<number>()

  const [isVisibleTutorialModal, setIsVisibleTutorialModal] = useState(false)
  const [visibleAndroidSupportModal, setVisibleAndroidSupportModal] =
    useState(false)

  // const didMount1 = useRef(false)
  const didMount2 = useRef(false)
  const didMount3 = useRef(false)
  const flatlistRef = useRef<FlatList>(null)

  const sendTrackEvent = useCallback(() => {
    if (userAccountType === 'user') {
      TrackingUtils.repro.track('【Screen】Personal_home_0830', 'Screen')
      TrackingUtils.adjust.trackEvent({ ios: 'lemi2n', android: 'kop1ab' })
    } else {
      TrackingUtils.repro.track('【Screen】Family_home_0830', 'Screen')
      TrackingUtils.adjust.trackEvent({ ios: 'gprca0', android: 'y3x3rt' })
    }
  }, [userAccountType])

  useEffect(() => {
    const unsubscribe = OsidoriEvent.addListener(
      'RequestOpenAndroidSupportModal',
      () => {
        setVisibleAndroidSupportModal(true)
      },
    )
    return () => {
      OsidoriEvent.removeListener(unsubscribe)
    }
  }, [])

  const getBs = useCallback(async () => {
    try {
      // 前日比
      const today = moment()
      const todayBs = await FinancialManager.getMonthlyReportBs({
        userAccountType,
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      })

      const yesterday = moment(today).subtract(1, 'day')
      const yesterdayBs = await FinancialManager.getMonthlyReportBs({
        userAccountType,
        year: yesterday.year(),
        month: yesterday.month() + 1,
        day: yesterday.date(),
      })

      if (todayBs[0] && yesterdayBs[0]) {
        setDayOver(todayBs[0].totalBalance - yesterdayBs[0].totalBalance)
      }
    } catch (error) {
      Log.warn(error)
    }
  }, [userAccountType])

  useEffect(() => {
    getBs()
  }, [getBs, totalAssetAmount])

  useEffect(() => {
    if (isFocused) {
      fetchAnnounces()
    }
  }, [isFocused])

  useEffect(() => {
    if (isFocused) {
      sendTrackEvent()
    }
  }, [dispatch, isFocused, sendTrackEvent])

  useEffect(() => {
    if (isFocused) {
      SessionManager.setLaunchHomeCount(SessionManager.getLaunchHomeCount() + 1)
    }
  }, [isFocused])

  const isFetchingHomeData = useRef(false)

  useEffect(() => {
    if (isFocused) {
      if (isFetchingHomeData.current) return
      isFetchingHomeData.current = true

      Osidori.fetchHomeData()
        .then(() => {
          Osidori.checkPairing()
            .then((isSuccessPairing) => {
              if (!isSuccessPairing) {
                GuidanceService.start()
              }
            })
            .finally(() => {
              isFetchingHomeData.current = false
            })
        })
        .catch(() => {
          isFetchingHomeData.current = false
        })
    }
  }, [dispatch, isFocused])

  useEffect(() => {
    if (userProfile) {
      TrackingUtils.repro.setUserId(userProfile.userId)
    }
  }, [userProfile])

  useEffect(() => {
    if (didMount2.current) {
      FinancialManager.fetchBalance(userAccountType, isFamilyShareMode)
    } else {
      didMount2.current = true
    }
  }, [isFamilyShareMode])

  useEffect(() => {
    if (didMount3.current) {
      fetcheData()
    } else {
      didMount3.current = true
    }
  }, [userAccountType])

  // TODO
  useEffect(() => {
    try {
      const updated = FinancialManager.checkTransactionsUpdated()
      console.log(updated)
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [])

  const fetcheData = useCallback(async () => {
    try {
      // navigation.isFocused() && RNProgressHud.show()
      SessionManager.setLaunchHomeCount(SessionManager.getLaunchHomeCount() + 1)

      await FinancialManager.fetchAssets(userAccountType)
      await FinancialManager.fetchBalance(userAccountType, isFamilyShareMode)
      await BudgetManager.fetchBudget({ userAccountType })
      GuidanceService.start()
      sendTrackEvent()
    } finally {
      // RNProgressHud.dismiss()
    }
  }, [userAccountType, isFamilyShareMode, sendTrackEvent])

  const renderTotalAssetsNoData = useMemo(
    () => (
      <View style={styles.totalAssets}>
        <View style={{ alignSelf: 'flex-start', marginBottom: 14 }}>
          <SpotlightTourSpot
            spotId="totalAssets"
            scale={1.8}
            translateY={30}
            hideTips>
            <Text style={styles.titleNodata}>資産総額</Text>
          </SpotlightTourSpot>
        </View>
        <View style={styles.wrapContent}>
          <Text style={styles.contentNoData}>
            現金や銀行口座のお金を登録すると、現在の資産総額が表示されます。
          </Text>
          <AppButton
            type="secondary-min"
            title="登録する"
            onPress={
              () =>
                NavigationService.navigate('FinancialAccountsTab', {
                  screen: 'FinancialAccountCooperation',
                })
              // NavigationService.navigate('FinancialAccountCooperation')
            }
          />
        </View>
      </View>
    ),
    [],
  )

  const [isVisibleTotalAssets, setIsVisibleTotalAssets] = useState(
    SessionManager.getIsVisibleTotalAssets(userAccountType),
  )

  useEffect(() => {
    setIsVisibleTotalAssets(
      SessionManager.getIsVisibleTotalAssets(userAccountType),
    )
  }, [userAccountType])

  const onPressEyeButton = useCallback(() => {
    setIsVisibleTotalAssets(!isVisibleTotalAssets)

    SessionManager.setIsVisibleTotalAssets(
      userAccountType,
      !isVisibleTotalAssets,
    )
  }, [isVisibleTotalAssets, userAccountType])

  const renderTotalAssets = useMemo(
    () => (
      <TouchableOpacity
        style={styles.totalAssets}
        onPress={() =>
          navigateToNavigatableScreen([
            { name: 'TotalAssets' },
            { name: 'FinancialAccountsTab' },
          ])
        }>
        <Pressable style={styles.warpTitle} onPress={onPressEyeButton}>
          <SpotlightTourSpot
            spotId="totalAssets"
            scale={1.8}
            translateY={30}
            hideTips>
            <Text style={styles.titleTotalAssets}>資産総額</Text>
          </SpotlightTourSpot>
          <EyeImage
            on={isVisibleTotalAssets}
            style={{ marginLeft: 5, tintColor: Color.DefaultText }}
          />
        </Pressable>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 'normal',
              marginTop: 2,
              color: Color.TextBlack,
            }}>
            合計
          </Text>
          <Text style={styles.money}>
            {isVisibleTotalAssets
              ? totalAssetAmount?.jpCurrency() || '-'
              : '¥---,---'}
          </Text>
        </View>
        <View
          style={{
            marginTop: 5,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 'normal',
              color: Color.TextBlack,
            }}>
            前日比
          </Text>
          <Text
            style={{
              marginLeft: 10,
              fontSize: 16,
              fontWeight: 'bold',
              color: Color.number(dayOver),
            }}>
            {dayOver !== undefined
              ? (dayOver >= 0 ? '+' : '') + dayOver.jpCurrency()
              : '-'}
          </Text>
        </View>
        {/* <RightArrowButton disabled>詳細を見る</RightArrowButton> */}
      </TouchableOpacity>
    ),
    [dayOver, isVisibleTotalAssets, onPressEyeButton, totalAssetAmount],
  )

  const isCouple = userAccountType === 'family'

  const isDisplayFinancialAccounts =
    FinancialManager.getFinancialAccountCount() > 0 ||
    manuallyCreatedTransactionsCount > 0 ||
    (userAccountType === 'family' && groupTransactionShared)

  const isTutorialDisplayCompleted = SessionManager.isTutorialDisplayCompleted()

  useEffect(() => {
    if (
      !isDisplayFinancialAccounts &&
      !isTutorialDisplayCompleted &&
      !isVisibleTutorialModal
    ) {
      setIsVisibleTutorialModal(true)
    } else {
      if (!isTutorialDisplayCompleted) {
        SessionManager.setIsTutorialDisplayCompleted(true)
      }
      if (!SessionManager.isTutorialTransactionDisplayCompleted()) {
        SessionManager.setIsTutorialTransactionDisplayCompleted(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const items = useMemo(
    () => [
      isDisplayFinancialAccounts ? renderTotalAssets : renderTotalAssetsNoData,
      <HomeExpenses />,
      <AnnounceLevel1 />,
      <HomeGoalList />,
      <AnnounceLevel2 />,
      userProfile?.rank === 'free' ? (
        <View
          style={{
            paddingHorizontal: wp(5),
            borderRadius: 10,
            paddingBottom: wp(5),
          }}>
          <View
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              overflow: 'hidden',
            }}>
            {/* AndroidではAdViewにbackgroundで色を指定するとNegative MarginのTouchが効かないバグがある */}
            <AdView type="Home300x250" width={wp(90)} />
          </View>
        </View>
      ) : null,
    ],
    [
      isDisplayFinancialAccounts,
      renderTotalAssets,
      renderTotalAssetsNoData,
      userProfile?.rank,
    ],
  )

  const renderItem = useCallback(({ item }: any) => item, [])
  const keyExtractor = useCallback(
    (_: never, index: number) => `home-${index}`,
    [],
  )

  const onRefresh = useCallback(async () => {
    setRefreshing(true)
    await AccountTrackerManager.syncAccount()
    await Osidori.fetchHomeData()
    setRefreshing(false)
  }, [])

  const refreshControl = useMemo(
    () => <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />,
    [onRefresh, refreshing],
  )

  const {
    startSpotlightTour,
    finishSpotlightTour,
    nextStep,
    setOverlayColor,
    setDisabled,
  } = useSpotlightTour()

  useEffect(() => {
    const listener = OsidoriEvent.addListener('RequestOpenTutorial', () => {
      flatlistRef.current?.scrollToIndex({ index: 0, animated: false })
      startTutorial()
    })
    return () => {
      OsidoriEvent.removeListener(listener)
    }
  })

  const startTutorial = useCallback(() => {
    setIsVisibleTutorialModal(false)

    NavigationService.navigate('HomeTop')

    startSpotlightTour([
      {
        spotIds: ['totalAssets', 'tab2'],
        before: () => {
          if (SessionManager.isTutorialDisplayCompleted()) {
            // 2回目以降の表示は家族に切り替える
            AccountManager.updateAccountMode('family')
            // store.dispatch(updateAccountMode('family'))
          }
          setDisabled(true)
        },
        after: () => {
          setTimeout(() => setDisabled(false), 800 + 500)
        },
        onPressSuperView: () => {
          setDisabled(true)
          nextStep()
        },
      },
      {
        spotIds: ['tab3'],
        after: () => {
          setTimeout(() => setDisabled(false), 800 + 500)
        },
        onPressSuperView: () => {
          setDisabled(true)
          nextStep()
        },
      },
      {
        spotIds: ['thisMonthHomeExpenses', 'tab4'],
        after: () => {
          setTimeout(() => setDisabled(false), 800 + 500)
        },
        onPressSuperView: () => {
          setDisabled(true)
          nextStep()
        },
      },
      {
        spotIds: ['tab5'],
        after: () => {
          setTimeout(() => setDisabled(false), 800 + 500)
        },
        onPressSuperView: () => {
          setDisabled(true)
          nextStep()
        },
      },
      {
        spotIds: ['menuButton'],
        after: () => {
          setDisabled(false)
          OsidoriEvent.emit('RequestSideMenuScrollToTop')
        },
      },
      {
        spotIds: [],
        before: () => {
          setDisabled(true)
          setOverlayColor('rgba(0, 0, 0, 0)')
          NavigationService.openDrawer()
          setTimeout(() => {
            setOverlayColor('rgba(0, 0, 0, 0.3)')
            nextStep()
          }, 800)
        },
      },
      {
        spotIds: ['avatar'],
        after: () => {
          setTimeout(() => setDisabled(false), 500)
        },
        onPressSuperView: () => {
          setDisabled(true)
          nextStep()
        },
      },
      {
        spotIds: ['avatarBottom'],
        before: () => {
          setOverlayColor('rgba(0, 0, 0, 0)')
        },
        after: () => {
          setTimeout(() => setDisabled(false), 800 + 500)
        },
        onPressSuperView: () => {
          setDisabled(true)
          nextStep()
        },
      },
      {
        spotIds: ['tutorial'],
        before: () => {
          setOverlayColor('rgba(0, 0, 0, 0.3)')
        },
        after: () => {
          setDisabled(false)
          SessionManager.setIsTutorialDisplayCompleted(true)
        },
      },
      {
        spotIds: [],
        before: () => {
          setOverlayColor('rgba(0, 0, 0, 0.0)')
        },
        after: () => {
          NavigationService.closeDrawer()
          OsidoriEvent.emit('RequestSideMenuScrollToTop')
          setTimeout(() => {
            finishSpotlightTour()
            GuidanceService.start()
          }, 400)
        },
      },
    ])
  }, [
    finishSpotlightTour,
    nextStep,
    setDisabled,
    setOverlayColor,
    startSpotlightTour,
  ])

  const onPressStartTutorial = startTutorial

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: `Home-${userAccountType === 'family' ? 'Family' : 'User'}`,
        title: `ホーム画面-${userAccountType === 'family' ? '家族' : '個人'}`,
      })
    }
  }, [isFocused, userAccountType])

  return (
    <View style={styles.container}>
      <StatusBar
        translucent={true}
        backgroundColor="transparent"
        hidden={false}
        barStyle="light-content"
      />
      <HomeBackgroundImage
        userAccountType={userAccountType === 'user' ? 'user' : 'family'}
        style={{
          width: '100%',
          resizeMode: 'cover',
        }}
      />
      <View style={{ zIndex: 200 }} pointerEvents="none">
        <View style={styles.wrapAvatar}>
          <Avatar type={userAccountType === 'user' ? 'user' : 'couple'} />
        </View>
      </View>
      <SafeAreaView
        edges={['top']}
        style={{ position: 'absolute', left: 0, right: 0 }}>
        <View style={styles.titleView}>
          <Text style={styles.titleText}>
            {isCouple ? '家族ホーム' : '個人ホーム'}
          </Text>
        </View>
      </SafeAreaView>
      <SafeAreaView edges={['top']} style={{ position: 'absolute' }}>
        <TouchableOpacity
          style={styles.menuButton}
          onPress={() => {
            NavigationService.openDrawer()
            ProfileManager.fetchPartnerProflie()

            TrackingUtils.ga.tap({
              action: 'Tap-SideMenu',
              label: 'サイドメニューをタップ',
            })
          }}>
          <SpotlightTourSpot
            spotId="menuButton"
            scale={3}
            translateY={0}
            tipsComponent={(props) => <TutorialTipsBalloon {...props} />}
            onPress={nextStep}>
            <Image
              style={styles.menuIcon}
              source={require('@images/common-header/common-header-menu-button.png')}
              resizeMode="contain"
            />
          </SpotlightTourSpot>
        </TouchableOpacity>
      </SafeAreaView>
      <SafeAreaView edges={['top']} style={{ position: 'absolute', right: 0 }}>
        <TouchableOpacity
          style={styles.timelineButton}
          onPress={() => {
            NavigationService.navigate('TimelineTop')
          }}>
          <TimelineBadgeIcon />
        </TouchableOpacity>
      </SafeAreaView>
      {/* Pixel 5では JSX.Elementを多く含むScrollViewだと落ちるようのでFlatList必須 */}
      <FlatList
        ref={flatlistRef}
        data={items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        style={styles.body}
        refreshControl={refreshControl}
      />
      {Platform.OS !== 'web' && <GuidanceFirstInvitePartnerDialog />}
      <PairingCompletedDialog />
      <TutorialModal
        visible={isVisibleTutorialModal}
        onPressStart={onPressStartTutorial}
      />
      {Platform.OS === 'android' && (
        <AndroidSupportModal
          visible={visibleAndroidSupportModal}
          onPressRequest={() => {
            setVisibleAndroidSupportModal(false)
            NavigationService.navigate('WebView', {
              title: 'お問い合わせ',
              uri: 'https://www.osidori.co/support/#contact',
            })
          }}
          onPressSupport={() => {
            setVisibleAndroidSupportModal(false)
            Linking.openURL('market://details?id=inc.osidori')
          }}
          onPressClose={() => setVisibleAndroidSupportModal(false)}
        />
      )}
    </View>
  )
}

export default HomeTopScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  menuButton: {
    // position: 'absolute',
    // left: 0,
    // top: 0 + statusBarHeight,
    padding: 14,
  },
  timelineButton: {
    // position: 'absolute',
    // right: 0,
    // top: 0 + statusBarHeight,
    padding: 14,
  },
  menuIcon: {
    width: 20,
    height: 20,
  },
  titleView: {
    width: '100%',
    // position: 'absolute',
    marginTop: 17,
  },
  titleText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
  },
  body: {
    flex: 1,
    backgroundColor: '#F7F7F7',
  },
  wrapAvatar: {
    position: 'absolute',
    top: -35,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
  },
  totalAssets: {
    width: '100%',
    backgroundColor: Color.White,
    justifyContent: 'flex-end',
    paddingTop: 20,
    paddingBottom: 17,
    paddingHorizontal: 17,
    borderBottomColor: 'rgb(240, 240, 240)',
    borderBottomWidth: 1,
  },
  wrapContent: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentNoData: {
    flex: 1,
    color: Color.Gray,
    fontSize: 13,
    fontWeight: 'normal',
    marginRight: 13,
  },
  titleNodata: {
    color: Color.TextBlack,
    fontSize: 15,
    fontWeight: 'bold',
  },
  warpTitle: {
    marginTop: -20,
    paddingTop: 20,
    marginLeft: -17,
    paddingLeft: 17,
    paddingRight: 17,
    marginBottom: 5,
    paddingBottom: 5,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  titleTotalAssets: {
    color: Color.TextBlack,
    fontSize: 15,
    fontWeight: 'bold',
  },
  money: {
    color: '#3A3A3A',
    fontSize: 34,
    fontFamily: GlobalFont.Family.Number,
    fontWeight: GlobalFont.Weight.Number,
    textAlign: 'right',
    marginLeft: 10,
  },
})
