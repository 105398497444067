import CommonHeader from '@components/CommonHeader'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import SessionManager from '@lib/SessionManager'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/core'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, ScrollView, ViewStyle } from 'react-native'
import ReactNativeBiometrics from 'react-native-biometrics'
import KeyboardManager from 'react-native-keyboard-manager'
import styled from 'styled-components/native'
import MenuListItem from './MenuListItem'

const StyledRootView = styled.View`
  flex: 1;
  background-color: #f7f7f7;
`

const BorderTopStyle: ViewStyle = {
  borderTopWidth: 1,
  borderTopColor: '#F7F7F7',
}

const ActionSheetLabels = [
  '即時',
  '1分後',
  '5分後',
  '30分後',
  '1時間後',
  '3時間後',
  'キャンセル',
]

const ActionSheetValues = [0, 1 * 60, 5 * 60, 30 * 60, 60 * 60, 180 * 60]

export type SettingSecurityScreenParams = {
  goBack?: () => void
}

const SettingSecurityScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingSecurity'>
> = ({ route }) => {
  const isFocused = useIsFocused()

  const biometrics = useRef<ReactNativeBiometrics>(
    new ReactNativeBiometrics(),
  ).current

  const [isSetPasscode, setIsSetPasscode] = useState(
    !!SessionManager.getPasscode(),
  )
  const [isBiometrics, setIsBiometrics] = useState(
    SessionManager.isBiometrics(),
  )
  const [requestInterval] = useState(
    SessionManager.getAuthenticationRequestInterval(),
  )

  const [biometricsTitle, setBiometricsTitle] = useState('')
  const [isSupportedBiometrics, setIsSupportedBiometrics] = useState(false)

  const requestIntervalTitle = useMemo(() => {
    const index = ActionSheetValues.findIndex((v) => v === requestInterval) || 0
    return ActionSheetLabels[index] || ''
  }, [requestInterval])

  const goBack = () => {
    if (route.params?.goBack) {
      route.params.goBack()
    } else {
      NavigationService.goBack()
    }
  }

  const onChangePasscodeSwitch = useCallback((value: boolean) => {
    if (value) {
      NavigationService.navigate('SettingPasscode')
    } else {
      SessionManager.removePasscode()
      SessionManager.setBiometrics(false)
      setIsSetPasscode(false)
      setIsBiometrics(false)
    }
  }, [])

  const onChangeBiometricsSwitch = useCallback((value: boolean) => {
    SessionManager.setBiometrics(value)
    setIsBiometrics(value)
  }, [])

  const onPressChangePassword = useCallback(() => {
    NavigationService.navigate('SettingPasscode', { settingMode: 'change' })
  }, [])

  const onPressAuthenticationRequestInterval = useCallback(() => {
    // ActionSheet.showActionSheetWithOptions(
    //   {
    //     options: ActionSheetLabels,
    //     cancelButtonIndex: ActionSheetCancelIndex,
    //   },
    //   (index: number) => {
    //     const requestInterval = ActionSheetValues[index] || 0
    //     SessionManager.setAuthenticationRequestInterval(requestInterval)
    //     setRequestInterval(requestInterval)
    //   },
    // )
  }, [])

  useEffect(() => {
    if (isFocused) {
      setIsSetPasscode(!!SessionManager.getPasscode())
    }
  }, [isFocused])

  useEffect(() => {
    if (Platform.OS === 'ios') {
      KeyboardManager.setEnableAutoToolbar(false)

      return () => {
        KeyboardManager.setEnableAutoToolbar(true)
      }
    }
  }, [])

  useEffect(() => {
    const checkBiometoryType = async () => {
      try {
        const { available, biometryType } = await biometrics.isSensorAvailable()

        if (available) {
          if (Platform.OS === 'ios') {
            if (biometryType === 'FaceID') {
              setBiometricsTitle('Face ID')
            } else if (biometryType === 'TouchID') {
              setBiometricsTitle('Touch ID')
            } else {
              setBiometricsTitle('生体認証')
            }
          } else {
            setBiometricsTitle('生体認証')
          }
          setIsSupportedBiometrics(true)
        } else {
          setBiometricsTitle('生体認証')
          setIsSupportedBiometrics(false)
        }
      } catch (error) {
        Log.warn(error)
        setBiometricsTitle('生体認証')
        setIsSupportedBiometrics(false)
      }
    }

    checkBiometoryType()
  }, [biometrics])

  return (
    <StyledRootView>
      <CommonHeader title="セキュリティ" onPressLeftButton={goBack} />
      <ScrollView contentContainerStyle={{ marginTop: 0 }}>
        <MenuListItem
          title="パスコードロック"
          subTitle="アプリ起動時にパスコードによる認証を行います"
          isDisplaySwitch
          onSwitchValueChange={onChangePasscodeSwitch}
          switchValue={isSetPasscode}
          style={BorderTopStyle}
        />
        <MenuListItem
          title={biometricsTitle}
          subTitle={
            biometricsTitle
              ? `パスコードの代わりに${biometricsTitle}を利用します`
              : '-'
          }
          disabled={!isSetPasscode || !isSupportedBiometrics}
          isDisplaySwitch
          onSwitchValueChange={onChangeBiometricsSwitch}
          switchValue={isBiometrics}
          style={BorderTopStyle}
        />
        <MenuListItem
          title="パスコード変更"
          subTitle="パスコード番号を変更したい場合はこちら"
          isDisplayArrow
          onPress={onPressChangePassword}
          disabled={!isSetPasscode}
          style={BorderTopStyle}
        />
        <MenuListItem
          title="パスコード要求時間"
          subTitle="パスコードの要求タイミングを変更できます"
          value={requestIntervalTitle}
          onPress={onPressAuthenticationRequestInterval}
          style={BorderTopStyle}
        />
      </ScrollView>
    </StyledRootView>
  )
}

export default SettingSecurityScreen
