import CommonHeader from '@components/CommonHeader'
import CommonListItem from '@components/CommonListItem'
import ItemSeparator from '@components/ItemSeparator'
import { PaymentUserType } from '@interfaces/Financial'
import { WalletEntity } from '@lib/api/Wallet'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  FlatList,
  ListRenderItem,
  Text,
  TouchableOpacity,
} from 'react-native'
import { AcitivityViewContainer, RootContainer } from './styles'

const PaymentSourceScreen: React.FC<
  StackScreenProps<RootStackParamList, 'PaymentSource'>
> = ({ navigation, route }) => {
  const [wallets, setWallets] = useState<WalletEntity[]>([])

  const getWallets = useCallback(async () => {
    const { paymentUserType } = route.params

    const wallets = await (async () => {
      let wallets: WalletEntity[]
      if (paymentUserType === PaymentUserType.Family) {
        wallets = await FinancialManager.getWallets({
          userAccountType: 'family',
          sort: false,
        })
      } else if (paymentUserType === PaymentUserType.User) {
        wallets = await FinancialManager.getWallets({
          userAccountType: 'user',
          sort: false,
        })
      } else {
        wallets = []
      }
      wallets.push({
        name: '現金',
        id: 0,
        amount: 0,
        finance: 'wallet',
        type: 'wallet',
      })
      return wallets
    })()

    setWallets(wallets)
  }, [route.params])

  useEffect(() => {
    navigation.addListener('focus', () => {
      getWallets()
    })
  }, [])

  const onPressItem = useCallback(
    (wallet: WalletEntity) => {
      route.params.onSelectWallet(wallet)
      NavigationService.goBack()
    },
    [route.params],
  )

  const renderItem: ListRenderItem<WalletEntity> = useCallback(
    ({ item }) => {
      return (
        <CommonListItem title={item.name} onPress={() => onPressItem(item)} />
      )
    },
    [onPressItem],
  )

  const renderFooter = useCallback(() => {
    return (
      <>
        {ItemSeparator}
        <TouchableOpacity
          onPress={() => {
            NavigationService.navigate('CreateWallet', {
              userAccountType:
                route.params.paymentUserType === PaymentUserType.Family
                  ? 'family'
                  : 'user',
              goBack: NavigationService.goBack,
            })
          }}
          style={{ marginTop: 10, marginBottom: 24, alignItems: 'center' }}>
          <Text
            style={{
              color: 'rgb(88, 199, 54)',
              fontWeight: 'bold',
              fontSize: 16,
            }}>
            +新しく作成する
          </Text>
        </TouchableOpacity>
      </>
    )
  }, [route.params.paymentUserType])

  return (
    <>
      <CommonHeader title="支払い元を選択" />
      {wallets.length > 0 ? (
        <RootContainer>
          <FlatList
            data={wallets}
            renderItem={renderItem}
            keyExtractor={(item, index) => item.id + '-' + index}
            ItemSeparatorComponent={() => ItemSeparator}
            ListFooterComponent={renderFooter}
          />
        </RootContainer>
      ) : (
        <AcitivityViewContainer>
          <ActivityIndicator />
        </AcitivityViewContainer>
      )}
    </>
  )
}

export default PaymentSourceScreen
