import Color from '@lib/Color'
import { Animated, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

export const CalculatorButtonHeight = wp(18)
export const CalculatorHeight = CalculatorButtonHeight * 5 + 20 + 3

export const RootContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
`
// top: ${CommonHeaderHeight}px;

export const Transparentview = styled.View`
  flex: 1;
  background-color: #00000000;
`

export const CalculatorView = styled.View`
  width: 100%;
  padding-top: 3px;
  height: ${CalculatorHeight}px;
  background-color: #3a3a3a;
`

export const Button = styled(TouchableOpacity)`
  flex: 1;
  height: ${CalculatorButtonHeight}px;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
`

export const EqualOkButton = styled(TouchableOpacity)`
  flex: 2;
  height: ${CalculatorButtonHeight}px;
  padding-vertical: ${wp(3)}px;
  align-items: center;
  justify-content: center;
`

export const EqualOkWrapView = styled.View`
  position: absolute;
  top: ${wp(2)}px;
  left: ${wp(2)}px;
  right: ${wp(2)}px;
  bottom: ${wp(2)}px;
  background-color: ${Color.Primary};
  border-radius: ${wp(2)}px;
  align-items: center;
  justify-content: center;
  /* width: 90%; */
`

export const EqualText = styled.Text`
  font-size: ${wp(6)}px;
  font-weight: normal;
  color: white;
`

export const OKText = styled.Text`
  font-size: ${wp(6)}px;
  font-weight: normal;
  color: white;
`

export const NumberText = styled.Text`
  font-size: ${wp(7)}px;
  font-weight: normal;
  color: white;
`

export const SymbolText = styled.Text`
  font-size: ${wp(8)}px;
  font-weight: normal;
  color: ${Color.Gray};
`

export const ButtonLine = styled.View`
  flex-direction: row;
  justify-content: space-between;
`
