import Color from '@lib/Color'
import { MaterialTopTabBarOptions } from '@react-navigation/material-top-tabs'
import { Platform } from 'react-native'

export const TabBarOptions: MaterialTopTabBarOptions = {
  activeTintColor: Color.Primary,
  inactiveTintColor: Color.Gray,
  indicatorStyle: {
    backgroundColor: Color.Primary,
    height: 1,
  },
  labelStyle: {
    fontWeight: '600',
    fontSize: 15,
  },
  style: {
    elevation: 0,
    backgroundColor: 'white',
    borderBottomWidth: Platform.OS === 'android' ? 1 : 0,
    borderBottomColor: Color.LightGray,
  },
}
