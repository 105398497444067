import { UserAccountType } from '@interfaces/Account'
import {
  FinancialAccount,
  ProfitLossSummary,
  Transaction,
} from '@interfaces/Financial'
import { AssetProduct } from '@interfaces/Stock'
import {
  GetAccountTransactionsResponse,
  GetHomeExpensesSummaryResponse,
} from '@lib/api/Financial'
import { WalletEntity } from '@lib/api/Wallet'

// State
export interface FinancialDetailState {
  // キャッシュチェック用 両方確認する
  // 自分とパートナーの全明細と証券の中で最新の更新日時
  updatedAt: {
    transactionsCount?: number // 自分とパートナーの全明細の件数（削除された場合、日付は更新されていないが表示内容は更新する必要があるため）
    bankTransaction?: string
    emoneyTransaction?: string
    cardTransaction?: string
    transactions?: string
    groupedTransactions?: string
    walletTransaction?: string
    assetProducts?: string
  }
  // データ
  banks?: FinancialAccount[]
  emoneys?: FinancialAccount[]
  cards?: FinancialAccount[]
  wallets?: WalletEntity[]
  investments?: FinancialAccount[]
  totalAssetAmount?: number
  thisMonthsPayment?: number
  monthsPL: { [yyyymm: string]: ProfitLossSummary }
  transactions?: Transaction[]
  nextTransactionDate?: string | null
  categoryTransactions?: Transaction[]
  calendarTransactions: {
    [yyyymm: string]: Transaction[]
  }
  bankTransaction?: GetAccountTransactionsResponse
  emoneyTransaction?: GetAccountTransactionsResponse
  cardTransaction?: GetAccountTransactionsResponse
  groupedTransactions?: Transaction[]
  manuallyCreatedTransactionsCount: number
  walletTransaction?: GetAccountTransactionsResponse
  assetProducts?: AssetProduct[]
  homeExpensesSummary: {
    [yyyymm: string]: GetHomeExpensesSummaryResponse
  }
  homeExpensesSharingTransactions: {
    [yyyymm: string]: Transaction[]
  }
  homeExpensesSelectedDate?: string
  homeExpensesSelectedYearMonth?: string
}

export interface FinancialState {
  user: FinancialDetailState
  family: FinancialDetailState
  transactions?: Transaction[] // 個人, 家族合わせた取引想履歴
  nextTransactionDate?: string | null // 個人, 家族合わせた取引想履歴
  calendarTransactions: {
    [yyyymm: string]: Transaction[] // 個人, 家族合わせた取引履歴
  }
  // キャッシュチェック用 両方確認する
  // 自分とパートナーの全明細と証券の中で最新の更新日時
  updatedAt: {
    transactionsCount?: number // 自分とパートナーの全明細の件数（削除された場合、日付は更新されていないが表示内容は更新する必要があるため）
    transactions?: string
    // 以下未使用
    bankTransaction?: string
    emoneyTransaction?: string
    cardTransaction?: string
    groupedTransactions?: string
    walletTransaction?: string
    assetProducts?: string
  }
  // TODO 以下は将来消す
  assets: {
    banks?: FinancialAccount[]
    emoneys?: FinancialAccount[]
  }
  // totalAssetAmount?: number
  // thisMonthsPayment?: number
  cards?: FinancialAccount[]
  bankTransaction?: GetAccountTransactionsResponse
  emoneyTransaction?: GetAccountTransactionsResponse
  cardTransaction?: GetAccountTransactionsResponse
  groupedTransactions?: Transaction[]
  manuallyCreatedTransactionsCount: number
  wallets?: WalletEntity[]
  walletTransaction?: GetAccountTransactionsResponse
  investments?: FinancialAccount[]
  assetProducts?: AssetProduct[]
  homeExpensesSummary: {
    [yyyymm: string]: GetHomeExpensesSummaryResponse
  }
  homeExpensesSharingTransactions: {
    [yyyymm: string]: Transaction[]
  }
  homeExpensesSelectedDate?: string
  homeExpensesIsYearly: boolean
}

// Actions
interface UpdateFinancialAssetsAction {
  type: 'UpdateFinancialAssets'
  userAccountType?: UserAccountType
  assets: {
    banks?: FinancialAccount[]
    emoneys?: FinancialAccount[]
  }
}

interface UpdateTotalAssetAmountAction {
  type: 'UpdateTotalAssetAmount'
  userAccountType?: UserAccountType
  totalAssetAmount?: number
}

interface UpdateThisMonthsPaymentAction {
  type: 'UpdateThisMonthsPayment'
  userAccountType?: UserAccountType
  thisMonthsPayment?: number
}

interface UpdateCardAccountsAction {
  type: 'UpdateCardAccounts'
  userAccountType?: UserAccountType
  cards?: FinancialAccount[]
}

interface UpdateBankAccountsAction {
  type: 'UpdateBankAccounts'
  userAccountType?: UserAccountType
  banks?: FinancialAccount[]
}

interface UpdateEmoneyAccountsAction {
  type: 'UpdateEmoneyAccounts'
  userAccountType?: UserAccountType
  emoneys?: FinancialAccount[]
}

interface UpdateMonthsPLAction {
  type: 'UpdateMonthsPL'
  userAccountType?: UserAccountType
  monthsPL?: { [yyyymm: string]: ProfitLossSummary }
}

interface UpdateTransactionsAction {
  type: 'UpdateTransactions'
  userAccountType?: UserAccountType
  transactions?: Transaction[]
  nextTransactionDate?: string | null
}

interface UpdateCategoryTransactionsAction {
  type: 'UpdateCategoryTransactions'
  userAccountType: UserAccountType
  categoryTransactions: Transaction[]
}

interface UpdateBankTransactionAction {
  type: 'UpdateBankTransaction'
  userAccountType?: UserAccountType
  bankTransaction?: GetAccountTransactionsResponse
}

interface UpdateEmoneyTransactionAction {
  type: 'UpdateEmoneyTransaction'
  userAccountType?: UserAccountType
  emoneyTransaction?: GetAccountTransactionsResponse
}

interface UpdateCardTransactionAction {
  type: 'UpdateCardTransaction'
  userAccountType?: UserAccountType
  cardTransaction?: GetAccountTransactionsResponse
}

interface UpdateGroupedTransactionsAction {
  type: 'UpdateGroupedTransactions'
  userAccountType?: UserAccountType
  groupedTransactions?: Transaction[]
}

interface UpdateManuallyCreatedTransactionsCountAction {
  type: 'UpdateManuallyCreatedTransactionsCount'
  userAccountType?: UserAccountType
  count: number
}

interface UpdateWalletsAction {
  type: 'UpdateWallets'
  userAccountType?: UserAccountType
  wallets?: WalletEntity[]
}

interface UpdateWalletTransactionAction {
  type: 'UpdateWalletTransaction'
  userAccountType?: UserAccountType
  walletTransaction?: GetAccountTransactionsResponse
}

interface UpdateInvestmentsAction {
  type: 'UpdateInvestments'
  userAccountType?: UserAccountType
  investments?: FinancialAccount[]
}

interface UpdateAssetProductsAction {
  type: 'UpdateAssetProducts'
  userAccountType?: UserAccountType
  assetProducts?: AssetProduct[]
}

interface UpdateHomeExpensesSummaryAction {
  type: 'UpdateHomeExpensesSummary'
  userAccountType?: UserAccountType
  homeExpensesSummary: {
    [yyyymm: string]: GetHomeExpensesSummaryResponse
  }
}

interface UpdateHomeExpensesSharingTransactionsAction {
  type: 'UpdateHomeExpensesSharingTransactions'
  userAccountType?: UserAccountType
  transactions: {
    [yyyymm: string]: Transaction[]
  }
}

interface UpdateHomeExpensesSelectedDateAction {
  type: 'UpdateHomeExpensesSelectedDate'
  userAccountType?: UserAccountType
  homeExpensesSelectedDate?: string
}

interface UpdateHomeExpensesSelectedYearMonthAction {
  type: 'UpdateHomeExpensesSelectedYearMonth'
  userAccountType?: UserAccountType
  homeExpensesSelectedYearMonth?: string
}

interface UpdateLatestTransactionsCountAction {
  type: 'UpdateLatestTransactionsCount'
  userAccountType?: UserAccountType
  count?: number
}

interface UpdateCalendarTransactionsAction {
  type: 'UpdateCalendarTransactions'
  userAccountType?: UserAccountType
  calendarTransactions: {
    [yyyymm: string]: Transaction[]
  }
}

interface UpdateHomeExpensesIsYearlyAction {
  type: 'UpdateHomeExpensesIsYearly'
  isYearly: boolean
}

export type FinancialActions =
  | UpdateFinancialAssetsAction
  | UpdateTotalAssetAmountAction
  | UpdateThisMonthsPaymentAction
  | UpdateCardAccountsAction
  | UpdateBankAccountsAction
  | UpdateEmoneyAccountsAction
  | UpdateMonthsPLAction
  | UpdateTransactionsAction
  | UpdateCategoryTransactionsAction
  | UpdateBankTransactionAction
  | UpdateEmoneyTransactionAction
  | UpdateCardTransactionAction
  | UpdateGroupedTransactionsAction
  | UpdateManuallyCreatedTransactionsCountAction
  | UpdateWalletsAction
  | UpdateWalletTransactionAction
  | UpdateInvestmentsAction
  | UpdateAssetProductsAction
  | UpdateHomeExpensesSummaryAction
  | UpdateHomeExpensesSharingTransactionsAction
  | UpdateHomeExpensesSelectedDateAction
  | UpdateHomeExpensesSelectedYearMonthAction
  | UpdateLatestTransactionsCountAction
  | UpdateCalendarTransactionsAction
  | UpdateHomeExpensesIsYearlyAction

// Functions
export const updateHomeExpensesSelectedDate = (
  userAccountType: UserAccountType,
  homeExpensesSelectedDate?: string,
): UpdateHomeExpensesSelectedDateAction => {
  return {
    type: 'UpdateHomeExpensesSelectedDate',
    userAccountType,
    homeExpensesSelectedDate,
  }
}

export const updateHomeExpensesSelectedYearMonth = (
  userAccountType: UserAccountType,
  homeExpensesSelectedYearMonth?: string,
): UpdateHomeExpensesSelectedYearMonthAction => {
  return {
    type: 'UpdateHomeExpensesSelectedYearMonth',
    userAccountType,
    homeExpensesSelectedYearMonth,
  }
}

export const updateHomeExpensesTransactions = (
  userAccountType: UserAccountType,
  transactions: {
    [yyyymm: string]: Transaction[]
  },
): UpdateHomeExpensesSharingTransactionsAction => {
  return {
    type: 'UpdateHomeExpensesSharingTransactions',
    userAccountType,
    transactions,
  }
}

export const updateLatestTransactionsCount = (
  userAccountType?: UserAccountType,
  count?: number,
): UpdateLatestTransactionsCountAction => ({
  type: 'UpdateLatestTransactionsCount',
  userAccountType,
  count,
})

export const updateCalendarTransactions = (
  userAccountType: UserAccountType | undefined,
  calendarTransactions: {
    [yyyymm: string]: Transaction[]
  },
): UpdateCalendarTransactionsAction => ({
  type: 'UpdateCalendarTransactions',
  userAccountType,
  calendarTransactions,
})

export const updateHomeExpensesIsYearly = (
  isYearly: boolean,
): UpdateHomeExpensesIsYearlyAction => ({
  type: 'UpdateHomeExpensesIsYearly',
  isYearly,
})
