/* eslint-disable @typescript-eslint/no-var-requires */
import ArrowDown from '@assets/images/arrow/icon-arrow-down-gray.png'
import ArrowRight from '@images/arrow/icon-arrow-white-small.png'
import AvatarMen from '@images/avatar/icon-avatar-men.png'
import AvatarWomen from '@images/avatar/icon-avatar-women.png'
import AvatarMenTint from '@images/avatar/tint/icon-avatar-men.png'
import AvatarWomenTint from '@images/avatar/tint/icon-avatar-women.png'
import CategoryAssetformation from '@images/category/icon-category-assetformation.png'
import CategoryCar from '@images/category/icon-category-car.png'
import CategoryCash from '@images/category/icon-category-cash.png'
import CategoryCloth from '@images/category/icon-category-cloth.png'
import CategoryEducation from '@images/category/icon-category-education.png'
import CategoryFood from '@images/category/icon-category-food.png'
import CategoryGrocery from '@images/category/icon-category-grocery.png'
import CategoryHobby from '@images/category/icon-category-hobby.png'
import CategoryHouse from '@images/category/icon-category-house.png'
import CategoryIncome from '@images/category/icon-category-income.png'
import CategoryInsurance from '@images/category/icon-category-insurance.png'
import CategoryMedical from '@images/category/icon-category-medical.png'
import CategoryOthers from '@images/category/icon-category-others.png'
import CategoryOutgoing from '@images/category/icon-category-outgoing.png'
import CategoryPayoff from '@images/category/icon-category-payoff.png'
import CategoryShipping from '@images/category/icon-category-shipping.png'
import CategorySpecial from '@images/category/icon-category-special.png'
import CategorySupply from '@images/category/icon-category-supply.png'
import CategoryTax from '@images/category/icon-category-tax.png'
import CategoryTraffic from '@images/category/icon-category-traffic.png'
import CategoryTransfer from '@images/category/icon-category-transfer.png'
import CategoryUncategorized from '@images/category/icon-category-uncategorized.png'
import FamilyShareButtonOff from '@images/family-share-button/icon-family-share-button-off.png'
import FamilyShareButtonOn from '@images/family-share-button/icon-family-share-button-on.png'
import Bad from '@images/goal/icon-bad.png'
import Best from '@images/goal/icon-best.png'
import Normal from '@images/goal/icon-normal.png'
import CheckMarkIcon from '@images/icons/checkbox/icon-check.png'
import BalanceIcon from '@images/icons/icon-balance.png'
import BankIcon from '@images/icons/icon-bank.png'
import BondIcon from '@images/icons/icon-bond.png'
import Calculator from '@images/icons/icon-calculator.png'
import CalendarIcon from '@images/icons/icon-calendar.png'
import CameraIcon from '@images/icons/icon-camera.png'
import CautionIcon from '@images/icons/icon-caution.png'
import CheckMarkOffIcon from '@images/icons/icon-check-mark-off.png'
import CheckMarkOnIcon from '@images/icons/icon-check-mark-on.png'
import CreditCardIcon from '@images/icons/icon-credit-card.png'
import FlagIcon from '@images/icons/icon-flag.png'
import IcCardIcon from '@images/icons/icon-ic-card.png'
import OsidOriLogoGrayIcon from '@images/icons/icon-logo-gray.png'
import MemoIcon from '@images/icons/icon-memo.png'
import MenuBudgetIcon from '@images/icons/icon-menu-budget.png'
import MenuCalendarIcon from '@images/icons/icon-menu-calendar.png'
import MenuCategoryIcon from '@images/icons/icon-menu-category.png'
import MenuRepeatIcon from '@images/icons/icon-menu-repeat.png'
import EyeOff from '@images/icons/icon-password-eye-off.png'
import EyeOn from '@images/icons/icon-password-eye-on.png'
import PayoffCancel from '@images/icons/icon-payoff-cancel.png'
import PeriodIcon from '@images/icons/icon-period.png'
import PremiumIcon from '@images/icons/icon-premium.png'
import Settlement from '@images/icons/icon-settlement.png'
import ShopIcon from '@images/icons/icon-shop.png'
import ArrowIcon from '@images/icons/icon-transfer-arrow.png'
import WalletIcon from '@images/icons/icon-wallet.png'
import YenIcon from '@images/icons/icon-yen.png'
import InvestmentArrowDown from '@images/icons/investment-arrow/icon-investment-arrow-down.png'
import InvestmentArrowOffset from '@images/icons/investment-arrow/icon-investment-arrow-offset.png'
import InvestmentArrowUp from '@images/icons/investment-arrow/icon-investment-arrow-up.png'
import GoalReportBad from '@images/report/goal-score-bad.png'
import GoalReportBest from '@images/report/goal-score-best.png'
import GoalReportNormal from '@images/report/goal-score-normal.png'
import { GoalProgressStatus } from '@interfaces/Goal'
import store from '@redux/store'
import { ImageRequireSource, ImageSourcePropType } from 'react-native'
import { ProfileState } from './ProfileManager/types'

export const Goal = {
  Best,
  Normal,
  Bad,
}

export const GoalReport = {
  Best: GoalReportBest,
  Normal: GoalReportNormal,
  Bad: GoalReportBad,
}

export const Password = {
  Eye: {
    On: EyeOn,
    Off: EyeOff,
  },
}

export const CategoryIcon = {
  Car: CategoryCar,
  Cash: CategoryCash,
  Cloth: CategoryCloth,
  Education: CategoryEducation,
  Food: CategoryFood,
  Grocery: CategoryGrocery,
  Hobby: CategoryHobby,
  House: CategoryHouse,
  Income: CategoryIncome,
  Insurance: CategoryInsurance,
  Medical: CategoryMedical,
  Others: CategoryOthers,
  Outgoing: CategoryOutgoing,
  Shipping: CategoryShipping,
  Special: CategorySpecial,
  Supply: CategorySupply,
  Tax: CategoryTax,
  Traffic: CategoryTraffic,
  CategoryAssetformation: CategoryAssetformation,
  Uncategorized: CategoryUncategorized,
  Transfer: CategoryTransfer,
  Payoff: CategoryPayoff,
}

export const Avatar = {
  Women: AvatarWomen,
  Men: AvatarMen,
}

export const FamilyShareButton = {
  On: FamilyShareButtonOn,
  Off: FamilyShareButtonOff,
}

export const Premium = PremiumIcon

export const goalFaceIcon = (
  status: GoalProgressStatus,
): ImageRequireSource => {
  switch (status) {
    case 'best':
      return require('@images/report/goal-score-best.png')
    case 'normal':
      return require('@images/report/goal-score-normal.png')
    case 'bad':
      return require('@images/report/goal-score-bad.png')
  }
}

export const goalReportFaceIcon = (status: GoalProgressStatus) => {
  switch (status) {
    case 'best':
      return GoalReport.Best
    case 'normal':
      return GoalReport.Normal
    case 'bad':
      return GoalReport.Bad
  }
}

export const OsidOriLogoGray = OsidOriLogoGrayIcon
export const Bank = BankIcon
export const IcCard = IcCardIcon
export const Caution = CautionIcon
export const Memo = MemoIcon
export const Shop = ShopIcon
export const Income = CategoryIncome
export const Wallet = WalletIcon
export const Calendar = CalendarIcon
export const CreditCard = CreditCardIcon
export const CheckMarkOn = CheckMarkOnIcon
export const CheckMarkOff = CheckMarkOffIcon
export const CheckMark = CheckMarkIcon
export const Balance = BalanceIcon
export const Flag = FlagIcon
export const Bond = BondIcon
export const Camera = CameraIcon
export const Yen = YenIcon
export const Period = PeriodIcon

export const MenuCategory = MenuCategoryIcon
export const MenuRepeat = MenuRepeatIcon
export const MenuBudget = MenuBudgetIcon
export const MenuCalendar = MenuCalendarIcon

export const Investment = {
  Arrow: {
    Up: InvestmentArrowUp,
    Down: InvestmentArrowDown,
    Offset: InvestmentArrowOffset,
  },
}

export const Arrow = {
  Right: ArrowRight,
  Down: ArrowDown,
}

export const Transfer = {
  Arrow: ArrowIcon,
}

export const userAvatar = (
  profile: ProfileState = store.getState().profile,
): ImageSourcePropType => {
  if (!profile.userProfile?.imgUrl) {
    return defaultUserAvatar(profile)
  }
  return { uri: profile.userProfile.imgUrl }
}

export const partnerAvatar = (
  profile: ProfileState = store.getState().profile,
): ImageSourcePropType => {
  if (!profile.partnerProfile?.imgUrl) {
    return defaultPartnerAvatar(profile)
  }
  return { uri: profile.partnerProfile.imgUrl }
}

export const defaultUserAvatar = (
  profile: ProfileState = store.getState().profile,
): ImageSourcePropType => {
  const userGender = getGender(profile.userProfile?.gender)
  const partnerGender = getGender(profile.partnerProfile?.gender)
  if (userGender === 'Unknown') {
    if (partnerGender === 'Unknown') return Avatar.Men as never
    return (partnerGender === 'Men' ? Avatar.Women : Avatar.Men) as never
  }
  return (userGender === 'Men' ? Avatar.Men : Avatar.Women) as never
}

export const defaultPartnerAvatar = (
  profile: ProfileState = store.getState().profile,
): ImageSourcePropType => {
  const userGender = getGender(profile.userProfile?.gender)
  const partnerGender = getGender(profile.partnerProfile?.gender)
  if (partnerGender === 'Unknown') {
    if (userGender === 'Unknown') return Avatar.Women as never
    return (userGender === 'Men' ? Avatar.Women : Avatar.Men) as never
  }
  return (partnerGender === 'Men' ? Avatar.Men : Avatar.Women) as never
}

const getGender = (
  gender: 0 | 1 | null | undefined,
): 'Men' | 'Women' | 'Unknown' => {
  if (gender === 0) return 'Women'
  if (gender === 1) return 'Men'
  return 'Unknown'
}

export const FaceIcon = {
  Men: AvatarMenTint,
  Women: AvatarWomenTint,
}

export const SettlementIcon = Settlement

export const CalculatorIcon: ImageRequireSource = Calculator as never

export const PayoffCancelIcon: ImageRequireSource = PayoffCancel as never

export const CheckBoxIcon: {
  On: ImageRequireSource
  Off: ImageRequireSource
} = {
  On: CheckMarkOnIcon as never,
  Off: CheckMarkOffIcon as never,
}
