import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TransactionItem from '@components/TransactionItem'
import { FinancialAccountType, Transaction } from '@interfaces/Financial'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import {
  ListRenderItem,
  SectionList,
  SectionListData,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'
import { payoffTransactionsSelector } from '../../selector'

const PayoffTransactionsScreen: React.FC<
  StackScreenProps<RootStackParamList, 'PayoffTransactions'>
> = () => {
  const transactions = useSelector(payoffTransactionsSelector)

  const getFinancialAccountType = useCallback(
    (transaction: Transaction): FinancialAccountType => {
      if (transaction.type === 'bank') return 'bank'
      if (transaction.type === 'card') return 'card'
      if (transaction.type === 'emoney') return 'emoney'
      return 'manually_created'
    },
    [],
  )

  const getAccountId = useCallback((transaction: Transaction) => {
    return (
      transaction.atUserBankAccountId ||
      transaction.atUserCardAccountId ||
      transaction.atUserEmoneyServiceAccountId ||
      transaction.walletId
    )
  }, [])

  const navigateTransactionDetail = useCallback(
    (item: Transaction, focusMemo: boolean) => {
      const financialAccountType = getFinancialAccountType(item)

      const accountId = getAccountId(item)

      NavigationService.navigate('TransactionDetail', {
        userAccountType: FinancialManager.isGroupTransaction(item)
          ? 'family'
          : 'user',
        financialAccountType,
        accountId,
        transactionId: item.transactionId,
        focusMemo,
      })
    },
    [getAccountId, getFinancialAccountType],
  )

  const onPressItem = useCallback(
    async (item: Transaction) => navigateTransactionDetail(item, false),
    [navigateTransactionDetail],
  )

  const onPressMemoButton = useCallback(
    (item: Transaction) => navigateTransactionDetail(item, true),
    [navigateTransactionDetail],
  )

  const renderItem: ListRenderItem<Transaction> = useCallback(
    ({ item }) => (
      <TransactionItem
        transaction={item}
        onPress={() => onPressItem(item)}
        onPressMemoButton={() => onPressMemoButton(item)}
      />
    ),
    [onPressItem, onPressMemoButton],
  )

  const sections = useMemo(() => {
    const transactionDates: {
      [date: string]: Transaction[]
    } = {}

    const sections: SectionListData<Transaction>[] = []

    transactions?.forEach((transaction) => {
      const date = moment(transaction.usedDate).format('YYYYMMDD')
      if (transactionDates[date]) {
        transactionDates[date].push(transaction)
      } else {
        transactionDates[date] = [transaction]
      }
    })

    const today = moment().format('YYYYMMDD')

    Object.keys(transactionDates)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: transactionDates[date],
        })
      })

    return sections
  }, [transactions])

  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <SectionList
        sections={sections}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title } }) => (
          <SectionListHeader title={title} style={{ fontWeight: 'normal' }} />
        )}
        style={{ marginTop: 20 }}
        keyExtractor={(item: Transaction) =>
          [
            item.type,
            item.transactionId,
            item.isAccountShared,
            item.isTransactionShared,
          ].join('-')
        }
        ItemSeparatorComponent={() => ItemSeparator}
      />
    </View>
  )
}

export default PayoffTransactionsScreen
