import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import { FPManager } from '@lib/api/FP'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { WebView, WebViewNavigation } from 'react-native-webview'
const FPWebViewScreen: React.FC<
  StackScreenProps<RootStackParamList, 'WebView'>
> = () => {
  const [uri, setUri] = useState<string>()

  const getUri = useCallback(async () => {
    console.log(FPManager.getWebURL())
    setUri(await FPManager.getWebURL())
  }, [])

  useEffect(() => {
    getUri()
  }, [getUri])

  const onShouldStartLoadWithRequest = useCallback(
    (event: WebViewNavigation) => {
      if (/^osidori:/.test(event.url)) {
        return false
      } else {
        track(event.url)
        return true
      }
    },
    [],
  )

  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: 'HouseholdDiagnosisLP',
        title: '家計診断のLP',
      })
    }
  }, [isFocused])

  return (
    <View style={{ flex: 1 }}>
      {uri ? (
        <WebView
          source={{ uri }}
          startInLoadingState={true}
          renderLoading={() => <AbsoluteActivityIndicator />}
          onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
        />
      ) : (
        <AbsoluteActivityIndicator />
      )}
    </View>
  )
}

const track = (url: string) => {
  const pathname = new URL(url).pathname

  switch (pathname) {
    case '/fp_lp':
      TrackingUtils.repro.track(
        '【Screen】household diagnosis_household diagnosis lp',
        'Screen',
      )
      break
    case '/fp/result':
      TrackingUtils.repro.track(
        '【Screen】household diagnosis_household diagnosis result',
        'Screen',
      )
      break
    case '/fp/diagnosis':
      TrackingUtils.repro.track(
        '【Screen】household diagnosis_household diagnosis q1',
        'Screen',
      )
      break
    case '/fp/advice':
      TrackingUtils.repro.track(
        '【Screen】household diagnosis_fpadvice q1',
        'Screen',
      )
      break
    case '/fp/advice_request':
      TrackingUtils.repro.track(
        '【Screen】household diagnosis_fpadvice thanks',
        'Screen',
      )
      break
  }
}

export default FPWebViewScreen
