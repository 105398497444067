import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { APIError } from '@lib/api'
import { UserAPI } from '@lib/api/User'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import Images from '@lib/Images'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import FastImage from 'react-native-fast-image'

interface Props {}

class CreateAccountResendEmailScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'CreateAccountResendEmail'>
> {
  private email!: string

  componentDidMount() {
    this.email = this.props.route.params?.email
  }

  private onPressSubmitButton = async () => {
    try {
      showAppButtonIndicator()
      const response = await UserAPI.reauthenticate({ email: this.email })
      if (response.ok) {
        NavigationService.goBack()
      } else {
        throw new APIError(response)
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator()
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader title="認証用URLの再送" />
        <FastImage source={Images.LoginBackground as never} style={styles.body}>
          <View style={styles.main}>
            <Text style={styles.title}>認証用URLの再送</Text>
            <Text
              style={
                styles.message
              }>{`ご登録頂いたメールアドレスに\n確認コードを再送します`}</Text>
            <Text style={styles.message}>{this.email}</Text>
          </View>
          <AppButton
            title="送信する"
            onPress={this.onPressSubmitButton}
            style={{ width: '100%' }}
          />
          <Text
            style={styles.btnLink}
            onPress={() => NavigationService.goBack('CreateAccountSendEmail')}>
            再度登録し直す場合はこちら
          </Text>
        </FastImage>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    width: '100%',
    paddingVertical: 20,
    borderRadius: 5,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 10,
  },
  message: {
    marginTop: 5,
    textAlign: 'center',
  },
  btnLink: {
    marginVertical: 15,
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    textDecorationLine: 'underline',
    fontWeight: '600',
  },
  btnRegister: {
    width: '80%',
    backgroundColor: Color.Primary,
    height: 50,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textRegister: {
    color: 'white',
    fontSize: 17,
    fontWeight: '600',
  },
})

export default CreateAccountResendEmailScreen
