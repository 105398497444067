import GlobalStyles from '@lib/GlobalStyles'
import store, { RootState } from '@redux/store'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  NativeSyntheticEvent,
  NativeTouchEvent,
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'
import { useSelector } from 'react-redux'
import { setLoadingStatus } from './slice'
import { AppButtonDefaultLoadingKey, Props } from './types'

export const showAppButtonIndicator = (
  key: string = AppButtonDefaultLoadingKey,
) => {
  store.dispatch(setLoadingStatus({ key, isLoading: true }))
}

export const hideAppButtonIndicator = (
  key: string = AppButtonDefaultLoadingKey,
) => {
  store.dispatch(setLoadingStatus({ key, isLoading: false }))
}

const AppButton: React.FC<Props> = ({
  loadingKey = AppButtonDefaultLoadingKey,
  type,
  style,
  disabled,
  ...props
}) => {
  const [onPressDisabled, setOnPressDisabled] = useState(false)
  const [frameStyle, setFrameStyle] = useState<StyleProp<ViewStyle>>()
  const [textStyle, setTextStyle] = useState<StyleProp<TextStyle>>()

  const loadingStatus = useSelector(
    ({ appButton }: RootState) => appButton.loadingStatus,
  )

  const onPress = useCallback(
    (ev: NativeSyntheticEvent<NativeTouchEvent>) => {
      if (onPressDisabled) return
      setOnPressDisabled(true)
      setTimeout(() => {
        setOnPressDisabled(false)
      }, 500)
      props.onPress?.(ev)
    },
    [onPressDisabled, props],
  )

  useEffect(() => {
    if (disabled) {
      setFrameStyle(GlobalStyles.disableButtonFrame)
      setTextStyle(GlobalStyles.disableButtonText)
    } else {
      switch (type) {
        case 'primary':
          setFrameStyle(GlobalStyles.buttonPrimaryFrame)
          setTextStyle(GlobalStyles.buttonPrimaryText)
          break
        case 'secondary':
          setFrameStyle(GlobalStyles.buttonSecondaryFrame)
          setTextStyle(GlobalStyles.buttonSecondaryText)
          break
        case 'secondary-min':
          setFrameStyle(GlobalStyles.buttonSecondaryMinFrame)
          setTextStyle(GlobalStyles.buttonSecondaryMinText)
          break
        case 'white':
          setFrameStyle(GlobalStyles.whiteButtonFrame)
          setTextStyle(GlobalStyles.whiteButtonText)
          break
        default:
          setFrameStyle(GlobalStyles.buttonPrimaryFrame)
          setTextStyle(GlobalStyles.buttonPrimaryText)
      }
    }
  }, [disabled, type])

  return (
    <TouchableOpacity
      style={[frameStyle, style]}
      onPress={onPress}
      disabled={disabled || loadingStatus[loadingKey]}>
      {loadingStatus[loadingKey] ? (
        <ActivityIndicator color="white" size="small" />
      ) : (
        <Text style={[textStyle, props.textStyle]}>{props.title}</Text>
      )}
    </TouchableOpacity>
  )
}

export default AppButton
