import CommonHeader from '@components/CommonHeader'
import AccountTrackerManager from '@lib/AccountTrackerManager'
import CommonDialog from '@lib/CommonDialog'
import { CloseAccountTrackerParams } from '@lib/DeepLinkService/CloseAccountTracker'
import { NativeApp } from '@lib/Env'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import { Component } from 'react'
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'

interface Props {}

export default class WaitFinancialAccountSyncScreen extends Component<
  Props & StackScreenProps<RootStackParamList, 'WaitFinancialAccountSync'>
> {
  componentDidMount() {
    const params = this.props.route.params
    this.closeAccountTracker(params)
  }

  private closeAccountTracker = async (params: CloseAccountTrackerParams) => {
    try {
      Log.info('closeAccountTracker', params)
      await AccountTrackerManager.syncAccount({
        fncType: params.fncType,
      })

      if (store.getState().account.accountMode === 'family') {
        // 個人に登録された口座を家族に移す
        // const financialAccountType = (() => {
        //   if (params.fncType === 'bank') return 'bank'
        //   if (params.fncType === 'card') return 'card'
        //   if (params.fncType === 'etc') return 'emoney'
        //   if (params.fncType === 'invest') return undefined
        //   return 'unknown'
        // })()
        // if (financialAccountType === 'unknown') {
        //   NavigationService.navigate('FinancialAccounts')
        //   return
        // }

        // let response: FinancialAccount[]
        // if (financialAccountType) {
        //   response = await FinancialManager.fetchFinancialAccounts(
        //     financialAccountType,
        //     'user',
        //   )
        // } else {
        //   response = await FinancialManager.fetchStockAccounts('user')
        // }
        // const financialAccount = response.find((v) => v.fncId === params.fncId)
        // if (financialAccount) {
        //   if (financialAccountType) {
        //     await FinancialManager.updateAccount({
        //       financialType: financialAccountType,
        //       accountId: financialAccount.accountId,
        //       name: financialAccount.name,
        //       share: true,
        //     })
        //   } else {
        //     await FinancialManager.updateStockAccount({
        //       id: financialAccount.accountId,
        //       name: financialAccount.name,
        //       share: true,
        //     })
        //   }
        //   TrackingUtils.adjust.trackEvent({ ios: 'lkm933', android: 'x76y56' })
        //   TrackingUtils.adjust.trackEvent({ ios: 'cupeik', android: 'df52nu' })
        // } else {
        //   // 家族口座の編集
        // }
        TrackingUtils.adjust.trackEvent({ ios: 'lkm933', android: 'x76y56' })
        TrackingUtils.adjust.trackEvent({ ios: 'cupeik', android: 'df52nu' })
      } else {
        TrackingUtils.repro.trackOnce('【Screen】add account_unique', 'Screen')
        TrackingUtils.adjust.trackEvent({ ios: 'n476rk', android: 'kup390' })
        TrackingUtils.repro.track('【Screen】add account_cumulative', 'Screen')
        TrackingUtils.adjust.trackEvent({ ios: 'd8w597', android: 'n17hv6' })
      }
      if (NativeApp) {
        NavigationService.navigate('FinancialAccountsTab', {
          screen: 'FinancialAccounts',
        })
      } else {
        NavigationService.goBack()
        NavigationService.navigate('FinancialAccountsTab')
      }
    } catch (error) {
      CommonDialog.showError({
        error,
        onPress: () => NavigationService.navigate('FinancialAccounts'),
      })
    }
  }

  render() {
    return (
      <>
        <CommonHeader leftButtonType="hidden" />
        <View style={styles.container}>
          <Text style={styles.textBold}>
            金融機関の情報を{'\n'}OsidOriへ読み込んでいます。
          </Text>
          <View style={styles.wrapLoading}>
            <ActivityIndicator color="white" size="large" />
          </View>
          <Text>少々お待ちください。</Text>
          <Text style={styles.time}>
            （最大で15秒程度かかることがあります）
          </Text>
        </View>
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapLoading: {
    backgroundColor: '#e5e5e5',
    padding: 15,
    borderRadius: 10,
    marginVertical: 30,
  },
  textBold: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  time: {
    fontSize: 10,
    fontWeight: 'normal',
  },
})
