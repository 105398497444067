import TrackingUtils from '@lib/TrackingUtils'
import { PremiumView2 } from '@Screen/Main/EditCategory/functions'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import { useEffect } from 'react'

const HomeBudgetPremiumBlurView = () => {
  useEffect(() => {
    TrackingUtils.repro.track('【Screen】upper limit_personal budget', 'Screen')
  }, [])

  return PremiumView2({
    image: require('@images/premium/limit_budget.png'),
    onPressShowDetailButton: () => {
      navigatePremiumPlanLP({ planCode: '001' })
      TrackingUtils.repro.track('【Tap】upper limit_personal budget', 'Tap')
      TrackingUtils.repro.setScreenTrackingAfterPurchase(
        '【Screen】purchase completed via upper limit_personal budget',
      )
    },
  })
}

export default HomeBudgetPremiumBlurView
