import CommonListItem from '@components/CommonListItem/component'
import ItemSeparator from '@components/ItemSeparator'
import {
  IconDefinition,
  faAirConditioner,
  faBabyCarriage,
  faBicycle,
  faCakeCandles,
  faCar,
  faCatSpace,
  faChair,
  faChildReaching,
  faHouse,
  faIslandTropical,
  faPersonCane,
  faPiggyBank,
  faRingsWedding,
  faScrewdriverWrench,
  faSignsPost,
  faSparkles,
  faSuitcaseRolling,
  faTruckMoving,
} from '@fortawesome/pro-solid-svg-icons'
import { GoalContent } from '@interfaces/Goal'
import Color from '@lib/Color'
import { FAIcon } from '@lib/FAIcon'
import { selectGoalCategories } from '@lib/api/Goal/slice'
import { useCallback, useMemo } from 'react'
import { FlatList, ListRenderItem } from 'react-native'
import Modal, { ModalProps } from 'react-native-modal'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'

export type SelectLifeEventModalProps = Partial<ModalProps> & {
  onPressLifeEvent: (item: GoalContent) => void
  onDismiss?: () => void
}

const SelectLifeEventModal = ({
  onPressLifeEvent,
  onDismiss,
  ...props
}: SelectLifeEventModalProps) => {
  const goalCategories = useSelector(selectGoalCategories).family

  const lifeEvents = useMemo(() => {
    const items: GoalContent[] = []

    goalCategories?.forEach((goalCategory) => {
      goalCategory.goalContents.forEach((goalContent) =>
        items.push(goalContent),
      )
    })

    return items
  }, [goalCategories])

  const renderItem: ListRenderItem<GoalContent> = useCallback(
    ({ item }) => (
      <CommonListItem
        title={item.name}
        textStyle={{ fontSize: 16 }}
        isDisplayArrow
        iconComponent={
          <StyledIconBackground color={item.color}>
            <FAIcon
              icon={icons[item.icon]}
              color="white"
              size={16}
              webSize="sm"
            />
          </StyledIconBackground>
        }
        onPress={() => onPressLifeEvent(item)}
      />
    ),
    [onPressLifeEvent],
  )

  return (
    <StyledModal
      {...props}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      onDismiss={onDismiss}
      onBackdropPress={onDismiss}>
      <StyledModalContent>
        <StyledHeaderView>
          <StyledHeaderText>
            追加するライフイベントの種類を選択
          </StyledHeaderText>
          <StyledCloseButton onPress={onDismiss}>
            <StyledCloseButtonImage />
          </StyledCloseButton>
        </StyledHeaderView>
        <StyledFlatList
          data={lifeEvents}
          renderItem={renderItem}
          ItemSeparatorComponent={() => ItemSeparator}
        />
      </StyledModalContent>
    </StyledModal>
  )
}

const icons: Record<string, IconDefinition> = {
  piggyBank: faPiggyBank,
  cakeCandles: faCakeCandles,
  truckMoving: faTruckMoving,
  ringWedding: faRingsWedding,
  islandTropical: faIslandTropical,
  babyCarriage: faBabyCarriage,
  childReaching: faChildReaching,
  house: faHouse,
  screwdriverWrench: faScrewdriverWrench,
  signsPost: faSignsPost,
  personCane: faPersonCane,
  suitcaseRolling: faSuitcaseRolling,
  bicycle: faBicycle,
  chair: faChair,
  airConditioner: faAirConditioner,
  car: faCar,
  catSpace: faCatSpace,
  sparkles: faSparkles,
}

const StyledModal = styled(Modal)({
  margin: 0,
  justifyContent: 'flex-end',
})

const StyledModalContent = styled.SafeAreaView.attrs({ edges: ['bottom'] })({
  maxHeight: '70%',
  backgroundColor: 'white',
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
})

const StyledHeaderView = styled.View({
  padding: 20,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
})

const StyledHeaderText = styled.Text({
  alignSelf: 'center',
  fontSize: wp(4),
  fontWeight: 'bold',
  color: Color.DefaultText,
})

const StyledCloseButton = styled.TouchableOpacity({
  position: 'absolute',
  right: 15,
})

export const StyledCloseButtonImage = styled.Image.attrs({
  source: require('@images/buttons/timetree/timetree-connect-dialog-close-button.png'),
  resiziMode: 'contain',
})({
  width: 20,
  height: 20,
})

const StyledFlatList = styled(FlatList)({
  // backgroundColor: Color.LightGray,
})

const StyledIconBackground = styled.View<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  width: 25,
  height: 25,
  borderRadius: 12.5,
  alignItems: 'center',
  justifyContent: 'center',
}))

export default SelectLifeEventModal
