import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import TermTransactionManager from '@lib/TermTransactionManager'
import { useTabNavigatorWebHack } from '@lib/web/tab-navigator-web-hack'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import GoalListScreen from './GoalListScreen'
import LifePlanWebScreen from './LifePlanWebScreen'

const Tab = createMaterialTopTabNavigator()

const LifePlanScreen: React.FC<
  StackScreenProps<RootStackParamList, 'CommonTabNavigator'>
> = ({ route }) => {
  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      TermTransactionManager.fetchTermTransactions()
    }
  }, [isFocused, route])

  useTabNavigatorWebHack()

  return (
    <>
      <CommonHeader title="家族の未来" onPressLeftButton={() => null} />
      <Tab.Navigator
        style={{ height: '100%', width: '100%', backgroundColor: 'red' }}
        backBehavior="none"
        swipeEnabled={Platform.OS !== 'android'}
        tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="LifePlanWeb"
          component={LifePlanWebScreen}
          options={{ title: 'ライフプラン' }}
        />
        <Tab.Screen
          name="GoalList"
          component={GoalListScreen}
          options={{ title: '目標貯金' }}
        />
      </Tab.Navigator>
    </>
  )
}

export default LifePlanScreen
