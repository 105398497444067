import PayoffScreen from '@Screen/Main/Home/Payoff/PayoffScreen'
import SettingPayoffScreen from '@Screen/Main/Home/Payoff/SettingPayoff'
import WebViewScreen from '@Screen/WebViewScreen'
import SessionManager from '@lib/SessionManager'
import {
  StackNavigationState,
  StackRouterOptions,
  TypedNavigator,
} from '@react-navigation/native'
import { StackNavigationOptions } from '@react-navigation/stack'
import {
  StackNavigationConfig,
  StackNavigationEventMap,
} from '@react-navigation/stack/lib/typescript/src/types'
import { RootState } from '@redux/store'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Calendar from './Calendar'
// import CategoryPickerSub from './CategoryPicker/CategoryPickerSub'
import ConnectLifePlanScreen from './ConnectLifePlanScreen'
import CreateLifePlanEventScreen from './CreateLifePlanScreen'
import GoalLPScreen from './GoalLPScreen'
import AccountTrackerScreen from './Home/AccountTrackerScreen'
import AssetProductsScreen from './Home/AssetProductsScreen'
import BudgetSettingScreen from './Home/Budget/BudgetSettingScreen'
import BudgetCategoryTransactionsScreen from './Home/BudgetCategoryTransactionsScreen'
import CategoryTransactionsScreen from './Home/CategoryTransactionsScreen'
import CreateWalletScreen from './Home/CreateWalletScreen'
import EditFinancialAccountScreen from './Home/EditFinancialAccountScreen'
import FinancialAccountCooperationScreen from './Home/FinancialAccountCooperation'
import FinancialAccountTransactionsScreen from './Home/FinancialAccountTransactionsScreen'
import EditGoalDetailScreen from './Home/GoalCreateGuide/EditGoalDetailScreen'
import GoalSelectTypeScreen from './Home/GoalCreateGuide/GoalSelectTypeScreen'
import SavingCompletionScreen from './Home/GoalCreateGuide/SavingCompletionScreen'
import SelectAccountScreen from './Home/GoalCreateGuide/SelectAccountScreen'
import GoalDetailScreen from './Home/GoalDetailScreen'
import AddToSavingsScreen from './Home/GoalDetailScreen/AddToSavingsScreen'
import NavigateAccountTrackerScreen from './Home/NavigateAccountTrackerScreen'
import TermTransactionScreen from './Home/TermTransactionScreen'
import TermTransactionsScreen from './Home/TermTransactionsScreen'
import WaitExternalServiceScreen from './Home/WaitExternalServiceScreen'
import WaitFinancialAccountSyncScreen from './Home/WaitFinancialAccountSyncScreen'
import TransactionDetailScreen from './TransactionDetailScreen'
import TransactionsTop from './TransactionsScreen'
import TransferSelectFinancialAccountScreen from './TransferSelectFinancialAccountScreen'
import TransferSelectTransactionScreen from './TransferSelectTransactionScreen'
import { MainScreenTab } from './types'

type Props = TypedNavigator<
  Record<string, object | undefined>,
  StackNavigationState<Record<string, object | undefined>>,
  StackNavigationOptions,
  StackNavigationEventMap,
  ({
    initialRouteName,
    children,
    screenOptions,
    ...rest
  }: // DefaultNavigatorOptions<StackNavigationOptions> &
  any & StackRouterOptions & StackNavigationConfig) => JSX.Element
>

const MainTabsStackScreens = (stack: Props, tab: MainScreenTab) => {
  const displayType =
    useSelector((state: RootState) => state.transactions.displayType) ||
    SessionManager.getTransactionDisplayType()

  const transactionRoutes = useMemo(() => {
    // if (tab === 'HomeTab') return []

    const transactionRoutes = [
      <stack.Screen
        key="TransactionsTop"
        name="TransactionsTop"
        component={TransactionsTop}
        options={{ animationEnabled: tab !== 'TransactionsTab' }}
        initialParams={{ visibleHeaderRightButton: tab === 'TransactionsTab' }}
      />,
      <stack.Screen
        key="Calendar"
        name="Calendar"
        component={Calendar}
        options={{ animationEnabled: tab !== 'TransactionsTab' }}
      />,
    ]

    if (tab === 'HomeTab') return transactionRoutes

    return [transactionRoutes[displayType === 'transactions' ? 0 : 1]]

    // if (selectedTab === 'TransactionsTab') {
    //   return [transactionRoutes[displayType === 'transactions' ? 0 : 1]]
    // } else {
    //   return displayType === 'transactions'
    //     ? transactionRoutes
    //     : transactionRoutes.reverse()
    // }
  }, [displayType, stack, tab])

  return [
    ...transactionRoutes,
    <stack.Screen key="WebView" name="WebView" component={WebViewScreen} />,
    <stack.Screen
      key="Transfer"
      name="Transfer"
      component={TransactionDetailScreen}
    />,
    <stack.Screen
      key="TransactionDetail"
      name="TransactionDetail"
      component={TransactionDetailScreen}
    />,
    <stack.Screen
      key="TransferSelectFinancialAccount"
      name="TransferSelectFinancialAccount"
      component={TransferSelectFinancialAccountScreen}
    />,
    <stack.Screen
      key="TransferSelectTransaction"
      name="TransferSelectTransaction"
      component={TransferSelectTransactionScreen}
    />,
    <stack.Screen key="Payoff" name="Payoff" component={PayoffScreen} />,
    <stack.Screen
      key="SettingPayoff"
      name="SettingPayoff"
      component={SettingPayoffScreen}
    />,
    // <stack.Screen
    //   key="EditCategoryTop"
    //   name="EditCategoryTop"
    //   component={EditCategoryTopScreen}
    // />,
    // <stack.Screen
    //   key="CategoryPickerSub"
    //   name="CategoryPickerSub"
    //   component={EditSubCategoryScreen}
    // />,
    <stack.Screen
      key="CreateWallet"
      name="CreateWallet"
      component={CreateWalletScreen}
    />,
    <stack.Screen
      key="GoalDetail"
      name="GoalDetail"
      component={GoalDetailScreen}
    />,
    <stack.Screen
      key="EditGoalDetail"
      name="EditGoalDetail"
      component={EditGoalDetailScreen}
    />,
    <stack.Screen
      key="ConnectLifePlan"
      name="ConnectLifePlan"
      component={ConnectLifePlanScreen}
    />,
    <stack.Screen
      key="CreateLifePlanEvent"
      name="CreateLifePlanEvent"
      component={CreateLifePlanEventScreen}
    />,
    <stack.Screen
      key="GoalSelectType"
      name="GoalSelectType"
      component={GoalSelectTypeScreen}
    />,
    <stack.Screen
      key="SelectAccount"
      name="SelectAccount"
      component={SelectAccountScreen}
    />,
    <stack.Screen
      key="SavingCompletion"
      name="SavingCompletion"
      component={SavingCompletionScreen}
    />,
    // <stack.Screen
    //   key="FinancialAccounts"
    //   name="FinancialAccounts"
    //   component={FinancialAccountsScreen}
    //   initialParams={{
    //     leftButtonType: tab === 'FinancialAccountsTab' ? 'menu' : 'stack',
    //   }}
    // />,
    <stack.Screen
      key="WaitExternalService"
      name="WaitExternalService"
      component={WaitExternalServiceScreen}
    />,
    <stack.Screen
      key="WaitFinancialAccountSync"
      name="WaitFinancialAccountSync"
      component={WaitFinancialAccountSyncScreen}
    />,
    <stack.Screen
      key="AccountTracker"
      name="AccountTracker"
      component={AccountTrackerScreen}
    />,
    <stack.Screen
      key="FinancialAccountTransactions"
      name="FinancialAccountTransactions"
      component={FinancialAccountTransactionsScreen}
    />,
    <stack.Screen
      key="AssetProducts"
      name="AssetProducts"
      component={AssetProductsScreen}
    />,
    <stack.Screen
      key="AddToSavings"
      name="AddToSavings"
      component={AddToSavingsScreen}
    />,
    <stack.Screen
      key="CategoryTransactions"
      name="CategoryTransactions"
      component={CategoryTransactionsScreen}
    />,
    <stack.Screen
      key="BudgetCategoryTransactions"
      name="BudgetCategoryTransactions"
      component={BudgetCategoryTransactionsScreen}
    />,
    <stack.Screen
      key="FinancialAccountCooperation"
      name="FinancialAccountCooperation"
      component={FinancialAccountCooperationScreen}
    />,
    <stack.Screen
      key="EditFinancialAccount"
      name="EditFinancialAccount"
      component={EditFinancialAccountScreen}
    />,
    <stack.Screen
      key="TermTransactions"
      name="TermTransactions"
      component={TermTransactionsScreen}
    />,
    <stack.Screen
      key="UpdateTermTransaction"
      name="UpdateTermTransaction"
      component={TermTransactionScreen}
    />,
    <stack.Screen
      key="NavigateAccountTracker"
      name="NavigateAccountTracker"
      component={NavigateAccountTrackerScreen}
    />,
    <stack.Screen
      key="BudgetSetting"
      name="BudgetSetting"
      component={BudgetSettingScreen}
    />,
    <stack.Screen key="GoalLP" name="GoalLP" component={GoalLPScreen} />,
    // <stack.Screen name="InsuranceWebView" component={InsuranceWebViewScreen} />,
  ]
}

export default MainTabsStackScreens
