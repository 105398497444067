import API, { APIResponse } from '../api/index'
import { CreateBudgetProps, GetBudgetProps, UpdateBudgetProps } from './types'

export const createBudget = (
  props: CreateBudgetProps
): Promise<APIResponse> => {
  const { userAccountType, ...params } = props
  return API.request(
    'POST',
    `/api/v1/${userAccountType.userAccountPath()}/budget`,
    params,
    true
  )
}

export const updateBudget = (
  props: UpdateBudgetProps
): Promise<APIResponse> => {
  const { userAccountType, ...params } = props
  return API.request(
    'PUT',
    `/api/v1/${userAccountType.userAccountPath()}/budget`,
    params,
    true
  )
}

export const getBudget = (props: GetBudgetProps): Promise<APIResponse> => {
  const { userAccountType, ...params } = props
  return API.request(
    'GET',
    `/api/v1/${userAccountType.userAccountPath()}/budget`,
    params,
    true
  )
}
