import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import TermTransactionManager from '@lib/TermTransactionManager'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useIsFocused } from '@react-navigation/native'
import { useEffect } from 'react'
import TermTransactionsContent from './TermTransactionsContent'

const Tab = createMaterialTopTabNavigator()

const TermTransactionsScreen = () => {
  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      TermTransactionManager.fetchTermTransactions()
    }
  }, [isFocused])

  return (
    <>
      <CommonHeader title="繰り返し明細の設定" />
      <Tab.Navigator backBehavior="none" tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="FamilyTermTransactions"
          component={TermTransactionsContent}
          initialParams={{ userAccountType: 'family' }}
          options={{ title: '家族画面' }}
        />
        <Tab.Screen
          name="UserTermTransactions"
          component={TermTransactionsContent}
          initialParams={{ userAccountType: 'user' }}
          options={{ title: '個人画面' }}
        />
      </Tab.Navigator>
    </>
  )
}

export default TermTransactionsScreen
