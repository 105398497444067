import { Gender, isValidGender } from '@interfaces/Gender'
import { ProfileState } from '@lib/ProfileManager/types'
import store from './store'

export const getUserGender = (
  profile: ProfileState = store.getState().profile,
): Gender => {
  const userGender = profile.userProfile?.gender
  if (isValidGender(userGender)) return userGender

  const partnerGender = profile.partnerProfile?.gender
  if (isValidGender(partnerGender)) {
    return partnerGender === Gender.Male ? Gender.Female : Gender.Male
  }

  return Gender.Male
}

export const getPartnerGender = (
  profile: ProfileState = store.getState().profile,
): Gender => {
  const partnerGender = profile.partnerProfile?.gender
  if (isValidGender(partnerGender)) return partnerGender

  const userGender = profile.userProfile?.gender
  if (isValidGender(userGender)) {
    return userGender === Gender.Male ? Gender.Female : Gender.Male
  }

  return Gender.Male
}
