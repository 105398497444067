import Color from '@lib/Color'
import React, { useEffect, useState } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import Diagnose from './Diagnose'

const WelcomeScreen: React.FC = () => {
  const [isDisplayWelcome, setDisplayWelcome] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setDisplayWelcome(false)
    }, 2000)
  }, [])

  return isDisplayWelcome ? (
    <View style={styles.container}>
      <Image
        style={styles.image}
        resizeMode="contain"
        source={require('@images/logo/logo.png')}
      />
      <Text style={styles.text}>
        OsidOriへようこそ。{'\n'}
        あなたに合った家計管理を行うために、{'\n'}
        いくつか質問をさせてください。
      </Text>
    </View>
  ) : (
    <Diagnose />
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    color: Color.DefaultText,
  },
  image: {
    width: '33%',
  },
})

export default WelcomeScreen
