import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'

export type InputMode =
  | 'SettingPasscode'
  | 'ConfirmPasscode'
  | 'CheckCurrentPassword'
export type SettingMode = 'new' | 'change'

export type SettingPasscodeScreenParams = { settingMode: SettingMode }

const SettingPasscodeScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingPasscode'>
> = () => null

export default SettingPasscodeScreen
