import store from '@redux/store'
import GuidanceFirstInvitePartnerDialog from '@Screen/Main/Home/Guidance/GuidanceFirstInvitePartnerDialog'
import SessionManager from './SessionManager'

class GuidanceService {
  start = () => {
    return

    const state = store.getState()
    if (state.account.accountMode === 'family') {
      if (!state.profile.userStatus?.paired) {
        const count = SessionManager.getDisplayInvitePartnerDialogCount()
        if (count < 1) {
          GuidanceFirstInvitePartnerDialog.show()
          SessionManager.setDisplayInvitePartnerDialogCount(1)
          return
        }
      }
      return
    }
  }
}

export default new GuidanceService()
