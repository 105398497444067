import AdView from '@components/AdView'
import CommonHeader from '@components/CommonHeader'
import FamilyShareToggleButton from '@components/FamilyShareToggleButton'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TransactionItem from '@components/TransactionItem'
import {
  FinanceType,
  FinancialAccountType,
  Transaction,
} from '@interfaces/Financial'
import {
  accountModeSelector,
  isFamilyShareModeSelector,
} from '@lib/AccountManager/actions'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import GlobalFontSizes from '@lib/GlobalFontSizes'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import { profileSelector } from '@lib/ProfileManager/selector'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { UpdateTransactionDetailProps } from '@lib/api/Financial'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Image,
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Toast from 'react-native-simple-toast'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { TransactionDetailScreenNavigationParams } from '../TransactionDetailScreen'
import { updateTransactionDisplayType } from '../Transactions/redux/actions'
import { mainScreenSelectedTabSelector } from '../redux/selector'

const events = [
  OsidoriEventName.DidCreateTransaction,
  OsidoriEventName.DidUpdateTransaction,
  OsidoriEventName.DidDeleteTransaction,
  OsidoriEventName.DidCreateTermTransaction,
  OsidoriEventName.DidUpdateTermTransaction,
  OsidoriEventName.DidDeleteTermTransaction,
  OsidoriEventName.DidCreateTransfer,
  OsidoriEventName.DidUpdateTransfer,
  OsidoriEventName.DidDeleteTransfer,
  OsidoriEventName.ClosePayoffScreen,
]

const TransactionsScreen: React.FC<
  StackScreenProps<RootStackParamList, 'TransactionsTop'>
> = ({ navigation, route }) => {
  const isFocused = useIsFocused()
  const dispatch = useDispatch()

  const userAccountType = useSelector(accountModeSelector)
  const isFamilyShareMode = useSelector(isFamilyShareModeSelector)
  const selectedTab = useSelector(mainScreenSelectedTabSelector)
  const { userProfile } = useSelector(profileSelector)

  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [nextTransactionDate, setNextTransactionDate] = useState<string | null>(
    null,
  )
  const [listeners, setListeners] = useState<string[]>([])

  const sectionListRef = useRef<SectionList>(null)
  const isFetchTransactions = useRef(false)

  const fetchTransactions = useCallback(
    async ({ append }: { append?: boolean } = { append: false }) => {
      if (isFetchTransactions.current) return
      isFetchTransactions.current = true

      if (!append && transactions.length > 0) {
        sectionListRef.current?.scrollToLocation({
          sectionIndex: 0,
          itemIndex: 0,
          viewPosition: 0,
          viewOffset: 0,
          animated: false,
        })
      }

      const {
        transactions: newTransactions,
        nextTransactionDate: newNextTransactionDate,
      } = await FinancialManager.getTransactions({
        accountType: userAccountType,
        share: isFamilyShareMode,
        future: false,
        transfer: 'transaction',
        to: append
          ? nextTransactionDate
            ? nextTransactionDate
            : undefined
          : undefined,
        exclusionCarryForward: false,
      })

      if (append) {
        setTransactions((prev) => [...prev, ...newTransactions])
      } else {
        setTransactions(newTransactions)
      }
      setNextTransactionDate(newNextTransactionDate)
      isFetchTransactions.current = false
    },
    [
      userAccountType,
      isFamilyShareMode,
      nextTransactionDate,
      transactions.length,
    ],
  )

  const navigateTransactionDetail = useCallback(
    (params: TransactionDetailScreenNavigationParams) => {
      NavigationService.navigate('TransactionDetail', params)
    },
    [],
  )

  const onPressItem = useCallback(
    (item: Transaction) => {
      const params: TransactionDetailScreenNavigationParams = {
        financialAccountType: item.walletId ? 'wallet' : item.type,
        accountId: item.accountId,
        transactionId: item.transactionId,
        isTransactionShared: item.isTransactionShared,
        transferId: item.transferId,
        userAccountType: FinancialManager.isGroupTransaction(item)
          ? 'family'
          : 'user',
        amount: item.amount,
      }
      navigateTransactionDetail(params)
    },
    [navigateTransactionDetail],
  )

  const onPressMemoButton = useCallback(
    (item: Transaction) => {
      const params: TransactionDetailScreenNavigationParams = {
        focusMemo: true,
        financialAccountType: item.walletId ? 'wallet' : item.type,
        accountId: item.accountId,
        transactionId: item.transactionId,
        isTransactionShared: item.isTransactionShared,
        transferId: item.transferId,
        userAccountType: FinancialManager.isGroupTransaction(item)
          ? 'family'
          : 'user',
        amount: item.amount,
      }
      navigateTransactionDetail(params)
    },
    [navigateTransactionDetail],
  )

  const onSwipeableOpenItem = useCallback(async (item: Transaction) => {
    const financialAccountType: FinancialAccountType = (() => {
      if (item.atUserBankAccountId) return 'bank'
      if (item.atUserCardAccountId) return 'card'
      if (item.atUserEmoneyServiceAccountId) return 'emoney'
      if (item.atUserEmoneyAccountId) return 'emoney'
      if (item.walletId) return 'wallet'
      return 'manually_created'
    })()

    const userAccountType = item.isAccountShared ? 'family' : 'user'
    const transactionShare = !item.isTransactionShared

    try {
      await FinancialManager.updateTransaction({
        userAccountType,
        financialAccountType,
        accountId: item.accountId,
        transactionId: item.transactionId,
        transactionShare,
        amount: item.amount,
        paymentMethodId: item.walletId ?? undefined,
        paymentMethodType: item.walletId ? 'wallet' : undefined,
        ignore: item.isIgnored,
        memo: item.memo !== null ? item.memo : undefined,
        type: item.amount >= 0 ? 'receipt' : 'payment',
        action: 'swipe',
      })
    } catch (error) {
      CommonDialog.showError({ error })
      return
    }

    if (Platform.OS !== 'web') {
      Toast.showWithGravity('明細を振り分けました', Toast.SHORT, Toast.BOTTOM)
    }

    if (userAccountType === 'family') {
      TrackingUtils.repro.trackOnce(
        '【Completed】Allocate details to family_unique',
        'Completed',
      )
      TrackingUtils.adjust.trackEvent({ ios: '46kvf5', android: '6cnfwh' })
      TrackingUtils.repro.track(
        '【Completed】Allocate details to family_cumulative',
        'Completed',
      )
      TrackingUtils.adjust.trackEvent({ ios: '659muc', android: '11ppeh' })
    }
  }, [])

  const renderItem: SectionListRenderItem<Transaction> = useCallback(
    ({ item }) => {
      return (
        <TransactionItem
          transaction={item}
          onPress={onPressItem}
          onPressMemoButton={onPressMemoButton}
          onSwipeableOpen={
            item.type !== 'carry_forward' ? onSwipeableOpenItem : undefined
          }
          // onPressAddPaylistButton={() => {
          //   fetchTransactions(false, false)
          // }}
        />
      )
    },
    [onPressItem, onPressMemoButton, onSwipeableOpenItem],
  )

  const renderListHeader = useCallback(() => {
    if (userAccountType === 'family') return null

    const familyShareTextStyle = isFamilyShareMode
      ? styles.familyShareOnText
      : styles.familyShareOffText
    const familyShareText = isFamilyShareMode
      ? '振り分けた取引を含める'
      : '振り分けた取引を含めない'

    return (
      <View style={styles.listHeader}>
        <Text style={familyShareTextStyle}>{familyShareText}</Text>
        <FamilyShareToggleButton />
      </View>
    )
  }, [userAccountType, isFamilyShareMode])

  const sections = useMemo(() => {
    const newTransactions: { [date: string]: Transaction[] } = {}
    const sections: SectionListData<Transaction>[] = []

    transactions?.forEach((transaction: Transaction) => {
      // const date = moment(transaction.usedDate).format('YYYYMMDD')
      if (newTransactions[transaction.usedDate]) {
        newTransactions[transaction.usedDate].push(transaction)
      } else {
        newTransactions[transaction.usedDate] = [transaction]
      }
    })

    const today = moment().format('YYYY-MM-DD')

    Object.keys(newTransactions)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title: date.startsWith(today)
            ? '今日'
            : moment(date).format('YYYY/M/D'),
          data: newTransactions[date],
        })
      })

    return sections
  }, [transactions])

  const onEndReached = useCallback(() => {
    if (!isFetchTransactions.current && nextTransactionDate) {
      fetchTransactions({ append: true })
    }
  }, [fetchTransactions, nextTransactionDate])

  const refreshControl = useMemo(
    () => (
      <RefreshControl
        refreshing={refreshing}
        onRefresh={async () => {
          setRefreshing(true)
          await fetchTransactions()
          setRefreshing(false)
        }}
      />
    ),
    [fetchTransactions, refreshing],
  )

  const renderSectionHeader = useCallback(
    ({ section: { title } }: { section: SectionListData<Transaction> }) => (
      <SectionListHeader title={title} style={{ fontWeight: 'normal' }} />
    ),
    [],
  )

  const switchCalender = useCallback(() => {
    dispatch(updateTransactionDisplayType('calendar'))
    SessionManager.setTransactionDisplayType('calendar')
  }, [dispatch])

  const renderRightButton = useCallback(() => {
    return (
      <TouchableOpacity
        onPress={switchCalender}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 8,
          paddingHorizontal: 5,
          paddingBottom: 5,
        }}>
        <Image
          style={{
            width: 18,
            height: 18,
            tintColor: Color.White,
          }}
          source={require('@images/icons/icon-calendar.png')}
        />
        <Text
          style={{
            fontSize: 11,
            fontWeight: 'normal',
            color: Color.White,
            marginTop: 3,
            paddingHorizontal: 10,
          }}>
          カレンダー
        </Text>
      </TouchableOpacity>
    )
  }, [switchCalender])

  const updateTransactionListener = useCallback(
    async (updateTransaction: UpdateTransactionDetailProps) => {
      Log.info('updateTransactionListener', updateTransaction)
      const targetTransactionIndex = transactions.findIndex(
        (t) => t.transactionId === updateTransaction.transactionId,
      )

      if (
        updateTransaction.action === 'swipe' &&
        !(userAccountType === 'user' && isFamilyShareMode)
      ) {
        return
      }

      if (targetTransactionIndex >= 0) {
        if (
          !updateTransaction.financialAccountType &&
          !updateTransaction.type
        ) {
          // TODO 明細削除の場合はtypeがないのでここにくる
          // リフレッシュする。ロジックは見直す必要あり
          fetchTransactions()
          return
        }

        // 詳細画面からのアップデートはfinancialAccountType
        // 一覧の未精算リストへの追加はtype
        const type =
          updateTransaction.financialAccountType ??
          (updateTransaction.type as FinanceType)

        const newTransaction = await FinancialManager.getTransactionDetail({
          // userAccountType: updateTransaction.userAccountType,
          financialAccountType: type,
          accountId: updateTransaction.accountId,
          transactionId: updateTransaction.transactionId,
          transfer: 'transaction',
        })
        transactions[targetTransactionIndex] = {
          ...transactions[targetTransactionIndex],
          ...newTransaction,
          accountId:
            updateTransaction.paymentMethodId ||
            transactions[targetTransactionIndex].accountId,
        }
        setTransactions([...transactions])
      } else {
        fetchTransactions()
      }
    },
    [userAccountType, fetchTransactions, isFamilyShareMode, transactions],
  )

  useEffect(() => {
    if (isFocused) {
      if (userAccountType === 'user') {
        TrackingUtils.repro.trackOnce(
          '【Screen】personal_detaile list',
          'Screen',
        )
      } else {
        TrackingUtils.repro.trackOnce('【Screen】family_detaile list', 'Screen')
      }
    }
  }, [userAccountType, navigation, isFocused])

  useEffect(() => {
    if (listeners.length > 0) {
      OsidoriEvent.removeListener(listeners)
    }

    const newLisners: string[] = []
    events.forEach((name) => {
      const id = OsidoriEvent.addListener(name, updateTransactionListener)
      if (id) {
        newLisners.push(id)
      }
    })
    setListeners(newLisners)

    return () => {
      OsidoriEvent.removeListener(listeners)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTransactionListener])

  useEffect(() => {
    if (selectedTab === 'TransactionsTab') {
      fetchTransactions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFamilyShareMode, userAccountType, selectedTab])

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: `Transactions-${
          userAccountType === 'family' ? 'Family' : 'User'
        }`,
        title: `${
          userAccountType === 'family' ? '家族' : '個人'
        }_取引履歴 一覧画面`,
      })
    }
  }, [isFocused, userAccountType])

  const keyExtractor = useCallback(
    (item: Transaction) => item.accountId + '-' + item.transactionId,
    [],
  )

  // const keyExtractor = useCallback(
  //   (item: Transaction, index: number) => JSON.stringify(item) + '-' + index,
  //   [],
  // )

  return (
    <StyledRootView>
      <CommonHeader
        leftButtonType={route.params?.headerLeftButtonType || 'menu'}
        title={(userAccountType === 'user' ? '個人' : '家族') + ' 取引履歴'}
        renderRightButton={
          route.params?.visibleHeaderRightButton ? renderRightButton : undefined
        }
      />
      <StyledSectionList
        ref={sectionListRef}
        sections={sections}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        ListHeaderComponent={renderListHeader}
        keyExtractor={keyExtractor}
        onEndReached={onEndReached}
        refreshControl={refreshControl}
        ItemSeparatorComponent={itemSeparator}
        ListFooterComponent={listFooterComponent}
        stickySectionHeadersEnabled={false}
      />
      {userProfile?.rank === 'free' && <AdView type="320x50" />}
    </StyledRootView>
  )
}

const itemSeparator = () => ItemSeparator

const listFooterComponent = styled.View({ height: 24 })

const StyledRootView = styled.View({
  flex: 1,
  justifyContent: 'space-between',
})

const StyledSectionList = styled.SectionList({
  flex: 1,
})

const styles = StyleSheet.create({
  listHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 15,
  },
  familyShareOnText: {
    color: Color.Primary,
    marginRight: 8,
    fontSize: GlobalFontSizes.Small,
    fontWeight: 'bold',
  },
  familyShareOffText: {
    color: Color.Gray,
    marginRight: 8,
    fontSize: GlobalFontSizes.Small,
    fontWeight: 'bold',
  },
})

export default TransactionsScreen
