import FullWidthImage from '@components/FullWidthImage'
import { Image } from 'react-native'
import styled from 'styled-components/native'

const StyledRootView = styled.View`
  position: absolute;
  margin-vertical: 20px;
  border-radius: 10px;
  shadow-color: rgb(0, 0, 0);
  shadow-opacity: 0.25;
  shadow-radius: 10px;
  shadow-offset: {
    width: 0px;
    height: 5px;
  }
  elevation: 4;
  padding-horizontal: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: white;
  width: ${wp(100) - 40}px;
  margin-horizontal: 20px;
  /* height: 100%; */
`

const StyledCloseIcon = styled.Image`
  width: 15px;
  height: 15px;
  tint-color: #aaa;
`

const StyledText = styled.Text`
  margin-bottom: ${wp(6)}px;
  font-size: ${wp(4.5)}px;
  font-weight: normal;
  line-height: ${wp(6.5)}px;
  font-weight: bold;
  color: rgb(58, 58, 58);
  text-align: center;
`

const StyledTouchableOpacity = styled.TouchableOpacity`
  padding: 5px;
  /* background-color: red; */
  align-self: flex-end;
`

const StyledOrangeText = styled.Text`
  color: rgb(255, 114, 0);
`

const TransactionsUsagePopup = ({
  onPressCloseButton,
}: {
  onPressCloseButton: (() => void) | undefined
}) => (
  <StyledRootView>
    <StyledTouchableOpacity onPress={onPressCloseButton}>
      <Image
        source={require('@images/common-header/common-header-close-button.png')}
        style={{
          width: 15,
          height: 15,
          tintColor: '#aaa',
        }}
      />
    </StyledTouchableOpacity>
    <StyledText>
      右上のアイコンをタップして{'\n'}
      <StyledOrangeText>カレンダー</StyledOrangeText>と
      <StyledOrangeText>一覧</StyledOrangeText>に{'\n'}
      表示を切り替えできます。
    </StyledText>
    <FullWidthImage source={require('@images/transactions-usage.png')} />
  </StyledRootView>
)

export default TransactionsUsagePopup
