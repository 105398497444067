import TintedImage from '@components/TintedImage'
import Color from '@lib/Color'
import { Platform } from 'react-native'
import styled from 'styled-components/native'

export const RootContainer = styled.ScrollView`
  flex: 1;
  background-color: ${Color.LightGray};
`

export const StyledNameText = styled.Text`
  color: ${Color.White};
  font-size: 15px;
  font-weight: bold;
  margin-top: 11px;
  text-shadow-offset: 1px 1px;
  text-shadow-radius: 1px;
  text-shadow-color: rgba(0, 0, 0, 0.1);
`

export const StyledAvatarUpdateText = styled.Text`
  color: ${Color.Primary};
  font-size: 12px;
  font-weight: bold;
`

export const StyledAvatarUpdateImage = styled(TintedImage)`
  width: 18px;
  height: 18px;
  margin-right: 3px;
  resize-mode: contain;
  tint-color: ${Platform.OS !== 'web'
    ? Color.OsidoriGreen
    : Color.UIBankPrimary};
`

export const StyledAvatarUpdateButton = styled.TouchableOpacity`
  background-color: ${Color.White};
  height: 28px;
  position: absolute;
  right: -32px;
  bottom: 0;
  border-radius: 14px;
  padding-horizontal: 10px;
  flex-direction: row;
  align-items: center;
  z-index: 2;
`
