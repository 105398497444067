import { UserAccountType } from '@interfaces/Account'
import { APIError } from '@lib/api/index'
import {
  UserAPI,
  UserCancelQuestionsResponse,
  WithdrawProps,
} from '@lib/api/User'
import SessionManager from '@lib/SessionManager'
import store from '@redux/store'
import { updateAccountMode, updateFamilyShareMode } from './types'

class AccountManager {
  updateAccountMode = (accountMode: UserAccountType) => {
    SessionManager.setAccountMode(accountMode)
    store.dispatch(updateAccountMode(accountMode))
  }

  updateFamilyShareMode = (isFamilyShareMode: boolean) => {
    SessionManager.setFamilyShareMode(isFamilyShareMode)
    store.dispatch(updateFamilyShareMode(isFamilyShareMode))
  }

  withdraw = async (props: WithdrawProps) => {
    await UserAPI.withdraw(props)
  }

  getWithdrawReasons = async () => {
    const response = await UserAPI.cancelQuestions()
    if (response.ok) {
      return (response.json as UserCancelQuestionsResponse).app
        .userCancelQuestions
    } else {
      throw new APIError(response)
    }
  }
}

export default new AccountManager()
