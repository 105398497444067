import ActionSheet from '@components/ActionSheet'
import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import CoupleButton from '@components/CoupleButton'
import DatePicker from '@components/DatePicker'
import IconImage from '@components/IcomImage'
import { APIError } from '@lib/api'
import { DownloadManager } from '@lib/api/Download'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import ProgressHUD from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import store from '@redux/store'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import Encoding from 'encoding-japanese'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { AlertButton } from 'react-native'
import Share from 'react-native-share'
import {
  StyledAccountTypeButtonsContainer,
  StyledAccountTypeContainer,
  StyledButtonContainer,
  StyledDescriptionText,
  StyledMainContainer,
  StyledPeriodContainer,
  StyledPeriodDescription,
  StyledPickerButton,
  StyledPickerContainer,
  StyledPickerText,
  StyledPlSettingsContainer,
  StyledSeparatorView,
  StyledTitleText,
} from './styles'
import {
  UsePlSettingsActionSheetIndex,
  UsePlSettingsActionSheetOptions,
} from './types'

const MonthlyDataDownloadScreen: React.FC = () => {
  const [isShared, setIsShared] = useState(
    store.getState().account.accountMode === 'family',
  )
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())
  const [isFromDatePickerVisible, setIsFromDatePickerVisible] = useState(false)
  const [isToDatePickerVisible, setIsToDatePickerVisible] = useState(false)
  const [usePlSettings, setUsePlSettings] = useState(true)

  const onPressFrom = useCallback(() => setIsFromDatePickerVisible(true), [])
  const onPressTo = useCallback(() => setIsToDatePickerVisible(true), [])

  const onConfirmFrom = useCallback((date: Date) => {
    setFrom(date)
    setIsFromDatePickerVisible(false)
  }, [])

  const onConfirmTo = useCallback((date: Date) => {
    setTo(date)
    setIsToDatePickerVisible(false)
  }, [])

  const onCancelFrom = useCallback(() => setIsFromDatePickerVisible(false), [])
  const onCancelTo = useCallback(() => setIsToDatePickerVisible(false), [])

  // 月次の集計期間
  const onPressPlSettings = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: UsePlSettingsActionSheetOptions,
        cancelButtonIndex: UsePlSettingsActionSheetIndex.Cancel,
      },
      (index: number) => {
        if (index === UsePlSettingsActionSheetIndex.Cancel) return
        setUsePlSettings(index === UsePlSettingsActionSheetIndex.UsePlSettings)
      },
    )
  }, [])

  const getErrorMessage = useCallback((error: Error) => {
    if (error instanceof APIError) {
      if (error.response?.status === 422) {
        const message: string = error.response?.json?.errors[0]?.message || ''
        return message.replace('\\n', '\n')
      }
    }
    return ''
  }, [])

  const onPressDownload = useCallback(async () => {
    TrackingUtils.repro.track('【Tap】monthly_data dl', 'Tap')

    try {
      ProgressHUD.show()
      const response = await DownloadManager.downloadMonthlyReportCSV({
        from: moment(from).format('YYYYMMDD'),
        to: moment(to).format('YYYYMMDD'),
        share: isShared,
        plSettingUse: usePlSettings,
      })
      if (NativeApp) {
        response.path && Share.open({ url: `file://${response.path}` })
      } else {
        const unicodeArray = Encoding.stringToCode(response.json.text) // Convert string to code array
        const sjisArray = Encoding.convert(unicodeArray, {
          to: 'SJIS',
          from: 'UNICODE',
        })
        const u8a = new Uint8Array(sjisArray)

        const blob = new Blob([u8a], { type: 'text/csv' })
        const csvUrl = window.URL.createObjectURL(blob)

        const filename = `${moment(from).format('YYYYMMDD')}-${moment(
          to,
        ).format('YYYYMMDD')}_osidori_pl.csv`

        const link = document.createElement('a')
        link.download = filename
        link.href = csvUrl
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.status === 422) {
          const buttons: AlertButton[] = [{ text: '閉じる' }]
          if (['010301', '010303'].includes(error.response.errorCode || '')) {
            buttons.push({
              text: '詳しく見る',
              onPress: () => {
                navigatePremiumPlanLP({ planCode: '001' })
              },
            })
          }
          Alert.alert('', getErrorMessage(error), buttons)
          return
        }
      }
      CommonDialog.showError({ error })
    } finally {
      ProgressHUD.dismiss()
    }
  }, [from, to, isShared, usePlSettings, getErrorMessage])

  return (
    <StyledMainContainer>
      <StyledDescriptionText>
        家計簿データを月別に集計しcsvファイルで出力できます。{'\n'}
        収入、支出がカテゴリー単位で集計されます。{'\n'}
        （例：食費 食料品 5,000円）
      </StyledDescriptionText>
      <StyledSeparatorView />
      <StyledAccountTypeContainer>
        <StyledTitleText>取得する画面</StyledTitleText>
        <StyledAccountTypeButtonsContainer>
          <CoupleButton
            buttonType="single"
            onPress={() => setIsShared(false)}
            isOn={!isShared}
            title="個人画面"
            disabled={!isShared}
            style={{ width: Math.min(106, wp(28)) }}
          />
          <CoupleButton
            buttonType="couple"
            onPress={() => setIsShared(true)}
            isOn={isShared}
            title="家族画面"
            disabled={isShared}
            style={{
              width: Math.min(106, wp(29)),
              marginLeft: Math.min(20, wp(2.5)),
            }}
          />
        </StyledAccountTypeButtonsContainer>
      </StyledAccountTypeContainer>
      <StyledSeparatorView />
      <StyledPeriodContainer>
        <StyledTitleText>取得期間</StyledTitleText>
        <StyledPeriodDescription>
          1度に最大1年間まで指定できます
        </StyledPeriodDescription>
      </StyledPeriodContainer>
      <StyledPickerContainer>
        <StyledTitleText>開始日</StyledTitleText>
        <StyledPickerButton onPress={onPressFrom}>
          <StyledPickerText>
            {moment(from).format('YYYY年M月D日')}
          </StyledPickerText>
          <IconImage name="ArrowDown" />
        </StyledPickerButton>
      </StyledPickerContainer>
      <StyledSeparatorView />
      <StyledPickerContainer>
        <StyledTitleText>終了日</StyledTitleText>
        <StyledPickerButton onPress={onPressTo}>
          <StyledPickerText>
            {moment(to).format('YYYY年M月D日')}
          </StyledPickerText>
          <IconImage name="ArrowDown" />
        </StyledPickerButton>
      </StyledPickerContainer>
      <StyledSeparatorView />
      <StyledPlSettingsContainer>
        <StyledTitleText>月次の集計期間</StyledTitleText>
        <StyledPickerButton onPress={onPressPlSettings}>
          <StyledPickerText>
            {usePlSettings
              ? UsePlSettingsActionSheetOptions[0]
              : UsePlSettingsActionSheetOptions[1]}
          </StyledPickerText>
          <IconImage name="ArrowDown" />
        </StyledPickerButton>
      </StyledPlSettingsContainer>
      <StyledSeparatorView />
      <StyledButtonContainer>
        <AppButton title="データを出力する" onPress={onPressDownload} />
      </StyledButtonContainer>
      <DatePicker
        date={from}
        isVisible={isFromDatePickerVisible}
        onConfirm={onConfirmFrom}
        onCancel={onCancelFrom}
        headerFormat="日付を選択"
        confirmText="決定"
        cancelText="キャンセル"
      />
      <DatePicker
        date={to}
        isVisible={isToDatePickerVisible}
        onConfirm={onConfirmTo}
        onCancel={onCancelTo}
        headerFormat="日付を選択"
        confirmText="決定"
        cancelText="キャンセル"
      />
    </StyledMainContainer>
  )
}

export default MonthlyDataDownloadScreen
