import FinancialAccountIcon from '@components/FinancialAccountIcon'
import IconImage from '@components/IcomImage'
import { QuestionButton } from '@components/QuestionButton'
import Color from '@lib/Color'
import { Platform, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import CategoryImage from '../CategoryImage'
import { CommonListItemProps } from './types'

const CommonListItem = ({
  displayType = 'default',
  title = '',
  value,
  categoryId,
  iconImageSource,
  iconComponent,
  financialAccountType,
  isDisplayArrow = false,
  arrowType = 'right',
  onPress,
  style,
  textStyle,
  disabled,
  editable = false,
  placeholder,
  onChangeText,
  onTextInputFocus,
  isDisplayBalloonButton,
  onPressBalloonButton,
}: CommonListItemProps) => {
  if (displayType === 'default') {
    return (
      <View>
        <StyledTouchableOpacity onPress={onPress} style={style}>
          {categoryId && <CategoryImage categoryId={categoryId} />}
          {iconImageSource && <StyledIconImage source={iconImageSource} />}
          {financialAccountType && (
            <FinancialAccountIcon financialAccountType={financialAccountType} />
          )}
          {iconComponent}
          <StyledText style={textStyle}>{title}</StyledText>
          {!disabled && isDisplayArrow && (
            <StyledArrowImage
              source={require('@images/arrow/icon-arrow-white-small.png')}
            />
          )}
        </StyledTouchableOpacity>
        {disabled && <StyledGrayoutView />}
      </View>
    )
  } else {
    return (
      <View>
        <StyledTouchableOpacity
          onPress={onPress}
          style={style}
          disabled={disabled && !editable}>
          <StyledText1Container>
            <StyledText1>{title}</StyledText1>
            {isDisplayBalloonButton && (
              <StyledBalloonButton onPress={onPressBalloonButton} />
            )}
          </StyledText1Container>

          {editable ? (
            <StyledTextInput
              placeholder={placeholder}
              onChangeText={onChangeText}
              onFocus={onTextInputFocus}
              value={value}
            />
          ) : value ? (
            <StyledText2>{value}</StyledText2>
          ) : (
            <StyledPlaceHolderText>{placeholder}</StyledPlaceHolderText>
          )}

          {!disabled && isDisplayArrow && (
            <IconImage
              name={arrowType === 'down' ? 'ArrowDown' : 'ArrowRight'}
            />
          )}
        </StyledTouchableOpacity>
      </View>
    )
  }
}

export const StyledTouchableOpacity = styled(TouchableOpacity)`
  padding-horizontal: 16px;
  background-color: ${Color.White};
  height: 44px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StyledDisabledView = styled.View`
  padding-horizontal: 16px;
  background-color: ${Color.White};
  height: 44px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StyledIconImage = styled.Image`
  width: 20px;
  height: 20px;
  resize-mode: contain;
`

export const StyledText = styled.Text`
  flex: 1;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

export const StyledArrowImage = styled.Image`
  tint-color: ${Color.Gray};
  width: 16px;
  height: 16px;
`

export const StyledGrayoutView = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eeeeee60;
`

const StyledText1Container = styled.View`
  width: 100px;
  margin-left: 8px;
  flex-direction: row;
  align-items: center;
`

const StyledText1 = styled.Text`
  font-size: 14px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

const StyledText2 = styled.Text`
  flex: 1;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

const StyledPlaceHolderText = styled.Text`
  flex: 1;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  color: rgb(200, 200, 200);
`

const StyledTextInput = styled.TextInput`
  flex: 1;
  margin-left: ${Platform.select({ ios: 8, android: 4 })}px;
  font-size: 14px;
  font-weight: normal;
  padding-vertical: 10px;
  color: ${Color.DefaultText};
`

const StyledBalloonButton = styled(QuestionButton)`
  padding: 10px;
`

export default CommonListItem
