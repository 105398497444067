import { UserAccountType } from '@interfaces/Account'
import API, { APIResponse } from '../index'
import {
  UpdateCarryForwardSettingsProps,
  UpdateCarryForwardTransactionProps,
} from './types'

export const getCarryForwardSettings = (
  userAccountType: UserAccountType,
): Promise<APIResponse> => {
  return API.request(
    'GET',
    `/api/v3/${userAccountType.userAccountPath()}/carry-forward-settings`,
    {},
    true,
  )
}

export const updateCarryForwardSettings = ({
  userAccountType,
  ...props
}: UpdateCarryForwardSettingsProps): Promise<APIResponse> => {
  return API.request(
    'PUT',
    `/api/v3/${userAccountType.userAccountPath()}/carry-forward-settings`,
    props,
    true,
  )
}

export const getCarryForwardTransaction = (
  transactionId: number,
): Promise<APIResponse> => {
  return API.request(
    'GET',
    `/api/v3/carry-forward-transactions/${transactionId}`,
    {},
    true,
  )
}

export const updateCarryForwardTransaction = ({
  transactionId,
  ...props
}: UpdateCarryForwardTransactionProps): Promise<APIResponse> => {
  return API.request(
    'PUT',
    `/api/v3/carry-forward-transactions/${transactionId}`,
    props,
    true,
  )
}
