import RightArrowButton from '@components/molecules/RightArrowButton'
import Color from '@lib/Color'
import DateUtilsV1 from '@lib/DateUtilsV1'
import { thisMonthBalanceSelector } from '@lib/Financial/selector'
import { userAccountTypeSelector } from '@Screen/Account/selector'
import { budgetSelector } from '@Screen/Main/Home/Budget/selector'
import { useMemo } from 'react'
import { View } from 'react-native'
import { PieChartData } from 'react-native-svg-charts'
import { useSelector } from 'react-redux'
import {
  ChartItemRow,
  ChatItemAmount,
  ChatItemTitle,
  DetailButtonContainer,
  PieChartDetailContainer,
  PieChartRate,
  PieChartTotalTitle,
  PieChartView,
  PieChartViewContainer,
  PieChartViewContent,
  RootContainer,
  StyledPieChart,
} from './styles'

const HomeBudgetChart = () => {
  const userAccountType = useSelector(userAccountTypeSelector)
  const budget =
    useSelector(budgetSelector)[userAccountType][DateUtilsV1.monthKey()]
  const profitLossSummary = useSelector(thisMonthBalanceSelector())

  const remain = useMemo(
    () => (profitLossSummary?.expenseAmount || 0) + (budget?.amount || 0),
    [profitLossSummary, budget],
  )

  const pieChartData: PieChartData[] = useMemo(
    () => [
      {
        value: Math.abs(profitLossSummary?.expenseAmount || 1), // グレーのグラフを描きたいので1にする
        svg: {
          fill: (budget?.amount || 0) > 0 ? Color.Primary : '#eeeeee',
        },
        key: 'expense',
      },
      {
        value: remain > 0 ? remain : 0,
        svg: {
          fill: '#eeeeee',
        },
        key: 'remain',
      },
    ],
    [profitLossSummary?.expenseAmount, budget?.amount, remain],
  )

  if (!budget || !profitLossSummary) return null

  const rate =
    budget.amount > 0
      ? Math.abs(profitLossSummary.expenseAmount) / budget.amount
      : undefined

  return (
    <RootContainer>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <PieChartViewContainer>
          <PieChartView>
            <StyledPieChart
              data={pieChartData}
              outerRadius="98%"
              innerRadius="75%"
              padAngle={0.04}
            />
            <PieChartViewContent>
              <PieChartTotalTitle>予算比</PieChartTotalTitle>
              <PieChartRate disabled={rate === undefined}>
                {rate !== undefined
                  ? Math.ceil(rate * 100).numberFormat()
                  : '--'}
                %
              </PieChartRate>
            </PieChartViewContent>
          </PieChartView>
        </PieChartViewContainer>
        <PieChartDetailContainer>
          <ChartItemRow>
            <ChatItemTitle>予算</ChatItemTitle>
            <ChatItemAmount number={budget.amount}>
              {budget.amount.jpCurrency()}
            </ChatItemAmount>
          </ChartItemRow>
          <ChartItemRow>
            <ChatItemTitle>支出</ChatItemTitle>
            <ChatItemAmount number={profitLossSummary.expenseAmount}>
              {profitLossSummary.expenseAmount.jpCurrency()}
            </ChatItemAmount>
          </ChartItemRow>
          <ChartItemRow>
            <ChatItemTitle over={remain < 0}>
              {remain >= 0 ? '残り' : '超過'}
            </ChatItemTitle>
            <ChatItemAmount number={remain} updateColor>
              {Math.abs(remain).jpCurrency()}
            </ChatItemAmount>
          </ChartItemRow>
        </PieChartDetailContainer>
      </View>

      <DetailButtonContainer>
        <RightArrowButton disabled>詳細を見る</RightArrowButton>
      </DetailButtonContainer>
    </RootContainer>
  )
}

export default HomeBudgetChart
