import { APIError } from '@lib/api'
import * as AssessmentAPI from './api'
import {
  GetAssessmentProps,
  GetAssessmentResponse,
  PostAssessmentProps,
  UsersAssessment,
} from './types'

export const postAssessment = async (props: PostAssessmentProps) => {
  const response = await AssessmentAPI.postAssessment(props)
  if (!response.ok) throw new APIError(response)
}

export const getAssessment = async (
  props: GetAssessmentProps,
): Promise<UsersAssessment> => {
  const response = await AssessmentAPI.getAssessment(props)
  if (!response.ok) throw new APIError(response)
  return (response.json as GetAssessmentResponse).assessment
}
