import Alert from '@components/Alert'
import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CrossPlatformDatePicker from '@components/DatePicker'
import { CarryForwardManager } from '@lib/api/CarryForwardSettings'
import CommonDialog from '@lib/CommonDialog'
import { yyyymm } from '@lib/DateUtils'
import { NativeApp } from '@lib/Env'
import navigationService from '@lib/NavigationService'
import ProgressHUD from '@lib/ProgressHUD'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ScrollView } from 'react-native'
import MonthPicker, { ACTION_DATE_SET } from 'react-native-month-year-picker'
import { useDispatch, useSelector } from 'react-redux'
import { carryForwardSelector } from './selector'
import { setCarryForward } from './slice'
import {
  StyledDateButton,
  StyledDetailText,
  StyledRootContainer,
  StyledSaveButtonContainer,
  StyledSettingItem,
  StyledSettingsTitle,
  StyledSettingsView,
  StyledSettingTitle,
  StyledSwitch,
  StyledTitleText,
} from './styles'

const SettingCarryForwardContentScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingCarryForwardContent'>
> = ({ route }) => {
  const dispatch = useDispatch()

  const { userAccountType } = route.params

  const settings = useSelector(carryForwardSelector)[userAccountType]

  const initialEnable = useRef(settings.enable).current

  const [isDisplayDatePicker, setIsDisplayDatePicker] = useState(false)

  const defaultDate = useRef(new Date()).current

  const updateSettings = useCallback(async () => {
    ProgressHUD.show()
    showAppButtonIndicator()
    try {
      await CarryForwardManager.updateCarryForwardSettings({
        userAccountType,
        year: settings.year || defaultDate.getFullYear(),
        month: settings.month || defaultDate.getMonth() + 1,
        enable: settings.enable,
      })
      navigationService.goBack()
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator()
      ProgressHUD.dismiss()
    }
  }, [
    defaultDate,
    settings.enable,
    settings.month,
    settings.year,
    userAccountType,
  ])

  const onPressSave = useCallback(async () => {
    if (initialEnable && !settings.enable) {
      Alert.alert(
        '繰り越しを解除します',
        'これまでの繰越明細が削除され、復元できなくなりますがよろしいですか？',
        [
          { text: 'キャンセル' },
          {
            text: 'OK',
            onPress: () => updateSettings(),
          },
        ],
      )
    } else {
      updateSettings()
    }
  }, [initialEnable, settings.enable, updateSettings])

  const onValueChangeSwitch = useCallback(
    (value: boolean) => {
      dispatch(
        setCarryForward({
          userAccountType,
          year: settings.year,
          month: settings.month,
          enable: value,
        }),
      )
    },
    [dispatch, settings.month, settings.year, userAccountType],
  )

  const onPressDate = useCallback(() => {
    setIsDisplayDatePicker(!isDisplayDatePicker)
  }, [isDisplayDatePicker])

  const onChangeDate = useCallback(
    (event: string, date: Date) => {
      setIsDisplayDatePicker(false)

      if (event === ACTION_DATE_SET) {
        const newDate = moment(date)

        dispatch(
          setCarryForward({
            userAccountType,
            year: newDate.year(),
            month: newDate.month() + 1,
            enable: settings.enable,
          }),
        )
      }
    },
    [dispatch, settings.enable, userAccountType],
  )

  useEffect(() => {
    const asyncFunc = async () => {
      const settings = await CarryForwardManager.getCarryForwardSettings(
        userAccountType,
      )
      dispatch(
        setCarryForward({
          userAccountType,
          year: settings.year,
          month: settings.month,
          enable: settings.enable,
        }),
      )
    }

    asyncFunc()
  }, [dispatch, userAccountType])

  return (
    <StyledRootContainer>
      <ScrollView>
        <StyledTitleText>繰り越し設定とは</StyledTitleText>
        <StyledDetailText>
          「今⽉の家計簿」の1ヶ⽉間の収⽀結果を、翌⽉の収⽀へ
          繰り越す機能です（プラス・マイナスいずれも繰越）。{'\n\n'}
          繰り越す⾦額は、家計簿の集計期間の最終⽇の翌⽇に
          「前⽉繰越」の名称で取引明細が⾃動作成されます。
        </StyledDetailText>
        <StyledSettingsView>
          <StyledSettingsTitle>繰り越し設定</StyledSettingsTitle>
          <StyledSettingItem>
            <StyledSettingTitle>収⽀を翌⽉へ繰り越す</StyledSettingTitle>
            <StyledSwitch
              onValueChange={onValueChangeSwitch}
              value={settings.enable}
            />
          </StyledSettingItem>
          <StyledSettingItem>
            <StyledSettingTitle>翌⽉へ繰越する最初の⽉</StyledSettingTitle>
            <StyledDateButton
              date={
                settings.year && settings.month
                  ? moment(
                      yyyymm(settings.year, settings.month),
                      'YYYYMM',
                    ).toDate()
                  : new Date()
              }
              onPress={onPressDate}
            />
          </StyledSettingItem>
        </StyledSettingsView>

        <StyledSaveButtonContainer>
          <AppButton title="保存する" onPress={onPressSave} />
        </StyledSaveButtonContainer>
      </ScrollView>
      {isDisplayDatePicker &&
        (NativeApp ? (
          <MonthPicker
            onChange={onChangeDate}
            value={
              settings.year && settings.month
                ? moment(
                    yyyymm(settings.year, settings.month),
                    'YYYYMM',
                  ).toDate()
                : defaultDate
            }
            minimumDate={new Date(2019, 1)}
            locale="ja"
            okButton="確定"
            cancelButton="キャンセル"
          />
        ) : (
          <CrossPlatformDatePicker
            isVisible={true}
            type="year-month"
            date={
              settings.year && settings.month
                ? moment(
                    yyyymm(settings.year, settings.month),
                    'YYYYMM',
                  ).toDate()
                : defaultDate
            }
            onConfirm={(date: Date) => {
              dispatch(
                setCarryForward({
                  userAccountType,
                  year: date.getFullYear(),
                  month: date.getMonth() + 1,
                  enable: settings.enable,
                }),
              )
              setIsDisplayDatePicker(false)
            }}
            onCancel={() => setIsDisplayDatePicker(false)}
            headerFormat={''}
            confirmText="確定"
            cancelText="キャンセル"
          />
        ))}
    </StyledRootContainer>
  )
}

export default SettingCarryForwardContentScreen
