import CalculatorInput from '@components/Calculator/CalculatorInput'
import Color from '@lib/Color'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 15px;
  height: 60px;
`

export const CategoryInfoContainer = styled.View`
  flex: 1;
  margin-left: 8px;
`

export const CategoryName = styled.Text`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: ${Color.DefaultText};
`

export const PrevMonthAmount = styled.Text`
  font-size: 14px;
  font-weight: normal;
  color: #666666;
`

export const Amount = styled.Text`
  border-color: #cccccc;
`

export const StyledCalculatorInput = styled(CalculatorInput)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-right: 15px;
`
