import {
  DEBUG_VERSION_CONFIG_URL,
  VERSION_CONFIG_URL,
} from 'react-native-dotenv'
import API, { APIResponse } from './index'

export interface CheckUpdateResponse {
  android: {
    version: string
    update: {
      forceUpdate: boolean
      message: string
      url: string
    }
    maintenance: {
      inService: boolean
      message: string
      url: string
    }
    review: {
      reviewMode: boolean
      appVersion: string
    }
    logging: boolean
  }
  iOs: {
    version: string
    update: {
      forceUpdate: boolean
      message: string
      url: string
    }
    maintenance: {
      inService: boolean
      message: string
      url: string
    }
    review: {
      reviewMode: boolean
      appVersion: string
    }
    logging: boolean
  }
}

class CheckUpdate {
  checkUpdate = (): Promise<APIResponse> => {
    const time = new Date().getTime()
    const url = __DEV__ ? DEBUG_VERSION_CONFIG_URL : VERSION_CONFIG_URL
    return API.request('GET', url + '?' + time, {}, false)
  }
}

export default new CheckUpdate()
