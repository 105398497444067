// import { Image } from '@components'
import AppButton from '@components/AppButton'
import ArrowIcon from '@components/ArrowIcon'
import CategoryImage from '@components/CategoryImage'
import FullWidthImage from '@components/FullWidthImage'
import ItemSeparator from '@components/ItemSeparator'
import { PremiumView } from '@components/PremiumView'
import SectionListHeader from '@components/SectionListHeader'
import { UserAccountType } from '@interfaces/Account'
import { TermTransactionDetail } from '@lib/api/TermTransaction'
import * as Buttons from '@lib/Buttons'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import TermTransactionManager from '@lib/TermTransactionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import { AddCaregoryButton } from '@Screen/Main/EditCategory/AddCategoryButton'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import moment from 'moment'
import React from 'react'
import {
  ActivityIndicator,
  Image,
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { connect } from 'react-redux'

interface StateProps {
  familyTermTransactions?: TermTransactionDetail[]
  userTermTransactions?: TermTransactionDetail[]
}

interface State {
  refreshing: boolean
  isActive: boolean
  isDisplayTermTransactionHelp: boolean
  isDisplayedPremiumPlanView: boolean
  isPurchaseCompleted: boolean
}

type SectionItem = TermTransactionDetail | string

class TermTransactionsContent extends React.PureComponent<
  StateProps & StackScreenProps<RootStackParamList, 'TermTransactionsContent'>
> {
  state: State = {
    refreshing: false,
    isActive: false,
    isDisplayTermTransactionHelp: false,
    isDisplayedPremiumPlanView: false,
    isPurchaseCompleted: false,
  }

  private userAccountType!: UserAccountType

  private purchaseCompletedListener?: () => void = undefined
  private closedPurchaseCompletedModalListener?: () => void = undefined

  componentDidMount() {
    this.userAccountType = this.props.route.params.userAccountType

    this.purchaseCompletedListener = signalOn('PURCHASE_COMPLETE', () => {
      if (this.props.navigation.isFocused()) {
        this.setState({
          isPurchaseCompleted: true,
          isDisplayedPremiumPlanView: false,
        })
      }
    })

    this.closedPurchaseCompletedModalListener = signalOn(
      'CLOSED_PURCHASE_COMPLETE_MODAL',
      () => {
        this.setState({
          isPurchaseCompleted: false,
        })
      },
    )
  }

  componentWillUnmount() {
    this.purchaseCompletedListener?.()
    this.closedPurchaseCompletedModalListener?.()
  }

  componentDidUpdate = () => {
    this.setState({
      isActive:
        this.props.familyTermTransactions && this.props.userTermTransactions,
    })
  }

  private fetch = async () => {
    try {
      await TermTransactionManager.fetchTermTransactions()
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  private onPressItem = (termTransaction: TermTransactionDetail) => {
    const params = {
      userAccountType: this.userAccountType,
      termTransactionId: termTransaction.termTransactionId,
    }
    NavigationService.navigate('UpdateTermTransaction', params)
  }

  private goBack = () => {
    if (this.state.isDisplayedPremiumPlanView) {
      this.setState({ isDisplayedPremiumPlanView: false })
    } else {
      NavigationService.goBack()
    }
  }

  render() {
    if (!this.state.isActive) {
      return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator color={Color.Gray} />
        </View>
      )
    }

    const sections = this.sections()

    return (
      <>
        <View style={styles.container}>
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
            }}>
            <TouchableOpacity
              onPress={() => {
                this.setState({
                  isDisplayTermTransactionHelp:
                    !this.state.isDisplayTermTransactionHelp,
                })
              }}
              style={{ alignSelf: 'flex-end', marginTop: 20, marginRight: 15 }}>
              <Image
                source={Buttons.TermTransaction.Dialog.Open as never}
                style={{
                  resizeMode: 'contain',
                  width: 137,
                  height: 20,
                }}
              />
            </TouchableOpacity>
            <SectionList
              style={{
                marginTop: 15,
              }}
              contentContainerStyle={{ paddingBottom: 100 }}
              keyExtractor={(item: SectionItem) => JSON.stringify(item)}
              renderSectionHeader={(info) =>
                info.section.title ? (
                  <SectionListHeader
                    title={info.section.title}
                    style={{
                      marginHorizontal: 15,
                      fontWeight: 'normal',
                      paddingHorizontal: 10,
                      color: Color.DefaultText,
                    }}
                  />
                ) : null
              }
              renderItem={this.renderItem}
              sections={sections}
              ItemSeparatorComponent={() => ItemSeparator}
              refreshControl={
                <RefreshControl
                  refreshing={this.state.refreshing}
                  onRefresh={async () => {
                    this.setState({ refreshing: true })
                    await this.fetch()
                    this.setState({ refreshing: false })
                  }}
                />
              }
              ListFooterComponent={() => {
                if (
                  typeof sections[0].data[0] !== 'string' ||
                  typeof sections[1].data[0] !== 'string'
                ) {
                  return <View style={{ height: 24 }} />
                }

                return (
                  <View
                    style={{
                      backgroundColor: Color.LightGray,
                      alignItems: 'center',
                      paddingVertical: 25,
                    }}>
                    <Image
                      source={require('@images/repeat.png')}
                      resizeMode="contain"
                      style={{ width: 160, height: 150 }}
                    />
                    <Text
                      style={{
                        color: Color.Gray,
                        fontSize: 13,
                        fontWeight: 'normal',
                        lineHeight: 23,
                        textAlign: 'center',
                        marginTop: 10,
                      }}>
                      定期的に発生する取引明細をまとめて{'\n'}
                      自動作成する設定ができます。（例：毎月の家賃）
                    </Text>
                    <AppButton
                      title="登録する"
                      style={{ width: '90%', marginVertical: 20 }}
                      textStyle={{ fontWeight: 'bold' }}
                      onPress={() => {
                        const params = {
                          userAccountType: this.userAccountType,
                        }
                        NavigationService.navigate(
                          'CreateTermTransaction',
                          params,
                        )
                      }}
                    />
                  </View>
                )
              }}
            />
            {this.state.isDisplayTermTransactionHelp && (
              <View
                style={{
                  marginTop: 40,
                  marginHorizontal: 10,
                  position: 'absolute',
                  alignSelf: 'center',
                  marginVertical: 15,
                  width: wp(100) - 30,
                }}>
                <FullWidthImage
                  source={require('@images/repeat-fukidasi.png')}
                />
                <View
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}>
                  <View style={{ width: '100%', alignItems: 'flex-end' }}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({ isDisplayTermTransactionHelp: false })
                      }}
                      style={{
                        padding: 10,
                        marginTop: 15,
                        marginRight: 4,
                      }}>
                      <Image
                        source={Buttons.TermTransaction.Dialog.Close as never}
                        style={{ width: 18, height: 18 }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            )}
            <AddCaregoryButton
              title="＋ 新しく追加する"
              edges={['top']}
              onPress={() => {
                if (TermTransactionManager.isNeedPremiumPlan()) {
                  this.setState({ isDisplayedPremiumPlanView: true })
                  TrackingUtils.repro.track(
                    '【Screen】upper limit_repeat',
                    'Screen',
                  )

                  TrackingUtils.ga.pageview({
                    page: 'Upperlimit-TermTransactionInput',
                    title: '繰り返し入力の上限タッチ画面',
                  })
                } else {
                  const params = {
                    userAccountType:
                      this.userAccountType === 'family' ? 'family' : 'user',
                  }
                  NavigationService.navigate('CreateTermTransaction', params)
                }
              }}
            />
          </View>
        </View>
        {this.state.isDisplayedPremiumPlanView ? (
          <>
            {Platform.OS !== 'web' ? (
              <PremiumView
                showTopMargin={false}
                title="無料プランの上限になりました"
                message={`※ 無料プランでは1アカウントにつき${'\n'}家族・個人画面で各1つまで作成できます。`}
                premiumType="term_transaction"
                onBackdropPress={() => {
                  this.setState({ isDisplayedPremiumPlanView: false })
                }}
              />
            ) : (
              <PremiumView
                showTopMargin={false}
                ignoreImageTouchEvent={true}
                onPressShowDetailButton={() => {
                  navigatePremiumPlanLP({ planCode: '001' })
                  this.setState({ isDisplayedPremiumPlanView: false })
                  TrackingUtils.repro.track('【Tap】upper limit_repeat', 'Tap')
                }}
                image={require('@images/premium/limit_term_transaction.png')}
                onBackdropPress={() => {
                  this.setState({ isDisplayedPremiumPlanView: false })
                }}
              />
            )}
          </>
        ) : (
          this.state.isPurchaseCompleted && (
            <PremiumView
              showTopMargin={false}
              buttonTitle="さっそくカスタマイズする"
              image={require('@images/premium/term_transaction_modal.png')}
              onPressShowDetailButton={() => {
                this.setState({ isPurchaseCompleted: false })
                sendSignal('CLOSED_PURCHASE_COMPLETE_MODAL', 'term_transaction')
              }}
            />
          )
        )}
      </>
    )
  }

  private renderItem: SectionListRenderItem<SectionItem> = ({ item }) => {
    if (typeof item === 'string') {
      return <NoDataListItem title={item} />
    } else {
      return <ListItem item={item} onPress={() => this.onPressItem(item)} />
    }
  }

  private sections = (): SectionListData<SectionItem>[] => {
    const termTransactions =
      this.userAccountType === 'family'
        ? this.props.familyTermTransactions
        : this.props.userTermTransactions
    if (!termTransactions) return []

    const income = termTransactions.filter(
      (v) => v.amount >= 0 && !v.transferId,
    )
    const expense = termTransactions.filter(
      (v) => v.amount < 0 && !v.transferId,
    )
    const transfer = termTransactions.filter((v) => v.transferId)

    return [
      {
        title: '収入',
        data: income.length > 0 ? income : ['収入はありません'],
      },
      {
        title: '支出',
        data: expense.length > 0 ? expense : ['支出はありません'],
      },
      {
        title: '振替',
        data: transfer.length > 0 ? transfer : ['振替はありません'],
      },
    ]
  }
}

const NoDataListItem = (props: { title: string }) => (
  <Text
    style={{
      paddingVertical: 25,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'normal',
      color: Color.Gray,
    }}>
    {props.title}
  </Text>
)

const ListItem: React.FC<
  { item: TermTransactionDetail } & TouchableOpacityProps
> = ({ item, onPress }) => {
  const termText = (() => {
    switch (item.term) {
      case 'yearly':
        return `毎年${+(item.termToYearly?.substring(0, 2) || '0')}月${+(
          item.termToYearly?.substring(2, 4) || '0'
        )}日`
      case 'monthly':
        return (
          '毎月' +
          ((item.termToMonthly || 0) > 0 ? `${item.termToMonthly}日` : '月末')
        )
      case 'weekly':
        return (
          '毎週' +
          {
            sunday: '日',
            monday: '月',
            tuesday: '火',
            wednesday: '水',
            thursday: '木',
            friday: '金',
            saturday: '土',
          }[item.termToWeekly!] +
          '曜日'
        )
      case 'daily':
        return '毎日'
    }
  })()

  return (
    <TouchableOpacity
      style={styles.listPaymentsItem}
      key={item.termTransactionId}
      onPress={onPress}>
      <View style={{ flexDirection: 'column', flex: 1 }}>
        <View style={styles.listPaymentsItemType}>
          <CategoryImage
            isTransfer={!!item.transferId}
            categoryId={item.atGroupedCategoryId}
          />
          <Text style={styles.listPaymentsItemTypeText}>
            {item.transferId ? '振替' : item.title}
          </Text>
        </View>
        <Text
          style={{
            marginTop: 5,
            marginLeft: 45,
            fontSize: 12,
            fontWeight: 'normal',
            color: Color.Gray,
          }}>
          {moment(item.startDate).format('YYYY/M/D')}
          {' 〜 '}
          {moment(item.endDate).format('YYYY/M/D')}
        </Text>
      </View>
      <View style={{ flexDirection: 'column' }}>
        <View style={styles.listPaymentsItemInfo}>
          <Text style={styles.listPaymentsItemInfoAmount}>
            {item.amount.jpCurrency()}
          </Text>
          <ArrowIcon style={styles.listPaymentsItemInfoIcon} />
        </View>
        <Text
          style={{
            marginTop: 5,
            fontSize: 12,
            fontWeight: 'normal',
            color: Color.Gray,
            marginRight: 35,
            textAlign: 'right',
          }}>
          {termText}
        </Text>
      </View>
      {item.isAvailable || (
        <View
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: '#ffffff80',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              backgroundColor: '#D1F0B7b5',
              color: Color.DefaultText,
              padding: 5,
            }}>
            上限数に達したため停止中です
          </Text>
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listPaymentsItem: {
    paddingTop: 15,
    paddingBottom: 10,
    flexDirection: 'row',
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
  },
  listPaymentsItemTypeText: {
    fontSize: 14,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
    marginLeft: 6,
  },
  listPaymentsItemInfo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
  },
  listPaymentsItemInfoAmount: {
    fontSize: 22,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    familyTermTransactions: state.termTransaction.familyTermTransactions,
    userTermTransactions: state.termTransaction.userTermTransactions,
  }
}

export default connect(mapStateToProps)(TermTransactionsContent)
