import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { PayoffManager } from '@lib/api/Payoff'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  BodyContainer,
  BottomContainer,
  DescriptionText,
  PercentText,
  RootContainer,
  ShareRatioCol,
  ShareRatioContainer,
  ShareRatioInput,
  ShareRatioInputContainer,
  ShareRatioPersonText,
  ShareRatioTitleText,
  TitleText,
} from './styles'

const SettingPayoffScreen: React.FC = () => {
  const [ownerSplitRatio, setOwnerSplitRatio] = useState('')
  const [partnerSplitRatio, setPartnerSplitRatio] = useState('')

  const getSettings = useCallback(async () => {
    try {
      const settings = (await PayoffManager.getSettings()).payoffSettings
      setOwnerSplitRatio(`${settings.owner.splitRatio || ''}`)
      setPartnerSplitRatio(`${settings.partner.splitRatio || ''}`)
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [])

  const updateSettings = useCallback(async () => {
    try {
      showAppButtonIndicator()
      await PayoffManager.updateSettings({
        owner: { splitRatio: +ownerSplitRatio },
        partner: { splitRatio: +partnerSplitRatio },
      })
      NavigationService.goBack()
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator()
    }
  }, [ownerSplitRatio, partnerSplitRatio])

  const onChangeOwnerSplitRatio = useCallback((value: string) => {
    const ownerValue = Math.min(+value.replace(/\D/, ''), 100)
    const partnerValue = 100 - ownerValue
    setOwnerSplitRatio(`${ownerValue}`)
    setPartnerSplitRatio(`${partnerValue}`)
  }, [])

  const onChangePartnerSplitRatio = useCallback((value: string) => {
    const partnerValue = Math.min(+value.replace(/\D/, ''), 100)
    const ownerValue = 100 - partnerValue
    setOwnerSplitRatio(`${ownerValue}`)
    setPartnerSplitRatio(`${partnerValue}`)
  }, [])

  const disabled = useMemo(() => !ownerSplitRatio && !partnerSplitRatio, [
    ownerSplitRatio,
    partnerSplitRatio,
  ])

  useEffect(() => {
    getSettings()
  }, [])

  return (
    <RootContainer>
      <CommonHeader title="分担ルール設定" />
      <BodyContainer>
        <TitleText>分担額のルール</TitleText>
        <DescriptionText>
          夫婦の分担ルールを設定できます。{'\n'}
          分担ルールはそれぞれが家族のために拠出した総額の内、何%を負担するかを取り決めます。
          {'\n'}
          ルールを設定すると、設定画面にてルールに合わせた拠出金額が自動計算されます。
        </DescriptionText>
        <ShareRatioContainer>
          <ShareRatioCol>
            <ShareRatioTitleText>分担割合</ShareRatioTitleText>
          </ShareRatioCol>
          <ShareRatioCol>
            <ShareRatioPersonText>
              {ProfileManager.getName('user')}
            </ShareRatioPersonText>
            <ShareRatioInputContainer>
              <ShareRatioInput
                keyboardType="decimal-pad"
                value={ownerSplitRatio}
                onChangeText={onChangeOwnerSplitRatio}
                editable={!disabled}
                selectTextOnFocus
              />
              <PercentText>%</PercentText>
            </ShareRatioInputContainer>
          </ShareRatioCol>
          <ShareRatioCol>
            <ShareRatioPersonText>
              {ProfileManager.getName('partner')}
            </ShareRatioPersonText>
            <ShareRatioInputContainer>
              <ShareRatioInput
                keyboardType="decimal-pad"
                value={partnerSplitRatio}
                onChangeText={onChangePartnerSplitRatio}
                editable={!disabled}
                selectTextOnFocus
              />
              <PercentText>%</PercentText>
            </ShareRatioInputContainer>
          </ShareRatioCol>
        </ShareRatioContainer>
        <BottomContainer>
          <AppButton
            title="保存する"
            onPress={updateSettings}
            disabled={disabled}
          />
        </BottomContainer>
      </BodyContainer>
    </RootContainer>
  )
}

export default SettingPayoffScreen
