import { UserAccountType } from '@interfaces/Account'
import store from '@redux/store'
import API, { APIResponse } from './index'

export interface ATTransactionCategory {
  atTransactionCategoryId: string
  atTransactionCategoryCategoryName1: string
  permission: number
  sort: number
  hide: boolean
  icon?: string
  color?: string
}

export interface AtGroupedCategory {
  atGroupedCategoryId: string | number
  atGroupedCategoryName: string
  sort: number
  hide: boolean
  permission: number
  icon?: string
  color?: string
  atTransactionCategories: ATTransactionCategory[]
}

export interface GetCategoriesResponse {
  app: {
    atGroupedCategories: AtGroupedCategory[]
  }
}

export interface GetCategoriesProps {
  accountType?: UserAccountType
}

export interface UpdateCategorySortProps {
  accountType: UserAccountType
  atGroupedCategories: {
    atGroupedCategoryId: string
    sort: number
    hide: boolean
  }[]
}

export interface UpdateTransactionCategorySortProps {
  accountType: UserAccountType
  categoryId: string
  atTransactionCategories?: {
    atTransactionCategoryId: string
    sort: number
    hide: boolean
  }[]
}

export interface AddCategoryProps {
  accountType: UserAccountType
  categoryId: string
  categoryName2: string
}

// export interface DeleteCategoryProps {
//   accountType: UserAccountType
//   categoryId: string
//   transactionCategoryId: string
// }

class Category {
  addCategory = async (props: AddCategoryProps) => {
    const accountType = (
      props.accountType || store.getState().account.accountMode
    ).userAccountPath()

    return API.request(
      'POST',
      `/api/v1/${accountType}/categories/${props.categoryId}/transaction-categories`,
      { categoryName2: props.categoryName2 },
      true
    )
  }

  // deleteCategory = async (props: DeleteCategoryProps) => {
  //   const accountType = (
  //     props.accountType || store.getState().account.accountMode
  //   ).userAccountPath()
  //   return API.request(
  //     'DELETE',
  //     `/api/v1/${accountType}/categories/${props.categoryId}/transaction-categories/${props.transactionCategoryId}`,
  //     {},
  //     true
  //   )
  // }

  getCategories = async (
    props: GetCategoriesProps = {}
  ): Promise<APIResponse> => {
    const accountType =
      props.accountType || store.getState().account.accountMode
    return API.request(
      'GET',
      `/api/v1/${accountType.userAccountPath()}/categories`,
      {},
      true
    )
  }

  updateSort = async (props: UpdateCategorySortProps): Promise<APIResponse> => {
    const { accountType, atGroupedCategories } = props
    return API.request(
      'PUT',
      `/api/v1/${accountType.userAccountPath()}/categories/settings`,
      { atGroupedCategories },
      true
    )
  }

  updateTransactionSort = async (
    props: UpdateTransactionCategorySortProps
  ): Promise<APIResponse> => {
    const { accountType, categoryId, atTransactionCategories } = props
    return API.request(
      'PUT',
      `/api/v1/${accountType.userAccountPath()}/categories/${categoryId}/settings`,
      { atTransactionCategories },
      true
    )
  }
}

export default new Category()
