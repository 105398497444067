import { useIsFocused } from '@react-navigation/native'
import { useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'

// react-navigation Tab.Navigator バグ回避

export const useTabNavigatorBugWorkaround = () => {
  const isFocused = useIsFocused()

  const [refresh, setRefresh] = useState(false)
  const needRefresh = useRef<boolean>(false)

  useEffect(() => {
    if (Platform.OS !== 'web') return

    if (isFocused) {
      if (needRefresh.current) {
        needRefresh.current = false
        setTimeout(() => setRefresh(!refresh), 100)
      }
    } else {
      needRefresh.current = true
    }
  }, [isFocused, refresh])

  return needRefresh.current
}
