import API, { APIError } from '@lib/api'

export type GetWebTokenResponse = {
  app: {
    webToken: string
    webTokenExpiresAt: string
  }
}

export const getWebToken = async () => {
  const response = await API.request('GET', '/api/v2/auth/web-token', {}, true)
  if (!response.ok) throw new APIError(response)
  return (response.json as GetWebTokenResponse).app.webToken
}
