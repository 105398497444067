import SessionManager from '@lib/SessionManager'
import {
  DrawerActions,
  NavigationAction,
  NavigationContainerRef,
  StackActions,
} from '@react-navigation/native'
import { clearAllState, updateRootScreen } from '@redux/actions'
import store from '@redux/store'
import { useEffect } from 'react'
import { logging } from '../Firestore'
import { ScreenName } from '../navigation/Screens'
import { PermissionManager } from './Permission'

const navigatableScreens = new Set<ScreenName>()

class NavigationService {
  private navigation!: NavigationContainerRef

  isKeepDisplayDrawer = false

  setNavigation = (navigation: NavigationContainerRef) => {
    this.navigation = navigation
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate = (routeName: any, params?: any, key?: string) => {
    // this.navigation.navigate(routeName, params)
    this.navigation.navigate({
      name: routeName,
      key,
      params,
    })
  }

  dispatch = (action: NavigationAction) => {
    this.navigation.dispatch(action)
  }

  // goBack = () => this.navigation.goBack()

  goBack = (name?: string, params?: any) => {
    name
      ? this.navigation.navigate(name, params)
      : this.navigation.canGoBack() && this.navigation.goBack()
  }

  push = (routeName: ScreenName, params?: any) => {
    this.navigation.dispatch(StackActions.push(routeName, params))
  }

  popToTop = () =>
    this.navigation.canGoBack() &&
    this.navigation.dispatch(StackActions.popToTop())

  pop = (n = 1) => this.navigation.dispatch(StackActions.pop(n))

  toggleDrawer = () => this.navigation.dispatch(DrawerActions.toggleDrawer())

  openDrawer = () => this.navigation.dispatch(DrawerActions.openDrawer())

  closeDrawer = () => this.navigation.dispatch(DrawerActions.closeDrawer())

  logout = () => {
    SessionManager.setLogoutUserId(
      store.getState().profile?.userProfile?.userId,
    )
    logging('ログアウト')

    SessionManager.inalidateAccessToken()
    SessionManager.setDefaultFamilyWalletId(undefined)
    SessionManager.setDefaultPersonalWalletId(undefined)
    store.dispatch(clearAllState())
    store.dispatch(updateRootScreen('Account'))
    SessionManager.setIsLockScreen(false)
    PermissionManager.setIDFA()
  }

  addNavigatableScreen = (screenName: ScreenName) => {
    navigatableScreens.add(screenName)
  }

  removeNavigatableScreen = (screenName: ScreenName) => {
    navigatableScreens.delete(screenName)
  }
}

const navigationService = new NavigationService()
export default navigationService

export const useNavigatableScreen = (screenName: ScreenName) => {
  useEffect(() => {
    navigatableScreens.add(screenName)

    return () => {
      navigatableScreens.delete(screenName)
    }
  }, [screenName])
}

export const navigateToNavigatableScreen = (
  screens: { name: ScreenName; params?: unknown }[],
) => {
  while (screens.length > 0) {
    const screen = screens.shift()
    if (
      screen &&
      (screens.length === 0 || navigatableScreens.has(screen.name))
    ) {
      navigationService.navigate(screen.name, screen.params)
    }
  }
}
