import Color from '@lib/Color'
import { NativeApp } from '@lib/Env'
import { ImageRequireSource, Pressable, ScrollView } from 'react-native'
import styled from 'styled-components/native'
import AppButton from './AppButton'
import FullWidthImage from './FullWidthImage'

export type SimplePremiumViewProps = {
  title?: string
  message?: string
  image: ImageRequireSource
  buttonTitle?: string
  onPressShowDetailButton: () => void
  onDissmiss?: () => void
}

const SimplePremiumView = ({
  title,
  message,
  image,
  buttonTitle,
  onPressShowDetailButton,
  onDissmiss,
}: SimplePremiumViewProps) => {
  return (
    <StyledPlanErrorView onPress={onDissmiss}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
        <PremiumContainerView>
          {title && <PremiumTitleText>{title}</PremiumTitleText>}
          {message && <PremiumText>{message}</PremiumText>}
          <StyledCardView>
            <FullWidthImage source={image} />
            <StyledAppButton
              title={buttonTitle || '詳しく見る'}
              onPress={onPressShowDetailButton}
            />
          </StyledCardView>
        </PremiumContainerView>
      </ScrollView>
    </StyledPlanErrorView>
  )
}

export const StyledPlanErrorView = styled(Pressable)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  background-color: #000000a0;
`

const StyledAppButton = styled(AppButton).attrs({
  textStyle: { fontSize: 18, fontWeight: 'bold' },
})({
  backgroundColor: Color.Primary,
  width: NativeApp ? '90%' : '80%',
  height: 48,
  position: 'absolute',
  bottom: wp(5),
  alignSelf: 'center',
})

export const PremiumContainerView = styled.View`
  /* padding-bottom: 20px; */
  padding: 0 ${wp(5)}px;
  /* padding-horizontal: 30px; */
`

export const PremiumTitleText = styled.Text<{ monthlyReport?: boolean }>`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
`

export const PremiumText = styled.Text<{ monthlyReport?: boolean }>`
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 16px;
  /* font-weight: bold; */
  color: white;
  /* line-height: 18px; */
`

const StyledCardView = styled.View({
  backgroundColor: 'white',
  padding: 20,
  paddingBottom: 90,
  boderRaduis: 5,
  overflow: 'hidden',
})

export default SimplePremiumView
