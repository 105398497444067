import { APIError } from '@lib/api'
import { getMonthlyReportCSV, getTransactionsCSV } from './api'
import {
  DownloadMonthlyReportCSVProps,
  DownloadTransactionsCSVProps,
} from './types'

export const downloadTransactionsCSV = async ({
  from,
  to,
  share,
}: DownloadTransactionsCSVProps) => {
  const response = await getTransactionsCSV({
    from,
    to,
    share,
  })
  if (!response.ok) throw new APIError(response)

  return response
}

export const downloadMonthlyReportCSV = async ({
  from,
  to,
  share,
  plSettingUse,
}: DownloadMonthlyReportCSVProps) => {
  const response = await getMonthlyReportCSV({
    from,
    to,
    share,
    plSettingUse,
  })
  if (!response.ok) throw new APIError(response)

  return response
}
