import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import AssetTransitionScreen from '../Home/FinancialAccounts/AssetTransitionScreen'
import { selectYearMonth } from './slice'
import { StyledCommonPanel } from './styles'

const StyledPanel = styled(StyledCommonPanel)`
  padding-top: ${wp(7)}px;
  /* padding-bottom: ${wp(7)}px; */
  padding-horizontal: ${wp(3)}px;
`

const AssetsPanel = () => {
  const yearMonth = useSelector(selectYearMonth)

  return (
    <StyledPanel>
      <AssetTransitionScreen monthlyReport yearMonth={yearMonth} />
      {/* <FullWidthImage source={require('@images/s1.png')} /> */}
    </StyledPanel>
  )
}

export default AssetsPanel
