import { GoalEntity } from '@interfaces/Goal'
import Color from '@lib/Color'
import { ViewProps } from 'react-native'
import styled from 'styled-components/native'

export type GoalCurrentAmountViewProps = ViewProps & {
  goal: GoalEntity
}

const GoalCurrentAmountView = ({
  goal,
  ...props
}: GoalCurrentAmountViewProps) => {
  const goalAmount = goal.goalAmount ?? 0
  const remainGoalAmount = goal.goalAmount - goal.currentAmount

  return (
    <StyledRootView {...props}>
      <StyledRow>
        <StyledCurrentAmountTitle>現在の貯金額</StyledCurrentAmountTitle>
        <StyledCurrentAmountText color={Color.goal(goal.currentProgressIcon)}>
          {goal.currentAmount.jpCurrency()}
        </StyledCurrentAmountText>
      </StyledRow>
      <StyledRow>
        <StyledItemTitle>目標金額</StyledItemTitle>
        <StyledItemAmount>
          {goalAmount ? goalAmount.jpCurrency() : 'ー'}
        </StyledItemAmount>
      </StyledRow>
      <StyledRow>
        <StyledItemTitle>達成まであと</StyledItemTitle>
        <StyledItemAmount>
          {goalAmount
            ? (remainGoalAmount > 0 ? remainGoalAmount : 0).jpCurrency()
            : 'ー'}
        </StyledItemAmount>
      </StyledRow>
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  marginTop: 15,
})

const StyledRow = styled.View({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 10,
})

const StyledCurrentAmountTitle = styled.Text({
  color: '#666666',
  fontSize: 14,
  fontWeight: 'bold',
})

const StyledCurrentAmountText = styled.Text<{ color: string }>(({ color }) => ({
  fontSize: 22,
  fontWeight: 'normal',
  color,
}))

const StyledItemTitle = styled.Text({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#999',
})

const StyledItemAmount = styled.Text({
  fontSize: 18,
  fontWeight: 'normal',
  color: Color.Black,
})

export default GoalCurrentAmountView
