import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TransactionItem from '@components/TransactionItem'
import { FinancialAccountType, Transaction } from '@interfaces/Financial'
import { AccountState } from '@lib/AccountManager/types'
import { BudgetReduxState } from '@lib/Budget/types'
import CommonDialog from '@lib/CommonDialog'
import { getYearlyDateRange, parseMonthKey } from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import DateUtilsV1 from '@lib/DateUtilsV1'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import {
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  Text,
  View,
} from 'react-native'
import Toast from 'react-native-simple-toast'
import { connect } from 'react-redux'
import { getDateRange } from '../../../lib/DateUtils'
import { TransactionDetailScreenNavigationParams } from '../TransactionDetailScreen'
import BudgetCategoryItem from './Budget/BudgetCategoryItem'

interface Props {}

interface StateProps {
  groupedTransactions?: Transaction[]
  transactions?: Transaction[]
  account: AccountState
  financial: FinancialState
  budget: BudgetReduxState
}

interface State {
  refreshing: boolean
  sections: SectionListData<Transaction>[]
}

class BudgetCategoryTransactionsScreen extends React.Component<
  Props &
    StateProps &
    StackScreenProps<RootStackParamList, 'BudgetCategoryTransactions'>,
  State
> {
  state: State = {
    refreshing: false,
    sections: [],
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.fetchBalance()
      this.fetchTransactions()
    })
  }

  componentWillUnmount() {
    store.dispatch({
      type: 'UpdateGroupedTransactions',
      undefined,
    })
  }

  componentDidUpdate(
    prevProps: Props &
      StateProps &
      StackScreenProps<RootStackParamList, 'BudgetCategoryTransactions'>,
  ) {
    const { account } = this.props
    if (
      prevProps.account.isFamilyShareMode !== account.isFamilyShareMode ||
      prevProps.account.accountMode !== account.accountMode
    ) {
      this.fetchBalance()
      this.fetchTransactions()
    }
  }

  private fetchBalance = async () => {
    const { date } = this.props.route.params

    await FinancialManager.fetchBalance(
      this.props.account.accountMode,
      this.props.account.isFamilyShareMode,
      date,
      this.props.financial.homeExpensesIsYearly,
    )
  }

  private dateRange = () => {
    const { year, month } = parseMonthKey(this.props.route.params.date)
    const settings = getStartDateSettings()

    return this.props.financial.homeExpensesIsYearly
      ? getYearlyDateRange(
          year,
          settings.start,
          settings.startDateSettingOption,
        )
      : getDateRange(
          year,
          month,
          settings.start,
          settings.startDateSettingOption,
        )
  }

  private fetchTransactions = async () => {
    const { categoryId } = this.props.route.params

    const { startDate, endDate } = this.dateRange()

    await FinancialManager.fetchGroupedTransactions({
      userAccountType: this.props.account.accountMode,
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      atGroupedCategoryId: categoryId,
      share: this.props.account.isFamilyShareMode,
      transfer: 'pl',
    })

    this.updateSections()
  }

  private updateSections = () => {
    const transactions: { [date: string]: Transaction[] } = {}

    const sections: SectionListData<Transaction>[] = []

    this.props.groupedTransactions?.forEach((transaction: Transaction) => {
      const date = moment(transaction.usedDate).format('YYYYMMDD')
      if (transactions[date]) {
        transactions[date].push(transaction)
      } else {
        transactions[date] = [transaction]
      }
    })

    const today = moment().format('YYYYMMDD')

    Object.keys(transactions)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: transactions[date],
        })
      })

    this.setState({ sections })
  }

  private onPressItem = (item: Transaction) => {
    NavigationService.navigate('TransactionDetail', {
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId:
        item.atUserBankAccountId ||
        item.atUserCardAccountId ||
        item.atUserEmoneyServiceAccountId ||
        item.walletId,
      transactionId: item.transactionId,
    } as TransactionDetailScreenNavigationParams)
  }

  private onPressMemoButton = (item: Transaction) => {
    NavigationService.navigate('TransactionDetail', {
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId:
        item.atUserBankAccountId ||
        item.atUserCardAccountId ||
        item.atUserEmoneyServiceAccountId ||
        item.walletId,
      transactionId: item.transactionId,
      focusMemo: true,
    } as TransactionDetailScreenNavigationParams)
  }

  private renderItem: SectionListRenderItem<Transaction> = ({ item }) => {
    return (
      <TransactionItem
        transaction={item}
        onPress={() => this.onPressItem(item)}
        onPressMemoButton={() => this.onPressMemoButton(item)}
        onSwipeableOpen={
          item.type !== 'carry_forward' ? this.onSwipeableOpenItem : undefined
        }
        onPressAddPaylistButton={() => {
          this.fetchBalance()
          this.fetchTransactions()
        }}
      />
    )
  }

  private renderListHeader = () => {
    const { categoryId, displayType } = this.props.route.params
    const { startDate, endDate } = this.dateRange()
    const isYearly = this.props.financial.homeExpensesIsYearly

    const accountMode = this.props.account.accountMode

    const yyyymm = DateUtilsV1.monthKey(startDate)
    const budget =
      this.props.budget[accountMode][isYearly ? yyyymm.substring(0, 4) : yyyymm]
    const categoryBudget = budget?.atGroupedCategoryBudgets.find(
      (v) => v.atGroupedCategoryId === categoryId,
    )

    const expenseAmount =
      this.props.financial[accountMode].monthsPL[
        isYearly ? yyyymm.substring(0, 4) : yyyymm
      ]?.expenseList.find((v) => v.categoryId === categoryId)?.amount || 0

    const prevMonth = isYearly
      ? moment(startDate).subtract(1, 'year')
      : moment(startDate).subtract(10, 'day')
    const prevMonthYYYYMM = DateUtilsV1.monthKey(prevMonth)
    const prevMonthExpenseAmount =
      this.props.financial[accountMode].monthsPL[
        isYearly ? prevMonthYYYYMM.substring(0, 4) : prevMonthYYYYMM
      ]?.expenseList.find((v) => v.categoryId === categoryId)?.amount || 0

    return (
      <View
        style={{
          alignItems: 'center',
          paddingTop: 10,
          paddingBottom: 15,
        }}>
        {isYearly ? (
          <Text
            style={{
              padding: 2,
              fontSize: 16,
              fontWeight: 'normal',
              textAlign: 'center',
            }}
            numberOfLines={2}>
            {startDate?.format('YYYY年')}
            <Text
              style={{
                fontSize: 10,
                fontWeight: 'normal',
              }}>
              {'\n'}
              {startDate?.format('YYYY/M/D')} ~ {endDate?.format('YYYY/M/D')}
            </Text>
          </Text>
        ) : (
          <Text style={{ fontSize: 16, fontWeight: 'normal' }}>
            {`${startDate.format('YYYY/M/D')} ~ ${endDate.format('YYYY/M/D')}`}
          </Text>
        )}
        <BudgetCategoryItem
          categoryId={categoryId}
          categoryName={categoryBudget?.categoryName1}
          expenseAmount={expenseAmount}
          prevExpenseAmount={prevMonthExpenseAmount}
          budgetAmount={categoryBudget?.amount || 0}
          displayType={displayType}
          categoryTextStyle={{ fontWeight: 'bold' }}
          isYearly={isYearly}
          style={{ width: '100%' }}
        />
      </View>
    )
  }

  private onSwipeableOpenItem = async (item: Transaction) => {
    const newItem = { ...item }
    newItem.isTransactionShared = true

    const financialAccountType: FinancialAccountType = (() => {
      if (item.atUserBankAccountId) return 'bank'
      if (item.atUserCardAccountId) return 'card'
      if (item.atUserEmoneyServiceAccountId) return 'emoney'
      if (item.atUserEmoneyAccountId) return 'emoney'
      if (item.walletId) return 'wallet'
      return 'manually_created'
    })()

    const userAccountType = item.isAccountShared ? 'family' : 'user'
    const share = !item.isTransactionShared

    try {
      await FinancialManager.updateTransaction({
        userAccountType,
        financialAccountType,
        accountId: item.accountId,
        transactionId: item.transactionId,
        transactionShare: share,
        amount: item.amount,
        paymentMethodId: item.walletId ?? undefined,
        paymentMethodType: item.walletId ? 'wallet' : undefined,
        ignore: item.isIgnored,
        memo: item.memo !== null ? item.memo : undefined,
        type: item.amount >= 0 ? 'receipt' : 'payment',
        action: 'swipe',
      })
    } catch (error) {
      CommonDialog.showError({ error })
      return
    }
    if (Platform.OS !== 'web') {
      Toast.showWithGravity('明細を振り分けました', Toast.SHORT, Toast.BOTTOM)
    }

    this.fetchBalance()
    this.fetchTransactions()
  }

  private refreshControl = (
    <RefreshControl
      refreshing={this.state.refreshing}
      onRefresh={async () => {
        this.setState({ refreshing: true })
        await this.fetchBalance()
        await this.fetchTransactions()
        this.setState({ refreshing: false })
      }}
    />
  )

  render() {
    const yyyymm = DateUtilsV1.monthKey(moment(this.props.route.params.date))
    const budget =
      this.props.budget[this.props.account.accountMode][
        this.props.financial.homeExpensesIsYearly
          ? yyyymm.substring(0, 4)
          : yyyymm
      ]
    const categoryBudget = budget?.atGroupedCategoryBudgets.find(
      (v) => v.atGroupedCategoryId === this.props.route.params.categoryId,
    )

    return (
      <View style={{ flex: 1 }}>
        <CommonHeader title={categoryBudget?.categoryName1} />
        <View style={{ flex: 1 }}>
          <SectionList
            sections={this.state.sections}
            renderItem={this.renderItem}
            renderSectionHeader={({ section: { title } }) => (
              <SectionListHeader
                title={title}
                style={{ fontWeight: 'normal' }}
              />
            )}
            keyExtractor={(item: Transaction) =>
              [
                item.type,
                item.transactionId,
                item.isAccountShared,
                item.isTransactionShared,
              ].join('-')
            }
            ListHeaderComponent={this.renderListHeader}
            refreshControl={this.refreshControl}
            ItemSeparatorComponent={() => ItemSeparator}
          />
        </View>
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => ({
  groupedTransactions: state.financial.groupedTransactions,
  transactions: state.financial.transactions,
  account: state.account,
  financial: state.financial,
  budget: state.budget,
})

export default connect(mapStateToProps)(BudgetCategoryTransactionsScreen)
