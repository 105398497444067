import { userAccountTypeSelector } from '@Screen/Account/selector'
import CoupleButton from '@components/CoupleButton'
import { PremiumView } from '@components/PremiumView'
import SectionListHeader2 from '@components/SectionListHeader2'
import { UserAccountType } from '@interfaces/Account'
import { GoalEntity } from '@interfaces/Goal'
import Color from '@lib/Color'
import {
  default as NavigationService,
  default as navigationService,
} from '@lib/NavigationService'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import { profileSelector } from '@lib/ProfileManager/selector'
import { GoalManager } from '@lib/api/Goal'
import { selectGoals } from '@lib/api/Goal/slice'
import { useIsFocused } from '@react-navigation/core'
import store from '@redux/store'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Platform,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { AddGoalButton } from './AddGoalButton'
import GoalChart from './GoalChart'
import GoalListItem from './GoalListItem'

const GoalListScreen = () => {
  const isFocused = useIsFocused()
  const userAccountType = useSelector(userAccountTypeSelector)
  const goals = useSelector(selectGoals)
  const profile = useSelector(profileSelector)

  const [tabUserAccountType, setTabUserAccountType] = useState<UserAccountType>(
    store.getState().account.accountMode,
  )
  const [isVisiblePremiumModal, setIsVisiblePremiumModal] = useState(false)

  useEffect(() => {
    if (isFocused) {
      GoalManager.fetchGoals({ share: tabUserAccountType === 'family' })
    }
  }, [isFocused, tabUserAccountType, userAccountType])

  const sessions = useMemo(() => {
    const noCompletedGoals =
      goals[tabUserAccountType]?.filter((v) => !v.completed) ?? []
    const completedGoals =
      goals[tabUserAccountType]?.filter((v) => v.completed) ?? []

    const sections: SectionListData<GoalEntity>[] = [
      {
        title: '',
        data: noCompletedGoals,
      },
    ]

    if (completedGoals.length > 0) {
      sections.push({
        title: '完了済み目標貯金',
        data: completedGoals,
      })
    }

    return sections
  }, [goals, tabUserAccountType])

  const isPremiumError = useMemo(
    () => tabUserAccountType === 'user' && profile.userProfile?.rank === 'free',
    [profile.userProfile?.rank, tabUserAccountType],
  )

  const renderItem: SectionListRenderItem<GoalEntity> = useCallback(
    ({ item, index }) => {
      return (
        <StyledGoalListItem
          goal={item}
          borderTopVisible={index === 0 && !item.completed}
          isPremiumError={isPremiumError}
          onPress={() => {
            if (isPremiumError) {
              setIsVisiblePremiumModal(true)
              return
            }

            NavigationService.navigate('GoalDetail', { goalId: item.goalId })
          }}
        />
      )
    },
    [isPremiumError],
  )

  const listHeader = useCallback(() => {
    return (
      <>
        <StyledTabView>
          <CoupleButton
            buttonType="couple"
            onPress={() => setTabUserAccountType('family')}
            isOn={tabUserAccountType === 'family'}
            title="家族目標"
            disabled={tabUserAccountType === 'family'}
            style={{
              height: 25,
              width: Platform.OS !== 'web' ? '46%' : wp(46),
            }}
          />
          <CoupleButton
            buttonType="single"
            onPress={() => setTabUserAccountType('user')}
            isOn={tabUserAccountType === 'user'}
            title="個人目標"
            disabled={tabUserAccountType === 'user'}
            style={{
              height: 25,
              width: Platform.OS !== 'web' ? '46%' : wp(46),
            }}
          />
        </StyledTabView>
        <StyledGoalChart userAccountType={tabUserAccountType} />
      </>
    )
  }, [tabUserAccountType])

  useEffect(
    () =>
      signalOn('TAPPED_NAVIGATION_LEFT_BUTTON', () => {
        if (isFocused) {
          navigationService.goBack()
        }
      }),
    [isFocused],
  )

  const [webHack, setWebHack] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setWebHack(true)
      console.log('webHack')
    }, 1000)
  }, [])

  if (!webHack) return null

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        {sessions && (
          <StyledSectionList
            sections={sessions}
            renderSectionHeader={({ section: { title } }) =>
              title ? (
                <SectionListHeader2
                  title={title}
                  textStyle={{ fontWeight: 'normal' }}
                  style={{ marginBottom: 5 }}
                />
              ) : null
            }
            ListHeaderComponent={listHeader}
            renderItem={renderItem}
            ListFooterComponent={() => <View style={{ height: 24 }} />}
          />
        )}
      </View>
      <AddGoalButton
        onPress={() => {
          NavigationService.navigate('GoalSelectType', {
            userAccountType: tabUserAccountType,
          })
        }}
      />
      {isVisiblePremiumModal && (
        <PremiumView
          premiumType="goal"
          title="プレミアムプランの機能です"
          message="個人の目標貯金はプレミアムプランにてご利用いただけます。"
          buttonTitle="さっそく作成する"
          showTopMargin={false}
          onPressShowDetailButton={() => {
            sendSignal('CLOSED_PURCHASE_COMPLETE_MODAL', 'goal')
            setIsVisiblePremiumModal(false)
          }}
          onBackdropPress={() => setIsVisiblePremiumModal(false)}
        />
      )}
    </View>
  )
}

const StyledTabView = styled.View({
  marginTop: 5,
  marginHorizontal: 15,
  marginBottom: 10,
  flexDirection: 'row',
  backgroundColor: '#f0f0f0',
  alignSelf: 'center',
  borderRadius: 16,
  justifyContent: 'space-between',
})

const StyledSectionList = styled(SectionList<GoalEntity>).attrs({
  contentContainerStyle: {
    paddingTop: 15,
    paddingBottom: 50,
  },
})({})

const StyledGoalListItem = styled(GoalListItem)({
  paddingHorizontal: 15,
})

const StyledGoalChart = styled(GoalChart)({
  paddingHorizontal: 15,
  paddingBottom: 15,
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  listPaymentsItem: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 15,
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listPaymentsItemTypeIcon: {
    marginRight: 6,
    width: 28,
    height: 28,
  },
  listPaymentsItemTypeText: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'normal',
    color: Color.TextBlack,
  },
  listPaymentsItemInfo: {
    flex: -1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
  },
  listPaymentsItemInfoPercent: {
    width: 42,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'right',
    color: Color.Gray,
  },
  listPaymentsItemInfoAmount: {
    flex: -1,
    fontSize: 18,
    fontWeight: 'normal',
    color: Color.NumberBlack,
  },
  listPaymentsItemInfoAmount2: {
    fontSize: 18,
    fontWeight: 'normal',
    color: Color.NumberBlack,
  },
})

export default GoalListScreen
