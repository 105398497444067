import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { accountModeSelector } from '@lib/AccountManager/actions'
import { useTabNavigatorWebHack } from '@lib/web/tab-navigator-web-hack'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React from 'react'
import { useSelector } from 'react-redux'
import BudgetSettingContentScreen from './BudgetSettingContentScreen'
import { RootContainer } from './styles'

const Tab = createMaterialTopTabNavigator()

const BudgetSettingScreen: React.FC = () => {
  const userAccountType = useSelector(accountModeSelector)

  useTabNavigatorWebHack(
    userAccountType === 'family' ? undefined : 'BudgetSettingUser',
  )

  return (
    <RootContainer>
      <CommonHeader title="予算の設定" />
      <Tab.Navigator
        backBehavior="none"
        tabBarOptions={TabBarOptions}
        initialRouteName={
          userAccountType === 'family'
            ? 'BudgetSettingFamily'
            : 'BudgetSettingUser'
        }>
        <Tab.Screen
          name="BudgetSettingFamily"
          component={BudgetSettingContentScreen}
          initialParams={{ userAccountType: 'family' }}
          options={{
            title: '家族画面',
          }}
        />
        <Tab.Screen
          name="BudgetSettingUser"
          component={BudgetSettingContentScreen}
          initialParams={{ userAccountType: 'user' }}
          options={{
            title: '個人画面',
          }}
        />
      </Tab.Navigator>
    </RootContainer>
  )
}

export default BudgetSettingScreen
