import TintedImage from '@components/TintedImage'
import { Gender } from '@interfaces/Gender'
import * as Icon from '@lib/Icon'
import styled from 'styled-components/native'

export type FaceIconProps = {
  faceType: Gender | Gender[]
  color?: string
}

export type FaceImageProps = {
  size?: number
  color?: string
}

const RootContainer = styled.View`
  flex-direction: row;
`

const FaceImage = styled(TintedImage)<FaceImageProps>`
  width: ${({ size = 22 }) => size}px;
  height: ${({ size = 22 }) => size}px;
  tint-color: ${({ color = '#888888' }) => color};
  resize-mode: contain;
`

const NextFaceImage = styled(TintedImage)<FaceImageProps>`
  margin-left: ${({ size = 22 }) => -(size / 4)}px;
  width: ${({ size = 22 }) => size}px;
  height: ${({ size = 22 }) => size}px;
  tint-color: ${({ color = '#888888' }) => color};
  resize-mode: contain;
`

const FaceIcon = ({ faceType, color }: FaceIconProps) => {
  if (Array.isArray(faceType)) {
    return (
      <RootContainer>
        {faceType.map((faceType, index) => {
          const imageSource =
            faceType === Gender.Male ? Icon.FaceIcon.Men : Icon.FaceIcon.Women
          return index === 0 ? (
            <FaceImage source={imageSource} color={color} key={index} />
          ) : (
            <NextFaceImage source={imageSource} color={color} key={index} />
          )
        })}
      </RootContainer>
    )
  } else {
    const imageSource =
      faceType === Gender.Male ? Icon.FaceIcon.Men : Icon.FaceIcon.Women
    return (
      <RootContainer>
        <FaceImage source={imageSource} />
      </RootContainer>
    )
  }
}

export default FaceIcon
