import Log from '@lib/Log'
import { PropsWithChildren, useCallback, useState } from 'react'
import { View } from 'react-native'
import { OverlayView } from './OverlayView'
import SpotlightTourContext from './SpotlightTourContext'
import { Spot, Spots, StartSpotlightTourOptions, Tour } from './types'

const DefualtOverlayColor = 'rgba(0, 0, 0, 0.3)'

export const SpotlightTourProvider = ({ children }: PropsWithChildren) => {
  const [spots, setSpots] = useState<Spots>({})
  const [tour, setTour] = useState<Tour>([])
  const [step, setStep] = useState<number>(0)
  const [onPressSuperView, setOnPressSuperView] =
    useState<(step: number) => void>()
  const [before, setBefore] = useState<(step: number) => void>()
  const [after, setAfter] = useState<(step: number) => void>()
  const [overlayColor, setOverlayColor] = useState<string>(DefualtOverlayColor)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [waitingSpotMeasures, setWaitingSpotMeasures] = useState<number>(0)

  const startSpotlightTour = useCallback(
    (
      tour: Tour,
      {
        step = 1,
        onPressSuperView,
        before,
        after,
        overlayColor: initialOverlayColor,
      }: StartSpotlightTourOptions = {},
    ) => {
      Log.info('startSpotlightTour', { tour, step, onPressSuperView })
      setOnPressSuperView(() => onPressSuperView)
      setBefore(() => before)
      setAfter(() => after)
      setOverlayColor(initialOverlayColor ?? DefualtOverlayColor)
      setDisabled(false)
      setWaitingSpotMeasures(0)
      setTour(tour)
      setStep(step)
    },
    [],
  )

  const setSpot = useCallback((spot: Spot) => {
    setSpots((prev) => ({ ...prev, [spot.spotId]: spot }))
    // Log.info('setSpot')
  }, [])

  const setSpotPosition = useCallback(
    (spotId: string, x: number, y: number, width: number, height: number) => {
      setSpots((prev) => {
        const spot = prev[spotId]
        if (
          !spot ||
          (spot.x === x &&
            spot.y !== y &&
            spot.width !== width &&
            spot.height !== height)
        ) {
          return prev
        }

        return {
          ...prev,
          [spotId]: { ...prev[spotId], x, y, width, height },
        }
      })

      setWaitingSpotMeasures((prev) => prev - 1)
    },
    [],
  )

  const nextStep = useCallback(() => {
    setStep((prev) => prev + 1)
  }, [])

  const previousStep = useCallback(() => {
    setStep((prev) => prev - 1)
  }, [])

  const finishSpotlightTour = useCallback(() => {
    setStep(0)
  }, [])

  return (
    <SpotlightTourContext.Provider
      value={{
        spots,
        setSpot,
        setSpotPosition,
        tour,
        setTour,
        step,
        setStep,
        startSpotlightTour,
        nextStep,
        previousStep,
        finishSpotlightTour,
        onPressSuperView,
        setOnPressSuperView,
        before,
        after,
        overlayColor,
        setOverlayColor,
        disabled,
        setDisabled,
        waitingSpotMeasures,
        setWaitingSpotMeasures,
      }}>
      <View
        style={{ flex: 1, backgroundColor: '#00000000' }}
        pointerEvents="box-none">
        {children}
        <OverlayView />
      </View>
    </SpotlightTourContext.Provider>
  )
}
