import { UserAPI } from '@lib/api/User'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import Osidori from '@lib/Osidori'
import { APIError } from '../api/index'

export interface ChangeEmailParams {
  token: string
}

export const changeEmail = async (params: ChangeEmailParams) => {
  try {
    const response = await UserAPI.confirmChangeEmail({
      users: { token: params.token },
    })
    if (response.ok) {
      CommonDialog.show(
        '変更完了',
        'ログイン情報の変更が完了しました。',
        () => {
          NavigationService.logout()
        },
      )
    } else {
      throw new APIError(response)
    }
  } catch (error) {
    CommonDialog.showError({
      error,
      onPress: () => Osidori.start(),
    })
  }
}
