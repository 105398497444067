import Color from '@lib/Color'
import GlobalFontSizes from '@lib/GlobalFontSizes'
import { StyleSheet } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  flex: 1;
  width: 100%;
`

export const StyledScrollView = styled.ScrollView`
  flex: 1;
`

export const TransactionsContainer = styled.View`
  background-color: #666666;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TransactionDateText = styled.Text`
  color: white;
`

export const AddTransactionButtonView = styled.View`
  align-items: center;
`

export const PremiumContainerView = styled.View`
  padding-bottom: 20px;
  flex: 1;
  padding-horizontal: 20px;
  justify-content: center;
`

export const PremiumText = styled.Text`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
`

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  thisMonthsBalanceView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  wrapMonthlyBalanceViewLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  balanceTime: {
    color: Color.Gray,
    fontSize: 15,
    marginLeft: 8,
  },
  titleTotalAssets: {
    color: '#555555',
    fontSize: 15,
    fontWeight: 'bold',
  },
  periodSection: {
    minHeight: 45,
    maxHeight: 45,
    paddingTop: 6,
    paddingHorizontal: 8,
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
  dateText: {
    padding: 8,
    fontSize: 16,
  },
  familyShareOnText: {
    color: Color.Primary,
    marginRight: 8,
    fontSize: GlobalFontSizes.Small,
    fontWeight: 'bold',
  },
  familyShareOffText: {
    color: Color.Gray,
    marginRight: 8,
    fontSize: GlobalFontSizes.Small,
    fontWeight: 'bold',
  },
  listHeader: {
    backgroundColor: Color.GrayVeryLight,
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    alignItems: 'center',
  },
  absolute: {
    position: 'absolute',
    top: wp(10),
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
    backgroundColor: '#33333366',
    // overflow: 'hidden',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 20,
  },
})

export const StyledBlurView = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
`
