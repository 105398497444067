import { ActionType, State, Actions } from './types'

const initialState: State = {
  yearMonth: {},
  startDate: {},
  endDate: {},
  calendarUserAccountType: 'family', // TODO
  totalPaymentAmount: {
    user: 0,
    family: 0,
    all: 0,
  },
  totalReceiptAmount: {
    user: 0,
    family: 0,
    all: 0,
  },
  isShowTransactions: false,
  isNeedUpdateCalendarUserAccountType: false,
}

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionType.UpdateCalenderDate:
      return {
        ...state,
        yearMonth: {
          ...state.yearMonth,
          [action.calendarUserAccountType]: action.yearMonth,
        },
        startDate: {
          ...state.startDate,
          [action.calendarUserAccountType]: action.startDate,
        },
        endDate: {
          ...state.endDate,
          [action.calendarUserAccountType]: action.endDate,
        },
      }
    case ActionType.UpdateCalendarUserAccountType:
      return {
        ...state,
        calendarUserAccountType: action.calendarUserAccountType,
      }
    case ActionType.UpdateCalendarTotalPaymentAmount:
      return {
        ...state,
        totalPaymentAmount: {
          ...state.totalPaymentAmount,
          [action.userAccountType]: action.totalPaymentAmount,
        },
      }
    case ActionType.UpdateCalendarTotalReceiptAmount:
      return {
        ...state,
        totalReceiptAmount: {
          ...state.totalReceiptAmount,
          [action.userAccountType]: action.totalReceiptAmount,
        },
      }
    case ActionType.UpdateShowTransactions:
      return {
        ...state,
        isShowTransactions: action.isShowTransactions,
      }
    case ActionType.UpdateNeedUpdateCalendarUserAccountType:
      return {
        ...state,
        isNeedUpdateCalendarUserAccountType:
          action.isNeedUpdateCalendarUserAccountType,
      }
    default:
      return state
  }
}

export default reducer
