import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { APIError } from '@lib/api'
import Purchase from '@lib/api/Purchase'
import { StripeManager } from '@lib/api/Stripe'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import RNProgressHud from '@lib/ProgressHUD'
import PurchaseManager from '@lib/PurchaseManager'
import TrackingUtils from '@lib/TrackingUtils'
import { getWebToken } from '@lib/WebToken'
import store from '@redux/store'
import React from 'react'
import {
  EmitterSubscription,
  Image,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextProps,
  TouchableOpacity,
  View,
} from 'react-native'
import {
  endConnection,
  finishTransaction,
  getSubscriptions,
  initConnection,
  PurchaseError,
  purchaseErrorListener,
  purchaseUpdatedListener,
  requestSubscription,
  Subscription,
  SubscriptionAndroid,
  SubscriptionPurchase,
} from 'react-native-iap'
import RNTestFlight from 'react-native-test-flight'
import styled from 'styled-components/native'

interface Props {}

interface State {
  selectedPlan: string
  isLoadedPlans: boolean
  products: Subscription[]
}

class PremiumPlanSelectScreen extends React.Component<Props, State> {
  state: State = {
    selectedPlan: '',
    isLoadedPlans: Platform.OS === 'android',
    products: [],
  }

  private purchaseUpdateSubscription?: EmitterSubscription
  private purchaseErrorSubscription?: EmitterSubscription

  async componentDidMount() {
    this.setState({ selectedPlan: this.plans.yearlyPlanWithPartner })

    if (NativeApp) {
      try {
        RNProgressHud.show()

        await ProfileManager.fetchUserProflie()

        this.setIapListener()

        const result = await initConnection()
        console.log('result', result)

        const products = await getSubscriptions({
          skus: Object.values(this.plans),
        })
        this.setState({ isLoadedPlans: true, products })
        console.log(products)
      } catch (error) {
        console.warn(error)
        CommonDialog.showError({ error, onPress: NavigationService.goBack })
      } finally {
        RNProgressHud.dismiss()
      }
    } else {
      this.setState({ isLoadedPlans: true })
    }

    TrackingUtils.repro.track('【Screen】choose plan', 'Screen')
  }

  componentWillUnmount() {
    if (NativeApp) {
      this.removeIapListener()
      endConnection()
    }
  }

  private plans = PurchaseManager.getPlans(Platform.OS)

  private removeIapListener = () => {
    if (!NativeApp) return

    this.purchaseUpdateSubscription?.remove()
    this.purchaseErrorSubscription?.remove()
    this.purchaseUpdateSubscription = undefined
    this.purchaseErrorSubscription = undefined
  }

  private setIapListener = () => {
    if (!NativeApp) return

    this.removeIapListener()

    this.purchaseErrorSubscription = purchaseErrorListener(
      (error: PurchaseError) => {
        RNProgressHud.dismiss()
        console.warn('purchaseErrorListener', error)
        if (error.code === 'E_USER_CANCELLED') {
          // CommonDialog.showMessage('購入をキャンセルしました。')
        } else {
          CommonDialog.debug(error)
        }
      },
    )

    this.purchaseUpdateSubscription = purchaseUpdatedListener(
      async (purchase: SubscriptionPurchase) => {
        console.log('purchaseUpdatedListener', purchase)
        const { transactionReceipt, purchaseToken, productId } = purchase
        if (transactionReceipt) {
          try {
            if (Platform.OS === 'ios') {
              await Purchase.appStoreReceiptVerification({
                receiptData: transactionReceipt,
                sandBox:
                  __DEV__ || RNTestFlight.isTestFlight ? true : undefined,
              })
            } else if (purchaseToken) {
              await Purchase.googlePlayReceiptVerification({
                productId: productId,
                purchaseToken: purchaseToken,
              })
            } else {
              throw new Error('purchaseToken is undefined')
            }
          } catch (error) {
            Log.info(error)
            CommonDialog.showMessage(
              'アプリへのプラン反映が失敗ました。サポートへお問い合わせください',
            )
          } finally {
            await finishTransaction({ purchase })
            TrackingUtils.repro.track('【Screen】purchase completed', 'Screen')
          }

          try {
            await ProfileManager.fetchUserProflie()
            NavigationService.goBack()
          } catch (error) {
            CommonDialog.showError({ error, onPress: NavigationService.goBack })
          } finally {
            RNProgressHud.dismiss()
          }
        } else {
          CommonDialog.showMessage(
            '購入に失敗しました。時間を置いて再度操作を行ってください',
          )
        }
      },
    )
  }

  private onPressSubscribe = async () => {
    Log.info('*** onPressSubscribe', this.state.selectedPlan)
    if (!this.state.selectedPlan) return

    TrackingUtils.repro.track('【Tap】agree to the terms', 'Tap')

    // Log.info('*** onPressSubscribe', this.state.selectedPlan)
    try {
      await PurchaseManager.confirm({
        platform: Platform.OS,
        productId: this.state.selectedPlan,
      })

      const selectedPlanType =
        this.state.selectedPlan === this.plans.monthlyPlan ||
        this.state.selectedPlan === this.plans.yearlyPlan
          ? 'indivisual'
          : 'family'

      const partnerPurchasedPlan =
        await PurchaseManager.getPartnerPurchasedPlanAsync()

      // パートナーは家族プラン購入済み
      // PurchaseManager.confirm の処理が入ったのでこの処理は実行されないはずとなるが残してある
      if (
        partnerPurchasedPlan === this.plans.monthlyPlanWithPartner ||
        partnerPurchasedPlan === this.plans.yearlyPlanWithPartner
      ) {
        Alert.alert(
          'ご確認',
          'パートナーがプレミアムプランを家族で申込済みです。\nあなたの分が重複しますが申し込みますか？',
          [
            {
              text: 'はい',
              onPress: this.subscribeSelectedPlan,
            },
            { text: 'キャンセル' },
          ],
        )
      } else if (
        (partnerPurchasedPlan === this.plans.monthlyPlan ||
          partnerPurchasedPlan === this.plans.yearlyPlan) &&
        selectedPlanType === 'family'
      ) {
        // パートナーは個人プラン購入済みで、自分は家族プランを選択
        Alert.alert(
          'ご確認',
          'パートナーがプレミアムプランを個人で利用中です。\nパートナー分が重複しますが申し込みますか？',
          [
            {
              text: 'はい',
              onPress: this.subscribeSelectedPlan,
            },
            { text: 'キャンセル' },
          ],
        )
      } else {
        // パートナーはいないか未課金
        this.subscribeSelectedPlan()
      }
    } catch (error) {
      CommonDialog.showError({
        error,
        onPress: () => this.errorHandler(error),
      })
    }
  }

  private errorHandler = (error: unknown) => {
    if (error instanceof APIError) {
      Log.info(`available_purchase=${error.response?.json?.availablePurchase}`)
      if (error.response?.json?.availablePurchase) {
        this.subscribeSelectedPlan()
      }
    }
  }

  private subscribeSelectedPlan = async () => {
    console.log('subscribeSelectedPlan')
    RNProgressHud.show()

    if (NativeApp) {
      try {
        if (Platform.OS === 'ios') {
          await requestSubscription({ sku: this.state.selectedPlan })
        } else {
          const offerToken = (
            this.state.products.filter(
              (v) => v.productId === this.state.selectedPlan,
            )?.[0] as SubscriptionAndroid
          )?.subscriptionOfferDetails?.[0].offerToken

          if (offerToken) {
            await requestSubscription({
              sku: this.state.selectedPlan,
              subscriptionOffers: [
                {
                  sku: this.state.selectedPlan,
                  offerToken,
                },
              ],
            })
          }
        }
      } catch (error) {
        console.warn(error)
      }
    } else {
      getWebToken().then((webToken) => {
        if (this.state.selectedPlan) {
          StripeManager.purchaseConfirm({
            productId: this.state.selectedPlan,
            webToken,
          })
            .then((response) => {
              const url = `${response.url}?web_token=${webToken}`
              window.location.href = url
            })
            .catch((error) => {
              CommonDialog.showError({ error })
            })
            .finally(() => {
              RNProgressHud.dismiss()
            })
        }
      })
    }
  }

  render() {
    const { userProfile } = store.getState().profile
    if (!userProfile) return
    const { purchasePlatform } = userProfile

    const crossPlatform =
      (Platform.OS === 'android' && purchasePlatform?.includes('ios')) ||
      (Platform.OS === 'ios' && purchasePlatform?.includes('android'))

    return (
      <View style={styles.container}>
        <CommonHeader title="プランを選択" />
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ paddingVertical: 20 }}>
          <StyledPlanListHeaderView>
            <StyledPlanListHeaderText>
              夫婦二人でプレミアム
            </StyledPlanListHeaderText>
          </StyledPlanListHeaderView>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() =>
              this.setState({ selectedPlan: this.plans.yearlyPlanWithPartner })
            }>
            <View>
              <Text style={styles.planName}>年額プラン（家族）</Text>
              <StyledPlanLabelsView>
                <StyledPlanLabelView>
                  <StyledPlanLabelText>2ヶ月分おトク</StyledPlanLabelText>
                </StyledPlanLabelView>
                <StyledPlanLabelView>
                  <StyledPlanLabelText>1人あたり367円/月</StyledPlanLabelText>
                </StyledPlanLabelView>
              </StyledPlanLabelsView>
              <Text style={styles.price}>
                <Text style={styles.orange}>¥8,800 </Text>
                <Text style={styles.priceLignThrough}>¥10,560</Text>
                <Text style={styles.tax}>（税込）</Text>
              </Text>
            </View>
            <CheckMark
              type={this.plans.yearlyPlanWithPartner}
              value={this.state.selectedPlan}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() =>
              this.setState({ selectedPlan: this.plans.monthlyPlanWithPartner })
            }>
            <View>
              <Text style={styles.planName}>月額プラン（家族）</Text>
              <Text style={styles.price}>
                <Text style={styles.orange}> ¥880</Text>
                <Text style={styles.tax}>（税込）</Text>
              </Text>
            </View>
            <CheckMark
              type={this.plans.monthlyPlanWithPartner}
              value={this.state.selectedPlan}
            />
          </TouchableOpacity>
          <StyledPlanListHeaderView>
            <StyledPlanListHeaderText>
              個人でプレミアム
            </StyledPlanListHeaderText>
          </StyledPlanListHeaderView>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() =>
              this.setState({ selectedPlan: this.plans.yearlyPlan })
            }>
            <View>
              <Text style={styles.planName}>年額プラン（個人）</Text>
              <StyledPlanLabelsView>
                <StyledPlanLabelView>
                  <StyledPlanLabelText>2ヶ月分おトク</StyledPlanLabelText>
                </StyledPlanLabelView>
              </StyledPlanLabelsView>
              <Text style={styles.price}>
                <Text style={styles.orange}>¥4,800 </Text>
                <Text style={styles.priceLignThrough}>¥5,760</Text>
                <Text style={styles.tax}>（税込）</Text>
              </Text>
            </View>
            <CheckMark
              type={this.plans.yearlyPlan}
              value={this.state.selectedPlan}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() =>
              this.setState({ selectedPlan: this.plans.monthlyPlan })
            }>
            <View style={{ flexDirection: 'column' }}>
              <Text style={styles.planName}>月額プラン（個人）</Text>
              <Text style={styles.price}>
                <Text style={styles.orange}>¥480</Text>
                <Text style={styles.tax}>（税込）</Text>
              </Text>
            </View>
            <CheckMark
              type={this.plans.monthlyPlan}
              value={this.state.selectedPlan}
            />
          </TouchableOpacity>

          {(purchasePlatform?.includes('android') ||
            (Platform.OS === 'android' &&
              purchasePlatform?.includes('ios'))) && (
            <View
              style={{
                backgroundColor: '#f7f7f7',
                marginTop: 20,
                paddingHorizontal: 20,
                paddingVertical: 16,
                borderRadius: 12,
                marginHorizontal: 20,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 15,
                }}>
                <Image
                  source={require('@images/icons/icon-caution.png')}
                  style={{ marginRight: 2, width: 20, height: 20 }}
                />
                <Text
                  style={{ fontSize: 12, fontWeight: 'bold', paddingTop: 1 }}>
                  {crossPlatform
                    ? 'ご注意：支払元（AppStore/GooglePlay）の変更'
                    : 'ご注意：Google Playで契約している場合'}
                </Text>
              </View>

              <Text style={{ fontSize: 12, fontWeight: 'normal' }}>
                {crossPlatform ? (
                  <>
                    現在プレミアムプランを契約している支払元（App Store / Google
                    Play）と、変更するプランの支払元が変わる場合は、
                    <Text style={{ color: 'red' }}>
                      必ず元の支払元を解約したうえで、新たな支払元でお申し込みください。
                    </Text>
                    それぞれの支払元で請求が発生し二重請求になります。 {'\n\n'}
                  </>
                ) : (
                  <>
                    プレミアムプランをGoogle Playで契約している場合は、
                    <Text style={{ color: 'red' }}>
                      一度現在のプランを解約したうえで新たなプランをお申し込みください。
                    </Text>
                    それぞれのプランで請求が発生し二重請求になります。 {'\n\n'}
                  </>
                )}
                {crossPlatform && (
                  <>
                    <TextButton
                      style={{
                        fontWeight: 'normal',
                        color: 'blue',
                        textDecorationLine: 'underline',
                      }}
                      onPress={() => {
                        Linking.openURL(
                          Platform.OS === 'ios'
                            ? 'https://apps.apple.com/account/subscriptions'
                            : 'https://support.apple.com/ja-jp/HT202039',
                        )
                      }}>
                      Apple IDの管理画面はこちら
                    </TextButton>
                    {'\n'}
                  </>
                )}
                {(crossPlatform || purchasePlatform?.includes('android')) && (
                  <TextButton
                    style={{
                      fontWeight: 'normal',
                      color: 'blue',
                      textDecorationLine: 'underline',
                    }}
                    onPress={() => {
                      Linking.openURL(
                        `https://play.google.com/store/account/subscriptions`,
                      )
                    }}>
                    Google Playの管理画面はこちら
                  </TextButton>
                )}
              </Text>
            </View>
          )}

          <View
            style={{
              flex: 1,
              marginTop: 40,
              marginHorizontal: 20,
            }}>
            <AppButton
              title="プランを変更する"
              onPress={this.onPressSubscribe}
              disabled={!this.state.selectedPlan || !this.state.isLoadedPlans}
            />
            <View
              style={{
                backgroundColor: '#f7f7f7',
                marginTop: 32,
                paddingHorizontal: 20,
                paddingVertical: 16,
                borderRadius: 12,
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: '#666666',
                }}>
                プレミアムプランにご登録いただくと、
                <TextButton
                  onPress={() => {
                    NavigationService.navigate('MainStackWebView', {
                      title: '利用規約',
                      uri: 'https://www.osidori.co/premium_terms/',
                      leftButtonType: 'modal',
                    })
                  }}>
                  利用規約
                </TextButton>
                と
                <TextButton
                  onPress={() => {
                    NavigationService.navigate('MainStackWebView', {
                      title: 'プライバシーポリシー',
                      uri: 'https://www.osidori.co/privacy/',
                      leftButtonType: 'modal',
                    })
                  }}>
                  プライバシーポリシー
                </TextButton>
                に同意したことになります。
                {'\n\n'}
                {Platform.OS === 'ios'
                  ? '・無料お試し期間が経過すると、月額プラン（個人）は￥480円(税込)、月額プラン（家族）は￥880円(税込)、年額プラン（個人）は￥4,800円(税込)、年額プラン（家族）は￥8,800円(税込)がApple IDに自動課金されます。\n・お試し期間中にプレミアムプランを解約した場合、残りのお試し期間は失効します。\n\n・プレミアムプランは有効期限終了前の24時間以内にApple IDに自動課金されます。\nプレミアムプランは自動更新され、更新手続きは登録期間終了の24時間以内に行われます。\n・解約の場合、それまでにApple IDサブスクリプション設定にて自動更新を停止ください。'
                  : '無料お試しは、GooglePlayアカウントごとに初回のみ適用されます。またお試し期間中にプレミアムプランを解約した場合、残りのお試し期間は失効します。\n\n解約を希望される場合、有効期限終了前までにGooglePlayアカウント設定にて自動更新を停止ください。'}
              </Text>
            </View>
          </View>
        </ScrollView>
      </View>
    )
  }
}

const TextButton: React.FC<TextProps> = ({ onPress, children, style }) => {
  const styles = StyleSheet.create({
    textButton: {
      fontWeight: 'bold',
    },
  })

  return (
    <Text style={[styles.textButton, style]} onPress={onPress}>
      {children}
    </Text>
  )
}

const CheckMark = (props: { type: string; value: string }) => {
  return (
    <Image
      source={
        props.type === props.value
          ? require('@images/icons/icon-check-mark-on.png')
          : require('@images/icons/icon-check-mark-off.png')
      }
      style={{ width: 20, height: 20 }}
      resizeMode="contain"
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  settingItem: {
    marginHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 15,
    alignItems: 'center',
    backgroundColor: Color.White,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  planName: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#3a3a3a',
  },
  price: {
    paddingTop: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3a3a3a',
  },
  priceLignThrough: {
    color: '#666666',
    textDecorationLine: 'line-through',
  },
  orange: {
    color: '#ff7200',
    fontWeight: 'bold',
  },
  tax: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#3a3a3a',
  },
})

const StyledPlanListHeaderView = styled.View`
  margin-horizontal: 20px;
  background-color: rgb(247, 247, 247);
  border-bottom-width: 1px;
  border-bottom-color: rgb(239, 239, 239);
  padding-vertical: 8px;
  padding-horizontal: 20px;
`

const StyledPlanListHeaderText = styled.Text`
  color: rgb(105, 192, 62);
  font-size: 14px;
  font-weight: normal;
`

const StyledPlanLabelsView = styled.View`
  margin-top: 8px;
  margin-bottom: 2px;
  flex-direction: row;
`

const StyledPlanLabelView = styled.Text`
  margin-right: 10px;
  background-color: ${Color.Orange};
  border-radius: 12px;
  overflow: hidden;
  padding-vertical: 5px;
  height: 24px;
  padding-horizontal: 10px;
`

const StyledPlanLabelText = styled.Text`
  color: white;
  font-size: 12px;
  font-weight: bold;
`

export default PremiumPlanSelectScreen
