import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import FullWidthImage from '@components/FullWidthImage'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import { Component } from 'react'
import {
  Image,
  NativeEventSubscription,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { SafeAreaView } from 'react-native-safe-area-context'
import DiagnosisResultData from './DiagnosisResultData'

interface Props {}

export interface DiagnosisResultScreenParams {
  resultType: number
}

class DiagnosisResultScreen extends Component<
  Props & StackScreenProps<RootStackParamList, 'DiagnosisResult'>
> {
  private backHandler?: NativeEventSubscription

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.addListener(this.onPressGoBack)
  }

  componentWillUnmount() {
    this.backHandler?.remove()
  }

  private getResult = () => {
    const result = this.props.route.params.resultType

    let resultData = DiagnosisResultData.result1
    switch (result) {
      case 1:
        resultData = DiagnosisResultData.result1
        break
      case 2:
        resultData = DiagnosisResultData.result2
        break
      case 3:
        resultData = DiagnosisResultData.result3
        break
      case 4:
        resultData = DiagnosisResultData.result4
        break
      case 5:
        resultData = DiagnosisResultData.result5
        break
      default:
        resultData = DiagnosisResultData.result1
        break
    }

    return resultData
  }

  private onPressSubmit = () => {
    if (store.getState().root.rootScreen === 'Welcome') {
      NavigationService.navigate('InvitePairing', {
        isSkipEnabled: true,
      })
    } else {
      NavigationService.popToTop()
    }
  }

  private onPressGoBack = () => {
    if (store.getState().root.rootScreen === 'Welcome') {
      NavigationService.popToTop()
    } else {
      NavigationService.goBack()
    }
  }

  render() {
    const result = this.getResult()
    return (
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <CommonHeader leftButtonType="hidden" title="家計管理タイプ診断結果" />
        <ScrollView>
          <FullWidthImage source={result.image} />
          <View style={styles.body}>
            <Text style={styles.question}>そんな2人にピッタリな使い方は？</Text>
            <Image
              style={styles.path}
              source={require('@assets/images/path/path3.png')}
            />

            {result.data.map((e, i) => (
              <View key={i}>
                <Text style={styles.itemTitle}>{e.title}</Text>
                <FullWidthImage style={styles.itemImage} source={e.image} />
                <Text style={styles.itemContent}>{e.content}</Text>
              </View>
            ))}
          </View>
          <AppButton
            style={{ marginHorizontal: 20 }}
            onPress={this.onPressSubmit}
            title={
              store.getState().root.rootScreen === 'Welcome'
                ? 'OsidOriをはじめる'
                : '登録内容を更新する'
            }
          />
          <AppButton
            style={{ margin: 20 }}
            type="white"
            onPress={this.onPressGoBack}
            title="前の画面にもどる"
          />
        </ScrollView>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  body: {
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  question: {
    color: '#3A3A3A',
    fontSize: 20,
    marginVertical: 10,
    fontWeight: '400',
  },
  path: {
    width: '100%',
    resizeMode: 'contain',
    marginVertical: 10,
  },
  itemTitle: {
    textAlign: 'center',
    color: '#3A3A3A',
    fontSize: 22,
    fontWeight: '600',
  },
  itemImage: {
    width: wp(80),
    resizeMode: 'contain',
    marginVertical: 20,
  },
  itemContent: {
    textAlign: 'center',
    fontSize: 15,
    color: '#666666',
    fontWeight: '400',
    marginBottom: 20,
  },
})

export default DiagnosisResultScreen
