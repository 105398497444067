import CommonHeader from '@components/CommonHeader'
import NavigationService from '@lib/NavigationService'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React from 'react'
import { Platform } from 'react-native'
import DebugInformationScreen from './DebugInformationScreen'
import DebugNetworkingScreen from './DebugNetworkingScreen'
import SystemReportScreen from './SystemReportScreen'
import { RootContainer } from './style'

const Tab = createMaterialTopTabNavigator()

const DebugScreen: React.FC = () => {
  return (
    <RootContainer>
      <CommonHeader
        title="DEBUG"
        leftButtonType="modal"
        onPressLeftButton={NavigationService.goBack}
      />
      <Tab.Navigator backBehavior="none">
        {Platform.OS !== 'web' && !__DEV__ && (
          <Tab.Screen name="ネットワーク" component={DebugNetworkingScreen} />
        )}
        <Tab.Screen name="情報" component={DebugInformationScreen} />
        <Tab.Screen name="レポート" component={SystemReportScreen} />
      </Tab.Navigator>
    </RootContainer>
  )
}

export default DebugScreen
