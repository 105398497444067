import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { IconImages } from '@components/IcomImage'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { useSignal } from '@lib/OsidoriSignal'
import { LifePlanManager } from '@lib/api/LifePlan'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useEffect, useState } from 'react'
import PickerSelect, { Item } from 'react-native-picker-select'
import styled from 'styled-components/native'
import LifePlanEventImageView from './LifePlanEventImageView'

const CreateLifePlanEventScreen: React.FC<
  StackScreenProps<RootStackParamList, 'CreateLifePlanEvent'>
> = ({ route }) => {
  const goalContent = route.params?.goalContent

  const [age, setAge] = useState(20)
  const [title, setTitle] = useState(goalContent?.name)
  const [imageUrl, setImageUrl] = useState<string>()

  const uploadedImage = useSignal('IMAGE_UPLOADED')

  const onPressSave = useCallback(() => {
    LifePlanManager.createLifePlanEvent({
      goalContentId: goalContent.id,
      title,
      age,
      imgUrl: imageUrl,
    })
      .then(() => {
        NavigationService.goBack()
      })
      .catch((error) => {
        CommonDialog.showError({ error })
      })
  }, [age, goalContent.id, imageUrl, title])

  useEffect(() => {
    if (uploadedImage?.uploadImageType === 'lifeplan_event') {
      setImageUrl(uploadedImage.imgUrl)
    }
  }, [uploadedImage])

  return (
    <StyledRootView>
      <CommonHeader title="ライフイベント作成" />
      <StyledScrollView>
        <LifePlanEventImageView goalContent={goalContent} />
        <StyledRow>
          <StyledRowTitle>タイトル</StyledRowTitle>
          <StyledTextInput
            value={title}
            onChangeText={(text) => setTitle(text)}
          />
        </StyledRow>
        <StyledRow>
          <StyledRowTitle>達成時期</StyledRowTitle>
          <PickerSelect
            style={{
              viewContainer: {
                flex: 1,
              },
              inputAndroid: {
                height: 60,
                color: '#00000000',
                // width: '200%', // アイコンを外に出すために横幅を広げる
              },
              inputIOS: {
                // flex: 1,
                // width: '100%',
                height: 60,
                color: '#000000',
                textAlign: 'right',
                paddingRight: 30,
                fontSize: 16,
              },
              inputWeb: {
                height: 60,
                color: '#00000000',
                backgroundColor: '#00000000',
              },
            }}
            placeholder=""
            value={age}
            onValueChange={(age) => {
              setAge(age)
            }}
            items={ages}
            Icon={StyledArrowImage}
            doneText="完了"
          />
        </StyledRow>
        <StyledRow>
          <StyledRowTitle>カテゴリ</StyledRowTitle>
          <StyledTextInput value={goalContent?.name} editable={false} />
        </StyledRow>
      </StyledScrollView>
      <StyledAppButton title="保存" onPress={onPressSave} />
    </StyledRootView>
  )
}

const ages: Item[] = [...Array(80).keys()]
  .map((v) => ({
    label: 20 + v + '歳',
    value: 20 + v,
  }))
  .concat({ label: '月末', value: -1 })

const StyledRootView = styled.View({ flex: 1 })

const StyledScrollView = styled.ScrollView({})

const StyledRow = styled.View({
  paddingHorizontal: 20,
  borderBottomWidth: 0.5,
  borderColor: '#eeeeee',
  flexDirection: 'row',
  alignItems: 'center',
})

const StyledRowTitle = styled.Text({
  color: Color.Black,
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: 10,
})

const StyledTextInput = styled.TextInput({
  flex: 1,
  height: 60,
  fontSize: 16,
  textAlign: 'right',
})

const StyledAppButton = styled(AppButton).attrs({
  textStyle: { fontWeight: 'bold' },
})({
  margin: 20,
})

const StyledArrowImage = styled.Image.attrs({
  source: IconImages.ArrowDown,
  resizeMode: 'contain',
})({
  width: 15,
  height: 60,
  // marginRight: 5,
})

export default CreateLifePlanEventScreen
