import {
  Image,
  Modal,
  ModalProps,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import FullWidthImage from './FullWidthImage'

export type AndroidSupportModalProps = ModalProps & {
  onPressRequest: () => void
  onPressSupport: () => void
  onPressClose: () => void
}

const AndroidSupportModal = ({
  onPressRequest,
  onPressSupport,
  onPressClose,
  ...props
}: AndroidSupportModalProps) => {
  return (
    <Modal transparent={true} animationType="fade" {...props}>
      <View style={styles.container}>
        <View style={styles.dialog}>
          <View style={{ backgroundColor: 'rgb(94,203,71)', padding: 10 }}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: 'white',
                lineHeight: 30,
                textAlign: 'center',
              }}>
              いつもご利用いただき{'\n'}ありがとうございます！
            </Text>
            <TouchableOpacity
              onPress={onPressClose}
              style={{ position: 'absolute', right: 0, padding: 10 }}>
              <Image
                style={{ width: 15, height: 15 }}
                source={require('@assets/images/icons/icon-close-white.png')}
              />
            </TouchableOpacity>
          </View>
          <FullWidthImage
            source={require('@assets/images/android_support_bg.jpg')}
          />
          <View style={styles.content}>
            <Text style={styles.text}>
              よろしければストアで★5つ評価をおねがいできますか？
            </Text>
            <Text style={styles.text2}>
              今後ともよろしくお願いいたします。{'\n'}（OsidOriチーム一同）
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              padding: 10,
            }}>
            <TouchableOpacity
              onPress={onPressRequest}
              style={{
                flex: 1,
                backgroundColor: 'rgb(143,143,143)',
                height: 44,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: 'normal',
                  color: 'white',
                }}>
                要望を送る
              </Text>
            </TouchableOpacity>
            <View style={{ width: 8 }} />
            <TouchableOpacity
              onPress={onPressSupport}
              style={{
                flex: 1,
                backgroundColor: 'rgb(94,203,71)',
                height: 44,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: 'normal',
                  color: 'white',
                }}>
                応援する
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#00000080',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    backgroundColor: 'white',
    width: '70%',
  },
  content: {
    padding: 20,
    paddingBottom: 5,
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'normal',
    marginBottom: 10,
  },
  text2: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
  },
})

export default AndroidSupportModal
