import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import AccountManager from '@lib/AccountManager'
import BudgetQuestions from '@lib/api/BudgetQuestions'
import Color from '@lib/Color'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import store from '@redux/store'
import React from 'react'
import {
  Image,
  ImageRequireSource,
  NativeEventSubscription,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

interface Question {
  text: string
  answers: {
    value: number
    image: ImageRequireSource
    result?: number
    question?: Question
  }[]
}

const question4: Question = {
  text: `貯金用の口座で\n近いものはどれですか？`,
  answers: [
    {
      value: 6,
      image: require('@assets/images/diagnose/qa/qa41/typetest-card-type5.png'),
      result: 2,
    },
    {
      value: 5,
      image: require('@assets/images/diagnose/qa/qa42/typetest-card-type7.png'),
      result: 1,
    },
    {
      value: 7,
      image: require('@assets/images/diagnose/qa/qa43/typetest-card-type6.png'),
      result: 3,
    },
  ],
}

const question3: Question = {
  text: `貯金用の口座で\n近いものはどちらですか？`,
  answers: [
    {
      value: 8,
      image: require('@assets/images/diagnose/qa/qa31/typetest-card-type5.png'),
      result: 4,
    },
    {
      value: 9,
      image: require('@assets/images/diagnose/qa/qa32/typetest-card-type6.png'),
      result: 5,
    },
  ],
}

const question2: Question = {
  text: `家計用の口座で\n近い方はどちらですか？`,
  answers: [
    {
      value: 3,
      image: require('@assets/images/diagnose/qa/qa21/typetest-card-type3.png'),
      question: question4,
    },
    {
      value: 4,
      image: require('@assets/images/diagnose/qa/qa22/typetest-card-type4.png'),
      question: question3,
    },
  ],
}

const question1: Question = {
  text: `夫婦の家計ルールに近い方は\nどれですか？`,
  answers: [
    {
      value: 2,
      image: require('@assets/images/diagnose/qa/qa11/typetest-card-type1.png'),
      question: question2,
    },
    {
      value: 3,
      image: require('@assets/images/diagnose/qa/qa12/typetest-card-type2.png'),
      question: question3,
    },
    {
      value: 4,
      image: require('@assets/images/diagnose/qa/qa13/typetest-card-type5.png'),
      question: question4,
    },
  ],
}

const questionUIBank: Question = {
  text: `あなたの使い方について\nおしえて下さい`,
  answers: [
    {
      value: 0,
      image: require('@assets/images/diagnose/qa/uibank1/question_uibank1_personal.png'),
      result: 0,
    },
    {
      value: 0,
      image: require('@assets/images/diagnose/qa/uibank1/question_uibank1_family.png'),
      question: question1,
    },
  ],
}

interface State {
  questions: Question[]
  selectedAnswers: number[]
}

class HouseholdDiagnosisScreen extends React.Component<{}, State> {
  private backHandler?: NativeEventSubscription

  state: State = {
    questions: [questionUIBank],
    selectedAnswers: [1],
  }

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.addListener(() => {
      if (this.state.questions.length > 1) {
        this.goBack()
      } else {
        HardwareBackPressHandler.onPressToExitApp()
      }
    })

    TrackingUtils.ga.pageview({
      page: 'UserInfoDianosis-1',
      title: '利用人数の質問画面',
    })
  }

  componentWillUnmount() {
    this.backHandler?.remove()
  }

  private currentQuestion = () => {
    const { questions } = this.state
    return questions[questions.length - 1]
  }

  private selectAnswer = (index: number) => {
    const { questions, selectedAnswers } = this.state
    const question = this.currentQuestion()
    const nextQuestion = question.answers[index]?.question
    selectedAnswers.push(question.answers[index]?.value)
    if (nextQuestion) {
      questions.push(nextQuestion)
      this.setState({ questions, selectedAnswers })
    } else {
      const resultType = question.answers[index]?.result

      if (store.getState().root.rootScreen === 'Welcome') {
        NavigationService.navigate('UserInfoDiagnosis', {
          resultType,
        })
      } else {
        if (!resultType) {
          // UIBank版で個人を選択した場合はresultは0
          AccountManager.updateAccountMode('user')
        }

        NavigationService.navigate('UserInfoDiagnosis', {
          resultType,
        })
      }
      this.setState({ selectedAnswers }, this.postAnswers)
    }
  }

  private goBack = () => {
    const { questions, selectedAnswers } = this.state
    questions.pop()
    selectedAnswers.pop()
    this.setState({ questions, selectedAnswers })
  }

  private postAnswers = () => {
    const budgetQuestions = this.state.selectedAnswers.map((v, index) => ({
      step: 1 + index,
      budgetQuestionId: v,
    }))
    BudgetQuestions.postBudgetQuestions({ budgetQuestions })
    this.setState({ questions: [question1] })
  }

  render() {
    const { questions } = this.state
    const question = this.currentQuestion()

    const isRootWelcome = store.getState().root.rootScreen === 'Welcome'

    return (
      <View style={styles.container}>
        <CommonHeader
          leftButtonType={isRootWelcome ? 'hidden' : 'stack'}
          title="家計管理タイプ診断"
        />
        <ScrollView
          contentContainerStyle={styles.body}
          showsVerticalScrollIndicator={false}>
          <Text style={styles.question}>Question</Text>
          <View style={styles.dash} />
          <Text style={styles.text}>{question.text}</Text>
          {question.answers.map((e, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => this.selectAnswer(index)}>
              <Image style={styles.imageAnswer} source={e.image} />
            </TouchableOpacity>
          ))}
          {questions.length > 1 && (
            <AppButton
              style={{ margin: 20 }}
              type="white"
              onPress={this.goBack}
              title="前の画面にもどる"
            />
          )}
        </ScrollView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f7f7f7',
  },
  body: {
    padding: 10,
  },
  question: {
    alignSelf: 'center',
    color: Color.Primary,
    fontSize: 17,
    letterSpacing: 1,
    fontWeight: 'bold',
    marginTop: 30,
  },
  dash: {
    alignSelf: 'center',
    width: 30,
    height: 2,
    backgroundColor: Color.Primary,
    marginVertical: 5,
  },
  text: {
    textAlign: 'center',
    lineHeight: 36,
    fontSize: 24,
    fontWeight: '400',
    color: '#3A3A3A',
  },
  imageAnswer: {
    width: '100%',
    height: 130,
    resizeMode: 'contain',
    marginTop: 10,
  },
})

export default HouseholdDiagnosisScreen
