import { PayoffScreenProps } from '@Screen/Main/Home/Payoff/PayoffScreen'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { Payoff, PayoffManager } from '@lib/api/Payoff'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ListRenderItem,
  RefreshControl,
  SectionList,
  SectionListData,
  Text,
} from 'react-native'
import { RootContainer } from '../styles'
import SettledListItem from './SettledListItem'

const SettledListScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettledList'>
> = ({ navigation }) => {
  const [payoffs, setPayoffs] = useState<Payoff[]>([])
  const [refreshing, setRefreshing] = useState(false)

  const [nextPayoffDate, setNextPayoffDate] = useState<string | null>()

  const getPayoffs = useCallback(
    async (append = false) => {
      try {
        const response = await PayoffManager.getPayoffs({
          to: append
            ? nextPayoffDate
              ? nextPayoffDate
              : undefined
            : undefined,
        })

        setPayoffs(
          append ? [...payoffs, ...response.payoffs] : response.payoffs,
        )
        setNextPayoffDate(response.nextPayoffDate)
      } catch (error) {
        CommonDialog.showError({ error })
      }
    },
    [nextPayoffDate, payoffs],
  )

  const sections = useMemo(() => {
    const payoffDates: { [date: string]: Payoff[] } = {}

    const sections: SectionListData<Payoff>[] = []

    payoffs.forEach((payoff: Payoff) => {
      const date = moment(payoff.payoffDate).format('YYYYMMDD')
      if (payoffDates[date]) {
        payoffDates[date].push(payoff)
      } else {
        payoffDates[date] = [payoff]
      }
    })

    const today = moment().format('YYYYMMDD')

    Object.keys(payoffDates)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: payoffDates[date],
        })
      })

    return sections
  }, [payoffs])

  const onEndReached = useCallback(() => {
    if (nextPayoffDate) {
      getPayoffs(true)
    }
  }, [getPayoffs, nextPayoffDate])

  const onPressSettledListItem = useCallback((payoff: Payoff) => {
    const payoffScreenProps: PayoffScreenProps = {
      displaySettleType: 'settled',
      payoffId: payoff.payoffId,
    }
    NavigationService.navigate('Payoff', payoffScreenProps)
  }, [])

  const renderItem: ListRenderItem<Payoff> = useCallback(
    ({ item }) => (
      <SettledListItem
        payoff={item}
        onPress={() => onPressSettledListItem(item)}
      />
    ),
    [onPressSettledListItem],
  )

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', () => {
      getPayoffs()

      TrackingUtils.repro.track('【Screen】settled lists', 'Screen')
    })

    return () => {
      unsubscribeFocus()
    }
    // Exclude getPayoffs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation])

  return (
    <RootContainer>
      {sections ? (
        <SectionList
          sections={sections}
          renderItem={renderItem}
          renderSectionHeader={({ section: { title } }) => (
            <SectionListHeader title={title} style={{ fontWeight: 'normal' }} />
          )}
          keyExtractor={(item: Payoff) => `${item.payoffId}`}
          onEndReached={onEndReached}
          style={{ marginTop: 20 }}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true)
                await getPayoffs()
                setRefreshing(false)
              }}
            />
          }
          ItemSeparatorComponent={() => ItemSeparator}
          stickySectionHeadersEnabled={false}
        />
      ) : (
        <>
          <Text>明細はありません</Text>
        </>
      )}
    </RootContainer>
  )
}

export default SettledListScreen
