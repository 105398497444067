import CommonHeader from '@components/CommonHeader'
import Images from '@lib/Images'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import FastImage from 'react-native-fast-image'

interface Props {}

class CreateAccountSendEmailScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'CreateAccountSendEmail'>
> {
  componentDidMount() {
    TrackingUtils.repro.track('【Completed】invitation_sent', 'Completed')
  }

  render() {
    const { email } = this.props.route.params
    return (
      <View style={styles.container}>
        <CommonHeader title="認証用URLを送りました" />
        <FastImage source={Images.LoginBackground as never} style={styles.body}>
          <Image
            style={styles.imgCheck}
            source={require('@images/icons/icon-check-green.png')}
          />
          <Text style={[styles.message, styles.title]}>
            認証用URLをお送りました
          </Text>
          <Text style={styles.message}>
            {`ご登録いただいたメールアドレスに\n認証用URLをお送りしました。\nメールに記載されているURLをタップすると、\nOsidOriが立ち上がり、使えるようになります。`}
          </Text>
          <Text style={styles.wrapText}>
            <Text
              style={styles.btnLink}
              onPress={() => {
                NavigationService.navigate('CreateAccountResendEmail', {
                  email,
                })
              }}>
              認証用URLが届かない方はこちらから{'\n'}
              再度メールアドレスを入力してください
            </Text>
          </Text>
        </FastImage>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgCheck: {
    width: 60,
    height: 60,
  },
  title: {
    fontSize: 23,
    fontWeight: 'normal',
    marginVertical: 10,
    lineHeight: 25,
  },
  message: {
    textAlign: 'center',
    color: '#fff',
    lineHeight: 20,
  },
  wrapText: {
    width: '90%',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    paddingTop: 15,
    fontSize: 14,
    fontWeight: 'normal',
    color: 'white',
    marginVertical: 15,
    textAlign: 'center',
    lineHeight: 20,
  },
  btnLink: {
    marginVertical: 15,
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    textDecorationLine: 'underline',
    fontWeight: '600',
  },
})

export default CreateAccountSendEmailScreen
