import { RootState } from '@redux/store'

type UserType = 'user' | 'partner'

export const profileSelector = (userType: UserType) => ({
  settingProfiles,
}: RootState) =>
  userType === 'user'
    ? settingProfiles.userProfile
    : settingProfiles.partnerProfile
