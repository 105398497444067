import Color from '@lib/Color'
import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

export type AddCaregoryButtonProps = TouchableOpacityProps

export const AddGoalButton = ({ onPress, style }: AddCaregoryButtonProps) => {
  return (
    <StyledRootView>
      <StyledTouchableOpacity onPress={onPress} style={style}>
        <StyledText>＋ 目標貯金を追加</StyledText>
      </StyledTouchableOpacity>
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  position: 'absolute',
  bottom: 15,
  right: 10,
  alignSelf: 'flex-end',
})

const StyledTouchableOpacity = styled.TouchableOpacity({
  borderRadius: 22,
  paddingHorizontal: 15,
  backgroundColor: Color.Primary,
  height: 44,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  shadowColor: 'rgba(0,0,0,0.5)',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 4,
})

const StyledText = styled.Text({
  fontSize: 14,
  fontWeight: 'bold',
  color: Color.White,
})
