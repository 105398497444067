import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { UserAPI } from '@lib/api/User'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import { ActionType } from '@redux/types'
import React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'

interface Props {}

interface State {
  isUpdatedPassword: boolean
  password: string
  confirmPassword: string
}

class UpdatePasswordScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'UpdatePassword'>,
  State
> {
  state: State = {
    isUpdatedPassword: false,
    password: '',
    confirmPassword: '',
  }

  private timeout?: NodeJS.Timeout

  componentDidMount() {
    store.dispatch({
      type: ActionType.SetNavigation,
      navigation: undefined,
    })
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  private onChangePassword = (password: string) => {
    this.setState({ password })
  }

  private onChangeConfirmPassword = (confirmPassword: string) => {
    this.setState({ confirmPassword })
  }

  private onSubmit = async () => {
    if (this.state.password !== this.state.confirmPassword) {
      CommonDialog.showMessage(
        '入力されたパスワードと確認用パスワードが一致しません。',
      )
      return
    }

    showAppButtonIndicator()

    const token = this.props.route.params.token

    try {
      const response = await UserAPI.changePassowrd({
        token,
        password: this.state.password,
        passwordConfirm: this.state.confirmPassword,
      })
      if (response.ok) {
        this.setState({ isUpdatedPassword: true })
        this.timeout = setTimeout(
          () => NavigationService.navigate('CreateAccountOrLogin'),
          3000,
        )
      } else {
        CommonDialog.showMessage('パスワードの再設定に失敗しました。')
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator()
    }
  }

  private onPressLeftButton = () =>
    NavigationService.navigate('CreateAccountOrLogin')

  render() {
    return (
      <>
        <CommonHeader
          title="パスワードの再設定"
          onPressLeftButton={this.onPressLeftButton}
        />
        <View style={styles.container}>
          {this.state.isUpdatedPassword ? (
            <Text style={styles.message}>
              パスワードの再登録が完了しました
              {'\n'}
              自動的にログイン画面に遷移します。
            </Text>
          ) : (
            <>
              <TextInput
                secureTextEntry={true}
                style={styles.input}
                value={this.state.password}
                placeholder="8〜16文字以内の半角英数字"
                placeholderTextColor={Color.PlaceHolderText}
                onChangeText={this.onChangePassword}
                autoFocus={true}
                returnKeyType="done"
              />
              <TextInput
                secureTextEntry={true}
                style={styles.input}
                value={this.state.confirmPassword}
                placeholder="確認のためもう一度入力してください"
                placeholderTextColor={Color.PlaceHolderText}
                onChangeText={this.onChangeConfirmPassword}
                returnKeyType="done"
              />
              <Text style={styles.message}>
                新しいバスワードを入力してください。
              </Text>
              <AppButton
                disabled={
                  this.state.password === '' ||
                  this.state.confirmPassword === ''
                }
                onPress={this.onSubmit}
                title="登録"
              />
            </>
          )}
        </View>
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: { marginHorizontal: 30, marginTop: 30 },
  input: {
    height: 40,
    borderRadius: 5,
    borderColor: '#dbdbdb',
    borderWidth: 1,
    paddingHorizontal: 10,
    marginVertical: 10,
  },
  message: {
    color: '#0e91ac',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 30,
  },
})

export default UpdatePasswordScreen
