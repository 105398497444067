import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import CommonHeader from '@components/CommonHeader'
import RootView from '@components/RootView'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import Log from '@lib/Log'
import { RootStackParamList } from '@navigation/Screens'
import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useRef } from 'react'
import { Linking, NativeEventSubscription, StyleSheet } from 'react-native'
import { WebView, WebViewNavigation } from 'react-native-webview'

const WebViewScreen: React.FC<
  StackScreenProps<RootStackParamList, 'WebView'>
> = ({ route }) => {
  const navigation = useNavigation()
  const backHandler = useRef<NativeEventSubscription>()

  const goBack = useCallback(() => {
    if (route.params?.goBack) {
      route.params.goBack()
    } else {
      navigation.goBack()
    }
  }, [navigation, route.params])

  const onShouldStartLoadWithRequest = useCallback(
    (event: WebViewNavigation) => {
      if (/^osidori:/.test(event.url)) {
        return false
      }

      // 外部サイトの場合は、外部ブラウザを開く
      if (
        !/^https?:\/\/(?:[^/]*\.)*(?:osidori\.co|youtube\.com)\//.test(
          event.url,
        )
      ) {
        Linking.openURL(event.url).catch((error) => Log.info(event.url, error))
        return false
      }

      return true
    },
    [],
  )

  useEffect(() => {
    backHandler.current = HardwareBackPressHandler.addListener(goBack)
    return backHandler.current?.remove
  }, [goBack])

  const { title, uri, leftButtonTitle, leftButtonType } = route.params

  return (
    <RootView>
      <CommonHeader
        title={title}
        leftButtonTitle={leftButtonTitle}
        leftButtonType={leftButtonType}
        onPressLeftButton={goBack}
      />
      <WebView
        style={styles.container}
        source={{ uri }}
        startInLoadingState={true}
        renderLoading={() => <AbsoluteActivityIndicator />}
        onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
      />
    </RootView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default WebViewScreen
