import { createContext } from 'react'
import { Spot, Spots, StartSpotlightTourOptions, Tour } from './types'

type SpotlightTourContext = {
  spots: Spots
  setSpot: (spot: Spot) => void
  setSpotPosition: (
    spotId: string,
    x: number,
    y: number,
    width: number,
    height: number,
  ) => void
  tour: Tour
  setTour: (tour: Tour) => void
  step: number
  startSpotlightTour: (tour: Tour, options?: StartSpotlightTourOptions) => void
  setStep: (step: number) => void
  nextStep: () => void
  previousStep: () => void
  finishSpotlightTour: () => void
  onPressSuperView?: (step: number) => void
  setOnPressSuperView: (onPressSuperView?: (step: number) => void) => void
  before?: (step: number) => void
  after?: (step: number) => void
  overlayColor: string
  setOverlayColor: (color: string) => void
  disabled: boolean
  setDisabled: (disabled: boolean) => void
  setWaitingSpotMeasures: (count: number) => void
  waitingSpotMeasures: number
  // setBaseRect: (rect: PositionSize) => void
  // basePosition: { x: number; y: number }
}

const SpotlightTourContext = createContext<SpotlightTourContext>({
  spots: {},
  setSpot: () => {},
  setSpotPosition: () => {},
  tour: [],
  setTour: () => {},
  step: 0,
  setStep: () => {},
  startSpotlightTour: () => {},
  nextStep: () => {},
  previousStep: () => {},
  finishSpotlightTour: () => {},
  setOnPressSuperView: () => {},
  overlayColor: '',
  setOverlayColor: () => {},
  disabled: false,
  setDisabled: () => {},
  setWaitingSpotMeasures: () => {},
  waitingSpotMeasures: 0,
})

export default SpotlightTourContext
