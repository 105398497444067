import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import CoupleButton from '@components/CoupleButton'
import DatePicker from '@components/DatePicker'
import IconImage from '@components/IcomImage'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import ProgressHUD from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { DownloadManager } from '@lib/api/Download'
import store from '@redux/store'
import Encoding from 'encoding-japanese'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { AlertButton } from 'react-native'
import Share from 'react-native-share'
import {
  StyledAccountTypeButtonsContainer,
  StyledAccountTypeContainer,
  StyledButtonContainer,
  StyledDescriptionText,
  StyledMainContainer,
  StyledPeriodContainer,
  StyledPeriodDescription,
  StyledPickerButton,
  StyledPickerContainer,
  StyledPickerText,
  StyledSeparatorView,
  StyledTitleText,
} from './styles'

const TransactionDataDownloadScreen: React.FC = () => {
  const [isShared, setIsShared] = useState(
    store.getState().account.accountMode === 'family',
  )
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())
  const [isFromDatePickerVisible, setIsFromDatePickerVisible] = useState(false)
  const [isToDatePickerVisible, setIsToDatePickerVisible] = useState(false)

  const onPressFrom = useCallback(() => setIsFromDatePickerVisible(true), [])
  const onPressTo = useCallback(() => setIsToDatePickerVisible(true), [])

  const onConfirmFrom = useCallback((date: Date) => {
    setFrom(date)
    setIsFromDatePickerVisible(false)
  }, [])

  const onConfirmTo = useCallback((date: Date) => {
    setTo(date)
    setIsToDatePickerVisible(false)
  }, [])

  const onCancelFrom = useCallback(() => setIsFromDatePickerVisible(false), [])
  const onCancelTo = useCallback(() => setIsToDatePickerVisible(false), [])

  const getErrorMessage = useCallback((error: Error) => {
    if (error instanceof APIError) {
      if (error.response?.status === 422) {
        const message: string = error.response?.json?.errors[0]?.message || ''
        return message.replace('\\n', '\n')
      }
    }
    return ''
  }, [])

  const onPressDownload = useCallback(async () => {
    TrackingUtils.repro.track('【Tap】detail_data dl dl', 'Tap')

    try {
      ProgressHUD.show()
      const response = await DownloadManager.downloadTransactionsCSV({
        from: moment(from).format('YYYYMMDD'),
        to: moment(to).format('YYYYMMDD'),
        share: isShared,
      })

      if (NativeApp) {
        response.path && Share.open({ url: `file://${response.path}` })
      } else {
        const unicodeArray = Encoding.stringToCode(response.json.text) // Convert string to code array
        const sjisArray = Encoding.convert(unicodeArray, {
          to: 'SJIS',
          from: 'UNICODE',
        })
        const u8a = new Uint8Array(sjisArray)

        const blob = new Blob([u8a], { type: 'text/csv' })
        const csvUrl = window.URL.createObjectURL(blob)

        const filename = `${moment(from).format('YYYYMMDD')}-${moment(
          to,
        ).format('YYYYMMDD')}_osidori_tran.csv`

        const link = document.createElement('a')
        link.download = filename
        link.href = csvUrl
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.status === 422) {
          const buttons: AlertButton[] = [{ text: '閉じる' }]
          if (['010301', '010303'].includes(error.response.errorCode || '')) {
            buttons.push({
              text: '詳しく見る',
              onPress: () => {
                navigatePremiumPlanLP({ planCode: '001' })
                TrackingUtils.repro.track('【Tap】upper limit_data dl', 'Tap')
                TrackingUtils.repro.setScreenTrackingAfterPurchase(
                  '【Screen】purchase completed via upper limit_data dl',
                )
              },
            })
          }
          Alert.alert('', getErrorMessage(error), buttons)
          TrackingUtils.repro.track('【Screen】upper limit_data dl', 'Screen')

          TrackingUtils.ga.pageview({
            page: 'Upperlimit-DataBackup-Transaction',
            title:
              'データバックアップ画面の「取引明細」タブで、ダイアログが表示',
          })
          return
        }
      }
      CommonDialog.showError({ error })
    } finally {
      ProgressHUD.dismiss()
    }
  }, [from, to, isShared, getErrorMessage])

  return (
    <StyledMainContainer>
      <StyledDescriptionText>
        OsidOriに登録された取引明細を1件単位でcsvファイルに出力できます。（例：食費
        食料品 オシドリストア 1,200円）
        {'\n'}
        ※取引明細データの出力はプレミアムプランの機能です。
      </StyledDescriptionText>
      <StyledSeparatorView />
      <StyledAccountTypeContainer>
        <StyledTitleText>取得する画面</StyledTitleText>
        <StyledAccountTypeButtonsContainer>
          <CoupleButton
            buttonType="single"
            onPress={() => setIsShared(false)}
            isOn={!isShared}
            title="個人画面"
            disabled={!isShared}
            style={{ width: Math.min(106, wp(28)) }}
          />
          <CoupleButton
            buttonType="couple"
            onPress={() => setIsShared(true)}
            isOn={isShared}
            title="家族画面"
            disabled={isShared}
            style={{
              width: Math.min(106, wp(29)),
              marginLeft: Math.min(20, wp(2.5)),
            }}
          />
        </StyledAccountTypeButtonsContainer>
      </StyledAccountTypeContainer>
      <StyledSeparatorView />
      <StyledPeriodContainer>
        <StyledTitleText>取得期間</StyledTitleText>
        <StyledPeriodDescription>
          1度に最大100日間まで指定できます
        </StyledPeriodDescription>
      </StyledPeriodContainer>
      <StyledPickerContainer>
        <StyledTitleText>開始日</StyledTitleText>
        <StyledPickerButton onPress={onPressFrom}>
          <StyledPickerText>
            {moment(from).format('YYYY年M月D日')}
          </StyledPickerText>
          <IconImage name="ArrowDown" />
        </StyledPickerButton>
      </StyledPickerContainer>
      <StyledSeparatorView />
      <StyledPickerContainer>
        <StyledTitleText>終了日</StyledTitleText>
        <StyledPickerButton onPress={onPressTo}>
          <StyledPickerText>
            {moment(to).format('YYYY年M月D日')}
          </StyledPickerText>
          <IconImage name="ArrowDown" />
        </StyledPickerButton>
      </StyledPickerContainer>
      <StyledSeparatorView />
      <StyledButtonContainer>
        <AppButton title="データを出力する" onPress={onPressDownload} />
      </StyledButtonContainer>
      <DatePicker
        date={from}
        isVisible={isFromDatePickerVisible}
        onConfirm={onConfirmFrom}
        onCancel={onCancelFrom}
        headerFormat="日付を選択"
        confirmText="決定"
        cancelText="キャンセル"
      />
      <DatePicker
        date={to}
        isVisible={isToDatePickerVisible}
        onConfirm={onConfirmTo}
        onCancel={onCancelTo}
        headerFormat="日付を選択"
        confirmText="決定"
        cancelText="キャンセル"
      />
    </StyledMainContainer>
  )
}

export default TransactionDataDownloadScreen
