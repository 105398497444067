import { ActivityIndicator } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { selectIsVisibleProgressHUD } from './slice'

const ProgressHUDView = () => {
  const isVisible = useSelector(selectIsVisibleProgressHUD)

  if (!isVisible) return null

  return (
    <StyledRootView>
      <StyledOverayView />
      <StyledProgressBgBiew>
        <ActivityIndicator color="gray" />
      </StyledProgressBgBiew>
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledOverayView = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
})

const StyledProgressBgBiew = styled.View({
  width: '70px',
  aspectRatio: 1,
  borderRadius: '10px',
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
})

export default ProgressHUDView
