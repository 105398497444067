import Color from '@lib/Color'
import React from 'react'
import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

export type CheckBoxProps = TouchableOpacityProps & {
  isOn?: boolean
  size?: number
  type?: 'circle' | 'box'
}

const CheckBox: React.FC<CheckBoxProps> = ({
  onPress,
  disabled,
  isOn,
  size,
  style,
  type = 'circle',
}) => {
  return type === 'circle' ? (
    <StyledRootContainer
      onPress={onPress}
      disabled={disabled}
      style={[style, { width: size, height: size }]}>
      <StyledImage isOn={isOn} size={size} />
    </StyledRootContainer>
  ) : (
    <StyledRootContainer onPress={onPress} disabled={disabled} style={style}>
      <StyledBoxView>{isOn && <StyledBoxImage />}</StyledBoxView>
    </StyledRootContainer>
  )
}

const StyledRootContainer = styled.TouchableOpacity``

const StyledImage = styled.Image.attrs(({ isOn }: { isOn?: boolean }) => ({
  source: isOn
    ? require('@images/icons/icon-check-mark-on.png')
    : require('@images/icons/icon-check-mark-off.png'),
}))<{ size?: number; isOn?: boolean }>(({ size }) => ({
  height: size !== undefined ? size : 24,
  width: size !== undefined ? size : 24,
  resizeMode: 'contain',
}))

const StyledBoxView = styled.View({
  borderRadius: 5,
  width: 20,
  height: 20,
  borderWidth: 2,
  borderColor: '#D8D8D8',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledBoxImage = styled.Image.attrs({
  source: require('@images/icons/checkbox/icon-check.png'),
  resizeMode: 'contain',
})({
  position: 'absolute',
  width: 24,
  height: 24,
  tintColor: Color.Primary,
})

export default CheckBox
