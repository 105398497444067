/* eslint-disable @typescript-eslint/no-explicit-any */
import AccountManager from '@lib/AccountManager'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import Osidori from '@lib/Osidori'
import { ScreenName } from '@navigation/Screens'
import store from '@redux/store'
import { ActionType } from '@redux/types'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import { Platform } from 'react-native'

export type DeeplinkNavigationParams = {
  name?: string
  screenType?: 'personal' | 'family'
  goBack?: string
  pop?: number | null
} & {
  [key: string]: any
}

const AllowScreenName = [
  'PremiumPlan', // alias
  'PremiumPlanForFreeMember', // alias
  'HomeExpensesScreen', // bug あとでけす
  'HomeExpenses',
  'PremiumPlanForPremiumUser',
  'PremiumPlanLP',
  'PremiumPlanWebLP',
  'FP',
  'MediaWebView',
  'MainMediaWebView',
  'SettingPairing',
  'FinancialAccounts',
  'GoalDetail',
  'Calendar',
  'TransactionDetail',
  'Payoff',
  'TermTransaction', // alias
  'UpdateTermTransaction',
  'WeeklyReportWebView',
  'MonthlyReport',
  'TimelineTop',
  'BudgetSetting',
  'InsuranceWebView',
  'HousingLP',
  'CreateTransactionDetail',
  'FinancialAccountCooperation', // 口座連携
  'EditCategoryTop',
  'TermTransactions',
  'WaitFinancialAccountSync',
  'UpdatePassword',
  'HomeTop',
  'LifePlan',
  'GoalSelectType',
]

const AllowScreenNameAlias: {
  [key: string]: string | ((params?: any) => void)
} = {
  // PremiumPlan: 'PremiumPlanForPremiumUser',
  PremiumPlan: (params?: any) => navigatePremiumPlanLP(params),
  PremiumPlanForFreeMember: (params?: any) => navigatePremiumPlanLP(params),
  TermTransaction: 'UpdateTermTransaction',
  HomeExpensesScreen: 'HomeExpenses', // bug あとでけす
  HousingLP: 'HousingWebView',
}

export const deeplinkNavigation = (props: DeeplinkNavigationParams = {}) => {
  const { name, screenType, goBack, pop, ...params } = props

  Log.info('deeplinkNavigation', { name, params })

  if (screenType) {
    AccountManager.updateAccountMode(
      screenType === 'personal' ? 'user' : 'family',
    )
  }

  if (name) {
    if (store.getState().root.rootScreen === 'Main') {
      if (AllowScreenName.includes(name)) {
        const alias = AllowScreenNameAlias[name]
        if (alias) {
          if (typeof alias === 'string') {
            NavigationService.navigate(alias as ScreenName, params)
          } else if (typeof alias === 'function') {
            alias(params)
          } else {
            // 通常はここには来ない
            Osidori.start()
          }
          return
        } else {
          NavigationService.navigate(name as ScreenName, params)
        }
      } else {
        Log.info(`Unauthorized access to the screen (${params.name})`)
        Osidori.start()
      }
    } else {
      // ログイン処理前
      store.dispatch({
        type: ActionType.SetNavigation,
        navigation: {
          screenName: name as ScreenName,
          params,
        },
      })
      Osidori.start()
    }
  }

  if (goBack) {
    if (AllowScreenName.includes(goBack)) {
      const alias = AllowScreenNameAlias[goBack]
      if (typeof alias === 'string') {
        NavigationService.goBack(goBack)
      } else {
        alias()
      }
      return
    } else {
      Log.info(`Unauthorized access to the screen (${params.name})`)
      Osidori.start()
    }
  }

  if (pop !== undefined) {
    NavigationService.pop(pop || 1)
    return
  }
}

export const navigationFromReduxState = () => {
  const { root: rootState } = store.getState()

  // Account はwebで必要
  if (
    (rootState.rootScreen === 'Main' || rootState.rootScreen === 'Account') &&
    rootState.navigation
  ) {
    const { screenName, params } = rootState.navigation
    Log.info('navigationFromReduxState', { screenName, params })

    if (AllowScreenName.includes(screenName)) {
      const alias = AllowScreenNameAlias[screenName]
      if (alias) {
        if (typeof alias === 'string') {
          NavigationService.navigate(alias as ScreenName, params)
        } else if (typeof alias === 'function') {
          alias()
        }
      } else {
        Log.info(
          'NavigationService.navigate(screenName as ScreenName, params)',
          { screenName, params },
        )
        if (Platform.OS !== 'web') {
          NavigationService.navigate(screenName as ScreenName, params)
        } else {
          setTimeout(() => {
            NavigationService.navigate(screenName as ScreenName, params)
          }, 1)
        }
      }
    } else {
      Log.info(`Unauthorized access to the screen (${screenName})`)
    }

    store.dispatch({
      type: ActionType.SetNavigation,
      navigation: undefined,
    })
  }
}
