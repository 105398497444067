import { RootState } from '@redux/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ReduxState = {
  isVisible: boolean
}

type Reducer = {
  setIsVisibleProgressHUD: (
    state: ReduxState,
    action: PayloadAction<{ isVisible: boolean }>,
  ) => void
}

const initialState: ReduxState = {
  isVisible: false,
}

const slice = createSlice<ReduxState, Reducer>({
  name: 'progressHUD',
  initialState,
  reducers: {
    setIsVisibleProgressHUD: (state, { payload }) => {
      state.isVisible = payload.isVisible
    },
  },
})

export const reducer = slice.reducer
export const { setIsVisibleProgressHUD } = slice.actions
export const selectIsVisibleProgressHUD = (state: RootState) =>
  state.progressHUD.isVisible
