import CalculatorInput from '@components/Calculator/CalculatorInput'
import Color from '@lib/Color'
// import { MaterialTopTabBarOptions } from '@react-navigation/material-top-tabs'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  flex: 1;
`

export const TitleText = styled.Text`
  margin-top: 15px;
  margin-left: 15px;
  font-size: 18px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const StyledCalculatorInput = styled(CalculatorInput)`
  padding-right: 15px;
  height: 40px;
`

export const RemainTextContainer = styled.View`
  margin-top: 10px;
  margin-horizontal: 15px;
  border-top-width: 0.5px;
  border-color: #cccccc;
  align-items: flex-end;
`

export const RemainText = styled.Text<{ over: boolean }>`
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${({ over }) => (over ? 'red' : '#888888')};
`

export const ListHeader = styled.Text`
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 15px;
  font-size: 18px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const SaveButtonContainer = styled.View`
  margin-vertical: 10px;
  margin-horizontal: 15px;
`

// export const tabBarStyles: MaterialTopTabBarOptions = {
//   activeTintColor: Color.Green,
//   inactiveTintColor: '#999',
//   indicatorStyle: {
//     backgroundColor: Color.Green,
//     height: 1,
//   },
//   labelStyle: {
//     fontWeight: '600',
//     fontSize: 15,
//   },
//   style: {
//     elevation: 0,
//     backgroundColor: 'white',
//     borderBottomWidth: 1,
//     borderBottomColor: 'rgba(0,0,0,0.1)',
//   },
// }
