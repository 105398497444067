import { PlSettings } from '@interfaces/Profile'
import { ProfileActions, ProfileState } from './types'

export default (state: ProfileState = {}, action: ProfileActions) => {
  switch (action.type) {
    case 'UpdateUserProfile':
      return { ...state, userProfile: action.userProfile }
    case 'UpdatePartnerProfile':
      return { ...state, partnerProfile: action.partnerProfile }
    case 'UpdateUserStatus':
      return { ...state, userStatus: action.userStatus }
    case 'UpdatePlSettings': {
      if (action.plSettings) {
        const {
          plType,
          plPeriodDate,
          groupPlType,
          groupPlPeriodDate,
        } = action.plSettings
        if (plType === groupPlType && plPeriodDate === groupPlPeriodDate) {
          return {
            ...state,
            plSettings: {
              ...action.plSettings,
              allPlType: plType,
              allPlPeriodDate: plPeriodDate,
            } as PlSettings,
          }
        } else {
          return {
            ...state,
            plSettings: {
              ...action.plSettings,
              allPlType: '',
              allPlPeriodDate: 1,
            } as PlSettings,
          }
        }
      } else {
        return { ...state, plSettings: undefined }
      }
    }
    default:
      return state
  }
}
