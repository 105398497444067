import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import LifePlanEventCard from '@components/LifePlanEventCard'
import SectionListHeader2 from '@components/SectionListHeader2'
import { GoalContent } from '@interfaces/Goal'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import ProgressHUD from '@lib/ProgressHUD'
import { GoalManager } from '@lib/api/Goal'
import { selectGoalCategories, selectGoalEditor } from '@lib/api/Goal/slice'
import { LifePlanEvent, LifePlanManager } from '@lib/api/LifePlan'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/core'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  SectionList,
  SectionListData,
  SectionListRenderItem,
} from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import SelectLifeEventModal from './SelectLifeEventModal'

const ConnectLifePlanScreen: React.FC<
  StackScreenProps<RootStackParamList, 'ConnectLifePlan'>
> = ({ route }) => {
  const isFocused = useIsFocused()

  const editor = useSelector(selectGoalEditor)
  const goalCategories = useSelector(selectGoalCategories)

  const [lifePlanEvents, setLifePlanEvents] = useState<LifePlanEvent[]>()
  const [checkedLifePlanEventId, setCheckedLifePlanEventId] = useState<number>(
    editor.lifeplanEventId ?? 0,
  )
  const [isVisibleSelectLifeEventModal, setIsVisibleSelectLifeEventModal] =
    useState(false)

  const mergedGoalCategories = useMemo(
    () =>
      goalCategories[editor.share ? 'family' : 'user']
        ?.map((v) => v.goalContents)
        .flat() ?? [],
    [goalCategories, editor.share],
  )

  const title = useMemo(() => {
    if (!mergedGoalCategories) return ''
    return (
      mergedGoalCategories.find((v) => v.id === editor.goalContentId)?.name ??
      ''
    )
  }, [editor.goalContentId, mergedGoalCategories])

  const onPressLifeEvent = useCallback((goalContent: GoalContent) => {
    setIsVisibleSelectLifeEventModal(false)
    NavigationService.navigate('CreateLifePlanEvent', { goalContent })
  }, [])

  useEffect(() => {
    if (isFocused) {
      LifePlanManager.getLifePlanEvents()
        .then((lifeplanEvents) => {
          setLifePlanEvents(lifeplanEvents)
        })
        .catch((error) => {
          CommonDialog.showError({ error })
        })
    }
  }, [isFocused])

  const onPressItem = useCallback((lifePlanEvent: LifePlanEvent) => {
    log.info('lifePlanEvent.id', lifePlanEvent.id)
    setCheckedLifePlanEventId(lifePlanEvent.id)
  }, [])

  const renderItem: SectionListRenderItem<LifePlanEvent> = useCallback(
    ({ item }) => {
      return (
        <LifePlanEventCard
          selectable
          lifePlanEvent={item}
          checked={item.id === checkedLifePlanEventId}
          onPress={onPressItem}
        />
      )
    },
    [checkedLifePlanEventId, onPressItem],
  )

  const sessions = useMemo((): SectionListData<LifePlanEvent>[] | undefined => {
    if (!lifePlanEvents) return undefined

    const sameLifeEvents: LifePlanEvent[] = []
    const otherLifeEvents: LifePlanEvent[] = []

    lifePlanEvents.forEach((lifePlanEvent) => {
      if (lifePlanEvent.goalContentId === editor.goalContentId) {
        sameLifeEvents.push(lifePlanEvent)
      } else {
        otherLifeEvents.push(lifePlanEvent)
      }
    })

    return [
      {
        title: '同じカテゴリーのプラン',
        data: sameLifeEvents,
      },
      {
        title: 'その他のライフプラン',
        data: otherLifeEvents,
      },
    ]
  }, [editor.goalContentId, lifePlanEvents])

  const listHeader = useCallback(() => {
    return (
      <StyledHeaderView>
        <StyledHeaderText>
          紐づけるライフプランを選択してください
        </StyledHeaderText>
        <StyledCreateLifeplanButton
          onPress={() => setIsVisibleSelectLifeEventModal(true)}>
          <StyledCreateLifeplanText>
            ＋ 新規ライフイベントを作成
          </StyledCreateLifeplanText>
        </StyledCreateLifeplanButton>
      </StyledHeaderView>
    )
  }, [])

  const updateLifeplanEventId = useCallback(
    async (lifeplanEventId: number) => {
      const updatedEditor = {
        ...editor,
        lifeplanEventId,
      }
      GoalManager.updateEditor(updatedEditor)
      if (route.params.editorMode) {
        NavigationService.goBack()
        return
      }

      try {
        ProgressHUD.show()
        await GoalManager.updateGoal(updatedEditor)
        updatedEditor.goalId &&
          (await GoalManager.fetchGoal(updatedEditor.goalId))
        NavigationService.goBack()
      } catch (error) {
        CommonDialog.showError({ error })
      } finally {
        ProgressHUD.dismiss()
      }
    },
    [editor, route.params.editorMode],
  )

  const onPressSave = useCallback(() => {
    updateLifeplanEventId(checkedLifePlanEventId)
  }, [checkedLifePlanEventId, updateLifeplanEventId])

  return (
    <StyledRootView>
      <CommonHeader title={title} />
      {sessions && (
        <StyledSectionList
          keyExtractor={(item) => item.id.toString()}
          renderItem={renderItem}
          renderSectionHeader={({ section: { title } }) => (
            <SectionListHeader2
              title={title}
              textStyle={{ fontWeight: 'normal' }}
              style={{ marginBottom: 5 }}
            />
          )}
          ListHeaderComponent={listHeader}
          sections={sessions}
        />
      )}
      <StyledAppButton
        title="保存"
        onPress={onPressSave}
        disabled={!checkedLifePlanEventId}
      />
      <SelectLifeEventModal
        isVisible={isVisibleSelectLifeEventModal}
        onPressLifeEvent={onPressLifeEvent}
        onDismiss={() => setIsVisibleSelectLifeEventModal(false)}
      />
    </StyledRootView>
  )
}

const StyledRootView = styled.View({ height: '100%' })

const StyledHeaderView = styled.View({
  padding: 20,
  alignItems: 'center',
})

const StyledHeaderText = styled.Text({
  fontSize: 14,
  color: Color.Black,
})

const StyledCreateLifeplanButton = styled.TouchableOpacity({
  marginTop: 15,
  borderWidth: 1,
  borderColor: Color.Primary,
  borderRadius: 8,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: 44,
})

const StyledCreateLifeplanText = styled.Text({
  fontSize: 16,
  fontWeight: 'bold',
  color: Color.Primary,
})

const StyledSectionList = styled(SectionList<LifePlanEvent>).attrs({
  contentContainerStyle: {
    // paddingBottom: 50,
  },
})({})

const StyledAppButton = styled(AppButton).attrs({
  textStyle: { fontWeight: 'bold' },
})({
  marginHorizontal: 20,
  marginVertical: 10,
})

export default ConnectLifePlanScreen
