import { APIError } from '@lib/api'
import Timeline, {
  GetActivitiesProps,
  GetTimelineActivitiesResponse,
  GetTimelineNoticesResponse,
  GetTimelineNoticesUnreasCountResponse,
} from '@lib/api/Timeline'
import store from '@redux/store'
import {
  updateTimelineActivities,
  updateTimelineNotices,
  updateTimelineNoticesBadgeCount,
} from './types'

class TimelineManager {
  fetchActivities = async (props?: GetActivitiesProps) => {
    const response = await Timeline.getActivities(props)
    if (response.ok) {
      const { activities, nextActivityDate } =
        response.json as GetTimelineActivitiesResponse
      if (props?.nextActivityDate) {
        store.dispatch(
          updateTimelineActivities([
            ...(store.getState().timeline.activities || []),
            ...activities,
          ]),
        )
      } else {
        store.dispatch(updateTimelineActivities(activities))
      }
      return { activities, nextActivityDate }
    } else {
      throw new APIError(response)
    }
  }

  fetchNotices = async () => {
    const response = await Timeline.getNotices()
    if (response.ok) {
      const notices = (response.json as GetTimelineNoticesResponse).notices
      store.dispatch(updateTimelineNotices(notices))
      return notices
    } else {
      throw new APIError(response)
    }
  }

  fetchNoficesUnreadCount = async () => {
    const response = await Timeline.getNoticeUnreadCount()
    if (response.ok) {
      const unreadTotalCount = (
        response.json as GetTimelineNoticesUnreasCountResponse
      ).notices.unreadTotalCount
      store.dispatch(updateTimelineNoticesBadgeCount(unreadTotalCount))
      return unreadTotalCount
    } else {
      throw new APIError(response)
    }
  }

  markAllNoticesAsRead = async () => {
    const response = await Timeline.markAllNoticesAsRead()
    if (response.ok) {
      store.dispatch(updateTimelineNoticesBadgeCount(0))
    } else {
      throw new APIError(response)
    }
  }
}

export default new TimelineManager()
