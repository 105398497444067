import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import PasswordInput from '@components/PasswordInput'
import { APIError } from '@lib/api'
import { UserAPI } from '@lib/api/User'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'

interface Props {}

interface State {
  email: string
  password: string
  newPassword: string
  confirmNewPassword: string
}

class SettingUserInformationScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'SettingUserInformation'>,
  State
> {
  state = {
    email: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  private onPressSendEmail = async () => {
    const { emailType } = this.props.route.params || {
      emailType: 'update',
    }

    try {
      showAppButtonIndicator('SendEmail')

      if (emailType === 'update') {
        const response = await UserAPI.changeEmail({
          users: { email: this.state.email },
        })
        if (!response.ok) throw new APIError(response)
        CommonDialog.showMessage('確認メールを送信しました。')
      } else {
        // activate
        const response = await UserAPI.reauthenticate({
          email: this.state.email,
        })
        if (!response.ok) throw new APIError(response)
        CommonDialog.show(
          '登録メールアドレスに再送しました',
          'メール記載のURLをクリックして認証を完了してください。',
        )
      }
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.status === 400) {
          CommonDialog.showMessage(
            'こちらのメールアドレスはすでに使用されています。',
          )
          return
        }
      }
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('SendEmail')
    }
  }

  private onPressUpdatePassword = async () => {
    try {
      const { password, newPassword, confirmNewPassword } = this.state
      showAppButtonIndicator('UpdatePassword')
      const response = await UserAPI.setupPassowrd({
        users: { password, newPassword, confirmNewPassword },
      })
      if (!response.ok) throw new APIError(response)

      CommonDialog.showMessage(
        'パスワードを変更できました。\n一度ログアウトしますので、新しいパスワードでログインしなおしてください。',
        () => NavigationService.logout(),
      )
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.status === 400) {
          CommonDialog.show(
            'パスワードの変更に失敗しました。',
            'パスワードの内容をお確かめください。\n（パスワードは英数字です）',
          )
          return
        }
      }
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('UpdatePassword')
    }
  }

  render() {
    return (
      <>
        <CommonHeader title="登録情報の変更" />
        <ScrollView style={styles.container}>
          <Text style={styles.subTitleText}>メールアドレスの変更</Text>
          <TextInput
            placeholder="新しいメールアドレスを入力"
            value={this.state.email}
            onChangeText={(email) => this.setState({ email })}
            style={styles.textInput}
            placeholderTextColor={Color.PlaceHolderText}
            keyboardType="email-address"
            autoCapitalize="none"
            returnKeyType="done"
          />
          <AppButton
            title="確認メールを送信する"
            onPress={this.onPressSendEmail}
            style={{ margin: 20 }}
            loadingKey="SendEmail"
            disabled={!this.state.email.isValidEmail()}
          />
          <View
            style={{
              marginTop: 10,
              backgroundColor: '#dddddd',
              width: '100%',
              height: 25,
            }}
          />
          <Text style={styles.subTitleText}>パスワードの変更</Text>
          <Text style={styles.passwordTitleText}>現在のパスワード</Text>
          <PasswordInput
            secureTextEntry={true}
            placeholder="8〜16文字以内の半角英数字"
            value={this.state.password}
            onChangeText={(currentPassword) =>
              this.setState({ password: currentPassword })
            }
            style={styles.textInput}
            placeholderTextColor={Color.PlaceHolderText}
            returnKeyType="done"
          />
          <Text style={styles.passwordTitleText}>新しいパスワード</Text>
          <PasswordInput
            secureTextEntry={true}
            placeholder="8〜16文字以内の半角英数字"
            value={this.state.newPassword}
            onChangeText={(newPassword) => this.setState({ newPassword })}
            style={styles.textInput}
            placeholderTextColor={Color.PlaceHolderText}
            returnKeyType="done"
          />
          <Text style={styles.passwordTitleText}>新しいパスワード（確認）</Text>
          <PasswordInput
            secureTextEntry={true}
            placeholder="8〜16文字以内の半角英数字"
            value={this.state.confirmNewPassword}
            onChangeText={(confirmNewPassword) =>
              this.setState({ confirmNewPassword })
            }
            style={styles.textInput}
            placeholderTextColor={Color.PlaceHolderText}
            returnKeyType="done"
          />
          <AppButton
            title="パスワードを変更する"
            onPress={this.onPressUpdatePassword}
            style={{ margin: 20 }}
            loadingKey="UpdatePassword"
            disabled={
              this.state.password === '' ||
              this.state.newPassword === '' ||
              this.state.newPassword !== this.state.confirmNewPassword
            }
          />
        </ScrollView>
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.LightGray,
  },
  subTitleText: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    fontWeight: 'bold',
    fontSize: 14,
  },
  passwordTitleText: {
    paddingTop: 20,
    paddingBottom: 1,
    paddingLeft: 20,
    fontWeight: 'bold',
    fontSize: 12,
  },
  textInput: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#3a3a3a',
    height: 45,
    borderBottomColor: '#cccccc',
    borderBottomWidth: 1,
    marginHorizontal: 20,
  },
})

export default SettingUserInformationScreen
