/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen'

export {}

declare global {
  const wp: (widthPercent: string | number) => number
  const hp: (heightPercent: string | number) => number
}

const g = global as any
g.wp = widthPercentageToDP
g.hp = heightPercentageToDP
