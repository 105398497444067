import { RootState } from '@redux/store'

export const isVisibleSelector = ({ calculator }: RootState) =>
  !!calculator.isVisible

export const displayValueSelector = ({ calculator }: RootState) =>
  calculator.displayValue || '0'

export const lastValueSelector = ({ calculator }: RootState) =>
  calculator.lastValue || 0

export const lastInputSymbolSelector = ({ calculator }: RootState) =>
  calculator.lastInputSymbol

export const isNotEnteredSelector = ({ calculator }: RootState) =>
  !!calculator.isNotEntered

export const animatedSelector = ({ calculator }: RootState) =>
  !!calculator.animated

export const onChangeSelector = ({ calculator }: RootState) =>
  calculator.onChange

export const initialValueSelector = ({ calculator }: RootState) =>
  calculator.initialValue

export const minusInputSelector = ({ calculator }: RootState) =>
  calculator.minusInput
