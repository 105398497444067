import { setIsVisibleProgressHUD } from '@components/ProgressHUDView/slice'
import store from '@redux/store'

export const ProgressHUD = {
  show: () => {
    store.dispatch(setIsVisibleProgressHUD({ isVisible: true }))
  },
  dismiss: () => {
    store.dispatch(setIsVisibleProgressHUD({ isVisible: false }))
  },
}

export default ProgressHUD
