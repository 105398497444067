import Alert from '@components/Alert'
import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CalculatorInput from '@components/Calculator/CalculatorInput'
import CheckBox from '@components/CheckBox'
import CommonHeader from '@components/CommonHeader'
import CoupleButton from '@components/CoupleButton'
import EllipsizeTextInput from '@components/EllipsizeTextInput'
import FinancialAccountIcon from '@components/FinancialAccountIcon'
import AccountTrackerManager from '@lib/AccountTrackerManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import styled from 'styled-components/native'

const EditFinancialAccountScreen: React.FC<
  StackScreenProps<RootStackParamList, 'EditFinancialAccount'>
> = ({ route }) => {
  const financialAccount = route.params.financialAccount

  const [financialAccountName, setFinancialAccountName] = useState('')
  const [isShared, setIsShared] = useState(false)
  const [amount, setAmount] = useState(0)
  const [categoryType, setCategoryType] = useState<
    'bank' | 'card' | 'emoney' | 'stock' | 'wallet'
  >(financialAccount.type)

  const userAccountType = store.getState().account.accountMode

  useEffect(() => {
    setFinancialAccountName(financialAccount.name)
    setIsShared(userAccountType === 'family')
    setAmount(
      financialAccount.type !== 'stock'
        ? financialAccount.amount || 0
        : financialAccount.balance || 0,
    )
  }, [financialAccount, userAccountType])

  const accountTypeName = useMemo(() => {
    switch (financialAccount.type) {
      case 'bank':
        return '銀行口座'
      case 'card':
        return 'カード'
      case 'emoney':
        return '電子マネー'
      case 'stock':
        return '証券/投資信託'
      case 'wallet':
        return '財布'
    }
  }, [financialAccount])

  const title = useMemo(() => `${accountTypeName}の編集`, [accountTypeName])

  const shareFinancialAccount = useCallback(async () => {
    try {
      showAppButtonIndicator()
      switch (financialAccount.finance) {
        case 'bank':
        case 'card':
        case 'emoney':
          await FinancialManager.updateAccount({
            financialType: financialAccount.finance,
            accountId: financialAccount.accountId,
            name: financialAccountName,
            share: isShared,
          })
          break
        case 'wallet':
          await FinancialManager.updateWallet({
            userAccountType,
            id: financialAccount.accountId,
            name: financialAccountName,
            balance: categoryType === 'card' ? undefined : amount,
            share: isShared,
            type: categoryType,
          })
          break
        case 'stock':
          await FinancialManager.updateStockAccount({
            id: financialAccount.accountId,
            name: financialAccountName,
            share: isShared,
          })
          break
      }
      CommonDialog.showMessage('口座登録情報を変更しました。', () => {
        NavigationService.pop(2)
      })
      if (isShared) {
        TrackingUtils.adjust.trackEvent({ ios: 'lkm933', android: 'x76y56' })
        TrackingUtils.adjust.trackEvent({ ios: 'cupeik', android: 'df52nu' })
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator()
    }
  }, [
    amount,
    categoryType,
    financialAccount,
    financialAccountName,
    isShared,
    userAccountType,
  ])

  const deleteAccount = useCallback(async () => {
    try {
      switch (financialAccount.finance) {
        case 'bank':
        case 'card':
        case 'emoney':
          await FinancialManager.deleteAccount({
            financialType: financialAccount.finance,
            accountId: financialAccount.accountId,
          })
          break
        case 'wallet':
          await FinancialManager.deleteWallet({
            id: financialAccount.accountId,
          })
          break
        case 'stock':
          await FinancialManager.deleteStockAccount({
            id: financialAccount.accountId,
          })
          break
      }
      CommonDialog.showMessage('口座を削除しました。', () => {
        if (financialAccount.finance !== 'stock') {
          NavigationService.pop(2)
          // NavigationService.navigate('FinancialAccountTransactions')
        } else {
          NavigationService.pop(2)
          // NavigationService.navigate('AssetProducts')
        }
      })
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [financialAccount])

  const renderSettingShareView = useCallback(() => {
    return (
      <View style={styles.settingShareView}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 'normal',
            color: Color.DefaultText,
          }}>
          管理する画面
        </Text>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <CoupleButton
            buttonType="single"
            onPress={() => setIsShared(false)}
            isOn={!isShared}
            disabled={!isShared}
            title="個人"
            style={{ width: Math.min(106, wp(27)) }}
          />
          <CoupleButton
            buttonType="couple"
            onPress={() => setIsShared(true)}
            isOn={isShared}
            disabled={isShared}
            title="家族"
            style={{
              width: Math.min(106, wp(27)),
              marginLeft: Math.min(20, wp(3)),
            }}
          />
        </View>
      </View>
    )
  }, [isShared])

  const onChangeFinancialAccountName = useCallback(
    (financialAccountName: string) => {
      setFinancialAccountName(financialAccountName)
    },
    [],
  )

  const onPressEditLoginInfo = useCallback(() => {
    AccountTrackerManager.updateFinancialAccount({
      fncId: financialAccount.fncId,
      act: 'modify',
      navigateBackScreen: route.name,
      backButtonTitle: title,
    })
  }, [financialAccount.fncId, title, route.name])

  const onPressCheckLinkData = useCallback(async () => {
    try {
      const uri = await AccountTrackerManager.getCheckLinkUrl(
        financialAccount.fncId,
      )
      NavigationService.navigate('NavigateAccountTracker', {
        uri,
        backButtonTitle: title,
        navigateBackScreen: route.name,
      })
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [financialAccount.fncId, title, route.name])

  const onPressUpdateButton = useCallback(() => {
    if (userAccountType === 'user' && isShared) {
      Alert.alert(
        '夫婦の画面に移しますか？',
        '口座の残高及び取引履歴等がパートナーにも見られるようになります。本当に夫婦の画面に移しますか？',
        [{ text: 'いいえ' }, { text: 'はい', onPress: shareFinancialAccount }],
      )
    } else {
      shareFinancialAccount()
    }
  }, [isShared, shareFinancialAccount, userAccountType])

  const onPressDeleteButton = useCallback(() => {
    Alert.alert(
      '本当に削除しますか？',
      '口座を削除すると、これまでに取得した入出金データや残高などの情報もすべて削除されます。',
      [{ text: 'いいえ' }, { text: 'はい', onPress: deleteAccount }],
    )
  }, [deleteAccount])

  const renderRightButton = useCallback(
    () => (
      <TouchableOpacity
        onPress={onPressDeleteButton}
        style={{
          paddingHorizontal: 15,
          paddingVertical: 4,
        }}>
        <Image
          source={require('@images/buttons/delete/button-delete.png')}
          style={{
            width: 24,
            height: 34,
            resizeMode: 'contain',
          }}
        />
      </TouchableOpacity>
    ),
    [onPressDeleteButton],
  )

  const onChangeAmount = (amount: number) => setAmount(amount)

  if (!financialAccount) return null

  return (
    <View style={styles.container}>
      <CommonHeader title={title} renderRightButton={renderRightButton} />
      <ScrollView contentContainerStyle={{ paddingVertical: 10 }}>
        <View style={styles.subContainer}>
          <View style={{ marginBottom: 20 }}>
            <Text style={styles.titleText}>表示内容の編集</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
            }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: '#666666',
              }}>
              {accountTypeName}の名前
            </Text>
          </View>
          <View>
            <EllipsizeTextInput
              style={styles.titleBalance}
              onChangeText={onChangeFinancialAccountName}
              value={financialAccountName}
            />
            {categoryType !== 'card' && (
              <View style={styles.wrapBalance}>
                <Text style={styles.balance}>
                  {financialAccount.type !== 'stock' ? '残高' : '評価額'}
                </Text>
                <CalculatorInput
                  editable={financialAccount.finance === 'wallet'}
                  onChange={onChangeAmount}
                  value={amount}
                  style={{
                    flex: 1,
                    width: undefined,
                  }}
                  prefixTextStyle={{
                    color:
                      financialAccount.finance === 'wallet'
                        ? '#3a3a3a'
                        : '#777',
                    fontSize: 22,
                    fontWeight: '500',
                    marginRight: 0,
                  }}
                  textStyle={{
                    color:
                      financialAccount.finance === 'wallet'
                        ? '#3a3a3a'
                        : '#777',
                    fontSize: 22,
                    fontWeight: '500',
                  }}
                />
              </View>
            )}
          </View>
          {userAccountType === 'user' && renderSettingShareView()}

          {financialAccount.finance === 'wallet' && (
            <StyleCategoryView>
              <StyledCategoryTitleText>
                この財布の金融カテゴリー
              </StyledCategoryTitleText>
              <StyledCategoryDetailText>
                財布をどの金融カテゴリーに表示するのかを選択できます。{'\n'}
                （例）銀行口座に設定すると、登録口座の一覧で銀行口座カテゴリーに表示されます。
              </StyledCategoryDetailText>
              <StyleCategoryItemView onPress={() => setCategoryType('bank')}>
                <FinancialAccountIcon financialAccountType="bank" />
                <StyleCategoryNameText>銀行口座</StyleCategoryNameText>
                <CheckBox isOn={categoryType === 'bank'} disabled />
              </StyleCategoryItemView>
              <StyleCategoryItemView onPress={() => setCategoryType('stock')}>
                <FinancialAccountIcon financialAccountType="stock" />
                <StyleCategoryNameText>証券/投資信託</StyleCategoryNameText>
                <CheckBox isOn={categoryType === 'stock'} disabled />
              </StyleCategoryItemView>
              <StyleCategoryItemView onPress={() => setCategoryType('card')}>
                <FinancialAccountIcon financialAccountType="card" />
                <StyleCategoryNameText>カード</StyleCategoryNameText>
                <CheckBox isOn={categoryType === 'card'} disabled />
              </StyleCategoryItemView>
              <StyleCategoryItemView onPress={() => setCategoryType('emoney')}>
                <FinancialAccountIcon financialAccountType="emoney" />
                <StyleCategoryNameText>電子マネー</StyleCategoryNameText>
                <CheckBox isOn={categoryType === 'emoney'} disabled />
              </StyleCategoryItemView>
              <StyleCategoryItemView onPress={() => setCategoryType('wallet')}>
                <FinancialAccountIcon financialAccountType="wallet" />
                <StyleCategoryNameText>財布（現金管理）</StyleCategoryNameText>
                <CheckBox isOn={categoryType === 'wallet'} disabled />
              </StyleCategoryItemView>
            </StyleCategoryView>
          )}

          {!isShared ? (
            <Text style={styles.descriptionText}>
              この{accountTypeName}
              は個人画面に表示されます。パートナーは見ることができません。
            </Text>
          ) : (
            <Text style={styles.descriptionText}>
              この{accountTypeName}
              は家族画面に表示されます。パートナーも見ることができます。
            </Text>
          )}
        </View>
        {financialAccount.finance !== 'wallet' && (
          <>
            <View style={{ backgroundColor: '#eee', height: 25 }} />
            <View style={styles.subContainer}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.titleText}>登録情報の編集</Text>
              </View>
              <View
                style={{
                  paddingTop: 15,
                  paddingBottom: 15,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text
                  style={[
                    {
                      fontSize: 15,
                      fontWeight: 'normal',
                      paddingRight: 15,
                      paddingVertical: 0,
                      flex: 1,
                    },
                  ]}
                  ellipsizeMode="tail"
                  numberOfLines={1}>
                  {financialAccount.name}
                </Text>
                <AppButton
                  title="ログイン情報を修正"
                  onPress={onPressEditLoginInfo}
                  type="secondary-min"
                  textStyle={{
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                  }}
                />
              </View>
              <Text
                style={[
                  styles.descriptionText,
                  { marginTop: 0, textAlign: 'right' },
                ]}>
                ログイン情報変更のため外部サイトに移動します
              </Text>
            </View>
            <View style={styles.subContainer}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.titleText}>取得データの確認</Text>
              </View>
              <View
                style={{
                  paddingTop: 15,
                  paddingBottom: 15,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}>
                <AppButton
                  title="連携データを確認"
                  onPress={onPressCheckLinkData}
                  type="secondary-min"
                  textStyle={{
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                  }}
                />
              </View>
              <Text
                style={[
                  styles.descriptionText,
                  { marginTop: 0, textAlign: 'right' },
                ]}>
                取得データ確認のためにAccount Trackerのサイトに移動します
              </Text>
            </View>
          </>
        )}
        <View style={styles.subContainer}>
          <StyledUpdateButton
            title="この内容で変更する"
            onPress={onPressUpdateButton}
          />
        </View>
      </ScrollView>
    </View>
  )
}

const StyledUpdateButton = styled(AppButton).attrs({
  textStyle: {
    fontWeight: 'bold',
  },
})({
  marginTop: 8,
})

const StyledCategoryTitleText = styled.Text({
  fontSize: 14,
  fontWeight: 'bold',
  color: Color.DefaultText,
  marginBottom: 5,
})

const StyledCategoryDetailText = styled.Text({
  fontSize: 12,
  fontWeight: 'normal',
  color: Color.DefaultText,
  marginBottom: 10,
})

const StyleCategoryView = styled.View({
  marginTop: 30,
})

const StyleCategoryItemView = styled.Pressable({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingVertical: 10,
  borderBottomColor: '#ddd',
  borderBottomWidth: 1,
  marginHorizontal: -20,
  paddingHorizontal: 20,
})

const StyleCategoryNameText = styled.Text({
  flex: 1,
  marginLeft: 10,
  fontSize: 16,
  fontWeight: 'normal',
  color: '#3a3a3a',
})

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  balance: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#3a3a3a',
  },
  wrapBalance: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 52,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  settingShareView: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 52,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    alignItems: 'center',
  },
  titleBalance: {
    fontSize: 16,
    fontWeight: 'normal',
    height: 44,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  shareButtonImage: {
    resizeMode: 'contain',
  },
  titleText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3a3a3a',
  },
  descriptionText: {
    fontSize: 11,
    fontWeight: 'normal',
    color: '#888',
    marginTop: 16,
  },
})

export default EditFinancialAccountScreen
