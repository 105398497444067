import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import CommonHeader from '@components/CommonHeader'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import {
  Linking,
  NativeEventSubscription,
  Platform,
  StyleSheet,
  View,
} from 'react-native'
import {
  WebView,
  WebViewMessageEvent,
  WebViewNavigation,
} from 'react-native-webview'

interface Props {}

interface State {
  uri: string
}

class AccountTrackerScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'AccountTracker'>,
  State
> {
  private backHandler?: NativeEventSubscription

  state = {
    uri: 'about:blank',
  }

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.addListener(this.goBack)

    const { uri } = this.props.route.params
    this.setState({ uri })
  }

  componentWillUnmount() {
    this.backHandler!.remove()
  }

  private goBack = () => {
    const navigateBackScreen = this.props.route.params?.navigateBackScreen
    if (navigateBackScreen) {
      NavigationService.navigate(navigateBackScreen)
    } else {
      NavigationService.pop(2)
    }
    // NavigationService.goBack('FinancialAccountCooperation')
  }

  private onShouldStartLoadWithRequest = (event: WebViewNavigation) => {
    if (Platform.OS === 'android') {
      if (/^osidori:/.test(event.url)) {
        return false
      }
    } else {
      // ios
      if (/^osidori:/.test(event.url)) {
        // DeepLinkService.open(event.url) // 必要なくなったっぽい (しばらく残して後でAndroidと統合)
        return false
      }
    }

    // 外部サイトの場合は、外部ブラウザを開く
    if (!/^https:\/\/(?:[^/]*\.)*369webcash\.com\//.test(event.url)) {
      Linking.openURL(event.url).catch((error) => Log.info(event.url, error))
      return false
    }

    return true
  }

  onMessage = (event: WebViewMessageEvent) => {
    const response = JSON.parse(event.nativeEvent.data)
    if (response.RSLT_CD === 'ERR002002') {
      this.setState({ uri: this.state.uri.replace('ACT=scrp', 'ACT=modify') })
    }
  }

  render() {
    // const injectJS = `
    //   (function(open) {
    //     XMLHttpRequest.prototype.open = function() {
    //       this.addEventListener("readystatechange", function() {
    //         if (this.readyState == XMLHttpRequest.DONE && this.responseURL.endsWith('openscrpr001.jct')) {
    //           window.ReactNativeWebView.postMessage(this.responseText);
    //         }
    //       }, false);
    //       open.apply(this, arguments);
    //     };
    //   })(XMLHttpRequest.prototype.open);
    // `
    return (
      <View style={styles.container}>
        <CommonHeader
          onPressLeftButton={this.goBack}
          leftButtonTitle={this.props.route.params?.backButtonTitle}
        />
        <WebView
          source={{ uri: this.state.uri }}
          startInLoadingState={true}
          renderLoading={() => <AbsoluteActivityIndicator />}
          onShouldStartLoadWithRequest={this.onShouldStartLoadWithRequest}
          // injectedJavaScript={injectJS}
          // onMessage={this.onMessage}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default AccountTrackerScreen
