import { Image } from '@components'
import ActionSheet from '@components/ActionSheet'
import Alert from '@components/Alert'
import AmountInput from '@components/AmountInput'
import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CategoryImage from '@components/CategoryImage'
import CommonHeader from '@components/CommonHeader'
import CoupleButton from '@components/CoupleButton'
import DatePicker from '@components/DatePicker'
import EllipsizeTextInput from '@components/EllipsizeTextInput'
import FaceIcon from '@components/FaceIcon'
import TermSegmentedPicker from '@components/TermSegmentedPicker'
import TintedImage from '@components/TintedImage'
import { FinancialAccountType, PaymentUserType } from '@interfaces/Financial'
import { Gender } from '@interfaces/Gender'
import { AccountState } from '@lib/AccountManager/types'
import CategoryManager, { CategoryUnit } from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import GlobalFontSizes from '@lib/GlobalFontSizes'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import ProfileManager from '@lib/ProfileManager'
import SessionManager from '@lib/SessionManager'
import TermTransactionManager from '@lib/TermTransactionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import {
  CreateTermTransactionProps,
  TermTransactionDetail,
  UpdateTermTransactionProps,
} from '@lib/api/TermTransaction'
import Transfer from '@lib/api/Transfer'
import { UserProfile } from '@lib/api/User'
import { WalletEntity } from '@lib/api/Wallet'
import {
  RootStackParamList,
  TermTransactionScreenParams,
  TransferSelectTransactionCallback,
} from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import { getPartnerGender, getUserGender } from '@redux/utils'
import moment from 'moment'
import React from 'react'
import {
  ImageSourcePropType,
  Platform,
  Pressable,
  ScrollView,
  StyleProp,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { Item } from 'react-native-picker-select'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { connect } from 'react-redux'
import { PremiumView2 } from '../EditCategory/functions'
import { navigatePremiumPlanLP } from '../PremiumPlan/index'
interface StateProps {
  userProfile?: UserProfile
  account: AccountState
}

interface State {
  transactionUserId?: number
  accountId?: number
  termTransactionId?: number
  amount: number
  startDate: string
  endDate: string
  categoryName1: string
  categoryName2: string
  subCategoryId?: string
  usedLocation: string
  paymentName: string
  isShared: boolean
  isAccountShared?: boolean
  selectedTab: TabType
  isTransactionLoaded: boolean
  isDisplayStartDatePicker: boolean
  isDisplayEndDatePicker: boolean
  isDisplayTermPicker: boolean
  isIncludeBs: boolean
  memo: string
  headerTitle?: string
  walletId?: number | null
  isDisplayedPremiumPlanView: boolean
  yearlySelections: {
    month: string
    day: string
  }
  monthlySelection: number // 1-31
  weekdaySelection: Weekday
  term?: 'daily' | 'weekly' | 'monthly' | 'yearly'
  termToWeekly?: Weekday
  termToMonthly?: number
  termToYearly?: string // 4桁の文字列で月日
  paymentUserType: PaymentUserType
  // transfer
  transferId?: number
  fromType?: string
  fromFinancialAccountId?: number
  fromTransactionId?: number
  fromWalletIsFamily: boolean
  fromFinancialAccountName: string
  toType?: string
  toWalletIsFamily: boolean
  toFinancialAccountId?: number
  toTransactionId?: number
  toFinancialAccountName: string
  isVisibleSegmentedPicker: boolean
  segmentedPickerDate: Date
}

enum ActionSheetIndex {
  Yearly = 0,
  Monthly = 1,
  Weekly = 2,
  Daily = 3,
  Cancel = 4,
}

enum PaymentUserTypeActionSheetIndex {
  User = 0,
  Family = 1,
  Pertner = 2,
  Cancel = 3,
}

type TabType = 'expense' | 'income' | 'transfer'
type Weekday =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

class TermTransactionScreen extends React.Component<
  StateProps & StackScreenProps<RootStackParamList, 'TermTransaction'>,
  State
> {
  defaultState = () => {
    const yyyymmdd = moment().format('YYYY-MM-DD')

    const defaultParams: State = {
      amount: 0,
      startDate: yyyymmdd,
      endDate: yyyymmdd,
      categoryName1: '未分類',
      categoryName2: '未分類',
      subCategoryId: '387',
      usedLocation: '',
      paymentName: '',
      isAccountShared: this.props.account.accountMode === 'family',
      isShared: this.props.account.accountMode === 'family',
      selectedTab: 'expense',
      isTransactionLoaded: false,
      isDisplayStartDatePicker: false,
      isDisplayEndDatePicker: false,
      isIncludeBs: true,
      memo: '',
      walletId: 0,
      isDisplayedPremiumPlanView: false,
      yearlySelections: {
        month: '01',
        day: '01',
      },
      monthlySelection: 1,
      weekdaySelection: 'sunday',
      paymentUserType:
        this.props.account.accountMode === 'user'
          ? PaymentUserType.User
          : PaymentUserType.Family,
      isDisplayTermPicker: false,
      fromWalletIsFamily: false,
      fromFinancialAccountName: '',
      toWalletIsFamily: false,
      toFinancialAccountName: '',
      isVisibleSegmentedPicker: false,
      segmentedPickerDate: new Date('2000-01-01'),
    }

    return defaultParams
  }

  private amountInput = React.createRef<AmountInput>()
  // private termYearlyPicker = React.createRef<SegmentedPicker>()
  // private termMonthlyPicker = React.createRef<SegmentedPicker>()
  // private termWeekdayPicker = React.createRef<SegmentedPicker>()

  private wallets: {
    user: WalletEntity[]
    family: WalletEntity[]
  } = {
    user: [],
    family: [],
  }

  private listeners: string[] = []
  private navigationParams!: TermTransactionScreenParams

  state = this.defaultState()

  async componentDidMount() {
    this.navigationParams = this.props.route.params

    await this.getWallets()

    if (this.navigationParams.termTransactionId) {
      // 更新
      this.getTermTransactionDetail(this.navigationParams.termTransactionId)
    } else {
      // 新規作成
      const isFamily = this.navigationParams.userAccountType === 'family'
      this.setState({
        isAccountShared: isFamily,
        isShared: isFamily,
        isTransactionLoaded: true,
      })
    }

    const listener = OsidoriEvent.addListener(
      'DidSelectSubCategory',
      this.chooseCategoryHandler,
    )
    listener && this.listeners.push(listener)
  }

  private getTermTransactionDetail = async (termTransactionId: number) => {
    try {
      this.setState({ isTransactionLoaded: false })

      const userAccountType =
        this.navigationParams.userAccountType || SessionManager.getAccountMode()

      const detail = await TermTransactionManager.getTermTransaction({
        userAccountType,
        termTransactionId,
      })
      this.setTransactionDetail(detail)
    } catch (error) {
      CommonDialog.showError({ error, onPress: NavigationService.goBack })
    } finally {
      this.setState({ isTransactionLoaded: true })
    }
  }

  private setTransactionDetail = (detail: TermTransactionDetail) => {
    this.setState({
      headerTitle: detail.usedLocation,
      termTransactionId: detail.termTransactionId,
      amount: detail.amount,
      startDate: moment(detail?.startDate).format('YYYY-MM-DD'),
      endDate: moment(detail?.endDate).format('YYYY-MM-DD'),
      categoryName1: detail.categoryName1,
      categoryName2: detail.categoryName2,
      subCategoryId: detail.atTransactionCategoryId,
      usedLocation: detail.usedLocation != undefined ? detail.usedLocation : '',
      isShared: detail.isShared,
      isAccountShared: detail.isAccountShared,
      selectedTab: detail.transfer
        ? 'transfer'
        : detail.amount <= 0
        ? 'expense'
        : 'income',
      isIncludeBs: !detail.isIgnored,
      memo: detail.memo !== null ? detail.memo : '',
      walletId: detail.paymentMethodId ? detail.paymentMethodId : 0,
      term: detail.term,
      termToYearly: detail.termToYearly,
      termToMonthly: detail.termToMonthly,
      termToWeekly: detail.termToWeekly,
      monthlySelection: detail.termToMonthly || 1,
      yearlySelections: {
        month: detail.termToYearly ? detail.termToYearly.substring(0, 2) : '01',
        day: detail.termToYearly ? detail.termToYearly.substring(2, 4) : '01',
      },
      weekdaySelection: detail.termToWeekly || 'sunday',
      paymentUserType: detail.paymentUserType,
    })

    // 振替の場合
    if (detail.transfer) {
      const fromWalletIsFamily = !!this.wallets['family'].find(
        (v) => v.id === detail?.transfer?.fromAccountId,
      )
      const toWalletIsFamily = !!this.wallets['family'].find(
        (v) => v.id === detail?.transfer?.toAccountId,
      )

      this.setState({
        transferId: detail.transfer.transferId,
        fromType: detail.transfer.fromType,
        fromWalletIsFamily,
        fromFinancialAccountId: detail.transfer.fromAccountId,
        fromFinancialAccountName: detail.transfer.fromAccountName,
        fromTransactionId: detail.transfer.fromTransactionId,
        toType: detail.transfer.toType,
        toWalletIsFamily,
        toFinancialAccountId: detail.transfer.toAccountId,
        toFinancialAccountName: detail.transfer.toAccountName,
        toTransactionId: detail.transfer.toTransactionId,
      })
    }
  }

  componentWillUnmount() {
    OsidoriEvent.removeListener(this.listeners)
  }

  private getWallets = async () => {
    try {
      this.wallets.user = await FinancialManager.getWallets({
        userAccountType: 'user',
        sort: false,
      })
      this.wallets.family = await FinancialManager.getWallets({
        userAccountType: 'family',
        sort: false,
      })
    } catch (error) {
      CommonDialog.showError({
        error,
        onPress: NavigationService.goBack,
      })
    }
  }

  private renderItem = (props: {
    categoryId?: string
    icon?: ImageSourcePropType
    title?: string
    content: string
    isDisplayRightIcon?: boolean
    onPress?: () => void
    placeholder?: string
    style?: StyleProp<ViewStyle>
  }) => {
    const {
      categoryId,
      title,
      icon,
      content,
      isDisplayRightIcon,
      onPress,
      placeholder,
      style,
    } = props
    const textStyle = content === '' ? styles.placeholder : styles.content
    const text = content !== '' ? content : placeholder || ''
    return (
      <TouchableOpacity
        onPress={onPress}
        disabled={!isDisplayRightIcon}
        style={[styles.wrapContent, style]}>
        {categoryId && <CategoryImage categoryId={categoryId} />}
        {icon && (
          <Image
            source={icon}
            style={{ width: 20, height: 20, resizeMode: 'contain' }}
          />
        )}
        <Text>{title}</Text>
        <Text style={textStyle}>{text}</Text>
        {isDisplayRightIcon && (
          <Image
            source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
            style={styles.iconDown}
          />
        )}
      </TouchableOpacity>
    )
  }

  private renderWalletTypeView = (type: 'from' | 'to') => {
    const { fromWalletIsFamily, toWalletIsFamily } = this.state
    const isFamily = type === 'from' ? fromWalletIsFamily : toWalletIsFamily

    return (
      <View style={[styles.settingShareView, { borderBottomWidth: 0 }]}>
        <Text
          style={{
            fontSize: 12,
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {type === 'from' ? '振替元' : '振替先'}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <CoupleButton
            buttonType="single"
            onPress={() =>
              type === 'from'
                ? this.setState({ fromWalletIsFamily: false })
                : this.setState({ toWalletIsFamily: false })
            }
            isOn={!isFamily}
            title={ProfileManager.getName('user')}
            disabled={!isFamily}
          />
          <CoupleButton
            buttonType="couple"
            onPress={() =>
              type === 'from'
                ? this.setState({ fromWalletIsFamily: true })
                : this.setState({ toWalletIsFamily: true })
            }
            isOn={isFamily}
            title="家族"
            disabled={isFamily}
            style={{ marginLeft: 8 }}
          />
        </View>
      </View>
    )
  }

  private onPressPaymentUserType = () => {
    ActionSheet.showActionSheetWithOptions(
      Platform.OS === 'ios'
        ? {
            options: [
              ProfileManager.getName('user'),
              '家族',
              ProfileManager.getName('partner'),
              'キャンセル',
            ],
            cancelButtonIndex: PaymentUserTypeActionSheetIndex.Cancel,
          }
        : {
            options: [
              ProfileManager.getName('user'),
              '家族',
              ProfileManager.getName('partner'),
            ],
          },
      (index: number) => {
        if (index === PaymentUserTypeActionSheetIndex.User) {
          this.setState({ paymentUserType: PaymentUserType.User })
        } else if (index === PaymentUserTypeActionSheetIndex.Family) {
          this.setState({ paymentUserType: PaymentUserType.Family })
        } else if (index === PaymentUserTypeActionSheetIndex.Pertner) {
          this.setState({ paymentUserType: PaymentUserType.Partner })
        }
      },
    )
  }

  private renderSettingPaymentUserType = () => {
    const items = {
      [PaymentUserType.User]: ProfileManager.getName('user'),
      [PaymentUserType.Family]: '家族',
      [PaymentUserType.Partner]: ProfileManager.getName('partner'),
    }

    return (
      <TouchableOpacity
        onPress={this.onPressPaymentUserType}
        style={[styles.wrapContent, { flex: -1 }]}>
        {this.state.paymentUserType === PaymentUserType.User ? (
          <FaceIcon faceType={getUserGender()} />
        ) : this.state.paymentUserType === PaymentUserType.Partner ? (
          <FaceIcon faceType={getPartnerGender()} />
        ) : (
          <FaceIcon faceType={[Gender.Male, Gender.Female]} />
        )}
        <Text style={styles.paymentPlaceText}>
          {this.state.selectedTab === 'expense' ? '支払い担当' : '受取り担当'}
        </Text>
        <Text style={[styles.content, { textAlign: 'right', marginRight: 15 }]}>
          {items[this.state.paymentUserType]}
        </Text>
        <Image
          source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
          style={styles.iconDown}
        />
      </TouchableOpacity>
    )
  }

  private renderSettingShareView = () => {
    const { isShared } = this.state
    return (
      <View style={styles.settingShareView}>
        <Text
          style={{
            fontSize: 12,
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          表示する画面
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <CoupleButton
            buttonType="single"
            onPress={() => this.onPressIsTransactionSharedButton(false)}
            isOn={!isShared}
            title="個人画面"
            disabled={!isShared}
          />
          <CoupleButton
            buttonType="couple"
            onPress={() => this.onPressIsTransactionSharedButton(true)}
            isOn={isShared}
            title="家族画面"
            disabled={isShared}
            style={{ marginLeft: 8 }}
          />
        </View>
      </View>
    )
  }

  private onPressTab = (selectedTab: TabType) => {
    const amount =
      selectedTab === 'income'
        ? Math.abs(this.state.amount)
        : -Math.abs(this.state.amount)
    const categoryName1 = selectedTab === 'expense' ? '未分類' : '入金'
    const categoryName2 = selectedTab === 'expense' ? '未分類' : 'その他入金'
    const subCategoryId = selectedTab === 'expense' ? '387' : '394'
    this.setState({
      selectedTab,
      amount,
      categoryName1,
      categoryName2,
      subCategoryId,
    })
  }

  private renderTab = () => {
    const isExpense = this.state.selectedTab === 'expense'
    const isIncome = this.state.selectedTab === 'income'
    const isTransfer = this.state.selectedTab === 'transfer'

    return (
      <View style={styles.tab}>
        <TouchableOpacity
          onPress={() => this.onPressTab('expense')}
          style={[
            styles.wrapTab,
            isExpense ? styles.wrapTabWithBorder : styles.wrapTabWithBorderGray,
          ]}
          disabled={isExpense}>
          <Text
            style={[
              styles.tabText,
              isExpense ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            支出
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onPressTab('income')}
          style={[
            styles.wrapTab,
            isIncome ? styles.wrapTabWithBorder : styles.wrapTabWithBorderGray,
          ]}
          disabled={isIncome}>
          <Text
            style={[
              styles.tabText,
              isIncome ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            収入
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onPressTab('transfer')}
          style={[
            styles.wrapTab,
            isTransfer
              ? styles.wrapTabWithBorder
              : styles.wrapTabWithBorderGray,
          ]}
          disabled={isTransfer}>
          <Text
            style={[
              styles.tabText,
              isTransfer ? styles.tabTextOn : styles.tabTextOff,
            ]}>
            振替
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  private handleStartDatePicked = (date: Date) => {
    this.setState({
      startDate: moment(date).format('YYYY-MM-DD'),
      isDisplayStartDatePicker: false,
    })
  }

  private handleEndDatePicked = (date: Date) => {
    this.setState({
      endDate: moment(date).format('YYYY-MM-DD'),
      isDisplayEndDatePicker: false,
    })
  }

  private onPressTermItem = () => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: ['毎年', '毎月', '毎週', '毎日', 'キャンセル'],
        cancelButtonIndex: ActionSheetIndex.Cancel,
      },
      (index: number) => {
        if (index === ActionSheetIndex.Yearly) {
          this.setState({
            term: 'yearly',
            isVisibleSegmentedPicker: true,
          })
        } else if (index === ActionSheetIndex.Monthly) {
          this.setState({ term: 'monthly', isVisibleSegmentedPicker: true })
        } else if (index === ActionSheetIndex.Weekly) {
          this.setState({ term: 'weekly', isVisibleSegmentedPicker: true })
        } else if (index === ActionSheetIndex.Daily) {
          this.setState({ term: 'daily' })
        }
      },
    )
  }

  private chooseCategoryHandler = (categoryUnit: CategoryUnit) => {
    const categoryName1 =
      CategoryManager.getCategoryIconInfo(categoryUnit.categoryId)
        ?.categoryName || ''
    this.setState({
      categoryName1,
      categoryName2: categoryUnit.name,
      subCategoryId: categoryUnit.subCategoryId,
    })
  }

  private onPressIsTransactionSharedButton = (isTransactionShared: boolean) => {
    this.setState({ isShared: isTransactionShared })

    if (this.state.subCategoryId && !+this.state.subCategoryId) {
      // 独自に作成したカテゴリの場合は、カテゴリを初期値に戻す
      const categoryName1 =
        this.state.selectedTab === 'expense' ? '未分類' : '入金'
      const categoryName2 =
        this.state.selectedTab === 'expense' ? '未分類' : 'その他入金'
      const subCategoryId = this.state.selectedTab === 'expense' ? '387' : '394'
      this.setState({
        categoryName1,
        categoryName2,
        subCategoryId,
      })
    }
  }

  private onPressCategory = async () => {
    try {
      const userAccountType = this.state.isShared ? 'family' : 'user'
      await CategoryManager.fetchCategories({ accountType: userAccountType })

      if (this.state.selectedTab === 'expense') {
        const params = {
          categoryType: this.state.selectedTab,
          userAccountType,
          isSelectable: true,
          leftButtonType: 'modal',
        }
        NavigationService.navigate('CategoryPickerTop', params)
      } else if (this.state.selectedTab === 'income') {
        const params = {
          category: CategoryManager.categoriesIncome(userAccountType)[0],
          userAccountType,
          isSelectable: true,
          leftButtonType: 'modal',
        }
        NavigationService.navigate('CategoryPickerSub', params)
      }
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  private onPressSaveButton = async () => {
    showAppButtonIndicator('save')

    const createParams: CreateTermTransactionProps = {
      // userAccountType: this.state.isAccountShared ? 'family' : 'user',
      share: this.state.isShared,
      usedLocation: this.state.usedLocation,
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      term: this.state.term!,
      termToWeekly:
        this.state.term === 'weekly' ? this.state.weekdaySelection : undefined,
      termToMonthly:
        this.state.term === 'monthly' ? this.state.monthlySelection : undefined,
      termToYearly:
        this.state.term === 'yearly'
          ? `0${this.state.yearlySelections.month}`.slice(-2) +
            `0${this.state.yearlySelections.day}`.slice(-2)
          : undefined,
      title:
        this.state.usedLocation.length > 0
          ? this.state.usedLocation
          : this.state.categoryName2,
      amount: this.state.amount,
      atTransactionCategoryId: this.state.subCategoryId ?? '',
      ignore: !this.state.isIncludeBs,
      memo: this.state.memo,
      paymentMethodType: this.state.walletId ? 'wallet' : undefined,
      paymentMethodId: this.state.walletId ? this.state.walletId : undefined,
      paymentUserType: this.state.paymentUserType,
    }

    try {
      if (this.isUpdateMode()) {
        const updateParams: UpdateTermTransactionProps = {
          ...createParams,
          termTransactionId: this.state.termTransactionId!,
        }
        await TermTransactionManager.updateTermTransaction(updateParams)
      } else {
        await TermTransactionManager.createTermTransaction(createParams)
      }

      CommonDialog.showMessage(
        `繰り返し明細を${this.isCreateMode() ? '作成' : '更新'}しました。`,
        NavigationService.goBack,
      )
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.errorCode === '004103') {
          this.setState({ isDisplayedPremiumPlanView: true })
          TrackingUtils.repro.track('【Screen】upper limit_repeat', 'Screen')
          return
        }
      }
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('save')
    }
  }

  private onPressSaveTransferButton = async () => {
    showAppButtonIndicator('save')

    if (!this.state.term) return

    try {
      if (this.isUpdateMode()) {
        // 振替更新
        const props = {
          transferId: this.state.transferId || 0,
          fromWalletId:
            this.state.fromType === 'wallet' ||
            this.state.fromType === 'manually_created'
              ? this.state.fromFinancialAccountId
              : undefined,
          fromAtUserBankAccountId:
            this.state.fromType === 'bank'
              ? this.state.fromFinancialAccountId
              : undefined,
          fromAtUserEmoneyServiceAccountId:
            this.state.fromType === 'emoney'
              ? this.state.fromFinancialAccountId
              : undefined,
          // fromUserManuallyCreatedTransactionId:
          //   this.state.fromType === 'wallet' ||
          //   this.state.fromType === 'manually_created'
          //     ? this.state.fromTransactionId
          //     : undefined,
          // fromAtUserBankTransactionId:
          //   this.state.fromType === 'bank'
          //     ? this.state.fromTransactionId
          //     : undefined,
          // fromAtUserEmoneyTransactionId:
          //   this.state.fromType === 'emoney'
          //     ? this.state.fromTransactionId
          //     : undefined,
          toWalletId:
            this.state.toType === 'wallet' ||
            this.state.toType === 'manually_created'
              ? this.state.toFinancialAccountId
              : undefined,
          toAtUserBankAccountId:
            this.state.toType === 'bank'
              ? this.state.toFinancialAccountId
              : undefined,
          toAtUserEmoneyServiceAccountId:
            this.state.toType === 'emoney'
              ? this.state.toFinancialAccountId
              : undefined,
          // toUserManuallyCreatedTransactionId:
          //   this.state.toType === 'wallet' ||
          //   this.state.toType === 'manually_created'
          //     ? this.state.toTransactionId
          //     : undefined,
          // toAtUserBankTransactionId:
          //   this.state.toType === 'bank'
          //     ? this.state.toTransactionId
          //     : undefined,
          // toAtUserEmoneyTransactionId:
          //   this.state.toType === 'emoney'
          //     ? this.state.toTransactionId
          //     : undefined,
          memo: this.state.memo,
          ...(this.state.fromType === 'wallet' ||
          this.state.fromType === 'manually_created'
            ? {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                term: this.state.term,
                termToWeekly: this.state.termToWeekly,
                termToMonthly: this.state.termToMonthly,
                termToYearly: this.state.termToYearly,
              }
            : {}),
        }
        const response = await Transfer.updateTransfer(props)
        if (!response.ok) throw new APIError(response)
        OsidoriEvent.emit(OsidoriEventName.DidUpdateTransfer, props)

        CommonDialog.showMessage(
          '振替明細を更新しました',
          NavigationService.goBack,
        )
      } else {
        // 振替作成
        const props = {
          fromWalletId: this.state.fromFinancialAccountId || 0,
          toWalletId: this.state.toFinancialAccountId || 0,
          amount: this.state.amount,
          memo: this.state.memo,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          term: this.state.term,
          termToWeekly: this.state.termToWeekly,
          termToMonthly: this.state.termToMonthly,
          termToYearly: this.state.termToYearly,
        }
        const response = await Transfer.createTransfer(props)
        if (!response.ok) throw new APIError(response)
        OsidoriEvent.emit(OsidoriEventName.DidCreateTransfer, props)

        CommonDialog.showMessage(
          '振替明細を作成しました',
          NavigationService.goBack,
        )
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('save')
    }
  }

  private deleteTransaction = async () => {
    const { termTransactionId } = this.state
    if (!termTransactionId) return

    showAppButtonIndicator('delete')
    TermTransactionManager.deleteTermTransaction({
      userAccountType: this.state.isAccountShared ? 'family' : 'user',
      termTransactionId,
    })
      .then(() => {
        CommonDialog.showMessage(
          '繰り返し明細を削除しました',
          NavigationService.goBack,
        )
      })
      .finally(() => {
        hideAppButtonIndicator('delete')
      })
  }

  /** 振替解除ボタンのタップ */
  private onPressDeleteTransferButton = async () => {
    const { termTransactionId } = this.state
    if (!termTransactionId) return

    try {
      showAppButtonIndicator('delete')

      await TermTransactionManager.deleteTermTransaction({
        userAccountType: this.state.isAccountShared ? 'family' : 'user',
        termTransactionId,
      })
      OsidoriEvent.emit(OsidoriEventName.DidDeleteTransfer, {
        userAccountType: this.state.isAccountShared ? 'family' : 'user',
        termTransactionId,
      })

      CommonDialog.showMessage('振替を解除しました', NavigationService.goBack)
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('delete')
    }
  }

  private onChangeAmount = (amount: number) => {
    const type = this.state.selectedTab
    if (
      (type === 'income' && amount < 0) ||
      (type === 'expense' && amount > 0)
    ) {
      amount = -amount
    }
    this.setState({ amount })
  }

  private isCreateMode = () => !this.navigationParams?.termTransactionId
  private isUpdateMode = () => !!this.navigationParams?.termTransactionId

  private goBack = () => {
    if (this.state.isDisplayedPremiumPlanView) {
      this.setState({ isDisplayedPremiumPlanView: false })
    } else {
      NavigationService.goBack()
    }
  }

  private onChangeUsedLocation = (usedLocation: string) =>
    this.setState({ usedLocation })

  private onChangeMemo = (memo: string) => this.setState({ memo })

  private selectTransferAccountHandler: TransferSelectTransactionCallback =
    (props: {
      transferDirection: 'from' | 'to'
      financialAccountType: FinancialAccountType
      financialAccountId: number
      financialAccountName: string
      transactionId?: number
    }) => {
      if (props.transferDirection === 'from') {
        this.setState({
          fromType: props.financialAccountType,
          fromFinancialAccountId: props.financialAccountId,
          fromFinancialAccountName: props.financialAccountName,
          fromTransactionId: props.transactionId,
        })
      } else {
        this.setState({
          toType: props.financialAccountType,
          toFinancialAccountId: props.financialAccountId,
          toFinancialAccountName: props.financialAccountName,
          toTransactionId: props.transactionId,
        })
      }
    }

  private title = () => {
    if (this.state.isTransactionLoaded) {
      return this.isCreateMode()
        ? '繰り返し明細の作成'
        : this.state.transferId
        ? '振替明細'
        : '取引明細' // TODO
    } else {
      return ''
    }
  }

  render() {
    const leftButtonType = this.state.isTransactionLoaded
      ? this.isUpdateMode()
        ? 'stack'
        : 'modal'
      : 'hidden'
    const leftButtonTitle = this.isUpdateMode() ? '戻る' : ''

    // wallets
    let wallets: Item[]
    if (this.state.paymentUserType === PaymentUserType.Family) {
      wallets =
        this.wallets.family.map((v) => ({
          label: v.name,
          value: v.id,
        })) || []
    } else if (this.state.paymentUserType === PaymentUserType.User) {
      wallets =
        this.wallets.user.map((v) => ({
          label: v.name,
          value: v.id,
        })) || []
    } else {
      wallets = []
    }
    wallets.push({ label: '現金', value: 0 })
    const walletId = wallets.find((v) => v.value === this.state.walletId)
      ? this.state.walletId
      : 0

    return (
      <View style={styles.container}>
        <CommonHeader
          leftButtonType={leftButtonType}
          leftButtonTitle={leftButtonTitle}
          title={this.title()}
          onPressLeftButton={this.goBack}
        />
        <View style={{ flex: 1 }}>
          {this.state.isTransactionLoaded &&
            this.state.selectedTab !== 'transfer' && (
              <ScrollView>
                {this.renderTab()}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: this.isCreateMode() ? 10 : 20,
                  }}>
                  {this.isCreateMode() || (
                    <Text
                      style={{
                        flex: 1,
                        textAlign: 'right',
                        marginRight: 30,
                        fontSize: 16,
                        fontWeight: 'normal',
                      }}
                      onPress={() => this.amountInput.current?.focus()}>
                      {this.state.amount < 0 ? '支出' : '収入'}
                    </Text>
                  )}
                  <AmountInput
                    ref={this.amountInput}
                    style={styles.wrapPrice}
                    value={this.state.amount}
                    onChange={this.onChangeAmount}
                    editable={true}
                    autoFocus={!this.isUpdateMode()}
                    containerStyle={{
                      flex: this.isCreateMode() ? 1 : 0,
                    }}
                  />
                </View>
                <View style={styles.wrapItem}>
                  <View style={styles.includeBsView}>
                    <Text style={styles.includeBsText}>
                      収支の計算対象に入れる
                    </Text>
                    <Switch
                      onValueChange={() => {
                        this.setState({ isIncludeBs: !this.state.isIncludeBs })
                      }}
                      value={this.state.isIncludeBs}
                    />
                  </View>
                  {this.renderItem({
                    // icon: CategoryManager.customIcon(this.state.categoryName1). .icon(
                    //   CategoryManager.categoryIdByName(this.state.categoryName1)
                    // ),
                    categoryId: this.state.subCategoryId,
                    content: this.state.categoryName2,
                    isDisplayRightIcon: true,
                    onPress: this.onPressCategory,
                  })}
                  {/* 開始/終了 */}
                  <View style={{ flexDirection: 'row' }}>
                    {this.renderItem({
                      title: '開始',
                      content: moment(this.state.startDate).format(
                        'YYYY/MM/DD',
                      ),
                      isDisplayRightIcon: true,
                      onPress: () => {
                        this.setState({ isDisplayStartDatePicker: true })
                      },
                      style: { marginRight: 10, flex: 1 },
                    })}
                    {this.renderItem({
                      title: '終了',
                      content: moment(this.state.endDate).format('YYYY/MM/DD'),
                      isDisplayRightIcon: true,
                      onPress: () => {
                        this.setState({ isDisplayEndDatePicker: true })
                      },
                      style: { flex: 1 },
                    })}
                  </View>
                  {this.renderItem({
                    icon: Icon.Period as never,
                    content: this.getTermText(),
                    isDisplayRightIcon: true,
                    placeholder: '間隔',
                    onPress: this.onPressTermItem,
                  })}
                  {this.renderSettingPaymentUserType()}
                  <View
                    style={{
                      paddingLeft: 25,
                      borderBottomWidth: 1,
                      borderBottomColor: '#ddd',
                      width: '100%',
                      height: 50,
                    }}>
                    <View
                      pointerEvents="none"
                      style={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'absolute',
                      }}>
                      <View
                        style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image
                          source={Icon.Wallet}
                          style={{
                            width: 20,
                            height: 20,
                            resizeMode: 'contain',
                          }}
                        />
                        <Text style={styles.paymentPlaceText}>
                          {this.state.selectedTab === 'expense'
                            ? '支払い元'
                            : '入金先'}
                        </Text>
                      </View>
                      {this.state.paymentUserType !==
                        PaymentUserType.Partner && (
                        <Image
                          source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
                          style={styles.iconDown}
                        />
                      )}
                    </View>
                    <Pressable
                      disabled={
                        this.state.paymentUserType === PaymentUserType.Partner
                      }
                      onPress={() =>
                        NavigationService.navigate('PaymentSource', {
                          paymentUserType: this.state.paymentUserType,
                          onSelectWallet: async (wallet: WalletEntity) => {
                            await this.getWallets()
                            this.setState({ walletId: wallet.id })
                          },
                        })
                      }
                      style={{ flex: 1, justifyContent: 'center' }}>
                      <Text
                        style={{
                          paddingRight: 31,
                          textAlign: 'right',
                          fontSize: 16,
                          fontWeight: 'normal',
                          color: Color.DefaultText,
                        }}>
                        {wallets.find((v) => v.value === walletId)?.label}
                      </Text>
                    </Pressable>
                    {/* <RNPickerSelect
                      style={{
                        inputAndroid: {
                          color: '#3A3A3A',
                          paddingLeft: 80,
                          paddingRight: 31,
                          textAlign: 'right',
                          fontSize: 16,
                        },
                        inputIOS: {
                          color: '#3A3A3A',
                          fontSize: 16,
                          paddingLeft: 80,
                          paddingRight: 31,
                          paddingTop: 15,
                          textAlign: 'right',
                        },
                      }}
                      placeholder={{}}
                      value={walletId}
                      onValueChange={(value) =>
                        this.setState({ walletId: value })
                      }
                      items={wallets}
                      Icon={() => null}
                      doneText="完了"
                      useNativeAndroidPickerStyle={false}
                    /> */}
                  </View>
                  {this.renderSettingShareView()}
                  <View style={styles.wrapContent}>
                    <Image
                      source={
                        this.state.selectedTab === 'expense'
                          ? Icon.Shop
                          : Icon.Income
                      }
                      style={{ width: 22, height: 22, resizeMode: 'contain' }}
                    />
                    <EllipsizeTextInput
                      value={this.state.usedLocation}
                      onChangeText={this.onChangeUsedLocation}
                      placeholder={
                        this.state.selectedTab === 'expense'
                          ? 'お店のおなまえ'
                          : '収入の内容'
                      }
                      style={styles.ellipsizeTextInput}
                      containerStyle={{ flex: 1 }}
                    />
                  </View>
                  <View
                    style={[
                      styles.wrapContent,
                      {
                        alignItems: 'flex-start',
                        height: undefined,
                        minHeight: 56,
                      },
                    ]}>
                    <Image
                      source={Icon.Memo}
                      style={{
                        marginTop: 20,
                        width: 24,
                        height: 18,
                        resizeMode: 'contain',
                      }}
                    />
                    <EllipsizeTextInput
                      value={this.state.memo}
                      onChangeText={this.onChangeMemo}
                      placeholder="メモ"
                      multiline={true}
                      returnKeyType="default"
                      style={[
                        styles.ellipsizeTextInput,
                        { marginBottom: Platform.select({ ios: 10 }) },
                      ]}
                      containerStyle={{ flex: 1 }}
                    />
                  </View>
                </View>
                {/* 保存、削除ボタン */}
                {this.isCreateMode() ? (
                  <View style={styles.wrapButton}>
                    <AppButton
                      title="保存する"
                      key="SaveButton"
                      onPress={this.onPressSaveButton}
                      disabled={
                        this.state.amount === 0 ||
                        this.state.walletId === null ||
                        !this.state.term ||
                        !this.getTermText()
                      }
                      loadingKey="save"
                      style={{ flex: 1 }}
                    />
                  </View>
                ) : (
                  <View style={styles.wrapButton}>
                    <AppButton
                      title="削除する"
                      key="DeleteButton"
                      onPress={() => {
                        Alert.alert(
                          '本当に削除しますか？',
                          '振替取引対象となっている場合、\n自動的に振替が解除されます',
                          [
                            { text: 'いいえ', style: 'cancel' },
                            { text: 'はい', onPress: this.deleteTransaction },
                          ],
                        )
                      }}
                      type="white"
                      loadingKey="delete"
                      style={styles.submitButton}
                    />
                    <AppButton
                      title="更新する"
                      key="SaveButton"
                      onPress={this.onPressSaveButton}
                      disabled={
                        this.state.amount === 0 || this.state.walletId === null
                      }
                      loadingKey="save"
                      style={styles.submitButton}
                    />
                  </View>
                )}
              </ScrollView>
            )}
          {/* 振替 */}
          {this.state.isTransactionLoaded &&
            this.state.selectedTab === 'transfer' && (
              <ScrollView>
                {this.isCreateMode() && this.renderTab()}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 10,
                    justifyContent: 'flex-end',
                  }}>
                  {this.isUpdateMode() && (
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: 'normal',
                        paddingRight: 16,
                      }}>
                      振替
                    </Text>
                  )}
                  <View>
                    <AmountInput
                      style={{ ...styles.wrapPrice }}
                      value={this.state.amount}
                      onChange={this.onChangeAmount}
                      editable={this.isCreateMode()}
                    />
                  </View>
                </View>
                <View style={styles.wrapItem}>
                  {/* 開始/終了 */}
                  <View style={{ flexDirection: 'row' }}>
                    {this.renderItem({
                      title: '開始',
                      content: moment(this.state.startDate).format(
                        'YYYY/MM/DD',
                      ),
                      isDisplayRightIcon: true,
                      onPress: () => {
                        this.setState({ isDisplayStartDatePicker: true })
                      },
                      style: { marginRight: 10, flex: 1 },
                    })}
                    {this.renderItem({
                      title: '終了',
                      content: moment(this.state.endDate).format('YYYY/MM/DD'),
                      isDisplayRightIcon: true,
                      onPress: () => {
                        this.setState({ isDisplayEndDatePicker: true })
                      },
                      style: { flex: 1 },
                    })}
                  </View>
                  {/* 間隔 */}
                  {this.renderItem({
                    icon: Icon.Period as never,
                    content: this.getTermText(),
                    isDisplayRightIcon: !this.isUpdateMode(),
                    placeholder: '間隔',
                    onPress: this.onPressTermItem,
                  })}
                  {this.renderWalletTypeView('from')}
                  <View style={{ flexDirection: 'row', flex: 1 }}>
                    <View
                      style={{
                        width: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Image
                        source={Icon.Transfer.Arrow}
                        style={{
                          width: 20,
                          height: 24,
                        }}
                        resizeMode="contain"
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        paddingLeft: 25,
                        borderBottomWidth: 1,
                        borderBottomColor: '#ddd',
                        height: 50,
                      }}>
                      <TouchableOpacity
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          flex: 1,
                          alignItems: 'center',
                        }}
                        onPress={() => {
                          const params = {
                            userAccountType: this.state.fromWalletIsFamily
                              ? 'family'
                              : 'user',
                            transferDirection: 'from',
                            isDisplayWalletsOnly: true,
                            isSelectFinancialAccountOnly: true,
                            callback: this.selectTransferAccountHandler,
                          }
                          NavigationService.navigate(
                            this.isCreateMode() // TODO
                              ? 'TransferSelectFinancialAccount'
                              : 'TransferSelectFinancialAccount',
                            params,
                          )
                        }}>
                        {this.state.fromFinancialAccountName ? (
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: 'normal',
                              color: Color.DefaultText,
                            }}>
                            {this.state.fromFinancialAccountName}
                          </Text>
                        ) : (
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: 'normal',
                              color: Color.Gray,
                            }}>
                            振替元を指定してください
                          </Text>
                        )}
                        <TintedImage
                          source={Icon.Arrow.Right}
                          style={{
                            tintColor: Color.Gray,
                            marginLeft: 5,
                            width: 14,
                            height: 14,
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  {this.renderWalletTypeView('to')}
                  <View
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: '#ddd',
                      height: 50,
                      alignItems: 'flex-end',
                    }}>
                    <TouchableOpacity
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        flex: 1,
                        alignItems: 'center',
                      }}
                      onPress={() => {
                        const params = {
                          userAccountType: this.state.toWalletIsFamily
                            ? 'family'
                            : 'user',
                          transferDirection: 'to',
                          isDisplayWalletsOnly: true,
                          isSelectFinancialAccountOnly: true,
                          callback: this.selectTransferAccountHandler,
                        }
                        NavigationService.navigate(
                          this.isCreateMode()
                            ? 'TransferSelectFinancialAccount'
                            : 'TransferSelectFinancialAccount',
                          params,
                        )
                      }}>
                      {this.state.toFinancialAccountName ? (
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: 'normal',
                            color: Color.DefaultText,
                          }}>
                          {this.state.toFinancialAccountName}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: 'normal',
                            color: Color.Gray,
                          }}>
                          振替先を指定してください
                        </Text>
                      )}
                      <TintedImage
                        source={Icon.Arrow.Right}
                        style={{
                          tintColor: Color.Gray,
                          marginLeft: 5,
                          width: 14,
                          height: 14,
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={[
                      styles.wrapContent,
                      {
                        alignItems: 'flex-start',
                        height: undefined,
                        minHeight: 56,
                      },
                    ]}>
                    <Image
                      source={Icon.Memo}
                      style={{
                        marginTop: 20,
                        width: 24,
                        height: 18,
                        resizeMode: 'contain',
                      }}
                    />
                    <EllipsizeTextInput
                      value={this.state.memo}
                      onChangeText={this.onChangeMemo}
                      placeholder="メモ"
                      multiline={true}
                      style={[
                        styles.ellipsizeTextInput,
                        { marginBottom: Platform.select({ ios: 10 }) },
                      ]}
                      containerStyle={{ flex: 1 }}
                    />
                  </View>
                </View>
                {/* 保存、削除ボタン */}
                {this.isCreateMode() ? (
                  <View style={styles.wrapButton}>
                    <AppButton
                      title="保存する"
                      key="SaveButton"
                      onPress={this.onPressSaveTransferButton}
                      disabled={
                        this.state.amount === 0 || this.state.walletId === null
                      }
                      loadingKey="save"
                      style={{ flex: 1 }}
                    />
                  </View>
                ) : (
                  <View style={styles.wrapButton}>
                    <AppButton
                      title="解除する"
                      key="DeleteButton"
                      onPress={() => {
                        Alert.alert(
                          '振替を解除しますか？',
                          '振替を解除すると、振替で新しく作成された取引明細も削除されます。',
                          [
                            { text: 'いいえ', style: 'cancel' },
                            {
                              text: 'はい',
                              onPress: this.onPressDeleteTransferButton,
                            },
                          ],
                        )
                      }}
                      type="white"
                      loadingKey="delete"
                      style={styles.submitButton}
                    />
                    <AppButton
                      title="更新する"
                      key="SaveButton"
                      onPress={this.onPressSaveTransferButton}
                      disabled={
                        this.state.amount === 0 || this.state.walletId === null
                      }
                      loadingKey="save"
                      style={styles.submitButton}
                    />
                  </View>
                )}
              </ScrollView>
            )}
          {this.state.isDisplayedPremiumPlanView &&
            PremiumView2({
              image: require('@images/premium/limit_term_transaction.png'),
              onPressShowDetailButton: () => {
                navigatePremiumPlanLP({ planCode: '001' })
                this.setState({ isDisplayedPremiumPlanView: false })
                TrackingUtils.repro.track('【Tap】upper limit_repeat', 'Tap')
                TrackingUtils.repro.setScreenTrackingAfterPurchase(
                  '【Screen】purchase completed via upper limit_repeat',
                )
              },
            })}
        </View>
        {this.state.isTransactionLoaded && (
          <>
            <DatePicker
              date={moment(this.state.startDate).toDate()}
              isVisible={this.state.isDisplayStartDatePicker}
              onConfirm={this.handleStartDatePicked}
              onCancel={() =>
                this.setState({ isDisplayStartDatePicker: false })
              }
              headerFormat=""
              confirmText="決定"
              cancelText="キャンセル"
            />
            <DatePicker
              date={moment(this.state.endDate).toDate()}
              isVisible={this.state.isDisplayEndDatePicker}
              onConfirm={this.handleEndDatePicked}
              onCancel={() => this.setState({ isDisplayEndDatePicker: false })}
              headerFormat=""
              confirmText="決定"
              cancelText="キャンセル"
            />
            <TermSegmentedPicker
              isVisible={
                this.state.term === 'yearly' &&
                this.state.isVisibleSegmentedPicker
              }
              term={this.state.term}
              date={this.state.segmentedPickerDate}
              onSelect={(date) => {
                this.setState({
                  isVisibleSegmentedPicker: false,
                  term: 'yearly',
                  termToYearly: moment(date).format('MMDD'),
                  yearlySelections: {
                    month: `${date.getMonth() + 1}`,
                    day: `${date.getDate()}`,
                  },
                })
              }}
              onCancel={() => {
                this.setState({
                  isVisibleSegmentedPicker: false,
                })
              }}
            />
            <TermSegmentedPicker
              isVisible={
                this.state.term === 'monthly' &&
                this.state.isVisibleSegmentedPicker
              }
              term={this.state.term}
              date={this.state.segmentedPickerDate}
              onSelect={(date) => {
                let mDate = date.getDate()
                if (mDate === 31) mDate = -1

                this.setState({
                  isVisibleSegmentedPicker: false,
                  term: 'monthly',
                  termToMonthly: mDate,
                  monthlySelection: mDate,
                })
              }}
              onCancel={() => {
                this.setState({
                  isVisibleSegmentedPicker: false,
                })
              }}
            />
            <TermSegmentedPicker
              isVisible={
                this.state.term === 'weekly' &&
                this.state.isVisibleSegmentedPicker
              }
              term={this.state.term}
              date={this.state.segmentedPickerDate}
              onSelect={(date) => {
                const termToWeekly = [
                  'sunday',
                  'monday',
                  'tuesday',
                  'wednesday',
                  'thursday',
                  'friday',
                  'saturday',
                ][date.getDate() - 1] as Weekday
                this.setState({
                  isVisibleSegmentedPicker: false,
                  term: 'weekly',
                  termToWeekly,
                  weekdaySelection: termToWeekly,
                })
              }}
              onCancel={() => {
                this.setState({
                  isVisibleSegmentedPicker: false,
                })
              }}
            />
            {/* <SegmentedPicker
              ref={this.termYearlyPicker}
              confirmText="決定"
              defaultSelections={{
                month: this.state.yearlySelections.month,
                day: this.state.yearlySelections.day,
              }}
              onConfirm={(selections) => {
                this.setState({
                  term: 'yearly',
                  termToYearly: `${selections.month}${selections.day}`,
                  yearlySelections: {
                    month: selections.month,
                    day: selections.day,
                  },
                })
              }}
              onValueChange={({ column, value }) => {
                if (column === 'month') {
                  this.termYearlyPicker.current!.selectIndex(0, 'day', false)
                  this.setState((prevState: State) => ({
                    yearlySelections: {
                      ...prevState.yearlySelections,
                      [column]: value,
                    },
                  }))
                }
              }}
              options={this.generateOptions()}
            />
            <SegmentedPicker
              ref={this.termMonthlyPicker}
              confirmText="決定"
              defaultSelections={{
                day: `0${this.state.monthlySelection}`.slice(-2),
              }}
              onConfirm={(selections) => {
                this.setState({
                  term: 'monthly',
                  monthlySelection: +selections.day,
                  termToMonthly: +selections.day || 1,
                })
              }}
              options={(() => {
                const days = []
                for (let i = 1; i <= 31; i++) {
                  days.push({ label: `${i}日`, value: `0${i}`.slice(-2) })
                }
                days.push({
                  label: '月末',
                  value: '-1',
                })
                return [{ key: 'day', items: days }]
              })()}
            />
            <SegmentedPicker
              ref={this.termWeekdayPicker}
              confirmText="決定"
              defaultSelections={{
                weekday: this.state.weekdaySelection,
              }}
              onConfirm={(selections) => {
                this.setState({
                  term: 'weekly',
                  weekdaySelection: selections.weekday as Weekday,
                  termToWeekly: selections.weekday as Weekday,
                })
              }}
              options={[
                {
                  key: 'weekday',
                  items: [
                    { label: '日', value: 'sunday' },
                    { label: '月', value: 'monday' },
                    { label: '火', value: 'tuesday' },
                    { label: '水', value: 'wednesday' },
                    { label: '木', value: 'thursday' },
                    { label: '金', value: 'friday' },
                    { label: '土', value: 'saturday' },
                  ],
                },
              ]}
            /> */}
          </>
        )}
      </View>
    )
  }

  getTermText = () => {
    const { termToYearly, termToMonthly, termToWeekly } = this.state
    switch (this.state.term) {
      case 'yearly':
        if (!termToYearly) return ''
        return `毎年${+(termToYearly?.substring(0, 2) || '0')}月${+(
          termToYearly?.substring(2, 4) || '0'
        )}日`
      case 'monthly':
        if (!termToMonthly) return ''
        return (
          '毎月' + ((termToMonthly || 0) > 0 ? `${termToMonthly}日` : '月末')
        )
      case 'weekly':
        if (!termToWeekly) return ''
        return (
          '毎週' +
          ({
            sunday: '日',
            monday: '月',
            tuesday: '火',
            wednesday: '水',
            thursday: '木',
            friday: '金',
            saturday: '土',
          }[termToWeekly] || '') +
          '曜日'
        )
      case 'daily':
        return '毎日'
      default:
        return ''
    }
  }

  generateOptions = () => {
    const { month, day } = {
      month: this.monthOptions(),
      day: this.dayOptions(),
    }
    const { yearlySelections } = this.state
    return [
      {
        key: 'month',
        items: month,
      },
      {
        key: 'day',
        items: day[yearlySelections.month],
      },
    ]
  }

  private monthOptions = () => {
    const options = []
    for (let i = 1; i <= 12; i++) {
      options.push({ label: `${i}月`, value: `0${i}`.slice(-2) })
    }
    return options
  }

  private dayOptions = (): {
    [key: string]: { label: string; value: string }[]
  } => {
    const options31 = []
    for (let i = 1; i <= 31; i++) {
      options31.push({ label: `${i}日`, value: `0${i}`.slice(-2) })
    }
    const options30 = []
    for (let i = 1; i <= 30; i++) {
      options30.push({ label: `${i}日`, value: `0${i}`.slice(-2) })
    }
    const options29 = []
    for (let i = 1; i <= 29; i++) {
      options29.push({ label: `${i}日`, value: `0${i}`.slice(-2) })
    }
    return {
      '01': options31,
      '02': options29,
      '03': options31,
      '04': options30,
      '05': options31,
      '06': options30,
      '07': options31,
      '08': options31,
      '09': options30,
      '10': options31,
      '11': options30,
      '12': options31,
    }
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    userProfile: state.profile.userProfile,
    account: state.account,
  }
}

export default connect(mapStateToProps)(TermTransactionScreen)

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 20,
  },
  wrapTab: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 18,
    paddingBottom: 16,
  },
  wrapTabWithBorder: {
    borderBottomWidth: 2,
    borderColor: Color.Primary,
  },
  wrapTabWithBorderGray: {
    borderBottomWidth: 1,
    borderColor: Color.LightGray,
  },
  amountOfMoney: {
    fontSize: 20,
    fontWeight: 'normal',
    color: '#8E8E8E',
    marginLeft: 20,
    marginTop: 15,
  },
  price: {
    textAlign: 'right',
    marginRight: 20,
    fontSize: 34,
    color: '#3A3A3A',
    fontWeight: '600',
  },
  wrapItem: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 33,
  },
  titleItem: {
    fontSize: 18,
    fontWeight: 'normal',
    color: '#8E8E8E',
  },
  wrapContent: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    // flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
  },
  content: {
    color: '#3A3A3A',
    flex: 1,
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'normal',
  },
  placeholder: {
    color: '#aaaaaa',
    flex: 1,
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'normal',
  },
  iconDown: {
    width: 16,
    height: 16,
  },
  settingShareView: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
    alignItems: 'center',
  },
  wrapButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginTop: 4,
    marginBottom: 25,
  },
  wrapPrice: {
    marginRight: 5,
  },
  minus: {
    fontSize: 30,
    color: '#3A3A3A',
    fontWeight: '600',
  },
  ellipsizeTextInput: {
    flex: 1,
    color: '#3A3A3A',
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  paymentPlaceText: {
    color: '#9e9e9e',
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  tab: {
    width: '100%',
    flexDirection: 'row',
  },
  tabText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  tabTextOn: {
    color: Color.Primary,
  },
  tabTextOff: {
    color: Color.Gray,
  },
  submitButton: {
    width: wp('50%') - 16 - 8,
    resizeMode: 'contain',
  },
  includeBsView: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    alignItems: 'center',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  includeBsText: {
    fontSize: GlobalFontSizes.Small,
    color: Color.DefaultText,
    fontWeight: 'bold',
  },
})
