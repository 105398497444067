import { MobileSafari, WebApp } from '@lib/Env'
import styled from 'styled-components/native'

const RootView = styled.View({
  flex: 1,
  height: WebApp
    ? MobileSafari
      ? '-webkit-fill-available'
      : '100vh'
    : undefined,
})

export default RootView
