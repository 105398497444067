import CommonHeader from '@components/CommonHeader'
import CommonListItem from '@components/CommonListItem'
import ItemSeparator from '@components/ItemSeparator'
import Color from '@lib/Color'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useRef } from 'react'
import { FlatList, ListRenderItem } from 'react-native'
import styled from 'styled-components/native'
import { CategoryPickerStackScreenProps } from './EditCategory/EditCategoryTopScreen'

const SettingCategorySelectUserAccountTypeScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingCategorySelectUserAccountType'>
> = ({ navigation }) => {
  const renderItem: ListRenderItem<ListItem> = useCallback(
    ({ item }) => (
      <CommonListItem
        title={item.title}
        isDisplayArrow={true}
        onPress={() => navigation.navigate('EditCategoryTop', item.params)}
      />
    ),
    [navigation],
  )

  const listItems = useRef<ListItem[]>([
    {
      title: '家族の画面',
      params: {
        userAccountType: 'family',
        isSelectable: false,
      },
    },
    {
      title: 'わたしの画面',
      params: {
        userAccountType: 'user',
        isSelectable: false,
      },
    },
  ]).current

  return (
    <StyledRootView>
      <CommonHeader title="カテゴリーの設定" />
      <StyledFlatList
        keyExtractor={({ title }) => title}
        renderItem={renderItem}
        data={listItems}
        ItemSeparatorComponent={() => ItemSeparator}
      />
    </StyledRootView>
  )
}

type ListItem = {
  title: string
  params: CategoryPickerStackScreenProps
}

const StyledRootView = styled.View({
  backgroundColor: Color.LightGray,
})

const StyledFlatList = styled(FlatList<ListItem>)({
  height: '100%',
})

export default SettingCategorySelectUserAccountTypeScreen
