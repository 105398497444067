import { NativeApp } from '@lib/Env'
import { ActionSheetIOSOptions, AlertButton } from 'react-native'
import RnActionSheet from 'react-native-action-sheet'
import WebAlert from './Alert'

const webShowActionSheetWithOptions = (
  options: ActionSheetIOSOptions,
  callback: (buttonIndex: number) => void,
) => {
  const buttons: AlertButton[] = options.options?.map((option, index) => ({
    text: option,
    onPress: () => callback(index),
  }))

  WebAlert.alert('', undefined, buttons, {
    ...options,
    menuDirection: 'column',
  })
}

const ActionSheet = {
  showActionSheetWithOptions: NativeApp
    ? RnActionSheet.showActionSheetWithOptions
    : webShowActionSheetWithOptions,
  // showShareActionSheetWithOptions:
  // RnActionSheet.showShareActionSheetWithOptions,
}

export default ActionSheet
