import CommonHeader from '@components/CommonHeader'
import Color from '@lib/Color'
import React from 'react'
import { StyleSheet, Text } from 'react-native'

class PasswordResetSentEmailScreen extends React.Component {
  render() {
    return (
      <>
        <CommonHeader title="メール送信" />
        <Text style={styles.textTitleConfirm}>
          登録のメールアドレスにメールを送信しました。
        </Text>
      </>
    )
  }
}

const styles = StyleSheet.create({
  textTitleConfirm: {
    color: Color.DarkBlue,
    fontSize: 12,
    fontWeight: 'normal',
    marginTop: 50,
    marginLeft: 20,
    marginRight: 20,
  },
})

export default PasswordResetSentEmailScreen
