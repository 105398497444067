import { Image } from '@components'
import Color from '@lib/Color'
import * as Icon from '@lib/Icon'
import React from 'react'
import { Dimensions, StyleSheet, Text, View } from 'react-native'
import { ProgressCircle } from 'react-native-svg-charts'

type ProgressStatus = 'best' | 'normal' | 'bad'

interface Props {
  status: ProgressStatus
  title: string
  current: number
  target: number
  imageURI: string
  progress: number
  monthOver?: number
  color: string
}

class GoalCard extends React.Component<Props> {
  private progressTextColor = (status: ProgressStatus) => {
    switch (status) {
      case 'best':
        return '#77C325'
      case 'normal':
        return '#FCC300'
      case 'bad':
        return '#FF7200'
    }
  }

  render() {
    const {
      status,
      title,
      current,
      target,
      imageURI,
      progress,
      monthOver,
      color,
    } = this.props

    return (
      <View
        style={{
          flexDirection: 'row',
          borderTopColor: '#ccc',
          borderTopWidth: 0.5,
          marginTop: 15,
          paddingTop: 15,
          // backgroundColor: 'red',
          // paddingBottom: 15,
        }}>
        {/* 左側画像 */}
        <View>
          <Image
            source={{ uri: imageURI }}
            style={{
              marginLeft: 5,
              width: 70,
              height: 70,
              resizeMode: 'cover',
              borderRadius: 10,
            }}
            resizeMode="cover"
          />
          <View
            style={{
              position: 'absolute',
              top: -5,
              width: 26,
              height: 26,
              borderRadius: 13,
              backgroundColor: color,
            }}
          />
        </View>
        {/* 右側 */}
        <View style={{ flex: 1, marginLeft: 10 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text
              ellipsizeMode="tail"
              numberOfLines={2}
              style={{
                color: 'rgb(58, 58, 58)',
                fontSize: 16,
                fontWeight: 'normal',
                maxWidth: wp(33),
              }}>
              {title}
            </Text>
            <View
              style={{
                flex: 1,
                alignItems: 'flex-end',
              }}>
              <Text
                numberOfLines={1}
                style={{
                  flex: 1,
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: Color.NumberBlack,
                }}>
                {target.jpCurrency()}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 5,
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 'normal',
                    color: 'rgb(58, 58, 58)',
                  }}>
                  前月比
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 'normal',
                    marginLeft: 8,
                    color: Color.number(monthOver),
                  }}>
                  {monthOver !== undefined
                    ? monthOver.jpCurrency({
                        showPlus: true,
                        showPlusMinus: true,
                      })
                    : '-'}
                </Text>
              </View>
            </View>
          </View>
          {!Number.isNaN(progress) && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {/* 進捗顔アイコン */}
                <View>
                  <Image
                    style={styles.itemImageStatus}
                    source={Icon.goalReportFaceIcon(status)}
                    resizeMode="contain"
                  />
                  <ProgressCircle
                    style={styles.progressCircle}
                    progress={progress}
                    progressColor={this.progressTextColor(status)}
                    backgroundColor="#ddd"
                    strokeWidth={1.5}
                  />
                </View>
                <Text
                  style={[
                    styles.progressText,
                    { color: this.progressTextColor(status) },
                  ]}>
                  {Math.floor(progress * 100)}%
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'normal',
                    marginTop: 3,
                    marginLeft: 3,
                    color: 'rgb(58, 58, 58)',
                  }}>
                  達成！
                </Text>
              </View>
              <View
                style={{
                  marginTop: 3,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'normal',
                    color: 'rgb(58, 58, 58)',
                  }}>
                  あと
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    marginLeft: 4,
                    color: 'rgb(58, 58, 58)',
                  }}>
                  {(progress >= 1 ? 0 : target - current).jpCurrency()}
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
    )
  }
}

const { width } = Dimensions.get('window')

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    marginTop: 10,
    marginBottom: 10,
    paddingBottom: 10,
    backgroundColor: Color.White,
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  disabledView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#3a3a3a33',
    borderRadius: 10,
  },
  itemImageBg: {
    width: width - 30,
    height: width * 0.55,
    marginBottom: 10,
    backgroundColor: '#ccc',
    alignItems: 'center',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  itemTitle: {
    paddingLeft: 15,
    marginBottom: 5,
    color: '#3A3A3A',
    fontSize: 14,
    fontWeight: 'normal',
  },
  itemProgress: {
    paddingLeft: 15,
    flexDirection: 'row',
  },
  itemProgressSeparator: {
    color: '#3A3A3A',
    fontSize: 17,
    fontWeight: 'normal',
  },
  itemProgressTarget: {
    color: '#3A3A3A',
    fontSize: 17,
    fontWeight: 'normal',
  },
  statusBestText: {
    color: '#77C325',
    fontSize: 17,
    fontWeight: 'normal',
  },
  statusNormalText: {
    color: '#FCC300',
    fontSize: 17,
    fontWeight: 'normal',
  },
  statusBadText: {
    color: '#FF7200',
    fontSize: 17,
    fontWeight: 'normal',
  },
  progressText: {
    fontWeight: 'bold',
    marginTop: 3,
    marginLeft: 5,
    color: 'rgb(58, 58, 58)',
    fontSize: 14,
  },
  progressCircle: {
    overflow: 'visible',
    width: 28,
    height: 28,
  },
  itemImageStatus: {
    position: 'absolute',
    margin: 6,
    width: 16,
    height: 16,
  },
})

export default GoalCard
