import ActionSheet from '@components/ActionSheet'
import CommonHeader from '@components/CommonHeader'
import TabBarLabel from '@components/TabBarLabel'
import TintedImage from '@components/TintedImage'
import { TabBarOptions } from '@constants'
import { accountModeSelector } from '@lib/AccountManager/actions'
import Color from '@lib/Color'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import AssetTransitionScreen from './AssetTransitionScreen'
import { selectIsTappingChart } from './AssetTransitionScreen/slice'
import TotalAssetsScreen from './TotalAssetsScreen'

const Tab = createMaterialTopTabNavigator()

const FinancialAccountsScreen: React.FC<
  StackScreenProps<RootStackParamList, 'FinancialAccounts'>
> = ({ route }) => {
  const showActionSheet = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: [
          '新しく追加する',
          '金融カテゴリーを並び替え',
          '登録した口座を並び替え',
          'キャンセル',
        ],
        cancelButtonIndex: 3,
      },
      (index: number) => {
        if (index === 0) {
          NavigationService.navigate('FinancialAccountCooperation')
        } else if (index === 1) {
          NavigationService.navigate('SortFinanceCategory')
        } else if (index === 2) {
          NavigationService.navigate('SortFinanceAccount')
        }
      },
    )
  }, [])

  const renderRightButton = useCallback(
    () => (
      <TouchableOpacity
        onPress={showActionSheet}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 8,
          // paddingHorizontal: 10,
          paddingBottom: 5,
        }}>
        <TintedImage
          style={{
            width: 18,
            height: 18,
            tintColor: Color.White,
          }}
          source={require('@images/buttons/edit/button-edit.png')}
        />
        <Text
          style={{
            fontSize: 11,
            fontWeight: 'normal',
            color: Color.White,
            marginTop: 3,
            paddingHorizontal: 10,
          }}>
          追加・編集
        </Text>
      </TouchableOpacity>
    ),
    [showActionSheet],
  )

  const userAccountType = useSelector(accountModeSelector)

  const isTappingChart = useSelector(selectIsTappingChart)

  return (
    <>
      <CommonHeader
        title={`${userAccountType === 'family' ? '家族' : '個人'} 口座`}
        leftButtonType="menu"
        renderRightButton={renderRightButton}
      />
      <Tab.Navigator
        backBehavior="none"
        initialRouteName={route.params?.tab}
        swipeEnabled={!isTappingChart}
        tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="TotalAssets"
          component={TotalAssetsScreen}
          options={{
            tabBarLabel: ({ color }) => (
              <TabBarLabel color={color}>登録口座</TabBarLabel>
            ),
          }}
        />
        <Tab.Screen
          name="AssetTransition"
          component={AssetTransitionScreen}
          options={{
            tabBarLabel: ({ color }) => (
              <TabBarLabel color={color}>資産推移</TabBarLabel>
            ),
          }}
        />
      </Tab.Navigator>
    </>
  )
}

export default FinancialAccountsScreen
