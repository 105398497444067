import '@lib/extensions'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

console.log('process.env.NODE_ENV', process.env.NODE_ENV)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
