import { Image } from '@components'
import CategoryImage from '@components/CategoryImage'
import TintedImage from '@components/TintedImage'
import * as Buttons from '@lib/Buttons'
import { Category } from '@lib/CategoryManager'
import Color from '@lib/Color'
import * as Icon from '@lib/Icon'
import Images from '@lib/Images'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { vibrate } from './functions'

export type TopCategoryListItemProps = {
  category: Category
  sortable: boolean
  disabled?: boolean
  selectable?: boolean
  onPress?: () => void
  onPressEye?: (hide: boolean) => void
  drag?: () => void
}

export const TopCategoryListItem = ({
  onPress,
  drag,
  sortable,
  category,
  onPressEye,
  disabled,
}: TopCategoryListItemProps) => {
  return (
    <TouchableOpacity
      onPress={() => {
        !sortable && !category.hide && onPress?.()
      }}
      style={{
        borderBottomColor: Color.LightGray,
        borderBottomWidth: 1,
      }}
      disabled={disabled}>
      <View style={styles.container}>
        {onPressEye && (
          <TouchableOpacity
            onPress={() => onPressEye(category.hide ? false : true)}>
            {!+category.categoryId ? (
              <Image
                source={Buttons.ListDelete}
                resizeMode="contain"
                style={{
                  marginLeft: 5,
                  marginRight: 12,
                  width: 18,
                  height: 18,
                }}
              />
            ) : (
              <Image
                resizeMode="contain"
                source={
                  category.hide ? Icon.Password.Eye.Off : Icon.Password.Eye.On
                }
                style={{
                  marginRight: 5,
                  tintColor: Color.Gray,
                  width: 30,
                  height: 30,
                }}
              />
            )}
          </TouchableOpacity>
        )}
        <CategoryImage categoryId={category.categoryId} />
        <Text style={styles.text}>{category.name}</Text>
        {sortable ? (
          <TouchableOpacity
            onLongPress={() => {
              vibrate()
              drag?.()
            }}
            style={styles.dragButton}>
            <TintedImage
              style={{
                tintColor: Color.Gray,
                width: 16,
                height: 16,
              }}
              source={Images.CommonHeader.MenuButton}
            />
          </TouchableOpacity>
        ) : category.hide || disabled ? null : (
          <TintedImage
            style={styles.arrowImage}
            source={require('@images/arrow/icon-arrow-white-small.png')}
          />
        )}
      </View>
      {disabled && (
        <View
          pointerEvents="box-none"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#eeeeee60',
          }}
        />
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 16,
    backgroundColor: Color.White,
    height: 44,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    flex: 1,
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  arrowImage: {
    tintColor: Color.Gray,
    marginRight: 16,
    resizeMode: 'contain',
    width: 19,
    height: 19,
  },
  dragButton: {
    paddingLeft: 30,
    paddingVertical: 14,
    paddingRight: 20,
  },
})
