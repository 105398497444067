import Color from '@lib/Color'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'

const HeaderMenuButton = (props: TouchableOpacityProps) => (
  <TouchableOpacity style={styles.container} {...props}>
    <View style={styles.wrapDot}>
      <View style={styles.dot} />
      <View style={styles.dot} />
      <View style={styles.dot} />
    </View>
    <Text style={styles.text}>メニュー</Text>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 8,
    paddingHorizontal: 4,
    paddingBottom: 5,
  },
  wrapDot: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 10,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: Color.White,
    borderRadius: 2,
    marginHorizontal: 2,
  },
  text: {
    fontSize: 11,
    fontWeight: 'normal',
    color: Color.White,
    marginTop: 8,
    paddingHorizontal: 10,
  },
})

export default HeaderMenuButton
