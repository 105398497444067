if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.info = () => {}
  console.warn = () => {}
}

export default {
  info: console.log,
  warn: console.warn,
  error: console.error,
}
