import { UserAccountType } from '@interfaces/Account'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type State = {
  user: {
    year: number | null
    month: number | null
    enable: boolean
  }
  family: {
    year: number | null
    month: number | null
    enable: boolean
  }
}

type Reducer = {
  setCarryForward: (
    state: State,
    action: PayloadAction<{
      userAccountType: UserAccountType
      year: number | null
      month: number | null
      enable: boolean
    }>,
  ) => void
}

const initialState: State = {
  user: {
    year: null,
    month: null,
    enable: false,
  },
  family: {
    year: null,
    month: null,
    enable: false,
  },
}

const slice = createSlice<State, Reducer>({
  name: 'carryForward',
  initialState,
  reducers: {
    setCarryForward: (state, { payload }) => {
      const { userAccountType, ...props } = payload
      state[userAccountType] = props
    },
  },
})

export const { setCarryForward } = slice.actions
export const reducer = slice.reducer
