import { TimelineActivityActions, TimelineState } from './types'

export default (
  state: TimelineState = {
    noticesBadgeCount: 0,
  },
  action: TimelineActivityActions
) => {
  switch (action.type) {
    case 'GetActivities':
      return { ...state, activities: action.activities }
    case 'GetNotices':
      return { ...state, notices: action.notices }
    case 'UpdateNoticesBadgeCount':
      return { ...state, noticesBadgeCount: action.noticesBadgeCount }
    default:
      return state
  }
}
