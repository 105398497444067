import CategoryImage from '@components/CategoryImage'
import React from 'react'
import {
  CategoryInfoContainer,
  CategoryName,
  PrevMonthAmount,
  RootContainer,
  StyledCalculatorInput,
} from './styles'
import { Props } from './types'

const CateboryBudgetCell = ({
  categoryId,
  categoryName,
  prevMonthAmount,
  amount,
  onChange,
  willFocus,
}: Props) => {
  return (
    <RootContainer>
      <CategoryImage categoryId={categoryId} />
      <CategoryInfoContainer>
        <CategoryName>{categoryName}</CategoryName>
        <PrevMonthAmount>前月 {prevMonthAmount.jpCurrency()}</PrevMonthAmount>
      </CategoryInfoContainer>
      <StyledCalculatorInput
        textStyle={{ fontSize: 18, fontWeight: 'normal' }}
        prefixTextStyle={{ fontSize: 18, fontWeight: 'normal', marginRight: 0 }}
        animated={true}
        value={amount}
        onChange={onChange}
        inputType="keyboard"
        willFocus={willFocus}
      />
    </RootContainer>
  )
}

export default CateboryBudgetCell
