import { NativeApp } from '@lib/Env'
import { RootStackParamList } from '@navigation/Screens'
import {
  createStackNavigator,
  StackScreenProps,
  TransitionPresets,
} from '@react-navigation/stack'
import React from 'react'
import HomeExpensesScreen from '../Home/HomeExpensesScreen'
import MainTabsStackScreens from '../MainTabsStackScreens'

const Stack = createStackNavigator()

const HouseholdAccountsTab: React.FC<
  StackScreenProps<RootStackParamList, 'HouseholdAccountsTab'>
> = ({ route }) => (
  <Stack.Navigator
    initialRouteName="HomeExpenses"
    screenOptions={{
      gestureEnabled: false,
      headerShown: false,
      ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
    }}>
    <Stack.Screen
      name="HomeExpenses"
      component={HomeExpensesScreen}
      initialParams={route.params}
    />
    {MainTabsStackScreens(Stack, 'HouseholdAccountsTab')}
  </Stack.Navigator>
)

export default HouseholdAccountsTab
