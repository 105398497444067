import { APIError } from '../index'
import * as TransactionDefaultSettingsAPI from './api'
import {
  GetTransactionDefaultSettingsProps,
  GetTransactionDefaultSettingsResponse,
  PostTransactionDefaultSettingsProps,
} from './types'

export const getTransactionDefaultSettings = async (
  props: GetTransactionDefaultSettingsProps,
) => {
  const response =
    await TransactionDefaultSettingsAPI.getTransactionDefaultSettings(props)
  if (!response.ok) throw new APIError(response)
  return (response.json as GetTransactionDefaultSettingsResponse).transaction
}

export const postTransactionDefaultSettings = async (
  props: PostTransactionDefaultSettingsProps,
) => {
  const response =
    await TransactionDefaultSettingsAPI.postTransactionDefaultSettings(props)
  if (!response.ok) throw new APIError(response)
}
