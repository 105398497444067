import { Image } from '@components'
import Avatar from '@components/Avatar'
import { UserAccountType } from '@interfaces/Account'
import { Transaction } from '@interfaces/Financial'
import Color from '@lib/Color'
import * as Icon from '@lib/Icon'
import ProfileManager from '@lib/ProfileManager'
import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import CategoryImage from './CategoryImage'

export interface TransactionItemProps {
  userAccountType: UserAccountType
  transaction: Transaction
  onPress: (transaction: Transaction) => void
}

class TransferTransactionItem extends React.PureComponent<TransactionItemProps> {
  private onPress = () => this.props.onPress(this.props.transaction)

  render() {
    const { transaction, userAccountType } = this.props

    return (
      <TouchableOpacity onPress={this.onPress}>
        <View style={styles.container}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={styles.flexRow}>
              <CategoryImage
                categoryId={transaction.atTransactionCategoryId}
                isTransfer={!!transaction.transferId}
              />
              <Text
                ellipsizeMode="tail"
                numberOfLines={1}
                style={styles.content}>
                {transaction.usedLocation || transaction.categoryName2}
              </Text>
            </View>
            <Text style={styles.money}>{transaction.amount.jpCurrency()}</Text>
          </View>
          <View style={styles.bottom}>
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                marginLeft: 27,
              }}>
              <Avatar size={19} />
              {userAccountType === 'family' && (
                <Avatar type="partner" size={18} style={{ marginLeft: 2 }} />
              )}
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: Color.DefaultText,
                  marginLeft: 3,
                }}>
                {userAccountType === 'user'
                  ? ProfileManager.getName('user')
                  : '家族'}
              </Text>
            </View>
            <Memo transaction={this.props.transaction} />
          </View>
        </View>
      </TouchableOpacity>
    )
  }
}

const Memo = ({ transaction }: { transaction: Transaction }) => {
  return transaction.memo && transaction.memo.length > 0 ? (
    <View
      style={{
        flexDirection: 'row',
        paddingVertical: 6,
        alignItems: 'center',
      }}>
      <Image source={Icon.Memo} style={{ width: 24, resizeMode: 'contain' }} />
      <Text
        ellipsizeMode="tail"
        numberOfLines={1}
        style={{
          color: Color.DefaultText,
          fontSize: 12,
          fontWeight: 'normal',
          maxWidth: wp('30%'),
        }}>
        {transaction.memo}
      </Text>
    </View>
  ) : null
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: Color.White,
  },
  flexRow: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    marginHorizontal: 4,
    fontSize: 16,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  bottom: {
    justifyContent: 'space-between',
    marginTop: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },
  money: {
    fontSize: 23,
    fontWeight: 'normal',
  },
})

export default TransferTransactionItem
