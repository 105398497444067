import { UserAccountTypeWithAll } from '@interfaces/Account'
import * as DateUtilsV2 from '@lib/DateUtils'
import store from '@redux/store'
import moment from 'moment'
import { getYearMonth, StartDateSettingOption } from './DateUtils'
import { getStartDateSettings } from './DateUtilsRedux'

export type RangeOfMonths = {
  startDate: moment.Moment
  endDate: moment.Moment
}

class DateUtilsV1 {
  monthKey = (date = moment()) => {
    const settings = getStartDateSettings()
    return DateUtilsV2.getYearMonth(
      date,
      settings.start,
      settings.startDateSettingOption,
    ).key
  }

  prevMonthKey = (monthKey: string) => {
    let { year, month } = this.parseMonthKey(monthKey)
    if (month > 1) {
      month--
    } else {
      year--
      month = 12
    }
    return year + `0${month}`.slice(-2)
  }

  nextMonthKey = (monthKey: string) => {
    let { year, month } = this.parseMonthKey(monthKey)
    if (month < 12) {
      month++
    } else {
      year++
      month = 1
    }
    return year + `0${month}`.slice(-2)
  }

  parseMonthKey = (monthKey: string) => ({
    year: +monthKey.slice(0, 4),
    month: +monthKey.slice(-2),
  })

  getRangeOfMonths = (
    date = moment(),
    ignorePlType = false,
    account: UserAccountTypeWithAll = store.getState().account.accountMode,
  ): RangeOfMonths => {
    const settings = getStartDateSettings(account)
    const yearMonth = getYearMonth(
      date,
      settings.start,
      ignorePlType
        ? StartDateSettingOption.None
        : settings.startDateSettingOption,
    )

    return DateUtilsV2.getDateRange(
      yearMonth.year,
      yearMonth.month,
      settings.start,
      ignorePlType
        ? StartDateSettingOption.None
        : settings.startDateSettingOption,
    )
  }
}

export default new DateUtilsV1()
