import AmountInput from '@components/AmountInput'
import AppButton from '@components/AppButton'
import Avatar from '@components/Avatar'
import CalculatorInput from '@components/Calculator/CalculatorInput'
import CheckBox from '@components/CheckBox'
import CommonHeader from '@components/CommonHeader'
import DatePicker from '@components/DatePicker'
import { QuestionButton } from '@components/QuestionButton'
import TintedImage from '@components/TintedImage'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import { APIError } from '@lib/api'
import { GoalManager } from '@lib/api/Goal'
import { selectGoalEditor } from '@lib/api/Goal/slice'
import { LifePlanEvent, LifePlanManager } from '@lib/api/LifePlan'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import GoalImageView from './GoalImageView'

const EditGoalDetailScreen: React.FC<
  StackScreenProps<RootStackParamList, 'EditGoalDetail'>
> = ({ route }) => {
  const goalId = route.params?.goalId

  const isFocused = useIsFocused()

  const editor = useSelector(selectGoalEditor)

  const [monthsDiff, setMonthsDiff] = useState(0)
  const [isFromDatePickerVisible, setIsFromDatePickerVisible] = useState(false)
  const [isToDatePickerVisible, setIsToDatePickerVisible] = useState(false)
  const [isNoEndDate, setIsNoEndDate] = useState(!editor?.endDate ?? false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [lifePlanEvents, setLifePlanEvents] = useState<LifePlanEvent[]>()
  const [isVisibleLifePlan, setIsVisibleLifePlan] = useState(editor.share)

  const onChangeTitle = useCallback(
    (title: string) => {
      GoalManager.updateEditor({ ...editor, name: title })
    },
    [editor],
  )

  const onChangeUserMonthlyAmount = useCallback(
    (amount: number) => {
      if (!editor.userGoalSettings) return

      GoalManager.updateEditor({
        ...editor,
        userGoalSettings: {
          ...editor.userGoalSettings,
          monthlyAmount: amount,
        },
      })
    },
    [editor],
  )

  const onChangePartnerMonthlyAmount = useCallback(
    (amount: number) => {
      if (!editor.partnerGoalSettings) return

      GoalManager.updateEditor({
        ...editor,
        partnerGoalSettings: {
          ...editor.partnerGoalSettings,
          monthlyAmount: amount,
        },
      })
    },
    [editor],
  )

  const onPressDateText = useCallback((dateType: 'from' | 'to') => {
    if (dateType === 'from') {
      setIsFromDatePickerVisible(true)
    }
    if (dateType === 'to') {
      setIsToDatePickerVisible(true)
    }
  }, [])

  const renderDateText = useCallback(
    (type: 'from' | 'to') => {
      const editor = store.getState().goal.editor
      let date = type === 'from' ? editor.startDate : editor.endDate
      if (date) {
        date = moment(date).format('YYYY/MM/DD')
      } else {
        date = moment().format('YYYY/MM/DD')
      }

      return (
        <TouchableOpacity
          style={styles.dateTimeSelectionItem}
          onPress={() => onPressDateText(type)}>
          <Text style={styles.dateTimeSelectionItemText}>{date}</Text>
          <TintedImage
            style={styles.dateTimeSelectionItemImage}
            source={require('@images/arrow/icon-arrow-white-small.png')}
          />
        </TouchableOpacity>
      )
    },
    [onPressDateText],
  )

  const title = useMemo(
    () => (editor.goalId ? '目標貯金の編集' : '目標貯金の作成'),
    [editor.goalId],
  )

  const onPressSaveAmountType = useCallback(
    (saveAmountType: 'auto' | 'manual') => {
      if (saveAmountType === 'auto') {
        GoalManager.updateEditor({
          ...editor,
          saveAmountType: 'auto',
        })
      } else {
        GoalManager.updateEditor({
          ...editor,
          saveAmountType: 'manual',
          userGoalSettings: editor.userGoalSettings
            ? { ...editor.userGoalSettings, monthlyAmount: 0 }
            : undefined,
          partnerGoalSettings: editor.partnerGoalSettings
            ? { ...editor.partnerGoalSettings, monthlyAmount: 0 }
            : undefined,
        })
      }
    },
    [editor],
  )

  const renderSaveAmountType = useCallback(
    (displayType: 'auto' | 'manual') => {
      const editor = store.getState().goal.editor

      const { saveAmountType } = editor

      return (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            // borderBottomColor: '#f0f0f0',
            // borderBottomWidth: 1,
          }}
          onPress={() => onPressSaveAmountType(displayType)}>
          <Image
            style={{ height: 24, width: 24, marginRight: 12 }}
            source={
              (displayType === 'manual' && saveAmountType === 'manual') ||
              (displayType === 'auto' && saveAmountType === 'auto')
                ? require('@images/icons/icon-check-green.png')
                : require('@images/icons/icon-oval.png')
            }
            resizeMode="contain"
          />
          {displayType === 'auto' ? (
            <Text
              style={{ fontSize: 17, fontWeight: 'normal', color: '#3a3a3a' }}>
              毎月定額で自動貯金する{' '}
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: 'normal',
                  color: Color.Primary,
                }}>
                👈オススメ
              </Text>
            </Text>
          ) : (
            <Text
              style={{ fontSize: 17, fontWeight: 'normal', color: '#3a3a3a' }}>
              手入力で貯金する
            </Text>
          )}
        </TouchableOpacity>
      )
    },
    [onPressSaveAmountType],
  )

  const updateMonthsDiff = useCallback(
    (from?: string, to?: string | null) => {
      if (isNoEndDate) return

      setMonthsDiff(
        Math.floor(moment(to).diff(from, 'day') / 30), // iOS版に合わせる
      )
    },
    [isNoEndDate],
  )

  const onChangeGoalAmount = useCallback(
    (value: number) => {
      GoalManager.updateEditor({ ...editor, goalAmount: Math.abs(value) })
    },
    [editor],
  )

  useEffect(() => {
    setTotalAmount(
      ((editor.userGoalSettings?.monthlyAmount ?? 0) +
        (editor.partnerGoalSettings?.monthlyAmount ?? 0)) *
        monthsDiff,
    )
  }, [
    editor.userGoalSettings?.monthlyAmount,
    editor.partnerGoalSettings?.monthlyAmount,
    monthsDiff,
  ])

  useEffect(() => {
    const fetchGoal = async () => {
      const editor = store.getState().goal.editor

      if (goalId) {
        await GoalManager.fetchGoal(goalId)
      }

      if (!editor.startDate || !editor.endDate) {
        const today = moment().format('YYYYMMDD')
        GoalManager.updateEditor({
          ...editor,
          startDate: today,
          endDate: isNoEndDate ? null : today,
        })
        setMonthsDiff(0)
      } else {
        updateMonthsDiff(editor.startDate, editor.endDate)
      }
    }
    fetchGoal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId])

  useEffect(() => {
    if (isFocused) {
      LifePlanManager.getLifePlanEvents()
        .then((lifePlanEvents) => {
          setLifePlanEvents(lifePlanEvents)
          setIsVisibleLifePlan(true)
        })
        .catch((error) => {
          if (error instanceof APIError) {
            if (
              error.response?.status === 422 &&
              error.response.errorCode === '005200'
            ) {
              return
            }
          }
          CommonDialog.showError({ error })
        })
    }
  }, [isFocused])

  const lifePlanName = useMemo(
    () =>
      lifePlanEvents?.find((v) => v.id === editor.lifeplanEventId)?.title ??
      '選択してください',
    [editor.lifeplanEventId, lifePlanEvents],
  )

  return (
    <View style={styles.container}>
      <CommonHeader title={title} />
      <ScrollView style={styles.scrollView}>
        <GoalImageView />
        <View style={styles.goalSection}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styles.subTitleSection}>タイトル</Text>
            <StyledRequiredView>
              <StyledRequiredText>必須</StyledRequiredText>
            </StyledRequiredView>
          </View>
          <TextInput
            style={styles.titleTextInput}
            value={editor.name}
            placeholder="入力してください"
            onChangeText={onChangeTitle}
            returnKeyType="done"
            placeholderTextColor={Color.PlaceHolderText}
          />
        </View>
        <View style={styles.goalSection}>
          <Text style={styles.subTitleSection}>目標額</Text>
          <Text style={styles.subTitleSection2}>
            目標額がない場合は0円での設定も可能です
          </Text>
          <View style={styles.goalAmountView}>
            <CalculatorInput
              inputType="keyboard"
              textStyle={{ fontSize: 18, fontWeight: 'normal' }}
              value={editor.goalAmount ?? 0}
              onChange={onChangeGoalAmount}
              style={{ flex: 1 }}
            />
          </View>
        </View>
        <View style={styles.periodSection}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={styles.subTitleSection}>貯金期間</Text>
              <StyledRequiredView>
                <StyledRequiredText>必須</StyledRequiredText>
              </StyledRequiredView>
            </View>
            <Pressable
              onPress={() => {
                setIsNoEndDate((prev) => !prev)
                GoalManager.updateEditor({
                  ...editor,
                  endDate: null,
                })
              }}>
              <View
                pointerEvents="none"
                style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: Color.DefaultText,
                    paddingRight: 3,
                  }}>
                  終了日を設定しない
                </Text>
                <CheckBox type="box" isOn={isNoEndDate} />
              </View>
            </Pressable>
          </View>
          <View style={styles.periodSelection}>
            {renderDateText('from')}
            <Text style={styles.dateTimeSep}>〜</Text>
            <View
              pointerEvents={isNoEndDate ? 'none' : undefined}
              style={{
                flex: 6,
                flexDirection: 'row',
                opacity: isNoEndDate ? 0 : undefined,
              }}>
              {renderDateText('to')}
            </View>
          </View>
        </View>
        {editor.share && isVisibleLifePlan && (
          <View style={styles.periodSection}>
            <View>
              <Text style={styles.subTitleSection}>ライフイベント</Text>
            </View>
            <View>
              <Text style={styles.subTitleSection2}>
                ライフプランにあるライフイベントと紐づけることができます
              </Text>
              <TouchableOpacity
                style={{
                  marginTop: 10,
                  height: 30,
                  flex: 5,
                  paddingVertical: 2,
                  borderBottomColor: '#f0f0f0',
                  borderBottomWidth: 1,
                }}
                onPress={() =>
                  NavigationService.navigate('ConnectLifePlan', {
                    editorMode: true,
                  })
                }>
                <Text
                  style={{
                    fontSize: 17,
                    fontWeight: 'normal',
                    color: '#3a3a3a',
                    textAlign: 'right',
                    marginRight: 30,
                  }}>
                  {lifePlanName}
                </Text>
                <TintedImage
                  style={styles.dateTimeSelectionItemImage}
                  source={require('@images/arrow/icon-arrow-white-small.png')}
                />
              </TouchableOpacity>
            </View>
          </View>
        )}
        <View
          style={{
            marginTop: 20,
            backgroundColor: '#f0f0f0',
            height: 28,
          }}
        />
        <View
          style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
          <Text style={[styles.titleSection, { paddingRight: 5 }]}>
            目標貯金のルール
          </Text>
          <QuestionButton />
        </View>
        <Text
          style={{
            color: '#666666',
            fontSize: 12,
            fontWeight: 'normal',
            marginTop: 10,
            marginLeft: 20,
          }}>
          <Text style={{ fontWeight: 'bold' }}>貯金のため方</Text>
          を決めましょう。
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
          }}>
          <Text style={[styles.subTitleSection, { marginLeft: 20 }]}>
            自動貯金か手動貯金はどちらにしますか？
          </Text>
          <StyledRequiredView>
            <StyledRequiredText>必須</StyledRequiredText>
          </StyledRequiredView>
        </View>
        <View style={{ marginVertical: 10 }}>
          {renderSaveAmountType('auto')}
          {renderSaveAmountType('manual')}
        </View>
        <Text
          style={[styles.subTitleSection, { marginLeft: 20, marginTop: 20 }]}>
          月々の目標貯金{' '}
        </Text>
        <View style={styles.accountItem}>
          <View style={styles.avatarView}>
            <Avatar />
            <Text style={styles.avatarText}>
              {ProfileManager.getName('user')}
            </Text>
          </View>
          <View style={styles.amountsView}>
            <View style={styles.settingAmountView}>
              <Text style={styles.amountPrefixText}>月々</Text>
              <AmountInput
                value={editor.userGoalSettings?.monthlyAmount}
                style={{ fontSize: 16, fontWeight: 'normal' }}
                onChange={onChangeUserMonthlyAmount}
              />
            </View>
          </View>
        </View>
        {/* パートナーの設定 */}
        {editor.share && (
          <View style={styles.accountItem}>
            <View style={styles.avatarView}>
              <Avatar type="partner" />
              <Text style={styles.avatarText}>
                {ProfileManager.getName('partner')}
              </Text>
            </View>
            <View style={styles.amountsView}>
              <View style={styles.settingAmountView}>
                <Text style={styles.amountPrefixText}>月々</Text>
                <AmountInput
                  value={editor.partnerGoalSettings?.monthlyAmount}
                  style={{ fontSize: 16, fontWeight: 'normal' }}
                  onChange={onChangePartnerMonthlyAmount}
                />
              </View>
            </View>
          </View>
        )}
        <StyledTotalView>
          <StyledTotalText>
            合計
            <StyledTotalAmountText>
              {isNoEndDate ? ' ¥- ' : ` ${totalAmount.jpCurrency()} `}
            </StyledTotalAmountText>
            貯金できます！
          </StyledTotalText>
        </StyledTotalView>
        <View style={styles.buttonArea}>
          <AppButton
            type="primary"
            title="次へ"
            onPress={() => {
              if (editor.saveAmountType === 'manual') {
                GoalManager.updateEditor({
                  ...editor,
                  userGoalSettings: editor.userGoalSettings
                    ? { ...editor.userGoalSettings, monthlyAmount: 0 }
                    : undefined,
                  partnerGoalSettings: editor.partnerGoalSettings
                    ? {
                        ...editor.partnerGoalSettings,
                        monthlyAmount: 0,
                      }
                    : undefined,
                  endDate: isNoEndDate ? null : editor.endDate,
                })
              }
              NavigationService.navigate('SelectAccount')
            }}
            disabled={
              editor.name === '' ||
              (editor.userGoalSettings?.monthlyAmount ?? 0) < 0 ||
              (editor.partnerGoalSettings?.monthlyAmount ?? 0) < 0 ||
              (editor.saveAmountType === 'auto' &&
                (editor.userGoalSettings?.monthlyAmount ?? 0) +
                  (editor.partnerGoalSettings?.monthlyAmount ?? 0) ===
                  0)
            }
          />
        </View>
        {/* From */}
        <DatePicker
          date={
            editor.startDate ? moment(editor.startDate).toDate() : undefined
          }
          isVisible={isFromDatePickerVisible}
          onConfirm={(date) => {
            setIsFromDatePickerVisible(false)
            const startDate = moment(date).format('YYYYMMDD')
            const endDate =
              editor.endDate &&
              moment(editor.endDate).diff(startDate, 'day') < 0
                ? moment(startDate).format('YYYYMMDD')
                : editor.endDate
            GoalManager.updateEditor({ ...editor, startDate, endDate })
            updateMonthsDiff(startDate, editor.endDate)
          }}
          onCancel={() => setIsFromDatePickerVisible(false)}
          headerFormat="日付を選択"
          confirmText="決定"
          cancelText="キャンセル"
        />
        {/* To */}
        <DatePicker
          date={editor.endDate ? moment(editor.endDate).toDate() : undefined}
          isVisible={isToDatePickerVisible}
          onConfirm={(date) => {
            setIsToDatePickerVisible(false)
            const endDate = moment(date).format('YYYYMMDD')
            GoalManager.updateEditor({ ...editor, endDate })
            updateMonthsDiff(editor.startDate, endDate)
          }}
          onCancel={() => setIsToDatePickerVisible(false)}
          headerFormat="日付を選択"
          confirmText="決定"
          cancelText="キャンセル"
          minimumDate={
            editor.startDate ? moment(editor.startDate).toDate() : undefined
          }
        />
      </ScrollView>
    </View>
  )
}

const StyledRequiredView = styled.View`
  margin-left: 5px;
  padding-horizontal: 5px;
  padding-vertical: 2px;
  background-color: ${Color.Orange};
  border-radius: 4px;
`

const StyledRequiredText = styled.Text`
  color: ${Color.White};
  font-size: 10px;
  font-weight: bold;
`

const StyledTotalView = styled.View({
  marginTop: 20,
  marginHorizontal: 20,
  borderWidth: 1,
  borderColor: '#eeeeee',
  backgroundColor: '#f8f8f8',
  borderRadius: 4,
  height: 30,
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledTotalText = styled.Text({
  color: Color.Black,
  fontSize: 14,
})

const StyledTotalAmountText = styled.Text({
  color: Color.Primary,
  fontWeight: 'bold',
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  topImage: {
    height: 120,
  },
  targetSection: {
    paddingTop: 30,
    backgroundColor: Color.White,
  },
  titleSection: {
    color: '#3a3a3a',
    fontSize: 17,
    fontWeight: 'bold',
    paddingLeft: 20,
  },
  subTitleSection: {
    color: '#3a3a3a',
    fontSize: 13,
    fontWeight: 'bold',
  },
  subTitleSection2: {
    marginTop: 4,
    color: '#3a3a3a',
    fontSize: 11,
    // fontWeight: 'bold',
  },
  goalAmountView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // height: 65,
    marginRight: 15,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderColor: '#f0f0f0',
  },
  goalSection: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 5,
  },
  titleTextInput: {
    fontSize: 15,
    fontWeight: 'normal',
    height: 36,
    paddingVertical: 6,
    marginRight: 15,
    marginTop: 10,
    borderBottomWidth: 1,
    borderColor: '#f0f0f0',
  },
  labelMonth: {
    fontSize: 15,
    fontWeight: 'normal',
    color: '#3a3a3a',
  },
  labelArbitrary: {
    fontSize: 10,
    fontWeight: 'normal',
    color: Color.White,
    backgroundColor: '#666666',
    paddingHorizontal: 5,
    paddingVertical: 2,
    marginLeft: 5,
    textAlign: 'center',
  },
  textNote: {
    color: '#3a3a3a',
    fontSize: 12,
    fontWeight: 'normal',
    marginTop: 10,
  },
  periodSection: {
    marginTop: 20,
    paddingHorizontal: 20,
  },
  periodSelection: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
  },
  dateTimeSep: {
    color: Color.DefaultText,
    fontSize: 17,
    fontWeight: 'normal',
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center',
  },
  dateTimeSelectionItem: {
    height: 30,
    flex: 5,
    paddingVertical: 2,
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
  },
  dateTimeSelectionItemText: {
    fontSize: 17,
    fontWeight: 'normal',
    color: '#3a3a3a',
  },
  dateTimeSelectionItemImage: {
    transform: [{ rotate: '90deg' }],
    tintColor: Color.Gray,
    position: 'absolute',
    right: 0,
    top: 5,
    width: 19,
    height: 19,
    resizeMode: 'contain',
  },
  cameraSection: {
    marginTop: 20,
    paddingHorizontal: 20,
  },
  cameraButton: {
    borderColor: Color.GreenBright,
    borderRadius: 8,
    borderWidth: 1,
    marginTop: 10,
    paddingVertical: 12,
    backgroundColor: Color.White,
    alignItems: 'center',
  },
  cameraButtonIcon: {
    width: 28,
    height: 28,
  },
  cameraButtonText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: Color.Primary,
    marginLeft: 10,
  },
  accountItem: {
    marginTop: 15,
    flexDirection: 'row',
    marginHorizontal: 20,
    alignItems: 'center',
  },
  avatarView: {
    alignItems: 'center',
  },
  amountsView: {
    flex: 1,
    marginLeft: 40,
  },
  settingAmountView: {
    flexDirection: 'row',
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
    height: 40,
    alignItems: 'center',
  },
  avatarText: {
    marginTop: 3,
    fontSize: 12,
    fontWeight: 'normal',
    color: '#3a3a3a',
  },
  amountPrefixText: {
    fontSize: 13,
    fontWeight: 'normal',
    color: '#3a3a3a',
  },
  amountSuffixText: {
    paddingRight: 10,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#f0f0f0',
  },
  buttonArea: {
    marginTop: 5,
    padding: 20,
  },
})

export default EditGoalDetailScreen
