import API, { APIResponse } from './index'

export interface PostBudgetQuestionsProps {
  budgetQuestions: {
    step: number
    budgetQuestionId: number
  }[]
}

class BudgetQuestions {
  postBudgetQuestions = (
    props: PostBudgetQuestionsProps
  ): Promise<APIResponse> => {
    return API.request('POST', '/api/v1/budget-questions', props, true)
  }
}

export default new BudgetQuestions()
