import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import { TransactionDetailScreenNavigationParams } from '@Screen/Main/TransactionDetailScreen'
import AdView from '@components/AdView'
import AppButton from '@components/AppButton'
import Avatar from '@components/Avatar'
import BlurView from '@components/BlurView'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TransactionItem from '@components/TransactionItem'
import { Transaction, TransactionDistributedType } from '@interfaces/Financial'
import { AccountState } from '@lib/AccountManager/types'
import AdfurikunManager from '@lib/AdfurikunManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import {
  getDateRange,
  getStartDate,
  getYearlyDateRange,
  parseMonthKey,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import DateUtilsV1 from '@lib/DateUtilsV1'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import ProgressHUD from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { UserProfile } from '@lib/api/User'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import {
  ActivityIndicator,
  Image,
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { connect } from 'react-redux'
import HomeExpensesSharingPieChart from '../HomeTopScreen/HomeExpenses/HomeExpensesSharingPieChart2'

interface Props {}

interface StateProps {
  account: AccountState
  financial: FinancialState
  userProfile?: UserProfile
}

interface State {
  distributedType: TransactionDistributedType
  sections: SectionListData<Transaction>[]
  refreshing: boolean
  planError: boolean
  isActive: boolean
  isLoadMovieAwardSuccess?: boolean
}

class HomeExpensesDetail extends React.PureComponent<
  Props &
    StateProps &
    StackScreenProps<RootStackParamList, 'HomeExpensesDetail'>
> {
  state: State = {
    distributedType: 'family',
    sections: [],
    refreshing: false,
    planError: false,
    isActive: false,
    isLoadMovieAwardSuccess: false,
  }

  // private listeners: string[] = []

  componentDidMount() {
    NavigationService.addNavigatableScreen('HomeExpensesShareDetail')

    this.props.navigation.addListener('focus', () => {
      this.setState({ isActive: true }, this.fetchData)

      TrackingUtils.ga.pageview({
        page: `HomeExpenses-Sharing-${
          this.props.account.accountMode === 'family' ? 'Family' : 'User'
        }`,
        title: `${
          this.props.account.accountMode === 'family' ? '家族' : '個人'
        }の家計簿 支出の分担`,
      })
    })
    this.props.navigation.addListener('blur', () => {
      this.setState({ isActive: false })
    })
  }

  componentWillUnmount() {
    NavigationService.removeNavigatableScreen('HomeExpensesShareDetail')

    const thisMonth = DateUtilsV1.monthKey()
    const accountMode = this.props.account.accountMode
    const { homeExpensesSummary } = this.props.financial[accountMode]
    Object.keys(homeExpensesSummary)
      .filter((k) => k !== thisMonth)
      .forEach((k) => delete homeExpensesSummary[k])

    // OsidoriEvent.removeListener(this.listeners)
  }

  async componentDidUpdate(prevProps: Props & StateProps, prevState: State) {
    const accountMode = this.props.account.accountMode

    const isUpdatedYearMonth =
      prevProps.financial[accountMode]?.homeExpensesSelectedYearMonth !==
      this.props.financial[accountMode]?.homeExpensesSelectedYearMonth

    const isUpdatedYearly =
      prevProps.financial.homeExpensesIsYearly !==
      this.props.financial.homeExpensesIsYearly

    if (isUpdatedYearMonth || isUpdatedYearly) {
      this.state.isActive && isUpdatedYearly && ProgressHUD.show()
      await this.fetchData()
      this.state.isActive && isUpdatedYearly && ProgressHUD.dismiss()
    }

    if (prevState.distributedType !== this.state.distributedType) {
      this.fetchTransactions()
    }
  }

  private fetchData = async () => {
    if (!this.state.isActive) return
    this.setState({ sections: [] })
    try {
      await this.fetchSummary()
      await this.fetchTransactions()
      this.setState({ planError: false })
    } catch (error) {
      if (
        error instanceof APIError &&
        (error.response?.errorCode === '007004' ||
          error.response?.errorCode === '007007')
      ) {
        this.setState({ planError: true })
        TrackingUtils.repro.track('【Screen】upper limit_pl', 'Screen')
        this.setState({ isLoadMovieAwardSuccess: undefined })
        AdfurikunManager.loadMovieReward((isLoadMovieAwardSuccess) => {
          this.setState({ isLoadMovieAwardSuccess })
        })
      } else {
        CommonDialog.showError({ error })
      }
    }
  }

  private getTargetMonthKey = () => {
    const targetMonthStartDate = this.homeExpensesSelectedDate()
    return targetMonthStartDate
      ? DateUtilsV1.monthKey(targetMonthStartDate)
      : undefined
  }

  private fetchSummary = async () => {
    const date = this.homeExpensesSelectedDate()
    if (!date) return
    await FinancialManager.fetchHomeExpensesSummary({
      date: date.format(),
      isYearly: this.props.financial.homeExpensesIsYearly,
    })
  }

  private renderSummary = () => {
    const yyyymm = this.getTargetMonthKey()
    if (!yyyymm) return

    const summary =
      this.props.financial.homeExpensesSummary[
        this.props.financial.homeExpensesIsYearly
          ? parseMonthKey(yyyymm).year
          : yyyymm
      ]
    if (!summary) return null
    return (
      <View style={{ paddingVertical: 10 }}>
        <HomeExpensesSharingPieChart
          summary={summary}
          displayDetailButton={false}
        />
        <View style={styles.shareAmountDiffView}>
          <Text style={styles.shareAmountDiffTitle}>ふたりの差分</Text>
          <Text style={styles.shareAmountDiffAmount}>
            {summary.ownerPartnerDiffAmount.jpCurrency()}
          </Text>
        </View>
      </View>
    )
  }

  private onPressItem = (item: Transaction) => {
    NavigationService.navigate('TransactionDetail', {
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId:
        item.atUserBankAccountId ||
        item.atUserCardAccountId ||
        item.atUserEmoneyServiceAccountId ||
        item.walletId,
      transactionId: item.transactionId,
    } as TransactionDetailScreenNavigationParams)
  }

  private onPressMemoButton = (item: Transaction) => {
    NavigationService.navigate('TransactionDetail', {
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId:
        item.atUserBankAccountId ||
        item.atUserCardAccountId ||
        item.atUserEmoneyServiceAccountId ||
        item.walletId,
      transactionId: item.transactionId,
      focusMemo: true,
    } as TransactionDetailScreenNavigationParams)
  }

  private renderItem: SectionListRenderItem<Transaction> = ({ item }) => {
    return (
      <TransactionItem
        transaction={item}
        onPress={() => this.onPressItem(item)}
        onPressMemoButton={() => this.onPressMemoButton(item)}
      />
    )
  }

  private homeExpensesSelectedDate = () => {
    const accountMode = this.props.account.accountMode

    const yearMonth =
      this.props.financial[accountMode]?.homeExpensesSelectedYearMonth
    if (!yearMonth) return undefined

    const { year, month } = parseMonthKey(yearMonth)
    const settings = getStartDateSettings()

    return getStartDate(
      year,
      month,
      settings.start,
      settings.startDateSettingOption,
    )
  }

  private fetchTransactions = async () => {
    if (!this.state.isActive) return
    const homeExpensesSelectedDate = this.homeExpensesSelectedDate()
    if (!homeExpensesSelectedDate) return

    const homeExpensesSelectedYearMonth =
      this.props.financial[this.props.account.accountMode]
        .homeExpensesSelectedYearMonth
    if (!homeExpensesSelectedYearMonth) return

    const yearMonth = parseMonthKey(homeExpensesSelectedYearMonth)
    const settings = getStartDateSettings()

    // const monthKey = DateUtilsV1.monthKey(moment(homeExpensesSelectedDate))

    const { startDate, endDate } = this.props.financial.homeExpensesIsYearly
      ? getYearlyDateRange(
          yearMonth.year,
          settings.start,
          settings.startDateSettingOption,
        )
      : getDateRange(
          yearMonth.year,
          yearMonth.month,
          settings.start,
          settings.startDateSettingOption,
        )

    // DateUtilsV1.getRangeOfMonths(
    //   moment(homeExpensesSelectedDate),
    // )

    await FinancialManager.fetchHomeExpensesSharingTransactions({
      accountType: this.props.account.accountMode,
      monthKey: this.props.financial.homeExpensesIsYearly
        ? homeExpensesSelectedYearMonth.substring(0, 4)
        : homeExpensesSelectedYearMonth,
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      share: this.props.account.isFamilyShareMode,
      distributedType: this.state.distributedType,
      exclusionCardDupulicate: true,
      transfer: 'pl',
    })
    this.updateSections()
    this.setState({ planError: false })
  }

  private updateSections = () => {
    const homeExpensesSelectedDate = this.homeExpensesSelectedDate()
    if (!homeExpensesSelectedDate) return

    const transactions: { [date: string]: Transaction[] } = {}

    const sections: SectionListData<Transaction>[] = []

    let yyyymm = DateUtilsV1.monthKey(moment(homeExpensesSelectedDate))
    if (this.props.financial.homeExpensesIsYearly)
      yyyymm = yyyymm.substring(0, 4)
    this.props.financial.homeExpensesSharingTransactions[yyyymm]?.forEach(
      (transaction: Transaction) => {
        const date = moment(transaction.usedDate).format('YYYYMMDD')
        if (transactions[date]) {
          transactions[date].push(transaction)
        } else {
          transactions[date] = [transaction]
        }
      },
    )

    const today = moment().format('YYYYMMDD')

    Object.keys(transactions)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: transactions[date],
        })
      })

    this.setState({ sections })
  }

  private onPressShareButton = (
    distributedType: TransactionDistributedType,
  ) => {
    this.setState({ distributedType })
  }

  private renderShareButtons = () => {
    return (
      <View style={{ paddingHorizontal: 15, paddingBottom: 15 }}>
        <Text style={{ fontWeight: 'bold' }}>分担の内訳</Text>
        <View style={{ alignItems: 'flex-end' }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={() => this.onPressShareButton('family')}
              style={[
                styles.shareButton,
                this.state.distributedType === 'family'
                  ? styles.shareButtonEnabled
                  : styles.shareButtonDisabled,
              ]}>
              <Text
                style={
                  this.state.distributedType === 'family'
                    ? styles.shareButtonTextEnabled
                    : styles.shareButtonTextDisabled
                }>
                家族
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => this.onPressShareButton('owner')}
              style={[
                styles.shareButton,
                this.state.distributedType === 'owner'
                  ? styles.shareButtonEnabled
                  : styles.shareButtonDisabled,
              ]}>
              <Text
                style={
                  this.state.distributedType === 'owner'
                    ? styles.shareButtonTextEnabled
                    : styles.shareButtonTextDisabled
                }>
                {ProfileManager.getName('user')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => this.onPressShareButton('partner')}
              style={[
                styles.shareButton,
                this.state.distributedType === 'partner'
                  ? styles.shareButtonEnabled
                  : styles.shareButtonDisabled,
              ]}>
              <Text
                style={
                  this.state.distributedType === 'partner'
                    ? styles.shareButtonTextEnabled
                    : styles.shareButtonTextDisabled
                }>
                {ProfileManager.getName('partner')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  private renderListHeader = () => {
    return (
      <View style={{ marginTop: 10 }}>
        {this.renderSummary()}
        {this.renderShareButtons()}
        {this.renderSharePanel()}
      </View>
    )
  }

  private renderSharePanel = () => {
    const yyyymm = this.getTargetMonthKey()
    const summary = yyyymm
      ? this.props.financial.homeExpensesSummary[
          this.props.financial.homeExpensesIsYearly
            ? yyyymm.substring(0, 4)
            : yyyymm
        ]
      : undefined
    if (!summary) return null
    const { distributedType } = this.state

    return (
      <View style={styles.sharePanel}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {distributedType === 'owner' && (
            <>
              <Avatar type="user" size={40} />
              <Text style={styles.sharePanelTitle}>
                {ProfileManager.getName('user')}の分担
              </Text>
            </>
          )}
          {distributedType === 'partner' && (
            <>
              <Avatar type="partner" size={40} />
              <Text style={styles.sharePanelTitle}>
                {ProfileManager.getName('partner')}の分担
              </Text>
            </>
          )}
          {distributedType === 'family' && (
            <>
              <Avatar type="couple" size={40} />
              <Text style={styles.sharePanelTitle}>家族の分担</Text>
            </>
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 15,
            paddingHorizontal: 5,
          }}>
          <View style={{ flex: 4 }}>
            <Text style={styles.sharePanelSubTitle}>金額</Text>
            <Text style={styles.sharePanelValue}>
              {(distributedType === 'owner'
                ? summary.owner.amount
                : distributedType === 'partner'
                ? summary.partner.amount
                : summary.family.amount
              ).jpCurrency()}
            </Text>
          </View>
          <View
            style={{
              flex: 3,
              borderLeftColor: Color.GrayVeryLight,
              borderLeftWidth: 1,
              borderRightColor: Color.GrayVeryLight,
              borderRightWidth: 1,
              paddingLeft: 15,
              paddingRight: 15,
            }}>
            <Text style={styles.sharePanelSubTitle}>分担の割合</Text>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.sharePanelValue}>
                {distributedType === 'owner'
                  ? summary.owner.rate
                  : distributedType === 'partner'
                  ? summary.partner.rate
                  : summary.family.rate}
              </Text>
              <Text style={{ marginBottom: 1 }}>%</Text>
            </View>
          </View>
          <View style={{ flex: 2, paddingLeft: 15 }}>
            <Text style={styles.sharePanelSubTitle}>件数</Text>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.sharePanelValue}>
                {distributedType === 'owner'
                  ? summary.owner.count
                  : distributedType === 'partner'
                  ? summary.partner.count
                  : summary.family.count}
              </Text>
              <Text style={{ marginBottom: 1 }}>件</Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  render() {
    // if (!this.state.isActive) return null
    const financial = this.props.financial[this.props.account.accountMode]

    const yyyymm = this.getTargetMonthKey()
    const summary = yyyymm
      ? financial.homeExpensesSummary[
          this.props.financial.homeExpensesIsYearly
            ? yyyymm.substring(0, 4)
            : yyyymm
        ]
      : undefined
    const transactions = yyyymm
      ? financial.homeExpensesSharingTransactions[
          this.props.financial.homeExpensesIsYearly
            ? yyyymm.substring(0, 4)
            : yyyymm
        ]
      : undefined

    if ((!summary || !transactions) && !this.state.planError) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            backgroundColor: 'white',
          }}>
          <ActivityIndicator color={Color.Gray} />
        </View>
      )
    }

    return (
      <View style={styles.container}>
        <View style={{ flex: 1, justifyContent: 'space-between' }}>
          <SectionList
            sections={this.state.sections}
            renderItem={this.renderItem}
            renderSectionHeader={({ section: { title } }) => (
              <SectionListHeader
                title={title}
                style={{ fontWeight: 'normal' }}
              />
            )}
            keyExtractor={(item: Transaction) =>
              item.transactionId + '-' + item.isTransactionShared
            }
            ListHeaderComponent={this.renderListHeader}
            refreshControl={
              <RefreshControl
                refreshing={this.state.refreshing}
                onRefresh={async () => {
                  this.setState({ refreshing: true })
                  await this.fetchData()
                  this.setState({ refreshing: false })
                }}
              />
            }
            ItemSeparatorComponent={() => ItemSeparator}
            ListFooterComponent={() => <View style={{ height: 24 }} />}
          />
          {this.props.userProfile?.rank === 'free' && (
            <AdView type="320x50" delay={500} />
          )}
        </View>
        {this.state.planError && (
          <BlurView
            disableBlur={Platform.OS === 'android' && !this.state.isActive}>
            {this.state.isLoadMovieAwardSuccess === undefined && (
              <View style={styles.absolute}>
                <ActivityIndicator color={Color.Gray} />
              </View>
            )}
          </BlurView>
        )}
        {this.state.planError &&
          this.state.isLoadMovieAwardSuccess !== undefined && (
            <View style={[styles.absolute, { alignItems: 'center' }]}>
              <Text style={styles.premiumPlanMessage}>
                <Text style={{ color: '#ff7200' }}>プレミアムプラン</Text>
                に加入すると{'\n'}
                過去の収支を表示できます
              </Text>
              <Image
                source={require('@images/premium/premium-4.png')}
                style={{
                  width: 195,
                  height: Math.min(103, wp(23)),
                  resizeMode: 'contain',
                }}
              />
              <AppButton
                title="詳しく見る"
                style={{
                  width: wp(80),
                  height: wp(12),
                  marginTop: wp(4),
                }}
                textStyle={{ fontSize: wp(4), fontWeight: 'bold' }}
                onPress={() => {
                  TrackingUtils.repro.track('【Tap】upper limit_pl', 'Tap')
                  navigatePremiumPlanLP({ planCode: '001' })
                  TrackingUtils.repro.setScreenTrackingAfterPurchase(
                    '【Screen】purchase completed via upper limit_pl',
                  )
                }}
              />
              <Text style={styles.premiumTextOr}>または</Text>
              <Text style={styles.premiumPlanMessage}>
                CMを1回視聴すると<Text style={{ color: '#4ba3f4' }}>無料</Text>
                で{'\n'}
                一定期間機能を利用できます
              </Text>
              <View
                style={{
                  backgroundColor: '#eeeeee',
                  paddingHorizontal: wp(6),
                  paddingVertical: wp(1),
                  borderRadius: wp(3),
                  marginBottom: wp(4),
                }}>
                <Text
                  style={{
                    color: '#333333',
                    fontSize: wp(8),
                    fontWeight: 'normal',
                  }}>
                  ▶
                </Text>
              </View>
              {this.state.isLoadMovieAwardSuccess ? (
                <AppButton
                  title="無料で使う"
                  style={{
                    width: wp(80),
                    height: wp(12),
                    backgroundColor: '#4ba3f4',
                  }}
                  textStyle={{ fontSize: wp(4), fontWeight: 'bold' }}
                  onPress={async () => {
                    AdfurikunManager.playMovieReward((success: boolean) => {
                      if (success) {
                        AdfurikunManager.adViewingComplete(true, async () => {
                          this.setState({ refreshing: true })
                          await this.fetchData()
                          this.setState({ refreshing: false })
                        })
                      } else {
                        AdfurikunManager.adViewingComplete(false)
                      }
                    })
                  }}
                />
              ) : (
                <Text
                  style={{
                    color: 'white',
                    fontSize: wp(4),
                    fontWeight: 'normal',
                    textAlign: 'center',
                  }}>
                  ただいま表示するCMがありません🙇‍♂️{'\n'}
                  時間を置いて再度アクセスしてください。
                </Text>
              )}
            </View>
          )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  shareAmountDiffView: {
    marginTop: 10,
    borderTopWidth: 2,
    borderTopColor: Color.LightGray,
    paddingTop: 15,
    paddingBottom: 5,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareAmountDiffTitle: {
    color: Color.DefaultText,
    flex: 1,
    fontSize: 14,
    fontWeight: 'normal',
    marginRight: 10,
    textAlign: 'right',
  },
  shareAmountDiffAmount: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  shareButton: {
    borderRadius: 20,
    marginLeft: 10,
  },
  shareButtonEnabled: {
    backgroundColor: Color.Primary,
  },
  shareButtonDisabled: {
    backgroundColor: Color.GrayVeryLight,
  },
  shareButtonTextEnabled: {
    color: Color.White,
    fontSize: 12,
    fontWeight: 'bold',
    paddingHorizontal: 15,
    paddingVertical: 8,
  },
  shareButtonTextDisabled: {
    color: Color.Gray,
    fontSize: 12,
    fontWeight: 'bold',
    paddingHorizontal: 15,
    paddingVertical: 8,
  },
  sharePanel: {
    marginHorizontal: 15,
    marginBottom: 15,
    padding: 10,
    borderWidth: 1,
    borderColor: Color.GrayVeryLight,
    borderRadius: 5,
  },
  sharePanelTitle: {
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },
  sharePanelSubTitle: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  sharePanelValue: {
    marginTop: 2,
    fontSize: 18,
    fontWeight: 'normal',
  },
  premiumTextOr: {
    margin: wp(3.5),
    color: 'white',
    fontSize: wp(4),
    fontWeight: 'bold',
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
    account: state.account,
    userProfile: state.profile.userProfile,
  }
}

export default connect(mapStateToProps)(HomeExpensesDetail)
