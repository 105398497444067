import Alert from '@components/Alert'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLeanpub } from '@fortawesome/free-brands-svg-icons'
import {
  faClipboardListCheck,
  faFileChartLine,
  faGraduationCap,
  faLockAlt,
  faPennant,
  faRoute,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faCloudDownloadAlt,
  faCommentsAltDollar,
  faDebug,
  faMobileAlt,
  faQuestionCircle,
  faSeedling,
} from '@fortawesome/pro-regular-svg-icons'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import { FAIcon } from '@lib/FAIcon'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent from '@lib/OsidoriEvent'
import ProfileManager from '@lib/ProfileManager'
import { SpotlightTourSpot } from '@lib/SpotlightTour'
import { ScreenName } from '@navigation/Screens'
import store from '@redux/store'
import { useCallback, useEffect, useRef } from 'react'
import {
  Image,
  ImageRequireSource,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { ENV } from 'react-native-dotenv'
import styled from 'styled-components/native'
import { navigatePremiumPlanLP } from '../PremiumPlan/index'
import TutorialTipsBalloon from '../TutorialTips/TutorialTipsBalloon'
import MenuGroup from './MenuGroup'
import MenuTop from './MenuTop'
import { StyledMenuItem } from './styles'

const SideMenu = () => {
  const renderMenu = ({
    icon,
    faIcon,
    title,
    newIcon,
    tintColor,
    underLine,
    onPress,
  }: {
    icon?: ImageRequireSource
    faIcon?: IconProp
    title: string
    newIcon?: boolean
    tintColor?: string
    underLine?: boolean
    onPress: () => void
  }) => (
    <>
      <StyledMenuItem onPress={onPress}>
        {icon && (
          <Image style={[styles.menuItemIcon, { tintColor }]} source={icon} />
        )}
        {faIcon && (
          <View style={styles.menuItemIcon}>
            <FAIcon icon={faIcon} size={24} color={Color.Gray} />
          </View>
        )}
        <Text style={styles.menuItemText}>{title}</Text>
        {newIcon && <Text style={styles.newIcon}>NEW!</Text>}
      </StyledMenuItem>
      {underLine && (
        <View
          style={{ marginVertical: 8, height: 0.5, backgroundColor: '#ddd' }}
        />
      )}
    </>
  )

  const goBackHandler = useCallback(() => {
    NavigationService.goBack()
    NavigationService.isKeepDisplayDrawer && NavigationService.openDrawer()
    NavigationService.isKeepDisplayDrawer = false
  }, [])

  const navigate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (screenName: ScreenName, params: any = {}) => {
      NavigationService.isKeepDisplayDrawer = true
      NavigationService.navigate(screenName, {
        goBack: goBackHandler,
        ...params,
      })
    },
    [goBackHandler],
  )

  const onPressPremiumPlan = useCallback(async () => {
    await ProfileManager.fetchUserProflie()
    if (store.getState().profile.userProfile?.rank === 'premium') {
      navigate('PremiumPlanForPremiumUser')
    } else {
      const planVersion =
        (store.getState().profile.userProfile?.userId ?? 0) % 2 === 0
          ? '3'
          : 'split'
      navigatePremiumPlanLP({ planVersion })
    }
  }, [navigate])

  const onPressSettingAccount = useCallback(
    () => navigate('SettingAccount'),
    [navigate],
  )

  const onPressSettingPairing = useCallback(
    () => navigate('SettingPairing'),
    [navigate],
  )

  const onPressSettingApp = useCallback(
    () => navigate('SettingApp'),
    [navigate],
  )

  const onPressLifePlan = useCallback(() => navigate('LifePlan'), [navigate])

  const onPressPayoffList = useCallback(
    () => navigate('PayoffList'),
    [navigate],
  )

  const onPressDataDownload = useCallback(
    () => navigate('DataDownload'),
    [navigate],
  )

  const onPressMonthlyReport = useCallback(
    () => navigate('MonthlyReport'),
    [navigate],
  )

  const onPressGoals = useCallback(
    () => navigate('LifePlan', { screen: 'GoalList' }),
    [navigate],
  )

  const onPressUsage = useCallback(() => {
    navigate('MediaWebView', {
      title: '家族のお金メディア👨‍🎓',
      uri: 'https://www.osidori.co/app/cate.php?tab=3',
    })
  }, [navigate])

  const onPressTutorial = useCallback(
    () => OsidoriEvent.emit('RequestOpenTutorial'),
    [],
  )

  const onPressOkaneMedia = useCallback(
    () =>
      navigate('MediaWebView', {
        title: '家族のお金メディア👨‍🎓',
        uri: 'https://www.osidori.co/app/cate.php',
      }),
    [navigate],
  )

  const onPressOthers = useCallback(() => navigate('SettingOthers'), [navigate])

  const onPressSecurity = useCallback(
    () => navigate('SettingSecurity'),
    [navigate],
  )

  const onPressWriteReview = useCallback(() => {
    switch (Platform.OS) {
      case 'ios':
        Alert.alert(
          `App Storeで応援レビューを\n書きますか？`,
          'ご質問・不具合ご報告・ご要望は「お問いわせ」よりサポートへご連絡ください。',
          [
            {
              text: 'キャンセル',
            },
            {
              text: 'OK',
              onPress: () => {
                Linking.openURL(
                  'https://itunes.apple.com/jp/app/id1473751623?mt=8&action=write-review',
                )
              },
            },
          ],
        )
        break
      case 'android':
        Alert.alert(
          `Play ストアで応援レビューを\n書きますか？`,
          'ご質問・不具合ご報告・ご要望は「お問いわせ」よりサポートへご連絡ください。',
          [
            {
              text: 'キャンセル',
            },
            {
              text: 'OK',
              onPress: () => {
                Linking.openURL('market://details?id=inc.osidori')
              },
            },
          ],
        )
        break
      default:
        break
    }
  }, [])

  const emailAuthenticated =
    store.getState().profile.userProfile?.emailAuthenticated || false

  const scrollViewRef = useRef<ScrollView>(null)

  const usageBefore = useCallback(() => {
    scrollViewRef.current?.scrollToEnd({ animated: true })
  }, [])

  useEffect(() => {
    const listener = OsidoriEvent.addListener(
      'RequestSideMenuScrollToTop',
      () => {
        scrollViewRef.current?.scrollTo({
          y: 0,
          animated: false,
        })
      },
    )
    return () => {
      OsidoriEvent.removeListener(listener)
    }
  })

  return (
    <>
      <MenuTop />
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={{ paddingVertical: 10, paddingBottom: 25 }}>
        {!emailAuthenticated && (
          <StyledMenuItem onPress={() => CommonDialog.unauthenticated()}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: -5,
              }}>
              <Image
                source={require('@images/icons/icon-caution.png')}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: 'contain',
                  marginRight: 2,
                }}
              />
              <Text
                style={{
                  color: Color.Orange,
                  fontWeight: 'bold',
                }}>
                メール認証を完了してください
              </Text>
            </View>
          </StyledMenuItem>
        )}
        <MenuGroup title="OsidOriのサービス" underline>
          <StyledMenuItem onPress={onPressPremiumPlan}>
            <Image
              style={styles.menuItemIcon}
              source={require('@images/icons/icon-premium.png')}
            />
            <StyledMenuTitleView>
              <StyledTitleText>OsidOriプレミアムプラン</StyledTitleText>
              <StyledSubTitleText>
                全機能が使い放題！無料でお試しできます
              </StyledSubTitleText>
            </StyledMenuTitleView>
          </StyledMenuItem>
          <StyledMenuItem
            onPress={() => {
              NavigationService.navigate('FP')
            }}>
            <View style={styles.menuItemIcon}>
              <FAIcon
                icon={faClipboardListCheck}
                size={24}
                color="rgb(74,162,123)"
              />
            </View>
            <StyledMenuTitleView>
              <StyledTitleText>かんたん家計診断</StyledTitleText>
              <StyledSubTitleText>
                わが家の家計・貯金はみんなと比べてどう？
              </StyledSubTitleText>
            </StyledMenuTitleView>
          </StyledMenuItem>
        </MenuGroup>
        <MenuGroup title="便利な機能" underline>
          {renderMenu({
            title: 'ライフプラン',
            faIcon: faRoute as never,
            tintColor: Color.Gray,
            onPress: onPressLifePlan,
          })}{' '}
          {renderMenu({
            title: '割り勘｜精算',
            faIcon: faCommentsAltDollar as never,
            tintColor: Color.Gray,
            onPress: onPressPayoffList,
          })}
          {renderMenu({
            title: '目標貯金',
            faIcon: faPennant as never,
            tintColor: Color.Gray,
            onPress: onPressGoals,
          })}
          {renderMenu({
            title: 'マンスリーレポート',
            faIcon: faFileChartLine as never,
            onPress: onPressMonthlyReport,
          })}
          {renderMenu({
            title: 'データバックアップ',
            faIcon: faCloudDownloadAlt as never,
            onPress: onPressDataDownload,
          })}
        </MenuGroup>
        <MenuGroup title="各種設定" underline>
          {renderMenu({
            title: '家計簿の設定',
            icon: require('@images/icons/icon-sidemenu-settings.png'),
            onPress: onPressSettingApp,
          })}
          {renderMenu({
            title: 'アカウント設定',
            icon: require('@images/icons/icon-sidemenu-account.png'),
            onPress: onPressSettingAccount,
          })}
          {renderMenu({
            title: 'ペアリング設定',
            icon: require('@images/icons/icon-sidemenu-pairing.png'),
            onPress: onPressSettingPairing,
          })}
          {renderMenu({
            title: 'セキュリティ',
            faIcon: faLockAlt as never,
            onPress: onPressSecurity,
          })}
        </MenuGroup>
        <MenuGroup title="その他">
          {renderMenu({
            title: 'アプリの使いかた',
            faIcon: faMobileAlt as never,
            onPress: onPressUsage,
          })}
          {renderMenu({
            title: 'お金のメディア',
            faIcon: faGraduationCap as never,
            onPress: onPressOkaneMedia,
          })}
          <SpotlightTourSpot
            spotId="tutorial"
            before={usageBefore}
            spotType="rect"
            tipsComponent={(props) => <TutorialTipsBalloon {...props} />}
            rectRadius={0}
            scale={1}
            startDelay={800}>
            {renderMenu({
              title: 'チュートリアル',
              faIcon: faLeanpub as IconProp,
              onPress: onPressTutorial,
            })}
          </SpotlightTourSpot>
          {renderMenu({
            title: 'FAQ・お問い合わせ 他',
            faIcon: faQuestionCircle as never,
            onPress: onPressOthers,
          })}
          {renderMenu({
            title: 'OsidOriを応援',
            faIcon: faSeedling as never,
            onPress: onPressWriteReview,
          })}
          {((__DEV__ && NativeApp) || ENV !== 'production') &&
            renderMenu({
              title: 'Debug',
              faIcon: faDebug as never,
              onPress: () => NavigationService.navigate('Debug'),
            })}
        </MenuGroup>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  menuItemIcon: {
    marginRight: 10,
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemText: {
    fontSize: 14,
    fontWeight: 'normal',
    color: 'gray',
  },
  newIcon: {
    fontSize: 14,
    color: 'red',
    marginLeft: 8,
  },
})

const StyledMenuTitleView = styled.View({})

const StyledTitleText = styled.Text({
  fontSize: 14,
  fontWeight: 'normal',
  color: 'gray',
})

const StyledSubTitleText = styled.Text({
  marginTop: 2,
  fontSize: 9,
  fontWeight: 'normal',
  color: 'rgb(153,153,153)',
})

export default SideMenu
