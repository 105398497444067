import { UserAccountType } from '@interfaces/Account'

export type BudgetSettingScreenParams = {}

export type BudgetSettingContentScreenParams = {
  userAccountType: UserAccountType
}

export const UsePlSettingsActionSheetIndex = {
  UsePlSettings: 0,
  BeginningOfMonth: 1,
  Cancel: 2,
}

export const UsePlSettingsActionSheetOptions = [
  '家計簿の集計期間',
  '月初から月末',
  'キャンセル',
]
