import { ActionType, State, Action } from './types'

const initialState: State = {
  displayType: undefined,
}

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateTransactionDisplayType:
      return { ...state, displayType: action.displayType }
    default:
      return state
  }
}

export default reducer
