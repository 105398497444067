import Avatar from '@components/Avatar'
import { AssessmentManager } from '@lib/api/Assessment'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Platform, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import {
  Score1Button,
  Score1DisabledIcon,
  Score1Icon,
  Score2Button,
  Score2DisabledIcon,
  Score2Icon,
  Score3Button,
  Score3DisabledIcon,
  Score3Icon,
} from './constant'
import { selectUserAccountType, selectYearMonth } from './slice'
import { StyledCommonPanel } from './styles'

const CommnentPanel = () => {
  const userAccountType = useSelector(selectUserAccountType)
  const yearMonth = useSelector(selectYearMonth)

  const [score, setScore] = useState<number>()
  const [comment, setComment] = useState<string>('')
  const [partnerScore, setPartnerScore] = useState<number>()
  const [partnerComment, setPartnerComment] = useState<string>('')

  const getAssessmentManager = useCallback(async () => {
    if (!userAccountType || !yearMonth) return
    const date = moment(yearMonth, 'YYYYMM')

    const assessment = await AssessmentManager.getAssessment({
      userAccountType,
      year: date.year(),
      month: date.month() + 1,
    })

    if (!assessment) return

    setScore(assessment.user.score || undefined)
    setComment(assessment.user.comment || '')
    setPartnerScore(assessment.partner?.score || undefined)
    setPartnerComment(assessment.partner?.comment || '')
  }, [userAccountType, yearMonth])

  useEffect(() => {
    getAssessmentManager()
  }, [getAssessmentManager])

  const onPressScoreButton = useCallback(
    (score: number) => {
      if (!userAccountType || !yearMonth) return

      const date = moment(yearMonth, 'YYYYMM')

      setScore(score)
      AssessmentManager.postAssessment({
        userAccountType,
        year: date.year(),
        month: date.month() + 1,
        score,
        comment,
      })
    },
    [comment, userAccountType, yearMonth],
  )

  const onChangeCommnet = useCallback((comment: string) => {
    setComment(comment)
  }, [])

  const onPressSaveButton = useCallback(async () => {
    if (!userAccountType || !yearMonth) return
    // if (!userAccountType || !yearMonth || !score) return

    const date = moment(yearMonth, 'YYYYMM')

    const trimmedComment = comment.trim()
    if (comment !== trimmedComment) {
      setComment(trimmedComment)
    }

    try {
      await AssessmentManager.postAssessment({
        userAccountType,
        year: date.year(),
        month: date.month() + 1,
        score,
        comment: trimmedComment,
      })
      CommonDialog.showMessage('保存しました')
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [comment, score, userAccountType, yearMonth])

  return (
    <>
      <StyledPanel>
        <StyledTitleText>今月を振り返ってひとこと！</StyledTitleText>
        <StyledAvatarView>
          <Avatar type="user" size={26} />
          <StyledSubTitleText>今月の評価は…</StyledSubTitleText>
        </StyledAvatarView>
        <StyledButtonsView>
          <TouchableOpacity onPress={() => onPressScoreButton(3)}>
            <StyledIconButtonImage on={score === 3} source={Score3Button} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onPressScoreButton(2)}>
            <StyledIconButtonImage on={score === 2} source={Score2Button} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onPressScoreButton(1)}>
            <StyledIconButtonImage on={score === 1} source={Score1Button} />
          </TouchableOpacity>
        </StyledButtonsView>
        <StyledCommentInputView>
          <StyledTextInput
            placeholder="メモを追加する"
            placeholderTextColor={Color.PlaceHolderText}
            value={comment}
            multiline={true}
            onChangeText={onChangeCommnet}
          />
          <StyledSaveButton onPress={onPressSaveButton}>
            <StyledSaveText>保存する</StyledSaveText>
          </StyledSaveButton>
        </StyledCommentInputView>
        {userAccountType === 'family' && (
          <StyledPartnerView>
            <StyledAvatarView>
              <Avatar type="partner" size={26} />
              <StyledPartnerTitleText>パートナーより</StyledPartnerTitleText>
            </StyledAvatarView>
            <StyledPartnerScoreView>
              <StyledPartnerScoreIcon
                source={partnerScore === 3 ? Score3Icon : Score3DisabledIcon}
              />
              <StyledPartnerScoreIcon
                source={partnerScore === 2 ? Score2Icon : Score2DisabledIcon}
              />
              <StyledPartnerScoreIcon
                source={partnerScore === 1 ? Score1Icon : Score1DisabledIcon}
              />
            </StyledPartnerScoreView>
            {partnerComment.trim().length > 0 && (
              <StyledPartnerCommentText numberOfLines={0}>
                {partnerComment.trim()}
              </StyledPartnerCommentText>
            )}
          </StyledPartnerView>
        )}
      </StyledPanel>
    </>
  )
}

const StyledPanel = styled(StyledCommonPanel)`
  padding-top: ${wp(7)}px;
  padding-bottom: ${wp(7)}px;
  padding-horizontal: ${wp(6)}px;
`

const StyledTitleText = styled.Text`
  /* margin-left: ${wp(3)}px; */
  margin-bottom: ${wp(6)}px;
  color: rgb(58, 58, 58);
  font-size: 16px;
  font-weight: bold;
`

const StyledIconButtonImage = styled.Image<{ on: boolean }>`
  opacity: ${({ on }) => (on ? 1.0 : 0.3)};
  width: 70px;
  height: 90px;
`

const StyledAvatarView = styled.View`
  flex-direction: row;
  align-items: center;
`

const StyledSubTitleText = styled.Text`
  margin-left: ${wp(1)}px;
  color: rgb(58, 58, 58);
  font-size: 14px;
  font-weight: normal;
`

const StyledButtonsView = styled.View`
  margin-top: ${wp(2)}px;
  padding-horizontal: ${wp(5)}px;
  flex-direction: row;
  justify-content: space-between;
`

const StyledCommentInputView = styled.View`
  margin-top: 20px;
  /* margin-horizontal: ${wp(5)}px; */
  padding-bottom: 8px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: #eee;
  align-items: flex-start;
`

const StyledSaveButton = styled.TouchableOpacity`
  margin-left: 8px;
  background-color: ${Color.Primary};
  padding-horizontal: 15px;
  padding-vertical: 8px;
  border-radius: 5px;
`

const StyledTextInput = styled.TextInput`
  font-size: 13px;
  font-weight: normal;
  color: ${({ value }) => (value ? 'rgb(58, 58, 58)' : Color.PlaceHolderText)};
  flex: 1;
  height: 100%;
  padding-top: ${() => Platform.select({ web: 7, default: 0 })}px;
`

const StyledSaveText = styled.Text`
  color: white;
  font-size: 12px;
  font-weight: bold;
`

const StyledPartnerView = styled.View`
  margin-top: 20px;
  background-color: rgb(247, 247, 247);
  border-radius: 5px;
  padding: ${wp(4)}px;
`

const StyledPartnerCommentText = styled.Text`
  margin-top: 18px;
  color: rgb(58, 58, 58);
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
`

const StyledPartnerScoreView = styled.View`
  margin-top: ${wp(4)}px;
  padding-horizontal: ${wp(5)}px;
  flex-direction: row;
  justify-content: space-between;
`

const StyledPartnerScoreIcon = styled.Image.attrs({
  resizeMode: 'contain',
})`
  width: ${wp(13)};
  height: ${wp(13)};
`

const StyledPartnerTitleText = styled.Text`
  margin-left: ${wp(1)}px;
  color: rgb(58, 58, 58);
  font-size: 14px;
  font-weight: bold;
`

export default CommnentPanel
