import CheckBox from '@components/CheckBox'
import Color from '@lib/Color'
import { LifePlanEvent } from '@lib/api/LifePlan'
import { useCallback } from 'react'
import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

export type LifePlanEventItemProps = Omit<TouchableOpacityProps, 'onPress'> & {
  lifePlanEvent: LifePlanEvent
  selectable?: boolean
  checked?: boolean
  onPress?: (lifePlanEvent: LifePlanEvent) => void
}
const LifePlanEventCard = ({
  lifePlanEvent,
  selectable,
  checked,
  onPress,
  ...props
}: LifePlanEventItemProps) => {
  const onPressItem = useCallback(() => {
    onPress?.(lifePlanEvent)
  }, [lifePlanEvent, onPress])

  const { title, age, imgUrl } = lifePlanEvent

  return (
    <StyledRootView onPress={onPressItem} {...props}>
      <StyledMainView>
        {selectable && <StyledCheckBox isOn={checked} disabled />}
        <StyledLiveEventView>
          <StyledImage source={{ uri: imgUrl }} />
          <StyledDatailView>
            <StyledTitleText>{title}</StyledTitleText>
            <StyledAgeView>
              <StyledAgeText>達成時期</StyledAgeText>
              <StyledAgeText>{age}歳</StyledAgeText>
            </StyledAgeView>
          </StyledDatailView>
        </StyledLiveEventView>
      </StyledMainView>
    </StyledRootView>
  )
}

const StyledRootView = styled.TouchableOpacity({})

const StyledMainView = styled.View({
  marginHorizontal: 15,
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: Color.White,
})

const StyledImage = styled.Image.attrs({ resizeMode: 'cover' })({
  width: 100,
  height: 'auto',
  aspectRatio: 480 / 321,
  borderRadius: 5,
})

const StyledLiveEventView = styled.View({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  padding: 10,
  marginVertical: 15,
  borderWidth: 1,
  borderColor: '#cccccc',
  borderRadius: 10,
  backgroundColor: Color.White,
  shadowColor: '#000000',
  shadowOffset: {
    width: 0,
    height: 10,
  },
  shadowOpacity: 0.1,
  shadowRadius: 4,
  elevation: 4,
})

const StyledCheckBox = styled(CheckBox)({
  marginRight: 15,
})

const StyledDatailView = styled.View({
  marginLeft: 10,
  flex: 1,
})

const StyledTitleText = styled.Text({
  color: Color.DefaultText,
  fontWeight: 'bold',
  fontSize: 14,
})

const StyledAgeView = styled.View({
  marginTop: 5,
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const StyledAgeText = styled.Text({
  color: Color.DefaultText,
  fontWeight: 'normal',
  fontSize: 12,
})

export default LifePlanEventCard
