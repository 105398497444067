import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import { PremiumType } from '@components/PremiumView'
import CommonDialog from '@lib/CommonDialog'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import { sendSignal } from '@lib/OsidoriSignal'
import ProfileManager from '@lib/ProfileManager'
import RNProgressHud from '@lib/ProgressHUD'
import PurchaseManager from '@lib/PurchaseManager'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import PremiumPlan, { GetPremiumPlansResponse } from '@lib/api/PremiumPlan'
import Purchase from '@lib/api/Purchase'
import store from '@redux/store'
import { useCallback, useEffect, useRef } from 'react'
import { EmitterSubscription, Platform } from 'react-native'
import {
  PurchaseError,
  Subscription,
  SubscriptionAndroid,
  SubscriptionPurchase,
  endConnection,
  finishTransaction,
  flushFailedPurchasesCachedAsPendingAndroid,
  getSubscriptions,
  initConnection,
  purchaseErrorListener,
  purchaseUpdatedListener,
  requestSubscription,
} from 'react-native-iap'
import RNTestFlight from 'react-native-test-flight'
import WebView, {
  WebViewMessageEvent,
  WebViewNavigation,
} from 'react-native-webview'

let products: Subscription[] = []
let selectedPlan = ''

const PremiumPlanWebContent = ({ type }: { type: PremiumType }) => {
  const webView = useRef<WebView>(null)

  useEffect(() => {
    initConnection().then(() => {
      fetchProfiles()

      flushFailedPurchasesCachedAsPendingAndroid()
        .catch((error) => {
          console.warn(error)
        })
        .then((value) => {
          console.log('flushFailedPurchasesCachedAsPendingAndroid done', {
            value,
          })
        })
    })

    return () => {
      endConnection()
    }
  }, [])

  const onMessage = useCallback((event: WebViewMessageEvent) => {
    const premiumType = event.nativeEvent.data as PremiumType
    sendSignal('TAPPED_UPPER_LIMIT_PREMIUM_CTA', premiumType)
    console.log({ premiumType })
  }, [])

  const injectedJavaScript = `
    $('#top-image').attr('src', 'images/${type}.png');

    let texts = {
      budget: '無料で作成する',
      term_transaction: '無料で追加登録する',
      financial_account: '無料で追加登録する',
      create_category: '無料で追加する',
      create_sub_category: '無料で追加する',
      sort_category: '無料でカスタマイズする',
      sort_sub_category: '無料でカスタマイズする',
      hide_category: '無料でカスタマイズする',
      hide_sub_category: '無料でカスタマイズする',
      goal: 'さっそく作成する',
    };

    $('#top-button').text(texts['${type}']);

    $('#top-button').click(function () {
      window.ReactNativeWebView.postMessage('${type}');
      return true;
    });
  `

  return (
    <WebView
      ref={webView}
      originWhitelist={['*']}
      source={{
        uri:
          Platform.OS === 'android'
            ? `file:///android_asset/premium.html`
            : `./assets/premium.html`,
      }}
      startInLoadingState={true}
      renderLoading={() => <AbsoluteActivityIndicator />}
      onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
      injectedJavaScript={injectedJavaScript}
      onMessage={onMessage}
      onStartShouldSetResponder={() => true}
      showsVerticalScrollIndicator={false}
      style={{ width: '100%', height: wp(104) }}
    />
  )
}

let purchaseUpdateSubscription: EmitterSubscription | undefined
let purchaseErrorSubscription: EmitterSubscription | undefined

const plans = PurchaseManager.getPlans(Platform.OS)

const onShouldStartLoadWithRequest = (event: WebViewNavigation) => {
  return checkURL(event.url)
}

const checkURL = (url: string) => {
  selectedPlan = ''

  if (url === 'osidori-subscribe://monthly_plan_with_partner') {
    subscribeSelectedPlan(plans.monthlyPlanWithPartner)
    return false
  } else if (url === 'osidori-subscribe://monthly_plan') {
    subscribeSelectedPlan(plans.monthlyPlan)
    return false
  } else if (url === 'osidori-subscribe://monthly_plan_with_partner?plan=001') {
    subscribeSelectedPlan(plans.monthlyPlanWithPartner2)
    return false
  } else if (url === 'osidori-subscribe://monthly_plan?plan=001') {
    subscribeSelectedPlan(plans.monthlyPlan2)
    return false
  } else if (url === 'osidori-subscribe://yearly_plan_with_partner') {
    subscribeSelectedPlan(plans.yearlyPlanWithPartner)
    return false
  } else if (url === 'osidori-subscribe://yearly_plan') {
    subscribeSelectedPlan(plans.yearlyPlan)
    return false
  }

  if (selectedPlan) {
    subscribeSelectedPlan(selectedPlan)
    return false
  }

  return true
}

const removeIapListener = () => {
  purchaseUpdateSubscription?.remove()
  purchaseErrorSubscription?.remove()
  purchaseUpdateSubscription = undefined
  purchaseErrorSubscription = undefined
}

const setIapListener = () => {
  removeIapListener()
  selectedPlan = ''

  purchaseErrorSubscription = purchaseErrorListener((error: PurchaseError) => {
    removeIapListener()

    RNProgressHud.dismiss()
    console.warn('purchaseErrorListener', error)
    if (error.code === 'E_USER_CANCELLED') {
      // CommonDialog.showMessage('購入をキャンセルしました。')
    } else {
      CommonDialog.debug(error)
    }
  })

  purchaseUpdateSubscription = purchaseUpdatedListener(
    async (purchase: SubscriptionPurchase) => {
      console.log('purchaseUpdatedListener', purchase)

      removeIapListener()

      const { transactionReceipt, purchaseToken, productId } = purchase
      if (transactionReceipt) {
        try {
          if (Platform.OS === 'ios') {
            await Purchase.appStoreReceiptVerification({
              receiptData: transactionReceipt,
              sandBox: __DEV__ || RNTestFlight.isTestFlight ? true : undefined,
            })
          } else if (purchaseToken) {
            await Purchase.googlePlayReceiptVerification({
              productId: productId,
              purchaseToken: purchaseToken,
            })
          } else {
            throw new Error('purchaseToken is undefined')
          }
        } catch (error) {
          Log.info(error)

          CommonDialog.showMessage(
            'アプリへのプラン反映が失敗ました。サポートへお問い合わせください',
          )
          return
        } finally {
          await finishTransaction({ purchase })
        }

        try {
          await ProfileManager.fetchUserProflie()

          if (store.getState().profile.userProfile?.rank !== 'premium') {
            // iOSで不明なイベントが発生する不具合の対策
            if (selectedPlan) {
              subscribeSelectedPlan(selectedPlan)
              selectedPlan = ''
            }
            return
          }

          // NavigationService.goBack()
          // NavigationService.navigate('PremiumPlanForPremiumUser', {
          //   goBack: NavigationService.goBack,
          // })

          sendSignal('PURCHASE_COMPLETE', selectedPlan)
        } catch (error) {
          CommonDialog.showError({ error, onPress: NavigationService.goBack })
        } finally {
          RNProgressHud.dismiss()
        }
      } else {
        removeIapListener()
        await finishTransaction({ purchase })

        CommonDialog.showMessage(
          '購入に失敗しました。時間を置いて再度操作を行ってください',
        )
      }
    },
  )
}

const execRequestSubscription = async (plan: string) => {
  try {
    setIapListener()
    if (Platform.OS === 'ios') {
      await requestSubscription({ sku: plan })
    } else {
      const offerToken = (
        products?.filter(
          (v) => v.productId === plan,
        )?.[0] as SubscriptionAndroid
      )?.subscriptionOfferDetails?.[0].offerToken

      if (offerToken) {
        await requestSubscription({
          sku: plan,
          subscriptionOffers: [
            {
              sku: plan,
              offerToken,
            },
          ],
        })
      }
    }
  } catch (error) {
    console.warn(error)
    Log.warn(error)
    RNProgressHud.dismiss()
  }
}

const subscribeSelectedPlan = async (plan: string) => {
  console.log('subscribeSelectedPlan', { plan })
  RNProgressHud.show()

  try {
    Log.info({ plan })

    try {
      await PurchaseManager.confirm({
        platform: Platform.OS,
        productId: plan,
      })
    } catch (error) {
      // 一旦消す
      RNProgressHud.dismiss()

      CommonDialog.showError({
        error,
        onPress: async () => {
          if (error instanceof APIError) {
            Log.info(
              `available_purchase=${error.response?.json?.availablePurchase}`,
            )
            if (error.response?.json?.availablePurchase) {
              // 購入処理へ
              RNProgressHud.show()
              try {
                await execRequestSubscription(plan)
              } catch (error) {
                Log.warn(error)
                RNProgressHud.dismiss()
              }
            }
          }
        },
      })
      return
    }

    await execRequestSubscription(plan)
  } catch (error) {
    Log.warn(error)
    RNProgressHud.dismiss()
  } finally {
    // removeIapListener() // bug
  }
}

const fetchProfiles = async () => {
  try {
    // TODO
    const response = await PremiumPlan.getPlamiumPlans()
    if (response.ok) {
      const plans = response.json as GetPremiumPlansResponse
      Log.info(plans)
    } else {
      throw new APIError(response)
    }

    RNProgressHud.show()

    await ProfileManager.fetchUserProflie()
    await ProfileManager.fetchPartnerProflie()

    const tmpProducts = await getSubscriptions({
      skus: Object.values(plans),
    })
    products = tmpProducts
  } catch (error) {
    console.warn(error)
    // CommonDialog.showError({ error, onPress: NavigationService.goBack })
    CommonDialog.showError({ error }) // TODO
  } finally {
    RNProgressHud.dismiss()
  }

  TrackingUtils.repro.track('【Screen】choose plan', 'Screen')
}

export default PremiumPlanWebContent
