import DateUtilsV1 from '@lib/DateUtilsV1'
import store from '@redux/store'
import moment from 'moment'
import { APIError } from '../api'
import * as BudgetAPI from './api'
import { setBudget } from './slice'
import {
  CreateBudgetProps,
  GetBudgetProps,
  GetBudgetResponse,
  UpdateBudgetProps,
} from './types'

export const getBudget = async (props: GetBudgetProps) => {
  const params: GetBudgetProps = props
  if (!params.year) {
    const { startDate } = DateUtilsV1.getRangeOfMonths(moment(), true)
    params.year = startDate.year()
    params.month = startDate.month() + 1
  }

  const response = await BudgetAPI.getBudget(params)
  if (response.ok) {
    const { budget } = response.json as GetBudgetResponse
    return budget
  } else {
    throw new APIError(response)
  }
}

export const fetchBudget = async (props: GetBudgetProps) => {
  const budget = await getBudget(props)
  store.dispatch(setBudget({ ...props, budget }))
  return budget
}

export const createBudget = async (props: CreateBudgetProps) => {
  const response = await BudgetAPI.createBudget(props)
  if (!response.ok) throw new APIError(response)
}

export const updateBudget = async (props: UpdateBudgetProps) => {
  const response = await BudgetAPI.updateBudget(props)
  if (!response.ok) throw new APIError(response)
}
