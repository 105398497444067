import { S3_BUCKET, S3_REGION } from 'react-native-dotenv'
import { getApiUrlIndex } from './api'

class S3 {
  getBucket = (): string => S3_BUCKET.split(',')[getApiUrlIndex()]

  getRemoteURL = (remotePath: string) => {
    return remotePath.startsWith('/')
      ? `https://${this.getBucket()}.s3.${S3_REGION}.amazonaws.com${remotePath}`
      : remotePath
  }
}

export const S3Utils = new S3()
