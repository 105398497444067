import { NativeApp } from '@lib/Env'
import API, { APIResponse } from '../index'
import { GetMonthlyReportCSVProps, GetTransactionsCSVProps } from './types'

export const getTransactionsCSV = (
  props: GetTransactionsCSVProps,
): Promise<APIResponse> => {
  if (NativeApp) {
    return API.requestBlob('GET', '/api/v3/csv/transaction', props, true)
  } else {
    return API.request('GET', '/api/v3/csv/transaction', props, true)
  }
}

export const getMonthlyReportCSV = (
  props: GetMonthlyReportCSVProps,
): Promise<APIResponse> => {
  if (NativeApp) {
    return API.requestBlob('GET', '/api/v3/csv/monthly', props, true)
  } else {
    return API.request('GET', '/api/v3/csv/monthly', props, true)
  }
}
