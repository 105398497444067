import { PlSettings } from '@interfaces/Profile'
import API from '../index'
import {
  ActivateUserProps,
  ChangeEmailProps,
  ChangePasswordProps,
  ChangePasswordRequestProps,
  ConfirmChangeEmailProps,
  CreateUserProfileProps,
  CreateUserProps,
  PostUserIconProps,
  ReauthenticateProps,
  SetupPasswordProps,
  UpdateHomeBackgroundImageProps,
  UpdateUserIconProps,
  UpdateUserProfileProps,
  WithdrawProps,
} from './types'

export const createUserProfile = (props: CreateUserProfileProps) =>
  API.request('POST', '/api/v1/user/profiles', props, true)

export const updateUserProfile = (props: UpdateUserProfileProps) =>
  API.request('PUT', '/api/v1/user/profiles', props, true)

export const getUserProfile = () =>
  API.request('GET', '/api/v1/user/profiles', {}, true)

export const activateUser = (props: ActivateUserProps) =>
  API.request('GET', '/api/v1/user/activate', props, true)

export const getUserIcon = () =>
  API.request('GET', '/api/v1/user/icon', {}, true)

export const postUserIcon = (props: PostUserIconProps) =>
  API.request('POST', '/api/v1/user/icon', props, true)

export const updateUserIcon = (props: UpdateUserIconProps) =>
  API.request('PUT', '/api/v1/user/icon', props, true)

export const postHomeBackgroundImage = (
  props: UpdateHomeBackgroundImageProps,
) =>
  API.request(
    'POST',
    `/api/v1/${props.userAccountType.userAccountPath()}/home_img`,
    { homeImgs: { homeImgUrl: props.imgUrl } },
    true,
  )

export const updateHomeBackgroundImage = (
  props: UpdateHomeBackgroundImageProps,
) =>
  API.request(
    'PUT',
    `/api/v1/${props.userAccountType.userAccountPath()}/home_img`,
    { homeImgs: { homeImgUrl: props.imgUrl } },
    true,
  )

export const createUser = (props: CreateUserProps) =>
  API.request('POST', '/api/v2/users', props, false, undefined, {
    updateRefreshTokenIfNeed: false,
  })

export const uiCreateUser = (props: CreateUserProps) =>
  API.request('POST', '/api/v2/ui/users', props, false, undefined, {
    updateRefreshTokenIfNeed: false,
  })

export const changeEmail = (props: ChangeEmailProps) =>
  API.request('POST', '/api/v1/users/change-email', props, true)

export const confirmChangeEmail = (props: ConfirmChangeEmailProps) =>
  API.request('POST', '/api/v1/users/confirm-change-email', props, false)

export const changePasswordRequest = (props: ChangePasswordRequestProps) =>
  API.request('POST', '/api/v1/users/change_password_request', props, false)

export const changePassowrd = (props: ChangePasswordProps) =>
  API.request('POST', '/api/v1/users/change_password', props, false)

export const setupPassowrd = (props: SetupPasswordProps) =>
  API.request('POST', '/api/v1/users/setup-password', props, true)

export const cancelQuestions = () =>
  API.request('GET', '/api/v1/users/cancel_questions', {}, true)

export const withdraw = (props: WithdrawProps) =>
  API.request('DELETE', '/api/v1/users', props, true)

export const reauthenticate = (props: ReauthenticateProps) =>
  API.request('POST', '/api/v1/user/resend', props, true)

export const getPartnerProfile = () =>
  API.request('GET', '/api/v1/group/profiles', {}, true)

export const getStatus = () =>
  API.request('GET', '/api/v2/user/status', {}, true)

export const getPlSettings = () =>
  API.request('GET', '/api/v2/user/pl-settings', {}, true)

export const updatePlSettings = (props: PlSettings) =>
  API.request(
    'PUT',
    '/api/v2/user/pl-settings',
    {
      plSettings: props,
    },
    true,
  )
