import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReportKey } from '@Screen/Debug/SystemReportScreen/types'

export type State = {
  [key in ReportKey]?: {
    startTime?: number
    endTime?: number
  }
}

type Reducer = {
  setStartTime: (
    state: State,
    action: PayloadAction<{ key: ReportKey; time?: number }>,
  ) => void
  setEndTime: (
    state: State,
    action: PayloadAction<{ key: ReportKey; time?: number }>,
  ) => void
}

const initialState: State = {}

const slice = createSlice<State, Reducer>({
  name: 'systemReport',
  initialState,
  reducers: {
    setStartTime: (state, { payload }) => {
      state[payload.key] = { startTime: payload.time || new Date().getTime() }
    },
    setEndTime: (state, { payload }) => {
      state[payload.key] = {
        startTime: state[payload.key]?.startTime,
        endTime: payload.time || new Date().getTime(),
      }
    },
  },
})

export const { setStartTime, setEndTime } = slice.actions

export default slice.reducer
