import { selectUserAccountType } from '@lib/AccountManager/selectors'
import TrackingUtils from '@lib/TrackingUtils'
import { selectGoal } from '@lib/api/Goal/slice'
import { LifePlanEvent, LifePlanManager } from '@lib/api/LifePlan'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import GoalCurrentAmountView from '../GoalCurrentAmountView'
import GoalLifePlan from './GoalLifePlan'
import GoalSummaryProgressView from './GoalSummaryProgressView'

const GoalSummaryScreen: React.FC<
  StackScreenProps<RootStackParamList, 'GoalSummary'>
> = () => {
  const isFocused = useIsFocused()

  const goal = useSelector(selectGoal)

  const [lifePlanEvent, setLifePlanEvent] = useState<LifePlanEvent>()

  const userAccountType = useSelector(selectUserAccountType)

  useEffect(() => {
    if (goal?.lifeplanEventId) {
      LifePlanManager.getLifePlanEvents().then((events) => {
        const lifePlanEvent = events.find(
          (event) => event.id === goal?.lifeplanEventId,
        )
        setLifePlanEvent(lifePlanEvent)
      })
    }
  }, [goal?.lifeplanEventId])

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.repro.track('【Screen】Saving_summary', 'Screen')

      TrackingUtils.ga.pageview({
        page: 'Goal-Overview',
        title: '目標貯金 目標の概要',
      })
    }
  }, [isFocused])

  if (!goal) return null

  return (
    <StyledScrollView>
      <StyledGoalView>
        <GoalCurrentAmountView goal={goal} />
        <GoalSummaryProgressView goal={goal} />
      </StyledGoalView>
      {userAccountType === 'family' && <GoalLifePlan />}
    </StyledScrollView>
  )
}

const StyledScrollView = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: false,
  contentContainerStyle: {
    paddingBottom: 100,
  },
})({
  flex: 1,
})

const StyledGoalView = styled.View({
  paddingHorizontal: 20,
})

export default GoalSummaryScreen
