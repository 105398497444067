import { Image } from '@components'
import AccountManager from '@lib/AccountManager'
import * as Icon from '@lib/Icon'
import SessionManager from '@lib/SessionManager'
import { RootState } from '@redux/store'
import { useCallback } from 'react'
import { TouchableOpacity, TouchableOpacityProps } from 'react-native'
import { useSelector } from 'react-redux'

type Props = {
  onChange?: (isFamilyShareMode: boolean) => void
}

const FamilyShareToggleButton = ({
  style,
  onChange,
}: Props & TouchableOpacityProps) => {
  const isFamilyShareMode =
    useSelector((state: RootState) => state.account.isFamilyShareMode) ||
    SessionManager.isFamilyShareMode()

  const onPress = useCallback(() => {
    const shareMode = !isFamilyShareMode
    AccountManager.updateFamilyShareMode(shareMode)
    onChange?.(shareMode)
  }, [onChange, isFamilyShareMode])

  return (
    <TouchableOpacity onPress={onPress} style={[style, {}]}>
      <Image
        source={
          isFamilyShareMode
            ? Icon.FamilyShareButton.On
            : Icon.FamilyShareButton.Off
        }
        style={{ width: 88, height: 32, resizeMode: 'contain' }}
      />
    </TouchableOpacity>
  )
}

export default FamilyShareToggleButton
