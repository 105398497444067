import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import FullWidthImage from '@components/FullWidthImage'
import NavigationService from '@lib/NavigationService'
import styled from 'styled-components/native'

const GoalLPScreen = () => {
  return (
    <>
      <CommonHeader title="目標貯金" />
      <StyledContainerView>
        <StyledContentView>
          <StyledImage1 />
          <ButtonsView />
        </StyledContentView>
        <StyledContentView>
          <StyledImage2 />
          <ButtonsView />
        </StyledContentView>
        <StyledContentView>
          <StyledImage3 />
          <ButtonsView />
        </StyledContentView>
        <StyledContentView>
          <StyledImage4 />
          <ButtonsView />
        </StyledContentView>
      </StyledContainerView>
    </>
  )
}

const ButtonsView = () => (
  <StyledButtonsView>
    <StyledAppButton1
      title="家族で貯金する"
      onPress={onPressNavigateGoalSelectTypeFamily}
    />
    <StyledAppButton1
      title="個人で貯金する"
      onPress={onPressNavigateGoalSelectTypeUser}
    />
  </StyledButtonsView>
)

const onPressNavigateGoalSelectTypeFamily = () =>
  NavigationService.navigate('GoalSelectType', { userAccountType: 'family' })

const onPressNavigateGoalSelectTypeUser = () =>
  NavigationService.navigate('GoalSelectType', { userAccountType: 'user' })

const StyledContainerView = styled.ScrollView({
  backgroundColor: 'white',
})

const StyledContentView = styled.View({})

const StyledImage1 = styled(FullWidthImage).attrs({
  source: require('@assets/images/goal/goal_lp_01.webp'),
})({})

const StyledImage2 = styled(FullWidthImage).attrs({
  source: require('@assets/images/goal/goal_lp_02.webp'),
})({
  marginTop: 30,
  marginHorizontal: 15,
})

const StyledImage3 = styled(FullWidthImage).attrs({
  source: require('@assets/images/goal/goal_lp_03.webp'),
})({})

const StyledImage4 = styled(FullWidthImage).attrs({
  source: require('@assets/images/goal/goal_lp_04.webp'),
})({
  marginTop: 30,
  marginHorizontal: 28,
})

const StyledFixedAppButtonView = styled.View({
  position: 'absolute',
  width: '100%',
  bottom: 40,
  paddingHorizontal: 28,
})

const StyledButtonsView = styled.View({
  flexDirection: 'row',
  position: 'absolute',
  width: '100%',
  bottom: 30,
  paddingHorizontal: 18,
  justifyContent: 'space-between',
})

const StyledAppButton1 = styled(AppButton).attrs({
  textStyle: { fontWeight: 'bold' },
})({
  flex: 1,
  marginHorizontal: 5,
})

const StyledAppButton2 = styled(AppButton).attrs({
  textStyle: { fontWeight: 'bold' },
})({
  marginTop: 15,
  marginHorizontal: 28,
  marginBottom: 40,
})

export default GoalLPScreen
