import TintedImage from '@components/TintedImage'
import Color from '@lib/Color'
import NavigationService from '@lib/NavigationService'
import { PureComponent } from 'react'
import {
  Image,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'

interface Props {}

interface State {
  visible: boolean
}

class GuidanceFirstInvitePartnerDialog extends PureComponent<Props, State> {
  private static current?: GuidanceFirstInvitePartnerDialog

  public static show = (visible = true) => {
    GuidanceFirstInvitePartnerDialog.current?.setState({ visible })
  }

  state = { visible: false }

  componentDidMount() {
    GuidanceFirstInvitePartnerDialog.current = this
  }

  componentWillUnmount() {
    GuidanceFirstInvitePartnerDialog.current = undefined
  }

  private close = () => {
    this.setState({ visible: false })
  }

  private onPress = () => {
    this.close()
    NavigationService.navigate('SettingPairing')
  }

  render() {
    return (
      <Modal
        transparent={true}
        visible={this.state.visible}
        animationType="fade"
        onRequestClose={this.close}>
        <View style={styles.container}>
          <Image
            source={require('@images/kamifubuki/kamifubuki.png')}
            style={styles.iconKamifubuki}
          />
          <View style={styles.body}>
            <View style={styles.wrapLogo}>
              <View style={{ flexDirection: 'row' }}>
                <TintedImage
                  source={require('@images/logo/logo.png')}
                  style={styles.logo}
                />
                <Text style={styles.title}>へようこそ！</Text>
              </View>
            </View>
            <View style={styles.wrapGuideImg1}>
              <Image
                source={require('@images/guide-img1/guide-img1.png')}
                style={styles.guideImg1}
              />
              <TouchableOpacity
                onPress={this.close}
                style={styles.wrapIconClose}>
                <Image
                  source={require('@images/common-header/common-header-close-button.png')}
                  style={styles.iconClose}
                />
              </TouchableOpacity>
            </View>
            <View style={styles.bodyPopup}>
              <Text
                style={
                  styles.welcome
                }>{`まずはパートナーを\n招待しましょう👫`}</Text>
              <Text style={styles.textContent}>
                {'家族のお金を2人で把握。\n個人のお金は自分専用ページで◎'}
              </Text>
              <TouchableOpacity onPress={this.onPress} style={styles.button}>
                <Text style={styles.titleButton}>いますぐ招待する</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  iconKamifubuki: {
    width: '100%',
    height: '40%',
  },
  body: {
    height: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'transparent',
    paddingHorizontal: wp('8%'),
    justifyContent: 'center',
  },
  guideImg1: {
    width: '100%',
    height: '100%',
  },
  wrapLogo: {
    marginBottom: 20,
    alignItems: 'center',
  },
  title: {
    color: Color.White,
    fontWeight: 'bold',
    fontSize: wp('7%'),
  },
  logo: {
    height: wp('7%'),
    width: wp('35%'),
    tintColor: Color.White,
    resizeMode: 'contain',
  },
  bodyPopup: {
    backgroundColor: Color.White,
    paddingHorizontal: 20,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  welcome: {
    color: '#69C900',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 15,
    fontWeight: 'bold',
  },
  textContent: {
    textAlign: 'center',
    marginTop: 10,
  },
  button: {
    backgroundColor: Color.Primary,
    width: '100%',
    height: 50,
    marginVertical: 25,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapGuideImg1: {
    height: '40%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  titleButton: {
    color: Color.White,
    fontSize: 17,
    fontWeight: 'bold',
  },
  iconClose: {
    width: 22,
    height: 22,
  },
  wrapIconClose: {
    position: 'absolute',
    top: 20,
    right: 15,
  },
})

export default GuidanceFirstInvitePartnerDialog
