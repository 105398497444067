import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core'
import { faRightToLine } from '@fortawesome/pro-regular-svg-icons'
import * as Buttons from '@lib/Buttons'
import Color from '@lib/Color'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList, ScreenName } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Image,
  ImageSourcePropType,
  ScrollView,
  SectionListData,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native'
import MenuListItem from './MenuListItem'

type ListItem = {
  title: string
  screen: ScreenName
  params?: unknown
  subTitle?: string
  iconImageSource?: ImageSourcePropType
  iconDefinition?: IconProp | IconDefinition
  style?: ViewStyle
}

const SettingAppScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingApp'>
> = ({ route }) => {
  const goBack = useCallback(() => {
    if (route.params?.goBack) {
      route.params.goBack()
    } else {
      NavigationService.goBack()
    }
  }, [route.params])

  const renderItem = useCallback((props: ListItem) => {
    const {
      title,
      style,
      iconImageSource,
      iconDefinition,
      subTitle,
      screen,
      params,
    } = props
    const key = JSON.stringify(props)

    return (
      <MenuListItem
        key={key}
        title={title}
        isDisplayArrow={true}
        style={style}
        iconImageSource={iconImageSource}
        iconDefinition={iconDefinition}
        subTitle={subTitle}
        onPress={() => NavigationService.navigate(screen, params)}
      />
    )
  }, [])

  const sectionListHeader = useCallback(
    (section: SectionListData<ListItem>) => {
      const { title, subTitle, iconImageSource, style } = section
      const key = title
      if (!title)
        return (
          <View
            key={key}
            style={{
              height: 1,
              width: '100%',
              backgroundColor: Color.LightGray,
            }}
          />
        )
      return (
        <>
          <View
            style={{
              height: 1,
              width: '100%',
              backgroundColor: Color.LightGray,
            }}
          />
          <View
            style={[
              {
                backgroundColor: 'white',
                paddingTop: 20,
                paddingBottom: 6,
                paddingLeft: 15,
                flexDirection: 'row',
                alignItems: 'center',
              },
              style,
            ]}>
            {iconImageSource && (
              <Image source={iconImageSource} style={styles2.icon} />
            )}
            <View style={{ flexDirection: 'column' }}>
              <Text
                style={[
                  styles2.text,
                  {
                    fontWeight: subTitle ? 'bold' : undefined,
                  },
                ]}>
                {title}
              </Text>
              <Text style={styles2.subTitle}>{subTitle}</Text>
            </View>
          </View>
        </>
      )
    },
    [],
  )

  const sessions = useMemo(
    (): SectionListData<ListItem>[] => [
      {
        data: [
          {
            title: '取引明細のデフォルト設定',
            subTitle: '新規で作成する取引明細の初期値を設定',
            screen: 'SettingTransactionDefault',
            iconImageSource: Buttons.Input as never,
          },
        ],
      },
      {
        data: [
          {
            title: '集計期間設定',
            subTitle: '月収支の集計期間（25日開始等）を設定',
            iconImageSource: Icon.MenuCalendar as never,
            screen: 'SettingPeriodTab',
          },
        ],
      },
      {
        data: [
          {
            title: 'カテゴリーの設定',
            subTitle: 'カテゴリー新規追加等で便利にカスタマイズ',
            iconImageSource: Icon.MenuCategory as never,
            screen: 'SettingCategorySelectUserAccountType',
          },
        ],
      },
      {
        data: [
          {
            title: '繰り返し明細の設定',
            subTitle: '定期的な支出収入（固定費等）を1度に作成',
            screen: 'TermTransactions',
            iconImageSource: Icon.MenuRepeat as never,
          },
        ],
      },
      {
        data: [
          {
            title: '予算設定',
            screen: 'BudgetSetting',
            subTitle: '月の予算設定で支出進捗や残予算が見える化',
            iconImageSource: Icon.MenuBudget as never,
          },
        ],
      },
      {
        data: [
          {
            title: '分担ルール設定',
            screen: 'SettingPayoff',
            subTitle: '支出の分担割合（60%：40%等）を設定',
            iconImageSource: require('@images/icons/icon-calculator.png'),
          },
        ],
      },
      {
        data: [
          {
            title: '繰り越し設定',
            screen: 'SettingCarryForward',
            subTitle: '1ヶ⽉間の収⽀結果を翌⽉へ繰り越す設定',
            iconDefinition: faRightToLine as never,
          },
        ],
      },
    ],
    [],
  )

  useEffect(() => {
    const subscription = HardwareBackPressHandler.addListener(goBack)
    return () => {
      subscription.remove()
    }
  }, [goBack])

  return (
    <View style={styles.container}>
      <CommonHeader title="家計簿の設定" onPressLeftButton={goBack} />
      <ScrollView>
        {sessions.map((section) => (
          <>
            {sectionListHeader(section)}
            {section.data.map((item) => (
              <>
                {renderItem(item)}
                {ItemSeparator}
              </>
            ))}
          </>
        ))}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.LightGray,
  },
  flatList: {
    flex: 1,
  },
})

export default SettingAppScreen

const styles2 = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: Color.White,
    height: 68,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    marginLeft: 8,
    marginTop: -3,
    alignSelf: 'flex-start',
  },
  text: {
    marginLeft: 8,
    fontSize: 15,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  subTitle: {
    marginTop: 6,
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Gray,
  },
})
