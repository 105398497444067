import CommonListItem from '@components/CommonListItem'
import ItemSeparator from '@components/ItemSeparator'
import PairingCompletedDialog from '@components/PairingCompletedDialog'
import SectionListHeader from '@components/SectionListHeader'
import { getApiUrl, updateApiUrlIndex } from '@lib/api'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { S3Utils } from '@lib/S3Utils'
import SessionManager from '@lib/SessionManager'
import { ScreenName } from '@navigation/Screens'
import { updateRootScreen } from '@redux/actions'
import store, { RootState } from '@redux/store'
import React, { useCallback, useMemo, useState } from 'react'
import {
  DefaultSectionT,
  ListRenderItem,
  SectionList,
  SectionListData,
  View,
} from 'react-native'
import { ENV, S3_REGION, VERSION_CODE, VERSION_NAME } from 'react-native-dotenv'
import { useSelector } from 'react-redux'

interface ListItem {
  title: string
  screen?: ScreenName
  params?: any
  key?: string
  onPress?: (key?: string) => void
}

const DebugInformationScreen: React.FC = () => {
  const profile = useSelector(({ profile }: RootState) => profile)

  const [apiServer, setApiServer] = useState<string>(getApiUrl())
  const [s3Bucket, setS3Bucket] = useState<string>(S3Utils.getBucket())

  const renderItem: ListRenderItem<ListItem> = useCallback(({ item }) => {
    return (
      <CommonListItem
        title={item.title}
        // isDisplayArrow={!!item.screen}
        onPress={() => {
          item.screen && NavigationService.navigate(item.screen, item.params)
          item.onPress && item.onPress(item.key)
        }}
      />
    )
  }, [])

  const sessions: SectionListData<ListItem>[] = useMemo((): SectionListData<
    ListItem,
    DefaultSectionT
  >[] => {
    const { userProfile, partnerProfile } = profile
    return [
      {
        title: '環境',
        data: [
          {
            title: ENV,
          },
          {
            title: `${VERSION_NAME} Build ${VERSION_CODE}`,
          },
          {
            title: `${S3_REGION}`,
          },
          {
            title: `${s3Bucket}`,
          },
        ],
      },
      {
        title: 'APIサーバ',
        data: [
          {
            title: `[${
              apiServer.startsWith('https://dev')
                ? 'DEV'
                : apiServer.startsWith('https://stg')
                ? 'STG'
                : '本番'
            }] ${apiServer}\nタップでサーバ切り替え`,
            onPress: () => {
              updateApiUrlIndex()
              setS3Bucket(S3Utils.getBucket())
              setApiServer(getApiUrl())
            },
          },
          {
            title:
              '  1. 再起動(APIサーバを変更した場合はまずここをタップ。一度ログアウトします。)',
            onPress: () => {
              NavigationService.goBack()
              NavigationService.logout()
              store.dispatch(updateRootScreen('Splash'))
            },
          },
          {
            title:
              '  2. 起動時間再計測(ログイン済み状態でアプリ起動時間の計測をします)',
            onPress: () => {
              NavigationService.goBack()
              store.dispatch(updateRootScreen('Splash'))
            },
          },
        ],
      },
      {
        title: 'ユーザー情報 (/api/v1/user/profiles)',
        data: [
          { title: `userId: ${userProfile?.userId}` },
          { title: `partner_user_id: ${userProfile?.partnerUserId}` },
          { title: `birthday: ${userProfile?.birthday}` },
          { title: `gender: ${userProfile?.gender}` },
          { title: `has_child: ${userProfile?.hasChild}` },
          { title: `img_url: ${userProfile?.imgUrl}` },
          { title: `home_img_url: ${userProfile?.homeImgUrl}` },
          { title: `push: ${userProfile?.push}` },
          { title: `rank: ${userProfile?.rank}` },
          { title: `premium_plan: ${userProfile?.premiumPlan}` },
          {
            title: `partner_premium_plan: ${JSON.stringify(
              userProfile?.purchasePlatform,
            )}`,
          },
          { title: `email: ${userProfile?.email}` },
          { title: `email_authenticated: ${userProfile?.emailAuthenticated}` },
        ],
      },
      {
        title: 'パートナー情報 (/api/v1/group/profiles)',
        data: [
          { title: `user_id: ${partnerProfile?.userId}` },
          { title: `partner_user_id: ${partnerProfile?.partnerUserId}` },
          { title: `birthday: ${partnerProfile?.birthday}` },
          { title: `gender: ${partnerProfile?.gender}` },
          { title: `has_child: ${partnerProfile?.hasChild}` },
          { title: `img_url: ${partnerProfile?.imgUrl}` },
          { title: `home_img_url: ${partnerProfile?.homeImgUrl}` },
          { title: `partner_rank: ${partnerProfile?.partnerRank}` },
          {
            title: `partner_premium_plan: ${partnerProfile?.partnerPremiumPlan}`,
          },
        ],
      },
      {
        title: '認証情報',
        data: [
          { title: `access_token: ${SessionManager.getAccessToken()}` },
          {
            title: `access_token_expires_at: ${SessionManager.getAccessTokenExpires()}`,
          },
          { title: `refresh_token: ${SessionManager.getRefreshToken()}` },
          {
            title: `refresh_token_expires_at: ${SessionManager.getRefreshTokenExpires()}`,
          },
        ],
      },
      {
        title: 'リセット',
        data: [
          {
            title: 'チュートリアル表示フラグリセット',
            onPress: () => {
              SessionManager.setIsTutorialDisplayCompleted(false)
              CommonDialog.showMessage(
                'リセットしました。\n再ログインが必要です。',
                () => {
                  NavigationService.goBack()
                  NavigationService.logout()
                },
              )
            },
          },
          {
            title: 'チュートリアル(明細)表示フラグリセット',
            onPress: () => {
              SessionManager.setIsTutorialTransactionDisplayCompleted(false)
              CommonDialog.showMessage('リセットしました。')
            },
          },
          {
            title: 'カレンダー初期表示ポップアップフラグリセット',
            key: 'ResetTransactionTabDisplayConpleted',
            onPress: () => {
              SessionManager.setTransactionTabDisplayCompleted(false)
              CommonDialog.showMessage('リセットしました。')
            },
          },
          {
            title: '全リセット（アプリインストール直後とほぼ同じ状態にします）',
            key: 'AllReset',
            onPress: () => {
              NavigationService.goBack()
              SessionManager.clearAll()
              // NavigationService.navigate('Splash')
              NavigationService.logout()
            },
          },
        ],
      },
      {
        title: '強制ログアウト',
        data: [
          {
            title: 'メールアドレス認証前でも強制ログアウトします',
            key: 'ForceLogout',
            onPress: () => {
              NavigationService.goBack()
              NavigationService.logout()
            },
          },
        ],
      },
      {
        title: 'テスト',
        data: [
          {
            title: 'ペアリング完了ダイアログ表示',
            onPress: () => {
              PairingCompletedDialog.show()
            },
          },
          {
            title: 'ユーザー情報初期登録画面表示',
            onPress: () => {
              NavigationService.goBack()
              store.dispatch(updateRootScreen('Welcome'))
            },
          },
          {
            title: '無料ユーザー用プレミアムプラムLP表示(Web)',
            screen: 'PremiumPlanWebLP',
          },
          {
            title: '無料ユーザー用プレミアムプラムLP表示(lp4.php)',
            screen: 'PremiumPlanWebLP',
          },
          {
            title: '無料ユーザー用プレミアムプラムLP表示(lp4.php?plan=001)',
            screen: 'PremiumPlanWebLP',
            params: { planCode: '001' },
          },
          {
            title: 'ウィークリーレポート表示',
            screen: 'WeeklyReportWebView',
          },
          {
            title: 'マンスリーレポート表示',
            screen: 'MonthlyReport',
          },
        ],
      },
    ]
  }, [apiServer, profile, s3Bucket])

  return (
    <View style={{ flex: 1, backgroundColor: Color.LightGray }}>
      <SectionList
        style={{ flex: 1, marginBottom: 30 }}
        keyExtractor={(item) => item.title}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title } }) => (
          <SectionListHeader title={title} />
        )}
        sections={sessions}
        ItemSeparatorComponent={() => ItemSeparator}
      />
      <PairingCompletedDialog />
    </View>
  )
}

export default DebugInformationScreen
