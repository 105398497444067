import CategoryImage from '@components/CategoryImage'
import React from 'react'
import {
  AmountText,
  CountText,
  PayoffCategoryImage,
  RootContainer,
  StyledPressable,
  TitleContainer,
  TitleText,
} from './styles'
import { SettledListItemProps } from './types'

const SettledListItem: React.FC<SettledListItemProps> = ({
  payoff,
  onPress,
}) => {
  return (
    <RootContainer>
      <StyledPressable onPress={onPress}>
        <TitleContainer>
          {payoff.atTransactionCategoryId ? (
            <CategoryImage categoryId={payoff.atTransactionCategoryId} />
          ) : (
            <PayoffCategoryImage
              resizeMode="contain"
              source={require('@images/category/icon-category-payoff.png')}
              style={{ width: 24, height: 24 }}
            />
          )}
          <TitleText ellipsizeMode="tail" numberOfLines={1}>
            {payoff.usedLocation.length > 0
              ? payoff.usedLocation
              : payoff.categoryName2}
          </TitleText>
        </TitleContainer>
        {payoff.transactionCount > 1 && (
          <CountText>{payoff.transactionCount}件</CountText>
        )}
        <AmountText>{payoff.payoffAmount?.jpCurrency() || 0}</AmountText>
      </StyledPressable>
    </RootContainer>
  )
}

export default SettledListItem
