import ImageResizer, {
  ResizeFormat,
  Response,
} from '@bam.tech/react-native-image-resizer'
import ActionSheet from '@components/ActionSheet'
import NavigationService from '@lib/NavigationService'
import * as ImagePickerWeb from 'expo-image-picker'
import { Platform } from 'react-native'
import RNFS from 'react-native-fs'
import ImagePicker from 'react-native-image-crop-picker'

const ChooseImageActionSheetIndex = {
  Camera: 0,
  PhotoLibraly: 1,
  Cancel: 2,
  Delete: -1,
}

const ChooseImageActionSheetWithDeleteIndex = {
  Camera: 0,
  PhotoLibraly: 1,
  Delete: 2,
  Cancel: 3,
}

const ChooseImageActionSheetOptions = [
  'カメラ',
  'フォトライブラリ',
  'キャンセル',
]

const ChooseImageActionSheetWithDeleteOptions = [
  'カメラ',
  'フォトライブラリ',
  '削除',
  'キャンセル',
]

const CommonImagePickerSettings = {
  cropping: true,
  writeTempFile: true,
  cropperToolbarTitle: '写真の編集',
  cropperCancelText: 'キャンセル',
  cropperChooseText: '選択',
  loadingLabelText: '',
  // compressImageQuality: Platform.select({ ios: 0.8, android: 0.9 }),
  forceJpg: true,
}

export type ImagePickerCallbackProps = {
  path: string
  mime: string
  data: string | null | undefined
}

export type OpenImagePickerCallback = (
  imageFile: ImagePickerCallbackProps | undefined,
) => void // string (web)
export type ChooseDeleteCallback = () => void

export const open = (
  props: {
    compressImageMaxWidth: number
    compressImageMaxHeight: number
    width?: number
    height?: number
    freeStyleCropEnabled?: boolean
    includeBase64?: boolean
  },
  callback: OpenImagePickerCallback,
  chooseDeleteCallback?: ChooseDeleteCallback,
) => {
  Platform.OS !== 'web'
    ? openNative(props, callback, chooseDeleteCallback)
    : openWeb(props, callback)
}

export const openNative = (
  {
    compressImageMaxWidth,
    compressImageMaxHeight,
    width,
    height,
    freeStyleCropEnabled,
    includeBase64,
  }: {
    compressImageMaxWidth: number
    compressImageMaxHeight: number
    width?: number
    height?: number
    freeStyleCropEnabled?: boolean
    includeBase64?: boolean
  },
  callback: OpenImagePickerCallback,
  chooseDeleteCallback?: ChooseDeleteCallback,
) => {
  const options = {
    compressImageMaxWidth,
    compressImageMaxHeight,
    width,
    height,
    freeStyleCropEnabled,
    includeBase64,
  }

  const actionSheetOptions = chooseDeleteCallback
    ? ChooseImageActionSheetWithDeleteOptions
    : ChooseImageActionSheetOptions
  const actionSheetIndex = chooseDeleteCallback
    ? ChooseImageActionSheetWithDeleteIndex
    : ChooseImageActionSheetIndex

  ActionSheet.showActionSheetWithOptions(
    {
      options: actionSheetOptions,
      cancelButtonIndex: actionSheetIndex.Cancel,
    },
    async (index: number) => {
      if (index === actionSheetIndex.Camera) {
        callback(await openCamera(options))
      } else if (index === actionSheetIndex.PhotoLibraly) {
        callback(await openGallery(options))
      } else if (index === actionSheetIndex.Delete) {
        chooseDeleteCallback?.()
      } else {
        callback(undefined)
      }
    },
  )
}

export const openCamera = async ({
  compressImageMaxWidth,
  compressImageMaxHeight,
  width,
  height,
  freeStyleCropEnabled,
  includeBase64,
}: {
  compressImageMaxWidth: number
  compressImageMaxHeight: number
  width?: number
  height?: number
  freeStyleCropEnabled?: boolean
  includeBase64?: boolean
}): Promise<ImagePickerCallbackProps | undefined> => {
  const file = await ImagePicker.openCamera({
    ...CommonImagePickerSettings,
    mediaType: 'photo',
    compressImageMaxWidth,
    compressImageMaxHeight,
    width,
    height,
    freeStyleCropEnabled,
    includeBase64,
    avoidEmptySpaceAroundImage: false,
  })

  if (Array.isArray(file)) return undefined
  // console.log('file.size', file.size, file.width, file.height)

  if (file.size > 200000) {
    const response = await resize({
      uri: Platform.OS === 'ios' ? 'file://' + file.path : file.path,
      maxWidth: compressImageMaxWidth ?? 1024,
      maxHeight: compressImageMaxHeight ?? 1024,
    })

    return { path: response.path, mime: file.mime, data: response.data }
  }

  return { path: file.path, mime: file.mime, data: file.data }
}

export const openGallery = async ({
  compressImageMaxWidth,
  compressImageMaxHeight,
  width,
  height,
  freeStyleCropEnabled,
  includeBase64,
}: {
  compressImageMaxWidth: number
  compressImageMaxHeight: number
  width?: number
  height?: number
  freeStyleCropEnabled?: boolean
  includeBase64?: boolean
}): Promise<ImagePickerCallbackProps | undefined> => {
  const file = await ImagePicker.openPicker({
    ...CommonImagePickerSettings,
    mediaType: 'photo',
    compressImageMaxWidth,
    compressImageMaxHeight,
    width,
    height,
    freeStyleCropEnabled,
    includeBase64,
  })

  if (Array.isArray(file)) return undefined
  // console.log('file.size', file.size, file.width, file.height)

  if (file.size > 200000) {
    const response = await resize({
      uri: Platform.OS === 'ios' ? 'file://' + file.path : file.path,
      maxWidth: compressImageMaxWidth ?? 1024,
      maxHeight: compressImageMaxHeight ?? 1024,
    })

    return { path: response.path, mime: file.mime, data: response.data }
  }

  return { path: file.path, mime: file.mime, data: file.data }
}

export const openWeb = (
  {
    compressImageMaxWidth,
    compressImageMaxHeight,
    width,
    height,
    freeStyleCropEnabled,
    includeBase64,
  }: {
    compressImageMaxWidth: number
    compressImageMaxHeight: number
    width?: number
    height?: number
    freeStyleCropEnabled?: boolean
    includeBase64?: boolean
  },
  callback: OpenImagePickerCallback,
) => {
  ImagePickerWeb.launchImageLibraryAsync({
    mediaTypes: ImagePickerWeb.MediaTypeOptions.All,
    allowsEditing: true,
    aspect: [4, 3],
    quality: 1,
  }).then((result) => {
    if (!result.canceled) {
      const uri = result.assets[0].uri
      NavigationService.navigate('ImageCrop', {
        uri,
        compressImageMaxWidth,
        compressImageMaxHeight,
        width,
        height,
        freeStyleCropEnabled,
        includeBase64,
        callback,
      })
    }
  })
}

const resize = async ({
  uri,
  maxWidth,
  maxHeight,
  format = 'JPEG',
}: {
  uri: string
  maxWidth: number
  maxHeight: number
  format?: ResizeFormat
}): Promise<Response & { data: string }> => {
  // console.log('resize', { uri, maxWidth, maxHeight, format, quality })
  const response = await ImageResizer.createResizedImage(
    uri,
    maxWidth,
    maxHeight,
    format,
    100,
    undefined,
    undefined,
    false, // keepmeta
    { onlyScaleDown: true },
  )
  // console.log('resize', { response })

  if (response?.size > 200000) {
    return resize({
      uri,
      maxWidth: maxWidth * 0.95,
      maxHeight: maxHeight * 0.95,
      format,
    })
  }

  const data = await RNFS.readFile(response.uri, 'base64')

  return {
    ...response,
    data,
  }
}
