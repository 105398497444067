import { APIError } from '@lib/api'
import store from '@redux/store'
import * as AnnounceAPI from './api'
import { setAnnounces } from './slice'
import { Announce, GetAnnouncesResponse } from './types'

export const getAnnounces = async (): Promise<Announce[]> => {
  const response = await AnnounceAPI.getAnnounces()
  if (!response.ok) throw new APIError(response)
  return (response.json as GetAnnouncesResponse).announce || []
}

export const fetchAnnounces = async (): Promise<Announce[]> => {
  const announces = await getAnnounces()
  store.dispatch(setAnnounces({ announces }))
  return announces
}
