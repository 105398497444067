import React from 'react'
import { ViewProps } from 'react-native'
import styled from 'styled-components/native'

const StyledBlurView = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: '#000000b0';
`

const StyledView = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const NonBlurView = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #4c4c4cfc;
`

export type BlurViewProps = {
  disableBlur?: boolean
} & ViewProps

const CustumBlurView: React.FC<BlurViewProps> = ({
  children,
  style,
  disableBlur,
}) => (
  <>
    {disableBlur ? <NonBlurView /> : <StyledBlurView style={style} />}
    <StyledView>{children}</StyledView>
  </>
)

export default CustumBlurView
