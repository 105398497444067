import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { NativeApp } from '@lib/Env'
import { useTabNavigatorBugWorkaround } from '@lib/hooks'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { Platform } from 'react-native'
import SettingMailPermissionsScreen from './SettingMailPermissionsScreen'
import SettingPushNotificationScreen from './SettingPushNotificationScreen'

const Tab = createMaterialTopTabNavigator()

const TabScreen = () => {
  useTabNavigatorBugWorkaround()

  return (
    <Tab.Navigator
      backBehavior="none"
      tabBarOptions={
        NativeApp ? TabBarOptions : { style: { display: 'none' } }
      }>
      {Platform.OS !== 'web' && (
        <Tab.Screen
          name="SettingPushNotifications"
          component={SettingPushNotificationScreen}
          options={{
            title: 'アプリ通知',
          }}
        />
      )}
      <Tab.Screen
        name="SettingMailPermissions"
        component={SettingMailPermissionsScreen}
        options={{
          title: 'メール通知',
        }}
      />
    </Tab.Navigator>
  )
}

const SettingNotificationTabScreen = () => (
  <>
    <CommonHeader title="通知設定" />
    <TabScreen />
  </>
)

export default SettingNotificationTabScreen
