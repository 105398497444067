import { APIError } from '../api'
import * as PushNotificationPermissionsAPI from './api'
import {
  GetPushNotificationPermissionResponse,
  UpdatePushNotificationPermissionProps,
} from './types'

export const updatePushNotificationPermission = async (
  props: UpdatePushNotificationPermissionProps,
) => {
  const response =
    await PushNotificationPermissionsAPI.updatePushNotificationPermission(props)
  if (!response.ok) throw new APIError(response)
}

export const getPushNotificationPermissions = async () => {
  const response = await PushNotificationPermissionsAPI.getPushPermissions()
  if (!response.ok) throw new APIError(response)
  return (response.json as GetPushNotificationPermissionResponse)
    .pushPermissions
}
