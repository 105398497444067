import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import { getApiUrl } from '@lib/api'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import PairingManager from '@lib/PairingManager'
import RNProgressHud from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { updateRootScreen } from '@redux/actions'
import store from '@redux/store'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Clipboard,
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import Share from 'react-native-share'

const InvitePairingScreen: React.FC<
  StackScreenProps<RootStackParamList, 'InvitePairing'>
> = ({ route }) => {
  const isFocused = useIsFocused()
  const [invitationText, setInvitationText] = useState('')
  const [isSkipEnabled, setIsSkipEnabled] = useState<boolean | undefined>(false)

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.repro.track('【Screen】Invitation', 'Screen')
    }
  }, [isFocused])

  const paramsIsSkipEnabled = route.params?.isSkipEnabled ?? false

  useEffect(() => {
    if (PairingManager.isRequested()) return
    console.log({ paramsIsSkipEnabled })
    setIsSkipEnabled(paramsIsSkipEnabled)

    PairingManager.makeInvitationText()
      .then((invitationText) => {
        setInvitationText(invitationText)
      })
      .catch((error) => {
        CommonDialog.showError({
          error,
          onPress: () => NavigationService.goBack(),
        })
      })
  }, [paramsIsSkipEnabled])

  const onPressShareLineButton = useCallback(() => {
    const message = encodeURIComponent(invitationText)
    const url = `line://msg/text/?${message}`
    Linking.openURL(url).catch((error) => {
      Log.info(error)
      const url = `https://line.me/R/msg/text/?${message}`
      Linking.openURL(url)
    })

    TrackingUtils.repro.track('【Tap】invitation_LINE', 'Tap')
    if (isSkipEnabled) {
      TrackingUtils.repro.track('【Tap】invitation_sidebar_LINE', 'Tap')
    } else {
      TrackingUtils.repro.track('【Tap】invitation_onboard_LINE', 'Tap')
    }
  }, [invitationText, isSkipEnabled])

  const onPressShareButton = useCallback(() => {
    if (NativeApp) {
      Share.open({ message: invitationText })
    } else {
      Alert.alert(
        'その他の方法で招待する',
        '',
        [
          {
            text: 'メール',
            onPress: () => {
              const message = encodeURIComponent(invitationText)
              const url = `mailto:?body=${message}`
              Linking.openURL(url).catch((error) => {
                Log.info(error)
              })
            },
          },

          {
            text: 'SMS',
            onPress: () => {
              const message = encodeURIComponent(invitationText)
              const url = `sms:&body=${message}` // ?bodyではだめ
              Linking.openURL(url).catch((error) => {
                Log.info(error)
              })
            },
          },
          { text: 'LINE', onPress: onPressShareLineButton },
          { text: 'キャンセル' },
        ],
        {
          menuDirection: 'column',
        },
      )
    }

    TrackingUtils.repro.track('【Tap】invitation_others', 'Tap')
    if (isSkipEnabled) {
      TrackingUtils.repro.track('【Tap】invitation_onboard__others', 'Tap')
    } else {
      TrackingUtils.repro.track('【Tap】invitation_sidebar_others', 'Tap')
    }
  }, [invitationText, isSkipEnabled, onPressShareLineButton])

  const onPressCopyButton = useCallback(() => {
    Clipboard.setString(invitationText)

    TrackingUtils.repro.track('【Tap】invitation_url', 'Tap')
    if (isSkipEnabled) {
      TrackingUtils.repro.track('【Tap】invitation_onboard_url', 'Tap')
    } else {
      TrackingUtils.repro.track('【Tap】invitation_sidebar_url', 'Tap')
    }
  }, [invitationText, isSkipEnabled])

  const onPressSkipButton = useCallback(async () => {
    RNProgressHud.show()
    await CategoryManager.fetchCategories({ accountType: 'user' })
    await CategoryManager.fetchCategories({ accountType: 'family' })
    RNProgressHud.dismiss()
    store.dispatch(updateRootScreen('Main'))
    // NavigationService.navigate('Main')
    TrackingUtils.repro.track('【Tap】invitation_skip', 'Tap')
  }, [])

  return (
    <View style={styles.container}>
      <CommonHeader
        title="パートナーを招待"
        leftButtonType={isSkipEnabled ? 'hidden' : 'stack'}
      />
      <ScrollView style={styles.container}>
        <View style={styles.viewTop}>
          <Text style={styles.titleInvite}>パートナーを招待しましょう</Text>
        </View>
        <View style={styles.body}>
          <TouchableOpacity
            style={styles.wrapButtonLine}
            onPress={onPressShareLineButton}>
            <View style={styles.buttonLine}>
              <Image
                resizeMode="contain"
                source={require('@images/line/icon-line.png')}
                style={styles.iconLine}
              />
              <Text style={styles.titleButtonLine}>LINEで招待する</Text>
            </View>
          </TouchableOpacity>
          <AppButton
            onPress={onPressShareButton}
            title="その他の方法で招待する"
            style={{ marginTop: 15 }}
          />
          <View style={styles.line} />
          <Text style={styles.titleURL}>
            招待URLをコピーして共有することもできます
          </Text>
          <View style={styles.warpTextInputUrl}>
            <TextInput
              editable={false}
              value={getApiUrl() + '/pairing...'}
              placeholder={invitationText}
              style={styles.textInputUrl}
            />
            <TouchableOpacity
              onPress={onPressCopyButton}
              style={styles.buttonUrl}>
              <Text style={styles.titleButtonUrl}>コピーする</Text>
            </TouchableOpacity>
          </View>
          {isSkipEnabled && (
            <AppButton
              onPress={onPressSkipButton}
              title="スキップ"
              style={{ marginTop: 20 }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  )
}

export default InvitePairingScreen

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F7F7F7',
  },
  iconBack: {
    width: 24,
    height: 24,
  },
  title: {
    color: Color.White,
    fontSize: 20,
    fontWeight: 'bold',
  },
  viewTop: {
    width: '100%',
    height: 77,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.White,
  },
  titleInvite: {
    fontSize: 15,
    color: '#3A3A3A',
    fontWeight: '400',
  },
  body: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 40,
  },
  wrapButtonLine: {
    width: '100%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#0EBD0F',
    borderRadius: 5,
    backgroundColor: Color.White,
  },
  buttonLine: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLine: {
    width: 33,
    height: 33,
    marginRight: 10,
  },
  titleButtonLine: {
    color: '#01B902',
    fontSize: 16,
    fontWeight: 'bold',
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: '#E0E0E0',
    marginTop: 30,
  },
  titleURL: {
    color: '#3A3A3A',
    fontSize: 15,
    fontWeight: '300',
    textAlign: 'center',
    marginTop: 28,
    width: '100%',
  },
  textInputUrl: {
    flex: 1,
    color: '#3A3A3A',
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 15,
  },
  warpTextInputUrl: {
    width: '100%',
    height: 50,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#E1E1E1',
    flexDirection: 'row',
    overflow: 'hidden',
    marginTop: 15,
  },
  buttonUrl: {
    width: 101,
    backgroundColor: '#9FA19C',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleButtonUrl: {
    color: Color.White,
    fontSize: 14,
    fontWeight: 'bold',
  },
})
