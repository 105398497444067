import { UserAccountType } from '@interfaces/Account'

export enum ActionType {
  UpdateCalenderDate = 'UpdateCalenderDate',
  UpdateCalendarUserAccountType = 'UpdateCalendarUserAccountType',
  UpdateCalendarTotalPaymentAmount = 'UpdateCalendarTotalPaymentAmount',
  UpdateCalendarTotalReceiptAmount = 'UpdateCalendarTotalReceiptAmount',
  UpdateShowTransactions = 'UpdateShowTransactions',
  UpdateNeedUpdateCalendarUserAccountType = 'UpdateNeedUpdateCalendarUserAccountType',
}

export type UpdateCalenderDateAction = {
  type: ActionType.UpdateCalenderDate
  calendarUserAccountType: CalendarUserAccountType
  yearMonth: moment.Moment
  startDate: moment.Moment
  endDate: moment.Moment
}

export type UpdateCalendarUserAccountTypeAction = {
  type: ActionType.UpdateCalendarUserAccountType
  calendarUserAccountType: CalendarUserAccountType
}

export type UpdateCalendarTotalPaymentAmountAction = {
  type: ActionType.UpdateCalendarTotalPaymentAmount
  userAccountType: UserAccountType | 'all'
  totalPaymentAmount: number
}

export type UpdateCalendarTotalReceiptAmountAction = {
  type: ActionType.UpdateCalendarTotalReceiptAmount
  userAccountType: UserAccountType | 'all'
  totalReceiptAmount: number
}

export type UpdateShowTransactionsAction = {
  type: ActionType.UpdateShowTransactions
  isShowTransactions: boolean
}

export type UpdateNeedUpdateCalendarUserAccountTypeAction = {
  type: ActionType.UpdateNeedUpdateCalendarUserAccountType
  isNeedUpdateCalendarUserAccountType: boolean
}

export type Actions =
  | UpdateCalenderDateAction
  | UpdateCalendarUserAccountTypeAction
  | UpdateCalendarTotalPaymentAmountAction
  | UpdateCalendarTotalReceiptAmountAction
  | UpdateShowTransactionsAction
  | UpdateNeedUpdateCalendarUserAccountTypeAction

export type State = {
  yearMonth: {
    // 日は無視される
    user?: moment.Moment
    family?: moment.Moment
    all?: moment.Moment
  }
  startDate: {
    user?: moment.Moment
    family?: moment.Moment
    all?: moment.Moment
  }
  endDate: {
    user?: moment.Moment
    family?: moment.Moment
    all?: moment.Moment
  }
  calendarUserAccountType: CalendarUserAccountType
  totalPaymentAmount: {
    user: number
    family: number
    all: number
  }
  totalReceiptAmount: {
    user: number
    family: number
    all: number
  }
  isShowTransactions: boolean
  isNeedUpdateCalendarUserAccountType: boolean
}

export type CalendarUserAccountType = 'all' | 'user' | 'family'
