import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import { FinancialAccountType } from '@interfaces/Financial'
import Color from '@lib/Color'
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native'
import { StyledQuestionIcon } from './CommonListItem/styles'

export interface SectionListHeaderProps {
  title?: string
  style?: TextStyle
  financialAccountType?: FinancialAccountType
  onPressQuestion?: () => void
}

export default (props: SectionListHeaderProps) => {
  if (props.title === undefined) return null

  if (props.financialAccountType === 'bank') {
    return (
      <View style={styles.view}>
        <Text style={{ ...styles.text, ...props.style }}>{props.title}</Text>
        <TouchableOpacity onPress={props.onPressQuestion} style={styles.button}>
          <StyledQuestionIcon icon={faQuestionCircle} size={18} />
          <Text style={styles.buttonText}>銀行の更新頻度</Text>
        </TouchableOpacity>
      </View>
    )
  }
  return <Text style={{ ...styles.text, ...props.style }}>{props.title}</Text>
}

const styles = StyleSheet.create({
  view: {
    backgroundColor: '#f0f0f0',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
    color: Color.Black,
  },
  button: {
    padding: 6,
    paddingRight: 15,
    flexDirection: 'row',
  },
  buttonText: {
    marginLeft: 5,
    color: Color.DefaultText,
    fontSize: 14,
    fontWeight: 'normal',
  },
})
