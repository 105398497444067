import Color from '@lib/Color'
import * as Icon from '@lib/Icon'
import { ProfileState } from '@lib/ProfileManager/types'
import store from '@redux/store'
import React from 'react'
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  View,
  ViewProps,
} from 'react-native'
import { connect } from 'react-redux'

export type AvatarType = 'user' | 'partner' | 'couple'

export interface AvatarProps {
  type: AvatarType
  size: number
  styleCouple?: any
}

interface StateProps {
  profile: ProfileState
}

class Avatar extends React.Component<AvatarProps & ViewProps & StateProps> {
  static defaultProps: AvatarProps = {
    type: 'user',
    size: 70,
  }

  private avatar = (source: ImageSourcePropType) => {
    const borderWidth = Math.max(Math.min(4, this.props.size / 15), 2)
    return (
      <View
        style={[
          styles.avatar,
          {
            width: this.props.size,
            height: this.props.size,
            borderRadius: this.props.size / 2,
          },
        ]}>
        <View
          style={[
            styles.wrapImage,
            {
              width: this.props.size - borderWidth,
              height: this.props.size - borderWidth,
              borderRadius: (this.props.size - borderWidth) / 2,
            },
          ]}>
          <Image
            source={source}
            style={[
              styles.image,
              {
                width: this.props.size - borderWidth,
                height: this.props.size - borderWidth,
              },
            ]}
          />
        </View>
      </View>
    )
  }

  private userIcon = (): ImageSourcePropType => {
    const profile: ProfileState =
      this.props.type === 'partner'
        ? ({
            userProfile: this.props.profile.partnerProfile,
            partnerProfile: this.props.profile.userProfile,
          } as ProfileState)
        : this.props.profile
    return Icon.userAvatar(profile)
  }

  private partnerIcon = (): ImageSourcePropType => {
    return Icon.partnerAvatar(this.props.profile)
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        {this.props.type === 'couple' ? (
          <View style={[styles.couple, this.props.styleCouple]}>
            {this.avatar(this.userIcon())}
            <View style={{ marginLeft: -this.props.size / 6 }}>
              {this.avatar(this.partnerIcon())}
            </View>
          </View>
        ) : (
          <View>{this.avatar(this.userIcon())}</View>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    profile: state.profile,
  }
}

export default connect(mapStateToProps)(Avatar)

export const styles = StyleSheet.create({
  container: {},
  couple: {
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
  },
  wrapImage: {
    backgroundColor: '#E0E0E0',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  avatar: {
    width: 70,
    height: 70,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.White,
    elevation: 2,
  },
})
