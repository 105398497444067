import Color from '@lib/Color'
import { ViewProps } from 'react-native'
import styled from 'styled-components/native'

const RootContainer = styled.View`
  border-radius: 3.5px;
  width: 100%;
  height: 7px;
  overflow: hidden;
`

const BorderBackgroundView = styled.View<{ color: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ color }) => color};
`

const BorderView = styled.View<{ color: string; ratio: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${({ ratio }) => Math.min(ratio, 1) * 100}%;
  background-color: ${({ color }) => color};
`
export type BarGraphType = {
  ratio: number
  borderColor?: string
  borderBackgroundColor?: string
}

const BarGraph = ({
  ratio,
  borderColor = Color.Primary,
  borderBackgroundColor = '#eeeeee',
  style,
}: BarGraphType & ViewProps) => (
  <RootContainer style={style}>
    <BorderBackgroundView color={borderBackgroundColor} />
    <BorderView ratio={ratio} color={borderColor} />
  </RootContainer>
)

export default BarGraph
