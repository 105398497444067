import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import MonthlyDataDownloadScreen from './MonthlyDataDownloadScreen'
import { RootContainer } from './styles'
import TransactionDataDownloadScreen from './TransactionDataDownloadScreen'

const Tab = createMaterialTopTabNavigator()

const DataDownloadScreen: React.FC<
  StackScreenProps<RootStackParamList, 'DataDownload'>
> = ({ route }) => (
  <RootContainer>
    <CommonHeader
      title="データバックアップ"
      onPressLeftButton={route.params?.goBack}
    />
    <Tab.Navigator backBehavior="none" tabBarOptions={TabBarOptions}>
      <Tab.Screen
        name="MonthlyDataDownload"
        component={MonthlyDataDownloadScreen}
        options={{ title: '月次集計' }}
      />
      <Tab.Screen
        name="TransactionDataDownload"
        component={TransactionDataDownloadScreen}
        options={{ title: '取引明細' }}
      />
    </Tab.Navigator>
  </RootContainer>
)

export default DataDownloadScreen
