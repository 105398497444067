import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import CommonHeader from '@components/CommonHeader'
import Color from '@lib/Color'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React from 'react'
import {
  Image,
  Linking,
  NativeEventSubscription,
  StatusBar,
  TouchableOpacity,
  View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { WebView, WebViewNavigation } from 'react-native-webview'
import { setTabBarVisible } from './Main/redux/actions'

interface State {
  canGoBack: boolean
  isShowOriginalHeader: boolean
}

class MediaWebViewScreen extends React.Component<
  StackScreenProps<RootStackParamList, 'MediaWebView'>
> {
  private backHandler?: NativeEventSubscription
  private webViewRef = React.createRef<WebView>()

  state: State = {
    canGoBack: false,
    isShowOriginalHeader: !!this.props.route.params.isShowOriginalHeader,
  }

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.addListener(this.goBack)
  }

  componentWillUnmount() {
    this.backHandler?.remove()
  }

  private goBack = () => {
    if (this.state.isShowOriginalHeader) {
      this.setState({ isShowOriginalHeader: false })
      store.dispatch(setTabBarVisible(true))
      if (this.state.canGoBack) {
        this.webViewRef.current?.goBack()
      } else {
        const params = this.props.route.params
        if (params?.goBack) {
          params.goBack()
        } else {
          NavigationService.goBack()
        }
      }
      return
    }

    if (this.state.canGoBack) {
      this.webViewRef.current?.goBack()
      return
    }

    const params = this.props.route.params
    if (params?.goBack) {
      params.goBack()
    } else {
      NavigationService.goBack()
    }
  }

  private onShouldStartLoadWithRequest = (event: WebViewNavigation) => {
    Log.info('onShouldStartLoadWithRequest', event.url)
    if (/^osidori:/.test(event.url)) {
      return false
    }

    // 外部サイトの場合は、外部ブラウザを開く
    if (
      !/^https?:\/\/(?:[^/]*\.)*(?:osidori\.co|youtube\.com)\//.test(event.url)
    ) {
      Linking.openURL(event.url).catch((error) => Log.info(event.url, error))
      return false
    }

    if (
      /\/article\.php/.test(event.url) &&
      this.props.route.params.isShowOriginalHeader !== false
    ) {
      this.setState({ isShowOriginalHeader: true })
      store.dispatch(setTabBarVisible(false))
      return true
    }

    return true
  }

  render() {
    const { title, uri, leftButtonTitle, leftButtonType } =
      this.props.route.params

    return (
      <View style={{ flex: 1 }}>
        <StatusBar translucent={true} hidden={false} barStyle="dark-content" />
        {this.state.isShowOriginalHeader || (
          <CommonHeader
            title={title}
            leftButtonTitle={leftButtonTitle}
            leftButtonType={leftButtonType}
            onPressLeftButton={this.goBack}
          />
        )}
        {this.state.isShowOriginalHeader && (
          <SafeAreaView edges={['top']}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                borderBottomColor: Color.Gray,
                borderBottomWidth: 0.5,
              }}>
              <TouchableOpacity
                onPress={() => {
                  this.goBack()
                }}
                style={{
                  paddingTop: 10,
                  paddingBottom: 15,
                  paddingLeft: 15,
                  paddingRight: 10,
                }}>
                <Image
                  source={require('@images/common-header/common-header-close-button.png')}
                  style={{ width: 19, height: 19, tintColor: Color.Gray }}
                />
              </TouchableOpacity>
              <Image
                resizeMode="contain"
                source={require('@images/icons/icon-logo-gray.png')}
                style={{
                  tintColor: Color.Primary,
                  marginBottom: 5,
                  width: 110,
                  height: 30,
                }}
              />
            </View>
          </SafeAreaView>
        )}
        <WebView
          ref={this.webViewRef}
          onNavigationStateChange={({ canGoBack, navigationType }) => {
            // iOSの場合、ウェブの読み込みが完了するまでcanGoBackがtrueにならないので、
            // 画面遷移開始時点でcanGoBackをtrueにしてしまう。
            this.setState({
              canGoBack: canGoBack || navigationType === 'click',
            })
          }}
          style={{ flex: 1 }}
          source={{ uri }}
          startInLoadingState={true}
          renderLoading={() => <AbsoluteActivityIndicator />}
          onShouldStartLoadWithRequest={this.onShouldStartLoadWithRequest}
        />
      </View>
    )
  }
}

export default MediaWebViewScreen
