import IconImage from '@components/IcomImage'
import { GoalEntity } from '@interfaces/Goal'
import Color from '@lib/Color'
import { useMemo } from 'react'
import { TouchableOpacityProps } from 'react-native'
import { ProgressCircle } from 'react-native-svg-charts'
import styled from 'styled-components/native'

export type GoalListItemProps = TouchableOpacityProps & {
  goal: GoalEntity
  borderTopVisible?: boolean
  isPremiumError?: boolean
}
const GoalListItem = ({
  goal,
  borderTopVisible,
  isPremiumError,
  ...props
}: GoalListItemProps) => {
  const comment = useMemo(
    () =>
      goal.currentProgressIcon !== 'bad' ? (
        <StyledCommentText>
          目標金額に対して{(goal.progress ?? 0) * 100}％貯まっています{'\n'}
          この目標貯金を
          <StyledCommentOrangeText>ライフプラン表</StyledCommentOrangeText>
          で見てみましょう
        </StyledCommentText>
      ) : (
        <StyledCommentText>
          これから毎月コツコツ貯めていきましょう💪
        </StyledCommentText>
      ),
    [goal.currentProgressIcon, goal.progress],
  )

  const color = useMemo(
    () => Color.goal(goal.currentProgressIcon),
    [goal.currentProgressIcon],
  )

  return (
    <StyledRootView borderTopVisible={borderTopVisible} {...props}>
      <StyledMainView>
        <StyledProgressCircleView>
          <StyledProgressCircle
            progress={goal.progress ?? 0}
            progressColor={color}
          />
          <StyledGoalImage source={{ uri: goal.imgUrl }} />
        </StyledProgressCircleView>
        <StyledGoalSummaryView>
          <StyledTitleView>
            {goal.completed && (
              <StyledCompleteView>
                <StyledCompleteText>完了</StyledCompleteText>
              </StyledCompleteView>
            )}
            <StyledTitleText>{goal.name}</StyledTitleText>
          </StyledTitleView>
          <StyledProgressView>
            <StyledProgressText>
              {goal.currentAmount.jpCurrency()}
            </StyledProgressText>
            <StyledGoalAmountText>
              / 目標額 {goal.goalAmount.jpCurrency()}
            </StyledGoalAmountText>
          </StyledProgressView>
        </StyledGoalSummaryView>
      </StyledMainView>
      {!goal.completed && (
        <StyledCommnentView>
          {comment}
          <StyledIconImage name="ArrowRight" />
        </StyledCommnentView>
      )}
      {isPremiumError && <StyledDisabledView />}
    </StyledRootView>
  )
}

const StyledRootView = styled.TouchableOpacity(
  ({ borderTopVisible }: { borderTopVisible?: boolean }) => ({
    // paddingHorizontal: 20,
    paddingVertical: 20,
    borderColor: '#dddddd',
    borderTopWidth: borderTopVisible ? 0.5 : 0,
    borderBottomWidth: 0.5,
  }),
)

const StyledMainView = styled.View({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
})

const StyledProgressCircleView = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledProgressCircle = styled(ProgressCircle).attrs({
  strokeWidth: 3.5,
})({
  width: 75,
  height: 75,
})

const StyledGoalImage = styled.Image.attrs({ resizeMode: 'cover' })({
  position: 'absolute',
  width: 62,
  height: 62,
  borderRadius: 30,
})

const StyledGoalSummaryView = styled.View({
  flex: 1,
  marginLeft: 10,
})

const StyledTitleView = styled.View({
  flexDirection: 'row',
})

const StyledCompleteView = styled.View({
  marginRight: 5,
  backgroundColor: '#666666',
  borderRadius: 2,
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: 5,
})

const StyledCompleteText = styled.Text({
  color: Color.White,
  fontWeight: 'bold',
  fontSize: 12,
  backgroundColor: '#666666',
  borderRadius: 5,
  overflow: 'hidden',
})

const StyledTitleText = styled.Text({
  color: Color.Black,
  fontSize: 18,
})

const StyledProgressView = styled.View({
  flexDirection: 'row',
  marginTop: 10,
  alignItems: 'center',
})

const StyledProgressText = styled.Text({
  fontSize: 18,
  fontWeight: 'bold',
})

const StyledGoalAmountText = styled.Text({
  paddingTop: 2,
  paddingLeft: 8,
  fontSize: 12,
  color: '#888888',
})

const StyledIconImage = styled(IconImage)({
  width: 15,
  height: 15,
})

const StyledCommnentView = styled.View({
  marginTop: 8,
  borderRadius: 5,
  backgroundColor: '#f8f8f8',
  flexDirection: 'row',
  paddingLeft: 10,
  paddingRight: 10,
  paddingVertical: 10,
  alignItems: 'center',
})

const StyledCommentText = styled.Text({
  flex: 1,
  paddingLeft: 8,
  color: Color.DefaultText,
  fontSize: 14,
  lineHeight: 20,
})

const StyledCommentOrangeText = styled.Text({
  color: Color.Orange,
})

const StyledDisabledView = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#3a3a3a33',
})

export default GoalListItem
