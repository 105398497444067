import { ScreenName } from '@navigation/Screens'

export enum ActionType {
  UpdateRootScreen = 'UpdateRootScreen',
  SetKeyboardVisible = 'SetKeyboardVisible',
  SetPlanCode = 'SetPlanCode',
  SetNavigation = 'SetNavigation',
  SetIDFA = 'SetIDFA',
  SetScreenLock = 'SetScreenLock',
  ClearAllState = 'ClearAllState',
}

export type UpdateRootScreeAction = {
  type: ActionType.UpdateRootScreen
  rootScreen: ScreenName
}

export type SetKeyboardVisibleAction = {
  type: ActionType.SetKeyboardVisible
  visible: boolean
}

export type SetPlanCodeAction = {
  type: ActionType.SetPlanCode
  planCode?: string
  planVersion?: string
}

export type SetNavigationAction = {
  type: ActionType.SetNavigation
  navigation?: {
    screenName: ScreenName
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any
  }
}

export type SetIDFAAction = {
  type: ActionType.SetIDFA
  idfa: string
}

export type SetScreenLockAction = {
  type: ActionType.SetScreenLock
  isScreenLock: boolean
}

export type ClearAllStateAction = {
  type: ActionType.ClearAllState
}

export type Action =
  | UpdateRootScreeAction
  | SetKeyboardVisibleAction
  | SetPlanCodeAction
  | SetNavigationAction
  | SetIDFAAction
  | SetScreenLockAction
  | ClearAllStateAction

export type State = {
  rootScreen: ScreenName
  keyboardVisible: boolean
  planCode?: string
  planVersion?: string
  navigation?: {
    screenName: ScreenName
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any
  }
  idfa: string
  isScreenLock: boolean
}
