import Color from '@lib/Color'
import NavigationService from '@lib/NavigationService'
import { useCallback } from 'react'
import { GestureResponderEvent, TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

export type AddToSavingButtonProps = TouchableOpacityProps

const AddToSavingButton = (props: AddToSavingButtonProps) => {
  const onPress = useCallback(
    (event: GestureResponderEvent) => {
      if (props.onPress) {
        props.onPress(event)
      } else {
        NavigationService.navigate('AddToSavings')
      }
    },
    [props],
  )

  return (
    <StyledTouchableOpacity onPress={onPress}>
      <StyledPlusImage />
      <StyledText>貯金額に追加</StyledText>
    </StyledTouchableOpacity>
  )
}

const StyledTouchableOpacity = styled.TouchableOpacity({
  backgroundColor: Color.Primary,
  width: 149,
  height: 44,
  position: 'absolute',
  bottom: 20,
  right: 16,
  borderRadius: 22,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  shadowColor: 'rgba(0,0,0,0.15)',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 4,
})

const StyledPlusImage = styled.Image.attrs({
  source: require('@assets/images/add/add-plus-button.png'),
})({
  width: 14,
  height: 15,
  tintColor: Color.White,
  marginRight: 5,
})

const StyledText = styled.Text({
  fontSize: 13,
  color: Color.White,
  fontWeight: 'bold',
})

export default AddToSavingButton
