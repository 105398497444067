import { Image } from '@components'
import CommonHeader from '@components/CommonHeader'
import HomeBackgroundImage from '@components/HomeBackgroundImage'
import { UserAccountType } from '@interfaces/Account'
import Color from '@lib/Color'
import * as ImagePickerUtils from '@lib/ImagePickerUtils'
import ProfileManager from '@lib/ProfileManager'
import RNProgressHud from '@lib/ProgressHUD'
import store from '@redux/store'
import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { connect } from 'react-redux'

interface Props {}

class SettingHomeBackgroundScreen extends React.Component<Props> {
  private onPressUpdateBackgroundImage = (userAccountType: UserAccountType) => {
    ImagePickerUtils.open(
      {
        compressImageMaxWidth: 1024,
        compressImageMaxHeight: 1024,
        width: wp('100%') * 2,
        height: wp('100%') * 2 * (155 / 320),
        includeBase64: true,
      },
      async (imageFile) => {
        if (!imageFile) return

        try {
          RNProgressHud.show()
          await ProfileManager.updateHomeBackgroundImage({
            userAccountType,
            mime: imageFile.mime,
            base64: imageFile.data || '',
          })
        } finally {
          RNProgressHud.dismiss()
        }
      },
    )
  }

  private renderBackgroundImageView = (userAccountType: UserAccountType) => {
    return (
      <View>
        <Text
          style={{
            paddingVertical: 20,
            paddingLeft: 20,
            fontWeight: 'bold',
            fontSize: 14,
          }}>
          {userAccountType === 'family' ? '家族' : '個人'}画面
        </Text>
        <View style={{ paddingHorizontal: 20 }}>
          <HomeBackgroundImage
            userAccountType={userAccountType}
            style={{
              width: wp('100%') - 40,
              height: (wp('100%') - 40) * (155 / 320),
              resizeMode: 'cover',
            }}
          />
          <TouchableOpacity
            style={styles.updatePhotoButton}
            onPress={() => this.onPressUpdateBackgroundImage(userAccountType)}>
            <Image
              source={require('@images/icons/icon-camera.png')}
              resizeMode="contain"
              style={{ width: 30, height: 30 }}
            />
            <Text style={styles.updatePhotoText}>写真を変更する</Text>
          </TouchableOpacity>
        </View>
        <Text
          style={{
            paddingTop: 15,
            paddingBottom: 20,
            paddingLeft: 20,
            fontSize: 12,
            fontWeight: 'normal',
            color: Color.DefaultText,
          }}>
          {userAccountType === 'family' ? '家族' : '個人'}
          のホーム画面で使用する背景画像が変更されます。
        </Text>
      </View>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader title="背景画像の変更" />
        <ScrollView>
          {this.renderBackgroundImageView('family')}
          <View
            style={{
              backgroundColor: '#dddddd',
              width: '100%',
              height: 25,
            }}
          />
          {this.renderBackgroundImageView('user')}
        </ScrollView>
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    profile: state.profile,
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.LightGray,
  },
  updatePhotoButton: {
    position: 'absolute',
    flexDirection: 'row',
    left: 20,
    right: 20,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000080',
    paddingVertical: 5,
  },
  updatePhotoText: {
    color: Color.White,
    fontWeight: 'bold',
    fontSize: 14,
    paddingLeft: 5,
  },
})

export default connect(mapStateToProps)(SettingHomeBackgroundScreen)
