import { RootState } from '@redux/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type State = {
  isTappingChart: boolean
}

type Reducer = {
  setIsTappingChart: (
    state: State,
    action: PayloadAction<{ isTappingChart: boolean }>,
  ) => void
}

const initialState: State = {
  isTappingChart: false,
}

const slice = createSlice<State, Reducer>({
  name: 'assetTransition',
  initialState,
  reducers: {
    setIsTappingChart: (state, { payload }) => {
      state.isTappingChart = payload.isTappingChart
    },
  },
})

export const { setIsTappingChart } = slice.actions
export const selectIsTappingChart = ({ assetTransition }: RootState) =>
  assetTransition.isTappingChart

export default slice.reducer
