import { UserAccountType } from '@interfaces/Account'
import { FinancialAccountType } from '@interfaces/Financial'
import NavigationService from '@lib/NavigationService'

export type NavigationTransactionParams = {
  financialAccountType: FinancialAccountType
  userAccountType?: UserAccountType
  accountId?: number
  transactionId: number
}

export const navigationTransaction = (params?: NavigationTransactionParams) => {
  // const selectedTab = store.getState().main.selectedTab

  if (params) {
    // if (selectedTab === 'HomeTab') {
    //   NavigationService.navigate('TransactionDetail', { ...params })
    // } else if (selectedTab === 'TimelineTab') {
    //   NavigationService.navigate('TimelineTransactionDetail', { ...params })
    // } else {
    NavigationService.navigate('TransactionDetail', {
      ...params,
    })
    // }
  } else {
    NavigationService.navigate('TransactionsTop', {
      headerLeftButtonType: 'stack',
    })

    // TODO こちらが理想の動作?
    // if (SessionManager.getTransactionDisplayType() === 'transactions') {
    //   NavigationService.navigate('TransactionsTop', {
    //     headerLeftButtonType: 'stack',
    //   })
    // } else {
    //   NavigationService.navigate('Calendar', {
    //     headerLeftButtonType: 'stack',
    //   })
    // }
  }
}
