import BarGraph from '@components/BarGraph'
import Color from '@lib/Color'
import styled from 'styled-components/native'

export const RootContainer = styled.TouchableOpacity`
  flex-direction: row;
  padding-horizontal: 15px;
  align-items: center;
`

export const IconContainer = styled.View`
  padding-vertical: 15px;
  width: 40px;
  align-items: center;
`

export const CategoryDetailContainer = styled.View`
  flex: 1;
  margin-left: 5px;
  /* background-color: red; */
  justify-content: center;
`

export const CategoryDataContainer = styled.View`
  /* flex: 1; */
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
`

export const CategoryExpenseContainer = styled.View`
  flex: 1;
`

export const CategoryRemainContainer = styled.View`
  flex: 1;
  align-items: flex-end;
`

export const CategoryText = styled.Text`
  margin-top: 5px;
  font-size: 12px;
  font-weight: normal;
  color: ${Color.DefaultText};
  text-align: center;
`

export const CategoryAllText = styled.Text`
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const BudgetText = styled.Text`
  color: #888888;
  font-size: 12px;
  font-weight: normal;
`

export const ExpenseContainer = styled.View`
  flex-direction: row;
  align-items: center;
`

export const ExpenseTitle = styled.Text`
  color: #888888;
  font-size: 12px;
  font-weight: normal;
`

export const ExpenseAmount = styled.Text`
  margin-left: 5px;
  color: ${Color.Black};
  font-size: 18px;
  font-weight: normal;
`

export const RemainTitle = styled.Text<{
  type: 'budget' | 'prevMonth'
  over?: boolean
}>`
  color: ${({ type, over }) =>
    over
      ? Color.NumberOrange
      : type === 'budget'
      ? Color.NumberBlack
      : Color.NumberGreen};
  font-size: 12px;
  font-weight: normal;
`

export const RemainAmount = styled.Text<{ over?: boolean }>`
  margin-left: 5px;
  color: ${({ over }) => (over ? Color.Orange : 'black')};
  font-size: 18px;
  font-weight: normal;
`

export const StyledBarGraph = styled(BarGraph)`
  margin-top: 1px;
`

export const ArrowContainer = styled.View`
  width: 25px;
  /* background-color: red; */
  align-items: flex-end;
`
