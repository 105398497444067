import * as holiday from '@holiday-jp/holiday_jp'
import moment from 'moment'

// 集計開始日設定オプション
export const StartDateSettingOption = {
  None: '', // 設定無し
  Ago: 'ago', // 開始日が土日祝日の場合は開始日の前にずらす
  Since: 'since', // 開始日が土日祝日の場合は開始日の後にずらす
} as const

export type StartDateSettingOption =
  typeof StartDateSettingOption[keyof typeof StartDateSettingOption]

// 月の開始日と終了日
export type RangeOfMonths = {
  startDate: moment.Moment
  endDate: moment.Moment
}

// 年月
export type YearMonth = {
  key: string // YYYYMM形式
  year: number
  month: number // 1-12
}

// 月の開始日のmoment返す
// e.g. 2020年10月度の開始日を取得, 開始日の設定は15日, 開始日が土日祝日の場合は開始日の前にずらす
// const startDate = getStartDate(2020, 10, 15, StartDateSettingOption.Ago)
export const getStartDate = (
  year: number,
  month: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): moment.Moment => {
  let startDate = moment(
    `${year}-${month}-${start === -1 ? 31 : start}`,
    'YYYY-MM-DD',
  )

  // 存在しない日付の場合(2/29, 2/30 11/31など)は、月末の設定とする
  if (!startDate.isValid()) {
    startDate = moment(`${year}-${month}-01`, 'YYYY-MM-DD')
      .endOf('month') // 月末にする
      .startOf('day') // 00:00:00にする
  }

  // format('E') = ISOの曜日番号 (月曜〜日曜 : 1~7)
  if (option === StartDateSettingOption.Ago) {
    while (
      +startDate.format('E') > 5 ||
      holiday.isHoliday(startDate.toDate())
    ) {
      // 開始日が土日祝日の場合は開始日の前にずらす
      startDate.subtract(1, 'day')
    }
  } else if (option === StartDateSettingOption.Since) {
    while (
      +startDate.format('E') > 5 ||
      holiday.isHoliday(startDate.toDate())
    ) {
      // 開始日が土日祝日の場合は開始日の後にずらす
      startDate.add(1, 'day')
    }
  }

  return startDate
}

// 月の終了日のmoment返す
// e.g. 2020年10月度の終了日を取得, 開始日の設定は15日, 開始日が土日祝日の場合は開始日の前にずらす
// const endDate = getEndDate(2020, 10, 15, StartDateSettingOption.Ago)
export const getEndDate = (
  year: number,
  month: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): moment.Moment => {
  // 翌月の開始日を取得
  const startDate =
    month < 12
      ? getStartDate(year, month + 1, start, option)
      : getStartDate(year + 1, 1, start, option)

  //  翌月の開始日-1日を返す
  return startDate.subtract(1, 'day')
}

// 月の開始日と終了日のmoment返す
// e.g. 2020年10月度の日付範囲を取得
// const rangeOfMonths = getDateRange(2020, 10, StartDateSettingOption.Ago)
export const getDateRange = (
  year: number,
  month: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): RangeOfMonths => {
  const startDate = getStartDate(year, month, start, option)
  const endDate = getEndDate(year, month, start, option)
  return { startDate, endDate }
}

export const getYearlyStartDate = (
  year: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): moment.Moment => {
  return getStartDate(year, 1, start, option)
}

export const getYearlyEndDate = (
  year: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): moment.Moment => {
  return getEndDate(year, 12, start, option)
}

export const getYearlyDateRange = (
  year: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): RangeOfMonths => {
  const startDate = getYearlyStartDate(year, start, option)
  const endDate = getYearlyEndDate(year, start, option)
  return { startDate, endDate }
}

export const getPrevMonthStartDate = (
  year: number,
  month: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): moment.Moment =>
  month === 1
    ? getStartDate(year - 1, 12, start, option)
    : getStartDate(year, month - 1, start, option)

export const getPrevMonthEndDate = (
  year: number,
  month: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): moment.Moment => getStartDate(year, month, start, option).subtract(1, 'day')

export const getPrevMonthDateRange = (
  year: number,
  month: number,
  start: number, // 開始日設定: 1〜31, -1は月末
  option: StartDateSettingOption,
): RangeOfMonths => {
  const startDate = getPrevMonthStartDate(year, month, start, option)
  const endDate = getPrevMonthEndDate(year, month, start, option)
  return { startDate, endDate }
}

// 指定した日付の年月(度)をで返す
export const getYearMonth = (
  date = moment(),
  start: number, // 開始日設定: 1〜31, -1は月末,
  option: StartDateSettingOption,
): YearMonth => {
  const targetDate = moment(date).startOf('day') // 00:00:00にする
  let year = targetDate.year()
  let month = targetDate.month() + 1 // date.month()は0-11なので+1する

  const { startDate, endDate } = getDateRange(year, month, start, option)

  if (targetDate.diff(startDate) < 0) {
    // 開始日前であれば前月度となる
    if (month > 1) {
      month--
    } else {
      year--
      month = 12
    }
  } else if (targetDate.diff(endDate) > 0) {
    // 終了日と同じかそれ以降であれば翌月度となる
    if (month < 12) {
      month++
    } else {
      year++
      month = 1
    }
  }

  return {
    key: `${year}${('0' + month).slice(-2)}`,
    year,
    month,
  }
}

// 以下はいつか消す

export const getPrevMonth = (yearMonthKey: string) => {
  let { year, month } = parseMonthKey(yearMonthKey)

  if (month > 1) {
    month--
  } else {
    year--
    month = 12
  }

  return {
    key: `${year}${('0' + month).slice(-2)}`,
    year,
    month,
  }
}

export const getNextMonth = (yearMonthKey: string) => {
  let { year, month } = parseMonthKey(yearMonthKey)

  if (month < 12) {
    month++
  } else {
    year++
    month = 1
  }

  return {
    key: `${year}${('0' + month).slice(-2)}`,
    year,
    month,
  }
}

export const parseMonthKey = (monthKey: string) => ({
  year: +monthKey.slice(0, 4),
  month: +monthKey.slice(-2),
})

export const yyyymm = (year: number, month: number, separator = '') =>
  `${year}${separator}${('0' + month).slice(-2)}`
