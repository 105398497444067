import { Action, ActionType, State } from './types'

const initialState: State = {
  rootScreen: 'Splash',
  keyboardVisible: false,
  idfa: '',
  isScreenLock: false,
}

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateRootScreen:
      return { ...state, rootScreen: action.rootScreen }
    case ActionType.SetKeyboardVisible:
      return { ...state, keyboardVisible: action.visible }
    case ActionType.SetPlanCode:
      return {
        ...state,
        planCode: action.planCode,
        planVersion: action.planVersion,
      }
    case ActionType.SetNavigation:
      return { ...state, navigation: action.navigation }
    case ActionType.SetIDFA:
      return { ...state, idfa: action.idfa }
    case ActionType.SetScreenLock:
      return { ...state, isScreenLock: action.isScreenLock }
    default:
      return state
  }
}

export default reducer
