import CategoryImage from '@components/CategoryImage'
import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TransactionItem from '@components/TransactionItem'
import {
  FinancialAccountType,
  ProfitLossItem,
  Transaction,
} from '@interfaces/Financial'
import { AccountState } from '@lib/AccountManager/types'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { getYearlyDateRange } from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import DateUtilsV1 from '@lib/DateUtilsV1'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import Toast from 'react-native-simple-toast'
import { connect } from 'react-redux'
import { TransactionDetailScreenNavigationParams } from '../TransactionDetailScreen'

interface Props {}

interface StateProps {
  groupedTransactions?: Transaction[]
  account: AccountState
  financial: FinancialState
}

interface State {
  refreshing: boolean
  sections: SectionListData<Transaction>[]
  totalAmount?: number
  totalRatio?: number
}

class CategoryTransactionsScreen extends React.Component<
  Props &
    StateProps &
    StackScreenProps<RootStackParamList, 'CategoryTransactions'>,
  State
> {
  state: State = {
    refreshing: false,
    sections: [],
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.fetchBalance()
      this.fetchTransactions()

      if (this.props.account.accountMode === 'family') {
        TrackingUtils.repro.track(
          '【Screen】Family_PL_spending_categories',
          'Screen',
        )
      }
    })
  }

  componentWillUnmount() {
    store.dispatch({
      type: 'UpdateGroupedTransactions',
      undefined,
    })

    store.dispatch({
      type: 'UpdateCategoryTransactions',
      userAccountType: 'user',
      categoryTransactions: undefined,
    })

    store.dispatch({
      type: 'UpdateCategoryTransactions',
      userAccountType: 'family',
      categoryTransactions: undefined,
    })
  }

  componentDidUpdate(
    prevProps: Props &
      StateProps &
      StackScreenProps<RootStackParamList, 'CategoryTransactions'>,
  ) {
    const { account } = this.props
    if (
      prevProps.account.isFamilyShareMode !== account.isFamilyShareMode ||
      prevProps.account.accountMode !== account.accountMode
    ) {
      this.setState({ totalAmount: 0, totalRatio: 0 })
      this.fetchBalance()
      this.fetchTransactions()
    }
  }

  private fetchBalance = async () => {
    const { date, categoryId, scope, subCategoryId, isYearly } =
      this.props.route.params

    await FinancialManager.fetchBalance(
      this.props.account.accountMode,
      this.props.account.isFamilyShareMode,
      date,
      isYearly,
    )

    const accountMode = this.props.account.accountMode

    if (!subCategoryId) {
      const targetMonth = moment(date)
      const thisMonth = DateUtilsV1.monthKey(targetMonth)
      const thisMonthsPL =
        this.props.financial[accountMode].monthsPL[
          isYearly ? thisMonth.substring(0, 4) : thisMonth
        ]
      if (!thisMonthsPL) return null

      let profitLossItem: ProfitLossItem | undefined
      if (scope === 'income') {
        profitLossItem = thisMonthsPL.incomeList.find(
          (v) => v.categoryId === categoryId,
        )
      } else {
        profitLossItem = thisMonthsPL.expenseList.find(
          (v) => v.categoryId === categoryId,
        )
      }
      if (profitLossItem) {
        this.setState({
          totalAmount: profitLossItem.amount,
          totalRatio: profitLossItem.ratio,
        })
      }
    }
  }

  private fetchTransactions = async () => {
    const {
      date,
      categoryId: atGroupedCategoryId,
      scope,
      subCategoryId,
      isYearly,
    } = this.props.route.params

    const targetDate = moment(date)
    const settings = getStartDateSettings(this.props.account.accountMode)

    const { startDate, endDate } = isYearly
      ? getYearlyDateRange(
          targetDate.year(),
          settings.start,
          settings.startDateSettingOption,
        )
      : DateUtilsV1.getRangeOfMonths(moment(date))

    if (!subCategoryId) {
      await FinancialManager.fetchGroupedTransactions({
        userAccountType: this.props.account.accountMode,
        from: startDate.format('YYYY-MM-DD'),
        to: endDate.format('YYYY-MM-DD'),
        atGroupedCategoryId,
        share: this.props.account.isFamilyShareMode,
        scope,
        transfer: 'pl',
      })
    } else {
      const transactions = await FinancialManager.fetchCategoryTransactions({
        accountType: this.props.account.accountMode,
        from: startDate.format('YYYY-MM-DD'),
        to: endDate.format('YYYY-MM-DD'),
        share: this.props.account.isFamilyShareMode,
        atTransactionCategoryId: subCategoryId,
        scope,
        transfer: 'pl',
        // future: false,
        useCache: false,
        exclusionCarryForward: false,
      })
      let totalAmount = 0
      transactions &&
        transactions
          .filter((v) => !v.isIgnored)
          .forEach((v) => {
            totalAmount += v.amount
          })
      this.setState({ totalAmount })
    }

    this.updateSections()
  }

  private updateSections = () => {
    const transactions: { [date: string]: Transaction[] } = {}

    const sections: SectionListData<Transaction>[] = []

    const subCategoryId = this.props.route.params?.subCategoryId

    if (!subCategoryId) {
      this.props.groupedTransactions?.forEach((transaction: Transaction) => {
        const date = moment(transaction.usedDate).format('YYYYMMDD')
        if (transactions[date]) {
          transactions[date].push(transaction)
        } else {
          transactions[date] = [transaction]
        }
      })
    } else {
      this.props.financial[
        this.props.account.accountMode
      ].categoryTransactions?.forEach((transaction: Transaction) => {
        const date = moment(transaction.usedDate).format('YYYYMMDD')
        if (transactions[date]) {
          transactions[date].push(transaction)
        } else {
          transactions[date] = [transaction]
        }
      })
    }

    const today = moment().format('YYYYMMDD')

    Object.keys(transactions)
      .sort()
      .reverse()
      .forEach((date) => {
        sections.push({
          title:
            date === today
              ? '今日'
              : moment(date, 'YYYYMMDD').format('YYYY/M/D'),
          data: transactions[date],
        })
      })

    this.setState({ sections })
  }

  private onPressItem = (item: Transaction) => {
    NavigationService.navigate('TransactionDetail', {
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId:
        item.atUserBankAccountId ||
        item.atUserCardAccountId ||
        item.atUserEmoneyServiceAccountId ||
        item.walletId,
      transactionId: item.transactionId,
    } as TransactionDetailScreenNavigationParams)
  }

  private onPressMemoButton = (item: Transaction) => {
    NavigationService.navigate('TransactionDetail', {
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId:
        item.atUserBankAccountId ||
        item.atUserCardAccountId ||
        item.atUserEmoneyServiceAccountId ||
        item.walletId,
      transactionId: item.transactionId,
      focusMemo: true,
    } as TransactionDetailScreenNavigationParams)
  }

  private renderItem: SectionListRenderItem<Transaction> = ({ item }) => {
    return (
      <TransactionItem
        transaction={item}
        onPress={() => this.onPressItem(item)}
        onPressMemoButton={() => this.onPressMemoButton(item)}
        onSwipeableOpen={
          item.type !== 'carry_forward' ? this.onSwipeableOpenItem : undefined
        }
        onPressAddPaylistButton={() => {
          this.fetchBalance()
          this.fetchTransactions()
        }}
      />
    )
  }

  private renderListHeader = () => {
    if (this.state.totalAmount === undefined) return null

    const { date, categoryId, subCategoryId, isYearly } =
      this.props.route.params

    const targetDate = moment(date)
    const settings = getStartDateSettings(this.props.account.accountMode)

    const { startDate, endDate } = isYearly
      ? getYearlyDateRange(
          targetDate.year(),
          settings.start,
          settings.startDateSettingOption,
        )
      : DateUtilsV1.getRangeOfMonths(moment(date))

    return (
      <View
        style={{
          alignItems: 'center',
          paddingTop: 10,
          paddingBottom: 15,
        }}>
        {isYearly ? (
          <Text
            style={{
              padding: 2,
              fontSize: 16,
              fontWeight: 'normal',
            }}
            numberOfLines={2}>
            {startDate?.format('YYYY年')}
            <Text style={{ fontSize: 10, fontWeight: 'normal' }}>
              {'\n'}
              {startDate?.format('YYYY/M/D')} ~ {endDate?.format('YYYY/M/D')}
            </Text>
          </Text>
        ) : (
          <Text style={{ fontSize: 16, fontWeight: 'normal' }}>
            {`${startDate.format('YYYY/M/D')} ~ ${endDate.format('YYYY/M/D')}`}
          </Text>
        )}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
            width: '100%',
          }}>
          <View style={{ alignItems: 'center' }}>
            <Text
              style={{
                // marginTop: 10,
                paddingHorizontal: 30,
                alignContent: 'flex-start',
                fontSize: 16,
                fontWeight: 'normal',
                color: Color.Gray,
              }}>
              {subCategoryId
                ? CategoryManager.getCategoryIconInfo(subCategoryId)
                    ?.categoryName
                : CategoryManager.categoryByCategoryId(
                    this.props.account.accountMode,
                    categoryId,
                  )?.name}
            </Text>
            <CategoryImage
              categoryId={subCategoryId || categoryId}
              style={{ marginTop: 5 }}
            />
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: 10,
              paddingRight: 20,
            }}>
            <Text style={{ fontSize: 20, fontWeight: 'normal' }}>
              {this.state.totalAmount.jpCurrency()}
            </Text>
            {this.state.totalRatio !== undefined && (
              <Text
                style={{
                  marginLeft: 10,
                  fontSize: 16,
                  fontWeight: 'normal',
                  color: Color.Gray,
                }}>
                {Math.round(this.state.totalRatio * 100)}%
              </Text>
            )}
          </View>
        </View>
      </View>
    )
  }

  private onSwipeableOpenItem = async (item: Transaction) => {
    const newItem = { ...item }
    newItem.isTransactionShared = true

    const financialAccountType: FinancialAccountType = (() => {
      if (item.atUserBankAccountId) return 'bank'
      if (item.atUserCardAccountId) return 'card'
      if (item.atUserEmoneyServiceAccountId) return 'emoney'
      if (item.atUserEmoneyAccountId) return 'emoney'
      if (item.walletId) return 'wallet'
      return 'manually_created'
    })()

    const userAccountType = item.isAccountShared ? 'family' : 'user'
    const share = !item.isTransactionShared

    try {
      await FinancialManager.updateTransaction({
        userAccountType,
        financialAccountType,
        accountId: item.accountId,
        transactionId: item.transactionId,
        transactionShare: share,
        amount: item.amount,
        paymentMethodId: item.walletId ?? undefined,
        paymentMethodType: item.walletId ? 'wallet' : undefined,
        ignore: item.isIgnored,
        memo: item.memo !== null ? item.memo : undefined,
        type: item.amount >= 0 ? 'receipt' : 'payment',
        action: 'swipe',
      })
    } catch (error) {
      CommonDialog.showError({ error })
      return
    }
    if (Platform.OS !== 'web') {
      Toast.showWithGravity('明細を振り分けました', Toast.SHORT, Toast.BOTTOM)
    }

    if (userAccountType === 'family') {
      TrackingUtils.repro.trackOnce(
        '【Completed】Allocate details to family_unique',
        'Completed',
      )
      TrackingUtils.adjust.trackEvent({ ios: '46kvf5', android: '6cnfwh' })
      TrackingUtils.repro.track(
        '【Completed】Allocate details to family_cumulative',
        'Completed',
      )
      TrackingUtils.adjust.trackEvent({ ios: '659muc', android: '11ppeh' })
    }

    this.fetchBalance()
    this.fetchTransactions()
  }

  private refreshControl = (
    <RefreshControl
      refreshing={this.state.refreshing}
      onRefresh={async () => {
        this.setState({ refreshing: true })
        await this.fetchBalance()
        await this.fetchTransactions()
        this.setState({ refreshing: false })
      }}
    />
  )

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader title="収支管理" />
        <View style={{ flex: 1 }}>
          {this.state.totalAmount !== undefined ? (
            this.state.sections.length > 0 ? (
              <SectionList
                sections={this.state.sections}
                renderItem={this.renderItem}
                renderSectionHeader={({ section: { title } }) => (
                  <SectionListHeader
                    title={title}
                    style={{ fontWeight: 'normal' }}
                  />
                )}
                keyExtractor={(item: Transaction) =>
                  [
                    item.type,
                    item.transactionId,
                    item.isAccountShared,
                    item.isTransactionShared,
                  ].join('-')
                }
                ListHeaderComponent={this.renderListHeader}
                refreshControl={this.refreshControl}
                ItemSeparatorComponent={() => ItemSeparator}
                // stickySectionHeadersEnabled={false}
              />
            ) : (
              <>
                {this.renderListHeader()}
                <Text style={{ marginLeft: 20, color: Color.DefaultText }}>
                  取引はありません
                </Text>
              </>
            )
          ) : (
            <ActivityIndicator style={{ flex: 1 }} color={Color.Gray} />
          )}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => ({
  groupedTransactions: state.financial.groupedTransactions,
  transactions: state.financial.transactions,
  account: state.account,
  financial: state.financial,
})

export default connect(mapStateToProps)(CategoryTransactionsScreen)
