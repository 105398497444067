import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { FAIcon } from '@lib/FAIcon'
import store from '@redux/store'
import React from 'react'
import { ImageStyle, View } from 'react-native'
import { connect } from 'react-redux'
import BadgeView from './BadgeView'

export interface IconWithBadgeProps {
  style?: ImageStyle
}

interface StateProps {
  noticesBadgeCount: number
}

class TimelineBadgeIcon extends React.Component<
  IconWithBadgeProps & StateProps
> {
  render() {
    const { style, noticesBadgeCount } = this.props
    return (
      <View>
        <FAIcon
          icon={faClock}
          // size={20}
          color="rgba(0, 0, 0, 0.25)"
          style={{ position: 'absolute', left: 0.5, top: 0.5, zIndex: -1 }}
        />
        <FAIcon icon={faClock} size={20} color="white" />
        <BadgeView
          badgeNumber={noticesBadgeCount}
          containerStyle={{
            position: 'absolute',
            right: +(style?.width || 0) - 6,
            top: -3,
          }}
        />
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    noticesBadgeCount: state.timeline.noticesBadgeCount,
  }
}

export default connect(mapStateToProps)(TimelineBadgeIcon)
