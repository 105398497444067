import { APIError } from '@lib/api'
import TermTransaction, {
  CreateTermTransactionProps,
  DeleteTermTransactionProps,
  GetTermTransactionProps,
  GetTermTransactionResponse,
  GetTermTransactionsResponse,
  TermTransactionDetail,
  UpdateTermTransactionProps,
} from '@lib/api/TermTransaction'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import store from '@redux/store'

class TermTransactionManager {
  fetchTermTransactions = async () => {
    const responseGroupShare = await TermTransaction.getTermTransactions({
      userAccountType: 'family',
      share: true,
    })
    if (!responseGroupShare.ok) throw new APIError(responseGroupShare)
    const responseGroup = await TermTransaction.getTermTransactions({
      userAccountType: 'family',
      share: false,
    })
    if (!responseGroup.ok) throw new APIError(responseGroup)
    const responseUserShare = await TermTransaction.getTermTransactions({
      userAccountType: 'user',
      share: true,
    })
    if (!responseUserShare.ok) throw new APIError(responseUserShare)
    const responseUser = await TermTransaction.getTermTransactions({
      userAccountType: 'user',
      share: false,
    })
    if (!responseUser.ok) throw new APIError(responseUser)

    const familyTermTransactionsShare = (responseGroupShare.json as GetTermTransactionsResponse)
      .termTransactions
    const userTermTransactionsShare = (responseUserShare.json as GetTermTransactionsResponse)
      .termTransactions
    const familyTermTransactions = (responseGroup.json as GetTermTransactionsResponse)
      .termTransactions
    const userTermTransactions = (responseUser.json as GetTermTransactionsResponse)
      .termTransactions

    const familyTabTermTransactions: TermTransactionDetail[] = []
    const userTabTermTransactions: TermTransactionDetail[] = []

    // 家族タブ用
    familyTermTransactionsShare.forEach((v) =>
      familyTabTermTransactions.push(v),
    )
    userTermTransactionsShare.forEach((v) => familyTabTermTransactions.push(v))

    // 個人タブ用
    familyTermTransactions.forEach((v) => userTabTermTransactions.push(v))
    userTermTransactions.forEach((v) => userTabTermTransactions.push(v))

    store.dispatch({
      type: 'UpdateTermTransactionAction',
      termTransaction: {
        familyTermTransactions: familyTabTermTransactions,
        userTermTransactions: userTabTermTransactions,
      },
    })
  }

  getTermTransaction = async (props: GetTermTransactionProps) => {
    const response = await TermTransaction.getTermTransaction(props)
    if (!response.ok) throw new APIError(response)
    return (response.json as GetTermTransactionResponse).termTransaction
  }

  createTermTransaction = async (props: CreateTermTransactionProps) => {
    const response = await TermTransaction.createTermTransaction(props)
    if (!response.ok) throw new APIError(response)
    OsidoriEvent.emit(OsidoriEventName.DidCreateTermTransaction, props)
  }

  updateTermTransaction = async (props: UpdateTermTransactionProps) => {
    const response = await TermTransaction.updateTermTransaction(props)
    if (!response.ok) throw new APIError(response)
    OsidoriEvent.emit(OsidoriEventName.DidUpdateTermTransaction, props)
  }

  deleteTermTransaction = async (props: DeleteTermTransactionProps) => {
    const response = await TermTransaction.deleteTermTransaction(props)
    if (!response.ok) throw new APIError(response)
    OsidoriEvent.emit(OsidoriEventName.DidDeleteTermTransaction, props)
  }

  isNeedPremiumPlan = () => {
    const userProfile = store.getState().profile.userProfile
    if (userProfile?.rank === 'premium') return false
    const termTransaction = store.getState().termTransaction
    const userId = userProfile?.userId
    const familyCount =
      termTransaction.familyTermTransactions?.filter((v) => v.userId === userId)
        .length || 0
    const userCount =
      termTransaction.userTermTransactions?.filter((v) => v.userId === userId)
        .length || 0
    return familyCount + userCount >= 2
  }
}

export default new TermTransactionManager()
