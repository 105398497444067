import IconImage from '@components/IcomImage'
import Log from '@lib/Log'
import moment from 'moment'
import React, { useEffect } from 'react'
import { TouchableOpacity, TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

const StyledTouchableOpacity = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
`

const StyledDateText = styled.Text`
  font-size: 16px;
  font-weight: normal;
  color: rgb(58, 58, 58);
`

const StyledIconImage = styled(IconImage)`
  margin-left: 20px;
  resize-mode: contain;
`

export type DateButtonProps = { date: Date } & TouchableOpacityProps

const DateButton: React.FC<DateButtonProps> = ({ date, ...props }) => {
  useEffect(() => {
    // test()
  }, [])

  Log.info('DateButton', { date })

  return (
    <StyledTouchableOpacity {...props}>
      <StyledDateText>{moment(date).format('YYYY/MM')}</StyledDateText>
      <StyledIconImage name="ArrowDown" />
    </StyledTouchableOpacity>
  )
}

export default DateButton
