import { FinancialAccount } from '@interfaces/Financial'
import Color from '@lib/Color'
import styled from 'styled-components/native'

const DEBUG_0RST = false
const DEBUG_S = false
const DEBUG_T = false

export type ErrorItemViewProps = {
  item: FinancialAccount
  onPress: (item: FinancialAccount) => void
}

const StyledView = styled.View`
  /* flex: -1; */
`

const StyledTouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  margin: -10px;
`

const StyledImage = styled.Image`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  resize-mode: contain;
`

const StyledText = styled.Text`
  margin-top: 1px;
  color: ${Color.DefaultText};
  font-size: 10px;
  font-weight: normal;
`

const getTitle = (lastRsltCd: string) => {
  if (!lastRsltCd) return
  if (
    (lastRsltCd !== '0' &&
      lastRsltCd !== 'R' &&
      lastRsltCd !== 'S' &&
      lastRsltCd !== 'T') ||
    DEBUG_0RST
  ) {
    return '再度連携が必要です'
  } else if (lastRsltCd === 'S' || DEBUG_S) {
    return '口座連携処理中です'
  } else if (lastRsltCd === 'T' || DEBUG_T) {
    // 当該金融機関の再認証が必要です
    return '再度連携が必要です'
  }
}

const ErrorItemView = ({ item, onPress }: ErrorItemViewProps) => {
  const title = getTitle(item.lastRsltCd)
  if (!title) return <StyledView />

  return (
    <StyledView>
      <StyledTouchableOpacity onPress={() => onPress(item)}>
        <StyledImage source={require('@images/icons/icon-caution.png')} />
        <StyledText>{title}</StyledText>
      </StyledTouchableOpacity>
    </StyledView>
  )
}

export default ErrorItemView
