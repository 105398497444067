import React from 'react'
import { ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'

const StyledRootView = styled.View`
  height: 60px;
  justify-content: center;
`

const ListLoadingView = (
  <StyledRootView>
    <ActivityIndicator />
  </StyledRootView>
)

export default ListLoadingView
