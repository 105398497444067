import { Platform } from 'react-native'

type FontWeight =
  | 'normal'
  | 'bold'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'

export default {
  Family: {
    Number: Platform.OS === 'android' ? 'nomal' : 'System',
  },
  Weight: {
    Number: '500' as FontWeight,
  },
}
