import { AddCaregoryButton } from '@Screen/Main/EditCategory/AddCategoryButton'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import AdView from '@components/AdView'
import { Balloon } from '@components/Balloon'
import FinancialAccountIcon from '@components/FinancialAccountIcon'
import FullWidthImage from '@components/FullWidthImage'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TintedImage from '@components/TintedImage'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import { UserAccountType } from '@interfaces/Account'
import { FinancialAccount } from '@interfaces/Financial'
import AccountTrackerManager from '@lib/AccountTrackerManager'
import Color from '@lib/Color'
import { NativeApp } from '@lib/Env'
import { FAIcon } from '@lib/FAIcon'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import * as Icon from '@lib/Icon'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import Stock, { GetStockAccountsResponse } from '@lib/api/Stock'
import { UserProfile } from '@lib/api/User'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import { Component } from 'react'
import {
  ActivityIndicator,
  Image,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { connect } from 'react-redux'
import styled from 'styled-components/native'
import ErrorItemView from './ErrorItemView'

// 口座の「登録口座」画面

interface ListItem {
  financialAccount: FinancialAccount
  // financialAccountType: FinancialAccountType
  monthOver?: number
}

interface StateProps {
  financial: FinancialState
  userProfile?: UserProfile
  userAccountType: UserAccountType
}

type Props = StateProps

interface State {
  refreshing: boolean
  planError: boolean
  isDisplayBalloon: boolean
  monthOver?: number
  accountMonthOver: { [accountId: string]: number }
  isLoading: boolean
  isVisiblePremiumBanner: boolean
  isVisiblePremiumBalloon: boolean
  isOpenedGoals: { [financialAccountId: number]: boolean }
  sortSettings?: ('bank' | 'card' | 'emoney' | 'stock' | 'wallet')[]
}

export const StyledQuestionIcon = styled(FAIcon).attrs({
  color: Color.Gray,
})`
  margin-top: 9px;
`

class TotalAssets extends Component<
  Props & StackScreenProps<RootStackParamList, 'TotalAssets'>,
  State
> {
  state: State = {
    refreshing: false,
    planError: false,
    isDisplayBalloon: false,
    accountMonthOver: {},
    isLoading: false,
    isVisiblePremiumBanner: this.props.userProfile?.rank === 'free',
    isVisiblePremiumBalloon: false,
    isOpenedGoals: {},
  }

  componentDidMount() {
    NavigationService.addNavigatableScreen('TotalAssets')

    this.props.navigation.addListener('focus', async () => {
      this.getTransactions()

      const settings = await FinancialManager.getFinanceSort()

      const sortSettings = settings
        .sort((a, b) => a.sort - b.sort)
        .map((v) => v.type)

      this.setState({ sortSettings })

      TrackingUtils.ga.pageview({
        page: `FinancialAccounts-${
          this.props.userAccountType === 'family' ? 'Family' : 'User'
        }`,
        title: `${
          this.props.userAccountType === 'family' ? '家族' : '個人'
        }_口座 登録口座`,
      })
    })
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.userAccountType !== this.props.userAccountType) {
      this.setState({ isLoading: true }, async () => {
        await this.getTransactions()
        this.setState({ isLoading: false })
      })
    }

    if (prevProps.userProfile?.rank !== this.props.userProfile?.rank) {
      this.setState({
        isVisiblePremiumBanner: this.props.userProfile?.rank === 'free',
      })
    }
  }

  componentWillUnmount = () => {
    NavigationService.removeNavigatableScreen('TotalAssets')
  }

  private getTransactions = async () => {
    await FinancialManager.fetchAssets()
    await FinancialManager.fetchCardAccounts()
    await FinancialManager.fetchWallets({
      userAccountType: store.getState().account.accountMode,
      sort: true,
    })

    if (this.props.userProfile?.rank === 'free') {
      await this.checkPlanError()
    }

    if (this.props.userAccountType === 'family') {
      TrackingUtils.repro.track('【Screen】Family_BS', 'Screen')
    } else {
      TrackingUtils.repro.track('【Screen】Personal_BS', 'Screen')
    }

    await this.getBs()
  }

  private getBs = async () => {
    try {
      // const settings = getStartDateSettings()

      // const date = getYearMonth(
      //   moment(),
      //   settings.start,
      //   settings.startDateSettingOption || StartDateSettingOption.None,
      // )

      // const assets = await FinancialManager.getMonthlyReportBs({
      //   userAccountType: store.getState().account.accountMode,
      //   year: date.year,
      //   month: date.month,
      //   term: 2,
      // })

      // if (assets.length < 2) return

      // 前日比
      const userAccountType = store.getState().account.accountMode

      const today = moment()
      const todayBs = await FinancialManager.getMonthlyReportBs({
        userAccountType,
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      })

      const yesterday = moment(today).subtract(1, 'day')
      const yesterdayBs = await FinancialManager.getMonthlyReportBs({
        userAccountType,
        year: yesterday.year(),
        month: yesterday.month() + 1,
        day: yesterday.date(),
      })

      if (!todayBs[0] || !yesterdayBs[0]) return
      const assets = [todayBs[0], yesterdayBs[0]]

      this.setState({
        monthOver: assets[0].totalBalance - assets[1].totalBalance,
      })

      const accountMonthOver: { [accountId: string]: number } = {}

      assets[0].bankAccounts.forEach((v) => {
        const account = assets[1].bankAccounts.find((vv) => v.id === vv.id)
        if (account) {
          accountMonthOver[`bank-${v.id}`] = v.balance - account.balance
        }
      })
      assets[0].emoneyAccounts.forEach((v) => {
        const account = assets[1].emoneyAccounts.find((vv) => v.id === vv.id)
        if (account) {
          accountMonthOver[`emoney-${v.id}`] = v.balance - account.balance
        }
      })
      assets[0].stockAccounts.forEach((v) => {
        const account = assets[1].stockAccounts.find((vv) => v.id === vv.id)
        if (account) {
          accountMonthOver[`stock-${v.id}`] = v.balance - account.balance
        }
      })
      assets[0].wallets.forEach((v) => {
        const account = assets[1].wallets.find((vv) => v.id === vv.id)
        if (account) {
          accountMonthOver[`wallet-${v.id}`] = v.balance - account.balance
        }
      })
      this.setState({ accountMonthOver })
    } catch (error) {
      Log.warn(error)
    }
  }

  private checkPlanError = async () => {
    // stockのエラーを使う（bank, emoneyでも良い）
    const response = await Stock.getStockAccounts({
      userAccountType: store.getState().account.accountMode,
    })
    if (!response.ok) return
    const data = response.json as GetStockAccountsResponse
    if (data.errors.find((v) => v.code === '007006')) {
      this.setState({ planError: true })
    } else {
      this.setState({ planError: false })
    }
  }

  private sectionHeader = (info: { section: SectionListData<ListItem> }) => (
    <SectionListHeader
      title={info.section.title}
      financialAccountType={info.section.key === 'bank' ? 'bank' : undefined}
      onPressQuestion={() =>
        this.setState({
          isVisiblePremiumBalloon: !this.state.isVisiblePremiumBalloon,
        })
      }
      style={{ fontWeight: 'normal' }}
    />
  )

  private onPressItem = (item: ListItem) => {
    if (item.financialAccount.finance !== 'stock') {
      NavigationService.navigate('FinancialAccountTransactions', {
        financialAccount: item.financialAccount,
      })
    } else {
      NavigationService.navigate('AssetProducts', {
        financialAccount: item.financialAccount,
      })
    }
  }

  private renderItem: SectionListRenderItem<ListItem> = ({ item }) => {
    let totalCurrentAmount = 0
    const goals = item.financialAccount.goals || []
    goals.forEach((v) => {
      totalCurrentAmount += v.currentAmount ?? v.balance ?? 0
    })

    const amountTitle =
      item.financialAccount.type === 'stock'
        ? '評価額'
        : item.financialAccount.type === 'card'
        ? '今月の利用額'
        : '残高'

    return (
      <>
        <TouchableOpacity
          style={styles.item}
          onPress={() => this.onPressItem(item)}>
          <View style={styles.row}>
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <FinancialAccountIcon
                financialAccountType={
                  item.financialAccount.financialAccountType
                }
              />
              <Text style={styles.textItem}>{item.financialAccount.name}</Text>
              <ErrorItemView
                item={item.financialAccount}
                onPress={this.onPressErrorItem}
              />
            </View>
          </View>
          <View style={[styles.row, styles.jpyBottom]}>
            <Text style={styles.textItem}>{amountTitle}</Text>
            <Text style={styles.jpy}>
              {(item.financialAccount.type !== 'stock'
                ? item.financialAccount.amount
                : item.financialAccount.balance
              )?.jpCurrency()}
            </Text>
            <Image
              source={require('@images/arrow/icon-arrow-white-small.png')}
              style={styles.arrow}
            />
          </View>
          {item.financialAccount.finance === 'stock' && (
            <View style={[styles.row, styles.jpyInvestment]}>
              <Text style={styles.profitLossLabel}>評価損益</Text>
              <Text
                style={
                  (item.financialAccount.profitLossAmount ?? 0) > 0
                    ? styles.jpyPlus
                    : (item.financialAccount.profitLossAmount ?? 0) < 0
                    ? styles.jpyMinus
                    : styles.jpyOffset
                }>
                {item.financialAccount.profitLossAmount?.jpCurrency()}
              </Text>
            </View>
          )}
          {item.financialAccount.finance == 'bank' ||
          item.financialAccount.finance === 'wallet' ? (
            <View
              style={{
                marginTop: 5,
                marginRight: 18,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: Color.TextBlack,
                }}>
                前日比
              </Text>
              <Text
                style={{
                  marginLeft: 10,
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: Color.number(item.monthOver),
                }}>
                {item.monthOver !== undefined
                  ? (item.monthOver >= 0 ? '+' : '') +
                    item.monthOver.jpCurrency()
                  : '-'}
              </Text>
            </View>
          ) : (
            <View
              style={{
                height: item.financialAccount.finance === 'stock' ? 3 : 10,
              }}
            />
          )}
        </TouchableOpacity>
        {item.financialAccount.goals &&
          item.financialAccount.goals.length > 0 && (
            <View style={{ alignItems: 'flex-start' }}>
              <TouchableOpacity
                onPress={() => {
                  this.setState({
                    isOpenedGoals: {
                      ...this.state.isOpenedGoals,
                      [item.financialAccount.accountId]:
                        !this.state.isOpenedGoals[
                          item.financialAccount.accountId
                        ],
                    },
                  })
                }}
                style={{
                  marginLeft: 30,
                  height: 28,
                  borderRadius: 14,
                  borderColor: '#aaaaaa',
                  borderWidth: 0.5,
                  alignItems: 'center',
                  paddingHorizontal: 15,
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    color: '#aaaaaa',
                    fontSize: 14,
                    fontWeight: 'normal',
                  }}>
                  口座の内訳
                </Text>
                <TintedImage
                  source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
                  style={{
                    marginLeft: 5,
                    tintColor: '#aaaaaa',
                    width: 12,
                    height: 12,
                    transform: [
                      {
                        rotate: this.state.isOpenedGoals[
                          item.financialAccount.accountId
                        ]
                          ? '180deg'
                          : '0deg',
                      },
                    ],
                  }}
                />
              </TouchableOpacity>
              {this.state.isOpenedGoals[item.financialAccount.accountId] ? (
                <TouchableOpacity
                  style={styles.goalsView}
                  onPress={() => this.onPressItem(item)}>
                  <View style={styles.goalsItem}>
                    <Image
                      source={require('@images/icons/icon-balance.png')}
                      style={styles.goalIconLabel}
                    />
                    <Text style={styles.goalsItemLabel}>使えるお金</Text>
                    <Text style={[styles.jpy, { fontSize: 15 }]}>
                      {(
                        (item.financialAccount.amount ??
                          item.financialAccount.balance ??
                          0) - totalCurrentAmount
                      ).jpCurrency()}
                    </Text>
                  </View>
                  {goals.map((v, index) => (
                    <View style={styles.goalsItem} key={index}>
                      <Image
                        source={require('@images/icons/icon-flag.png')}
                        style={styles.goalIconLabel}
                      />
                      <Text style={styles.goalsItemLabel}>{v.name}</Text>
                      <Text style={[styles.jpy, { fontSize: 15 }]}>
                        {(v.currentAmount ?? v.currentAmount ?? 0).jpCurrency()}
                      </Text>
                    </View>
                  ))}
                </TouchableOpacity>
              ) : (
                <View style={{ height: 10 }} />
              )}
            </View>
          )}

        {/* {this.state.planError && item.financialAccount.finance !== 'wallet' && (
          <View style={styles.disabledView} />
        )} */}
      </>
    )
  }

  private onPressErrorItem = (item: FinancialAccount) => {
    AccountTrackerManager.updateFinancialAccount({
      fncId: item.fncId,
      act: item.lastRsltCd === 'T' ? 'modify' : 'scrp',
      backButtonTitle: '口座',
      navigateBackScreen: this.props.route.name,
    })
  }

  private sections = (): SectionListData<ListItem>[] => {
    if (!this.state.sortSettings) return []

    const sections: SectionListData<ListItem>[] = []

    const setBanks = () => {
      const banks = this.props.financial.assets.banks || []
      if (banks.length > 0) {
        const section: SectionListData<ListItem> = {
          title: '銀行',
          key: 'bank',
          data: banks.map((bank) => ({
            financialAccount: bank,
            financialAccountType: 'bank',
            monthOver: this.state.accountMonthOver[`bank-${bank.accountId}`],
          })),
        }
        sections.push(section)
      }
    }

    const setCards = () => {
      const cards = this.props.financial.cards || []
      if (cards.length > 0) {
        const section: SectionListData<ListItem> = {
          title: 'カード',
          data: cards.map((card) => ({
            financialAccount: card,
            financialAccountType: 'card',
            // monthOver: this.state.accountMonthOver[`bank-${bank.accountId}`]
          })),
        }
        sections.push(section)
      }
    }

    const setEmoneys = () => {
      const emoneys = this.props.financial.assets.emoneys || []
      if (emoneys.length > 0) {
        const section: SectionListData<ListItem> = {
          title: '電子マネー',
          data: emoneys.map((emoney) => ({
            financialAccount: emoney,
            financialAccountType: 'emoney',
            monthOver:
              this.state.accountMonthOver[`emoney-${emoney.accountId}`],
          })),
        }
        sections.push(section)
      }
    }

    const setStocks = () => {
      const investments = this.props.financial.investments || []
      if (investments.length > 0) {
        const section: SectionListData<ListItem> = {
          title: '証券/投資信託',
          data: investments.map((investment) => ({
            financialAccount: investment,
            financialAccountType: 'stock',
            monthOver:
              this.state.accountMonthOver[`stock-${investment.accountId}`],
          })),
        }
        sections.push(section)
      }
    }

    const setWallets = () => {
      const wallets = this.props.financial.wallets || []
      if (wallets.length > 0) {
        const section: SectionListData<ListItem> = {
          title: '現金',
          data: wallets.map((wallet) => ({
            financialAccount: {
              accountId: wallet.id,
              financialAccountType: 'wallet',
              name: wallet.name,
              amount: wallet.amount,
              fncId: '',
              lastRsltCd: '0',
              lastRsltMsg: '',
              goals: wallet.goals,
              finance: wallet.finance,
              type: wallet.type,
            },
            financialAccountType: 'wallet',
            icon: Icon.Wallet,
            monthOver: this.state.accountMonthOver[`wallet-${wallet.id}`],
          })),
        }
        sections.push(section)
      }
    }

    this.state.sortSettings.forEach((v) => {
      if (v === 'bank') {
        setBanks()
      } else if (v === 'card') {
        setCards()
      } else if (v === 'emoney') {
        setEmoneys()
      } else if (v === 'stock') {
        setStocks()
      } else if (v === 'wallet') {
        setWallets()
      }
    })

    return sections
  }

  render() {
    const userAccountType = store.getState().account.accountMode

    return (
      <View style={styles.container}>
        {this.state.isVisiblePremiumBanner && (
          <TouchableOpacity
            onPress={() => navigatePremiumPlanLP({ planCode: '001' })}
            style={{
              marginTop: 15,
              marginHorizontal: 15,
              borderWidth: 0.5,
              borderRadius: 5,
              borderColor: '#dddddd',
              padding: 10,
            }}>
            <FullWidthImage source={require('@images/scraping-banner.webp')} />
            <TouchableOpacity
              onPress={() => {
                this.setState({ isVisiblePremiumBanner: false })
              }}
              style={{
                position: 'absolute',
                right: 0,
                paddingTop: 8,
                paddingRight: 8,
                paddingLeft: 15,
                paddingBottom: 15,
              }}>
              <Image
                source={require('@images/common-header/common-header-close-button.png')}
                style={{
                  width: wp(3),
                  height: wp(3),
                  tintColor: '#cccccc',
                }}
              />
            </TouchableOpacity>
          </TouchableOpacity>
        )}
        <View style={{ zIndex: 1 }}>
          {this.state.isDisplayBalloon && (
            <Balloon
              title="「現在の資産総額」の内訳は？"
              message={`「銀行口座の残高」「電子マネーの残高」「証券/投資信託の評価額」「財布の残高」の4項目の合計額が表示されます。\nなお「カード」の利用額はこちらには反映されません。`}
              onPressCloseButton={() => {
                this.setState({ isDisplayBalloon: false })
              }}
            />
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.label}>現在の資産総額</Text>
            <TouchableOpacity
              onPress={() => {
                this.setState({ isDisplayBalloon: true })
              }}
              style={{ paddingTop: NativeApp ? 4 : 8, paddingLeft: 4 }}>
              <StyledQuestionIcon
                icon={faQuestionCircle as never}
                size={19}
                webSize="1x"
              />
            </TouchableOpacity>
          </View>

          <View style={{ marginTop: 30 }}>
            <Text style={styles.textTotal}>
              {this.props.financial[userAccountType].totalAssetAmount
                ? this.props.financial[
                    userAccountType
                  ].totalAssetAmount?.jpCurrency()
                : (0).jpCurrency()}
            </Text>
            <View
              style={{
                marginTop: 5,
                marginRight: 20,
                marginBottom: 10,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: Color.TextBlack,
                }}>
                前日比
              </Text>
              <Text
                style={{
                  marginLeft: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: Color.number(this.state.monthOver),
                }}>
                {this.state.monthOver !== undefined
                  ? (this.state.monthOver >= 0 ? '+' : '') +
                    this.state.monthOver.jpCurrency()
                  : '-'}
              </Text>
            </View>
          </View>
        </View>
        {this.state.planError && (
          <View
            style={{
              marginTop: 5,
              borderTopColor: '#eeeeee',
              borderTopWidth: 1,
              padding: 20,
            }}>
            <TouchableOpacity
              style={styles.errorView}
              onPress={() => {
                NavigationService.navigate('DeleteFinancialAccounts')
              }}>
              <Image
                source={require('@images/icons/icon-caution.png')}
                style={styles.cautionImage}
              />
              <Text style={styles.errorText}>
                口座連携数が上限の5個を超えており{'\n'}
                データ更新を一時的に停止中です。
              </Text>
              <TintedImage
                source={require('@images/arrow/icon-arrow-white-small.png')}
                style={{
                  position: 'absolute',
                  right: 10,
                  width: 19,
                  height: 19,
                  tintColor: '#ff7200',
                }}
              />
            </TouchableOpacity>
          </View>
        )}
        <View style={{ flex: 1, justifyContent: 'space-between' }}>
          <View style={{ flex: 1 }}>
            <SectionList
              keyExtractor={(item) =>
                `${item.financialAccount.accountId}-${item.financialAccount.finance}-${item.financialAccount.amount}`
              }
              contentContainerStyle={{ paddingBottom: 100 }}
              renderItem={this.renderItem}
              renderSectionHeader={this.sectionHeader}
              sections={this.sections()}
              extraData={this.props.financial}
              ItemSeparatorComponent={() => ItemSeparator}
              refreshControl={
                <RefreshControl
                  refreshing={this.state.refreshing}
                  onRefresh={async () => {
                    this.setState({ refreshing: true })
                    await FinancialManager.fetchAssets()
                    await this.getBs()
                    await this.checkPlanError()
                    this.setState({ refreshing: false })
                  }}
                />
              }
            />
            {this.state.isVisiblePremiumBalloon && (
              <View
                style={{
                  position: 'absolute',
                  top: 25,
                  width: '100%',
                  paddingHorizontal: 10,
                  zIndex: 1,
                }}>
                <FullWidthImage
                  source={require('@images/scraping-balloon.webp')}
                />
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ isVisiblePremiumBalloon: false })
                  }}
                  style={{
                    position: 'absolute',
                    top: 25,
                    right: 18,
                    padding: 10,
                  }}>
                  <TintedImage
                    source={require('@images/common-header/common-header-close-button.png')}
                    style={{
                      tintColor: '#cccccc',
                      width: 15,
                      height: 15,
                    }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
          <AddCaregoryButton
            title="＋ 新しく追加する"
            edges={['top']}
            onPress={() =>
              NavigationService.navigate('FinancialAccountCooperation')
            }
            style={{
              marginBottom: this.props.userProfile?.rank === 'free' ? 90 : 10,
            }}
          />
          {this.props.userProfile?.rank === 'free' && <AdView type="320x50" />}
        </View>
        {this.state.isLoading && (
          <ActivityIndicator
            style={{
              position: 'absolute',
              alignSelf: 'center',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  disabledView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ffffff80',
  },
  errorView: {
    borderWidth: 1,
    borderColor: '#eeeeee',
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
  },
  cautionImage: {
    marginEnd: 10,
    resizeMode: 'contain',
    width: 24,
    height: 24,
  },
  errorText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#3a3a3a',
  },
  label: {
    color: '#555555',
    paddingLeft: 15,
    fontSize: 15,
    fontWeight: 'normal',
    paddingTop: 15,
  },
  textTotal: {
    color: '#rgb(58, 58, 58)',
    textAlign: 'right',
    paddingRight: 20,
    fontSize: 28,
    fontWeight: 'bold',
  },
  title: {
    color: '#555555',
    fontSize: 13,
    fontWeight: 'normal',
    backgroundColor: '#f0f0f0',
    paddingLeft: 15,
    paddingVertical: 10,
  },
  item: {
    paddingTop: 20,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  goalIconLabel: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  textItem: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingLeft: 5,
    paddingRight: 5,
    flex: 1,
  },
  profitLossLabel: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingRight: 8,
  },
  jpyBottom: {
    marginTop: 5,
    justifyContent: 'flex-end',
  },
  jpyInvestment: {
    marginTop: 4,
    marginRight: 18,
    justifyContent: 'flex-end',
  },
  jpy: {
    marginLeft: 15,
    fontSize: 22,
    color: '#3a3a3a',
    fontWeight: '700',
  },
  jpyPlus: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Primary,
  },
  jpyMinus: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Orange,
  },
  jpyOffset: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Gray,
  },
  arrow: {
    width: 16,
    height: 16,
    tintColor: Color.Gray,
  },
  goalsView: {
    paddingTop: 16,
    paddingBottom: 8,
    paddingLeft: 35,
    paddingRight: 20,
    width: '100%',
  },
  goalsItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  goalsItemLabel: {
    flex: 1,
    marginLeft: 8,
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
    userProfile: state.profile.userProfile,
    userAccountType: state.account.accountMode,
  }
}

export default connect(mapStateToProps)(TotalAssets)
