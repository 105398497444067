import { FinancialAccountType } from '@interfaces/Financial'
import AccountManager from '@lib/AccountManager'
import Log from '@lib/Log'
import Osidori from '@lib/Osidori'
import SessionManager from '@lib/SessionManager'
import store from '@redux/store.web'
import camelcaseKeys from 'camelcase-keys'
import queryString from 'query-string'
import { Linking } from 'react-native'
import { PermissionManager } from '../Permission'
import { activate, ActivateParams } from './Activate'
import { ArticleParams, navigationArticle } from './Article'
import { navigateBudgetSetting } from './BudgetSetting'
import { changeEmail, ChangeEmailParams } from './ChangeEmail'
import { changePassword, ChangePasswordParams } from './ChangePassword'
import {
  closeAccountTracker,
  CloseAccountTrackerParams,
} from './CloseAccountTracker'
import { navigationCreateGoal } from './CreateGoal'
import { navigationCreateTransaction } from './CreateTransaction'
import {
  deeplinkNavigation,
  DeeplinkNavigationParams,
} from './DeeplinkNavigationParams'
import { navigationFinancialAccountCooperation } from './FinancialAccountCooperation'
import { navigationGoals, NavigationGoalsParams } from './Goals'
import { navigateHome, NavigationHomeParams } from './Home'
import { navigateHousingLP } from './HousingLP'
import { navigateHowToUse } from './HowToUse'
import { navigateInsuranceWebView } from './InsuranceLP'
import { navigateInsuranceMortgage } from './InsuranceMortgage'
import { navigateMoneyManagement } from './MoneyManagement'
import { navigateMonthlyReport } from './MonthlyReport'
import { navigateNotices, NoticesParams } from './Notices'
import { pairing, PairingParams } from './Pairing'
import { navigationSettingAccount } from './SettingAccount'
import { navigationSettingPairing } from './SettingPairing'
import { navigationTransaction } from './Transactions'
import { updatePlan, UpdatePlansParams } from './UpdatePlan'
import { navigateWeeklyReport } from './WeeklyReport'

enum Command {
  Activate = 'activate',
  Article = 'article',
  ChangeEmail = 'change_email',
  ChangePassword = 'change_password',
  CloseAccountTracker = 'close-at',
  Pairing = 'pairing',
  Transactions = 'transactions',
  Goals = 'goals',
  Navigation = 'navigation',
  SettingPairing = 'setting-pairing',
  SettingAccount = 'setting-account',
  CreateGoal = 'create-goal',
  UpdatePlan = 'updatePlan',
  RequestATT = 'requestATT',
  WeeklyReport = 'weeklyReport',
  MonthlyReport = 'monthlyReport',
  Notices = 'notices',
  HowToUse = 'howToUse',
  MoneyManagement = 'moneyManagement',
  InsuranceMortgage = 'insuranceMortgage',
  BudgetSetting = 'budgetSetting',
  InsuranceLP = 'insuranceLP',
  HousingLP = 'housingLP',
  Housing = 'housing',
  CreateTransaction = 'createTransaction',
  FinancialAccountCooperation = 'financialAccountCooperation',
  PremiumPlan = 'premium-plan',
  PremiumPlanLP = 'premium-plan-lp',
  Home = 'home',
  LifePlanLP = 'lifeplan-lp',
  SelectGoalCategory = 'select-goal-category',
}

class DeepLinkService {
  constructor() {
    Log.info('DeepLinkService: constructor()')
    Linking.addEventListener('url', (event: { url: string }) =>
      this.onChangeURL(event.url),
    )
  }

  private onChangeURL = async (url: string) => {
    Log.info('DeepLinkService.onChangeURL', url)
    // if (url && url !== 'osidori://' && url !== 'osidori://redirect_top') {
    this.open(url)
    // }
  }

  open = (url: string) => {
    Log.info('DeepLinkService.openURL()', url)
    // const match = url.match(/^osidori:\/\/([^/?]*)(?:\/?\??)(.*)$/)
    const match = url.match(/^osidori:\/\/([^?]*)(?:\?)?(.*)$/)
    Log.info('DeepLinkService match', match)

    if (!match) {
      Osidori.start()
      return
    }

    const args = match[1].split('/')
    const command = args.shift()
    const query = match[2]
    const snakeCaseParams = queryString.parse(query, {
      parseBooleans: true,
      parseNumbers: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as { [key: string]: any }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const paramsOrigin = queryString.parse(query) as { [key: string]: any }
    const params = camelcaseKeys(snakeCaseParams) as unknown

    Log.info('DeepLinkService', {
      command,
      args,
      paramsOrigin,
      params,
      snakeCaseParams,
    })

    switch (command) {
      case Command.Navigation: {
        deeplinkNavigation(params as DeeplinkNavigationParams)
        break
      }
      case Command.Activate: {
        activate(params as ActivateParams)
        break
      }
      case Command.Article: {
        navigationArticle(params as ArticleParams)
        break
      }
      case Command.Pairing: {
        pairing(params as PairingParams)
        break
      }
      case Command.ChangeEmail: {
        changeEmail(params as ChangeEmailParams)
        break
      }
      case Command.ChangePassword: {
        changePassword(params as ChangePasswordParams)
        break
      }
      case Command.CloseAccountTracker: {
        closeAccountTracker(params as CloseAccountTrackerParams)
        break
      }
      case Command.Transactions: {
        if (args.length > 0) {
          const financialAccountType = (params as { type: string })
            .type as FinancialAccountType
          const account = (params as { account: string }).account
          const accountId = (params as { accountId?: string }).accountId
          const transactionId = +args[0]

          navigationTransaction({
            financialAccountType,
            userAccountType:
              account === 'person'
                ? 'user'
                : account === 'family'
                ? 'family'
                : undefined,
            accountId: accountId ? +accountId : undefined,
            transactionId,
          })
        } else {
          navigationTransaction()
        }
        break
      }
      case Command.CreateGoal: {
        navigationCreateGoal()
        break
      }
      case Command.Goals: {
        const goalId = +args[0]

        if (goalId) {
          const goalId = +args[0]
          navigationGoals({ goalId } as NavigationGoalsParams)
        } else {
          const userAccountType = (params as { userAccountType: string })
            .userAccountType
          if (userAccountType === 'family') {
            AccountManager.updateAccountMode('family')
            deeplinkNavigation({
              name: 'LifePlan',
              screen: 'GoalList',
            })
          }
        }
        break
      }
      case Command.SettingAccount: {
        navigationSettingAccount()
        break
      }
      case Command.SettingPairing: {
        navigationSettingPairing()
        break
      }
      case Command.UpdatePlan: {
        updatePlan(paramsOrigin as UpdatePlansParams) // paramsOriginにしないと001が1になる
        break
      }
      case Command.RequestATT: {
        PermissionManager.requestAppTrackingTransparency()
        break
      }
      case Command.MonthlyReport: {
        navigateMonthlyReport()
        break
      }
      case Command.WeeklyReport: {
        navigateWeeklyReport()
        break
      }
      case Command.Notices: {
        navigateNotices(params as NoticesParams)
        break
      }
      case Command.HowToUse: {
        navigateHowToUse()
        break
      }
      case Command.MoneyManagement: {
        navigateMoneyManagement()
        break
      }
      case Command.InsuranceMortgage: {
        navigateInsuranceMortgage()
        break
      }
      case Command.BudgetSetting: {
        navigateBudgetSetting()
        break
      }
      case Command.InsuranceLP: {
        navigateInsuranceWebView()
        break
      }
      case Command.HousingLP: {
        navigateHousingLP(params as { skipLp?: boolean })
        break
      }
      case Command.CreateTransaction: {
        navigationCreateTransaction()
        break
      }
      case Command.FinancialAccountCooperation: {
        navigationFinancialAccountCooperation()
        break
      }
      case Command.PremiumPlan: {
        if (store.getState().profile?.userProfile?.rank === 'free') {
          const planVersion =
            (store.getState().profile.userProfile?.userId ?? 0) % 2 === 0
              ? '3'
              : 'split'
          deeplinkNavigation({ name: 'PremiumPlanWebLP', planVersion })
        } else {
          deeplinkNavigation({ name: 'PremiumPlanForPremiumUser' })
        }
        break
      }
      case Command.PremiumPlanLP: {
        const planVersion =
          (store.getState().profile.userProfile?.userId ?? 0) % 2 === 0
            ? '3'
            : 'split'
        deeplinkNavigation({ name: 'PremiumPlanWebLP', planVersion })
        break
      }
      case Command.Home: {
        navigateHome(params as NavigationHomeParams)
        break
      }
      case Command.LifePlanLP: {
        deeplinkNavigation({
          name: 'LifePlan',
          screen: 'LifePlanWeb',
        })
        break
      }
      case Command.SelectGoalCategory: {
        deeplinkNavigation({
          name: 'GoalSelectType',
          ...(params as { userAccountType: string }),
        })
        break
      }
      default:
        // アプリ起動中に osidori:// が呼ばれた場合は、ここにくる
        // store.dispatch(updateRootScreen('Splash'))
        // Osidori.start()

        if (SessionManager.isLoggedIn()) {
          console.log('fetchHomeData 2')
          Osidori.fetchHomeData()
        }
        break
    }
  }
}

export default new DeepLinkService()
