import React from 'react'
import { ViewProps } from 'react-native'
import styled from 'styled-components/native'

const StyledView = styled.View`
  border-bottom-color: #f6f6f6;
  border-bottom-width: 1px;
`

const itemSeparator = ({ style }: ViewProps) => <StyledView style={style} />

export default itemSeparator
