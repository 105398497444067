import { View, Text, ViewStyle, TextStyle, Platform } from 'react-native'
import React from 'react'

export interface BadgeViewProps {
  containerStyle?: ViewStyle
  textStyle?: TextStyle
  badgeNumber: number
}

class BadgeView extends React.Component<BadgeViewProps> {
  static defaultProps: BadgeViewProps = {
    badgeNumber: 0,
  }

  render() {
    const { containerStyle, textStyle, badgeNumber } = this.props
    if (!badgeNumber) return null
    return (
      <View
        style={[
          {
            backgroundColor: 'red',
            borderRadius: 6,
            minWidth: 12,
            height: 12,
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: Platform.OS === 'ios' ? 2 : 0,
            paddingRight: Platform.OS === 'ios' ? 1 : 0,
          },
          containerStyle,
        ]}
      >
        <Text
          style={[
            {
              paddingBottom: Platform.OS === 'android' ? 1 : 0,
              color: 'white',
              fontSize: 9,
              fontWeight: 'bold',
            },
            textStyle,
          ]}
        >
          {badgeNumber}
        </Text>
      </View>
    )
  }
}

export default BadgeView
