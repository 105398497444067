// import { reducer as alert } from '@components/Alert'
import debug from '@Screen/Debug/redux/reducer'
import calendar from '@Screen/Main/Calendar/redux/reducer'
import assetTransition from '@Screen/Main/Home/FinancialAccounts/AssetTransitionScreen/slice'
import payoff from '@Screen/Main/Home/Payoff/slice'
import monthlyReport from '@Screen/Main/MonthlyReportScreen/slice'
import { reducer as settingCarryForward } from '@Screen/Main/SettingCarryForwardScreen/slice'
import settingPeriod from '@Screen/Main/SettingPeriodTabScreen/slice'
import settingProfiles from '@Screen/Main/SettingProfilesScreen/slice'
import settingTransactionDefault from '@Screen/Main/SettingTransactionDefaultScreen/slice'
import transactions from '@Screen/Main/Transactions/redux/reducer'
import main from '@Screen/Main/redux/reducer'
import appButton from '@components/AppButton/slice'
import calculator from '@components/Calculator/slice'
import { reducer as progressHUD } from '@components/ProgressHUDView/slice'
import account from '@lib/AccountManager/reducers'
import { reducer as announce } from '@lib/Announce/slice'
import { reducer as budget } from '@lib/Budget'
import financial from '@lib/FinancialManager/reducers'
import profile from '@lib/ProfileManager/reducers'
import termTransaction from '@lib/TermTransactionManager/reducers'
import timeline from '@lib/TimelineManager/reducers'
import goal from '@lib/api/Goal/slice'
import systemReport from '@redux/slice'
import { combineReducers, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import root from './reducer'
import { ActionType } from './types'

const appReducer = combineReducers({
  debug,
  root,
  main,
  appButton,
  financial,
  profile,
  account,
  timeline,
  goal,
  termTransaction,
  transactions,
  calendar,
  calculator,
  budget,
  payoff,
  settingProfiles,
  systemReport,
  monthlyReport,
  announce,
  assetTransition,
  settingTransactionDefault,
  settingPeriod,
  settingCarryForward,
  // alert,
  progressHUD,
})

export type RootState = ReturnType<typeof appReducer>

const rootReducer = (state: RootState | undefined, action: any): RootState => {
  if (action.type === ActionType.ClearAllState) {
    state = undefined
  }
  return appReducer(state, action)
}

const store = createStore(rootReducer)
export const persistor = persistStore(store)

export default store
