import { GoalContent } from '@interfaces/Goal'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import * as ImagePickerUtils from '@lib/ImagePickerUtils'
import ProgressHUD from '@lib/ProgressHUD'
import { UploadImageManager } from '@lib/api/UploadImage'
import { useCallback, useState } from 'react'
import { Dimensions, ImageBackground, Platform } from 'react-native'
import FastImage from 'react-native-fast-image'
import styled from 'styled-components/native'

export type LifePlanEventImageViewProps = {
  goalContent: GoalContent
}

const LifePlanEventImageView = ({
  goalContent,
}: LifePlanEventImageViewProps) => {
  const [imageUri, setImageUri] = useState(goalContent.lifeplanImgUrl)

  const uploadPhoto = useCallback(
    async ({ mime, base64 }: { mime: string; base64: string }) => {
      const response = await UploadImageManager.uploadImage({
        imageUploadType: 'lifeplan_event',
        mime,
        base64,
      })
      setImageUri(response.imgUrl)
    },
    [],
  )

  const onPressAddPhotoButton = useCallback(() => {
    ImagePickerUtils.open(
      {
        compressImageMaxWidth: 800,
        compressImageMaxHeight: 600,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').width * (600 / 800),
        includeBase64: true,
      },
      async (imageFile) => {
        try {
          if (!imageFile) return
          ProgressHUD.show()
          await uploadPhoto({
            mime: imageFile.mime,
            base64: imageFile.data || '',
          })
        } catch (error) {
          CommonDialog.showError({
            error,
            message: '写真のアップロードに失敗しました。',
          })
        } finally {
          ProgressHUD.dismiss()
        }
      },
    )
  }, [uploadPhoto])

  return (
    <StyledImage source={{ uri: imageUri }}>
      <StyledAvatarUpdateButton onPress={onPressAddPhotoButton}>
        <StyledCameraIcon />
        <StyledAvatarUpdateText>変更</StyledAvatarUpdateText>
      </StyledAvatarUpdateButton>
    </StyledImage>
  )
}

const StyledImage = styled(
  // TODO なぜかconfig-overridesのaliasが効かないのでPlatform.OSをみる
  Platform.OS !== 'web' ? FastImage : ImageBackground,
).attrs({
  resizeMode: 'cover',
})({
  height: 120,
})

const StyledAvatarUpdateButton = styled.TouchableOpacity({
  backgroundColor: Color.White,
  height: 28,
  position: 'absolute',
  right: 10,
  bottom: 10,
  borderRadius: 14,
  paddingHorizontal: 10,
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 1,
})

const StyledCameraIcon = styled.Image.attrs({
  source: require('@images/camera/icon-camera-small.png'),
  resizeMode: 'contain',
})({
  width: 18,
  height: 18,
  marginRight: 3,
  tintColor: Platform.OS !== 'web' ? Color.OsidoriGreen : Color.UIBankPrimary,
})

const StyledAvatarUpdateText = styled.Text({
  color: Color.Primary,
  fontSize: 12,
  fontWeight: 'bold',
})

export default LifePlanEventImageView
