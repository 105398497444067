import API, { APIResponse } from '../index'
import { CreateLifePlanEventProps, UpdateLifePlanEventProps } from './types'

export const getLifePlanEvents = (): Promise<APIResponse> => {
  return API.request('GET', '/api/v3/lifeplan/events', {}, true)
}

export const createLifePlanEvent = (
  props: CreateLifePlanEventProps,
): Promise<APIResponse> => {
  return API.request('POST', '/api/v3/lifeplan/events', props, true)
}

export const updateLifePlanEvent = ({
  id,
  ...props
}: UpdateLifePlanEventProps): Promise<APIResponse> => {
  return API.request('PUT', `/api/v3/lifeplan/events/${id}`, props, true)
}
