import { faFileChartLine } from '@fortawesome/pro-duotone-svg-icons'
import {
  faInfoCircle,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import Log from '@lib/Log'
import {
  MailPermission,
  MailPermissionsManager,
  UpdateMailPermissionProps,
} from '@lib/MailPermissions'
import NavigationService from '@lib/NavigationService'
import { useCallback, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  ImageRequireSource,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native'
import MenuListItem from './MenuListItem'

const icons: { [title: string]: IconDefinition | ImageRequireSource } = {
  ウィークリーレポート: faFileChartLine as never,
  マンスリーレポート: faFileChartLine as never,
  おトクな情報に関するお知らせ: require('@images/icons/icon-menu-budget.png'),
}

const SettingMailPermissionsScreen = () => {
  const [permissions, setPermissions] = useState<MailPermission[]>([])

  useEffect(() => {
    const callAPI = async () => {
      try {
        const permissions = await MailPermissionsManager.getMailPermissions()
        setPermissions(permissions)
      } catch (error) {
        CommonDialog.showError({ error, onPress: NavigationService.goBack })
      }
    }
    callAPI()
  }, [])

  const updatePermission = useCallback(
    async (props: UpdateMailPermissionProps) => {
      try {
        await MailPermissionsManager.updateMailPermission(props)
      } catch (error) {
        CommonDialog.showError({ error, onPress: NavigationService.goBack })
      }
    },
    [],
  )

  const setSwitch = useCallback(
    (id: number, suppressed: boolean) => {
      const newPermissions = permissions.map((v) =>
        v.id === id ? { ...v, MailPermissionsManager, suppressed } : v,
      )
      setPermissions(newPermissions)
      updatePermission({ id, suppressed })
    },
    [permissions, updatePermission],
  )

  return (
    <View style={styles.container}>
      {permissions.length > 0 ? (
        <ScrollView>
          <View style={{ marginTop: 20 }}>
            {permissions.map((v) => (
              <MenuListItem
                key={v.id}
                title={v.name}
                subTitle={v.description}
                iconDefinition={
                  typeof icons[v.name] !== 'number'
                    ? (icons[v.name] as IconDefinition) || faInfoCircle
                    : undefined
                }
                iconImageSource={
                  typeof icons[v.name] === 'number'
                    ? (icons[v.name] as ImageRequireSource)
                    : undefined
                }
                isDisplaySwitch
                onSwitchValueChange={() => {
                  Log.info('onSwitchValueChange', v)
                  setSwitch(v.id, !v.suppressed)
                }}
                switchValue={!v.suppressed}
                style={{ borderTopWidth: 1, borderTopColor: '#F7F7F7' }}
              />
              // <View key={v.id}>
              //   <View style={styles.settingItem}>
              //     <Text style={styles.settingLabel}>{v.name}</Text>
              //     <Switch
              //       onValueChange={() => setSwitch(v.id, !v.suppressed)}
              //       value={!v.suppressed}
              //     />
              //   </View>
              //   <Text style={styles.settingNote}>{v.description}</Text>
              // </View>
            ))}
          </View>
        </ScrollView>
      ) : (
        <ActivityIndicator style={{ flex: 1 }} />
      )}
    </View>
  )
}

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F7F7F7',
  },
  body: {
    flex: 1,
  },
  settingItem: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    backgroundColor: Color.White,
  },
  settingLabel: {
    color: Color.Tundora,
  },
  settingNote: {
    padding: 20,
    color: Color.Jumboapprox,
  },
})

export default SettingMailPermissionsScreen
