import Log from '@lib/Log'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import Svg, { Circle, Defs, Mask, Rect } from 'react-native-svg'
import SpotlightTourContext from './SpotlightTourContext'
import TipsContainer from './Tips'
import { PositionSize, Spot } from './types'
import { TourUtils } from './utils'

const circleProps = (
  { x, y, width, height, scale, translateX, translateY }: Spot,
  rect: PositionSize,
) => ({
  r: width > 0 && height > 0 ? (Math.max(width, height) * scale) / 2 : 0,
  cx: x + width / 2 + translateX - rect.x,
  cy: y + height / 2 + translateY - rect.y,
})

const rectProps = (
  { x, y, width, height, scale, rectRadius, translateX, translateY }: Spot,
  rect: PositionSize,
) => ({
  x: x - (width * scale - width) / 2 + translateX - rect.x,
  y: y - (height * scale - height) / 2 + translateY - rect.y,
  width: width > 0 && height > 0 ? width * scale : 0,
  height: width > 0 && height > 0 ? height * scale : 0,
  rx: rectRadius,
})

const styles = StyleSheet.create({
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // top: 0,
    // right: 0,
    // bottom: 0,
    // left: 0,
    backgroundColor: '#00000000',
  },
  overlayEmpty: {
    position: 'absolute',
  },
  tipsContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
})

export const OverlayView = () => {
  const {
    spots,
    tour,
    step,
    overlayColor,
    disabled,
    waitingSpotMeasures,
    setWaitingSpotMeasures,
  } = useContext(SpotlightTourContext)

  const viewRef = useRef<View>(null)
  const overlayViewRect = useRef<PositionSize>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  const prevStep = useRef<number>(0)

  // spotのbeforeが全て実行完了したらtrue
  const [isSpotsBeforeCompleted, setIsSpotsBeforeCompleted] = useState(false)

  // spotのbefore完了後、tourStepのbeforeが完了したらtrue
  const [isTourStepBeforeCompleted, setIsTourStepBeforeCompleted] =
    useState(false)

  // spotのafterが全て完了したらtrue
  const [isSpotsAfterCompleted, setIsSpotsAfterCompleted] = useState(false)

  // tourStep after完了 (今の所未使用)
  const [isReady, setIsReady] = useState(false)

  const [isOverlayMeasureReady, setIsOverlayMeasureReady] = useState(false)

  const tourStep = useMemo(() => {
    if (step > 0 && step <= tour.length) {
      const nextTourStep = TourUtils.convertTourStep(tour[step - 1])
      setWaitingSpotMeasures(nextTourStep?.spotIds.length || 0)
      return TourUtils.convertTourStep(tour[step - 1])
    }
  }, [setWaitingSpotMeasures, step, tour])

  const targetSpots = useMemo(() => {
    return (
      tourStep?.spotIds.map((spotId) => spots[spotId]).filter(Boolean) || []
    )
  }, [spots, tourStep])

  const visibleSpots = useMemo(
    () =>
      isTourStepBeforeCompleted &&
      waitingSpotMeasures === 0 &&
      isOverlayMeasureReady
        ? targetSpots
        : [],
    [
      isTourStepBeforeCompleted,
      targetSpots,
      waitingSpotMeasures,
      isOverlayMeasureReady,
    ],
  )

  // Log.info('OverlayView', {
  //   spots,
  //   tour,
  //   step,
  //   isSpotsBeforeCompleted,
  //   isTourStepBeforeCompleted,
  //   isSpotsAfterCompleted,
  //   isReady,
  //   targetSpots,
  //   visibleSpots,
  //   waitingSpotMeasures,
  // })

  useEffect(() => {
    if (prevStep.current === step) return
    prevStep.current = step >= 0 && step <= tour.length ? step : 0

    setIsReady(false)
    setIsSpotsAfterCompleted(false)
    setIsTourStepBeforeCompleted(false)
    setIsSpotsBeforeCompleted(false)
  }, [targetSpots, setWaitingSpotMeasures, tourStep, step, tour])

  useEffect(() => {
    if (step === 0 || isSpotsBeforeCompleted) return

    const spotBefores = targetSpots.filter((spot) => spot.before)

    if (spotBefores.length > 0) {
      const waitAllBefore = async () => {
        Log.info('waitAllBefore start')
        await Promise.all(
          spotBefores.map(
            (spot) =>
              new Promise<void>((resolve) => {
                spot.before?.(spot.spotId)
                if (spot.startDelay) {
                  setTimeout(resolve, spot.startDelay)
                } else {
                  resolve()
                }
              }),
          ),
        )
        Log.info('waitAllBefore end')

        setIsSpotsBeforeCompleted(true)
      }
      waitAllBefore()
    } else {
      setIsSpotsBeforeCompleted(true)
    }
  }, [isSpotsBeforeCompleted, step, targetSpots])

  useEffect(() => {
    if (isTourStepBeforeCompleted || !isSpotsBeforeCompleted) return

    if (tourStep?.before) {
      const waitTourStepBefore = async () => {
        Log.info('waitTourStepBefore start')

        await new Promise<void>((resolve) => {
          tourStep.before?.(step)
          resolve()
        })
        Log.info('waitTourStepBefore end')

        setIsTourStepBeforeCompleted(true)
      }
      waitTourStepBefore()
    } else {
      setIsTourStepBeforeCompleted(true)
    }
  }, [isSpotsBeforeCompleted, isTourStepBeforeCompleted, step, tourStep])

  useEffect(() => {
    if (isSpotsAfterCompleted || !isTourStepBeforeCompleted) return

    targetSpots.forEach((spot) => spot.onStart())

    const spotAfters = visibleSpots
      .filter((spot) => spot.after)
      .map(
        (spot) =>
          new Promise<void>((resolve) => {
            spot.after?.(spot.spotId)
            resolve()
          }),
      )

    if (spotAfters.length > 0) {
      const waitAllAfter = async () => {
        await Promise.all(spotAfters)
        setIsSpotsAfterCompleted(true)
      }
      waitAllAfter()
    } else {
      setIsSpotsAfterCompleted(true)
    }
  }, [
    isSpotsAfterCompleted,
    isTourStepBeforeCompleted,
    step,
    targetSpots,
    tourStep,
    visibleSpots,
  ])

  useEffect(() => {
    if (isReady || !isSpotsAfterCompleted) return

    if (tourStep?.after) {
      const asyncProc = async () => {
        await new Promise<void>((resolve) => {
          tourStep.after?.(step)
          resolve()
        })
        setIsReady(true)
      }
      asyncProc()
    } else {
      setIsReady(true)
    }
  }, [isReady, isSpotsAfterCompleted, step, tourStep])

  const onLayout = useCallback(() => {
    // Log.info(
    //   `OverlayView: onLayout ${viewRef.current ? true : viewRef.current}`,
    // )

    viewRef.current?.measureInWindow((x, y, width, height) => {
      // Log.info('viewRef.current?.measure', { x, y, width, height })
      overlayViewRect.current = { x, y, width, height }
      setIsOverlayMeasureReady(true)
    })
  }, [])

  if (!tourStep)
    return (
      <View ref={viewRef} style={styles.overlayEmpty} onLayout={onLayout} />
    )

  return (
    <View ref={viewRef} style={styles.overlayContainer} onLayout={onLayout}>
      <Svg height="100%" width="100%">
        <Defs>
          <Mask id="mask" x={0} y={0} height="100%" width="100%">
            <Rect height="100%" width="100%" fill="white" />
            {visibleSpots.map((spot) =>
              spot.spotType === 'rect' ? (
                <Rect
                  {...rectProps(spot, overlayViewRect.current)}
                  key={spot.spotId}
                  fill="black"
                />
              ) : (
                <Circle
                  key={spot.spotId}
                  {...circleProps(spot, overlayViewRect.current)}
                  fill="black"
                />
              ),
            )}
          </Mask>
        </Defs>
        <Rect
          height="100%"
          width="100%"
          fill={overlayColor}
          mask="url(#mask)"
          {...(Platform.OS !== 'web'
            ? {
                onPress: () => {
                  !disabled && tourStep?.onPressSuperView?.(step)
                },
              }
            : {
                onClick: () => {
                  !disabled && tourStep?.onPressSuperView?.(step)
                },
              })}
        />
        {visibleSpots
          .filter((spot) => spot.onPress)
          .map((spot) =>
            spot.spotType === 'rect' ? (
              <Rect
                key={spot.spotId}
                {...rectProps(spot, overlayViewRect.current)}
                fill="#00000000"
                {...(Platform.OS !== 'web'
                  ? {
                      onPress: () => !disabled && spot.onPress?.(spot.spotId),
                    }
                  : {
                      onClick: () => !disabled && spot.onPress?.(spot.spotId),
                    })}
              />
            ) : (
              <Circle
                key={spot.spotId}
                {...circleProps(spot, overlayViewRect.current)}
                fill="#00000000"
                {...(Platform.OS !== 'web'
                  ? {
                      onPress: () => !disabled && spot.onPress?.(spot.spotId),
                    }
                  : {
                      onClick: () => !disabled && spot.onPress?.(spot.spotId),
                    })}
              />
            ),
          )}
      </Svg>

      <View
        style={styles.tipsContainer}
        pointerEvents={disabled ? 'none' : 'box-none'}>
        {visibleSpots
          .filter((spot) => !spot.hideTips)
          .map((spot) => (
            <TipsContainer
              key={spot.spotId}
              spotId={spot.spotId}
              overlayViewRect={overlayViewRect.current}
            />
          ))}
      </View>
    </View>
  )
}
