import store from '@redux/store'
import { Platform, PlatformOSType } from 'react-native'
import { ENV } from 'react-native-dotenv'
import ProfileManager from './ProfileManager'
import { APIError } from './api'
import Purchase from './api/Purchase'

class PurchaseManager {
  getPartnerPurchasedPlan = (
    partnerPurchasedPlan: string | undefined = store.getState().profile
      .partnerProfile?.partnerPremiumPlan,
  ) => {
    const iOSPlans = this.getPlans('ios')
    const androidPlans = this.getPlans('android')
    const plans = this.getPlans()

    if (
      partnerPurchasedPlan === iOSPlans.monthlyPlan ||
      partnerPurchasedPlan === androidPlans.monthlyPlan
    ) {
      return plans.monthlyPlan
    } else if (
      partnerPurchasedPlan === iOSPlans.monthlyPlan2 ||
      partnerPurchasedPlan === androidPlans.monthlyPlan2
    ) {
      return plans.monthlyPlan2
    } else if (
      partnerPurchasedPlan === iOSPlans.monthlyPlanWithPartner ||
      partnerPurchasedPlan === androidPlans.monthlyPlanWithPartner
    ) {
      return plans.monthlyPlanWithPartner
    } else if (
      partnerPurchasedPlan === iOSPlans.monthlyPlanWithPartner2 ||
      partnerPurchasedPlan === androidPlans.monthlyPlanWithPartner2
    ) {
      return plans.monthlyPlanWithPartner2
    } else if (
      partnerPurchasedPlan === iOSPlans.yearlyPlan ||
      partnerPurchasedPlan === androidPlans.yearlyPlan
    ) {
      return plans.yearlyPlan
    } else if (
      partnerPurchasedPlan === iOSPlans.yearlyPlanWithPartner ||
      partnerPurchasedPlan === androidPlans.yearlyPlanWithPartner
    ) {
      return plans.yearlyPlanWithPartner
    }
  }

  getPartnerPurchasedPlanAsync = async () => {
    const partnerProfile = await ProfileManager.fetchPartnerProflie()

    if (!partnerProfile || !partnerProfile.partnerPremiumPlan) {
      return undefined
    }

    const partnerPurchasedPlan = partnerProfile.partnerPremiumPlan
    return this.getPartnerPurchasedPlan(partnerPurchasedPlan)
  }

  getPlans = (platform: PlatformOSType = Platform.OS) => {
    if (platform === 'ios') {
      if (ENV === 'development') {
        return {
          monthlyPlan: 'com.osidori.inc.indivisualMonthlySubscription',
          monthlyPlanWithPartner: 'com.osidori.inc.familyMonthlySubscription',
          monthlyPlan2: 'com.osidori.inc.indivisualMonthlySubscription2',
          monthlyPlanWithPartner2: 'com.osidori.inc.familyMonthlySubscription2',
          yearlyPlan: 'com.osidori.inc.indivisualYearlySubscription',
          yearlyPlanWithPartner: 'com.osidori.inc.familyYearlySubscription',
        }
      } else if (ENV === 'staging') {
        return {
          monthlyPlan: 'com.stg.osidori.inc.indivisualMonthlySubscription',
          monthlyPlanWithPartner:
            'com.stg.osidori.inc.familyMonthlySubscription',
          monthlyPlan2: 'com.stg.osidori.inc.indivisualMonthlySubscription2',
          monthlyPlanWithPartner2:
            'com.stg.osidori.inc.familyMonthlySubscription2',
          yearlyPlan: 'com.stg.osidori.inc.indivisualYearlySubscription',
          yearlyPlanWithPartner: 'com.stg.osidori.inc.familyYearlySubscription',
        }
      } else {
        return {
          monthlyPlan: 'osidori.inc.indivisualMonthlySubscription',
          monthlyPlanWithPartner: 'osidori.inc.familyMonthlySubscription',
          monthlyPlan2: 'osidori.inc.indivisualMonthlySubscription2',
          monthlyPlanWithPartner2: 'osidori.inc.familyMonthlySubscription2',
          yearlyPlan: 'osidori.inc.indivisualYearlySubscription',
          yearlyPlanWithPartner: 'osidori.inc.familyYearlySubscription',
        }
      }
    } else {
      return {
        monthlyPlan: 'monthly_plan',
        monthlyPlanWithPartner: 'monthly_plan_with_partner',
        monthlyPlan2: 'monthly_plan2',
        monthlyPlanWithPartner2: 'monthly_plan_with_partner2',
        yearlyPlan: 'yearly_plan',
        yearlyPlanWithPartner: 'yearly_plan_with_partner',
      }
    }
  }

  confirm = async (props: { platform: PlatformOSType; productId: string }) => {
    const platform: 'app-store' | 'google-play' | undefined =
      props.platform === 'ios'
        ? 'app-store'
        : props.platform === 'android'
        ? 'google-play'
        : undefined
    if (!platform) return

    const response = await Purchase.confirm({
      platform,
      productId: props.productId,
    })
    if (!response.ok) throw new APIError(response)
    return response.json
  }
}

export default new PurchaseManager()
