import Color from '@lib/Color'
// import { MaterialTopTabBarOptions } from '@react-navigation/material-top-tabs'
import styled from 'styled-components/native'
import DateButton from './DateButton'

export const StyledRootContainer = styled.View`
  flex: 1;
`

export const StyledTitleText = styled.Text`
  margin-top: 20px;
  margin-left: 20px;
  font-size: 13px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const StyledDetailText = styled.Text`
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 13px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

export const StyledSettingsView = styled.View({
  borderTopWidth: 1,
  borderTopColor: '#dddddd',
  marginTop: 25,
  marginLeft: 20,
  marginRight: 20,
})

export const StyledSettingsTitle = styled.Text`
  margin-top: 15px;
  font-size: 13px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const StyledSettingItem = styled.View({
  borderBottomWidth: 1,
  borderBottomColor: '#dddddd',
  paddingTop: 15,
  paddingBottom: 15,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const StyledSettingTitle = styled.Text`
  margin-left: 20px;
  font-size: 13px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const StyledStartMonthText = styled.Text`
  flex: 1;
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  color: ${Color.DefaultText};
  margin-right: 20px;
`

export const StyledSwitch = styled.Switch`
  /* margin-left: 10px; */
`

export const StyledArrowImage = styled.Image.attrs({
  source: require('@assets/images/arrow/icon-arrow-down-gray.png'),
})``

export const StyledSaveButtonContainer = styled.View`
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
`

// export const tabBarStyles: MaterialTopTabBarOptions = {
//   activeTintColor: Color.Green,
//   inactiveTintColor: '#999',
//   indicatorStyle: {
//     backgroundColor: Color.Green,
//     height: 1,
//   },
//   labelStyle: {
//     fontWeight: '600',
//     fontSize: 15,
//   },
//   style: {
//     elevation: 0,
//     backgroundColor: 'white',
//     borderBottomWidth: 1,
//     borderBottomColor: 'rgba(0,0,0,0.1)',
//   },
// }

export const StyledDateButton = styled(DateButton)`
  /* margin-top: ${wp(4)}px; */
  /* padding-vertical: ${wp(2)}px; */
  /* padding-right: ${wp(3)}px; */
`
