// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepEqual = (a: any, b: any) => {
  if (a === b) return true
  if (a === null || b === null) return false
  if (typeof a !== 'object' || typeof b !== 'object') return false
  if (Object.keys(a).length !== Object.keys(b).length) return false
  for (const key in a) {
    if (!Object.prototype.hasOwnProperty.call(b, key)) return false
    if (!deepEqual(a[key], b[key])) return false
  }
  return true
}
