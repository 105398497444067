import AppButton from '@components/AppButton'
import LifePlanEventCard from '@components/LifePlanEventCard'
import SectionListHeader2 from '@components/SectionListHeader2'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import navigationService from '@lib/NavigationService'
import { GoalManager } from '@lib/api/Goal'
import { selectGoal } from '@lib/api/Goal/slice'
import { LifePlanEvent, LifePlanManager } from '@lib/api/LifePlan'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'

const GoalLifePlan = () => {
  const goal = useSelector(selectGoal)

  const [lifePlanEvent, setLifePlanEvent] = useState<LifePlanEvent>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (goal?.lifeplanEventId) {
      setIsLoading(true)

      LifePlanManager.getLifePlanEvents()
        .then((events) => {
          const lifePlanEvent = events.find(
            (event) => event.id === goal?.lifeplanEventId,
          )
          setLifePlanEvent(lifePlanEvent)
        })
        .catch((error) => {
          CommonDialog.showError({ error })
        })
        .finally(() => setIsLoading(false))
    } else {
      setLifePlanEvent(undefined)
      setIsLoading(false)
    }
  }, [goal?.lifeplanEventId])

  const onPressConnectLifePlan = useCallback(() => {
    if (!goal) return
    GoalManager.updateEditor(goal)
    navigationService.navigate('ConnectLifePlan', { editorMode: false })
  }, [goal])

  log.info({ goal, lifePlanEvent })

  if (!goal || isLoading) return null

  return (
    <>
      <StyledHeader title="ライフプラン" />
      {lifePlanEvent ? (
        <>
          <StyledLifePlanEventCard lifePlanEvent={lifePlanEvent} disabled />
          <StyledEditLifePlanButtonView>
            <StyledEditLifePlanButton
              title="紐付けを編集"
              onPress={onPressConnectLifePlan}
            />
          </StyledEditLifePlanButtonView>
        </>
      ) : (
        <StyledEmptyView>
          <StyledEmptyImage />
          <StyledEmptyText>
            ライフイベントと連携して{'\n'}家族の夢を着実に実現していこう！
          </StyledEmptyText>
          <StyledConnectLifePlanButtonView>
            <StyledConnectLifePlanButton
              title="ライフプランと連携"
              onPress={onPressConnectLifePlan}
            />
          </StyledConnectLifePlanButtonView>
        </StyledEmptyView>
      )}
    </>
  )
}

const StyledHeader = styled(SectionListHeader2).attrs({
  textStyle: {
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
})({
  paddingLeft: 10,
})

const StyledLifePlanEventCard = styled(LifePlanEventCard)({
  marginTop: 5,
  marginHorizontal: 5,
})

const StyledEmptyImage = styled.Image.attrs({
  source: require('@assets/images/lifeplan/lifeplan_empty.webp'),
})({
  width: '50%',
  height: 'auto',
  aspectRatio: 612 / 480,
})

const StyledEmptyView = styled.View({
  paddingTop: 20,
  alignItems: 'center',
})

const StyledEmptyText = styled.Text({
  marginTop: 20,
  color: '#999999',
  fontSize: 14,
  lineHeight: 24,
  textAlign: 'center',
})

const StyledConnectLifePlanButtonView = styled.View({
  width: '100%',
  padding: 15,
})

const StyledConnectLifePlanButton = styled(AppButton).attrs({
  type: 'white',
  textStyle: { fontWeight: 'bold' },
})({
  marginHorizontal: 15,
})

const StyledEditLifePlanButtonView = styled.View({
  width: '100%',
  paddingTop: 5,
  paddingHorizontal: 20,
})

const StyledEditLifePlanButton = styled(AppButton).attrs({
  type: 'white',
  textStyle: { fontWeight: 'bold' },
})({})

export default GoalLifePlan
