import { AccountActions, AccountState } from './types'

export default (
  state: AccountState = {
    accountMode: 'family',
    isFamilyShareMode: false,
  },
  action: AccountActions
): AccountState => {
  switch (action.type) {
    case 'UpdateAccountMode':
      return { ...state, accountMode: action.accountMode }
    case 'UpdateFamilyShareMode':
      return {
        ...state,
        isFamilyShareMode: action.isFamilyShareMode,
      }
    default:
      return state
  }
}
