import { GoalEditorProps } from '@interfaces/Goal'

import { APIError } from '@lib/api'
import store from '@redux/store'
import * as GoalAPI from './api'
import {
  updateGoal as updateGoalAction,
  updateGoalCategories,
  updateGoalEditor,
  updateGoals,
} from './slice'
import {
  AddGoalAmountProps,
  DeleteGoalProps,
  GetGoalCategoriesProps,
  GetGoalCategoriesResponse,
  GetGoalProps,
  GetGoalResponse,
  GetGoalsProps,
  GetGoalsResponse,
  GetMonthlyReportProps,
  GetMonthlyReportResponse,
  initialGoalEditorProps,
} from './types'

export const initEditor = () => {
  store.dispatch(updateGoalEditor(initialGoalEditorProps))
}

export const updateEditor = (editor: GoalEditorProps) => {
  store.dispatch(updateGoalEditor({ ...initialGoalEditorProps, ...editor }))
}

export const createGoal = async (props: GoalEditorProps) => {
  const response = await GoalAPI.createGoal(props)
  if (!response.ok) throw new APIError(response)
}

export const updateGoal = async (props: GoalEditorProps) => {
  const response = await GoalAPI.updateGoal(props)
  if (!response.ok) throw new APIError(response)
}

export const getGoals = async (props: GetGoalsProps) => {
  const response = await GoalAPI.getGoals(props)
  if (!response.ok) throw new APIError(response)
  return (response.json as GetGoalsResponse).goals
}

export const getGoal = async (props: GetGoalProps) => {
  const response = await GoalAPI.getGoal(props)
  if (!response.ok) throw new APIError(response)
  return response.json as GetGoalResponse
}

export const fetchGoal = async (goalId: number) => {
  const response = await GoalAPI.getGoal({ goalId })
  if (response.ok) {
    const json: GetGoalResponse = response.json
    const goal = json.goal
    store.dispatch(updateGoalAction(goal))

    // const ownerSettings = goal.goalSettings.find(
    //   (v) => v.userId === store.getState().profile.userProfile?.userId,
    // )
    // const partnerSettings = goal.goalSettings.find(
    //   (v) => v.userId !== store.getState().profile.userProfile?.userId,
    // )
    // if (!ownerSettings || !partnerSettings) throw new APIError(response)

    const editor: GoalEditorProps = {
      goalId: goal.goalId,
      goalContentId: goal.goalContentId,
      name: goal.name,
      goalAmount: goal.goalAmount,
      startDate: goal.startDate,
      endDate: goal.endDate,
      imgUrl: goal.imgUrl,
      saveAmountType: goal.saveAmountType,
      share: goal.share,
      userGoalSettings: goal.userGoalSettings,
      partnerGoalSettings: goal.partnerGoalSettings,
      lifeplanEventId: goal.lifeplanEventId,
    }
    updateEditor(editor)
  } else {
    if (response.status === 422) {
      // Require group
      clearDetail()
    } else {
      throw new APIError(response)
    }
  }
}

export const fetchGoals = async (props: GetGoalsProps) => {
  const goals = await getGoals(props)
  store.dispatch(
    updateGoals({ userAccountType: props.share ? 'family' : 'user', goals }),
  )
  return goals
}

export const clearDetail = () => {
  store.dispatch(updateGoalAction(undefined))
}

export const addGoalAmount = async (props: AddGoalAmountProps) => {
  const response = await GoalAPI.addGoalAmount(props)
  if (!response.ok) throw new APIError(response)
}

export const deleteGoal = async (props: DeleteGoalProps) => {
  const response = await GoalAPI.deleteGoal(props)
  if (!response.ok) throw new APIError(response)
}

export const getMonthlyReport = async (props: GetMonthlyReportProps) => {
  const response = await GoalAPI.getMonthlyReport(props)
  if (!response.ok) throw new APIError(response)
  return (response.json as GetMonthlyReportResponse).goal
}

export const getGoalCategories = async (props: GetGoalCategoriesProps) => {
  const response = await GoalAPI.getGoalCategories(props)
  if (!response.ok) throw new APIError(response)
  return (response.json as GetGoalCategoriesResponse).goalCategories
}

export const fetchGoalCategories = async (props: GetGoalCategoriesProps) => {
  const goalCategories = await getGoalCategories(props)
  store.dispatch(
    updateGoalCategories({
      userAccountType: props.share ? 'family' : 'user',
      goalCategories,
    }),
  )
}
