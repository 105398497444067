import React from 'react'
import { StyledImage, StyledText, StyledTouchableOpacity } from './styles'
import { Props } from './types'

const RightArrowButton: React.FC<Props> = ({ children, onPress, disabled }) => {
  return (
    <StyledTouchableOpacity onPress={onPress} disabled={disabled}>
      <StyledText>{children}</StyledText>
      <StyledImage
        source={require('@images/arrow/icon-arrow-white-small.png')}
      />
    </StyledTouchableOpacity>
  )
}

export default RightArrowButton
