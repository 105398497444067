import Color from '@lib/Color'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  wrapTab: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 18,
    paddingBottom: 16,
  },
  wrapTabWithBorder: {
    borderBottomWidth: 2,
    borderColor: Color.Primary,
  },
  wrapTabWithBorderGray: {
    borderBottomWidth: 1,
    borderColor: Color.LightGray,
  },
  price: {
    textAlign: 'right',
    marginRight: 20,
    fontSize: 34,
    color: '#3A3A3A',
    fontWeight: '600',
  },
  wrapItem: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 33,
  },
  titleItem: {
    fontSize: 18,
    fontWeight: 'normal',
    color: '#8E8E8E',
  },
  wrapContent: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    minHeight: 56,
  },
  content: {
    color: '#3A3A3A',
    flex: 1,
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'normal',
  },
  placeholder: {
    color: '#aaaaaa',
    flex: 1,
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'normal',
  },
  iconDown: {
    width: 16,
    height: 16,
  },
  settingShareView: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
    alignItems: 'center',
  },
  wrapButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginTop: 4,
    marginBottom: 25,
  },
  minus: {
    fontSize: 30,
    color: '#3A3A3A',
    fontWeight: '600',
  },
  ellipsizeTextInput: {
    flex: 1,
    color: '#3A3A3A',
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  paymentPlaceText: {
    color: '#9e9e9e',
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  tab: {
    width: '100%',
    flexDirection: 'row',
  },
  tabText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  tabTextOn: {
    color: Color.Primary,
  },
  tabTextOff: {
    color: Color.Gray,
  },
  submitButton: {
    flex: 1,
    // width: wp('50%') - 16 - 8,
    resizeMode: 'contain',
  },
  col2: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 7,
    paddingBottom: 11,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    alignItems: 'center',
  },
})
