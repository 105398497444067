import { ScreenName } from '@navigation/Screens'
import { Action, ActionType, SetIDFAAction, SetScreenLockAction } from './types'

export const updateRootScreen = (screenName: ScreenName): Action => ({
  type: ActionType.UpdateRootScreen,
  rootScreen: screenName,
})

export const setKeyboardVisible = (visible: boolean): Action => ({
  type: ActionType.SetKeyboardVisible,
  visible,
})

export const setPlanCode = (
  planCode?: string,
  planVersion?: string,
): Action => ({
  type: ActionType.SetPlanCode,
  planCode,
  planVersion,
})

export const setIDFA = (idfa: string): SetIDFAAction => ({
  type: ActionType.SetIDFA,
  idfa,
})

export const setScreenLock = (isScreenLock: boolean): SetScreenLockAction => ({
  type: ActionType.SetScreenLock,
  isScreenLock,
})

export const clearAllState = (): Action => ({
  type: ActionType.ClearAllState,
})
