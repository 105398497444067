import { RootState } from '@redux/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Announce } from './types'

export type AnnouncesState = {
  announces: Announce[]
}

type Reducer = {
  setAnnounces: (
    state: AnnouncesState,
    action: PayloadAction<{
      announces: Announce[]
    }>,
  ) => void
}

const initialState: AnnouncesState = {
  announces: [],
}

const slice = createSlice<AnnouncesState, Reducer>({
  name: 'announces',
  initialState,
  reducers: {
    setAnnounces: (state, { payload }) => {
      state.announces = payload.announces
    },
  },
})

export const { setAnnounces } = slice.actions
export const selectAnnounces = (state: RootState) => state.announce.announces
export const reducer = slice.reducer
