import { deeplinkNavigation } from './DeeplinkNavigationParams'

const uris = {
  // budget: 'https://osidori.co/news/2020/11/30/1-55/',
  update: 'https://osidori.co/news/2020/05/25/627/',
}

export type NoticesParams = {
  item?: keyof typeof uris
}

export const navigateNotices = ({ item }: NoticesParams) => {
  const uri = item && uris[item]

  deeplinkNavigation({
    name: 'TimelineTop',
    screen: 'Notices',
    params: { uri },
  })
}
