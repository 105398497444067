import IconImage from '@components/IcomImage'
import { UserAccountType } from '@interfaces/Account'
import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

const StyledTouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: ${wp(4)}px;
  height: ${wp(8)}px;
  padding-horizontal: ${wp(4)}px;
`

const StyledText = styled.Text`
  font-size: ${wp(3.5)}px;
  font-weight: normal;
  color: rgb(58, 58, 58);
`

const StyledIconImage = styled(IconImage)`
  margin-left: ${wp(1)}px;
  width: ${wp(4)}px;
  height: ${wp(4)}px;
  resize-mode: contain;
`

export type UserAccountButtonProps = {
  userAccountType: UserAccountType
} & TouchableOpacityProps

const UserAccountButton = ({
  userAccountType,
  ...props
}: UserAccountButtonProps) => {
  return (
    <StyledTouchableOpacity {...props}>
      <StyledText>{userAccountType === 'family' ? '家族' : '個人'}</StyledText>
      <StyledIconImage name="ArrowDown" />
    </StyledTouchableOpacity>
  )
}

export default UserAccountButton
