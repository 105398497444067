import Log from '@lib/Log'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
} from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import SpotlightTourContext from './SpotlightTourContext'
import { TipsProps } from './Tips'
import { SpotType, TipsPosition } from './types'
import { deepEqual } from './utils'

export type SpotlightTourSpotProps = {
  spotId: string
  spotType?: SpotType
  scale?: number
  translateX?: number
  translateY?: number
  rectRadius?: number
  tipsPosition?: TipsPosition
  tipsComponent?: (props: TipsProps) => React.ReactElement
  hideTips?: boolean
  tipsTitle?: string
  tipsMessage?: string
  tipsNextTitle?: string
  tipsPreviosTitle?: string
  tipsFinishTitle?: string
  onPress?: (spotId: string) => void
  style?: StyleProp<ViewStyle>
  before?: (spotId: string) => void
  after?: (spotId: string) => void
  startDelay?: number // ms
}

export const SpotlightTourSpot = ({
  children,
  style,
  ...props
}: PropsWithChildren<SpotlightTourSpotProps>) => {
  const { spots, setSpot, setSpotPosition } = useContext(SpotlightTourContext)

  const {
    spotId,
    spotType = 'circle',
    scale = 1.3,
    translateX = 0,
    translateY = 0,
    rectRadius = 5,
    tipsPosition = 'auto',
    ...params
  } = props

  const containerRef = useRef<View>(null)

  const onStart = useCallback(() => {
    Log.info(
      `onStart: ${spotId}`,
      containerRef.current ? true : containerRef.current,
    )
    containerRef.current?.measureInWindow((x, y, width, height) => {
      Log.info(
        `onStart: ${spotId}: measureInWindow: x=${x} y=${y} width=${width} height=${height}`,
      )
      setSpotPosition(spotId, x, y, width, height)
    })
  }, [setSpotPosition, spotId])

  const onLayout = useCallback(() => {
    containerRef.current?.measureInWindow((x, y, width, height) => {
      // Log.info(
      //   `onLayout: ${spotId}: measureInWindow: x=${x} y=${y} width=${width} height=${height}`,
      // )

      const spot = spots[spotId]
      if (!spot || !deepEqual(spot, { ...props, x, y, width, height })) {
        setSpot({
          spotId,
          spotType,
          scale,
          translateX,
          translateY,
          rectRadius,
          tipsPosition,
          onStart,
          x,
          y,
          width,
          height,
          ...params,
        })
      }
    })
  }, [
    onStart,
    params,
    props,
    rectRadius,
    scale,
    setSpot,
    spotId,
    spotType,
    spots,
    tipsPosition,
    translateX,
    translateY,
  ])

  return (
    <View ref={containerRef} style={style} onLayout={onLayout}>
      {children}
    </View>
  )
}
