import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import TintedImage from '@components/TintedImage'
import {
  faEnvelope,
  faInfoCircle,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import Color from '@lib/Color'
import { NativeApp } from '@lib/Env'
import { FAIcon } from '@lib/FAIcon'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList, ScreenName } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import {
  Image,
  ImageSourcePropType,
  Linking,
  NativeEventSubscription,
  ScrollView,
  SectionListData,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

interface ListItem {
  title: string
  screen: ScreenName
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any
  subTitle?: string
  iconImageSource?: ImageSourcePropType
  iconDefinition?: IconDefinition
  style?: ViewStyle
  onPress?: () => void
}

interface Props {}

class SettingOthersScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'SettingOthers'>
> {
  private backHandler?: NativeEventSubscription

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.addListener(this.goBack)
  }

  componentWillUnmount() {
    this.backHandler?.remove()
  }

  private goBack = () => {
    const params = this.props.route.params
    if (params?.goBack) {
      params.goBack()
    } else {
      NavigationService.goBack()
    }
  }

  private renderItem = (props: ListItem) => {
    const {
      title,
      style,
      iconImageSource,
      iconDefinition,
      subTitle,
      screen,
      params,
      onPress,
    } = props
    const key = JSON.stringify(props)

    return (
      <MenuListItem
        key={key}
        title={title}
        isDisplayArrow={true}
        style={style}
        iconImageSource={iconImageSource}
        iconDefinition={iconDefinition}
        subTitle={subTitle}
        onPress={
          onPress
            ? onPress
            : () => {
                if (NativeApp) {
                  NavigationService.navigate(screen, params)
                } else {
                  if (screen === 'WebView') {
                    Linking.openURL(params.uri)
                  } else {
                    NavigationService.navigate(screen, params)
                  }
                }
              }
        }
      />
    )
  }

  private sectionListHeader = (section: SectionListData<ListItem>) => {
    const { title, subTitle, iconImageSource, style } = section
    const key = title
    if (!title)
      return (
        <View
          key={key}
          style={{
            height: 1,
            width: '100%',
            backgroundColor: Color.LightGray,
          }}
        />
      )
    return (
      <>
        <View
          style={{
            height: 1,
            width: '100%',
            backgroundColor: Color.LightGray,
          }}
        />
        <View
          style={[
            {
              backgroundColor: 'white',
              paddingTop: 20,
              paddingBottom: 6,
              paddingLeft: 15,
              flexDirection: 'row',
              alignItems: 'center',
            },
            style,
          ]}>
          {iconImageSource && (
            <Image source={iconImageSource} style={styles2.icon} />
          )}
          <View style={{ flexDirection: 'column' }}>
            <Text
              style={[
                styles2.text,
                {
                  fontWeight: subTitle ? 'bold' : undefined,
                },
              ]}>
              {title}
            </Text>
            <Text style={styles2.subTitle}>{subTitle}</Text>
          </View>
        </View>
      </>
    )
  }

  private sessions: SectionListData<ListItem>[] = [
    {
      data: [
        {
          title: 'FAQ',
          subTitle: '不明点はFAQで調べられます',
          screen: 'WebView',
          params: {
            title: 'FAQ',
            uri: 'https://support.osidori.co/hc/ja',
          },
          iconDefinition: faQuestionCircle as never,
        },
      ],
    },
    {
      data: [
        {
          title: 'お問い合わせ',
          subTitle: 'ご要望、ご質問はこちらから',
          screen: 'WebView',
          params: {
            title: 'お問い合わせ',
            uri: 'https://support.osidori.co/hc/ja/requests/new',
          },
          iconDefinition: faEnvelope as never,
        },
      ],
    },
    {
      data: [
        {
          title: 'OsidOriについて',
          subTitle: '利用規約、個人情報保護方針',
          screen: 'AboutOsidOri',
          iconDefinition: faInfoCircle as never,
        },
      ],
    },
  ]

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader
          title="FAQ・お問い合わせ 他"
          onPressLeftButton={this.goBack}
        />
        <ScrollView>
          {this.sessions.map((section) => (
            <>
              {this.sectionListHeader(section)}
              {section.data.map((item) => (
                <>
                  {this.renderItem(item)}
                  {ItemSeparator}
                </>
              ))}
            </>
          ))}
        </ScrollView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.LightGray,
  },
  flatList: {
    flex: 1,
  },
  menuItemIcon: {
    marginRight: 2,
    width: 24,
    height: '100%',
    marginLeft: 8,
    paddingTop: 13,
    alignItems: 'center',
    // justifyContent: 'center',
  },
})

export default SettingOthersScreen

export interface MenuListItemProps {
  title?: string
  subTitle?: string
  iconImageSource?: ImageSourcePropType
  iconDefinition?: IconDefinition
  isDisplayArrow?: boolean
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}

const MenuListItem = ({
  title,
  subTitle,
  iconImageSource,
  iconDefinition,
  isDisplayArrow,
  onPress,
  style,
}: MenuListItemProps) => (
  <TouchableOpacity onPress={onPress} style={[styles2.container, style]}>
    {iconImageSource && <Image source={iconImageSource} style={styles2.icon} />}
    {iconDefinition && (
      <View style={styles.menuItemIcon}>
        <FAIcon icon={iconDefinition as never} size={24} color={Color.Gray} />
      </View>
    )}
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
      }}>
      <Text
        style={[styles2.text, { fontWeight: subTitle ? 'bold' : undefined }]}>
        {title}
      </Text>
      {subTitle !== undefined && (
        <Text style={styles2.subTitle}>{subTitle}</Text>
      )}
    </View>
    {isDisplayArrow && (
      <TintedImage
        style={styles2.arrowImage}
        source={require('@images/arrow/icon-arrow-white-small.png')}
      />
    )}
  </TouchableOpacity>
)

const styles2 = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: Color.White,
    height: 68,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    marginLeft: 8,
  },
  text: {
    marginLeft: 8,
    fontSize: 15,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  subTitle: {
    marginTop: 6,
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.Gray,
  },
  arrowImage: {
    width: 19,
    height: 19,
    tintColor: Color.Gray,
    resizeMode: 'contain',
  },
})
