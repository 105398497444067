import CommonHeader from '@components/CommonHeader'
import NavigationService from '@lib/NavigationService'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { RootStackParamList } from '@navigation/Screens'

class CategoryColorPickerScreen extends React.Component<
  StackScreenProps<RootStackParamList, 'CategoryColorPicker'>
> {
  private renderDefaultColorButtons = () => {
    return (
      <>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FF6D6D')}
            style={[styles.colorButton, { backgroundColor: '#FF6D6D' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FFD028')}
            style={[styles.colorButton, { backgroundColor: '#FFD028' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FF9A16')}
            style={[styles.colorButton, { backgroundColor: '#FF9A16' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FF83AF')}
            style={[styles.colorButton, { backgroundColor: '#FF83AF' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#DC7AFF')}
            style={[styles.colorButton, { backgroundColor: '#DC7AFF' }]}
          />
        </View>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#22ABDB')}
            style={[styles.colorButton, { backgroundColor: '#22ABDB' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#83DBF7')}
            style={[styles.colorButton, { backgroundColor: '#83DBF7' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#47D3FF')}
            style={[styles.colorButton, { backgroundColor: '#47D3FF' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#90E6B8')}
            style={[styles.colorButton, { backgroundColor: '#90E6B8' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#A2A0FF')}
            style={[styles.colorButton, { backgroundColor: '#A2A0FF' }]}
          />
        </View>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#D88E58')}
            style={[styles.colorButton, { backgroundColor: '#D88E58' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#E2BE87')}
            style={[styles.colorButton, { backgroundColor: '#E2BE87' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#BDB679')}
            style={[styles.colorButton, { backgroundColor: '#BDB679' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#B9BDC1')}
            style={[styles.colorButton, { backgroundColor: '#B9BDC1' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#7E929D')}
            style={[styles.colorButton, { backgroundColor: '#7E929D' }]}
          />
        </View>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#34C5A2')}
            style={[styles.colorButton, { backgroundColor: '#34C5A2' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#6CDF49')}
            style={[styles.colorButton, { backgroundColor: '#6CDF49' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#A9EE39')}
            style={[styles.colorButton, { backgroundColor: '#A9EE39' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#8BE994')}
            style={[styles.colorButton, { backgroundColor: '#8BE994' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#7DAEA0')}
            style={[styles.colorButton, { backgroundColor: '#7DAEA0' }]}
          />
        </View>
      </>
    )
  }

  private renderCustomColorButtons = () => {
    return (
      <>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#A5396E')}
            style={[styles.colorButton, { backgroundColor: '#A5396E' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#D14E47')}
            style={[styles.colorButton, { backgroundColor: '#D14E47' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#EA635B')}
            style={[styles.colorButton, { backgroundColor: '#EA635B' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FEB02F')}
            style={[styles.colorButton, { backgroundColor: '#FEB02F' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#E4A336')}
            style={[styles.colorButton, { backgroundColor: '#E4A336' }]}
          />
        </View>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#2B4B98')}
            style={[styles.colorButton, { backgroundColor: '#2B4B98' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#3E77AB')}
            style={[styles.colorButton, { backgroundColor: '#3E77AB' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#507AE0')}
            style={[styles.colorButton, { backgroundColor: '#507AE0' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#44A9AF')}
            style={[styles.colorButton, { backgroundColor: '#44A9AF' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#3FC9C0')}
            style={[styles.colorButton, { backgroundColor: '#3FC9C0' }]}
          />
        </View>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#5955AA')}
            style={[styles.colorButton, { backgroundColor: '#5955AA' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#7E7ACD')}
            style={[styles.colorButton, { backgroundColor: '#7E7ACD' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#D76ACC')}
            style={[styles.colorButton, { backgroundColor: '#D76ACC' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#656963')}
            style={[styles.colorButton, { backgroundColor: '#656963' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#3A3C38')}
            style={[styles.colorButton, { backgroundColor: '#3A3C38' }]}
          />
        </View>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#F69CC8')}
            style={[styles.colorButton, { backgroundColor: '#F69CC8' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FC857E')}
            style={[styles.colorButton, { backgroundColor: '#FC857E' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FFAAAE')}
            style={[styles.colorButton, { backgroundColor: '#FFAAAE' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FED28A')}
            style={[styles.colorButton, { backgroundColor: '#FED28A' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#FABA80')}
            style={[styles.colorButton, { backgroundColor: '#FABA80' }]}
          />
        </View>
        <View style={styles.colorButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#F6B6FB')}
            style={[styles.colorButton, { backgroundColor: '#F6B6FB' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#84A7FB')}
            style={[styles.colorButton, { backgroundColor: '#84A7FB' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#B3D1FF')}
            style={[styles.colorButton, { backgroundColor: '#B3D1FF' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#BEBCE6')}
            style={[styles.colorButton, { backgroundColor: '#BEBCE6' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#A19FDB')}
            style={[styles.colorButton, { backgroundColor: '#A19FDB' }]}
          />
        </View>
        <View style={[styles.colorButtonGroup, { marginBottom: 40 }]}>
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#A4E5D9')}
            style={[styles.colorButton, { backgroundColor: '#A4E5D9' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#A1E6F7')}
            style={[styles.colorButton, { backgroundColor: '#A1E6F7' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#8E928B')}
            style={[styles.colorButton, { backgroundColor: '#8E928B' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#AFB2AD')}
            style={[styles.colorButton, { backgroundColor: '#AFB2AD' }]}
          />
          <TouchableOpacity
            onPress={() => this.onPressColorButton('#D6D7D5')}
            style={[styles.colorButton, { backgroundColor: '#D6D7D5' }]}
          />
        </View>
      </>
    )
  }

  private onPressColorButton = (color: string) => {
    this.props.route.params?.colorHandler(color)
    NavigationService.goBack()
  }

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader title="カラーの選択" />
        <ScrollView>
          <View style={[styles.wrapItem, { marginTop: 20 }]}>
            <Text
              style={{
                fontWeight: 'bold',
                color: '#3A3A3A',
                marginBottom: 10,
              }}
            >
              デフォルト
            </Text>
            {this.renderDefaultColorButtons()}
          </View>
          <View style={[styles.wrapItem, { marginTop: 20 }]}>
            <Text
              style={{
                fontWeight: 'bold',
                color: '#3A3A3A',
                marginBottom: 10,
              }}
            >
              カスタム
            </Text>
            {this.renderCustomColorButtons()}
          </View>
        </ScrollView>
      </View>
    )
  }
}

export default CategoryColorPickerScreen

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  colorButtonGroup: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: wp(3),
  },
  colorButton: {
    borderRadius: 5,
    width: wp(11),
    height: wp(11),
  },
  wrapItem: {
    flex: 1,
    paddingHorizontal: wp(6),
    paddingTop: 8,
  },
})
