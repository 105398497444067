import { sendSignal } from '@lib/OsidoriSignal'
import { APIError } from '@lib/api'
import * as UploadImageAPI from './api'
import { UploadImageProps, UploadImageResponse } from './types'

export const uploadImage = async (
  props: UploadImageProps,
): Promise<UploadImageResponse> => {
  const response = await UploadImageAPI.uploadImage(props)
  if (!response.ok) throw new APIError(response)

  const data = response.json as UploadImageResponse

  sendSignal('IMAGE_UPLOADED', {
    uploadImageType: props.imageUploadType,
    imgUrl: data.imgUrl,
  })

  return data
}
