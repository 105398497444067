import API, { APIResponse } from './index'

export interface PairingProps {
  pairingToken: string
}

export interface PairingResponse {
  app: {
    token: string
  }
}

class Pairing {
  generateToken = (): Promise<APIResponse> => {
    return API.request(
      'GET',
      '/api/v1/pairing-requests/generate_pairing_token',
      {},
      true,
    )
  }

  pairing = (props: PairingProps): Promise<APIResponse> => {
    return API.request(
      'POST',
      '/api/v1/pairing-requests/receive_pairing_request',
      props,
      true,
    )
  }

  cancel = (): Promise<APIResponse> => {
    return API.request('DELETE', '/api/v1/pairing-requests', {}, true)
  }
}

export default new Pairing()
