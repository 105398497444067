import ActionSheet from '@components/ActionSheet'
import CategoryImage from '@components/CategoryImage'
import IconImage from '@components/IcomImage'
import { BudgetManager } from '@lib/Budget'
import { BudgetCommonState } from '@lib/Budget/types'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import {
  getPrevMonthStartDate,
  getStartDate,
  parseMonthKey,
  StartDateSettingOption,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import FinancialManager from '@lib/FinancialManager'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Image,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import { PieChart, PieChartData } from 'react-native-svg-charts'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import ShowMoreButton from './ShowMoreButton'
import {
  selectBalances,
  selectUserAccountType,
  selectYearMonth,
  setBalances,
  setIsProcessingBalance,
} from './slice'
import { StyledCommonPanel } from './styles'

interface Item {
  ratio: number
  categoryId: string
  amount: number
  scope?: 'income' | 'expense'
  monthOver?: number
  type: number
  budgetOver?: number
}

const StyledPanel = styled(StyledCommonPanel)`
  padding-top: ${wp(7)}px;
  padding-bottom: ${wp(7)}px;
  padding-horizontal: ${wp(5)}px;
`

const DropdownContainer = styled.TouchableOpacity`
  margin-top: 15px;
  background-color: #f0f0f0;
  height: 32px;
  padding-horizontal: 16px;
  border-radius: 16px;
  align-items: center;
  flex-direction: row;
`

const DropdownText = styled.Text`
  font-size: 15px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

const DropdownIconImage = styled(IconImage)`
  margin-left: 4px;
`

const ActionSheetIndex = {
  PrevMonth: 0,
  Budget: 1,
}

const BalancePanel = (props: TouchableOpacityProps) => {
  const dispatch = useDispatch()

  const userAccountType = useSelector(selectUserAccountType)
  const yearMonth = useSelector(selectYearMonth)
  const balances = useSelector(selectBalances)

  const [scope, setScope] = useState<'income' | 'expense'>('expense')
  const [isShowMore, setIsShowMore] = useState(true)
  const [dropdownType, setDropdownType] = useState(ActionSheetIndex.PrevMonth)
  const [budget, setBudget] = useState<BudgetCommonState>()

  const onPressDropdown = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: ['前月比', '予算比', 'キャンセル'],
        cancelButtonIndex: 2,
      },
      (index: number) => {
        if (index === ActionSheetIndex.PrevMonth) {
          setDropdownType(ActionSheetIndex.PrevMonth)
        } else if (index === ActionSheetIndex.Budget) {
          setDropdownType(ActionSheetIndex.Budget)
        }
      },
    )
  }, [])

  const fetchBalance = useCallback(async () => {
    if (!yearMonth || !userAccountType) return
    dispatch(setIsProcessingBalance(true))

    try {
      const { year, month } = parseMonthKey(yearMonth)
      const settings = getStartDateSettings(userAccountType)
      const startDate = getStartDate(
        year,
        month,
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      )

      const thisMonth = await FinancialManager.fetchBalance(
        userAccountType,
        false, // TODO
        startDate.format(),
      )

      // 前月
      const prevMonthStartDate = getPrevMonthStartDate(
        year,
        month,
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      )
      const prevMonth = await FinancialManager.fetchBalance(
        userAccountType,
        false, // TODO
        prevMonthStartDate.format(),
      )

      if (!prevMonth || !thisMonth) return

      dispatch(setBalances([thisMonth, prevMonth]))
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      dispatch(setIsProcessingBalance(false))
    }
  }, [dispatch, userAccountType, yearMonth])

  const fetchBudget = useCallback(async () => {
    if (!yearMonth || !userAccountType) return
    const { year, month } = parseMonthKey(yearMonth)

    const budget = await BudgetManager.getBudget({
      userAccountType,
      year,
      month,
    })

    setBudget(budget)
  }, [userAccountType, yearMonth])

  useEffect(() => {
    fetchBalance()
    fetchBudget()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountType, yearMonth])

  const pieChartView = (
    title: string,
    amount: number,
    data: PieChartData[],
  ) => {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <PieChart
          style={{ width: wp('27%'), height: wp('27%') }}
          data={data}
          outerRadius="98%"
          innerRadius="75%"
          padAngle={0.04}
        />
        <View style={{ position: 'absolute' }}>
          <Text
            style={{
              fontSize: wp('3%'),
              fontWeight: 'normal',
              color: '#3A3A3A',
              textAlign: 'center',
            }}>
            {title}
          </Text>
          <Text
            style={{
              fontSize: wp('3%'),
              fontWeight: 'normal',
              color: '#3A3A3A',
              textAlign: 'center',
            }}>
            {amount.jpCurrency()}
          </Text>
        </View>
      </View>
    )
  }

  const renderCharts = () => {
    if (!yearMonth || !userAccountType) return

    const pl = balances[0]
    if (!pl) return null

    // Income
    const incomeAmounts = pl.incomeList.map((v) => v.amount)
    const incomeColors = pl.incomeList.map(
      (v) => CategoryManager.getCategoryIconInfo(v.categoryId)?.color,
    )
    const incomePieData = incomeAmounts.map((value, index) => ({
      value,
      svg: {
        fill: incomeColors[index],
      },
      key: String(index),
    }))

    // Expense
    const expenseAmounts = pl.expenseList.map((v) => Math.abs(v.amount))
    const expenseColors = pl.expenseList.map(
      (v) => CategoryManager.getCategoryIconInfo(v.categoryId)?.color,
    )
    const expensePieData = expenseAmounts.map((value, index) => ({
      value,
      svg: {
        fill: expenseColors[index],
      },
      key: String(index),
    }))

    return (
      <View style={styles.chartSection}>
        {pieChartView('収入', pl.incomeAmount, incomePieData)}
        {pieChartView('支出', pl.expenseAmount, expensePieData)}
      </View>
    )
  }

  const listHeader = () => {
    if (!yearMonth) return null

    const thisMonthTotalAmount =
      balances.length >= 1
        ? balances[0].expenseAmount + balances[0].incomeAmount
        : undefined
    const prevMonthTotalAmount =
      balances.length >= 2
        ? balances[1].expenseAmount + balances[1].incomeAmount
        : undefined

    const monthOver =
      thisMonthTotalAmount !== undefined && prevMonthTotalAmount !== undefined
        ? thisMonthTotalAmount - prevMonthTotalAmount
        : undefined

    const budgetOver =
      budget?.amount && thisMonthTotalAmount !== undefined
        ? budget.amount - Math.abs(thisMonthTotalAmount)
        : undefined

    return (
      <View style={{ marginTop: 0 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}>
          <View>
            <Text
              style={{
                marginTop: 4,
                fontSize: 16,
                fontWeight: 'normal',
                color: 'rgb(58, 58, 58)',
              }}>
              今月の収支
            </Text>
            <DropdownContainer onPress={onPressDropdown}>
              <DropdownText>
                {dropdownType === ActionSheetIndex.Budget ? '予算比' : '前月比'}
              </DropdownText>
              <DropdownIconImage name="ArrowDown" />
            </DropdownContainer>
          </View>
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}>
              {monthOver !== undefined &&
                (monthOver > 0 ? (
                  <Image
                    source={require('@images/report/up-icon.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ) : monthOver < 0 ? (
                  <Image
                    source={require('@images/report/down-icon.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ) : (
                  <Image
                    source={require('@images/report/arrow-flat.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ))}
              <Text
                style={{
                  marginLeft: 3,
                  fontSize: wp(100) < 375 ? wp(6) : 24,
                  fontWeight: 'bold',
                  color: Color.NumberBlack,
                }}>
                {thisMonthTotalAmount !== undefined
                  ? thisMonthTotalAmount.jpCurrency()
                  : '-'}
              </Text>
            </View>
            <View
              style={{
                marginTop: 5,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: 'rgb(102, 102, 102)',
                }}>
                {dropdownType === ActionSheetIndex.PrevMonth
                  ? '前月比'
                  : '予算比'}
              </Text>
              {dropdownType === ActionSheetIndex.PrevMonth ? (
                <Text
                  style={{
                    marginLeft: 10,
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: Color.number(monthOver),
                  }}>
                  {monthOver !== undefined
                    ? monthOver.jpCurrency({
                        showPlus: true,
                        showPlusMinus: true,
                      })
                    : '-'}
                </Text>
              ) : (
                <>
                  {budgetOver !== undefined && (
                    <Text
                      style={{
                        marginLeft: 10,
                        fontSize: 14,
                        fontWeight: 'normal',
                        color: Color.number(budgetOver),
                      }}>
                      {budgetOver >= 0 ? '残り' : '超過'}
                    </Text>
                  )}
                  <Text
                    style={{
                      marginLeft: 5,
                      fontSize: 14,
                      fontWeight: 'bold',
                      color: Color.number(budgetOver),
                    }}>
                    {budgetOver !== undefined
                      ? Math.abs(budgetOver).jpCurrency()
                      : '-'}
                  </Text>
                </>
              )}
            </View>
          </View>
        </View>

        {renderCharts()}

        {/* 収入、支出ボタン */}
        <View
          style={{
            marginTop: 20,
            marginBottom: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              color: 'rgb(58, 58, 58)',
            }}>
            内訳
          </Text>
          <View
            style={{
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}>
            <ScopeButton
              title="収入"
              on={scope === 'income'}
              onPress={() => setScope('income')}
            />
            <ScopeButton
              title="支出"
              on={scope === 'expense'}
              onPress={() => setScope('expense')}
            />
          </View>
        </View>
      </View>
    )
  }

  const renderItem: SectionListRenderItem<Item> = ({ item, index }) => {
    if (isShowMore && index > 2) return null

    return item.scope ? (
      <ListItem item={item} index={index} />
    ) : (
      <NoDataListItem />
    )
  }

  const onPressShowMoreButton = useCallback(() => setIsShowMore(false), [])

  const monthOver = useCallback(
    (scope: 'income' | 'expense', amount: number, categoryId: string) => {
      const thisMonthBalance = balances[0]
      const prevBalance = balances[1]
      if (!thisMonthBalance || !prevBalance) return undefined

      const list =
        scope === 'income' ? prevBalance.incomeList : prevBalance.expenseList
      const prevMonthPL = list.find((v) => v.categoryId === categoryId)
      if (!prevMonthPL) return undefined

      return amount - prevMonthPL.amount
    },
    [balances],
  )

  const budgetOver = useCallback(
    (categoryId: string, amount: number) => {
      const categoryBudget = budget?.atGroupedCategoryBudgets.find(
        (v) => v.atGroupedCategoryId === categoryId,
      )
      if (!categoryBudget) return undefined
      if (!categoryBudget.amount) return undefined
      return categoryBudget.amount - Math.abs(amount)
    },
    [budget?.atGroupedCategoryBudgets],
  )

  const sections = useMemo((): SectionListData<Item>[] => {
    const pl = balances[0]
    if (!pl) return []

    return scope === 'income'
      ? [
          {
            title: '',
            data: pl.incomeList.map((v) => ({
              categoryId: v.categoryId,
              ratio: v.ratio,
              amount: v.amount,
              scope: 'income',
              monthOver: monthOver(scope, v.amount, v.categoryId),
              type: dropdownType,
            })),
          },
        ]
      : [
          {
            title: '',
            data: pl.expenseList.map((v) => ({
              categoryId: v.categoryId,
              ratio: v.ratio,
              amount: v.amount,
              scope: 'expense',
              monthOver: monthOver(scope, v.amount, v.categoryId),
              type: dropdownType,
              budgetOver: budgetOver(v.categoryId, v.amount),
            })),
          },
        ]
  }, [balances, scope, monthOver, dropdownType, budgetOver])

  return (
    <StyledPanel>
      <View style={styles.container}>
        <View style={{ flex: 1, justifyContent: 'space-between' }}>
          <SectionList
            keyExtractor={(item: Item) => JSON.stringify(item)}
            renderItem={renderItem}
            sections={sections}
            // ItemSeparatorComponent={() => ItemSeparator}
            ListHeaderComponent={listHeader}
            ListFooterComponent={() => (
              <>
                <View
                  style={{
                    borderTopWidth: 0.5,
                    borderTopColor: '#ccc',
                    height: 5,
                  }}
                />
                {isShowMore && (sections[0]?.data.length || 0) > 3 && (
                  <ShowMoreButton onPress={onPressShowMoreButton} />
                )}
              </>
            )}
          />
        </View>
        {/* ) : (
          <View style={{ height: 25 }} />
        )} */}
      </View>
    </StyledPanel>
  )
}

const NoDataListItem = () => (
  <Text style={{ padding: 20, color: Color.DefaultText }}>
    データありません
  </Text>
)

const ListItem: React.FC<{ item: Item; index: number }> = ({ item, index }) => {
  return (
    <View
      key={item.categoryId}
      style={{
        borderTopWidth: 0.5,
        borderTopColor: '#ccc',
        paddingRight: 10,
        backgroundColor: index % 2 === 0 ? 'white' : 'rgb(254, 248, 243)',
      }}>
      <View style={styles.listPaymentsItem}>
        <View style={styles.listPaymentsItemType}>
          <CategoryImage
            categoryId={item.categoryId}
            style={styles.listPaymentsItemTypeIcon}
          />
          <Text style={styles.listPaymentsItemTypeText} numberOfLines={1}>
            {CategoryManager.getCategoryIconInfo(item.categoryId)?.categoryName}
          </Text>
        </View>
        <View style={styles.listPaymentsItemInfo}>
          <Text style={styles.listPaymentsItemInfoAmount}>
            {item.amount.jpCurrency()}
          </Text>
          <Text style={styles.listPaymentsItemInfoPercent}>
            {Math.round(item.ratio * 100)}%
          </Text>
        </View>
      </View>
      {item.scope === 'expense' || item.type === ActionSheetIndex.PrevMonth ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 3,
            marginBottom: 8,
          }}>
          <Text
            style={{
              color: 'rgb(102, 102, 102)',
              fontSize: wp(100) < 375 ? wp(3) : 12,
              fontWeight: 'normal',
            }}>
            {' '}
            {item.type === ActionSheetIndex.PrevMonth ? '前月比' : '予算比'}
          </Text>
          {item.type === ActionSheetIndex.PrevMonth ? (
            <Text
              style={{
                color: Color.number(item.monthOver),
                marginLeft: 10,
                fontSize: wp(100) < 375 ? wp(3.5) : 14,
                fontWeight: 'bold',
              }}>
              {item.monthOver !== undefined
                ? item.monthOver.jpCurrency({
                    showPlus: true,
                    showPlusMinus: true,
                  })
                : '-'}
            </Text>
          ) : (
            <>
              {item.budgetOver !== undefined && (
                <Text
                  style={{
                    marginLeft: 10,
                    fontSize: 12,
                    fontWeight: 'normal',
                    color: Color.number(item.budgetOver),
                  }}>
                  {item.budgetOver >= 0 ? '残り' : '超過'}
                </Text>
              )}
              <Text
                style={{
                  color: Color.number(item.budgetOver),
                  marginLeft: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                }}>
                {item.budgetOver !== undefined
                  ? Math.abs(item.budgetOver).jpCurrency()
                  : '-'}
              </Text>
            </>
          )}
        </View>
      ) : (
        <View style={{ height: 15 }} />
      )}
    </View>
  )
}

const ScopeButton = ({
  title,
  on,
  ...props
}: { title: string; on?: boolean } & TouchableOpacityProps) => {
  return (
    <TouchableOpacity
      style={{
        marginLeft: 10,
        backgroundColor: on ? 'rgb(63, 197, 21)' : '#eee',
        borderRadius: 16,
        height: 32,
        width: 56,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}>
      <Text
        style={{
          fontSize: 12,
          color: on ? 'white' : 'rgb(153, 153, 153)',
          fontWeight: 'bold',
        }}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
  periodSection: {
    minHeight: 45,
    maxHeight: 45,
    paddingTop: 6,
    paddingHorizontal: 8,
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  chartSection: {
    flexDirection: 'row',
    height: wp('30%'),
    marginTop: 4,
  },
  dateText: {
    padding: 8,
    fontSize: 16,
    fontWeight: 'normal',
  },
  paymentSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textAmount: {
    marginRight: 24,
    color: Color.GrayVeryDark,
    fontSize: 34,
    fontWeight: 'normal',
  },
  textBalance: {
    marginTop: 14,
    marginRight: 12,
    color: Color.GrayVeryDark,
    fontSize: 15,
    fontWeight: 'normal',
  },
  scrollView: {
    height: '50%',
  },
  listPaymentsItem: {
    paddingTop: 15,
    // paddingBottom: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listPaymentsItemTypeIcon: {
    marginLeft: 15,
    marginRight: 6,
  },
  listPaymentsItemTypeText: {
    flex: 1,
    fontSize: wp(100) < 375 ? wp(3.5) : 17,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfo: {
    flex: -1,
    marginLeft: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
  },
  listPaymentsItemInfoPercent: {
    width: wp(100) < 375 ? wp(10) : 44,
    fontSize: wp(100) < 375 ? wp(3.5) : 14,
    fontWeight: 'normal',
    textAlign: 'right',
    color: Color.Gray,
  },
  listPaymentsItemInfoAmount: {
    fontSize: wp(100) < 375 ? wp(4) : 18,
    fontWeight: 'bold',
    color: Color.GrayVeryDark,
  },
  iconEdit: {
    resizeMode: 'contain',
    height: 20,
  },
  btnEdit: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  premiumTextOr: {
    margin: wp(3.5),
    color: 'white',
    fontSize: wp(4),
    fontWeight: 'bold',
  },
})

export default BalancePanel
