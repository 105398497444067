import Account from '@Screen/Account'
import DebugScreen from '@Screen/Debug/DebugScreen'
import Main from '@Screen/Main'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import SplashScreen from '@Screen/SplashScreen'
import UIBankFirstScreen from '@Screen/UIBankFirstScreen'
import WalkthroughScreen from '@Screen/WalkthroughScreen'
import WelcomeScreen from '@Screen/WelcomeScreen'
import Alert from '@components/Alert'
import Calculator from '@components/Calculator'
import { closeCalculator } from '@components/Calculator/slice'
import ImageCrop from '@components/ImageCrop'
import ProgressHUDView from '@components/ProgressHUDView'
import { MobileSafari } from '@lib/Env'
import NavigationService from '@lib/NavigationService'
import { GA_ID } from '@lib/webenv'
import {
  DefaultTheme,
  NavigationContainer,
  NavigationContainerRef,
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import * as moment from 'moment-timezone'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { setPlanCode } from './redux/actions'
import store, { RootState } from './redux/store'

moment.tz.setDefault('Asia/Tokyo')

ReactGA.initialize(GA_ID)
console.log({ GA_ID })

const Stack = createStackNavigator()

const StyledWebRootView = styled.View({
  height: MobileSafari ? '-webkit-fill-available' : '100vh',
})

const rootNameSelector = ({ root }: RootState) => root.rootScreen
const planCodeSelector = ({ root }: RootState) => root.planCode
const planVersionSelector = ({ root }: RootState) => root.planVersion

const RootStack = () => {
  const dispatch = useDispatch()

  const rootName = useSelector(rootNameSelector)
  const planCode = useSelector(planCodeSelector)
  const planVersion = useSelector(planVersionSelector)

  useEffect(() => {
    if (rootName === 'Main' && planVersion) {
      navigatePremiumPlanLP({ planVersion, planCode })
      dispatch(setPlanCode())
    }
  }, [rootName, planCode, dispatch, planVersion])

  return (
    <StyledWebRootView>
      <Stack.Navigator
        screenOptions={{
          gestureEnabled: false,
          headerShown: false,
          animationEnabled: false,
        }}>
        {rootName === 'Splash' && (
          <Stack.Screen name="Splash" component={SplashScreen} />
        )}
        {rootName === 'Walkthrough' && (
          <Stack.Screen name="Walkthrough" component={WalkthroughScreen} />
        )}
        {rootName === 'UIBankFirst' && (
          <Stack.Screen name="UIBankFirst" component={UIBankFirstScreen} />
        )}
        {rootName === 'Welcome' && (
          <Stack.Screen name="Welcome" component={WelcomeScreen} />
        )}
        {rootName === 'Account' && (
          <Stack.Screen name="Account" component={Account} />
        )}
        {rootName === 'Main' && <Stack.Screen name="Main" component={Main} />}
        <Stack.Screen name="ImageCrop" component={ImageCrop} />
        <Stack.Screen name="Debug" component={DebugScreen} />
      </Stack.Navigator>
    </StyledWebRootView>
  )
}

const onNavigationStateChange = () => {
  if (store.getState().calculator.isVisible) {
    store.dispatch(closeCalculator({ animated: true }))
  }
}

const documentTitleFormatter = {
  formatter: () => 'OsidOri',
}

const navigationContainerRef = (navigation: NavigationContainerRef | null) => {
  navigation && NavigationService.setNavigation(navigation)
}

const Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
  },
}

export default () => (
  <Provider store={store}>
    <SafeAreaProvider>
      <NavigationContainer
        onStateChange={onNavigationStateChange}
        theme={Theme}
        ref={navigationContainerRef}
        documentTitle={documentTitleFormatter}>
        <RootStack />
      </NavigationContainer>
      <Calculator />
      <Alert />
      <ProgressHUDView />
    </SafeAreaProvider>
  </Provider>
)
