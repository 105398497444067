import ActionSheet from '@components/ActionSheet'
import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import ArrowIcon from '@components/ArrowIcon'
import Avatar from '@components/Avatar'
import { IconImages } from '@components/IcomImage'
import { PayoffManager } from '@lib/api/Payoff'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import RNProgressHud from '@lib/ProgressHUD'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import {
  payoffDisplaySettleTypeSelector,
  payoffSelector,
  payoffTransactionsSelector,
} from '../../selector'
import SettledTransactionDialog from '../../SettledTransactionDialog'
import { RootContainer, StyledTextInput } from './styles'

type InputType = 'percent' | 'amount'

const PayoffContentsScreen: React.FC<
  StackScreenProps<RootStackParamList, 'PayoffContents'>
> = () => {
  const [ownerSplitRatio, setOwnerSplitRatio] = useState(0)
  const [partnerSplitRatio, setPartnerSplitRatio] = useState(0)
  const [inputType, setInputType] = useState<InputType>('percent')
  const [userSettleAmount, setUserSettleAmount] = useState(0)
  const [partnerSettleAmount, setPartnerSettleAmount] = useState(0)
  const [userSplitAmount, setUserSplitAmount] = useState(0)
  const [partnerSplitAmount, setPartnerSplitAmount] = useState(0)
  const [settleAmount, setSettleAmount] = useState(0)

  const displaySettleType = useSelector(payoffDisplaySettleTypeSelector)
  const transactions = useSelector(payoffTransactionsSelector)
  const payoff = useSelector(payoffSelector)

  const totalSettleAmount = useMemo(
    () => userSettleAmount + partnerSettleAmount,
    [userSettleAmount, partnerSettleAmount],
  )

  useEffect(() => {
    if (!transactions) return

    let userSettleAmount = 0
    let partnerAmount = 0

    const myUserId = store.getState().profile.userProfile?.userId

    transactions.forEach((transaction) => {
      if (myUserId === transaction.userId) {
        userSettleAmount += Math.abs(transaction.amount)
      } else {
        partnerAmount += Math.abs(transaction.amount)
      }
    })

    setUserSettleAmount(userSettleAmount)
    setPartnerSettleAmount(partnerAmount)
  }, [transactions])

  useEffect(() => {
    if (!displaySettleType) return

    if (displaySettleType === 'notSettled') {
      // 分担する額
      const userSplitAmount = Math.round(totalSettleAmount * ownerSplitRatio)
      const partnerSplitAmount = totalSettleAmount - userSplitAmount

      // 精算額（パートナーがわたしに支払う金額。マイナスなら、わたしがパートナーに支払う金額となる）
      const settleAmount = userSettleAmount - userSplitAmount

      setUserSplitAmount(userSplitAmount)
      setPartnerSplitAmount(partnerSplitAmount)
      setSettleAmount(settleAmount)
    } else {
      // 精算済み
      // 分担する額
      const userSplitAmount = payoff?.owner.calcSplitAmount || 0
      const partnerSplitAmount = payoff?.partner.calcSplitAmount || 0
      // 精算額（パートナーがわたしに支払う金額。マイナスなら、わたしがパートナーに支払う金額となる）
      const settleAmount =
        (payoff?.partner.payoffAmount || 0) - (payoff?.owner.payoffAmount || 0)
      // 分担割合
      // const ownerSplitRatio = userSplitAmount / totalSettleAmount
      // const partnerSplitRatio = 1.0 - ownerSplitRatio
      const ownerSplitRatio = (payoff?.owner.splitRatio || 0) / 100
      const partnerSplitRatio = 1 - ownerSplitRatio

      setUserSplitAmount(userSplitAmount)
      setPartnerSplitAmount(partnerSplitAmount)
      setSettleAmount(settleAmount)
      setOwnerSplitRatio(ownerSplitRatio)
      setPartnerSplitRatio(partnerSplitRatio)
    }
  }, [
    displaySettleType,
    totalSettleAmount,
    userSettleAmount,
    partnerSettleAmount,
    ownerSplitRatio,
    partnerSplitRatio,
    payoff?.owner.calcSplitAmount,
    payoff?.owner.payoffAmount,
    payoff?.owner.splitRatio,
    payoff?.partner.calcSplitAmount,
    payoff?.partner.payoffAmount,
  ])

  const setSettings = useCallback(async () => {
    if (!displaySettleType) return

    if (displaySettleType === 'notSettled') {
      try {
        const settings = (await PayoffManager.getSettings()).payoffSettings
        setOwnerSplitRatio(settings.owner.splitRatio / 100)
        setPartnerSplitRatio(settings.partner.splitRatio / 100)
      } catch (error) {
        CommonDialog.showError({ error })
      }
    }
  }, [displaySettleType])

  const onChangeOwnerSplitRatio = useCallback((value: string) => {
    const ownerValue = Math.min(+value.replace(/\D/, '') / 100, 1.0)
    const partnerValue = 1.0 - ownerValue
    setOwnerSplitRatio(ownerValue)
    setPartnerSplitRatio(partnerValue)
  }, [])

  const onChangePartnerSplitRatio = useCallback((value: string) => {
    const partnerValue = Math.min(+value.replace(/\D/, '') / 100, 1.0)
    const ownerValue = 1.0 - partnerValue
    setOwnerSplitRatio(ownerValue)
    setPartnerSplitRatio(partnerValue)
  }, [])

  const onChangeOwnerSplitAmount = useCallback(
    (value: string) => {
      const ownerValue = Math.min(totalSettleAmount, +value.replace(/\D/, ''))
      const partnerValue = totalSettleAmount - ownerValue
      const userRatio = ownerValue / totalSettleAmount
      const partnerRatio = 1.0 - userRatio
      setUserSplitAmount(ownerValue)
      setPartnerSplitAmount(partnerValue)
      setOwnerSplitRatio(userRatio)
      setPartnerSplitRatio(partnerRatio)
    },
    [totalSettleAmount],
  )

  const onChangePartnerSplitAmount = useCallback(
    (value: string) => {
      const partnerValue = Math.min(totalSettleAmount, +value.replace(/\D/, ''))
      const ownerValue = totalSettleAmount - partnerValue
      const partnerRatio = partnerValue / totalSettleAmount
      const userRatio = 1.0 - partnerRatio
      setUserSplitAmount(ownerValue)
      setPartnerSplitAmount(partnerValue)
      setOwnerSplitRatio(userRatio)
      setPartnerSplitRatio(partnerRatio)
    },
    [totalSettleAmount],
  )

  const isPaymentFromPartnerToOwner = useMemo(
    () => settleAmount >= 0,
    [settleAmount],
  )

  const disabled = useMemo(
    () =>
      displaySettleType === 'settled' ||
      (!ownerSplitRatio && !partnerSplitRatio),
    [displaySettleType, ownerSplitRatio, partnerSplitRatio],
  )

  const onPressChangeInputTypeButton = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      {
        options:
          Platform.OS === 'ios'
            ? ['割合', '金額指定', 'キャンセル']
            : ['割合', '金額指定'],
        cancelButtonIndex: 2,
      },
      (index: number) => {
        if (index === 0) {
          setInputType('percent')
        } else if (index === 1) {
          setInputType('amount')
        }
      },
    )
  }, [])

  const settle = useCallback(async () => {
    try {
      RNProgressHud.show()

      await PayoffManager.payoffs(
        transactions || [],
        {
          splitAmount: userSettleAmount,
          calcSplitAmount: userSplitAmount,
          payoffAmount: isPaymentFromPartnerToOwner
            ? 0
            : Math.abs(settleAmount),
          splitRatio: Math.round(ownerSplitRatio * 100),
        },
        {
          splitAmount: partnerSettleAmount,
          calcSplitAmount: partnerSplitAmount,
          payoffAmount: isPaymentFromPartnerToOwner
            ? Math.abs(settleAmount)
            : 0,
          splitRatio: Math.round(partnerSplitRatio * 100),
        },
      )

      SettledTransactionDialog.show(true, {
        date: moment().format('YYYY/M/D'),
        categoryId: payoff?.atTransactionCategoryId || '387',
        amount: totalSettleAmount.jpCurrency(),
        isPaymentFromPartnerToOwner,
      })
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      RNProgressHud.dismiss()
    }
  }, [
    userSettleAmount,
    userSplitAmount,
    isPaymentFromPartnerToOwner,
    settleAmount,
    ownerSplitRatio,
    partnerSettleAmount,
    partnerSplitAmount,
    partnerSplitRatio,
    transactions,
    payoff?.atTransactionCategoryId,
    totalSettleAmount,
  ])

  const onPressSettleButton = useCallback(() => {
    Alert.alert(
      '精算を行ないます。\nよろしいですか？',
      '精算後の取引は「精算管理」の『精算済み』タブに表示されます。\n実際の精算（現金や振込等のやりとり）はお客さまでご対応をお願いします。',
      [
        { text: '戻る', style: 'cancel' },
        { text: 'OK', onPress: () => settle() },
      ],
    )
  }, [settle])

  useEffect(() => {
    setSettings()

    const totalSettleAmount = userSettleAmount + partnerSettleAmount

    const userSplitAmount = Math.round(
      totalSettleAmount * (+ownerSplitRatio / 100),
    )
    const partnerSplitAmount = totalSettleAmount - userSplitAmount

    setUserSplitAmount(userSplitAmount)
    setPartnerSplitAmount(partnerSplitAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySettleType])

  return (
    <RootContainer>
      <View
        style={{
          marginTop: 20,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Text
          style={{
            fontSize: 15,
            fontWeight: 'normal',
            color: Color.DefaultText,
          }}>
          精算対象の金額
        </Text>
        <Text
          style={{
            marginLeft: 15,
            marginBottom: 3,
            fontSize: 20,
            fontWeight: 'normal',
            color: Color.DefaultText,
          }}>
          {totalSettleAmount.jpCurrency()}
        </Text>
      </View>
      <View
        style={{
          marginTop: 15,
          marginHorizontal: 20,
          padding: 15,
          alignItems: 'center',
          backgroundColor: 'white',
          borderWidth: 1,
          borderColor: '#cccccc',
          borderRadius: 10,
          shadowColor: '#00000080',
          shadowOffset: {
            width: 0,
            height: 0,
          },
          shadowOpacity: 0.25,
          shadowRadius: 5,
          elevation: 5,
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Avatar type="user" size={50} />
          <Text
            style={{
              marginHorizontal: 20,
              fontSize: 18,
              fontWeight: 'normal',
              color: '#888888',
            }}>
            {isPaymentFromPartnerToOwner ? '◀' : '▶'}
          </Text>
          <Avatar type="partner" size={50} />
        </View>
        <View>
          <View
            style={{
              marginTop: 10,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontSize: 15,
                fontWeight: 'bold',
                color: Color.DefaultText,
              }}>
              精算額
            </Text>
            <Text
              style={{
                marginLeft: 20,
                marginBottom: 3,
                fontSize: 24,
                fontWeight: 'bold',
                color: Color.Primary,
              }}>
              {Math.abs(settleAmount).jpCurrency()}
            </Text>
          </View>
          <View style={{ marginTop: 3 }}>
            <Text
              style={{
                color: Color.DefaultText,
                fontSize: 14,
                fontWeight: 'normal',
              }}>
              {isPaymentFromPartnerToOwner
                ? `${ProfileManager.getName(
                    'partner',
                  )}から${ProfileManager.getName('user')}へ支払い`
                : `${ProfileManager.getName(
                    'user',
                  )}から${ProfileManager.getName('partner')}へ支払い`}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          marginTop: 20,
          marginHorizontal: 20,
          paddingVertical: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomColor: Color.Gray,
          borderBottomWidth: 0.5,
        }}>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          {ProfileManager.getName('user')}
        </Text>
        <View style={{ flex: 1 }} />
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          {ProfileManager.getName('partner')}
        </Text>
      </View>
      <View
        style={{
          marginHorizontal: 20,
          paddingVertical: 15,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomColor: Color.Gray,
          borderBottomWidth: 0.5,
        }}>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {userSettleAmount.jpCurrency()}
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          支払済み金額
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {partnerSettleAmount.jpCurrency()}
        </Text>
      </View>
      <View
        style={{
          marginHorizontal: 20,
          paddingVertical: 15,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomColor: Color.Gray,
          borderBottomWidth: 0.5,
        }}>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {userSplitAmount.jpCurrency()}
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
          }}>
          分担する額
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            color: Color.DefaultText,
            fontWeight: 'bold',
          }}>
          {partnerSplitAmount.jpCurrency()}
        </Text>
      </View>
      <View
        style={{
          marginTop: 30,
          marginHorizontal: 20,
          backgroundColor: '#eeeeee',
          paddingVertical: 10,
          paddingHorizontal: 10,
        }}>
        <Text
          style={{
            color: '#666666',
            fontSize: 15,
            fontWeight: 'normal',
          }}>
          分担ルール
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginHorizontal: 20,
          marginTop: 15,
        }}>
        {inputType === 'percent' && (
          <>
            <View
              style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
              <StyledTextInput
                onChangeText={onChangeOwnerSplitRatio}
                editable={!disabled}
                selectTextOnFocus
                keyboardType="number-pad"
                value={`${Math.round(ownerSplitRatio * 100)}`}
              />
              <Text style={{ marginLeft: 5, color: Color.DefaultText }}>%</Text>
            </View>
            <TouchableOpacity
              onPress={onPressChangeInputTypeButton}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center',
              }}>
              <Text style={{ color: Color.DefaultText, fontWeight: 'bold' }}>
                割合
              </Text>
              <ArrowIcon direction="down" style={{ marginLeft: 5 }} />
            </TouchableOpacity>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'flex-end',
              }}>
              <StyledTextInput
                onChangeText={onChangePartnerSplitRatio}
                editable={!disabled}
                selectTextOnFocus
                keyboardType="number-pad"
                value={`${100 - Math.round(ownerSplitRatio * 100)}`}
              />
              <Text style={{ marginLeft: 5, color: Color.DefaultText }}>%</Text>
            </View>
          </>
        )}
        {inputType === 'amount' && (
          <>
            <View
              style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
              <StyledTextInput
                onChangeText={onChangeOwnerSplitAmount}
                editable={!disabled}
                selectTextOnFocus
                keyboardType="number-pad"
                value={userSplitAmount.numberFormat()}
              />
              <Text style={{ marginLeft: 5, color: Color.DefaultText }}>
                円
              </Text>
            </View>
            <TouchableOpacity
              onPress={onPressChangeInputTypeButton}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center',
              }}>
              <Text style={{ color: Color.DefaultText, fontWeight: 'bold' }}>
                金額指定
              </Text>
              <Image source={IconImages.ArrowDown} style={{ marginLeft: 5 }} />
            </TouchableOpacity>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'flex-end',
              }}>
              <StyledTextInput
                onChangeText={onChangePartnerSplitAmount}
                editable={!disabled}
                selectTextOnFocus
                keyboardType="number-pad"
                value={partnerSplitAmount.numberFormat()}
              />
              <Text style={{ marginLeft: 5, color: Color.DefaultText }}>
                円
              </Text>
            </View>
          </>
        )}
      </View>
      <View
        style={{
          marginHorizontal: 20,
          marginTop: 20,
          alignItems: 'flex-end',
        }}>
        <TouchableOpacity
          onPress={() => {
            NavigationService.navigate('SettingPayoff')
          }}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Text
            style={{ fontSize: 12, fontWeight: 'normal', color: '#888888' }}>
            分担ルールの設定
          </Text>
          <ArrowIcon size={12} style={{ marginLeft: 2 }} />
        </TouchableOpacity>
      </View>
      {displaySettleType === 'notSettled' && (
        <View style={{ marginHorizontal: 20, marginTop: 30 }}>
          <AppButton title="精算する" onPress={onPressSettleButton} />
        </View>
      )}
      <SettledTransactionDialog />
    </RootContainer>
  )
}

export default PayoffContentsScreen
