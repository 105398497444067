import { UserAccountType } from '@interfaces/Account'
import { GoalCategory, GoalEditorProps, GoalEntity } from '@interfaces/Goal'

export const initialGoalEditorProps: GoalEditorProps = {
  goalId: undefined,
  goalContentId: undefined,
  name: '',
  goalAmount: 0,
  startDate: '',
  endDate: '',
  imgUrl: undefined,
  lifeplanEventId: undefined,
  saveAmountType: 'auto',
  share: false,
  complete: undefined,
  userGoalSettings: {
    goalSettingId: undefined,
    userId: undefined,
    atUserBankAccountId: undefined,
    atUserStockAccountId: undefined,
    walletId: undefined,
    monthlyAmount: 0,
  },
  partnerGoalSettings: {
    goalSettingId: undefined,
    userId: undefined,
    atUserBankAccountId: undefined,
    atUserStockAccountId: undefined,
    walletId: undefined,
    monthlyAmount: 0,
  },
}

export interface GetGoalProps {
  goalId: number
}

export interface AddGoalAmountProps {
  goalId: number
  addAmount: number
}

export interface AddGoalAmountResponse {}

export interface DeleteGoalProps {
  goalId: number
}

export interface GoalMonthlyProgressStatus {
  progress: number
  icon: 'best' | 'normal' | 'bad'
}

export interface GoalAllProgressStatus {
  progress: number
}

export interface GoalSettings {
  goalSettingId: number
  userId: number
  atUserBankAccountId: number | null
  walletId: number | null
  monthlyAmount: number
  firstAmount: number
}

export type GetGoalsProps = {
  share?: boolean
}

export type GetGoalsResponse = {
  // errors: []
  goals: GoalEntity[]
}

export interface CurrentAmounts {
  monthlyAmount: number
  firstAmount: number
  addAmount: number
  minusAddAmount: number
  currentAmount: number
}

export interface GoalDetail {
  goals: GoalEntity
  goalSettings: GoalSettings[]
  ownerCurrentAmount: CurrentAmounts
  partnerCurrentAmount: CurrentAmounts
}

export interface GetGoalResponse {
  goal: GoalEntity
}

export type CreateGoalResponse = GetGoalResponse

export type GetMonthlyReportProps = {
  userAccountType: UserAccountType
  year?: number
  month?: number
  term: number | 'all' | 'future' // termに指定した月数分遡ります
  exclusionCompleted?: boolean // false = 完了済み目標もレスポンスに入る
}

export type GetMonthlyReportGoalItem = {
  id: number
  name: string
  goalAmount: number
  currentAmount: number
  imgUrl: string
  progress: number | null // 未使用
  icon: 'best' | 'normal' | 'bad'
  completedAt?: boolean
}

export type MonthlyReportGoal = {
  year: number
  month: number
  date: string
  totalCurrentAmount: number
  goals: GetMonthlyReportGoalItem[]
}

export type GetMonthlyReportResponse = {
  goal: MonthlyReportGoal[]
}

export type GetGoalCategoriesProps = {
  share: boolean
}

export type GetGoalCategoriesResponse = {
  goalCategories: GoalCategory[]
}
