import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import Avatar from '@components/Avatar'
import BlurView from '@components/BlurView'
import CheckBox from '@components/CheckBox'
import CommonHeader from '@components/CommonHeader'
import { QuestionButton } from '@components/QuestionButton'
import { FinanceType, FinancialAccount } from '@interfaces/Financial'
import { GoalEditorProps } from '@interfaces/Goal'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { GoalManager } from '@lib/api/Goal'
import { selectGoalEditor } from '@lib/api/Goal/slice'
import { WalletEntity } from '@lib/api/Wallet'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  ListRenderItem,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import FastImage from 'react-native-fast-image'
import { connect, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import GoalHelpModal from './GoalHelpModal'

export type SelectAccountScreenProps = {
  editor: GoalEditorProps
  banks?: FinancialAccount[]
  wallets?: WalletEntity[]
  stocks?: FinancialAccount[]
}

type ListItem = {
  type: FinanceType
  finance: FinanceType
  accountId: number
  name: string
  amount: number
} | null

const SelectAccountScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SelectAccount'>
> = () => {
  const isFocused = useIsFocused()

  const editor = useSelector(selectGoalEditor)

  const [userBanks, setUserBanks] = useState<FinancialAccount[]>()
  const [userStocks, setUserStocks] = useState<FinancialAccount[]>()
  const [userWallets, setUserWallets] = useState<WalletEntity[]>()
  const [familyBanks, setFamilyBanks] = useState<FinancialAccount[]>()
  const [famillyWallets, setFamillyWallets] = useState<WalletEntity[]>()
  const [familyStocks, setFamilyStocks] = useState<FinancialAccount[]>()
  const [planError, setPlanError] = useState(false)
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        setUserBanks(await FinancialManager.getBankAccounts('user'))
        setFamilyBanks(await FinancialManager.getBankAccounts('family'))
        setUserStocks(await FinancialManager.getStockAccounts('user'))
        setFamilyStocks(await FinancialManager.getStockAccounts('family'))
        setUserWallets(
          await FinancialManager.getWallets({
            userAccountType: 'user',
            sort: false,
          }),
        )
        setFamillyWallets(
          await FinancialManager.getWallets({
            userAccountType: 'family',
            sort: false,
          }),
        )
      } catch (error) {
        CommonDialog.showError({
          error,
          onPress: () => {
            NavigationService.goBack()
          },
        })
      }
    }
    fetch()
  }, [])

  const onPressItem = useCallback(
    (item: ListItem) => {
      if (!editor.userGoalSettings) return

      if (item === null) {
        GoalManager.updateEditor({
          ...editor,
          userGoalSettings: {
            ...editor.userGoalSettings,
            atUserBankAccountId: undefined,
            walletId: undefined,
            atUserStockAccountId: undefined,
          },
        })
        return
      }

      if (
        item.finance === 'bank' &&
        editor.userGoalSettings?.atUserBankAccountId !== item.accountId
      ) {
        GoalManager.updateEditor({
          ...editor,
          userGoalSettings: {
            ...editor.userGoalSettings,
            atUserBankAccountId: item.accountId,
            atUserStockAccountId: undefined,
            walletId: undefined,
          },
        })
      } else if (
        item.finance === 'stock' &&
        editor.userGoalSettings?.atUserStockAccountId !== item.accountId
      ) {
        GoalManager.updateEditor({
          ...editor,
          userGoalSettings: {
            ...editor.userGoalSettings,
            atUserBankAccountId: undefined,
            atUserStockAccountId: item.accountId,
            walletId: undefined,
          },
        })
      } else if (
        item.finance === 'wallet' &&
        editor.userGoalSettings.walletId !== item.accountId
      ) {
        GoalManager.updateEditor({
          ...editor,
          userGoalSettings: {
            ...editor.userGoalSettings,
            atUserBankAccountId: undefined,
            atUserStockAccountId: undefined,
            walletId: item.accountId,
          },
        })
      } else {
        GoalManager.updateEditor({
          ...editor,
          userGoalSettings: {
            ...editor.userGoalSettings,
            atUserBankAccountId: undefined,
            atUserStockAccountId: undefined,
            walletId: undefined,
          },
        })
      }
    },
    [editor],
  )

  const createGoal = useCallback(async () => {
    let apiError: APIError | undefined

    try {
      showAppButtonIndicator('submit')

      await GoalManager.createGoal(editor)

      NavigationService.navigate('SavingCompletion')
    } catch (error) {
      if (error instanceof APIError && error.response?.errorCode === '007001') {
        apiError = error
      } else {
        CommonDialog.showError({ error })
      }
    } finally {
      hideAppButtonIndicator('submit')
    }

    if (apiError) {
      setPlanError(true)
      TrackingUtils.repro.track('【Screen】upper limit_finance', 'Screen')
    }
  }, [editor])

  const updateGoal = useCallback(async () => {
    try {
      showAppButtonIndicator('submit')
      await GoalManager.updateGoal(editor)
      NavigationService.navigate('GoalDetail')
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('submit')
    }
  }, [editor])

  const onSubmit = useCallback(async () => {
    if (editor.goalId) {
      updateGoal()
    } else {
      createGoal()
    }
  }, [createGoal, editor.goalId, updateGoal])

  const renderItem: ListRenderItem<ListItem> = useCallback(
    ({ item }) => {
      if (!editor.userGoalSettings) return null

      const { atUserBankAccountId, atUserStockAccountId, walletId } =
        editor.userGoalSettings

      if (item === null) {
        return (
          <TouchableOpacity
            onPress={() => onPressItem(item)}
            style={{ paddingHorizontal: 20, height: 66 }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              <CheckBox
                isOn={
                  !atUserBankAccountId && !atUserStockAccountId && !walletId
                }
              />
              <Text
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                  fontWeight: 'normal',
                  color: Color.DefaultText,
                }}>
                口座を紐付けない
              </Text>
            </View>
          </TouchableOpacity>
        )
      }

      return (
        <TouchableOpacity onPress={() => onPressItem(item)}>
          <View style={{ flexDirection: 'column', paddingHorizontal: 20 }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              <Image
                style={{ height: 24, width: 24, marginRight: 12 }}
                source={
                  [
                    atUserBankAccountId,
                    atUserStockAccountId,
                    walletId,
                  ].includes(item.accountId)
                    ? require('@images/icons/icon-check-green.png')
                    : require('@images/icons/icon-oval.png')
                }
                resizeMode="contain"
              />
              <Image
                style={{ height: 25, width: 25, marginRight: 12 }}
                source={
                  item.type === 'bank'
                    ? require('@images/icons/icon-bank.png')
                    : item.type === 'stock'
                    ? require('@images/icons/icon-bond.png')
                    : require('@images/icons/icon-wallet.png')
                }
                resizeMode="contain"
              />
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: 'normal',
                  color: Color.DefaultText,
                }}>
                {item.name}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 15,
              }}>
              <Text
                style={{
                  flex: 1,
                  textAlign: 'right',
                  fontSize: 15,
                  fontWeight: 'normal',
                  color: Color.DefaultText,
                }}>
                {item.type === 'stock' ? '評価額' : '残高'}
              </Text>
              <Text
                style={{
                  flex: 1,
                  textAlign: 'right',
                  fontSize: 15,
                  fontWeight: 'normal',
                  color: Color.DefaultText,
                }}>
                {item.amount.jpCurrency()}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      )
    },
    [editor.userGoalSettings, onPressItem],
  )

  const listItems = useMemo(() => {
    if (userBanks === undefined || familyBanks === undefined) return undefined
    if (userStocks === undefined || familyStocks === undefined) return undefined
    if (userWallets === undefined || famillyWallets === undefined)
      return undefined

    const items: ListItem[] = []
    userBanks.forEach((v) =>
      items.push({
        type: v.type,
        finance: v.finance,
        accountId: v.accountId,
        name: v.name,
        amount: v.amount || 0,
      }),
    )
    familyBanks.forEach((v) =>
      items.push({
        type: v.type,
        finance: v.finance,
        accountId: v.accountId,
        name: v.name,
        amount: v.amount || 0,
      }),
    )
    userStocks.forEach((v) =>
      items.push({
        type: v.type,
        finance: v.finance,
        accountId: v.accountId,
        name: v.name,
        amount: v.balance ?? 0,
      }),
    )
    familyStocks.forEach((v) =>
      items.push({
        type: v.type,
        finance: v.finance,
        accountId: v.accountId,
        name: v.name,
        amount: v.balance ?? 0,
      }),
    )
    userWallets
      .filter((v) => v.type !== 'bank')
      .forEach((v) =>
        items.push({
          type: v.type,
          finance: v.finance,
          accountId: v.id,
          name: v.name,
          amount: v.amount || 0,
        }),
      )
    famillyWallets
      .filter((v) => v.type !== 'bank')
      .forEach((v) =>
        items.push({
          type: v.type,
          finance: v.finance,
          accountId: v.id,
          name: v.name,
          amount: v.amount || 0,
        }),
      )
    const sortedItems = items.sort((a, b) => {
      if (!a || !b) return 0
      const n1 = a.type === 'bank' ? 0 : a.type === 'stock' ? 1 : 2
      const n2 = b.type === 'bank' ? 0 : b.type === 'stock' ? 1 : 2
      return n1 - n2
    })

    // 「口座を紐付けない」を先頭に追加
    sortedItems.unshift(null)

    return sortedItems
  }, [
    famillyWallets,
    familyBanks,
    familyStocks,
    userBanks,
    userStocks,
    userWallets,
  ])

  const ImageComponent = Platform.OS === 'web' ? ImageBackground : FastImage

  return (
    <StyledRootView>
      <CommonHeader title={editor.goalId ? '口座を選択' : '口座を選択'} />
      <View style={{ flex: 1 }}>
        <ImageComponent
          style={styles.topImage}
          source={{ uri: editor.imgUrl }}
          resizeMode="cover"
        />
        <View
          style={{
            flexDirection: 'row',
            marginTop: 25,
            marginHorizontal: 25,
            marginBottom: 10,
          }}>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              color: Color.DefaultText,
              paddingRight: 3,
            }}>
            貯金に紐付ける口座を選択してください
          </Text>
          <QuestionButton
            questionIconProps={{ size: 16 }}
            onPress={() => setIsHelpModalVisible(true)}
          />
        </View>
        <FlatList
          data={listItems ?? []}
          renderItem={renderItem}
          keyExtractor={(item) => item?.accountId.toString() ?? 'null'}
          ListHeaderComponent={() => (
            <View style={styles.accountItem}>
              <View style={styles.accountItemInfo}>
                <Avatar />
                <Text style={styles.accountItemInfoName}>
                  {ProfileManager.getName('user')}
                </Text>
              </View>
            </View>
          )}
          ListFooterComponent={
            listItems ? null : <ActivityIndicator color="gray" />
          }
        />
        <StyledAppButtonView
          style={editor.goalId ? undefined : { paddingBottom: 0 }}>
          <AppButton
            title={editor.goalId ? '編集内容を保存する' : '次へ'}
            textStyle={{
              fontSize: 15,
              fontWeight: 'bold',
            }}
            onPress={onSubmit}
            loadingKey="submit"
          />
        </StyledAppButtonView>
        {planError && (
          <BlurView disableBlur={Platform.OS === 'android' && !isFocused}>
            <StyledModalAbsoluteView>
              <StyledPremiumPlanText>
                口座連携の上限数5個に達しました。{'\n'}
                プレミアムプランに加入すると{'\n'}
                口座連携を制限なく行えます。
              </StyledPremiumPlanText>
              <AppButton
                title="詳しく見る"
                onPress={() => {
                  TrackingUtils.repro.track('【Tap】upper limit_finance', 'Tap')
                  navigatePremiumPlanLP({ planCode: '001' })
                }}
              />
            </StyledModalAbsoluteView>
          </BlurView>
        )}
      </View>
      <GoalHelpModal
        isVisible={isHelpModalVisible}
        onClose={() => setIsHelpModalVisible(false)}
      />
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  flex: 1,
})

const StyledModalAbsoluteView = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  paddingHorizontal: 20,
  justifyContent: 'center',
})

const StyledPremiumPlanText = styled.Text({
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 'bold',
  color: 'white',
  marginBottom: 20,
})

const styles = StyleSheet.create({
  topImage: {
    height: 120,
  },
  accountItem: {
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 20,
  },
  accountItemInfo: {
    flexDirection: 'row',
  },
  accountItemInfoAvatar: {
    height: 80,
    width: 80,
    borderRadius: 40,
  },
  accountItemInfoName: {
    fontSize: 15,
    fontWeight: 'normal',
    marginLeft: 10,
    marginTop: 24,
    color: Color.DefaultText,
  },
})

const StyledAppButtonView = styled.View({
  paddingTop: 10,
  paddingBottom: 25,
  paddingLeft: 20,
  paddingRight: 20,
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => ({
  editor: state.goal.editor,
  banks: state.financial.assets.banks,
  wallets: state.financial.wallets,
  stocks: state.financial.investments,
})

export default connect(mapStateToProps)(SelectAccountScreen)
