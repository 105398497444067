import CommonHeader from '@components/CommonHeader'
import FinancialAccountIcon from '@components/FinancialAccountIcon'
import TintedImage from '@components/TintedImage'
import { FinanceType, FinancialAccount } from '@interfaces/Financial'
import {
  FinanceAccountSort,
  UpdateFinanceAccountSortProps,
} from '@lib/api/Financial'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import RNProgressHud from '@lib/ProgressHUD'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useState } from 'react'
import DragListView from 'react-drag-listview'
import { View } from 'react-native'
import styled from 'styled-components/native'

type Account = {
  finance: FinanceType
  type: FinanceType
  accountId: number
  name: string
}

const FinancialAccountName: { [key in FinanceType]: string } = {
  bank: '銀行',
  card: 'カード',
  emoney: '電子マネー',
  stock: '証券',
  wallet: '財布（現金管理）',
} as const

const SortFinanceAccountScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SortFinanceAccount'>
> = () => {
  const [financialCategories, setFinancialCategories] = useState<FinanceType[]>(
    [],
  )

  const [sections, setSections] = useState<{
    [key in FinanceType]: Account[]
  }>({ bank: [], card: [], emoney: [], stock: [], wallet: [] })

  const getSortSettings = useCallback(async () => {
    try {
      RNProgressHud.show()

      const convert = (financialAccounts: FinancialAccount[]): Account[] =>
        financialAccounts.map((v) => ({
          finance: v.finance,
          type: v.type,
          accountId: v.accountId,
          name: v.name,
        }))

      await Promise.all([
        (async () => {
          const settings = await FinancialManager.getFinanceSort()
          const sortedSettings = settings
            .sort((a, b) => a.sort - b.sort)
            .map((v) => v.type)
          setFinancialCategories(sortedSettings)
        })(),
        (async () => {
          const bank: Account[] = convert(
            await FinancialManager.fetchBankAccounts(),
          )
          setSections((prev) => ({ ...prev, bank }))
        })(),
        (async () => {
          const card: Account[] = convert(
            await FinancialManager.fetchCardAccounts(),
          )
          setSections((prev) => ({ ...prev, card }))
        })(),
        (async () => {
          const emoney: Account[] = convert(
            await FinancialManager.fetchEmoneyAccounts(),
          )
          setSections((prev) => ({ ...prev, emoney }))
        })(),
        (async () => {
          const stock: Account[] = convert(
            await FinancialManager.fetchStockAccounts(),
          )
          setSections((prev) => ({ ...prev, stock }))
        })(),
        (async () => {
          const wallet: Account[] = (await FinancialManager.fetchWallets()).map(
            (v) => ({
              finance: v.finance,
              type: v.type,
              accountId: v.id,
              name: v.name,
            }),
          )
          setSections((prev) => ({ ...prev, wallet }))
        })(),
      ])
    } catch (error) {
      CommonDialog.showError({ error })
      NavigationService.goBack()
    } finally {
      RNProgressHud.dismiss()
    }
  }, [])

  const updateSortSettings = useCallback(async () => {
    const finances: FinanceAccountSort[] = []

    financialCategories.forEach((category) => {
      const accounts = sections[category]

      accounts.forEach((account, index) => {
        finances.push({
          finance: account.finance,
          type: account.type,
          accountId: account.accountId,
          sort: 1 + index,
        })
      })
    })

    const props: UpdateFinanceAccountSortProps = {
      userAccountType: store.getState().account.accountMode,
      finances,
    }

    await FinancialManager.updateFinanceAccountSort(props)
  }, [financialCategories, sections])

  const onPressFinishButton = useCallback(async () => {
    try {
      RNProgressHud.show()
      await updateSortSettings()
      NavigationService.goBack()
    } catch (error) {
      CommonDialog.showError({ error })
      NavigationService.goBack()
    } finally {
      RNProgressHud.dismiss()
    }
  }, [updateSortSettings])

  const renderRightButton = useCallback(
    () => (
      <StyledRightButton onPress={onPressFinishButton}>
        <StyledRightButtonText>完了</StyledRightButtonText>
      </StyledRightButton>
    ),
    [onPressFinishButton],
  )

  useEffect(() => {
    getSortSettings()
  }, [getSortSettings])

  return (
    <StyledRootView>
      <CommonHeader
        title="口座表示の編集"
        leftButtonType="hidden"
        renderRightButton={renderRightButton}
      />
      <StyledHeaderText>
        右のつまみを長押しして並べ替えができます
      </StyledHeaderText>
      <StyledScrollView>
        {financialCategories &&
          financialCategories.map((category) => (
            <View key={category}>
              <SectionHeaderView type={category} />
              <DragListView
                onDragEnd={(fromIndex, toIndex) => {
                  const data = [...sections[category]]
                  const item = data.splice(fromIndex, 1)[0]
                  data.splice(toIndex, 0, item)
                  setSections((prev) => ({ ...prev, [data[0].type]: data }))
                }}
                nodeSelector=".draggable"
                handleSelector={
                  sections[category].length > 1 ? '.draggable' : undefined
                }>
                {sections[category].map((account) => (
                  <div key={account.accountId} className="draggable">
                    <ListItem
                      account={account}
                      disabled={sections[category].length === 1}
                    />
                  </div>
                ))}
              </DragListView>
            </View>
          ))}
      </StyledScrollView>
    </StyledRootView>
  )
}

type SectionHeaderProps = {
  type: FinanceType
}

const SectionHeaderView = ({ type }: SectionHeaderProps) => {
  return (
    <StyledSectionHeaderView>
      <FinancialAccountIcon financialAccountType={type} />
      <StyledHeaderItemText>{FinancialAccountName[type]}</StyledHeaderItemText>
    </StyledSectionHeaderView>
  )
}

type ListItemProps = {
  account: Account
  disabled?: boolean
}

const ListItem = ({ account, disabled }: ListItemProps) => {
  return (
    <StyledListItemView>
      <StyledListItemText>{account.name}</StyledListItemText>
      <StyledListItemDragButton>
        <StyledListItemDragImage disabled={disabled} />
      </StyledListItemDragButton>
    </StyledListItemView>
  )
}

const StyledRootView = styled.View({
  flex: 1,
})

const StyledScrollView = styled.ScrollView({
  flex: 1,
})

const StyledHeaderText = styled.Text({
  backgroundColor: Color.LightGray,
  padding: 15,
  color: Color.DefaultText,
  fontSize: 12,
  fontWeight: 'normal',
})

const StyledRightButton = styled.TouchableOpacity({
  paddingHorizontal: 15,
  justifyContent: 'center',
  height: 40,
})

const StyledRightButtonText = styled.Text({
  color: 'white',
  fontSize: 13,
  fontWeight: 'normal',
})

const StyledSectionHeaderView = styled.View({
  paddingLeft: 16,
  backgroundColor: Color.White,
  height: 44,
  flexDirection: 'row',
  alignItems: 'center',
  borderTopWidth: 1,
  borderTopColor: Color.LightGray,
})

const StyledListItemView = styled.View({
  marginLeft: 40,
  backgroundColor: Color.White,
  height: 44,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTopWidth: 1,
  borderTopColor: Color.LightGray,
})

const StyledListItemDragButton = styled.View.attrs({
  pointerEvents: 'none',
})({
  paddingLeft: 30,
  paddingVertical: 14,
  paddingRight: 20,
})

const StyledHeaderItemText = styled.Text({
  flex: 1,
  marginLeft: 8,
  fontSize: 13,
  fontWeight: 'bold',
  color: '#666666',
})

const StyledListItemText = styled.Text({
  flex: 1,
  marginLeft: 8,
  fontSize: 15,
  fontWeight: 'normal',
  color: '#3a3a3a',
})

const StyledListItemDragImage = styled(TintedImage).attrs({
  resizeMode: 'contain',
  source: require('@images/common-header/common-header-menu-button.png'),
})<{ disabled?: boolean }>(({ disabled }) => ({
  tintColor: Color.Gray,
  width: 16,
  height: 16,
  opacity: disabled ? 0.3 : 1,
}))

export default SortFinanceAccountScreen
