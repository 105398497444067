import TintedImage from '@components/TintedImage'
import * as Buttons from '@lib/Buttons'
import Color from '@lib/Color'
import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

const StyledTouchableOpacity = styled.TouchableOpacity`
  height: 44px;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 20px;
`

const Icon = styled.Image`
  width: 20px;
  height: 2px;
  tint-color: ${Color.Primary};
`

const StyledText = styled.Text`
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${Color.Primary};
`

const AddTransactionButton = ({ onPress }: TouchableOpacityProps) => (
  <StyledTouchableOpacity onPress={onPress}>
    <TintedImage
      source={Buttons.Add}
      style={{
        tintColor: Color.Primary,
        width: 20,
        height: 20,
      }}
    />
    <StyledText>明細を追加する</StyledText>
  </StyledTouchableOpacity>
)

export default AddTransactionButton
