import AppButton from '@components/AppButton'
import CategoryImage from '@components/CategoryImage'
import RightArrowButton from '@components/molecules/RightArrowButton'
import { AccountState } from '@lib/AccountManager/types'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import DateUtilsV1 from '@lib/DateUtilsV1'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import GlobalFont from '@lib/GlobalFont'
import NavigationService, {
  navigateToNavigatableScreen,
} from '@lib/NavigationService'
import { ProfileState } from '@lib/ProfileManager/types'
import TrackingUtils from '@lib/TrackingUtils'
import store from '@redux/store'
import { TransactionDetailScreenNavigationParams } from '@Screen/Main/TransactionDetailScreen'
import React from 'react'
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { PieChart } from 'react-native-svg-charts'
import { connect } from 'react-redux'

interface StateProps {
  financial: FinancialState
  profile: ProfileState
  account: AccountState
}

class HomeExpensesBalance extends React.Component<StateProps> {
  componentDidMount() {
    if (this.props.account.accountMode === 'family') {
      TrackingUtils.repro.track('【Screen】Family_PL_spending', 'Screen')
    } else {
      TrackingUtils.repro.track('【Screen】Personal_PL_spending', 'Screen')
    }
  }

  private renderItemMonthlyBalanceCategory = ({
    item,
  }: {
    item: { id: string | number; name: string }
  }) => {
    return (
      <View style={styles.itemMonthlyBalanceCategory}>
        <CategoryImage categoryId={item.id} />
        <Text style={styles.titleMonthlyBalanceCategory}>{item.name}</Text>
      </View>
    )
  }

  // private onChangeFamilyShareMode = (_isFamilyShareMode: boolean) => {
  //   TrackingUtils.repro.track('【Tap】Personal_Family mode on/off', 'Tap')
  // }

  private renderMonthlyBalance = () => {
    const { accountMode } = this.props.account

    const thisMonth = DateUtilsV1.monthKey()
    const thisMonthsPL = this.props.financial[accountMode].monthsPL[thisMonth]
    if (!thisMonthsPL) return null
    const { incomeAmount, expenseAmount } = thisMonthsPL

    const amounts: number[] = []
    const colors: string[] = []
    const categories: {
      id: string | number
      name: string
    }[] = []

    thisMonthsPL.expenseList.forEach((expense) => {
      amounts.push(-expense.amount)
      colors.push(
        CategoryManager.getCategoryIconInfo(expense.categoryId)?.color || '',
      )
      categories.push({
        name:
          CategoryManager.getCategoryIconInfo(expense.categoryId)
            ?.categoryName || '',
        id: expense.categoryId,
      })
    })

    const pieData = amounts.map((value, index) => ({
      value,
      svg: {
        fill: colors[index],
      },
      key: String(index),
    }))

    // const { accountMode } = this.props.account

    return (
      <View style={{ minHeight: 230 }}>
        <TouchableOpacity
          style={styles.monthlyBalance}
          onPress={() =>
            navigateToNavigatableScreen([
              { name: 'HomeExpensesCategories' },
              {
                name: 'HouseholdAccountsTab',
                params: { tab: 'HomeExpensesCategories' },
              },
            ])
          }>
          <View style={styles.wrapContentMonthlyBalance}>
            <View style={{ width: wp(45) }}>
              <PieChart
                style={{ height: wp('27%') }}
                data={pieData}
                outerRadius="98%"
                innerRadius="75%"
                padAngle={0.04}
              />
            </View>
            <View style={styles.viewContentMonthlyBalanceRight}>
              <View
                style={[
                  styles.wrapMoneyOfChart,
                  { marginBottom: 10, marginRight: 20 },
                ]}>
                <Text style={styles.titleMoneyOfChart}>収入</Text>
                <Text
                  style={[styles.moneyOfChart, { color: Color.NumberBlack }]}>
                  {incomeAmount.jpCurrency()}
                </Text>
              </View>
              <View
                style={[
                  styles.wrapMoneyOfChart,
                  { marginBottom: 10, marginRight: 20 },
                ]}>
                <Text style={styles.titleMoneyOfChart}>支出</Text>
                <Text
                  style={[styles.moneyOfChart, { color: Color.NumberBlack }]}>
                  {expenseAmount.jpCurrency()}
                </Text>
              </View>
              <View style={[styles.wrapMoneyOfChart, { marginRight: 20 }]}>
                <Text style={styles.titleMoneyOfChart}>収支</Text>
                <Text
                  style={[
                    styles.moneyOfChart,
                    {
                      color: Color.number(incomeAmount + expenseAmount),
                    },
                  ]}>
                  {(incomeAmount + expenseAmount).jpCurrency()}
                </Text>
              </View>
              <View style={styles.tapDetailView}>
                <RightArrowButton disabled>詳細を見る</RightArrowButton>
              </View>
            </View>
          </View>
        </TouchableOpacity>
        <FlatList
          style={styles.monthlyBalanceMenu}
          ListHeaderComponent={() => <View style={{ width: 20 }} />}
          ListFooterComponent={() => <View style={{ width: 5 }} />}
          horizontal={true}
          data={categories}
          renderItem={this.renderItemMonthlyBalanceCategory}
          keyExtractor={(item) => `${item.id}`}
        />
      </View>
    )
  }

  private renderMonthlyBalanceNoData = () => (
    <View style={styles.monthlyBalanceNoData}>
      {this.props.account.accountMode === 'user' ? (
        <Text
          style={[styles.titleMonthlyBalanceNoData, { fontWeight: 'bold' }]}>
          今月の家計簿
        </Text>
      ) : (
        <Text style={styles.titleMonthlyBalanceNoData}>今月の収支</Text>
      )}
      <View style={styles.contentMonthlyBalanceNoData}>
        <Image
          resizeMode="contain"
          source={require('@images/chartCircle/items-chart-circle.png')}
          style={styles.IconChartCircle}
        />
        <View style={styles.ViewMonthlyBalanceNoDataRight}>
          <Text style={styles.contentMonthlyBalanceNoDataRight}>
            収入・支出の入力や、金融機関の連携で家計簿がつけられます。
          </Text>
          <View style={styles.wrapButtonContentMonthlyBalanceNoDataRight}>
            <AppButton
              type="secondary-min"
              title="入力する"
              onPress={() =>
                NavigationService.navigate('CreateTransactionDetail', {
                  financialAccountType: 'manually_created',
                } as TransactionDetailScreenNavigationParams)
              }
            />
          </View>
        </View>
      </View>
    </View>
  )

  render() {
    const { accountMode } = this.props.account
    const isDisplayFinancialAccounts =
      FinancialManager.getFinancialAccountCount() > 0 ||
      this.props.financial.manuallyCreatedTransactionsCount > 0 ||
      (accountMode === 'family' &&
        this.props.profile.userStatus?.groupTransactionShared)
    return isDisplayFinancialAccounts
      ? this.renderMonthlyBalance()
      : this.renderMonthlyBalanceNoData()
  }
}

const styles = StyleSheet.create({
  thisMonthsBalanceView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingHorizontal: 20,
  },
  tapDetailView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 15,
    paddingRight: 18,
    alignItems: 'center',
  },
  tapDetailText: {
    color: '#888888',
    fontSize: 13,
    fontWeight: 'normal',
  },
  titleTotalAssets: {
    color: '#555555',
    fontSize: 15,
    fontWeight: 'bold',
  },
  monthlyBalance: {
    backgroundColor: Color.White,
    marginTop: 15,
    flex: 1,
  },
  balanceTime: {
    color: Color.Gray,
    fontSize: 15,
    fontWeight: 'normal',
    marginLeft: 8,
  },
  wrapContentMonthlyBalance: {
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 15,
  },
  viewContentMonthlyBalanceRight: {
    flex: 1,
    justifyContent: 'space-between',
  },
  moneyOfChart: {
    fontSize: Math.min(26, wp(6)),
    fontWeight: 'normal',
    fontFamily: GlobalFont.Family.Number,
  },
  wrapMoneyOfChart: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleMoneyOfChart: {
    fontSize: 13,
    fontWeight: 'normal',
    color: '#666666',
  },
  titleMonthlyBalanceCategory: {
    color: Color.DefaultText,
    paddingLeft: 5,
  },
  itemMonthlyBalanceCategory: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 12,
  },
  monthlyBalanceMenu: {
    height: 50,
    maxHeight: 50,
    backgroundColor: Color.White,
    borderTopColor: Color.LightGray,
    borderTopWidth: 2,
  },
  monthlyBalanceNoData: {
    width: '100%',
    height: 200,
    paddingTop: 20,
    paddingHorizontal: 20,
    backgroundColor: Color.White,
    marginTop: 15,
  },
  titleMonthlyBalanceNoData: {
    color: '#555555',
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 21,
  },
  contentMonthlyBalanceNoData: {
    width: '100%',
    flexDirection: 'row',
  },
  IconChartCircle: {
    width: 110,
    height: 110,
    flex: 1,
  },
  contentMonthlyBalanceNoDataRight: {
    color: '#8e8e8e',
    fontSize: 13,
    fontWeight: 'normal',
  },
  ViewMonthlyBalanceNoDataRight: {
    flex: 1.3,
  },
  wrapButtonContentMonthlyBalanceNoDataRight: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  wrapMonthlyBalanceViewLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
    profile: state.profile,
    account: state.account,
  }
}

export default connect(mapStateToProps)(HomeExpensesBalance)
