import {
  selectUserAccountType,
  setIsProcessingAssets,
  setAssets as setReduxAssets,
} from '@Screen/Main/MonthlyReportScreen/slice'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import AbsolutePositionView from '@components/AbsolutePositionView'
import AppButton from '@components/AppButton'
import FullWidthImage from '@components/FullWidthImage'
import { FinancialAccountType } from '@interfaces/Financial'
import { accountModeSelector } from '@lib/AccountManager/actions'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { StartDateSettingOption, getYearMonth } from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import FinancialManager from '@lib/FinancialManager'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { MonthlyReportBs, MonthlyReportBsAccount } from '@lib/api/Financial'
import { useIsFocused } from '@react-navigation/core'
import * as scale from 'd3-scale'
import * as shape from 'd3-shape'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActivityIndicator,
  GestureResponderEvent,
  Image,
  LayoutChangeEvent,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import {
  Grid,
  LineChart,
  StackedAreaChart,
  XAxis,
  YAxis,
} from 'react-native-svg-charts'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsTappingChart, setIsTappingChart } from './slice'
import {
  PremiumContainerView,
  PremiumText,
  PremiumTitleText,
  StyledPlanErrorView,
} from './styles'

type Item =
  | (() => JSX.Element)
  | {
      financialAccountType: FinancialAccountType
      name: string
      amount: number
      ratio: number
      childItems: MonthlyReportBsAccount[]
      monthOver?: number
      monthlyReport?: boolean
    }

//   StackScreenProps<RootStackParamList, 'AssetTransitionScreen'>

type AssetTransitionScreenProps = {
  monthlyReport?: boolean
  yearMonth?: string
}

const AssetTransitionScreen: React.FC<AssetTransitionScreenProps> = ({
  monthlyReport,
  yearMonth,
}) => {
  const dispatch = useDispatch()
  const isFocused = useIsFocused()

  const sectionList = React.createRef<SectionList<Item>>()

  const commonUserAccountType = useSelector(accountModeSelector)
  const reportUserAccountType = useSelector(selectUserAccountType)
  const userAccountType = monthlyReport
    ? reportUserAccountType
    : commonUserAccountType
  const isTappingChart = useSelector(selectIsTappingChart)

  const [refreshing, setRefreshing] = useState(false)
  const [planError, setPlanError] = useState(false)
  const [isDisplaySubCategories, setIsDisplaySubCategories] = useState(
    monthlyReport
      ? false
      : SessionManager.getDisplayHomeExpensesSubCategories(),
  )
  const [assets, setAssets] = useState<MonthlyReportBs[]>([])
  const [dayAssets, setDayAssets] = useState<MonthlyReportBs[]>([])
  const [period, setPeriod] = useState<3 | 6 | 12 | 'all'>(6)
  const [isLoading, setIsLoading] = useState(false)
  const [listHeaderHeight, setListHeaderHeight] = useState(0)

  type ChartData = {
    date: string
    total: number
    bank: number
    stock: number
    wallet: number
  }

  const chartData: ChartData[] = useMemo(() => {
    return assets
      .map((v) => ({
        date: v.date,
        total: v.totalBalance,
        bank: v.bankBalance > 0 ? v.bankBalance : 0,
        stock: v.stockBalance > 0 ? v.stockBalance : 0,
        wallet:
          v.walletBalance + v.emoneyBalance > 0
            ? v.walletBalance + v.emoneyBalance
            : 0,
      }))
      .reverse()
  }, [assets])

  const getBs = useCallback(async () => {
    dispatch(setIsProcessingAssets(true))
    try {
      if (!userAccountType) return

      if (period === 'all') setIsLoading(true)

      const settings = getStartDateSettings(userAccountType)
      const selectedYearMonth = monthlyReport
        ? yearMonth
        : getYearMonth(
            moment(),
            settings.start,
            settings.startDateSettingOption || StartDateSettingOption.None,
          ).key

      if (!selectedYearMonth) return
      const date = moment(selectedYearMonth, 'YYYYMM')

      const assets = await FinancialManager.getMonthlyReportBs({
        userAccountType,
        year: date.year(),
        month: date.month() + 1,
        term: period,
      })
      setAssets(assets)

      if (!monthlyReport) {
        // 前日比
        const today = moment()
        const todayBs = await FinancialManager.getMonthlyReportBs({
          userAccountType,
          year: today.year(),
          month: today.month() + 1,
          day: today.date(),
        })

        const yesterday = moment(today).subtract(1, 'day')
        const yesterdayBs = await FinancialManager.getMonthlyReportBs({
          userAccountType,
          year: yesterday.year(),
          month: yesterday.month() + 1,
          day: yesterday.date(),
        })

        if (todayBs[0] && yesterdayBs[0]) {
          setDayAssets([todayBs[0], yesterdayBs[0]])
        }
      }

      setPlanError(false)
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.errorCode === '007004') {
          setPlanError(true)
          sectionList.current?.scrollToLocation({
            sectionIndex: 0,
            itemIndex: 0,
          })
          TrackingUtils.repro.track(
            '【Screen】upper limit_asset transition',
            'Screen',
          )

          if (monthlyReport) {
            TrackingUtils.ga.pageview({
              page: 'Upperlimit-MonthlyReport-Chart-Year-All',
              title:
                '「マンスリーレポート」の資産総額チャートで「1年」or「すべて」タップ時の上限タッチ画面（家族画面+個人画面の和）',
            })
          } else {
            TrackingUtils.ga.pageview({
              page: 'Upperlimit-Chart-Year-All',
              title:
                '資産推移タブのチャート「1年」or「すべて」タップ時の上限タッチ画面（家族画面+個人画面の和）',
            })
          }
          return
        }
      }
      CommonDialog.showError({ error, onPress: () => setPeriod(6) })
    } finally {
      if (period === 'all') setIsLoading(false)
      dispatch(setIsProcessingAssets(false))
    }
  }, [dispatch, monthlyReport, period, sectionList, userAccountType, yearMonth])

  useEffect(() => {
    if (isFocused) {
      getBs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, period, yearMonth, userAccountType])

  const setDisplaySubCategories = useCallback(
    (isDisplaySubCategories: boolean) => {
      setIsDisplaySubCategories(isDisplaySubCategories)
      SessionManager.setDisplayHomeExpensesSubCategories(isDisplaySubCategories)
    },
    [],
  )

  const onPressPeriodButton = useCallback((value: 3 | 6 | 12 | 'all') => {
    setPeriod(value)
  }, [])

  const totalAmount = useMemo(
    () =>
      monthlyReport ? assets[0]?.totalBalance : dayAssets[0]?.totalBalance,
    [assets, dayAssets, monthlyReport],
  )

  const prevMonthOver = useMemo(
    () =>
      assets[0] && assets[1]
        ? assets[0].totalBalance - assets[1].totalBalance
        : undefined,
    [assets],
  )

  const prevMonthOverBank = useMemo(
    () =>
      assets[0] && assets[1]
        ? assets[0].bankBalance - assets[1].bankBalance
        : undefined,
    [assets],
  )

  const prevMonthOverStock = useMemo(
    () =>
      assets[0] && assets[1]
        ? assets[0].stockBalance - assets[1].stockBalance
        : undefined,
    [assets],
  )

  const prevMonthOverWallet = useMemo(
    () =>
      assets[0] && assets[1]
        ? assets[0].walletBalance - assets[1].walletBalance
        : undefined,
    [assets],
  )

  const prevDayOver = useMemo(
    () =>
      dayAssets[0] && dayAssets[1]
        ? dayAssets[0].totalBalance - dayAssets[1].totalBalance
        : undefined,
    [dayAssets],
  )

  const prevDayOverBank = useMemo(
    () =>
      dayAssets[0] && dayAssets[1]
        ? dayAssets[0].bankBalance - dayAssets[1].bankBalance
        : undefined,
    [dayAssets],
  )

  const prevDayOverWallet = useMemo(
    () =>
      dayAssets[0] && dayAssets[1]
        ? dayAssets[0].walletBalance - dayAssets[1].walletBalance
        : undefined,
    [dayAssets],
  )

  useEffect(() => {
    dispatch(setReduxAssets(assets))
  }, [assets, dispatch])

  const updateListHeaderHeight = useCallback((event: LayoutChangeEvent) => {
    setListHeaderHeight(
      event.nativeEvent.layout.height + event.nativeEvent.layout.y,
    )
  }, [])

  const listHeader1 = useCallback(() => {
    if (chartData.length === 0) return <></>

    const prevOver = monthlyReport ? prevMonthOver : prevDayOver

    return (
      <View
        onLayout={updateListHeaderHeight}
        style={{
          marginTop: monthlyReport ? 0 : 30,
          marginBottom: monthlyReport ? 0 : 0,
        }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text
            style={{
              marginTop: monthlyReport ? 4 : 0,
              paddingLeft: monthlyReport ? 10 : 15,
              fontSize: 16,
              fontWeight: monthlyReport ? 'normal' : 'bold',
              color: 'rgb(58, 58, 58)',
            }}>
            資産総額
          </Text>
          <View
            style={{
              marginTop: monthlyReport ? 0 : 20,
              paddingHorizontal: 15,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}>
              {prevOver !== undefined &&
                (prevOver > 0 ? (
                  <Image
                    source={require('@images/report/up-icon.png')}
                    style={{ width: 20, height: 20, resizeMode: 'contain' }}
                  />
                ) : prevOver < 0 ? (
                  <Image
                    source={require('@images/report/down-icon.png')}
                    style={{ width: 20, height: 20, resizeMode: 'contain' }}
                  />
                ) : (
                  <Image
                    source={require('@images/report/arrow-flat.png')}
                    style={{ width: 20, height: 20, resizeMode: 'contain' }}
                  />
                ))}
              <Text
                style={{
                  marginLeft: 3,
                  fontSize: 26,
                  fontWeight: 'bold',
                  color: 'rgb(58, 58, 58)',
                }}>
                {totalAmount != undefined ? totalAmount.jpCurrency() : '-'}
              </Text>
            </View>
            <View
              style={{
                marginTop: 5,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: 'rgb(102, 102, 102)',
                }}>
                {monthlyReport ? '前月比' : '前日比'}
              </Text>
              <Text
                style={{
                  marginLeft: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: Color.number(prevOver),
                }}>
                {prevOver !== undefined
                  ? prevOver.jpCurrency({
                      showPlus: true,
                      showPlusMinus: true,
                    })
                  : '-'}
              </Text>
            </View>
          </View>
        </View>

        {/* 期間ボタン */}
        <View
          style={{
            marginTop: 20,
            marginBottom: 10,
            paddingHorizontal: 15,
            justifyContent: 'flex-end',
            flexDirection: 'row',
            height: 38,
          }}>
          {!isTappingChart && (
            <>
              <PeriodButton
                title="3ヶ月"
                on={period === 3}
                onPress={() => onPressPeriodButton(3)}
              />
              <PeriodButton
                title="6ヶ月"
                on={period === 6}
                onPress={() => onPressPeriodButton(6)}
              />
              <PeriodButton
                title="1年"
                on={period === 12}
                onPress={() => onPressPeriodButton(12)}
              />
              <PeriodButton
                title="すべて"
                on={period === 'all'}
                onPress={() => onPressPeriodButton('all')}
              />
            </>
          )}
        </View>
      </View>
    )
  }, [
    chartData.length,
    monthlyReport,
    prevMonthOver,
    prevDayOver,
    updateListHeaderHeight,
    totalAmount,
    period,
    onPressPeriodButton,
    isTappingChart,
  ])

  const [touchesChartData, setTouchesChartData] = useState<
    ChartData & { x: number; y: number; index: number }
  >()

  const displayChartInfo = useCallback(
    (event: GestureResponderEvent) => {
      const chartWidth = wp(100 - 15) - 15
      const { locationX: x, locationY: y } = event.nativeEvent
      const index = Math.ceil((x / chartWidth) * chartData.length) - 1
      if (index >= 0 && index < chartData.length) {
        setTouchesChartData({ ...chartData[index], x, y, index })
      } else {
        setTouchesChartData(undefined)
      }
      // Log.info(x, index, chartData[index])
    },
    [chartData],
  )

  const onChartTouchStart = useCallback(
    (event: GestureResponderEvent) => {
      dispatch(setIsTappingChart({ isTappingChart: true }))
      displayChartInfo(event)
    },
    [dispatch, displayChartInfo],
  )

  const onChartTouchMove = useCallback(
    (event: GestureResponderEvent) => {
      displayChartInfo(event)
    },
    [displayChartInfo],
  )
  const onChartTouchEnd = useCallback(() => {
    dispatch(setIsTappingChart({ isTappingChart: false }))
    setTouchesChartData(undefined)
  }, [dispatch])

  const listHeader2 = useCallback(() => {
    let yMax = assets.reduce((prv, cur) => Math.max(prv, cur.totalBalance), 0)
    yMax *= 1.2

    const chartWidth = wp(100 - 15) - 15 // YAxisを除いた部分
    const chartInfoCenterX =
      (chartWidth / (chartData.length - 1)) * (touchesChartData?.index || 0)

    return (
      <View
        style={{
          paddingTop: monthlyReport ? 0 : 0,
          paddingBottom: monthlyReport ? 20 : 0,
          paddingRight: monthlyReport ? 0 : 15,
        }}>
        {/* チャート */}
        {/* <View> */}
        <View
          onTouchStart={onChartTouchStart}
          onTouchMove={onChartTouchMove}
          onTouchEnd={onChartTouchEnd}
          style={{
            flexDirection: 'row',
            height: monthlyReport ? wp(40) : wp(50),
          }}>
          <YAxis
            style={{
              width: '15%',
              marginBottom: 10,
              // backgroundColor: 'red',
            }}
            data={StackedAreaChart.extractDataPoints(chartData, [
              'bank',
              'stock',
              'wallet',
            ])}
            formatLabel={(value) =>
              value >= 10000 ? `${Math.floor(value / 10000)}万` : value
            }
            numberOfTicks={5}
            contentInset={{ top: 5, bottom: 5 }}
            svg={{ fontSize: 10, fill: 'grey' }}
            max={yMax}
          />
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                marginTop: 5,
                paddingVertical: 0,
                // backgroundColor: 'gray',
              }}>
              <StackedAreaChart
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                data={chartData}
                yMax={yMax}
                keys={['wallet', 'stock', 'bank']}
                colors={[
                  'rgba(108, 223, 7, 0.3)',
                  'rgba(71, 211, 255, 0.3)',
                  'rgba(255, 208, 40, 0.3)',
                ]}
                // curve={shape.curveNatural}
                numberOfTicks={5}
                curve={shape.curveLinear}>
                <Grid svg={{ stroke: '#f0f0f0' }} />
              </StackedAreaChart>
              <LineChart
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                yMax={yMax}
                yMin={0}
                data={chartData.map((v) => v.bank + v.stock + v.wallet)}
                svg={{ stroke: 'rgb(255, 208, 40)' }}
              />
              <LineChart
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                yMax={yMax}
                yMin={0}
                data={chartData.map((v) => v.stock + v.wallet)}
                svg={{ stroke: 'rgb(71, 211, 255)' }}
              />
              <LineChart
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                yMax={yMax}
                yMin={0}
                data={chartData.map((v) => v.wallet)}
                svg={{ stroke: 'rgb(108, 223, 7)' }}
              />
            </View>
            <XAxis
              style={{
                marginTop: 5,
                marginHorizontal: 0,
                height: 12,
                // backgroundColor: 'red',
              }}
              data={chartData}
              // scale={scale.scaleBand}
              scale={scale.scaleLinear}
              // spacingInner={1}
              // spacingOuter={1}
              formatLabel={(value, index) =>
                chartData.length <= 6 ||
                (chartData.length > 6 &&
                  (chartData.length - index - 1) %
                    Math.ceil(chartData.length / 6) ===
                    0)
                  ? moment(chartData[index].date, 'YYYY-MM-DD').format(
                      chartData.length > 12 ? 'Y/M' : 'M/D',
                    )
                  : ''
              }
              contentInset={{
                left: 14,
                right: chartData.length > 12 ? 21 : 14,
              }}
              svg={{ dy: 1, fontSize: 10, fill: 'grey' }}
            />
            {!monthlyReport && touchesChartData?.date && (
              <>
                <View
                  style={{
                    position: 'absolute',
                    left: chartInfoCenterX,
                  }}>
                  <View
                    style={{
                      alignSelf: 'center',
                      left: -1,
                      width: 2,
                      height: wp(50) - 17,
                      backgroundColor: Color.Primary,
                    }}
                  />
                </View>
                {touchesChartData.total >= 0 && (
                  <View
                    style={{
                      position: 'absolute',
                      left: chartInfoCenterX - 7.5,
                      top:
                        wp(50) -
                          17 -
                          (wp(50) - 17 - 4) * (touchesChartData?.total / yMax) -
                          7.5 || 0, //  - 4 はLineChartとのずれを微調整
                      width: 15,
                      height: 15,
                      borderRadius: 7.5,
                      backgroundColor: Color.Primary,
                    }}
                  />
                )}
                {touchesChartData && (
                  <View
                    style={{
                      position: 'absolute',
                      left: Math.max(
                        -wp(15) + 5,
                        Math.min(chartInfoCenterX - 50, chartWidth - 100 + 10),
                      ),
                      top: -38,
                      width: 100,
                      backgroundColor: Color.Primary,
                      borderRadius: 5,
                      overflow: 'hidden',
                      paddingVertical: 3,
                      height: 38,
                      justifyContent: 'space-between',
                    }}>
                    <Text
                      style={{
                        color: 'white',
                        alignSelf: 'center',
                      }}>
                      {touchesChartData?.total?.jpCurrency()}
                    </Text>
                    <Text
                      style={{
                        marginRight: 10,
                        color: 'white',
                        fontSize: 9,
                        fontWeight: 'normal',
                        alignSelf: 'flex-end',
                      }}>
                      {moment(touchesChartData.date, 'YYYY-MM-DD').format(
                        'YYYY/M/D',
                      )}
                    </Text>
                  </View>
                )}
              </>
            )}
          </View>
        </View>
        {/* </View> */}
        {isLoading && (
          <AbsolutePositionView
            style={{ backgroundColor: '#00000033', justifyContent: 'center' }}>
            <ActivityIndicator />
          </AbsolutePositionView>
        )}
      </View>
    )
  }, [
    assets,
    monthlyReport,
    onChartTouchStart,
    onChartTouchMove,
    onChartTouchEnd,
    chartData,
    touchesChartData,
    isLoading,
  ])

  const listHeader3 = useCallback(() => {
    if (monthlyReport) return <></>
    return (
      <View>
        <View
          style={{
            marginLeft: 20,
            marginTop: 20,
            marginBottom: 20,
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
          }}>
          <Switch
            style={{ marginRight: 5 }}
            onValueChange={() => {
              setDisplaySubCategories(!isDisplaySubCategories)
            }}
            value={isDisplaySubCategories}
          />
          <Text
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              color: 'rgb(102, 102, 102)',
            }}>
            小項目表示
          </Text>
        </View>
      </View>
    )
  }, [isDisplaySubCategories, monthlyReport, setDisplaySubCategories])

  const sections = useMemo((): SectionListData<Item>[] => {
    const data: Item[] = [listHeader1, listHeader2, listHeader3]

    const asset = monthlyReport ? assets[0] : dayAssets[0]

    if (!asset) {
      return [
        {
          title: '',
          data,
        },
      ]
    }

    const bankRatio = asset.bankBalance / asset.totalBalance
    const stockRatio = asset.stockBalance / asset.totalBalance
    const otherRatio =
      (asset.walletBalance + asset.emoneyBalance) / asset.totalBalance

    const prevOverBank = monthlyReport ? prevMonthOverBank : prevDayOverBank
    const prevOverWallet = monthlyReport
      ? prevMonthOverWallet
      : prevDayOverWallet

    const bankAssets: Item | undefined =
      asset.bankAccounts.length > 0
        ? {
            financialAccountType: 'bank',
            name: '銀行口座',
            amount: asset.bankBalance,
            ratio: bankRatio ? bankRatio : 0,
            childItems: isDisplaySubCategories ? asset.bankAccounts : [],
            monthOver: prevOverBank,
            monthlyReport,
          }
        : undefined
    const stockAssets: Item | undefined =
      asset.stockAccounts.length > 0
        ? {
            financialAccountType: 'stock',
            name: '証券/投資信託',
            amount: asset.stockBalance,
            ratio: stockRatio ? stockRatio : 0,
            childItems: isDisplaySubCategories ? asset.stockAccounts : [],
            monthOver: prevMonthOverStock,
            monthlyReport,
          }
        : undefined
    const otherAssets: Item | undefined =
      asset.wallets.length + asset.emoneyAccounts.length > 0
        ? {
            financialAccountType: 'wallet',
            name: '現金・電子マネー',
            amount: asset.walletBalance + asset.emoneyBalance,
            ratio: otherRatio ? otherRatio : 0,
            childItems: isDisplaySubCategories
              ? asset.wallets.concat(asset.emoneyAccounts)
              : [],
            monthOver: prevOverWallet,
            monthlyReport,
          }
        : undefined

    if (bankAssets) data.push(bankAssets)
    if (stockAssets) data.push(stockAssets)
    if (otherAssets) data.push(otherAssets)

    return [
      {
        title: '',
        data,
      },
    ]
  }, [
    assets,
    dayAssets,
    isDisplaySubCategories,
    listHeader1,
    listHeader2,
    listHeader3,
    monthlyReport,
    prevDayOverBank,
    prevDayOverWallet,
    prevMonthOverBank,
    prevMonthOverStock,
    prevMonthOverWallet,
  ])

  const renderItem: SectionListRenderItem<Item> = useCallback(({ item }) => {
    if (typeof item === 'function') return item()
    return <ListItem item={item} />

    // return item.scope ? (
    //   <ListItem item={item} onPress={() => onPressItem(item)} />
    // ) : (
    //   <NoDataListItem />
    // )
  }, [])

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: `AssetTransition-${
          userAccountType === 'family' ? 'Family' : 'User'
        }`,
        title: `${
          userAccountType === 'family' ? '家族' : '個人'
        }_口座 資産推移`,
      })
    }
  }, [userAccountType, isFocused])

  if (sections.length === 0 && !planError) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          backgroundColor: 'white',
        }}>
        <ActivityIndicator color={Color.Gray} />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <SectionList
          ref={sectionList}
          // scrollEnabled={!isTappingChart}
          keyExtractor={(item: Item, index) =>
            JSON.stringify(item) || `${index}`
          }
          renderItem={renderItem}
          sections={sections}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true)
                await getBs()
                setRefreshing(false)
              }}
            />
          }
          ListFooterComponent={() => <View style={{ height: 24 }} />}
        />
        {planError && (
          <StyledPlanErrorView
            pointerEvents="box-none"
            monthlyReport={monthlyReport}
            top={listHeaderHeight}>
            <PremiumContainerView pointerEvents="box-none">
              {!monthlyReport ? (
                <>
                  <FullWidthImage
                    source={require('@images/premium/limit_graph2.png')}
                  />
                  <AppButton
                    title="詳しく見る"
                    textStyle={{ fontSize: 14, fontWeight: 'bold' }}
                    style={{
                      backgroundColor: Color.Primary,
                      width: '80%',
                      height: 48,
                      position: 'absolute',
                      bottom: wp(5),
                      alignSelf: 'center',
                    }}
                    onPress={() => {
                      navigatePremiumPlanLP({ planCode: '001' })
                      TrackingUtils.repro.track(
                        '【Tap】upper limit_asset transition',
                        'Tap',
                      )
                      TrackingUtils.repro.setScreenTrackingAfterPurchase(
                        '【Screen】purchase completed via upper limit_asset transition',
                      )
                    }}
                  />
                </>
              ) : (
                <>
                  <PremiumTitleText monthlyReport={monthlyReport}>
                    過去の資産推移を確認しよう
                  </PremiumTitleText>
                  <PremiumText monthlyReport={monthlyReport}>
                    プレミアムプランでは1年以上前の資産推移を{'\n'}
                    グラフで表示できます。{'\n'}
                    {monthlyReport ? null : '\n'}
                  </PremiumText>
                  <AppButton
                    title="詳しく見る"
                    onPress={() => {
                      TrackingUtils.repro.track(
                        '【Tap】upper limit_asset transition',
                        'Tap',
                      )
                      navigatePremiumPlanLP({ planCode: '001' })
                      TrackingUtils.repro.setScreenTrackingAfterPurchase(
                        '【Screen】purchase completed via upper limit_monthly eport asset transition',
                      )
                    }}
                  />
                </>
              )}
            </PremiumContainerView>
          </StyledPlanErrorView>
        )}
      </View>
    </View>
  )
}

const ListItem: React.FC<{ item: Item }> = ({ item }) => {
  if (typeof item === 'function') return null

  const icon =
    item.financialAccountType === 'bank'
      ? require('@images/icons/list-item-bank-icon.png')
      : item.financialAccountType === 'stock'
      ? require('@images/icons/list-item-bond-icon.png')
      : require('@images/icons/list-item-wallet-icon.png')

  const color =
    item.financialAccountType === 'bank'
      ? 'rgb(255, 208, 40)'
      : item.financialAccountType === 'stock'
      ? 'rgb(71, 211, 255)'
      : 'rgb(108, 223, 7)'

  return (
    <>
      <View
        style={{
          height: 0.5,
          backgroundColor: '#ccc',
          marginTop: 5,
          marginHorizontal: 15,
        }}
      />
      <View style={styles.listPaymentsItem}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View style={styles.listPaymentsItemType}>
            <Image source={icon} style={styles.listPaymentsItemTypeIcon} />
            <Text numberOfLines={1} style={styles.listPaymentsItemTypeText}>
              {item.name}
            </Text>
          </View>
          <View style={styles.listPaymentsItemInfo}>
            <Text numberOfLines={1} style={styles.listPaymentsItemInfoAmount}>
              {item.amount.jpCurrency()}
            </Text>
            <Text style={styles.listPaymentsItemInfoPercent}>
              {Math.round(item.ratio * 100)}%
            </Text>
          </View>
        </View>
        {item.monthlyReport || item.financialAccountType !== 'stock' ? (
          <View
            style={{
              marginTop: 5,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 'normal',
                color: 'rgb(102, 102, 102)',
              }}>
              {item.monthlyReport ? '前月比' : '前日比'}
            </Text>
            <Text
              style={{
                marginLeft: 10,
                fontSize: 12,
                fontWeight: 'normal',
                color: Color.number(item.monthOver),
              }}>
              {item.monthOver !== undefined
                ? item.monthOver.jpCurrency({
                    showPlus: true,
                    showPlusMinus: true,
                  })
                : '-'}
            </Text>
          </View>
        ) : (
          <View style={{ height: 15 }} />
        )}
        {item.childItems.map((v, index) => {
          return (
            <View
              key={index}
              style={[
                styles.listPaymentsItemType,
                { marginTop: 12, marginBottom: 3 },
              ]}>
              <View
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  marginLeft: 30,
                  marginRight: 10,
                  backgroundColor: color,
                }}
              />
              <Text style={[styles.listPaymentsItemTypeText, { flex: 1 }]}>
                {v.name}
              </Text>
              <Text style={styles.listPaymentsItemInfoAmount2}>
                {v.balance.jpCurrency()}
              </Text>
            </View>
          )
        })}
      </View>
    </>
  )
}

const PeriodButton = ({
  title,
  on,
  ...props
}: { title: string; on?: boolean } & TouchableOpacityProps) => {
  return (
    <TouchableOpacity
      style={{
        marginLeft: 10,
        backgroundColor: on ? Color.Primary : '#eee',
        borderRadius: 18,
        height: 36,
        width: Math.min(68, wp(15)),
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}>
      <Text
        style={{
          fontSize: 12,
          color: on ? 'white' : 'rgb(153, 153, 153)',
          fontWeight: 'bold',
        }}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  listPaymentsItem: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 15,
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listPaymentsItemTypeIcon: {
    marginRight: 6,
    width: 28,
    height: 28,
  },
  listPaymentsItemTypeText: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'normal',
    color: Color.TextBlack,
  },
  listPaymentsItemInfo: {
    flex: -1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
  },
  listPaymentsItemInfoPercent: {
    width: 42,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'right',
    color: Color.Gray,
  },
  listPaymentsItemInfoAmount: {
    flex: -1,
    fontSize: 18,
    fontWeight: 'normal',
    color: Color.NumberBlack,
  },
  listPaymentsItemInfoAmount2: {
    fontSize: 18,
    fontWeight: 'normal',
    color: Color.NumberBlack,
  },
})

export default AssetTransitionScreen
