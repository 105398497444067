import Color from '@lib/Color'
import { Dimensions } from 'react-native'
import { PieChart } from 'react-native-svg-charts'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  /* flex-direction: row; */
`

export const PieChartViewContainer = styled.View`
  flex: 1;
  justify-content: flex-start;
`

export const PieChartView = styled.View`
  align-items: center;
  justify-content: center;
`
export const PieChartViewContent = styled.View`
  position: absolute;
`

export const PieChartTotalTitle = styled.Text`
  font-size: ${Dimensions.get('window').width * 0.03}px;
  color: ${Color.DefaultText};
  text-align: center;
  margin-bottom: 1px;
`

export const PieChartRate = styled.Text<{ disabled?: boolean }>`
  margin-top: ${Dimensions.get('window').width * 0.01}px;
  font-size: ${Dimensions.get('window').width * 0.045}px;
  font-weight: bold;
  color: ${({ disabled }) => (disabled ? '#aaaaaa' : Color.Primary)};
  text-align: center;
`

export const StyledPieChart = styled(PieChart)`
  width: ${Dimensions.get('window').width * 0.27}px;
  height: ${Dimensions.get('window').width * 0.27}px;
`

export const PieChartDetailContainer = styled.View`
  flex: 1;
  justify-content: space-between;
`

export const ChartItemRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  /* padding-left: 20px; */
  width: 100%;
`

export const ChatItemTitle = styled.Text<{ over?: boolean }>`
  font-size: 14px;
  min-width: 30px;
  color: ${({ over }) => (over ? Color.Orange : Color.DefaultText)};
`

export const ChatItemAmount = styled.Text<{
  number: number
  updateColor?: boolean
}>`
  width: 100%;
  font-size: ${Math.min(26, wp(6))}px;
  /* font-weight: bold; */
  text-align: right;
  padding-right: 20px;
  text-align: right;
  color: ${({ number, updateColor }) =>
    updateColor ? Color.number(number) : Color.Black};
`

export const DetailButtonContainer = styled.View`
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  /* padding-top: 15px; */
  padding-top: 0;
  padding-right: 20px;
  /* align-items: center; */
  /* background-color: red; */
  /* height: 100px; */
  /* width: 100px; */
`

export const DetailButtonText = styled.Text`
  color: #888888;
  font-size: 13px;
`

export const DetailButtonIcon = styled.Image`
  width: 11px;
  height: 11px;
  tint-color: #888888;
  margin-left: 1px;
  resize-mode: contain;
`
