import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import CommonHeader from '@components/CommonHeader'
import WebPurchaseWebView from '@components/WebPurchaseWebView'
import CommonDialog from '@lib/CommonDialog'
import { Direct } from '@lib/Env'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import { profileSelector } from '@lib/ProfileManager/selector'
import {
  default as ProgressHUD,
  default as RNProgressHud,
} from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { getWebToken } from '@lib/WebToken'
import { APIError, getApiUrl } from '@lib/api'
import PremiumPlan, { GetPremiumPlansResponse } from '@lib/api/PremiumPlan'
import { StripeManager } from '@lib/api/Stripe'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, Text, TouchableOpacity, View } from 'react-native'
import { WebViewMessageEvent, WebViewNavigation } from 'react-native-webview'
import {
  ShouldStartLoadRequest,
  WebViewScrollEvent,
} from 'react-native-webview/lib/WebViewTypes'
import { useSelector } from 'react-redux'

const PlanDescriptions: {
  [url: string]: {
    title: string
    description: string
  }
} = {
  'osidori-subscribe://monthly_plan_with_partner': {
    title: '月額プラン（家族）を試す',
    description: '７日間の無料体験後、¥880(税込)/月',
  },
  'osidori-subscribe://monthly_plan': {
    title: '月額プラン（個人）を試す',
    description: '７日間の無料体験後、¥480(税込)/月',
  },
  'osidori-subscribe://monthly_plan_with_partner?plan=001': {
    title: '月額プラン（家族）を試す',
    description: '30日間の無料体験後、¥880(税込)/月',
  },
  'osidori-subscribe://monthly_plan?plan=001': {
    title: '月額プラン（個人）を試す',
    description: '30日間の無料体験後、¥480(税込)/月',
  },
  'osidori-subscribe://yearly_plan_with_partner': {
    title: '年額プラン（家族）を試す',
    description: '30日間の無料体験後、¥8,800(税込)/年',
  },
  'osidori-subscribe://yearly_plan': {
    title: '年額プラン（個人）を試す',
    description: '30日間の無料体験後、¥4,800(税込)/年',
  },
}

export declare type OnShouldStartLoadWithRequest = (
  event: ShouldStartLoadRequest,
) => boolean

const checkURL = (url: string) => {
  if (url === 'osidori-subscribe://monthly_plan_with_partner') {
    return false
  } else if (url === 'osidori-subscribe://monthly_plan') {
    return false
  } else if (url === 'osidori-subscribe://monthly_plan_with_partner?plan=001') {
    return false
  } else if (url === 'osidori-subscribe://monthly_plan?plan=001') {
    return false
  } else if (url === 'osidori-subscribe://yearly_plan_with_partner') {
    return false
  } else if (url === 'osidori-subscribe://yearly_plan') {
    return false
  }

  return true
}

let url = ''

let planCode: string | undefined = undefined
let planVersion: string | undefined = undefined

const PremiumPlanWebLPScreen: FC<
  StackScreenProps<RootStackParamList, 'PremiumPlanWebLP'>
> = ({ route }) => {
  planCode = route.params?.planCode
  planVersion = route.params?.planVersion ?? '4' // lp${planversion}.php

  const [selectedPlanURL, setSelectedPlanURL] = useState('')
  const [isVisibleBottomButton, setVisibleBottomButton] = useState(false)
  const [canGoBack, setCanGoBack] = useState(false)

  const profile = useSelector(profileSelector)

  const webView = useRef<WebPurchaseWebView>(null)

  const fetchProfiles = useCallback(async () => {
    try {
      // TODO
      const response = await PremiumPlan.getPlamiumPlans()
      if (response.ok) {
        const plans = response.json as GetPremiumPlansResponse
        Log.info(plans)
      } else {
        throw new APIError(response)
      }

      RNProgressHud.show()

      await ProfileManager.fetchUserProflie()
      await ProfileManager.fetchPartnerProflie()
    } catch (error) {
      console.warn(error)
      // CommonDialog.showError({ error, onPress: NavigationService.goBack })
      CommonDialog.showError({ error }) // TODO
    } finally {
      RNProgressHud.dismiss()
    }

    TrackingUtils.repro.track('【Screen】choose plan', 'Screen')
  }, [])

  const uri = useMemo(() => {
    const platform = Direct ? 'direct' : 'ui'
    const purchased = Platform.select({
      ios: profile.userProfile?.hasAppStorePurchase,
      android: profile.userProfile?.hasGooglePlayPurchase,
      web: profile.userProfile?.hasUserStripeSubscription,
    })
    const uri = `https://www.osidori.co/app/lp/lp${planVersion}.php${
      (planCode ? `?plan=${planCode}` : '?') +
      `&purchased=${purchased ? 1 : 0}&platform=${platform}`
    }`
    Log.info({ uri })
    return uri
  }, [profile.userProfile])

  const onScroll = useCallback(
    (event: WebViewScrollEvent) => {
      const e = event // as unknown as NativeSyntheticEvent<NativeScrollEvent>
      const newValue =
        e.nativeEvent.contentOffset.y / e.nativeEvent.contentSize.height >=
        650 / 6685
      if (newValue !== isVisibleBottomButton) {
        setVisibleBottomButton(newValue)
      }
    },
    [isVisibleBottomButton],
  )

  const onMessage = useCallback((event: WebViewMessageEvent) => {
    const url = event.nativeEvent.data
    if (typeof url === 'string' && url?.startsWith('osidori-subscribe://')) {
      const plan = url.replace('osidori-subscribe://', '')

      setSelectedPlanURL(plan)
    }
  }, [])

  const onNavigationStateChange = useCallback(
    ({ canGoBack }: WebViewNavigation) => {
      setCanGoBack(canGoBack)
    },
    [],
  )

  const onPressLeftButton = useCallback(() => {
    if (canGoBack) {
      // webView.current?.goBack()
    } else {
      NavigationService.goBack()
    }
  }, [canGoBack])

  const onShouldStartLoadWithRequest = useCallback(
    (event: ShouldStartLoadRequest) => {
      url = event.url
      Log.info({ url })
      const ret = checkURL(url)
      if (!ret && url.startsWith('osidori-subscribe://')) {
        getWebToken().then((webToken) => {
          const stripeUrl = url.replace(
            'osidori-subscribe://',
            `${getApiUrl()}/stripe/monthly_plan`,
          )
          Log.info(`${stripeUrl}?web_token=${webToken}`)
          setWebviewUri(`${stripeUrl}?web_token=${webToken}`)
        })

        TrackingUtils.ga.tap({
          action: 'Tap-PremiumPlanLP-Purchase',
          label: 'プレミアムプランのLPで購入ボタンをタップ',
        })
      }
      return ret
    },
    [],
  )

  useEffect(() => {
    fetchProfiles()
  }, [fetchProfiles])

  const [webviewUri, setWebviewUri] = useState(uri)

  useEffect(() => {
    Log.info({ uri })
    setWebviewUri(uri)
  }, [uri])

  useEffect(() => {
    getWebToken().then((webToken) => {
      if (selectedPlanURL) {
        StripeManager.purchaseConfirm({
          productId: selectedPlanURL,
          webToken,
        })
          .then((response) => {
            console.log({ response })
            ProgressHUD.show()

            TrackingUtils.ga.tap({
              action: 'Tap-PremiumPlanLP-Purchase',
              label: 'プレミアムプランのLPで購入ボタンをタップ',
            })

            const url = `${response.url}?web_token=${webToken}`
            Log.info({ url })
            setTimeout(() => {
              ProgressHUD.dismiss()
              window.location.href = url
            }, 4000)
          })
          .catch((error) => {
            CommonDialog.showError({ error })
          })
          .finally(() => {
            // ProgressHUD.dismiss()
          })
      }
    })
  }, [selectedPlanURL])

  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: 'PremiumPlanLP',
        title: 'プレミアムプランのLP',
      })
    }
  }, [isFocused])

  return (
    <View style={{ flex: 1 }}>
      <CommonHeader
        title="プレミアムプランを無料体験"
        leftButtonType={canGoBack ? 'stack' : 'modal'}
        onPressLeftButton={onPressLeftButton}
      />
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <WebPurchaseWebView
          ref={webView}
          originWhitelist={['*']}
          source={{ uri: webviewUri }}
          startInLoadingState={true}
          renderLoading={() => <AbsoluteActivityIndicator />}
          onScroll={(e: any) => onScroll(e)}
          onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
          onMessage={onMessage}
          onNavigationStateChange={onNavigationStateChange}
        />
        {isVisibleBottomButton && PlanDescriptions[selectedPlanURL] && (
          <View
            style={{
              paddingTop: 10,
              paddingHorizontal: 20,
              paddingBottom: 30,
            }}>
            <TouchableOpacity
              onPress={() => {
                checkURL(selectedPlanURL)
              }}
              style={{
                borderRadius: 6,
                backgroundColor: 'rgb(63, 197, 21)',
                paddingTop: wp(4.1),
                paddingBottom: wp(2.5),
                alignItems: 'center',
              }}>
              <Text
                style={{
                  color: 'white',
                  fontSize: wp(5.8),
                  fontWeight: 'bold',
                }}>
                {PlanDescriptions[selectedPlanURL]?.title}
              </Text>
              <Text
                style={{
                  color: 'white',
                  fontSize: wp(4.15),
                  fontWeight: 'normal',
                  marginTop: wp(2),
                }}>
                {PlanDescriptions[selectedPlanURL]?.description}
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                marginTop: 10,
                fontSize: 12,
                fontWeight: 'normal',
                color: '#888888',
              }}>
              無料お試しは
              {Platform.OS === 'ios' ? 'Apple ID' : 'GooglePlayアカウント'}
              ごとに初回のみ適用されます。本ページ内の注意事項をお読の上ご購入ください。
            </Text>
          </View>
        )}
      </View>
    </View>
  )
}

export default PremiumPlanWebLPScreen
