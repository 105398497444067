import PremiumPlanWebContent from '@Screen/Main/PremiumPlan/PremiumPlanWebContent'
import AppButton from '@components/AppButton'
import FullWidthImage from '@components/FullWidthImage'
import Color from '@lib/Color'
import { NativeApp } from '@lib/Env'
import { ImageRequireSource, Pressable } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'
import BlurView from './BlurView'

export type PremiumType =
  | 'budget'
  | 'financial_account'
  | 'term_transaction'
  | 'sort_category'
  | 'sort_sub_category'
  | 'hide_category'
  | 'hide_sub_category'
  | 'create_category'
  | 'create_sub_category'
  | 'goal'

export type PremiumViewProps = {
  title?: string
  message?: string
  buttonTitle?: string
  onPressShowDetailButton?: () => void
  image?: ImageRequireSource
  premiumType?: PremiumType
  onBackdropPress?: () => void
  showTopMargin?: boolean
  ignoreImageTouchEvent?: boolean
}

export const PremiumView = ({
  title,
  message,
  buttonTitle,
  onPressShowDetailButton,
  image,
  premiumType,
  onBackdropPress,
  showTopMargin = true,
  ignoreImageTouchEvent,
}: PremiumViewProps) => {
  // const navigatePremiumPlan = useCallback(async () => {
  //   await ProfileManager.fetchUserProflie()
  //   if (store.getState().profile.userProfile?.rank === 'premium') {
  //     NavigationService.navigate('PremiumPlanForPremiumUser')
  //   } else {
  //     const planVersion =
  //       (store.getState().profile.userProfile?.userId ?? 0) % 2 === 0
  //         ? '3'
  //         : 'split'

  //     let adid = SessionManager.getAdid()
  //     if (!adid) {
  //       adid = await new Promise<string>((resolve) => {
  //         Adjust.getAttribution((attribution) => resolve(attribution.adid))
  //       })
  //     }
  //     if (adid) {
  //       try {
  //         const ads = await AdvertisementManager.getAdvertisements({
  //           adid,
  //         })
  //         const tracker = ads.find((v) => v.tracker)?.tracker

  //         // 1.ハレトケ
  //         // nonpaid_alliance_haretoke
  //         // https://app.adjust.com/e7qlw6s

  //         // 2.アイアル
  //         // nonpaid_alliance_air
  //         // https://app.adjust.com/lmxu9q0

  //         // <android>
  //         // 3.ハレトケ
  //         // nonpaid_alliance_haretoke
  //         // https://app.adjust.com/71osynb

  //         // 4.アイアル
  //         // nonpaid_alliance_air
  //         // https://app.adjust.com/exb2z1o3

  //         if (
  //           tracker &&
  //           ['e7qlw6s', 'lmxu9q0', '71osynb', 'eb2z1o3'].includes(tracker)
  //         ) {
  //           navigatePremiumPlanLP({ planVersion, planCode: '001' })
  //         } else {
  //           navigatePremiumPlanLP({ planVersion })
  //         }
  //       } catch (error) {
  //         CommonDialog.showError({ error })
  //       }
  //     } else {
  //       navigatePremiumPlanLP({ planVersion })
  //     }
  //   }
  // }, [])

  // const onPressSubmit = useCallback(async () => {
  //   if (onPressShowDetailButton) {
  //     onPressShowDetailButton()
  //   } else {
  //     showAppButtonIndicator()
  //     await navigatePremiumPlan()
  //     hideAppButtonIndicator()
  //   }
  // }, [navigatePremiumPlan, onPressShowDetailButton])

  // return (
  //   <StyledRootView>
  //     <StyledWebView source={{ uri: uri! }} scrollEnabled />
  //   </StyledRootView>
  // )

  return (
    <StyledRootView animation="fadeIn" duration={300}>
      {showTopMargin && (
        <SafeAreaView edges={['top']}>
          <StyledHeaderMarginView onPress={onBackdropPress} />
        </SafeAreaView>
      )}
      <StyledMainView>
        <BlurView onBackdropPress={onBackdropPress}>
          <StyledScrollView scrollEnabled={!premiumType}>
            <PremiumContainerView>
              {title && <PremiumTitleText>{title}</PremiumTitleText>}
              {message && <PremiumText>{message}</PremiumText>}
              {/* イベントを親に伝えないようにする */}
              {image && (
                <>
                  {ignoreImageTouchEvent ? (
                    <FullWidthImage source={image} />
                  ) : (
                    <Pressable>
                      <FullWidthImage source={image} />
                    </Pressable>
                  )}
                  <StyledAppButton
                    title={buttonTitle || '詳しく見る'}
                    onPress={onPressShowDetailButton}
                  />
                </>
              )}
              {premiumType && (
                <>
                  <StyledWebTopView />
                  <PremiumPlanWebContent type={premiumType} />
                  <StyledWebBottomView />
                </>
              )}
            </PremiumContainerView>
          </StyledScrollView>
        </BlurView>
      </StyledMainView>
    </StyledRootView>
  )
}

const StyledRootView = styled(Animatable.View)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  justifyContent: 'center',
})

const StyledHeaderMarginView = styled.Pressable({
  height: 44,
})

const StyledMainView = styled.View({
  flex: 1,
})

const StyledScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'center',
  },
})({})

const PremiumContainerView = styled.View({
  paddingVertical: 0,
  paddingHorizontal: wp(5),
})

const PremiumTitleText = styled.Text({
  textShadowColor: 'rgba(0,0,0,1)',
  textShadowOffset: {
    width: 0,
    height: 2,
  },
  textShadowOpacity: 0.5,
  textShadowRadius: 4,
  elevation: 4,
  textAlign: 'center',
  fontSize: wp(5),
  fontWeight: 'bold',
  color: 'white',
})

const PremiumText = styled.Text({
  marginTop: 8,
  marginBottom: 16,
  textShadowColor: 'rgba(0,0,0,1)',
  textShadowOffset: {
    width: 0,
    height: 2,
  },
  textShadowOpacity: 0.5,
  textShadowRadius: 4,
  elevation: 4,
  textAlign: 'center',
  fontSize: wp(4.1),
  color: 'white',
})

const StyledAppButton = styled(AppButton).attrs({
  textStyle: { fontSize: 18, fontWeight: 'bold' },
})({
  backgroundColor: Color.Primary,
  width: NativeApp ? '90%' : '80%',
  height: 48,
  position: 'absolute',
  bottom: wp(5),
  alignSelf: 'center',
})

const StyledWebTopView = styled.View({
  width: '100%',
  height: 8,
  backgroundColor: 'white',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  overflow: 'hidden', // iOSで正確な半弧を描くために必要
})

const StyledWebBottomView = styled.View({
  width: '100%',
  height: 8,
  backgroundColor: 'white',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  overflow: 'hidden', // iOSで正確な半弧を描くために必要
})
