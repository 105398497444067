import { NativeApp } from '@lib/Env'
import NavigationService from '@lib/NavigationService'
import { deeplinkNavigation } from './DeeplinkNavigationParams'

export interface ChangePasswordParams {
  token: string
}

export const changePassword = (params: ChangePasswordParams) => {
  if (NativeApp) {
    NavigationService.navigate('UpdatePassword', { token: params.token })
  } else {
    deeplinkNavigation({
      name: 'UpdatePassword',
      token: params.token,
    })
  }
}
