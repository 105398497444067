import NavigationService from '@lib/NavigationService'

export const PremiumPlanPageIndexFinancialAccounts = 0
export const PremiumPlanPageIndexCategory = 1
export const PremiumPlanPageIndexAutoTransaction = 2
export const PremiumPlanPageIndexTransfer = 3
export const PremiumPlanPageIndexCalendar = 4
export const PremiumPlanPageIndexOldData = 5
export const PremiumPlanPageIndexBudget = 6
export const PremiumPlanPageIndexGoals = 7
export const PremiumPlanPageIndexAd = 8
export const PremiumPlanPageIndexSupport = 9

export const navigatePremiumPlanLP = async (props?: {
  goBack?: () => void
  planCode?: string
  planVersion?: string
}) => {
  NavigationService.navigate('PremiumPlanWebLP', {
    goBack: props?.goBack,
    planCode: props?.planCode,
    planVersion: props?.planVersion,
  })
}
