import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import CommonHeader from '@components/CommonHeader'
import { getApiUrl } from '@lib/api'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Linking, View } from 'react-native'
import { WebView, WebViewNavigation } from 'react-native-webview'

const HousingWebViewScreen: React.FC<
  StackScreenProps<RootStackParamList, 'HousingWebView'>
> = ({ route }) => {
  const [uri, setUri] = useState<string>()
  const [pathname, setPathname] = useState<string>()
  const [canGoBack, setCanGoBack] = useState<boolean>(false)

  const webViewRef = useRef<WebView>(null)

  const goBack = useCallback(() => {
    if (!pathname) {
      NavigationService.goBack()
      return
    }

    /*
      /houging/lp
      /houging
      /lifeinsurance_terms/ 利用規約
      /privacy/ プライイバシーポリシー
      /housing/complete
    */

    if (pathname === '/housing/lp') {
      NavigationService.goBack()
      return
    } else if (pathname === '/housing/complete') {
      NavigationService.goBack()
      return
    } else if (pathname === '/lifeinsurance_terms' || pathname === '/privacy') {
      setPathname('/houging')
      webViewRef.current?.injectJavaScript(`
        $(".modal_page").hide();
        $(".modal_iframe").remove();
      `)
      return
    }
    if (canGoBack) {
      webViewRef.current?.goBack()
    } else {
      NavigationService.goBack()
    }
  }, [canGoBack, pathname])

  useEffect(() => {
    const uri = `${getApiUrl()}/${
      route.params?.skipLp ? 'housing' : 'housing/lp'
    }`
    setUri(uri)
  }, [route.params])

  useEffect(() => HardwareBackPressHandler.addListener(goBack).remove, [goBack])

  const onShouldStartLoadWithRequest = useCallback(
    (event: WebViewNavigation) => {
      Log.info({ url: event.url })
      const open = event.url.match(/^osidori:\/\/open\?uri=(.+)$/)?.[1]
      if (open) {
        const url = decodeURIComponent(open)
        Linking.openURL(url)
        Log.info({ url })

        return false
      }

      const close = event.url.match(/^osidori:\/\/close$/)
      if (close) {
        NavigationService.goBack()
        return false
      }

      if (!event.url.match(/^https?:\/\//)) {
        Linking.openURL(event.url)
        return false
      }

      setPathname(new URL(event.url).pathname.replace(/\/$/, ''))

      return true
    },
    [],
  )

  useEffect(() => {
    Log.info({ pathname })
    switch (pathname) {
      case '/housing/lp':
        TrackingUtils.repro.track('【Screen】housing_lp', 'Screen')
        break
      case '/housing':
        TrackingUtils.repro.track('【Screen】housing_ef', 'Screen')
        break
      case '/housing/complete':
        TrackingUtils.repro.track('【Screen】housing_thanks', 'Screen')
        break
    }
  }, [pathname])

  return (
    <View style={{ flex: 1 }}>
      <CommonHeader
        title="OsidOri おうちの相談窓口"
        leftButtonType="stack"
        onPressLeftButton={goBack}
      />
      {uri ? (
        <WebView
          source={{ uri }}
          startInLoadingState={true}
          originWhitelist={['*']}
          renderLoading={() => <AbsoluteActivityIndicator />}
          onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
          ref={webViewRef}
          onNavigationStateChange={({ canGoBack, navigationType }) => {
            // iOSの場合、ウェブの読み込みが完了するまでcanGoBackがtrueにならないので、
            // 画面遷移開始時点でcanGoBackをtrueにしてしまう。
            setCanGoBack(canGoBack || navigationType === 'click')
          }}
        />
      ) : (
        <AbsoluteActivityIndicator />
      )}
    </View>
  )
}

export default HousingWebViewScreen
