import Avatar from '@components/Avatar'
import HomeBackgroundImage from '@components/HomeBackgroundImage'
import { UserAccountType } from '@interfaces/Account'
import AccountManager from '@lib/AccountManager'
import { accountModeSelector } from '@lib/AccountManager/actions'
import Color from '@lib/Color'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import { SpotlightTourSpot } from '@lib/SpotlightTour'
import {
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import TutorialTipsBalloon from '../TutorialTips/TutorialTipsBalloon'

interface AvatarState {
  widthHeightAvatarPersonal: number
  widthHeightAvatarCouple: number
  widthOfWrapCouple: number
  isCouple: boolean
  leftPersonal?: number
  rightPersonal?: number
  topPersonal: number
  leftCouple?: number
  rightCouple?: number
  topCouple: number
  zIndexPersonal: number
  zIndexCouple: number
}

const PersonalAvatarState: AvatarState = {
  widthHeightAvatarPersonal: 70,
  widthHeightAvatarCouple: 36,
  widthOfWrapCouple: 66,
  isCouple: false,
  leftPersonal: 20,
  rightPersonal: undefined,
  topPersonal: 5 + (StatusBar.currentHeight || 6),
  leftCouple: undefined, // 190,
  rightCouple: 20,
  topCouple: 2 + (StatusBar.currentHeight || 6),
  zIndexPersonal: 0,
  zIndexCouple: 200,
}

const CoupleAvatarState: AvatarState = {
  widthHeightAvatarPersonal: 36,
  widthHeightAvatarCouple: 70,
  widthOfWrapCouple: 130,
  isCouple: true,
  leftPersonal: undefined, // 220
  rightPersonal: 20,
  topPersonal: (StatusBar?.currentHeight || 0) + 8,
  leftCouple: 20,
  rightCouple: undefined,
  topCouple: (StatusBar?.currentHeight || 0) + 11,
  zIndexPersonal: 200,
  zIndexCouple: 0,
}

const MenuTop = () => {
  const changeAccountMode = (accountMode: UserAccountType) => {
    AccountManager.updateAccountMode(accountMode)
    NavigationService.closeDrawer()
  }

  const onPressAvatarPersonal = () => {
    changeAccountMode('user')
  }

  const onPressAvatarFamily = () => {
    changeAccountMode('family')
  }

  const accountMode = useSelector(accountModeSelector)

  const {
    topPersonal,
    leftPersonal,
    rightPersonal,
    widthHeightAvatarPersonal,
    widthHeightAvatarCouple,
    topCouple,
    leftCouple,
    rightCouple,
    widthOfWrapCouple,
    isCouple,
    zIndexCouple,
    zIndexPersonal,
  } = accountMode === 'user' ? PersonalAvatarState : CoupleAvatarState

  return (
    <>
      <HomeBackgroundImage
        userAccountType={accountMode}
        style={styles.container}
        blurRadius={34}
      />
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}>
        {/* <View style={{ height: StatusBar.currentHeight || 0 }} /> */}

        <SafeAreaView edges={['top']}>
          {/* for Android */}
          {/* <View style={{ height: StatusBar.currentHeight || 0 }} /> */}
        </SafeAreaView>
        <View style={{ flex: 1 }}>
          <View
            style={[
              styles.warpAvatarCouple,
              {
                top: topCouple,
                left: leftCouple,
                right: rightCouple,
                zIndex: zIndexCouple,
              },
            ]}>
            <TouchableOpacity
              disabled={isCouple}
              onPress={onPressAvatarFamily}
              style={styles.avatarView}>
              <Avatar
                styleCouple={{ width: widthOfWrapCouple }}
                size={widthHeightAvatarCouple}
                type="couple"
              />
              <Text
                style={
                  accountMode === 'family'
                    ? styles.avatarNameText
                    : styles.avatarNameSmallText
                }>
                家族
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={[
              styles.warpAvatarPersonal,
              {
                top: topPersonal,
                left: leftPersonal,
                right: rightPersonal,
                zIndex: zIndexPersonal,
              },
            ]}>
            <TouchableOpacity
              disabled={!isCouple}
              onPress={onPressAvatarPersonal}>
              <SpotlightTourSpot
                spotId="avatar"
                scale={1.6}
                tipsComponent={(props) => <TutorialTipsBalloon {...props} />}>
                <View style={styles.avatarView}>
                  <Avatar size={widthHeightAvatarPersonal} />
                  <Text
                    style={
                      accountMode === 'user'
                        ? styles.avatarNameText
                        : styles.avatarNameSmallText
                    }>
                    {ProfileManager.getName('user')}
                  </Text>
                </View>
              </SpotlightTourSpot>
              <SpotlightTourSpot
                spotId="avatarBottom"
                tipsComponent={(props) => <TutorialTipsBalloon {...props} />}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  )
}

export default MenuTop

export const styles = StyleSheet.create({
  container: {
    height:
      Platform.OS === 'android'
        ? 115 + (StatusBar?.currentHeight || 0)
        : undefined,
  },
  avatarView: {
    alignItems: 'center',
  },
  avatarNameText: {
    marginTop: 7,
    color: Color.White,
    fontWeight: 'bold',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
    fontSize: 14,
  },
  avatarNameSmallText: {
    marginTop: 7,
    color: Color.White,
    fontWeight: 'bold',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
    fontSize: 12,
  },
  warpAvatarCouple: {
    position: 'absolute',
  },
  warpAvatarPersonal: {
    position: 'absolute',
  },
})
