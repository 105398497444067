import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CalculationSymbol } from './types'

export type State = {
  displayValue: string
  isVisible: boolean
  lastInputSymbol?: CalculationSymbol
  lastValue?: number
  isNotEntered: boolean // ディスプレに数字は表示されているが、数字は未入力状態（＋等の記号を押した直後）
  animated?: boolean
  initialValue: number
  minusInput: boolean
  onChange?: (number: number) => void
}

type Reducer = {
  setCaluculatorDisplayValue: (
    state: State,
    action: PayloadAction<{ displayValue: string }>,
  ) => void
  setCalculatorLastInputSymbol: (
    state: State,
    action: PayloadAction<{
      lastInputSymbol?: CalculationSymbol
    }>,
  ) => void
  setCalculatorLastValue: (
    state: State,
    action: PayloadAction<{ lastValue: number }>,
  ) => void
  setCalculatorNotEntered: (
    state: State,
    action: PayloadAction<{ isNotEntered: boolean }>,
  ) => void
  openCalculator: (
    state: State,
    action: PayloadAction<{
      onChange?: (number: number) => void
      initialValue: number
      animated?: boolean
      minusInput?: boolean
    }>,
  ) => void
  closeCalculator: (
    state: State,
    action: PayloadAction<{ animated: boolean }>,
  ) => void
  clearCalculator: (state: State, action: PayloadAction) => void
}

const initialState: State = {
  displayValue: '0',
  isVisible: false,
  isNotEntered: false, // ディスプレに数字は表示されているが、数字は未入力状態（＋等の記号を押した直後）
  animated: true,
  initialValue: 0,
  minusInput: false,
}

const calendarSlice = createSlice<State, Reducer>({
  name: 'calendar',
  initialState,
  reducers: {
    setCaluculatorDisplayValue: (state, { payload }) => {
      state.displayValue = payload.displayValue
    },
    setCalculatorLastInputSymbol: (state, { payload }) => {
      state.lastInputSymbol = payload.lastInputSymbol
    },
    setCalculatorLastValue: (state, { payload }) => {
      state.lastValue = payload.lastValue
    },
    setCalculatorNotEntered: (state, { payload }) => {
      state.isNotEntered = payload.isNotEntered
    },
    openCalculator: (state, { payload }) => {
      state.animated = payload.animated
      state.onChange = payload.onChange
      state.initialValue = payload.initialValue
      state.isVisible = true
      state.minusInput = !!payload.minusInput
    },
    closeCalculator: (state, { payload }) => {
      state.animated = payload.animated
      state.isVisible = false
    },
    clearCalculator: (state) => {
      state.displayValue = initialState.displayValue
      state.isVisible = initialState.isVisible
      state.lastInputSymbol = initialState.lastInputSymbol
      state.lastValue = initialState.lastValue
      state.isNotEntered = initialState.isNotEntered
      state.animated = initialState.animated
      state.onChange = initialState.onChange
      state.initialValue = initialState.initialValue
    },
  },
})

export const {
  setCaluculatorDisplayValue,
  setCalculatorLastInputSymbol,
  setCalculatorLastValue,
  setCalculatorNotEntered,
  openCalculator,
  closeCalculator,
  clearCalculator,
} = calendarSlice.actions

export default calendarSlice.reducer
