import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import { faFileCertificate } from '@fortawesome/pro-duotone-svg-icons'
import { faHandshakeAngle } from '@fortawesome/pro-regular-svg-icons'
import { AdvertisementManager } from '@lib/api/Advertisement'
import Purchase from '@lib/api/Purchase'
import { StripeManager } from '@lib/api/Stripe'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import ProgressHud from '@lib/ProgressHUD'
import PurchaseManager from '@lib/PurchaseManager'
import SessionManager from '@lib/SessionManager'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store, { RootState } from '@redux/store'
import React, { useCallback, useMemo } from 'react'
import {
  FlatList,
  ListRenderItem,
  Platform,
  StyleSheet,
  View,
} from 'react-native'
import { Adjust } from 'react-native-adjust'
import {
  endConnection,
  getAvailablePurchases,
  initConnection,
  ProductPurchase,
} from 'react-native-iap'
import Toast from 'react-native-simple-toast'
import RNTestFlight from 'react-native-test-flight'
import { useSelector } from 'react-redux'
import MenuListItem, { MenuListItemProps } from './MenuListItem'
import { navigatePremiumPlanLP } from './PremiumPlan'

const SettingPremiumScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SettingApp'>
> = () => {
  const rank = useSelector(
    ({ profile }: RootState) => profile.userProfile?.rank,
  )

  const onPressPremiumPlan = useCallback(async () => {
    if (rank === 'premium') {
      NavigationService.navigate('PremiumPlanForPremiumUser')
    } else {
      const planVersion =
        (store.getState().profile.userProfile?.userId ?? 0) % 2 === 0
          ? '3'
          : 'split'

      if (NativeApp) {
        let adid = SessionManager.getAdid()
        if (!adid) {
          adid = await new Promise<string>((resolve) => {
            Adjust.getAttribution((attribution) => resolve(attribution.adid))
          })
        }
        if (adid) {
          try {
            const ads = await AdvertisementManager.getAdvertisements({
              adid,
            })
            const tracker = ads.find((v) => v.tracker)?.tracker

            // 1.ハレトケ
            // nonpaid_alliance_haretoke
            // https://app.adjust.com/e7qlw6s

            // 2.アイアル
            // nonpaid_alliance_air
            // https://app.adjust.com/lmxu9q0

            // <android>
            // 3.ハレトケ
            // nonpaid_alliance_haretoke
            // https://app.adjust.com/71osynb

            // 4.アイアル
            // nonpaid_alliance_air
            // https://app.adjust.com/eb2z1o3
            if (
              tracker &&
              ['e7qlw6s', 'lmxu9q0', '71osynb', 'eb2z1o3'].includes(tracker)
            ) {
              navigatePremiumPlanLP({ planVersion, planCode: '001' })
            } else {
              navigatePremiumPlanLP({ planVersion })
            }
          } catch (error) {
            CommonDialog.showError({ error })
          }
        } else {
          navigatePremiumPlanLP({ planVersion })
        }
      } else {
        // Web
        navigatePremiumPlanLP({ planVersion })
      }
    }
  }, [rank])

  const renderItem: ListRenderItem<MenuListItemProps> = useCallback(
    ({ item }) => {
      return <MenuListItem {...item} />
    },
    [],
  )

  const getLatestPurchase = useCallback(
    (purchases: ProductPurchase[]): ProductPurchase => {
      // First, sort the array, so the latest purchase is on top
      // https://github.com/dooboolab/react-native-iap/issues/532#issuecomment-503174711
      const sortedPurchases = purchases.sort(
        (a, b) => b.transactionDate - a.transactionDate,
      )
      const purchase = sortedPurchases[0]
      return purchase
    },
    [],
  )

  const nativeRestore = useCallback(async () => {
    try {
      ProgressHud.showWithStatus(
        '購入情報を確認中',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore ProgressHudのバグでwarningとなるので
        ProgressHud.ProgressHUDMaskType.Black,
      )

      await initConnection()

      let purchases = await getAvailablePurchases()

      if (purchases.length === 0) {
        throw new Error('プレミアム購入履歴がありませんでした。')
      }

      if (Platform.OS === 'ios') {
        purchases = [getLatestPurchase(purchases)]
      }

      purchases.forEach(async (purchase) => {
        const { transactionReceipt, purchaseToken, productId } = purchase
        if (transactionReceipt) {
          try {
            if (Platform.OS === 'ios') {
              await Purchase.appStoreReceiptVerification({
                receiptData: transactionReceipt,
                sandBox:
                  __DEV__ || RNTestFlight.isTestFlight ? true : undefined,
              })
            } else if (purchaseToken) {
              await Purchase.googlePlayReceiptVerification({
                productId: productId,
                purchaseToken: purchaseToken,
              })
            } else {
              throw new Error('purchaseToken is undefined')
            }

            await ProfileManager.fetchUserProflie()

            Toast.showWithGravity(
              '最新情報に更新しました',
              Toast.SHORT,
              Toast.BOTTOM,
            )

            ProgressHud.dismiss()
          } catch (error) {
            throw new Error(
              'アプリへのプラン反映が失敗ました。サポートへお問い合わせください',
            )
          }
        } else {
          throw new Error('transactionReceipt is undefined')
        }
      })
    } catch (error) {
      Log.info(error)
      ProgressHud.dismiss()
      CommonDialog.showError({ error })
    } finally {
      endConnection()
    }
  }, [getLatestPurchase])

  const webRestore = useCallback(async () => {
    try {
      ProgressHud.show()
      await StripeManager.verification()
      await ProfileManager.fetchUserProflie()
      CommonDialog.show('最新情報に更新しました')
      // Toast.showWithGravity('最新情報に更新しました', Toast.SHORT, Toast.BOTTOM)
    } catch {
      CommonDialog.show(
        'アプリへのプラン反映が失敗ました。サポートへお問い合わせください',
      )
    } finally {
      ProgressHud.dismiss()
    }
  }, [])

  const getPlanName = useCallback(() => {
    const iOSPlans = PurchaseManager.getPlans('ios')
    const androidPlans = PurchaseManager.getPlans('android')

    const externalService =
      !!store.getState().profile.userProfile?.externalService ||
      !!store.getState().profile.partnerProfile?.pertnerExternalService

    const purchasedPlan = store.getState().profile.userProfile?.premiumPlan

    const partnerPurchasedPlan = PurchaseManager.getPartnerPurchasedPlan()

    let premiumPlanName: string

    if (
      purchasedPlan &&
      [
        iOSPlans.yearlyPlanWithPartner,
        androidPlans.yearlyPlanWithPartner,
      ].includes(purchasedPlan)
    ) {
      premiumPlanName = '年額プラン（家族）'
    } else if (
      partnerPurchasedPlan &&
      [
        iOSPlans.yearlyPlanWithPartner,
        androidPlans.yearlyPlanWithPartner,
      ].includes(partnerPurchasedPlan)
    ) {
      premiumPlanName = '年額プラン（家族）'
    } else if (
      purchasedPlan &&
      [iOSPlans.yearlyPlan, androidPlans.yearlyPlan].includes(purchasedPlan)
    ) {
      premiumPlanName = '年額プラン（個人）'
    } else if (
      purchasedPlan &&
      [
        iOSPlans.monthlyPlanWithPartner,
        iOSPlans.monthlyPlanWithPartner2,
        androidPlans.monthlyPlanWithPartner,
        androidPlans.monthlyPlanWithPartner2,
      ].includes(purchasedPlan)
    ) {
      premiumPlanName = '月額プラン（家族）'
    } else if (
      partnerPurchasedPlan &&
      [
        iOSPlans.monthlyPlanWithPartner,
        iOSPlans.monthlyPlanWithPartner2,
        androidPlans.monthlyPlanWithPartner,
        androidPlans.monthlyPlanWithPartner2,
      ].includes(partnerPurchasedPlan)
    ) {
      premiumPlanName = '月額プラン（家族）'
    } else if (
      purchasedPlan &&
      [
        iOSPlans.monthlyPlan,
        iOSPlans.monthlyPlan2,
        androidPlans.monthlyPlan,
        androidPlans.monthlyPlan2,
      ].includes(purchasedPlan)
    ) {
      premiumPlanName = '月額プラン（個人）'
    } else {
      premiumPlanName =
        purchasedPlan ||
        (externalService ? 'OsidOriでんきの見直し\n（最大1年無料）' : '未登録')
    }

    return premiumPlanName
  }, [])

  const data: MenuListItemProps[] = useMemo(
    () => [
      {
        title: '会員ステータス',
        subTitle: 'プレミアムプランの登録状況',
        iconDefinition: faFileCertificate as never,
        value: rank === 'premium' ? getPlanName() : '未登録',
      },
      {
        title: 'プレミアムプランの購入',
        subTitle: 'プレミアム機能の確認、購入申込はこちら',
        iconImageSource: require('@images/icon_premium_mono.png'),
        disabledTintColor: true,
        onPress: onPressPremiumPlan,
        isDisplayArrow: true,
      },
      {
        title: '購入の復元',
        subTitle: '購入後に反映しない、以前の購入復元はこちら',
        iconDefinition: faHandshakeAngle as never,
        onPress: NativeApp ? nativeRestore : webRestore,
        isDisplayArrow: true,
      },
    ],
    [rank, getPlanName, onPressPremiumPlan, nativeRestore, webRestore],
  )

  return (
    <View style={styles.container}>
      <CommonHeader title="プレミアムプランの設定" />
      <FlatList
        data={data}
        renderItem={(item) => renderItem(item)}
        keyExtractor={(_, index) => `${index}`}
        ItemSeparatorComponent={() => ItemSeparator}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.LightGray,
  },
  flatList: {
    flex: 1,
  },
})

export default SettingPremiumScreen
