import Color from '@lib/Color'
import store from '@redux/store'
import { ImageBackground, Modal, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

const TutorialModal = ({
  visible,
  onPressStart,
}: {
  visible: boolean
  onPressStart: () => void
}) => {
  return (
    <Modal
      presentationStyle="overFullScreen"
      animationType="fade"
      transparent={true}
      visible={visible}
      style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
      <ImageBackground
        source={require('@images/tutorial_bg.png')}
        resizeMode="contain"
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          marginTop: '-30%',
        }}>
        <StyledContainer>
          {store.getState().account.accountMode === 'family' ? (
            <>
              <StyledFamilyDialogImage
                source={require('@images/tutorial_dialog.png')}></StyledFamilyDialogImage>
              <StyledButton onPress={onPressStart}>
                <StyledButtonImage
                  type="family"
                  source={require('@images/tutorial_start_button.png')}
                />
              </StyledButton>
            </>
          ) : (
            <>
              <StyledPersonalDialogImage
                source={require('@images/tutorial_dialog_personal.png')}></StyledPersonalDialogImage>
              <StyledButton onPress={onPressStart}>
                <StyledButtonImage
                  type="personal"
                  source={require('@images/tutorial_start_button.png')}
                />
              </StyledButton>
            </>
          )}
        </StyledContainer>
      </ImageBackground>
    </Modal>
  )
}

const StyledContainer = styled.View`
  width: ${(270 / 375) * 100}%;
  align-items: center;
`

const StyledFamilyDialogImage = styled.Image.attrs({
  resizeMode: 'contain',
})`
  margin-top: 20%;
  width: 100%;
  height: auto;
  aspect-ratio: ${270 / 307};
`

const StyledPersonalDialogImage = styled.Image.attrs({
  resizeMode: 'contain',
})`
  margin-top: 20%;
  width: 100%;
  height: auto;
  aspect-ratio: ${1079 / 1251};
`

const StyledButton = styled(TouchableOpacity)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  align-self: center;
`

const StyledButtonImage = styled.Image<{ type: 'personal' | 'family' }>`
  width: 100%;
  height: auto;
  aspect-ratio: ${268 / 44};
  tint-color: ${({ type }) =>
    type === 'family' ? Color.OsidoriGreen : Color.UIBankPrimary};
`

export default TutorialModal
