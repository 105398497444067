import { GetHomeExpensesSummaryResponse } from '@lib/api/Financial'
import Color from '@lib/Color'
import {
  getPrevMonthStartDate,
  getStartDate,
  parseMonthKey,
  StartDateSettingOption,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import FinancialManager from '@lib/FinancialManager'
import ProfileManager from '@lib/ProfileManager'
import { useCallback, useEffect } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { PieChart, PieChartData } from 'react-native-svg-charts'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSummaryTransactions,
  selectUserAccountType,
  selectYearMonth,
  setIsProcessingShare,
  setSummaryTransactions,
} from '../slice'

const FamilyColor = '#79C7AC'
const UserColor = '#9AE1F3'
const PartnerColor = '#F7E379'

const SharePanelContent = () => {
  const dispatch = useDispatch()
  const userAccountType = useSelector(selectUserAccountType)
  const yearMonth = useSelector(selectYearMonth)
  const summaryTransactions = useSelector(selectSummaryTransactions)

  const fetchSummary = useCallback(async () => {
    if (!yearMonth || !userAccountType) return
    dispatch(setIsProcessingShare(true))

    try {
      const settings = getStartDateSettings(userAccountType)
      const { year, month } = parseMonthKey(yearMonth)

      const startDate = getStartDate(
        year,
        month,
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      )

      const prevMonthStartDate = getPrevMonthStartDate(
        year,
        month,
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      )

      const thisMonth = await FinancialManager.fetchHomeExpensesSummary({
        date: startDate.format('YYYYMMDD'),
        userAccountType,
      })

      const prevMonthMonth = await FinancialManager.fetchHomeExpensesSummary({
        date: prevMonthStartDate.format('YYYYMMDD'),
        userAccountType,
      })

      dispatch(setSummaryTransactions([thisMonth, prevMonthMonth]))
    } finally {
      dispatch(setIsProcessingShare(false))
    }
  }, [dispatch, userAccountType, yearMonth])

  useEffect(() => {
    fetchSummary()
  }, [fetchSummary, userAccountType, yearMonth])

  const summary = summaryTransactions[0]
  const monthOver =
    summaryTransactions.length >= 2
      ? summaryTransactions[0].totalAmount - summaryTransactions[1].totalAmount
      : undefined

  return (
    <>
      <View style={styles.container}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text
            style={{
              marginTop: 4,
              fontSize: 16,
              fontWeight: 'normal',
              color: 'rgb(58, 58, 58)',
            }}>
            支出の分担
          </Text>
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}>
              {monthOver !== undefined &&
                (monthOver > 0 ? (
                  <Image
                    source={require('@images/report/up-icon.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ) : monthOver < 0 ? (
                  <Image
                    source={require('@images/report/down-icon.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ) : (
                  <Image
                    source={require('@images/report/arrow-flat.png')}
                    resizeMode="contain"
                    style={{ width: 22, height: 22 }}
                  />
                ))}
              <Text
                style={{
                  marginLeft: 3,
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: 'rgb(58, 58, 58)',
                }}>
                {summary !== undefined ? summary.totalAmount.jpCurrency() : '-'}
              </Text>
            </View>
            <View
              style={{
                marginTop: 5,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text style={{ fontSize: 12, color: 'rgb(102, 102, 102)' }}>
                前月比
              </Text>
              <Text
                style={{
                  marginLeft: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: Color.number(monthOver),
                }}>
                {monthOver !== undefined
                  ? monthOver.jpCurrency({
                      showPlus: true,
                      showPlusMinus: true,
                    })
                  : '-'}
              </Text>
            </View>
          </View>
        </View>
        <Chart summary={summaryTransactions} />
      </View>
      <View style={styles.footerView}>
        <Text style={styles.footerTitle}>ふたりの差分</Text>
        <Text style={styles.footerAmount}>
          {summary !== undefined
            ? summary.ownerPartnerDiffAmount.jpCurrency()
            : '-'}
        </Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingHorizontal: 10,
    paddingBottom: 15,
  },
  monthlyBalanceNoData: {
    width: '100%',
    height: 200,
    paddingTop: 20,
    paddingHorizontal: 20,
    backgroundColor: Color.White,
    marginTop: 15,
  },
  titleMonthlyBalanceNoData: {
    color: '#555555',
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 21,
  },
  contentMonthlyBalanceNoData: {
    width: '100%',
    flexDirection: 'row',
  },
  iconChartCircle: {
    width: 110,
    height: 110,
    flex: 1,
  },
  contentMonthlyBalanceNoDataRight: {
    color: '#8e8e8e',
    fontSize: 13,
    fontWeight: 'normal',
  },
  ViewMonthlyBalanceNoDataRight: {
    flex: 1.3,
  },
  wrapButtonContentMonthlyBalanceNoDataRight: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  footerView: {
    borderTopColor: Color.LightGray,
    borderTopWidth: 2,
    marginRight: 10,
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  footerTitle: {
    color: Color.DefaultText,
    fontSize: 12,
    fontWeight: 'normal',
    marginRight: 10,
  },
  footerAmount: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  pieChartRight: {
    flex: 1,
    justifyContent: 'space-between',
  },
  chartItemTitleRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chartItemTitleText: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  chartItemAmount: {
    fontSize: 18,
    fontWeight: 'normal',
    textAlign: 'right',
    paddingRight: 20,
  },
  chartItemRate: {
    fontSize: 14,
    fontWeight: 'normal',
    color: Color.Gray,
    textAlign: 'right',
    paddingRight: 20,
  },
  li: {
    width: 12,
    height: 12,
    marginEnd: 5,
  },
  liFamily: {
    backgroundColor: FamilyColor,
  },
  liUser: {
    backgroundColor: UserColor,
  },
  liPartner: {
    backgroundColor: PartnerColor,
  },
  tapDetailView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 15,
    paddingRight: 20,
    alignItems: 'center',
  },
  tapDetailText: {
    color: '#888888',
    fontSize: 13,
    fontWeight: 'normal',
  },
})

export default SharePanelContent

export const Chart = ({
  summary,
}: {
  summary: GetHomeExpensesSummaryResponse[]
}) => {
  const thisMonth = summary[0]
  const prevMonth = summary[1]

  const pieChartData: PieChartData[] = thisMonth
    ? [
        {
          value: Math.abs(thisMonth.family.amount),
          svg: {
            fill: FamilyColor,
          },
          key: FamilyColor,
        },
        {
          value: Math.abs(thisMonth.owner.amount),
          svg: {
            fill: UserColor,
          },
          key: UserColor,
        },
        {
          value: Math.abs(thisMonth.partner.amount),
          svg: {
            fill: PartnerColor,
          },
          key: PartnerColor,
        },
      ]
    : []

  const pieChartSize = wp('35%')

  const row = (
    title: string,
    color: string,
    amount?: number,
    monthOver?: number,
    rate?: number,
  ) => {
    return (
      <View style={chartStyles.pieChartRight}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <View style={chartStyles.chartItemTitleRow}>
            <View style={[chartStyles.li, { backgroundColor: color }]} />
            <Text style={chartStyles.chartItemTitleText}>{title}</Text>
          </View>
        </View>

        <View
          style={{
            alignItems: 'flex-end',
          }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={chartStyles.chartItemAmount}>
              {amount !== undefined ? amount.jpCurrency() : '-'}
            </Text>
            <Text style={chartStyles.chartItemRate}>
              {rate !== undefined ? rate : '-'}%
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 'normal',
                color: 'rgb(102, 102, 102)',
              }}>
              前月比
            </Text>
            <Text
              style={{
                marginLeft: 10,
                fontSize: 14,
                fontWeight: 'bold',
                color: Color.number(monthOver),
              }}>
              {monthOver !== undefined
                ? monthOver.jpCurrency({
                    showPlus: true,
                    showPlusMinus: true,
                  })
                : '-'}
            </Text>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={chartStyles.container}>
      {/* チャート */}
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 15,
        }}>
        <PieChart
          style={{ width: pieChartSize, height: pieChartSize }}
          data={pieChartData}
          outerRadius="98%"
          innerRadius="75%"
          padAngle={0.04}
        />
        <View style={{ position: 'absolute' }}>
          <Text
            style={{
              fontSize: wp('3%'),
              fontWeight: 'normal',
              color: Color.DefaultText,
              textAlign: 'center',
              marginBottom: 1,
            }}>
            合計
          </Text>
          <Text
            style={{
              fontSize: wp('3%'),
              fontWeight: 'normal',
              textAlign: 'center',
            }}>
            {thisMonth !== undefined ? thisMonth.totalAmount.jpCurrency() : '-'}
          </Text>
        </View>
      </View>

      {/* テキスト */}
      {row(
        '家族',
        FamilyColor,
        thisMonth?.family.amount,
        thisMonth !== undefined && prevMonth !== undefined
          ? thisMonth.family.amount - prevMonth.family.amount
          : undefined,
        thisMonth?.family.rate,
      )}
      {row(
        ProfileManager.getName('user'),
        UserColor,
        thisMonth?.owner.amount,
        thisMonth !== undefined && prevMonth !== undefined
          ? thisMonth.owner.amount - prevMonth.owner.amount
          : undefined,
        thisMonth?.owner.rate,
      )}
      {row(
        ProfileManager.getName('partner'),
        PartnerColor,
        thisMonth?.partner.amount,
        thisMonth !== undefined && prevMonth !== undefined
          ? thisMonth.partner.amount - prevMonth.partner.amount
          : undefined,
        thisMonth?.partner.rate,
      )}
    </View>
  )
}

const chartStyles = StyleSheet.create({
  container: {},
  pieChartRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 10,
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  chartItemTitleRow: {
    marginTop: 3,
    flexDirection: 'row',
    alignItems: 'center',
  },
  chartItemTitleText: {
    fontSize: 14,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  chartItemAmount: {
    fontSize: 18,
    fontWeight: 'normal',
    textAlign: 'right',
    paddingRight: 8,
  },
  chartItemRate: {
    fontSize: 15,
    fontWeight: 'normal',
    color: Color.Gray,
    textAlign: 'right',
  },
  li: {
    width: 14,
    height: 14,
    marginRight: 6,
  },
  liFamily: {
    backgroundColor: FamilyColor,
  },
  liUser: {
    backgroundColor: UserColor,
  },
  liPartner: {
    backgroundColor: PartnerColor,
  },
  tapDetailView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 15,
    paddingRight: 20,
    alignItems: 'center',
  },
  tapDetailText: {
    color: '#888888',
    fontSize: 13,
    fontWeight: 'normal',
  },
})
