export {}

type Props = {
  showPlus?: boolean
  showPlusMinus?: boolean
}

declare global {
  interface Number {
    numberFormat(options?: Props): string
    jpCurrency(options?: Props): string
  }
}

Number.prototype.numberFormat = function (options: Props) {
  const symbol =
    options?.showPlus && this > 0
      ? '+'
      : options?.showPlusMinus && this == 0 // === にしてはいけない
      ? '±'
      : ''
  return symbol + this.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,')
}

Number.prototype.jpCurrency = function (options: Props) {
  return '¥' + this.numberFormat(options)
}
