import ActionSheet from '@components/ActionSheet'
import AdView from '@components/AdView'
import AppButton from '@components/AppButton'
import BlurView from '@components/BlurView'
import HomeBudgetPremiumBlurView from '@components/BudgetPremiumBlurView'
import IconImage from '@components/IcomImage'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import { UserAccountType } from '@interfaces/Account'
import AdfurikunManager from '@lib/AdfurikunManager'
import { APIError } from '@lib/api'
import { BudgetManager } from '@lib/Budget'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import {
  getDateRange,
  getStartDate,
  getYearlyDateRange,
  parseMonthKey,
  StartDateSettingOption,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import DateUtilsV1 from '@lib/DateUtilsV1'
import FinancialManager from '@lib/FinancialManager'
import NavigationService, { useNavigatableScreen } from '@lib/NavigationService'
import ProgressHUD from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import BudgetCategoryItem from '@Screen/Main/Home/Budget/BudgetCategoryItem/BudgetCategoryItem'
import { BudgetCategoryItemProps } from '@Screen/Main/Home/Budget/BudgetCategoryItem/types'
import { budgetSelector } from '@Screen/Main/Home/Budget/selector'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActivityIndicator,
  Image,
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'
import {
  BudgetSettingButton,
  BudgetSettingButtonTitle,
  DropdownContainer,
  DropdownIconImage,
  DropdownText,
  ListHeaderContainer,
  ListHeaderTopContainer,
  StyledBudgetCategoryItem,
  StyledFamilyShareToggleButton,
} from './styles'

// let listeners: string[] = []

const fetchBudget = async (
  userAccountType: UserAccountType,
  date: string,
  isYearly: boolean,
) => {
  const settings = getStartDateSettings()

  const thisMonth = DateUtilsV1.getRangeOfMonths(moment(date), false).startDate
  const prevMonth = isYearly
    ? getYearlyDateRange(
        thisMonth.year() - 1,
        settings.start,
        StartDateSettingOption.None,
      ).startDate
    : getDateRange(
        moment(thisMonth).subtract(1, 'month').year(),
        moment(thisMonth).subtract(1, 'month').month() + 1,
        settings.start,
        StartDateSettingOption.None,
      ).startDate

  const callAPI = async () => {
    await BudgetManager.fetchBudget({
      userAccountType,
      year: prevMonth.year(),
      month: isYearly ? undefined : prevMonth.month() + 1,
    })
    await BudgetManager.fetchBudget({
      userAccountType,
      year: thisMonth.year(),
      month: isYearly ? undefined : thisMonth.month() + 1,
    })
  }

  await callAPI()
}

const fetchBalance = async (
  userAccountType: UserAccountType,
  isFamilyShareMode: boolean,
  date: string,
  isYearly: boolean,
) => {
  const callAPI = async () => {
    await FinancialManager.fetchBalance(
      userAccountType,
      isFamilyShareMode,
      date,
      isYearly,
    )
    await FinancialManager.fetchBalance(
      userAccountType,
      isFamilyShareMode,
      isYearly
        ? moment(date).subtract(1, 'year').toISOString()
        : moment(date).subtract(10, 'day').toISOString(),
      isYearly,
    )
  }

  await callAPI()
}

const ActionSheetIndex = {
  Budget: 0,
  PrevMonth: 1,
}

const onPressBudgetSettingButton = () => {
  NavigationService.navigate('BudgetSetting')
}

const HomeExpensesCategoryBudgets: React.FC<
  StackScreenProps<RootStackParamList, 'HomeExpensesCategoryBudgets'>
> = ({ navigation }) => {
  useNavigatableScreen('HomeExpensesCategoryBudgets')

  const financial = useSelector((state: RootState) => state.financial)
  const account = useSelector((state: RootState) => state.account)
  const profile = useSelector((state: RootState) => state.profile)
  const budget = useSelector(budgetSelector)[account.accountMode]
  const selectedYearMonth = useSelector((state: RootState) => state.financial)[
    account.accountMode
  ]?.homeExpensesSelectedYearMonth
  const isYearly = useSelector(
    ({ financial }: RootState) => financial.homeExpensesIsYearly,
  )

  const prevIsYearRef = useRef(isYearly)

  const isFocused = useIsFocused()

  const startDate = useMemo(() => {
    if (!selectedYearMonth) return undefined

    const { year, month } = parseMonthKey(selectedYearMonth)
    const settings = getStartDateSettings()

    return getStartDate(
      year,
      month,
      settings.start,
      settings.startDateSettingOption,
    ).format()
  }, [selectedYearMonth])

  const [dropdownType, setDropdownType] = useState(ActionSheetIndex.Budget)
  const [refreshing, setRefreshing] = useState(false)
  const [isPlanError, setIsPlanError] = useState(false)
  const [isLoadMovieAwardSuccess, setIsLoadMovieAwardSuccess] = useState<
    boolean | undefined
  >(false)

  // const didMount = useRef(false)

  const onPressDropdown = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: ['予算比', isYearly ? '前年比' : '前月比', 'キャンセル'],
        cancelButtonIndex: 2,
      },
      (index: number) => {
        if (index === ActionSheetIndex.PrevMonth) {
          setDropdownType(ActionSheetIndex.PrevMonth)
        } else if (index === ActionSheetIndex.Budget) {
          setDropdownType(ActionSheetIndex.Budget)
        }
      },
    )
  }, [isYearly])

  const fetchData = useCallback(async () => {
    if (!startDate) return

    let isNeedShowProgressHUD: boolean
    if (prevIsYearRef.current !== isYearly) {
      isNeedShowProgressHUD = true
      prevIsYearRef.current = isYearly
    } else {
      isNeedShowProgressHUD = false
    }

    try {
      if (isNeedShowProgressHUD) {
        ProgressHUD.show()
      }
      await fetchBudget(account.accountMode, startDate, isYearly)
      await fetchBalance(
        account.accountMode,
        account.isFamilyShareMode,
        startDate,
        isYearly,
      )
      setIsPlanError(false)
    } catch (error) {
      if (
        error instanceof APIError &&
        (error.response?.errorCode === '007004' ||
          error.response?.errorCode === '007007')
      ) {
        TrackingUtils.repro.track('【Screen】upper limit_pl', 'Screen')
        setIsPlanError(true)
        setIsLoadMovieAwardSuccess(undefined)
        AdfurikunManager.loadMovieReward((isLoadMovieAwardSuccess) => {
          setIsLoadMovieAwardSuccess(isLoadMovieAwardSuccess)
        })
      } else {
        CommonDialog.showError({ error })
      }
    } finally {
      if (isNeedShowProgressHUD) {
        ProgressHUD.dismiss()
      }
    }
  }, [startDate, account.accountMode, account.isFamilyShareMode, isYearly])

  const listHeader = useMemo(() => {
    const thisMonthYYYYMM = DateUtilsV1.monthKey(moment(startDate))
    const prevMonthYYYYMM = isYearly
      ? DateUtilsV1.monthKey(moment(startDate).subtract(1, 'year'))
      : DateUtilsV1.prevMonthKey(thisMonthYYYYMM)

    const budgetAmount =
      budget[isYearly ? thisMonthYYYYMM.substring(0, 4) : thisMonthYYYYMM]
        ?.amount
    const expenseAmount =
      financial[account.accountMode].monthsPL[
        isYearly ? thisMonthYYYYMM.substring(0, 4) : thisMonthYYYYMM
      ]?.expenseAmount || 0
    const prevMonthsExpenseAmount =
      financial[account.accountMode].monthsPL[
        isYearly ? prevMonthYYYYMM.substring(0, 4) : prevMonthYYYYMM
      ]?.expenseAmount || 0

    return (
      <ListHeaderContainer>
        <ListHeaderTopContainer>
          <DropdownContainer onPress={onPressDropdown}>
            <DropdownText>
              {dropdownType === ActionSheetIndex.Budget
                ? '予算比'
                : isYearly
                ? '前年比'
                : '前月比'}
            </DropdownText>
            <DropdownIconImage name="ArrowDown" />
          </DropdownContainer>
          {account.accountMode === 'user' && <StyledFamilyShareToggleButton />}
        </ListHeaderTopContainer>

        {dropdownType === ActionSheetIndex.Budget && (
          <BudgetSettingButton onPress={onPressBudgetSettingButton}>
            <BudgetSettingButtonTitle>予算を設定する</BudgetSettingButtonTitle>
            <IconImage name="ArrowRight" />
          </BudgetSettingButton>
        )}

        <StyledBudgetCategoryItem
          expenseAmount={expenseAmount}
          budgetAmount={budgetAmount || 0}
          prevExpenseAmount={prevMonthsExpenseAmount}
          displayType={
            dropdownType === ActionSheetIndex.Budget ? 'budget' : 'prevMonth'
          }
          isYearly={isYearly}
        />
      </ListHeaderContainer>
    )
  }, [
    startDate,
    budget,
    financial,
    account.accountMode,
    onPressDropdown,
    dropdownType,
    isYearly,
  ])

  const sections =
    useMemo((): SectionListData<BudgetCategoryItemProps | null>[] => {
      if (!budget || !financial) return []

      const thisMonth = moment(startDate)
      const thisMonthYYYYMM = DateUtilsV1.monthKey(thisMonth)

      const thisMonthPL =
        financial[account.accountMode].monthsPL[
          isYearly ? thisMonthYYYYMM.substring(0, 4) : thisMonthYYYYMM
        ]
      if (!thisMonthPL) return []

      const prevYearMonth = isYearly
        ? moment(thisMonth).subtract(1, 'year')
        : moment(thisMonth).subtract(10, 'day')
      const prevMonthYYYYMM = DateUtilsV1.monthKey(prevYearMonth)
      const prevMonthPL =
        financial[account.accountMode].monthsPL[
          isYearly ? prevMonthYYYYMM.substring(0, 4) : prevMonthYYYYMM
        ]

      if (dropdownType === ActionSheetIndex.PrevMonth && !prevMonthPL) return []

      const thisMonthBudget =
        budget[isYearly ? thisMonthYYYYMM.substring(0, 4) : thisMonthYYYYMM]
      if (dropdownType === ActionSheetIndex.Budget && !thisMonthBudget)
        return []

      // 支出Map { categoryId: amount }
      const expenseMap = thisMonthPL.expenseList.reduce<{
        [key: string]: number
      }>((v1, v2) => {
        v1[v2.categoryId] = v2.amount
        return v1
      }, {})

      // prevMonth 支出Map { categoryId: amount }
      const prevMonthExpenseMap =
        dropdownType === ActionSheetIndex.PrevMonth
          ? prevMonthPL.expenseList.reduce<{
              [key: string]: number
            }>((v1, v2) => {
              v1[v2.categoryId] = v2.amount
              return v1
            }, {})
          : {}

      const categoryBudgets =
        thisMonthBudget?.atGroupedCategoryBudgets.map<BudgetCategoryItemProps>(
          (v) => ({
            categoryId: v.atGroupedCategoryId,
            categoryName: v.categoryName1,
            expenseAmount: expenseMap[v.atGroupedCategoryId] || 0,
            budgetAmount: v.amount,
            prevExpenseAmount: prevMonthExpenseMap[v.atGroupedCategoryId] || 0,
            displayType:
              dropdownType === ActionSheetIndex.Budget ? 'budget' : 'prevMonth',
            onPress: () => {
              NavigationService.navigate('BudgetCategoryTransactions', {
                date: selectedYearMonth,
                categoryId: v.atGroupedCategoryId,
                displayType:
                  dropdownType === ActionSheetIndex.Budget
                    ? 'budget'
                    : 'prevMonth',
              })
            },
            isYearly,
          }),
        ) || []

      const validCategoryBudgets =
        categoryBudgets?.filter((v) => v.budgetAmount || 0 > 0) || []

      const invalidCategoryBudgets =
        categoryBudgets?.filter((v) => v.budgetAmount === 0) || []

      return dropdownType === ActionSheetIndex.Budget
        ? [
            {
              title: 'カテゴリー予算',
              data: validCategoryBudgets,
            },
            {
              title: '',
              data: validCategoryBudgets.length === 0 ? [null] : [],
            },
            {
              title: '予算外',
              data: invalidCategoryBudgets,
            },
          ]
        : [
            {
              title: 'カテゴリー予算',
              data: categoryBudgets,
            },
          ]
    }, [
      budget,
      financial,
      startDate,
      account.accountMode,
      isYearly,
      dropdownType,
      selectedYearMonth,
    ])

  useEffect(() => {
    if (isFocused) {
      if (account.accountMode === 'family') {
        TrackingUtils.repro.track('【Screen】Family_PL_analysis', 'Screen')
      } else {
        TrackingUtils.repro.track('【Screen】Personal_PL_analysis', 'Screen')
      }

      TrackingUtils.ga.pageview({
        page: `HomeExpenses-HomeExpenses-${
          account.accountMode === 'family' ? 'Family' : 'User'
        }`,
        title: `${
          account.accountMode === 'family' ? '家族' : '個人'
        }の家計簿 分析`,
      })
    }
  }, [account.accountMode, isFocused])

  useEffect(() => {
    if (isFocused) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, startDate, isYearly])

  // useEffect(() => {
  //   // if (didMount.current && navigation.isFocused()) {

  //   if (navigation.isFocused()) {
  //     fetchData()
  //   } else {
  //     didMount.current = true
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startDate])

  if (sections.length === 0 && !isPlanError) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          backgroundColor: 'white',
        }}>
        <ActivityIndicator color={Color.Gray} />
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <SectionList
          keyExtractor={(item) => JSON.stringify(item)}
          renderSectionHeader={(info) =>
            info.section.title ? (
              <SectionListHeader
                title={info.section.title}
                style={{ color: '#666666', fontWeight: 'normal' }}
              />
            ) : null
          }
          renderItem={renderItem}
          sections={sections}
          ItemSeparatorComponent={() => ItemSeparator}
          ListHeaderComponent={listHeader}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true)
                await fetchData()
                setRefreshing(false)
              }}
            />
          }
          ListFooterComponent={() => <View style={{ height: 24 }} />}
        />
        {profile.userProfile?.rank === 'free' && (
          <AdView type="320x50" delay={500} />
        )}
      </View>
      {account.accountMode === 'user' &&
        profile.userProfile?.rank === 'free' && (
          <HomeBudgetPremiumBlurView
          // disableBlur={Platform.OS === 'android' && !navigation.isFocused()}
          />
        )}
      {isPlanError && (
        <BlurView disableBlur={Platform.OS === 'android' && !isFocused}>
          {isLoadMovieAwardSuccess === undefined && (
            <View style={styles.absolute}>
              <ActivityIndicator color={Color.Gray} />
            </View>
          )}
        </BlurView>
      )}
      {isPlanError && isLoadMovieAwardSuccess !== undefined && (
        <View style={[styles.absolute, { alignItems: 'center' }]}>
          <Text style={styles.premiumPlanMessage}>
            <Text style={{ color: '#ff7200' }}>プレミアムプラン</Text>
            に加入すると{'\n'}
            過去の収支を表示できます
          </Text>
          <Image
            source={require('@images/premium/premium-4.png')}
            style={{
              width: 195,
              height: Math.min(103, wp(23)),
              resizeMode: 'contain',
            }}
          />
          <AppButton
            title="詳しく見る"
            style={{
              width: wp(80),
              height: wp(12),
              marginTop: wp(4),
            }}
            textStyle={{ fontSize: wp(4), fontWeight: 'bold' }}
            onPress={() => {
              TrackingUtils.repro.track('【Tap】upper limit_pl', 'Tap')
              navigatePremiumPlanLP({ planCode: '001' })
              TrackingUtils.repro.setScreenTrackingAfterPurchase(
                '【Screen】purchase completed via upper limit_pl',
              )
            }}
          />
          <Text style={styles.premiumTextOr}>または</Text>
          <Text style={styles.premiumPlanMessage}>
            CMを1回視聴すると<Text style={{ color: '#4ba3f4' }}>無料</Text>で
            {'\n'}
            一定期間機能を利用できます
          </Text>
          <View
            style={{
              backgroundColor: '#eeeeee',
              paddingHorizontal: wp(6),
              paddingVertical: wp(1),
              borderRadius: wp(3),
              marginBottom: wp(4),
            }}>
            <Text
              style={{
                color: '#333333',
                fontSize: wp(8),
                fontWeight: 'normal',
              }}>
              ▶
            </Text>
          </View>
          {isLoadMovieAwardSuccess ? (
            <AppButton
              title="無料で使う"
              style={{
                width: wp(80),
                height: wp(12),
                backgroundColor: '#4ba3f4',
              }}
              textStyle={{ fontSize: wp(4), fontWeight: 'bold' }}
              onPress={async () => {
                AdfurikunManager.playMovieReward((success: boolean) => {
                  if (success) {
                    AdfurikunManager.adViewingComplete(true, async () => {
                      setRefreshing(true)
                      await fetchData()
                      setRefreshing(false)
                    })
                  } else {
                    AdfurikunManager.adViewingComplete(false)
                  }
                })
              }}
            />
          ) : (
            <Text
              style={{
                color: 'white',
                fontSize: wp(4),
                fontWeight: 'normal',
                textAlign: 'center',
              }}>
              ただいま表示するCMがありません🙇‍♂️{'\n'}
              時間を置いて再度アクセスしてください。
            </Text>
          )}
        </View>
      )}
    </View>
  )
}

const renderItem: SectionListRenderItem<BudgetCategoryItemProps | null> = ({
  item,
}) => {
  return item ? (
    <BudgetCategoryItem {...item} />
  ) : (
    <View style={{ paddingHorizontal: 20, paddingVertical: 20 }}>
      <AppButton
        type="white"
        title="予算を設定する"
        onPress={() => NavigationService.navigate('BudgetSetting')}
        textStyle={{ fontWeight: 'bold' }}
      />
    </View>
  )
}

export default HomeExpensesCategoryBudgets

const styles = StyleSheet.create({
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  premiumTextOr: {
    margin: wp(3.5),
    color: 'white',
    fontSize: wp(4),
    fontWeight: 'bold',
  },
})
