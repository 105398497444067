import Color from '@lib/Color'
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'

interface CoupleButtonProps extends TouchableOpacityProps {
  buttonType: 'single' | 'couple'
  isOn: boolean
  title: string
}

const CoupleButton = (props: CoupleButtonProps) => {
  const { buttonType, isOn, title, style, ...touchableOpacityProps } = props

  const imageSource =
    buttonType === 'single'
      ? require('@assets/images/icons/icon-woman-small.png')
      : require('@assets/images/icons/icon-couple-small.png')
  const containerStyle = isOn ? styles.backgroundOn : styles.backgroundOff
  const imageStyle = isOn ? styles.imageOn : styles.imageOff
  const textStyle = isOn ? styles.textOn : styles.textOff

  return (
    <TouchableOpacity
      style={[styles.container, containerStyle, style]}
      {...touchableOpacityProps}>
      <Image source={imageSource} style={imageStyle} />
      <Text style={textStyle}>{title}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: 106,
    paddingHorizontal: 10,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
  },
  backgroundOn: {
    backgroundColor: Color.Primary,
  },
  backgroundOff: {
    backgroundColor: '#f0f0f0',
  },
  imageOn: {
    tintColor: 'white',
    resizeMode: 'contain',
    width: 26,
    height: 16,
    // ...(Platform.OS === 'web' ? { width: 26, height: 16 } : {}),
  },
  imageOff: {
    tintColor: Color.Gray,
    resizeMode: 'contain',
    width: 26,
    height: 16,
    // ...(Platform.OS === 'web' ? { width: 26, height: 16 } : {}),
  },
  textOn: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'white',
    marginLeft: 3,
  },
  textOff: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Color.Gray,
    marginLeft: 3,
  },
})

export default CoupleButton
