/* eslint-disable @typescript-eslint/no-var-requires */
import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import FullWidthImage from '@components/FullWidthImage'
import PremiumPlan from '@lib/api/PremiumPlan'
import { PartnerProfile, UserProfile } from '@lib/api/User'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import PurchaseManager from '@lib/PurchaseManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList, WebViewScreenParams } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React from 'react'
import {
  Image,
  NativeEventSubscription,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import Swiper from 'react-native-swiper'
import { connect } from 'react-redux'

const SwipeImageFinancialAccounts = require('@images/premium/premium-swipe-1.webp')
const SwipeImageCategory = require('@images/premium/premium-swipe-3.png')
const SwipeImageAutoTransaction = require('@images/premium/premium-repeat.png')
const SwipeImageOldData = require('@images/premium/premium-swipe-4.png')
const SwipeImageGoals = require('@images/premium/premium-swipe-2.webp')
const SwipeImageAd = require('@images/premium/premium-swipe-5.png')
const SwipeImageSupport = require('@images/premium/premium-swipe-6.png')
const SwipeImageCalendar = require('@images/premium/premium-calendar.png')
const SwipeImageBudget = require('@images/premium/premium-budget.png')

interface State {
  startSwiper: boolean
  isLoadedProfiles: boolean
}

interface StateProps {
  userProfile?: UserProfile
  partnerProfile?: PartnerProfile
}

class PremiumPlanForPremiumUserScreen extends React.Component<
  StateProps &
    StackScreenProps<RootStackParamList, 'PremiumPlanForPremiumUser'> &
    State
> {
  state = {
    startSwiper: false,
    isLoadedProfiles: false,
  }

  private swiper = React.createRef<Swiper>()

  private backHandler?: NativeEventSubscription

  private unsubscribe?: () => void

  async componentDidMount() {
    PremiumPlan.getPlamiumPlans()

    this.unsubscribe = this.props.navigation.addListener('focus', async () => {
      this.setState({ isLoadedProfiles: false })
      await this.fetchProfiles()
      this.setState({ isLoadedProfiles: true })
    })
    this.backHandler = HardwareBackPressHandler.addListener(this.goBack)
    setTimeout(() => {
      this.setState({ startSwiper: true })
    }, 100)

    TrackingUtils.repro.track('【Screen】about premiumplan', 'Screen')
  }

  componentWillUnmount() {
    this.backHandler?.remove()
    this.unsubscribe && this.unsubscribe()
  }

  private fetchProfiles = async () => {
    try {
      await ProfileManager.fetchUserProflie()
      await ProfileManager.fetchPartnerProflie()
    } catch (error) {
      CommonDialog.showError({ error, onPress: this.goBack })
    }
  }

  private goBack = () => {
    const goBack = this.props.route.params?.goBack
    if (goBack) {
      goBack()
    } else {
      NavigationService.goBack()
    }
  }

  private renderPremiumUserView = () => {
    const externalService =
      !!this.props.userProfile?.externalService ||
      !!this.props.partnerProfile?.pertnerExternalService

    const buttonWidth = externalService ? '100%' : (wp('100%') - 56) / 2

    const iOSPlans = PurchaseManager.getPlans('ios')
    const androidPlans = PurchaseManager.getPlans('android')

    const purchasedPlan = this.props.userProfile?.premiumPlan
    // console.log({ purchasedPlan })

    const partnerPurchasedPlan = PurchaseManager.getPartnerPurchasedPlan()

    let premiumPlanName: string
    let premiumPlanButtonName: string

    if (
      purchasedPlan &&
      [
        iOSPlans.yearlyPlanWithPartner,
        androidPlans.yearlyPlanWithPartner,
      ].includes(purchasedPlan)
    ) {
      premiumPlanName = '年額プラン（家族）'
      premiumPlanButtonName = 'プラン変更'
    } else if (
      partnerPurchasedPlan &&
      [
        iOSPlans.yearlyPlanWithPartner,
        androidPlans.yearlyPlanWithPartner,
      ].includes(partnerPurchasedPlan)
    ) {
      premiumPlanName = '年額プラン（家族）をパートナーが購入'
      premiumPlanButtonName = 'プラン変更'
    } else if (
      purchasedPlan &&
      [iOSPlans.yearlyPlan, androidPlans.yearlyPlan].includes(purchasedPlan)
    ) {
      premiumPlanName = '年額プラン（個人）'
      premiumPlanButtonName = 'プラン変更'
    } else if (
      purchasedPlan &&
      [
        iOSPlans.monthlyPlanWithPartner,
        iOSPlans.monthlyPlanWithPartner2,
        androidPlans.monthlyPlanWithPartner,
        androidPlans.monthlyPlanWithPartner2,
      ].includes(purchasedPlan)
    ) {
      premiumPlanName = '月額プラン（家族）'
      premiumPlanButtonName = 'プラン変更'
    } else if (
      partnerPurchasedPlan &&
      [
        iOSPlans.monthlyPlanWithPartner,
        iOSPlans.monthlyPlanWithPartner2,
        androidPlans.monthlyPlanWithPartner,
        androidPlans.monthlyPlanWithPartner2,
      ].includes(partnerPurchasedPlan)
    ) {
      premiumPlanName = '月額プラン（家族）をパートナーが購入'
      premiumPlanButtonName = 'プランを見る'
    } else if (
      purchasedPlan &&
      [
        iOSPlans.monthlyPlan,
        iOSPlans.monthlyPlan2,
        androidPlans.monthlyPlan,
        androidPlans.monthlyPlan2,
      ].includes(purchasedPlan)
    ) {
      premiumPlanName = '月額プラン（個人）'
      premiumPlanButtonName = 'プラン変更'
    } else {
      premiumPlanName =
        purchasedPlan ||
        (externalService ? 'OsidOriでんきの見直し（最大1年無料）' : '未登録')
      premiumPlanButtonName = ''
    }

    return (
      <View
        style={{
          alignItems: 'center',
          paddingBottom: 10,
          borderWidth: 1,
          borderColor: '#eeeeee',
          borderTopStartRadius: 20,
          borderTopEndRadius: 20,
          paddingTop: 20,
          backgroundColor: 'white',
        }}>
        <Image
          style={styles.menuItemIcon}
          source={require('@images/icons/icon-premium.png')}
        />
        <Text style={{ fontSize: 15, fontWeight: 'bold', marginTop: 15 }}>
          あなたはプレミアムユーザーです
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 8,
          }}>
          {!externalService && (
            <Text
              style={{
                fontSize: 8,
                fontWeight: 'normal',
                color: '#888888',
              }}>
              ●{' '}
            </Text>
          )}
          <Text
            style={{
              fontSize: 14,
              fontWeight: 'normal',
              color: '#888888',
            }}>
            {premiumPlanName}
          </Text>
        </View>
        {this.state.isLoadedProfiles ? (
          <View
            style={{
              width: '100%',
              paddingHorizontal: 20,
              paddingVertical: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 80,
            }}>
            {!externalService && (
              <AppButton
                type="secondary"
                title={premiumPlanButtonName}
                style={{ width: buttonWidth, height: 40 }}
                textStyle={{ fontWeight: 'bold', fontSize: 14 }}
                onPress={() => NavigationService.navigate('PremiumPlanSelect')}
              />
            )}
            <AppButton
              title={'使い方をみる'}
              style={{ width: buttonWidth, height: 40 }}
              textStyle={{ fontWeight: 'bold', fontSize: 14 }}
              onPress={() => {
                const params: WebViewScreenParams = {
                  title: '“お金の可視化”がもっと進む！プレミアム機能の活用方法',
                  uri: 'https://osidori.co/news/2020/07/27/premium/',
                  leftButtonType: 'modal',
                }
                NavigationService.navigate('MainStackWebView', params)
                // Linking.openURL('https://osidori.co/news/2020/07/27/premium/')
              }}
            />
          </View>
        ) : (
          <View style={{ height: 80 }} />
        )}
      </View>
    )
  }

  render() {
    const params = this.props.route.params

    return (
      <View style={styles.container}>
        <CommonHeader
          title="プレミアムプランについて"
          leftButtonType="modal"
          onPressLeftButton={this.goBack}
          // style={{ flex: 0 }}
        />
        <ScrollView style={{ flex: 1 }}>
          {/* <Tab.Navigator
            backBehavior="none"
            tabBar={NullTabBar}
            // swipeEnabled={false}
            animationEnabled={false}>
            {Pages.map((page, index) => (
              <Tab.Screen
                key={`page-${index}`}
                name={`page-${index}`}
                component={page}
                listeners={
                  {
                    // focus: () => setPageIndex(index),
                  }
                }
              />
            ))}
          </Tab.Navigator> */}
          {this.state.startSwiper && (
            <Swiper
              ref={this.swiper}
              index={params?.pageIndex || 0}
              paginationStyle={styles.swiper}
              loop={false}
              bounces={true}
              style={{ height: wp('100%') * (288 / 286) }}
              activeDotColor={
                Platform.OS !== 'web' ? Color.OsidoriGreen : Color.UIBankPrimary
              }>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageFinancialAccounts} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageCategory} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageAutoTransaction} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageCalendar} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageOldData} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageBudget} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageGoals} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageAd} />
              </View>
              <View style={styles.page}>
                <FullWidthImage source={SwipeImageSupport} />
              </View>
            </Swiper>
          )}
          <View style={{ height: 40 }} />
        </ScrollView>
        {this.renderPremiumUserView()}
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    userProfile: state.profile.userProfile,
    partnerProfile: state.profile.partnerProfile,
  }
}

const PremiumPlanTop = connect(mapStateToProps)(PremiumPlanForPremiumUserScreen)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  swiper: {
    marginBottom: -50,
    color: '#3fc515',
  },
  page: {
    marginTop: 20,
    marginHorizontal: 20,
  },
  menuItemIcon: {
    marginRight: 10,
    width: 44,
    height: 44,
  },
})

export default PremiumPlanTop
