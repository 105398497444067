import { UserAccountType } from '@interfaces/Account'
import {
  AccountTrackerData,
  AccountTrackerGroupedCategory,
} from '@interfaces/AccountTracker'
import API, { APIResponse } from './index'

export interface GetAtTokenResponse {
  app: {
    token: string
    expiresAt: string
  }
}

export interface GetAtUrlResponse {
  app: AccountTrackerData
}

export interface AtSyncProps {
  onlyAccounts?: boolean
  fncType?: string
}

export interface GetAtUrlProps {
  fncId?: string
  type?: 'manage'
  process?: 'add'
  share?: boolean
}

export interface GetAtCategoriesResponse {
  app: {
    atGroupedCategories: AccountTrackerGroupedCategory[]
  }
}

export interface CreateCategoryProps {
  userAccountType: UserAccountType
  categoryName: string
  categoryType: 'expense' | 'income'
  icon: string
  color: string
}

export interface UpdateCategoryProps extends CreateCategoryProps {
  atGroupedCategorieId: number
}

// export interface DeleteCategoryProps {
//   accountType: UserAccountType
//   atGroupedCategorieId: number
// }

class AccountTracker {
  public getAtToken = (): Promise<APIResponse> => {
    return API.request('GET', '/api/v1/users/at-token', {}, true)
  }

  public getAtUrl = (props: GetAtUrlProps): Promise<APIResponse> => {
    return API.request('GET', '/api/v1/user/at-url', props, true)
  }

  public atSync = (props: AtSyncProps): Promise<APIResponse> => {
    return API.request('GET', '/api/v1/user/at-sync', props, true)
  }

  public createCategory = (
    props: CreateCategoryProps,
  ): Promise<APIResponse> => {
    const { categoryName, categoryType, icon, color } = props
    return API.request(
      'POST',
      `/api/v1/${props.userAccountType.userAccountPath()}/categories`,
      {
        categoryName,
        categoryType,
        icon,
        color,
      },
      true,
    )
  }

  public updateCategory = (
    props: UpdateCategoryProps,
  ): Promise<APIResponse> => {
    const { atGroupedCategorieId, categoryName, categoryType, icon, color } =
      props
    return API.request(
      'PUT',
      `/api/v1/${props.userAccountType.userAccountPath()}/categories`,
      {
        atGroupedCategorieId,
        categoryName,
        categoryType,
        icon,
        color,
      },
      true,
    )
  }

  public getCategories = (): Promise<APIResponse> => {
    return API.request('GET', '/api/v1/categories', {}, true)
  }

  // public deleteCategory = (
  //   props: DeleteCategoryProps
  // ): Promise<APIResponse> => {
  //   return API.request(
  //     'DELETE',
  //     `/api/v1/categories/${props.atGroupedCategorieId}`,
  //     props,
  //     true
  //   )
  // }
}

export default new AccountTracker()
