import API, { APIResponse } from '../api/index'
import { UpdateMailPermissionProps } from './types'

export const updateMailPermission = ({
  id,
  ...params
}: UpdateMailPermissionProps): Promise<APIResponse> => {
  return API.request('PUT', `/api/v1/user/permissions/mail/${id}`, params, true)
}

export const getMailPermissions = (): Promise<APIResponse> => {
  return API.request('GET', '/api/v1/user/permissions/mail', {}, true)
}
