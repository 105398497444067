import AppButton from '@components/AppButton'
import CommonHeader from '@components/CommonHeader'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp, UIBank, WebApp } from '@lib/Env'
import NavigationService from '@lib/NavigationService'
import NotificationService from '@lib/NotificationService'
import Osidori from '@lib/Osidori'
import ProgressHUD from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import Auth, { PostLoginResponse } from '@lib/api/Auth'
import { CreateUserResponse, UserAPI } from '@lib/api/User'
import { APIError } from '@lib/api/index'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { updateRootScreen } from '@redux/actions'
import store from '@redux/store'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Platform,
  StatusBar,
  Text,
  TextInput,
  TouchableOpacity,
} from 'react-native'
import {
  DEBUG_DEFAULT_CREATE_ACCOUNT_EMAIL,
  DEBUG_DEFAULT_CREATE_ACCOUNT_PASSWORD,
} from 'react-native-dotenv'
import { useDispatch } from 'react-redux'
import { logging } from '../../../Firestore'
import {
  StyledDash,
  StyledEmailInput,
  StyledFastImage,
  StyledForgotPasswordText,
  StyledForgotPasswordView,
  StyledFormTitleText,
  StyledFormView,
  StyledLine,
  StyledPasswordInput,
  StyledRootView,
  StyledScrollView,
  StyledTermsText,
  StyledUnderlineText,
} from './styles'

const CreateAccountOrLoginScreen: React.FC<
  StackScreenProps<RootStackParamList, 'CreateAccountOrLogin'>
> = () => {
  const isFocused = useIsFocused()

  const [email, setEmail] = useState(
    __DEV__ ? DEBUG_DEFAULT_CREATE_ACCOUNT_EMAIL : '',
  )
  const [password, setPassword] = useState(
    __DEV__ ? DEBUG_DEFAULT_CREATE_ACCOUNT_PASSWORD : '',
  )

  const dispatch = useDispatch()

  const passwordInputRef = useRef<TextInput>(null)

  const createAccount = useCallback(async () => {
    try {
      ProgressHUD.show()
      const response = NativeApp
        ? await UserAPI.createUser({ email, password })
        : await UserAPI.uiCreateUser({ email, password })
      if (response.ok) {
        const json: CreateUserResponse = response.json
        const {
          accessToken,
          accessTokenExpiresAt,
          refreshToken,
          refreshTokenExpiresAt,
        } = json.app

        await SessionManager.setAccessToken(
          accessToken,
          accessTokenExpiresAt,
          refreshToken,
          refreshTokenExpiresAt,
        )

        SessionManager.setIsTutorialDisplayCompleted(false)
        SessionManager.setIsTutorialTransactionDisplayCompleted(false)

        dispatch(updateRootScreen('Welcome'))
      } else {
        switch (response.status) {
          case 400:
            if (response.json.errors[0].code === 'taken') {
              CommonDialog.show(
                '登録失敗',
                'そのメールアドレスは既に登録されています。',
              )
            } else {
              CommonDialog.show(
                '登録失敗',
                'ユーザー登録に失敗しました。メールアドレス、パスワードをお確かめください。\n（パスワードは英数字です）',
              )
            }
            return
        }
        throw new APIError(response)
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      ProgressHUD.dismiss()
    }
  }, [dispatch, email, password])

  const login = useCallback(async (email: string, password: string) => {
    try {
      ProgressHUD.show()
      const response =
        Platform.OS !== 'web'
          ? await Auth.login({ email, password })
          : await Auth.uiLogin({ email, password })
      if (response.ok) {
        const json: PostLoginResponse = response.json
        const {
          accessToken,
          accessTokenExpiresAt,
          refreshToken,
          refreshTokenExpiresAt,
        } = json.app
        await SessionManager.setAccessToken(
          accessToken,
          accessTokenExpiresAt,
          refreshToken,
          refreshTokenExpiresAt,
        )
        await Osidori.start()
      } else {
        console.log(response.isAuthenticationError)

        if (response.isAuthenticationError) {
          CommonDialog.show(
            'ログイン失敗',
            'ユーザーログインに失敗しました。メールアドレス、パスワードをお確かめください。（パスワードは英数字です）',
          )
        } else {
          throw new APIError(response, 'ログイン失敗')
        }
      }
    } catch (error) {
      console.log({ error })
      CommonDialog.showError({ error })
    } finally {
      ProgressHUD.dismiss()
    }
  }, [])

  const onPressCreateAccountButton = useCallback(
    () => createAccount(),
    [createAccount],
  )

  const onPressLoginButton = useCallback(
    () => login(email, password),
    [email, login, password],
  )

  const onEmailSubmitEditing = useCallback(
    () => passwordInputRef.current?.focus(),
    [],
  )

  const onPressTerms = useCallback(() => {
    NavigationService.navigate('WebView', {
      title: '利用規約',
      uri: 'https://www.osidori.co/terms/',
    })
  }, [])

  const onPressPrivacyPolicy = useCallback(() => {
    NavigationService.navigate('WebView', {
      title: '個人情報の取り扱いについて',
      uri: !UIBank
        ? 'https://www.osidori.co/privacy/'
        : 'https://www.osidori.co/privacy/uibank/',
    })
  }, [])

  const onPressPasswordReset = useCallback(() => {
    NavigationService.navigate('PasswordReset', { email })
  }, [email])

  const onChangeEmail = useCallback((email: string) => setEmail(email), [])

  const onChangePassword = useCallback(
    (password: string) => setPassword(password),
    [],
  )

  const isNeedDisplayUpdatePassword = useMemo(
    () =>
      isFocused &&
      store.getState().root.navigation?.screenName === 'UpdatePassword',
    [isFocused],
  )

  useEffect(() => {
    NotificationService.init()

    if (WebApp) {
      if (store.getState().root.navigation?.screenName === 'UpdatePassword') {
        setTimeout(() => {
          NavigationService.navigate(
            'UpdatePassword',
            store.getState().root.navigation?.params,
          )
        }, 1)
      }
    }
  }, [])

  useEffect(() => {
    const isCreateAccountTop = SessionManager.getLaunchHomeCount() === 0

    TrackingUtils.ga.pageview({
      page: `CreateAccountOrLogin-${
        isCreateAccountTop ? 'CreateAccount' : 'Login'
      }`,
      title: isCreateAccountTop ? 'アカウント登録画面' : 'ログイン画面',
    })
  }, [])

  useEffect(() => {
    if (isFocused) {
      logging('ログイン画面表示')
    }
  }, [isFocused])

  if (isNeedDisplayUpdatePassword) return null

  return (
    <StyledRootView>
      <StatusBar
        translucent={true}
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <CommonHeader title="OsidOriへようこそ" leftButtonType="hidden" />
      <StyledFastImage source={require('@images/bg/bg-signin.png')}>
        <StyledScrollView>
          <StyledFormView>
            <StyledFormTitleText>メールアドレス</StyledFormTitleText>
            <StyledEmailInput
              placeholder="メールアドレスを入力"
              value={email}
              onChangeText={onChangeEmail}
              placeholderTextColor={Color.PlaceHolderText}
              keyboardType="ascii-capable"
              autoCapitalize="none"
              returnKeyType="next"
              textContentType="emailAddress"
              autoCorrect={false}
              onSubmitEditing={onEmailSubmitEditing}
            />
            <StyledDash />
            <StyledFormTitleText>パスワード</StyledFormTitleText>
            <StyledPasswordInput
              ref={passwordInputRef}
              secureTextEntry={true}
              placeholder="8〜16文字以内の半角英数字"
              value={password}
              onChangeText={onChangePassword}
              placeholderTextColor={Color.PlaceHolderText}
              returnKeyType="done"
              textContentType="password"
            />
          </StyledFormView>
          {SessionManager.getLaunchHomeCount() === 0 ? (
            <>
              <StyledTermsText>
                {WebApp && UIBank && (
                  <Text>
                    アプリ版OsidOriに登録のアカウントは「お金の管理 by
                    OsidOri」ではご利用頂けません。{'\n'}
                  </Text>
                )}
                <StyledUnderlineText onPress={onPressTerms}>
                  利用規約
                </StyledUnderlineText>
                <Text>と</Text>
                <StyledUnderlineText onPress={onPressPrivacyPolicy}>
                  個人情報の取扱いについて
                </StyledUnderlineText>
                <Text>に同意の上、お進みください。</Text>
              </StyledTermsText>
              <AppButton
                title="同意して新規登録する"
                key="CreateAccount"
                onPress={onPressCreateAccountButton}
              />
              <StyledLine />
              <AppButton
                title="ログインする"
                key="Login"
                onPress={onPressLoginButton}
                type="white"
              />
              <StyledForgotPasswordView>
                <TouchableOpacity onPress={onPressPasswordReset}>
                  <StyledForgotPasswordText>
                    パスワードをお忘れですか？
                  </StyledForgotPasswordText>
                </TouchableOpacity>
              </StyledForgotPasswordView>
            </>
          ) : (
            <>
              <AppButton
                title="ログインする"
                key="Login"
                onPress={onPressLoginButton}
                style={{ marginTop: 30 }}
              />
              <StyledForgotPasswordView>
                <TouchableOpacity onPress={onPressPasswordReset}>
                  <StyledForgotPasswordText>
                    パスワードをお忘れですか？
                  </StyledForgotPasswordText>
                </TouchableOpacity>
              </StyledForgotPasswordView>
              <StyledLine />
              <AppButton
                title="同意して新規登録する"
                key="CreateAccount"
                type="white"
                onPress={onPressCreateAccountButton}
              />
              <StyledTermsText>
                {WebApp && UIBank && (
                  <Text>
                    アプリ版OsidOriに登録のアカウントは「お金の管理 by
                    OsidOri」ではご利用頂けません。{'\n'}
                  </Text>
                )}
                <StyledUnderlineText onPress={onPressTerms}>
                  利用規約
                </StyledUnderlineText>
                <Text>と</Text>
                <StyledUnderlineText onPress={onPressPrivacyPolicy}>
                  個人情報の取扱いについて
                </StyledUnderlineText>
                <Text>に同意の上、お進みください。</Text>
              </StyledTermsText>
            </>
          )}
        </StyledScrollView>
      </StyledFastImage>
    </StyledRootView>
  )
}

export default CreateAccountOrLoginScreen
