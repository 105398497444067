import { deeplinkNavigation } from './DeeplinkNavigationParams'

export type ArticleParams = {
  p: number
}

export const navigationArticle = ({ p }: ArticleParams) => {
  deeplinkNavigation({
    name: 'MainMediaWebView',
    uri: `https://www.osidori.co/app/article.php?p=${p}`,
    isShowOriginalHeader: true,
  })
}
