import AppButton from '@components/AppButton'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import TintedImage from '@components/TintedImage'
import TransactionItem from '@components/TransactionItem'
import { FinancialAccountType, Transaction } from '@interfaces/Financial'
import AccountManager from '@lib/AccountManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import ProfileManager from '@lib/ProfileManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import {
  Animated,
  Dimensions,
  Easing,
  FlatList,
  LayoutChangeEvent,
  ListRenderItem,
  Platform,
  Pressable,
  ScrollView,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import Toast from 'react-native-simple-toast'
import { connect } from 'react-redux'
import { navigatePremiumPlanLP } from '../PremiumPlan'
import { TransactionDetailScreenNavigationParams } from '../TransactionDetailScreen'
import AddTransactionButton from './AddTransactionButton'
import {
  updateCalendarTotalPaymentAmount,
  updateCalendarTotalReceiptAmount,
  updateShowTransactions,
} from './redux/actions'
import {
  AddTransactionButtonView,
  PremiumContainerView,
  PremiumText,
  RootContainer,
  StyledBlurView,
  StyledScrollView,
  TransactionDateText,
  TransactionsContainer,
} from './styles'
import { Props, State } from './types'

class CalendarScreen extends React.PureComponent<
  Props & StackScreenProps<RootStackParamList, 'Calendar'>,
  State
> {
  state: State = {
    sections: [],
    refreshing: false,
    calendarUserAccountType: store.getState().account.accountMode,
    transactions: {},
    selectedDay: '',
    transactionsViewHeight: new Animated.Value(0),
    calendarMaxLine: 0,
    isDisplayedPremiumPlanView: false,
    selectedLine: 0,
  }

  private isLoading = false

  private listeners: string[] = []

  private dayLineNumber: { [yyyymmd: string]: number } = {}

  private calendarDayViews = () => {
    const { startDate, endDate } = this.props.calendar

    const targetStartDate = startDate[this.state.calendarUserAccountType]
    if (!targetStartDate) {
      log.warn(`startDate[${this.state.calendarUserAccountType}] is undefined.`)
      return
    }
    const targetEndDate = endDate[this.state.calendarUserAccountType]
    if (!targetEndDate) {
      log.warn(`endDate[${this.state.calendarUserAccountType}] is undefined.`)
      return
    }

    const startWeekOfDay = targetStartDate.get('weekday')
    const endWeekOfDay = targetEndDate.get('weekday')

    const prevDays = []
    const nextDays = [...Array(6 - endWeekOfDay)]

    const days = []
    const endYYYYMMDD = targetEndDate.format('YYYYMMDD')
    const date = moment(targetStartDate)
    let yyyymmdd = date.format('YYYYMMDD')
    let i = 0 // バグが有った場合にフリーズさせないための保険
    while (yyyymmdd !== endYYYYMMDD && i < 50) {
      // 31だと土日をずらす設定があった場合にたりない
      yyyymmdd = date.format('YYYYMMDD')
      days.push(yyyymmdd)
      date.add(1, 'day')
      i++
    }

    const weekOfDayView = ['日', '月', '火', '水', '木', '金', '土'].map(
      (v) => (
        <View
          key={v}
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text
            style={{
              color: Color.DefaultText,
              fontSize: wp(3),
              fontWeight: 'normal',
            }}>
            {v}
          </Text>
        </View>
      ),
    )

    // カレンダー表示開始日
    for (let i = startWeekOfDay; i > 0; i--) {
      prevDays.push(moment(targetStartDate).subtract(i, 'day').get('date'))
    }

    const lastDay = moment(days[days.length - 1])

    this.dayLineNumber = {}

    const views = prevDays
      .map((day) => this.calendarEmptyDayView(day))
      .concat(
        days.map((yyyymmdd, i) => {
          const lineNumber = Math.floor((prevDays.length + i) / 7)
          this.dayLineNumber[yyyymmdd] = lineNumber
          return this.calendarDayView(yyyymmdd)
        }),
        nextDays.map((_, i) =>
          this.calendarEmptyDayView(
            moment(lastDay)
              .add(1 + i, 'day')
              .get('date'),
          ),
        ),
      )

    const lines: JSX.Element[][] = []
    for (let i = 0; i < views.length; i += 7) {
      lines.push(views.slice(i, i + 7))
    }
    this.setState({ calendarMaxLine: lines.length })

    return (
      <View>
        <View style={{ flexDirection: 'row', marginTop: wp(2) }}>
          {weekOfDayView}
        </View>
        {lines.map((line, index) => {
          return (
            <View
              key={`${index}`}
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderBottomColor: '#dddddd',
                borderBottomWidth: 0.5,
                height: wp(16),
              }}>
              {line.map((day) => day)}
            </View>
          )
        })}
      </View>
    )
  }

  private makeSections = (yyyymmdd: string) => {
    const userId = this.props.userProfile?.userId

    const familyTransactions: (Transaction | null)[] =
      this.state.transactions[yyyymmdd]
        ?.filter((v) => !(v.userId === userId && !v.isTransactionShared))
        ?.map((v) => ({ ...v, userAccountType: 'family' })) || []
    if (familyTransactions.length === 0) familyTransactions.push(null)

    const userTransactions: (Transaction | null)[] =
      this.state.transactions[yyyymmdd]
        ?.filter((v) => v.userId === userId && !v.isTransactionShared)
        ?.map((v) => ({ ...v, userAccountType: 'user' })) || []
    if (userTransactions.length === 0) userTransactions.push(null)

    const sections: SectionListData<Transaction | null>[] = [
      { title: '家族', data: familyTransactions },
      { title: ProfileManager.getName('user'), data: userTransactions },
    ]

    return sections
  }

  private calendarEmptyDayView = (day: number) => {
    return (
      <Pressable
        key={day}
        style={{ flex: 1, alignItems: 'center' }}
        onPress={() => {
          store.dispatch(updateShowTransactions(false))
          this.setState({ selectedDay: '' })
        }}>
        <View
          style={{
            height: wp(5.2),
            justifyContent: 'center',
            marginTop: wp(1),
          }}>
          <Text
            style={{
              fontSize: wp(3),
              fontWeight: 'normal',
              color: Color.Gray,
            }}>
            {day}
          </Text>
        </View>
      </Pressable>
    )
  }

  private calendarDayView = (yyyymmdd: string) => {
    let receiptAmount = 0
    let paymentAmount = 0
    let transferAmount = 0

    const transactions = this.state.transactions[yyyymmdd]
    transactions?.forEach((v) => {
      if (!v.isIgnored) {
        receiptAmount += v.aggregationAmount?.receiptAmount || 0
        paymentAmount += v.aggregationAmount?.paymentAmount || 0
        transferAmount += v.aggregationAmount?.transferAmount || 0
      }
    })

    const backgroundColor =
      yyyymmdd === this.state.selectedDay ? '#eee' : 'white'
    const today = moment()
    const isToday = yyyymmdd === today.format('YYYYMMDD')
    return (
      <TouchableOpacity
        key={yyyymmdd}
        style={{ flex: 1, alignItems: 'center', backgroundColor }}
        onPress={() => this.onPressDay(yyyymmdd)}>
        <View
          style={{
            backgroundColor: isToday ? Color.Primary : undefined,
            borderRadius: wp(2.6),
            width: wp(5.2),
            height: wp(5.2),
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: wp(1),
          }}>
          <Text
            style={{
              fontSize: wp(3),
              color: isToday ? 'white' : 'black',
              fontWeight: 'bold',
            }}>
            {+yyyymmdd.slice(-2)}
          </Text>
        </View>
        {transactions && (
          <>
            {receiptAmount !== 0 && (
              <Text
                style={{
                  fontSize: wp(2.5),
                  fontWeight: 'normal',
                  color: Color.Primary,
                }}>
                {receiptAmount.jpCurrency()}
              </Text>
            )}
            {paymentAmount !== 0 && (
              <Text
                style={{
                  fontSize: wp(2.5),
                  fontWeight: 'normal',
                  color: Color.Orange,
                }}>
                {paymentAmount.jpCurrency()}
              </Text>
            )}
            {transferAmount !== 0 && (
              <Text
                style={{
                  fontSize: wp(2.5),
                  fontWeight: 'normal',
                  color: Color.Gray,
                }}>
                {transferAmount.jpCurrency()}
              </Text>
            )}
            {transactions.length > 0 &&
              !transactions.find((v) => !v.isIgnored) && (
                <Text
                  style={{
                    fontSize: wp(2.5),
                    fontWeight: 'normal',
                    color: Color.Gray,
                  }}>
                  {(0).jpCurrency()}
                </Text>
              )}
          </>
        )}
      </TouchableOpacity>
    )
  }

  private unsubscribe?: () => void

  componentDidMount() {
    const { calendarUserAccountType, selectedDate } = this.props.route.params
    this.setState({ calendarUserAccountType })

    this.unsubscribe = this.props.navigation.addListener('focus', async () => {
      if (this.props.calendar.isNeedUpdateCalendarUserAccountType) {
        if (selectedDate) {
          this.selectDate(selectedDate)
        }
        return
      }

      const { calendarUserAccountType: userAccountType } = this.state
      if (
        userAccountType !== 'all' &&
        userAccountType !== store.getState().account.accountMode
      ) {
        AccountManager.updateAccountMode(userAccountType)
      }
      await this.fetchCalendarTransactions()

      if (selectedDate) {
        this.selectDate(selectedDate)
      }

      // Repro Tracking
      if (userAccountType === 'user') {
        TrackingUtils.repro.track('【Screen】personal_calendar', 'Screen')
      } else if (userAccountType === 'family') {
        TrackingUtils.repro.track('【Screen】family_calendar', 'Screen')
        TrackingUtils.repro.setViewFamilyCalendar(1)
      } else {
        TrackingUtils.repro.track('【Screen】all_calendar', 'Screen')
      }
    })

    const events = [
      OsidoriEventName.DidCreateTransaction,
      OsidoriEventName.DidUpdateTransaction,
      OsidoriEventName.DidDeleteTransaction,
      OsidoriEventName.DidCreateTermTransaction,
      OsidoriEventName.DidUpdateTermTransaction,
      OsidoriEventName.DidDeleteTermTransaction,
      OsidoriEventName.DidCreateTransfer,
      OsidoriEventName.DidUpdateTransfer,
      OsidoriEventName.DidDeleteTransfer,
      OsidoriEventName.ClosePayoffScreen,
    ]
    events.forEach((name) => {
      const id = OsidoriEvent.addListener(name, this.updateTransactionListener)
      if (id) {
        this.listeners.push(id)
      }
    })
  }

  private updateTransactionListener = () => {
    this.fetchCalendarTransactions()
  }

  componentWillUnmount() {
    this.unsubscribe?.()

    store.dispatch(updateShowTransactions(false))
    this.setState({ selectedDay: '' })

    OsidoriEvent.removeListener(this.listeners)
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.calendar.startDate !== this.props.calendar.startDate ||
      prevProps.account.isFamilyShareMode !==
        this.props.account.isFamilyShareMode
    ) {
      this.fetchCalendarTransactions()
    }

    if (
      prevProps.calendar.isShowTransactions !==
      this.props.calendar.isShowTransactions
    ) {
      Animated.timing(this.state.transactionsViewHeight, {
        toValue: this.props.calendar.isShowTransactions ? height / 2.37 : 0,
        duration: 250,
        easing: Easing.inOut(Easing.exp),
        useNativeDriver: false,
      }).start(() => this.scrollCalendar(false))
    }
  }

  private scrollCalendar = (animated: boolean) => {
    if (this.state.selectedLine < 2) {
      this.scrollViewRef.current?.scrollTo({ y: 0, animated })
    } else if (this.state.selectedLine >= this.state.calendarMaxLine - 2) {
      this.scrollViewRef.current?.scrollToEnd({ animated })
    } else {
      this.scrollViewRef.current?.scrollTo({
        y:
          (this.scrollViewHeight - wp(8)) *
          (this.state.selectedLine / this.state.calendarMaxLine),
        animated,
      })
    }
  }

  private fetchCalendarTransactions = async () => {
    const { startDate, endDate } = this.props.calendar

    const accountType = this.state.calendarUserAccountType

    const from = startDate[accountType]?.format('YYYY-MM-DD')
    const to = endDate[accountType]?.format('YYYY-MM-DD')

    if (!from || !to) return

    if (this.isLoading) return
    this.isLoading = true

    try {
      let transactionsAll: Transaction[]

      if (this.state.calendarUserAccountType !== 'all') {
        transactionsAll =
          (await FinancialManager.fetchCalendarTransactions({
            accountType: this.state.calendarUserAccountType,
            from,
            to,
            share: this.props.account.isFamilyShareMode,
            future: true,
            transfer: 'transaction',
            useCache: false, // TODO
            exclusionCardDupulicate: true,
            exclusionCarryForward: false,
          })) || []
      } else {
        transactionsAll =
          (await FinancialManager.fetchCalendarTransactions({
            from,
            to,
            share: this.props.account.isFamilyShareMode,
            future: true,
            transfer: 'transaction',
            useCache: false, // TODO
            exclusionCardDupulicate: true,
            exclusionCarryForward: false,
          })) || []
      }

      let totalPaymentAmount = 0
      let totalReceiptAmount = 0

      const transactions: { [yyyymmdd: string]: Transaction[] } = {}
      transactionsAll.forEach((v) => {
        const date = moment(v.usedDate).format('YYYYMMDD')
        if (transactions[date]) {
          transactions[date].push(v)
        } else {
          transactions[date] = [v]
        }

        if (!v.isIgnored) {
          totalPaymentAmount += v.aggregationAmount?.paymentAmount || 0
          totalReceiptAmount += v.aggregationAmount?.receiptAmount || 0
        }
      })

      this.setState({ transactions }, () => {
        if (this.state.calendarUserAccountType === 'all') {
          this.setState({
            sections: this.makeSections(this.state.selectedDay),
          })
        }
      })

      if (
        this.props.calendar.calendarUserAccountType ===
        this.state.calendarUserAccountType
      ) {
        store.dispatch(
          updateCalendarTotalPaymentAmount(
            this.state.calendarUserAccountType,
            totalPaymentAmount,
          ),
        )
        store.dispatch(
          updateCalendarTotalReceiptAmount(
            this.state.calendarUserAccountType,
            totalReceiptAmount,
          ),
        )
      }
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      this.isLoading = false
    }
  }

  private onPressDay = (yyyymmdd: string) => {
    this.selectDate(yyyymmdd)
  }

  private selectDate = (yyyymmdd: string) => {
    const sections =
      this.state.calendarUserAccountType === 'all'
        ? this.makeSections(yyyymmdd)
        : []
    this.setState(
      {
        selectedDay: yyyymmdd,
        sections,
        selectedLine: this.dayLineNumber[yyyymmdd] || 0,
      },
      () => {
        if (this.props.calendar.isShowTransactions) {
          this.scrollCalendar(true)
        } else {
          store.dispatch(updateShowTransactions(true))
        }
        TrackingUtils.repro.track('【Screen】day_calendar', 'Screen')
      },
    )
  }

  private onPressItem = (item: Transaction) => {
    const params: TransactionDetailScreenNavigationParams = {
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId: item.accountId,
      transactionId: item.transactionId,
      isTransactionShared: item.isTransactionShared,
      transferId: item.transferId,
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      amount: item.amount,
    }
    NavigationService.navigate('TransactionDetail', params)
  }

  private onPressMemoButton = (item: Transaction) => {
    const params: TransactionDetailScreenNavigationParams = {
      focusMemo: true,
      financialAccountType: item.walletId ? 'wallet' : item.type,
      accountId: item.accountId,
      transactionId: item.transactionId,
      isTransactionShared: item.isTransactionShared,
      transferId: item.transferId,
      userAccountType: FinancialManager.isGroupTransaction(item)
        ? 'family'
        : 'user',
      amount: item.amount,
    }
    NavigationService.navigate('TransactionDetail', params)
  }

  private renderItem: ListRenderItem<Transaction> = ({ item }) => {
    return (
      <TransactionItem
        transaction={item}
        onPress={this.onPressItem}
        onPressMemoButton={this.onPressMemoButton}
        onSwipeableOpen={
          item.type !== 'carry_forward' ? this.onSwipeableOpenItem : undefined
        }
        onPressAddPaylistButton={async () => {
          await this.fetchCalendarTransactions()
          if (this.state.calendarUserAccountType === 'all') {
            this.setState({
              sections: this.makeSections(this.state.selectedDay),
            })
          }
        }}
      />
    )
  }

  private onScrollBeginDrag = () => {
    store.dispatch(updateShowTransactions(false))
    this.setState({ selectedDay: '' })
  }

  private onPressAddTransaction = () => {
    const { selectedDay } = this.state

    NavigationService.navigate('CreateTransactionDetail', {
      usedDate: moment(selectedDay, 'YYYYMMDD').format('YYYY-MM-DD'),
      userAccountType: this.props.account.accountMode,
    })
  }

  private renderSectionItem: SectionListRenderItem<Transaction | null> = ({
    item,
  }) => {
    if (!item) return TransactionEmptyView

    return (
      <TransactionItem
        transaction={item}
        onPress={() => this.onPressItem(item)}
        onPressMemoButton={() => this.onPressMemoButton(item)}
        onSwipeableOpen={
          item.type !== 'carry_forward' ? this.onSwipeableOpenItem : undefined
        }
        onPressAddPaylistButton={async () => {
          await this.fetchCalendarTransactions()
          if (this.state.calendarUserAccountType === 'all') {
            this.setState({
              sections: this.makeSections(this.state.selectedDay),
            })
          }
        }}
      />
    )
  }

  private scrollViewRef = React.createRef<ScrollView>()
  private scrollViewHeight = 0

  private onSwipeableOpenItem = async (item: Transaction) => {
    const newItem = { ...item }
    newItem.isTransactionShared = true

    const financialAccountType: FinancialAccountType = (() => {
      if (item.atUserBankAccountId) return 'bank'
      if (item.atUserCardAccountId) return 'card'
      if (item.atUserEmoneyServiceAccountId) return 'emoney'
      if (item.atUserEmoneyAccountId) return 'emoney'
      if (item.walletId) return 'wallet'
      return 'manually_created'
    })()

    const userAccountType = item.isAccountShared ? 'family' : 'user'
    const share = !item.isTransactionShared

    try {
      await FinancialManager.updateTransaction({
        userAccountType,
        financialAccountType,
        accountId: item.accountId,
        transactionId: item.transactionId,
        transactionShare: share,
        amount: item.amount,
        paymentMethodId: item.walletId ?? undefined,
        paymentMethodType: item.walletId ? 'wallet' : undefined,
        ignore: item.isIgnored,
        memo: item.memo !== null ? item.memo : undefined,
        type: item.amount >= 0 ? 'receipt' : 'payment',
        action: 'swipe',
      })
    } catch (error) {
      CommonDialog.showError({ error })
      return
    }
    if (Platform.OS !== 'web') {
      Toast.showWithGravity('明細を振り分けました', Toast.SHORT, Toast.BOTTOM)
    }

    if (userAccountType === 'family') {
      TrackingUtils.repro.trackOnce(
        '【Completed】Allocate details to family_unique',
        'Completed',
      )
      TrackingUtils.adjust.trackEvent({ ios: '46kvf5', android: '6cnfwh' })
      TrackingUtils.repro.track(
        '【Completed】Allocate details to family_cumulative',
        'Completed',
      )
      TrackingUtils.adjust.trackEvent({ ios: '659muc', android: '11ppeh' })
    }

    await this.fetchCalendarTransactions()
    if (this.state.calendarUserAccountType === 'all') {
      this.setState({ sections: this.makeSections(this.state.selectedDay) })
    }
  }

  render() {
    const { selectedDay } = this.state
    let receiptAmount = 0
    let paymentAmount = 0
    this.state.transactions[selectedDay]?.forEach((v) => {
      if (!v.isIgnored) {
        receiptAmount += v.aggregationAmount?.receiptAmount || 0
        paymentAmount += v.aggregationAmount?.paymentAmount || 0
      }
    })

    const calendarDayViews = this.calendarDayViews()
    return (
      <RootContainer>
        <StyledScrollView
          ref={this.scrollViewRef}
          onScrollBeginDrag={this.onScrollBeginDrag}
          onLayout={({ nativeEvent }: LayoutChangeEvent) => {
            this.scrollViewHeight = nativeEvent.layout.height
          }}>
          {calendarDayViews}
        </StyledScrollView>
        <Animated.View style={{ height: this.state.transactionsViewHeight }}>
          <TransactionsContainer>
            <View>
              <TransactionDateText>
                {moment(selectedDay, 'YYYYMMDD').format('YYYY/M/D')}
              </TransactionDateText>
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <Text
                  style={{
                    color: 'white',
                    fontSize: 14,
                    fontWeight: 'normal',
                    marginLeft: 10,
                    paddingBottom: 4,
                  }}>
                  収入
                </Text>
                <Text
                  style={{
                    color: 'white',
                    marginLeft: 10,
                    fontSize: 20,
                    fontWeight: 'normal',
                  }}>
                  {receiptAmount.jpCurrency()}
                </Text>
                <Text
                  style={{
                    color: 'white',
                    fontSize: 14,
                    fontWeight: 'normal',
                    marginLeft: 10,
                    paddingBottom: 4,
                  }}>
                  支出
                </Text>
                <Text
                  style={{
                    color: 'white',
                    marginLeft: 10,
                    fontSize: 20,
                    fontWeight: 'normal',
                  }}>
                  {paymentAmount.jpCurrency()}
                </Text>
              </View>
            </View>
            <TouchableOpacity
              style={{ padding: 10 }}
              onPress={() => store.dispatch(updateShowTransactions(false))}>
              <TintedImage
                source={require('@assets/images/arrow/icon-arrow-down-gray.png')}
                style={{
                  tintColor: 'white',
                  width: 14,
                  height: 14,
                  marginLeft: 4,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </TransactionsContainer>
          {this.state.calendarUserAccountType === 'all' ? (
            <SectionList
              sections={this.state.sections}
              renderSectionHeader={({ section: { title } }) => (
                <SectionListHeader
                  title={title}
                  style={{ fontWeight: 'normal' }}
                />
              )}
              data={this.state.transactions[this.state.selectedDay] || []}
              renderItem={this.renderSectionItem}
              keyExtractor={(item) =>
                item
                  ? [
                      item.type,
                      item.transactionId,
                      item.isAccountShared,
                      item.isTransactionShared,
                    ].join('-')
                  : `${new Date().getTime()}`
              }
              ItemSeparatorComponent={() => ItemSeparator}
              ListFooterComponent={() => <View style={{ height: 24 }} />}
            />
          ) : (
            <FlatList
              data={this.state.transactions[this.state.selectedDay] || []}
              renderItem={this.renderItem}
              keyExtractor={(item: Transaction) =>
                [
                  item.type,
                  item.transactionId,
                  item.isAccountShared,
                  item.isTransactionShared,
                ].join('-')
              }
              ListEmptyComponent={() => TransactionEmptyView}
              ItemSeparatorComponent={() => ItemSeparator}
              ListFooterComponent={() => <View style={{ height: 24 }} />}
            />
          )}
          <AddTransactionButtonView>
            <AddTransactionButton onPress={this.onPressAddTransaction} />
          </AddTransactionButtonView>
        </Animated.View>
        {this.state.calendarUserAccountType === 'all' &&
          this.props.userProfile?.rank !== 'premium' && (
            <StyledBlurView>
              <PremiumContainerView>
                <PremiumText>
                  カレンダーの「すべて」は{'\n'}
                  プレミアムプランの機能です。{'\n'}
                  {'\n'}
                  「家族」と「わたし」のカレンダー情報が{'\n'}
                  1枚でまとめて表示されます。{'\n'}
                </PremiumText>
                <AppButton
                  title="詳しく見る"
                  onPress={() => {
                    TrackingUtils.repro.track(
                      '【Tap】upper limit_all calendar',
                      'Tap',
                    )
                    navigatePremiumPlanLP({ planCode: '001' })
                    TrackingUtils.repro.setScreenTrackingAfterPurchase(
                      '【Screen】purchase completed via upper limit_all calendar',
                    )
                  }}
                />
              </PremiumContainerView>
            </StyledBlurView>
          )}
      </RootContainer>
    )
  }
}

const TransactionEmptyView = (
  <View style={{ height: 70, alignItems: 'center', justifyContent: 'center' }}>
    <Text style={{ fontSize: 18, fontWeight: 'normal', color: Color.Gray }}>
      収支はありません
    </Text>
  </View>
)
const { height } = Dimensions.get('window')

const mapStateToProps = (state: ReturnType<typeof store.getState>) => ({
  account: state.account,
  userProfile: state.profile.userProfile,
  calendar: state.calendar,
})

export default connect(mapStateToProps)(CalendarScreen)
