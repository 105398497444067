/* eslint-disable @typescript-eslint/no-var-requires */

export const FamilyIllustration = require('@images/report/monthly_report_family.png')
export const PersonalIllustration = require('@images/report/monthly_report_personal.png')

export const DownIcon = require('@images/report/down-icon.png')
export const UpIcon = require('@images/report/up-icon.png')
export const PlusIcon = require('@images/report/plus-icon.png')
export const MinusIcon = require('@images/report/minus-icon.png')

export const Score3Button = require('@images/report/score3-button.png')
export const Score2Button = require('@images/report/score2-button.png')
export const Score1Button = require('@images/report/score1-button.png')

export const Score3Icon = require('@images/report/goal_score3.png')
export const Score2Icon = require('@images/report/goal_score2.png')
export const Score1Icon = require('@images/report/goal_score1.png')
export const Score3DisabledIcon = require('@images/report/goal_score3_disabled.png')
export const Score2DisabledIcon = require('@images/report/goal_score2_disabled.png')
export const Score1DisabledIcon = require('@images/report/goal_score1_disabled.png')
