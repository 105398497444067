import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import { getApiUrl } from '@lib/api'
import CommonDialog from '@lib/CommonDialog'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import { signalOn } from '@lib/OsidoriSignal'
import { getWebToken } from '@lib/WebToken'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { WebView } from 'react-native-webview'

const LifePlanWebScreen: React.FC<
  StackScreenProps<RootStackParamList, 'WebView'>
> = () => {
  const isFocused = useIsFocused()

  const [uri, setUri] = useState<string>()
  const [canGoBack, setCanGoBack] = useState<boolean>(false)

  const webViewRef = useRef<WebView>(null)

  const getUri = useCallback(async () => {
    try {
      const token = await getWebToken()
      const uri = `${getApiUrl()}/lifeplan/?web_token=${token}`
      log.info({ uri })
      setUri(uri)
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [])

  const goBack = useCallback(() => {
    if (canGoBack) {
      webViewRef.current?.goBack()
    } else {
      NavigationService.goBack()
    }
  }, [canGoBack])

  useEffect(() => HardwareBackPressHandler.addListener(goBack).remove, [goBack])

  useEffect(
    () =>
      signalOn('TAPPED_NAVIGATION_LEFT_BUTTON', () => {
        if (isFocused) {
          goBack()
        }
      }),
    [goBack, isFocused],
  )

  useEffect(() => {
    getUri()
  }, [getUri])

  return (
    <View style={{ flex: 1 }}>
      {uri ? (
        <WebView
          source={{ uri }}
          startInLoadingState={true}
          originWhitelist={['*']}
          renderLoading={() => <AbsoluteActivityIndicator />}
          ref={webViewRef}
          onNavigationStateChange={({ canGoBack, navigationType, url }) => {
            log.info({ canGoBack, navigationType, url })
            if (url.endsWith('lifeplan')) {
              setCanGoBack(false)
            } else {
              // iOSの場合、ウェブの読み込みが完了するまでcanGoBackがtrueにならないので、
              // 画面遷移開始時点でcanGoBackをtrueにしてしまう。
              setCanGoBack(canGoBack || navigationType === 'click')
            }
          }}
        />
      ) : (
        <AbsoluteActivityIndicator />
      )}
    </View>
  )
}

export default LifePlanWebScreen
