import { TouchableOpacity, TouchableOpacityProps } from 'react-native'
import QuestionIcon, { QuestionIconProps } from './QuestionIcon'

export type QuestionButtonProps = TouchableOpacityProps & {
  questionIconProps?: QuestionIconProps
}

export const QuestionButton = ({
  questionIconProps,
  ...props
}: QuestionButtonProps) => (
  <TouchableOpacity {...props}>
    <QuestionIcon {...questionIconProps} />
  </TouchableOpacity>
)

export default QuestionIcon
