import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import Color from '@lib/Color'
import { FAIcon, FAIconProps } from '@lib/FAIcon'

export type QuestionIconProps = Omit<FAIconProps, 'icon'>

export const QuestionIcon = ({
  size = 18,
  color = Color.Gray,
  ...props
}: QuestionIconProps) => (
  <FAIcon icon={faQuestionCircle} size={size} color={color} {...props} />
)

export default QuestionIcon
