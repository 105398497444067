import { Image } from '@components'
import AppButton from '@components/AppButton'
import CheckBox from '@components/CheckBox'
import CommonHeader from '@components/CommonHeader'
import ItemSeparator from '@components/ItemSeparator'
import { GoalEntity } from '@interfaces/Goal'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import RNProgressHud from '@lib/ProgressHUD'
import { GoalManager } from '@lib/api/Goal'
import { selectGoals } from '@lib/api/Goal/slice'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { useSelector } from 'react-redux'
import { navigatePremiumPlanLP } from '../PremiumPlan'

const DeleteGoalsScreen = () => {
  const goals = useSelector(selectGoals)

  const [refreshing, setRefreshing] = useState(false)
  const [unSelectedFinancialAccounts, setUnSelectedFinancialAccounts] =
    useState(EmptyGoalSet)
  const [displayMode, setDisplayMode] = useState<'select' | 'confirm'>('select')

  const flatList = useRef<FlatList<GoalEntity>>(null)

  const mergedGoals = useMemo(
    () => goals?.user?.concat(goals?.family || []) || [],
    [goals],
  )

  const onPressConfirm = useCallback(() => {
    const count = mergedGoals.length - (unSelectedFinancialAccounts?.size ?? 0)

    if (count > 3) {
      CommonDialog.showMessage('継続する目標貯金は\n3個まで選択可能です')
    } else {
      setDisplayMode('confirm')
      scrollToTop()
    }
  }, [mergedGoals.length, unSelectedFinancialAccounts?.size])

  const scrollToTop = () => {
    flatList.current?.scrollToOffset({ animated: true, offset: 0 })
  }

  const onPressItem = useCallback(
    (goal: GoalEntity) => {
      if (unSelectedFinancialAccounts.has(goal)) {
        unSelectedFinancialAccounts.delete(goal)
      } else {
        unSelectedFinancialAccounts.add(goal)
      }
      setUnSelectedFinancialAccounts(new Set(unSelectedFinancialAccounts))
    },
    [unSelectedFinancialAccounts],
  )

  const renderItem: ListRenderItem<GoalEntity> = useCallback(
    ({ item }) => {
      const selected = !unSelectedFinancialAccounts.has(item)

      return (
        <>
          <TouchableOpacity
            style={styles.item}
            disabled={displayMode === 'confirm'}
            onPress={() => onPressItem(item)}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <View>
                <Text style={styles.goalNameText}>{item.name}</Text>
                <Text style={styles.goalAmountText}>
                  {item.currentAmount.jpCurrency()}
                  {' / '}
                  <Text style={{ color: '#666666' }}>
                    {item.goalAmount.jpCurrency()}
                  </Text>
                </Text>
              </View>
              <CheckBox isOn={selected} />
            </View>
          </TouchableOpacity>
        </>
      )
    },
    [displayMode, onPressItem, unSelectedFinancialAccounts],
  )

  const renderHeader = () => {
    return (
      <>
        <View
          style={{
            backgroundColor: '#f7f7f7',
            padding: 20,
          }}>
          <View
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
              borderWidth: 1,
              borderColor: '#f0f0f0',
              padding: 20,
            }}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                <Image source={Icon.Caution} style={styles.cautionImage} />
                <Text
                  style={{
                    color: '#ff7200',
                    fontSize: 17,
                    fontWeight: 'bold',
                  }}>
                  削除時のご注意
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 18,
                  textAlign: 'center',
                }}>
                目標貯金が完全に削除され、{'\n'}後から復元はできません。
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: '#666666',
                  lineHeight: 16,
                  paddingTop: 10,
                }}>
                ・貯金の詳細が閲覧できなくなります{'\n'}
                ・貯金の追加、自動貯金ができなくなります{'\n'}
                ・口座に紐づく貯金が表示されなくなります{'\n'}
                ・家族画面ではパートナー側も見れなくなります
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: 'white',
            paddingHorizontal: 20,
            alignItems: 'center',
          }}>
          {displayMode === 'select' ? (
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 17,
                paddingTop: 20,
                lineHeight: 22,
                textAlign: 'center',
              }}>
              続ける目標貯金を3個まで選んでください
            </Text>
          ) : (
            <Text
              style={{
                color: '#ff7200',
                fontWeight: 'bold',
                fontSize: 17,
                paddingTop: 20,
                lineHeight: 22,
                textAlign: 'center',
              }}>
              チェック済の貯金を継続します
            </Text>
          )}
          <Text style={{ fontWeight: 'bold', fontSize: 12, paddingTop: 5 }}>
            (チェックを外した貯金は削除されます)
          </Text>
          <Text
            style={{
              color: '#3a3a3a',
              fontSize: 12,
              fontWeight: 'normal',
              paddingTop: 10,
            }}>
            無料プランでは
            <Text style={{ fontWeight: 'bold' }}>最大3個</Text>まで管理できます
          </Text>
          <Text
            style={{
              color: '#3fc515',
              fontSize: 12,
              fontWeight: 'normal',
              paddingTop: 5,
            }}>
            🏅プレミアムプランなら
            <Text style={{ fontWeight: 'bold' }}>上限無制限</Text>です
          </Text>
        </View>
        <View style={{ borderBottomWidth: 1, borderBottomColor: '#eeeeee' }}>
          <Text
            style={{
              paddingHorizontal: 20,
              paddingTop: 25,
              paddingBottom: 10,
              color: '#3a3a3a',
              fontSize: 13,
              fontWeight: 'bold',
            }}>
            目標貯金の一覧
          </Text>
        </View>
      </>
    )
  }

  const renderFooter = () => {
    const buttonWidth = (wp('100%') - 50) / 2
    return (
      <View
        style={{
          borderTopColor: '#eeeeee',
          borderTopWidth: 1,
          paddingHorizontal: 20,
          paddingVertical: 20,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {displayMode === 'select' ? (
          <AppButton
            type="secondary"
            title="確認画面へ"
            style={{ width: buttonWidth }}
            textStyle={{ fontWeight: 'bold', fontSize: 14 }}
            onPress={onPressConfirm}
          />
        ) : (
          <AppButton
            type="secondary"
            title="完了する"
            style={{ width: buttonWidth }}
            textStyle={{ fontWeight: 'bold', fontSize: 14 }}
            onPress={() => {
              RNProgressHud.show()
              deleteFinancialAccounts()
              RNProgressHud.dismiss()
              NavigationService.goBack()
            }}
          />
        )}
        <AppButton
          title={'プレミアムプラン\nを確認する'}
          style={{ width: buttonWidth }}
          textStyle={{ fontWeight: 'bold', fontSize: 14, lineHeight: 18 }}
          onPress={() => {
            NavigationService.goBack()
            navigatePremiumPlanLP({ planCode: '001' })
          }}
        />
      </View>
    )
  }

  const deleteFinancialAccounts = () => {
    unSelectedFinancialAccounts.forEach(async (v) => {
      await GoalManager.deleteGoal({ goalId: v.goalId })
    })
  }

  return (
    <View style={styles.container}>
      <CommonHeader
        leftButtonType={displayMode === 'select' ? 'modal' : 'stack'}
        title={displayMode === 'select' ? '連携を続ける口座を選択' : '確認画面'}
        onPressLeftButton={() => {
          if (displayMode === 'select') {
            NavigationService.goBack()
          } else {
            setDisplayMode('select')
            scrollToTop()
          }
        }}
      />
      <FlatList
        ref={flatList}
        data={mergedGoals}
        renderItem={renderItem}
        keyExtractor={(item) => String(item.goalId)}
        ItemSeparatorComponent={() => ItemSeparator}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={async () => {
              setRefreshing(true)
              await FinancialManager.fetchAssets()
              setRefreshing(false)
            }}
          />
        }
        ListHeaderComponent={renderHeader}
        ListFooterComponent={renderFooter}
      />
    </View>
  )
}

const EmptyGoalSet = new Set<GoalEntity>()

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cautionImage: {
    marginEnd: 10,
    resizeMode: 'contain',
    width: 20,
    height: 20,
  },
  item: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    justifyContent: 'center',
  },
  goalNameText: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingLeft: 5,
  },
  goalAmountText: {
    marginTop: 5,
    fontSize: 13,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingLeft: 5,
  },
})

export default DeleteGoalsScreen
