import Color from '@lib/Color'
import { CheckMark, CheckMarkOff } from '@lib/Icon'
import React from 'react'
import { Pressable, PressableProps } from 'react-native'
import styled from 'styled-components/native'

export const RootContainer = styled(Pressable)``

export const StyleImage = styled.Image<{ size?: number }>`
  height: ${({ size }) => (size !== undefined ? size : 30)}px;
  width: ${({ size }) => (size !== undefined ? size : 30)}px;
  resize-mode: contain;
`

export const StyledIcon = styled.Image<{ size?: number }>`
  height: ${({ size }) => (size !== undefined ? size : 30)}px;
  width: ${({ size }) => (size !== undefined ? size : 30)}px;
  resize-mode: contain;
  background-color: ${Color.Primary};
  border-radius: ${({ size }) => (size !== undefined ? size / 2 : 15)}px;
`

export type CheckMarkToggleButtonProps = PressableProps & {
  isOn?: boolean
  size?: number
}

const CheckMarkToggleButton: React.FC<CheckMarkToggleButtonProps> = ({
  onPress,
  disabled,
  isOn,
  size,
}) => {
  return (
    <RootContainer
      onPress={onPress}
      disabled={disabled}
      style={[{ width: size, height: size }]}>
      {isOn ? (
        <StyledIcon source={CheckMark as never} size={size} />
      ) : (
        <StyleImage source={CheckMarkOff as never} size={size} />
      )}
    </RootContainer>
  )
}

export default CheckMarkToggleButton
