import AppButton from '@components/AppButton'
import FullWidthImage from '@components/FullWidthImage'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import store from '@redux/store'
import { useCallback, useEffect, useState } from 'react'
import {
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { SafeAreaView } from 'react-native-safe-area-context'
import { logging } from '../Firestore'
import { updateRootScreen } from '../redux/actions'

const Tab = createMaterialTopTabNavigator()

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  prevButtonView: {
    position: 'absolute',
    left: wp('3%'),
    top: wp('3%'),
  },
  headerButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'normal',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
  },
  skipButtonView: {
    position: 'absolute',
    right: wp('3%'),
    top: wp('3%'),
  },
  textView: {
    paddingTop: wp('4.5%'),
    width: '100%',
    height: '47%',
    paddingLeft: 0,
    paddingRight: 0,
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  textTitle: {
    fontSize: wp('7.5%'),
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: wp('10%'),
    marginBottom: wp('7%'),
  },
  text: {
    fontSize: wp('3.8%'),
    fontWeight: 'normal',
    lineHeight: 18,
    textAlign: 'center',
  },
  buttonView: {
    width: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    position: 'absolute',
    bottom: 30,
  },
  page: {
    width: '100%',
    height: '100%',
  },
})

const Pages = [
  () => (
    <View style={styles.page}>
      <FullWidthImage
        source={require('@images/walkthrough/walkthrough-1.png')}
        verticalAdjustment={-0.1}
      />
      <View style={styles.textView}>
        <Text style={styles.textTitle}>
          夫婦で一緒に{'\n'}お金をカンタン管理
        </Text>
        <Text style={styles.text}>
          個人のお金は自分専用ページで。{'\n'}だからプライベートもOK。
        </Text>
      </View>
    </View>
  ),
  () => (
    <View style={styles.page}>
      <FullWidthImage
        source={require('@images/walkthrough/walkthrough-2.png')}
        verticalAdjustment={-0.1}
      />
      <View style={styles.textView}>
        <Text style={styles.textTitle}>
          夫婦のお金も自分のお金も{'\n'}アプリひとつで
        </Text>
        <Text style={styles.text}>個人ページは自分だけがチェック◎{'\n'} </Text>
      </View>
    </View>
  ),
  () => (
    <View style={styles.page}>
      <FullWidthImage
        source={require('@images/walkthrough/walkthrough-3.png')}
        verticalAdjustment={-0.1}
      />
      <View style={styles.textView}>
        <Text style={styles.textTitle}>
          シェアしたい支払いは{'\n'}スワイプで共有完了！
        </Text>
        <Text style={styles.text}>
          自分で払った家賃など、{'\n'}シェアしたい分を選んで共有。
        </Text>
      </View>
    </View>
  ),
  () => (
    <View style={styles.page}>
      <FullWidthImage
        source={require('@images/walkthrough/walkthrough-4.png')}
        verticalAdjustment={-0.1}
      />
      <View style={styles.textView}>
        <Text style={styles.textTitle}>家族貯金も{'\n'}かんたんにスタート</Text>
        <Text style={styles.text}>
          家族の旅行や子供の教育など、{'\n'}
          目標別の貯金がすぐにはじめられます。
        </Text>
      </View>
    </View>
  ),
  () => (
    <View style={styles.page}>
      <FullWidthImage
        source={require('@images/walkthrough/walkthrough-5.png')}
        verticalAdjustment={-0.1}
      />
      <View style={styles.textView}>
        <Text style={styles.textTitle}>
          管理はOsidOriに{'\n'}2人は家族の時間を
        </Text>
        <Text style={styles.text}>
          忙しい2人に代わって、OsidOriが家計の管理を{'\n'}
          します。空いた時間を家族の時間に。
        </Text>
      </View>
    </View>
  ),
]

const NullTabBar = () => null

const WalkthroughScreen = () => {
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const [pageIndex, setPageIndex] = useState(0)

  const navigateNextScreen = useCallback(() => {
    SessionManager.setWalkthroughDisplayCompleted(true)
    store.dispatch(updateRootScreen('Account'))
  }, [])

  const onButtonPress = useCallback(() => {
    if (pageIndex < Pages.length - 1) {
      navigation.navigate(`Walkthrough-${pageIndex + 1}` as never)
    } else {
      navigateNextScreen()
    }
  }, [navigateNextScreen, navigation, pageIndex])

  const onPrevButtonPress = useCallback(() => {
    if (pageIndex > 0) {
      navigation.navigate('Walkthrough-0' as never)
    }
  }, [navigation, pageIndex])

  const onSkipButtonPress = useCallback(() => {
    navigateNextScreen()
  }, [navigateNextScreen])

  useEffect(() => {
    TrackingUtils.ga.pageview({
      page: `Walkthrough-${pageIndex + 1}`,
      title: `ウォークスルー_${pageIndex + 1}枚目`,
    })
  }, [pageIndex])

  useEffect(() => {
    if (isFocused) {
      logging('ウォークスルー画面表示')
    }
  }, [isFocused])

  return (
    <View style={styles.container}>
      <StatusBar
        translucent={true}
        backgroundColor="transparent"
        hidden={false}
        barStyle="dark-content"
      />
      <Tab.Navigator
        backBehavior="none"
        tabBar={NullTabBar}
        // swipeEnabled={false}
        animationEnabled={false}>
        {Pages.map((page, index) => (
          <Tab.Screen
            key={`Walkthrough-${index}`}
            name={`Walkthrough-${index}`}
            component={page}
            listeners={{
              focus: () => setPageIndex(index),
            }}
          />
        ))}
      </Tab.Navigator>

      {pageIndex > 0 && (
        <SafeAreaView edges={['top']} style={styles.prevButtonView}>
          <TouchableOpacity onPress={onPrevButtonPress}>
            <Text style={styles.headerButtonText}>＜ 前の説明に戻る</Text>
          </TouchableOpacity>
        </SafeAreaView>
      )}
      {pageIndex === 0 && (
        <SafeAreaView edges={['top']} style={styles.skipButtonView}>
          <TouchableOpacity onPress={onSkipButtonPress}>
            <Text style={styles.headerButtonText}>説明をスキップする ＞</Text>
          </TouchableOpacity>
        </SafeAreaView>
      )}
      <View style={styles.buttonView}>
        <View
          style={{
            width: Pages.length * 13,
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'space-between',
            marginBottom: 15,
          }}>
          {Pages.map((_, index) => (
            <View
              key={`Page-${index}`}
              style={{
                height: 8,
                width: 8,
                borderRadius: 4,
                backgroundColor: pageIndex === index ? '#3388ff' : '#cccccc',
              }}
            />
          ))}
        </View>
        <AppButton
          title={pageIndex < Pages.length - 1 ? '次へ' : 'OsidOriをはじめる'}
          onPress={onButtonPress}
        />
      </View>
    </View>
  )
}

export default WalkthroughScreen
