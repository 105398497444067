import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import CommonHeader from '@components/CommonHeader'
import { getApiUrl } from '@lib/api'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useEffect, useMemo } from 'react'
import { View } from 'react-native'
import { WebView } from 'react-native-webview'
import { useSelector } from 'react-redux'
import { selectUserAccountType } from './MonthlyReportScreen/slice'

const WeeklyReportWebViewScreen: React.FC<
  StackScreenProps<RootStackParamList, 'WebView'>
> = () => {
  const userAccountType = useSelector(selectUserAccountType)

  const uri = useMemo(
    () =>
      `${getApiUrl()}/report/weekly?token=${SessionManager.getAccessToken()}`,
    [],
  )

  useEffect(() => {
    if (userAccountType === 'user') {
      TrackingUtils.repro.track('【Screen】personal_weekly report', 'Screen')
    } else {
      TrackingUtils.repro.track('【Screen】family_weekly report', 'Screen')
    }
  }, [userAccountType])

  return (
    <View style={{ flex: 1, backgroundColor: 'black' }}>
      <CommonHeader title="ウィークリーレポート" leftButtonType="modal" />
      {uri ? (
        <WebView
          source={{ uri }}
          startInLoadingState={true}
          renderLoading={() => <AbsoluteActivityIndicator />}
        />
      ) : (
        <AbsoluteActivityIndicator />
      )}
    </View>
  )
}

export default WeeklyReportWebViewScreen
