import { UserAccountTypeWithAll } from '@interfaces/Account'
import store from '@redux/store'
import { StartDateSettingOption } from './DateUtils'

export type StartDateSettings = {
  start: number
  startDateSettingOption: StartDateSettingOption
}

export const getStartDateSettings = (
  accountMode: UserAccountTypeWithAll = store.getState().account.accountMode,
): StartDateSettings => {
  const plSettings = store.getState().profile.plSettings

  const defaultStartDateSettings: StartDateSettings = {
    start: 1,
    startDateSettingOption: StartDateSettingOption.None,
  }

  if (!plSettings?.groupPlPeriodDate || !plSettings.groupPlPeriodDate) {
    return defaultStartDateSettings
  }

  const userStart = plSettings.plPeriodDate || 1
  const userStartDateSettingOption: StartDateSettingOption =
    plSettings.plType || StartDateSettingOption.None
  const familyStart = plSettings.groupPlPeriodDate || 1
  const falimyStartDateSettingOption: StartDateSettingOption =
    plSettings.groupPlType || StartDateSettingOption.None

  if (accountMode === 'user') {
    return {
      start: userStart,
      startDateSettingOption: userStartDateSettingOption,
    }
  } else {
    // allは家族にする
    return {
      start: familyStart,
      startDateSettingOption: falimyStartDateSettingOption,
    }
  }
}
