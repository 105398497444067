import { APIError } from '@lib/api'
import * as StripeAPI from './api'
import {
  GetStringPurchaseConfirmResponse,
  GetStripePremiumPlansResponse,
  StringPurchaseConfirmProps,
} from './types'

export const getStripe = async (planCode?: string) => {
  const response = await StripeAPI.getStripePremiumPlans(planCode)
  if (!response.ok) throw new APIError(response)
  return response.json as GetStripePremiumPlansResponse
}

export const verification = async () => {
  const response = await StripeAPI.verification()
  if (!response.ok) throw new APIError(response)
}

export const purchaseConfirm = async (props: StringPurchaseConfirmProps) => {
  const response = await StripeAPI.purchaseConfirm(props)
  if (!response.ok) throw new APIError(response)
  return response.json as GetStringPurchaseConfirmResponse
}
