import { Image } from '@components'
import Color from '@lib/Color'
import { goalFaceIcon } from '@lib/Icon'
import { useCallback } from 'react'
import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import { ProgressCircle } from 'react-native-svg-charts'
import styled from 'styled-components/native'

const StyledRootContainer = styled(TouchableOpacity)`
  margin-left: 15px;
  border-radius: 8px;
  overflow: hidden;
  border-width: 1px;
  border-color: #dddddd;
  background-color: white;
  justify-content: space-between;
`

const StyledProgressView = styled.View`
  width: ${wp(20)}px;
  height: ${wp(20)}px;
  align-items: center;
  justify-content: center;
`

const StyledFaiceView = styled.View`
  align-items: center;
  position: absolute;
`

const StyledProgressCircle = styled(ProgressCircle)`
  position: absolute;
  width: ${wp(20)}px;
  height: ${wp(20)}px;
`

const StyledBottomView = styled.View`
  width: ${wp(60)}px;
  height: ${wp(60) * (56.3 / 216)}px;
  /* background-color: red; */
  justify-content: center;
`

type ProgressStatus = 'best' | 'normal' | 'bad'

export type GoalCardProps = TouchableOpacityProps & {
  status: ProgressStatus
  title: string
  currentAmount: number
  goalAmount: number
  imageURI: string
  progress: number
  isPlanError?: boolean
}

const GoalCard = ({
  status,
  title,
  currentAmount: current,
  goalAmount: target,
  imageURI,
  progress,
  isPlanError,
  ...props
}: GoalCardProps) => {
  const textStyle = useCallback((status: ProgressStatus) => {
    switch (status) {
      case 'best':
        return styles.statusBestText
      case 'normal':
        return styles.statusNormalText
      case 'bad':
        return styles.statusBadText
    }
  }, [])

  const progressTextColor = useCallback((status: ProgressStatus) => {
    switch (status) {
      case 'best':
        return '#77C325'
      case 'normal':
        return '#FCC300'
      case 'bad':
        return '#FF7200'
    }
  }, [])

  return (
    <StyledRootContainer {...props}>
      <ImageBackground
        source={{ uri: imageURI }}
        resizeMode="cover"
        style={{
          width: wp(60),
          height: wp(60) * (112 / 216),
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <StyledProgressView>
          <StyledProgressCircle
            progress={progress}
            progressColor={progressTextColor(status)}
            backgroundColor={Color.Gray}
            strokeWidth={4}
          />
          <StyledFaiceView>
            <Image
              style={styles.itemImageStatus}
              source={goalFaceIcon(status)}
              resizeMode="contain"
            />
            <Text style={styles.progressText}>
              {Math.round(progress * 100)}%
            </Text>
          </StyledFaiceView>
        </StyledProgressView>
      </ImageBackground>
      <StyledBottomView>
        <Text style={styles.itemTitle}>{title}</Text>
        <View style={styles.itemProgress}>
          <Text style={textStyle(status)}>{current.jpCurrency()}</Text>
          <Text style={styles.itemProgressSeparator}> / </Text>
          <Text style={styles.itemProgressTarget}>{target.jpCurrency()}</Text>
        </View>
      </StyledBottomView>
      {isPlanError && <View style={styles.disabledView} />}
    </StyledRootContainer>
  )
}

const styles = StyleSheet.create({
  disabledView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#3a3a3a33',
    borderRadius: 8,
  },
  itemImageStatus: {
    width: wp(10),
    height: wp(10),
  },
  itemTitle: {
    marginLeft: 15,
    marginBottom: 2,
    color: Color.TextBlack,
    fontSize: 16,
    fontWeight: 'bold',
  },
  itemProgress: {
    paddingLeft: 15,
    flexDirection: 'row',
  },
  itemProgressSeparator: {
    color: '#3A3A3A',
    fontSize: 14,
    fontWeight: 'normal',
  },
  itemProgressTarget: {
    color: '#3A3A3A',
    fontSize: 14,
    fontWeight: 'normal',
  },
  statusBestText: {
    color: '#77C325',
    fontSize: 14,
    fontWeight: 'bold',
  },
  statusNormalText: {
    color: '#FCC300',
    fontSize: 14,
    fontWeight: 'bold',
  },
  statusBadText: {
    color: '#FF7200',
    fontSize: 14,
    fontWeight: 'bold',
  },
  progressText: {
    marginTop: 3,
    color: Color.White,
    fontSize: 12,
    fontWeight: 'bold',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
  },
})

export default GoalCard
