import { useContext } from 'react'
import SpotlightTourContext from './SpotlightTourContext'

export const useSpotlightTour = () => {
  const {
    startSpotlightTour,
    finishSpotlightTour,
    setStep,
    nextStep,
    previousStep,
    setOverlayColor,
    setDisabled,
  } = useContext(SpotlightTourContext)

  return {
    startSpotlightTour,
    finishSpotlightTour,
    setStep,
    nextStep,
    previousStep,
    setOverlayColor,
    setDisabled,
  }
}
