import styled from 'styled-components/native'

export const RootContainer = styled.ScrollView`
  flex: 1;
`

export const StyledTextInput = styled.TextInput`
  border-width: 0.5px;
  border-color: #cccccc;
  width: 80px;
  height: 30px;
  text-align: right;
  padding-horizontal: 5px;
  padding-vertical: 0;
`
