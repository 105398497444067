import FamilyShareToggleButton from '@components/FamilyShareToggleButton'
import IconImage from '@components/IcomImage'
import Color from '@lib/Color'
import BudgetCategoryItem from '@Screen/Main/Home/Budget/BudgetCategoryItem'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  flex-direction: row;
`

export const ListHeaderContainer = styled.View`
  /* flex-direction: row; */
`

export const ListHeaderTopContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* background-color: red; */
`

export const DropdownContainer = styled.TouchableOpacity`
  background-color: #f0f0f0;
  height: 32px;
  padding-horizontal: 16px;
  border-radius: 16px;
  align-items: center;
  flex-direction: row;
`

export const DropdownText = styled.Text`
  font-size: 15px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const DropdownIconImage = styled(IconImage)`
  margin-left: 4px;
`

export const StyledFamilyShareToggleButton = styled(FamilyShareToggleButton)`
  position: absolute;
  right: 15px;
`

export const AllCategoryContainer = styled.View`
  flex-direction: row;
`

export const AllCategoryIconContainer = styled.View``

export const AllCategoryDetailContainer = styled.View`
  flex: 1;
`

export const AllCategoryDataContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`

export const AllCategoryExpenseContainer = styled.View`
  flex: 1;
`

export const AllCategoryRemainContainer = styled.View`
  flex: 1;
  align-items: flex-end;
`

export const AllCategoryText = styled.Text`
  font-size: 15px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const BudgetSettingButton = styled.TouchableOpacity`
  flex-direction: row;
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 15px;
`

export const BudgetSettingButtonTitle = styled.Text`
  font-size: 14px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

export const StyledBudgetCategoryItem = styled(BudgetCategoryItem)`
  margin-top: 10px;
  margin-bottom: 10px;
`
