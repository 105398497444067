const Toast = {
  SHORT: 'SHORT',
  LONG: 'LONG',
  BOTTOM: 'BOTTOM',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showWithGravity: (message: string, time: string, position: string) => {
    // TODO
  },
}

export default Toast
