import AppButton from '@components/AppButton'
import HomeBudgetPremiumBlurView from '@components/BudgetPremiumBlurView'
import { AccountState } from '@lib/AccountManager/types'
import { BudgetManager } from '@lib/Budget'
import { BudgetReduxState } from '@lib/Budget/types'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import { getYearMonth, StartDateSettingOption } from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import DateUtilsV1 from '@lib/DateUtilsV1'
import { FinancialState } from '@lib/FinancialManager/types'
import NavigationService, {
  navigateToNavigatableScreen,
} from '@lib/NavigationService'
import { ProfileState } from '@lib/ProfileManager/types'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import HomeBudgetChart from './HomeBudgetChart'

interface Props {}

interface StateProps {
  financial: FinancialState
  profile: ProfileState
  account: AccountState
  budget: BudgetReduxState
}

type State = {
  isLoading: boolean
}

class HomeBudget extends React.Component<
  Props & StateProps & StackScreenProps<RootStackParamList, 'HomeBudget'>,
  State
> {
  state = {
    isLoading: true,
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.setState({ isLoading: true }, async () => {
        await this.fetchBudget()
        this.setState({ isLoading: false })
      })
    })
  }

  private fetchBudget = async () => {
    try {
      const settings = getStartDateSettings(this.props.account.accountMode)

      const yearMonth = getYearMonth(
        moment(),
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      )

      await BudgetManager.fetchBudget({
        year: yearMonth.year,
        month: yearMonth.month,
        userAccountType: this.props.account.accountMode,
      })
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  private renderSummary = () => {
    const yyyymm = DateUtilsV1.monthKey()
    const budget = this.props.budget[this.props.account.accountMode][yyyymm]
    if (!budget) return null

    return (
      <View style={{ minHeight: 230 }}>
        <TouchableOpacity
          style={styles.container}
          onPress={() =>
            navigateToNavigatableScreen([
              { name: 'HomeExpensesCategoryBudgets' },
              {
                name: 'HouseholdAccountsTab',
                params: { tab: 'HomeExpensesCategoryBudgets' },
              },
            ])
          }>
          <HomeBudgetChart />
        </TouchableOpacity>
        {budget && budget.amount > 0 ? (
          <View style={styles.footerView}>
            <View
              style={{
                backgroundColor: Color.Primary,
                width: 16,
                height: 16,
                borderRadius: 8,
              }}
            />
            <Text style={{ marginLeft: 5, color: Color.DefaultText }}>
              支出
            </Text>
            <View
              style={{
                marginLeft: 10,
                backgroundColor: '#eeeeee',
                width: 16,
                height: 16,
                borderRadius: 8,
              }}
            />
            <Text style={{ marginLeft: 5, color: Color.DefaultText }}>
              残り
            </Text>
          </View>
        ) : (
          <View style={{ paddingHorizontal: 15, paddingBottom: 10 }}>
            <AppButton
              type="white"
              title="予算を設定する"
              onPress={() => NavigationService.navigate('BudgetSetting')}
              textStyle={{ fontWeight: 'bold' }}
            />
          </View>
        )}
        {this.props.account.accountMode === 'user' &&
          this.props.profile.userProfile?.rank === 'free' && (
            <HomeBudgetPremiumBlurView
            // disableBlur={
            //   Platform.OS === 'android' && !this.props.navigation.isFocused()
            // }
            />
          )}
      </View>
    )
  }

  private renderMonthlyBalanceNoData = () => (
    <View style={styles.monthlyBalanceNoData}>
      <Text style={styles.titleMonthlyBalanceNoData}>今月の分析</Text>
      <View style={styles.contentMonthlyBalanceNoData}>
        <Image
          resizeMode="contain"
          source={require('@images/chartCircle/items-chart-circle.png')}
          style={styles.iconChartCircle}
        />
        <View style={styles.ViewMonthlyBalanceNoDataRight}>
          <Text style={styles.contentMonthlyBalanceNoDataRight}>
            予算を設定すると、いくら使って、残りいくら使えるかが見える化します
          </Text>
          <View style={styles.wrapButtonContentMonthlyBalanceNoDataRight}>
            <AppButton
              type="secondary-min"
              title="予算を設定する"
              onPress={() => NavigationService.navigate('BudgetSetting')}
            />
          </View>
        </View>
      </View>
    </View>
  )

  render() {
    // if (this.state.isLoading) return null

    const { accountMode } = this.props.account

    const yyyymm = DateUtilsV1.monthKey()
    const budget = this.props.budget[accountMode][yyyymm]

    return (budget?.amount || 0) > 0
      ? this.renderSummary()
      : this.renderMonthlyBalanceNoData()
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 23,
    paddingBottom: 15,
  },
  monthlyBalanceNoData: {
    width: '100%',
    height: 200,
    paddingTop: 20,
    paddingHorizontal: 20,
    backgroundColor: Color.White,
    marginTop: 15,
  },
  titleMonthlyBalanceNoData: {
    color: '#555555',
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 21,
  },
  contentMonthlyBalanceNoData: {
    width: '100%',
    flexDirection: 'row',
  },
  iconChartCircle: {
    width: 110,
    height: 110,
    flex: 1,
  },
  contentMonthlyBalanceNoDataRight: {
    color: '#8e8e8e',
    fontSize: 13,
    fontWeight: 'normal',
  },
  ViewMonthlyBalanceNoDataRight: {
    flex: 1.3,
  },
  wrapButtonContentMonthlyBalanceNoDataRight: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  footerView: {
    borderTopColor: Color.LightGray,
    borderTopWidth: 2,
    paddingHorizontal: 20,
    height: 50,
    maxHeight: 50,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  footerTitle: {
    color: Color.DefaultText,
    fontSize: 14,
    fontWeight: 'normal',
    marginRight: 10,
  },
  footerAmount: {
    fontSize: 14,
    fontWeight: 'normal',
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
    profile: state.profile,
    account: state.account,
    budget: state.budget,
  }
}

export default connect(mapStateToProps)(HomeBudget)
