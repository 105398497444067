import { useNavigation, useRoute } from '@react-navigation/native'
import { useEffect, useRef } from 'react'
import { Platform } from 'react-native'

// react-native-web と react-navigation verstion 5で
// タブを指定して画面遷移すると、タブの内容が１番目の画面が表示されるバグの回避

export const useTabNavigatorWebHack = (screenName?: string) => {
  const route = useRoute()
  const { navigate } = useNavigation()
  const tabNavigatorWebHacked = useRef(0)

  useEffect(() => {
    if (Platform.OS === 'web') {
      const screen =
        screenName ??
        (route.params &&
        'screen' in route.params &&
        typeof route.params.screen === 'string'
          ? route.params.screen
          : undefined)

      if (
        screen &&
        tabNavigatorWebHacked.current < 4 // 3だとうまくいかない
      ) {
        tabNavigatorWebHacked.current++
        navigate(screen)
      }
    }
  }, [navigate, route, screenName])
}
