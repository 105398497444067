import { deeplinkNavigation } from './DeeplinkNavigationParams'

export interface CloseAccountTrackerParams {
  fncId: string
  fncType: string
}

export const closeAccountTracker = async (
  params: CloseAccountTrackerParams,
) => {
  deeplinkNavigation({ name: 'WaitFinancialAccountSync', ...params })
  // NavigationService.navigate('WaitFinancialAccountSync', params)
}
