import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { NativeApp } from '@lib/Env'
import { useTabNavigatorBugWorkaround } from '@lib/hooks'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'
import DiagnosisResultScreen from '@Screen/Diagnose/DiagnosisResultScreen'
import HouseholdDiagnosisScreen from '@Screen/Diagnose/HouseholdDiagnosisScreen'
import UserInfoDiagnosisScreen from '@Screen/Diagnose/UserInfoDiagnosisScreen'
import FPHomeDiagnosisScreen from './FPHomeDiagnosisScreen'
import FPWebViewScreen from './FPWebViewScreen'
import { RootContainer } from './styles'

const Tab = createMaterialTopTabNavigator()

const FPScreen = () => {
  useTabNavigatorBugWorkaround()

  return (
    <RootContainer>
      <CommonHeader title="かんたん家計診断" leftButtonType="modal" />
      <Tab.Navigator backBehavior="none" tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="FPWebView"
          component={FPWebViewScreen}
          options={{ title: '家計診断' }}
        />
        <Tab.Screen
          name="FPHomeDiagnosis"
          component={FPHomeDiagnosisScreen}
          options={{ title: 'おうち情報' }}
        />
      </Tab.Navigator>
    </RootContainer>
  )
}

const Stack = createStackNavigator()

const StackScreen = () => (
  <Stack.Navigator
    screenOptions={{
      gestureEnabled: false,
      headerShown: false,
      ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
    }}>
    <Stack.Screen name="FP" component={FPScreen} />
    <Stack.Screen
      name="HouseholdDiagnosis"
      component={HouseholdDiagnosisScreen}
    />
    <Stack.Screen
      name="UserInfoDiagnosis"
      component={UserInfoDiagnosisScreen}
    />
    <Stack.Screen name="DiagnosisResult" component={DiagnosisResultScreen} />
  </Stack.Navigator>
)

export default StackScreen
