import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useMemo } from 'react'
import BeforeSettlementListScreen from './BeforeSettlementListScreen'
import SettledListScreen from './SettledListScreen'
import { RootContainer } from './styles'

const Tab = createMaterialTopTabNavigator()

// const tabBarStyles: MaterialTopTabBarOptions = {
//   activeTintColor: Color.Green,
//   inactiveTintColor: '#999',
//   indicatorStyle: {
//     backgroundColor: Color.Green,
//     height: 1,
//   },
//   labelStyle: {
//     fontWeight: '600',
//     fontSize: 15,
//   },
//   style: {
//     elevation: 0,
//     backgroundColor: 'white',
//     borderBottomWidth: 1,
//     borderBottomColor: 'rgba(0,0,0,0.1)',
//   },
// }

const PayoffListScreen: React.FC<
  StackScreenProps<RootStackParamList, 'PayoffList'>
> = ({ route }) => {
  const goBackHandler = route.params?.goBack
  const tab = route.params?.tab || 'NotSettled'

  const goBack = useCallback(() => {
    if (goBackHandler) {
      goBackHandler()
      return
    } else {
      NavigationService.goBack()
    }
  }, [goBackHandler])

  const backHandler = useMemo(
    () => HardwareBackPressHandler.addListener(goBack),
    [goBack],
  )

  useEffect(() => {
    return () => {
      backHandler?.remove()
    }
  }, [backHandler])

  return (
    <RootContainer>
      <CommonHeader title="精算管理" onPressLeftButton={goBack} />
      <Tab.Navigator
        backBehavior="none"
        tabBarOptions={TabBarOptions}
        initialRouteName={tab}>
        <Tab.Screen
          name="NotSettled"
          component={BeforeSettlementListScreen}
          options={{ title: '未精算リスト' }}
        />
        <Tab.Screen
          name="Settled"
          component={SettledListScreen}
          options={{ title: '精算済み' }}
        />
      </Tab.Navigator>
    </RootContainer>
  )
}

export default PayoffListScreen
