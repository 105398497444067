import UAParser from 'ua-parser-js'

/*
GCP版
  uibank-stg.osidori-dev.com
  www.uibank-stg.osidori-dev.com
  www-85ec8360.uibank-stg.osidori-dev.com
*/
export const UIBank =
  // window.location.hostname?.startsWith('uibank') ||
  window.location.hostname?.match(/uibank/) !== null ||
  window.location.hostname === 'localhost'

export const Direct = !UIBank

export const NativeApp = false

export const WebApp = true

const uaParserResult = UAParser(window.navigator.userAgent)

export const MobileSafari =
  uaParserResult.device.type === 'mobile' && uaParserResult.os.name === 'iOS'
