import styled from 'styled-components/native'

export const StyledAbsoluteView = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
`

export const StyledPlanErrorView = styled(StyledAbsoluteView)<{
  monthlyReport?: boolean
  top?: number
}>`
  background-color: #000000a0;
  position: absolute;
  top: ${({ top }) => top || 0};
  right: 0;
  bottom: 0;
  left: 0;
`

export const PremiumContainerView = styled.View`
  /* padding-bottom: 20px; */
  padding-horizontal: ${wp(5)}px;
  /* padding-horizontal: 30px; */
`

export const PremiumTitleText = styled.Text<{ monthlyReport?: boolean }>`
  text-align: center;
  font-size: ${({ monthlyReport }) =>
    monthlyReport ? Math.min(18, wp(4.3)) : 18}px;
  font-weight: bold;
  color: white;
`

export const PremiumText = styled.Text<{ monthlyReport?: boolean }>`
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: ${({ monthlyReport }) =>
    monthlyReport ? Math.min(14, wp(3)) : 13.5}px;
  font-weight: bold;
  color: white;
  line-height: 18px;
`
