import { NativeApp } from '@lib/Env'
import React from 'react'
import DatePicker from 'react-mobile-datepicker'
import { PressableProps } from 'react-native'

export type TermType = 'yearly' | 'monthly' | 'weekly' | 'daily'

export type TermSegmentedPickerProps = PressableProps & {
  isVisible: boolean
  term?: TermType
  date: Date
  onSelect: (date: Date) => void
  onCancel: () => void
}

const TermSegmentedPicker: React.FC<TermSegmentedPickerProps> = ({
  isVisible,
  term,
  date,
  onSelect,
  onCancel,
}) => {
  if (NativeApp) return null
  if (!term) return null

  return (
    <DatePicker
      theme="ios"
      key={term}
      headerFormat={''}
      confirmText={'決定'}
      cancelText={'キャンセル'}
      value={date}
      isOpen={isVisible}
      onSelect={onSelect}
      onCancel={onCancel}
      dateConfig={
        term === 'yearly'
          ? {
              month: {
                format: (date: Date) => {
                  const year = date.getFullYear()
                  return year === 2000 ? `${date.getMonth() + 1}月` : ''
                },
                caption: 'Mon',
                step: 1,
              },
              date: {
                format: (date: Date) => {
                  const year = date.getFullYear()
                  return year === 2000 ? `${date.getDate()}日` : ''
                },
                caption: 'Day',
                step: 1,
              },
            }
          : term === 'monthly'
          ? {
              date: {
                format: (date: Date) => {
                  const year = date.getFullYear()
                  if (year !== 2000) return ''

                  const day = date.getDate()
                  return day === 31 ? '月末' : `${date.getDate()}日`
                },
                caption: 'Day',
                step: 1,
              },
            }
          : term === 'weekly'
          ? {
              date: {
                format: (date: Date) => {
                  const day = date.getDate() - 1
                  return ['日', '月', '火', '水', '木', '金', '土'][day]
                },
                caption: 'Day',
                step: 1,
              },
            }
          : undefined
      }
      min={new Date('2000-01-01')}
      max={
        term === 'yearly'
          ? new Date('2000-12-31')
          : term === 'monthly'
          ? new Date('2000-01-31')
          : new Date('2000-01-07')
      }
    />
  )
}

export default TermSegmentedPicker

// { label: '日', value: 'sunday' },
// { label: '月', value: 'monday' },
// { label: '火', value: 'tuesday' },
// { label: '水', value: 'wednesday' },
// { label: '木', value: 'thursday' },
// { label: '金', value: 'friday' },
// { label: '土', value: 'saturday' },
