import CommonHeader from '@components/CommonHeader'
import FinancialAccountIcon from '@components/FinancialAccountIcon'
import HeaderEditButton from '@components/HeaderEditButton'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import { AssetProduct, AssetUserProduct } from '@interfaces/Stock'
import Color from '@lib/Color'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import {
  Image,
  ListRenderItem,
  RefreshControl,
  SectionList,
  SectionListData,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { connect } from 'react-redux'

interface Props {}

interface State {
  refreshing: boolean
  sections: SectionListData<AssetUserProduct>[]
}

interface StateProps {
  assetProducts?: AssetProduct[]
}

class AssetProductsScreen extends React.Component<
  Props & StateProps & StackScreenProps<RootStackParamList, 'AssetProducts'>,
  State
> {
  state: State = {
    refreshing: false,
    sections: [],
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', async () => {
      this.fetchAssetProducts()
    })
  }

  componentDidUpdate(
    prevProps: Props &
      StateProps &
      StackScreenProps<RootStackParamList, 'AssetProducts'>,
  ) {
    if (prevProps.assetProducts !== this.props.assetProducts) {
      this.updateSections()
    }
  }

  private financialAccount = () => this.props.route.params?.financialAccount

  private fetchAssetProducts = async () => {
    const { accountId } = this.financialAccount()
    FinancialManager.fetchAssetProducts({
      userAccountType: store.getState().account.accountMode,
      accountId,
    })
  }

  private onPressEditButton = () => {
    NavigationService.navigate('EditFinancialAccount', {
      financialAccount: this.financialAccount(),
    })
  }

  private renderRightButton = () => (
    <HeaderEditButton onPress={this.onPressEditButton} />
  )

  private renderItem: ListRenderItem<AssetUserProduct> = ({ item }) => {
    return (
      <View style={styles.itemContainer}>
        <View style={styles.row}>
          <Image
            source={this.getInvestArrowIcon(item.productProfitLossAmount)}
            style={styles.iconLabel}
          />
          <Text style={styles.textItem}>{item.productName}</Text>
        </View>
        <View style={[styles.row, styles.jpyBottom]}>
          <Text style={styles.textItem}>評価額</Text>
          {item.productBalance !== null ? (
            <Text style={styles.jpy}>{item.productBalance.jpCurrency()}</Text>
          ) : (
            <Text
              style={[styles.jpyOffset, { marginBottom: 1, marginRight: 2 }]}>
              金融機関側更新中
            </Text>
          )}
        </View>
        <View style={[styles.row, styles.jpyInvestment]}>
          <Text style={styles.profitLossLabel}>評価損益</Text>
          <Text
            style={
              item.productProfitLossAmount === null
                ? styles.jpyOffset
                : item.productProfitLossAmount > 0
                ? styles.jpyPlus
                : styles.jpyMinus
            }>
            {item.productProfitLossAmount !== null
              ? item.productProfitLossAmount.jpCurrency()
              : '金融機関側更新中'}
          </Text>
        </View>
      </View>
    )
  }

  private updateSections = () => {
    const assetProducts = this.props.assetProducts || []

    const sections: SectionListData<AssetUserProduct>[] = assetProducts
      .filter((v) => v.atUserProducts.length > 0)
      .map((assetProduct) => ({
        title: assetProduct.assetsProductType,
        data: assetProduct.atUserProducts,
      }))
    sections.push({
      title: '現金',
      data: [
        {
          productProfitLossAmount: 0,
          productBalance: this.financialAccount().depositBalance || 0,
          atUserProductId: 0,
          productBondRate: null,
          productName: '現金',
        },
      ],
    })

    this.setState({ sections })
  }

  private getInvestArrowIcon = (profit: number | null | undefined) => {
    if (!profit)
      return require('@images/icons/investment-arrow/icon-investment-arrow-offset.png')
    if (profit > 0)
      return require('@images/icons/investment-arrow/icon-investment-arrow-up.png')
    if (profit < 0)
      return require('@images/icons/investment-arrow/icon-investment-arrow-down.png')
  }

  render() {
    const financialAccount = this.financialAccount()
    return (
      <>
        <CommonHeader
          title={financialAccount.name}
          leftButtonTitle="口座"
          renderRightButton={this.renderRightButton}
        />
        <View style={styles.header}>
          <View style={styles.row}>
            <FinancialAccountIcon financialAccountType="stock" />
            <Text style={styles.textItem}>{financialAccount.name}</Text>
          </View>
          <View style={[styles.row, styles.jpyBottom]}>
            <Text style={styles.textItem}>評価額</Text>
            <Text style={styles.jpy}>
              {financialAccount.balance?.jpCurrency()}
            </Text>
          </View>
          <View style={[styles.row, styles.jpyInvestment]}>
            <Text style={styles.profitLossLabel}>評価損益</Text>
            <Text
              style={
                financialAccount.profitLossAmount! > 0
                  ? styles.jpyPlus
                  : financialAccount.profitLossAmount! < 0
                  ? styles.jpyMinus
                  : styles.jpyOffset
              }>
              {financialAccount.profitLossAmount?.jpCurrency()}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: Color.DefaultText,
                  lineHeight: 17,
                }}>
                最終取得日：
                {moment(financialAccount.scrpAt).format('YYYY/M/D h:mm')}
              </Text>
            </View>
          </View>
        </View>
        <SectionList
          sections={this.state.sections}
          renderItem={this.renderItem}
          renderSectionHeader={({ section: { title } }) => (
            <SectionListHeader title={title} />
          )}
          keyExtractor={(item: AssetUserProduct) =>
            String(item.atUserProductId)
          }
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={async () => {
                this.setState({ refreshing: true })
                await this.fetchAssetProducts()
                this.setState({ refreshing: false })
              }}
            />
          }
          ItemSeparatorComponent={() => ItemSeparator}
        />
      </>
    )
  }
}

const styles = StyleSheet.create({
  header: {
    padding: 16,
  },
  itemContainer: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: Color.White,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLabel: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  textItem: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingLeft: 5,
    paddingRight: 10,
  },
  profitLossLabel: {
    fontSize: 13,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingRight: 8,
  },
  jpyBottom: {
    marginTop: 15,
    justifyContent: 'flex-end',
  },
  jpyInvestment: {
    marginTop: 4,
    marginRight: 2,
    justifyContent: 'flex-end',
  },
  jpy: {
    fontSize: 22,
    color: '#3a3a3a',
    fontWeight: '700',
  },
  jpyPlus: {
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.Primary,
  },
  jpyMinus: {
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.Orange,
  },
  jpyOffset: {
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.Gray,
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    assetProducts: state.financial.assetProducts,
  }
}

export default connect(mapStateToProps)(AssetProductsScreen)
