import { UserAccountType } from '@interfaces/Account'
import {
  FinanceType,
  FinancialAccount,
  FinancialAccountType,
  PaymentUserType,
  PlCategory,
  PlGroupedCategory,
  Transaction,
  TransactionDetail,
  TransactionDistributedType,
  TransactionImageProps,
} from '@interfaces/Financial'
import API, { APIResponse } from './index'

export type ScrapingAccountProps = {
  financialAccountType: 'bank' | 'card' | 'emoney' | 'stock'
  id: number
}

export interface GetAccountsProps {
  accountType: UserAccountType
  financialType: 'bank' | 'card' | 'emoney'
}

export interface UpdateAccountProps {
  financialType: 'bank' | 'card' | 'emoney'
  accountId: number
  name: string
  share: boolean
}

export interface GetAccountsResponse {
  app: FinancialAccount[]
}

// DeleteAccount
export interface DeleteAccountProps {
  financialType: 'bank' | 'card' | 'emoney'
  accountId: number
}

// GetCardThisMonthsPayment
export interface GetCardThisMonthsPaymentProps {
  accountType: UserAccountType
}

export interface GetCardThisMonthsPaymentResponse {
  app: {
    currentMonthPayment: number
  }
}

export interface GetPlCategoriesResponse {
  pl: PlCategory[]
}

export interface GetPlGroupedCategoriesResponse {
  pl: PlGroupedCategory[]
}

export interface GetPlGroupedCategoriesProps {
  from: string
  to: string
  share: boolean
  userAccountType: UserAccountType
  term?: 'yearly'
  exclusionCarryForward?: boolean
}

export interface GetPlCategoriesProps {
  from: string
  to: string
  share: boolean
  userAccountType: UserAccountType
  term?: 'yearly'
  exclusionCarryForward?: boolean
}

export interface GetTransactionsProps {
  accountType?: UserAccountType
  from?: string
  to?: string
  share?: boolean
  atGroupedCategoryId?: number | string
  atTransactionCategoryId?: number | string // TODO
  scope?: 'income' | 'expense'
  distributedType?: TransactionDistributedType
  exclusionCardDupulicate?: boolean
  future?: boolean
  yetPayoff?: boolean
  useCache?: boolean
  transfer: 'transaction' | 'pl' // 必須
  // term?: 'yearly'
  exclusionCarryForward?: boolean
}

export interface GetTransactionsResponse {
  transactions: Transaction[]
  nextTransactionDate: string | null
}

export interface GetAccountTransactionsProps {
  userAccountType: UserAccountType
  financialType: 'bank' | 'card' | 'emoney' | 'wallet'
  accountId: number
  from?: string
  to?: string
  amount?: number
}

export interface GetAccountTransactionsResponse {
  transactions?: Transaction[]
  nextTransactionUsedDate?: string
}

export interface GetTransactionDetailProps {
  // userAccountType: UserAccountType
  financialAccountType: FinancialAccountType // bank, card, emony, wallet
  accountId?: number
  transactionId: number
  transfer: 'transaction' | 'pl' // 必須
  payoffId?: number
}

export interface GetTransactionDetailResponse {
  transaction: TransactionDetail
}

export interface DeleteTransactionProps {
  userAccountType: UserAccountType
  transactionId: number
}

export interface CreateTransactionDetailProps {
  userAccountType?: UserAccountType
  financialAccountType?: FinancialAccountType // bank, card, emony
  atTransactionCategoryId?: string
  usedLocation?: string
  transactionShare?: boolean
  usedDate?: string // manual only
  amount?: number // manual only
  ignore?: boolean
  memo?: string
  paymentMethodId?: number // 財布のid
  paymentMethodType?: string // 現在はwalletのみ
  type?: 'payment' | 'receipt'
  /*
    typeが未指定なら支払（金額をマイナスに）
    paymentなら支払（金額をマイナスに）
    receiptなら収入（金額をプラスに）
  */
  userManuallyCreatedTransactionShare?: boolean
  paymentUserType?: PaymentUserType
  yetPayoff?: boolean
  images?: TransactionImageProps[]
  copy?: boolean
}

export interface UpdateTransactionDetailProps
  extends CreateTransactionDetailProps {
  accountId?: number // bank, card, emoney
  transactionId: number
  action?: 'swipe'
}

export interface GetGroupedTransactionsProps {
  userAccountType: UserAccountType
  atGroupedCategoryId: string
  from?: string
  to?: string
  scope?: 'income' | 'expense'
  share?: boolean // user only
  transfer: 'transaction' | 'pl' // 必須
}

export interface GetGroupedTransactionsResponse {
  app: Transaction[]
}

export interface GetManuallyCreatedTransactionsCountProps {
  userAccountType: UserAccountType
}

export interface GetManuallyCreatedTransactionsCountResponse {
  app: {
    count: number
  }
}

export interface GetHomeExpensesSummaryProps {
  from: string
  to: string
  scope: 'expense'
  term?: 'yearly'
}

export interface GetHomeExpensesSummaryResponse {
  owner: {
    count: number
    rate: number
    amount: number
  }
  partner: {
    count: number
    rate: number
    amount: number
  }
  family: {
    count: number
    rate: number
    amount: number
  }
  ownerPartnerDiffAmount: number
  totalAmount: number
}

export type CheckTransactionsUpdatedResponse = {
  transaction: {
    updatedAt: string
    count: number
  }
}

export type GetMonthlyReportBsProps = {
  userAccountType: UserAccountType
  year: number
  month: number
  term?: number | 'all' // termに指定した月数分遡ります
  day?: number // 前日比表示用
}

export type MonthlyReportBsAccount = {
  id: number
  name: string
  balance: number
}

export type MonthlyReportBs = {
  year: number
  month: number
  date: string
  totalBalance: number
  bankBalance: number
  bankAccounts: MonthlyReportBsAccount[]
  emoneyBalance: number
  emoneyAccounts: MonthlyReportBsAccount[]
  stockBalance: number
  stockAccounts: MonthlyReportBsAccount[]
  walletBalance: number
  wallets: MonthlyReportBsAccount[]
}

export type GetMonthlyReportBsResponse = {
  bs: MonthlyReportBs[]
}

export type FinanceSort = {
  type: FinanceType
  sort: number
}

export type UpdateFinanceSortProps = {
  userAccountType: UserAccountType
  types: FinanceSort[]
}

export type FinanceAccountSort = {
  finance: FinanceType
  type: FinanceType
  accountId: number
  sort: number
}

export type UpdateFinanceAccountSortProps = {
  userAccountType: UserAccountType
  finances: FinanceAccountSort[]
}

export type GetFinanceSortResponse = {
  types: FinanceSort[]
}

class Financial {
  scrapingAccount = ({
    financialAccountType,
    id,
  }: ScrapingAccountProps): Promise<APIResponse> => {
    return API.request(
      'POST',
      `/api/v1/user/${financialAccountType}-accounts/${id}/scrp`,
      {},
      true,
    )
  }

  getAccounts = (props: GetAccountsProps): Promise<APIResponse> => {
    const { accountType, financialType } = props
    return API.request(
      'GET',
      `/api/v1/${accountType.userAccountPath()}/${financialType}-accounts`,
      { sort: true },
      true,
    )
  }

  updateAccount = (props: UpdateAccountProps): Promise<APIResponse> => {
    const { accountId, financialType, name, share } = props
    return API.request(
      'PUT',
      `/api/v1/user/${financialType}-accounts/${accountId}`,
      { name, share },
      true,
    )
  }

  deleteAccount = (props: DeleteAccountProps): Promise<APIResponse> => {
    const { accountId, financialType } = props
    return API.request(
      'DELETE',
      `/api/v1/user/${financialType}-accounts/${accountId}`,
      {},
      true,
    )
  }

  getCardThisMonthsPayment = (
    props: GetCardThisMonthsPaymentProps,
  ): Promise<APIResponse> => {
    const { accountType } = props
    return API.request(
      'GET',
      `/api/v1/${accountType.userAccountPath()}/card-accounts-summary`,
      {},
      true,
    )
  }

  getPlGroupedCategories = ({
    userAccountType,
    share,
    ...props
  }: GetPlGroupedCategoriesProps): Promise<APIResponse> => {
    const shareCondition = this.generateShareCondition(userAccountType, share)
    return API.request(
      'GET',
      '/api/v3/pl-grouped-categories',
      {
        ...props,
        ...shareCondition,
      },
      true,
    )
  }

  getPlCategories = ({
    userAccountType,
    share,
    ...props
  }: GetPlCategoriesProps): Promise<APIResponse> => {
    const shareCondition = this.generateShareCondition(userAccountType, share)
    return API.request(
      'GET',
      '/api/v3/pl-transaction-categories',
      {
        ...props,
        ...shareCondition,
      },
      true,
    )
  }

  private generateShareCondition = (
    userAccountType?: UserAccountType,
    share?: boolean,
  ) => {
    /*
    share_condition：共有状態を配列でOR条件で繋げる
                                  {account: true} : 支払担当：家族のみ
                                  {account: false} : 支払担当：個人のみ（自分）
                                  {transaction: true} : 表示する画面：家族画面のみ
                                  {transaction: false} : 表示する画面：個人画面のみ（自分）
                                  {account: true, transaction: false} : 支払担当：家族 表示する画面：個人画面（自分)
                                  {account: false, transaction: true} : 支払担当：個人（自分） 表示する画面：家族画面
    */
    const addShareCondition = share
      ? {
          'share_condition[1][account]': false,
          'share_condition[1][transaction]': true,
        }
      : {}

    return userAccountType === 'family'
      ? {
          'share_condition[0][transaction]': true, // 表示する画面：家族画面のみ
          // ...addShareCondition,
        }
      : userAccountType === 'user'
      ? {
          'share_condition[0][transaction]': false, // 表示する画面：個人画面のみ（自分）
          ...addShareCondition,
        }
      : undefined
  }

  getTransactions = (props: GetTransactionsProps): Promise<APIResponse> => {
    const params = { ...props }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { accountType, share, useCache, ...newParams } = params

    const shareCondition = this.generateShareCondition(accountType, share)

    return API.request(
      'GET',
      `/api/v3/transactions`,
      {
        ...newParams,
        ...shareCondition,
      },
      true,
    )
  }

  getTransactionDetail = (
    props: GetTransactionDetailProps,
  ): Promise<APIResponse> => {
    const { financialAccountType, accountId, transactionId, transfer } = props
    if (['bank', 'card', 'emoney'].includes(financialAccountType)) {
      return API.request(
        'GET',
        `/api/v3/${financialAccountType}-accounts/${accountId}/transactions/${transactionId}`,
        { transfer },
        true,
      )
    } else if (financialAccountType === 'wallet') {
      return API.request(
        'GET',
        `/api/v3/wallets/${accountId}/transactions/${transactionId}`,
        { transfer },
        true,
      )
    } else {
      return API.request(
        'GET',
        `/api/v3/user-manually-created-transactions/${transactionId}`,
        { transfer },
        true,
      )
    }
  }

  createTransactionDetail = (
    props: CreateTransactionDetailProps,
  ): Promise<APIResponse> => {
    const {
      atTransactionCategoryId,
      usedLocation,
      transactionShare: share,
      usedDate,
      amount,
      ignore,
      memo,
      paymentMethodType,
      paymentMethodId,
      type,
      // userManuallyCreatedTransactionShare, // paymentUserTypeにかわった
      paymentUserType,
      yetPayoff,
      images,
      copy,
    } = props
    const path = paymentUserType === PaymentUserType.Family ? 'group' : 'user'

    return API.request(
      'POST',
      `/api/v1/${path}/user-manually-created-transactions`,
      {
        share,
        usedDate,
        amount,
        usedLocation,
        atTransactionCategoryId,
        ignore,
        memo,
        paymentMethodType,
        paymentMethodId,
        type,
        paymentUserType,
        yetPayoff,
        images,
        copy,
      },
      true,
    )
  }

  updateTransactionDetail = (
    props: UpdateTransactionDetailProps,
  ): Promise<APIResponse> => {
    const {
      userAccountType,
      financialAccountType,
      accountId,
      transactionId,
      atTransactionCategoryId,
      usedLocation,
      transactionShare: share,
      usedDate,
      amount,
      ignore,
      memo,
      paymentMethodType,
      paymentMethodId,
      type,
      // userManuallyCreatedTransactionShare, // paymentUserTypeにかわった
      paymentUserType,
      yetPayoff,
      images,
    } = props
    // const path = (() => {
    //   if (userAccountType) return userAccountType
    //   return paymentUserType === PaymentUserType.Family ? 'family' : 'user'
    // })()

    if (['bank', 'card', 'emoney'].includes(financialAccountType ?? '')) {
      return API.request(
        'PUT',
        `/api/v1/${userAccountType?.userAccountPath()}/${financialAccountType}-accounts/${accountId}/transactions/${transactionId}`,
        {
          share,
          usedLocation,
          atTransactionCategoryId,
          ignore,
          memo,
          paymentUserType,
          yetPayoff,
          images,
        },
        true,
      )
    } else {
      return API.request(
        'PUT',
        `/api/v1/${userAccountType?.userAccountPath()}/user-manually-created-transactions/${transactionId}`,
        {
          share,
          usedDate,
          amount,
          usedLocation,
          atTransactionCategoryId,
          ignore,
          memo,
          paymentMethodType,
          paymentMethodId,
          type,
          paymentUserType,
          yetPayoff,
          images,
        },
        true,
      )
    }
  }

  deleteTransaction = (props: DeleteTransactionProps): Promise<APIResponse> => {
    const { userAccountType, transactionId } = props

    return API.request(
      'DELETE',
      `/api/v1/${userAccountType.userAccountPath()}/user-manually-created-transactions/${transactionId}`,
      {},
      true,
    )
  }

  getManuallyCreatedTransactionsCount = (
    props: GetManuallyCreatedTransactionsCountProps,
  ): Promise<APIResponse> => {
    return API.request(
      'GET',
      `/api/v1/${props.userAccountType.userAccountPath()}/user-manually-created-transactions/`,
      {},
      true,
    )
  }

  getAccountTransactions = (
    props: GetAccountTransactionsProps,
  ): Promise<APIResponse> => {
    const { financialType, accountId, from, to, amount } = props

    return API.request(
      'GET',
      `/api/v3/${financialType}-accounts/${accountId}/transactions`,
      { from, to, amount },
      true,
    )
  }

  getHomeExpensesSummary = (props: GetHomeExpensesSummaryProps) => {
    const { from, to, scope, term } = props

    return API.request(
      'GET',
      `/api/v2/group/summary-transactions`,
      { from, to, scope, term },
      true,
    )
  }

  checkTransactionsUpdated = () => {
    return API.request('GET', `/api/v2/transactions/latest`, {}, true)
  }

  getMonthlyReportBs = ({
    userAccountType,
    ...props
  }: GetMonthlyReportBsProps) => {
    return API.request(
      'GET',
      `/api/v3/${userAccountType.userAccountPath()}/report/bs`,
      { ...props, sort: true },
      true,
    )
  }

  updateFinanceSort = ({
    userAccountType,
    ...props
  }: UpdateFinanceSortProps) => {
    return API.request(
      'PUT',
      `/api/v3/${userAccountType.userAccountPath()}/finance-type/sort`,
      props,
      true,
    )
  }

  getFinanceSort = (userAccountType: UserAccountType) => {
    return API.request(
      'GET',
      `/api/v3/${userAccountType.userAccountPath()}/finance-type/sort`,
      {},
      true,
    )
  }

  updateFinanceAccountSort = ({
    userAccountType,
    ...props
  }: UpdateFinanceAccountSortProps) => {
    return API.request(
      'PUT',
      `/api/v3/${userAccountType.userAccountPath()}/finance/sort`,
      props,
      true,
    )
  }

  confirmCopyTransaction = () => {
    return API.request(
      'GET',
      `/api/v3/user-manually-creted-transactions/confirm-copy-transaction`,
      {},
      true,
    )
  }
}

export default new Financial()
