import ActionSheet from '@components/ActionSheet'
import Avatar from '@components/Avatar'
import CommonHeader from '@components/CommonHeader'
import CrossPlatformDatePicker from '@components/DatePicker'
import { GoalManager } from '@lib/api/Goal'
import {
  getPrevMonth,
  getYearMonth,
  StartDateSettingOption,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import { NativeApp } from '@lib/Env'
import NavigationService from '@lib/NavigationService'
import ProgressHUD from '@lib/ProgressHUD'
import TrackingUtils from '@lib/TrackingUtils'
import { Target } from '@nandorojo/anchor'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
} from 'react-native'
import MonthPicker, { ACTION_DATE_SET } from 'react-native-month-year-picker'
import { useDispatch, useSelector } from 'react-redux'
import AssetsPanel from './AssetsPanel'
import BalancePanel from './BalancePanel'
import CommnentPanel from './CommentPanel'
import { FamilyIllustration, PersonalIllustration } from './constant'
import GoalsPanel from './GoalsPanel'
import MenuPanel from './MenuPanel'
import PayoffPanel from './PayoffPanel'
import SharePanel from './SharePanel'
import {
  selectIsProcessing,
  selectUserAccountType,
  selectYearMonth,
  setUserAccountType,
  setYearMonth,
} from './slice'
import {
  StyledAvatarView,
  StyledBgImage,
  StyledBgImageView,
  StyledColorBgView,
  StyledDateButton,
  StyledFooterRowView,
  StyledFooterSymbolText,
  StyledFooterText,
  StyledFooterView,
  StyledMainContainer,
  StyledOverviewText,
  StyledScrollTopTopButton,
  StyledScrollTopTopImage,
  StyledScrollView,
  StyledTitleText,
  StyledUserAccountButton,
} from './styles'

const MonthlyReportScreen: React.FC<
  StackScreenProps<RootStackParamList, 'MonthlyReport'>
> = ({ route }) => {
  const dispatch = useDispatch()

  const userAccountType = useSelector(selectUserAccountType)
  const yearMonth = useSelector(selectYearMonth)
  const isProcessing = useSelector(selectIsProcessing)

  const [isDisplayDatePicker, setIsDisplayDatePicker] = useState(false)
  const [isVisibleScrollToTopButton, setIsVisibleScrollToTopButton] =
    useState(false)
  const [maxDate, setMaxDate] = useState<Date>()

  useEffect(() => {
    if (userAccountType === 'user') {
      TrackingUtils.repro.track('【Screen】personal_monthly report', 'Screen')
    } else {
      TrackingUtils.repro.track('【Screen】family_monthly report', 'Screen')
    }
  }, [userAccountType])

  useEffect(() => {
    const settings = getStartDateSettings(userAccountType)
    const yearMonth = getPrevMonth(
      getYearMonth(
        moment(),
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      ).key,
    )
    setMaxDate(new Date(yearMonth.year, yearMonth.month - 1, 1))
  }, [userAccountType])

  useEffect(() => {
    if (!yearMonth) {
      const settings = getStartDateSettings(userAccountType)
      const yearMonth = getPrevMonth(
        getYearMonth(
          moment(),
          settings.start,
          settings.startDateSettingOption || StartDateSettingOption.None,
        ).key,
      )
      dispatch(setYearMonth(yearMonth.key))
    }
  }, [dispatch, userAccountType, yearMonth])

  useEffect(() => {
    isProcessing ? ProgressHUD.show() : ProgressHUD.dismiss()
  }, [isProcessing])

  useEffect(() => {
    GoalManager.fetchGoals({ share: userAccountType === 'family' })
  }, [userAccountType])

  const onPressDate = useCallback(() => {
    setIsDisplayDatePicker(!isDisplayDatePicker)
  }, [isDisplayDatePicker])

  const onChangeDate = useCallback(
    (event: string, date: Date) => {
      setIsDisplayDatePicker(false)

      if (event === ACTION_DATE_SET) {
        dispatch(setYearMonth(moment(date).format('YYYYMM')))
      }
    },
    [dispatch],
  )

  const onPressUserAccountType = useCallback(() => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: ['家族', '個人', 'キャンセル'],
        cancelButtonIndex: 2,
      },
      async (index: number) => {
        if (index === 0) {
          dispatch(setUserAccountType('family'))
        } else if (index === 1) {
          dispatch(setUserAccountType('user'))
        }
      },
    )
  }, [dispatch])

  const scrollViewRef = useRef<ScrollView>(null)

  const onPressScrollToTop = useCallback(() => {
    scrollViewRef.current?.scrollTo({ y: 0, animated: true })
  }, [scrollViewRef])

  const timer = useRef<NodeJS.Timeout>()

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const newValue =
        event.nativeEvent.contentOffset.y /
          event.nativeEvent.contentSize.height >=
        630 / 3838
      if (newValue !== isVisibleScrollToTopButton) {
        setIsVisibleScrollToTopButton(newValue)

        if (timer.current) clearTimeout(timer.current)
        if (newValue) {
          timer.current = setTimeout(
            () => setIsVisibleScrollToTopButton(false),
            3000,
          )
        }
      }
    },
    [isVisibleScrollToTopButton],
  )

  const goBack = () => {
    if (route.params?.goBack) {
      route.params?.goBack()
    } else {
      NavigationService.goBack()
    }
  }

  return (
    <>
      <CommonHeader title="マンスリーレポート" onPressLeftButton={goBack} />
      <StyledScrollView
        ref={scrollViewRef}
        onScroll={(e) => onScroll(e)}
        scrollEventThrottle={16}
        contentContainerStyle={{ paddingBottom: 60 }}>
        <StyledColorBgView userAccountType={userAccountType} />
        <StyledBgImageView
          userAccountType={userAccountType}
          pointerEvents="none">
          <StyledBgImage
            userAccountType={userAccountType}
            source={
              userAccountType === 'family'
                ? FamilyIllustration
                : PersonalIllustration
            }
          />
        </StyledBgImageView>
        <StyledMainContainer>
          <StyledTitleText>Monthly Report</StyledTitleText>
          <StyledDateButton
            date={moment(yearMonth, 'YYYYMM').toDate()}
            onPress={onPressDate}
          />
          <StyledAvatarView>
            <Avatar
              type={userAccountType === 'family' ? 'couple' : 'user'}
              size={wp(10)}
            />
            <StyledUserAccountButton
              userAccountType={userAccountType}
              onPress={onPressUserAccountType}
            />
          </StyledAvatarView>
          <StyledOverviewText>
            今月も1ヶ月お疲れさまでした。{'\n'}
            マンスリーレポートを2人で振り返り、{'\n'}
            次月に活かしましょう！
          </StyledOverviewText>
          <MenuPanel userAccountType={userAccountType} />
          <Target name="assets" />
          <AssetsPanel />
          <Target name="balance" />
          <BalancePanel />
          {userAccountType === 'family' && true && (
            <>
              <Target name="share" />
              <SharePanel />
              <Target name="payoff" />
              <PayoffPanel />
            </>
          )}
          <Target name="goals" />
          <GoalsPanel userAccountType={userAccountType} />
          <Target name="comment" />
          <CommnentPanel />
          <StyledFooterView>
            <StyledFooterRowView>
              <StyledFooterSymbolText>※</StyledFooterSymbolText>
              <StyledFooterText>
                家計簿の設定の「集計期間設定」の期間で集計しています。
              </StyledFooterText>
            </StyledFooterRowView>
            <StyledFooterRowView>
              <StyledFooterSymbolText>※</StyledFooterSymbolText>
              <StyledFooterText>
                集計期間が終わった期間のレポートを表示できます。
              </StyledFooterText>
            </StyledFooterRowView>
            <StyledFooterRowView>
              <StyledFooterSymbolText>※</StyledFooterSymbolText>
              <StyledFooterText>
                レポート月名は集計期間の開始日を含む月を表示しています。集計期間が2021/9/15-10/14の場合は2021/09と表示されています。
              </StyledFooterText>
            </StyledFooterRowView>
          </StyledFooterView>
        </StyledMainContainer>
      </StyledScrollView>
      {isVisibleScrollToTopButton && (
        <StyledScrollTopTopButton onPress={onPressScrollToTop}>
          <StyledScrollTopTopImage
            source={require('@images/buttons/scroll_to_top_button.png')}
          />
        </StyledScrollTopTopButton>
      )}
      {isDisplayDatePicker &&
        (NativeApp ? (
          <MonthPicker
            onChange={onChangeDate}
            value={moment(yearMonth, 'YYYYMM').toDate()}
            minimumDate={new Date(2019, 1)}
            maximumDate={maxDate}
            locale="ja"
            okButton="確定"
            cancelButton="キャンセル"
          />
        ) : (
          <CrossPlatformDatePicker
            isVisible={true}
            type="year-month"
            date={moment(yearMonth, 'YYYYMM').toDate()}
            onConfirm={(date: Date) => {
              dispatch(setYearMonth(moment(date).format('YYYYMM')))
              setIsDisplayDatePicker(false)
            }}
            minimumDate={new Date(2019, 1)}
            maximumDate={maxDate}
            onCancel={() => setIsDisplayDatePicker(false)}
            headerFormat={''}
            confirmText="確定"
            cancelText="キャンセル"
          />
        ))}
    </>
  )
}

export default MonthlyReportScreen
