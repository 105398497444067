import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import Color from '@lib/Color'
import { setEndTime, setStartTime } from '@redux/slice'
import store, { RootState } from '@redux/store'
import moment from 'moment'
import React, { useCallback } from 'react'
import {
  ListRenderItem,
  SectionList,
  SectionListData,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'
import ListItem from './ListItem'
import { ReportKey } from './types'

export async function measure<T>(key: ReportKey, func: () => Promise<T>) {
  store.dispatch(setStartTime({ key, time: new Date().getTime() }))
  const value = await func()
  store.dispatch(setEndTime({ key, time: new Date().getTime() }))
  return value
}

type ListItem = {
  title: string
  value: string
}

const SystemReportScreen: React.FC = () => {
  const systemReport = useSelector(
    ({ systemReport }: RootState) => systemReport,
  )

  const getTimeText = useCallback(
    (key: ReportKey) => {
      const time = systemReport[key]
      if (!time) return '計測なし'
      if (!time.startTime) return '計測なし'

      const { startTime, endTime } = time

      const start = moment(startTime).format('H:mm:ss.SSS')
      const end = endTime ? moment(endTime).format('H:mm:ss.SSS') : '計測中...'
      const diff = endTime ? `${(endTime - startTime) / 1000}秒` : ''

      return `${diff} (${start}〜${end})`
    },
    [systemReport],
  )

  const renderItem: ListRenderItem<ListItem> = ({ item }) => {
    return (
      <ListItem displayType={'2column'} title={item.title} value={item.value} />
    )
  }

  const sessions: SectionListData<ListItem>[] = (() => {
    return [
      {
        title: 'スプラッシュ画面',
        data: [
          {
            title: '表示時間',
            value: getTimeText('SplashScreen'),
          },
        ],
      },
      {
        title: 'ログイン済み',
        data: [
          {
            title: '「わたし」のカテゴリー一覧の取得',
            value: getTimeText('GetUserCategories'),
          },
          {
            title: '「パートナー」のカテゴリ一覧の取得',
            value: getTimeText('GetFamilyCategories'),
          },
        ],
      },
      {
        title: 'ホーム画面表示前タスク',
        data: [
          // {
          //   title: '合計',
          //   value: getTimeText('FetchHomeData'),
          // },
          {
            title: '集計期間設定の取得',
            value: getTimeText('FetchPlSettings'),
          },
          {
            title: '銀行口座一覧の取得',
            value: getTimeText('GetBankAccounts'),
          },
          {
            title: '電子マネー一覧の取得',
            value: getTimeText('GetEmoneyAccounts'),
          },
          {
            title: '証券/投資信託口座一覧の取得',
            value: getTimeText('GetStockAccounts'),
          },
          {
            title: 'カード一覧の取得',
            value: getTimeText('FetchCardAccounts'),
          },
          {
            title: '今月の家計簿データの取得（大カテゴリー毎のサマリー）',
            value: getTimeText('GetGroupedCategories'),
          },
          {
            title: '今月の家計簿データの取得（小カテゴリー毎のサマリー）',
            value: getTimeText('GetChildeGroupedCategories'),
          },
          {
            title: '支出の分担データの取得',
            value: getTimeText('FetchHomeExpensesSummary'),
          },
          {
            title: '手動明細の登録数の取得',
            value: getTimeText('FetchManuallyCreatedTransactionsCount'),
          },
          {
            title: '目標データの取得',
            value: getTimeText('FetchGoals'),
          },
          {
            title: '「わたし」のプロフィール情報取得',
            value: getTimeText('FetchUserProflie'),
          },
          {
            title: '「パートナー」のプロフィール情報取得',
            value: getTimeText('FetchPartnerProflie'),
          },
          {
            title: 'ユーザーの詳細データの取得',
            value: getTimeText('FetchUserStatus'),
          },
          {
            title: '予算設定データの取得',
            value: getTimeText('FetchBudget'),
          },
          {
            title: '目標貯金カテゴリーの取得',
            value: getTimeText('FetchGoalCategories'),
          },
        ],
      },
      {
        title: 'AccountTracker同期',
        data: [
          {
            title: 'AccountTracker同期',
            value: getTimeText('AtSync'),
          },
        ],
      },
    ]
  })()

  return (
    <View style={{ flex: 1, backgroundColor: Color.LightGray }}>
      <SectionList
        style={{ flex: 1, marginBottom: 30 }}
        keyExtractor={(item) => item.title}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title } }) => (
          <SectionListHeader title={title} />
        )}
        sections={sessions}
        ItemSeparatorComponent={() => ItemSeparator}
      />
    </View>
  )
}

export default SystemReportScreen
