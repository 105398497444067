import AppButton from '@components/AppButton'
import BlurView from '@components/BlurView'
import FullWidthImage from '@components/FullWidthImage'
import Color from '@lib/Color'
import { NativeApp } from '@lib/Env'
import {
  ImageRequireSource,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import ReactNativeHapticFeedback from 'react-native-haptic-feedback'
import styled from 'styled-components/native'

export const StyledAbsoluteView = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
`

export const StyledPlanErrorView = styled(StyledAbsoluteView)<{
  monthlyReport?: boolean
  top?: number
}>`
  background-color: #000000a0;
  position: absolute;
  top: ${({ top }) => top || 0};
  right: 0;
  bottom: 0;
  left: 0;
`

export const PremiumContainerView = styled.View`
  /* padding-bottom: 20px; */
  padding: 0 ${wp(5)}px;
  /* padding-horizontal: 30px; */
`

export const PremiumTitleText = styled.Text<{ monthlyReport?: boolean }>`
  text-align: center;
  font-size: ${({ monthlyReport }) =>
    monthlyReport ? Math.min(18, wp(4.3)) : 18}px;
  font-weight: bold;
  color: white;
`

export const PremiumText = styled.Text<{ monthlyReport?: boolean }>`
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: ${({ monthlyReport }) =>
    monthlyReport ? Math.min(14, wp(3)) : 13.5}px;
  font-weight: bold;
  color: white;
  line-height: 18px;
`

export const PremiumView2 = (props: {
  onPressShowDetailButton: () => void
  image: ImageRequireSource
}) => {
  return (
    <StyledPlanErrorView pointerEvents="box-none">
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
        <PremiumContainerView pointerEvents="box-none">
          <FullWidthImage source={props.image} />
          <AppButton
            title="詳しく見る"
            textStyle={{ fontSize: 14, fontWeight: 'bold' }}
            style={{
              backgroundColor: Color.Primary,
              width: NativeApp ? '90%' : '80%',
              height: 48,
              position: 'absolute',
              bottom: wp(5),
              alignSelf: 'center',
            }}
            onPress={props.onPressShowDetailButton}
          />
        </PremiumContainerView>
      </ScrollView>
    </StyledPlanErrorView>
  )
}

export const PremiumView = (props: { onPressShowDetailButton: () => void }) => {
  return (
    <BlurView style={styles.absolute} disableBlur={Platform.OS === 'android'}>
      <View style={[styles.absolute, { bottom: 100 }]}>
        <Text style={styles.premiumPlanMessage}>
          プレミアムプランに加入すると{'\n'}
          以下をご利用いただけます。{'\n'}
        </Text>
        <Text
          style={[
            styles.premiumPlanMessage,
            {
              alignSelf: 'center',
              textAlign: 'left',
              marginBottom: 40,
            },
          ]}>
          ・カテゴリーをいくつでも追加作成{'\n'}
          ・カテゴリーの非表示{'\n'}
          ・カテゴリーの並び替え{'\n'}
          {'\n'}
          ※無料プランでは1アカウントにつき{'\n'}
          {'　'}大/小カテゴリーを各1つ作成できます
        </Text>
        <AppButton title="詳しく見る" onPress={props.onPressShowDetailButton} />
      </View>
    </BlurView>
  )
}

export const vibrate = () => {
  ReactNativeHapticFeedback.trigger('impactHeavy', {
    enableVibrateFallback: true,
    ignoreAndroidSystemSettings: true,
  })
}

const styles = StyleSheet.create({
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
  },
})
