import React from 'react'
import {
  Image,
  ImageProps,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  StyleSheet,
} from 'react-native'

export type EyeImageProps = Omit<ImageProps, 'source' | 'style'> & {
  on?: boolean
  source?: ImageSourcePropType
  style?: StyleProp<Omit<ImageStyle, 'tintColor'> & { tintColor: string }>
}

const EyeImage: React.FC<EyeImageProps> = ({ on, source, style, ...props }) => {
  const { tintColor, ...eyeImageStyle } = StyleSheet.flatten([
    { tintColor: undefined },
    style,
  ])

  return on ? (
    <Image
      key="eye-on"
      source={source ?? require('@images/icons/icon-password-eye-on.png')}
      style={[{ width: 25, height: 25, tintColor }, eyeImageStyle]}
      {...props}
    />
  ) : (
    <Image
      key="eye-off"
      source={source ?? require('@images/icons/icon-password-eye-off.png')}
      style={[{ width: 25, height: 25, tintColor }, eyeImageStyle]}
      {...props}
    />
  )
}

export default EyeImage
