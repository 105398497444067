import { NativeApp } from '@lib/Env'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'
import React from 'react'
import MainTabsStackScreens from '../MainTabsStackScreens'

const Stack = createStackNavigator()

const StackScreen: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
      }}>
      {MainTabsStackScreens(Stack, 'TransactionsTab')}
    </Stack.Navigator>
  )
}

export default StackScreen
