import { Image, ImageProps } from 'react-native'

export const IconImages = {
  ArrowDown: require('@assets/images/arrow/icon-arrow-down-gray.png'),
  ArrowRight: require('@images/arrow/icon-arrow-white-small.png'),
  CategoryAll: require('@assets/images/category/icon-category-all.png'),
} as const

const DefaultTintColors = {
  ArrowRight: '#888888',
  ArrowDown: '#888888',
  CategoryAll: undefined,
} as const

const Sizes = {
  ArrowRight: { width: 12, height: 12 },
  ArrowDown: { width: 12, height: 12 },
  CategoryAll: { width: 24, height: 24 },
} as const

export type IconImageNames = keyof typeof IconImages

export type IconImageProps = {
  name: IconImageNames
} & Omit<ImageProps, 'source'>

const IconImage = ({ name, style, ...params }: IconImageProps) => (
  <Image
    source={IconImages[name]}
    {...params}
    style={[
      {
        width: Sizes[name].width,
        height: Sizes[name].height,
        tintColor: DefaultTintColors[name],
      },
      style,
    ]}
  />
)

export default IconImage
