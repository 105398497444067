import { APIError } from '../api'
import { GetMainPermissionResponse, UpdateMailPermissionProps } from './types'
import * as MailPermissionsAPI from './api'

export const updateMailPermission = async (
  props: UpdateMailPermissionProps
) => {
  const response = await MailPermissionsAPI.updateMailPermission(props)
  if (!response.ok) throw new APIError(response)
}

export const getMailPermissions = async () => {
  const response = await MailPermissionsAPI.getMailPermissions()
  if (!response.ok) throw new APIError(response)
  return (response.json as GetMainPermissionResponse).mailPermissions
}
