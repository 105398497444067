import { Image } from '@components'
import { UserAccountType } from '@interfaces/Account'
import Color from '@lib/Color'
import { useScrollTo } from '@nandorojo/anchor'
import { useCallback } from 'react'
import {
  ImageBackground,
  ImageSourcePropType,
  TouchableOpacityProps,
} from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import {
  selectAssets,
  selectBalances,
  selectMonthlyGoals,
  selectPayoffAmounts,
  selectSummaryTransactions,
} from './slice'
import { StyledCommonPanel } from './styles'

const StyledTouchableOpacity = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: ${100 / 3}%;
`

const StyleMenuLine = styled.View<{ userAccountType: UserAccountType }>(
  ({ userAccountType }) => ({
    marginTop: wp(5),
    paddingHorizontal: userAccountType === 'user' ? wp(8) : undefined,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  }),
)

// const StyledImageBackground = styled(ImageBackground)`
//   width: ${wp(15)}px;
//   height: ${wp(15)}px;
//   resize-mode: contain;
//   align-items: center;
//   justify-content: center;
// `

const StyledImage = styled(Image)`
  width: ${wp(10)}px;
  height: ${wp(10)}px;
  resize-mode: contain;
`

const StyledPanel = styled(StyledCommonPanel)`
  padding-top: ${wp(3)}px;
  padding-bottom: ${wp(7)}px;
  padding-horizontal: ${wp(3)}px;
`

const StyledHeaderText = styled.Text`
  margin-left: ${wp(2)}px;
  font-size: ${wp(2.8)}px;
  font-weight: normal;
  color: rgb(58, 58, 58);
`

const StyledMenuTextView = styled.View`
  padding-top: ${wp(2)}px;
  align-items: center;
`

const StyledMenuTitleText = styled.Text`
  font-size: ${wp(3)}px;
  font-weight: bold;
  color: rgb(153, 153, 153);
  /* height: ${wp(3)}px; */
`

const StyledMenuAmountText = styled.Text`
  margin-top: ${wp(1.5)}px;
  font-size: ${wp(4)}px;
  font-weight: bold;
  color: rgb(58, 58, 58);
`

const StyledMenuSmallText = styled.Text`
  font-size: ${wp(2.8)}px;
  font-weight: normal;
  color: rgb(58, 58, 58);
`

const StyledAmountText = styled.Text<{ color: string }>`
  margin-left: ${wp(1)}px;
  font-size: ${wp(2.8)}px;
  font-weight: bold;
  color: ${({ color }) => color};
`

const StyledMenuRatioView = styled.View`
  margin-top: ${wp(1)}px;
  flex-direction: row;
  align-items: center;
`

const StyledCommentText = styled.Text`
  margin-top: ${wp(1.5)}px;
  font-size: ${wp(2.8)}px;
  font-weight: normal;
  color: rgb(58, 58, 58);
  text-align: center;
`

const MenuButton = (props: { source: string | ImageSourcePropType }) => (
  <ImageBackground
    source={require('@images/report/circle-button-bg.png')}
    resizeMode="contain"
    style={{
      width: wp(15),
      height: wp(15),
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <StyledImage {...props} />
  </ImageBackground>
)

export type MenuPanelProps = {
  userAccountType: UserAccountType
} & TouchableOpacityProps

const MenuPanel = ({ userAccountType, ...props }: MenuPanelProps) => {
  const { scrollTo } = useScrollTo()

  const onPressAssets = useCallback(() => scrollTo('assets'), [scrollTo])
  const onPressBalance = useCallback(() => scrollTo('balance'), [scrollTo])
  const onPressShare = useCallback(() => scrollTo('share'), [scrollTo])
  const onPressPayoff = useCallback(() => scrollTo('payoff'), [scrollTo])
  const onPressGoals = useCallback(() => scrollTo('goals'), [scrollTo])
  const onPressComment = useCallback(() => scrollTo('comment'), [scrollTo])

  // 資産総額
  const assets = useSelector(selectAssets)
  const prevMonthOver =
    assets.length >= 2
      ? assets[0].totalBalance - assets[1].totalBalance
      : undefined
  const prevMonthOverText =
    prevMonthOver !== undefined ? prevMonthOver.jpCurrency() : '-'

  // 今月の収支
  const balances = useSelector(selectBalances)
  const thisMonthTotalAmount = balances[0]
    ? balances[0].expenseAmount + balances[0].incomeAmount
    : undefined
  const prevMonthTotalAmount = balances[1]
    ? balances[1].expenseAmount + balances[1].incomeAmount
    : undefined
  const balanceText =
    thisMonthTotalAmount !== undefined ? thisMonthTotalAmount.jpCurrency() : '-'
  const balancePrevMonthOver =
    thisMonthTotalAmount !== undefined && prevMonthTotalAmount !== undefined
      ? thisMonthTotalAmount - prevMonthTotalAmount
      : undefined
  const balancePrevMonthOverText =
    balancePrevMonthOver !== undefined
      ? balancePrevMonthOver.jpCurrency({
          showPlus: true,
          showPlusMinus: true,
        })
      : '-'

  // 支出の分担
  const shareAmounts = useSelector(selectSummaryTransactions)
  const shareAmonntText =
    shareAmounts.length > 0 ? shareAmounts[0].totalAmount.jpCurrency() : '-'
  const sharePrevMonthOver =
    shareAmounts.length >= 2
      ? shareAmounts[0].totalAmount - shareAmounts[1].totalAmount
      : undefined
  const sharePrevMonthOverText =
    sharePrevMonthOver !== undefined
      ? sharePrevMonthOver.jpCurrency({
          showPlus: true,
          showPlusMinus: true,
        })
      : '-'

  // 割り勘 | 精算
  const payoffAmounts = useSelector(selectPayoffAmounts)
  const payoffAmonntText =
    payoffAmounts.length > 0 ? payoffAmounts[0].jpCurrency() : '-'
  const payoffPrevMonthOver =
    payoffAmounts.length >= 2 ? payoffAmounts[0] - payoffAmounts[1] : undefined
  const payoffPrevMonthOverText =
    payoffPrevMonthOver !== undefined
      ? payoffPrevMonthOver.jpCurrency({
          showPlus: true,
          showPlusMinus: true,
        })
      : '-'

  // 目標貯金
  const goals = useSelector(selectMonthlyGoals)
  const goalsPrevMonthOver =
    goals.length >= 2
      ? goals[0].totalCurrentAmount - goals[1].totalCurrentAmount
      : undefined
  const goalsPrevMonthOverText =
    goalsPrevMonthOver !== undefined
      ? goalsPrevMonthOver.jpCurrency({
          showPlus: true,
          showPlusMinus: true,
        })
      : '-'

  return (
    <StyledPanel>
      <StyledHeaderText>タップすると詳細画面に遷移します。</StyledHeaderText>
      <StyleMenuLine userAccountType={userAccountType}>
        {/* 資産総額 */}
        <StyledTouchableOpacity onPress={onPressAssets}>
          <MenuButton source={require('@images/report/assets-button.png')} />
          <StyledMenuTextView>
            <StyledMenuTitleText>資産総額</StyledMenuTitleText>
            <StyledMenuAmountText>
              {assets[0] ? assets[0].totalBalance.jpCurrency() : '-'}
            </StyledMenuAmountText>
            <StyledMenuRatioView>
              <StyledMenuSmallText>前月比</StyledMenuSmallText>
              <StyledAmountText color={Color.number(prevMonthOver)}>
                {prevMonthOverText}
              </StyledAmountText>
            </StyledMenuRatioView>
          </StyledMenuTextView>
        </StyledTouchableOpacity>
        {/* 今月の収支 */}
        <StyledTouchableOpacity onPress={onPressBalance}>
          <MenuButton source={require('@images/report/piechart-button.png')} />
          <StyledMenuTextView>
            <StyledMenuTitleText>今月の収支</StyledMenuTitleText>
            <StyledMenuAmountText>{balanceText}</StyledMenuAmountText>
            <StyledMenuRatioView>
              <StyledMenuSmallText>前月比</StyledMenuSmallText>
              <StyledAmountText color={Color.number(balancePrevMonthOver)}>
                {balancePrevMonthOverText}
              </StyledAmountText>
            </StyledMenuRatioView>
          </StyledMenuTextView>
        </StyledTouchableOpacity>
        {/* 支出の分担 || 2人のコメント */}
        {userAccountType === 'family' ? (
          <StyledTouchableOpacity onPress={onPressShare}>
            <MenuButton source={require('@images/report/arrow-button.png')} />
            <StyledMenuTextView>
              <StyledMenuTitleText>支出の分担</StyledMenuTitleText>
              <StyledMenuAmountText>{shareAmonntText}</StyledMenuAmountText>
              <StyledMenuRatioView>
                <StyledMenuSmallText>前月比</StyledMenuSmallText>
                <StyledAmountText color={Color.number(sharePrevMonthOver)}>
                  {sharePrevMonthOverText}
                </StyledAmountText>
              </StyledMenuRatioView>
            </StyledMenuTextView>
          </StyledTouchableOpacity>
        ) : null}
      </StyleMenuLine>
      {userAccountType === 'family' ? (
        <StyleMenuLine userAccountType={userAccountType}>
          {/* 割り勘 | 精算 */}
          <StyledTouchableOpacity onPress={onPressPayoff}>
            <MenuButton source={require('@images/report/payoff-button.png')} />
            <StyledMenuTextView>
              <StyledMenuTitleText>割り勘 | 精算</StyledMenuTitleText>
              <StyledMenuAmountText>{payoffAmonntText}</StyledMenuAmountText>
              <StyledMenuRatioView>
                <StyledMenuSmallText>前月比</StyledMenuSmallText>
                <StyledAmountText color={Color.number(payoffPrevMonthOver)}>
                  {payoffPrevMonthOverText}
                </StyledAmountText>
              </StyledMenuRatioView>
            </StyledMenuTextView>
          </StyledTouchableOpacity>
          {/* 目標貯金 */}
          <StyledTouchableOpacity onPress={onPressGoals}>
            <MenuButton source={require('@images/report/goals-button.png')} />
            <StyledMenuTextView>
              <StyledMenuTitleText>目標貯金</StyledMenuTitleText>
              <StyledMenuAmountText>
                {goals[0] ? goals[0].totalCurrentAmount.jpCurrency() : '-'}
              </StyledMenuAmountText>
              <StyledMenuRatioView>
                <StyledMenuSmallText>前月比</StyledMenuSmallText>
                <StyledAmountText color={Color.number(goalsPrevMonthOver)}>
                  {goalsPrevMonthOverText}
                </StyledAmountText>
              </StyledMenuRatioView>
            </StyledMenuTextView>
          </StyledTouchableOpacity>
          {/* 2人のコメント */}
          <StyledTouchableOpacity onPress={onPressComment}>
            <MenuButton source={require('@images/report/comment-button.png')} />
            <StyledMenuTextView>
              <StyledMenuTitleText>2人のコメント</StyledMenuTitleText>
              <StyledMenuRatioView>
                <StyledCommentText>
                  今月の振り返りを{'\n'}書きましょう
                </StyledCommentText>
              </StyledMenuRatioView>
            </StyledMenuTextView>
          </StyledTouchableOpacity>
        </StyleMenuLine>
      ) : (
        <StyleMenuLine userAccountType={userAccountType}>
          {/* 目標貯金 */}
          <StyledTouchableOpacity onPress={onPressGoals}>
            <MenuButton source={require('@images/report/goals-button.png')} />
            <StyledMenuTextView>
              <StyledMenuTitleText>目標貯金</StyledMenuTitleText>
              <StyledMenuAmountText>
                {goals[0] ? goals[0].totalCurrentAmount.jpCurrency() : '-'}
              </StyledMenuAmountText>
              <StyledMenuRatioView>
                <StyledMenuSmallText>前月比</StyledMenuSmallText>
                <StyledAmountText color={Color.number(goalsPrevMonthOver)}>
                  {goalsPrevMonthOverText}
                </StyledAmountText>
              </StyledMenuRatioView>
            </StyledMenuTextView>
          </StyledTouchableOpacity>
          <StyledTouchableOpacity onPress={onPressComment}>
            <MenuButton source={require('@images/report/comment-button.png')} />
            <StyledMenuTextView>
              <StyledMenuTitleText>2人のコメント</StyledMenuTitleText>
              <StyledMenuRatioView>
                <StyledCommentText>
                  今月の振り返りを{'\n'}書きましょう
                </StyledCommentText>
              </StyledMenuRatioView>
            </StyledMenuTextView>
          </StyledTouchableOpacity>
        </StyleMenuLine>
      )}
    </StyledPanel>
  )
}

export default MenuPanel
