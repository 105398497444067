import { UserAccountType } from '@interfaces/Account'
import { PlSettings } from '@interfaces/Profile'
import CommonDialog from '@lib/CommonDialog'
import { PushNotificationPermissionsManager } from '@lib/PushNotificationPermissions'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { UploadImageManager } from '@lib/api/UploadImage'
import {
  GetPartnerProfileResponse,
  GetUserPlSettingsResponse,
  GetUserProfileResponse,
  GetUserStatusResponse,
  UpdateUserProfileProps,
  UserAPI,
} from '@lib/api/User'
import store from '@redux/store'
import { ProfileActions } from './types'

class ProfileManager {
  getUserProflie = async () => {
    const response = await UserAPI.getUserProfile()
    if (response.ok) {
      return (response.json as GetUserProfileResponse).app
    } else {
      throw new APIError(response)
    }
  }

  getName = (userType: 'user' | 'partner') => {
    if (userType === 'user') {
      return store.getState().profile.userProfile?.name || 'わたし'
    } else {
      return store.getState().profile.partnerProfile?.name || 'パートナー'
    }
  }

  fetchUserProflie = async () => {
    const userProfile = await this.getUserProflie()
    store.dispatch({
      type: 'UpdateUserProfile',
      userProfile,
    } as ProfileActions)
    return userProfile
  }

  getPartnerProflie = async () => {
    const response = await UserAPI.getPartnerProfile()
    if (!response.ok) throw new APIError(response)
    return (response.json as GetPartnerProfileResponse).app
  }

  fetchPartnerProflie = async () => {
    const partnerProfile = await this.getPartnerProflie()
    store.dispatch({
      type: 'UpdatePartnerProfile',
      partnerProfile,
    } as ProfileActions)
    return partnerProfile
  }

  clearPartnerProfile = () => {
    store.dispatch({
      type: 'UpdatePartnerProfile',
      partnerProfile: undefined,
    } as ProfileActions)
  }

  updateUserProfile = async (props: UpdateUserProfileProps) => {
    const response = await UserAPI.updateUserProfile(props)
    if (response.ok) {
      await this.fetchUserProflie()
    } else {
      throw new APIError(response)
    }
  }

  updateAvatarIcon = async ({
    mime,
    base64,
    userAccountType,
  }: {
    mime: string
    base64: string
    userAccountType: UserAccountType
  }) => {
    try {
      await UploadImageManager.uploadImage({
        imageUploadType: 'profile',
        userAccountType,
        mime,
        base64,
      })

      if (userAccountType === 'user') {
        await this.fetchUserProflie()
      } else {
        await this.fetchPartnerProflie()
      }
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  updateHomeBackgroundImage = async ({
    mime,
    base64,
    userAccountType,
  }: {
    mime: string
    base64: string
    userAccountType: UserAccountType
  }) => {
    try {
      await UploadImageManager.uploadImage({
        imageUploadType: 'home_backgroud',
        userAccountType,
        mime,
        base64,
      })

      if (userAccountType === 'user') {
        await this.fetchUserProflie()
      } else {
        await this.fetchPartnerProflie()
      }
      if (userAccountType === 'user') {
        this.fetchUserProflie()
      } else {
        this.fetchPartnerProflie()
      }
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  fetchUserStatus = async () => {
    const response = await UserAPI.getStatus()
    if (response.ok) {
      const userStatus = (response.json as GetUserStatusResponse).userStatus
      store.dispatch({
        type: 'UpdateUserStatus',
        userStatus,
      })

      TrackingUtils.repro.setUserStatus(userStatus)
      if (userStatus.paired) {
        TrackingUtils.adjust.trackEvent({ ios: 'ka14np', android: 'ukvret' })
      }

      // TODO userStatus APIの中に入れた方が良い
      const pushPermissionOsidori = (
        await PushNotificationPermissionsManager.getPushNotificationPermissions()
      ).find((p) => p.id === 3)
      if (pushPermissionOsidori) {
        TrackingUtils.repro.setPushPermissionNoticefromOsidOri(
          pushPermissionOsidori.suppressed ? 0 : 1,
        )
      }
    }
  }

  fetchPlSettings = async () => {
    const response = await UserAPI.getPlSettings()
    if (response.ok) {
      const rawPlSettings = (response.json as GetUserPlSettingsResponse)
        .plSettings
      const plSettings: PlSettings = {
        plPeriodDate:
          rawPlSettings.plPeriodDate !== null ? rawPlSettings.plPeriodDate : 1,
        plType: rawPlSettings.plType !== null ? rawPlSettings.plType : '',
        groupPlPeriodDate:
          rawPlSettings.plPeriodDate !== null
            ? rawPlSettings.groupPlPeriodDate
            : 1,
        groupPlType:
          rawPlSettings.plType !== null ? rawPlSettings.groupPlType : '',
      }
      store.dispatch({
        type: 'UpdatePlSettings',
        plSettings,
      })
      return plSettings
    } else {
      throw new APIError(response)
    }
  }

  updatePlSettings = async (props: PlSettings) => {
    const response = await UserAPI.updatePlSettings(props)
    if (!response.ok) {
      throw new APIError(response)
    }
  }
}

export default new ProfileManager()
