import { UserAccountType } from '@interfaces/Account'
import styled from 'styled-components/native'
import { StyledCommonPanel } from '../styles'
import GoalsPanelContent from './Content'

export type GoalsPanelProps = {
  userAccountType: UserAccountType
}

const GoalsPanel = ({ userAccountType }: GoalsPanelProps) => {
  return (
    <StyledPanel>
      <GoalsPanelContent userAccountType={userAccountType} />
    </StyledPanel>
  )
}

const StyledPanel = styled(StyledCommonPanel)({
  paddingTop: wp(7),
  paddingHorizontal: wp(3),
})

export default GoalsPanel
