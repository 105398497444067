import { TimelineNotice, TimelineActivity } from '@lib/api/Timeline'

// State
export interface TimelineState {
  activities?: TimelineActivity[]
  notices?: TimelineNotice[]
  noticesBadgeCount: number
}

// Actions
interface UpdateActivitiesAction {
  type: 'GetActivities'
  activities?: TimelineActivity[]
}

interface UpdateNoticesAction {
  type: 'GetNotices'
  notices?: TimelineNotice[]
}

interface UpdateNoticesBadgeCountAction {
  type: 'UpdateNoticesBadgeCount'
  noticesBadgeCount: number
}

export type TimelineActivityActions =
  | UpdateActivitiesAction
  | UpdateNoticesAction
  | UpdateNoticesBadgeCountAction

// Functions
export const updateTimelineActivities = (
  activities?: TimelineActivity[]
): UpdateActivitiesAction => {
  return {
    type: 'GetActivities',
    activities,
  }
}

export const updateTimelineNotices = (
  notices?: TimelineNotice[]
): UpdateNoticesAction => {
  return {
    type: 'GetNotices',
    notices,
  }
}

export const updateTimelineNoticesBadgeCount = (
  noticesBadgeCount: number
): UpdateNoticesBadgeCountAction => {
  return {
    type: 'UpdateNoticesBadgeCount',
    noticesBadgeCount,
  }
}
