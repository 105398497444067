export const ENV = process.env.REACT_APP_ENV

export const VERSION_NAME = '1.32.7'
export const VERSION_CODE = '17301'
export const S3_REGION = 'ap-northeast-1'
export const ADJUST_TOKEN_ANDROID = '7gxgdn20ov0g'
export const ADJUST_TOKEN_IOS = 'eksx4hztn56o'
export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL
export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET
export const VERSION_CONFIG_URL = ''
export const GA_ID = process.env.REACT_APP_GA_ID as string

export const ADFURIKUN_IOS_APP_ID = ''
export const ADFURIKUN_ANDROID_APP_ID = ''

export const DEBUG_DEFAULT_LOGIN_ID = ''
export const DEBUG_DEFAULT_LOGIN_PASSWORD = ''
export const DEBUG_DEFAULT_CREATE_ACCOUNT_EMAIL = ''
export const DEBUG_DEFAULT_CREATE_ACCOUNT_PASSWORD = ''
export const DEBUG_VERSION_CONFIG_URL = ''
