import { NativeApp } from '@lib/Env'
import React from 'react'
import DatePicker from 'react-mobile-datepicker'
import { PressableProps, Text } from 'react-native'
import DateTimePicker from 'react-native-modal-datetime-picker'

export type DatePickerType = 'default' | 'year-month'

export type DatePickerProps = PressableProps & {
  date?: Date
  isVisible: boolean
  onConfirm: (date: Date) => void
  onCancel: () => void
  headerFormat: string
  confirmText: string
  cancelText: string
  maximumDate?: Date
  minimumDate?: Date
  type?: DatePickerType
}

const CrossPlatformDatePicker: React.FC<DatePickerProps> = ({
  date,
  isVisible,
  onConfirm,
  onCancel,
  headerFormat,
  confirmText,
  cancelText,
  maximumDate,
  minimumDate,
  type = 'default',
}) => {
  return NativeApp ? (
    <DateTimePicker
      timePickerModeAndroid="spinner"
      mode="date"
      date={date}
      isVisible={isVisible}
      onConfirm={onConfirm}
      onCancel={onCancel}
      customHeaderIOS={() => (
        <Text
          style={{
            alignSelf: 'center',
            fontSize: 19,
            color: 'gray',
            paddingTop: 10,
          }}>
          {headerFormat}
        </Text>
      )}
      confirmTextIOS={confirmText}
      cancelTextIOS={cancelText}
      maximumDate={maximumDate}
      minimumDate={minimumDate}
    />
  ) : (
    <DatePicker
      theme="ios"
      headerFormat={headerFormat}
      confirmText={confirmText}
      cancelText={cancelText}
      value={date}
      isOpen={isVisible}
      onSelect={onConfirm}
      onCancel={onCancel}
      dateConfig={
        type === 'year-month'
          ? {
              year: {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
              },
              month: {
                format: 'M',
                caption: 'Mon',
                step: 1,
              },
            }
          : undefined
      }
      max={maximumDate}
      min={minimumDate}
    />
  )
}

export default CrossPlatformDatePicker
