import { selectAnnounces } from '@lib/Announce/slice'
import Color from '@lib/Color'
import DeepLinkService from '@lib/DeepLinkService'
import { NativeApp } from '@lib/Env'
import TrackingUtils from '@lib/TrackingUtils'
import store from '@redux/store'
import { useCallback, useMemo } from 'react'
import { Linking, ScrollView } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'

const StyledRootView = styled.View`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
`
const StyledContentView = styled.View`
  /* border-top-width: 1px; */
  /* border-bottom-width: 1px; */
  /* border-color: #dddddd; */
  padding-bottom: 20px;
`

const StyledTitleText = styled.Text`
  color: ${Color.TextBlack};
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
`

const AnnounceLevel2 = () => {
  const announces = useSelector(selectAnnounces)

  const level2Announces = useMemo(() => {
    return announces.find((v) => v.level === 2)?.announces
  }, [announces])

  if (!level2Announces) return null

  return (
    <StyledRootView>
      <StyledContentView>
        <StyledTitleText>便利な機能</StyledTitleText>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingRight: 15 }}>
          {level2Announces.map((v, index) => {
            return (
              <Panel
                key={index}
                url={v.url}
                imageUrl={v.imgUrl}
                index={index}
              />
            )
          })}
        </ScrollView>
      </StyledContentView>
    </StyledRootView>
  )
}

const StyledPanelView = styled.TouchableOpacity`
  margin-left: 15px;
  border-width: 1px;
  border-color: #dddddd;
  border-radius: 8px;
  width: ${wp(26)}px;
  height: ${wp(26) * (104 / 96)}px;
  overflow: hidden;
`

const StyledPanelImage = styled.Image`
  width: ${wp(26)}px;
  height: ${wp(26) * (104 / 96)}px;
  resize-mode: contain;
`

type PanelProps = {
  imageUrl: string
  url: string
  index: number
}

const Panel = ({ imageUrl, url, index }: PanelProps) => {
  const onPress = useCallback(() => {
    if (url) {
      if (NativeApp) {
        Linking.openURL(url)
      } else {
        url.startsWith('osidori://')
          ? DeepLinkService.open(url)
          : Linking.openURL(url)
      }
    }

    if (store.getState().account.accountMode === 'family') {
      TrackingUtils.repro.track(
        `【Tap】family_notification frame_small ${1 + index}`,
        'Tap',
      )
    } else {
      TrackingUtils.repro.track(
        `【Tap】personal_notification frame_small ${1 + index}`,
        'Tap',
      )
    }
  }, [index, url])

  return (
    <StyledPanelView onPress={onPress}>
      <StyledPanelImage source={{ uri: imageUrl }} />
    </StyledPanelView>
  )
}

export default AnnounceLevel2
