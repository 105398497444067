import FullWidthImage from '@components/FullWidthImage'
import Modal, { ModalProps } from 'react-native-modal'
import styled from 'styled-components/native'

export type GoalHelpModalProps = Partial<ModalProps> & {
  onClose?: () => void
}

const GoalHelpModal = ({ onClose, ...props }: GoalHelpModalProps) => {
  return (
    <StyleModal animationIn="fadeIn" animationOut="fadeOut" {...props}>
      <StyledModalContainer>
        <StyledMainView>
          <StyledBackgroundImage />
          <StyledCloseButton onPress={onClose}>
            <StyledCloseButtonImage />
          </StyledCloseButton>
        </StyledMainView>
      </StyledModalContainer>
    </StyleModal>
  )
}

const StyleModal = styled(Modal)({
  marginTop: 20,
  marginHorizontal: 20,
})

const StyledModalContainer = styled.ScrollView.attrs({
  contentContainerStyle: { flexGrow: 1, justifyContent: 'center' },
})({})

const StyledMainView = styled.View({})

const StyledBackgroundImage = styled(FullWidthImage).attrs({
  source: require('@assets/images/goal/goal_help_modal.webp'),
})({})

export const StyledCloseButton = styled.TouchableOpacity`
  position: absolute;
  padding: 15px;
  align-self: flex-end;
`

export const StyledCloseButtonImage = styled.Image.attrs({
  source: require('@images/buttons/timetree/timetree-connect-dialog-close-button.png'),
  resiziMode: 'contain',
})({
  width: 24,
  height: 24,
})

export default GoalHelpModal
