import ItemSeparator from '@components/ItemSeparator'
import { TimelineNotice } from '@lib/api/Timeline'
import Color from '@lib/Color'
import TimelineManager from '@lib/TimelineManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import React, { useCallback, useEffect, useState } from 'react'
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native'
import { useSelector } from 'react-redux'

const NoticesScreen: React.FC<
  StackScreenProps<RootStackParamList, 'Notices'>
> = ({ route }) => {
  const navigation = useNavigation()
  const [refreshing, setRefreshing] = useState(false)
  const isFocused = useIsFocused()

  const notices = useSelector(({ timeline }: RootState) => timeline.notices)

  const navigate = useCallback(
    (uri: string) => {
      navigation.navigate('WebView', {
        uri,
        leftButtonTitle: 'タイムライン',
      })
    },
    [navigation],
  )

  const onPressItem = useCallback(
    (item: TimelineNotice) => {
      navigate(item.url)
    },
    [navigate],
  )

  const renderItem: ListRenderItem<TimelineNotice> = useCallback(
    ({ item }) => (
      <TouchableOpacity style={styles.item} onPress={() => onPressItem(item)}>
        <Text style={styles.textContent}>{item.title}</Text>
      </TouchableOpacity>
    ),
    [onPressItem],
  )

  useEffect(() => {
    if (route.params?.uri) {
      navigation.navigate(route.params.uri)
    }
  }, [navigation, route.params?.uri])

  useEffect(() => {
    if (isFocused) {
      TimelineManager.fetchNotices()
      TrackingUtils.repro.track('【Screen】timeline_information', 'Screen')

      TimelineManager.markAllNoticesAsRead().then(() => {
        TimelineManager.fetchNoficesUnreadCount()
      })
    }
  }, [isFocused])

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: 'Timeline-Notices',
        title: 'タイムライン_お知らせ',
      })
    }
  }, [isFocused])

  return (
    <FlatList
      data={notices}
      renderItem={renderItem}
      keyExtractor={(item) => String(item.id)}
      ItemSeparatorComponent={() => ItemSeparator}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={() => {
            setRefreshing(true)
            TimelineManager.fetchNotices()
            setRefreshing(false)
          }}
        />
      }
      style={styles.container}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
  item: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: Color.White,
  },
  textContent: {
    color: Color.Gray,
  },
})

export default NoticesScreen
