import API, { APIResponse } from './index'

type Weekly =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

// 振替作成
export interface CreateTransferProps {
  fromWalletId: number
  toWalletId: number
  amount: number
  memo: string
  // 1明細の場合
  usedDate?: string
  // 繰り返し明細の場合
  startDate?: string
  endDate?: string
  term?: 'daily' | 'weekly' | 'monthly' | 'yearly'
  termToWeekly?: Weekly
  termToMonthly?: number // 1 - 31（月末は−1）
  termToYearly?: string // 4桁の文字列で月日
}

export interface ConvertTransferProps {
  base: 'from_transaction' | 'to_transaction'
  fromWalletId?: number
  fromAtUserBankAccountId?: number
  fromAtUserEmoneyServiceAccountId?: number
  fromAtUserCardAccountId?: number
  fromUserManuallyCreatedTransactionId?: number
  fromAtUserBankTransactionId?: number
  fromAtUserEmoneyTransactionId?: number
  fromAtUserCardTransactionId?: number
  toWalletId?: number
  toAtUserBankAccountId?: number
  toAtUserEmoneyServiceAccountId?: number
  // toAtUserCardAccountId?: number
  toUserManuallyCreatedTransactionId?: number
  toAtUserBankTransactionId?: number
  toAtUserEmoneyTransactionId?: number
  // toAtUserCardTransactionId?: number
  memo: string
}

export interface UpdateTransferProps {
  transferId: number
  fromWalletId?: number
  fromAtUserBankAccountId?: number
  fromAtUserEmoneyServiceAccountId?: number
  fromAtUserCardAccountId?: number
  fromUserManuallyCreatedTransactionId?: number
  fromAtUserBankTransactionId?: number
  fromAtUserEmoneyTransactionId?: number
  fromAtUserCardTransactionId?: number
  toWalletId?: number
  toAtUserBankAccountId?: number
  toAtUserEmoneyServiceAccountId?: number
  // toAtUserCardAccountId?: number
  toUserManuallyCreatedTransactionId?: number
  toAtUserBankTransactionId?: number
  toAtUserEmoneyTransactionId?: number
  // toAtUserCardTransactionId?: number
  memo: string
  // 手動明細
  startDate?: string
  endDate?: string
  term?: 'daily' | 'weekly' | 'monthly' | 'yearly'
  termToWeekly?: Weekly
  termToMonthly?: number // 1 - 31（月末は−1）
  termToYearly?: string // 4桁の文字列で月日
  // 自動明細
  // usedDate?: string
}

export interface DeleteTransferProps {
  transferId: number
}

class Transfer {
  createTransfer = (props: CreateTransferProps): Promise<APIResponse> => {
    return API.request('POST', '/api/v1/transfers', props, true)
  }

  updateTransfer = (props: UpdateTransferProps): Promise<APIResponse> => {
    return API.request(
      'PUT',
      `/api/v1/transfers/convert/${props.transferId}`,
      props,
      true,
    )
  }

  convertTransfer = (props: ConvertTransferProps): Promise<APIResponse> => {
    return API.request('PUT', `/api/v1/transfers/convert`, props, true)
  }

  deleteTransfer = (props: DeleteTransferProps): Promise<APIResponse> => {
    return API.request('DELETE', `/api/v1/transfers/${props.transferId}`, true)
  }
}

export default new Transfer()
