export type SettingProfileContentScreenProps = {
  userType: 'user' | 'partner'
}

export type SettingProfileContentStackScreenProps = {
  SettingProfileContentUser: SettingProfileContentScreenProps
  SettingProfileContentPartner: SettingProfileContentScreenProps
}

export const ChooseImageActionSheetIndex = {
  Camera: 0,
  PhotoLibraly: 1,
  Cancel: 2,
}

export const ChooseImageActionSheetOptions = [
  'カメラ',
  'フォトライブラリ',
  'キャンセル',
]

export const MarriageActionSheetIndex = {
  Married: 0,
  Unmarried: 1,
  Cancel: 2,
}

export const MarriageActionSheetOptions = ['既婚', '未婚', 'キャンセル']

export const GenderActionSheetIndex = {
  Male: 0,
  Female: 1,
  Cancel: 2,
}

export const GenderActionSheetOptions = ['男性', '女性', 'キャンセル']
