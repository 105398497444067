import AbsoluteActivityIndicator from '@components/AbsoluteActivityIndicator'
import CommonHeader from '@components/CommonHeader'
import { getApiUrl } from '@lib/api'
import CommonDialog from '@lib/CommonDialog'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import Log from '@lib/Log'
import NavigationService from '@lib/NavigationService'
import TrackingUtils from '@lib/TrackingUtils'
import { getWebToken } from '@lib/WebToken'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Linking, Platform, View } from 'react-native'
import { WebView, WebViewNavigation } from 'react-native-webview'

export type InsuranceWebViewScreenParams = {}

const InsuranceWebViewScreen: React.FC<
  StackScreenProps<RootStackParamList, 'WebView'>
> = () => {
  const [uri, setUri] = useState<string>()
  const [pathname, setPathname] = useState<string>()
  const [canGoBack, setCanGoBack] = useState<boolean>(false)

  const webViewRef = useRef<WebView>(null)

  const getUri = useCallback(async () => {
    try {
      const token = await getWebToken()
      const uri = `${getApiUrl()}/insurance/lp?web_token=${token}`
      setUri(uri)
      if (Platform.OS === 'android') {
        // Androidで最初のページ読み込みでonShouldStartLoadWithRequestが呼ばれない問題の対応
        setPathname(new URL(uri).pathname.replace(/\/$/, ''))
      }
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }, [])

  const goBack = useCallback(() => {
    if (!pathname) {
      NavigationService.goBack()
      return
    }

    if (pathname === '/insurance/lp') {
      NavigationService.goBack()
      return
    } else if (pathname === '/insurance/complete') {
      setUri(`${getApiUrl()}/insurance/lp`)
      return
    } else if (pathname === '/lifeinsurance_terms' || pathname === '/privacy') {
      setPathname('/insurance')
      webViewRef.current?.injectJavaScript(`
        $(".modal_page").hide();
        $(".modal_iframe").remove();
      `)
      return
    }
    if (canGoBack) {
      webViewRef.current?.goBack()
    } else {
      NavigationService.goBack()
    }
  }, [canGoBack, pathname])

  useEffect(() => HardwareBackPressHandler.addListener(goBack).remove, [goBack])

  useEffect(() => {
    getUri()
  }, [getUri])

  const onShouldStartLoadWithRequest = useCallback(
    (event: WebViewNavigation) => {
      Log.info({ url: event.url })
      const open = event.url.match(/^osidori:\/\/open\?uri=(.+)$/)?.[1]
      if (open) {
        Linking.openURL(decodeURIComponent(open))
        return false
      }

      const close = event.url.match(/^osidori:\/\/close$/)
      if (close) {
        NavigationService.goBack()
        return false
      }

      if (!event.url.match(/^https?:\/\//)) {
        Linking.openURL(event.url)
        return false
      }

      setPathname(new URL(event.url).pathname.replace(/\/$/, ''))

      return true
    },
    [],
  )

  useEffect(() => {
    Log.info({ pathname })
    switch (pathname) {
      case '/insurance/lp':
        TrackingUtils.repro.track('【Screen】life insurance_lp', 'Screen')
        break
      case '/insurance':
        TrackingUtils.repro.track('【Screen】life insurance_ef', 'Screen')
        break
      case '/insurance/complete':
        TrackingUtils.repro.track('【Screen】life insurance_thanks', 'Screen')
        break
    }
  }, [pathname])

  return (
    <View style={{ flex: 1 }}>
      <CommonHeader
        title="OsidOri 家族のお金相談"
        leftButtonType={pathname === '/insurance/complete' ? 'hidden' : 'stack'}
        onPressLeftButton={goBack}
      />
      {uri ? (
        <WebView
          source={{ uri }}
          startInLoadingState={true}
          originWhitelist={['*']}
          renderLoading={() => <AbsoluteActivityIndicator />}
          onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
          ref={webViewRef}
          onNavigationStateChange={({ canGoBack, navigationType }) => {
            // iOSの場合、ウェブの読み込みが完了するまでcanGoBackがtrueにならないので、
            // 画面遷移開始時点でcanGoBackをtrueにしてしまう。
            setCanGoBack(canGoBack || navigationType === 'click')
          }}
        />
      ) : (
        <AbsoluteActivityIndicator />
      )}
    </View>
  )
}

export default InsuranceWebViewScreen
