import { Image, ImageStyle, StyleProp } from 'react-native'

export type ArrowIconProps = {
  size?: number
  color?: string
  direction?: 'up' | 'right' | 'down' | 'left'
  style?: StyleProp<ImageStyle>
}

const ArrowIcon = ({
  size = 15,
  color,
  direction = 'right',
  style,
}: ArrowIconProps) => (
  <Image
    resizeMode="contain"
    source={require('@images/icons/icon-arrow-right.png')}
    style={[
      {
        width: size,
        height: size,
        tintColor: color,
        transform: [
          {
            rotate:
              direction === 'down'
                ? '90deg'
                : direction === 'up'
                ? '-90deg'
                : direction === 'left'
                ? '180deg'
                : '0deg',
          },
        ],
      },
      style,
    ]}
  />
)

export default ArrowIcon
