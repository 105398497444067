import Avatar from '@components/Avatar'
import Color from '@lib/Color'
import ProfileManager from '@lib/ProfileManager'
import TrackingUtils from '@lib/TrackingUtils'
import { selectGoal } from '@lib/api/Goal/slice'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import GoalCurrentAmountView from './GoalCurrentAmountView'

const GoalDetailsScreen: React.FC<
  StackScreenProps<RootStackParamList, 'GoalDetails'>
> = () => {
  const isFocused = useIsFocused()

  const goal = useSelector(selectGoal)

  const [isItemized, setIsItemized] = useState(false)

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.repro.track('【Screen】Saving_detail', 'Screen')

      TrackingUtils.ga.pageview({
        page: 'Goal-Detail',
        title: '目標貯金 目標の詳細',
      })
    }
  }, [isFocused])

  if (!goal) return null

  return (
    <StyledScrollView>
      <StyledGoalCurrentAmountView goal={goal} />
      <StyledDetailTitleView>
        <StyledDetailTitleText>貯金額の詳細</StyledDetailTitleText>
      </StyledDetailTitleView>
      <View style={styles.detailsView}>
        <View style={styles.moneySaved}>
          <View style={styles.avatarView}>
            <Avatar />
            <Text style={styles.avatarTitle}>
              {ProfileManager.getName('user')}
            </Text>
          </View>
          <View style={styles.currentAmountView}>
            <Text style={styles.currentAmounTitle}>現在の貯金額</Text>
            <Text style={styles.currentAmountText}>
              {goal.currentAmount.jpCurrency()}
            </Text>
          </View>
          <View style={styles.avatarView}>
            <Avatar type="partner" />
            <Text style={styles.avatarTitle}>
              {ProfileManager.getName('partner')}
            </Text>
          </View>
        </View>
        <View style={styles.amountItemView}>
          <Text style={styles.priceEachSavingsAmount}>
            {goal.userCurrentAmount?.currentAmount.jpCurrency()}
          </Text>
          <Text style={styles.eachCurrentAmountTitleText}>
            それぞれの貯金額
          </Text>
          <Text style={styles.priceEachSavingsAmount}>
            {goal.partnerCurrentAmount?.currentAmount.jpCurrency()}
          </Text>
        </View>
        {isItemized && (
          <>
            <View style={styles.wrapItemDetail}>
              <Text style={styles.priceItemDetail}>
                {goal.userCurrentAmount?.monthlyAmount?.jpCurrency()}
              </Text>
              <Text style={styles.titleItemDetail}>月々の積立額</Text>
              <Text style={styles.priceItemDetail}>
                {goal.partnerCurrentAmount?.monthlyAmount?.jpCurrency()}
              </Text>
            </View>
            <View style={styles.wrapItemDetail}>
              <Text style={styles.priceItemDetail}>
                {goal.userCurrentAmount?.addAmount?.jpCurrency()}
              </Text>
              <Text style={styles.titleItemDetail}>追加入金の額</Text>
              <Text style={styles.priceItemDetail}>
                {goal.partnerCurrentAmount?.addAmount?.jpCurrency()}
              </Text>
            </View>
            <View style={styles.wrapItemDetail}>
              <Text style={styles.priceItemDetail}>
                {goal.userCurrentAmount?.minusAmount?.jpCurrency()}
              </Text>
              <Text style={styles.titleItemDetail}>取り崩した額</Text>
              <Text style={styles.priceItemDetail}>
                {goal.partnerCurrentAmount?.minusAmount?.jpCurrency()}
              </Text>
            </View>
          </>
        )}
        <TouchableOpacity
          onPress={() => setIsItemized(!isItemized)}
          style={styles.wrapButton}>
          <View style={styles.button}>
            <Text style={styles.titleButton}>
              {isItemized ? '内訳を閉じる' : '内訳を開く'}
            </Text>
            <StyledArrowImage isItemized />
          </View>
        </TouchableOpacity>
        <View style={styles.twoRules}>
          <View style={styles.wrapTitleTwoRules}>
            <Text style={styles.titleTwoRules}>ふたりの積み立てルール</Text>
          </View>
          <View style={styles.bodyTwoRules}>
            <Text style={styles.priceTwoRules}>
              {goal.userGoalSettings?.monthlyAmount?.jpCurrency()}
            </Text>
            <Text style={styles.monthlySavings}>月々の積立額</Text>
            <Text style={styles.priceTwoRules}>
              {goal.partnerGoalSettings?.monthlyAmount?.jpCurrency()}
            </Text>
          </View>
        </View>
      </View>
    </StyledScrollView>
  )
}

const StyledScrollView = styled.ScrollView({ flex: 1 })

const StyledGoalCurrentAmountView = styled(GoalCurrentAmountView)({
  marginHorizontal: 16,
})

const StyledDetailTitleView = styled.View({
  width: '100%',
  paddingHorizontal: 20,
  paddingVertical: 10,
  backgroundColor: Color.SemiLightGray,
  marginTop: 25,
})

const StyledDetailTitleText = styled.Text({
  color: '#555555',
  fontSize: 13,
  fontWeight: 'normal',
})

const StyledArrowImage = styled.Image.attrs<{ isItemized: boolean }>(
  ({ isItemized }) => ({
    source: isItemized
      ? require('@assets/images/arrow/icon-arrow-up.png')
      : require('@assets/images/arrow/icon-arrow-down-gray.png'),
  }),
)<{ isItemized: boolean }>({
  width: 14,
  height: 14,
  tintColor: '#9A9A9A',
  marginLeft: 4,
  resizeMode: 'contain',
})

export default GoalDetailsScreen

const styles = StyleSheet.create({
  detailsView: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 81,
  },
  avatarView: {
    alignItems: 'center',
  },
  avatar: {
    width: 70,
    height: 70,
    overflow: 'hidden',
    borderRadius: 35,
  },
  imageAvatar: {
    width: 70,
    height: 70,
  },
  avatarTitle: {
    marginTop: 4,
    color: Color.DefaultText,
    fontSize: 13,
    fontWeight: 'normal',
  },
  moneySaved: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingTop: 24,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderColor: '#E9E9E9',
  },
  currentAmountText: {
    fontSize: 20,
    color: '#3A3A3A',
    fontWeight: 'bold',
  },
  currentAmounTitle: {
    fontSize: 13,
    fontWeight: 'normal',
    color: Color.DefaultText,
  },
  currentAmountView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceItemDetail: {
    fontSize: 15,
    fontWeight: 'normal',
    color: '#3A3A3A',
    flex: 1,
    paddingRight: 20,
    textAlign: 'right',
  },
  wrapItemDetail: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderColor: Color.LightGray,
  },
  titleItemDetail: {
    color: Color.Gray,
    fontSize: 11,
    fontWeight: 'normal',
    flex: 1.2,
    textAlign: 'center',
  },
  priceEachSavingsAmount: {
    color: '#3A3A3A',
    fontSize: 17,
    fontWeight: 'bold',
    paddingRight: 20,
    textAlign: 'right',
    flex: 1,
  },
  eachCurrentAmountTitleText: {
    fontSize: 13,
    color: Color.DefaultText,
    fontWeight: '400',
    flex: 1.2,
    textAlign: 'center',
  },
  amountItemView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderColor: Color.LightGray,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleButton: {
    fontSize: 13,
    fontWeight: 'normal',
    color: '#666666',
  },
  wrapButton: {
    width: '100%',
    alignItems: 'center',
    paddingTop: 17,
  },
  wrapTitleTwoRules: {
    width: '100%',
    paddingVertical: 10,
    backgroundColor: '#EEEEEE',
    paddingHorizontal: 16,
  },
  titleTwoRules: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#666666',
  },
  twoRules: {
    marginTop: 28,
    backgroundColor: '#F7F7F7',
    borderRadius: 12,
    overflow: 'hidden',
  },
  bodyTwoRules: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    paddingVertical: 15,
  },
  priceTwoRules: {
    color: '#3A3A3A',
    fontSize: 15,
    fontWeight: 'bold',
    width: 127,
    textAlign: 'center',
  },
  monthlySavings: {
    fontSize: 11,
    fontWeight: 'normal',
    color: '#666666',
  },
})
