import { Image } from '@components'
import CommonHeader from '@components/CommonHeader'
import TabBarLabel from '@components/TabBarLabel'
import { TabBarOptions } from '@constants'
import { accountModeSelector } from '@lib/AccountManager/actions'
import * as Buttons from '@lib/Buttons'
import Color from '@lib/Color'
import {
  getDateRange,
  getNextMonth,
  getPrevMonth,
  getYearlyDateRange,
  getYearMonth,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import {
  updateHomeExpensesIsYearly,
  updateHomeExpensesSelectedYearMonth,
} from '@lib/FinancialManager/types'
import ProfileManager from '@lib/ProfileManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { useDispatch, useSelector } from 'react-redux'
import HomeExpensesCategories from './HomeExpensesCategories'
import HomeExpensesCategoryBudgets from './HomeExpensesCategoryBudgets/HomeExpensesCategoryBudgets'
import HomeExpensesDetail from './HomeExpensesDetail'

const Tab = createMaterialTopTabNavigator()

const HomeExpensesScreen: React.FC<
  StackScreenProps<RootStackParamList, 'HomeExpenses'>
> = ({ route }) => {
  const dispatch = useDispatch()

  const userAccountType = useSelector(accountModeSelector)
  const financial = useSelector(({ financial }: RootState) => financial)
  const isYearly = useSelector(
    ({ financial }: RootState) => financial.homeExpensesIsYearly,
  )

  const [yearMonth, setYearMonth] = useState<string>()
  const [startDate, setStartDate] = useState<moment.Moment>()
  const [endDate, setEndDate] = useState<moment.Moment>()

  const onPressNextMonthButton = useCallback(() => {
    const yearMonth = financial[userAccountType]?.homeExpensesSelectedYearMonth
    if (!yearMonth) return

    const settings = getStartDateSettings()

    if (isYearly) {
      const year = +yearMonth.substring(0, 4) + 1
      const month = yearMonth.slice(-2)

      const { startDate, endDate } = getYearlyDateRange(
        year,
        settings.start,
        settings.startDateSettingOption,
      )

      setYearMonth(`${year}${month}`)
      setStartDate(startDate)
      setEndDate(endDate)
    } else {
      const nextMonth = getNextMonth(yearMonth)
      const settings = getStartDateSettings()
      const { startDate, endDate } = getDateRange(
        nextMonth.year,
        nextMonth.month,
        settings.start,
        settings.startDateSettingOption || '',
      )
      setYearMonth(nextMonth.key)
      setStartDate(startDate)
      setEndDate(endDate)
    }
  }, [financial, userAccountType, isYearly])

  const onPressPrevMonthButton = useCallback(() => {
    const yearMonth = financial[userAccountType]?.homeExpensesSelectedYearMonth
    if (!yearMonth) return

    const settings = getStartDateSettings()

    if (isYearly) {
      const year = +yearMonth.substring(0, 4) - 1
      const month = yearMonth.slice(-2)

      const { startDate, endDate } = getYearlyDateRange(
        year,
        settings.start,
        settings.startDateSettingOption,
      )

      setYearMonth(`${year}${month}`)
      setStartDate(startDate)
      setEndDate(endDate)
    } else {
      const prevMonth = getPrevMonth(yearMonth)
      const { startDate, endDate } = getDateRange(
        prevMonth.year,
        prevMonth.month,
        settings.start,
        settings.startDateSettingOption || '',
      )

      setYearMonth(prevMonth.key)
      setStartDate(startDate)
      setEndDate(endDate)
    }
  }, [financial, userAccountType, isYearly])

  const renderHeader = useCallback(() => {
    return (
      <View style={styles.periodSection}>
        <TouchableOpacity
          onPress={onPressPrevMonthButton}
          style={{ padding: 8 }}>
          <Image
            style={styles.arrowIcon}
            source={Buttons.Arrow.Left}
            resizeMode="contain"
          />
        </TouchableOpacity>
        {isYearly ? (
          <Text
            style={[styles.dateText, { padding: 2, textAlign: 'center' }]}
            numberOfLines={2}>
            {startDate?.format('YYYY年')}
            <Text style={{ fontSize: 10, fontWeight: 'normal' }}>
              {'\n'}
              {startDate?.format('YYYY/M/D')} ~ {endDate?.format('YYYY/M/D')}
            </Text>
          </Text>
        ) : (
          <Text style={styles.dateText}>
            {startDate?.format('YYYY/M/D')}~{endDate?.format('YYYY/M/D')}
          </Text>
        )}
        <TouchableOpacity
          onPress={onPressNextMonthButton}
          style={{ padding: 8 }}>
          <Image
            style={styles.arrowIcon}
            source={Buttons.Arrow.Right}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </View>
    )
  }, [
    endDate,
    isYearly,
    onPressNextMonthButton,
    onPressPrevMonthButton,
    startDate,
  ])

  useEffect(() => {
    if (userAccountType === 'user') {
      TrackingUtils.repro.track('【Screen】Personal_PL', 'Screen')
    } else {
      TrackingUtils.repro.track('【Screen】Family_PL', 'Screen')
    }
  }, [userAccountType])

  useEffect(() => {
    const now = moment()

    const settings = getStartDateSettings(userAccountType)
    const yearMonth = getYearMonth(
      now,
      settings.start,
      settings.startDateSettingOption,
    )

    const { startDate, endDate } = isYearly
      ? getYearlyDateRange(
          yearMonth.year,
          settings.start,
          settings.startDateSettingOption,
        )
      : getDateRange(
          yearMonth.year,
          yearMonth.month,
          settings.start,
          settings.startDateSettingOption,
        )

    setYearMonth(yearMonth.key)
    setStartDate(startDate)
    setEndDate(endDate)
  }, [isYearly, userAccountType])

  useEffect(() => {
    dispatch(updateHomeExpensesSelectedYearMonth(userAccountType, yearMonth))
  }, [dispatch, userAccountType, yearMonth])

  const renderRightButton = useCallback(() => {
    return (
      <TouchableOpacity
        onPress={() => dispatch(updateHomeExpensesIsYearly(!isYearly))}
        style={{
          paddingVertical: 2,
          paddingHorizontal: 12,
        }}>
        {isYearly ? (
          <Image
            source={require('@images/icons/year_month_icon.png')}
            resizeMode="contain"
            style={{ width: 40, height: 36 }}
          />
        ) : (
          <Image
            source={require('@images/icons/month_year_icon.png')}
            resizeMode="contain"
            style={{ width: 40, height: 36 }}
          />
        )}
      </TouchableOpacity>
    )
  }, [dispatch, isYearly])

  const title =
    userAccountType === 'user'
      ? `${ProfileManager.getName('user')}の家計簿`
      : '家族の家計簿'

  return (
    <View style={styles.container}>
      <CommonHeader
        title={title}
        leftButtonType="menu"
        renderRightButton={renderRightButton}
      />
      {renderHeader()}
      <Tab.Navigator
        backBehavior="none"
        initialRouteName={route.params?.initialRouteName || route.params?.tab}
        tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="HomeExpensesCategories"
          component={HomeExpensesCategories}
          options={{
            tabBarLabel: ({ color }) => (
              <TabBarLabel color={color}>収支</TabBarLabel>
            ),
          }}
        />
        {userAccountType === 'family' && (
          <Tab.Screen
            name="HomeExpensesShareDetail"
            component={HomeExpensesDetail}
            options={{
              tabBarLabel: ({ color }) => (
                <TabBarLabel color={color}>支出の分担</TabBarLabel>
              ),
            }}
          />
        )}
        <Tab.Screen
          name="HomeExpensesCategoryBudgets"
          component={HomeExpensesCategoryBudgets}
          options={{
            tabBarLabel: ({ color }) => (
              <TabBarLabel color={color}>分析</TabBarLabel>
            ),
          }}
        />
      </Tab.Navigator>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
  periodSection: {
    minHeight: 45,
    maxHeight: 45,
    paddingTop: 6,
    paddingHorizontal: 8,
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  chartSection: {
    flexDirection: 'row',
    height: wp('30%'),
    paddingHorizontal: 20,
  },
  dateText: {
    padding: 8,
    fontSize: 16,
    fontWeight: 'normal',
  },
  paymentSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textAmount: {
    marginRight: 24,
    color: Color.GrayVeryDark,
    fontSize: 34,
    fontWeight: 'normal',
  },
  textBalance: {
    marginTop: 14,
    marginRight: 12,
    color: Color.GrayVeryDark,
    fontSize: 15,
    fontWeight: 'normal',
  },
  scrollView: {
    height: '50%',
  },
  listPaymentsItem: {
    paddingTop: 15,
    paddingBottom: 10,
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listPaymentsItemTypeIcon: {
    marginLeft: 15,
    marginRight: 6,
  },
  listPaymentsItemTypeText: {
    fontSize: 17,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
  },
  listPaymentsItemInfoPercent: {
    width: 50,
    fontSize: 17,
    fontWeight: 'normal',
    textAlign: 'right',
    color: Color.Gray,
  },
  listPaymentsItemInfoAmount: {
    fontSize: 22,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  iconEdit: {
    resizeMode: 'contain',
    height: 20,
  },
  btnEdit: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
})

export default HomeExpensesScreen
