import CommonHeader from '@components/CommonHeader'
import FinancialAccountIcon from '@components/FinancialAccountIcon'
import { UpdateFinanceSortProps } from '@lib/api/Financial'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import FinancialManager from '@lib/FinancialManager'
import NavigationService from '@lib/NavigationService'
import RNProgressHud from '@lib/ProgressHUD'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useState } from 'react'
import DragListView from 'react-drag-listview'
import styled from 'styled-components/native'

type FinancialCategory = 'bank' | 'card' | 'emoney' | 'stock' | 'wallet'

const FinancialAccountName = {
  bank: '銀行',
  card: 'カード',
  emoney: '電子マネー',
  stock: '証券',
  wallet: '財布（現金管理）',
} as const

const SortFinanceCategoryScreen: React.FC<
  StackScreenProps<RootStackParamList, 'SortFinanceCategory'>
> = () => {
  const [financialCategories, setFinancialCategories] =
    useState<FinancialCategory[]>()

  const getSortSettings = useCallback(async () => {
    const settings = await FinancialManager.getFinanceSort()

    const sortedSettings = settings
      .sort((a, b) => a.sort - b.sort)
      .map((v) => v.type)

    setFinancialCategories(sortedSettings)
  }, [])

  const updateSortSettings = useCallback(async () => {
    if (!financialCategories) return

    const settings: UpdateFinanceSortProps = {
      userAccountType: store.getState().account.accountMode,
      types: financialCategories.map((type, index) => ({
        type,
        sort: 1 + index,
      })),
    }

    await FinancialManager.updateFinanceSort(settings)
  }, [financialCategories])

  const onPressFinishButton = useCallback(async () => {
    try {
      RNProgressHud.show()
      await updateSortSettings()
      NavigationService.goBack()
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      RNProgressHud.dismiss()
    }
  }, [updateSortSettings])

  const renderRightButton = useCallback(
    () => (
      <StyledRightButton onPress={onPressFinishButton}>
        <StyledRightButtonText>完了</StyledRightButtonText>
      </StyledRightButton>
    ),
    [onPressFinishButton],
  )

  useEffect(() => {
    getSortSettings()
  }, [getSortSettings])

  return (
    <StyledRootView>
      <CommonHeader
        title="口座表示の編集"
        leftButtonType="hidden"
        renderRightButton={renderRightButton}
      />
      <StyledHeaderText>
        右のつまみを長押しして並べ替えができます
      </StyledHeaderText>
      {financialCategories && (
        <StyledScrollView>
          <DragListView
            onDragEnd={(fromIndex, toIndex) => {
              const tempFinancialCategories = [...financialCategories]
              const item = tempFinancialCategories.splice(fromIndex, 1)[0]
              tempFinancialCategories.splice(toIndex, 0, item)
              setFinancialCategories(tempFinancialCategories)
            }}
            nodeSelector=".draggable"
            handleSelector=".draggable">
            {financialCategories.map((item) => (
              <div key={item} className="draggable">
                <ListItem type={item} />
              </div>
            ))}
          </DragListView>
        </StyledScrollView>
      )}
    </StyledRootView>
  )
}

type ListItemProps = {
  type: 'bank' | 'card' | 'emoney' | 'stock' | 'wallet'
}

const ListItem = ({ type }: ListItemProps) => {
  return (
    <StyledListItemView>
      <FinancialAccountIcon financialAccountType={type} />
      <StyledListItemText>{FinancialAccountName[type]}</StyledListItemText>
      <StyledListItemDragButton>
        <StyledListItemDragImage />
      </StyledListItemDragButton>
    </StyledListItemView>
  )
}

const StyledRootView = styled.View({
  flex: 1,
})

const StyledScrollView = styled.ScrollView({
  flex: 1,
})

const StyledHeaderText = styled.Text({
  backgroundColor: Color.LightGray,
  padding: 15,
  color: Color.DefaultText,
  fontSize: 12,
  fontWeight: 'normal',
})

const StyledRightButton = styled.TouchableOpacity({
  paddingHorizontal: 15,
  justifyContent: 'center',
  height: 40,
})

const StyledRightButtonText = styled.Text({
  color: 'white',
  fontSize: 13,
  fontWeight: 'normal',
})

const StyledListItemView = styled.View({
  paddingLeft: 16,
  backgroundColor: Color.White,
  height: 44,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomWidth: 1,
  borderBottomColor: Color.LightGray,
})

const StyledListItemDragButton = styled.View.attrs({
  pointerEvents: 'none',
})({
  paddingLeft: 30,
  paddingVertical: 14,
  paddingRight: 20,
})

const StyledListItemText = styled.Text({
  flex: 1,
  marginLeft: 8,
  fontSize: 14,
  fontWeight: 'normal',
  color: Color.DefaultText,
})

const StyledListItemDragImage = styled.Image.attrs({
  resizeMode: 'contain',
  source: require('@images/common-header/common-header-menu-button.png'),
})({
  tintColor: Color.Gray,
  width: 16,
  height: 16,
})

export default SortFinanceCategoryScreen
