import FullWidthImage from '@components/FullWidthImage'
import { selectAnnounces } from '@lib/Announce/slice'
import Color from '@lib/Color'
import DeepLinkService from '@lib/DeepLinkService'
import { NativeApp } from '@lib/Env'
import TrackingUtils from '@lib/TrackingUtils'
import store from '@redux/store'
import { useCallback, useMemo, useState } from 'react'
import { Linking } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'

const StyledRootView = styled.View`
  margin-top: 20px;
  background-color: white;
`
const StyledContentView = styled.View`
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: 'rgb(224, 224, 224)';
`

const StyledHeaderView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledTitleText = styled.Text`
  color: ${Color.TextBlack};
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
`

const StyledImage = styled(FullWidthImage)``

const StyledImageButton = styled.TouchableOpacity`
  margin-horizontal: 15px;
  margin-bottom: 15px;
  border-width: 1px;
  border-color: #dddddd;
  border-radius: 8px;
  overflow: hidden;
`
const StyledOpenCloseButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 15px;
`

const StyledOpenCloseButtonText = styled.Text`
  color: rgb(102, 102, 102);
  font-size: 14px;
  font-weight: normal;
`

const StyledArrowImage = styled.Image.attrs({
  resizeMode: 'contain',
})<{ isOpened: boolean }>`
  margin-left: 3px;
  width: 18px;
  height: 18px;
  transform: rotate(${({ isOpened }) => (isOpened ? 0 : 180)}deg);
`

const AnnounceLevel1 = () => {
  const announces = useSelector(selectAnnounces)
  const [isOpened, setIsOpened] = useState(true)

  const announce = useMemo(() => {
    return announces.find((v) => v.level === 1)?.announces[0]
  }, [announces])

  const onPressOpenCloseButton = useCallback(
    () => setIsOpened(!isOpened),
    [isOpened],
  )

  const onPressImageButton = useCallback(() => {
    if (announce?.url) {
      if (NativeApp) {
        Linking.openURL(announce.url)
      } else {
        announce.url.startsWith('osidori://')
          ? DeepLinkService.open(announce.url)
          : Linking.openURL(announce.url)
      }
    }

    if (store.getState().account.accountMode === 'family') {
      TrackingUtils.repro.track('【Tap】family_notification frame_big', 'Tap')
    } else {
      TrackingUtils.repro.track('【Tap】personal_notification frame_big', 'Tap')
    }
  }, [announce])

  if (!announce) return null

  return (
    <StyledRootView>
      <StyledContentView>
        <StyledHeaderView>
          <StyledTitleText>おすすめ</StyledTitleText>
          <StyledOpenCloseButton onPress={onPressOpenCloseButton}>
            <StyledOpenCloseButtonText>
              {isOpened ? '閉じる' : '開く'}
            </StyledOpenCloseButtonText>
            <StyledArrowImage
              isOpened={isOpened}
              source={require('@images/icons/arrow-up-regular-icon.png')}
            />
          </StyledOpenCloseButton>
        </StyledHeaderView>
        {isOpened && (
          <StyledImageButton onPress={onPressImageButton}>
            <StyledImage source={{ uri: announce.imgUrl }} />
          </StyledImageButton>
        )}
      </StyledContentView>
    </StyledRootView>
  )
}

export default AnnounceLevel1
