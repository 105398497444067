import API, { APIResponse } from './index'

export interface PostLoginProps {
  email: string
  password: string
}

export interface PostLoginResponse {
  app: {
    accessToken: string
    accessTokenExpiresAt: string
    refreshToken: string
    refreshTokenExpiresAt: string
  }
}

class Auth {
  public login = (props: PostLoginProps): Promise<APIResponse> => {
    return API.request('POST', '/api/v2/auth/login', props, false)
  }

  public uiLogin = (props: PostLoginProps): Promise<APIResponse> => {
    return API.request('POST', '/api/v2/ui/auth/login', props, false)
  }
}

export default new Auth()
