import CategoryImage from '@components/CategoryImage'
import IconImage from '@components/IcomImage'
import Color from '@lib/Color'
import {
  ArrowContainer,
  BudgetText,
  CategoryAllText,
  CategoryDataContainer,
  CategoryDetailContainer,
  CategoryExpenseContainer,
  CategoryRemainContainer,
  CategoryText,
  ExpenseAmount,
  ExpenseContainer,
  ExpenseTitle,
  IconContainer,
  RemainAmount,
  RemainTitle,
  RootContainer,
  StyledBarGraph,
} from './styles'
import { BudgetCategoryItemProps } from './types'

const BudgetCategoryItem = ({
  categoryId,
  categoryName,
  expenseAmount,
  prevExpenseAmount,
  budgetAmount,
  onPress,
  style,
  categoryTextStyle,
  displayType,
  isYearly,
}: BudgetCategoryItemProps) => {
  const targetAmount =
    displayType === 'budget' ? budgetAmount : Math.abs(prevExpenseAmount)

  const remain = targetAmount + expenseAmount
  const ratio = Math.abs(expenseAmount) / targetAmount

  return (
    <RootContainer onPress={onPress} disabled={!onPress} style={style}>
      <IconContainer>
        {categoryId ? (
          <CategoryImage categoryId={categoryId} />
        ) : (
          <IconImage name="CategoryAll" />
        )}
        {categoryName ? (
          <CategoryText style={categoryTextStyle}>{categoryName}</CategoryText>
        ) : (
          <CategoryAllText style={categoryTextStyle}>全体</CategoryAllText>
        )}
      </IconContainer>
      <CategoryDetailContainer>
        <CategoryDataContainer>
          <CategoryExpenseContainer>
            {(displayType === 'prevMonth' ||
              targetAmount > 0 ||
              !categoryId) && (
              <BudgetText>{`${
                displayType === 'budget' ? '予算' : isYearly ? '前年' : '前月'
              } ${targetAmount.jpCurrency()}`}</BudgetText>
            )}
            <ExpenseContainer>
              <ExpenseTitle>支出</ExpenseTitle>
              <ExpenseAmount>
                {Math.abs(expenseAmount).jpCurrency()}
              </ExpenseAmount>
            </ExpenseContainer>
          </CategoryExpenseContainer>
          {(displayType === 'prevMonth' || targetAmount > 0) && (
            <CategoryRemainContainer>
              <RemainTitle type={displayType} over={remain < 0}>
                {displayType === 'budget'
                  ? remain >= 0
                    ? '残り'
                    : '超過'
                  : remain >= 0
                  ? '減少'
                  : '増加'}
              </RemainTitle>
              <RemainAmount over={remain < 0}>
                {Math.abs(remain).jpCurrency()}
              </RemainAmount>
            </CategoryRemainContainer>
          )}
        </CategoryDataContainer>
        {(displayType === 'prevMonth' || targetAmount > 0) && (
          <StyledBarGraph
            ratio={ratio}
            borderColor={ratio > 1 ? Color.Orange : Color.Primary}
          />
        )}
      </CategoryDetailContainer>
      {onPress && (
        <ArrowContainer>
          <IconImage name="ArrowRight" />
        </ArrowContainer>
      )}
    </RootContainer>
  )
}

export default BudgetCategoryItem
