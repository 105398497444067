export {}

declare global {
  interface String {
    isValidEmail(): boolean
    userAccountPath(): 'user' | 'group'
  }
}

String.prototype.isValidEmail = function () {
  // https://emailregex.com/
  return !!this.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

String.prototype.userAccountPath = function () {
  const userAccountType = this.toString()
  if (userAccountType === 'user') return 'user'
  if (userAccountType === 'family') return 'group'
  throw new Error(`'${userAccountType}' is not UserAccountType.`)
}
