import { Image } from '@components'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Color from '@lib/Color'
import { FAIcon } from '@lib/FAIcon'
import Images from '@lib/Images'
import NavigationService from '@lib/NavigationService'
import { sendSignal } from '@lib/OsidoriSignal'
import ProfileManager from '@lib/ProfileManager'
import { SpotlightTourSpot } from '@lib/SpotlightTour'
import { TipsProps } from '@lib/SpotlightTour/Tips'
import TrackingUtils from '@lib/TrackingUtils'
import React from 'react'
import {
  StatusBar,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { SafeAreaView } from 'react-native-safe-area-context'

export type CommonHeaderLeftButtonType = 'menu' | 'stack' | 'modal' | 'hidden'

interface Props {
  title?: string
  renderTitle?: () => JSX.Element
  renderLeftButton?: () => JSX.Element | null
  renderRightButton?: () => JSX.Element | null
  onPressLeftButton?: () => void
  onPressRightButton?: () => void
  onPressTitleHelpIcon?: () => void
  tipsComponent?: (props: TipsProps) => React.ReactElement
  leftButtonType?: CommonHeaderLeftButtonType
  leftButtonTitle?: string
  rightButtonTitle?: string
  rightButtonTextStyle?: StyleProp<TextStyle>
  backKey?: string
  style?: StyleProp<ViewStyle>
}

class CommonHeader extends React.Component<Props> {
  static defaultProps: Props = {
    title: '',
    leftButtonTitle: '',
    rightButtonTitle: '',
    leftButtonType: 'stack',
    style: {},
  }

  private onPressLeftButton = () => {
    sendSignal('TAPPED_NAVIGATION_LEFT_BUTTON')

    if (this.props.onPressLeftButton) {
      this.props.onPressLeftButton()
    } else if (this.props.leftButtonType === 'menu') {
      NavigationService.openDrawer()
      ProfileManager.fetchPartnerProflie()

      TrackingUtils.ga.pageview({
        page: 'Open-SideMenu',
        title: 'サイドメニュー表示',
      })
    } else if (this.props.leftButtonType !== 'hidden') {
      NavigationService.goBack()
    }
  }

  private renderLeftButton = () => {
    // Custome left button
    if (this.props.renderLeftButton) {
      return this.props.renderLeftButton()
    }

    // Menu button
    switch (this.props.leftButtonType) {
      case 'menu':
        return (
          <View style={styles.menuButton}>
            <Image
              source={Images.CommonHeader.MenuButton}
              style={{ width: 20, height: 20 }}
            />
          </View>
        )
      case 'stack':
        return (
          <View style={{ paddingLeft: 10 }}>
            <Image
              resizeMode="contain"
              source={Images.CommonHeader.BackButton}
              style={styles.stackLeftButtonImage}
            />
          </View>
        )
      case 'modal':
        return (
          <View
            style={{
              paddingLeft: 10,
              paddingRight: 5,
            }}>
            <Image
              resizeMode="contain"
              source={Images.CommonHeader.CloseButton}
              style={styles.modalLeftButtonImage}
            />
          </View>
        )
      default:
        // hidden
        return null
    }
  }

  private renderRightButton = () => {
    // Custome right button
    if (this.props.renderRightButton) {
      return this.props.renderRightButton()
    }
    return null
  }

  private renderTitle = () => {
    if (this.props.renderTitle) return this.props.renderTitle()
    if (this.props.leftButtonTitle && this.props.leftButtonTitle.length > 5) {
      return null
    }
    if (this.props.title === undefined) return null

    return (
      <View style={styles.titleView}>
        <Text
          ellipsizeMode="tail"
          numberOfLines={1}
          style={[
            styles.titleText,
            {
              maxWidth: this.props.leftButtonTitle ? wp('60%') : wp('80%'),
              marginLeft:
                this.props.onPressTitleHelpIcon && !!this.props.title ? 26 : 0,
            },
          ]}>
          {this.props.title}
        </Text>
        {this.props.onPressTitleHelpIcon && !!this.props.title && (
          <TouchableOpacity
            onPress={this.props.onPressTitleHelpIcon}
            style={{ padding: 6 }}>
            <FAIcon
              icon={faQuestionCircle}
              size={14}
              color="white"
              style={{ marginLeft: 0 }}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  render() {
    return (
      <>
        <StatusBar translucent={true} />
        <SpotlightTourSpot
          spotId="CommonHeaderHelpIconSpot"
          spotType="rect"
          rectRadius={0}
          scale={1}
          tipsComponent={this.props.tipsComponent}>
          <SafeAreaView edges={['top']} style={styles.container}>
            <View style={[styles.contents, this.props.style]}>
              {this.renderTitle()}
              <TouchableOpacity
                onPress={this.onPressLeftButton}
                style={styles.leftButton}>
                {this.renderLeftButton()}
                <View
                  style={{
                    paddingRight: 5,
                    justifyContent: 'center',
                  }}>
                  <Text style={styles.leftButtonTitle}>
                    {this.props.leftButtonTitle}
                  </Text>
                </View>
              </TouchableOpacity>
              <View style={styles.rightButton}>
                {this.renderRightButton()}
                {!!this.props.rightButtonTitle && (
                  <TouchableOpacity onPress={this.props.onPressRightButton}>
                    <Text
                      style={[
                        styles.rightButtonTitle,
                        this.props.rightButtonTextStyle,
                      ]}>
                      {this.props.rightButtonTitle}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </SafeAreaView>
        </SpotlightTourSpot>
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.Primary,
  },
  contents: {
    flexDirection: 'row',
    backgroundColor: Color.Primary,
    alignItems: 'center',
    height: 44,
  },
  menuButton: {
    padding: 14,
  },
  leftButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  stackLeftButtonImage: {
    width: 24,
    height: 24,
  },
  modalLeftButtonImage: {
    marginLeft: 5,
    width: 20,
    height: 20,
  },
  rightButton: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    right: 0,
    bottom: 2,
  },
  titleView: {
    width: '100%',
    position: 'absolute',
    // top: statusBarHeight,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  titleText: {
    color: Color.White,
    fontSize: Math.min(16, wp(4)),
    fontWeight: 'bold',
  },
  leftButtonTitle: {
    alignItems: 'center',
    color: Color.White,
    fontSize: 16,
    fontWeight: 'normal',
  },
  rightButtonTitle: {
    color: Color.White,
    fontSize: 16,
    fontWeight: 'normal',
    paddingHorizontal: 18,
    paddingVertical: 14,
  },
})

export default CommonHeader
