import AppButton from '@components/AppButton'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { updateRootScreen } from '@redux/actions'
import store from '@redux/store'
import { useCallback, useEffect } from 'react'
import { Linking } from 'react-native'
import styled from 'styled-components/native'

const UIBankFirstScreen = () => {
  const onButtonPress = useCallback(() => {
    SessionManager.setWalkthroughDisplayCompleted(true)
    store.dispatch(updateRootScreen('Account'))
  }, [])

  useEffect(() => {
    TrackingUtils.ga.pageview({
      page: 'lp01',
      title: `LP`,
    })
  }, [])

  return (
    <StyledRootView>
      <StyledScrollView>
        <StyledImageBackground />
        <StyledAboutDetailButton
          onPress={() =>
            Linking.openURL('https://www.uibank.co.jp/service/osidori/')
          }>
          <StyledAbountDetailImage />
        </StyledAboutDetailButton>
      </StyledScrollView>
      <StyledBottomView>
        <AppButton title="さっそくはじめる" onPress={onButtonPress} />
      </StyledBottomView>
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  flex: 1,
  justifyContent: 'space-between',
})

const StyledScrollView = styled.ScrollView({
  flex: 1,
})

const StyledImageBackground = styled.Image.attrs({
  source: require('@images/uibank/uibank-first-screen.webp'),
})({
  width: '100%',
  aspectRatio: 1125 / 5949,
})

const StyledAboutDetailButton = styled.TouchableOpacity({
  marginBottom: wp(8),
  marginHorizontal: wp(3.9),
})

const StyledAbountDetailImage = styled.Image.attrs({
  source: require('@images/uibank/about-detail-button.webp'),
})({
  width: '100%',
  aspectRatio: 1005 / 219,
})

const StyledBottomView = styled.View({
  borderTopWidth: 0.5,
  borderTopColor: '#E0E0E0',
  paddingVertical: wp(5),
  paddingHorizontal: wp(3.9),
})

export default UIBankFirstScreen
