import AdView from '@components/AdView'
import AppButton from '@components/AppButton'
import BlurView from '@components/BlurView'
import CategoryImage from '@components/CategoryImage'
import GroupShareToggleButton from '@components/FamilyShareToggleButton'
import ItemSeparator from '@components/ItemSeparator'
import SectionListHeader from '@components/SectionListHeader'
import { accountModeSelector } from '@lib/AccountManager/actions'
import AdfurikunManager from '@lib/AdfurikunManager'
import { APIError } from '@lib/api'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import {
  getStartDate,
  parseMonthKey,
  StartDateSettingOption,
} from '@lib/DateUtils'
import { getStartDateSettings } from '@lib/DateUtilsRedux'
import DateUtilsV1 from '@lib/DateUtilsV1'
import FinancialManager from '@lib/FinancialManager'
import Log from '@lib/Log'
import NavigationService, { useNavigatableScreen } from '@lib/NavigationService'
import ProgressHUD from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/core'
import { StackScreenProps } from '@react-navigation/stack'
import { RootState } from '@redux/store'
import { navigatePremiumPlanLP } from '@Screen/Main/PremiumPlan'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActivityIndicator,
  GestureResponderEvent,
  Image,
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { PieChart, PieChartData } from 'react-native-svg-charts'
import { useSelector } from 'react-redux'

type Item = {
  ratio: number
  categoryId: string
  amount: number
  childItems: {
    subCategoryId: string
    amount: number
    onPress: (event: GestureResponderEvent) => void
  }[]
  scope?: 'income' | 'expense'
}

// 今月の家計簿 - 収支

const HomeExpensesCategories: React.FC<
  StackScreenProps<RootStackParamList, 'HomeExpensesCategories'>
> = () => {
  useNavigatableScreen('HomeExpensesCategories')

  const userAccountType = useSelector(accountModeSelector)

  const isYearly = useSelector(
    ({ financial }: RootState) => financial.homeExpensesIsYearly,
  )

  const prevIsYearRef = useRef(isYearly)

  const financial = useSelector(
    ({ financial }: RootState) => financial[userAccountType],
  )

  const userProfile = useSelector(
    ({ profile }: RootState) => profile.userProfile,
  )

  const isFamilyShareMode = useSelector(
    ({ account }: RootState) => account.isFamilyShareMode,
  )

  const [refreshing, setRefreshing] = useState(false)
  const [planError, setPlanError] = useState(false)
  const [isDisplaySubCategories, setIsDisplaySubCategories] = useState(
    SessionManager.getDisplayHomeExpensesSubCategories(),
  )
  const [isLoadMovieAwardSuccess, setIsLoadMovieAwardSuccess] = useState<
    boolean | undefined
  >(false)

  const isFocused = useIsFocused()

  const fetchBalance = useCallback(async () => {
    if (!financial) return

    const { homeExpensesSelectedYearMonth } = financial
    if (!homeExpensesSelectedYearMonth) return

    let isNeedShowProgressHUD: boolean
    if (prevIsYearRef.current !== isYearly) {
      isNeedShowProgressHUD = true
      prevIsYearRef.current = isYearly
    } else {
      isNeedShowProgressHUD = false
    }

    try {
      if (isNeedShowProgressHUD) {
        ProgressHUD.show()
      }

      const { year, month } = parseMonthKey(homeExpensesSelectedYearMonth)
      const settings = getStartDateSettings()
      const startDate = getStartDate(
        year,
        month,
        settings.start,
        settings.startDateSettingOption || StartDateSettingOption.None,
      )

      await FinancialManager.fetchBalance(
        userAccountType,
        isFamilyShareMode,
        startDate.format(),
        isYearly,
      )
      setPlanError(false)
    } catch (error) {
      if (
        error instanceof APIError &&
        (error.response?.errorCode === '007004' ||
          error.response?.errorCode === '007007')
      ) {
        setPlanError(true)
        TrackingUtils.repro.track('【Screen】upper limit_pl', 'Screen')

        TrackingUtils.ga.pageview({
          page: 'Upperlimit-PL',
          title: 'PL制限にタッチの画面',
        })

        setIsLoadMovieAwardSuccess(undefined)
        AdfurikunManager.loadMovieReward((isLoadMovieAwardSuccess) => {
          Log.info({ isLoadMovieAwardSuccess })
          setIsLoadMovieAwardSuccess(isLoadMovieAwardSuccess)
        })
      } else {
        CommonDialog.showError({ error })
      }
    } finally {
      if (isNeedShowProgressHUD) {
        ProgressHUD.dismiss()
      }
    }
  }, [financial, isFamilyShareMode, isYearly, userAccountType])

  useEffect(() => {
    if (isFocused) {
      fetchBalance()
    }

    return () => {
      const thisMonth = DateUtilsV1.monthKey()
      const monthsPL = financial.monthsPL
      Object.keys(monthsPL)
        .filter((k) => k !== thisMonth)
        .forEach((k) => delete monthsPL[k])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFocused,
    userAccountType,
    isYearly,
    financial?.homeExpensesSelectedYearMonth,
    isFamilyShareMode,
  ])

  // useEffect(() => {
  //   fetchBalance()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userAccountType, isYearly, isFamilyShareMode])

  const setDisplaySubCategories = useCallback(
    (isDisplaySubCategories: boolean) => {
      setIsDisplaySubCategories(isDisplaySubCategories)
      SessionManager.setDisplayHomeExpensesSubCategories(isDisplaySubCategories)
    },
    [],
  )

  const homeExpensesSelectedDate = useCallback(() => {
    const yearMonth = financial?.homeExpensesSelectedYearMonth
    if (!yearMonth) return undefined

    const { year, month } = parseMonthKey(yearMonth)
    const settings = getStartDateSettings()

    return getStartDate(
      year,
      month,
      settings.start,
      settings.startDateSettingOption || StartDateSettingOption.None,
    )
  }, [financial?.homeExpensesSelectedYearMonth])

  const onPressItem = useCallback(
    (item: Item) => {
      const date = homeExpensesSelectedDate()
      if (!date) return

      NavigationService.navigate('CategoryTransactions', {
        date,
        categoryId: item.categoryId,
        scope: item.scope,
        isYearly,
      })
    },
    [homeExpensesSelectedDate, isYearly],
  )

  const onPressSubCategoryItem = useCallback(
    (
      scope: 'income' | 'expense',
      categoryId: string,
      subCategoryId: string,
    ) => {
      const date = homeExpensesSelectedDate()
      if (!date) return

      NavigationService.navigate('CategoryTransactions', {
        date,
        categoryId,
        scope,
        subCategoryId,
        isYearly,
      })
    },
    [homeExpensesSelectedDate, isYearly],
  )

  const pieChartView = useCallback(
    (title: string, amount: number, data: PieChartData[]) => {
      return (
        <View
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <PieChart
            style={{ width: wp('27%'), height: wp('27%') }}
            data={data}
            outerRadius="98%"
            innerRadius="75%"
            padAngle={0.04}
          />
          <View style={{ position: 'absolute' }}>
            <Text
              style={{
                fontSize: wp('3%'),
                color: Color.NumberBlack,
                textAlign: 'center',
              }}>
              {title}
            </Text>
            <Text
              style={{
                fontSize: wp('3%'),
                fontWeight: 'normal',
                color: Color.NumberBlack,
                textAlign: 'center',
              }}>
              {amount.jpCurrency()}
            </Text>
          </View>
        </View>
      )
    },
    [],
  )

  const charts = useMemo(() => {
    const yearMonth = financial.homeExpensesSelectedYearMonth
    if (!yearMonth) return

    // const targetMonth = moment(homeExpensesSelectedDate)
    // const yyyymm = DateUtilsV1.monthKey(targetMonth)
    const pl =
      financial.monthsPL[isYearly ? yearMonth.substring(0, 4) : yearMonth]
    if (!pl) return null

    // Income
    const incomeAmounts = pl.incomeList.map((v) => v.amount)
    const incomeColors = pl.incomeList.map(
      (v) => CategoryManager.getCategoryIconInfo(v.categoryId)?.color,
    )
    const incomePieData = incomeAmounts.map((value, index) => ({
      value,
      svg: {
        fill: incomeColors[index],
      },
      key: String(index),
    }))

    // Expense
    const expenseAmounts = pl.expenseList.map((v) => Math.abs(v.amount))
    const expenseColors = pl.expenseList.map(
      (v) => CategoryManager.getCategoryIconInfo(v.categoryId)?.color,
    )
    const expensePieData = expenseAmounts.map((value, index) => ({
      value,
      svg: {
        fill: expenseColors[index],
      },
      key: String(index),
    }))

    return (
      <View style={styles.chartSection}>
        {pieChartView('収入', pl.incomeAmount, incomePieData)}
        {pieChartView('支出', pl.expenseAmount, expensePieData)}
      </View>
    )
  }, [
    financial.homeExpensesSelectedYearMonth,
    financial.monthsPL,
    isYearly,
    pieChartView,
  ])

  const listHeader = useCallback(() => {
    const date = homeExpensesSelectedDate()
    if (!date) return null

    const yyyymm = DateUtilsV1.monthKey(moment(date))
    const pl = financial.monthsPL[isYearly ? yyyymm.substring(0, 4) : yyyymm]

    return (
      <View style={{ marginTop: 10 }}>
        {userAccountType === 'user' && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginEnd: 15,
            }}>
            <GroupShareToggleButton />
          </View>
        )}
        {pl && (
          <>
            {charts}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginRight: 20,
                marginBottom: 15,
              }}>
              <View
                style={{
                  marginLeft: 20,
                  flexDirection: 'row',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <Switch
                  style={{ marginRight: 5 }}
                  onValueChange={() => {
                    setDisplaySubCategories(!isDisplaySubCategories)
                  }}
                  value={isDisplaySubCategories}
                />
                <Text
                  style={{
                    fontSize: 12,
                    color: Color.Gray,
                    fontWeight: 'bold',
                  }}>
                  小項目表示
                </Text>
              </View>
              <View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: 'bold',
                      color: Color.Gray,
                      marginEnd: 10,
                    }}>
                    収支
                  </Text>
                  <Text
                    style={{
                      fontSize: Math.min(30, wp(7)),
                      fontWeight: 'normal',
                      color: Color.number(pl.incomeAmount + pl.expenseAmount),
                    }}>
                    {(pl.incomeAmount + pl.expenseAmount).jpCurrency()}
                  </Text>
                </View>
                {/* <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Text>前日比</Text>
                  <Text style={{ color: Color.number(0) }}>
                    {(0).jpCurrency()}
                  </Text>
                </View> */}
              </View>
            </View>
          </>
        )}
      </View>
    )
  }, [
    charts,
    financial.monthsPL,
    homeExpensesSelectedDate,
    isDisplaySubCategories,
    isYearly,
    setDisplaySubCategories,
    userAccountType,
  ])

  const sections = useMemo((): SectionListData<Item>[] => {
    const yearMonth = financial?.homeExpensesSelectedYearMonth
    if (!yearMonth) return []

    const pl =
      financial?.monthsPL[isYearly ? yearMonth.substring(0, 4) : yearMonth]
    if (!pl) return []

    return [
      {
        title: '収入',
        data: pl.incomeList.map((v) => ({
          categoryId: v.categoryId,
          ratio: v.ratio,
          amount: v.amount,
          scope: 'income',
          childItems:
            isDisplaySubCategories && v.subCategories
              ? v.subCategories.map((vv) => ({
                  ...vv,
                  onPress: () => {
                    onPressSubCategoryItem(
                      'income',
                      v.categoryId,
                      vv.subCategoryId,
                    )
                  },
                }))
              : [],
        })),
      },
      {
        title: '',
        data: !pl.incomeList.length
          ? [
              {
                categoryId: '',
                ratio: 0,
                amount: 0,
                scope: undefined,
                childItems: [],
              },
            ]
          : [],
      },
      {
        title: '支出',
        data: pl.expenseList.map((v) => ({
          categoryId: v.categoryId,
          ratio: v.ratio,
          amount: v.amount,
          scope: 'expense',
          childItems:
            isDisplaySubCategories && v.subCategories
              ? v.subCategories.map((vv) => ({
                  ...vv,
                  onPress: () => {
                    onPressSubCategoryItem(
                      'expense',
                      v.categoryId,
                      vv.subCategoryId,
                    )
                  },
                }))
              : [],
        })),
      },
      {
        title: '',
        data: !pl.expenseList.length
          ? [
              {
                categoryId: '',
                ratio: 0,
                amount: 0,
                childItems: [],
              },
            ]
          : [],
      },
    ]
  }, [
    financial?.homeExpensesSelectedYearMonth,
    financial?.monthsPL,
    isDisplaySubCategories,
    isYearly,
    onPressSubCategoryItem,
  ])

  useEffect(() => {
    if (isFocused) {
      TrackingUtils.ga.pageview({
        page: `HomeExpenses-Balance-${
          userAccountType === 'family' ? 'Family' : 'User'
        }`,
        title: `${userAccountType === 'family' ? '家族' : '個人'}の家計簿 収支`,
      })
    }
  }, [isFocused, userAccountType])

  if (sections.length === 0 && !planError) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          backgroundColor: 'white',
        }}>
        <ActivityIndicator color={Color.Gray} />
      </View>
    )
  }

  const renderItem: SectionListRenderItem<Item> = ({ item }) => {
    return item.scope ? (
      <ListItem item={item} onPress={() => onPressItem(item)} />
    ) : (
      <NoDataListItem />
    )
  }

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <SectionList
          keyExtractor={(item: Item) => JSON.stringify(item)}
          renderSectionHeader={(info) =>
            info.section.title ? (
              <SectionListHeader title={info.section.title} />
            ) : null
          }
          renderItem={renderItem}
          sections={sections}
          ItemSeparatorComponent={() => ItemSeparator}
          ListHeaderComponent={listHeader}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true)
                await fetchBalance()
                setRefreshing(false)
              }}
            />
          }
          ListFooterComponent={() => <View style={{ height: 24 }} />}
        />
        {userProfile?.rank === 'free' && <AdView type="320x50" delay={500} />}
      </View>
      {planError && (
        <BlurView disableBlur={Platform.OS === 'android' && !isFocused}>
          {isLoadMovieAwardSuccess === undefined && (
            <View style={styles.absolute}>
              <ActivityIndicator color={Color.Gray} />
            </View>
          )}
        </BlurView>
      )}
      {planError && isLoadMovieAwardSuccess !== undefined && (
        <View style={[styles.absolute, { alignItems: 'center' }]}>
          <Text style={styles.premiumPlanMessage}>
            <Text style={{ color: '#ff7200' }}>プレミアムプラン</Text>
            に加入すると{'\n'}
            過去の収支を表示できます
          </Text>
          <Image
            source={require('@images/premium/premium-4.png')}
            style={{
              width: 195,
              height: Math.min(103, wp(23)),
              resizeMode: 'contain',
            }}
          />
          <AppButton
            title="詳しく見る"
            style={{
              width: wp(80),
              height: wp(12),
              marginTop: wp(4),
            }}
            textStyle={{ fontSize: wp(4), fontWeight: 'bold' }}
            onPress={() => {
              TrackingUtils.repro.track('【Tap】upper limit_pl', 'Tap')
              navigatePremiumPlanLP({ planCode: '001' })
              TrackingUtils.repro.setScreenTrackingAfterPurchase(
                '【Screen】purchase completed via upper limit_pl',
              )
            }}
          />
          <Text style={styles.premiumTextOr}>または</Text>
          <Text style={styles.premiumPlanMessage}>
            CMを1回視聴すると<Text style={{ color: '#4ba3f4' }}>無料</Text>で
            {'\n'}
            一定期間機能を利用できます
          </Text>
          <View
            style={{
              backgroundColor: '#eeeeee',
              paddingHorizontal: wp(6),
              paddingVertical: wp(1),
              borderRadius: wp(3),
              marginBottom: wp(4),
            }}>
            <Text
              style={{
                color: '#333333',
                fontSize: wp(8),
                fontWeight: 'normal',
              }}>
              ▶
            </Text>
          </View>
          {isLoadMovieAwardSuccess ? (
            <AppButton
              title="無料で使う"
              style={{
                width: wp(80),
                height: wp(12),
                backgroundColor: '#4ba3f4',
              }}
              textStyle={{ fontSize: wp(4), fontWeight: 'bold' }}
              onPress={async () => {
                await AdfurikunManager.playMovieReward((success: boolean) => {
                  if (success) {
                    AdfurikunManager.adViewingComplete(true, async () => {
                      setRefreshing(true)
                      await fetchBalance()
                      setRefreshing(false)
                    })
                  } else {
                    AdfurikunManager.adViewingComplete(false)
                  }
                })
              }}
            />
          ) : (
            <Text
              style={{
                color: 'white',
                fontSize: wp(4),
                fontWeight: 'normal',
                textAlign: 'center',
              }}>
              ただいま表示するCMがありません🙇‍♂️{'\n'}
              時間を置いて再度アクセスしてください。
            </Text>
          )}
        </View>
      )}
    </View>
  )
}

const NoDataListItem = () => (
  <Text style={{ padding: 20, color: Color.DefaultText }}>
    データありません
  </Text>
)

const ListItem: React.FC<{ item: Item } & TouchableOpacityProps> = ({
  item,
  onPress,
}) => (
  <TouchableOpacity
    style={styles.listPaymentsItem}
    key={item.categoryId}
    onPress={onPress}>
    <View style={styles.listPaymentsItemType}>
      <CategoryImage
        categoryId={item.categoryId}
        style={styles.listPaymentsItemTypeIcon}
      />
      <Text style={styles.listPaymentsItemTypeText}>
        {CategoryManager.getCategoryIconInfo(item.categoryId)?.categoryName}
      </Text>
    </View>
    <View style={styles.listPaymentsItemInfo}>
      <Text style={styles.listPaymentsItemInfoAmount}>
        {item.amount.jpCurrency()}
      </Text>
      <Text style={styles.listPaymentsItemInfoPercent}>
        {Math.round(item.ratio * 100)}%
      </Text>
      <Image
        style={styles.listPaymentsItemInfoIcon}
        source={require('@images/home/balance/icon-more.png')}
        resizeMode="contain"
      />
    </View>
    {item.childItems.map((v, index) => (
      <TouchableOpacity key={index} onPress={v.onPress}>
        <View
          style={[
            styles.listPaymentsItemType,
            { marginTop: 12, marginBottom: 3 },
          ]}>
          <View
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              marginLeft: 18,
              marginRight: 12,
              backgroundColor: CategoryManager.getCategoryIconInfo(
                item.categoryId,
              )?.color,
            }}
          />
          <Text style={[styles.listPaymentsItemTypeText, { flex: 1 }]}>
            {CategoryManager.categoryBySubCategoryId(v.subCategoryId)?.name}
          </Text>
          <Text style={styles.listPaymentsItemInfoAmount2}>
            {v.amount.jpCurrency()}
          </Text>
          <Image
            style={styles.listPaymentsItemInfoIcon}
            source={require('@images/home/balance/icon-more.png')}
            resizeMode="contain"
          />
        </View>
      </TouchableOpacity>
    ))}
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  premiumPlanMessage: {
    textAlign: 'center',
    fontSize: wp(5),
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 15,
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
  periodSection: {
    minHeight: 45,
    maxHeight: 45,
    paddingTop: 6,
    paddingHorizontal: 8,
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  chartSection: {
    flexDirection: 'row',
    height: wp('30%'),
    marginTop: 4,
  },
  dateText: {
    padding: 8,
    fontSize: 16,
    fontWeight: 'normal',
  },
  paymentSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textAmount: {
    marginRight: 24,
    color: Color.GrayVeryDark,
    fontSize: 34,
    fontWeight: 'normal',
  },
  textBalance: {
    marginTop: 14,
    marginRight: 12,
    color: Color.GrayVeryDark,
    fontSize: 15,
    fontWeight: 'normal',
  },
  scrollView: {
    height: '50%',
  },
  listPaymentsItem: {
    paddingTop: 15,
    paddingBottom: 10,
  },
  listPaymentsItemType: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listPaymentsItemTypeIcon: {
    marginLeft: 15,
    marginRight: 6,
  },
  listPaymentsItemTypeText: {
    fontSize: 17,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaymentsItemInfoIcon: {
    marginRight: 12,
    marginLeft: 8,
    width: 18,
    height: 18,
  },
  listPaymentsItemInfoPercent: {
    width: 50,
    fontSize: 17,
    fontWeight: 'normal',
    textAlign: 'right',
    color: Color.Gray,
  },
  listPaymentsItemInfoAmount: {
    fontSize: 22,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  listPaymentsItemInfoAmount2: {
    fontSize: 18,
    fontWeight: 'normal',
    color: Color.GrayVeryDark,
  },
  iconEdit: {
    resizeMode: 'contain',
    height: 20,
  },
  btnEdit: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  premiumTextOr: {
    margin: wp(3.5),
    color: 'white',
    fontSize: wp(4),
    fontWeight: 'bold',
  },
})

export default HomeExpensesCategories
