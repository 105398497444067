import { Tour, TourStep } from '../types'

export const convertTourStep = (
  spotIds: string | string[] | TourStep,
): TourStep => {
  if (typeof spotIds === 'string') {
    return { spotIds: [spotIds] }
  }
  if (Array.isArray(spotIds)) {
    return { spotIds }
  }
  return spotIds
}

export const getSpotIds = (tour: Tour, step: number): string[] | undefined => {
  if (step === 0 || step > tour.length) return undefined
  return convertTourStep(tour[step - 1]).spotIds
}
