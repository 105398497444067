import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '50vw',
    height: '50vh',
    resizeMode: 'contain',
  },
})
