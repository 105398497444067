import Alert from '@components/Alert'
import CommonHeader, {
  CommonHeaderLeftButtonType,
} from '@components/CommonHeader'
import { PremiumView } from '@components/PremiumView'
import { UserAccountType } from '@interfaces/Account'
import CategoryManager, { Category, CategoryUnit } from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent from '@lib/OsidoriEvent'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import RNProgressHud from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import { RootStackParamList } from '@navigation/Screens'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DragListView from 'react-drag-listview'
import {
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'
import { navigatePremiumPlanLP } from '../PremiumPlan'
import { AddCaregoryButton } from './AddCategoryButton'
import {
  SubCategoryListItem,
  SubCategoryListItemProps,
} from './SubCategoryListItem'

export type EditSubCategoryScreenProps = {
  category: Category
  isSortOnly: boolean
  userAccountType: UserAccountType
  isSelectable?: boolean
  leftButtonType?: CommonHeaderLeftButtonType
}

export interface ListItemProps {
  drag?: () => void
  sortable: boolean
  onPress?: () => void
  categoryUnit: CategoryUnit
  onPressEye?: (hide: boolean) => void
  disabled?: boolean
}

const EditSubCategoryScreen: React.FC<
  StackScreenProps<RootStackParamList, 'EditSubCategory'>
> = ({ route }) => {
  const isFocused = useIsFocused()

  const userAccountType = useRef(route.params.userAccountType).current
  const isSortOnly = useRef(!!route.params.isSortOnly).current
  const category = useRef(route.params.category).current
  const selectable = useRef(!!route.params.isSelectable).current

  const [editType, setEditType] = useState<'list' | 'sort'>(
    route.params.isSortOnly ? 'sort' : 'list',
  )
  const [categoryUnits, setCategoryUnits] = useState<CategoryUnit[]>(
    CategoryManager.categoryByCategoryId(userAccountType, category.categoryId)
      ?.subCategories ?? [],
  )

  const [isPurchaseCompleted, setIsPurchaseCompleted] = useState(false)

  const [isDisplayedPremiumPlanView, setIsDisplayedPremiumPlanView] =
    useState(false)
  const [premiumPlanErrorType, setPremiumPlanErrorType] = useState<
    'sort' | 'hide' | 'create'
  >()
  const [isDisplayBalloon, setIsDisplayBalloon] = useState(false)

  useEffect(() => {
    const launcSubCategoryPickerCount =
      SessionManager.getLaunchSubCategoryPickerCount() + 1
    SessionManager.setLaunchSubCategoryPickerCount(launcSubCategoryPickerCount)

    if (
      !SessionManager.isDisplayedBalloonCreateSubCategory() &&
      launcSubCategoryPickerCount >= 2
    ) {
      setIsDisplayBalloon(true)
      SessionManager.setIsDisplayedBalloonCreateSubCategory(true)
    }
  }, [])

  const onPressCancelButton = useCallback(() => {
    if (isDisplayedPremiumPlanView) {
      setIsDisplayedPremiumPlanView(false)
    }

    setEditType('list')
    setCategoryUnits(category.subCategories)
  }, [category.subCategories, isDisplayedPremiumPlanView])

  const onPressEditButton = useCallback(async () => {
    if (editType === 'list') {
      setEditType('sort')
    } else {
      // 完了
      if (
        store.getState().profile.userProfile?.rank === 'free' &&
        categoryUnits.find((v) => +v.subCategoryId && v.hide)
      ) {
        categoryUnits
          .filter((v) => +v.subCategoryId && v.hide)
          .forEach((v) => (v.hide = false))

        setPremiumPlanErrorType('hide')
        setIsDisplayedPremiumPlanView(true)
        setCategoryUnits([...categoryUnits])

        TrackingUtils.repro.track(
          '【Screen】upper limit_hide small category',
          'Screen',
        )

        TrackingUtils.ga.pageview({
          page: 'Upperlimit-HideSubCategory',
          title: '小カテゴリ非表示時のタッチ画面',
        })
        return
      }

      if (!isSortOnly) {
        setEditType('list')
      }

      try {
        RNProgressHud.show()

        await CategoryManager.updateTransactionSort({
          accountType: userAccountType,
          categoryId: category.categoryId,
          subCategories: categoryUnits,
        })
        await CategoryManager.fetchCategories({ accountType: userAccountType })

        if (isSortOnly) {
          NavigationService.goBack()
          return
        }
      } catch (error) {
        CommonDialog.showError({ error })
      } finally {
        RNProgressHud.dismiss()
      }
    }
  }, [
    category.categoryId,
    categoryUnits,
    editType,
    isSortOnly,
    userAccountType,
  ])

  const renderRightButton = useCallback(
    () => (
      <TouchableOpacity
        onPress={onPressEditButton}
        style={{
          paddingHorizontal: 15,
          justifyContent: 'center',
          height: 40,
        }}>
        <Text style={{ color: 'white' }}>
          {editType === 'list' ? '編集' : '完了'}
        </Text>
      </TouchableOpacity>
    ),
    [editType, onPressEditButton],
  )

  const renderItem = useCallback(
    (item: ListItemProps) => {
      if (item.categoryUnit.hide) return <></>
      return (
        <SubCategoryListItem
          onPress={() => {
            OsidoriEvent.emit('DidSelectSubCategory', item.categoryUnit)
            if (route.params?.leftButtonType === 'modal') {
              NavigationService.goBack()
            } else {
              NavigationService.pop(2)
            }
          }}
          selectable={selectable}
          {...item}
          drag={() => {
            if (store.getState().profile.userProfile?.rank === 'free') {
              setIsDisplayedPremiumPlanView(true)
              setPremiumPlanErrorType('sort')
              TrackingUtils.repro.track(
                '【Screen】upper limit_sort small category',
                'Screen',
              )

              TrackingUtils.ga.pageview({
                page: 'Upperlimit-SortSubCategory',
                title: '小カテゴリ並び替え時のタッチ画面',
              })
            } else {
              item.drag?.()
            }
          }}
          onPressEye={
            editType === 'sort'
              ? (hide) => {
                  Alert.alert(
                    '',
                    `「${item.categoryUnit.name}」カテゴリーを${
                      +item.categoryUnit.subCategoryId
                        ? '非表示にします。'
                        : '削除します。'
                    }\nよろしいですか？`,
                    [
                      {
                        text: 'キャンセル',
                      },
                      {
                        text: 'はい',
                        onPress: () => {
                          const index = categoryUnits.findIndex(
                            (v) =>
                              v.subCategoryId ===
                              item.categoryUnit.subCategoryId,
                          )
                          if (index === undefined) return
                          const newCategoryUnits = [...categoryUnits]
                          newCategoryUnits[index] = {
                            categoryId: item.categoryUnit.subCategoryId,
                            subCategoryId: item.categoryUnit.subCategoryId,
                            name: item.categoryUnit.name,
                            sort: item.categoryUnit.sort,
                            hide,
                            permission: item.categoryUnit.permission,
                          }
                          setCategoryUnits(newCategoryUnits)
                        },
                      },
                    ],
                  )
                }
              : undefined
          }
          sortable={editType === 'sort'}
        />
      )
    },
    [categoryUnits, editType, route.params?.leftButtonType, selectable],
  )

  // const renderItem = useCallback(
  //   ({ item, drag }: RenderItemParams<SubCategoryListItemProps>) => {
  //     if (item.categoryUnit.hide) return <></>

  //     return (
  //       <SubCategoryListItem
  //         onPress={() => {
  //           OsidoriEvent.emit('DidSelectSubCategory', item.categoryUnit)
  //           if (route.params?.leftButtonType === 'modal') {
  //             NavigationService.goBack()
  //           } else {
  //             NavigationService.pop(2)
  //           }
  //         }}
  //         selectable={selectable}
  //         {...item}
  //         drag={() => {
  //           if (store.getState().profile.userProfile?.rank === 'free') {
  //             setPremiumPlanErrorType('sort')
  //             setIsDisplayedPremiumPlanView(true)

  //             TrackingUtils.repro.track(
  //               '【Screen】upper limit_sort small category',
  //               'Screen',
  //             )
  //           } else {
  //             drag()
  //           }
  //         }}
  //         onPressEye={
  //           editType === 'sort'
  //             ? (hide) => {
  //                 Alert.alert(
  //                   '',
  //                   `「${item.categoryUnit.name}」カテゴリーを${
  //                     +item.categoryUnit.subCategoryId
  //                       ? '非表示にします。'
  //                       : '削除します。'
  //                   }\nよろしいですか？`,
  //                   [
  //                     {
  //                       text: 'キャンセル',
  //                     },
  //                     {
  //                       text: 'はい',
  //                       onPress: () => {
  //                         const index = categoryUnits.findIndex(
  //                           (v) =>
  //                             v.subCategoryId ===
  //                             item.categoryUnit.subCategoryId,
  //                         )
  //                         if (index === undefined) return
  //                         const newCategoryUnits = [...categoryUnits]
  //                         newCategoryUnits[index] = {
  //                           categoryId: item.categoryUnit.subCategoryId,
  //                           subCategoryId: item.categoryUnit.subCategoryId,
  //                           name: item.categoryUnit.name,
  //                           sort: item.categoryUnit.sort,
  //                           hide,
  //                           permission: item.categoryUnit.permission,
  //                         }
  //                         setCategoryUnits(newCategoryUnits)
  //                       },
  //                     },
  //                   ],
  //                 )
  //               }
  //             : undefined
  //         }
  //         sortable={editType === 'sort'}
  //       />
  //     )
  //   },
  //   [categoryUnits, editType, route.params?.leftButtonType, selectable],
  // )

  const reload = useCallback(async () => {
    await CategoryManager.fetchCategories({ accountType: userAccountType })
    const fetchedCategory = CategoryManager.categoryByCategoryId(
      userAccountType,
      category.categoryId,
    )
    if (fetchedCategory) {
      setCategoryUnits(fetchedCategory?.subCategories)
    }
  }, [category.categoryId, userAccountType])

  const addSubCategory = useCallback(
    async (categoryName2: string) => {
      if (categoryName2.length === 0) return

      try {
        RNProgressHud.show()
        await CategoryManager.addSubCategory({
          accountType: userAccountType,
          categoryId: category.categoryId,
          categoryName2,
        })
        await reload()
      } catch (error) {
        if (error instanceof APIError) {
          if (error.response?.errorCode === '004302') {
            setPremiumPlanErrorType('create')
            setIsDisplayedPremiumPlanView(true)

            TrackingUtils.repro.track(
              '【Screen】upper limit_create small category',
              'Screen',
            )

            TrackingUtils.ga.pageview({
              page: 'Upperlimit-AddSubCategory',
              title: '小カテゴリ追加時のタッチ画面',
            })
            return
          }
        }
        CommonDialog.showError({ error })
      } finally {
        RNProgressHud.dismiss()
      }
    },
    [category.categoryId, reload, userAccountType],
  )

  const renderHiddenItem = useCallback(
    (item: ListItemProps) => {
      return (
        <SubCategoryListItem
          {...item}
          onPressEye={(hide) => {
            Alert.alert(
              '',
              `「${item.categoryUnit.name}」カテゴリーを再表示します。\nよろしいですか？`,
              [
                {
                  text: 'キャンセル',
                },
                {
                  text: 'はい',
                  onPress: () => {
                    const index = categoryUnits.findIndex(
                      (v) =>
                        v.subCategoryId === item.categoryUnit.subCategoryId,
                    )
                    if (index === undefined) return
                    const newCategoryUnits = [...categoryUnits]
                    newCategoryUnits[index] = {
                      categoryId: item.categoryUnit.categoryId,
                      subCategoryId: item.categoryUnit.subCategoryId,
                      name: item.categoryUnit.name,
                      sort: item.categoryUnit.sort,
                      hide,
                      permission: item.categoryUnit.permission,
                    }
                    setCategoryUnits(newCategoryUnits)
                  },
                },
              ],
            )
          }}
        />
      )
    },
    [categoryUnits],
  )

  const onPressCreateSubCategory = useCallback(() => {
    Alert.prompt('小カテゴリー名', '', [
      {
        text: 'キャンセル',
        style: 'cancel',
      },
      {
        text: '追加する',
        onPress: (value) => {
          if (value) {
            addSubCategory(value)
          }
        },
      },
    ])
  }, [addSubCategory])

  const items: SubCategoryListItemProps[] = useMemo(
    () =>
      categoryUnits.map((v) => ({
        categoryUnit: v,
        sortable: editType === 'sort',
        disabled: v.permission === 1,
      })),
    [categoryUnits, editType],
  )

  const hiddenItems: SubCategoryListItemProps[] = useMemo(
    () =>
      categoryUnits
        .filter((v) => v.hide && +v.subCategoryId)
        .map((categoryUnit) => ({
          categoryUnit,
          sortable: false,
        })),
    [categoryUnits],
  )

  useEffect(() => {
    const unsubscribe1 = signalOn('PURCHASE_COMPLETE', () => {
      setIsDisplayedPremiumPlanView(false)
      if (isFocused) {
        setIsPurchaseCompleted(true)
      }
    })

    const unsubscribe2 = signalOn('CLOSED_PURCHASE_COMPLETE_MODAL', () => {
      setIsPurchaseCompleted(false)
    })

    return () => {
      unsubscribe1()
      unsubscribe2()
    }
  }, [isFocused])

  return (
    <StyledRootView>
      <CommonHeader
        title={
          isSortOnly
            ? userAccountType === 'user'
              ? '小項目の設定（個人画面）'
              : '小項目の設定（家族画面）'
            : category.name
        }
        leftButtonType={route.params?.leftButtonType}
        onPressLeftButton={
          editType === 'sort' ? onPressCancelButton : undefined
        }
        renderRightButton={
          isDisplayedPremiumPlanView ? undefined : renderRightButton
        }
      />
      {editType === 'sort' && (
        <StyledSortDescriptionText>
          右のつまみを長押しして並べ替えができます
        </StyledSortDescriptionText>
      )}
      <ScrollView style={{ flex: 1 }}>
        <DragListView
          onDragEnd={(fromIndex, toIndex) => {
            const item = items.splice(fromIndex, 1)[0]
            items.splice(toIndex, 0, item)
            setCategoryUnits(items.map((v) => v.categoryUnit))
          }}
          nodeSelector={editType === 'sort' ? '.draggable' : undefined}
          handleSelector=".draggable">
          {items.map((item) => (
            <div key={item.categoryUnit.subCategoryId} className="draggable">
              {renderItem(item)}
            </div>
          ))}
          {items.length > 0 && editType === 'sort' ? (
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  backgroundColor: Color.LightGray,
                  padding: 15,
                  color: Color.DefaultText,
                  fontSize: 12,
                  fontWeight: 'normal',
                }}>
                非表示
              </Text>
              {hiddenItems.map((item) => renderHiddenItem(item))}
              <View style={{ marginBottom: 110 }} />
            </View>
          ) : null}
        </DragListView>
      </ScrollView>

      {!isDisplayedPremiumPlanView && (
        <>
          {isDisplayBalloon && (
            <StyledBalloonView
              onPress={() => {
                setIsDisplayBalloon(false)
              }}>
              <StyledBalloonSafeAreaView>
                <StyledBalloonImage />
              </StyledBalloonSafeAreaView>
            </StyledBalloonView>
          )}
          <AddCaregoryButton
            title="＋ 小カテゴリーを追加"
            onPress={onPressCreateSubCategory}
          />
        </>
      )}
      {isDisplayedPremiumPlanView ? (
        <>
          {Platform.OS !== 'web' ? (
            <PremiumView
              title="無料プランの上限になりました"
              message={
                premiumPlanErrorType === 'create'
                  ? `※ 無料プランでは1アカウントにつき${'\n'}大小カテゴリを各1つまで作成できます。`
                  : '※ カテゴリの並び替え・非表示はプレミアムプランの機能です。'
              }
              premiumType={
                premiumPlanErrorType === 'create'
                  ? 'create_sub_category'
                  : premiumPlanErrorType === 'sort'
                  ? 'sort_category'
                  : 'hide_category'
              }
              onBackdropPress={() => {
                setIsDisplayedPremiumPlanView(false)
              }}
            />
          ) : (
            <PremiumView
              title="プレミアムプランの機能です"
              message="※ 2つ以上の小カテゴリ作成はプレミアムプランにてご利用いただけます。"
              image={require('@images/premium/limit_create_sub_category.png')}
              buttonTitle="30日間無料で試す"
              onBackdropPress={() => {
                setIsDisplayedPremiumPlanView(false)
              }}
              onPressShowDetailButton={() => {
                navigatePremiumPlanLP({ planCode: '001' })
                setIsDisplayedPremiumPlanView(false)
                setPremiumPlanErrorType(undefined)

                TrackingUtils.repro.track(
                  premiumPlanErrorType === 'hide'
                    ? '【Tap】upper limit_hide small category'
                    : premiumPlanErrorType === 'sort'
                    ? '【Tap】upper limit_sort small category'
                    : '【Tap】upper limit_create small category',
                  'Tap',
                )
                TrackingUtils.repro.setScreenTrackingAfterPurchase(
                  premiumPlanErrorType === 'hide'
                    ? '【Screen】purchase completed via upper limit_hide small category'
                    : premiumPlanErrorType === 'sort'
                    ? '【Screen】purchase completed via upper limit_sort small category'
                    : '【Screen】purchase completed via upper limit_create small category',
                )
              }}
            />
          )}
        </>
      ) : (
        isPurchaseCompleted && (
          <PremiumView
            buttonTitle={
              premiumPlanErrorType === 'create'
                ? 'さっそく追加する'
                : 'さっそくカスタマイズする'
            }
            image={
              premiumPlanErrorType === 'create'
                ? require('@images/premium/create_sub_category_modal.png')
                : require('@images/premium/sort_category_modal.png')
            }
            onPressShowDetailButton={() => {
              setIsPurchaseCompleted(false)
              sendSignal(
                'CLOSED_PURCHASE_COMPLETE_MODAL',
                premiumPlanErrorType === 'create'
                  ? 'create_sub_category'
                  : 'sort_sub_category',
              )
            }}
          />
        )
      )}
    </StyledRootView>
  )
}

const StyledRootView = styled.View({
  flex: 1,
  backgroundColor: Color.LightGray,
})

const StyledSortDescriptionText = styled.Text({
  backgroundColor: Color.LightGray,
  padding: 15,
  color: Color.DefaultText,
  fontSize: 12,
  fontWeight: 'normal',
})

const StyledBalloonView = styled.Pressable({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#00000080',
})

const StyledBalloonSafeAreaView = styled(SafeAreaView)({
  position: 'absolute',
  bottom: 60,
  paddingHorizontal: 15,
})

const StyledBalloonImage = styled.Image.attrs({
  source: require('@images/premium/balloon_create_category.png'),
  resizeMode: 'contain',
})({
  width: '100%',
  height: 'auto',
  aspectRatio: `${1029 / 541}`,
})

export default EditSubCategoryScreen
