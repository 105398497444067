import { UserAccountType } from '@interfaces/Account'

// State
export interface AccountState {
  accountMode: UserAccountType
  isFamilyShareMode: boolean
}

// Actions
interface UpdateAccountAction {
  type: 'UpdateAccountMode'
  accountMode: UserAccountType
}

interface UpdateFamilyShareModeAction {
  type: 'UpdateFamilyShareMode'
  isFamilyShareMode: boolean
}

export type AccountActions = UpdateAccountAction | UpdateFamilyShareModeAction

// Functions
export const updateAccountMode = (
  accountMode: UserAccountType,
): UpdateAccountAction => {
  return {
    type: 'UpdateAccountMode',
    accountMode,
  }
}

export const updateFamilyShareMode = (
  isFamilyShareMode: boolean,
): UpdateFamilyShareModeAction => {
  return {
    type: 'UpdateFamilyShareMode',
    isFamilyShareMode,
  }
}
