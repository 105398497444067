import API, { APIResponse } from '../index'
import { StringPurchaseConfirmProps } from './types'

export const getStripePremiumPlans = (
  planCode?: string,
): Promise<APIResponse> => {
  return API.request('GET', '/api/v2/premium-plans/stripe', { planCode }, true)
}

// STRIPE課金情報登録
export const verification = (): Promise<APIResponse> => {
  return API.request('POST', '/api/v2/purchases/stripe-verification', {}, true)
}

export const purchaseConfirm = ({
  productId,
  ...props
}: StringPurchaseConfirmProps): Promise<APIResponse> => {
  return API.request(
    'GET',
    `/stripe/purchase-confirm/${productId}`,
    props,
    true,
  )
}
