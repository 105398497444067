import store from '@redux/store'
import API, { APIResponse } from '../index'
import { PostAssessmentProps } from './types'

export const postAssessment = ({
  userAccountType = store.getState().account.accountMode,
  ...props
}: PostAssessmentProps): Promise<APIResponse> => {
  return API.request(
    'POST',
    `/api/v3/${userAccountType?.userAccountPath()}/assessment`,
    props,
    true,
  )
}

export const getAssessment = ({
  userAccountType = store.getState().account.accountMode,
  ...props
}): Promise<APIResponse> => {
  return API.request(
    'GET',
    `/api/v3/${userAccountType?.userAccountPath()}/assessment`,
    props,
    true,
  )
}
