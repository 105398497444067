import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import Avatar from '@components/Avatar'
import AvatarBackground from '@components/AvatarBackground'
import CommonHeader from '@components/CommonHeader'
import AccountManager from '@lib/AccountManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import NavigationService from '@lib/NavigationService'
import PairingManager from '@lib/PairingManager'
import ProfileManager from '@lib/ProfileManager'
import { ProfileState } from '@lib/ProfileManager/types'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React from 'react'
import {
  NativeEventSubscription,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { connect } from 'react-redux'

interface Props {
  profile: ProfileState
}

export interface SettingPairingScreenProps {
  goBack?: () => void
}

class SettingPairingScreen extends React.Component<
  Props & StackScreenProps<RootStackParamList, 'SettingPairing'>
> {
  private backHandler?: NativeEventSubscription

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.addListener(() => {
      this.goBack()
      return true
    })
  }

  componentWillUnmount() {
    this.backHandler?.remove()
  }

  private goBack = () => {
    const params = this.props.route.params
    if (params?.goBack) {
      params.goBack()
    } else {
      NavigationService.goBack()
    }
  }

  private onPressPairing = () => {
    NavigationService.navigate('InvitePairing')
  }

  private onPressCancel = () => {
    Alert.alert(
      'ペアを解除しますか？',
      `ペアを解除すると、現在ペアリングされているパートナーとの連携が解除さます。

      これに伴い、金融機関情報、明細情報、目標貯金、それらに関係する家族画面の全ての情報が削除され、復元ができなくなります。

      誤ってペアリングの解除をされないようご注意ください。`.replace(
        /^[^\S\n]+/gm,
        '',
      ),
      [
        { text: 'いいえ' },
        {
          text: 'ペアを解除',
          onPress: this.confirmCancel,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    )
  }

  private confirmCancel = () => {
    Alert.alert(
      '本当に解除しますか？',
      '本当に解除してよろしいですか？\nもう一度話し合ってみませんか？',
      [
        { text: 'いいえ' },
        {
          text: 'ペアを解除',
          onPress: this.cancelPairing,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    )
  }

  private cancelPairing = async () => {
    try {
      await PairingManager.cancel()
      AccountManager.updateAccountMode('user')
      ProfileManager.clearPartnerProfile()
      NavigationService.navigate('HomeTop')
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  render() {
    const hasPartner = !!this.props.profile.userStatus?.paired
    return (
      <View style={styles.container}>
        <CommonHeader title="パートナー設定" onPressLeftButton={this.goBack} />
        <ScrollView style={styles.container}>
          <AvatarBackground type="partner">
            <Avatar size={100} type="partner" />
            <Text style={styles.notSetup}>
              {hasPartner ? 'パートナー' : '未設定'}
            </Text>
          </AvatarBackground>
          <View style={styles.body}>
            <AppButton
              type="primary"
              onPress={this.onPressPairing}
              disabled={hasPartner}
              title="パートナーを招待する"
              style={styles.invitationButton}
            />
            <View style={styles.line} />
            <AppButton
              type="primary"
              onPress={this.onPressCancel}
              disabled={!hasPartner}
              title="ペアを解除する"
              style={styles.cancelButton}
            />
            <Text style={styles.textContent}>
              ペアを解除すると、その旨が相手に通知され、家族ホーム画面を共有することができなくなります。
            </Text>
            <Text style={styles.textContent}>
              ペアを解除すると、家族ページに登録した口座情報、振り分けを行った取引データ等、各情報が削除され、閲覧できなくなります。
            </Text>
            <Text style={styles.textContent}>
              ペアを解除すると、共同で設定した目標が削除されます。
            </Text>
          </View>
        </ScrollView>
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    profile: state.profile,
  }
}

export default connect(mapStateToProps)(SettingPairingScreen)

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F7F7F7',
  },
  iconBack: {
    width: 24,
    height: 24,
  },
  title: {
    color: Color.White,
    fontSize: 20,
    fontWeight: 'bold',
  },
  imageTop: {
    width: '100%',
    height: 175,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarContainer: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: Color.White,
    justifyContent: 'center',
    alignItems: 'center',
  },
  warpAvatar: {
    width: 95,
    height: 95,
    borderRadius: 50,
    backgroundColor: '#E0E0E0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notSetup: {
    color: Color.White,
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 11,
  },
  body: {
    flex: 1,
    paddingHorizontal: 20,
  },
  invitationButton: {
    marginTop: 40,
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: '#E0E0E0',
    marginTop: 30,
  },
  cancelButton: {
    marginTop: 28,
  },
  textContent: {
    color: Color.Gray,
    fontSize: 12,
    fontWeight: 'normal',
    marginTop: 15,
  },
})
