import AppButton, {
  hideAppButtonIndicator,
  showAppButtonIndicator,
} from '@components/AppButton'
import CategoryImage from '@components/CategoryImage'
import CommonHeader from '@components/CommonHeader'
import EllipsizeTextInput from '@components/EllipsizeTextInput'
import { PremiumView } from '@components/PremiumView'
import { UserAccountType } from '@interfaces/Account'
import CategoryManager from '@lib/CategoryManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import { sendSignal, signalOn } from '@lib/OsidoriSignal'
import TrackingUtils from '@lib/TrackingUtils'
import { APIError } from '@lib/api'
import AccountTracker from '@lib/api/AccountTracker'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import React from 'react'
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { connect } from 'react-redux'
import { PremiumView2 } from './EditCategory/functions'
import { navigatePremiumPlanLP } from './PremiumPlan/index'

interface State {
  categoryName: string
  customIconName: string
  color: string
  isDisplayedPremiumPlanView: boolean
  isPurchaseCompleted: boolean
}

export interface CreateCategoryTopScreenNavigationParams {
  userAccountType: UserAccountType
}

class CreateCategoryTopScreen extends React.Component<
  StackScreenProps<RootStackParamList, 'CreateCategoryTop'>,
  State
> {
  state: State = {
    categoryName: '',
    customIconName: '39',
    color: '#B9BDC1',
    isDisplayedPremiumPlanView: false,
    isPurchaseCompleted: false,
  }

  private userAccountType!: UserAccountType

  private purchaseCompletedListener?: () => void = undefined
  private closedPurchaseCompletedModalListener?: () => void = undefined

  componentDidMount() {
    this.userAccountType = this.props.route.params.userAccountType

    this.purchaseCompletedListener = signalOn('PURCHASE_COMPLETE', () => {
      if (this.props.navigation.isFocused()) {
        this.setState({
          isPurchaseCompleted: true,
          isDisplayedPremiumPlanView: false,
        })
      }
    })

    this.closedPurchaseCompletedModalListener = signalOn(
      'CLOSED_PURCHASE_COMPLETE_MODAL',
      () => {
        this.setState({
          isPurchaseCompleted: false,
        })
      },
    )
  }

  componentWillUnmount() {
    this.purchaseCompletedListener?.()
    this.closedPurchaseCompletedModalListener?.()
  }

  private renderItem = (props: {
    customIconName?: string
    color?: string
    title?: string
    onPress?: () => void
    columnMargin?: number
  }) => {
    const { customIconName: categoryIconName, title, color, onPress } = props
    if (!categoryIconName) {
      // color
      return (
        <TouchableOpacity
          onPress={onPress}
          style={[styles.wrapContent, { marginRight: props.columnMargin }]}>
          <Text style={styles.content}>{title}</Text>
          <View
            style={{
              borderRadius: 3,
              backgroundColor: color,
              width: 22,
              height: 22,
            }}
          />
          <Image
            source={require('@images/arrow/icon-arrow-white-small.png')}
            style={{
              tintColor: Color.Gray,
              marginLeft: 5,
              width: 19,
              height: 19,
            }}
          />
        </TouchableOpacity>
      )
    } else {
      return (
        <TouchableOpacity
          onPress={onPress}
          style={[styles.wrapContent, { marginRight: props.columnMargin }]}>
          <Text style={styles.content}>{title}</Text>
          <CategoryImage
            categoryId={categoryIconName}
            color={this.state.color}
          />
          <Image
            source={require('@images/arrow/icon-arrow-white-small.png')}
            style={{
              tintColor: Color.Gray,
              marginLeft: 5,
              width: 19,
              height: 19,
            }}
          />
        </TouchableOpacity>
      )
    }
  }

  private onPressSaveButton = async () => {
    const { color, customIconName, categoryName } = this.state
    if (!color || !customIconName || categoryName.trim().length === 0) return

    showAppButtonIndicator('save')

    try {
      const response = await AccountTracker.createCategory({
        categoryName,
        icon: customIconName,
        color,
        userAccountType: this.userAccountType,
        categoryType: 'expense',
      })
      if (response.ok) {
        CategoryManager.fetchCategories({ accountType: this.userAccountType })
        CommonDialog.showMessage(
          '大カテゴリーを作成しました。',
          NavigationService.goBack,
        )
      } else {
        throw new APIError(response)
      }
    } catch (error) {
      if (error instanceof APIError) {
        if (error.response?.errorCode === '004301') {
          this.setState({ isDisplayedPremiumPlanView: true })
          TrackingUtils.repro.track(
            '【Screen】upper limit_create big category',
            'Screen',
          )

          TrackingUtils.ga.pageview({
            page: 'Upperlimit-AddCategory',
            title: '大カテゴリ追加時のタッチ画面',
          })
          return
        }
      }
      CommonDialog.showError({ error })
    } finally {
      hideAppButtonIndicator('save')
    }
  }

  private iconHandler = (customIconName: string) => {
    this.setState({ customIconName })
  }

  private colorHandler = (color: string) => {
    this.setState({ color })
  }

  render() {
    return (
      <View style={styles.container}>
        <CommonHeader title="大カテゴリーの追加" />
        <View style={{ flex: 1 }}>
          <ScrollView>
            <View style={[styles.wrapItem, { marginTop: 20 }]}>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: '#3A3A3A',
                  marginBottom: 10,
                }}>
                カテゴリー名
              </Text>
              <EllipsizeTextInput
                value={this.state.categoryName}
                onChangeText={(categoryName) => {
                  this.setState({ categoryName })
                }}
                placeholder="カテゴリー"
                style={styles.ellipsizeTextInput}
                containerStyle={{ height: 35 }}
              />
            </View>
            <View style={styles.wrapItem}>
              {this.renderItem({
                customIconName: this.state.customIconName,
                color: this.state.color,
                title: 'アイコン',
                onPress: () => {
                  const params = {
                    iconHandler: this.iconHandler,
                  }
                  NavigationService.navigate('CategoryIconPicker', params)
                },
              })}
              {this.renderItem({
                title: 'カラー',
                color: this.state.color,
                onPress: () => {
                  const params = {
                    colorHandler: this.colorHandler,
                  }
                  NavigationService.navigate('CategoryColorPicker', params)
                },
              })}
            </View>
            {/* 保存ボタン */}
            <View style={styles.wrapButton}>
              <AppButton
                title="保存する"
                key="SaveButton"
                onPress={this.onPressSaveButton}
                disabled={this.state.categoryName.trim().length === 0}
                loadingKey="save"
                // style={{ flex: 1 }}
              />
            </View>
          </ScrollView>
          {this.state.isDisplayedPremiumPlanView ? (
            <>
              {Platform.OS !== 'web' ? (
                <PremiumView
                  showTopMargin={false}
                  title="無料プランの上限になりました"
                  message={`※ 無料プランでは1アカウントにつき${'\n'}大小カテゴリを各1つまで作成できます。`}
                  premiumType="create_category"
                />
              ) : (
                PremiumView2({
                  image: require('@images/premium/limit_create_category.png'),
                  onPressShowDetailButton: () => {
                    navigatePremiumPlanLP({ planCode: '001' })
                    this.setState({ isDisplayedPremiumPlanView: false })
                    TrackingUtils.repro.track(
                      '【Tap】upper limit_create big category',
                      'Tap',
                    )
                    TrackingUtils.repro.setScreenTrackingAfterPurchase(
                      '【Screen】purchase completed via upper limit_create big category',
                    )
                  },
                })
              )}
            </>
          ) : (
            this.state.isPurchaseCompleted && (
              <PremiumView
                showTopMargin={false}
                buttonTitle="さっそく追加する"
                image={require('@images/premium/create_category_modal.png')}
                onPressShowDetailButton={() => {
                  this.setState({ isPurchaseCompleted: false })
                  sendSignal(
                    'CLOSED_PURCHASE_COMPLETE_MODAL',
                    'create_category',
                  )
                }}
              />
            )
          )}
        </View>
      </View>
    )
  }
}

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    userProfile: state.profile.userProfile,
    account: state.account,
  }
}

export default connect(mapStateToProps)(CreateCategoryTopScreen)

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrapItem: {
    // flex: 1,
    paddingHorizontal: 20,
    paddingTop: 8,
  },
  wrapContent: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingTop: 30,
    paddingBottom: 10,
    // flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    color: '#3A3A3A',
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold',
  },
  wrapButton: {
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginTop: 50,
    marginBottom: 25,
  },
  ellipsizeTextInput: {
    flex: 1,
    color: '#3A3A3A',
    fontSize: 16,
    fontWeight: 'normal',
    // height: 35,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
})
