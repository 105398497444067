import Alert from '@components/Alert'
import CommonHeader from '@components/CommonHeader'
import { TabBarOptions } from '@constants'
import { PayoffManager } from '@lib/api/Payoff'
import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import OsidoriEvent, { OsidoriEventName } from '@lib/OsidoriEvent'
import { RootStackParamList } from '@navigation/Screens'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { StackScreenProps } from '@react-navigation/stack'
import {
  setPayoff,
  setPayoffDisplaySettleType,
  setPayoffTransactions,
} from '@Screen/Main/Home/Payoff/slice'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Image, Text, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import PayoffContentsScreen from './PayoffContentsScreen'
import PayoffTransactionsScreen from './PayoffTransactionsScreen'
import { RootContainer } from './styles'

const Tab = createMaterialTopTabNavigator()

// const tabBarStyles: MaterialTopTabBarOptions = {
//   activeTintColor: Color.Green,
//   inactiveTintColor: '#999',
//   indicatorStyle: {
//     backgroundColor: Color.Green,
//     height: 1,
//   },
//   labelStyle: {
//     fontWeight: '600',
//     fontSize: 15,
//   },
//   style: {
//     elevation: 0,
//     backgroundColor: 'white',
//     borderBottomWidth: 1,
//     borderBottomColor: 'rgba(0,0,0,0.1)',
//   },
// }

const PayoffScreen: React.FC<
  StackScreenProps<RootStackParamList, 'Payoff'>
> = ({ route }) => {
  const {
    displaySettleType = 'settled',
    payoffId,
    transactionId,
  } = route.params

  const dispatch = useDispatch()

  useEffect(() => {
    const eventId = OsidoriEvent.addListener('ClosePayoffScreen', () => {
      if (route.params?.onSettled) {
        route.params.onSettled()
      } else {
        NavigationService.goBack()
      }
    })
    return () => {
      OsidoriEvent.removeListener(eventId)
    }
  }, [route.params])

  useEffect(() => {
    dispatch(setPayoffDisplaySettleType({ displaySettleType }))

    if (displaySettleType === 'notSettled') {
      dispatch(
        setPayoffTransactions({
          transactions: route.params.transactions || [],
        }),
      )
    } else if (payoffId || transactionId) {
      // transactionIdは後で消す
      PayoffManager.fetchPayoffDetail({
        payoffId: payoffId || transactionId || 0,
      })
    }

    return () => {
      dispatch(setPayoffDisplaySettleType({ displaySettleType: undefined }))
      dispatch(setPayoff({ payoff: undefined }))
      dispatch(setPayoffTransactions({ transactions: undefined }))
    }
  }, [
    dispatch,
    displaySettleType,
    payoffId,
    route.params?.transactions,
    transactionId,
  ])

  const title = useMemo(
    () =>
      route.params?.displaySettleType === 'notSettled' ? '精算' : '精算済み',
    [route.params?.displaySettleType],
  )

  const onPressPayoffCancelButton = useCallback(async () => {
    try {
      if (!payoffId) return
      await PayoffManager.deletePayoff({ payoffId })
      OsidoriEvent.emit(OsidoriEventName.DidCancelSettlement)
    } catch (error) {
      CommonDialog.showError({ error })
    }
    CommonDialog.show('', '精算を解除しました。', NavigationService.goBack)
  }, [payoffId])

  const onPressPayoffCancelConfirmButton = useCallback(() => {
    Alert.alert(
      '精算済みの取引を解除する',
      '精算済みの取引を解除すると、作成した精算を削除し、元の状態に戻されます。',
      [
        { text: '戻る', style: 'cancel' },
        {
          text: '解除する',
          onPress: onPressPayoffCancelButton,
        },
      ],
    )
  }, [onPressPayoffCancelButton])

  const headerRightButton = useMemo(() => {
    return () =>
      displaySettleType === 'settled' ? (
        <TouchableOpacity
          style={{
            alignItems: 'center',
            paddingTop: 4,
            paddingHorizontal: 15,
            paddingBottom: 4,
            // backgroundColor: 'red',
          }}
          onPress={onPressPayoffCancelConfirmButton}>
          <Image
            source={require('@images/icons/icon-payoff-cancel.png')}
            style={{ width: 18, height: 18 }}
          />
          <Text
            style={{
              color: 'white',
              fontSize: 10,
              fontWeight: 'bold',
              marginTop: 2,
            }}>
            精算取消
          </Text>
        </TouchableOpacity>
      ) : null
  }, [displaySettleType, onPressPayoffCancelConfirmButton])

  return (
    <RootContainer>
      <CommonHeader title={title} renderRightButton={headerRightButton} />
      <Tab.Navigator
        initialRouteName={route.params?.tab}
        backBehavior="none"
        tabBarOptions={TabBarOptions}>
        <Tab.Screen
          name="PayoffContents"
          component={PayoffContentsScreen}
          options={{ title: '精算内容' }}
        />
        <Tab.Screen
          name="PayoffTransactions"
          component={PayoffTransactionsScreen}
          options={{ title: '精算内容' }}
        />
      </Tab.Navigator>
    </RootContainer>
  )
}

export default PayoffScreen
