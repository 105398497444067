import { UserAccountType } from '@interfaces/Account'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BudgetCommonState, BudgetReduxState } from './types'

export type State = BudgetReduxState

type Reducer = {
  setBudget: (
    state: State,
    action: PayloadAction<{
      userAccountType: UserAccountType
      year?: number
      month?: number
      budget?: BudgetCommonState
    }>,
  ) => void
}

const initialState: State = {
  user: {},
  family: {},
}

const slice = createSlice<State, Reducer>({
  name: 'budget',
  initialState,
  reducers: {
    setBudget: (state, { payload }) => {
      const { userAccountType, year, month, budget } = payload

      if (!year) {
        state[userAccountType] = {}
      } else {
        if (month) {
          const yyyymm = `${year}${('0' + month).slice(-2)}`
          if (budget) {
            state[userAccountType][yyyymm] = { ...budget }
          } else {
            delete state[userAccountType][yyyymm]
          }
        } else {
          if (budget) {
            state[userAccountType][`${year}`] = { ...budget }
          } else {
            delete state[userAccountType][`${year}`]
          }
        }
      }
    },
  },
})

export const { setBudget } = slice.actions
export const reducer = slice.reducer
