import { forwardRef, useState } from 'react'
import {
  TextInput,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native'
import styled from 'styled-components/native'
import EyeImage from './EyeImage'

const StyledTouchableOpacity = styled(TouchableOpacity)`
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  justify-content: center;
`

const PasswordInput = forwardRef<TextInput, TextInputProps>(
  ({ style: styles, ...props }, ref) => {
    const [isDisplayPassword, setIsDisplayPassword] = useState(false)

    const onPressEye = () => setIsDisplayPassword(!isDisplayPassword)

    const style = (Array.isArray(styles) ? styles[0] : styles) as TextStyle

    const {
      margin,
      marginVertical,
      marginHorizontal,
      marginBottom,
      marginEnd,
      marginLeft,
      marginStart,
      marginTop,
      marginRight,
      position,
      top,
      right,
      bottom,
      left,
      ...textInputStyles
    } = style

    return (
      <View
        style={{
          margin,
          marginVertical,
          marginHorizontal,
          marginBottom,
          marginEnd,
          marginLeft,
          marginStart,
          marginTop,
          marginRight,
          position,
          top,
          right,
          bottom,
          left,
        }}>
        <TextInput
          style={textInputStyles}
          ref={ref}
          {...props}
          secureTextEntry={!isDisplayPassword}
        />
        <StyledTouchableOpacity onPress={onPressEye}>
          <EyeImage on={isDisplayPassword} />
        </StyledTouchableOpacity>
      </View>
    )
  },
)

export default PasswordInput
