import IconImage from '@components/IcomImage'
import Color from '@lib/Color'
import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

export type ShowMoreButtonButtonProps = TouchableOpacityProps

const ShowMoreButton = (props: ShowMoreButtonButtonProps) => (
  <StyledRootView>
    <StyledTouchableOpacity {...props}>
      <StyledText>もっと見る</StyledText>
      <StyledArrowDown />
    </StyledTouchableOpacity>
  </StyledRootView>
)

const StyledRootView = styled.View`
  padding-top: 10px;
  padding-bottom: 15px;
  flex-direction: row;
  justify-content: center;
`

const StyledTouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 5px;
`

const StyledText = styled.Text`
  font-size: 14px;
  font-weight: normal;
  color: ${Color.TextBlack};
`

const StyledArrowDown = styled(IconImage).attrs({
  name: 'ArrowDown',
})({
  marginLeft: 5,
})

export default ShowMoreButton
