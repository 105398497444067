import Color from '@lib/Color'
import { FAIcon } from '@lib/FAIcon'
import styled from 'styled-components/native'

export const StyledTouchableOpacity = styled.TouchableOpacity`
  padding-horizontal: 16px;
  background-color: ${Color.White};
  height: 44px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StyledIconImage = styled.Image`
  width: 20px;
  height: 20px;
  resize-mode: contain;
`

export const StyledText = styled.Text`
  flex: 1;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

export const StyledArrowImage = styled.Image`
  tint-color: ${Color.Gray};
`

export const StyledGrayoutView = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eeeeee60;
`

export const StyledText1Container = styled.View`
  width: 120px;
  // margin-left: 8px;
  flex-direction: row;
  align-items: center;
`

export const StyledQuestionIcon = styled(FAIcon).attrs({
  color: Color.Gray,
})`
  margin-left: 10px;
`

export const StyledText1 = styled.Text`
  font-size: 11px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const StyledText2 = styled.Text`
  flex: 1;
  margin-left: 8px;
  font-size: 11px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

export const StyledPlaceHolderText = styled.Text`
  flex: 1;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  color: rgb(200, 200, 200);
`

export const StyledTextInput = styled.TextInput`
  flex: 1;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  padding-vertical: 10px;
  color: ${Color.DefaultText};
  // background-color: red;
`
