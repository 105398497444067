import Alert from '@components/Alert'
import AppButton from '@components/AppButton'
import CommonHeader, {
  CommonHeaderLeftButtonType,
} from '@components/CommonHeader'
import AccountManager from '@lib/AccountManager'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import ProgressHUD from '@lib/ProgressHUD'
import SessionManager from '@lib/SessionManager'
import { WithdrawReason } from '@lib/api/User'
import { updateRootScreen } from '@redux/actions'
import store from '@redux/store'
import React from 'react'
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { setTabBarVisible } from './redux/actions'

interface Props {}

interface State {
  screenMode: 'initial' | 'completed'
  reasonId?: number
  reasonDetail: string
  leftButtonType: CommonHeaderLeftButtonType
  isEditableReasonDetail: boolean
  withdrawReasons: WithdrawReason[]
}

class WithdrawScreen extends React.Component<Props, State> {
  state: State = {
    screenMode: 'initial',
    reasonDetail: '',
    leftButtonType: 'stack',
    isEditableReasonDetail: false,
    withdrawReasons: [],
  }

  async componentDidMount() {
    try {
      ProgressHUD.show()
      const withdrawReasons = await AccountManager.getWithdrawReasons()
      this.setState({ withdrawReasons })
    } catch (error) {
      CommonDialog.showError({ error, onPress: NavigationService.goBack })
    } finally {
      ProgressHUD.dismiss()
    }
  }

  private onPressConfirmButton = () => {
    Alert.alert(
      '本当に退会しますか？',
      '退会するとこれまでのデータがすべて削除され、復元できなくなります。',
      [
        { text: 'OK', onPress: this.onPressWithdrawOkButton },
        { text: 'キャンセル', style: 'cancel' },
      ],
    )
  }

  private onPressWithdrawOkButton = async () => {
    try {
      ProgressHUD.show()

      const reason = this.state.reasonDetail.trim()
      await AccountManager.withdraw({
        userCancelQuestionId: this.state.reasonId ?? 0,
        userCancelReason: reason,
      })
      HardwareBackPressHandler.disableBackPress()
      store.dispatch(setTabBarVisible(false))
      SessionManager.clearAll()
      SessionManager.dump() // debug
      this.setState({
        screenMode: 'completed',
        leftButtonType: 'hidden',
      })
    } catch (error) {
      CommonDialog.showError({ error })
    } finally {
      ProgressHUD.dismiss()
    }
  }

  canWithdraw = () => {
    return (
      this.state.reasonId !== undefined &&
      this.state.reasonDetail.trim().length > 0
    )
  }

  render() {
    const { screenMode } = this.state
    return (
      <View style={styles.container}>
        <CommonHeader
          leftButtonType={this.state.leftButtonType}
          title={screenMode === 'initial' ? '退会' : '退会の完了'}
        />
        {screenMode === 'initial' && (
          <ScrollView style={styles.container}>
            <View style={styles.body}>
              {/* ヘッダ説明部分 */}
              <View
                style={{
                  backgroundColor: Color.LightGray,
                  padding: 20,
                }}>
                <Text
                  style={{
                    color: '#3a3a3a',
                    fontSize: 17,
                    fontWeight: 'bold',
                    alignSelf: 'center',
                  }}>
                  退会のお手続き
                </Text>
                <Text
                  style={{
                    color: '#3a3a3a',
                    fontSize: 12,
                    fontWeight: 'normal',
                    marginTop: 14,
                  }}>
                  これまでOsidOriのご利用、誠にありがとうございました。
                  今後のサービス向上のため、お客様のご意見を承りたく、アンケート回答の上、退会手続きをお願いいたします。
                </Text>
              </View>
              {/* 退会される理由を1つお選びください */}
              <View style={{ margin: 20, flexDirection: 'row' }}>
                <Text
                  style={{
                    color: '#3a3a3a',
                    fontSize: 15,
                    fontWeight: 'bold',
                  }}>
                  退会される理由を1つお選びください
                </Text>
                <RequiredMark
                  style={Platform.select({
                    ios: { left: 3, top: 1 },
                    android: { left: 3, top: 3 },
                  })}
                />
              </View>
              <View>
                {this.state.withdrawReasons.map((v) => (
                  <TouchableOpacity
                    key={v.userCancelQuestionId}
                    style={styles.item}
                    onPress={() => {
                      if (Platform.OS === 'ios') {
                        this.setState({ reasonId: v.userCancelQuestionId })
                      } else {
                        this.setState({ reasonId: undefined }, () => {
                          // undefinedに一度するのは、Androidでテキストの行数が変わらない場合、必須マーク位置が変わらない問題の対応
                          this.setState({ reasonId: v.userCancelQuestionId })
                        })
                      }
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                      <View style={styles.row}>
                        <Text style={styles.textItem}>{v.cancelReason}</Text>
                      </View>
                      <CheckMark
                        selected={
                          this.state.reasonId === v.userCancelQuestionId
                        }
                      />
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
              <View style={{ padding: 20 }}>
                {this.state.reasonId && (
                  <>
                    <View>
                      <Text
                        style={{
                          color: '#3a3a3a',
                          fontSize: 13,
                          fontWeight: 'bold',
                        }}>
                        {
                          this.state.withdrawReasons.find(
                            (v) =>
                              v.userCancelQuestionId === this.state.reasonId,
                          )?.freeAreaTitle
                        }
                        <RequiredMark
                          style={Platform.select({
                            ios: { left: 3, top: 5 },
                            android: { left: 3, top: 2 },
                          })}
                        />
                      </Text>
                    </View>
                    <TextInput
                      value={this.state.reasonDetail}
                      placeholder="内容を入力してください"
                      placeholderTextColor={Color.PlaceHolderText}
                      style={[
                        styles.textInput,
                        this.state.reasonId === undefined
                          ? { backgroundColor: Color.LightGray }
                          : {},
                      ]}
                      multiline={true}
                      onChangeText={(text: string) =>
                        this.setState({ reasonDetail: text })
                      }
                      editable={this.state.reasonId !== undefined}
                    />
                  </>
                )}
                <AppButton
                  onPress={this.onPressConfirmButton}
                  title="OsidOriを退会する"
                  style={styles.button}
                  disabled={!this.canWithdraw()}
                />
              </View>
            </View>
          </ScrollView>
        )}
        {screenMode === 'completed' && (
          <View style={{ flex: 1, padding: 20 }}>
            <Text style={styles.textNormal}>退会手続きが完了しました。</Text>
            <Text style={styles.textNormal}>
              ご利用いただき、誠に有難うございました。
              またお会いできる日を従業員一同心よりお待ちしております。
            </Text>
            <Text style={styles.textAttention}>
              ※ご注意{'\n'}
              OsidOriを退会しても、プレミアムプランは自動的には解約されません。
              {'\n'}
              プレミアムプランをご契約の方は、別途AppleStore、Google
              Playよりサブスクリプションの解約を実施してください。
            </Text>
            <AppButton
              type="primary"
              onPress={() => store.dispatch(updateRootScreen('Splash'))}
              title="OsidOriアプリ トップへ"
              style={styles.goToTopButton}
            />
          </View>
        )}
      </View>
    )
  }
}

const RequiredMark = (props: { style?: ViewStyle }) => {
  return (
    <View>
      <View
        style={[
          {
            // paddingVertical: 2,
            paddingHorizontal: 4,
            backgroundColor: Color.Orange,
            justifyContent: 'center',
            borderRadius: 3,
            height: 15,
            // marginLeft: 8,
          },
          props.style,
        ]}>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 'bold',
            color: Color.White,
          }}>
          必須
        </Text>
      </View>
    </View>
  )
}

const CheckMark = (props: { selected: boolean }) => {
  return (
    <Image
      source={(props.selected ? Icon.CheckMarkOn : Icon.CheckMarkOff) as never}
      style={{ width: 20, height: 20 }}
      resizeMode="contain"
    />
  )
}

export default WithdrawScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.LightGray,
  },
  iconBack: {
    width: 24,
    height: 24,
  },
  title: {
    color: Color.White,
    fontSize: 20,
    fontWeight: 'bold',
  },
  body: {
    // flex: 1,
    backgroundColor: Color.White,
    // height: '100%',
  },
  textNormal: {
    color: Color.DefaultText,
    marginBottom: 15,
  },
  textAttention: {
    color: Color.Red,
    marginBottom: 15,
  },
  textWarning: {
    color: Color.Gray,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 8,
    marginBottom: 8,
    borderTopWidth: 1,
    borderTopColor: Color.GrayVeryLight,
    borderBottomWidth: 1,
    borderBottomColor: Color.GrayVeryLight,
    paddingTop: 12,
    paddingBottom: 12,
    textAlign: 'center',
  },
  textBlue: {
    color: Color.Blue,
  },
  textRed: {
    color: Color.Red,
  },
  textBold: {
    fontWeight: '600',
  },
  textInput: {
    borderWidth: 1,
    borderColor: '#cccccc',
    marginTop: 20,
    padding: 8,
    height: 200,
    color: Color.DefaultText,
    textAlignVertical: 'top',
    borderRadius: 5,
  },
  button: {
    marginTop: 40,
    marginBottom: 30,
  },
  buttonText: {
    textAlign: 'center',
    color: Color.Primary,
  },
  goToTopButton: {
    marginTop: 40,
  },
  item: {
    padding: 20,
    height: 42,
    justifyContent: 'center',
    borderBottomColor: Color.LightGray,
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLabel: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  textItem: {
    fontSize: 15,
    fontWeight: 'normal',
    color: '#3a3a3a',
    paddingRight: 10,
  },
})
