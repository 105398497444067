import styled from 'styled-components/native'

export const StyledAbsoluteView = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const StyledPlanErrorView = styled(StyledAbsoluteView)`
  background-color: #000000a0;
  position: absolute;
  top: ${wp(33)}px;
  right: 0;
  bottom: 0;
  left: 0;
`

export const PremiumContainerView = styled.View`
  padding-bottom: 20px;
  flex: 1;
  padding-horizontal: 30px;
  justify-content: center;
`

export const PremiumTitleText = styled.Text`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
`

export const PremiumText = styled.Text`
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  line-height: 18px;
`
