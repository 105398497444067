import { Image } from '@components'
import CommonHeader from '@components/CommonHeader'
import DatePicker from '@components/DatePicker'
import RootView from '@components/RootView'
import AccountManager from '@lib/AccountManager'
import { FPManager, FPProfiles } from '@lib/api/FP'
import * as Buttons from '@lib/Buttons'
import Color from '@lib/Color'
import CommonDialog from '@lib/CommonDialog'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import { updateRootScreen } from '@redux/actions'
import store from '@redux/store'
import moment from 'moment'
import React, { Component } from 'react'
import {
  NativeEventSubscription,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

interface State {
  datePickerVisible: boolean
  marrigeDatePickerVisible: boolean
  birthdayPickerVisible: boolean
  date?: string
  gender: string
  hasBaby: boolean
  married: boolean
  marrigeDate?: string
  children: ({ birthday: string } | undefined)[]
  selectedBirthdayIndex: number
  profiles?: FPProfiles // アップデート時に利用
}

class UserInfoDiagnosisScreen extends Component<
  StackScreenProps<RootStackParamList, 'UserInfoDiagnosis'>,
  State
> {
  private backHandler?: NativeEventSubscription
  private scrollView = React.createRef<ScrollView>()

  state: State = {
    date: undefined,
    datePickerVisible: false,
    marrigeDatePickerVisible: false,
    birthdayPickerVisible: false,
    gender: 'female',
    hasBaby: false,
    married: true,
    children: [undefined],
    selectedBirthdayIndex: 0,
  }

  private getProfiles = async () => {
    try {
      const userProfile = await ProfileManager.fetchUserProflie()
      const gender = userProfile.gender === 1 ? 'male' : 'female'
      const birthday = userProfile.birthday ? userProfile.birthday : undefined

      const profiles = await FPManager.getProfiles()

      const childrenArray = profiles.children ? profiles.children : []
      const hasBaby = childrenArray.length > 0
      const children = hasBaby ? childrenArray : [undefined]
      const married = !!profiles.married
      const marrigeDate = profiles.marrigeDate
        ? moment(profiles.marrigeDate).format('YYYY-MM-DD')
        : undefined

      this.setState({
        date: birthday,
        gender,
        hasBaby,
        married,
        marrigeDate,
        children,
        profiles,
      })
    } catch (error) {
      CommonDialog.showError({ error, onPress: NavigationService.goBack })
    }
  }

  componentDidMount() {
    this.backHandler = HardwareBackPressHandler.onPressToExitApp()

    if (store.getState().root.rootScreen !== 'Welcome') {
      this.getProfiles()
    }

    TrackingUtils.ga.pageview({
      page: 'UserInfoDianosis-2',
      title: '生年月日等の質問画面',
    })
  }

  componentWillUnmount() {
    this.backHandler?.remove()
  }

  private hideDateTimePicker = () => {
    this.setState({ datePickerVisible: false })
  }

  private onPressDatePicker = () => {
    this.setState({ datePickerVisible: true })
  }

  private hideMarrigeDateTimePicker = () => {
    this.setState({ marrigeDatePickerVisible: false })
  }

  private onPressMarrigeDatePicker = () => {
    this.setState({ marrigeDatePickerVisible: true })
  }

  private hideBirthdayPicker = () => {
    this.setState({ birthdayPickerVisible: false })
  }

  private onPressBirthdayPicker = (index: number) => {
    this.setState({ birthdayPickerVisible: true, selectedBirthdayIndex: index })
  }

  private onDateConfirm = (date: Date) => {
    this.setState({
      datePickerVisible: false,
      date: moment(date).format('YYYY-MM-DD'),
    })
  }

  private onMarrigeDateConfirm = (date: Date) => {
    this.setState({
      marrigeDatePickerVisible: false,
      marrigeDate: moment(date).format('YYYY-MM-DD'),
    })
  }

  private onBirthdayConfirm = (date: Date) => {
    const children = this.state.children
    const birthday = {
      birthday: moment(date).format('YYYY-MM-DD'),
    }
    children[this.state.selectedBirthdayIndex] = birthday
    this.setState({
      birthdayPickerVisible: false,
      children,
    })
  }

  private goDiagnosisResult = () => {
    const resultType = this.props.route.params?.resultType

    if (resultType) {
      NavigationService.navigate('DiagnosisResult', {
        resultType,
      })
    } else {
      // UIBank版で個人を選択した場合はresultは0
      AccountManager.updateAccountMode('user')

      if (store.getState().root.rootScreen === 'Welcome') {
        store.dispatch(updateRootScreen('Main'))
      } else {
        // NavigationService.navigate('FPHomeDiagnosis')

        // バグ（タブの中身の画面違う）
        // NavigationService.navigate('FP', {
        //   screen: 'FPHomeDiagnosis',
        // })

        // ひとまずこちらに戻す
        NavigationService.navigate('FP', {
          screen: 'FPWebViewScreen',
        })
      }
    }
  }

  private onSkip = () => {
    this.goDiagnosisResult()
    // if (store.getState().root.rootScreen === 'Welcome') {
    //   this.goDiagnosisResult()
    // } else {
    //   NavigationService.navigate('HouseholdDiagnosis')
    // }
  }

  private onSubmit = async () => {
    try {
      const updated = store.getState().root.rootScreen === 'Welcome' ? 0 : 1

      await ProfileManager.updateUserProfile({
        gender: this.state.gender === 'male' ? 1 : 0, // 0: female, 1: male
        birthday: this.state.date,
        hasChild: this.state.hasBaby ? 1 : 0,
        push: updated ? undefined : true,
        married: this.state.married ? 1 : 0,
        marrigeDate: this.state.marrigeDate,
        children: this.state.children
          .filter((v) => v !== undefined)
          .map((v) => ({
            birthday: v?.birthday || '',
          })),
        updated,
      })

      this.goDiagnosisResult()

      // if (store.getState().root.rootScreen === 'Welcome') {
      //   this.goDiagnosisResult()
      // } else {
      //   NavigationService.navigate('HouseholdDiagnosis')
      // }
    } catch (error) {
      CommonDialog.showError({ error })
    }
  }

  private onPressAddChildButton = () => {
    const children = this.state.children
    children.push(undefined)
    this.setState({ children }, () => {
      setTimeout(() => {
        this.scrollView.current?.scrollToEnd({ animated: true })
      }, 50)
    })
  }

  private childView = (index: number, date?: { birthday: string }) => (
    <View style={styles.birthdayView} key={index}>
      <Text style={styles.label}>{1 + index}人目</Text>
      <TouchableOpacity
        style={styles.btnDate}
        onPress={() => this.onPressBirthdayPicker(index)}>
        <Image
          source={Icon.Calendar as never}
          style={{
            width: 20,
            height: 20,
            marginRight: 5,
            resizeMode: 'contain',
          }}
        />
        {date ? (
          <Text style={styles.textDate}>
            {date.birthday.replace('/\\d{2}$/', '')}
          </Text>
        ) : (
          <Text style={styles.textDateDisabled}>2020/01</Text>
        )}
        <Image
          style={styles.imgArrow}
          source={require('@images/arrow/icon-arrow-white-small.png')}
        />
      </TouchableOpacity>
    </View>
  )

  render() {
    const {
      datePickerVisible,
      marrigeDatePickerVisible,
      birthdayPickerVisible,
      gender,
      hasBaby,
      date,
      married,
      marrigeDate,
      children,
    } = this.state

    const isRootWelcome = store.getState().root.rootScreen === 'Welcome'

    return (
      <RootView>
        <CommonHeader
          leftButtonType={isRootWelcome ? 'hidden' : 'stack'}
          title="お客様情報の入力"
          renderRightButton={() => (
            <TouchableOpacity style={styles.btnHeader} onPress={this.onSkip}>
              <Text style={styles.skip}>スキップ</Text>
            </TouchableOpacity>
          )}
        />
        {isRootWelcome || (!isRootWelcome && this.state.profiles) ? (
          <>
            <ScrollView ref={this.scrollView} style={styles.body}>
              <Text style={styles.title}>
                あなたについて教えてください
                {store.getState().root.rootScreen === 'Welcome'
                  ? '\n最適な利用方法のご案内やお祝いをします😌'
                  : ''}
              </Text>
              <Text style={styles.label}>性別</Text>
              <View style={styles.wrapGender}>
                <TouchableOpacity
                  onPress={() => this.setState({ gender: 'female' })}
                  style={[
                    styles.itemGender,
                    { marginRight: 5 },
                    gender === 'female' && styles.borderActive,
                  ]}>
                  <Image
                    source={require('@images/avatar/tint/icon-avatar-women.png')}
                    style={{
                      tintColor: Color.Gray,
                      width: 50,
                      height: 50,
                      resizeMode: 'contain',
                      ...(gender === 'female' && styles.tintColorActive),
                    }}
                  />
                  <Text
                    style={[
                      gender === 'female'
                        ? styles.textGenderActive
                        : styles.textGender,
                    ]}>
                    女性
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.setState({ gender: 'male' })}
                  style={[
                    styles.itemGender,
                    { marginLeft: 5 },
                    gender === 'male' && styles.borderActive,
                  ]}>
                  <Image
                    source={require('@images/avatar/tint/icon-avatar-men.png')}
                    style={{
                      tintColor: Color.Gray,
                      width: 50,
                      height: 50,
                      resizeMode: 'contain',
                      ...(gender === 'male' && styles.tintColorActive),
                    }}
                  />
                  <Text
                    style={[
                      gender === 'male'
                        ? styles.textGenderActive
                        : styles.textGender,
                    ]}>
                    男性
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.birthdayView}>
                <Text style={styles.label}>生年月日</Text>
                <TouchableOpacity
                  style={styles.btnDate}
                  onPress={this.onPressDatePicker}>
                  <Image
                    source={Icon.Calendar}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                      resizeMode: 'contain',
                    }}
                  />
                  {date ? (
                    <Text style={styles.textDate}>{date}</Text>
                  ) : (
                    <Text style={styles.textDateDisabled}>1990/01/01</Text>
                  )}
                  <Image
                    style={styles.imgArrow}
                    source={require('@images/arrow/icon-arrow-white-small.png')}
                  />
                </TouchableOpacity>
              </View>
              <Text style={styles.label}>結婚</Text>
              <View style={styles.wrapGender}>
                <TouchableOpacity
                  onPress={() => this.setState({ married: true })}
                  style={[styles.itemBtn, married && styles.borderActive]}>
                  <Text
                    style={
                      married ? styles.textGenderActive : styles.textGender
                    }>
                    既婚
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.setState({ married: false })}
                  style={[styles.itemBtn, !married && styles.borderActive]}>
                  <Text
                    style={
                      married ? styles.textGender : styles.textGenderActive
                    }>
                    未婚
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.birthdayView}>
                <Text style={styles.label}>結婚年月 または 結婚予定年月</Text>
                <TouchableOpacity
                  style={styles.btnDate}
                  onPress={this.onPressMarrigeDatePicker}>
                  <Image
                    source={Icon.Calendar}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                      resizeMode: 'contain',
                    }}
                  />
                  {marrigeDate ? (
                    <Text style={styles.textDate}>
                      {marrigeDate.replace('/01', '')}
                    </Text>
                  ) : (
                    <Text style={styles.textDateDisabled}>2020/01</Text>
                  )}
                  <Image
                    style={styles.imgArrow}
                    source={require('@images/arrow/icon-arrow-white-small.png')}
                  />
                </TouchableOpacity>
              </View>
              <Text style={styles.label}>子ども</Text>
              <View style={styles.wrapGender}>
                <TouchableOpacity
                  onPress={() =>
                    this.setState({ hasBaby: true }, () => {
                      setTimeout(() => {
                        this.scrollView.current?.scrollToEnd({ animated: true })
                      }, 50)
                    })
                  }
                  style={[styles.itemBtn, hasBaby && styles.borderActive]}>
                  <Text
                    style={[
                      styles.textGender,
                      hasBaby ? styles.textGenderActive : styles.textGender,
                    ]}>
                    いる
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.setState({ hasBaby: false })}
                  style={[styles.itemBtn, !hasBaby && styles.borderActive]}>
                  <Text
                    style={[
                      styles.textGender,
                      hasBaby ? styles.textGender : styles.textGenderActive,
                    ]}>
                    いない
                  </Text>
                </TouchableOpacity>
              </View>
              {this.state.hasBaby && (
                <>
                  <Text style={styles.label}>子どもの誕生年月</Text>
                  {this.state.children.map((child, index) =>
                    this.childView(index, child),
                  )}
                  {this.state.children.length < 3 ? (
                    <TouchableOpacity
                      style={styles.addButton}
                      onPress={this.onPressAddChildButton}>
                      <Image
                        source={Buttons.Add}
                        style={{ width: 20, height: 20, resizeMode: 'contain' }}
                      />
                      <Text style={styles.addButtonText}>追加</Text>
                    </TouchableOpacity>
                  ) : (
                    <View style={{ height: 20 }} />
                  )}
                </>
              )}
            </ScrollView>
            <SafeAreaView edges={['bottom']} style={styles.bottom}>
              <TouchableOpacity
                style={styles.btnResult}
                onPress={this.onSubmit}>
                <Text style={styles.textViewResult}>選択して次へ</Text>
              </TouchableOpacity>
            </SafeAreaView>
            <DatePicker
              date={
                this.state.date
                  ? moment(this.state.date).toDate()
                  : moment('1990-01-01').toDate()
              }
              isVisible={datePickerVisible}
              onConfirm={this.onDateConfirm}
              onCancel={this.hideDateTimePicker}
              headerFormat="日付を選択"
              confirmText="決定"
              cancelText="キャンセル"
              minimumDate={new Date('1900-01-01')}
            />
            <DatePicker
              date={
                marrigeDate
                  ? moment(marrigeDate).toDate()
                  : moment('2020-01-01').toDate()
              }
              isVisible={marrigeDatePickerVisible}
              onConfirm={this.onMarrigeDateConfirm}
              onCancel={this.hideMarrigeDateTimePicker}
              headerFormat="日付を選択"
              confirmText="決定"
              cancelText="キャンセル"
              minimumDate={new Date('1900-01-01')}
            />
            <DatePicker
              date={moment(
                children[this.state.selectedBirthdayIndex]?.birthday ||
                  '2020-01-01',
              ).toDate()}
              isVisible={birthdayPickerVisible}
              onConfirm={this.onBirthdayConfirm}
              onCancel={this.hideBirthdayPicker}
              headerFormat="日付を選択"
              confirmText="決定"
              cancelText="キャンセル"
              minimumDate={new Date('1900-01-01')}
            />
          </>
        ) : (
          <></>
        )}
      </RootView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  btnHeader: {
    padding: 12,
  },
  skip: {
    color: 'white',
  },
  title: {
    textAlign: 'center',
    paddingTop: 15,
    fontSize: 15,
    fontWeight: 'normal',
    color: '#3A3A3A',
  },
  body: {
    paddingHorizontal: 15,
  },
  label: {
    paddingVertical: 11,
    color: '#3a3a3a',
  },
  wrapGender: {
    flexDirection: 'row',
    paddingBottom: 10,
    borderBottomColor: '#eeeeee',
    borderBottomWidth: 1,
  },
  birthdayView: {
    paddingBottom: 10,
    borderBottomColor: '#eeeeee',
    borderBottomWidth: 1,
  },
  itemGender: {
    flex: 1,
    backgroundColor: '#eeeeee',
    alignItems: 'center',
    height: 90,
    borderRadius: 5,
    justifyContent: 'center',
  },
  img: {
    tintColor: Color.Gray,
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  imgArrow: {
    transform: [{ rotate: '90deg' }],
    tintColor: Color.Gray,
    width: 19,
    height: 19,
  },
  textGenderActive: {
    fontSize: 15,
    fontWeight: 'bold',
    color: 'white',
  },
  textGender: {
    fontSize: 15,
    fontWeight: 'bold',
    color: Color.Gray,
  },
  borderActive: {
    backgroundColor: Color.Primary,
  },
  colorActive: {
    color: Color.Primary,
  },
  tintColorActive: {
    tintColor: 'white',
  },
  btnDate: {
    flexDirection: 'row',
    backgroundColor: 'white',
    marginHorizontal: 0,
    padding: 0,
    alignItems: 'center',
  },
  textDate: {
    color: '#3a3a3a',
    flex: 1,
  },
  textDateDisabled: {
    color: '#bbbbbb',
    flex: 1,
  },
  itemBtn: {
    height: 40,
    borderRadius: 20,
    flex: 1,
    backgroundColor: '#eeeeee',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
  },
  bottom: {
    paddingHorizontal: 15,
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 15,
  },
  btnResult: {
    borderRadius: 6,
    backgroundColor: Color.Primary,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textViewResult: {
    color: 'white',
    fontWeight: 'bold',
  },
  addButton: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 20,
  },
  addButtonText: {
    color: Color.Gray,
    fontWeight: 'bold',
    fontSize: 14,
    marginLeft: 5,
  },
})

export default UserInfoDiagnosisScreen
