import { TermTransactionActions, TermTransactionState } from './types'

export default (
  state: TermTransactionState = {
    familyTermTransactions: undefined,
    userTermTransactions: undefined,
  },
  action: TermTransactionActions
) => {
  switch (action.type) {
    case 'UpdateTermTransactionAction':
      return { ...state, ...action.termTransaction }
    default:
      return state
  }
}
