export const OsidoriEventName = {
  DidCreateTransaction: 'DidCreateTransaction',
  DidUpdateTransaction: 'DidUpdateTransaction',
  DidDeleteTransaction: 'DidDeleteTransaction',
  DidCreateTermTransaction: 'DidCreateTermTransaction',
  DidUpdateTermTransaction: 'DidUpdateTermTransaction',
  DidDeleteTermTransaction: 'DidDeleteTermTransaction',
  DidCreateTransfer: 'DidCreateTransfer',
  DidUpdateTransfer: 'DidUpdateTransfer',
  DidDeleteTransfer: 'DidDeleteTransfer',
  ClosePayoffScreen: 'ClosePayoffScreen',
} as const

export type OsidoriEventName = keyof typeof OsidoriEventName

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OsidoriEventCallback = (data: any) => void

class OsidoriEvent {
  private listeners: {
    [id: string]: { name: string; callback: OsidoriEventCallback }
  } = {}

  private counter = 1

  addListener = (name: OsidoriEventName, callback: OsidoriEventCallback) => {
    const id = `${this.counter++}`
    this.listeners[id] = { name, callback }

    return id
  }

  removeListener = (id?: string | string[]) => {
    if (!id) return false

    if (typeof id === 'string') {
      delete this.listeners[id]
    } else {
      id.forEach((v) => delete this.listeners[v])
    }

    return true
  }

  reemoveAllListener = () => {
    this.listeners = {}
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit = (name: OsidoriEventName, data?: any) => {
    for (const key in this.listeners) {
      const event = this.listeners[key]
      if (event.name === name) {
        event.callback(data)
      }
    }
  }
}

export default new OsidoriEvent()
