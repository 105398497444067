import { Image } from '@components'
import AppButton from '@components/AppButton'
import GoalCard from '@components/GoalCard'
import { PremiumView } from '@components/PremiumView'
import QuestionIcon from '@components/QuestionIcon'
import { GoalEntity } from '@interfaces/Goal'
import { selectUserAccountType } from '@lib/AccountManager/selectors'
import Color from '@lib/Color'
import NavigationService from '@lib/NavigationService'
import { sendSignal } from '@lib/OsidoriSignal'
import { profileSelector } from '@lib/ProfileManager/selector'
import { selectGoals } from '@lib/api/Goal/slice'
import { useCallback, useMemo, useState } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Modal from 'react-native-modal'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'

const HomeGoalList = () => {
  const userAccountType = useSelector(selectUserAccountType)
  const profile = useSelector(profileSelector)
  const goals = useSelector(selectGoals)

  const [isVisiblePremiumModal, setIsVisiblePremiumModal] = useState(false)

  const filteredGoals = useMemo(
    () => goals?.[userAccountType]?.filter((goal) => !goal.completed) ?? [],
    [goals, userAccountType],
  )

  const isPlanError = useMemo(
    () => userAccountType === 'user' && profile.userProfile?.rank === 'free',
    [profile.userProfile?.rank, userAccountType],
  )

  const renderEmptyGoals = useCallback(
    () => (
      <>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 20,
            paddingHorizontal: 15,
            paddingBottom: 5,
          }}>
          <Text style={styles.titleTotalAssets}>目標貯金</Text>
        </View>
        <View style={styles.emptyGoals}>
          <Image
            resizeMode="contain"
            source={require('@images/home/illust-home-goal-nomatch.png')}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 180,
              height: 150,
            }}
          />
          <StyledCaptionButton onPress={onPressNavigateGoalLP}>
            <StyledCaptionText>目標貯金とは</StyledCaptionText>
            <QuestionIcon size={12} color={Color.DefaultText} />
          </StyledCaptionButton>
          <Text style={styles.goalListNoMatchText}>
            この画面では家族や個人の目標を設定できます。{'\n'}
            それぞれの目標に向けて、貯金目標を立てましょう。
          </Text>
          <AppButton
            type={'secondary'}
            onPress={() => NavigationService.navigate('GoalSelectType')}
            title={'目標を作成する'}
            textStyle={{ fontWeight: 'bold' }}
          />
        </View>
      </>
    ),
    [],
  )

  const onPressGoalCard = useCallback(
    (goal: GoalEntity) => {
      if (isPlanError) {
        setIsVisiblePremiumModal(true)
        return
      }

      NavigationService.navigate('GoalDetail', {
        goalId: goal.goalId,
      })
    },
    [isPlanError],
  )

  const renderGoals = useCallback(
    () => (
      <>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 15,
          }}>
          <Text style={styles.titleTotalAssets}>目標貯金</Text>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 20,
              paddingHorizontal: 15,
              paddingBottom: 20,
            }}
            onPress={() => {
              NavigationService.navigate('LifePlan', { screen: 'GoalList' })
            }}>
            <Text style={styles.createGoalButtonText}>一覧画面へ</Text>
            <Image
              source={require('@images/arrow/icon-arrow-white-small.png')}
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>

        {/* {planError && (
          <TouchableOpacity
            style={styles.errorView}
            onPress={() => {
              setIsVisiblePremiumModal(true)
              // NavigationService.navigate('DeleteGoals')
            }}>
            <Image source={Icon.Caution} style={styles.cautionImage} />
            <Text style={styles.errorText}>
              目標貯金数が上限の3個を超えており{'\n'}
              一時的に停止中です。
            </Text>
            <Image
              source={Icon.Arrow.Right}
              style={{
                position: 'absolute',
                right: 10,
                tintColor: '#ff7200',
                width: 19,
                height: 19,
              }}
            />
          </TouchableOpacity>
        )} */}

        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingRight: 20 }}>
          {filteredGoals.map((goal) => (
            <GoalCard
              key={goal.goalId}
              status={goal.currentProgressIcon}
              title={goal.name}
              currentAmount={goal.currentAmount ?? 0}
              goalAmount={goal.goalAmount}
              imageURI={goal.imgUrl}
              progress={goal.progress ?? 0}
              isPlanError={isPlanError}
              onPress={() => onPressGoalCard(goal)}
            />
          ))}
        </ScrollView>
        <Modal
          isVisible={isVisiblePremiumModal}
          backdropColor="#00000000"
          animationIn="fadeIn"
          animationOut="fadeOut"
          style={{ margin: 0, marginVertical: -50 }}>
          <PremiumView
            premiumType="goal"
            title="プレミアムプランの機能です"
            message="個人の目標貯金はプレミアムプランにてご利用いただけます。"
            buttonTitle="さっそく作成する"
            onPressShowDetailButton={() => {
              sendSignal('CLOSED_PURCHASE_COMPLETE_MODAL', 'goal')
              setIsVisiblePremiumModal(false)
            }}
            onBackdropPress={() => setIsVisiblePremiumModal(false)}
          />
        </Modal>
      </>
    ),
    [filteredGoals, isPlanError, isVisiblePremiumModal, onPressGoalCard],
  )

  return (
    <View
      style={{
        paddingBottom: 10,
        borderColor: 'rgb(240, 240, 240)',
      }}>
      {filteredGoals.length > 0 ? renderGoals() : renderEmptyGoals()}
    </View>
  )
}

const onPressNavigateGoalLP = () => NavigationService.navigate('GoalLP')

const StyledCaptionButton = styled.TouchableOpacity({
  marginTop: 8,
  paddingBottom: 1,
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'center',
  borderBottomWidth: 1,
  borderColor: Color.DefaultText,
})

const StyledCaptionText = styled.Text({
  fontSize: 12,
  color: Color.DefaultText,
  marginRight: 2,
})

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
    paddingHorizontal: 15,
  },
  errorView: {
    marginHorizontal: 15,
    marginBottom: 15,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#eeeeee',
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
  },
  cautionImage: {
    marginEnd: 10,
    resizeMode: 'contain',
    width: 20,
    height: 20,
  },
  errorText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#3a3a3a',
    textAlign: 'center',
  },
  titleView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 20,
    paddingHorizontal: 15,
    paddingBottom: 5,
  },
  titleTotalAssets: {
    color: Color.TextBlack,
    fontSize: 15,
    fontWeight: 'bold',
  },
  createGoalButtonText: {
    color: 'rgb(102, 102, 102)',
    fontSize: 14,
    fontWeight: 'normal',
  },
  icon: {
    marginTop: -1,
    width: 12,
    height: 12,
    tintColor: '#888888',
    marginLeft: 2,
    resizeMode: 'contain',
  },
  goalListNoMatchText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 15,
    marginBottom: 20,
    color: '#8e8e8e',
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'center',
  },
  emptyGoals: {
    paddingHorizontal: 20,
    // paddingBottom: 10,
  },
})

export default HomeGoalList
