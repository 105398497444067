/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Log from '@lib/Log'

export {}

declare global {
  const log: {
    info: (...args: any[]) => void
    warn: (message?: any, ...optionalParams: any[]) => void
    error: (message?: any, ...optionalParams: any[]) => void
  }
}

const g = global as any
g.log = {
  info: (...args: any) => Log.info(args),
  warn: Log.warn,
  error: Log.error,
}
