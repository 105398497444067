import Color from '@lib/Color'
import styled from 'styled-components/native'

export const RootContainer = styled.View`
  /* PressableをRootContainerにするとSectionListの表示がバグる */
  height: 58px;
`

export const StyledPressable = styled.Pressable`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 15px;
`

export const TitleContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`

export const TitleText = styled.Text`
  margin-left: 8px;
  font-size: 15px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

export const CountText = styled.Text`
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  color: ${Color.DefaultText};
`

export const AmountText = styled.Text`
  margin-left: 8px;
  font-size: 18px;
  font-weight: bold;
  color: ${Color.DefaultText};
`

export const PayoffCategoryImage = styled.Image`
  height: 24px;
  resize-mode: contain;
`
