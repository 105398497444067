import { Platform } from 'react-native'
import API, { APIResponse } from './index'

export type PremiumPlanDetail = {
  id: number
  productId: string
  name: string
  description: string
  available: boolean
}

export type GetPremiumPlansProps = {
  planCode: string
}

export type GetPremiumPlansResponse = {
  premiumPlans: PremiumPlanDetail[]
}

export interface UpdatePlanCodeProps {
  planCode: string
}

export interface UpdatePlanCodeResponse {
  // rewardExipresAt: string
}

class PremiumPlan {
  getPlamiumPlans = (props?: GetPremiumPlansProps): Promise<APIResponse> => {
    if (Platform.OS === 'android') {
      return API.request(
        'GET',
        '/api/v2/premium-plans/google-play',
        props,
        true,
      )
    } else if (Platform.OS === 'ios') {
      return API.request('GET', '/api/v2/premium-plans/app-store', props, true)
    } else {
      return API.request('GET', '/api/v2/premium-plans/stripe', {}, true)
    }
  }

  // updatePlan = (props: UpdatePlanCodeProps): Promise<APIResponse> => {
  //   return API.request('POST', '/api/v2/user/plan_code', props, true)
  // }
}

export default new PremiumPlan()
