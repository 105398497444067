import { UserAccountType } from '@interfaces/Account'
import { ProfitLossSummary } from '@interfaces/Financial'
import {
  GetHomeExpensesSummaryResponse,
  MonthlyReportBs,
} from '@lib/api/Financial'
import { MonthlyReportGoal } from '@lib/api/Goal'
import { RootState } from '@redux/store'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type State = {
  yearMonth?: string // YYYYMM
  userAccountType: UserAccountType
  assets: MonthlyReportBs[] // 資産総額
  balances: ProfitLossSummary[] // 今月の収支 balances[0]今月, balances[1]前月
  summaryTransactions: GetHomeExpensesSummaryResponse[] // 支出の分担
  payoffAmounts: number[] // 割り勘 | 精算
  goals: MonthlyReportGoal[] // 目標貯金
  isProcessingAssets: boolean
  isProcessingBalance: boolean
  isProcessingShare: boolean
  isProcessingPayoff: boolean
  isProcessingGoal: boolean
}

type Reducer = {
  setYearMonth: (
    state: State,
    action: PayloadAction<string | undefined>,
  ) => void
  setUserAccountType: (
    state: State,
    action: PayloadAction<UserAccountType>,
  ) => void
  setAssets: (state: State, action: PayloadAction<MonthlyReportBs[]>) => void
  setBalances: (
    state: State,
    action: PayloadAction<ProfitLossSummary[]>,
  ) => void
  setSummaryTransactions: (
    state: State,
    action: PayloadAction<GetHomeExpensesSummaryResponse[]>,
  ) => void
  setGoals: (state: State, action: PayloadAction<MonthlyReportGoal[]>) => void
  setPayoffAmounts: (state: State, action: PayloadAction<number[]>) => void
  setIsProcessingAssets: (state: State, action: PayloadAction<boolean>) => void
  setIsProcessingBalance: (state: State, action: PayloadAction<boolean>) => void
  setIsProcessingShare: (state: State, action: PayloadAction<boolean>) => void
  setIsProcessingPayoff: (state: State, action: PayloadAction<boolean>) => void
  setIsProcessingGoal: (state: State, action: PayloadAction<boolean>) => void
}

const initialState: State = {
  yearMonth: undefined,
  userAccountType: 'family', // store.getState().account.accountMode,
  assets: [],
  balances: [],
  goals: [],
  summaryTransactions: [],
  payoffAmounts: [],
  isProcessingAssets: false,
  isProcessingBalance: false,
  isProcessingShare: false,
  isProcessingPayoff: false,
  isProcessingGoal: false,
}

const slice = createSlice<State, Reducer>({
  name: 'monthlyReport',
  initialState,
  reducers: {
    setYearMonth: (state, { payload }) => {
      state.yearMonth = payload
    },
    setUserAccountType: (state, { payload }) => {
      state.userAccountType = payload
    },
    setAssets: (state, { payload }) => {
      state.assets = payload
    },
    setBalances: (state, { payload }) => {
      state.balances = payload
    },
    setSummaryTransactions: (state, { payload }) => {
      state.summaryTransactions = payload
    },
    setPayoffAmounts: (state, { payload }) => {
      state.payoffAmounts = payload
    },
    setGoals: (state, { payload }) => {
      state.goals = payload
    },
    setIsProcessingAssets: (state, { payload }) => {
      state.isProcessingAssets = payload
    },
    setIsProcessingBalance: (state, { payload }) => {
      state.isProcessingBalance = payload
    },
    setIsProcessingShare: (state, { payload }) => {
      state.isProcessingShare = payload
    },
    setIsProcessingPayoff: (state, { payload }) => {
      state.isProcessingPayoff = payload
    },
    setIsProcessingGoal: (state, { payload }) => {
      state.isProcessingGoal = payload
    },
  },
})

export const {
  setAssets,
  setUserAccountType,
  setYearMonth,
  setBalances,
  setSummaryTransactions,
  setPayoffAmounts,
  setGoals,
  setIsProcessingAssets,
  setIsProcessingBalance,
  setIsProcessingShare,
  setIsProcessingPayoff,
  setIsProcessingGoal,
} = slice.actions

export const selectUserAccountType = (state: RootState) =>
  state.monthlyReport.userAccountType
export const selectYearMonth = (state: RootState) =>
  state.monthlyReport.yearMonth
export const selectAssets = (state: RootState) => state.monthlyReport.assets
export const selectBalances = (state: RootState) => state.monthlyReport.balances
export const selectMonthlyGoals = (state: RootState) =>
  state.monthlyReport.goals
export const selectSummaryTransactions = (state: RootState) =>
  state.monthlyReport.summaryTransactions
export const selectPayoffAmounts = (state: RootState) =>
  state.monthlyReport.payoffAmounts
export const selectIsProcessing = (state: RootState) =>
  state.monthlyReport.isProcessingAssets ||
  state.monthlyReport.isProcessingBalance ||
  state.monthlyReport.isProcessingShare ||
  state.monthlyReport.isProcessingPayoff ||
  state.monthlyReport.isProcessingGoal

export default slice.reducer
