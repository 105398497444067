import { RootState } from '@redux/store'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Animated, Easing, Keyboard } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  animatedSelector,
  displayValueSelector,
  initialValueSelector,
  isNotEnteredSelector,
  isVisibleSelector,
  lastInputSymbolSelector,
  lastValueSelector,
  minusInputSelector,
  onChangeSelector,
} from './selector'
import {
  closeCalculator,
  setCalculatorLastInputSymbol,
  setCalculatorLastValue,
  setCalculatorNotEntered,
  setCaluculatorDisplayValue,
} from './slice'
import {
  Button,
  ButtonLine,
  CalculatorHeight,
  CalculatorView,
  EqualOkButton,
  EqualOkWrapView,
  EqualText,
  NumberText,
  OKText,
  RootContainer,
  SymbolText,
  Transparentview,
} from './styles'
import { CalculationSymbol, CalculatorProps } from './types'

export const CalculatorAnimatedDuration = 300

const Calculator: React.FC<CalculatorProps> = ({
  disableOnTouchBackground,
}) => {
  const dispatch = useDispatch()

  const isVisible = useSelector(isVisibleSelector)
  const displayValue = useSelector(displayValueSelector)
  const lastValue = useSelector(lastValueSelector)
  const lastInputSymbol = useSelector(lastInputSymbolSelector)
  const isNotEntered = useSelector(isNotEnteredSelector)
  const animated = useSelector(animatedSelector)
  const onChange = useSelector(onChangeSelector)
  const initialValue = useSelector(initialValueSelector)
  const minusInput = useSelector(minusInputSelector)

  const keyboardVisible = useSelector(
    ({ root }: RootState) => root.keyboardVisible,
  )

  const calc = useCallback(
    (symbol: CalculationSymbol, num1: number, num2: number) => {
      switch (symbol) {
        case '+':
          return minusInput
            ? num1 + Math.abs(num2)
            : Math.abs(num1) + Math.abs(num2)
        case '-': {
          if (minusInput) {
            return num1 - Math.abs(num2)
          }
          const value = Math.abs(num1) - Math.abs(num2)
          return value > 0 ? value : 0
        }
        case '*':
          return minusInput
            ? num1 * Math.abs(num2)
            : Math.abs(num1) * Math.abs(num2)
        case '/':
          if (!num1 || !num2) return 0
          return minusInput
            ? num1 / Math.abs(num2)
            : Math.abs(num1) / Math.abs(num2)
      }
    },
    [minusInput],
  )

  const onTouchBackground = useCallback(() => {
    dispatch(closeCalculator({ animated: true }))
  }, [dispatch])

  const setDisplayValue = useCallback(
    (value: string, onChangeEvent = true) => {
      const displayValue = value.length < 13 ? value : value.substring(0, 12)
      dispatch(setCaluculatorDisplayValue({ displayValue }))
      onChangeEvent && onChange?.(+displayValue)
    },
    [dispatch, onChange],
  )

  const onPressEnter = useCallback(() => {
    setDisplayValue(displayValue.replace(/\..*$/, ''))
    dispatch(closeCalculator({ animated: true }))
  }, [dispatch, displayValue, setDisplayValue])

  const onPressNumber = useCallback(
    (number: number) => {
      if (isNotEntered) {
        if (minusInput && lastValue === 0 && lastInputSymbol === '-') {
          setDisplayValue('-' + number.toString())
          dispatch(setCalculatorLastInputSymbol({ lastInputSymbol: undefined }))
        } else {
          setDisplayValue(number.toString())
        }
        dispatch(setCalculatorNotEntered({ isNotEntered: false }))
      } else {
        setDisplayValue(
          (displayValue + number.toString()).replace(/^-?0([^.])/, '$1'),
        )
      }
    },
    [
      dispatch,
      displayValue,
      isNotEntered,
      lastInputSymbol,
      lastValue,
      minusInput,
      setDisplayValue,
    ],
  )

  const onPressSymbol = useCallback(
    (symbol: CalculationSymbol | 'c' | 'bs' | '.' | '=' | '00') => {
      switch (symbol) {
        case 'c':
          setDisplayValue('0')
          dispatch(setCalculatorLastValue({ lastValue: 0 }))
          dispatch(setCalculatorLastInputSymbol({ lastInputSymbol: undefined }))
          dispatch(setCalculatorNotEntered({ isNotEntered: true }))
          break
        case 'bs': {
          let value = displayValue.toString().slice(0, -1)

          if (value.slice(-1) === '.' || value.slice(-1) === '-') {
            value = value.slice(0, -1)
          }
          if (value === '') {
            value = '0'
          }
          setDisplayValue(value)
          break
        }
        case '.': {
          if (displayValue.toString().indexOf('.') >= 0) break
          setDisplayValue(displayValue + '.')
          isNotEntered &&
            dispatch(setCalculatorNotEntered({ isNotEntered: false }))
          break
        }
        case '00': {
          if (isNotEntered) {
            setDisplayValue('0')
            dispatch(setCalculatorNotEntered({ isNotEntered: false }))
          } else {
            setDisplayValue((displayValue + '00').replace(/^0+$/, '0'))
            isNotEntered &&
              dispatch(setCalculatorNotEntered({ isNotEntered: false }))
          }
          break
        }
        case '=': {
          if (!lastInputSymbol) break
          if (lastValue === undefined) break

          const value = calc(lastInputSymbol, lastValue, +displayValue)
          setDisplayValue(value.toString())
          dispatch(setCalculatorLastInputSymbol({ lastInputSymbol: undefined }))
          dispatch(setCalculatorNotEntered({ isNotEntered: true }))
          break
        }
        default:
          // + - * /
          if (lastInputSymbol && isNotEntered) {
            dispatch(setCalculatorLastInputSymbol({ lastInputSymbol: symbol }))
            return
          }

          if (lastInputSymbol && lastValue !== undefined) {
            const value = lastInputSymbol
              ? calc(lastInputSymbol, lastValue, +displayValue)
              : lastValue
            dispatch(setCalculatorLastValue({ lastValue: value }))
            setDisplayValue(value.toString())
          } else {
            dispatch(setCalculatorLastValue({ lastValue: +displayValue }))
          }

          dispatch(setCalculatorNotEntered({ isNotEntered: true }))
          dispatch(setCalculatorLastInputSymbol({ lastInputSymbol: symbol }))
      }
    },
    [
      setDisplayValue,
      dispatch,
      lastInputSymbol,
      isNotEntered,
      lastValue,
      displayValue,
      calc,
    ],
  )

  const bottom = useMemo(() => new Animated.Value(0), [])
  const [isViewVisible, setViewVisible] = useState(false)

  useEffect(() => {
    return () => {
      isVisible && dispatch(closeCalculator({ animated: false }))
    }
  }, [dispatch, isVisible])

  useEffect(() => {
    if (initialValue !== undefined && !lastInputSymbol) {
      dispatch(setCalculatorLastValue({ lastValue: initialValue }))
      setDisplayValue(initialValue.toString(), false)
    }
    // Exclude lastInputSymbol
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, initialValue, setDisplayValue])

  useEffect(() => {
    if (isVisible && keyboardVisible) {
      Keyboard.dismiss()
      return
    }

    if (isVisible) {
      setViewVisible(true)
      dispatch(setCalculatorNotEntered({ isNotEntered: true }))
    }

    Animated.timing(bottom, {
      // toValue: isVisible ? windowHeight - statusBarHeight - 44 - marginTop : 0,
      toValue: isVisible ? 0 : -CalculatorHeight,
      duration: animated ? CalculatorAnimatedDuration : 0,
      easing: Easing.out(Easing.exp),
      useNativeDriver: false,
    }).start(() => {
      if (!isVisible) {
        setViewVisible(false)
      }
    })
  }, [isVisible, animated, keyboardVisible, bottom, dispatch])

  if (!isVisible && !isViewVisible) return null

  return (
    <RootContainer style={{ bottom }} pointerEvents="box-none">
      <Transparentview
        onTouchStart={disableOnTouchBackground ? undefined : onTouchBackground}
        pointerEvents={disableOnTouchBackground ? 'none' : undefined}
      />
      <CalculatorView>
        <ButtonLine>
          <Button onPress={() => onPressNumber(7)}>
            <NumberText>7</NumberText>
          </Button>
          <Button onPress={() => onPressNumber(8)}>
            <NumberText>8</NumberText>
          </Button>
          <Button onPress={() => onPressNumber(9)}>
            <NumberText>9</NumberText>
          </Button>
          <Button onPress={() => onPressSymbol('/')}>
            <SymbolText>÷</SymbolText>
          </Button>
        </ButtonLine>
        <ButtonLine>
          <Button onPress={() => onPressNumber(4)}>
            <NumberText>4</NumberText>
          </Button>
          <Button onPress={() => onPressNumber(5)}>
            <NumberText>5</NumberText>
          </Button>
          <Button onPress={() => onPressNumber(6)}>
            <NumberText>6</NumberText>
          </Button>
          <Button onPress={() => onPressSymbol('*')}>
            <SymbolText>×</SymbolText>
          </Button>
        </ButtonLine>
        <ButtonLine>
          <Button onPress={() => onPressNumber(1)}>
            <NumberText>1</NumberText>
          </Button>
          <Button onPress={() => onPressNumber(2)}>
            <NumberText>2</NumberText>
          </Button>
          <Button onPress={() => onPressNumber(3)}>
            <NumberText>3</NumberText>
          </Button>
          <Button onPress={() => onPressSymbol('-')}>
            <SymbolText>−</SymbolText>
          </Button>
        </ButtonLine>
        <ButtonLine>
          <Button onPress={() => onPressSymbol('.')}>
            <NumberText>.</NumberText>
          </Button>
          <Button onPress={() => onPressNumber(0)}>
            <NumberText>0</NumberText>
          </Button>
          <Button onPress={() => onPressSymbol('00')}>
            <NumberText>00</NumberText>
          </Button>
          <Button onPress={() => onPressSymbol('+')}>
            <SymbolText>＋</SymbolText>
          </Button>
        </ButtonLine>
        <ButtonLine>
          <Button onPress={() => onPressSymbol('c')}>
            <SymbolText>C</SymbolText>
          </Button>
          {lastInputSymbol ? (
            <EqualOkButton onPress={() => onPressSymbol('=')}>
              <EqualOkWrapView>
                <EqualText>＝</EqualText>
              </EqualOkWrapView>
            </EqualOkButton>
          ) : (
            <EqualOkButton onPress={onPressEnter}>
              <EqualOkWrapView>
                <OKText>OK</OKText>
              </EqualOkWrapView>
            </EqualOkButton>
          )}
          <Button onPress={() => onPressSymbol('bs')}>
            <SymbolText>←</SymbolText>
          </Button>
        </ButtonLine>
      </CalculatorView>
    </RootContainer>
  )
}

export default Calculator
