import Alert from '@components/Alert'
import { AdManager, UIBankAdData, UIBankAdType } from '@lib/api/AD'
import CommonDialog from '@lib/CommonDialog'
import { NativeApp } from '@lib/Env'
import TrackingUtils from '@lib/TrackingUtils'
import store, { RootState } from '@redux/store'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Image,
  Linking,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import WebView, { WebViewNavigation } from 'react-native-webview'
import { useSelector } from 'react-redux'
import { navigatePremiumPlanLP } from '../Screen/Main/PremiumPlan'
import AbsoluteActivityIndicator from './AbsoluteActivityIndicator'

interface Props {
  type: '320x50' | 'Home300x250' | 'Transaction300x250'
  width?: number
  style?: StyleProp<ViewStyle>
  premiulPageIndex?: number
  onPressHiddenButton?: () => void
  delay?: number // ms
}

const AdView: React.FC<Props> = ({
  delay,
  type,
  width,
  style,
  onPressHiddenButton: onPressHiddenButtonHandler,
}) => {
  const TestAD = false

  const idfa = useSelector((state: RootState) => state.root.idfa)

  // const [onLoadEnd, setOnLoadEnd] = useState(false)
  const [isActive, setActive] = useState(!delay)

  const timer = useCallback(() => setActive(true), [])

  useEffect(() => {
    delay && setTimeout(timer, delay)
  }, [delay, timer])

  const onPressHiddenButton = useCallback(() => {
    if (onPressHiddenButtonHandler) {
      onPressHiddenButtonHandler()
    } else {
      navigatePremiumPlanLP({ planCode: '001' })
    }
    TrackingUtils.repro.track('【Tap】hide ads', 'Screen')
  }, [onPressHiddenButtonHandler])

  const onLoadEnd = useRef(false)

  const onShouldStartLoadWithRequest = useCallback(
    (event: WebViewNavigation) => {
      if (onLoadEnd.current && event.url !== 'about:blank') {
        if (__DEV__) {
          Alert.alert(
            'DEBUG',
            `event.navigationType=${event.navigationType}\nurl=${event.url}`,
            [
              { text: 'Close' },
              {
                text: 'Open browser',
                onPress: () =>
                  Linking.openURL(event.url).catch((error) =>
                    CommonDialog.debug({ url: event.url, error }),
                  ),
              },
            ],
          )
        } else {
          Linking.openURL(event.url).catch((error) =>
            CommonDialog.debug({ url: event.url, error }),
          )
        }
        return false
      }
      return true
    },
    [],
  )

  useEffect(() => {
    if (idfa) {
      onLoadEnd.current = false
    }
  }, [idfa])

  const [adData, setAdData] = useState<UIBankAdData>()

  useEffect(() => {
    if (NativeApp) return

    const adType: UIBankAdType = type === '320x50' ? 'ad2' : 'ad1'
    AdManager.getUIBankAd(adType).then((response) => setAdData(response.ad))
  }, [type])

  const adWebView = useCallback(
    (
      locationId: string,
      argWidth: number,
      height: number,
      contentWidth?: number,
    ) => {
      if (idfa === undefined) return null

      const viewWidth = contentWidth || width || wp(100)
      const scale = viewWidth / argWidth
      const userProfile = store.getState().profile.userProfile
      const userStatus = store.getState().profile.userStatus

      let src = `https://i.socdm.com/sdk/js/adg-script-loader.js?id=${locationId}&targetID=targetID&displayid=1&adType=SP&async=false&tagver=2.0.0`
      if (Platform.OS === 'ios') {
        src += `&idfa=${idfa}`
        src += `&appbundle=osidori.inc`
      } else if (Platform.OS === 'android') {
        src += `&advertising_id=${idfa}`
        src += `&appbundle=inc.osidori`
      }
      src += `&label_age=${userProfile?.age}`
      src += `&label_age_range=${userProfile?.ageRange}`
      src += `&label_age_range2=${userProfile?.ageRange2}`
      src += `&label_gender=${userProfile?.gender}`
      src += `&label_has_child=${userProfile?.hasChild}`
      src += `&label_finance_registered=${userStatus?.financeRegistered}`
      src += `&label_finance_shared=${userStatus?.financeShared}`
      src += `&label_married=${userProfile?.married}`

      return (
        <View
          style={{
            width: viewWidth,
            height: height * scale,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View style={{ width: argWidth, height, transform: [{ scale }] }}>
            <WebView
              style={{ width: argWidth }}
              automaticallyAdjustContentInsets={false}
              originWhitelist={['*']}
              startInLoadingState={true}
              renderLoading={() => <AbsoluteActivityIndicator />}
              onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
              onLoadEnd={() => {
                onLoadEnd.current = true
              }}
              allowsInlineMediaPlayback={true}
              source={{
                html: `<html><head><style>* {margin:0; padding:0;}</style><meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=no"></head><body><script src="${src}"></script></body></html>`,
              }}
            />
          </View>
          {__DEV__ && Platform.OS !== 'web' && (
            <View
              pointerEvents="none"
              style={{
                position: 'absolute',
                backgroundColor: '#00000033',
                top: 0,
              }}>
              <Text style={{ fontSize: 10 }}>{src}</Text>
            </View>
          )}
        </View>
      )
    },
    [idfa, width, onShouldStartLoadWithRequest],
  )

  const uiBankAdWebView = useCallback(
    (argWidth: number, height: number, contentWidth?: number) => {
      if (!adData) return null

      const viewWidth = contentWidth || width || wp(100)
      const scale = viewWidth / argWidth

      return (
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(adData.url)
            // location.href = adData.url
          }}
          style={{
            width: viewWidth,
            height: height * scale,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View style={{ width: argWidth, height, transform: [{ scale }] }}>
            <Image
              source={{ uri: adData.imgUrl }}
              style={{ width: argWidth, height, resizeMode: 'contain' }}
            />
          </View>
        </TouchableOpacity>
      )
    },
    [width, adData],
  )

  const render320x50 = useCallback(() => {
    const viewWidth = width || wp('100%')
    // const height = width * (50 / 320)
    const locationId = TestAD
      ? '48547'
      : Platform.OS === 'ios'
      ? '113193'
      : '113191'
    return (
      <View style={[style, { width: viewWidth }]} pointerEvents="box-none">
        <View style={styles.closeButtonView} pointerEvents="box-none">
          <TouchableOpacity
            style={styles.closeButton}
            onPress={onPressHiddenButton}>
            <Text style={styles.closeButtonText}>✕ 非表示</Text>
          </TouchableOpacity>
        </View>
        {NativeApp
          ? adWebView(locationId, 320, 50, viewWidth)
          : uiBankAdWebView(320, 50, viewWidth)}
      </View>
    )
  }, [width, TestAD, style, onPressHiddenButton, adWebView, uiBankAdWebView])

  const render300x250Frame = useCallback(
    (adType: 'home' | 'transaction') => {
      const viewWidth = (width || wp(100)) - wp(10)
      const locationId = TestAD
        ? '48549'
        : adType === 'home'
        ? Platform.OS === 'ios'
          ? '113195'
          : '113196'
        : Platform.OS === 'ios'
        ? '113194'
        : '113192'

      return (
        <View style={style}>
          <Text
            style={{
              color: 'white',
              fontSize: 10,
              fontWeight: 'normal',
              textAlign: 'center',
              width: 50,
              paddingVertical: 2,
              backgroundColor: '#aaaaaa',
            }}>
            PR
          </Text>
          <View style={{ padding: wp(5) }}>
            <View style={[style, { width: viewWidth }]}>
              <View style={styles.closeButtonView}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={onPressHiddenButton}>
                  <Text style={styles.closeButtonText}>✕ 非表示</Text>
                </TouchableOpacity>
              </View>
              {NativeApp
                ? adWebView(locationId, 300, 250, viewWidth)
                : uiBankAdWebView(300, 250, viewWidth)}
            </View>
          </View>
        </View>
      )
    },
    [width, TestAD, style, onPressHiddenButton, adWebView, uiBankAdWebView],
  )

  if (!isActive) return null

  switch (type) {
    case '320x50':
      return render320x50()
    case 'Home300x250':
      return render300x250Frame('home')
    case 'Transaction300x250':
      return render300x250Frame('transaction')
  }
}

const styles = StyleSheet.create({
  closeButtonView: {
    marginTop: -24,
    marginRight: 3,
    alignItems: 'flex-end',
  },
  closeButton: {
    backgroundColor: 'white',
    borderColor: '#eeeeee',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopStartRadius: 5,
    borderTopEndRadius: 5,
    height: 24,
    justifyContent: 'center',
    paddingHorizontal: 10,
  },
  closeButtonText: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 'normal',
  },
})

export default AdView
