import TintedImage from '@components/TintedImage'
import { faChartPie, faCreditCard } from '@fortawesome/pro-solid-svg-icons'
import Color from '@lib/Color'
import { NativeApp } from '@lib/Env'
import { FAIcon } from '@lib/FAIcon'
import Images from '@lib/Images'
import NavigationService from '@lib/NavigationService'
import { SpotlightTourSpot } from '@lib/SpotlightTour'
import TrackingUtils from '@lib/TrackingUtils'
import { useTabNavigatorBugWorkaround } from '@lib/hooks'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import store, { RootState } from '@redux/store'
import { useEffect, useRef } from 'react'
import { Keyboard, Platform, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import FinancialAccountsTabScreen from './FinancialAccountsTab'
import HomeTopScreen from './Home'
import HouseholdAccountsTabScreen from './HouseholdAccountsTab'
import Transactions from './Transactions'
import TutorialTipsBalloon from './TutorialTips/TutorialTipsBalloon'
import { updateMainScreenSelectedTab } from './redux/actions'
import { mainScreenSelectedTabSelector } from './redux/selector'

export const Tab = createBottomTabNavigator()

const EmptyComponent = () => null

const MainTabScreen = () => {
  useTabNavigatorBugWorkaround()

  const dispatch = useDispatch()

  const mainScreenTab = useSelector(mainScreenSelectedTabSelector)
  const tabBarVisible = useSelector(({ main }: RootState) => main.tabBarVisible)
  const keyboardVisible = useSelector(
    ({ root }: RootState) => root.keyboardVisible,
  )

  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      NavigationService.navigate(mainScreenTab)
    } else {
      didMountRef.current = true
    }
  }, [mainScreenTab])

  return (
    <View style={{ flex: 1, paddingBottom: Platform.select({ web: 15 }) }}>
      <Tab.Navigator
        backBehavior="none"
        tabBarOptions={{
          activeTintColor: Color.Primary,
          style: tabBarVisible ? undefined : { height: 0, opacity: 0 },
        }}>
        <Tab.Screen
          name="HomeTab"
          component={HomeTopScreen}
          options={{
            tabBarIcon: ({ color: tintColor }) => (
              <TintedImage
                source={Images.Tab.Home}
                style={{
                  width: NativeApp ? 20 : 25,
                  height: NativeApp ? 20 : 25,
                  tintColor,
                }}
              />
            ),
            title: 'ホーム',
          }}
          listeners={{
            tabPress: (e) => {
              e.preventDefault()
              if (mainScreenTab === 'HomeTab') {
                if (keyboardVisible) {
                  Keyboard.dismiss()
                  return
                }
                NavigationService.popToTop()
              } else {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('HomeTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
            focus: () => {
              if (mainScreenTab !== 'HomeTab') {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('HomeTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
          }}
        />
        <Tab.Screen
          name="FinancialAccountsTab"
          component={FinancialAccountsTabScreen}
          options={{
            tabBarIcon: ({ color }) => (
              <SpotlightTourSpot
                spotId="tab2"
                translateY={8}
                scale={3.2}
                tipsComponent={(props) => <TutorialTipsBalloon {...props} />}>
                <FAIcon icon={faCreditCard as never} size={20} color={color} />
              </SpotlightTourSpot>
            ),
            title: '口座',
          }}
          listeners={{
            tabPress: (e) => {
              e.preventDefault()
              if (mainScreenTab === 'FinancialAccountsTab') {
                if (keyboardVisible) {
                  Keyboard.dismiss()
                  return
                }
                NavigationService.popToTop()
              } else {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('FinancialAccountsTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
            focus: () => {
              if (mainScreenTab !== 'FinancialAccountsTab') {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('FinancialAccountsTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
          }}
        />
        <Tab.Screen
          name="CreateTransactionTab"
          component={EmptyComponent}
          options={{
            tabBarIcon: ({ color: tintColor }) => (
              <SpotlightTourSpot
                spotId="tab3"
                translateY={8}
                scale={3.2}
                tipsComponent={(props) => <TutorialTipsBalloon {...props} />}>
                <TintedImage
                  source={require('@images/buttons/input/button-input.png')}
                  style={{ width: 20, height: 20, tintColor }}
                />
              </SpotlightTourSpot>
            ),
            title: '入力',
          }}
          listeners={() => ({
            tabPress: (e) => {
              e.preventDefault()
              NavigationService.navigate('CreateTransactionDetail')

              const userAccountType = store.getState().account.accountMode

              if (userAccountType === 'user') {
                TrackingUtils.repro.track(
                  '【Tap】personal_create detaile',
                  'Tap',
                )
              } else {
                TrackingUtils.repro.track('【Tap】family_create detaile', 'Tap')
              }

              TrackingUtils.ga.tap({
                action: `Tap-TransactionInput-${
                  userAccountType === 'family' ? 'Family' : 'User'
                }`,
                label: `${
                  userAccountType === 'family' ? '家族' : '個人'
                }_ホームメニューの「入力」をタップ`,
              })
            },
          })}
        />
        <Tab.Screen
          name="HouseholdAccountsTab"
          component={HouseholdAccountsTabScreen}
          options={{
            tabBarIcon: ({ color }) => (
              <SpotlightTourSpot
                spotId="tab4"
                translateY={8}
                scale={3.2}
                tipsComponent={(props) => <TutorialTipsBalloon {...props} />}>
                <FAIcon icon={faChartPie as never} size={20} color={color} />
              </SpotlightTourSpot>
            ),
            title: '家計簿',
          }}
          listeners={{
            tabPress: (e) => {
              e.preventDefault()
              if (mainScreenTab === 'HouseholdAccountsTab') {
                if (keyboardVisible) {
                  Keyboard.dismiss()
                  return
                }
                NavigationService.popToTop()
              } else {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('HouseholdAccountsTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
            focus: () => {
              if (mainScreenTab !== 'HouseholdAccountsTab') {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('HouseholdAccountsTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
          }}
        />
        <Tab.Screen
          name="TransactionsTab"
          component={Transactions}
          options={{
            tabBarIcon: ({ color: tintColor }) => (
              <SpotlightTourSpot
                spotId="tab5"
                translateY={8}
                scale={3.2}
                tipsComponent={(props) => <TutorialTipsBalloon {...props} />}>
                <TintedImage
                  source={require('@images/icons/faCalendarDays.png')}
                  style={{ width: 20, height: 20, tintColor }}
                />
              </SpotlightTourSpot>
            ),
            title: '取引履歴',
          }}
          listeners={{
            tabPress: (e) => {
              e.preventDefault()
              if (mainScreenTab === 'TransactionsTab') {
                if (keyboardVisible) {
                  Keyboard.dismiss()
                  return
                }
                NavigationService.popToTop()
              } else {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('TransactionsTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
            focus: () => {
              if (mainScreenTab !== 'TransactionsTab') {
                if (keyboardVisible) Keyboard.dismiss()
                dispatch(updateMainScreenSelectedTab('TransactionsTab'))
                NavigationService.isKeepDisplayDrawer = false
              }
            },
          }}
        />
      </Tab.Navigator>
    </View>
  )
}

export default MainTabScreen
