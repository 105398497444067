import { NativeApp } from '@lib/Env'
import HardwareBackPressHandler from '@lib/HardwareBackPressHandler'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'
import InvitePairingScreen from '@Screen/InvitePairingScreen'
import MediaWebViewScreen from '@Screen/MediaWebViewScreen'
import WebViewScreen from '@Screen/WebViewScreen'
import React from 'react'
import AboutOsidOriScreen from '../AboutOsidOriScreen'
import DataDownloadScreen from '../DataDownloadScreen'
import LifePlanScreen from '../LifePlanScreen'
import MainTabsStackScreens from '../MainTabsStackScreens'
import MonthlyReportScreen from '../MonthlyReportScreen'
import SettingAccountScreen from '../SettingAccountScreen'
import SettingAppScreen from '../SettingAppScreen'
import SettingCarryForwardScreen from '../SettingCarryForwardScreen'
import SettingCategorySelectUserAccountTypeScreen from '../SettingCategorySelectUserAccountTypeScreen'
import SettingCountPeriodScreen from '../SettingCountPeriodScreen'
import SettingHomeBackgroundScreen from '../SettingHomeBackgroundScreen'
import SettingMailPermissionsScreen from '../SettingMailPermissionsScreen'
import SettingNotificationTabScreen from '../SettingNotificationTabScreen'
import SettingOthersScreen from '../SettingOthersScreen'
import SettingPairingScreen from '../SettingPairingScreen'
import SettingPasscodeScreen from '../SettingPasscodeScreen'
import SettingPeriodTabScreen from '../SettingPeriodTabScreen'
import SettingPremiumScreen from '../SettingPremiumScreen'
import SettingProfilesScreen from '../SettingProfilesScreen'
import SettingPushNotificationScreen from '../SettingPushNotificationScreen'
import SettingSecurityScreen from '../SettingSecurityScreen'
import SettingTransactionDefaultScreen from '../SettingTransactionDefaultScreen'
import SettingUserInformationScreen from '../SettingUserInformation'
import TimelineScreen from '../Timeline/TimelineScreen'
import WithdrawScreen from '../WithdrawScreen'
import HomeTopScreen, { HomeTopScreenProps } from './HomeTopScreen'
import PayoffListScreen from './Payoff/PayoffListScreen'

HardwareBackPressHandler.onPressToExitApp()

export type HomeStackScreenParams = {
  HomeTop: HomeTopScreenProps
}

const Stack = createStackNavigator()

const HomeStackScreen: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        ...(NativeApp ? TransitionPresets.SlideFromRightIOS : {}),
      }}>
      <Stack.Screen name="HomeTop" component={HomeTopScreen} />
      <Stack.Screen name="MediaWebView" component={MediaWebViewScreen} />
      <Stack.Screen name="SettingAccount" component={SettingAccountScreen} />
      <Stack.Screen name="SettingPremium" component={SettingPremiumScreen} />
      <Stack.Screen name="SettingProfiles" component={SettingProfilesScreen} />
      <Stack.Screen name="SettingPairing" component={SettingPairingScreen} />
      <Stack.Screen name="SettingApp" component={SettingAppScreen} />
      <Stack.Screen name="SettingOthers" component={SettingOthersScreen} />
      <Stack.Screen name="AboutOsidOri" component={AboutOsidOriScreen} />
      <Stack.Screen name="Withdraw" component={WithdrawScreen} />
      <Stack.Screen name="WithdrawFAQ" component={WebViewScreen} />
      <Stack.Screen
        name="SettingTransactionDefault"
        component={SettingTransactionDefaultScreen}
      />
      <Stack.Screen
        name="SettingCountPeriod"
        component={SettingCountPeriodScreen}
      />
      <Stack.Screen
        name="SettingPeriodTab"
        component={SettingPeriodTabScreen}
      />
      {/* <Stack.Screen name="SettingPeriod" component={SettingPeriodScreen} /> */}
      <Stack.Screen
        name="SettingCategorySelectUserAccountType"
        component={SettingCategorySelectUserAccountTypeScreen}
      />
      {/* <Stack.Screen
        name="SettingCategory"
        component={SideMenuSettingCategoryScreen}
      /> */}
      <Stack.Screen
        name="SettingHomeBackground"
        component={SettingHomeBackgroundScreen}
      />
      <Stack.Screen
        name="SettingUserInformation"
        component={SettingUserInformationScreen}
      />
      <Stack.Screen
        name="SettingNotificationTab"
        component={SettingNotificationTabScreen}
      />
      <Stack.Screen
        name="SettingPushNotification"
        component={SettingPushNotificationScreen}
      />
      <Stack.Screen
        name="SettingMailPermissions"
        component={SettingMailPermissionsScreen}
      />
      <Stack.Screen name="SettingSecurity" component={SettingSecurityScreen} />
      <Stack.Screen name="SettingPasscode" component={SettingPasscodeScreen} />
      <Stack.Screen name="InvitePairing" component={InvitePairingScreen} />
      {/* <Stack.Screen name="BudgetSetting" component={BudgetSettingScreen} /> */}
      <Stack.Screen name="PayoffList" component={PayoffListScreen} />
      {/* <Stack.Screen name="EditSubCategory" component={EditSubCategoryScreen} /> */}
      <Stack.Screen name="DataDownload" component={DataDownloadScreen} />
      <Stack.Screen name="TimelineTop" component={TimelineScreen} />
      <Stack.Screen name="MonthlyReport" component={MonthlyReportScreen} />
      <Stack.Screen
        name="SettingCarryForward"
        component={SettingCarryForwardScreen}
      />
      <Stack.Screen
        name="LifePlan"
        component={LifePlanScreen}
        options={
          NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
        }
      />
      {/* <Stack.Screen
        name="GoalList"
        component={GoalListScreen}
        options={
          NativeApp ? { ...TransitionPresets.SlideFromRightIOS } : undefined
        }
      /> */}
      {MainTabsStackScreens(Stack, 'HomeTab')}
    </Stack.Navigator>
  )
}

export default HomeStackScreen
