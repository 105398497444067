import API, { APIResponse } from '../index'
import {
  AddYetPayoffsProps,
  DeletePayoffProps,
  DeleteYetPayoffsProps,
  GetPayoffDetailProps,
  GetPayoffsProps,
  PayoffsProps,
  UpdatePayoffSettingsProps,
} from './types'

export const updateSettings = (
  props: UpdatePayoffSettingsProps,
): Promise<APIResponse> => {
  return API.request('PUT', '/api/v1/group/payoff/settings', props, true)
}

export const getSettings = (): Promise<APIResponse> => {
  return API.request('GET', '/api/v1/group/payoff/settings', {}, true)
}

// 未精算一覧追加
export const addYetPeyoffs = (props: AddYetPayoffsProps) => {
  return API.request('POST', '/api/v1/group/yet-payoffs', props, true)
}

// 未精算一覧から除外
export const deleteYetPeyoffs = (props: DeleteYetPayoffsProps) => {
  return API.request('DELETE', '/api/v1/group/yet-payoffs', props, true)
}

// 未精算一覧取得
// export const getYetPayoffs = (props: GetYetPayoffsProps) => {
//   return API.request('GET', '/api/v3/yet-payoffs', props, true)
// }

// 精算
export const payoffs = (props: PayoffsProps) => {
  return API.request('POST', '/api/v1/group/payoffs', props, true)
}

// 精算済み一覧
export const getPayoffs = (props: GetPayoffsProps) => {
  return API.request('GET', '/api/v3/payoffs', props, true)
}

// 精算解除
export const deletePayoff = ({ payoffId }: DeletePayoffProps) => {
  return API.request('DELETE', `/api/v1/group/payoffs/${payoffId}`, {}, true)
}

// 精算済み詳細取得
export const getPayoffDetail = ({ payoffId }: GetPayoffDetailProps) => {
  return API.request('GET', `/api/v3/payoffs/${payoffId}`, {}, true)
}
