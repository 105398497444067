import styled from 'styled-components/native'

export const RootContainer = styled.View`
  flex: 1;
  /* background-color: blue; */
`

export const BodyContainer = styled.ScrollView`
  flex: 1;
  padding-horizontal: 20px;
  padding-vertical: 30px;
  /* background-color: blue; */
`

export const TitleText = styled.Text`
  font-size: 17px;
  font-weight: bold;
  color: rgb(58, 58, 58);
`

export const DescriptionText = styled.Text`
  margin-top: 15px;
  font-size: 12px;
  font-weight: normal;
  color: rgb(102, 102, 102);
`

export const ShareRatioContainer = styled.View`
  margin-top: 35px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  /* padding-horizontal: 20px;
  padding-vertical: 30px; */
`

export const ShareRatioCol = styled.View`
  /* flex: 1; */
`

export const ShareRatioPersonText = styled.Text`
  font-size: 12px;
  font-weight: normal;
  color: black;
`

export const ShareRatioTitleText = styled.Text`
  font-size: 12px;
  font-weight: bold;
  color: rgb(58, 58, 58);
  margin-bottom: 8px;
`

export const ShareRatioInputContainer = styled.View`
  flex-direction: row;
  align-items: center;
`

export const ShareRatioInput = styled.TextInput`
  margin-top: 4px;
  width: 100px;
  height: 28px;
  text-align: right;
  padding-right: 4px;
  padding-vertical: 0;
  border-width: 1px;
  border-color: rgb(224, 224, 224);
  color: rgb(58, 58, 58);
`

export const PercentText = styled.Text`
  margin-left: 5px;
  font-size: 12px;
  font-weight: normal;
  color: black;
`

export const BottomContainer = styled.View`
  margin-top: 50px;
  /* flex: 1; */
  justify-content: flex-end;
`

export const SaveButtonContainer = styled.View`
  /*  */
`
