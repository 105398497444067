const results = {
  data1: {
    title: '共用口座を2人で見よう',
    image: require('@assets/images/illust/illust-share.png'),
    content:
      '共有口座を家族ページに登録すれば、\n入出金や残高を一緒にチェックできます。',
  },
  data2: {
    title: '家計と貯金を区別して管理しよう',
    image: require('@assets/images/illust/illust-pie.png'),
    content: '口座の残高の中身を、貯金と\n使えるお金とを区別して把握できます。',
  },
  data3: {
    title: '2人の共同貯金を始めよう',
    image: require('@assets/images/illust/illust-joint.png'),
    content: '家族旅行・教育費用などの目標貯金を、\nカンタンに始められます。',
  },
  data4: {
    title: '世帯全体の家計支出を2人で見よう',
    image: require('@assets/images/illust/illust-sorting.png'),
    content:
      '各自の支出から家計分を共有すると、\n世帯全体の支出が見える化します。',
  },
}

const diagnosisResultData = {
  result1: {
    image: require('@assets/images/diagnose/qaans/type1.png'),
    data: [results.data1],
  },
  result2: {
    image: require('@assets/images/diagnose/qaans/type2.png'),
    data: [results.data1, results.data2],
  },
  result3: {
    image: require('@assets/images/diagnose/qaans/type3.png'),
    data: [results.data1, results.data3],
  },
  result4: {
    image: require('@assets/images/diagnose/qaans/type4.png'),
    data: [results.data4, results.data1],
  },
  result5: {
    image: require('@assets/images/diagnose/qaans/type5.png'),
    data: [results.data4, results.data3],
  },
}

export default diagnosisResultData
