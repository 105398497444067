import CommonHeader from '@components/CommonHeader'
import { Image } from '@components/Image'
import RootView from '@components/RootView'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faAmbulance,
  faAnalytics,
  faAward,
  faBabyCarriage,
  faBackpack,
  faBallotCheck,
  faBedAlt,
  faBeer,
  faBell,
  faBicycle,
  faBone,
  faBook,
  faBreadLoaf,
  faBriefcase,
  faCalendarAlt,
  faCamera,
  faChair,
  faChalkboardTeacher,
  faCheck,
  faCheeseburger,
  faCloud,
  faCommentAltCheck,
  faCreditCard,
  faGamepadAlt,
  faGift,
  faHandHoldingHeart,
  faIcons,
  faLaughSquint,
  faLeaf,
  faLockAlt,
  faMobile,
  faPaperPlane,
  faPen,
  faPhoneLaptop,
  faPlusCircle,
  faRingsWedding,
  faSmoking,
  faStar,
} from '@fortawesome/pro-solid-svg-icons'
import Color from '@lib/Color'
import { NativeApp } from '@lib/Env'
import { FAIcon } from '@lib/FAIcon'
import * as Icon from '@lib/Icon'
import NavigationService from '@lib/NavigationService'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'

class CategoryIconPickerScreen extends React.Component<
  StackScreenProps<RootStackParamList, 'CategoryIconPicker'>
> {
  private renderDefaultColorButtons = () => {
    return (
      <>
        <View style={styles.iconButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('39')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Food} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('43')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Cloth} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('47')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Cash} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('51')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Car} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('56')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Others} style={styles.iconImage} />
          </TouchableOpacity>
        </View>
        <View style={styles.iconButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('40')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Grocery}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('44')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Outgoing}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('48')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Supply} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('52')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Tax} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('57')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Uncategorized}
              style={styles.iconImage}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.iconButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('41')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Traffic}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('45')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Medical}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('49')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Shipping}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('53')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Insurance}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('58')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Income} style={styles.iconImage} />
          </TouchableOpacity>
        </View>
        <View style={styles.iconButtonGroup}>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('42')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.Hobby} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('46')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Education}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('50')}
            style={styles.iconButton}>
            <Image source={Icon.CategoryIcon.House} style={styles.iconImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('54')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.Special}
              style={styles.iconImage}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onPressIconButton('55')}
            style={styles.iconButton}>
            <Image
              source={Icon.CategoryIcon.CategoryAssetformation}
              style={styles.iconImage}
            />
          </TouchableOpacity>
        </View>
      </>
    )
  }

  private customIconSize = wp(10)

  private customIcon = (iconName: string, icon: IconProp) => (
    <TouchableOpacity
      onPress={() => this.onPressIconButton(iconName)}
      style={styles.customIconButton}>
      <FAIcon
        icon={icon}
        size={this.customIconSize}
        webSize="2x"
        color={Color.Gray}
      />
    </TouchableOpacity>
  )

  private renderCustomIconButtons = () => {
    return (
      <>
        <View style={styles.iconButtonGroup}>
          {this.customIcon('breadLoaf', faBreadLoaf as never)}
          {this.customIcon('handHoldingHeart', faHandHoldingHeart as never)}
          {this.customIcon('gamepadAlt', faGamepadAlt as never)}
          {this.customIcon('gift', faGift as never)}
          {this.customIcon('creditCard', faCreditCard as never)}
        </View>
        <View style={styles.iconButtonGroup}>
          {this.customIcon('cheeseburger', faCheeseburger as never)}
          {this.customIcon('ringsWedding', faRingsWedding as never)}
          {this.customIcon('book', faBook as never)}
          {this.customIcon('analytics', faAnalytics as never)}
          {this.customIcon('phoneLaptop', faPhoneLaptop as never)}
        </View>
        <View style={styles.iconButtonGroup}>
          {this.customIcon('beer', faBeer as never)}
          {this.customIcon('ambulance', faAmbulance as never)}
          {this.customIcon('bicycle', faBicycle as never)}
          {this.customIcon('calendarAlt', faCalendarAlt as never)}
          {this.customIcon('pen', faPen as never)}
        </View>
        <View style={styles.iconButtonGroup}>
          {this.customIcon('bedAlt', faBedAlt as never)}
          {this.customIcon('bone', faBone as never)}
          {this.customIcon('chalkboardTeacher', faChalkboardTeacher as never)}
          {this.customIcon('ballotCheck', faBallotCheck as never)}
          {this.customIcon('briefcase', faBriefcase as never)}
        </View>
        <View style={styles.iconButtonGroup}>
          {this.customIcon('chair', faChair as never)}
          {this.customIcon('smoking', faSmoking as never)}
          {this.customIcon('camera', faCamera as never)}
          {this.customIcon('mobile', faMobile as never)}
          {this.customIcon('lockAlt', faLockAlt as never)}
        </View>
        <View style={[styles.iconButtonGroup, { marginBottom: wp(10) }]}>
          {this.customIcon('babyCarriage', faBabyCarriage as never)}
          {this.customIcon('icons', faIcons as never)}
          {this.customIcon('backpack', faBackpack as never)}
          <View style={styles.iconButton} />
          <View style={styles.iconButton} />
        </View>
        <View style={styles.iconButtonGroup}>
          {this.customIcon('bell', faBell as never)}
          {this.customIcon('check', faCheck as never)}
          {this.customIcon('commentAltCheck', faCommentAltCheck as never)}
          {this.customIcon('leaf', faLeaf as never)}
          {this.customIcon('plusCircle', faPlusCircle as never)}
        </View>
        <View style={[styles.iconButtonGroup, { marginBottom: 40 }]}>
          {this.customIcon('award', faAward as never)}
          {this.customIcon('cloud', faCloud as never)}
          {this.customIcon('laughSquint', faLaughSquint as never)}
          {this.customIcon('paperPlane', faPaperPlane as never)}
          {this.customIcon('star', faStar as never)}
        </View>
      </>
    )
  }

  private onPressIconButton = (icon: string) => {
    this.props.route.params?.iconHandler(icon)
    NavigationService.goBack()
  }

  render() {
    return (
      <RootView>
        <CommonHeader title="アイコンの選択" />
        <ScrollView>
          <View style={[styles.wrapItem, { marginTop: 20 }]}>
            <Text
              style={{
                fontWeight: 'bold',
                color: '#3A3A3A',
                marginBottom: 10,
              }}>
              デフォルト
            </Text>
            {this.renderDefaultColorButtons()}
          </View>
          <View style={[styles.wrapItem, { marginTop: 20 }]}>
            <Text
              style={{
                fontWeight: 'bold',
                color: '#3A3A3A',
                marginBottom: 10,
              }}>
              カスタム
            </Text>
            {this.renderCustomIconButtons()}
          </View>
        </ScrollView>
      </RootView>
    )
  }
}

export default CategoryIconPickerScreen

export const styles = StyleSheet.create({
  iconButtonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: NativeApp ? wp(3) : 20,
    marginBottom: NativeApp ? wp(6) : 20,
  },
  iconButton: {
    width: NativeApp ? wp(11) : 44,
    height: NativeApp ? wp(11) : 44,
    alignItems: 'center',
  },
  customIconButton: {
    width: NativeApp ? wp(11) : 40,
    height: NativeApp ? wp(11) : 40,
    alignItems: 'center',
  },
  iconImage: {
    width: NativeApp ? wp(11) : 44,
    height: NativeApp ? wp(11) : 44,
    resizeMode: 'contain',
    tintColor: Color.Gray,
  },
  wrapItem: {
    paddingHorizontal: wp(6),
    paddingTop: 8,
  },
})
