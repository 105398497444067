import CommonHeader from '@components/CommonHeader'
import TabBarLabel from '@components/TabBarLabel'
import TimelineTabLabel from '@components/TimelineTabLabel'
import { TabBarOptions } from '@constants'
import { useTabNavigatorBugWorkaround } from '@lib/hooks'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import ActivitiesScreen from './ActivitiesScreen'
import NoticesScreen from './NoticesScreen'

const Tab = createMaterialTopTabNavigator()

const TabScreen = () => {
  useTabNavigatorBugWorkaround()

  return (
    <Tab.Navigator backBehavior="none" tabBarOptions={TabBarOptions}>
      <Tab.Screen
        name="Activities"
        component={ActivitiesScreen}
        options={{
          tabBarLabel: ({ color }) => (
            <TabBarLabel color={color}>アクティビティー</TabBarLabel>
          ),
        }}
      />
      <Tab.Screen
        name="Notices"
        component={NoticesScreen}
        options={{
          tabBarLabel: ({ color: tintColor }) => (
            <TimelineTabLabel tintColor={tintColor} />
          ),
        }}
      />
    </Tab.Navigator>
  )
}

const TimelineScreen = () => (
  <>
    <CommonHeader title="タイムライン" />
    <TabScreen />
  </>
)

export default TimelineScreen
