import { UIBank } from '@lib/Env'
import Log from '@lib/Log'
import Osidori from '@lib/Osidori'
import SessionManager from '@lib/SessionManager'
import TrackingUtils from '@lib/TrackingUtils'
import { setEndTime, setStartTime } from '@redux/slice'
import { useCallback, useEffect, useMemo } from 'react'
import { Image, Linking, Platform, StatusBar, View } from 'react-native'
import { VERSION_CODE, VERSION_NAME } from 'react-native-dotenv'
import { useDispatch } from 'react-redux'
import { styles } from './styles'

const SplashScreen = () => {
  const dispatch = useDispatch()

  const init = useCallback(async () => {
    await SessionManager.init()

    if (Platform.OS !== 'web') {
      if (Platform.OS === 'android') {
        TrackingUtils.repro.setPlatform('Android')
      } else if (Platform.OS === 'ios') {
        TrackingUtils.repro.setPlatform('iOS')
      }
      TrackingUtils.repro.setOSVersion(Platform.Version.toString())
      TrackingUtils.repro.setAppVersion(VERSION_NAME)
      TrackingUtils.repro.setAppBuild(VERSION_CODE)
    }

    const url = await Linking.getInitialURL()
    Log.info('SplashScreen', { url })

    if (!url || url === 'osidori://' || url === 'osidori://redirect_top') {
      Osidori.start()
    } else {
      Osidori.start(url)
    }
  }, [])

  const logo = useMemo(
    () =>
      Platform.select({
        web: UIBank
          ? require('@images/uibank/ui-logo.webp')
          : require('@images/osidori-logo.png'),
        default: require('@images/osidori-logo.png'),
      }),
    [],
  )

  useEffect(() => {
    dispatch(setStartTime({ key: 'SplashScreen' }))
    init()
    return () => {
      dispatch(setEndTime({ key: 'SplashScreen' }))
    }
  }, [dispatch, init])

  return (
    <View style={styles.container}>
      <StatusBar
        translucent={true}
        backgroundColor="transparent"
        hidden={false}
        barStyle="dark-content"
      />
      <Image style={styles.image} source={logo} />
    </View>
  )
}

export default SplashScreen
