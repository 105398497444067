import { APIError, getApiUrl } from '@lib/api'
import Pairing, { PairingResponse } from '@lib/api/Pairing'
import ProfileManager from '@lib/ProfileManager'
import SessionManager from '@lib/SessionManager'
import moment from 'moment'
import { ENV } from 'react-native-dotenv'
import { UIBank } from './Env'
import TrackingUtils from './TrackingUtils'

class PairingManager {
  private _isRequested = false

  isRequested = () => this._isRequested

  makeInvitationText = async () => {
    this._isRequested = true

    const response = await Pairing.generateToken()
    if (!response.ok) {
      this._isRequested = false
      throw new APIError(response)
    }

    const token = (response.json as PairingResponse).app.token

    const expires = moment(SessionManager.getAccessTokenExpires()).format(
      'M/D H:mm',
    )

    this._isRequested = false

    return (
      !UIBank
        ? `【OsidOriの招待です】

      夫婦の家計簿・貯金アプリ「OsidOri（オシドリ）」をはじめましょう！

      ◆簡単2ステップで完了です
      ①まずはアプリをダウンロードし、ユーザー登録をしましょう(既に登録済みの方は手順②へ)
      https://www.osidori.co/

      ②アプリにログインした状態で下記URLをタップすれば、ペアリングが完了です♪
      （アプリにログインしている必要があります）
      ${getApiUrl()}/pairing.html?token=${token}

      （注）
      URLをクリック後しばらくしてもアプリが開かない場合は、URLをコピーしSafariかChromeなどのブラウザに貼り付けて開いて下さい。

      ※有効期限： ${expires}
`
        : `【「お金の管理 by OsidOri」の招待です】

      「お金の管理 by OsidOri」をはじめましょう！

      ◆簡単2ステップで完了です
      ①まずは「お金の管理 by OsidOri」にユーザー登録をしましょう（既に登録済みの方は手順②へ）

      《U I 銀行口座をお持ちの方》
      U I銀行 アプリをダウンロードいただき、アプリ内の「お金の管理 by OsidOri」を開いて登録する

      《U I 銀行口座をお持ちでない方》
      下記URLから登録する
      https://${ENV === 'production' ? 'uibank' : 'uibank-stg'}.osidori.co/

      ②「お金の管理 by OsidOri」にログインした状態で下記URLをタップすると、ペアリングが完了です♪
      （ログインしている必要があります）
      ${getApiUrl()}/pairing.html?token=${token}

      （注）
      URLをクリック後しばらくしても「お金の管理 by OsidOri」が開かない場合は、URLをコピーしSafariかChromeなどのブラウザに貼り付けて開いて下さい。
      ※有効期限： ${expires}`
    ).replace(/^[^\S\n]+/gm, '')
  }

  pairing = async (token: string) => {
    const response = await Pairing.pairing({ pairingToken: token })
    if (!response.ok) {
      throw new APIError(response)
    }
    TrackingUtils.repro.track('【Screen】pairing_number of pairs', 'Screen')
    TrackingUtils.adjust.trackEvent({ ios: 'xhnbb4', android: 'yagl98' })
    await ProfileManager.fetchPartnerProflie()
  }

  cancel = async () => {
    const response = await Pairing.cancel()
    if (!response.ok) {
      throw new APIError(response)
    }
  }
}

export default new PairingManager()
