import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type State = {
  loadingStatus: { [key: string]: boolean }
}

type Reducer = {
  setLoadingStatus: (
    state: State,
    action: PayloadAction<{ key: string; isLoading: boolean }>,
  ) => void
}

const initialState: State = {
  loadingStatus: {},
}

const slice = createSlice<State, Reducer>({
  name: 'appButton',
  initialState,
  reducers: {
    setLoadingStatus: (state, { payload }) => {
      state.loadingStatus[payload.key] = payload.isLoading
    },
  },
})

export const { setLoadingStatus } = slice.actions

export default slice.reducer
