import CommonDialog from '@lib/CommonDialog'
import NavigationService from '@lib/NavigationService'
import ProfileManager from '@lib/ProfileManager'
import ProgressHUD from '@lib/ProgressHUD'
import store from '@redux/store'

export const saveProfiles = async () => {
  try {
    ProgressHUD.show()

    const userProfile = store.getState().settingProfiles.userProfile
    const partnerProfile = store.getState().settingProfiles.partnerProfile

    await ProfileManager.updateUserProfile({
      updated: 1,
      name: userProfile.name,
      gender: userProfile.gender,
      birthday: userProfile.birthday,
      married:
        userProfile.married !== undefined
          ? userProfile.married
            ? 1
            : 0
          : undefined,
      partnerProfiles: {
        name: partnerProfile.name,
        gender: partnerProfile.gender,
        birthday: partnerProfile.birthday,
        married:
          partnerProfile.married !== undefined
            ? partnerProfile.married
              ? 1
              : 0
            : undefined,
      },
    })
  } catch (error) {
    CommonDialog.showError({ error })
  } finally {
    ProgressHUD.dismiss()
  }

  NavigationService.goBack()
}
