import IconImage from '@components/IcomImage'
import moment from 'moment'
import { TouchableOpacityProps } from 'react-native'
import styled from 'styled-components/native'

const StyledTouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`

const StyledDateText = styled.Text`
  font-size: ${wp(5)}px;
  font-weight: normal;
  color: rgb(58, 58, 58);
`

const StyledIconImage = styled(IconImage)`
  margin-left: ${wp(2)}px;
  width: ${wp(4)}px;
  height: ${wp(4)}px;
  resize-mode: contain;
`

export type DateButtonProps = { date: Date } & TouchableOpacityProps

const DateButton = ({ date, ...props }: DateButtonProps) => {
  return (
    <StyledTouchableOpacity {...props}>
      <StyledDateText>{moment(date).format('YYYY/MM')}</StyledDateText>
      <StyledIconImage name="ArrowDown" />
    </StyledTouchableOpacity>
  )
}

export default DateButton
