import NavigationService from '@lib/NavigationService'

export type NavigationGoalsParams = {
  goalId: number
}

export const navigationGoals = (params: NavigationGoalsParams) => {
  NavigationService.navigate('GoalDetail', {
    ...params,
  })
}
