import { Component } from 'react'
import {
  ActivityIndicator,
  StyleSheet,
  View,
  unstable_createElement as createElement,
} from 'react-native'

export class WebPurchaseWebView extends Component {
  static defaultProps = {
    scrollEnabled: true,
  }

  state = { html: null }

  constructor(props) {
    super(props)
    // this.handleSource(props.source, props.newWindow)
  }

  setRef = (ref) => (this.frameRef = ref)

  componentDidMount() {
    if (this.props.onMessage) {
      // eslint-disable-next-line no-undef
      window.addEventListener('message', this.onMessage, true)
    }

    const { uri } = this.props.source
    const baseUrl = uri.substr(0, uri.lastIndexOf('/') + 1)

    //   fetch(this.props.source.uri, { mode: 'no-cors' })

    fetch(this.props.source.uri)
      .then((response) => response.text())
      .then((html) => {
        const newHtml = html
          .replace('<head>', `<head><base href="${baseUrl}" />`)
          .replace('</body>', `<script>${injectedJavaScript}</script></body>`)
        this.setState({ html: newHtml })
      })
  }

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     this.props.source.uri !== nextProps.source.uri ||
  //     this.props.source.method !== nextProps.source.method ||
  //     this.props.source.body !== nextProps.source.body
  //   ) {
  //     this.handleSource(nextProps.source, nextProps.newWindow)
  //   }
  // }

  componentWillUnmount() {
    if (this.props.onMessage) {
      // eslint-disable-next-line no-undef
      window.removeEventListener('message', this.onMessage, true)
    }
  }

  onMessage = (nativeEvent) => {
    this.props.onMessage({ nativeEvent })
  }

  postMessage = (message, origin) => {
    this.frameRef.contentWindow.postMessage(message, origin)
  }

  handleInjectedJavaScript = (html) => {
    if (this.props.injectedJavaScript) {
      if (html) {
        return html.replace(
          '</body>',
          `<script>${this.props.injectedJavaScript}</script></body>`,
        )
      } else {
        return html
      }
    } else {
      return html
    }
  }

  render() {
    if (!this.state.html) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator />
        </View>
      )
    }

    const { title, source, onLoad, scrollEnabled } = this.props
    const styleObj = StyleSheet.flatten(this.props.style)
    return createElement('iframe', {
      title,
      ref: this.setRef,
      // src: !source.method ? source.uri : undefined,
      srcDoc: this.handleInjectedJavaScript(this.state.html || source.html),
      width: styleObj && styleObj.width,
      height: styleObj && styleObj.height,
      style: StyleSheet.flatten([
        styles.iframe,
        scrollEnabled && styles.noScroll,
        this.props.style,
      ]),
      allowFullScreen: true,
      allowpaymentrequest: 'true',
      frameBorder: '0',
      seamless: true,
      onLoad,
    })
  }
}

export default WebPurchaseWebView

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
  noScroll: {
    overflow: 'hidden',
  },
})

const injectedJavaScript = `
  $('.tamesu.plan1_link').click(function() {
     window.parent.postMessage($('.tamesu.plan1_link').attr('href'), '*');
     return false;
  });

  $('.tamesu.plan2_link').click(function() {
     window.parent.postMessage($('.tamesu.plan2_link').attr('href'), '*');
     return false;
  });

  $('.tamesu.plan3_link').click(function() {
     window.parent.postMessage($('.tamesu.plan3_link').attr('href'), '*');
     return false;
  });

  $('.tamesu.plan4_link').click(function() {
     window.parent.postMessage($('.tamesu.plan4_link').attr('href'), '*');
     return false;
  });
`
