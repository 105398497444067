import { UserAccountType } from '@interfaces/Account'
import { APIError } from '@lib/api'
import store from '@redux/store'
import * as LifePlanAPI from './api'
import { CreateLifePlanEventProps, GetLifePlanEventResponse } from './types'

export const getLifePlanEvents = async () => {
  const response = await LifePlanAPI.getLifePlanEvents()
  if (!response.ok) throw new APIError(response)
  return (response.json as GetLifePlanEventResponse).events
}

export const getLifeplanCategoryByGoalContentId = (
  goalContentId: number,
  userAccountType: UserAccountType,
) => {
  const goalCategories = store.getState().goal.goalCategories[userAccountType]

  if (!goalCategories) return undefined

  for (let i = 0; i < goalCategories.length; i++) {
    if (goalCategories[i].goalContents.find((v) => v.id === goalContentId)) {
      return goalCategories[i]
    }
  }
}

export const createLifePlanEvent = async (props: CreateLifePlanEventProps) => {
  const response = await LifePlanAPI.createLifePlanEvent(props)
  if (!response.ok) throw new APIError(response)
}
