import React from 'react'
import { View, ViewProps } from 'react-native'

const AbsolutePositionView: React.FC<ViewProps> = ({ children, style }) => (
  <View
    style={[
      {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      style,
    ]}>
    {children}
  </View>
)

export default AbsolutePositionView
