import { TransactionDetailScreenNavigationParams } from '@Screen/Main/TransactionDetailScreen'
import AppButton from '@components/AppButton'
import { AccountState } from '@lib/AccountManager/types'
import Color from '@lib/Color'
import DateUtilsV1 from '@lib/DateUtilsV1'
import FinancialManager from '@lib/FinancialManager'
import { FinancialState } from '@lib/FinancialManager/types'
import NavigationService, {
  navigateToNavigatableScreen,
} from '@lib/NavigationService'
import { ProfileState } from '@lib/ProfileManager/types'
import TrackingUtils from '@lib/TrackingUtils'
import { RootStackParamList } from '@navigation/Screens'
import { StackScreenProps } from '@react-navigation/stack'
import store from '@redux/store'
import moment from 'moment'
import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import HomeExpensesSharingPieChart2 from './HomeExpensesSharingPieChart2'

interface Props {}

interface StateProps {
  financial: FinancialState
  profile: ProfileState
  account: AccountState
}

class HomeExpensesSharing extends React.Component<
  Props &
    StateProps &
    StackScreenProps<RootStackParamList, 'HomeExpensesSharing'>
> {
  componentDidMount() {
    this.props.navigation.addListener('focus', async () => {
      this.fetchSummary()
      if (this.props.account.accountMode === 'family') {
        TrackingUtils.repro.track('【Screen】Family_PL_share', 'Screen')
      }
    })
  }

  private fetchSummary = () => {
    FinancialManager.fetchHomeExpensesSummary({ date: moment().format() })
  }

  private renderSummary = () => {
    const thisMonth = DateUtilsV1.monthKey()
    const summary = this.props.financial.homeExpensesSummary[thisMonth]
    if (!summary) return null
    return (
      <>
        <TouchableOpacity
          style={styles.container}
          onPress={() =>
            navigateToNavigatableScreen([
              { name: 'HomeExpensesShareDetail' },
              {
                name: 'HouseholdAccountsTab',
                params: { tab: 'HomeExpensesShareDetail' },
              },
            ])
          }>
          <HomeExpensesSharingPieChart2
            summary={summary}
            displayDetailButton={true}
          />
        </TouchableOpacity>
        <View style={styles.footerView}>
          <Text style={styles.footerTitle}>ふたりの差分</Text>
          <Text style={styles.footerAmount}>
            {summary.ownerPartnerDiffAmount.jpCurrency()}
          </Text>
        </View>
      </>
    )
  }

  private renderMonthlyBalanceNoData = () => (
    <View style={styles.monthlyBalanceNoData}>
      <Text style={styles.titleMonthlyBalanceNoData}>支出の分担</Text>
      <View style={styles.contentMonthlyBalanceNoData}>
        <Image
          resizeMode="contain"
          source={require('@images/chartCircle/items-chart-circle.png')}
          style={styles.iconChartCircle}
        />
        <View style={styles.ViewMonthlyBalanceNoDataRight}>
          <Text style={styles.contentMonthlyBalanceNoDataRight}>
            各自が分担して支出した金額・割合・内容が表示されます
          </Text>
          <View style={styles.wrapButtonContentMonthlyBalanceNoDataRight}>
            <AppButton
              type="secondary-min"
              title="入力する"
              onPress={() =>
                NavigationService.navigate('CreateTransactionDetail', {
                  financialAccountType: 'manually_created',
                } as TransactionDetailScreenNavigationParams)
              }
            />
          </View>
        </View>
      </View>
    </View>
  )

  render() {
    const { accountMode } = this.props.account
    const isDisplayFinancialAccounts =
      FinancialManager.getFinancialAccountCount() > 0 ||
      this.props.financial.manuallyCreatedTransactionsCount > 0 ||
      (accountMode === 'family' &&
        this.props.profile.userStatus?.groupTransactionShared)
    return isDisplayFinancialAccounts
      ? this.renderSummary()
      : this.renderMonthlyBalanceNoData()
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 23,
    paddingBottom: 15,
  },
  monthlyBalanceNoData: {
    width: '100%',
    height: 200,
    paddingTop: 20,
    paddingHorizontal: 20,
    backgroundColor: Color.White,
    marginTop: 15,
  },
  titleMonthlyBalanceNoData: {
    color: '#555555',
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 21,
  },
  contentMonthlyBalanceNoData: {
    width: '100%',
    flexDirection: 'row',
  },
  iconChartCircle: {
    width: 110,
    height: 110,
    flex: 1,
  },
  contentMonthlyBalanceNoDataRight: {
    color: '#8e8e8e',
    fontSize: 13,
    fontWeight: 'normal',
  },
  ViewMonthlyBalanceNoDataRight: {
    flex: 1.3,
  },
  wrapButtonContentMonthlyBalanceNoDataRight: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  footerView: {
    borderTopColor: Color.LightGray,
    borderTopWidth: 2,
    paddingHorizontal: 20,
    height: 50,
    maxHeight: 50,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  footerTitle: {
    color: Color.DefaultText,
    fontSize: 14,
    fontWeight: 'normal',
    marginRight: 10,
  },
  footerAmount: {
    fontSize: 14,
    fontWeight: 'normal',
  },
})

const mapStateToProps = (state: ReturnType<typeof store.getState>) => {
  return {
    financial: state.financial,
    profile: state.profile,
    account: state.account,
  }
}

export default connect(mapStateToProps)(HomeExpensesSharing)
