import { Image } from '@components'
import * as Buttons from '@lib/Buttons'
import Color from '@lib/Color'
import React from 'react'
import styled from 'styled-components/native'

export const StyledRootView = styled.View`
  background-color: white;
  position: absolute;
  top: 70px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 10px;
  shadow-color: rgb(0, 0, 0);
  shadow-opacity: 0.25;
  shadow-radius: 10px;
  shadow-offset: {
    width: 0px;
    height: 5px;
  }
  elevation: 4;
`

export const StyledContentView = styled.ScrollView`
  /* flex-direction: column; */
`

export const StyledCloseButton = styled.TouchableOpacity`
  position: absolute;
  padding: 15px;
  align-self: flex-end;
`

export const StyledDefaultText = styled.Text<{ space?: boolean }>`
  color: ${Color.DefaultText};
  font-size: 14px;
  font-weight: normal;
  margin-left: ${({ space }) => (space ? 10 : 0)}px;
`

export const StyledBoldText = styled.Text`
  color: ${Color.DefaultText};
  font-size: 14px;
  font-weight: bold;
`

export type Props = {
  onPressCloseButton?: () => void
}

export const HelpDialog: React.FC<Props> = ({ onPressCloseButton }) => {
  return (
    <StyledRootView>
      <StyledContentView
        contentContainerStyle={{ padding: 20, paddingTop: 40 }}>
        <StyledDefaultText>
          新規で取引明細を作成する際の、明細作成画面のデフォルト値を設定します。
          {'\n'}
        </StyledDefaultText>
        <StyledDefaultText>
          各項目については以下を示しています。{'\n'}
        </StyledDefaultText>
        <StyledBoldText>カテゴリー</StyledBoldText>
        <StyledDefaultText space>
          指定のカテゴリーに変更できます。{'\n'}
          またカテゴリー作成や削除等も実施できます。{'\n'}
        </StyledDefaultText>
        <StyledBoldText>支払い担当</StyledBoldText>
        <StyledDefaultText space>
          この取引のお支払いをされた人を指します。{'\n'}
        </StyledDefaultText>
        <StyledBoldText>支払い元</StyledBoldText>
        <StyledDefaultText space>
          支払ったお金はどこから払ったかを記載します。{'\n'}
          デフォルト値は現金ですが、財布を作成することができますので、任意の支払い元を作成することが可能です。
          {'\n'}
        </StyledDefaultText>
        <StyledBoldText>表示する画面</StyledBoldText>
        <StyledDefaultText space>
          この取引を保存し、閲覧する場所を指します。{'\n'}
          家族共有画面で両者で確認するか、個別で管理するかを選ぶことができます。
          {'\n'}
        </StyledDefaultText>
        <StyledBoldText>未精算リストに入れる</StyledBoldText>
        <StyledDefaultText space>
          この取引を事後に精算する場合はこちらをチェックしてください。{'\n'}
        </StyledDefaultText>
        <StyledBoldText>収支の計算対象に入れる</StyledBoldText>
        <StyledDefaultText space>
          家計簿の計算対象から外したい場合は、チェックを外してください。
        </StyledDefaultText>
      </StyledContentView>
      <StyledCloseButton onPress={onPressCloseButton}>
        <Image
          source={Buttons.TimeTree.Connect.Dialog.Close}
          style={{ width: 19, height: 19 }}
        />
      </StyledCloseButton>
    </StyledRootView>
  )
}
